import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { EscrowAlert } from 'spa/features/ui/EscrowAlert';
import AlertTitle from '@mui/material/AlertTitle';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { MediumText, SmallText } from 'spa/features/ui/EscrowTypography';
import { transactionSelector } from '../../transactionSlice';
import { usePatchTransactionMutation } from '../../transactionApi';
import { EscrowButton } from 'spa/features/ui/EscrowButton';

const getBodyText = (transactionType, inspectionDays, userRole) => {
  const inspectionDaysPhrase =
    inspectionDays === 1 ? `${inspectionDays} calendar day` : `${inspectionDays} calendar days`;
  const formattedTransactionType = transactionType.replace(/_/g, ' ');
  switch (userRole) {
    case 'buyer':
      return (
        <SmallText>
          Once you confirm that you have received the {formattedTransactionType}, the inspection
          period of <strong>{inspectionDaysPhrase}</strong> will start. Please make sure{' '}
          <strong> that you have the {formattedTransactionType} </strong> before proceeding.
        </SmallText>
      );
    case 'seller':
      return (
        <SmallText>
          Once you confirm that you have received the returned {formattedTransactionType}, the
          inspection period of <strong>{inspectionDaysPhrase}</strong> will start. Please make sure{' '}
          <strong> that you have the {formattedTransactionType} </strong> before proceeding.
        </SmallText>
      );
    default:
      return null;
  }
};

export const ItemReceiptConfirmation = ({ transactionId, inspectionDays, userRole }) => {
  const theme = useTheme();
  const isMobileViewport = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const [patchTransaction, { isLoading, error }] = usePatchTransactionMutation();
  const [errorMsg, setErrorMsg] = useState('');

  const transaction = useSelector(transactionSelector(transactionId));
  if (!transaction) {
    return null;
  }

  const title =
    userRole === 'buyer'
      ? `Confirm receipt of the ${transaction.transactionTypeCode.replace(/_/g, ' ')}`
      : `Confirm receipt of the returned ${transaction.transactionTypeCode.replace(/_/g, ' ')}`;
  const buttonCopy = `Confirm ${transaction.transactionTypeCode.replace(/_/g, ' ')} receipt`;
  const handleConfirm = async () => {
    try {
      await patchTransaction({
        id: transaction.id,
        ...(userRole === 'buyer' && { action: 'receive' }),
        ...(userRole === 'seller' && { action: 'receive_return' }),
      }).unwrap();
      setOpen(false);
    } catch (e) {
      // RTK handles the error
      let errorMsgTmp =
        "Your receipt couldn't be confirmed due to a technical issue. Please refresh the page and try again.";
      if (e.xRequestId) {
        errorMsgTmp += ` If the issue persists, contact support@escrow.com and provide the id: ${e.xRequestId}`;
      }
      setErrorMsg(errorMsgTmp);
    }
  };

  const handleModalClose = () => {
    if (isLoading) {
      return;
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <EscrowButton variant="contained" onClick={() => setOpen(true)}>
        {buttonCopy}
      </EscrowButton>
      <Dialog
        fullScreen={isMobileViewport}
        open={open}
        onClose={handleModalClose}
        maxWidth="md"
        PaperProps={{
          sx: {
            maxWidth: '710px',
          },
        }}
      >
        {isMobileViewport && (
          <IconButton
            sx={{ marginLeft: 'auto' }}
            onClick={handleModalClose}
            aria-label="Close Item Receipt Modal"
          >
            <CloseIcon sx={{ color: 'mono.dark', height: 24, width: 24 }} />
          </IconButton>
        )}
        {error && (
          <EscrowAlert>
            <AlertTitle>
              Unable to confirm the {transaction.transactionTypeCode.replace('_', ' ')} receipt
            </AlertTitle>
            {errorMsg}
          </EscrowAlert>
        )}
        <DialogTitle>
          <MediumText>{title}</MediumText>
        </DialogTitle>
        <DialogContent>
          {transaction &&
            transaction.transactionTypeCode &&
            getBodyText(transaction.transactionTypeCode, inspectionDays, userRole)}
        </DialogContent>
        <DialogActions>
          {!isMobileViewport && (
            <EscrowButton sx={isMobileViewport && { width: '100%' }} onClick={handleModalClose}>
              Back
            </EscrowButton>
          )}
          <EscrowButton
            sx={isMobileViewport && { width: '100%' }}
            variant="contained"
            disabled={isLoading}
            onClick={handleConfirm}
          >
            Confirm and start inspection
          </EscrowButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
