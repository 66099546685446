import React, { Fragment } from 'react';

const TransactionEscrowTerms = ({ transaction, buyer, seller }) => {
  const { description, items } = transaction;

  const inpsectionPeriod = items[0].inspection_period / 86400;
  const { payer_customer: payerEmail } = items[0].fees.find(({ type }) => type === 'escrow');
  const feePayer = [buyer, seller].find(({ email }) => email === payerEmail);

  return (
    <Fragment>
      <h4 className="partnerDashboard-details-sectionTitle">Escrow Terms</h4>
      <div className="partnerDashboard-details-tableContainer">
        <table className="transactionTable">
          <tbody className="transactionTable-body">
            <tr key={``} className="transactionTable-row">
              <td className="transactionTable-cell">Transaction Title:</td>
              <td className="transactionTable-cell">{description}</td>
            </tr>
            <tr key={``} className="transactionTable-row">
              <td className="transactionTable-cell">Buyer:</td>
              <td className="transactionTable-cell">
                {buyer.first_name} {buyer.last_name} ({buyer.email})
              </td>
            </tr>
            <tr key={``} className="transactionTable-row">
              <td className="transactionTable-cell">Seller:</td>
              <td className="transactionTable-cell">
                {seller.first_name} {seller.last_name} ({seller.email})
              </td>
            </tr>
            <tr key={``} className="transactionTable-row">
              <td className="transactionTable-cell">Inspection Period:</td>
              <td className="transactionTable-cell">{inpsectionPeriod} days</td>
            </tr>
            <tr key={``} className="transactionTable-row">
              <td className="transactionTable-cell">Escrow Fee Payee:</td>
              <td className="transactionTable-cell">
                {feePayer.first_name} {feePayer.last_name} ({payerEmail})
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default TransactionEscrowTerms;
