import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class MakeAnOfferHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogo: false,
    };

    this.handleLogoOnLoad = this.handleLogoOnLoad.bind(this);
  }

  handleLogoOnLoad() {
    this.setState({ ...this.state, showLogo: true });
  }

  renderPartnerLogo() {
    return (
      <div className={classnames('checkout-header-logo', { 'is-hidden': !this.state.showLogo })}>
        <img
          className="checkout-header-logo-img"
          alt="partner logo"
          src={`/api/auction/${this.props.auctionToken}/logo`}
          onLoad={this.handleLogoOnLoad}
        />
      </div>
    );
  }

  render() {
    return (
      <div className={classnames('checkout-header', { 'is-hidden': !this.state.showLogo })}>
        <div className="checkout-header-inner">{this.renderPartnerLogo()}</div>
      </div>
    );
  }
}

MakeAnOfferHeader.propTypes = {
  auctionToken: PropTypes.string,
};

export default MakeAnOfferHeader;
