export default {
  REGISTER_OPERATOR_STATUS_CALLBACKS: 'REGISTER_OPERATOR_STATUS_CALLBACKS',
  START_OLARK: 'START_OLARK',
  SET_OLARK_STATE: 'SET_OLARK_STATE',
  SET_IS_CONVERSING: 'SET_IS_CONVERSING',
  NOTIFY_KYC_SUBMISSION: 'NOTIFY_KYC_SUBMISSION',
  GENERAL_GROUP: 'GENERAL_GROUP',
  HOMEPAGE_GROUP: 'HOMEPAGE_GROUP',
  TRANSACTION_GROUP: 'TRANSACTION_GROUP',
  VT_GROUP: 'VT_GROUP',
  CHECKOUT_GROUP: 'CHECKOUT_GROUP',
  MY_TRANSACTIONS_GROUP: 'MY_TRANSACTIONS_GROUP',
  START_TRANSACTION_GROUP: 'START_TRANSACTION_GROUP',
  POPUP_DELAY: 6000,
  OLARK_INITIALISED_FLAG: 'olf',
};
