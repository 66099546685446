// Code requirements
import SearchResultsTpl from 'templates/js/searchresults.html';

import virtualize from '../utils/virtualize';

import Component from '../utils/Component';
import SearchConstants from '../constants/SearchConstants';
import SearchResultsStore from '../stores/SearchResultsStore';

function getState(category) {
  const results = SearchResultsStore.getResults(category);
  return {
    results: results.results,
    input: results.terms,
    error: results.error,
  };
}

export default class SearchResults extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.setState(getState(this.props.category));
    this._onChange = this._onChange.bind(this);
  }

  componentHasMounted() {
    SearchResultsStore.addChangeListener(this._onChange);
  }

  componentWillDismount() {
    SearchResultsStore.removeChangeListener(this._onChange);
  }

  _onChange() {
    this.setState(getState(this.props.category));
  }

  render() {
    const html = SearchResultsTpl.render({
      results: this.state.results,
      input: this.state.input,
      error: this.state.error,
    });
    return virtualize.fromHTML(html);
  }
}

setTimeout(() => {
  for (const e of document.querySelectorAll('[data-component="search-results"]')) {
    const component = new SearchResults({ category: SearchConstants.CATEGORY_MENU });
    component.placeInto(e);
  }
});
