import React from 'react';
import { renderUrlAsLink } from '../../../utils/StringToComponentHelper';

const FormError = ({ error }) => (
  <div className="defaultForm-errors" data-sentry-unmask="true">
    <strong>{renderUrlAsLink(error)}</strong>
  </div>
);

export default FormError;
