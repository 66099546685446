import React from 'react';
import Stack from '@mui/material/Stack';
import { IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { SmallText } from 'spa/features/ui/EscrowTypography';
import { EmailText } from './information/EmailText';
import { getNavigator } from '../../../../utils/globals';

const ContactTitle = ({ role }) => (
  <SmallText>
    <SmallText variant="bold" data-testid="shipping-information-contact-title">
      {`${role.charAt(0).toUpperCase() + role.slice(1)} contact information`}
    </SmallText>
  </SmallText>
);

const DestinationTitle = ({ role, useEmail }) => (
  <SmallText data-testid="shipping-information-destination-title">
    <SmallText variant="bold">
      {role.charAt(0).toUpperCase() + role.slice(1)} {useEmail ? 'email' : 'shipping'}
      {' address'}
    </SmallText>
  </SmallText>
);

const Contact = ({ name, phoneNumber }) => (
  <Stack data-testid="shipping-information-contact">
    <SmallText>{name}</SmallText>
    <SmallText style={{ overflowWrap: 'break-word', marginTop: '8px' }}>{phoneNumber}</SmallText>
  </Stack>
);

const Destination = ({ useEmail, address }) =>
  useEmail ? (
    <EmailText testId="shipping-information-destination" email={address} />
  ) : (
    <SmallText data-testid="shipping-information-destination">{address}</SmallText>
  );

const CopyIcon = ({ handleCopyAddress }) => (
  <IconButton
    aria-label="Copy Transaction Id"
    onClick={handleCopyAddress}
    sx={{ padding: 0, marginLeft: '8px' }}
  >
    <ContentCopyIcon
      sx={{
        width: '20px',
        height: '20px',
        color: 'secondary.dark',
        transform: 'scaleY(-1)',
      }}
    />
  </IconButton>
);

const ShippingInformation = ({
  role,
  transactionTypeCode,
  party: {
    phoneNumber,
    email,
    name,
    address: { line1, city, state, country, zipCode },
  },
  isMobileViewport,
  mobileSx,
}) => {
  const useEmail = transactionTypeCode === 'domain_name';
  const address = useEmail
    ? email
    : `${line1} ${city} ${state}, ${country}${zipCode ? `, ${zipCode}` : ''}`;
  const handleCopyAddress = async () => getNavigator().clipboard.writeText(address);

  return isMobileViewport ? (
    <Stack spacing={2} sx={{ ...mobileSx }}>
      <Stack>
        <ContactTitle role={role} />
        <Contact name={name} phoneNumber={phoneNumber} />
      </Stack>
      <Stack>
        <DestinationTitle role={role} useEmail={useEmail} />
        <Destination useEmail={useEmail} address={address} />
      </Stack>
    </Stack>
  ) : (
    <Stack direction="row" sx={{ marginTop: '24px' }}>
      <Stack sx={{ width: '50%' }} spacing="8px">
        <ContactTitle role={role} />
        <Contact name={name} phoneNumber={phoneNumber} />
      </Stack>
      <Stack direction="row" sx={{ width: '50%' }}>
        <Stack spacing="8px">
          <DestinationTitle role={role} useEmail={useEmail} />
          <Destination useEmail={useEmail} address={address} />
        </Stack>
        <CopyIcon handleCopyAddress={handleCopyAddress} />
      </Stack>
    </Stack>
  );
};

export default ShippingInformation;
