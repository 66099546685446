import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import {
  kycSetIsCompany as kycSetIsCompanyRoutine,
  kycGotoNextPage as kycGotoNextPageRoutine,
} from 'spa/actions/VerificationActions';
import VerificationConstants, { KYCPages } from 'spa/constants/VerificationConstants';
import Spinner from 'spa/components/Indicators/Spinner';
import CustomerDetails from './CustomerDetails';
import { AccountTypeSwitcher } from './Fields';
import {
  customerCompanyNameSelector,
  customerIsCompanySelector,
  customerKycLoadingUiSelector,
  customerKycSubmitLoadingSelector,
} from '../../../selectors/CustomerSelectors';

const VerificationDetails = ({
  isCompany,
  hasCompanyName,
  isLoading,
  setIsCompany,
  loadNextPage,
  isAccountSwitchSubmitting,
}) => (
  <React.Fragment>
    {isLoading && <Spinner />}
    <div className="kyc-verificationDetails">
      <Form
        onSubmit={(values) => setIsCompany(values['account-type'])}
        initialValues={{
          'account-type': isCompany
            ? VerificationConstants.ACCOUNT_TYPE_COMPANY
            : VerificationConstants.ACCOUNT_TYPE_INDIVIDUAL,
        }}
      >
        {({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} name={VerificationConstants.ACCOUNT_TYPE_FORM}>
            <AccountTypeSwitcher
              onConfirm={(accountType) => {
                if (!hasCompanyName && accountType === VerificationConstants.ACCOUNT_TYPE_COMPANY) {
                  loadNextPage({
                    nextPage: KYCPages.COMPANY_DETAILS_FORM,
                    isCompany: isCompany,
                  });
                } else {
                  form.submit();
                }
              }}
              isCompany={isCompany}
              isSubmitting={isAccountSwitchSubmitting}
            />
          </form>
        )}
      </Form>
      <div className="kyc-section" data-testid="kyc-details-customer">
        <CustomerDetails />
      </div>
      {isCompany ? (
        <div className="kyc-section" data-testid="kyc-details-company">
          <CustomerDetails company />
        </div>
      ) : null}
    </div>
  </React.Fragment>
);

const mapStateToProps = (state) => ({
  isCompany: customerIsCompanySelector(state),
  hasCompanyName: Boolean(customerCompanyNameSelector(state).trim()),
  isLoading: customerKycLoadingUiSelector(state),
  isAccountSwitchSubmitting: customerKycSubmitLoadingSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  setIsCompany: (accountType) => dispatch(kycSetIsCompanyRoutine.trigger(accountType)),
  loadNextPage: (nextPage) => dispatch(kycGotoNextPageRoutine.trigger(nextPage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerificationDetails);
