import React, { Fragment } from 'react';
import UserControl from 'spa/containers/UserControl';
import { gettext } from '../../../utils/filters';
import { getWindow } from '../../../utils/globals';

const LoggedInNav = () => {
  const window = getWindow();

  const handleStartNewTransaction = () => {
    // Need to do this because window is undefined inside the component during render
    window.location = window.config.transaction_choice;
  };

  return (
    <Fragment>
      <ul className="headerV3-nav">
        <li className="headerV3-nav-item">
          <a
            className="btn btn--secondary media--hidden@tablet"
            onClick={handleStartNewTransaction}
            role="button"
            tabIndex={0}
          >
            {gettext('Start New Transaction')}
          </a>
        </li>
        <UserControl />
      </ul>
    </Fragment>
  );
};

export default LoggedInNav;
