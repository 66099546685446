export const baseSelector = (state) => state.payment;
export const cardsSelector = (state) =>
  baseSelector(state) ? baseSelector(state).cardsById : null;
export const cardByIdSelector = (state, cardId) =>
  cardsSelector(state) ? cardsSelector(state)[cardId] : null;
export const cardVerificationDetailsSelector = (state) =>
  baseSelector(state) ? baseSelector(state).cardVerificationById : null;
export const cardVerificationDetailsByIdSelector = (state, cardVerificationId) =>
  cardVerificationDetailsSelector(state)
    ? cardVerificationDetailsSelector(state)[cardVerificationId]
    : null;
export const paymentsSelector = (state) =>
  baseSelector(state) ? baseSelector(state).payments : null;
export const paypalSelector = (state) =>
  paymentsSelector(state) ? paymentsSelector(state).paypal : null;
export const paypalLoadingSelector = (state) =>
  paypalSelector(state) ? paypalSelector(state).loading : null;
export const paypalSuccessSelector = (state) =>
  paypalSelector(state) ? paypalSelector(state).success : null;
export const paypalErrorSelector = (state) =>
  paypalSelector(state) ? paypalSelector(state).error : null;
export const paypalPartnerSelector = (state) =>
  paypalSelector(state) ? paypalSelector(state).partner : null;
export const buyerBankDetailsSubmission = (state) =>
  baseSelector(state) ? baseSelector(state).submitUserWireDetails : null;
export const lastBuyerBankEntrySelector = (state) =>
  buyerBankDetailsSubmission(state) ? buyerBankDetailsSubmission(state).lastBuyerBankEntry : null;
export const adyenDetailsSelector = (state) =>
  baseSelector(state) ? baseSelector(state).adyenDetails : null;
export const adyenFormSelector = (state) =>
  adyenDetailsSelector(state) ? adyenDetailsSelector(state).form : null;
export const adyenFormDataSelector = (state) =>
  adyenFormSelector(state) ? adyenFormSelector(state).data : null;
export const adyenIsValidSelector = (state) =>
  adyenFormDataSelector(state) ? adyenFormSelector(state).isValid : false;
export const adyenInitSelector = (state) =>
  adyenDetailsSelector(state) ? adyenDetailsSelector(state).init : null;
export const adyenAvailablePaymentMethodsSelector = (state) =>
  adyenInitSelector(state) ? adyenInitSelector(state).availablePaymentMethods : null;
export const adyenInitCheckoutSelector = (state) =>
  adyenInitSelector(state) ? adyenInitSelector(state).checkout : null;
export const adyenHasCCSelector = (state) =>
  adyenAvailablePaymentMethodsSelector(state)
    ? (adyenAvailablePaymentMethodsSelector(state).paymentMethods || []).some(
        (method) => method.name === 'Credit Card'
      )
    : false;
export const adyenThreeDSResultSelector = (state) =>
  adyenDetailsSelector(state) ? adyenDetailsSelector(state).threeDSResult : null;
export const adyenThreeDSResultCodeSelector = (state) =>
  adyenThreeDSResultSelector(state) ? adyenThreeDSResultSelector(state).result_code : null;
export const adyenActionSelector = (state) =>
  adyenDetailsSelector(state) ? adyenDetailsSelector(state).action : null;
export const adyenUiSelector = (state) =>
  adyenDetailsSelector(state) ? adyenDetailsSelector(state).ui : null;
export const adyenUiLoadingSelector = (state) =>
  adyenUiSelector(state) ? adyenUiSelector(state).loading : null;
export const adyenUiErrorSelector = (state) =>
  adyenUiSelector(state) ? adyenUiSelector(state).error : null;
