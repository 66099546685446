import React from 'react';
import Footer from 'spa/containers/Footer';
import AuthenticationContainer from 'spa/containers/Authentication';
import AuthenticationConstants from 'spa/constants/AuthenticationConstants';

const AuthenticationPage = () => (
  <div>
    <div className="verifyPage section section--mid">
      <div className="checkout-content section-container">
        <div className="verifyPage-body">
          <div className="verifyPage-card">
            <AuthenticationContainer loginPage={AuthenticationConstants.DEFAULT_LOGIN_PAGE} />
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
);

export default AuthenticationPage;
