import { connect } from 'react-redux';
import HeaderV3 from 'spa/components/HeaderV3';
import { toggleSidebar } from 'spa/actions/HeaderActions';
import { sendVerificationEmail } from 'spa/actions/TwoFactorActions';
import {
  customerEmailVerificationStatusSelector,
  customerEmailVerificationSending,
  customerEmailVerificationSent,
  customerDetailsLoadingSelector,
} from 'spa/selectors/CustomerSelectors';

const mapStateToProps = (state) => ({
  emailSending: customerEmailVerificationSending(state),
  emailSent: customerEmailVerificationSent(state),
  emailVerified:
    customerEmailVerificationStatusSelector(state) || customerDetailsLoadingSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  toggleSidebar: () => dispatch(toggleSidebar()),
  sendVerificationEmail: () => dispatch(sendVerificationEmail.trigger()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderV3);
