const userSelector = (state) => state.user;

const userLoadingSelector = (state) => {
  const loading = userSelector(state).loading;
  if (typeof loading === 'undefined') {
    return true;
  }
  return loading;
};

const userErrorSelector = (state) => userSelector(state).error;

const userInfoSelector = (state) => userSelector(state).info || {};

const partnerIDSelector = (state) => userInfoSelector(state).partnerID;

export { userSelector, userLoadingSelector, userErrorSelector, partnerIDSelector };
