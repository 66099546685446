import React from 'react';
import PropTypes from 'prop-types';

const ErrorIndicator = ({ children }) => <div>{children}</div>;

ErrorIndicator.propTypes = {
  children: PropTypes.string,
};

ErrorIndicator.defaultProps = {
  children: 'An error has occured',
};

export default ErrorIndicator;
