import React from 'react';

import DisplayTaskList from 'spa/containers/DisplayTaskList';
import Footer from 'spa/components/Footer';

import {
  Section,
  SectionTitle,
  SectionDesc,
  SectionSubTitle,
  SectionList,
  SectionListItem,
} from 'spa/components/Section';

const ApiPage = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktopSmall-9">
        <h1 className="integrationsPortal-title">Webhooks</h1>
        <Section>
          <SectionTitle>Escrow.com Webhooks</SectionTitle>
          <SectionDesc>
            The Escrow.com Webhooks is the best way to bring secure payments to your marketplace or
            website. Webhooks integration gives you the most flexibility to create and manage
            transactions as well as display or notify.
          </SectionDesc>
          <SectionSubTitle>Benefits:</SectionSubTitle>
          <SectionList>
            <SectionListItem>Tightly integrated with your website or marketplace</SectionListItem>
            <SectionListItem>
              Full control over the forms and styling on your website
            </SectionListItem>
            <SectionListItem>Real-time updates of the status of each transaction</SectionListItem>
          </SectionList>
        </Section>
        <DisplayTaskList list="webhooks">
          <SectionTitle>Webhooks Integration Checklist</SectionTitle>
          <SectionDesc>
            To successfully integrate the Webhooks into your website, you’ll need to complete your
            account setup as well as an integration setup. The time it takes to complete the
            technical tasks varies, but the integration can be done in as little as a day.
          </SectionDesc>
        </DisplayTaskList>
        <Footer />
      </div>
    </div>
  </div>
);

export default ApiPage;
