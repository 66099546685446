import { createRoutine } from 'redux-saga-routines';

import UserConstants from 'spa/constants/UserConstants';

export function getUserRequest() {
  return {
    type: UserConstants.GET_USER_REQUEST,
  };
}

export function getUserSuccess(user) {
  return {
    type: UserConstants.GET_USER_SUCCESS,
    user,
  };
}

export function getUserFailure(message) {
  return {
    type: UserConstants.GET_USER_FAILURE,
    message,
  };
}

export function setUserDetailsFromV2(custId, v2CustomerData) {
  return {
    type: UserConstants.SET_USER_DETAILS_FROM_V2,
    custId,
    v2CustomerData,
  };
}

export function setUserDetailsFromV4(v4CustomerData = {}) {
  return {
    type: UserConstants.SET_USER_DETAILS_FROM_V4,
    v4CustomerData,
  };
}

/**
 *
 * @param {boolean} hasContactDetails
 */
export function setUserHasContactDetails(hasContactDetails) {
  return {
    type: UserConstants.SET_USER_HAS_CONTACT_DETAILS,
    payload: {
      hasContactDetails,
    },
  };
}

/**
 * @param {{isApproved?: boolean, isInProgress?: boolean, isSubmitted?: boolean}} newStatus
 */
export function updateIndividualVerificationStatus(newStatus) {
  return {
    type: UserConstants.UPDATE_INDIVIDUAL_VERIFICATION_STATUS,
    payload: newStatus,
  };
}

/**
 * @param {{isApproved?: boolean, isInProgress?: boolean, isSubmitted?: boolean}} newStatus
 */
export function updateCompanyVerificationStatus(newStatus) {
  return {
    type: UserConstants.UPDATE_COMPANY_VERIFICATION_STATUS,
    payload: newStatus,
  };
}

export const verifyTier2AndTier3 = createRoutine(UserConstants.VERIFY_KYC2_AND_KYC3);

export const fluxGetCustomerRequest = createRoutine(UserConstants.FLUX_GET_CUSTOMER_REQUEST);

export const addCustomerVatNumber = createRoutine(UserConstants.ADD_VAT_NUMBER);

export const deleteTaxNumber = createRoutine(UserConstants.DELETE_TAX_NUMBER);

export const getV4Customer = createRoutine(UserConstants.V4_GET_CUSTOMER);

export const submitPartnerLogo = createRoutine(UserConstants.SUBMIT_PARTNER_LOGO);

export const listPartnerLogos = createRoutine(UserConstants.LIST_PARTNER_LOGOS);

export const removePartnerLogo = createRoutine(UserConstants.REMOVE_PARTNER_LOGO);

export const setCustomerTaxNumber = createRoutine(UserConstants.SET_CUSTOMER_TAX_NUMBER);

export const setExtendedKycRequirement = createRoutine(UserConstants.SET_EXTENDED_KYC_REQUIREMENT);

export const verifyCustomerInformation = createRoutine(UserConstants.VERIFY_CUSTOMER_INFORMATION);

export const electronicallyVerifyCustomer = createRoutine(
  UserConstants.ELECTRONICALLY_VERIFY_CUSTOMER
);

export const getUserEKYCData = createRoutine(UserConstants.GET_USER_EKYC_DATA);

export const getSavedCreditCards = createRoutine(UserConstants.GET_SAVED_CREDIT_CARDS);

export const getSavedPlaidAccounts = createRoutine(UserConstants.GET_SAVED_PLAID_ACCOUNTS);

export const getUserVerificationStatuses = createRoutine(
  UserConstants.GET_USER_VERIFICATION_STATUSES
);
