import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'spa/components/Icon';

const CollapsableContentTitle = ({ children, toggleContent }) => (
  <header className="collapsableContent-header" onClick={toggleContent} role="button" tabIndex={0}>
    <Icon name="ui-chevron-right" className="collapsableContent-header-icon" />
    <h3 className="collapsableContent-header-title">{children}</h3>
  </header>
);

CollapsableContentTitle.propTypes = {
  children: PropTypes.node,
};

export default CollapsableContentTitle;
