export const getTypeTextSubstrings = (typeCode, multipleItems) => {
  let text;
  let itemText;
  switch (typeCode) {
    case 'motor_vehicle':
      text = 'a ';
      itemText = 'motor vehicle';
      if (multipleItems) {
        text = '';
        itemText = 'motor vehicles';
      }
      break;
    case 'general_merchandise':
      itemText = 'general merchandise';
      break;
    case 'domain_name':
      text = 'a ';
      itemText = 'domain name';
      if (multipleItems) {
        text = '';
        itemText = 'domain names';
      }
      break;
    default:
      text = 'an ';
      itemText = 'item';
      if (multipleItems) {
        text = '';
        itemText = 'items';
      }
      break;
  }

  return { text, itemText };
};
