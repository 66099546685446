import { createRoutine } from 'redux-saga-routines';

import APIIntegration from 'spa/constants/APIIntegrationConstants';

// GET
export function getAPIKeyRequest() {
  return {
    type: APIIntegration.GET_APIKEY_REQUEST,
  };
}

export function getAPIKeySuccess(apiKeys) {
  return {
    type: APIIntegration.GET_APIKEY_SUCCESS,
    apiKeys,
  };
}

export function getAPIKeyFailure(message) {
  return {
    type: APIIntegration.GET_APIKEY_FAILURE,
    message,
  };
}

// CREATE
export const createAPIKeys = createRoutine('CREATE_APIKEY');

// DELETE
export function deleteAPIKeyRequest(id) {
  return {
    type: APIIntegration.DELETE_APIKEY_REQUEST,
    id,
  };
}

export function deleteAPIKeySuccess(apiKey) {
  return {
    type: APIIntegration.DELETE_APIKEY_SUCCESS,
    apiKey,
  };
}

export function deleteAPIKeyFailure(apiKey) {
  return {
    type: APIIntegration.DELETE_APIKEY_FAILURE,
    apiKey,
  };
}
