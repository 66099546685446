var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/navigation/v2/startTransactionUpsell.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"headerV2-upsell media--hidden@tablet\">\n  <div class=\"headerV2-upsell-content\">\n    <h3 class=\"headerV2-upsell-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_1 = "";t_1 += "Start A Transaction With Escrow";
cb(null, t_1);
;
return t_1;
}
), true && env.opts.autoescape);
output += "</h3>\n    <p class=\"headerV2-upsell-desc\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_2 = "";t_2 += "Sell, buy or broker anything from domain names to vehicles";
cb(null, t_2);
;
return t_2;
}
), true && env.opts.autoescape);
output += "</p>\n  </div>\n  <div class=\"headerV2-upsell-action\">\n    <a href=\"";
output += runtime.suppressValue((lineno = 6, colno = 20, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".signup_page"])), env.opts.autoescape);
output += "?loginLocation=transaction-choice\" class=\"btn btn--secondary\" data-e2e-target=\"header-nav-start-transaction\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_3 = "";t_3 += "Get Started Now";
cb(null, t_3);
;
return t_3;
}
), true && env.opts.autoescape);
output += "</a>\n  </div>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/navigation/v2/startTransactionUpsell.html"] , dependencies)