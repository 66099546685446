import React from 'react';
import window from 'window-shim';
import { connect } from 'react-redux';
import { partnerIDSelector } from 'spa/selectors/UserSelectors';
import { Code } from 'spa/components/Code';
import { Section, SectionImage } from 'spa/components/Section';

class ReferralLinksBanner extends React.PureComponent {
  render() {
    return (
      <Section>
        <SectionImage
          src={`${window.config.ecart_url}/Content/Images/Affiliate%20Banners/${this.props.src}`}
          alt="Escrow.com: Buy or Sell Online Without the Fear of Fraud"
        />

        <Code language="html">
          {`
<a href="https://my.escrow.com/partner.asp?pid=${this.props.partnerID}">
    <img
      src="${`https://secureapi.escrow.com/api/ecart/Content/Images/Affiliate%20Banners/${this.props.src}`}"
      width="${this.props.width}"
      height="${this.props.height}"
      alt="Escrow.com: Buy or Sell Online Without the Fear of Fraud"
      border="0"
    />
</a>
          `}
        </Code>
      </Section>
    );
  }
}

const mapStateToProps = (state) => ({
  partnerID: partnerIDSelector(state),
});

const ReferralLinksBannerContainer = connect(mapStateToProps, null)(ReferralLinksBanner);

export default ReferralLinksBannerContainer;
