import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';

import { getUserRequest } from 'spa/actions/UserActions';
import { userLoadingSelector } from 'spa/selectors/UserSelectors';

import { Spinner } from 'spa/components/Indicators';

import { getRouteFromPath } from '../../../routeConfig';
import AuthenticationStore from '../../../stores/AuthenticationStore';

class AuthRequired extends React.Component {
  componentWillMount() {
    const pathname = this.props.location.pathname;
    const route = getRouteFromPath(pathname);

    if (route && (typeof route.auth_required === 'undefined' || route.auth_required)) {
      if (!AuthenticationStore.isAuthenticated()) {
        window.location = '/integrations/signup';
      } else {
        this.props.getUser();
      }
    }
  }

  render() {
    const { route, loading } = this.props;

    let content;
    if (loading) {
      content = <Spinner />;
    } else {
      content = renderRoutes(route.routes);
    }

    return <div>{content}</div>;
  }
}

AuthRequired.propTypes = {
  route: PropTypes.object,
};

const mapStateToProps = (state) => ({
  loading: userLoadingSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUser: () => dispatch(getUserRequest()),
});

const AuthRequiredContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthRequired)
);

export default AuthRequiredContainer;
