import React from 'react';
import ReactSVG from 'react-svg';

import Icon from 'spa/components/Icon';
import { gettext } from '../../utils/filters';

class MainFunnelHeader extends React.Component {
  renderEscrowLogo() {
    return <ReactSVG src={`../../../../build/images/global/escrow-logo-v2.svg`} />;
  }

  renderBackArrow() {
    return <Icon name="back-arrow" />;
  }

  renderShieldLogo() {
    return <Icon name="escrow-shield" />;
  }

  render() {
    return (
      <div data-header="payments_main_funnel">
        <header className="headerMainFunnel is-header-scrollTop is-sticky" data-sticky-header>
          <div className="headerMainFunnel-primary">
            <div className="headerMainFunnel-container section-container">
              <div className="headerMainFunnel-inner ">
                <a
                  className="headerMainFunnel-logo media--hidden@mobile"
                  href={`${window.config.www_base_url}/transactions`}
                >
                  {this.renderEscrowLogo()}
                </a>
                <a
                  className="headerMainFunnel-shield  media--available@mobile"
                  href={`${window.config.www_base_url}/transactions`}
                >
                  {this.renderBackArrow()}
                </a>
                <div className="headerMainFunnel-shield media--available@mobile">
                  {this.renderShieldLogo()}
                </div>
                <div className="headerMainFunnel-label media--available@mobile">
                  <strong>{gettext('Pay Now')}</strong>
                </div>
              </div>
              <a className="headerMainFunnel-inner" href="/learn-more" target="_blank">
                <span className="headerMainFunnel-avatar avatar media--available@mobile">
                  {gettext('?')}
                </span>
                <div className="headerMainFunnel-label media--hidden@mobile">
                  {gettext('Help and FAQs')}
                </div>
              </a>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default MainFunnelHeader;
