import React from 'react';

const SampleVerificationDocument = ({ title, description, imgAlt, imgSrc }) => (
  <div className="verifyPage-card verifyPage-sampleDoc">
    <div className="verifyPage-sampleDoc-body">
      <h2 className="verifyPage-sampleDoc-title">{title}</h2>
      <p className="verifyPage-sampleDoc-desc">{description}</p>
    </div>
    <div className="verifyPage-sampleDoc-figure">
      <img className="verifyPage-sampleDoc-img" alt={imgAlt} src={imgSrc} />
    </div>
  </div>
);

export default SampleVerificationDocument;
