var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["components/listItems.html"] = require( "components/listItems.html" );




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/dropdown.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("components/listItems.html", false, "escrow_www/templates/dropdown.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(context.getVariables(), frame, function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(t_1.hasOwnProperty("listItems")) {
var t_4 = t_1.listItems;
} else {
cb(new Error("cannot import 'listItems'")); return;
}
context.setVariable("listItems", t_4);
var macro_t_5 = runtime.makeMacro(
["items", "specifier", "mutators"], 
["attrs"], 
function (l_items, l_specifier, l_mutators, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("items", l_items);
frame.set("specifier", l_specifier);
frame.set("mutators", l_mutators);
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
var t_6 = "";t_6 += "<ul class=\"dropdown ";
t_6 += runtime.suppressValue(l_specifier, env.opts.autoescape);
t_6 += " ";
t_6 += runtime.suppressValue(l_mutators, env.opts.autoescape);
t_6 += "\" data-component=\"";
t_6 += runtime.suppressValue(l_specifier, env.opts.autoescape);
t_6 += "\" ";
t_6 += runtime.suppressValue(env.getFilter("xmlattr").call(context, runtime.contextOrFrameLookup(context, frame, "attrs")), env.opts.autoescape);
t_6 += ">\n  ";
t_6 += runtime.suppressValue((lineno = 4, colno = 12, runtime.callWrap(t_4, "listItems", context, [l_items])), env.opts.autoescape);
t_6 += "\n</ul>";
;
frame = frame.pop();
return new runtime.SafeString(t_6);
});
context.addExport("dropdown");
context.setVariable("dropdown", macro_t_5);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/dropdown.html"] , dependencies)