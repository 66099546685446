import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';

const EscrowAlert = styled(Alert)(() => ({
  bgcolor: 'background.paper',
  margin: '24px 24px 12px 24px',
}));

EscrowAlert.defaultProps = {
  ...EscrowAlert.defaultProps,
  variant: 'outlined',
  severity: 'error',
};

export { EscrowAlert };
