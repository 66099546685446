function calculateUseCapture(eventName) {
  const nonBubbling = ['blur', 'error', 'focus', 'load', 'resize', 'scroll'];
  return nonBubbling.indexOf(eventName) !== -1;
}

export class EventHandler {
  constructor(element) {
    this.events = {};

    if (element) {
      this.setEventListeners(element);
      this.element = element;
    }
  }

  addEventListener(event, selector, func) {
    if (this.element && !this.events[event]) {
      this.element.addEventListener(event, this.handleEvent.bind(this, event));
    }

    this.events[event] = this.events[event] || {};
    this.events[event][selector] = func;
  }

  removeEventListeners(event, selector) {
    if (this.element && !this.events[event]) {
      this.element.removeEventListener(event, this.handleEvent.bind(this, event));
    }

    this.events[event] = this.events[event] || {};
    delete this.events[event][selector];
  }

  handleEvent(eventName, event) {
    if (!this.events[eventName]) {
      return undefined;
    }

    function callCallbacks(element, events) {
      if (!element) {
        return undefined;
      }

      for (const selector of Object.keys(events[eventName])) {
        if (element.matches(selector)) {
          events[eventName][selector](event, element);
          return undefined;
        }
      }

      if (element.parentElement !== event.currentTarget) {
        callCallbacks(element.parentElement, events);
      }
    }

    callCallbacks(event.target, this.events);
  }

  setEventListeners(element) {
    for (const eventName of Object.keys(this.events)) {
      element.addEventListener(
        eventName,
        this.handleEvent.bind(this, eventName),
        calculateUseCapture(eventName)
      );
    }
  }
}

export const GlobalEventHandler = new EventHandler(document);
