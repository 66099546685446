import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'spa/components/Icon';

const HeaderBackArrow = (props) => (
  <a className="headerV3-menuBtn media--available@tablet" href={props.href}>
    <Icon name="back-arrow" />
  </a>
);

HeaderBackArrow.propTypes = {
  href: PropTypes.string.isRequired,
};

export default HeaderBackArrow;
