import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import QueryString from 'query-string';
import Spinner from 'spa/components/Indicators/Spinner';
import { processSuccessfulPaypalPayment as processSuccessfulPaypalPaymentRoutine } from 'spa/actions/PaymentsActions';
import {
  paypalLoadingSelector,
  paypalSuccessSelector,
  paypalErrorSelector,
  paypalPartnerSelector,
} from 'spa/selectors/PaymentSelectors';
import ErrorMessageBox from 'spa/components/ErrorMessageBox';
import { ConfirmationMessageBox } from 'spa/components/CheckoutDetails';

const PaypalSuccessPage = (props) => {
  const {
    processSuccessfulPaypalPayment,
    loading,
    success,
    error,
    partner,
    match: {
      params: { transaction_id: transactionId },
    },
  } = props;
  const companyName = partner && partner.name;
  const { return_url: returnUrl } = QueryString.parse(window.location.search);
  const heading =
    "Success! Your transaction's payment has been sent. Please wait for Escrow.com to confirm your payment";

  useEffect(() => {
    processSuccessfulPaypalPayment();
  }, [processSuccessfulPaypalPayment]);

  return (
    <div className="accountInfo">
      <section className="section section--small section--mid">
        <div className="section-container">
          <div className="account-inner">
            <div className="checkout-card checkout-card-secondary card">
              <div className="checkout-card-content checkout-card-content--light">
                {loading && <Spinner />}
                {error && !success && !loading && <ErrorMessageBox isCurrentStep heading={error} />}
                {success && !error && !loading && (
                  <ConfirmationMessageBox
                    isCurrentStep
                    heading={heading}
                    returnUrl={returnUrl}
                    companyName={companyName}
                    transactionId={transactionId}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: paypalLoadingSelector(state),
  success: paypalSuccessSelector(state),
  error: paypalErrorSelector(state),
  partner: paypalPartnerSelector(state),
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const transactionId = parseInt(ownProps.match.params.transaction_id, 10);
  const queryString = QueryString.parse(window.location.search);
  return {
    processSuccessfulPaypalPayment: () =>
      dispatch(
        processSuccessfulPaypalPaymentRoutine.trigger({
          transactionId,
          paypalRef: queryString.tx,
          returnUrl: queryString.return_url,
          redirectType: queryString.redirect_type,
        })
      ),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaypalSuccessPage));
