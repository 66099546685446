import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'escrow-common-js/dist/components';
import TwoFactorConstants from 'spa/constants/TwoFactorConstants';
import { gettext } from '../../../utils/filters';

const ConfigCompleteContainer = (props) => (
  <div>
    <div className="twoFactorAuth-card-header">
      <img
        alt="Success"
        className="twoFactorAuth-page-icon"
        src="../../../../../build/images/global/success-2fa.gif"
      />
      <h2 className="twoFactorAuth-card-header-title twoFactorAuth-card-header-title--auth">
        {gettext(TwoFactorConstants.CONFIG_COMPLETE_TITLE[props.actionType][props.configType])}
      </h2>
      {props.actionType !== TwoFactorConstants.ACTION_VERIFY && (
        <div className="twoFactorAuth-page-subheader twoFactorAuth-page-subheader--auth">
          {gettext(TwoFactorConstants.CONFIG_COMPLETE_MSG[props.actionType][props.configType])}
        </div>
      )}
    </div>
    {props.actionType !== TwoFactorConstants.ACTION_VERIFY && (
      <div className="twoFactorAuth-page">
        <Button className="twoFactorAuth-card-btn" onClick={props.navigateMain}>
          {gettext('Done')}
        </Button>
      </div>
    )}
  </div>
);

ConfigCompleteContainer.propTypes = {
  actionType: PropTypes.string.isRequired,
  configType: PropTypes.string.isRequired,
  navigateMain: (props, propName) => {
    if (
      props.actionType !== TwoFactorConstants.ACTION_VERIFY &&
      (props[propName] === undefined || typeof props[propName] !== 'function')
    ) {
      return new Error('Please provide a navigateMain function');
    }
  },
};

export default ConfigCompleteContainer;
