import classnames from 'classnames';
import React, { Fragment, useState, useEffect } from 'react';
import Icon from 'spa/components/Icon';
import TransactionSummaryContainer from 'spa/containers/TransactionSummary/TransactionSummaryContainer';

import API from '../../api';

import { getPartiesByRole } from '../../utils/V4Transaction';

const OfferDropdown = ({ title, token, viewerRole }) => {
  const [showContent, setShowContent] = useState(false);
  const [transaction, setTransaction] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (token) {
      API.getAuctionByToken(token)
        .then((response) => {
          setTransaction(response);
        })
        .catch((e) => {
          setError(e);
        });
    }
  }, [token]);

  const sellerEmail = transaction ? getPartiesByRole(transaction, 'seller')[0].customer : '';
  const buyerEmail = transaction ? getPartiesByRole(transaction, 'buyer')[0].customer : '';
  const headerTitle = transaction ? `${title} for ${transaction.description}` : title;
  return (
    <Fragment>
      <div className="checkout-card-content checkout-card-content--offerHeader">
        <header className="offer-header">
          <h2 className="offer-header-title">{headerTitle}</h2>
          {!error && (
            <div className="offer-dropdown">
              <a
                href="#"
                className={classnames(['offer-dropdown-trigger checkout-dropdown-trigger'], {
                  'is-active': showContent,
                })}
                onClick={() => setShowContent(!showContent)}
              >
                <div className="offer-dropdown-label">
                  {showContent ? 'Hide Items' : 'View Items'}
                </div>
                <div className="checkout-dropdown-indicator">
                  <Icon
                    name="ui-chevron-down"
                    className="offer-dropdown-indicatorIcon checkout-dropdown-indicatorIcon"
                  />
                </div>
              </a>
              {showContent && (
                <div className="offer-dropdown-dialog checkout-dropdown-dialog">
                  <div className="checkout-dropdown-dialog-content">
                    <TransactionSummaryContainer
                      transaction={transaction}
                      viewerPartyEmail={viewerRole === 'seller' ? sellerEmail : buyerEmail}
                      viewerPartyRole={viewerRole}
                      hideTotal
                      showNoPrices
                      showTerms={false}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </header>
      </div>
    </Fragment>
  );
};

export default OfferDropdown;
