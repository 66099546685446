import QueryString from 'query-string';
import { call, put, take, takeLatest, race } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';

import {
  loadDocuments as loadDocumentsRoutine,
  submitDocument as submitDocumentRoutine,
  submitDocumentForm as submitDocumentFormRoutine,
} from 'spa/actions/AdditionalDocumentsActions';
import API from '../../api';

export function* loadDocumentsSaga(action) {
  try {
    const transactionId = action.payload.transactionId;
    const requiredDocs = yield call(API.getDocumentsRequested, transactionId);
    const payload = {
      requiredDocs,
    };

    yield put(loadDocumentsRoutine.success(payload));
  } catch (err) {
    yield put(loadDocumentsRoutine.failure());
  }
}

export function* submitDocumentSaga(action) {
  try {
    const { submissionId, s3ObjectId } = action.payload;
    const doc = yield call(API.submitDocument, submissionId, s3ObjectId);

    yield put(submitDocumentRoutine.success(doc));
  } catch (apiError) {
    yield put(submitDocumentRoutine.failure(apiError));
  }
}

export function* submitDocumentFormSaga(action) {
  yield put(submitDocumentFormRoutine.request());

  const transactionId = QueryString.parse(window.location.search)['trans-id'];
  const formData = action.payload.values;
  const s3ObjectId = formData.documentFiles[0].fileId;
  const submissionId = formData.submissionId;
  const content = {
    submissionId,
    s3ObjectId,
  };

  try {
    yield put(submitDocumentRoutine.trigger(content));
    const { apiRequestSuccess, apiRequestFailure } = yield race({
      apiRequestSuccess: take(submitDocumentRoutine.SUCCESS),
      apiRequestFailure: take(submitDocumentRoutine.FAILURE),
    });
    if (apiRequestFailure) {
      throw new Error(apiRequestFailure.payload);
    }

    yield put(submitDocumentFormRoutine.success(apiRequestSuccess));
    window.location.replace(`${window.config.www_base_url}/transaction/${transactionId}`);
  } catch (error) {
    const submissionError = new SubmissionError({ _error: 'Failed to submit document' });
    yield put(submitDocumentFormRoutine.failure(submissionError));
  }
  yield put(submitDocumentFormRoutine.fulfill());
}

export function* additionalDocumentsWatcher() {
  yield takeLatest(loadDocumentsRoutine.TRIGGER, loadDocumentsSaga);
  yield takeLatest(submitDocumentRoutine.TRIGGER, submitDocumentSaga);
  yield takeLatest(submitDocumentFormRoutine.TRIGGER, submitDocumentFormSaga);
}

export default [additionalDocumentsWatcher];
