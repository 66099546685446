import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-overlays';

import { initModalAction, toggleModalAction } from 'spa/actions/ModalActions';
import { makeShowSelector } from 'spa/selectors/ModalSelectors';
import Icon from 'spa/components/Icon';

class ModalWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.props.initModal();
  }

  render() {
    const { show, toggleModal, children } = this.props;

    return (
      <div>
        <Modal
          show={show}
          onHide={toggleModal}
          className={`modalV2 modalV2--${this.props.modifier}`}
          backdropClassName="modalV2-backdrop"
          containerClassName="is-modalV2-open"
        >
          <div className="modalV2-dialog">
            <h4 className="modalV2-title">{this.props.title}</h4>
            <p className="modalV2-desc">{this.props.description}</p>
            {children}
            <button
              className="modalV2-closeBtn"
              onClick={toggleModal}
              data-tracking-name="modal-close"
              data-tracking-value={this.props.name}
            >
              <Icon name="ui-close" className="modalV2-closeBtn-icon" />
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

ModalWrapper.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  modifier: PropTypes.string,
};

ModalWrapper.defaultProps = {
  modifier: '',
};

const makeMapStateToProps = () => {
  const showSelector = makeShowSelector();
  const mapStateToProps = (state, props) => ({
    show: showSelector(state, props.name),
  });
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch, props) => ({
  toggleModal: () => dispatch(toggleModalAction(props.name)),
  initModal: () => dispatch(initModalAction(props.name)),
});

const ModalContainer = connect(makeMapStateToProps, mapDispatchToProps)(ModalWrapper);

export default ModalContainer;
