import { call, put, takeLatest } from 'redux-saga/effects';
import QueryString from 'query-string';
import _ from 'lodash';

import {
  getNotificationSettings,
  setNotificationSettings,
} from 'spa/actions/NotificationSettingActions';
import API from '../../api';
import NotificationSettingConstants from '../constants/NotificationSettingConstants';

function getToken() {
  let token = QueryString.parse(window.location.search).token;
  if (token === undefined) {
    token = '';
  }
  return token;
}

export function processRawFormData(values) {
  const processedData = {};
  NotificationSettingConstants.availableSettings.map((elem) => {
    processedData[_.snakeCase(elem.fieldName)] = values[elem.fieldName] || false;
  });

  return processedData;
}

export function* setNotificationPreferences(action) {
  try {
    const apiBody = yield call(processRawFormData, action.payload.values);

    yield call(API.setNotificationPreferences, apiBody, getToken());

    yield put(setNotificationSettings.success());
  } catch (error) {
    yield put(setNotificationSettings.failure(error));
  }
}

export function* getNotificationPreferences() {
  try {
    const notificationPreferences = yield call(API.getNotificationPreferences, getToken());
    yield put(getNotificationSettings.success(notificationPreferences));
  } catch (error) {
    yield put(getNotificationSettings.failure(error));
  }
}

export function* notificationSettingWatcher() {
  yield takeLatest(getNotificationSettings.TRIGGER, getNotificationPreferences);
  yield takeLatest(setNotificationSettings.TRIGGER, setNotificationPreferences);
}

export default [notificationSettingWatcher];
