import TableCell from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

export const EscrowLeftCell = styled(TableCell)(() => ({
  paddingLeft: '0',
  borderBottom: 'none',
  textAlign: 'left',
  paddingTop: '8px',
  paddingBottom: '8px',
}));

export const EscrowRightCell = styled(TableCell)(() => ({
  paddingRight: '0',
  borderBottom: 'none',
  textAlign: 'right',
  paddingTop: '8px',
  paddingBottom: '8px',
}));
