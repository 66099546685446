import History from 'html5-history-api';

import AppDispatcher from '../dispatcher/AppDispatcher';
import NavigationConstants from '../constants/NavigationConstants';

export class NavigationActions {
  signupRedirect() {
    AppDispatcher.handleServerAction({
      actionType: NavigationConstants.SIGNUP_REDIRECT,
    });
  }

  loginRedirect() {
    AppDispatcher.handleServerAction({
      actionType: NavigationConstants.LOGIN_REDIRECT,
    });
  }

  loginIntegrationsRedirect() {
    AppDispatcher.handleServerAction({
      actionType: NavigationConstants.PARTNER_LOGIN_REDIRECT,
    });
  }

  startTransactionRedirect() {
    AppDispatcher.handleViewAction({
      actionType: NavigationConstants.START_TRANSACTION_REDIRECT,
    });
  }

  navigateLoggedIn(page) {
    AppDispatcher.handleViewAction({
      actionType: NavigationConstants.NAVIGATE_LOGGED_IN,
      page: page,
    });
  }

  calculatorTransaction(signup) {
    AppDispatcher.handleViewAction({
      actionType: NavigationConstants.CALCULATOR_TRANSACTION,
      signup: signup,
    });
  }

  domainTransaction() {
    AppDispatcher.handleViewAction({
      actionType: NavigationConstants.DOMAIN_TRANSACTION,
    });
  }

  setLoginLocation(page) {
    AppDispatcher.handleViewAction({
      actionType: NavigationConstants.SET_LOGIN_LOCATION,
      page: page,
    });
  }

  pushState(state, title, url) {
    AppDispatcher.handleViewAction({
      actionType: NavigationConstants.HISTORY_PUSH,
      state: state,
      title: title,
      url: url,
    });
  }

  popState(state) {
    AppDispatcher.handleViewAction({
      actionType: NavigationConstants.HISTORY_POP,
      state: state,
    });
  }

  replaceState(state) {
    AppDispatcher.handleViewAction({
      actionType: NavigationConstants.HISTORY_REPLACE,
      state: state,
    });
  }

  searchOpen() {
    AppDispatcher.handleViewAction({
      actionType: NavigationConstants.ACTION_SEARCH_OPEN,
    });
  }

  searchClose() {
    AppDispatcher.handleViewAction({
      actionType: NavigationConstants.ACTION_SEARCH_CLOSE,
    });
  }

  pop() {
    History.go(-1);
  }

  closeModal(name) {
    AppDispatcher.handleViewAction({
      actionType: NavigationConstants.CLOSE_MODAL,
      name: name,
    });
  }

  partnerLandingPageStart(category) {
    AppDispatcher.handleViewAction({
      actionType: NavigationConstants.PARTNER_LANDING_PAGE_START,
      attributes: {
        category,
      },
    });
  }

  setPageType(pageType) {
    AppDispatcher.handleViewAction({
      actionType: NavigationConstants.SET_PAGE_TYPE,
      pageType: pageType,
    });
  }
}

const actions = new NavigationActions();

window.onpopstate = function onpopstate(event) {
  actions.popState(event.state, false);
};
export default actions;
