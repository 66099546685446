import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  V3KYCMobilePages as Pages,
  KYC_FILES as FILES,
  KYC_BUTTONS as BUTTONS,
  KYC_TITLES as TITLES,
  KYC_SUBTITLES as SUBTITLES,
  KYC_PAGE_STATES,
  ERROR_TYPES,
} from 'spa/constants/VerificationConstants';
import { useUploadFileMutation, useVerifyCustomerMutation } from 'spa/features/kyc/kycApi';
import {
  kycCompanyDocumentSelector,
  kycImageSelector,
  kycImagesSelector,
  updateKycImageId,
} from 'spa/features/kyc/kycSlice';
import { dataURLtoFile } from 'spa/../utils/PhotoCapture';

import PhotoReview from '../../components/PhotoReview';
import KYCAlert from '../../components/KYCAlert';
import MobilePage from '../../components/MobilePage';
import RetakePhotoButton from '../../components/ExtraButtons/RetakePhotoButton';

const key = FILES.COMPANY_DOCUMENT;

const { INITIAL, LOADING, ERROR, SUCCESS } = KYC_PAGE_STATES;

const CompanyCaptureReviewPage = () => {
  const dispatch = useDispatch();

  const [pageState, setPageState] = useState(INITIAL);
  const [onSubmit, setOnSubmit] = useState(null);
  const [retryVerify, setRetryVerify] = useState(false);

  const [prevPageTrigger, setPrevPageTrigger] = useState(false);

  const kycImageDataUrl = useSelector(kycImageSelector(key));
  const kycImages = useSelector(kycImagesSelector);
  const kycCompanyDocument = useSelector(kycCompanyDocumentSelector);

  const [
    uploadFile,
    {
      data: uploadData,
      isSuccess: isSuccessUpload,
      isLoading: isLoadingUpload,
      error: uploadError,
      isError: isErrorUpload,
      reset: resetUpload,
    },
  ] = useUploadFileMutation();

  const [
    verifyCustomerT3,
    {
      isLoading: isLoadingVerify,
      isError: isErrorVerify,
      isSuccess: isSuccessVerify,
      isUninitialized: isUninitializedVerify,
      reset: resetVerify,
      error: verifyError,
    },
  ] = useVerifyCustomerMutation();

  const handleUpload = useCallback(async () => {
    const capturedFile = await dataURLtoFile(kycImageDataUrl, `${key}.png`);
    await uploadFile(capturedFile);
  }, [kycImageDataUrl, uploadFile]);

  const verifyT3KYC = useCallback(
    async (documentFileId) => {
      const t3DocumentsObject = [];

      t3DocumentsObject.push({
        file_ids: [documentFileId],
        proof_type: kycCompanyDocument.documentType,
        proof_for: ['company_existence'],
      });

      const payload = {
        account: {
          type: 'company',
          relationship: kycCompanyDocument.relationship,
          company_type: kycCompanyDocument.companyType,
        },
        documents: t3DocumentsObject,
      };

      await verifyCustomerT3(payload);
    },
    [kycCompanyDocument, verifyCustomerT3]
  );

  useEffect(() => {
    // Reset image id on initial page load
    dispatch(updateKycImageId({ fileKey: key, id: null }));
  }, [dispatch]);

  useEffect(() => {
    // Only trigger these side effects if the file has not yet been added to the state
    const documentFileId = kycImages[key].id;

    if (!documentFileId) {
      if (isSuccessUpload && uploadData) {
        const uploadId = uploadData.id;
        if (uploadId) {
          dispatch(updateKycImageId({ fileKey: key, id: uploadId }));
          verifyT3KYC(uploadId);
        }
      } else if (isErrorUpload) {
        setOnSubmit(() => resetUpload);
        setPageState(ERROR);
      } else if (isLoadingUpload) {
        setPageState(LOADING);
      } else {
        setOnSubmit(() => handleUpload);
        setPageState(INITIAL);
      }
    }
  }, [
    isSuccessUpload,
    uploadData,
    dispatch,
    isErrorUpload,
    resetUpload,
    handleUpload,
    isLoadingUpload,
    kycImages,
    verifyT3KYC,
  ]);

  useEffect(() => {
    // Only trigger these side effects if the file upload succeeded
    const documentFileId = kycImages[key].id;

    if (documentFileId) {
      if (!isUninitializedVerify) {
        if (isSuccessVerify) {
          setPageState(SUCCESS);
        } else if (isErrorVerify) {
          setOnSubmit(() => resetVerify);
          setRetryVerify(true);
          setPageState(ERROR);
        } else if (isLoadingVerify) {
          setPageState(LOADING);
        }
      } else if (retryVerify) {
        setOnSubmit(() => verifyT3KYC(documentFileId));
        setRetryVerify(false);
      }
    }
  }, [
    isErrorVerify,
    isSuccessVerify,
    isUninitializedVerify,
    isLoadingVerify,
    retryVerify,
    resetVerify,
    verifyT3KYC,
    kycImages,
    setPageState,
    setOnSubmit,
  ]);

  return (
    <MobilePage
      title={pageState === LOADING ? null : TITLES.COMPANY_DOCUMENT}
      subtitle={pageState === LOADING ? null : SUBTITLES.COMPANY_DOCUMENT}
      ctaText={pageState === INITIAL ? BUTTONS.SubmitPhoto : BUTTONS.TryAgain}
      onSubmit={onSubmit}
      nextPage={Pages.COMPANY_UPLOAD_SUCCESS}
      nextPageTrigger={pageState === SUCCESS}
      disableButtons={pageState === LOADING}
      noButton={pageState === LOADING ? true : null}
      ExtraButton={(props) => (
        <RetakePhotoButton
          {...props}
          onClick={() => {
            setPrevPageTrigger(true);
          }}
        >
          Retake photo
        </RetakePhotoButton>
      )}
      prevPageTrigger={prevPageTrigger}
    >
      {uploadError && <KYCAlert isError={uploadError} errorType={ERROR_TYPES.PHOTO_UPLOAD_FAILED} xRequestId={uploadError.xRequestId} />}
      {verifyError && <KYCAlert isError={verifyError} errorType={ERROR_TYPES.SUBMISSION_FAILED} xRequestId={verifyError.xRequestId} />}
      <PhotoReview fileKey={key} parentPageState={pageState} />
    </MobilePage>
  );
};

export default CompanyCaptureReviewPage;
