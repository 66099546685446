import React, { useEffect } from 'react';
import { DashboardCard, DashboardCardBody } from 'spa/components/DashboardCard';
import { Section } from 'spa/components/Section';
import { FormError } from '../form';

const PartnerLogoSettingsForm = (props) => {
  const {
    customerId,
    partnerLogos,
    partnerLogosLoading,
    partnerLogosSettingsError,
    partnerLogosSettingsLoading,
    partnerLogosSettings,
    listLogos,
    getPartnerLogoSettings,
    updatePartnerLogoSettings,
  } = props;

  useEffect(() => {
    listLogos();
  }, [listLogos]);
  useEffect(() => {
    getPartnerLogoSettings(customerId);
  }, [getPartnerLogoSettings, customerId]);

  if (!partnerLogosLoading && partnerLogos && partnerLogos.length > 0) {
    return (
      <Section>
        <DashboardCard>
          <DashboardCardBody>
            <h3 className="dashboardCard-title"> Logo Settings </h3>
            {partnerLogosSettingsError && <FormError error={partnerLogosSettingsError} />}
            <div className="partner-logo-settings-row">
              <p className="dashboardCard-header-desc partner-logo-settings-label">
                Use logo in transactional emails?
              </p>
              <label
                className={`toggleSwitch${
                  partnerLogosSettingsLoading ? ` toggleSwitch-disabled` : ''
                }`}
              >
                <span className="toggleSwitch-inner">
                  <input
                    type="checkbox"
                    className="toggleSwitch-checkbox"
                    disabled={partnerLogosSettingsLoading}
                    checked={partnerLogosSettings.enableCobrandedEmails}
                    onClick={() => {
                      updatePartnerLogoSettings(customerId, {
                        cobranded_transaction_emails: !partnerLogosSettings.enableCobrandedEmails,
                      });
                    }}
                  />
                  <span className="toggleSwitch-bg partner-logo-toggle" />
                  <span className="toggleSwitch-handle" />
                </span>
              </label>
            </div>
          </DashboardCardBody>
        </DashboardCard>
      </Section>
    );
  }

  return null;
};

export default PartnerLogoSettingsForm;
