import React from 'react';
import Icon from 'spa/components/Icon';
import { renderUrlAsLink } from '../../../utils/StringToComponentHelper';

const FormErrorBox = ({ error }) => (
  <div className="defaultForm-messageBox defaultForm-messageBox--error">
    <div className="defaultForm-messageBox-icon-wrapper">
      <Icon name="ui-alert-circle" className="defaultForm-messageBox-icon" />
    </div>
    <span className="defaultForm-messageBox-msg">{renderUrlAsLink(error)}</span>
  </div>
);

export default FormErrorBox;
