import React, { Fragment, forwardRef } from 'react';

import { useTheme } from '@mui/material/styles';

import { Select, InputLabel } from '@mui/material';
import { FieldError } from './FieldError';

export const SelectField = forwardRef((props, ref) => {
  const theme = useTheme();

  const { error, helpertext, id, label } = props;
  return (
    <Fragment>
      <InputLabel error={error} id={`${id}-label`}>
        {label}
      </InputLabel>
      <Select
        {...props}
        ref={ref}
        sx={{
          '& .MuiOutlinedInput-root.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.error.main,
          },
          '& .MuiInputLabel-root.Mui-error': {
            color: theme.palette.error.main,
          },
        }}
      />
      <FieldError error={error} text={helpertext} />
    </Fragment>
  );
});
