import { throttle, debounce } from 'lodash';
import request from 'browser-request';
import url from 'url';
import immutable from 'immutable';

import AppDispatcher from '../dispatcher/AppDispatcher';
import config from '../utils/config';
import { gettext } from '../utils/filters';
import SearchConstants from '../constants/SearchConstants';

export class SearchInputAction {
  constructor() {
    this.fetchSearchResults = throttle(
      this.fetchSearchResults.bind(this),
      SearchConstants.SEARCH_TIMEOUT
    );
    this.trackSearch = debounce(this.trackSearch.bind(this), SearchConstants.DEBOUNCE_TIMEOUT);
  }

  search(value, category) {
    if (value.length > 0) {
      AppDispatcher.handleViewAction({
        actionType: SearchConstants.SEARCH_INPUT,
        category: category,
        value: value,
      });
      this.trackSearch(value, 'search');
      this.fetchSearchResults(value, category);
    } else {
      this.invalidate();
    }
  }

  // action for drop down menu
  searchSelect(text, category) {
    AppDispatcher.handleViewAction({
      actionType: SearchConstants.SEARCH_SELECT,
      text: text,
    });
    this.trackSearch(text, 'dropdown');

    this.fetchSearchResults(text, category);
  }

  searchHover(value, idx) {
    AppDispatcher.handleViewAction({
      actionType: SearchConstants.SEARCH_HOVER,
      value: value,
      idx: idx,
    });
  }

  reset() {
    AppDispatcher.handleViewAction({
      actionType: SearchConstants.SEARCH_RESET,
    });
  }

  invalidate() {
    AppDispatcher.handleViewAction({
      actionType: SearchConstants.SEARCH_INVALID,
      error: gettext('Please enter a search term'),
    });
  }

  updateInputs() {
    AppDispatcher.handleViewAction({
      actionType: SearchConstants.SEARCH_UPDATE_INPUTS,
    });
  }

  fetchSearchResults(terms, category) {
    let endpoint;
    let limit;
    if (category === 'CALC_SEARCH') {
      endpoint = '/api/keyword-search';
      limit = SearchConstants.CALCULATOR_SEARCH_LIMIT;
    } else {
      endpoint = '/api/search';
      limit = SearchConstants.SEARCH_LIMIT;
    }

    request.get(
      {
        url: url.format({
          pathname: endpoint,
          query: {
            q: terms,
            language: config.locale,
            category: category,
            limit: limit,
          },
        }),
        json: true,
      },
      (error, response, body) => {
        if (!error && response.status === 200) {
          AppDispatcher.handleServerAction({
            actionType: SearchConstants.SEARCH_RESULTS,
            category: category,
            terms: terms,
            results: immutable.fromJS(body.results),
            suggestions: immutable.fromJS(body.suggestions),
          });
        } else {
          this.invalidate();
        }
      }
    );
  }

  trackSearch(value, label) {
    AppDispatcher.handleViewAction({
      actionType: SearchConstants.TRACKING_SEARCH,
      value: value,
      label: label,
    });
  }
}

export default new SearchInputAction();
