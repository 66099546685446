import React, { useState, useEffect, Fragment, useContext } from 'react';
import { Form } from 'react-final-form';
import ReactSVG from 'react-svg';
import { MenuItem, Divider } from '@mui/material';
import { connect } from 'react-redux';

import { RegionsByCountry } from 'escrow-common-js/dist/constants';
import {
  kycGotoPrevPage as kycGotoPrevPageRoutine,
  kycClearErrorAlert,
  kycSetErrorAlert,
  kycSetSubheader,
  kycSubmitIndividual as kycSubmitIndividualRoutine,
} from 'spa/actions/VerificationActions';
import { useWindowDimensions } from 'spa/hooks';
import TransactionConstants from 'spa/constants/TransactionConstants';
import { Alert, Heading, UploadBox } from 'spa/components/CustomerVerification/V2';
import { composeValidators, required } from 'spa/components/form/validate';
import VerificationConstants, {
  frontOnlyIdTypes,
  getIdTypeOptions,
  addressProofTypeOptions,
  KYCErrorAlertMessages,
  individualVerificationFieldOptions,
  getIdTypeWithRequiredProofOfAddress,
  ID_FRONT,
  ID_BACK,
  ID_ADDRESS,
  INDIV_VERIFICATION_UPLOAD_OPTION_VALUE,
} from 'spa/constants/VerificationConstants';
import {
  customerCountrySelector,
  customerKycSubmitLoadingSelector,
  customerKycFileForProofSelector,
  customerKycUploadedFilesSelector,
  customerEKycDisabledSelector,
  customerKycErrorAlertSelector,
  customerKycHasPrevPagesSelector,
  customerEKycEligibilitySelector,
} from 'spa/selectors/CustomerSelectors';
import ActionButtons from 'spa/components/CustomerVerification/V2/ActionButtons';
import { InformationModal, SelectField } from 'spa/components/StartTransaction/Fields';
import { KYCContext } from 'spa/context/KYCContext';
import A from 'spa/components/A';
import { CheckboxField, InputField } from './Fields';
import ErrorMessages from '../../../../constants/ErrorMessages';
import { getNationalDocuments } from '../../../../utils/IdentificationDocuments';

const { PERSONAL_VERIFICATION_FORM } = VerificationConstants;

const IdGuidelines = ({ idType }) => (
  <div className="kyc-guidelines">
    Important guidelines for your {idType}. Please ensure it has the following:
    <ul className="kyc-guidelines-list">
      <li>Your photo and details are visible and free of any blurs or image glare</li>
      <li>The expiry date must be visible</li>
      <li>
        Your <strong>name</strong>, <strong>date of birth</strong>, and <strong>address</strong>{' '}
        must be readable
      </li>
    </ul>
  </div>
);

const UploadSection = ({
  proofFor,
  heading,
  uploadBoxLabel,
  sampleImageHeader,
  sampleImageSrc,
  stepCounter,
  disabled,
}) => (
  <div>
    <Heading text={heading} secondaryText={stepCounter} />
    {sampleImageHeader}
    <ReactSVG
      src={sampleImageSrc}
      className="kyc-sample-id"
      beforeInjection={(svg) => {
        svg.classList.add('kyc-sample-id-image');
      }}
    />
    <UploadBox proofFor={proofFor} label={uploadBoxLabel} disabled={disabled} />
  </div>
);

const ProofOfAddress = ({ disabled }) => (
  <div>
    <Heading text={'Proof of Address'} hasDivider />
    Your proof of address should show the same address in your personal details.
    <SelectField
      label="Document Type"
      name="addressDocumentType"
      validate={required}
      disabled={disabled}
      blurOnClose
      handleOlark
    >
      {addressProofTypeOptions.map((entry) => (
        <MenuItem value={entry.value} key={entry.value}>
          {entry.label}
        </MenuItem>
      ))}
    </SelectField>
    <UploadBox proofFor={ID_ADDRESS} label="Upload Document" disabled={disabled} />
  </div>
);

const UploadVerification = ({
  isSubmitting,
  values,
  hasValidationErrors,
  countryAlpha2,
  loadPrevPage,
  setErrorAlert,
  clearErrorAlert,
  submitKYC,
  getUploadedFile,
  uploadedFiles,
  hasPrevPages,
  form,
  clearFieldsOnUploadIdTypeChange,
}) => {
  const [currentIdSide, setCurrentIdSide] = useState(null);
  const [requiredIdSides, setRequiredIdSides] = useState([]);
  const [isProofOfAddressRequired, setIsProofOfAddressRequired] = useState(false);
  const kycContext = useContext(KYCContext);
  const idTypeOptions = getIdTypeOptions(countryAlpha2).reduce(
    (prev, curr) => ({ ...prev, [curr.value]: curr.label }),
    {}
  );
  const isLastStep = () => currentIdSide === requiredIdSides.slice(-1)[0];
  const getStepCounter = () => {
    const totalSteps = requiredIdSides.length;
    if (totalSteps < 2) {
      return null;
    }
    const currentStep = requiredIdSides.findIndex((e) => e === currentIdSide) + 1;
    return `${currentStep}/${totalSteps}`;
  };
  const checkMissingFiles = () => {
    if (
      !getUploadedFile(ID_FRONT).id ||
      (isLastStep() && !values.isAddressInId && !getUploadedFile(ID_ADDRESS).id) ||
      (currentIdSide === ID_BACK && !getUploadedFile(ID_BACK).id)
    ) {
      setErrorAlert(KYCErrorAlertMessages.FILE_REQUIRED);
      return true;
    }
    clearErrorAlert();
    return false;
  };

  const handleIdTypeChange = (e) => {
    const selectedIdType = e.target.value;

    setCurrentIdSide(ID_FRONT);
    if (frontOnlyIdTypes.includes(selectedIdType)) {
      setRequiredIdSides([ID_FRONT]);
    } else {
      setRequiredIdSides([ID_FRONT, ID_BACK]);
    }
    setIsProofOfAddressRequired(
      getIdTypeWithRequiredProofOfAddress(countryAlpha2).includes(selectedIdType)
    );
    clearFieldsOnUploadIdTypeChange(form, values);
  };
  const handleBack = () => {
    if (currentIdSide === ID_BACK) {
      setCurrentIdSide(ID_FRONT);
    } else {
      loadPrevPage();
    }
  };
  const handleNext = () => {
    if (checkMissingFiles() || hasValidationErrors) {
      return null;
    } else if (!isLastStep()) {
      setCurrentIdSide(ID_BACK);
    } else {
      submitKYC({
        values,
        requiredIdSides,
        kycContext,
      });
    }
  };
  const uploadSections = [ID_FRONT, ID_BACK].reduce(
    (prevSides, idSide) => ({
      ...prevSides,
      [idSide]: (
        <UploadSection
          key={idSide}
          proofFor={idSide}
          heading={`Upload the ${idSide} of your ID`}
          uploadBoxLabel={`Upload ${idSide.toUpperCase()} of ID`}
          sampleImageHeader={`Example of ${idSide} of ID`}
          sampleImageSrc={`../../../../../build/images/verify/sample-id-${idSide}.svg`}
          stepCounter={getStepCounter()}
          disabled={isSubmitting}
        />
      ),
    }),
    {}
  );

  return (
    <div>
      <Heading text={'Proof of Identity'} hasDivider />
      <IdGuidelines
        idType={values.identityDocumentType ? idTypeOptions[values.identityDocumentType] : 'ID'}
      />
      <SelectField
        label="Document type"
        placeholder="Select a document type"
        renderSelectedOption={(selected) =>
          Object.entries(idTypeOptions).find(([value]) => selected === value)[1]
        }
        name="identityDocumentType"
        onChange={handleIdTypeChange}
        validate={required}
        disabled={isSubmitting}
        blurOnClose
        handleOlark
      >
        {Object.entries(idTypeOptions).map(([value, label]) => (
          <MenuItem value={value} key={value}>
            {label}
          </MenuItem>
        ))}
      </SelectField>
      {currentIdSide && (
        <Fragment>
          {uploadSections[currentIdSide]}
          {isLastStep() && !isProofOfAddressRequired && (
            <CheckboxField
              name="isAddressInId"
              label="The uploaded file shows my current address"
              disabled={isSubmitting}
            />
          )}
          {isLastStep() && !values.isAddressInId && <ProofOfAddress disabled={isSubmitting} />}
        </Fragment>
      )}
      <ActionButtons
        secondaryText="Back"
        primaryText={isLastStep() ? 'Submit' : 'Next'}
        withBack={hasPrevPages}
        type="submit"
        isLoading={isSubmitting}
        isDisabled={
          hasValidationErrors || Object.values(uploadedFiles).some((file) => file && file.loading)
        }
        onSecondaryClick={handleBack}
        onPrimaryClick={handleNext}
      />
    </div>
  );
};

const VerificationFormContent = ({
  verificationMethod,
  uploadVerificationContainer,
  ekycVerificationContainer,
}) => {
  switch (verificationMethod) {
    case INDIV_VERIFICATION_UPLOAD_OPTION_VALUE:
      return uploadVerificationContainer;
    case '':
      return <Fragment />;
    default:
      return ekycVerificationContainer;
  }
};

const EkycVerification = ({
  countryAlpha2,
  verificationMethod,
  values,
  hasValidationErrors,
  loadPrevPage,
  submitKYC,
  isSubmitting,
  changeFormIndividualVerificationMethod,
  form,
  hasPrevPages,
}) => {
  const [open, setOpen] = useState(false);
  const { width } = useWindowDimensions();
  const kycContext = useContext(KYCContext);
  const mobile = width < TransactionConstants.DESKTOP_VIEW_WIDTH;
  const document = getNationalDocuments(countryAlpha2).find(
    (doc) => doc.name === verificationMethod
  );
  const conditionals = {};
  document.fields.map((field) => {
    if (field.toggleFieldsOnChange) {
      field.toggleFieldsOnChange.map((fieldToToggle) => {
        conditionals[fieldToToggle.field] = {
          basedFrom: field.name,
          isEnabled: fieldToToggle.isEnabled,
        };
      });
    }
  });

  const handleEkycMaxAttempts = () => {
    changeFormIndividualVerificationMethod(INDIV_VERIFICATION_UPLOAD_OPTION_VALUE);
  };

  const handleNext = () => {
    if (hasValidationErrors) {
      return null;
    }
    submitKYC({
      values,
      handleEkycMaxAttempts,
      kycContext,
    });
  };

  const handleDocumentFieldChange = (dependents) => {
    if (dependents) {
      dependents.map((dependent) => {
        if (dependent in values) {
          form.change(dependent, null);
          form.resetFieldState(dependent);
        }
      });
    }
  };

  const driversLicenseNumberLocation = {
    ACT: 'front of the card, and runs vertically alongside your photo',
    NSW: 'top right of the front of the card',
    SA: 'top right of the back of the card',
    TAS: 'top right of the back of the card',
    WA: 'middle right of the back of the card',
    QLD: 'front of the card and in the middle bottom or back of the card and on the bottom right',
    VIC: 'back of the card and on the top right',
  };

  return (
    <Fragment>
      <Heading text={`${document.displayName} Information`} hasDivider />
      {document.fields
        .filter(
          (field) =>
            !conditionals[field.name] ||
            (conditionals[field.name] &&
              conditionals[field.name].isEnabled(values[conditionals[field.name].basedFrom]))
        )
        .map((field) => (
          <Fragment key={`${document.name}-${field.name}`}>
            {field.type === 'dropdown' ? (
              <SelectField
                name={field.name}
                label={field.displayName}
                validate={required}
                blurOnClose
                handleOlark
                onChange={() => handleDocumentFieldChange(field.dependents)}
              >
                {field.options.map((entry) => (
                  <MenuItem value={entry.value} key={entry.value}>
                    {entry.label}
                  </MenuItem>
                ))}
              </SelectField>
            ) : (
              <InputField
                name={field.name}
                label={field.displayName}
                validate={composeValidators([
                  required,
                  ...(field.validators || []).map(
                    (validator) => (value) => validator(document)(value, values)
                  ),
                ])}
              />
            )}
          </Fragment>
        ))}
      {Object.keys(driversLicenseNumberLocation).includes(values.state) ? (
        <Fragment>
          <InformationModal
            title={'Where can I find the card number?'}
            text={`The driver\'s license card number for ${
              RegionsByCountry.AU.find((region) => region.code === values.state).name
            } may be found at the ${driversLicenseNumberLocation[values.state]}.`}
            isOpen={open}
            onClose={() => setOpen(false)}
            fullWidth={mobile}
          />
          <p className="kyc-driversLicenseGuide">
            <a role="button" onClick={() => setOpen(true)} tabIndex={0}>
              Where can I find the card number?
            </a>
          </p>
          <Divider />
        </Fragment>
      ) : null}
      <CheckboxField
        name="readAndAgreed"
        validate={required}
        label={
          <Fragment>
            <span>I consent to verifying my identity as outlined in the </span>
            <A
              link={{
                type: 'internal',
                route: '/legal',
                newTab: true,
              }}
            >
              {'privacy policy'}
            </A>
            <span>.</span>
          </Fragment>
        }
        disabled={isSubmitting}
      />
      <ActionButtons
        secondaryText="Back"
        primaryText={'Verify'}
        withBack={hasPrevPages}
        type="submit"
        isLoading={isSubmitting}
        isDisabled={hasValidationErrors}
        onSecondaryClick={() => loadPrevPage()}
        onPrimaryClick={handleNext}
      />
    </Fragment>
  );
};

const IndividualVerificationForm = ({
  countryAlpha2,
  loadPrevPage,
  setSubheaderText,
  hasEkycVerificationOptions,
  isEkycMaxAttempts,
  hasMaxAttemptErrorMessage,
  disableKYC,
  setErrorAlert,
  isSubmitting,
  clearErrorAlert,
  submitKYC,
  getUploadedFile,
  uploadedFiles,
  hasPrevPages,
  isEKYCEligible,
}) => {
  const onSubmit = () => {
    console.log('form submit'); // eslint-disable-line no-console
  };
  const { forceEkycDisabled } = useContext(KYCContext);
  const isEkycEnabled = hasEkycVerificationOptions && !forceEkycDisabled && isEKYCEligible;
  useEffect(() => {
    setSubheaderText(`We protect both sides of the transaction by verifying the identity of all users.
Your verified identity must be the same as the account holder's name on any bank account used to pay or receive funds from Escrow.com
`);
  }, [setSubheaderText]);
  const [verificationMethod, setVerificationMethod] = useState(
    INDIV_VERIFICATION_UPLOAD_OPTION_VALUE
  );
  useEffect(() => {
    setVerificationMethod(isEkycEnabled ? '' : INDIV_VERIFICATION_UPLOAD_OPTION_VALUE);
  }, [isEkycEnabled]);
  useEffect(() => {
    if (isEkycMaxAttempts) {
      setVerificationMethod(INDIV_VERIFICATION_UPLOAD_OPTION_VALUE);
    }
  }, [isEkycMaxAttempts]);

  const clearEkycFields = (form) => {
    const oldDocument = getNationalDocuments(countryAlpha2).find(
      (doc) => doc.name === verificationMethod
    );
    oldDocument.fields.map((field) => {
      if (field.required) {
        form.change(field.name, null);
        form.resetFieldState(field.name);
      }
    });
  };

  const clearFieldsOnUploadIdTypeChange = (form, values) => {
    const fieldList = ['isAddressInId', 'readAndAgreed', 'addressDocumentType'];
    fieldList.map((field) => {
      if (field in values) {
        form.change(field, null);
      }
    });
  };

  const handleVerificationMethodChange = (newValue, form, values) => {
    const hasChangedFromEkyc =
      verificationMethod && verificationMethod !== INDIV_VERIFICATION_UPLOAD_OPTION_VALUE;
    if (hasChangedFromEkyc) {
      clearEkycFields(form);
    }
    const hasChangedfromUploadDocument =
      verificationMethod && verificationMethod === INDIV_VERIFICATION_UPLOAD_OPTION_VALUE;
    if (hasChangedfromUploadDocument) {
      form.change('identityDocumentType', null);
      clearFieldsOnUploadIdTypeChange(form, values);
    }
    setVerificationMethod(newValue);
  };

  const verificationFormProps = {
    countryAlpha2,
    loadPrevPage,
    isSubmitting,
    submitKYC,
    hasPrevPages,
  };

  const verificationFieldOptions = individualVerificationFieldOptions(
    countryAlpha2,
    isEkycMaxAttempts,
    disableKYC
  );

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{
        values: true,
        errors: true,
        submitError: true,
        submitting: true,
        hasValidationErrors: true,
      }}
      initialValues={{
        ...(((isEkycMaxAttempts || !isEkycEnabled) && {
          individualVerificationMethod: INDIV_VERIFICATION_UPLOAD_OPTION_VALUE,
        }) ||
          {}),
      }}
      mutators={{
        setVerificationMethodOption: (args, state, utils) => {
          utils.changeValue(state, 'individualVerificationMethod', () => args);
        },
      }}
    >
      {({ handleSubmit, hasValidationErrors, values, form }) => (
        <form onSubmit={handleSubmit} name={PERSONAL_VERIFICATION_FORM}>
          {isEkycEnabled && (
            <Fragment>
              <Heading text={'Verification Method'} hasDivider />
              {isEkycMaxAttempts && !hasMaxAttemptErrorMessage && (
                <Alert {...KYCErrorAlertMessages.DISABLED_EKYC} type="info" />
              )}
              <SelectField
                label="Verification Method"
                placeholder="Select a verification method"
                renderSelectedOption={(value) =>
                  verificationFieldOptions.find((item) => item.value === value).label
                }
                name="individualVerificationMethod"
                onChange={(e) => handleVerificationMethodChange(e.target.value, form, values)}
                validate={required}
                disabled={isEkycMaxAttempts}
                blurOnClose
                handleOlark
              >
                {verificationFieldOptions.map((entry) => (
                  <MenuItem value={entry.value} key={entry.value}>
                    {entry.label}
                  </MenuItem>
                ))}
              </SelectField>
            </Fragment>
          )}
          <VerificationFormContent
            verificationMethod={verificationMethod}
            uploadVerificationContainer={
              <UploadVerification
                {...{
                  ...verificationFormProps,
                  values,
                  hasValidationErrors,
                  setErrorAlert,
                  clearErrorAlert,
                  getUploadedFile,
                  uploadedFiles,
                  form,
                  clearFieldsOnUploadIdTypeChange,
                }}
              />
            }
            ekycVerificationContainer={
              <EkycVerification
                {...{
                  ...verificationFormProps,
                  values,
                  hasValidationErrors,
                  verificationMethod,
                  changeFormIndividualVerificationMethod: (value) =>
                    form.mutators.setVerificationMethodOption(value),
                  form,
                }}
              />
            }
          />
          {!values.individualVerificationMethod && (
            <ActionButtons
              secondaryText="Back"
              primaryText="Submit"
              withBack={hasPrevPages}
              isDisabled
              onSecondaryClick={() => loadPrevPage()}
            />
          )}
        </form>
      )}
    </Form>
  );
};

const mapStateToProps = (state) => {
  const customerCountry = customerCountrySelector(state);
  const disableKYC = false; // Port dynamic value (T231853) on checkout integration
  const isEkycMaxAttempts = customerEKycDisabledSelector(state);
  return {
    hasMaxAttemptErrorMessage:
      (customerKycErrorAlertSelector(state) || {}).message ===
      ErrorMessages.VERIFY_EKYC_FAIL.MAX_ATTEMPTS,
    isEkycMaxAttempts,
    disableKYC,
    hasEkycVerificationOptions:
      individualVerificationFieldOptions(customerCountry, isEkycMaxAttempts, disableKYC).length > 1,
    countryAlpha2: customerCountry,
    isSubmitting: customerKycSubmitLoadingSelector(state),
    getUploadedFile: (proofFor) => customerKycFileForProofSelector(state, proofFor),
    uploadedFiles: customerKycUploadedFilesSelector(state),
    hasPrevPages: customerKycHasPrevPagesSelector(state),
    isEKYCEligible: customerEKycEligibilitySelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadPrevPage: () => dispatch(kycGotoPrevPageRoutine.trigger()),
  setErrorAlert: (error) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    dispatch(kycSetErrorAlert(error));
  },
  clearErrorAlert: () => dispatch(kycClearErrorAlert()),
  setSubheaderText: (subheaderText) => dispatch(kycSetSubheader(subheaderText)),
  submitKYC: (payload) => dispatch(kycSubmitIndividualRoutine.trigger(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndividualVerificationForm);
