import React from 'react';
import { connect } from 'react-redux';

import { makeExpandedSelector } from 'spa/selectors/ExpandCollapseSelectors';

class CollapsableContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
    };
    this.toggleContent = this.toggleContent.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.expandCollapseSubscription !== nextProps.expandCollapseSubscription) {
      this.setState({
        expand: nextProps.expandCollapseSubscription,
      });
    }
  }

  toggleContent() {
    this.setState((prevState) => ({
      expand: !prevState.expand,
    }));
  }

  render() {
    return (
      <section className={`collapsableContent ${this.state.expand && 'is-expanded'}`}>
        {React.Children.map(this.props.children, (child) =>
          React.cloneElement(child, {
            toggleContent: this.toggleContent,
            height: this.state.expand ? 'auto' : 0,
          })
        )}
      </section>
    );
  }
}

const makeMapStateToProps = () => {
  const expandedSelector = makeExpandedSelector();
  const mapStateToProps = (state, props) => ({
    expandCollapseSubscription: expandedSelector(state, props.expandCollapseSubscriptionName),
  });
  return mapStateToProps;
};

const CollapsableContentContainer = connect(makeMapStateToProps, null)(CollapsableContent);

export default CollapsableContentContainer;
