import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { V3KYCMobilePages as Pages } from 'spa/constants/VerificationConstants';
import { SmallText } from 'spa/features/ui/EscrowTypography';
import MobilePage from '../../components/MobilePage';

const CompanyUploadSuccessPage = () => {
  const theme = useTheme();
  const [nextPageTrigger, setNextPageTrigger] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setNextPageTrigger(true);
    }, 1500);
  }, []);

  return (
    <MobilePage
      ctaText="Continue"
      nextPage={Pages.COMPANY_VERIFICATION_SUCCESS}
      nextPageTrigger={nextPageTrigger}
      noButton
    >
      <div
        className="camera-upload-container"
        style={{ backgroundColor: theme.palette.white, top: '20%', bottom: '20%' }}
      >
        <img
          className="camera-upload-icon"
          src={`spa/../../build/images/kyc/upload-success-tick-only.gif`}
          alt="Upload successful"
        />
        <SmallText sx={{ fontSize: '16px', fontWeight: '600' }}>Upload successful</SmallText>
      </div>
    </MobilePage>
  );
};

export default CompanyUploadSuccessPage;
