import {
  getCardDetails as getCardDetailsRoutine,
  getLastBuyerBankEntry as getLastBuyerBankEntryRoutine,
  getCardVerificationDetails as getCardVerificationDetailsRoutine,
  initAdyenCheckout as initAdyenCheckoutRoutine,
  processSuccessfulPaypalPayment as processSuccessfulPaypalPaymentRoutine,
  submitUserWireDetails as submitUserWireDetailsRoutine,
} from 'spa/actions/PaymentsActions';
import PaymentConstants from 'spa/constants/PaymentConstants';

const initialState = {
  cardsById: null,
  cardVerificationById: null,
  payments: {
    paypal: {
      loading: false,
      success: false,
      error: null,
    },
  },
  submitUserWireDetails: {
    error: false,
    errorMessage: '',
    submitting: false,
    lastBuyerBankEntry: null,
  },
  adyenDetails: {
    init: {
      availablePaymentMethods: null,
      checkout: null,
    },
    form: null,
    action: null,
    threeDSResult: null,
    ui: {
      loading: false,
      error: false,
    },
  },
};

function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case PaymentConstants.SET_ADYEN_FORM_DATA:
      return {
        ...state,
        adyenDetails: {
          ...state.adyenDetails,
          form: action.payload,
          threeDSResult: null,
        },
      };
    case PaymentConstants.SET_ADYEN_ACTION:
      return {
        ...state,
        adyenDetails: {
          ...state.adyenDetails,
          action: action.payload.action,
        },
      };
    case PaymentConstants.SET_ADYEN_PAYMENT_TOKEN:
      return {
        ...state,
        adyenDetails: {
          ...state.adyenDetails,
          form: {
            ...state.adyenDetails.form,
            data: {
              paymentMethod: {
                storedPaymentMethodId: action.payload.adyenCCId,
              },
              browserInfo: action.payload.browserInfo,
            },
          },
        },
      };
    case PaymentConstants.SET_ADYEN_THREEDS_RESULT:
      return {
        ...state,
        adyenDetails: {
          ...state.adyenDetails,
          threeDSResult: action.payload.threeDSResult,
        },
      };
    case initAdyenCheckoutRoutine.TRIGGER:
      return {
        ...state,
        adyenDetails: {
          ...state.adyenDetails,
          ui: {
            loading: true,
            error: false,
          },
        },
      };
    case initAdyenCheckoutRoutine.SUCCESS:
      return {
        ...state,
        adyenDetails: {
          ...state.adyenDetails,
          init: {
            availablePaymentMethods: action.payload.paymentMethods,
            checkout: action.payload.checkout,
          },
          ui: {
            loading: false,
            error: false,
          },
        },
      };
    case initAdyenCheckoutRoutine.FAILURE:
      return {
        ...state,
        adyenDetails: {
          ...state.adyenDetails,
          ui: {
            loading: false,
            error: action.payload.errorMessage,
          },
        },
      };
    case getCardDetailsRoutine.TRIGGER:
      return {
        ...state,
        cardsById: {
          [action.payload.cardId]: {
            loading: true,
          },
        },
      };
    case getCardDetailsRoutine.SUCCESS:
      return {
        ...state,
        cardsById: {
          [action.payload.cardId]: {
            ...action.payload,
            loading: false,
          },
        },
      };
    case getCardDetailsRoutine.FAILURE:
      return {
        ...state,
        cardsById: {
          [action.payload.cardId]: {
            loading: false,
          },
        },
      };
    case getCardVerificationDetailsRoutine.TRIGGER:
      return {
        ...state,
        cardVerificationById: {
          [action.payload.cardVerificationId]: {
            ...(state.cardVerificationById || {})[action.payload.cardVerificationId],
            loading: true,
          },
        },
      };
    case getCardVerificationDetailsRoutine.SUCCESS:
      return {
        ...state,
        cardVerificationById: {
          [action.payload.id]: {
            attempts_left: action.payload.attempts_left,
            custom_charge_attempts: action.payload.custom_charge_attempts,
            credit_card_id: action.payload.credit_card_information.id,
            loading: false,
          },
        },
      };
    case getCardVerificationDetailsRoutine.FAILURE:
      return {
        ...state,
        cardVerificationById: {
          [action.payload.id]: {
            attempts_left: action.payload.attempts_left,
            loading: false,
          },
        },
      };
    case processSuccessfulPaypalPaymentRoutine.TRIGGER:
      return {
        ...state,
        payments: {
          ...state.payments,
          paypal: {
            ...state.payments.paypal,
            loading: true,
            success: false,
          },
        },
      };
    case processSuccessfulPaypalPaymentRoutine.SUCCESS:
      return {
        ...state,
        payments: {
          ...state.payments,
          paypal: {
            ...state.payments.paypal,
            loading: false,
            success: true,
            partner: action.payload,
          },
        },
      };
    case processSuccessfulPaypalPaymentRoutine.FAILURE:
      return {
        ...state,
        payments: {
          ...state.payments,
          paypal: {
            ...state.payments.paypal,
            loading: false,
            error: action.payload,
          },
        },
      };
    default:
      return state;

    case submitUserWireDetailsRoutine.TRIGGER:
      return {
        ...state,
        submitUserWireDetails: {
          ...state.submitUserWireDetails,
          submitting: true,
        },
      };

    case submitUserWireDetailsRoutine.SUCCESS:
      return {
        ...state,
        submitUserWireDetails: {
          ...state.submitUserWireDetails,
          error: false,
          errorMessage: '',
          submitting: false,
        },
      };

    case submitUserWireDetailsRoutine.FAILURE:
      return {
        ...state,
        submitUserWireDetails: {
          ...state.submitUserWireDetails,
          error: true,
          errorMessage: action.payload.errorMessage,
          submitting: false,
        },
      };

    case getLastBuyerBankEntryRoutine.SUCCESS:
      return {
        ...state,
        submitUserWireDetails: {
          ...state.submitUserWireDetails,
          lastBuyerBankEntry: action.payload,
        },
      };
  }
}

export default paymentReducer;
