import React from 'react';
import PropTypes from 'prop-types';

const Section = ({ children }) => (
  <section className="integrationsPortal-section">{children}</section>
);

Section.propTypes = {
  children: PropTypes.node,
};

export default Section;
