import React from 'react';

import DisplayAPIKeys from 'spa/containers/DisplayAPIKeys';
import CreateAPIKeys from 'spa/containers/CreateAPIKeys';
import APIKeysModal from 'spa/containers/APIKeysModal';
import Footer from 'spa/components/Footer';

const APIIntegrationPage = () => (
  <div className="grid">
    <div className="grid-col grid-col--12">
      <h1 className="integrationsPortal-title">API Dashboard</h1>
      <DisplayAPIKeys />
      <CreateAPIKeys />
      <APIKeysModal />
      <Footer />
    </div>
  </div>
);

export default APIIntegrationPage;
