import ExpandCollapseConstants from 'spa/constants/ExpandCollapseConstants';

function expandCollapseReducer(state = {}, action) {
  switch (action.type) {
    case ExpandCollapseConstants.INIT_EXPAND_COLLAPSE:
      return Object.assign({}, state, {
        [action.name]: {
          expanded: action.expanded,
        },
      });
    case ExpandCollapseConstants.TOGGLE_EXPAND_COLLAPSE:
      return Object.assign({}, state, {
        [action.name]: {
          expanded: !state[action.name].expanded,
        },
      });
    default:
      return state;
  }
}

export default expandCollapseReducer;
