import { createSelector } from 'reselect';
import _ from 'lodash';

import { userSelector } from './UserSelectors';

import { taskData, startTasks } from './taskData';

import { urlFor } from '../../routeConfig';

const taskListsSelector = (state) => userSelector(state).tasks || {};

const taskListSelector = (state, name) => taskListsSelector(state)[name] || [];

// this only works on the first layer of account tasks
const accountTaskListSelector = (state, name) => {
  const accountTasks = taskListsSelector(state).account;
  if (_.isEmpty(accountTasks)) {
    return [];
  }

  // iterate over the subtasks of account_setup
  const transformedSubtasks = accountTasks[0].subtasks.filter(({ type }) => {
    // hide extended account tasks for bin
    if (name === 'buttons' && type === 'extended') {
      return false;
    }
    return true;
  });
  return [
    {
      ...accountTasks[0],
      subtasks: transformedSubtasks,
    },
  ];
};

// this function is transforms the tasks from the state:
// - calculate progress bar value
// - propogate frozen to subtasks
const transformTasks = (taskList) => {
  let complete = 0.0;
  let total = 0.0;

  // get the transformed tasks
  let transformedTasks;
  if (!_.isEmpty(taskList)) {
    transformedTasks = taskList
      .filter((task) => !task.hidden)
      .map(
        ({
          completion_date: completionDate,
          task_list: taskListName,
          subtasks,
          frozen,
          points,
          ...rest
        }) => {
          let completed = !!completionDate;
          // if a task definition is frozen, freeze all its children
          const transformedSubtasks = transformTasks(subtasks);
          // if there are subtasks, use their complete/total instead of this tasks
          if (!_.isEmpty(transformedSubtasks)) {
            total += transformedSubtasks.total;
            complete += transformedSubtasks.complete;
            completed = transformedSubtasks.complete === transformedSubtasks.total;
          } else {
            total += points || 10.0;
            complete += completed ? points || 10.0 : 0.0;
          }
          // if there is taskData, merge that as well
          const additionalData = taskData[rest.name];
          return {
            frozen: frozen || !_.isEmpty(transformedSubtasks), // freeze tasks with subtasks
            taskList: taskListName,
            completed,
            ...rest,
            ...additionalData,
            ...transformedSubtasks,
          };
        }
      );
  }

  // create the return object
  if (!_.isEmpty(transformedTasks)) {
    return {
      subtasks: transformedTasks,
      complete,
      total,
      progressBarValue: Math.round((complete / total) * 100),
    };
  }
  return {};
};

// momoize the tasklists to reduce unnecessary recalculations
const makeTaskListSelector = () =>
  createSelector(
    taskListSelector,
    accountTaskListSelector,
    (taskList, accountTaskList) => transformTasks([...taskList, ...accountTaskList]) || []
  );

const transformedTaskListSelector = createSelector(
  taskListSelector,
  (taskList) => transformTasks(taskList) || []
);

const incompletedTaskListsSelector = (state) =>
  // for all task lists except account
  Object.keys(taskListsSelector(state)).filter((taskList) => {
    if (taskList === 'account') {
      return false;
    }
    // filter out the complete ones
    const { complete, total } = transformedTaskListSelector(state, taskList);
    return complete !== total;
  });

const mostRecentStartedTaskSelector = createSelector(
  incompletedTaskListsSelector,
  taskListsSelector,
  (incompleteTaskLists, taskLists) => {
    if (_.isEmpty(incompleteTaskLists)) {
      // if there are no incomplete tasks, redirect to dashboard
      return urlFor('integrations_dashboard');
    }
    let latest = { completion_date: null };
    incompleteTaskLists.forEach((listName) => {
      const taskStatus = taskLists[listName].find(
        (task) => task.name === startTasks[listName].name
      );
      if (new Date(taskStatus.completion_date) > new Date(latest.completion_date)) {
        latest = {
          ...startTasks[listName],
          completion_date: taskStatus.completion_date,
        };
      }
    });
    return latest.route;
  }
);

// create a dictionary of momoized task selectors
const taskSelectors = {};

const searchTaskList = (taskName, taskList) =>
  taskList.find((task) => {
    if (task.name === taskName) {
      return task;
    }
    if (task.subtasks && !_.isEmpty(task.subtasks)) {
      const found = searchTaskList(taskName, task.subtasks);
      if (found) {
        return found;
      }
    }
  });

const makeTaskSelector = (taskName) => {
  if (!taskSelectors[taskName]) {
    taskSelectors[taskName] = createSelector(taskListsSelector, (taskLists) => {
      // for each task list
      for (const taskList of Object.keys(taskLists)) {
        // search all tasks
        const found = searchTaskList(taskName, taskLists[taskList]);
        if (found) {
          return found;
        }
      }
      return {};
    });
  }
  return taskSelectors[taskName];
};

const updatingSelector = (state) => taskListsSelector(state).updating;

export {
  makeTaskListSelector,
  transformedTaskListSelector,
  mostRecentStartedTaskSelector,
  makeTaskSelector,
  updatingSelector,
};
