import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { clearKycImages } from 'spa/features/kyc/kycSlice';
import MobilePage from '../../components/MobilePage';

const CompanyVerificationSuccessPage = () => {
  const dispatch = useDispatch();

  const handleViewTransactions = useCallback(() => {
    dispatch(clearKycImages());
    window.location.href = `/transactions`;
  }, [dispatch]);

  return (
    <MobilePage
      title={`Verification Successful`}
      subtitle={`Your company verification is completed.`}
      ctaText="View my transactions"
      onSubmit={handleViewTransactions}
    />
  );
};

export default CompanyVerificationSuccessPage;
