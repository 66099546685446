import React from 'react';

const IntegrationsQuote = () => (
  <blockquote className="integrationsAuth-quote">
    <p className="integrationsAuth-quote-message">
      Escrow.com is one of the most trusted escrow services in the world, and is the most trusted in
      the domain name industry. Everybody I know uses them, and I always find their staff to be
      available, knowledgable and helpful.
    </p>
    <div className="integrationsAuth-quote-author">
      <span className="integrationsAuth-quote-avatar" />
      <div>
        <span className="integrationsAuth-quote-name">Michael Cyger</span>
        <span>Publisher &amp; Founder, DomainSherpa.com</span>
      </div>
    </div>
  </blockquote>
);

export default IntegrationsQuote;
