import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment';

import Icon from 'spa/components/Icon';
import { maxRowsPerPage } from 'spa/components/PartnerDashboard/tables/EnhancedTable';
import TransactionTable from 'spa/containers/PartnerDashboard/TransactionTable';
import { Filter, TabFilter } from 'spa/components/Filter';
import { DateRangePickerWithPresets } from 'spa/components/form';
import {
  getTransactionList as getTransactionListRoutine,
  getTransactionStatistics as getTransactionStatisticsRoutine,
  pushTransactionListPageTransactionCount,
  resetTransactionListPageTransactionCount,
  updateTransactionListFilter as updateTransactionListFilterAction,
} from 'spa/actions/PartnerActions';
import {
  transactionsCursorSelector,
  transactionListPageTransactionCount,
  transactionListPageUiSelector,
  transactionListPageFiltersSelector,
  transactionListPageOpenDateRangeSelector,
  transactionListPageCloseDateRangeSelector,
} from 'spa/selectors/PartnerSelectors';
import PartnerConstants from 'spa/constants/PartnerConstants';
import API from '../../../api';
import Spinner from '../../components/Indicators/Spinner';
import { urlFor } from '../../../routeConfig';

const { DEFAULT_START_DATE } = PartnerConstants;

const defaultTableFilterValues = {
  order: 'desc',
  orderBy: 'id',
  selected: [],
  currentPageIndex: 0,
  rowsPerPage: 5,
};

const FilterActions = ({ toggleDropdown, onApply, onClear }) => {
  const toggleDropdownOnApply = () => {
    onApply();
    toggleDropdown();
  };

  return (
    <div className="filter-actions">
      <a href="#" role="button" className="filter-actions-secondary" onClick={onClear}>
        Clear
      </a>
      <a href="#" role="button" className="filter-actions-primary" onClick={toggleDropdownOnApply}>
        Apply
      </a>
    </div>
  );
};

class TransactionListPage extends Component {
  constructor(props) {
    super(props);

    this.changeTabFilter = this.changeTabFilter.bind(this);
    this.onOpenDateChange = this.onOpenDateChange.bind(this);
    this.applyOpenDateFilter = this.applyOpenDateFilter.bind(this);
    this.clearOpenDateFilter = this.clearOpenDateFilter.bind(this);
    this.onCloseDateChange = this.onCloseDateChange.bind(this);
    this.applyCloseDateFilter = this.applyCloseDateFilter.bind(this);
    this.clearCloseDateFilter = this.clearCloseDateFilter.bind(this);
    this.clearNonTabFilters = this.clearNonTabFilters.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { getTransactionCount, getTransactionData } = this.props;
    if (prevProps.transactionListPage.filters !== this.props.transactionlistPageFilter) {
      const { filters } = this.props.transactionListPage;
      getTransactionData({ filters, limit: maxRowsPerPage });
      getTransactionCount({ filters, triggerAction: resetTransactionListPageTransactionCount });
    }
  }

  onOpenDateChange(startDate, endDate, selectedPreset) {
    this.props.updateFilter({
      openDateRange: { startDate, endDate, selectedPreset },
    });
  }

  onCloseDateChange(startDate, endDate, selectedPreset) {
    this.props.updateFilter({
      closeDateRange: { startDate, endDate, selectedPreset },
    });
  }

  async onGenerateReport() {
    await this.props.updateFilter({ isGeneratingReport: true });
    await API.generatePartnerReports(this.props.transactionlistPageFilter, 'transaction_filters');
    await this.props.updateFilter({ isGeneratingReport: false });
    this.props.viewReports();
  }

  getDateRangeFilterLabel(dateType) {
    const {
      initiation_start_date: initiationStartDate,
      initiation_end_date: initiationEndDate,
      closed_start_date: closeStartDate,
      closed_end_date: closeEndDate,
    } = this.props.transactionlistPageFilter;

    let dateString;
    let startDate;
    let endDate;

    switch (dateType) {
      case 'open':
        startDate = initiationStartDate;
        endDate = initiationEndDate;
        dateString = 'Date Created';
        break;
      case 'close':
        startDate = closeStartDate;
        endDate = closeEndDate;
        dateString = 'Date Closed';
        break;
      default:
        dateString = 'Default Date Label';
        break;
    }

    const formatStr = 'MMM D, YYYY';
    if (startDate && startDate[0] && endDate && endDate[0]) {
      return `${dateString} - ${moment(startDate[0]).format(formatStr)} - ${moment(
        endDate[0]
      ).format(formatStr)}`;
    } else if (startDate && startDate[0]) {
      return `${dateString} - From ${moment(startDate[0]).format(formatStr)}`;
    } else if (endDate && endDate[0]) {
      return `${dateString} - To ${moment(endDate[0]).format(formatStr)}`;
    }
    return `${dateString}`;
  }

  applyOpenDateFilter() {
    const { startDate, endDate } = this.props.transactionListPageOpenDateRange;
    if (startDate || endDate) {
      const filters = {
        ...this.props.transactionlistPageFilter,
        initiation_start_date: startDate ? [startDate.format('YYYY-MM-DD')] : [],
        initiation_end_date: endDate ? [endDate.format('YYYY-MM-DD')] : [],
      };
      this.props.updateFilter({ filters });
    }
  }

  applyCloseDateFilter() {
    const { startDate, endDate } = this.props.transactionListPageCloseDateRange;
    if (startDate || endDate) {
      const filters = {
        ...this.props.transactionlistPageFilter,
        closed_start_date: startDate ? [startDate.format('YYYY-MM-DD')] : [],
        closed_end_date: endDate ? [endDate.format('YYYY-MM-DD')] : [],
      };
      this.props.updateFilter({ filters });
    }
  }

  clearOpenDateFilter() {
    const { initiation_start_date, initiation_end_date, ...filters } =
      this.props.transactionlistPageFilter;
    this.props.updateFilter({
      filters,
      openDateRange: { startDate: null, endDate: null, selectedPreset: '' },
    });
  }

  clearCloseDateFilter() {
    const { closed_start_date, closed_end_date, ...filters } = this.props.transactionlistPageFilter;
    this.props.updateFilter({
      filters,
      closeDateRange: { startDate: null, endDate: null, selectedPreset: '' },
    });
  }

  changeTabFilter(tabFilterType) {
    const filters = {
      status: tabFilterType === 'all' ? [] : [tabFilterType],
    };
    const tableFilterValues = defaultTableFilterValues;
    this.props.updateFilter({ filters, tableFilterValues });
  }

  clearNonTabFilters() {
    const { status } = this.props.transactionlistPageFilter;
    this.props.updateFilter({
      filters: { status },
      openDateRange: { startDate: null, endDate: null, selectedPreset: '' },
      closeDateRange: { startDate: null, endDate: null, selectedPreset: '' },
    });
  }

  render() {
    const { filters, isGeneratingReport, closeDateRange, openDateRange } =
      this.props.transactionListPage;
    const { nextCursor, getTransactionData, getTransactionCount, transactionTotal } = this.props;
    const hasActiveFilters = Object.entries(filters).find(
      ([filterKey, filterKeyValues]) => (filterKeyValues || []).length > 0 && filterKey !== 'status'
    );
    return (
      <Fragment>
        <header className="partnerDashboard-sectionHeader">
          <h2 className="partnerDashboard-sectionHeader-title">Transactions</h2>
        </header>
        <nav className="partnerDashboard-contentNav contentNav contentNav--horizontal">
          <TabFilter
            label="All"
            handleClick={() => this.changeTabFilter('all')}
            active={
              !filters.status.find((filterValue) => filterValue === 'ongoing') &&
              !filters.status.find((filterValue) => filterValue === 'completed')
            }
          />
          <TabFilter
            label="Ongoing"
            handleClick={() => this.changeTabFilter('ongoing')}
            active={filters.status.find((filterValue) => filterValue === 'ongoing')}
          />
          <TabFilter
            label="Completed"
            handleClick={() => this.changeTabFilter('completed')}
            active={filters.status.find((filterValue) => filterValue === 'completed')}
          />
        </nav>
        <div className="partnerDashboard-controls">
          <span className="partnerDashboard-controls-primary-label">Filter by:</span>
          <div className="partnerDashboard-controls-primary filter">
            <Filter
              title={this.getDateRangeFilterLabel('open')}
              className="partnerDashboard-controls-filterItem"
            >
              <DateRangePickerWithPresets
                startDate={openDateRange.startDate}
                endDate={openDateRange.endDate}
                selectedPreset={openDateRange.selectedPreset}
                onDateChange={this.onOpenDateChange}
                dayPickerRangeProps={{
                  isOutsideRange: (day) => day.isAfter(moment().endOf('day')),
                }}
              />
              <FilterActions
                onApply={this.applyOpenDateFilter}
                onClear={this.clearOpenDateFilter}
              />
            </Filter>
          </div>
          <div className="partnerDashboard-controls-primary filter">
            <Filter
              title={this.getDateRangeFilterLabel('close')}
              className="partnerDashboard-controls-filterItem"
            >
              <DateRangePickerWithPresets
                startDate={closeDateRange.startDate}
                endDate={closeDateRange.endDate}
                selectedPreset={closeDateRange.selectedPreset}
                onDateChange={this.onCloseDateChange}
                dayPickerRangeProps={{
                  isOutsideRange: (day) => day.isAfter(moment().endOf('day')),
                }}
              />
              <FilterActions
                onApply={this.applyCloseDateFilter}
                onClear={this.clearCloseDateFilter}
              />
            </Filter>
          </div>
          {isGeneratingReport ? (
            <Spinner />
          ) : (
            <button
              className="partnerDashboard-downloadBtn btn"
              onClick={() => this.onGenerateReport()}
              disabled={transactionTotal <= 0}
            >
              <Icon name="ui-download" className="icon" />
              <span className="partnerDashboard-downloadBtn-text">Generate Report</span>
            </button>
          )}
        </div>
        <div className="partnerDashboard-controlResult">
          {hasActiveFilters && (
            <Fragment>
              <a
                href="#"
                role="button"
                className="partnerDashboard-controlResult-action"
                onClick={this.clearNonTabFilters}
              >
                Clear filters
              </a>
              <div className="partnerDashboard-controlResult-count">
                {transactionTotal} results found
              </div>
            </Fragment>
          )}
        </div>
        <TransactionTable
          filters={filters}
          getData={(payload) =>
            getTransactionData({ filters, cursor: nextCursor, limit: maxRowsPerPage, ...payload })
          }
          getDataTotals={() => getTransactionCount({ filters })}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  transactionTotal: transactionListPageTransactionCount(state),
  nextCursor: transactionsCursorSelector(state),
  transactionListPage: transactionListPageUiSelector(state),
  transactionListPageOpenDateRange: transactionListPageOpenDateRangeSelector(state),
  transactionListPageCloseDateRange: transactionListPageCloseDateRangeSelector(state),
  transactionlistPageFilter: transactionListPageFiltersSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getTransactionData: (payload) => dispatch(getTransactionListRoutine.trigger(payload)),
  updateFilter: (payload) => dispatch(updateTransactionListFilterAction(payload)),
  getTransactionCount: ({ filters = {}, triggerAction }) => {
    const {
      initiation_start_date: startDateFilter,
      initiation_end_date: endDateFilter,
      ...nonDateFilters
    } = filters;

    const getDateFromFilter = (dateFilter, defaultValue) => {
      if (dateFilter && dateFilter.length > 0) {
        return dateFilter[0];
      }

      return defaultValue;
    };

    dispatch(
      getTransactionStatisticsRoutine.trigger({
        dataType: 'initiation_date',
        interval: 'yearly',
        dateStart: getDateFromFilter(startDateFilter, DEFAULT_START_DATE),
        dateEnd: getDateFromFilter(endDateFilter, moment().format('YYYY-MM-DD')),
        dataLoaderAction: pushTransactionListPageTransactionCount,
        filters: nonDateFilters,
        triggerAction,
      })
    );
  },
  viewReports: () => dispatch(push(urlFor('partner_dashboard_reports'))),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionListPage);
