import { createSelector } from 'reselect';

const expandCollapsesSelector = (state) => state.expandCollapse;

const expandCollapseSelector = (state, name) => expandCollapsesSelector(state)[name] || {};

const makeExpandedSelector = () =>
  createSelector(expandCollapseSelector, (expandCollapse) => expandCollapse.expanded);

export { makeExpandedSelector };
