import React from 'react';
import { useDispatch } from 'react-redux';

import { Card, Stack, CardActionArea, Box } from '@mui/material';
import { PersonOutlined, ChevronRight, Apartment } from '@mui/icons-material';

import { V3KYCMobilePages as Pages } from 'spa/constants/VerificationConstants';
import VerificationConstants from 'spa/constants/VerificationConstants';
import { pushPreviousPage, saveAccountType, saveCurrentPage } from 'spa/features/kyc/kycSlice';

import { LargeText, SmallText } from 'spa/features/ui/EscrowTypography';
import MobilePage from 'spa/features/kyc/mobile/components/MobilePage';

const AccountTypeCard = ({ onClick, Icon, accountType, description }) => {
  console.log('account_type', VerificationConstants.ACCOUNT_TYPE_COMPANY);

  return (
    <Card variant="outlined" sx={{ borderRadius: '8px' }}>
      <CardActionArea onClick={onClick}>
        <Stack
          direction="row"
          sx={{
            gap: '8px',
            margin: '24px 16px',
            width: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Stack direction="column" sx={{ gap: '4px', display: 'flex', alignItems: 'start' }}>
            <Icon
              sx={{
                width: '32px',
                height: '32px',
              }}
            />
            <LargeText sx={{ fontSize: 'clamp(20px, 5.5vw, 24px)' }}>{accountType}</LargeText>
            <SmallText>{description}</SmallText>
          </Stack>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
            <ChevronRight
              sx={{
                width: '24px',
                height: '24px',
              }}
            />
          </Box>
        </Stack>
      </CardActionArea>
    </Card>
  );
};

const AccountTypePage = () => {
  const dispatch = useDispatch();

  const onIndividualClick = () => {
    dispatch(saveAccountType({ accountType: VerificationConstants.ACCOUNT_TYPE_INDIVIDUAL }));
    dispatch(pushPreviousPage({ page: Pages.ACCOUNT_TYPE }));
    dispatch(saveCurrentPage({ page: Pages.VERIFICATION_SUCCESS }));
  };

  const onCompanyClick = () => {
    dispatch(saveAccountType({ accountType: VerificationConstants.ACCOUNT_TYPE_COMPANY }));
    dispatch(pushPreviousPage({ page: Pages.ACCOUNT_TYPE }));
    dispatch(saveCurrentPage({ page: Pages.COMPANY_DETAILS }));
  };

  return (
    <MobilePage title={'Great, let’s select an account type'} noButton dynamicTitleFontSize="5.5vw">
      <Stack sx={{ gap: '24px' }}>
        <AccountTypeCard
          Icon={PersonOutlined}
          onClick={onIndividualClick}
          accountType="Individual"
          description="Buy or sell under your own name."
        />
        <AccountTypeCard
          Icon={Apartment}
          onClick={onCompanyClick}
          accountType="Company"
          description="Buy or sell as a corporation, LLC, trust, partnership, or sole proprietorship."
        />
      </Stack>
    </MobilePage>
  );
};

export default AccountTypePage;
