import React from 'react';

import NotificationSettings from 'spa/containers/NotificationSettings';
import AccountHeader from 'spa/components/AccountHeader';
import UserStore from '../../../stores/UserStore';
import AuthenticationStore from '../../../stores/AuthenticationStore';

class NotificationSettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
    };
    this._onChange = this._onChange.bind(this);
    AuthenticationStore.addChangeListener(this._onChange);
    UserStore.addChangeListener(this._onChange);
  }

  componentWillUnmount() {
    AuthenticationStore.removeChangeListener(this._onChange);
    UserStore.removeChangeListener(this._onChange);
  }

  _onChange() {
    const custId = AuthenticationStore.getCustomerID();
    this.setState({
      name: UserStore.getFullName(custId),
    });
  }

  render() {
    return (
      <div className="accountInfo">
        <AccountHeader name={this.state.name} />
        <NotificationSettings />
      </div>
    );
  }
}

export default NotificationSettingsPage;
