const baseSelector = (state) => state.offer;

export const activeTransIdSelector = (state) => baseSelector(state).activeTransId;

export const auctionIdSelector = (state) => baseSelector(state).auctionId;

export const offerIdsByAuctionIdSelector = (state) => baseSelector(state).offerIdsByAuctionId || {};

export const offersByIdSelector = (state) => baseSelector(state).offersById || {};

export const offersSelector = (state) => {
  const offersById = offersByIdSelector(state);
  const offerIdsByAuctionId = offerIdsByAuctionIdSelector(state);
  const offerIdsForAuctionId = offerIdsByAuctionId[auctionIdSelector(state)] || [];
  return offerIdsForAuctionId.map((oId) => offersById[oId]);
};

export const auctionTransIdsSelector = (state) =>
  Array.from(new Set(offersSelector(state).map((offer) => offer.transactionId)));

export const offersByTransIdSelector = (state) => {
  const offers = Object.values(offersByIdSelector(state));
  const transIds = Array.from(new Set(offers.map((offer) => offer.transactionId)));
  return transIds.reduce((result, tId) => {
    result[tId] = offers.filter((o) => o.transactionId === tId);
    return result;
  }, {});
};

export const uiSelector = (state) => baseSelector(state).ui;

export const auctionsByIdSelector = (state) => baseSelector(state).auctionsById || {};

export const auctionsSelector = (state) => Object.values(auctionsByIdSelector(state));

export const currentAuctionSelector = (state) =>
  auctionsByIdSelector(state)[auctionIdSelector(state)];

export const auctionTokenSelector = (state) => baseSelector(state).auctionToken;

export const auctionSourceSelector = (state) => baseSelector(state).auctionSource;

export const viewSelector = (state) => baseSelector(state).view;

export const requestSelector = (state) => baseSelector(state).request;

export const buyItNowRequestSelector = (state, auctionId) =>
  requestSelector(state).buyItNowByAuctionId[auctionId] || {
    error: false,
    errorMessage: null,
    loading: false,
  };

export const acceptRequestSelector = (state, offerId) =>
  requestSelector(state).acceptOfferById[offerId] || {
    error: false,
    errorMessage: null,
    loading: false,
  };

export const acceptRequestFromManagementSelector = (state, offerId) =>
  requestSelector(state).acceptOfferById[offerId] || {
    error: false,
    errorMessage: null,
    loading: false,
  };

export const rejectRequestSelector = (state, offerId) =>
  requestSelector(state).rejectOfferById[offerId] || {
    error: false,
    errorMessage: null,
    loading: false,
  };

export const cancelRequestSelector = (state, offerId) =>
  requestSelector(state).cancelOfferById[offerId] || {
    error: false,
    errorMessage: null,
    loading: false,
  };

export const getOffersByAuctionIdSelector = (state, auctionId) =>
  requestSelector(state).getAuctionOffersByAuctionId[auctionId] || {
    error: false,
    errorMessage: null,
    loading: false,
  };

export const payTransactionRequestSelector = (state, transactionId) =>
  requestSelector(state).payTransactionByTransactionId[transactionId] || {
    error: false,
    errorMessage: null,
    loading: false,
  };
