import React from 'react';
import classnames from 'classnames';
import CheckoutConstants from 'spa/constants/CheckoutConstants';
import { gettext } from '../../../utils/filters';

const CheckoutGuide = (props) => {
  let heading;
  let subheading;
  let centered = false;
  let light = false;
  const hashPattern = /[A-Za-z0-9]{30}/;
  switch (props.currentStep) {
    case CheckoutConstants.STEP_LOGIN:
      heading = 'Login to Escrow.com';
      subheading =
        !props.partner || !props.partner.name || hashPattern.test(props.partner.name)
          ? 'The most trusted, licensed online escrow service in the world.'
          : `Escrow.com is a trusted, and licensed online escrow service recommended by ${props.partner.name} to protect buyers and sellers.`;
      centered = true;
      break;
    case CheckoutConstants.STEP_SIGNUP:
      heading = 'Create an account on Escrow.com';
      subheading =
        !props.partner || !props.partner.name || hashPattern.test(props.partner.name)
          ? 'The most trusted, licensed online escrow service in the world.'
          : `Escrow.com is a trusted, and licensed online escrow service recommended by ${props.partner.name} to protect buyers and sellers.`;
      centered = true;
      break;
    case CheckoutConstants.STEP_VIEW_DETAILS:
      heading = 'Your Account Details';
      subheading =
        'Please review your account details below if they are correct. If they are, you may confirm and continue to the next step.';
      light = true;
      break;
    case CheckoutConstants.STEP_TIER1_VERIFICATION:
      heading = 'Add Your Account Details';
      subheading =
        'Let’s set up your account in order for you to deposit and manage your funds securely.';
      light = true;
      break;
    default:
      heading = null;
      subheading = null;
  }
  if (!heading && !subheading) {
    return null;
  }

  return (
    <div
      className={classnames('checkout-card-guide', {
        'checkout-card-guide--center': centered,
        'checkout-card-content--light': light,
      })}
    >
      <h2>{gettext(heading)}</h2>
      <div className="checkout-guide-subheading">{gettext(subheading)}</div>
    </div>
  );
};

export default CheckoutGuide;
