var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/components/listItems.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
["items", "specifier"], 
[], 
function (l_items, l_specifier, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("items", l_items);
frame.set("specifier", l_specifier);
var t_2 = "";frame = frame.push();
var t_5 = l_items;
if(t_5) {var t_4 = t_5.length;
for(var t_3=0; t_3 < t_5.length; t_3++) {
var t_6 = t_5[t_3];
frame.set("item", t_6);
frame.set("loop.index", t_3 + 1);
frame.set("loop.index0", t_3);
frame.set("loop.revindex", t_4 - t_3);
frame.set("loop.revindex0", t_4 - t_3 - 1);
frame.set("loop.first", t_3 === 0);
frame.set("loop.last", t_3 === t_4 - 1);
frame.set("loop.length", t_4);
t_2 += "\n  <li class=\"dropdown-item ";
if(runtime.memberLookup((t_6),"mutator")) {
t_2 += " ";
t_2 += runtime.suppressValue(runtime.memberLookup((t_6),"mutator"), env.opts.autoescape);
t_2 += " ";
;
}
t_2 += "\" data-component=\"";
t_2 += runtime.suppressValue(l_specifier, env.opts.autoescape);
t_2 += "\">\n  <a class=\"dropdown-link ";
if(runtime.memberLookup((t_6),"mutator")) {
t_2 += " ";
t_2 += runtime.suppressValue(runtime.memberLookup((t_6),"mutator"), env.opts.autoescape);
t_2 += " ";
;
}
t_2 += "\" data-value=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((t_6),"category"), env.opts.autoescape);
t_2 += "\" data-e2e-target=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((t_6),"phrase"), env.opts.autoescape);
t_2 += "\">";
t_2 += runtime.suppressValue(runtime.memberLookup((t_6),"phrase"), env.opts.autoescape);
t_2 += "</a>\n  </li>\n  ";
;
}
}
frame = frame.pop();
;
frame = frame.pop();
return new runtime.SafeString(t_2);
});
context.addExport("listItems");
context.setVariable("listItems", macro_t_1);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/components/listItems.html"] , dependencies)