import { formLoginSignup, login, twoFAValidate } from 'spa/actions/AuthenticationActions';
import AuthenticationConstants from 'spa/constants/AuthenticationConstants';

const initialState = {
  prefill: {
    username: null,
  },
  form: {
    mode: AuthenticationConstants.MODE_LOGIN,
    error: false,
    loading: false,
  },
  login: {
    loading: false,
    success: false,
    error: null,
    // 2fa check result:
    has_valid_cust_device_token: false,
    authenticator: false,
    sms: false,
  },
};

function AuthenticationReducer(state = initialState, action) {
  switch (action.type) {
    case AuthenticationConstants.PREFILL_USERNAME:
      return {
        ...state,
        prefill: {
          ...state.prefill,
          username: action.payload,
        },
      };
    case AuthenticationConstants.ACTION_SET_MODE:
      return {
        ...state,
        form: {
          ...state.form,
          mode: action.payload,
          error: false,
        },
      };

    case formLoginSignup.REQUEST:
      return {
        ...state,
        form: {
          ...state.form,
          error: false,
          loading: true,
        },
      };

    case formLoginSignup.SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          error: false,
          loading: false,
        },
      };

    case formLoginSignup.FAILURE:
      return {
        ...state,
        form: {
          ...state.form,
          error: true,
          loading: false,
        },
      };
    case login.REQUEST:
    case twoFAValidate.REQUEST:
      return {
        ...state,
        login: {
          ...state.login,
          loading: true,
        },
      };
    case login.SUCCESS:
      return {
        ...state,
        login: {
          ...state.login,
          has_valid_cust_device_token: action.payload.has_valid_cust_device_token,
          authenticator: action.payload.authenticator,
          sms: action.payload.sms,
          loading: false,
          success: true,
          error: null,
        },
      };
    case login.FAILURE:
      return {
        ...state,
        login: {
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    case twoFAValidate.SUCCESS:
      return {
        ...state,
        login: {
          ...state.login,
          has_valid_cust_device_token: true,
        },
      };
    case twoFAValidate.FULFILL:
      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
        },
      };
    case twoFAValidate.FAILURE:
      return {
        ...state,
        login: {
          ...state.login,
          has_valid_cust_device_token: false,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default AuthenticationReducer;
