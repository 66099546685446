import React, { useState } from 'react';
import classNames from 'classnames';
import Icon from 'spa/components/Icon';

const Announcement = (props) => {
  const { type, title, subtitle, children, closable = true, className } = props;

  const [isOpen, setIsOpen] = useState(true);

  const announcementStyle = classNames(
    'announcement',
    {
      'is-hidden': !isOpen,
      'announcement--warning': type === 'warning',
      'announcement--success': type === 'tick',
      'announcement--icon': !!type,
    },
    className
  );
  let icon;

  switch (type) {
    case 'warning':
      icon = <Icon name="ui-warning" />;
      break;
    case 'tick':
      icon = <Icon name="ui-tick" />;
      break;
    default:
      break;
  }

  return (
    <div className={announcementStyle}>
      <div className="announcement-container">
        <div className="announcement-content">
          {icon && <span className="announcement-icon">{icon}</span>}
          {title && <span className="announcement-title">{title}</span>}
          {subtitle && <span className="announcement-subtitle">{subtitle}</span>}
          {children && <div className="announcement-extra">{children}</div>}
        </div>
        {closable && (
          <button
            className="announcement-close"
            onClick={() => setIsOpen(false)}
            title="Close announcement"
          >
            <Icon name="ui-close" className="modalV2-closeBtn-icon" />
          </button>
        )}
      </div>
    </div>
  );
};

export default Announcement;
