import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment';
import Icon from 'spa/components/Icon';
import CustomerTable from 'spa/containers/PartnerDashboard/CustomerTable';
import { TabFilter } from 'spa/components/Filter';
import { maxRowsPerPage } from 'spa/components/PartnerDashboard/tables/EnhancedTable';
import {
  getCustomerList as getCustomerListRoutine,
  getCustomerStatistics as getCustomerStatisticsRoutine,
  pushUserListPageUsersCount,
  resetUserListPageUsersCount,
} from 'spa/actions/PartnerActions';
import {
  customersCursorSelector,
  customerListPageUserCountSelector,
} from 'spa/selectors/PartnerSelectors';
import { apiVerificationStatuses } from 'spa/constants/VerificationConstants';
import PartnerConstants from 'spa/constants/PartnerConstants';
import API from '../../../api';
import Spinner from '../../components/Indicators/Spinner';
import { urlFor } from '../../../routeConfig';

const { DEFAULT_START_DATE } = PartnerConstants;

class CustomerListPage extends Component {
  constructor() {
    super();

    this.state = {
      filters: {
        verification_status: [],
      },
      isGeneratingReport: false,
    };

    this.changeTabFilter = this.changeTabFilter.bind(this);
  }

  async onGenerateReport() {
    await this.setState({ isGeneratingReport: true });
    await API.generatePartnerReports(this.state.filters, 'customer_filters');
    await this.setState({ isGeneratingReport: false });
    this.props.viewReports();
  }

  changeTabFilter(tabFilterType) {
    const { getCustomerData, getCustomerCount } = this.props;
    const filters = {
      verification_status: [],
    };

    if (tabFilterType === apiVerificationStatuses.VERIFIED) {
      filters.verification_status = ['personal_verified', 'company_verified'];
    } else if (tabFilterType === apiVerificationStatuses.NOT_VERIFIED) {
      filters.verification_status = [apiVerificationStatuses.NOT_VERIFIED];
    }

    this.setState({ filters }, () => {
      getCustomerData({ filters, limit: maxRowsPerPage });
      getCustomerCount({ filters, triggerAction: resetUserListPageUsersCount });
    });
  }

  render() {
    const { filters, isGeneratingReport } = this.state;
    const { customerTotal, nextCursor, getCustomerData, getCustomerCount } = this.props;
    const hasActiveFilters = Object.entries(filters).find(
      ([filterKey, filterKeyValues]) =>
        (filterKeyValues || []).length > 0 && filterKey !== 'verification_status'
    );
    return (
      <Fragment>
        <header className="partnerDashboard-sectionHeader">
          <h2 className="partnerDashboard-sectionHeader-title">Users</h2>
        </header>
        <nav className="partnerDashboard-contentNav contentNav contentNav--horizontal">
          <TabFilter
            label="All"
            handleClick={() => this.changeTabFilter('all')}
            active={
              !filters.verification_status.find((filter) => filter === 'personal_verified') &&
              !filters.verification_status.find((filter) => filter === 'company_verified') &&
              !filters.verification_status.find(
                (filter) => filter === apiVerificationStatuses.NOT_VERIFIED
              )
            }
          />
          <TabFilter
            label="Verified"
            handleClick={() => this.changeTabFilter(apiVerificationStatuses.VERIFIED)}
            active={
              filters.verification_status.find((filter) => filter === 'personal_verified') &&
              filters.verification_status.find((filter) => filter === 'company_verified')
            }
          />
          <TabFilter
            label="Not Verified"
            handleClick={() => this.changeTabFilter(apiVerificationStatuses.NOT_VERIFIED)}
            active={filters.verification_status.find(
              (filter) => filter === apiVerificationStatuses.NOT_VERIFIED
            )}
          />
        </nav>
        <div className="partnerDashboard-controls">
          <div className="partnerDashboard-controls-primary filter" />
          {isGeneratingReport ? (
            <Spinner />
          ) : (
            <button
              className="partnerDashboard-downloadBtn btn"
              onClick={() => this.onGenerateReport()}
              disabled={customerTotal <= 0}
            >
              <Icon name="ui-download" className="icon" />
              <span className="partnerDashboard-downloadBtn-text">Generate Report</span>
            </button>
          )}
        </div>
        <div className="partnerDashboard-controlResult">
          {hasActiveFilters && (
            <Fragment>
              <a href="#" role="button" className="partnerDashboard-controlResult-action">
                Clear filters
              </a>
              <div className="partnerDashboard-controlResult-count">
                {customerTotal} results found
              </div>
            </Fragment>
          )}
        </div>
        <CustomerTable
          filters={filters}
          getData={(payload) =>
            getCustomerData({ filters, cursor: nextCursor, limit: maxRowsPerPage, ...payload })
          }
          getDataTotals={() => getCustomerCount({ filters })}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  customerTotal: customerListPageUserCountSelector(state),
  nextCursor: customersCursorSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCustomerData: (payload) => dispatch(getCustomerListRoutine.trigger(payload)),
  getCustomerCount: ({ filters = {}, triggerAction }) =>
    dispatch(
      getCustomerStatisticsRoutine.trigger({
        dataType: 'date_registered',
        interval: 'yearly',
        dateStart: DEFAULT_START_DATE,
        dateEnd: moment().format('YYYY-MM-DD'),
        dataLoaderAction: pushUserListPageUsersCount,
        filters,
        triggerAction,
      })
    ),
  viewReports: () => dispatch(push(urlFor('partner_dashboard_reports'))),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerListPage);
