import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { MenuItem, InputLabel, TextField, Select, Stack } from '@mui/material';
import { CountryToAlpha2, Alpha3ToAlpha2 } from 'spa/constants/ISOCountryCodes';
import {
  V3KYCMobilePages as Pages,
  kycV2ApiMapping,
  ERROR_TYPES,
} from 'spa/constants/VerificationConstants';
import {
  saveCompanyDetails,
  kycEmailSelector,
  kycCustomerIdSelector,
  kycCompanyDetailsSelector,
} from 'spa/features/kyc/kycSlice';
import MobilePage from 'spa/features/kyc/mobile/components/MobilePage';
import MobilePageFormControl from 'spa/features/kyc/mobile/components/MobilePageFormControl';
import { getStateSelectOptions } from 'spa/components/CustomerVerification/V2/Fields/AddressFieldSet';
import {
  useGetCustomerDetailsQuery,
  useUpdateCustomerDetailsMutation,
  useUpdateV4CustomerDetailsMutation,
} from 'spa/features/kyc/kycApi';
import { processKycV2RawIndividualFormData } from 'spa/sagas/VerificationSaga';
import { mapFormDataToRequestBody } from 'spa/../utils/DataMapping';
import KYCAlert from '../../components/KYCAlert';

const CompanyDetailsPage = () => {
  const dispatch = useDispatch();

  const customerId = useSelector(kycCustomerIdSelector);
  const email = useSelector(kycEmailSelector);
  const companyDetails = useSelector(kycCompanyDetailsSelector);

  const [
    updateCustomerDetails,
    { isLoading: isLoadingLegacy, error: errorLegacy, isSuccess: isSuccessLegacy },
  ] = useUpdateCustomerDetailsMutation();
  const [
    updateV4CustomerDetails,
    { isLoading: isLoadingV4, error: errorV4, isSuccess: isSuccessV4 },
  ] = useUpdateV4CustomerDetailsMutation();
  const {
    // eslint-disable-next-line no-unused-vars
    data: customerData,
  } = useGetCustomerDetailsQuery(customerId);

  const isSuccess = isSuccessLegacy && isSuccessV4;
  const isLoading = isLoadingLegacy || isLoadingV4;
  const submissionError = errorLegacy || errorV4;

  // eslint-disable-next-line no-console
  console.log('errorLegacy', errorLegacy);
  // eslint-disable-next-line no-console
  console.log('errorV4', errorV4);
  // eslint-disable-next-line no-console
  console.log('submissionError', submissionError);

  const onSubmitDetails = useCallback(
    (rawData) => {
      dispatch(saveCompanyDetails(rawData));

      // eslint-disable-next-line no-console
      console.log('data', rawData);

      const companyData = {
        'account-type': 'company',
        'company-address': rawData.companyAddress,
        'company-city': rawData.city,
        'company-country': rawData.country,
        'company-name': rawData.companyName,
        'company-post-code': rawData.postCode,
        'company-state': rawData.state,
      };
      const processedData = processKycV2RawIndividualFormData(companyData, email);
      const processedCompanyDetails = mapFormDataToRequestBody(kycV2ApiMapping, processedData);

      // eslint-disable-next-line no-console
      console.log('processedCompanyDetails', processedCompanyDetails);

      updateCustomerDetails({ customerId, customerDetails: processedCompanyDetails });

      const companyAddressDetails = {
        address: {
          state: customerData.state,
          country: customerData.country,
        },
        company: {
          address: {
            state: rawData.state,
            country: rawData.country,
          },
        },
      };
      // Sets ISO code for GST Tax as per D141759
      updateV4CustomerDetails({ addressDetails: companyAddressDetails });
    },
    [customerId, dispatch, email, updateCustomerDetails, updateV4CustomerDetails, customerData]
  );

  const defaultCountry =
    (Alpha3ToAlpha2[window.config.geoip_country] &&
      Alpha3ToAlpha2[window.config.geoip_country].toUpperCase()) ||
    'US';

  const {
    handleSubmit,
    watch,
    resetField,
    control,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      companyName: companyDetails.companyName,
      country: companyDetails.country || defaultCountry,
      companyAddress: companyDetails.companyAddress,
      city: companyDetails.city,
      state: companyDetails.state,
      postCode: companyDetails.postCode,
    },
  });

  const selectedCountry = watch('country');
  const stateSelectOptions = getStateSelectOptions(selectedCountry).map(({ label, value }) => (
    <MenuItem key={value} value={value}>
      {label}
    </MenuItem>
  ));

  useEffect(() => {
    resetField('state');
  }, [resetField, selectedCountry]);

  return (
    <MobilePage
      title="Enter your company's details"
      ctaText="Continue"
      onSubmit={handleSubmit((data) => {
        if (isValid) {
          onSubmitDetails(data);
        }
      })}
      nextPage={Pages.COMPANY_DOCUMENT_SELECTION}
      nextPageTrigger={isSuccess}
      disableButtons={isLoading}
    >
      <form>
        <Stack direction="column">
          {submissionError && (
            <KYCAlert
              sx={{ marginBottom: '32px' }}
              isError={submissionError}
              errorType={ERROR_TYPES.SUBMISSION_FAILED}
              xRequestId={submissionError.xRequestId}
            />
          )}
          <MobilePageFormControl>
            <Controller
              name="companyName"
              control={control}
              rules={{ required: true, pattern: /^[^\s].*$/ }}
              render={({ field }) => (
                <TextField {...field} id="company-name" label="Company name" />
              )}
            />
          </MobilePageFormControl>
          <MobilePageFormControl>
            <InputLabel id="country-label">Country</InputLabel>
            <Controller
              name="country"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select {...field} id="country" label="Country">
                  {Object.entries(CountryToAlpha2).map(([name, alpha2]) => (
                    <MenuItem key={alpha2} value={alpha2}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </MobilePageFormControl>
          <MobilePageFormControl>
            <Controller
              name="companyAddress"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField {...field} id="company-address" label="Company address" />
              )}
            />
          </MobilePageFormControl>
          <MobilePageFormControl>
            <Controller
              name="city"
              control={control}
              rules={{ required: true }}
              render={({ field }) => <TextField {...field} id="city" label="City" />}
            />
          </MobilePageFormControl>
          <MobilePageFormControl>
            <InputLabel id="country-label">State</InputLabel>
            <Controller
              name="state"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select {...field} id="state" label="State">
                  {stateSelectOptions}
                </Select>
              )}
            />
          </MobilePageFormControl>
          <MobilePageFormControl>
            <Controller
              name="postCode"
              control={control}
              render={({ field }) => <TextField {...field} id="post-code" label="Zip code" />}
            />
          </MobilePageFormControl>
        </Stack>
      </form>
    </MobilePage>
  );
};

export default CompanyDetailsPage;
