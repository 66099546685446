import window from 'window-shim';
import QueryString from 'query-string';
import { connect } from 'react-redux';
import React from 'react';

import { MakeAnOfferContainer } from 'spa/containers/Offer';
import { changeView } from 'spa/actions/OfferActions';
import { trackEscrowUserAction } from 'spa/actions/TrackingActions';
import OfferConstants from 'spa/constants/OfferConstants';
import Header from 'spa/containers/Header';
import { gettext } from '../../../utils/filters';

class MakeAnOfferPage extends React.Component {
  constructor(props) {
    super(props);

    const query = QueryString.parse(window.location.search);
    const token = query.token || window.js_context.auction_token;
    this.state = {
      token: token,
      source: query.source,
    };
  }

  componentDidMount() {
    this.props.changeView(OfferConstants.MAKE_OFFER_VIEW);
    this.props.trackPageSource(this.state.source);
  }

  render() {
    return (
      <div>
        <Header placeholder={gettext('Make Offer with Escrow.com')} />
        <div className="checkout section section--small section--dark">
          <div className="checkout-content section-container">
            <MakeAnOfferContainer token={this.state.token} source={this.state.source} />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeView: (view) => dispatch(changeView(view)),
  trackPageSource: (source) =>
    dispatch(
      trackEscrowUserAction({
        name: 'user_landing',
        section: source,
      })
    ),
});

export default connect(null, mapDispatchToProps)(MakeAnOfferPage);
