import { parseAmount, parseDate } from 'spa/features/transaction/utils/parsers';

// Order of roles when determining a customer's role in a transaction
export const ROLE_ORDER = ['buyer', 'seller', 'broker', 'partner'];

export const PROCESSING_FEES = ['credit_card', 'intermediary'];

function formatCurrency(currency) {
  // The v4 response returns 'euro' instead of 'eur'
  // safer to do this than to change the v4 response
  if (currency === 'euro') {
    return 'eur';
  }
  return currency;
}

export function processTransactionJson(transactionJson, extraDetailsJson) {
  let inspectionPeriod;
  let shippingType;
  let concierge = false;
  let titleCollection = false;
  let lienHolder = false;
  const escrowFeePayerEmails = new Set();
  let shippingFeePayerEmail;
  let shippingFeeBeneficiaryEmail;
  let brokerFeePayerEmail;
  let brokerFee = 0;
  let partnerFeePayerEmail;
  let brokerFeeHiddenFrom = [];
  let dnsManager;
  let odometer;
  let vin;
  let merchantUrl;
  const fees = {};
  const taxes = {};
  const emailToRoles = {};
  const partyHiddenFrom = {};
  const parties = {};
  for (const party of transactionJson.parties) {
    fees[party.customer] = {};
    taxes[party.customer] = {};
    const role = party.role;
    if (party.customer in emailToRoles) {
      emailToRoles[party.customer].push(role);
    } else {
      emailToRoles[party.customer] = [role];
    }

    if ('visibility' in party && party.visibility.hidden_from) {
      partyHiddenFrom[role] = party.visibility.hidden_from;
    }

    const addressData = extraDetailsJson[`${role}_address`];
    let address;
    if (addressData) {
      address = { ...addressData, zipCode: addressData.post_code };
      delete address.post_code;
    }
    const email = extraDetailsJson[`${role}_email`];
    parties[role] = {
      email: /^confidential\+\d*@escrow\.com$/.test(email) ? null : email,
      name: extraDetailsJson[`${role}_name`],
      phoneNumber: extraDetailsJson[`${role}_phone_number`] || null,
      address: address || null,
    };
  }

  let arbitration = false;
  const transactionItems = [];
  for (const item of transactionJson.items) {
    if (item.type === 'shipping_fee') {
      shippingFeePayerEmail = item.schedule[0].payer_customer;
      shippingFeeBeneficiaryEmail = item.schedule[0].beneficiary_customer;
      fees[shippingFeePayerEmail].shippingFee = item.schedule[0].amount;
    } else if (item.type === 'broker_fee') {
      brokerFeePayerEmail = item.schedule[0].payer_customer;
      brokerFee += parseAmount(item.schedule[0].amount);
      if ('visibility' in item && item.visibility.hidden_from) {
        brokerFeeHiddenFrom = item.visibility.hidden_from;
      }
      fees[brokerFeePayerEmail].brokerFee = item.schedule[0].amount;
    } else if (item.type === 'partner_fee') {
      partnerFeePayerEmail = item.schedule[0].payer_customer;
      fees[partnerFeePayerEmail].partnerFee = item.schedule[0].amount;
    } else {
      // A non-fee item

      transactionItems.push({
        title: item.title,
        description: item.description,
        type: item.type,
        category: item.category,
        quantity: item.quantity,
        amount: item.schedule.reduce((sum, schedule) => sum + Number(schedule.amount), 0),
        status: item.status,
      });

      for (const fee of item.fees) {
        if (fees[fee.payer_customer] === undefined) {
          fees[fee.payer_customer] = {};
        }
        if (fee.type === 'escrow') {
          escrowFeePayerEmails.add(fee.payer_customer);
          if (fees[fee.payer_customer].escrow) {
            fees[fee.payer_customer].escrowFee += fee.amount;
          } else {
            fees[fee.payer_customer].escrowFee = fee.amount;
          }
          if (fee.taxes && fee.taxes[0] && fee.taxes[0].type) {
            taxes[fee.payer_customer].escrowFee = fee.taxes[0].type;
          }
        }
        if (fee.type === 'concierge') {
          escrowFeePayerEmails.add(fee.payer_customer);
          if (fees[fee.payer_customer].concierge) {
            fees[fee.payer_customer].concierge += fee.amount;
          } else {
            fees[fee.payer_customer].concierge = fee.amount;
          }
          if (fee.taxes && fee.taxes[0] && fee.taxes[0].type) {
            taxes[fee.payer_customer].concierge = fee.taxes[0].type;
          }
        }
        if (fee.type === 'title_collection') {
          escrowFeePayerEmails.add(fee.payer_customer);
          if (fees[fee.payer_customer].titleCollection) {
            fees[fee.payer_customer].titleCollection += fee.amount;
          } else {
            fees[fee.payer_customer].titleCollection = fee.amount;
          }
          if (fee.taxes && fee.taxes[0] && fee.taxes[0].type) {
            taxes[fee.payer_customer].titleCollection = fee.taxes[0].type;
          }
        }
        if (fee.type === 'lien_holder_payoff') {
          escrowFeePayerEmails.add(fee.payer_customer);
          if (fees[fee.payer_customer] && fees[fee.payer_customer].lienHolderPayoff) {
            fees[fee.payer_customer].lienHolderPayoff += fee.amount;
          } else {
            fees[fee.payer_customer].lienHolderPayoff = fee.amount;
          }
          if (fee.taxes && fee.taxes[0] && fee.taxes[0].type) {
            taxes[fee.payer_customer].lienHolderPayoff = fee.taxes[0].type;
          }
        }
        if (fee.type === 'disbursement') {
          escrowFeePayerEmails.add(fee.payer_customer);
          if (fees[fee.payer_customer].disbursement) {
            fees[fee.payer_customer].disbursement += fee.amount;
          } else {
            fees[fee.payer_customer].disbursement = fee.amount;
          }
          if (fee.taxes && fee.taxes[0] && fee.taxes[0].type) {
            taxes[fee.payer_customer].disbursement = fee.taxes[0].type;
          }
        }
        if (fee.type === 'domain_name_holding') {
          escrowFeePayerEmails.add(fee.payer_customer);
          if (fees[fee.payer_customer].domainNameHolding) {
            fees[fee.payer_customer].domainNameHolding = (
              parseAmount(fees[fee.payer_customer].domainNameHolding) + parseAmount(fee.amount)
            ).toString();
          } else {
            fees[fee.payer_customer].domainNameHolding = fee.amount;
          }
          if (fee.taxes && fee.taxes[0] && fee.taxes[0].type) {
            taxes[fee.payer_customer].domainNameHolding = fee.taxes[0].type;
          }
        }
        if (PROCESSING_FEES.includes(fee.type)) {
          if (fees[fee.payer_customer].paymentProcessingFee) {
            fees[fee.payer_customer].paymentProcessingFee += fee.amount;
          } else {
            fees[fee.payer_customer].paymentProcessingFee = fee.amount;
          }
          if (fee.taxes && fee.taxes[0] && fee.taxes[0].type) {
            taxes[fee.payer_customer].paymentProcessingFee = fee.taxes[0].type;
          }
        }
      }
      if (inspectionPeriod === undefined) {
        inspectionPeriod = item.inspection_period;
      }
      if (shippingType === undefined) {
        shippingType = item.shipping_type;
      }
      if ('extra_attributes' in item && item.extra_attributes !== undefined) {
        const extraAttributes = item.extra_attributes;
        if ('concierge' in extraAttributes) {
          concierge = extraAttributes.concierge;
        }
        if ('title_collection' in extraAttributes) {
          titleCollection = extraAttributes.title_collection;
        }
        if ('lien_holder_payoff' in extraAttributes) {
          lienHolder = extraAttributes.lien_holder_payoff;
        }
        if ('dns_manager' in extraAttributes && dnsManager === undefined) {
          dnsManager = extraAttributes.dns_manager;
        }
        if ('odometer' in extraAttributes) {
          odometer = extraAttributes.odometer;
        }
        if ('vin' in extraAttributes) {
          vin = extraAttributes.vin;
        }
        if ('merchant_url' in extraAttributes) {
          merchantUrl = extraAttributes.merchant_url;
        }
      }

      if ('status' in item && 'in_dispute' in item.status && item.status.in_dispute) {
        arbitration = true;
      }
    }
  }

  for (const [email, roles] of Object.entries(emailToRoles)) {
    emailToRoles[email] = roles.sort((a, b) => ROLE_ORDER.indexOf(a) - ROLE_ORDER.indexOf(b));
  }
  for (const [party, hiddenFromEmails] of Object.entries(partyHiddenFrom)) {
    partyHiddenFrom[party] = hiddenFromEmails.reduce(
      (hiddenFrom, email) => [...hiddenFrom, ...emailToRoles[email]],
      []
    );
  }
  brokerFeeHiddenFrom = brokerFeeHiddenFrom.reduce(
    (hiddenFrom, email) => [...hiddenFrom, ...emailToRoles[email]],
    []
  );

  const escrowFeePayers = new Set(
    Array.from(escrowFeePayerEmails).map((email) => emailToRoles[email][0])
  );

  let cancellationStatus;
  if (extraDetailsJson.funds_secured_cancellation_status) {
    cancellationStatus = {
      buyer: extraDetailsJson.funds_secured_cancellation_status.buyer_approved,
      seller: extraDetailsJson.funds_secured_cancellation_status.seller_approved,
    };
  }

  return {
    id: transactionJson.id,
    title: transactionJson.description,
    currency: formatCurrency(transactionJson.currency),
    inspectionDays: Math.ceil(inspectionPeriod / 86400),
    escrowFeePayers: Array.from(escrowFeePayers),
    dnsManager: dnsManager,
    concierge: concierge,
    titleCollection: titleCollection,
    lienHolder: lienHolder,
    shippingFeePayer: shippingFeePayerEmail ? emailToRoles[shippingFeePayerEmail][0] : undefined,
    shippingFeeBeneficiary: shippingFeeBeneficiaryEmail
      ? emailToRoles[shippingFeeBeneficiaryEmail][0]
      : undefined,
    shippingType: shippingType,
    brokerFeePayer: brokerFeePayerEmail ? emailToRoles[brokerFeePayerEmail][0] : undefined,
    brokerFee: brokerFee,
    brokerFeeHiddenFrom: brokerFeeHiddenFrom,
    transactionItems: transactionItems,
    partyHiddenFrom: partyHiddenFrom,
    parties: parties,
    arbitration: arbitration,
    combineBrokerCommissionWithEscrowFee:
      extraDetailsJson.combine_broker_commission_with_escrow_fee,
    commissionType: extraDetailsJson.commission_type,
    waiveEscrowFee: extraDetailsJson.waive_escrow_fee,
    waiveMilestoneItemDisbursementFee: extraDetailsJson.waive_milestone_item_disbursement_fee,
    waiveOnlyEscrowFee: extraDetailsJson.waive_only_escrow_fee,
    waiveIntermediaryFee: extraDetailsJson.waive_intermediary_fee,
    waiveIntermediaryFeeV4API: extraDetailsJson.waive_intermediary_fee_v4_api,
    fees: fees,
    taxes: taxes,
    transactionStatus: extraDetailsJson.status_code,
    transactionTypeCode: transactionItems[0].type ? transactionItems[0].type : undefined,
    inArbitration: transactionItems[0].status.in_dispute
      ? transactionItems[0].status.in_dispute
      : false,
    tier1KycRequired: extraDetailsJson.tier1_kyc_required,
    tier2KycRequired: extraDetailsJson.tier2_kyc_required,
    tier3KycRequired: extraDetailsJson.tier3_kyc_required,
    additionalDocumentsRequested: extraDetailsJson.additional_documents_requested,
    disbursementMethodRequired: extraDetailsJson.disbursement_method_required,
    shippingCompanies: extraDetailsJson.shipping_companies,
    partnerFeeLabel: extraDetailsJson.partner_fee_label,
    odometer: odometer,
    vin: vin,
    cancellationStatus: cancellationStatus,
    usesEbayAuthenticator: extraDetailsJson.uses_ebay_authenticator,
    merchantUrl: merchantUrl,
  };
}

export const getMilestoneDetails = (transactionJson) => {
  const milestoneDetailsList = [];
  for (const item of transactionJson.items) {
    if (item.type === 'milestone') {
      milestoneDetailsList.push({
        description: item.description,
        inspectionPeriod: item.inspection_period,
        amount: item.schedule.reduce((sum, schedule) => sum + Number(schedule.amount), 0),
        status: item.status,
        deliveryType: item.shipping_type ? item.shipping_type : undefined,
      });
    }
  }
  return milestoneDetailsList;
};

export const getDNHPaymentSchedule = (transactionJson) => {
  let paymentSchedule = [];
  for (const item of transactionJson.items) {
    if (item.type === 'domain_name_holding') {
      const itemPaymentSchedule = item.schedule
        .filter((schedule) => schedule.due_date !== null)
        .map((schedule) => ({
          amount: parseAmount(schedule.amount),
          dueDate: schedule.due_date === null ? undefined : schedule.due_date,
          paidDate: schedule.paid_date === null ? undefined : schedule.paid_date,
        }));
      const mergedPaymentSchedule = [];
      let itemPaymentScheduleIdx = 0;
      for (const payment of paymentSchedule) {
        // Note the date are ordered by string comparison, not time
        // This is fine because any consistant ordering will work
        while (
          itemPaymentScheduleIdx < paymentSchedule.length &&
          itemPaymentSchedule[itemPaymentScheduleIdx].dueDate <= payment.dueDate
        ) {
          if (itemPaymentSchedule[itemPaymentScheduleIdx].dueDate < payment.dueDate) {
            mergedPaymentSchedule.push(itemPaymentSchedule[itemPaymentScheduleIdx]);
          } else {
            payment.amount += itemPaymentSchedule[itemPaymentScheduleIdx].amount;
          }
          itemPaymentScheduleIdx++;
        }
        mergedPaymentSchedule.push(payment);
      }
      for (let i = itemPaymentScheduleIdx; i < itemPaymentSchedule.length; i++) {
        mergedPaymentSchedule.push(itemPaymentSchedule[i]);
      }
      paymentSchedule = mergedPaymentSchedule;
    }
  }
  paymentSchedule = paymentSchedule.sort((a, b) => parseDate(a.dueDate) - parseDate(b.dueDate));

  return paymentSchedule;
};

/**
 * Returns an object with the transaction fees for the current user
 *
 * @param transaction transaction from `processTransactionJson`
 * @param custEmail current user's email
 * @returns
 */
export const calculateFees = (transaction, custEmail) => {
  const {
    transactionItems,
    fees: transactionFees,
    combineBrokerCommissionWithEscrowFee,
    commissionType,
    waiveEscrowFee,
    waiveMilestoneItemDisbursementFee,
    waiveOnlyEscrowFee,
  } = transaction;
  const currentUsersFees = transactionFees[custEmail];

  if (currentUsersFees === undefined) {
    return {
      escrowFee: 0,
      shippingFee: 0,
      brokerFee: 0,
      partnerFee: 0,
      conciergeFee: 0,
      titleCollectionFee: 0,
      lienHolderPayoffFee: 0,
      disbursementFee: 0,
      processingFee: 0,
      combinedPartnerAndEscrowFee: 0,
    };
  }

  const calculatedFees = {
    escrowFee: parseAmount(currentUsersFees.escrowFee),
    shippingFee: parseAmount(currentUsersFees.shippingFee),
    brokerFee: parseAmount(currentUsersFees.brokerFee),
    partnerFee: parseAmount(currentUsersFees.partnerFee),
    conciergeFee: parseAmount(currentUsersFees.concierge),
    titleCollectionFee: parseAmount(currentUsersFees.titleCollection),
    lienHolderPayoffFee: parseAmount(currentUsersFees.lienHolderPayoff),
    disbursementFee: parseAmount(currentUsersFees.disbursement),
    processingFee: 0,
    combinedPartnerAndEscrowFee: 0,
    paymentProcessingFee: parseAmount(currentUsersFees.paymentProcessingFee),
  };

  if (combineBrokerCommissionWithEscrowFee) {
    const brokerFee = parseAmount(currentUsersFees.brokerFee);
    const escrowFee = parseAmount(currentUsersFees.escrowFee);
    const processingFee = brokerFee + escrowFee;
    return {
      ...calculatedFees,
      processingFee: processingFee,
      escrowFee: 0,
      brokerFee: 0,
    };
  }

  if (commissionType === 2) {
    const partnerFee = parseAmount(currentUsersFees.partnerFee);
    const escrowFee = parseAmount(currentUsersFees.escrowFee);
    const combinedPartnerAndEscrowFee = partnerFee + escrowFee;
    return {
      ...calculatedFees,
      combinedPartnerAndEscrowFee: combinedPartnerAndEscrowFee,
      partnerFee: 0,
      escrowFee: 0,
    };
  }

  if (waiveMilestoneItemDisbursementFee && transactionItems[0].type === 'milestone') {
    return {
      ...calculatedFees,
      disbursementFee: 0,
    };
  }

  if (waiveOnlyEscrowFee) {
    return {
      ...calculatedFees,
      escrowFee: 0,
      conciergeFee: 0,
      titleCollectionFee: 0,
      lienHolderPayoffFee: 0,
    };
  }

  if (waiveEscrowFee) {
    return {
      ...calculatedFees,
      escrowFee: 0,
      conciergeFee: 0,
      titleCollectionFee: 0,
      lienHolderPayoffFee: 0,
      disbursementFee: 0,
    };
  }

  return calculatedFees;
};
