const TwoFactorConstants = {
  // Step constants
  STEP_MAIN: 1,
  STEP_AUTH_APP: 2,
  STEP_SMS: 3,
  STEP_DONE: 4,
  STEP_DISABLE_AUTH_APP: 5,
  STEP_DISABLE_SMS: 6,
  STEP_DISABLE_DONE: 7,

  TYPE_AUTH_APP: 'authenticator',
  TYPE_SMS: 'sms',

  ACTION_SETUP: 'setup',
  ACTION_DISABLE: 'disable',
  ACTION_VERIFY: 'verify',

  GET_STATUS: 'TwoFactorConstants.GET_STATUS',
  SEND_VERIFICATION_EMAIL: 'TwoFactorConstants.SEND_VERIFICATION_EMAIL',
  SIGN_UP_SUCCESS: 'TwoFactorConstants.SIGN_UP_SUCCESS',
  ENABLE_AUTH_APP: 'TwoFactorConstants.ENABLE_AUTH_APP',
  ENABLE_SMS: 'TwoFactorConstants.ENABLE_SMS',
  DISABLE_TWO_FACTOR: 'TwoFactorConstants.DISABLE_TWO_FACTOR',
  VALIDATE: 'TwoFactorConstants.VALIDATE',
  SEND_SMS_TWO_FACTOR: 'TwoFactorConstants.SEND_SMS_TWO_FACTOR',

  VERIFY_FORM_NAME: 'two-factor-auth-config-verify-form',
  METHOD_SELECTION_FORM_NAME: 'two-factor-auth-method-selection-form',
  SEND_SMS_FORM_NAME: 'two-factor-auth-config-send-sms-form',

  CONFIG_COMPLETE_TITLE: {
    setup: {
      authenticator: 'Authenticator app verification enabled.',
      sms: 'SMS verification enabled',
    },
    disable: {
      authenticator: 'Authenticator app verification disabled.',
      sms: 'SMS verification disabled',
    },
    verify: {
      authenticator: 'Authenticator app verification successful',
      sms: 'SMS verification successful',
    },
  },

  CONFIG_COMPLETE_MSG: {
    setup: {
      authenticator: `From now on when you login to your account you'll need to enter both your
        credentials and a security code from your authenticator app.`,
      sms: `From now on when you login to your account you'll need to enter both your
        credentials and a security code from your mobile phone.`,
    },
    disable: {
      authenticator: `You have disabled the option to use an authenticator app to verify two-factor
        authentication.`,
      sms: `You have disabled the option to use SMS messages sent to your phone to verify
        two-factor authentication.`,
    },
  },
};

export default TwoFactorConstants;
