import React from 'react';
import Icon from 'spa/components/Icon';
import { isArray } from 'lodash';

const ErrorMessage = ({ message, className }) => (
  <span className={`field-error ${className}`}>{message}</span>
);

const ErrorMessageWithIcon = ({ message }) => (
  <div className="customInput-error-inner">
    <span className="customInput-error-icon">
      <Icon name="ui-warning" wrapper="span" />
    </span>
    <span className="customInput-error-msg">{message}</span>
  </div>
);

const FieldError = ({ message, showIcon }) => {
  let child;
  if (isArray(message)) {
    child = message.map((e) =>
      showIcon ? (
        <ErrorMessageWithIcon key={e} message={e} />
      ) : (
        <ErrorMessage key={e} message={e} className="field-error--bulleted" />
      )
    );
  } else {
    child = showIcon ? (
      <ErrorMessageWithIcon message={message} />
    ) : (
      <ErrorMessage message={message} />
    );
  }

  return <div>{child}</div>;
};

export default FieldError;
