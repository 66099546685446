import AdyenCheckout from '@adyen/adyen-web';
import window from 'window-shim';
import { delay } from 'redux-saga';
import { SubmissionError } from 'redux-form';
import { push } from 'connected-react-router';
import { call, put, takeLatest, take, race, select } from 'redux-saga/effects';
import { paymentsPagePlaidClientSelector } from 'spa/selectors/PaymentsPageSelectors';
import {
  checkoutTokenSelector,
  checkoutTransactionTokenSelector,
  checkoutTransactionIdSelector,
  checkoutTransIdSelector,
} from 'spa/selectors/CheckoutSelectors';
import cardValidator from 'card-validator';
import APIConstants from 'spa/constants/APIConstants';
import CheckoutConstants from 'spa/constants/CheckoutConstants';
import { returnToLogin as returnToLoginRoutine } from 'spa/actions/CheckoutActions';
import {
  setAdyenFormData,
  setAdyenAction,
  initAdyenCheckout as initAdyenCheckoutRoutine,
  getCardDetails as getCardDetailsRoutine,
  getCardVerificationDetails as getCardVerificationDetailsRoutine,
  triggerCardVerification as triggerCardVerificationRoutine,
  triggerCardVerificationForm as triggerCardVerificationFormRoutine,
  verifyCardVerification as verifyCardVerificationRoutine,
  verifyCardVerificationForm as verifyCardVerificationFormRoutine,
  saveCreditCardForm as saveCreditCardFormRoutine,
  saveCreditCard as saveCreditCardRoutine,
  getWireDetails as getWireDetailsRoutine,
  initPlaidClient as initPlaidClientRoutine,
  launchPlaidClient as launchPlaidClientRoutine,
  getLastBuyerBankEntry as getLastBuyerBankEntryRoutine,
  getCheckDetails as getCheckDetailsRoutine,
  processSuccessfulPaypalPayment as processSuccessfulPaypalPaymentRoutine,
  submitUserWireDetails as submitUserWireDetailsRoutine,
} from 'spa/actions/PaymentsActions';
import PaymentConstants from 'spa/constants/PaymentConstants';
import { adyenFormDataSelector } from 'spa/selectors/PaymentSelectors';
import { getSavedPlaidAccounts as getSavedPlaidAccountsRoutine } from 'spa/actions/UserActions';
import { logError } from 'spa/actions/ErrorLoggingActions';
import Store from '../../store';
import AuthenticationStore from '../../stores/AuthenticationStore';
import { defaultApiExceptionMessage } from '../../utils/error';
import { gettext } from '../../utils/filters';
import API from '../../api';
import config from '../../utils/config';
import ErrorMessages from '../../constants/ErrorMessages';

export function* getCardDetailsSaga(action) {
  const { transId, cardId } = action.payload;

  try {
    const cardVerificationDetails = yield call(
      API.getCardVerificationDetailsByTransactionId,
      transId
    );

    if (cardVerificationDetails.date_verified) {
      const url = `${window.config.www_base_url}/transactions`;
      window.location.href = url;
    } else if (cardVerificationDetails.date_initiated) {
      const url = `/card-verification/verify?card-id=${cardId}&trans-id=${transId}&card-verification-id=${cardVerificationDetails.id}`;
      window.location.href = url;
    } else {
      const {
        credit_card_information: { bin, last_four_digits },
      } = cardVerificationDetails;
      const payload = {
        cardId,
        bin,
        last_four_digits,
      };

      yield put(getCardDetailsRoutine.success(payload));
    }
  } catch (error) {
    if (error.type === APIConstants.FORBIDDEN) {
      const url = `${window.config.www_base_url}/transactions`;
      window.location.href = url;
    } else {
      yield put(getCardDetailsRoutine.failure({ cardId }));
    }
  }
}

export function* triggerChargeSaga(action) {
  const { transId, cardId } = action.payload;

  try {
    const result = yield call(API.chargeVerificationAmounts, cardId, transId);
    yield put(
      triggerCardVerificationRoutine.success({ cardId, transId, cardVerificationId: result.id })
    );
  } catch (error) {
    yield put(triggerCardVerificationRoutine.failure());
  }
  yield put(triggerCardVerificationRoutine.fulfill());
}

export function* triggerChargeFormSaga(action) {
  const formData = action.payload.values;
  const cardId = formData.cardId;
  const transId = formData.transId;

  try {
    yield put(triggerCardVerificationRoutine.trigger({ cardId, transId }));
    const { apiRequestSuccess, apiRequestFailure } = yield race({
      apiRequestFailure: take(triggerCardVerificationRoutine.FAILURE),
      apiRequestSuccess: take(triggerCardVerificationRoutine.SUCCESS),
    });
    if (apiRequestFailure) {
      const submissionError = new SubmissionError({ _error: 'Failed to charge card' });
      throw submissionError;
    }

    yield put(triggerCardVerificationFormRoutine.success());
    const cardVerificationId = apiRequestSuccess.payload.cardVerificationId;
    const url = `/card-verification/verify?card-id=${cardId}&trans-id=${transId}&card-verification-id=${cardVerificationId}`;
    yield put(push(url));
  } catch (error) {
    yield put(triggerCardVerificationFormRoutine.failure(error));
  }
  yield put(triggerCardVerificationFormRoutine.fulfill());
}

export function* getCardVerificationDetailsSaga(action) {
  const { cardVerificationId } = action.payload;

  try {
    const chargeDetails = yield call(API.getCardVerificationDetails, cardVerificationId);

    if (chargeDetails.attempts_left < 1) {
      throw new Error();
    }

    yield put(getCardVerificationDetailsRoutine.success(chargeDetails));
  } catch (error) {
    if (error.type === APIConstants.FORBIDDEN) {
      const url = `${window.config.www_base_url}/transactions`;
      window.location.href = url;
    } else {
      yield put(
        getCardVerificationDetailsRoutine.failure({ id: cardVerificationId, attempts_left: 0 })
      );
    }
  }

  yield put(getCardVerificationDetailsRoutine.fulfill());
}

export function* verifyCardVerificationSaga(action) {
  const { transId, cardId, firstCharge, secondCharge } = action.payload;

  try {
    const result = yield call(
      API.verifyCardVerificationAmounts,
      cardId,
      transId,
      firstCharge,
      secondCharge
    );

    yield put(verifyCardVerificationRoutine.success(result));
  } catch (error) {
    yield put(verifyCardVerificationRoutine.failure());
  }
  yield put(verifyCardVerificationRoutine.fulfill());
}

export function* verifyCardVerificationFormSaga(action) {
  const formData = action.payload.values;
  const { cardId, transId, cardVerificationId, firstCharge, secondCharge } = formData;

  try {
    yield put(
      verifyCardVerificationRoutine.trigger({ cardId, transId, firstCharge, secondCharge })
    );
    const { apiRequestSuccess, apiRequestFailure } = yield race({
      apiRequestFailure: take(verifyCardVerificationRoutine.FAILURE),
      apiRequestSuccess: take(verifyCardVerificationRoutine.SUCCESS),
    });
    if (apiRequestFailure) {
      const submissionError = new SubmissionError({ _error: 'Verification Failed' });
      throw submissionError;
    }
    if (!apiRequestSuccess.payload.is_successful) {
      const submissionError = new SubmissionError({ _error: 'Incorrect charges.' });
      throw submissionError;
    }

    yield put(verifyCardVerificationFormRoutine.success());
    const url = `/card-verification/success?trans-id=${transId}`;
    yield put(push(url));
  } catch (error) {
    yield put(getCardVerificationDetailsRoutine.trigger({ cardId, cardVerificationId }));
    yield race([
      take(getCardVerificationDetailsRoutine.SUCCESS),
      take(getCardVerificationDetailsRoutine.FAILURE),
    ]);

    yield put(verifyCardVerificationFormRoutine.failure(error));
  }
  yield put(verifyCardVerificationFormRoutine.fulfill());
}

export function* saveNewCreditCardSaga(action) {
  const { customerID, formData } = action.payload;
  const cardData = cardValidator.number(formData.cardNumber);
  const cardTypeLookup = {
    visa: 'Visa',
    'master-card': 'Mastercard',
    'american-express': 'American Express',
  };

  const payload = {
    card_number: formData.cardNumber.replace(/ /g, ''),
    cvv: formData.securityCode.trim(),
    expiry_date: {
      month: formData.expirationMonth.trim(),
      year: formData.expirationYear.trim(),
    },
    name_on_card: formData.nameOnCard,
    brand: cardTypeLookup[cardData.card.type],
    bill_to: formData.billTo,
  };

  try {
    const result = yield call(API.saveCreditCard, customerID, payload);

    yield put(saveCreditCardRoutine.success(result));
  } catch (error) {
    const errorMessage = defaultApiExceptionMessage(
      error,
      gettext('Failed to save your credit card details.')
    );
    yield put(saveCreditCardRoutine.failure(errorMessage));
  }
  yield put(saveCreditCardRoutine.fulfill());
}

export function* saveAdyenCreditCard() {
  const adyenData = yield select(adyenFormDataSelector);
  const customerId = AuthenticationStore.getCustomerID();

  let _error;
  let response = {};
  try {
    response = yield call(API.saveAdyenCreditCard, customerId, adyenData);
  } catch (error) {
    if (error.type === APIConstants.BAD_REQUEST) {
      _error = `We currently do not support the card type you have chosen. Please try a Visa, Mastercard or AMEX card. Please see ${window.config.www_base_url}/learn-more/how-escrow-payments-work/credit-card for more information.`;
    }
  }
  const { resultCode, refusalReason, action: additionalAction } = response;
  if (additionalAction) {
    yield put(setAdyenAction({ action: additionalAction }));
    return;
  }
  switch (resultCode) {
    case 'Authorised':
    case 'Pending':
    case 'Received':
      return;
    case 'Error':
      _error = `The following error occured while processing your payment: ${refusalReason}`;
      break;
    case 'Refused':
      _error = 'Your payment was refused.  Please try again with a different payment method.';
      break;
    default:
      if (!_error) {
        _error = 'An unexpected error occurred.  Please contact support@escrow.com';
      }
  }
  throw new Error(_error);
}

export function* saveNewCreditCardFormSaga(action) {
  const customerID = AuthenticationStore.getCustomerID();
  const formData = action.payload.values;
  const paymentGateway = window.js_context.credit_card_gateway;

  try {
    if (paymentGateway === PaymentConstants.CREDIT_CARD_GATEWAY.ADYEN) {
      yield* saveAdyenCreditCard();
    } else {
      yield put(saveCreditCardRoutine.trigger({ customerID, formData }));
      const { apiRequestFailure } = yield race({
        apiRequestFailure: take(saveCreditCardRoutine.FAILURE),
        apiRequestSuccess: take(saveCreditCardRoutine.SUCCESS),
      });

      if (apiRequestFailure) {
        throw new Error(apiRequestFailure.payload);
      }
    }

    yield put(saveCreditCardFormRoutine.success());
    const url = `/account-info`;
    window.location.href = url;
  } catch (error) {
    const submissionError = new SubmissionError({
      _error: error.message || gettext('Failed to save your credit card details.'),
    });
    yield put(saveCreditCardFormRoutine.failure(submissionError));
  }
  yield put(saveCreditCardFormRoutine.fulfill());
}

export function* initAdyenCheckout() {
  try {
    const paymentMethods = yield call(API.getUserAdyenMethods);
    const checkout = new AdyenCheckout({
      locale: 'en_US',
      environment: config.adyen_env,
      clientKey: config.adyen_client_key,
      paymentMethodsResponse: paymentMethods,
      onChange: (state) => Store.dispatch(setAdyenFormData(state)),
      onAdditionalDetails: (state) => Store.dispatch(setAdyenFormData(state)),
    });
    yield put(
      initAdyenCheckoutRoutine.success({
        paymentMethods,
        checkout,
      })
    );
  } catch (error) {
    let errorMessage = ErrorMessages.TECHNICAL_DIFFICULTIES;
    if (error.type === APIConstants.BAD_REQUEST) {
      errorMessage = error.errors.error;
    } else if (error.type === APIConstants.FORBIDDEN) {
      errorMessage = error.error;
    } else if (error.type === APIConstants.UNPROCESSABLE_ENTITY) {
      errorMessage = `An error occurred in the format of the request.
        ${ErrorMessages.ASSISTANT_MESSAGE_FOLLOWED_BY_REQUEST_ID} ${error.requestId}`;
    } else if (error.type === APIConstants.INTERNAL_SERVER_ERROR) {
      errorMessage = error.errors.error;
    } else if (error.type === APIConstants.UNHANDLED) {
      errorMessage = error.errors.error;
    }

    yield put(initAdyenCheckoutRoutine.failure({ errorMessage }));
  }
  yield put(initAdyenCheckoutRoutine.fulfill());
}

export function* launchPlaidClient() {
  try {
    const handler = yield select(paymentsPagePlaidClientSelector);
    if (handler) {
      handler.open();
    }
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}

export function* initPlaidClient(action) {
  let plaidToken = {};
  try {
    const Plaid = window.Plaid;
    const { accountId, onSuccess } = action.payload || {};
    plaidToken = yield call(API.getPlaidLinkToken, accountId);
    localStorage.setItem('plaid_link_token', plaidToken.link_token);

    const token = yield select(checkoutTokenSelector);
    const ttoken = yield select(checkoutTransactionTokenSelector);
    if (!token && !ttoken) {
      const tid = yield select(checkoutTransIdSelector);
      const source = 'payment';
      localStorage.setItem('checkout_query', JSON.stringify({ tid, source }));
    } else {
      const tid = yield select(checkoutTransactionIdSelector);
      localStorage.setItem('checkout_query', JSON.stringify({ token, ttoken, tid }));
    }

    const handler = Plaid.create({
      token: plaidToken.link_token,
      onSuccess: async function success(publicToken, metadata) {
        if (!accountId) {
          Store.dispatch(getSavedPlaidAccountsRoutine.request());
          const savedAccounts = await API.getPlaidAccessToken(
            publicToken,
            metadata.accounts,
            metadata.institution
          );
          const selectedAccount = savedAccounts.filter(
            (savedAccount) => savedAccount.plaid_account_id === metadata.accounts[0].id
          );
          if (selectedAccount.length > 0 && onSuccess) {
            action.payload.onSuccess(selectedAccount[0]);
          }
          Store.dispatch(
            getSavedPlaidAccountsRoutine.success({ savedPlaidAccounts: savedAccounts })
          );
        }
        Store.dispatch(launchPlaidClientRoutine.success());
      },
      onExit: async function exit(err) {
        Store.dispatch(logError(err));
        Store.dispatch(launchPlaidClientRoutine.failure(err));
      },
    });
    yield put(initPlaidClientRoutine.success({ handler }));
  } catch (error) {
    let errorMsg = '';
    if (error.type === APIConstants.UNAUTHORIZED) {
      yield put(returnToLoginRoutine());
    } else if (error.type === APIConstants.FORBIDDEN) {
      errorMsg =
        'Escrow.com can not process funds from your selected bank/region. Please select another bank.';
    } else if (error.type === APIConstants.BAD_REQUEST) {
      return;
    } else {
      yield put(logError(error));
      errorMsg = APIConstants.GENERIC_API_ERROR_MESSAGE;
    }
    yield put(initPlaidClientRoutine.failure({ error: errorMsg }));
  }
}

export function* getWireDetails(action) {
  let wireData = {};
  try {
    wireData = yield call(API.getWireDetails, action.payload.transId);
    yield put(getWireDetailsRoutine.success(wireData));
  } catch (error) {
    let errorMsg = '';
    if (error.type === APIConstants.UNAUTHORIZED) {
      yield put(returnToLoginRoutine());
    } else if (error.type === APIConstants.FORBIDDEN) {
      errorMsg =
        'Escrow.com can not process funds from your selected bank/region. Please select another source to wire the funds into escrow.';
    } else if (error.type === APIConstants.BAD_REQUEST) {
      return;
    } else {
      yield put(logError(error));
      errorMsg = APIConstants.GENERIC_API_ERROR_MESSAGE;
    }
    yield put(getWireDetailsRoutine.failure({ errorMessage: errorMsg }));
  }
}

export function* getCheckDetails(action) {
  let checkData = null;
  try {
    const transaction = yield call(API.getTransactionById, action.payload.transId);
    if (transaction.currency === 'usd') {
      checkData = yield call(API.getCheckDetails, action.payload.transId);
    }
    yield put(getCheckDetailsRoutine.success(checkData));
  } catch (error) {
    yield put(getCheckDetailsRoutine.failure());
    yield put(logError(error));
  }
}

export function* getLastBuyerBankEntry(action) {
  try {
    const buyerBankEntry = yield call(API.getLastBuyerBankEntry, action.payload.customerId);
    yield put(getLastBuyerBankEntryRoutine.success(buyerBankEntry));
  } catch (error) {
    if (error.type === APIConstants.UNAUTHORIZED) {
      yield put(returnToLoginRoutine());
    } else {
      yield put(logError(error));
    }
    yield put(getLastBuyerBankEntryRoutine.failure());
  }
}

export function* processSuccessfulPaypalPayment(action) {
  const { transactionId, returnUrl, redirectType, paypalRef } = action.payload;
  try {
    yield call(API.setPaymentMethod, transactionId, 'paypal', {});
    yield call(API.paypalSuccess, transactionId, paypalRef);

    const partnerData = yield call(API.getTransactionPartner, transactionId);
    yield put(
      processSuccessfulPaypalPaymentRoutine.success(partnerData ? partnerData.partner : null)
    );

    if (!redirectType || redirectType === CheckoutConstants.REDIRECT_TYPES.automatic) {
      yield delay(3000);
      yield window.location.assign(
        returnUrl || `${window.config.www_base_url}/transaction/${transactionId}`
      );
    }
  } catch (error) {
    let errorMessage = ErrorMessages.TECHNICAL_DIFFICULTIES;
    if (error.type === APIConstants.BAD_REQUEST) {
      errorMessage = error.errors.error;
    } else if (error.type === APIConstants.UNHANDLED) {
      errorMessage = error.errors.error;
    } else {
      errorMessage = error.errors.error;
    }

    yield put(processSuccessfulPaypalPaymentRoutine.failure(errorMessage));
    yield put(logError(error));
  }
}

export function* submitUserWireDetailsMethod(action) {
  yield put(submitUserWireDetailsRoutine.request());

  try {
    const {
      transId,
      accountName,
      bankName,
      bankCountry,
      bankState,
      thirdPartyCheckbox,
      thirdPartyEmail,
    } = action.payload.values;
    const requestBody = {
      account_name: accountName,
      bank_name: bankName,
      bank_country: bankCountry,
      bank_state: bankState || undefined,
      third_party_email: (thirdPartyCheckbox && thirdPartyEmail) || undefined,
    };

    yield call(API.submitBuyerBankDetails, transId, requestBody);
    yield put(getWireDetailsRoutine.trigger({ transId: transId }));
    yield put(submitUserWireDetailsRoutine.success());
  } catch (error) {
    let errorMsg = ErrorMessages.TECHNICAL_DIFFICULTIES;

    if (error.type === APIConstants.BAD_REQUEST) {
      errorMsg = error.errors.error;
    } else if (error.type === APIConstants.INTERNAL_SERVER_ERROR) {
      errorMsg = error.errors.error;
    } else if (error.type === APIConstants.UNHANDLED) {
      errorMsg = error.errors.error;
    } else if (error.type === APIConstants.UNAUTHORIZED) {
      yield put(returnToLoginRoutine());
    } else if (error.type === APIConstants.UNPROCESSABLE_ENTITY && error.errors.third_party_email) {
      errorMsg = error.errors.third_party_email[0];
    }

    yield put(submitUserWireDetailsRoutine.failure({ errorMessage: errorMsg }));
  }
  yield put(submitUserWireDetailsRoutine.fulfill());
}

export function* paymentWatcher() {
  yield takeLatest(getCardDetailsRoutine.TRIGGER, getCardDetailsSaga);
  yield takeLatest(triggerCardVerificationRoutine.TRIGGER, triggerChargeSaga);
  yield takeLatest(triggerCardVerificationFormRoutine.TRIGGER, triggerChargeFormSaga);
  yield takeLatest(getCardVerificationDetailsRoutine.TRIGGER, getCardVerificationDetailsSaga);
  yield takeLatest(verifyCardVerificationFormRoutine.TRIGGER, verifyCardVerificationFormSaga);
  yield takeLatest(verifyCardVerificationRoutine.TRIGGER, verifyCardVerificationSaga);
  yield takeLatest(saveCreditCardFormRoutine.TRIGGER, saveNewCreditCardFormSaga);
  yield takeLatest(saveCreditCardRoutine.TRIGGER, saveNewCreditCardSaga);
  yield takeLatest(getWireDetailsRoutine.TRIGGER, getWireDetails);
  yield takeLatest(initPlaidClientRoutine.TRIGGER, initPlaidClient);
  yield takeLatest(launchPlaidClientRoutine.TRIGGER, launchPlaidClient);
  yield takeLatest(getLastBuyerBankEntryRoutine.TRIGGER, getLastBuyerBankEntry);
  yield takeLatest(getCheckDetailsRoutine.TRIGGER, getCheckDetails);
  yield takeLatest(processSuccessfulPaypalPaymentRoutine.TRIGGER, processSuccessfulPaypalPayment);
  yield takeLatest(submitUserWireDetailsRoutine.TRIGGER, submitUserWireDetailsMethod);
  yield takeLatest(initAdyenCheckoutRoutine.TRIGGER, initAdyenCheckout);
}

export default [paymentWatcher];
