import window from 'window-shim';

export const fetchRequest = (method, url, body, headers = {}, credentials = true) => {
  const payload = {
    method,
    headers,
    credentials: credentials ? 'include' : 'omit',
  };
  if (body) {
    payload.body = JSON.stringify(body);
  }
  return window.fetch(url, payload);
};

export const fetchFileRequest = (method, url, files, headers = {}, credentials = true) => {
  const payload = {
    method,
    headers,
    credentials: credentials ? 'include' : 'omit',
  };
  if (files) {
    const formData = new FormData();
    Object.entries(files).forEach(([key, file]) => formData.append(key, file));
    payload.body = formData;
  }
  return window.fetch(url, payload);
};

export const getJSON = (url, headers = {}, credentials = true) => {
  const jsonHeaders = {
    'Content-Type': 'application/json',
    ...headers,
  };
  return fetchRequest('GET', url, null, jsonHeaders, credentials);
};

export const postJSON = (url, body = null, headers = {}, credentials = true) => {
  const jsonHeaders = {
    'Content-Type': 'application/json',
    ...headers,
  };
  return fetchRequest('POST', url, body, jsonHeaders, credentials);
};

export const patchJSON = (url, body = null, headers = {}, credentials = true) => {
  const jsonHeaders = {
    'Content-Type': 'application/json',
    ...headers,
  };
  return fetchRequest('PATCH', url, body, jsonHeaders, credentials);
};

export const putJSON = (url, body = null, headers = {}, credentials = true) => {
  const jsonHeaders = {
    'Content-Type': 'application/json',
    ...headers,
  };
  return fetchRequest('PUT', url, body, jsonHeaders, credentials);
};

export const deleteJSON = (url, body = null, headers = {}, credentials = true) => {
  const jsonHeaders = {
    'Content-Type': 'application/json',
    ...headers,
  };
  return fetchRequest('DELETE', url, body, jsonHeaders, credentials);
};

export const postForm = (url, body = null, options = {}) =>
  window.fetch(url, {
    body,
    method: 'POST',
    credentials: 'include',
    ...options,
  });

export default {
  fetchRequest,
  postJSON,
  patchJSON,
};
