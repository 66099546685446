import React from 'react';
import PropTypes from 'prop-types';
import { gettext } from '../../../utils/filters';

const AccountType = (props) => {
  const e2e = props['data-e2e-target'];
  return (
    <div className="verifyPage-card">
      <header className="verifyPage-card-header">
        <h2 className="verifyPage-card-header-title">{props.title}</h2>
        <div className="verifyPage-card-header-actions">
          <div className="verifyPage-switcher">
            <strong className="verifyPage-switcher-item">
              {props.isCompany ? gettext('Company') : gettext('Individual')}
            </strong>
            {props.changeAccountTypeOnClick && props.changeAccountTypeLabel && (
              <a
                href="#"
                role="button"
                className="verifyPage-switcher-item"
                onClick={props.changeAccountTypeOnClick}
                data-tracking-name="edit"
                data-e2e-target={e2e ? `${e2e}-change-button` : null}
              >
                {props.changeAccountTypeLabel}
              </a>
            )}
          </div>
        </div>
      </header>
    </div>
  );
};

AccountType.propTypes = {
  isCompany: PropTypes.bool.isRequired,

  changeAccountTypeOnClick: PropTypes.func,
  changeAccountTypeLabel: PropTypes.string,

  title: PropTypes.string,
};

export default AccountType;
