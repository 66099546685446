import window from 'window-shim';

import AppDispatcher from '../dispatcher/AppDispatcher';
import UserConstants from '../constants/UserConstants';

export class UserActions {
  _get(url) {
    return window.fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
  }

  getKycStatus(customerID) {
    this._get(`${window.config.kyc_status_api_endpoint}/${customerID}`).then((response) => {
      response.json().then((jsonData) => {
        AppDispatcher.handleServerAction({
          actionType: UserConstants.KYC_STATUS_FETCH,
          data: jsonData,
          customerID: customerID,
        });
      });
    });
  }

  getCustomerData(customerID) {
    const startAPICall = () => {
      this._get(`${window.config.customer_api_endpoint}/${customerID}`)
        .then((response) => {
          response
            .json()
            .then((jsonData) => {
              if (!response.ok) {
                return Promise.reject(jsonData);
              }
              AppDispatcher.handleServerAction({
                actionType: UserConstants.CUSTOMER_DATA_FETCH,
                data: jsonData,
                customerID: customerID,
              });
            })
            .catch((error) => {
              AppDispatcher.handleServerAction({
                actionType: UserConstants.CUSTOMER_DATA_FETCH_FAILURE,
                error,
              });
            });
        })
        .catch((error) => {
          AppDispatcher.handleServerAction({
            actionType: UserConstants.CUSTOMER_DATA_FETCH_FAILURE,
            error,
          });
        });
    };
    const intervalID = window.setInterval(() => {
      if (!AppDispatcher.isDispatching()) {
        window.clearInterval(intervalID);
        AppDispatcher.handleViewAction({
          actionType: UserConstants.CUSTOMER_DATA_FETCH_TRIGGER,
        });
        startAPICall();
      }
    }, 10);
  }

  checkIfCustDetailsUpdateRequired(customerID) {
    this._get(window.config.address_api_endpoint).then((response) => {
      response.json().then((jsonData) => {
        AppDispatcher.handleServerAction({
          actionType: UserConstants.CUSTOMER_DETAILS_UPDATE_REQUIRED,
          needsUpdate: jsonData.UpdateRequired,
          customerID: customerID,
        });
      });
    });
  }
}

export default new UserActions();
