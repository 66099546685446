import Fuse from 'fuse.js';

import AppDispatcher from '../dispatcher/AppDispatcher';
import CategorySearchConstants from '../constants/CategorySearchConstants';
import { ChangeEmitter } from '../utils/ChangeEmitter';
import StartTransactionConstants from '../constants/StartTransactionConstants';

function getFuzzySearch(transactionCategories) {
  return new Fuse(transactionCategories, {
    keys: ['label'],
    threshold: 0.4,
    includeScore: true,
  });
}

export class CategorySearchStore extends ChangeEmitter {
  constructor() {
    super();

    this.searchQuery = '';
    this.transactionType = null;
    this.categoryValue = null;
    this.customCategoryValue = null;
    this.isProhibited = false;

    this.categories = {};
    this.categories[StartTransactionConstants.GENERAL_TRANSACTION_TYPE] =
      CategorySearchConstants.GENERAL_TRANSACTION_CATEGORIES;
    this.categories[StartTransactionConstants.VEHICLE_TRANSACTION_TYPE] =
      CategorySearchConstants.VEHICLE_TRANSACTION_CATEGORIES;

    this.fuzzySearch = {};
    this.fuzzySearch[StartTransactionConstants.GENERAL_TRANSACTION_TYPE] = getFuzzySearch(
      CategorySearchConstants.GENERAL_TRANSACTION_CATEGORIES
    );
    this.fuzzySearch[StartTransactionConstants.VEHICLE_TRANSACTION_TYPE] = getFuzzySearch(
      CategorySearchConstants.VEHICLE_TRANSACTION_CATEGORIES
    );
  }

  /**
   * @param  {StartTransactionConstants} transactionType
   * @return {Array} Matching category objects
   */
  filterCategories(transactionType) {
    this.transactionType = transactionType;
    if (!this.categories[transactionType] || !this.fuzzySearch[transactionType]) {
      const error = {
        name: 'NotImplementedError',
        message: 'transactionType not implemented with CategorySearchStore',
      };
      throw error;
    }
    if (!this.searchQuery) {
      return this.categories[transactionType];
    }
    const fuzzyResults = this.fuzzySearch[transactionType].search(this.searchQuery);
    return fuzzyResults.map((result) => result.item);
  }

  /**
   * @return {String} Category value which most closely matches the search
   * query. If there are no matches below the threshold value, this function
   * returns null.
   */
  _closestMatchValue() {
    const fuzzyResults = this.fuzzySearch[this.transactionType].search(this.searchQuery);
    if (fuzzyResults.length === 0) {
      return null;
    }
    return fuzzyResults[0].item.value;
  }

  /**
   * @return {double} The score of the category which most closely matches the
   * search query (lower score indicates stronger match). If there are no
   * matches below the threshold value, this function returns null.
   */
  _closestMatchScore() {
    const fuzzyResults = this.fuzzySearch[this.transactionType].search(this.searchQuery);
    if (fuzzyResults.length === 0) {
      return null;
    }
    return fuzzyResults[0].score;
  }

  _handleCategoryChange() {
    if (this._closestMatchScore() === 0) {
      this.categoryValue = this._closestMatchValue();
      this.customCategoryValue = null;
      this.isProhibited = CategorySearchConstants.PROHIBITED_CATEGORIES.includes(
        this.categoryValue
      );
    } else {
      this.categoryValue = this._closestMatchValue();
      this.customCategoryValue = this.searchQuery;
      this.isProhibited = false;
    }
  }

  handleViewAction(action) {
    const actionType = action.actionType;
    if (actionType === CategorySearchConstants.SEARCH_FIELD_CHANGED) {
      this.searchQuery = action.value.trim();
      this._handleCategoryChange();
      this.emitChange();
    }
    if (actionType === CategorySearchConstants.SELECT_CATEGORY) {
      this.searchQuery = action.value.trim();
      this._handleCategoryChange();
      this.emitChange();
    }
  }
}

const categorySearchStore = new CategorySearchStore();
categorySearchStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;
  const source = payload.source;

  if (source === 'VIEW_ACTION') {
    categorySearchStore.handleViewAction(action);
  }
});

export default categorySearchStore;
