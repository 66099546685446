import { Dispatcher } from 'flux';

class AppDispatcher extends Dispatcher {
  handleViewAction(action) {
    this.dispatch({
      source: 'VIEW_ACTION',
      action: action,
    });
  }

  handleServerAction(action) {
    this.dispatch({
      source: 'SERVER_ACTION',
      action: action,
    });
  }

  registerStore(store) {
    function handleAction(payload) {
      if (payload.source === 'VIEW_ACTION' && store.handleViewAction) {
        store.handleViewAction(payload.action);
      }
      if (payload.source === 'SERVER_ACTION' && store.handleServerAction) {
        store.handleServerAction(payload.action);
      }
    }

    return this.register(handleAction);
  }
}

export default new AppDispatcher();
