import { urlFor } from '../../../routeConfig';

const config = [
  {
    sectionTitle: '',
    items: [
      {
        label: 'Getting Started',
        link: {
          type: 'app',
          route: urlFor('integrations_getstarted'),
        },
        iconPath: 'ui-started',
      },
      {
        label: 'Dashboard',
        link: {
          type: 'internal',
          route: urlFor('partner_dashboard'),
          newTab: true,
        },
        iconPath: 'ui-dashboard',
      },
    ],
  },
  {
    sectionTitle: 'My Integrations',
    items: [
      {
        label: 'API',
        link: {
          type: 'app',
          route: urlFor('integrations_api_getstarted'),
        },
        iconPath: 'ui-code',
        items: [
          {
            label: 'API Keys',
            link: {
              type: 'app',
              route: urlFor('integrations_api'),
            },
          },
          {
            label: 'Webhooks',
            link: {
              type: 'app',
              route: urlFor('integrations_webhooks'),
            },
          },
        ],
      },
      {
        label: 'Buttons',
        link: {
          type: 'app',
          route: urlFor('integrations_buttons_getstarted'),
        },
        iconPath: 'ui-buttons',
      },
      {
        label: 'Referral Links',
        link: {
          type: 'app',
          route: urlFor('integrations_referrals_getstarted'),
        },
        iconPath: 'ui-links',
        items: [
          {
            label: 'Banners',
            link: {
              type: 'app',
              route: urlFor('integrations_referrals_banners'),
            },
          },
        ],
      },
      {
        label: 'Partner Logo',
        link: {
          type: 'app',
          route: urlFor('integrations_logos_getstarted'),
        },
        iconPath: 'ui-users',
      },
    ],
  },
  {
    sectionTitle: 'Support',
    authRequired: false,
    displaySectionTitle: ({ authenticated }) => authenticated,
    items: [
      {
        label: 'Escrow API Documentation',
        link: {
          type: 'app',
          route: urlFor('api_guide_getting_started'),
        },
        iconPath: 'ui-document',
        items: [
          {
            label: 'Escrow API Basics',
            link: {
              type: 'app',
              route: urlFor('api_guide_basics'),
            },
          },
          {
            label: 'Create a customer',
            link: {
              type: 'app',
              route: urlFor('api_guide_create_customer'),
            },
          },
          {
            label: 'Update a customer',
            link: {
              type: 'app',
              route: urlFor('api_guide_update_customer'),
            },
          },
          {
            label: 'Create a transaction',
            link: {
              type: 'app',
              route: urlFor('api_guide_create_transaction'),
            },
          },
          {
            label: 'Fetch transaction details',
            link: {
              type: 'app',
              route: urlFor('api_guide_fetch_transaction'),
            },
          },
          {
            label: 'Listing your transactions',
            link: {
              type: 'app',
              route: urlFor('api_guide_list_transactions'),
            },
          },
          {
            label: 'Listing partner transactions',
            link: {
              type: 'app',
              route: urlFor('api_guide_list_partner_transactions'),
            },
          },
          {
            label: 'Agree to a transaction',
            link: {
              type: 'app',
              route: urlFor('api_guide_agree_transaction'),
            },
          },
          {
            label: 'Funding a transaction',
            link: {
              type: 'app',
              route: urlFor('api_guide_fund_transaction'),
            },
          },
          {
            label: 'Shipping a transaction',
            link: {
              type: 'app',
              route: urlFor('api_guide_ship_transaction'),
            },
          },
          {
            label: 'Cancelling a transaction',
            link: {
              type: 'app',
              route: urlFor('api_guide_cancel_transaction'),
            },
          },
          {
            label: 'Disbursing a transaction',
            link: {
              type: 'app',
              route: urlFor('api_guide_disburse_transaction'),
            },
          },
          {
            label: 'Receiving items in a transaction',
            link: {
              type: 'app',
              route: urlFor('api_guide_receive_items'),
            },
          },
          {
            label: 'Accepting items in a transaction',
            link: {
              type: 'app',
              route: urlFor('api_guide_accept_items'),
            },
          },
          {
            label: 'Rejecting items in a transaction',
            link: {
              type: 'app',
              route: urlFor('api_guide_reject_items'),
            },
          },
          {
            label: 'Returning items in a transaction',
            link: {
              type: 'app',
              route: urlFor('api_guide_return_items'),
            },
          },
          {
            label: 'Receiving returned items in a transaction',
            link: {
              type: 'app',
              route: urlFor('api_guide_receive_returned_items'),
            },
          },
          {
            label: 'Accepting returned items in a transaction',
            link: {
              type: 'app',
              route: urlFor('api_guide_accept_returned_items'),
            },
          },
          {
            label: 'Rejecting returned items in a transaction',
            link: {
              type: 'app',
              route: urlFor('api_guide_reject_returned_items'),
            },
          },
          {
            label: 'Using Webhooks',
            link: {
              type: 'app',
              route: urlFor('api_guide_webhooks'),
            },
          },
          {
            label: 'Escrow API Reference',
            link: {
              type: 'internal',
              route: '/api/docs/reference',
              newTab: true,
            },
          },
        ],
      },
      {
        label: 'Escrow Offer',
        link: {
          type: 'app',
          route: urlFor('escrow_offer_docs'),
        },
        iconPath: 'ui-document',
        items: [
          {
            label: 'Retrieving auction tokens',
            link: {
              type: 'app',
              route: urlFor('escrow_offer_docs_fetch_auction_token'),
            },
          },
          {
            label: 'Cancelling an auction',
            link: {
              type: 'app',
              route: urlFor('escrow_offer_docs_cancel_auction'),
            },
          },
          {
            label: 'Retrieving auction details',
            link: {
              type: 'app',
              route: urlFor('escrow_offer_docs_fetch_auction'),
            },
          },
          {
            label: 'Making an offer',
            link: {
              type: 'app',
              route: urlFor('escrow_offer_docs_create_offer'),
            },
          },
          {
            label: 'Listing offers',
            link: {
              type: 'app',
              route: urlFor('escrow_offer_docs_list_offer'),
            },
          },
          {
            label: 'Cancelling an offer',
            link: {
              type: 'app',
              route: urlFor('escrow_offer_docs_cancel_offer'),
            },
          },
          {
            label: 'Accepting/Rejecting an offer',
            link: {
              type: 'app',
              route: urlFor('escrow_offer_docs_offer_action'),
            },
          },
          {
            label: 'Retreiving auction history',
            link: {
              type: 'app',
              route: urlFor('escrow_offer_docs_list_event'),
            },
          },
        ],
      },
      {
        label: 'Escrow Pay',
        link: {
          type: 'app',
          route: urlFor('escrow_pay_docs'),
        },
        iconPath: 'ui-document',
      },
      {
        label: 'Escrow ID Verify',
        link: {
          type: 'app',
          route: urlFor('escrow_id_verify_docs'),
        },
        iconPath: 'ui-document',
      },
      {
        label: 'Integration Helper',
        link: {
          type: 'app',
          route: urlFor('integration_helper_getting_started'),
        },
        iconPath: 'ui-document',
        items: [
          {
            label: 'Getting started',
            link: {
              type: 'app',
              route: urlFor('integration_helper_getting_started'),
            },
          },
          {
            label: 'Approving payments for a transaction',
            link: {
              type: 'app',
              route: urlFor('integration_helper_approve_payments'),
            },
          },
          {
            label: 'Verification',
            link: {
              type: 'app',
              route: urlFor('integration_helper_verification'),
            },
          },
        ],
      },
      {
        label: 'Plugins',
        link: {
          type: 'app',
          route: urlFor('plugins_getstarted'),
        },
        iconPath: 'ui-zap',
        items: [
          {
            label: 'WooCommerce',
            link: {
              type: 'app',
              route: urlFor('plugins_woocommerce'),
            },
          },
        ],
      },
      {
        label: 'FAQs',
        link: {
          type: 'app',
          route: urlFor('integrations_faqs'),
        },
        iconPath: 'ui-chat',
      },
      {
        label: 'Contact Us',
        link: {
          type: 'external',
          route: 'mailto:developers@escrow.com',
        },
        iconPath: 'ui-email',
      },
    ],
  },
  {
    sectionTitle: '',
    items: [
      {
        label: 'Account Settings',
        link: {
          type: 'internal',
          route: '/account-info',
        },
        iconPath: 'ui-cog',
      },
    ],
  },
];

export default config;
