import React from 'react';
import { connect } from 'react-redux';

import { listPartnerLogos, removePartnerLogo } from 'spa/actions/UserActions';
import {
  custIdSelector,
  partnerLogosSelector,
  partnerLogosLoadingSelector,
} from 'spa/selectors/CustomerSelectors';
import LogoUploader from 'spa/components/LogoUploader';
import { gettext } from '../../../utils/filters';

const mapStateToProps = (state) => ({
  customerId: custIdSelector(state),
  partnerLogos: partnerLogosSelector(state),
  partnerLogosLoading: partnerLogosLoadingSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  listLogos: () => dispatch(listPartnerLogos()),
  removeLogo: () => dispatch(removePartnerLogo()),
});

const note = gettext('Allowed documents: png - 1MB maximum file size.');
const extraNote = gettext('Suggested max image dimension is 260px width & 48px height.');
const fileExtensions = ['.png'];
const PartnerLogoUploader = (props) => (
  <LogoUploader note={note} extraNote={extraNote} fileExtensions={fileExtensions} {...props} />
);

export default connect(mapStateToProps, mapDispatchToProps)(PartnerLogoUploader);
