import { put, takeLatest, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { SubmissionError } from 'redux-form';
import {
  getTwoFactorStatus as getTwoFactorStatusRoutine,
  enableAuthAppTwoFactor as enableAuthAppTwoFactorRoutine,
  enableSMSTwoFactor as enableSMSTwoFactorRoutine,
  validateTwoFactor as validateTwoFactorRoutine,
  disableTwoFactor as disableTwoFactorRoutine,
  sendSMSTwoFactor as sendSMSTwoFactorRoutine,
  sendVerificationEmail as sendVerificationEmailRoutine,
} from 'spa/actions/TwoFactorActions';
import PhoneCountryCodes from 'spa/constants/PhoneCountryCodes';
import API from '../../api';
import ErrorMessages from '../../constants/ErrorMessages';
import { formatPhoneNumber } from '../../utils/parse-phone';

export function* getTwoFactorStatus() {
  try {
    const response = yield call(API.checkRegistered2fa);
    yield put(getTwoFactorStatusRoutine.success(response));
  } catch (error) {
    yield put(getTwoFactorStatusRoutine.failure(error));
  }
}

export function* enableAuthAppTwoFactor() {
  try {
    const url2fa = yield call(API.registerAuthApp2fa);
    yield put(enableAuthAppTwoFactorRoutine.success(url2fa.secret));
  } catch (error) {
    yield put(enableAuthAppTwoFactorRoutine.failure(error));
  }
}

export function* enableSMSTwoFactor(action) {
  try {
    const formData = action.payload.values;
    const phonePrefix = PhoneCountryCodes[formData.phoneCountry].countryCode;
    const phoneSuffix = formData.phoneNumber;
    const phoneNumber = formatPhoneNumber(phonePrefix, phoneSuffix);
    yield call(API.registerSMS2fa, `+${phoneNumber}`);
    yield call(API.sendSMS2fa);
    yield put(enableSMSTwoFactorRoutine.success());
    yield delay(5000);
    yield put(enableSMSTwoFactorRoutine.fulfill());
  } catch (error) {
    const reduxFormError = { _error: ErrorMessages.TWO_FACTOR_SEND_SMS_FAIL };
    yield put(enableSMSTwoFactorRoutine.failure(new SubmissionError(reduxFormError)));
  }
}

export function* disableTwoFactor(action) {
  try {
    const formData = action.payload.values;
    const formProps = action.payload.props;
    yield call(API.disable2fa, formData.code2fa, formProps.configType);
    yield put(disableTwoFactorRoutine.success());
    yield delay(5000);
    yield put(disableTwoFactorRoutine.fulfill());
  } catch (error) {
    const reduxFormError = { _error: ErrorMessages.TWO_FACTOR_CODE_FAIL };
    yield put(disableTwoFactorRoutine.failure(new SubmissionError(reduxFormError)));
  }
}

export function* validateTwoFactor(action) {
  try {
    const formData = action.payload.values;
    const formProps = action.payload.props;
    yield call(API.validate2fa, formData.code2fa, formProps.configType);
    yield put(validateTwoFactorRoutine.success());
  } catch (error) {
    const reduxFormError = { _error: ErrorMessages.TWO_FACTOR_CODE_FAIL };
    yield put(validateTwoFactorRoutine.failure(new SubmissionError(reduxFormError)));
  }
}

export function* sendSMSTwoFactor() {
  try {
    yield call(API.sendSMS2fa);
    yield put(sendSMSTwoFactorRoutine.success());
    yield delay(5000);
    yield put(sendSMSTwoFactorRoutine.fulfill());
  } catch (error) {
    const reduxFormError = { _error: ErrorMessages.TWO_FACTOR_SEND_SMS_FAIL };
    yield put(sendSMSTwoFactorRoutine.failure(new SubmissionError(reduxFormError)));
  }
}

export function* sendVerificationEmail() {
  yield put(sendVerificationEmailRoutine.request());
  try {
    yield call(API.sendVerificationEmail);
    yield put(sendVerificationEmailRoutine.success());
  } catch (error) {
    yield put(sendVerificationEmailRoutine.failure());
  }
}

export function* twoFactorWatcher() {
  yield takeLatest(getTwoFactorStatusRoutine.TRIGGER, getTwoFactorStatus);
  yield takeLatest(enableAuthAppTwoFactorRoutine.TRIGGER, enableAuthAppTwoFactor);
  yield takeLatest(enableSMSTwoFactorRoutine.TRIGGER, enableSMSTwoFactor);
  yield takeLatest(validateTwoFactorRoutine.TRIGGER, validateTwoFactor);
  yield takeLatest(disableTwoFactorRoutine.TRIGGER, disableTwoFactor);
  yield takeLatest(sendSMSTwoFactorRoutine.TRIGGER, sendSMSTwoFactor);
  yield takeLatest(sendVerificationEmailRoutine.TRIGGER, sendVerificationEmail);
}

export default [twoFactorWatcher];
