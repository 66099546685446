export default {
  GET_USER_REQUEST: 'UserConstants.GET_USER_REQUEST',
  GET_USER_SUCCESS: 'UserConstants.GET_USER_SUCCESS',
  GET_USER_FAILURE: 'UserConstants.GET_USER_FAILURE',
  FLUX_GET_CUSTOMER_REQUEST: 'UserConstants.FLUX_GET_CUSTOMER_REQUEST',

  SET_EXTENDED_KYC_REQUIREMENT: 'UserConstants.SET_EXTENDED_KYC_REQUIREMENT',
  SET_USER_DETAILS_FROM_V2: 'UserConstants.SET_USER_DETAILS_FROM_V2',
  SET_USER_DETAILS_FROM_V4: 'UserConstants.SET_USER_DETAILS_FROM_V4',
  SET_USER_HAS_CONTACT_DETAILS: 'UserConstants.SET_USER_HAS_CONTACT_DETAILS',
  SET_CUSTOMER_TAX_NUMBER: 'UserConstants.SET_CUSTOMER_TAX_NUMBER',
  UPDATE_INDIVIDUAL_VERIFICATION_STATUS: 'UserConstants.UPDATE_INDIVIDUAL_VERIFICATION_STATUS',
  UPDATE_COMPANY_VERIFICATION_STATUS: 'UserConstants.UPDATE_COMPANY_VERIFICATION_STATUS',
  VERIFY_KYC2_AND_KYC3: 'UserConstants.VERIFY_KYC2_AND_KYC3',
  ADD_VAT_NUMBER: 'UserConstants.ADD_VAT_NUMBER',
  V4_GET_CUSTOMER: 'UserConstants.V4_GET_CUSTOMER',
  DELETE_TAX_NUMBER: 'UserConstants.DELETE_TAX_NUMBER',
  VERIFY_CUSTOMER_INFORMATION: 'UserConstants.VERIFY_CUSTOMER_INFORMATION',
  ELECTRONICALLY_VERIFY_CUSTOMER: 'UserConstants.ELECTRONICALLY_VERIFY_CUSTOMER',
  GET_USER_EKYC_DATA: 'UserConstants.GET_USER_EKYC_DATA',
  GET_USER_VERIFICATION_STATUSES: 'UserConstants.GET_USER_VERIFICATION_STATUSES',

  RESPONSE_SCHEMAS: {
    ADD_TAX_NUMBER: {
      number: 'vatNumber',
      'extra_attributes.country_code': 'vatCountry',
    },
  },

  PARTNER_LOGO_FORM: 'customer-partner-logo-form',
  SUBMIT_PARTNER_LOGO: 'UserConstants.SUBMIT_PARTNER_LOGO',
  LIST_PARTNER_LOGOS: 'UserConstants.LIST_PARTNER_LOGOS',
  REMOVE_PARTNER_LOGO: 'UserConstants.REMOVE_PARTNER_LOGO',

  GET_SAVED_CREDIT_CARDS: 'UserConstants.GET_SAVED_CREDIT_CARDS',
  GET_SAVED_PLAID_ACCOUNTS: 'UserConstants.GET_SAVED_PLAID_ACCOUNTS',
};
