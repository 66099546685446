import React, { Fragment } from 'react';
import ReactSVG from 'react-svg';
import { withRouter } from 'react-router-dom';
import { Button } from 'escrow-common-js/dist/components';
import Footer from 'spa/containers/Footer';
import { gettext } from '../../../utils/filters';
import { urlFor } from '../../../routeConfig';
import AuthenticationStore from '../../../stores/AuthenticationStore';

const EmailVerificationResultPage = (props) => {
  const { history } = props;
  const { emailVerifiedResult } = window.js_context || {};
  const authenticated = AuthenticationStore.isAuthenticated();
  let header;
  let content;
  let success;
  let iconSrc;

  switch (emailVerifiedResult) {
    case 'verified':
      header = gettext('Email Verified');
      iconSrc = '../../../../../build/images/global/icons/ui-circle-tick.svg';
      content = gettext('You have successfully verified your email address.');
      success = true;
      break;
    case 'failed':
      header = gettext('Email Verification Failed');
      iconSrc = '../../../../../build/images/global/fail-icon.svg';
      content = (
        <Fragment>
          {gettext('Unfortunately, this email verification link has expired.')}
          <br />
          {authenticated
            ? gettext('You may click the button below to request a new link.')
            : gettext('Please login to your account to request for a new link.')}
        </Fragment>
      );
      success = false;
      break;
    default:
      header = gettext('Email Verification Failed');
      iconSrc = '../../../../../build/images/global/fail-icon.svg';
      content = gettext('Something got wrong, please refresh the page');
      success = false;
      break;
  }

  return (
    <div>
      <div className="verifyPage section section--mid">
        <div className="checkout-content section-container">
          <div className="verifyPage-body">
            <div className="verifyPage-card">
              <div className="twoFactorAuth-card-header">
                <h1 className="emailVerification-header emailVerification-header--center">
                  {header}
                </h1>
                <div className="emailVerification-iconContainer">
                  <ReactSVG
                    src={iconSrc}
                    beforeInjection={(svg) => {
                      svg.classList.add('twoFactorAuth-page-icon');
                    }}
                  />
                </div>
                <p className="emailVerification-text emailVerification-text--center">{content}</p>
                {success ? (
                  <Button
                    className="twoFactorAuth-card-btn"
                    onClick={() =>
                      window.location.assign(
                        `${window.config.www_base_url}${urlFor('transactions_page')}`
                      )
                    }
                  >
                    {gettext('View My Transactions')}
                  </Button>
                ) : (
                  <Button
                    className="twoFactorAuth-card-btn"
                    onClick={() => history.push(urlFor('email_verification'))}
                  >
                    {authenticated
                      ? gettext('Resend verification email')
                      : gettext('Log in to Escrow.com')}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(EmailVerificationResultPage);
