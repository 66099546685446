import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'spa/components/Icon';

const HintBanner = ({ className, title, desc, icon, warning }) => (
  <div className={`hintBanner ${warning && 'hintBanner--warning'} ${className}`}>
    {icon && (
      <div className="hintBanner-figure">
        <Icon name={icon} className="hintBanner-icon" />
      </div>
    )}
    <div className="hintBanner-content">
      <h4 className="hintBanner-title">{title}</h4>
      <p className="hintBanner-desc">{desc}</p>
    </div>
  </div>
);

HintBanner.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  warning: PropTypes.bool,
};

export default HintBanner;
