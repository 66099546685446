import React, { useState, useEffect } from 'react';
import TransactionDetails from 'spa/components/TransactionProgress/TransactionDetails';
import TransactionAction from 'spa/components/TransactionProgress/TransactionAction';
import HeaderV3 from 'spa/containers/HeaderV3';
import { MAIN_FUNNEL_PAGE } from 'spa/constants/HeaderConstants';
import EscrowTheme from 'spa/components/MaterialUI/Theme';
import { Spinner } from 'spa/components/Indicators';
import { gettext } from '../../../utils/filters';
import API from '../../../api';
import TrackingActions from '../../../actions/TrackingActions';

const TransactionProgressContainer = ({ transToken }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const redirectToErrorPage = () => {
    window.location = `${window.config.www_base_url}/404`;
  };

  const redirectToLoginPage = (transId) => {
    window.location = `${window.config.www_base_url}/login-page?tid=${transId}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await API.getTransactionProgressDetails(transToken);
        setData(result);
        setLoading(false);
        if (result && result.transaction_details.id && result.party.role) {
          TrackingActions.track({
            event: 'escrow_user_action',
            section: 'transaction-progress-page-section',
            name: 'transaction-progress-page-view',
            action: 'page-view',
            value: `transaction-progress-${result.transaction_details.id}`,
            label: `transaction-progress-${result.party.role}`,
          });
        }
      } catch (e) {
        setLoading(false);
        setError(true);
        if (
          e.errors &&
          e.errors.message &&
          e.errors.message === 'Transaction status not handled at the moment.'
        ) {
          redirectToLoginPage(e.errors.transaction_id);
        } else {
          redirectToErrorPage();
        }
      }
    };
    fetchData();
  }, [transToken]);

  return (
    <div className="transactionProgress-body section--mid">
      <HeaderV3
        title={gettext('Transaction Progress')}
        pageType={MAIN_FUNNEL_PAGE}
        hideEmailBanner
        hideBackArrow
      />
      {loading && (
        <div className="transactionProgress-body-spinner">
          <Spinner />
        </div>
      )}
      <EscrowTheme>
        <TransactionDetails data={data} loading={loading} error={error} />
        <TransactionAction data={data} loading={loading} error={error} />
      </EscrowTheme>
    </div>
  );
};

export default TransactionProgressContainer;
