import React from 'react';
import { format } from 'date-fns-tz';
import { parseAmount, parseDate } from 'spa/features/transaction/utils/parsers';
import { formatCurrency } from 'spa/components/StartTransaction/util';
import { EscrowLinkButton } from 'spa/features/ui/EscrowButton';
import { SelectDomainTransferMethod } from '../domain-name/SelectDomainTransferMethod';
import { ItemAcceptanceConfirmation } from '../item-acceptance/ItemAcceptance';
import { ItemRejection } from '../item-rejection/ItemRejection';
import { ItemReceiptConfirmation } from '../item-receipt/ItemReceipt';
import { SmallText, SmallLink } from '../../../ui/EscrowTypography';
import { SelectDisbursementMethod } from '../disbursement/SelectDisbursementMethod';
import { ModifyTransaction } from '../modify-transaction/ModifyTransaction';
import { AddTrackingInformation } from '../modal/AddTrackingInformation';
import { TransactionAgreement } from '../transaction-agreement/TransactionAgreement';
import { ConfirmHandover } from '../modal/ConfirmHandover';
import { WireInstructions } from '../modal/WireInstructions';
import { CreditCardInstructions } from '../modal/CreditCardInstructions';
import { CancelTransaction } from '../modal/CancelTransaction';

import { urlfor } from '../../../../../utils/filters';

/** Statuses common between mv, dn and gm transactions */
const commonInstructions = {
  0: {
    buyer: {
      title: 'This transaction has been cancelled.',
    },
    seller: {
      title: 'This transaction has been cancelled.',
    },
    broker: {
      title: 'This transaction has been cancelled.',
    },
  },
  3: {
    buyer: {
      title: 'Review and agree to the transaction',
      description:
        'The broker has started the transaction. Please review the transaction details and terms.',
      primaryCta: 'TRANSACTION_AGREE',
    },
    seller: {
      title: 'Review and agree to the transaction',
      description:
        'The broker has started the transaction. Please review the transaction details and terms.',
      primaryCta: 'TRANSACTION_AGREE',
    },
    broker: {
      title: 'Waiting for the buyer and seller to review and agree to the transaction',
      description:
        'The transaction will only move forward once both parties agree to the transaction.',
    },
  },
  5: {
    buyer: {
      title: 'Waiting for the seller to review and agree to the transaction',
      description: 'Upon reviewing the transaction, the seller may agree or request changes.',
    },
    seller: {
      title: 'Review and agree to the transaction',
      description:
        'The buyer has started the transaction. Please review the transaction details and terms.',
      primaryCta: 'TRANSACTION_AGREE',
      alternateCta: 'MODIFY_TRANSACTION',
    },
  },
  6: {
    buyer: {
      title: 'Waiting for the seller to review and agree to the transaction',
      description:
        'The transaction will only move forward once both parties agree to the transaction.',
    },
    seller: {
      title: 'Review and agree to the transaction',
      description:
        'The buyer has agreed to the transaction. Please review the transaction details and terms.',
      primaryCta: 'TRANSACTION_AGREE',
    },
    broker: {
      title: 'Waiting for the seller to review and agree to the transaction',
      description:
        'The buyer has agreed to the transaction. The seller must now log in and review the transaction details and terms.',
    },
  },
  9: {
    buyer: {
      title: 'Review and agree to the transaction',
      description:
        'The seller has agreed to the transaction. Please review the transaction details and terms.',
      primaryCta: 'TRANSACTION_AGREE',
    },
    seller: {
      title: 'Waiting for the buyer to review and agree to the transaction',
      description:
        'The transaction will only move forward once both parties agree to the transaction.',
    },
    broker: {
      title: 'Waiting for the buyer to review and agree to the transaction',
      description:
        'The seller has agreed to the transaction. The buyer must now log in and review the transaction details and terms.',
    },
  },
  10: {
    buyer: {
      title: 'Review and agree to the transaction',
      description:
        'The seller has started the transaction. Please review the transaction details and terms.',
      primaryCta: 'TRANSACTION_AGREE',
      alternateCta: 'MODIFY_TRANSACTION',
    },
    seller: {
      title: 'Waiting for the buyer to review and agree to the transaction',
      description: 'Upon reviewing the transaction, the buyer may agree or request changes.',
    },
  },
  15: {
    buyer: {
      title: 'Pay for the transaction',
      description:
        'Please click on the button below and follow the instructions to send the payment for the transaction.',
      primaryCta: 'SELECT_PAYMENT_METHOD',
    },
    seller: {
      title: 'Waiting for the buyer to send their payment to Escrow.com',
      description:
        'All parties have agreed to the transaction and its terms. We have notified the buyer to send their payment.',
    },
    broker: {
      title: 'Waiting for the buyer to send their payment to Escrow.com',
      description:
        'All parties have agreed to the transaction and its terms. We have notified the buyer to send their payment.',
    },
  },
  20: {
    buyer_wire: {
      title: 'Waiting for your payment to arrive',
      description:
        'If you have not sent your payment, click on the button below to view the wire transfer instructions.',
      secondaryCtas: ['WIRE_INSTRUCTIONS'],
    },
    buyer_cc: {
      title: 'Waiting for your payment to arrive',
      description:
        'If you have not completed your payment, click on the button below.',
      secondaryCtas: ['CREDIT_CARD_INSTRUCTIONS'],
    },
    buyer: {
      title: 'Waiting for your payment to arrive',
      description:
        'You have sent your payment. We are waiting for the payment to arrive in our secure escrow account.',
    },
    seller: {
      title: "Waiting for the buyer's payment to arrive",
      description:
        'The buyer has sent their payment. We are waiting for the payment to arrive in our secure escrow account.',
    },
    broker: {
      title: "Waiting for the buyer's payment to arrive",
      description:
        'The buyer has sent their payment. We are waiting for the payment to arrive in our secure escrow account.',
    },
  },
  70: {
    buyer: {
      title: 'The transaction is currently on hold.',
    },
    seller: {
      title: 'The transaction is currently on hold.',
    },
    broker: {
      title: 'The transaction is currently on hold.',
    },
  },
  80: {
    buyer: {
      title: 'The transaction is now closed',
      description:
        'We have sent the closing statement to your email. If you wish to download the transaction summary or the invoice, please click below.',
      secondaryCtas: ['VIEW_TRANSACTION_SUMMARY', 'VIEW_INVOICE'],
    },
    seller: {
      title: 'The transaction is now closed',
      description:
        'We have sent the closing statement to your email. If you wish to download the transaction summary or the invoice, please click below.',
      secondaryCtas: ['VIEW_TRANSACTION_SUMMARY', 'VIEW_INVOICE'],
    },
    broker: {
      title: 'The transaction is now closed',
      description:
        'We have sent the closing statement to your email. If you wish to download the transaction summary or the invoice, please click below.',
      secondaryCtas: ['VIEW_TRANSACTION_SUMMARY', 'VIEW_INVOICE'],
    },
  },
  85: {
    buyer: {
      title: 'The transaction is now closed',
      description:
        'We have sent the closing statement to your email. If you wish to download the transaction summary or the invoice, please click below.',
      secondaryCtas: ['VIEW_TRANSACTION_SUMMARY', 'VIEW_INVOICE'],
    },
    seller: {
      title: 'The transaction is now closed',
      description:
        'We have sent the closing statement to your email. If you wish to download the transaction summary or the invoice, please click below.',
      secondaryCtas: ['VIEW_TRANSACTION_SUMMARY', 'VIEW_INVOICE'],
    },
    broker: {
      title: 'The transaction is now closed',
      description:
        'We have sent the closing statement to your email. If you wish to download the transaction summary or the invoice, please click below.',
      secondaryCtas: ['VIEW_TRANSACTION_SUMMARY', 'VIEW_INVOICE'],
    },
  },
};

// Instructions for status 75 transactions
const cancellationInstructions = {
  first_approval_required: {
    title: 'Cancellation pending acceptance',
    description:
      'Escrow.com requires the agreement of both parties to cancel this transaction. Please confirm below that you agree to the cancellation.',
    primaryCta: 'CANCEL_TRANSACTION',
  },
  // Approval required from current party
  second_approval_required: {
    title: 'Cancellation pending acceptance',
    description:
      'The {otherParty} has agreed to cancel this transaction. Please confirm below that you agree to the cancellation.',
    primaryCta: 'CANCEL_TRANSACTION',
  },
  // Approval given by current party
  approval_given: {
    title: 'Cancellation pending acceptance',
    description:
      'You have agreed to the cancellation of this transaction. We are waiting for the {otherParty} to agree to the cancellation.',
  },
  // Approval not needed from current party e.g. broker
  other_party: {
    title: 'Cancellation pending acceptance',
    description: 'Escrow.com has received a request to cancel this transaction.',
  },
};

/** Statuses common between shipping and no shipping gm transactions */
const gmCommonInstructions = {
  35: {
    buyer: {
      title: 'Inspect the merchandise',
      description:
        'You have **{inspectionDays} calendar {day/s}** to conduct an inspection. Within the said time frame, you have to confirm whether you **Accept** or **Reject** the merchandise.',
      primaryCta: 'ITEM_ACCEPTANCE_CONFIRMATION',
      alternateCta: 'ITEM_REJECTION',
    },
    seller: {
      title: 'Waiting for the buyer to accept the merchandise',
      description:
        'The buyer is provided **{inspectionDays} calendar {day/s}** to conduct an inspection. Within the said time frame, they have to confirm whether they **Accept** or **Reject** the merchandise.',
    },
    broker: {
      title: 'Waiting for the buyer to accept the merchandise',
      description:
        'The buyer is provided **{inspectionDays} calendar {day/s}** to conduct an inspection. Within the said time frame, they have to confirm whether they **Accept** or **Reject** the merchandise.',
    },
  },
  40: {
    buyer: {
      title: 'You have accepted the merchandise',
      description:
        "We will now process the transfer of the seller's proceeds. Once the transaction is closed, we will send both parties a copy of the closing statement.",
    },
    seller: {
      title: 'The buyer has accepted the merchandise',
      description:
        'We are working on transferring the payment to your nominated bank account. After the transaction is closed, please expect the payment to arrive within **1-3** business days.',
    },
    broker: {
      title: 'The buyer has accepted the merchandise',
      description:
        'We are working on transferring the broker commission to your nominated bank account. After the transaction is closed, please expect the payment to arrive within **1-3** business days.',
    },
  },
  55: {
    buyer: {
      title: 'Waiting for the seller to accept the returned merchandise',
      description:
        'The seller is provided **{inspectionDays} calendar {day/s}** to conduct an inspection. Within the said time frame, they have to confirm whether they **Accept** or **Reject** the returned merchandise.',
    },
    seller: {
      title: 'Inspect the returned merchandise',
      description:
        'You have **{inspectionDays} calendar {day/s}** to conduct an inspection. Within the said time frame, you have to confirm whether you **Accept** or **Reject** the returned merchandise.',
      primaryCta: 'ITEM_ACCEPTANCE_CONFIRMATION',
      alternateCta: 'ITEM_REJECTION',
    },
    broker: {
      title: 'Waiting for the seller to accept the returned merchandise',
      description:
        'The seller is provided **{inspectionDays} calendar {day/s}** to conduct an inspection. Within the said time frame, they have to confirm whether they **Accept** or **Reject** the returned merchandise.',
    },
  },
  60: {
    buyer: {
      title: 'The seller has accepted the returned merchandise',
      description:
        'We are working on transferring the refund to your nominated bank account. After the transaction is closed, please expect the payment to arrive within **1-3** business days.',
    },
    seller: {
      title: 'You have accepted the returned merchandise',
      description:
        'We will now process the refund of the buyer. Once the transaction is closed, we will send both parties a copy of the closing statement.',
    },
    broker: {
      title: 'The seller has accepted the returned merchandise',
      description:
        "We are working on transferring the refund to the buyer's nominated bank account. Once the transaction is closed, we will send all parties a copy of the closing statement.",
    },
  },
  65: {
    buyer: {
      title: 'The transaction is under dispute',
      description: 'The seller has rejected the returned merchandise.',
    },
    seller: {
      title: 'The transaction is under dispute',
      description: 'You have rejected the returned merchandise.',
    },
    broker: {
      title: 'The transaction is under dispute',
      description: 'The seller has rejected the returned merchandise.',
    },
  },
};

/** Statuses specific for gm shipping transactions */
const gmShippingInstructions = {
  22: {
    buyer: {
      title: 'Payment received and is under review',
      description:
        'Your payment is being reviewed. Once approved, we will notify the seller to ship the merchandise to you.',
      secondaryCtas: ['WIRE_INSTRUCTIONS'],
    },
    seller: {
      title: 'Payment received and is under review',
      description:
        'We have received the buyer’s payment. Please wait until we approve the payment before shipping the merchandise to the buyer.',
    },
    broker: {
      title: 'Payment received and is under review',
      description:
        'We have received the buyer’s payment. The seller must wait until we approve the payment before shipping the merchandise to the buyer.',
    },
  },
  25: {
    buyer: {
      title: 'Waiting for the seller to ship the merchandise',
      description:
        'Your payment has been approved and is kept secure in an escrow account. The seller has been instructed to ship the merchandise to you.',
    },
    seller: {
      title: 'Ship the merchandise to the buyer',
      description:
        'The payment has been approved and is kept secure in an escrow account. You may now ship the merchandise to the buyer.',
      primaryCta: 'ADD_TRACKING_INFORMATION',
    },
    broker: {
      title: 'Waiting for the seller to ship the merchandise',
      description:
        'The payment has been approved and is kept secure in an escrow account. The seller has been instructed to ship the merchandise.',
    },
  },
  30: {
    buyer: {
      title: 'The seller has shipped the merchandise',
      description:
        'The seller has provided the tracking details for the shipment. Please confirm that you have received the merchandise by clicking the button below.',
      primaryCta: 'ITEM_RECEIPT_CONFIRMATION',
    },
    seller: {
      title: 'Waiting for the buyer to confirm merchandise receipt',
      description:
        'Once the buyer confirms they have the merchandise, the **inspection period** of **{inspectionDays} calendar {day/s}** will start.',
    },
    broker: {
      title: 'Waiting for the buyer to confirm merchandise receipt',
      description:
        'Once the buyer confirms they have the merchandise, the **inspection period** of **{inspectionDays} calendar {day/s}** will start.',
    },
  },
  45: {
    buyer: {
      title: 'Return the merchandise to the seller',
      description:
        'You have rejected the merchandise. Please ship back the merchandise to the seller.',
      primaryCta: 'ADD_TRACKING_INFORMATION',
    },
    seller: {
      title: 'Waiting for the buyer to return the merchandise',
      description:
        'The buyer has rejected the merchandise. We have instructed the buyer to ship the merchandise back to you.',
    },
    broker: {
      title: 'Waiting for the buyer to return the merchandise',
      description:
        'The buyer has rejected the merchandise. We have instructed the buyer to ship the merchandise back to the seller.',
    },
  },
  50: {
    buyer: {
      title: 'Waiting for the seller to confirm merchandise receipt',
      description:
        'Once the seller confirms they have received the returned merchandise, the **inspection period** of **{inspectionDays} calendar {day/s}** will start.',
    },
    seller: {
      title: 'The buyer has returned the merchandise',
      description:
        'The buyer has provided the tracking details for the shipment. Please confirm that you have received the returned merchandise by clicking the button below.',
      primaryCta: 'ITEM_RECEIPT_CONFIRMATION',
    },
    broker: {
      title: 'Waiting for the seller to confirm merchandise receipt',
      description:
        'Once the seller confirms they have received the returned merchandise, the **inspection period** of **{inspectionDays} calendar {day/s}** will start.',
    },
  },
};

/** Statuses specific for gm no shipping transactions */
const gmNoShippingInstructions = {
  22: {
    buyer: {
      title: 'Payment received and is under review',
      description:
        'Your payment is being reviewed. Once approved, we will notify the seller to hand over the merchandise to you.',
      secondaryCtas: ['WIRE_INSTRUCTIONS'],
    },
    seller: {
      title: 'Payment received and is under review',
      description:
        'We have received the buyer’s payment. Please wait until we approve the payment before handing over the merchandise to the buyer.',
    },
    broker: {
      title: 'Payment received and is under review',
      description:
        'We have received the buyer’s payment. The seller must wait until we approve the payment before handing over the merchandise to the buyer.',
    },
  },
  25: {
    buyer: {
      title: 'Waiting for the seller to confirm handover of the merchandise',
      description:
        'Your payment has been approved and is kept secure in an escrow account. The seller has been instructed to hand over the merchandise to you.',
    },
    seller: {
      title: 'Hand over the merchandise to the buyer',
      description:
        'The payment has been approved and is kept secure in an escrow account. You may now hand over the merchandise to the buyer.',
      primaryCta: 'CONFIRM_HANDOVER',
    },
    broker: {
      title: 'Waiting for the seller to confirm handover of the merchandise',
      description:
        'The payment has been approved and is kept secure in an escrow account. The seller has been instructed to hand over the merchandise to the buyer.',
    },
  },
  30: {
    buyer: {
      title: 'The seller handed over the merchandise',
      description:
        'The seller confirmed that the merchandise has been handed over to you. Please confirm that you have received the merchandise by clicking the button below.',
      primaryCta: 'ITEM_RECEIPT_CONFIRMATION',
    },
    seller: {
      title: 'Waiting for the buyer to confirm merchandise receipt',
      description:
        'Once the buyer confirms they have the merchandise, the **inspection period** of **{inspectionDays} calendar {day/s}** will start.',
    },
    broker: {
      title: 'Waiting for the buyer to confirm merchandise receipt',
      description:
        'Once the buyer confirms they have the merchandise, the **inspection period** of **{inspectionDays} calendar {day/s}** will start.',
    },
  },
  45: {
    buyer: {
      title: 'Return the merchandise to the seller',
      description:
        'You have rejected the merchandise. Please return the merchandise to the seller.',
      primaryCta: 'CONFIRM_HANDOVER',
    },
    seller: {
      title: 'Waiting for the buyer to return the merchandise',
      description:
        'The buyer has rejected the merchandise. We have instructed the buyer to return the merchandise to you.',
    },
    broker: {
      title: 'Waiting for the buyer to return the merchandise',
      description:
        'The buyer has rejected the merchandise. We have instructed the buyer to return the merchandise back to the seller.',
    },
  },
  50: {
    buyer: {
      title: 'Waiting for the seller to confirm merchandise receipt',
      description:
        'Once the seller confirms they have received the returned merchandise, the **inspection period** of **{inspectionDays} calendar {day/s}** will start.',
    },
    seller: {
      title: 'The buyer has returned the merchandise',
      description:
        'The buyer confirmed that the merchandise has been returned. Please confirm that you have received the returned merchandise by clicking the button below.',
      primaryCta: 'ITEM_RECEIPT_CONFIRMATION',
    },
    broker: {
      title: 'Waiting for the seller to confirm merchandise receipt',
      description:
        'Once the seller confirms they have received the returned merchandise, the **inspection period** of **{inspectionDays} calendar {day/s}** will start.',
    },
  },
};

/** Statuses common between shipping and no shipping mv transactions */
const mvCommonInstructions = {
  35: {
    buyer: {
      title: 'Inspect the motor vehicle',
      description:
        'You have **{inspectionDays} calendar {day/s}** to conduct an inspection. Within the said time frame, you have to confirm whether you **Accept** or **Reject** the motor vehicle.',
      primaryCta: 'ITEM_ACCEPTANCE_CONFIRMATION',
      alternateCta: 'ITEM_REJECTION',
    },
    seller: {
      title: 'Waiting for the buyer to accept the motor vehicle',
      description:
        'The buyer is provided **{inspectionDays} calendar {day/s}** to conduct an inspection. Within the said time frame, they have to confirm whether they **Accept** or **Reject** the motor vehicle.',
    },
    broker: {
      title: 'Waiting for the buyer to accept the motor vehicle',
      description:
        'The buyer is provided **{inspectionDays} calendar {day/s}** to conduct an inspection. Within the said time frame, they have to confirm whether they **Accept** or **Reject** the motor vehicle.',
    },
  },
  40: {
    buyer: {
      title: 'You have accepted the motor vehicle',
      description:
        "We will now process the transfer of the seller's proceeds. Once the transaction is closed, we will send both parties a copy of the closing statement.",
    },
    seller: {
      title: 'The buyer has accepted the motor vehicle',
      description:
        'We are working on transferring the payment to your nominated bank account. After the transaction is closed, please expect the payment to arrive within **1-3** business days.',
    },
    broker: {
      title: 'The buyer has accepted the motor vehicle',
      description:
        'We are working on transferring the broker commission to your nominated bank account. After the transaction is closed, please expect the payment to arrive within **1-3** business days.',
    },
  },
  55: {
    buyer: {
      title: 'Waiting for the seller to accept the returned motor vehicle',
      description:
        'The seller is provided **{inspectionDays} calendar {day/s}** to conduct an inspection. Within the said time frame, they have to confirm whether they **Accept** or **Reject** the returned motor vehicle.',
    },
    seller: {
      title: 'Inspect the returned motor vehicle',
      description:
        'You have **{inspectionDays} calendar {day/s}** to conduct an inspection. Within the said time frame, you have to confirm whether you **Accept** or **Reject** the returned motor vehicle.',
      primaryCta: 'ITEM_ACCEPTANCE_CONFIRMATION',
      alternateCta: 'ITEM_REJECTION',
    },
    broker: {
      title: 'Waiting for the seller to accept the returned motor vehicle',
      description:
        'The seller is provided **{inspectionDays} calendar {day/s}** to conduct an inspection. Within the said time frame, they have to confirm whether they **Accept** or **Reject** the returned motor vehicle.',
    },
  },
  60: {
    buyer: {
      title: 'The seller has accepted the returned motor vehicle',
      description:
        'We are working on transferring the refund to your nominated bank account. After the transaction is closed, please expect the payment to arrive within **1-3** business days.',
    },
    seller: {
      title: 'You have accepted the returned motor vehicle',
      description:
        'We will now process the refund of the buyer. Once the transaction is closed, we will send both parties a copy of the closing statement.',
    },
    broker: {
      title: 'The seller has accepted the returned motor vehicle',
      description:
        "We are working on transferring the refund to the buyer's nominated bank account. Once the transaction is closed, we will send all parties a copy of the closing statement.",
    },
  },
  65: {
    buyer: {
      title: 'The transaction is under dispute',
      description: 'The seller has rejected the returned motor vehicle.',
    },
    seller: {
      title: 'The transaction is under dispute',
      description: 'You have rejected the returned motor vehicle.',
    },
    broker: {
      title: 'The transaction is under dispute',
      description: 'The seller has rejected the returned motor vehicle.',
    },
  },
};

/** Statuses specific for mv shipping transactions */
const mvShippingInstructions = {
  22: {
    buyer: {
      title: 'Payment received and is under review',
      description:
        'Your payment is being reviewed. Once approved, we will notify the seller to ship the motor vehicle to you.',
      secondaryCtas: ['WIRE_INSTRUCTIONS'],
    },
    seller: {
      title: 'Payment received and is under review',
      description:
        'We have received the buyer’s payment. Please wait until we approve the payment before shipping the motor vehicle to the buyer.',
    },
    broker: {
      title: 'Payment received and is under review',
      description:
        'We have received the buyer’s payment. The seller must wait until we approve the payment before shipping the motor vehicle to the buyer.',
    },
  },
  25: {
    buyer: {
      title: 'Waiting for the seller to ship the motor vehicle',
      description:
        'Your payment has been approved and is kept secure in an escrow account. The seller has been instructed to ship the motor vehicle to you.',
    },
    seller: {
      title: 'Ship the motor vehicle to the buyer',
      description:
        'The payment has been approved and is kept secure in an escrow account. You may now ship the motor vehicle to the buyer.',
      primaryCta: 'ADD_TRACKING_INFORMATION',
    },
    broker: {
      title: 'Waiting for the seller to ship the motor vehicle',
      description:
        'The payment has been approved and is kept secure in an escrow account. The seller has been instructed to ship the motor vehicle.',
    },
  },
  30: {
    buyer: {
      title: 'The seller has shipped the motor vehicle',
      description:
        'The seller has provided the tracking details for the shipment. Please confirm that you have received the motor vehicle by clicking the button below.',
      primaryCta: 'ITEM_RECEIPT_CONFIRMATION',
    },
    seller: {
      title: 'Waiting for the buyer to confirm motor vehicle receipt',
      description:
        'Once the buyer confirms they have the motor vehicle, the **inspection period** of **{inspectionDays} calendar {day/s}** will start.',
    },
    broker: {
      title: 'Waiting for the buyer to confirm motor vehicle receipt',
      description:
        'Once the buyer confirms they have the motor vehicle, the **inspection period** of **{inspectionDays} calendar {day/s}** will start.',
    },
  },
  45: {
    buyer: {
      title: 'Return the motor vehicle to the seller',
      description:
        'You have rejected the motor vehicle. Please ship back the motor vehicle to the seller.',
      primaryCta: 'ADD_TRACKING_INFORMATION',
    },
    seller: {
      title: 'Waiting for the buyer to return the motor vehicle',
      description:
        'The buyer has rejected the motor vehicle. We have instructed the buyer to ship the motor vehicle back to you.',
    },
    broker: {
      title: 'Waiting for the buyer to return the motor vehicle',
      description:
        'The buyer has rejected the motor vehicle. We have instructed the buyer to ship the motor vehicle back to the seller.',
    },
  },
  50: {
    buyer: {
      title: 'Waiting for the seller to confirm motor vehicle receipt',
      description:
        'Once the seller confirms they have received the returned motor vehicle, the **inspection period** of **{inspectionDays} calendar {day/s}** will start.',
    },
    seller: {
      title: 'The buyer has returned the motor vehicle',
      description:
        'The buyer has provided the tracking details for the shipment. Please confirm that you have received the returned motor vehicle by clicking the button below.',
      primaryCta: 'ITEM_RECEIPT_CONFIRMATION',
    },
    broker: {
      title: 'Waiting for the seller to confirm motor vehicle receipt',
      description:
        'Once the seller confirms they have received the returned motor vehicle, the **inspection period** of **{inspectionDays} calendar {day/s}** will start.',
    },
  },
};

/** Statuses specific for mv no shipping transactions */
const mvNoShippingInstructions = {
  22: {
    buyer: {
      title: 'Payment received and is under review',
      description:
        'Your payment is being reviewed. Once approved, we will notify the seller to hand over the motor vehicle to you.',
      secondaryCtas: ['WIRE_INSTRUCTIONS'],
    },
    seller: {
      title: 'Payment received and is under review',
      description:
        'We have received the buyer’s payment. Please wait until we approve the payment before handing over the motor vehicle to the buyer.',
    },
    broker: {
      title: 'Payment received and is under review',
      description:
        'We have received the buyer’s payment. The seller must wait until we approve the payment before handing over the motor vehicle to the buyer.',
    },
  },
  25: {
    buyer: {
      title: 'Waiting for the seller to confirm handover of the motor vehicle',
      description:
        'Your payment has been approved and is kept secure in an escrow account. The seller has been instructed to hand over the motor vehicle to you.',
    },
    seller: {
      title: 'Hand over the motor vehicle to the buyer',
      description:
        'The payment has been approved and is kept secure in an escrow account. You may now hand over the motor vehicle to the buyer.',
      primaryCta: 'CONFIRM_HANDOVER',
    },
    broker: {
      title: 'Waiting for the seller to confirm handover of the motor vehicle',
      description:
        'The payment has been approved and is kept secure in an escrow account. The seller has been instructed to hand over the motor vehicle to the buyer.',
    },
  },
  30: {
    buyer: {
      title: 'The seller handed over the motor vehicle',
      description:
        'The seller confirmed that the motor vehicle has been handed over to you. Please confirm that you have received the motor vehicle by clicking the button below.',
      primaryCta: 'ITEM_RECEIPT_CONFIRMATION',
    },
    seller: {
      title: 'Waiting for the buyer to confirm motor vehicle receipt',
      description:
        'Once the buyer confirms they have the motor vehicle, the **inspection period** of **{inspectionDays} calendar {day/s}** will start.',
    },
    broker: {
      title: 'Waiting for the buyer to confirm motor vehicle receipt',
      description:
        'Once the buyer confirms they have the motor vehicle, the **inspection period** of **{inspectionDays} calendar {day/s}** will start.',
    },
  },
  45: {
    buyer: {
      title: 'Return the motor vehicle to the seller',
      description:
        'You have rejected the motor vehicle. Please return the motor vehicle to the seller.',
      primaryCta: 'CONFIRM_HANDOVER',
    },
    seller: {
      title: 'Waiting for the buyer to return the motor vehicle',
      description:
        'The buyer has rejected the motor vehicle. We have instructed the buyer to return the motor vehicle to you.',
    },
    broker: {
      title: 'Waiting for the buyer to return the motor vehicle',
      description:
        'The buyer has rejected the motor vehicle. We have instructed the buyer to return the motor vehicle back to the seller.',
    },
  },
  50: {
    buyer: {
      title: 'Waiting for the seller to confirm motor vehicle receipt',
      description:
        'Once the seller confirms they have received the returned motor vehicle, the **inspection period** of **{inspectionDays} calendar {day/s}** will start.',
    },
    seller: {
      title: 'The buyer has returned the motor vehicle',
      description:
        'The buyer confirmed that the motor vehicle has been returned. Please confirm that you have received the returned motor vehicle by clicking the button below.',
      primaryCta: 'ITEM_RECEIPT_CONFIRMATION',
    },
    broker: {
      title: 'Waiting for the seller to confirm motor vehicle receipt',
      description:
        'Once the seller confirms they have received the returned motor vehicle, the **inspection period** of **{inspectionDays} calendar {day/s}** will start.',
    },
  },
};

const dnInstructions = {
  // For DN transactions status 21 is used for concierge transactions
  21: {
    buyer: {
      title: 'Escrow.com is managing the domain name transfer',
      description:
        'We will communicate with the seller about the domain name transfer. Once Escrow.com has the domain name, you will be notified about the next steps.',
    },
    seller: {
      title: 'Escrow.com is managing the domain name transfer',
      description:
        'Our team will reach out to you regarding the domain transfer. Once Escrow.com has the domain name, the buyer will be notified about the next steps.',
    },
    broker: {
      title: 'Escrow.com is managing the domain name transfer',
      description:
        'We will communicate with the seller about the domain name transfer. Once Escrow.com has the domain name, the buyer will be notified about the next steps.',
    },
  },
  22: {
    buyer: {
      title: 'Payment received and is under review',
      description:
        'Your payment is being reviewed. Once approved, we will notify the seller to transfer the domain name.',
      secondaryCtas: ['WIRE_INSTRUCTIONS'],
    },
    seller: {
      title: 'Payment received and is under review',
      description:
        "We have received the buyer's payment. Please wait until we approve the payment before initiating the domain name transfer.",
    },
    broker: {
      title: 'Payment received and is under review',
      description:
        "We have received the buyer's payment. The seller must wait until we approve the payment before initiating the domain name transfer.",
    },
  },
  25: {
    buyer: {
      title: 'Waiting for the seller to transfer the domain name',
      description:
        'Your payment has been approved and is kept secure in an escrow account. The seller has been instructed to transfer the domain name.',
    },
    seller: {
      title: 'Transfer the domain name',
      description:
        'The payment has been approved and is kept secure in an escrow account. You may now transfer the domain name.',
      primaryCta: 'SELECT_DN_TRANSFER_METHOD',
    },
    broker: {
      title: 'Waiting for the seller to transfer the domain name',
      description:
        'The payment has been approved and is kept secure in an escrow account. The seller has been instructed to transfer the domain name.',
    },
  },
  30: {
    buyer: {
      title: 'The seller selected a domain transfer method',
      description:
        'Please wait for further instructions. Once you have the domain name, confirm you have received it by clicking the button below.',
      primaryCta: 'ITEM_RECEIPT_CONFIRMATION',
    },
    seller: {
      title: 'You have selected a domain transfer method',
      description:
        'Send the domain transfer instructions to the email address below. Once the buyer confirms they have the domain name, the **inspection period** of **{inspectionDays} calendar {day/s}** will start.',
    },
    broker: {
      title: 'The seller selected a domain transfer method',
      description:
        'Once the buyer confirms they have the domain name, the **inspection period** of **{inspectionDays} calendar {day/s}** will start.',
    },
  },
  35: {
    buyer: {
      title: 'Inspect the domain name',
      description:
        'You have **{inspectionDays} calendar {day/s}** to conduct an inspection. Within the said time frame, you have to confirm whether you **Accept** or **Reject** the domain name.',
      primaryCta: 'ITEM_ACCEPTANCE_CONFIRMATION',
      alternateCta: 'ITEM_REJECTION',
    },
    seller: {
      title: 'Waiting for the buyer to accept the domain name',
      description:
        'The buyer is provided **{inspectionDays} calendar {day/s}** to conduct an inspection. Within the said time frame, they have to confirm whether they **Accept** or **Reject** the domain name.',
    },
    broker: {
      title: 'Waiting for the buyer to accept the domain name',
      description:
        'The buyer is provided **{inspectionDays} calendar {day/s}** to conduct an inspection. Within the said time frame, they have to confirm whether they **Accept** or **Reject** the domain name.',
    },
  },
  40: {
    buyer: {
      title: 'You have accepted the domain name',
      description:
        "We will now process the transfer of the seller's proceeds. Once the transaction is closed, we will send both parties a copy of the closing statement.",
    },
    seller: {
      title: 'The buyer has accepted the domain name',
      description:
        'We are working on transferring the payment to your nominated bank account. After the transaction is closed, please expect the payment to arrive within **1-3** business days.',
    },
    broker: {
      title: 'The buyer has accepted the domain name',
      description:
        'We are working on transferring the broker commission to your nominated bank account. After the transaction is closed, please expect the payment to arrive within **1-3** business days.',
    },
  },
  45: {
    buyer: {
      title: 'Return the domain name',
      description: 'You have rejected the domain name. Please transfer back the domain name.',
      primaryCta: 'SELECT_DN_TRANSFER_METHOD',
    },
    seller: {
      title: 'Waiting for the buyer to return the domain name',
      description:
        'The buyer has rejected the domain name. We have instructed the buyer to return the domain name.',
    },
    broker: {
      title: 'Waiting for the buyer to return the domain name',
      description:
        'The buyer has rejected the domain name. We have instructed the buyer to return the domain name.',
    },
  },
  50: {
    buyer: {
      title: 'You have selected a domain transfer method',
      description:
        'Send the domain transfer instructions to the email address below. Once the seller confirms they have the domain name, the **inspection period** of **{inspectionDays} calendar {day/s}** will start.',
    },
    seller: {
      title: 'The buyer selected a domain transfer method',
      description:
        'Please wait for further instructions. Once you have the domain name, confirm you have received it by clicking the button below.',
      primaryCta: 'ITEM_RECEIPT_CONFIRMATION',
    },
    broker: {
      title: 'The buyer selected a domain transfer method',
      description:
        'Once the seller confirms they have the domain name, the **inspection period** of **{inspectionDays} calendar {day/s}** will start.',
    },
  },
  55: {
    buyer: {
      title: 'Waiting for the seller to accept the returned domain name',
      description:
        'The seller is provided **{inspectionDays} calendar {day/s}** to conduct an inspection. Within the said time frame, they have to confirm whether they **Accept** or **Reject** the domain name.',
    },
    seller: {
      title: 'Inspect the returned domain name',
      description:
        'You have **{inspectionDays} calendar {day/s}** to conduct an inspection. Within the said time frame, you have to confirm whether you **Accept** or **Reject** the domain name.',
      primaryCta: 'ITEM_ACCEPTANCE_CONFIRMATION',
      alternateCta: 'ITEM_REJECTION',
    },
    broker: {
      title: 'Waiting for the seller to accept the returned domain name',
      description:
        'The seller is provided **{inspectionDays} calendar {day/s}** to conduct an inspection. Within the said time frame, they have to confirm whether they **Accept** or **Reject** the domain name.',
    },
  },
  60: {
    buyer: {
      title: 'The seller has accepted the returned domain name',
      description:
        'We are working on transferring the refund to your nominated bank account. After the transaction is closed, please expect the payment to arrive within **1-3** business days.',
    },
    seller: {
      title: 'You have accepted the returned domain name',
      description:
        'We will now process the refund of the buyer. Once the transaction is closed, we will send both parties a copy of the closing statement.',
    },
    broker: {
      title: 'The seller has accepted the returned domain name',
      description:
        "We are working on transferring the refund to the buyer's nominated bank account. Once the transaction is closed, we will send all parties a copy of the closing statement.",
    },
  },
  65: {
    buyer: {
      title: 'The transaction is under dispute',
      description: 'The seller has rejected the returned domain name.',
    },
    seller: {
      title: 'The transaction is under dispute',
      description: 'You have rejected the returned domain name.',
    },
    broker: {
      title: 'The transaction is under dispute',
      description: 'The seller has rejected the returned domain name.',
    },
  },
  70: {
    buyer: {
      title: 'The transaction is currently on hold.',
    },
    seller: {
      title: 'The transaction is currently on hold.',
    },
    broker: {
      title: 'The transaction is currently on hold.',
    },
  },
  80: {
    buyer: {
      title: 'The transaction is now closed',
      description:
        'We have sent the closing statement to your email.If you wish to download the transaction summary or the invoice, please click below.',
      secondaryCtas: ['VIEW_TRANSACTION_SUMMARY', 'VIEW_INVOICE'],
    },
    seller: {
      title: 'The transaction is now closed',
      description:
        'We have sent the closing statement to your email.If you wish to download the transaction summary or the invoice, please click below.',
      secondaryCtas: ['VIEW_TRANSACTION_SUMMARY', 'VIEW_INVOICE'],
    },
    broker: {
      title: 'The transaction is now closed',
      description:
        'We have sent the closing statement to your email. If you wish to download the transaction summary or the invoice, please click below.',
    },
  },
  85: {
    buyer: {
      title: 'The transaction is now closed',
      description:
        'We have sent the closing statement to your email. If you wish to download the transaction summary or the invoice, please click below.',
      secondaryCtas: ['VIEW_TRANSACTION_SUMMARY', 'VIEW_INVOICE'],
    },
    seller: {
      title: 'The transaction is now closed',
      description:
        'We have sent the closing statement to your email. If you wish to download the transaction summary or the invoice, please click below.',
      secondaryCtas: ['VIEW_TRANSACTION_SUMMARY', 'VIEW_INVOICE'],
    },
    broker: {
      title: 'The transaction is now closed',
      description:
        'We have sent the closing statement to your email. If you wish to download the transaction summary or the invoice, please click below.',
    },
  },
};

const arbitrationInstructions = {
  65: {
    buyer: {
      title: 'The transaction is under arbitration',
      description:
        'Please coordinate with the seller to register a third party arbitrator for the transaction.',
    },
    seller: {
      title: 'The transaction is under arbitration',
      description:
        'Please coordinate with the buyer to register a third party arbitrator for the transaction.',
    },
    broker: {
      title: 'The transaction is under arbitration',
      description:
        'The buyer and seller must coordinate to register a third party arbitrator for the transaction.',
    },
  },
};

const dnhInstructions = {
  agreement: {
    buyer: {
      title: 'Submit the signed DNH agreement',
      description:
        'We have sent the DNH agreement via email. Please sign the said document, and submit back to dnholding@escrow.com.',
    },
    seller: {
      title: 'Submit the signed DNH agreement',
      description:
        'We have sent the DNH agreement via email. Please sign the said document, and submit back to dnholding@escrow.com.',
    },
    broker: {
      title: 'Waiting for the signed DNH agreement ',
      description:
        'We have sent the DNH agreement to the buyer and seller via email. Once submitted, this transaction may proceed. Contact dnholding@escrow.com for any concerns.',
    },
  },
  singlePayment: {
    buyer: {
      title: 'Pay for the amount due on {scheduleDueDate}',
      description:
        'Your payment of {scheduleAmount} is due on {scheduleDueDate}. If you wish to pay via wire transfer, you may view the wire instructions below. Contact dnholding@escrow.com for any concerns.',
      secondaryCtas: ['WIRE_INSTRUCTIONS'],
    },
    seller: {
      title: "Waiting for the buyer's payment due on {scheduleDueDate}",
      description:
        "The buyer's payment of {scheduleAmount} is due on {scheduleDueDate}. Contact dnholding@escrow.com for any concerns.",
    },
    broker: {
      title: "Waiting for the buyer's payment due on {scheduleDueDate}",
      description:
        "The buyer's payment of {scheduleAmount} is due on {scheduleDueDate}. Contact dnholding@escrow.com for any concerns.",
    },
  },
  multiPaymentFirst: {
    buyer: {
      title: 'Pay for the amount due on {scheduleDueDate}',
      description:
        'Your first payment of {scheduleAmount} is due on {scheduleDueDate}. If you wish to pay via wire transfer, you may view the wire instructions below. Contact dnholding@escrow.com for any concerns.',
      secondaryCtas: ['WIRE_INSTRUCTIONS'],
    },
    seller: {
      title: "Waiting for the buyer's payment due on {scheduleDueDate}",
      description:
        "The buyer's first payment of {scheduleAmount} is due on {scheduleDueDate}. Contact dnholding@escrow.com for any concerns.",
    },
    broker: {
      title: "Waiting for the buyer's payment due on {scheduleDueDate}",
      description:
        "The buyer's first payment of {scheduleAmount} is due on {scheduleDueDate}. Contact dnholding@escrow.com for any concerns.",
    },
  },
  multiPaymentMidway: {
    buyer: {
      title: 'Pay for the amount due on {scheduleDueDate}',
      description:
        'Your next payment of {scheduleAmount} is due on {scheduleDueDate}. If you wish to pay via wire transfer, you may view the wire instructions below. Contact dnholding@escrow.com for any concerns.',
      secondaryCtas: ['WIRE_INSTRUCTIONS'],
    },
    seller: {
      title: "Waiting for the buyer's payment due on {scheduleDueDate}",
      description:
        "The buyer's next payment of {scheduleAmount} is due on {scheduleDueDate}. Contact dnholding@escrow.com for any concerns.",
    },
    broker: {
      title: "Waiting for the buyer's payment due on {scheduleDueDate}",
      description:
        "The buyer's next payment of {scheduleAmount} is due on {scheduleDueDate}. Contact dnholding@escrow.com for any concerns.",
    },
  },
  multiPaymentFinal: {
    buyer: {
      title: 'Pay for the amount due on {scheduleDueDate}',
      description:
        'Your final payment of {scheduleAmount} is due on {scheduleDueDate}. If you wish to pay via wire transfer, you may view the wire instructions below. Contact dnholding@escrow.com for any concerns.',
      secondaryCtas: ['WIRE_INSTRUCTIONS'],
    },
    seller: {
      title: "Waiting for the buyer's payment due on {scheduleDueDate}",
      description:
        "The buyer's final payment of {scheduleAmount} is due on {scheduleDueDate}. Contact dnholding@escrow.com for any concerns.",
    },
    broker: {
      title: "Waiting for the buyer's payment due on {scheduleDueDate}",
      description:
        "The buyer's final payment of {scheduleAmount} is due on {scheduleDueDate}. Contact dnholding@escrow.com for any concerns.",
    },
  },
  paymentDone: {
    buyer: {
      title: 'All payments have been made',
      description:
        'You have sent your final payment. Contact dnholding@escrow.com for any concerns.',
    },
    seller: {
      title: 'All payments have been made',
      description:
        'The buyer has sent their final payment. Contact dnholding@escrow.com for any concerns.',
    },
    broker: {
      title: 'All payments have been made',
      description:
        'The buyer has sent their final payment. Contact dnholding@escrow.com for any concerns.',
    },
  },
};

const msInstructions = {
  22: {
    buyer: {
      title: 'Payment received and is under review',
      description:
        'Your payment is being reviewed. Once approved, we will notify the seller to transfer the domain name to you.',
    },
    seller: {
      title: 'Payment received and is under review',
      description:
        "We have received the buyer's payment. Please wait until we approve the payment before initiating the domain name transfer.",
    },
    broker: {
      title: 'Payment received and is under review',
      description:
        "We have received the buyer's payment. The seller must wait until we approve the payment before initiating the domain name transfer.",
    },
  },
  26: {
    buyer: {
      title: 'Waiting for the seller to provide milestone updates',
      description:
        'Once the seller has provided updates on milestone fulfillment, Escrow.com will notify you and request for your review.',
    },
    seller: {
      title: 'Provide updates on milestone progress',
      description:
        'Once you have provided updates on milestone fulfillment, the buyer will be notified for their review.',
      primaryCta: 'Update milestone progress',
    },
    broker: {
      title: 'Waiting for the seller to provide milestone updates',
      description:
        "Once the seller has provided updates on milestone fulfillment, Escrow.com will notify you and request for the buyer's review.",
    },
  },
  66: {
    buyer: {
      title: 'All milestones have been fulfilled and accepted',
      description:
        "We will now process the transfer of the last milestone's payment. Once the transaction is closed, we will send both parties a copy of the closing statement.",
    },
    seller: {
      title: 'All milestones have been fulfilled and accepted',
      description:
        'We are working on transferring the last milestone payment to your nominated bank account. After the transaction is closed, please expect the payment to arrive within **1-3** business days.',
    },
    broker: {
      title: 'All milestones have been fulfilled and accepted',
      description:
        'We are working on processing the closure of this milestone transaction. Closing statements will be sent to all parties once the transaction is closed.',
    },
  },
  67: {
    buyer: {
      title: 'The milestones have been partially accepted',
      description:
        'Negotiation for the rejected milestones will be started. Once a decision has been made, this transaction will be updated accordingly.',
    },
    seller: {
      title: 'The milestones have been partially accepted',
      description:
        'Negotiation for the rejected milestones will be started. Once a decision has been made, this transaction will be updated accordingly.',
    },
    broker: {
      title: 'The milestones have been partially accepted',
      description:
        'Negotiation for the rejected milestones will be started. Once a decision has been made, this transaction will be updated accordingly.',
    },
  },
  68: {
    buyer: {
      title: 'All milestones have been rejected',
      description:
        'We are working on transferring the refund to your nominated bank account. After the transaction is closed, please expect the refund to arrive within **1-3** business days.',
    },
    seller: {
      title: 'All milestones have been rejected',
      description:
        'We will now process the refund of the buyer. Once the transaction is closed, we will send both parties a copy of the closing statement.',
    },
    broker: {
      title: 'All milestones have been rejected',
      description:
        "We are working on transferring the refund to the buyer's nominated bank account. Once the transaction is closed, we will send all parties a copy of the closing statement.",
    },
  },
};

const msStatusInstructions = {
  buyerAndSellerActionRequired: {
    buyer: {
      title: 'Updates regarding the milestones of the transaction are available',
      description:
        'Your review of the milestone updates is required. Kindly click on the button below to view the required action for each milestone.',
      primaryCta: 'Review milestone updates',
    },
    seller: {
      title: 'Updates regarding the milestones of the transaction are available',
      description:
        'Your review of the milestone updates is required. Kindly click on the button below to view the required action for each milestone.',
      primaryCta: 'Review milestone updates',
    },
    broker: {
      title: 'Updates regarding the milestones of the transaction are available',
      description: 'Both the buyer and seller must review the milestone updates.',
    },
  },
  buyerActionRequiredAndSellerNoActionRequired: {
    buyer: {
      title: 'Updates regarding the milestones of the transaction are available',
      description:
        'Your review of the milestone updates is required. Kindly click on the button below to view the required action for each milestone.',
      primaryCta: 'Review milestone updates',
    },
    seller: {
      title: 'Updates regarding the milestones of the transaction are available',
      description: 'The buyer must review the latest milestone updates.',
    },
    broker: {
      title: 'Updates regarding the milestones of the transaction are available',
      description: 'The buyer must review the latest milestone updates.',
    },
  },
  buyerNoActionRequiredSellerActionRequired: {
    buyer: {
      title: 'Updates regarding the milestones of the transaction are available',
      description: 'The seller must review the latest milestone updates.',
    },
    seller: {
      title: 'Updates regarding the milestones of the transaction are available',
      description:
        'Your review of the milestone updates is required. Kindly click on the button below to view the required action for each milestone.',
      primaryCta: 'Review milestone updates',
    },
    broker: {
      title: 'Updates regarding the milestones of the transaction are available',
      description: 'No action required',
    },
  },
  buyerAndSellerNoActionRequired: {
    buyer: {
      title: 'No further actions are needed for the milestones of the transaction',
      description:
        'Escrow.com will notify you in case any further actions will be needed for this transaction.',
    },
    seller: {
      title: 'No further actions are needed for the milestones of the transaction',
      description:
        'Escrow.com will notify you in case any further actions will be needed for this transaction.',
    },
    broker: {
      title: 'No further actions are needed for the milestones of the transaction',
      description:
        'Escrow.com will notify you in case any further actions will be needed for this transaction.',
    },
  },
};

// dnh status instructions that come from common instructions
const dnhCommonStatuses = [0, 3, 5, 6, 9, 10, 70, 75, 80, 85];

const GeneralEscrowInstructionLink = ({ description }) => (
  <React.Fragment>
    <SmallText>
      {description} Please refer to Section 10 of the{' '}
      <SmallLink href={urlfor('.escrow-101/general-escrow-instructions')}>
        General Escrow Instructions
      </SmallLink>{' '}
      for details regarding a dispute.
    </SmallText>
  </React.Fragment>
);

/* eslint-disable react/display-name */
/**
 * Maps the cta type to react components.
 *
 * props will contain the following keys:
 *   - transactionId
 * @see TransactionInstruction.jsx
 */
export const ctaMapping = {
  SELECT_DN_TRANSFER_METHOD: (props) => <SelectDomainTransferMethod {...props} />,
  ITEM_ACCEPTANCE_CONFIRMATION: (props) => <ItemAcceptanceConfirmation {...props} />,
  ITEM_REJECTION: (props) => <ItemRejection {...props} />,
  ITEM_RECEIPT_CONFIRMATION: (props) => <ItemReceiptConfirmation {...props} />,
  VERIFY_ACCOUNT: ({ transactionId }) => {
    const urlParams = new URLSearchParams({
      flow: 'checkout',
      redirect: `/transaction/${transactionId}`,
    });
    return <EscrowLinkButton to={`/verify?${urlParams}`} buttonText="Verify my account" hard />;
  },
  UPLOAD_DOCUMENTS: ({ transactionId }) => (
    <EscrowLinkButton
      to={`/account-info/upload-documents?trans-id=${transactionId}`}
      buttonText="Upload documents"
      hard
    />
  ),
  SELECT_DISBURSEMENT_METHOD: (props) => <SelectDisbursementMethod {...props} />,
  MODIFY_TRANSACTION: (props) => <ModifyTransaction {...props} />,
  ADD_TRACKING_INFORMATION: (props) => <AddTrackingInformation {...props} />,
  TRANSACTION_AGREE: (props) => <TransactionAgreement {...props} />,
  SELECT_PAYMENT_METHOD: (props) => (
    <EscrowLinkButton
      to={`/transactions/${props.transactionId}/payment`}
      buttonText="Select a payment method"
      hard
    />
  ),
  VIEW_TRANSACTION_SUMMARY: ({ transactionId }) => (
    <EscrowLinkButton
      to={`/transaction/${transactionId}/summary`}
      variant="outlined"
      buttonText="View transaction summary"
      hard
    />
  ),
  VIEW_INVOICE: ({ transactionId }) => (
    <EscrowLinkButton
      to={`/transaction/${transactionId}/tax-invoice`}
      buttonText="View invoice"
      variant="outlined"
      hard
    />
  ),
  CONFIRM_HANDOVER: (props) => <ConfirmHandover {...props} />,
  WIRE_INSTRUCTIONS: ({ transactionId }) => <WireInstructions transactionId={transactionId} />,
  CREDIT_CARD_INSTRUCTIONS: ({ paymentLink }) => <CreditCardInstructions paymentLink={paymentLink} />,
  CANCEL_TRANSACTION: (props) => <CancelTransaction {...props} />,
};

function getCancellationInstruction(role, cancellationStatus) {
  if (!cancellationStatus) {
    // Shouldn't happen, but just in case
    return {
      title: 'The transaction will be cancelled.',
    };
  }
  if (!(role in cancellationStatus)) {
    return cancellationInstructions.other_party;
  }

  if (!cancellationStatus.buyer && !cancellationStatus.seller) {
    return cancellationInstructions.first_approval_required;
  }

  let instruction;
  if (!cancellationStatus[role]) {
    instruction = cancellationInstructions.second_approval_required;
  } else {
    instruction = cancellationInstructions.approval_given;
  }
  return instruction;
}

export function getDnhInstruction(
  transactionStatus,
  role,
  paymentSchedule,
  currencyCode,
  buyerFees,
  cancellationStatus
) {
  let instruction;
  if (transactionStatus === 19) {
    instruction = dnhInstructions.agreement[role];
  } else if (transactionStatus === 75) {
    instruction = getCancellationInstruction(role, cancellationStatus);
  } else if (transactionStatus === 21) {
    const nextPaymentIdx = paymentSchedule.findIndex((schedule) => schedule.paidDate === undefined);
    if (nextPaymentIdx === 0) {
      if (paymentSchedule.length === 1) {
        instruction = dnhInstructions.singlePayment[role];
      } else {
        instruction = dnhInstructions.multiPaymentFirst[role];
      }
    } else if (nextPaymentIdx === -1) {
      instruction = dnhInstructions.paymentDone[role];
    } else if (nextPaymentIdx === paymentSchedule.length - 1) {
      instruction = dnhInstructions.multiPaymentFinal[role];
    } else {
      // nextPaymentIdx < paymentSchedule.length - 1
      instruction = dnhInstructions.multiPaymentMidway[role];
    }
    if (nextPaymentIdx !== -1) {
      const nextPayment = paymentSchedule[nextPaymentIdx];
      const nextPaymentDate = format(parseDate(nextPayment.dueDate), 'MMMM d, yyyy, h:mm a z');
      let amount = parseAmount(nextPayment.amount);
      if (nextPaymentIdx === 0) {
        amount += parseAmount(buyerFees.domainNameHolding) + parseAmount(buyerFees.escrowFee);
      }
      instruction.description = instruction.description
        .replace('{scheduleDueDate}', nextPaymentDate)
        .replace('{scheduleAmount}', formatCurrency(amount, currencyCode));
      instruction.title = instruction.title.replace('{scheduleDueDate}', nextPaymentDate);
    }
  } else if (dnhCommonStatuses.includes(transactionStatus)) {
    instruction = commonInstructions[transactionStatus][role];
  }

  return instruction;
}

export function getInstruction(
  transactionType,
  transactionStatus,
  shipping,
  arbitration,
  role,
  kycRequired,
  additionalDocumentsRequested,
  disbursementMethodRequired,
  paymentMethod,
  cancellationStatus,
  transactionItems,
  skipStripe
) {
  let instruction;
  if (disbursementMethodRequired) {
    instruction = {
      title: 'Select a disbursement method',
      description:
        'Please select how Escrow.com will transfer the funds to you once this transaction is complete.',
      primaryCta: 'SELECT_DISBURSEMENT_METHOD',
    };
  } else if (additionalDocumentsRequested && additionalDocumentsRequested.length > 0) {
    instruction = {
      title: 'Upload requested documents for the transaction',
      descriptionHtml: (
        <React.Fragment>
          <SmallText>
            In compliance with AML regulations, the following documents are required to proceed with
            this transaction:
          </SmallText>
          <ul
            data-testid="requested-documents-list"
            style={{ listStyleType: 'disc', marginLeft: '2em' }}
          >
            {additionalDocumentsRequested.map((doc, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index}>
                <SmallText>{doc}</SmallText>
              </li>
            ))}
          </ul>
        </React.Fragment>
      ),
      primaryCta: 'UPLOAD_DOCUMENTS',
    };
  } else if (arbitration && transactionStatus in arbitrationInstructions) {
    instruction = arbitrationInstructions[transactionStatus][role];
    instruction.descriptionHtml = (
      <GeneralEscrowInstructionLink description={instruction.description} />
    );
  } else if (transactionStatus === 75) {
    instruction = getCancellationInstruction(role, cancellationStatus);
  } else if (transactionStatus in commonInstructions) {
    instruction = commonInstructions[transactionStatus][role];

    if (transactionStatus === 20 && role === 'buyer' && paymentMethod === 'wire_transfer') {
      instruction = commonInstructions[transactionStatus].buyer_wire;
    } if (!skipStripe && transactionStatus === 20 && role === 'buyer' && paymentMethod === 'credit_card') {
      instruction = commonInstructions[transactionStatus].buyer_cc;
    }
  } else if (transactionType === 'milestone' && transactionStatus === 26) {
    const buyerOrSellerActionRequired = [
      'shipped',
      'rejected',
      'shipped_returned',
      'received_returned',
    ];
    const buyerNoActionRequired = [
      'accepted',
      'disbursed_accepted',
      'shipped_returned',
      'received_returned',
      'accepted_returned',
      'rejected_returned',
    ];
    const sellerNoActionRequired = [
      'shipped',
      'received',
      'accepted',
      'disbursed_accepted',
      'disbursed_rejected',
      'accepted_returned',
    ];
    let paymentSecuredInstruction = transactionItems.every((item) =>
      Object.values(item.status).every((status) => status === false)
    );
    let buyerNoActionInstruction = transactionItems.every(
      (item) =>
        buyerNoActionRequired.some((status) => item.status[status]) ||
        Object.values(item.status).every((status) => status === false)
    );
    let sellerNoActionInstruction = transactionItems.every((item) =>
      sellerNoActionRequired.some((status) => item.status[status])
    );
    let buyerOrSellerActionInstruction = buyerOrSellerActionRequired.some((status) =>
      transactionItems.some((item) => item.status[status])
    );
    if (paymentSecuredInstruction) {
      instruction = msInstructions[transactionStatus][role];
    } else if (buyerNoActionInstruction && sellerNoActionInstruction) {
      instruction = msStatusInstructions['buyerAndSellerNoActionRequired'][role];
    } else if (sellerNoActionInstruction) {
      instruction = msStatusInstructions['buyerActionRequiredAndSellerNoActionRequired'][role];
    } else if (buyerNoActionInstruction) {
      instruction = msStatusInstructions['buyerNoActionRequiredSellerActionRequired'][role];
    } else if (buyerOrSellerActionInstruction) {
      instruction = msStatusInstructions['buyerAndSellerActionRequired'][role];
    }
  } else if (transactionType === 'milestone') {
    instruction = msInstructions[transactionStatus][role];
  } else {
    let typeCommonInstructions;
    let typeShippingInstructions;
    let typeNoShippingInstructions;
    if (transactionType === 'motor_vehicle') {
      typeCommonInstructions = mvCommonInstructions;
      typeShippingInstructions = mvShippingInstructions;
      typeNoShippingInstructions = mvNoShippingInstructions;
    } else if (transactionType === 'general_merchandise') {
      typeCommonInstructions = gmCommonInstructions;
      typeShippingInstructions = gmShippingInstructions;
      typeNoShippingInstructions = gmNoShippingInstructions;
    } else if (transactionType === 'domain_name') {
      typeCommonInstructions = dnInstructions;
    }

    if (transactionStatus in typeCommonInstructions) {
      instruction = typeCommonInstructions[transactionStatus][role];
      if (transactionStatus === 65) {
        instruction.descriptionHtml = (
          <GeneralEscrowInstructionLink description={instruction.description} />
        );
      }
    } else if (shipping && transactionStatus in typeShippingInstructions) {
      instruction = typeShippingInstructions[transactionStatus][role];
    } else if (!shipping && transactionStatus in typeNoShippingInstructions) {
      instruction = typeNoShippingInstructions[transactionStatus][role];
    }
  }

  if (
    ((instruction &&
      instruction.primaryCta === undefined &&
      instruction.secondaryCtas === undefined) ||
      [20, 22].includes(transactionStatus)) &&
    kycRequired
  ) {
    instruction = {
      title: 'Verify your Escrow.com account',
      description:
        'We protect all parties in a transaction by verifying the identity of the involved users. The verification of your account is required.',
      primaryCta: 'VERIFY_ACCOUNT',
    };
  }

  if (instruction === undefined) {
    instruction = {
      title: 'Invalid transaction status',
      description: 'Please contact Escrow.com support.',
    };
  }
  return instruction;
}
