import React, { Fragment, useRef, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';

import { Stack, MenuItem, TextField, FormHelperText } from '@mui/material';
import {
  EventAvailableOutlined,
  WbIncandescentOutlined,
  ArticleOutlined,
} from '@mui/icons-material';

import { date } from 'spa/components/form/validate';
import { saveSelectedID, kycSelectedIDSelector } from 'spa/features/kyc/kycSlice';
import { V3KYCMobilePages as Pages, idTypeOptions } from 'spa/constants/VerificationConstants';

import MobilePageFormControl from 'spa/features/kyc/mobile/components/MobilePageFormControl';
import { errorMessage, required } from 'spa/features/kyc/utils';
import { SmallText } from 'spa/features/ui/EscrowTypography';
import MobilePage from '../../components/MobilePage';
import ReminderIcon from '../../components/ReminderIcon';
import { SelectField } from '../../components/SelectField';
import { DatePickerField } from '../../components/DatePickerField';

const IDSelectionPage = ({ back }) => {
  const dispatch = useDispatch();

  const selectedID = useSelector(kycSelectedIDSelector);

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      idType: selectedID.type,
      idNumber: selectedID.number,
      idExpiry: selectedID.expiry ? moment(selectedID.expiry) : null,
    },
  });
  const [helperTextHeight, setHelperTextHeight] = useState(0);
  const [nextPageTrigger, setNextPageTrigger] = useState(false);

  const onSubmitID = useCallback(
    ({ idType, idNumber, idExpiry }) => {
      dispatch(saveSelectedID({ type: idType, number: idNumber, expiry: idExpiry }));
    },
    [dispatch]
  );

  const helperTextRef = useRef(null);

  useEffect(() => {
    setHelperTextHeight(helperTextRef.current.clientHeight);
  }, []);

  return (
    <MobilePage
      title="Select an ID to submit"
      ctaText="Continue"
      onSubmit={handleSubmit((data) => {
        if (isValid) {
          onSubmitID(data);
          setNextPageTrigger(true);
        }
      })}
      nextPageTrigger={nextPageTrigger}
      nextPage={Pages.ID_FRONT_INSTRUCTIONS}
    >
      <form>
        <Stack direction="column">
          <MobilePageFormControl>
            <Controller
              name="idType"
              control={control}
              rules={{ validate: { required } }}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <SelectField
                  error={!!error}
                  helpertext={errorMessage(error)}
                  {...field}
                  label="ID type"
                  id="idType"
                >
                  {idTypeOptions.map(({ label, value: idValue }) => (
                    <MenuItem key={idValue} value={idValue}>
                      {label}
                    </MenuItem>
                  ))}
                </SelectField>
              )}
            />
          </MobilePageFormControl>
          <MobilePageFormControl sx={{ marginBottom: `${32 - helperTextHeight}px` }}>
            <Controller
              name="idNumber"
              control={control}
              defaultValue=""
              rules={{ validate: { required } }}
              render={({ field, fieldState: { error } }) => (
                <Fragment>
                  <TextField
                    error={!!error}
                    helperText={errorMessage(error)}
                    label="ID number"
                    {...field}
                  />
                  {!error ? (
                    <FormHelperText ref={helperTextRef}>e.g. D01234567</FormHelperText>
                  ) : null}
                </Fragment>
              )}
            />
          </MobilePageFormControl>
          <MobilePageFormControl>
            <Controller
              name="idExpiry"
              control={control}
              rules={{ validate: { required, date } }}
              defaultValue={null}
              render={({ field, fieldState: { error } }) => (
                <DatePickerField
                  error={!!error}
                  helperText={errorMessage(error)}
                  {...field}
                  id="id-expiry"
                  label="Expiration date"
                  disablePast
                  ignoreTimezone
                />
              )}
            />
          </MobilePageFormControl>
          <SmallText variant="bold" sx={{ fontSize: '16px', marginBottom: '16px' }}>
            Reminders:
          </SmallText>
          <Stack direction="column" sx={{ gap: '8px' }}>
            <Stack direction="row" sx={{ gap: '8px' }}>
              <ReminderIcon Icon={EventAvailableOutlined} />
              <SmallText sx={{ fontSize: '16px' }}>Your ID must not be expired</SmallText>
            </Stack>
            <Stack direction="row" sx={{ gap: '8px' }}>
              <ReminderIcon Icon={WbIncandescentOutlined} />
              <SmallText sx={{ fontSize: '16px' }}>Make sure you are in a well lit room</SmallText>
            </Stack>
            <Stack direction="row" sx={{ gap: '8px' }}>
              <ReminderIcon Icon={ArticleOutlined} />
              <SmallText sx={{ fontSize: '16px' }}>
                Your information must be clear and legible
              </SmallText>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </MobilePage>
  );
};

export default IDSelectionPage;
