import React, { Component } from 'react';
import { connect } from 'react-redux';
import QueryString from 'query-string';
import { FieldArray, Field, reduxForm } from 'redux-form';
import { bindRoutineToReduxForm } from 'redux-saga-routines';
import { oneOrMoreFiles, required } from 'spa/components/form/validate';
import { FormError, ActionButton } from 'spa/components/form';
import { Spinner } from 'spa/components/Indicators';
import { submitDocumentForm as submitDocumentFormRoutine } from 'spa/actions/AdditionalDocumentsActions';
import { fileUploadingSelector } from 'spa/selectors/FileUploadSelectors';

import Select from '../form/Select';
import FileUpload from '../form/FileUpload/FileUpload';
import { gettext } from '../../../utils/filters';
import { fileValidatorFactory } from '../../../utils/FormValidation';

const fileValidator = fileValidatorFactory(
  ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
  100000000
);

const createOptionsFromDocRequests = (requests) => {
  const results = [];
  if (requests.length < 1) {
    return results;
  }

  requests.map((request) => {
    request.requested_documents.map((doc) => {
      results.push({
        label: doc.document_type,
        value: doc.id,
      });
    });
  });

  return results;
};

class AdditionalDocumentsForm extends Component {
  constructor(props) {
    super(props);

    this.handleGetDocuments = this.handleGetDocuments.bind(this);
  }

  componentDidMount() {
    const transactionId = QueryString.parse(window.location.search)['trans-id'];
    this.handleGetDocuments(transactionId);
  }

  handleGetDocuments(transactionId) {
    this.props.getDocuments(transactionId);
  }

  render() {
    const { loading, requiredDocs } = this.props;

    return (
      <div>
        <header className="account-section-header-content account-section-header-content--full">
          <div className="account-section-header-content">
            <h1 className="account-section-title">{gettext('Upload Additional Documents')}</h1>
          </div>
        </header>
        <div>
          {gettext(`In order to comply with AML regulations,
          you are required to submit the following documents:`)}
          <ul className="account-list">
            {!loading && requiredDocs
              ? requiredDocs.map((request) =>
                  request.requested_documents.map((doc) => (
                    <li key={`doc-${doc.id}`}>
                      {doc.document_type} {doc.s3_object_id ? '(Pending for approval)' : ''}
                    </li>
                  ))
                )
              : null}
          </ul>
          {gettext(`Please contact support@escrow.com if you
          need any assistance.`)}
        </div>
        <br />
        {!loading && requiredDocs ? (
          <div>
            {this.props.submitFailed && this.props.error && <FormError error={this.props.error} />}
            <form
              onSubmit={this.props.handleSubmit(bindRoutineToReduxForm(submitDocumentFormRoutine))}
            >
              <fieldset>
                <h2> {gettext('Upload File')} </h2>
                <Field
                  name="submissionId"
                  label={null}
                  placeholder={`-- ${gettext('Please select document to submit')} --`}
                  component={Select}
                  options={createOptionsFromDocRequests(this.props.requiredDocs)}
                  validate={[required]}
                  requiredIndicator
                />
                <FieldArray
                  name="documentFiles"
                  component={FileUpload}
                  label={gettext('Document Uploaded:')}
                  numFiles={1}
                  fileValidators={[fileValidator]}
                  uploadEndpoint={`/api/file/additional_documents`}
                  note={gettext(
                    'Allowed documents: jpg, jpeg, png, pdf - 100MB maximum file size.'
                  )}
                  validate={[oneOrMoreFiles]}
                />
                <ActionButton
                  className="btn btn--secondary"
                  name="submitDocument"
                  type="submit"
                  label={gettext('Submit Document')}
                  loading={this.props.submitting || this.props.isFileUploading}
                />
              </fieldset>
            </form>
          </div>
        ) : (
          <Spinner />
        )}
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isFileUploading: fileUploadingSelector(state),
});
export default connect(
  mapStateToProps,
  null
)(
  reduxForm({
    form: 'ADDITIONAL_DOCUMENTS_CREATE_FORM',
    enableReinitialize: true,
  })(AdditionalDocumentsForm)
);
