import window from 'window-shim';

import { fetchRequest } from './FetchUtils';

export default {
  isAvailable() {
    if (window.olark) {
      return true;
    }
    return false;
  },

  setOperatorGroup(groupId) {
    window.olark('api.chat.setOperatorGroup', {
      group: groupId,
    });
  },

  showConsole() {
    window.olark('api.box.show');
  },

  hideConsole() {
    window.olark('api.box.hide');
  },

  toggleConsoleVisibility(show) {
    if (this.isAvailable()) {
      if (show) {
        this.showConsole();
        return;
      }
      this.hideConsole();
    }
  },

  expandConsole() {
    window.olark('api.box.expand');
  },

  shrinkConsole() {
    window.olark('api.box.shrink');
  },

  notifyOperator(message) {
    window.olark('api.chat.sendNotificationToOperator', {
      body: message,
    });
  },

  sendVisitorMessage(message) {
    window.olark('api.chat.sendMessageToVisitor', {
      body: message,
    });
  },

  updateVisitorName(name) {
    window.olark('api.visitor.updateFullName', {
      fullName: name,
    });
  },

  defineRule(options) {
    window.olark('api.rules.defineRule', {
      id: options.id,
      description: options.description,
      condition: options.condition,
      action: options.action,
      perVisit: options.perVisit,
    });
  },

  sendMessageOnBeginConversation(message) {
    window.olark('api.chat.onBeginConversation', () => {
      window.olark('api.chat.sendNotificationToOperator', {
        body: message,
      });
    });
  },

  onOperatorsAvailable(cb) {
    window.olark('api.chat.onOperatorsAvailable', cb);
  },

  onOperatorsAway(cb) {
    window.olark('api.chat.onOperatorsAway', cb);
  },

  isConversing(cb) {
    window.olark('api.visitor.getDetails', (details) => {
      cb(details.isConversing);
    });
  },

  // calls an unsupported olark api
  queryOlarkState(groupId) {
    return fetchRequest(
      'GET',
      `https://rpc.olark.com/nrpc/?c=presence&site_id=${window.config.olark_account_id}&group_id=${groupId}&xhttp=t`,
      null,
      {
        Accept: 'application/json',
      },
      false
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return Promise.reject(res);
      })
      .catch((err) => Promise.reject(err));
  },

  isOperatorGroupAvailable(groupId) {
    return this.queryOlarkState(groupId)
      .then((state) => state.status_state === 'available')
      .catch(() => false);
  },
};
