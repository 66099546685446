import React, { Fragment } from 'react';
import ReactSVG from 'react-svg';
import { Button } from 'escrow-common-js/dist/components';
import { getCurrencyTagFromApiCurrency } from 'escrow-common-js/dist/utils';
import CheckoutConstants from 'spa/constants/CheckoutConstants';
import PaymentConstants from 'spa/constants/PaymentConstants';
import Currency from 'spa/components/Currency';
import { reduxForm, formValues } from 'redux-form';
import { gettext } from '../../../../utils/filters';
import ListOption from './ListOption';

const getPaymentSelectTracking = (paymentType) => {
  const { WIRE_TRANSFER, PAYPAL, CREDIT_CARD, DIRECT_DEBIT } = PaymentConstants.PAYMENT_METHODS;
  switch (paymentType) {
    case WIRE_TRANSFER:
      return 'continue-wire';
    case PAYPAL:
      return 'continue-pp';
    case CREDIT_CARD:
      return 'continue-cc';
    case DIRECT_DEBIT:
      return 'continue-ach';
    default:
      return 'continue';
  }
};

const SelectPaymentButton = ({ paymentType, authorizedPayments }) => {
  switch (paymentType) {
    case PaymentConstants.PAYMENT_METHODS.PAYPAL:
      if (!authorizedPayments[PaymentConstants.PAYMENT_METHODS.PAYPAL])
        return <div className="checkout-card-btn checkout-card-btn--paypal" />;
      break;
    default:
  }
  return (
    <Button
      disabled={!paymentType}
      type="submit"
      className="checkout-card-btn btn btn--secondary"
      data-e2e-target="checkout-form-action"
      data-tracking-name={getPaymentSelectTracking(paymentType)}
    >
      {gettext('Continue payment')}
    </Button>
  );
};

const createPayPalOrder = (actions, transactionTotal, currency) =>
  actions.order.create({
    purchase_units: [
      {
        amount: {
          currency,
          value: transactionTotal,
        },
      },
    ],
  });

class PaymentMethodSelectionForm extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.renderPaymentOption = this.renderPaymentOption.bind(this);
    this.initForSelectedPaymentType = this.initForSelectedPaymentType.bind(this);
  }

  componentDidMount() {
    const { paymentType } = this.props;
    if (paymentType) this.initForSelectedPaymentType(paymentType);
  }

  componentDidUpdate(prevProps) {
    const { paymentType } = this.props;
    if (paymentType && prevProps.paymentType !== paymentType) {
      this.initForSelectedPaymentType(paymentType);
    }
  }

  onSubmit({ paymentType, details }) {
    const { setPaypalOrderDetails, nextPage, authorizedPayments } = this.props;
    const { PAYPAL } = PaymentConstants.PAYMENT_METHODS;
    if (paymentType === PAYPAL) {
      let orderDetails = details;
      const savedOrderDetails = authorizedPayments[PaymentConstants.PAYMENT_METHODS.PAYPAL];
      if (savedOrderDetails) {
        orderDetails = savedOrderDetails;
      }
      setPaypalOrderDetails(orderDetails);
    }
    nextPage(paymentType);
  }

  initForSelectedPaymentType(paymentType) {
    const { paymentMethods, currency } = this.props;
    const { PAYPAL } = PaymentConstants.PAYMENT_METHODS;
    const transactionTotal = (
      paymentMethods.find((paymentMethod) => paymentMethod.type === paymentType) || {}
    ).total;
    switch (paymentType) {
      case PAYPAL:
        if (transactionTotal) this.renderPayPalButton(transactionTotal, currency);
        break;
      default:
    }
  }

  renderPayPalButton(transactionTotal, currency) {
    const { PAYPAL } = PaymentConstants.PAYMENT_METHODS;
    const paypal = window.paypal;
    paypal
      .Buttons({
        style: {
          color: 'blue',
          label: 'pay',
          height: 42,
        },
        fundingSource: paypal.FUNDING.PAYPAL,
        createOrder: (data, actions) => createPayPalOrder(actions, transactionTotal, currency),
        onApprove: (data, actions) =>
          actions.order.get().then((details) => this.onSubmit({ paymentType: PAYPAL, details })),
      })
      .render('.checkout-card-btn--paypal');
  }

  renderPaymentOption(paymentMethod, total, disabled) {
    const { setPaymentType, currency, paymentType } = this.props;
    let title;
    let detail;
    let logo;
    let badgeText;
    let content;
    let showContent = false;
    switch (paymentMethod) {
      case PaymentConstants.PAYMENT_METHODS.DIRECT_DEBIT:
        title = (
          <Fragment>
            Bank Debit
            <br />
            (ACH - US Only)
          </Fragment>
        );
        detail = 'No additional processing fees.';
        logo = '../../../../build/images/payment-methods/ach.svg';
        badgeText = 'RECOMMENDED';
        content = currency && (
          <div className="checkout-paymentOption-text-detail">
            <span>{gettext('You will be debited ')}</span>
            <span className="checkout-paymentOption-text-detail--bold">
              <Currency
                code={getCurrencyTagFromApiCurrency(currency)}
                amount={parseFloat(total)}
                withTag
              />
            </span>
            <span>{gettext('.')}</span>
            <div>{gettext('The full amount will be debited from your selected account.')}</div>
          </div>
        );
        showContent = paymentType === PaymentConstants.PAYMENT_METHODS.DIRECT_DEBIT;
        break;
      case PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER: {
        title = 'Wire Transfer';
        detail = 'Fees may apply depending on your bank.';
        logo = '../../../../build/images/payment-methods/wire-v2.svg';
        break;
      }
      case PaymentConstants.PAYMENT_METHODS.CREDIT_CARD:
        title = 'Credit or Debit Card';
        detail = total ? (
          <span>
            {/* {'Fee: '}
            <Currency
              code={getCurrencyTagFromApiCurrency(this.props.currency)}
              amount={total - this.props.totalWithoutPaymentFee}
              withTag
            />
            {'.'} */}
            {'You will be redirected to a hosted payment page at the end of checkout.'}
          </span>
        ) : (
          <span>
            Processing fees may apply. <i>Verification Required. </i>
          </span>
        );
        logo = '../../../../build/images/payment-methods/card-v2.svg';
        break;
      case PaymentConstants.PAYMENT_METHODS.PAYPAL:
        title = 'PayPal';
        detail = total ? (
          <span>
            {'Fee: '}
            <Currency
              code={getCurrencyTagFromApiCurrency(this.props.currency)}
              amount={total - this.props.totalWithoutPaymentFee}
              withTag
            />
            {'.'}
          </span>
        ) : (
          <span>
            Processing fees may apply. <i>Verification Required. </i>
          </span>
        );
        logo = '../../../../build/images/payment-methods/paypal.svg';
        break;
      default:
        break;
    }
    logo = <ReactSVG src={logo} />;

    return (
      <ListOption
        id={`${paymentMethod}`}
        key={`${paymentMethod}`}
        title={title}
        name="paymentType"
        logo={logo}
        subtitle={detail}
        content={content}
        showContent={showContent}
        onChange={() => setPaymentType(paymentMethod)}
        badgeText={badgeText}
        disabled={disabled}
      />
    );
  }

  render() {
    const {
      paymentMethods,
      paymentType,
      form,
      handleSubmit,
      authorizedPayments,
    } = this.props;

    const availablePaymentOptions = CheckoutConstants.ENABLED_PAYMENT_METHODS_V2.reduce(
      (acc, paymentOption) => {
        const available = paymentMethods.find((method) => method.type === paymentOption);
        if (available) {
          return [...acc, available];
        }
        return acc;
      },
      []
    );

    return (
      <form
        name={form}
        onSubmit={handleSubmit(this.onSubmit)}
        data-tracking-subsection="payment-method-selection"
      >
        <h3 className="checkout-page-header">How would you like to pay?</h3>
        <div>
          {availablePaymentOptions.map((paymentMethod) =>
            this.renderPaymentOption(paymentMethod.type, paymentMethod.total)
          )}
        </div>
        <div className="checkout-page-form-actions">
          <SelectPaymentButton paymentType={paymentType} authorizedPayments={authorizedPayments} />
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: CheckoutConstants.PAYMENT_METHOD_SELECTION_FORM,
  enableReinitialize: false,
  destroyOnUnmount: false,
})(formValues('paymentType')(PaymentMethodSelectionForm));
