import { createRoutine } from 'redux-saga-routines';

import Webhooks from 'spa/constants/WebhooksConstants';

// GET
export function getWebhooksRequest() {
  return {
    type: Webhooks.GET_WEBHOOKS_REQUEST,
  };
}

export function getWebhooksSuccess(webhooks) {
  return {
    type: Webhooks.GET_WEBHOOKS_SUCCESS,
    webhooks,
  };
}

export function getWebhooksFailure(message) {
  return {
    type: Webhooks.GET_WEBHOOKS_FAILURE,
    message,
  };
}

// CREATE
export const createWebhooks = createRoutine('CREATE_WEBHOOKS');

// DELETE
export function deleteWebhooksRequest(id) {
  return {
    type: Webhooks.DELETE_WEBHOOKS_REQUEST,
    id,
  };
}

export function deleteWebhooksSuccess(id) {
  return {
    type: Webhooks.DELETE_WEBHOOKS_SUCCESS,
    id,
  };
}

export function deleteWebhooksFailure(webhook) {
  return {
    type: Webhooks.DELETE_WEBHOOKS_FAILURE,
    webhook,
  };
}
