import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { MenuItem, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FmdGoodOutlined, EventAvailableOutlined, ArticleOutlined } from '@mui/icons-material';
import {
  saveSelectedPOA,
  kycSelectedPOASelector,
  updateKycImageId,
  updateUploadedFilesInfo,
} from 'spa/features/kyc/kycSlice';
import { CustomFormControl } from 'spa/features/transaction/components/modify-transaction/CustomFormControl';
import {
  V3KYCMobilePages as Pages,
  addressProofTypeOptions,
  KYC_FILES as FILES,
  ERROR_TYPES,
} from 'spa/constants/VerificationConstants';
import { errorMessage, required } from 'spa/features/kyc/utils';
import { SmallText, MediumText } from 'spa/features/ui/EscrowTypography';
import { useUploadFileMutation } from 'spa/features/kyc/kycApi';
import MobilePage from '../../components/MobilePage';
import { SelectField } from '../../components/SelectField';
import UploadFileButton from '../../components/ExtraButtons/UploadFileButton';
import KYCAlert from '../../components/KYCAlert';

const iconStyles = (theme) => ({
  width: '20px',
  height: '20px',
  color: theme.palette.secondaryLight.dark,
  borderColor: theme.palette.mono.xxlight,
  fontSize: '16px',
});

const POASelectionPage = () => {
  const dispatch = useDispatch();

  const [nextPageTrigger, setNextPageTrigger] = useState(false);
  const [
    uploadFile,
    {
      data: uploadData,
      isSuccess: isSuccessUpload,
      isLoading: isLoadingUpload,
      error: uploadError,
    },
  ] = useUploadFileMutation();

  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
  } = useForm({
    defaultValues: {
      poaType: useSelector(kycSelectedPOASelector).type,
    },
  });

  const onSubmitPOA = useCallback(
    ({ poaType }) => {
      dispatch(saveSelectedPOA({ type: poaType }));
    },
    [dispatch]
  );

  const theme = useTheme();
  const customIconStyles = iconStyles(theme);

  // eslint-disable-next-line no-console
  console.log('errors: ', errors);
  // eslint-disable-next-line no-console
  console.log('isValid', isValid);

  // eslint-disable-next-line no-console
  console.log(
    'uploadData, isSuccessUpload, isLoadingUpload, error',
    uploadData,
    isSuccessUpload,
    isLoadingUpload,
    uploadError
  );

  const [nextPage, setNextPage] = useState(Pages.POA_INSTRUCTIONS);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (isSubmitting && isSuccessUpload && uploadData) {
      const uploadId = uploadData.id;
      if (uploadId) {
        dispatch(updateKycImageId({ fileKey: FILES.ADDRESS, id: uploadId }));
        setNextPage(Pages.POA_FILE_REVIEW);
        setNextPageTrigger(true);
      }
    }
  }, [uploadData, dispatch, isSuccessUpload, isSubmitting]);

  useEffect(() => {
    if (isSubmitting && uploadError) {
      setIsSubmitting(false);
    }
  }, [isSubmitting, uploadError]);

  const disabled = isLoadingUpload || isSubmitting;

  return (
    <form>
      <MobilePage
        title="Select a proof of address"
        ctaText="Take a photo"
        subtitle="Select a document to upload"
        onSubmit={handleSubmit((data) => {
          if (isValid) {
            onSubmitPOA(data);
            setNextPage(Pages.POA_INSTRUCTIONS);
            setNextPageTrigger(true);
          }
        })}
        nextPage={nextPage}
        nextPageTrigger={nextPageTrigger}
        disableButtons={disabled}
        ExtraButton={(props) => (
          <UploadFileButton
            {...props}
            control={control}
            fileKey={FILES.ADDRESS}
            onSubmit={handleSubmit((formData) => {
              if (isValid) {
                onSubmitPOA(formData);
                // To allow time for the file to API calls to end
                // before moving to the next page,
                // we instead set the Submit state to true
                setIsSubmitting(true);
              }
            })}
            onUploadFile={(file) => {
              if (isValid) {
                uploadFile(file);
                dispatch(
                  updateUploadedFilesInfo({
                    fileKey: FILES.ADDRESS,
                    fileName: file.name,
                    fileSize: file.size,
                  })
                );
              }
            }}
            disabled={disabled}
          >
            Upload a file
          </UploadFileButton>
        )}
      >
        {uploadError && (
          <KYCAlert
            isError={uploadError}
            errorType={ERROR_TYPES.FILE_UPLOAD_FAILED}
            xRequestId={uploadError.xRequestId}
          />
        )}
        <div>
          <CustomFormControl>
            <Controller
              name="poaType"
              control={control}
              defaultValue=""
              rules={{ validate: { required } }}
              render={({ field, fieldState: { error } }) => (
                <SelectField
                  {...field}
                  error={!!error}
                  helpertext={errorMessage(error)}
                  id="poa-type"
                  label="Document type"
                >
                  {addressProofTypeOptions.map(({ label, value: idValue }) => (
                    <MenuItem key={idValue} value={idValue}>
                      {label}
                    </MenuItem>
                  ))}
                </SelectField>
              )}
            />
          </CustomFormControl>
          <MediumText
            style={{
              paddingTop: '12px',
              paddingBottom: '12px',
              color: theme.palette.secondary.dark,
              fontSize: '16px',
            }}
          >
            Reminders:
          </MediumText>
          <Stack direction="column" sx={{ gap: '8px' }}>
            <Stack direction="row" sx={{ gap: '8px' }}>
              <FmdGoodOutlined
                sx={{
                  ...customIconStyles,
                }}
              />
              <SmallText sx={{ fontSize: '16px' }}>
                Document must contain your full address
              </SmallText>
            </Stack>
            <Stack direction="row" sx={{ gap: '8px' }}>
              <EventAvailableOutlined
                sx={{
                  ...customIconStyles,
                }}
              />
              <SmallText sx={{ fontSize: '16px' }}>
                Document must be issued in the last 3 months
              </SmallText>
            </Stack>
            <Stack direction="row" sx={{ gap: '8px' }}>
              <ArticleOutlined
                sx={{
                  ...customIconStyles,
                }}
              />
              <SmallText sx={{ fontSize: '16px' }}>
                For uploaded files, only PDFs will be accepted
              </SmallText>
            </Stack>
          </Stack>
        </div>
      </MobilePage>
    </form>
  );
};

export default POASelectionPage;
