import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'spa/components/Icon';
import A from './A';

const PrimaryLink = ({ link, text, reversed }) => (
  <A link={link} className={`primaryLink ${reversed && 'primaryLink--reversed'}`}>
    <span className="primaryLink-text">{text}</span>
    <Icon name="ui-arrow-right" className="primaryLink-icon" />
  </A>
);

PrimaryLink.PropTypes = {
  link: PropTypes.shape({
    type: PropTypes.string,
    route: PropTypes.string,
  }).isRequired,
  text: PropTypes.string.isRequired,
  reversed: PropTypes.bool,
};

export default PrimaryLink;
