const buyerFAQMapping = [
  {
    transactionStatus: 3,
    faqs: [
      {
        title: 'How does online escrow work?',
        description:
          'Online escrow is a five-step payment process that protects buyers and sellers in a transaction.',
        redirectPath: '.support/faqs/how-does-online-escrow-work',
      },
      {
        title: 'How long does the escrow process take?',
        description:
          'While most transactions are completed within 1 to 20 business days, transaction timelines are still unique to each transaction.',
        redirectPath: '.support/faqs/how-long-does-the-escrow-process-take',
      },
      {
        title: 'Are services offered through Escrow.com safe and legitimate?',
        description:
          'The Company, founded in 1999 by Fidelity National Financial, has established itself as one of the leading providers of secure business and consumer transaction management on the Internet.',
        redirectPath: '.support/faqs/are-services-offered-through-escrowcom-safe-and-legitimate',
      },
    ],
  },
  {
    transactionStatus: 5,
    faqs: [
      {
        title: 'How does online escrow work?',
        description:
          'Online escrow is a five-step payment process that protects buyers and sellers in a transaction.',
        redirectPath: '.support/faqs/how-does-online-escrow-work',
      },
      {
        title: 'How long does the escrow process take?',
        description:
          'While most transactions are completed within 1 to 20 business days, transaction timelines are still unique to each transaction.',
        redirectPath: '.support/faqs/how-long-does-the-escrow-process-take',
      },
      {
        title: 'How does Escrow.com protect me?',
        description:
          'Escrow.com ensures sellers are paid when they deliver goods or complete agreed upon services, ensuring buyers receive a refund if goods are not delivered or services rendered.',
        redirectPath: '.support/faqs/how-does-escrowcom-protect-me',
      },
    ],
  },
  {
    transactionStatus: 6,
    faqs: [
      {
        title: 'How does online escrow work?',
        description:
          'Online escrow is a five-step payment process that protects buyers and sellers in a transaction.',
        redirectPath: '.support/faqs/how-does-online-escrow-work',
      },
      {
        title: 'How does Escrow.com protect me?',
        description:
          'Escrow.com ensures sellers are paid when they deliver goods or complete agreed upon services, ensuring buyers receive a refund if goods are not delivered or services rendered.',
        redirectPath: '.support/faqs/how-does-escrowcom-protect-me',
      },
      {
        title: 'Are services offered through Escrow.com safe and legitimate?',
        description:
          'The Company, founded in 1999 by Fidelity National Financial, has established itself as one of the leading providers of secure business and consumer transaction management on the Internet.',
        redirectPath: '.support/faqs/are-services-offered-through-escrowcom-safe-and-legitimate',
      },
    ],
  },
  {
    transactionStatus: 9,
    faqs: [
      {
        title: 'How does online escrow work?',
        description:
          'Online escrow is a five-step payment process that protects buyers and sellers in a transaction.',
        redirectPath: '.support/faqs/how-does-online-escrow-work',
      },
      {
        title: 'How does Escrow.com protect me?',
        description:
          'Escrow.com ensures sellers are paid when they deliver goods or complete agreed upon services, ensuring buyers receive a refund if goods are not delivered or services rendered.',
        redirectPath: '.support/faqs/how-does-escrowcom-protect-me',
      },
      {
        title: 'Are services offered through Escrow.com safe and legitimate?',
        description:
          'The Company, founded in 1999 by Fidelity National Financial, has established itself as one of the leading providers of secure business and consumer transaction management on the Internet.',
        redirectPath: '.support/faqs/are-services-offered-through-escrowcom-safe-and-legitimate',
      },
    ],
  },
  {
    transactionStatus: 10,
    faqs: [
      {
        title: 'How does online escrow work?',
        description:
          'Online escrow is a five-step payment process that protects buyers and sellers in a transaction.',
        redirectPath: '.support/faqs/how-does-online-escrow-work',
      },
      {
        title: 'How does Escrow.com protect me?',
        description:
          'Escrow.com ensures sellers are paid when they deliver goods or complete agreed upon services, ensuring buyers receive a refund if goods are not delivered or services rendered.',
        redirectPath: '.support/faqs/how-does-escrowcom-protect-me',
      },
      {
        title: 'How do I change transaction details and terms?',
        description:
          'Changes can be made on the “Transaction Detail Screen” after signing onto Escrow.com when the status is “Awaiting Agreement”.',
        redirectPath: '.support/faqs/how-do-i-change-transaction-details-and-terms',
      },
    ],
  },
  {
    transactionStatus: 15,
    faqs: [
      {
        title: 'How do I pay for the transaction?',
        description:
          'Once you have created your Escrow.com account, created your transaction, and the Seller has accepted the transaction, you’ll be able to select your payment method.',
        redirectPath: '.support/payment-options',
      },
      {
        title: 'As the buyer, what happens to my money when I send it to Escrow.com?',
        description:
          'Your funds remain in our secured escrow non-interest-bearing trust account at Citibank until the transaction is complete.',
        redirectPath:
          '.support/faqs/as-the-buyer,-what-happens-to-my-money-when-i-send-it-to-escrowcom',
      },
      {
        title: 'Who is responsible for paying escrow fees to Escrow.com?',
        description:
          'The buyers and sellers determine which party is responsible for the escrow fees.',
        redirectPath: '.support/faqs/who-is-responsible-for-paying-escrow-fees-to-escrowcom',
      },
    ],
  },
  {
    transactionStatus: 20,
    faqs: [
      {
        title: 'As the buyer, what happens to my money when I send it to Escrow.com?',
        description:
          'Your funds remain in our secured escrow non-interest-bearing trust account at Citibank until the transaction is complete.',
        redirectPath:
          '.support/faqs/as-the-buyer,-what-happens-to-my-money-when-i-send-it-to-escrowcom',
      },
      {
        title: 'How quickly are incoming payments processed?',
        description:
          "The best way to have funds approved quickly is to ensure that the payment's sender information matches the buyer's account details on their Escrow.com profile.",
        redirectPath: '.support/faqs/how-quickly-are-incoming-payments-processed',
      },
      {
        title: 'What happens if my payment results in a shortage?',
        description:
          'Escrow.com will reach out to the buyer when a payment does not cover the full transaction amount.',
        redirectPath: '.support/faqs/what-happens-if-my-payment-results-in-a-shortage',
      },
    ],
  },
  {
    transactionStatus: 22,
    faqs: [
      {
        title: 'How quickly are incoming payments processed?',
        description:
          "The best way to have funds approved quickly is to ensure that the payment's sender information matches the buyer's account details on their Escrow.com profile.",
        redirectPath: '.support/faqs/how-quickly-are-incoming-payments-processed',
      },
      {
        title: 'Why is there a delay in my payment?',
        description:
          'Payments can be delayed for multiple reasons, which include verification delays, bank delays, or inaccurate customer information (e.g. incorrect bank account numbers).',
        redirectPath: '.support/faqs/why-is-there-a-delay-in-my-payment',
      },
      {
        title: 'What happens if my payment results in a shortage?',
        description:
          'Escrow.com will reach out to the buyer when a payment does not cover the full transaction amount.',
        redirectPath: '.support/faqs/what-happens-if-my-payment-results-in-a-shortage',
      },
    ],
  },
  {
    transactionStatus: 25,
    transactionType: ['domain_name'],
    faqs: [
      {
        title: 'When does the seller ship the items?',
        description:
          'Once Escrow.com verifies and approves payment from the buyer, Escrow.com notifies the seller to ship or send the merchandise, domain, or service.',
        redirectPath: '.support/faqs/when-does-the-seller-ship-the-items',
      },
      {
        title: 'Does Escrow.com assist in domain name transfer?',
        description:
          "Yes, we offer a service the Domain Concierge Service, in which Escrow.com holds the domain securely in Escrow.com's registrar account throughout the transaction and ensures safe delivery to the buyer.",
        redirectPath: '.support/faqs/does-escrowcom-assist-in-domain-name-transfer',
      },
      {
        title: "Is the seller required to transfer the domain to the buyer's registrar?",
        description:
          'In some cases, a buyer may use a different registrar than the seller, in which case a transfer between registrars may be required.',
        redirectPath:
          '.support/faqs/is-the-seller-required-to-transfer-the-domain-to-the-buyers-registrar',
      },
    ],
  },
  {
    transactionStatus: 25,
    transactionType: ['motor_vehicle', 'general_merchandise'],
    faqs: [
      {
        title: 'When does the seller ship the items?',
        description:
          'Once Escrow.com verifies and approves payment from the buyer, Escrow.com notifies the seller to ship or send the merchandise, domain, or service.',
        redirectPath: '.support/faqs/when-does-the-seller-ship-the-items',
      },
      {
        title: 'Who pays for shipping?',
        description:
          'During the agreement of terms, the buyer and seller agree as to who will pay for shipping. If the seller is paying the shipping, the cost does not need to be added to the transaction.',
        redirectPath: '.support/faqs/who-pays-for-shipping',
      },
      {
        title: 'What if the items are damaged during shipping?',
        description:
          'In the event you receive damaged merchandise, the receiver (buyer or seller) should first log onto Escrow.com and mark the merchandise as "received" and then "reject." ',
        redirectPath: '.support/faqs/what-if-the-items-are-damaged-during-shipping',
      },
    ],
  },
  {
    transactionStatus: 30,
    faqs: [
      {
        title: 'What is an Inspection Period, when does it start, and how long does it last?',
        description:
          'The Inspection Period is the specified length of time agreed upon by both parties in which the buyer may examine the merchandise or services after receipt.',
        redirectPath:
          '.support/faqs/what-is-an-inspection-period,-when-does-it-start,-and-how-long-does-it-last',
      },
      {
        title: 'How long does the Buyer have to inspect the items?',
        description:
          'At the start of the transaction, the buyer, seller, or broker will need to set the terms of their transaction to include an Inspection Period.',
        redirectPath: '.support/faqs/how-long-does-the-buyer-have-to-inspect-the-items',
      },
      {
        title: 'What if the items are damaged during shipping?',
        description:
          'In the event you receive damaged merchandise, the receiver (buyer or seller) should first log onto Escrow.com and mark the merchandise as "received" and then "reject." ',
        redirectPath: '.support/faqs/what-if-the-items-are-damaged-during-shipping',
      },
    ],
  },
  {
    transactionStatus: 35,
    faqs: [
      {
        title: 'What is an Inspection Period, when does it start, and how long does it last?',
        description:
          'The Inspection Period is the specified length of time agreed upon by both parties in which the buyer may examine the merchandise or services after receipt.',
        redirectPath:
          '.support/faqs/what-is-an-inspection-period,-when-does-it-start,-and-how-long-does-it-last',
      },
      {
        title: 'How long does the Buyer have to inspect the items?',
        description:
          'At the start of the transaction, the buyer, seller, or broker will need to set the terms of their transaction to include an Inspection Period.',
        redirectPath: '.support/faqs/how-long-does-the-buyer-have-to-inspect-the-items',
      },
      {
        title: 'What if the items are damaged during shipping?',
        description:
          'In the event you receive damaged merchandise, the receiver (buyer or seller) should first log onto Escrow.com and mark the merchandise as "received" and then "reject." ',
        redirectPath: '.support/faqs/what-if-the-items-are-damaged-during-shipping',
      },
    ],
  },
  {
    transactionStatus: 40,
    faqs: [
      {
        title:
          'Why is my transaction complete but the funds are not released? What does pending verification mean?',
        description:
          'When a buyer marks a transaction as accepted or the inspection period times out, the transaction status shows as complete. However, funds are still in escrow until the transaction is closed.',
        redirectPath:
          '.support/faqs/why-is-my-transaction-complete-but-the-funds-are-not-released-what-does-pending-verification-mean',
      },
      {
        title: 'What if the buyer forgets to accept or reject the items online at Escrow.com?',
        description:
          'If the buyer has neglected to “Accept” or “Reject” the merchandise, Escrow.com will release funds to the seller at the end of the inspection period, as long as Escrow.com can verify that the buyer has received their merchandise.',
        redirectPath:
          '.support/faqs/what-if-the-buyer-forgets-to-accept-or-decline-the-items-online-at-escrowcom',
      },
      {
        title: 'When are funds released to the seller?',
        description:
          'Escrow.com releases funds to the seller when the buyer accepts products and/or services or after the Inspection Period has expired.',
        redirectPath: '.support/faqs/when-are-funds-released-to-the-seller',
      },
    ],
  },
  {
    transactionStatus: 45,
    faqs: [
      {
        title: 'What happens if the buyer is unsatisfied with their merchandise?',
        description:
          'If the buyer is dissatisfied with a transaction, they have the option to “Accept” or “Reject” their merchandise during the inspection period.',
        redirectPath:
          '.support/faqs/what-happens-if-the-buyer-is-unsatisfied-with-their-merchandise',
      },
      {
        title:
          'What if there is a disagreement during the transaction? What is dispute resolution?',
        description:
          'A Dispute is initiated when the buyer returns merchandise to the seller and the seller rejects the merchandise.',
        redirectPath:
          '.support/faqs/what-if-there-is-a-disagreement-during-the-transaction-what-is-dispute-resolution',
      },
      {
        title: 'If the buyer returns an item, who pays for shipping?',
        description:
          'The buyer has the option to “Reject” or “Accept” the item. If the buyer decides to “Reject” the item, the buyer is responsible for covering the cost of shipping.',
        redirectPath: '.support/faqs/if-the-buyer-returns-an-item,-who-pays-for-shipping',
      },
    ],
  },
  {
    transactionStatus: 50,
    faqs: [
      {
        title: 'What happens if the buyer is unsatisfied with their merchandise?',
        description:
          'If the buyer is dissatisfied with a transaction, they have the option to “Accept” or “Reject” their merchandise during the inspection period.',
        redirectPath:
          '.support/faqs/what-happens-if-the-buyer-is-unsatisfied-with-their-merchandise',
      },
      {
        title:
          'What if there is a disagreement during the transaction? What is dispute resolution?',
        description:
          'A Dispute is initiated when the buyer returns merchandise to the seller and the seller rejects the merchandise.',
        redirectPath:
          '.support/faqs/what-if-there-is-a-disagreement-during-the-transaction-what-is-dispute-resolution',
      },
      {
        title: 'How are buyers refunded?',
        description:
          "In the event of a cancelled transaction or a partial refund to the buyer, the buyer's funds will be refunded back to the original source of the payment method used to pay Escrow.com. Escrow.com charges fees to refund via wire transfer - $10 for domestic wires, $20 for international wires. The original escrow fee is also non-refundable.",
        redirectPath: '.support/faqs/how-are-buyers-refunded',
      },
    ],
  },
  {
    transactionStatus: 55,
    faqs: [
      {
        title: 'What happens if the buyer is unsatisfied with their merchandise?',
        description:
          'If the buyer is dissatisfied with a transaction, they have the option to “Accept” or “Reject” their merchandise during the inspection period.',
        redirectPath:
          '.support/faqs/what-happens-if-the-buyer-is-unsatisfied-with-their-merchandise',
      },
      {
        title:
          'What if there is a disagreement during the transaction? What is dispute resolution?',
        description:
          'A Dispute is initiated when the buyer returns merchandise to the seller and the seller rejects the merchandise.',
        redirectPath:
          '.support/faqs/what-if-there-is-a-disagreement-during-the-transaction-what-is-dispute-resolution',
      },
      {
        title: 'How are buyers refunded?',
        description:
          "In the event of a cancelled transaction or a partial refund to the buyer, the buyer's funds will be refunded back to the original source of the payment method used to pay Escrow.com. Escrow.com charges fees to refund via wire transfer - $10 for domestic wires, $20 for international wires. The original escrow fee is also non-refundable.",
        redirectPath: '.support/faqs/how-are-buyers-refunded',
      },
    ],
  },
  {
    transactionStatus: 60,
    faqs: [
      {
        title: 'How are buyers refunded?',
        description:
          "In the event of a cancelled transaction or a partial refund to the buyer, the buyer's funds will be refunded back to the original source of the payment method used to pay Escrow.com. Escrow.com charges fees to refund via wire transfer - $10 for domestic wires, $20 for international wires. The original escrow fee is also non-refundable.",
        redirectPath: '.support/faqs/how-are-buyers-refunded',
      },
    ],
  },
  {
    transactionStatus: 65,
    faqs: [
      {
        title:
          'What if there is a disagreement during the transaction? What is dispute resolution?',
        description:
          'A Dispute is initiated when the buyer returns merchandise to the seller and the seller rejects the merchandise.',
        redirectPath:
          '.support/faqs/what-if-there-is-a-disagreement-during-the-transaction-what-is-dispute-resolution',
      },
      {
        title: 'What happens if the buyer is unsatisfied with their merchandise?',
        description:
          'If the buyer is dissatisfied with a transaction, they have the option to “Accept” or “Reject” their merchandise during the inspection period.',
        redirectPath:
          '.support/faqs/what-happens-if-the-buyer-is-unsatisfied-with-their-merchandise',
      },
      {
        title: 'How are buyers refunded?',
        description:
          "In the event of a cancelled transaction or a partial refund to the buyer, the buyer's funds will be refunded back to the original source of the payment method used to pay Escrow.com. Escrow.com charges fees to refund via wire transfer - $10 for domestic wires, $20 for international wires. The original escrow fee is also non-refundable.",
        redirectPath: '.support/faqs/how-are-buyers-refunded',
      },
    ],
  },
  {
    transactionStatus: 70,
    faqs: [
      {
        title: 'How do buyers or sellers cancel transactions?',
        description:
          'Prior to the agreement of terms by both parties, the buyer or seller can cancel the transaction by clicking the “Cancel” button on the transaction detail screen.',
        redirectPath: '.support/faqs/how-do-buyers-or-sellers-cancel-transactions',
      },
      {
        title: 'Can a transaction be cancelled by someone other than the buyer or seller?',
        description:
          'Yes. We review transactions and may cancel a transaction if it does not meet the requirements for what can be sold through Escrow.com. We may also cancel a transaction if fraud is suspected.',
        redirectPath:
          '.support/faqs/can-a-transaction-be-cancelled-by-someone-other-than-the-buyer-or-seller',
      },
    ],
  },
  {
    transactionStatus: 75,
    faqs: [
      {
        title: 'How do buyers or sellers cancel transactions?',
        description:
          'Prior to the agreement of terms by both parties, the buyer or seller can cancel the transaction by clicking the “Cancel” button on the transaction detail screen.',
        redirectPath: '.support/faqs/how-do-buyers-or-sellers-cancel-transactions',
      },
      {
        title: 'Can a transaction be cancelled by someone other than the buyer or seller?',
        description:
          'Yes. We review transactions and may cancel a transaction if it does not meet the requirements for what can be sold through Escrow.com. We may also cancel a transaction if fraud is suspected.',
        redirectPath:
          '.support/faqs/can-a-transaction-be-cancelled-by-someone-other-than-the-buyer-or-seller',
      },
    ],
  },
  {
    transactionStatus: 80,
    faqs: [
      {
        title:
          'Why is my transaction complete but the funds are not released? What does pending verification mean?',
        description:
          'When a buyer marks a transaction as accepted or the inspection period times out, the transaction status shows as complete. However, funds are still in escrow until the transaction is closed.',
        redirectPath:
          '.support/faqs/why-is-my-transaction-complete-but-the-funds-are-not-released-what-does-pending-verification-mean',
      },
    ],
  },
  {
    transactionStatus: 85,
    faqs: [
      {
        title:
          'Why is my transaction complete but the funds are not released? What does pending verification mean?',
        description:
          'When a buyer marks a transaction as accepted or the inspection period times out, the transaction status shows as complete. However, funds are still in escrow until the transaction is closed.',
        redirectPath:
          '.support/faqs/why-is-my-transaction-complete-but-the-funds-are-not-released-what-does-pending-verification-mean',
      },
    ],
  },
];

const sellerFAQMapping = [
  {
    transactionStatus: 3,
    faqs: [
      {
        title: 'How does online escrow work?',
        description:
          'Online escrow is a five-step payment process that protects buyers and sellers in a transaction.',
        redirectPath: '.support/faqs/how-does-online-escrow-work',
      },
      {
        title: 'How long does the escrow process take?',
        description:
          'While most transactions are completed within 1 to 20 business days, transaction timelines are still unique to each transaction.',
        redirectPath: '.support/faqs/how-long-does-the-escrow-process-take',
      },
      {
        title: 'Are services offered through Escrow.com safe and legitimate?',
        description:
          'The Company, founded in 1999 by Fidelity National Financial, has established itself as one of the leading providers of secure business and consumer transaction management on the Internet.',
        redirectPath: '.support/faqs/are-services-offered-through-escrowcom-safe-and-legitimate',
      },
    ],
  },
  {
    transactionStatus: 5,
    faqs: [
      {
        title: 'How does online escrow work?',
        description:
          'Online escrow is a five-step payment process that protects buyers and sellers in a transaction.',
        redirectPath: '.support/faqs/how-does-online-escrow-work',
      },
      {
        title: 'How does Escrow.com protect me?',
        description:
          'Escrow.com ensures sellers are paid when they deliver goods or complete agreed upon services, ensuring buyers receive a refund if goods are not delivered or services rendered.',
        redirectPath: '.support/faqs/how-does-escrowcom-protect-me',
      },
      {
        title: 'How do I change transaction details and terms?',
        description:
          'Changes can be made on the "Transaction Detail Screen" after signing onto Escrow.com when the status is "Awaiting Agreement".',
        redirectPath: '.support/faqs/how-do-i-change-transaction-details-and-terms',
      },
    ],
  },
  {
    transactionStatus: 6,
    faqs: [
      {
        title: 'How does online escrow work?',
        description:
          'Online escrow is a five-step payment process that protects buyers and sellers in a transaction.',
        redirectPath: '.support/faqs/how-does-online-escrow-work',
      },
      {
        title: 'How does Escrow.com protect me?',
        description:
          'Escrow.com ensures sellers are paid when they deliver goods or complete agreed upon services, ensuring buyers receive a refund if goods are not delivered or services rendered.',
        redirectPath: '.support/faqs/how-does-escrowcom-protect-me',
      },
      {
        title: 'Are services offered through Escrow.com safe and legitimate?',
        description:
          'The Company, founded in 1999 by Fidelity National Financial, has established itself as one of the leading providers of secure business and consumer transaction management on the Internet.',
        redirectPath: '.support/faqs/are-services-offered-through-escrowcom-safe-and-legitimate',
      },
    ],
  },
  {
    transactionStatus: 9,
    faqs: [
      {
        title: 'How does online escrow work?',
        description:
          'Online escrow is a five-step payment process that protects buyers and sellers in a transaction.',
        redirectPath: '.support/faqs/how-does-online-escrow-work',
      },
      {
        title: 'How does Escrow.com protect me?',
        description:
          'Escrow.com ensures sellers are paid when they deliver goods or complete agreed upon services, ensuring buyers receive a refund if goods are not delivered or services rendered.',
        redirectPath: '.support/faqs/how-does-escrowcom-protect-me',
      },
      {
        title: 'Are services offered through Escrow.com safe and legitimate?',
        description:
          'The Company, founded in 1999 by Fidelity National Financial, has established itself as one of the leading providers of secure business and consumer transaction management on the Internet.',
        redirectPath: '.support/faqs/are-services-offered-through-escrowcom-safe-and-legitimate',
      },
    ],
  },
  {
    transactionStatus: 10,
    faqs: [
      {
        title: 'How does online escrow work?',
        description:
          'Online escrow is a five-step payment process that protects buyers and sellers in a transaction.',
        redirectPath: '.support/faqs/how-does-online-escrow-work',
      },
      {
        title: 'How long does the escrow process take?',
        description:
          'While most transactions are completed within 1 to 20 business days, transaction timelines are still unique to each transaction.',
        redirectPath: '.support/faqs/how-long-does-the-escrow-process-take',
      },
      {
        title: 'How does Escrow.com protect me?',
        description:
          'Escrow.com ensures sellers are paid when they deliver goods or complete agreed upon services, ensuring buyers receive a refund if goods are not delivered or services rendered.',
        redirectPath: '.support/faqs/how-does-escrowcom-protect-me',
      },
    ],
  },
  {
    transactionStatus: 15,
    faqs: [
      {
        title: 'Who is responsible for paying escrow fees to Escrow.com?',
        description:
          'The buyers and sellers determine which party is responsible for the escrow fees.',
        redirectPath: '.support/faqs/who-is-responsible-for-paying-escrow-fees-to-escrowcom',
      },
      {
        title: 'How quickly are incoming payments processed?',
        description:
          "The best way to have funds approved quickly is to ensure that the payment's sender information matches the buyer's account details on their Escrow.com profile.",
        redirectPath: '.support/faqs/how-quickly-are-incoming-payments-processed',
      },
      {
        title: 'How do sellers get paid?',
        description:
          "In the five-step Escrow process, sellers get paid when the buyer confirms their acceptance of the goods/services. This is when the buyer's inspection period (step 4) is completed.",
        redirectPath: '.support/faqs/how-do-sellers-get-paid',
      },
    ],
  },
  {
    transactionStatus: 20,
    faqs: [
      {
        title: 'Who is responsible for paying escrow fees to Escrow.com?',
        description:
          'The buyers and sellers determine which party is responsible for the escrow fees.',
        redirectPath: '.support/faqs/who-is-responsible-for-paying-escrow-fees-to-escrowcom',
      },
      {
        title: 'How quickly are incoming payments processed?',
        description:
          "The best way to have funds approved quickly is to ensure that the payment's sender information matches the buyer's account details on their Escrow.com profile.",
        redirectPath: '.support/faqs/how-quickly-are-incoming-payments-processed',
      },
      {
        title: 'How do sellers get paid?',
        description:
          "In the five-step Escrow process, sellers get paid when the buyer confirms their acceptance of the goods/services. This is when the buyer's inspection period (step 4) is completed.",
        redirectPath: '.support/faqs/how-do-sellers-get-paid',
      },
    ],
  },
  {
    transactionStatus: 22,
    faqs: [
      {
        title: 'Who is responsible for paying escrow fees to Escrow.com?',
        description:
          'The buyers and sellers determine which party is responsible for the escrow fees.',
        redirectPath: '.support/faqs/who-is-responsible-for-paying-escrow-fees-to-escrowcom',
      },
      {
        title: 'How quickly are incoming payments processed?',
        description:
          "The best way to have funds approved quickly is to ensure that the payment's sender information matches the buyer's account details on their Escrow.com profile.",
        redirectPath: '.support/faqs/how-quickly-are-incoming-payments-processed',
      },
      {
        title: 'How do sellers get paid?',
        description:
          "In the five-step Escrow process, sellers get paid when the buyer confirms their acceptance of the goods/services. This is when the buyer's inspection period (step 4) is completed.",
        redirectPath: '.support/faqs/how-do-sellers-get-paid',
      },
    ],
  },
  {
    transactionStatus: 25,
    transactionType: ['domain_name'],
    faqs: [
      {
        title: 'When does the seller ship the items?',
        description:
          'Once Escrow.com verifies and approves payment from the buyer, Escrow.com notifies the seller to ship or send the merchandise, domain, or service.',
        redirectPath: '.support/faqs/when-does-the-seller-ship-the-items',
      },
      {
        title: 'Does Escrow.com assist in domain name transfer?',
        description:
          "Yes, we offer a service the Domain Concierge Service, in which Escrow.com holds the domain securely in Escrow.com's registrar account throughout the transaction and ensures safe delivery to the buyer.",
        redirectPath: '.support/faqs/does-escrowcom-assist-in-domain-name-transfer',
      },
      {
        title: "Is the seller required to transfer the domain to the buyer's registrar?",
        description:
          'In some cases, a buyer may use a different registrar than the seller, in which case a transfer between registrars may be required.',
        redirectPath:
          '.support/faqs/is-the-seller-required-to-transfer-the-domain-to-the-buyers-registrar',
      },
    ],
  },
  {
    transactionStatus: 25,
    transactionType: ['motor_vehicle', 'general_merchandise'],
    faqs: [
      {
        title: 'When does the seller ship the items?',
        description:
          'Once Escrow.com verifies and approves payment from the buyer, Escrow.com notifies the seller to ship or send the merchandise, domain, or service.',
        redirectPath: '.support/faqs/when-does-the-seller-ship-the-items',
      },
      {
        title: 'Who can I use to ship the merchandise?',
        description:
          "Escrow.com has a list of preferred shippers that we work with on a consistent basis. However, in reality you can use any shipping company you wish, as long as the shipping company provides online tracking to the other party's address provided by Escrow.com.",
        redirectPath: '.support/faqs/who-can-i-use-to-ship-the-merchandise',
      },
      {
        title: 'What if the items are damaged during shipping?',
        description:
          'In the event you receive damaged merchandise, the receiver (buyer or seller) should first log onto Escrow.com and mark the merchandise as "received" and then "reject".',
        redirectPath: '.support/faqs/what-if-the-items-are-damaged-during-shipping',
      },
    ],
  },
  {
    transactionStatus: 30,
    faqs: [
      {
        title: 'What is an Inspection Period, when does it start, and how long does it last?',
        description:
          'The Inspection Period is the specified length of time agreed upon by both parties in which the buyer may examine the merchandise or services after receipt.',
        redirectPath:
          '.support/faqs/what-is-an-inspection-period,-when-does-it-start,-and-how-long-does-it-last',
      },
      {
        title: 'How long does the Buyer have to inspect the items?',
        description:
          'At the start of the transaction, the buyer, seller, or broker will need to set the terms of their transaction to include an Inspection Period.',
        redirectPath: '.support/faqs/how-long-does-the-buyer-have-to-inspect-the-items',
      },
      {
        title: 'What if the items are damaged during shipping?',
        description:
          'In the event you receive damaged merchandise, the receiver (buyer or seller) should first log onto Escrow.com and mark the merchandise as "received" and then "reject".',
        redirectPath: '.support/faqs/what-if-the-items-are-damaged-during-shipping',
      },
    ],
  },
  {
    transactionStatus: 35,
    faqs: [
      {
        title: 'What is an Inspection Period, when does it start, and how long does it last?',
        description:
          'The Inspection Period is the specified length of time agreed upon by both parties in which the buyer may examine the merchandise or services after receipt.',
        redirectPath:
          '.support/faqs/what-is-an-inspection-period,-when-does-it-start,-and-how-long-does-it-last',
      },
      {
        title: 'How long does the Buyer have to inspect the items?',
        description:
          'At the start of the transaction, the buyer, seller, or broker will need to set the terms of their transaction to include an Inspection Period.',
        redirectPath: '.support/faqs/how-long-does-the-buyer-have-to-inspect-the-items',
      },
      {
        title: 'What if the items are damaged during shipping?',
        description:
          'In the event you receive damaged merchandise, the receiver (buyer or seller) should first log onto Escrow.com and mark the merchandise as "received" and then "reject."',
        redirectPath: '.support/faqs/what-if-the-items-are-damaged-during-shipping',
      },
    ],
  },
  {
    transactionStatus: 40,
    faqs: [
      {
        title:
          'Why is my transaction complete but the funds are not released? What does pending verification mean?',
        description:
          'When a buyer marks a transaction as accepted or the inspection period times out, the transaction status shows as complete. However, funds are still in escrow until the transaction is closed.',
        redirectPath:
          '.support/faqs/why-is-my-transaction-complete-but-the-funds-are-not-released-what-does-pending-verification-mean',
      },
      {
        title: 'What if the buyer forgets to accept or reject the items online at Escrow.com?',
        description:
          'If the buyer has neglected to "Accept" or "Reject" the merchandise, Escrow.com will release funds to the seller at the end of the inspection period, as long as Escrow.com can verify that the buyer has received their merchandise.',
        redirectPath:
          '.support/faqs/what-if-the-buyer-forgets-to-accept-or-decline-the-items-online-at-escrowcom',
      },
      {
        title: 'When are funds released to the seller?',
        description:
          'Escrow.com releases funds to the seller when the buyer accepts products and/or services or after the Inspection Period has expired.',
        redirectPath: '.support/faqs/when-are-funds-released-to-the-seller',
      },
    ],
  },
  {
    transactionStatus: 45,
    faqs: [
      {
        title: 'What happens if the buyer is unsatisfied with their merchandise?',
        description:
          'If the buyer is dissatisfied with a transaction, they have the option to "Accept" or "Reject" their merchandise during the inspection period.',
        redirectPath:
          '.support/faqs/what-happens-if-the-buyer-is-unsatisfied-with-their-merchandise',
      },
      {
        title:
          'What if there is a disagreement during the transaction? What is dispute resolution?',
        description:
          'A Dispute is initiated when the buyer returns merchandise to the seller and the seller rejects the merchandise.',
        redirectPath:
          '.support/faqs/what-if-there-is-a-disagreement-during-the-transaction-what-is-dispute-resolution',
      },
      {
        title: 'If the buyer returns an item, who pays for shipping?',
        description:
          'The buyer has the option to "Reject" or "Accept" the item. If the buyer decides to "Reject" the item, the buyer is responsible for covering the cost of shipping.',
        redirectPath: '.support/faqs/if-the-buyer-returns-an-item,-who-pays-for-shipping',
      },
    ],
  },
  {
    transactionStatus: 50,
    faqs: [
      {
        title: 'What happens if the buyer is unsatisfied with their merchandise?',
        description:
          'If the buyer is dissatisfied with a transaction, they have the option to "Accept" or "Reject" their merchandise during the inspection period.',
        redirectPath:
          '.support/faqs/what-happens-if-the-buyer-is-unsatisfied-with-their-merchandise',
      },
      {
        title:
          'What if there is a disagreement during the transaction? What is dispute resolution?',
        description:
          'A Dispute is initiated when the buyer returns merchandise to the seller and the seller rejects the merchandise.',
        redirectPath:
          '.support/faqs/what-if-there-is-a-disagreement-during-the-transaction-what-is-dispute-resolution',
      },
    ],
  },
  {
    transactionStatus: 55,
    faqs: [
      {
        title: 'What happens if the buyer is unsatisfied with their merchandise?',
        description:
          'If the buyer is dissatisfied with a transaction, they have the option to "Accept" or "Reject" their merchandise during the inspection period.',
        redirectPath:
          '.support/faqs/what-happens-if-the-buyer-is-unsatisfied-with-their-merchandise',
      },
      {
        title:
          'What if there is a disagreement during the transaction? What is dispute resolution?',
        description:
          'A Dispute is initiated when the buyer returns merchandise to the seller and the seller rejects the merchandise.',
        redirectPath:
          '.support/faqs/what-if-there-is-a-disagreement-during-the-transaction-what-is-dispute-resolution',
      },
    ],
  },
  {
    transactionStatus: 60,
    faqs: [],
  },
  {
    transactionStatus: 65,
    faqs: [
      {
        title:
          'What if there is a disagreement during the transaction? What is dispute resolution?',
        description:
          'A Dispute is initiated when the buyer returns merchandise to the seller and the seller rejects the merchandise.',
        redirectPath:
          '.support/faqs/what-if-there-is-a-disagreement-during-the-transaction-what-is-dispute-resolution',
      },
      {
        title: 'What happens if the buyer is unsatisfied with their merchandise?',
        description:
          'If the buyer is dissatisfied with a transaction, they have the option to "Accept" or "Reject" their merchandise during the inspection period.',
        redirectPath:
          '.support/faqs/what-happens-if-the-buyer-is-unsatisfied-with-their-merchandise',
      },
    ],
  },
  {
    transactionStatus: 70,
    faqs: [
      {
        title: 'How do buyers or sellers cancel transactions?',
        description:
          'Prior to the agreement of terms by both parties, the buyer or seller can cancel the transaction by clicking the "Cancel" button on the transaction detail screen.',
        redirectPath: '.support/faqs/how-do-buyers-or-sellers-cancel-transactions',
      },
      {
        title: 'Can a transaction be cancelled by someone other than the buyer or seller?',
        description:
          'Yes. We review transactions and may cancel a transaction if it does not meet the requirements for what can be sold through Escrow.com. We may also cancel a transaction if fraud is suspected.',
        redirectPath:
          '.support/faqs/can-a-transaction-be-cancelled-by-someone-other-than-the-buyer-or-seller',
      },
    ],
  },
  {
    transactionStatus: 75,
    faqs: [
      {
        title: 'How do buyers or sellers cancel transactions?',
        description:
          'Prior to the agreement of terms by both parties, the buyer or seller can cancel the transaction by clicking the "Cancel" button on the transaction detail screen.',
        redirectPath: '.support/faqs/how-do-buyers-or-sellers-cancel-transactions',
      },
      {
        title: 'Can a transaction be cancelled by someone other than the buyer or seller?',
        description:
          'Yes. We review transactions and may cancel a transaction if it does not meet the requirements for what can be sold through Escrow.com. We may also cancel a transaction if fraud is suspected.',
        redirectPath:
          '.support/faqs/can-a-transaction-be-cancelled-by-someone-other-than-the-buyer-or-seller',
      },
    ],
  },
  {
    transactionStatus: 80,
    faqs: [
      {
        title:
          'Why is my transaction complete but the funds are not released? What does pending verification mean?',
        description:
          'When a buyer marks a transaction as accepted or the inspection period times out, the transaction status shows as complete. However, funds are still in escrow until the transaction is closed.',
        redirectPath:
          '.support/faqs/why-is-my-transaction-complete-but-the-funds-are-not-released-what-does-pending-verification-mean',
      },
    ],
  },
  {
    transactionStatus: 85,
    faqs: [
      {
        title:
          'Why is my transaction complete but the funds are not released? What does pending verification mean?',
        description:
          'When a buyer marks a transaction as accepted or the inspection period times out, the transaction status shows as complete. However, funds are still in escrow until the transaction is closed.',
        redirectPath:
          '.support/faqs/why-is-my-transaction-complete-but-the-funds-are-not-released-what-does-pending-verification-mean',
      },
    ],
  },
];

const brokerFAQMapping = [
  {
    transactionStatus: 3,
    faqs: [
      {
        title: 'How does online escrow work?',
        description:
          'Online escrow is a five-step payment process that protects buyers and sellers in a transaction.',
        redirectPath: '.support/faqs/how-does-online-escrow-work',
      },
      {
        title: 'Who pays the Broker commission?',
        description:
          'The Broker commission can be paid by Buyer, Seller, or split between Buyer and Seller.',
        redirectPath: '.support/faqs/who-pays-the-broker-commission',
      },
      {
        title: 'Who pays the escrow fee in a Broker transaction?',
        description:
          'The escrow fee can be paid by the Buyer, Seller, Broker, split between Buyer and Seller, or split between Buyer and Broker.',
        redirectPath: '.support/faqs/who-pays-the-escrow-fee-in-a-broker-transaction',
      },
    ],
  },
  {
    transactionStatus: 6,
    faqs: [
      {
        title: 'How does online escrow work?',
        description:
          'Online escrow is a five-step payment process that protects buyers and sellers in a transaction.',
        redirectPath: '.support/faqs/how-does-online-escrow-work',
      },
      {
        title: 'Who pays the Broker commission?',
        description:
          'The Broker commission can be paid by Buyer, Seller, or split between Buyer and Seller.',
        redirectPath: '.support/faqs/who-pays-the-broker-commission',
      },
      {
        title: 'Who pays the escrow fee in a Broker transaction?',
        description:
          'The escrow fee can be paid by the Buyer, Seller, Broker, split between Buyer and Seller, or split between Buyer and Broker.',
        redirectPath: '.support/faqs/who-pays-the-escrow-fee-in-a-broker-transaction',
      },
    ],
  },
  {
    transactionStatus: 9,
    faqs: [
      {
        title: 'How does online escrow work?',
        description:
          'Online escrow is a five-step payment process that protects buyers and sellers in a transaction.',
        redirectPath: '.support/faqs/how-does-online-escrow-work',
      },
      {
        title: 'Who pays the Broker commission?',
        description:
          'The Broker commission can be paid by Buyer, Seller, or split between Buyer and Seller.',
        redirectPath: '.support/faqs/who-pays-the-broker-commission',
      },
      {
        title: 'Who pays the escrow fee in a Broker transaction?',
        description:
          'The escrow fee can be paid by the Buyer, Seller, Broker, split between Buyer and Seller, or split between Buyer and Broker.',
        redirectPath: '.support/faqs/who-pays-the-escrow-fee-in-a-broker-transaction',
      },
    ],
  },
  {
    transactionStatus: 15,
    faqs: [
      {
        title: 'How long does the escrow process take?',
        description:
          'While most transactions are completed within 1 to 20 business days, transaction timelines are still unique to each transaction.',
        redirectPath: '.support/faqs/how-long-does-the-escrow-process-take',
      },
      {
        title: 'How quickly are incoming payments processed?',
        description:
          "The best way to have funds approved quickly is to ensure that the payment's sender information matches the buyer's account details on their Escrow.com profile.",
        redirectPath: '.support/faqs/how-quickly-are-incoming-payments-processed',
      },
      {
        title: 'Who pays the escrow fee in a Broker transaction?',
        description:
          'The escrow fee can be paid by the Buyer, Seller, Broker, split between Buyer and Seller, or split between Buyer and Broker.',
        redirectPath: '.support/faqs/who-pays-the-escrow-fee-in-a-broker-transaction',
      },
    ],
  },
  {
    transactionStatus: 20,
    faqs: [
      {
        title: 'How long does the escrow process take?',
        description:
          'While most transactions are completed within 1 to 20 business days, transaction timelines are still unique to each transaction.',
        redirectPath: '.support/faqs/how-long-does-the-escrow-process-take',
      },
      {
        title: 'How quickly are incoming payments processed?',
        description:
          "The best way to have funds approved quickly is to ensure that the payment's sender information matches the buyer's account details on their Escrow.com profile.",
        redirectPath: '.support/faqs/how-quickly-are-incoming-payments-processed',
      },
      {
        title: 'Who pays the escrow fee in a Broker transaction?',
        description:
          'The escrow fee can be paid by the Buyer, Seller, Broker, split between Buyer and Seller, or split between Buyer and Broker.',
        redirectPath: '.support/faqs/who-pays-the-escrow-fee-in-a-broker-transaction',
      },
    ],
  },
  {
    transactionStatus: 22,
    faqs: [
      {
        title: 'How long does the escrow process take?',
        description:
          'While most transactions are completed within 1 to 20 business days, transaction timelines are still unique to each transaction.',
        redirectPath: '.support/faqs/how-long-does-the-escrow-process-take',
      },
      {
        title: 'How quickly are incoming payments processed?',
        description:
          "The best way to have funds approved quickly is to ensure that the payment's sender information matches the buyer's account details on their Escrow.com profile.",
        redirectPath: '.support/faqs/how-quickly-are-incoming-payments-processed',
      },
      {
        title: 'Who pays the escrow fee in a Broker transaction?',
        description:
          'The escrow fee can be paid by the Buyer, Seller, Broker, split between Buyer and Seller, or split between Buyer and Broker.',
        redirectPath: '.support/faqs/who-pays-the-escrow-fee-in-a-broker-transaction',
      },
    ],
  },
  {
    transactionStatus: 25,
    transactionType: ['domain_name'],
    faqs: [
      {
        title: 'When does the seller ship the items?',
        description:
          'Once Escrow.com verifies and approves payment from the buyer, Escrow.com notifies the seller to ship or send the merchandise, domain, or service.',
        redirectPath: '.support/faqs/when-does-the-seller-ship-the-items',
      },
      {
        title: 'Does Escrow.com assist in domain name transfer?',
        description:
          "Yes, we offer a service the Domain Concierge Service, in which Escrow.com holds the domain securely in Escrow.com's registrar account throughout the transaction and ensures safe delivery to the buyer.",
        redirectPath: '.support/faqs/does-escrowcom-assist-in-domain-name-transfer',
      },
      {
        title: "Is the seller required to transfer the domain to the buyer's registrar?",
        description:
          'In some cases, a buyer may use a different registrar than the seller, in which case a transfer between registrars may be required.',
        redirectPath:
          '.support/faqs/is-the-seller-required-to-transfer-the-domain-to-the-buyers-registrar',
      },
    ],
  },
  {
    transactionStatus: 25,
    transactionType: ['motor_vehicle', 'general_merchandise'],
    faqs: [
      {
        title: 'When does the seller ship the items?',
        description:
          'Once Escrow.com verifies and approves payment from the buyer, Escrow.com notifies the seller to ship or send the merchandise, domain, or service.',
        redirectPath: '.support/faqs/when-does-the-seller-ship-the-items',
      },
    ],
  },
  {
    transactionStatus: 30,
    faqs: [
      {
        title: 'What is an Inspection Period, when does it start, and how long does it last?',
        description:
          'The Inspection Period is the specified length of time agreed upon by both parties in which the buyer may examine the merchandise or services after receipt.',
        redirectPath:
          '.support/faqs/what-is-an-inspection-period,-when-does-it-start,-and-how-long-does-it-last',
      },
      {
        title: 'How long does the Buyer have to inspect the items?',
        description:
          'At the start of the transaction, the buyer, seller, or broker will need to set the terms of their transaction to include an Inspection Period.',
        redirectPath: '.support/faqs/how-long-does-the-buyer-have-to-inspect-the-items',
      },
      {
        title: 'What if the buyer forgets to accept or reject the items online at Escrow.com?',
        description:
          'If the buyer has neglected to “Accept” or “Reject” the merchandise, Escrow.com will release funds to the seller at the end of the inspection period, as long as Escrow.com can verify that the buyer has received their merchandise.',
        redirectPath:
          '.support/faqs/what-if-the-buyer-forgets-to-accept-or-decline-the-items-online-at-escrowcom',
      },
    ],
  },
  {
    transactionStatus: 35,
    faqs: [
      {
        title: 'What is an Inspection Period, when does it start, and how long does it last?',
        description:
          'The Inspection Period is the specified length of time agreed upon by both parties in which the buyer may examine the merchandise or services after receipt.',
        redirectPath:
          '.support/faqs/what-is-an-inspection-period,-when-does-it-start,-and-how-long-does-it-last',
      },
      {
        title: 'How long does the Buyer have to inspect the items?',
        description:
          'At the start of the transaction, the buyer, seller, or broker will need to set the terms of their transaction to include an Inspection Period.',
        redirectPath: '.support/faqs/how-long-does-the-buyer-have-to-inspect-the-items',
      },
      {
        title: 'What if the buyer forgets to accept or reject the items online at Escrow.com?',
        description:
          'If the buyer has neglected to “Accept” or “Reject” the merchandise, Escrow.com will release funds to the seller at the end of the inspection period, as long as Escrow.com can verify that the buyer has received their merchandise.',
        redirectPath:
          '.support/faqs/what-if-the-buyer-forgets-to-accept-or-decline-the-items-online-at-escrowcom',
      },
    ],
  },
  {
    transactionStatus: 40,
    faqs: [
      {
        title:
          'Why is my transaction complete but the funds are not released? What does pending verification mean?',
        description:
          'When a buyer marks a transaction as accepted or the inspection period times out, the transaction status shows as complete. However, funds are still in escrow until the transaction is closed.',
        redirectPath:
          '.support/faqs/why-is-my-transaction-complete-but-the-funds-are-not-released-what-does-pending-verification-mean',
      },
      {
        title: 'What if the buyer forgets to accept or reject the items online at Escrow.com?',
        description:
          'If the buyer has neglected to “Accept” or “Reject” the merchandise, Escrow.com will release funds to the seller at the end of the inspection period, as long as Escrow.com can verify that the buyer has received their merchandise.',
        redirectPath:
          '.support/faqs/what-if-the-buyer-forgets-to-accept-or-decline-the-items-online-at-escrowcom',
      },
      {
        title: 'When are funds released to the seller?',
        description:
          'Escrow.com releases funds to the seller when the buyer accepts products and/or services or after the Inspection Period has expired.',
        redirectPath: '.support/faqs/when-are-funds-released-to-the-seller',
      },
    ],
  },
  {
    transactionStatus: 45,
    faqs: [
      {
        title: 'What happens if the buyer is unsatisfied with their merchandise?',
        description:
          'If the buyer is dissatisfied with a transaction, they have the option to “Accept” or “Reject” their merchandise during the inspection period.',
        redirectPath:
          '.support/faqs/what-happens-if-the-buyer-is-unsatisfied-with-their-merchandise',
      },
      {
        title:
          'What if there is a disagreement during the transaction? What is dispute resolution?',
        description:
          'A Dispute is initiated when the buyer returns merchandise to the seller and the seller rejects the merchandise.',
        redirectPath:
          '.support/faqs/what-if-there-is-a-disagreement-during-the-transaction-what-is-dispute-resolution',
      },
      {
        title: 'If the buyer returns an item, who pays for shipping?',
        description:
          'The buyer has the option to “Reject” or “Accept” the item. If the buyer decides to “Reject” the item, the buyer is responsible for covering the cost of shipping.',
        redirectPath: '.support/faqs/if-the-buyer-returns-an-item,-who-pays-for-shipping',
      },
    ],
  },
  {
    transactionStatus: 50,
    faqs: [
      {
        title: 'What happens if the buyer is unsatisfied with their merchandise?',
        description:
          'If the buyer is dissatisfied with a transaction, they have the option to “Accept” or “Reject” their merchandise during the inspection period.',
        redirectPath:
          '.support/faqs/what-happens-if-the-buyer-is-unsatisfied-with-their-merchandise',
      },
      {
        title:
          'What if there is a disagreement during the transaction? What is dispute resolution?',
        description:
          'A Dispute is initiated when the buyer returns merchandise to the seller and the seller rejects the merchandise.',
        redirectPath:
          '.support/faqs/what-if-there-is-a-disagreement-during-the-transaction-what-is-dispute-resolution',
      },
    ],
  },
  {
    transactionStatus: 55,
    faqs: [
      {
        title: 'What happens if the buyer is unsatisfied with their merchandise?',
        description:
          'If the buyer is dissatisfied with a transaction, they have the option to “Accept” or “Reject” their merchandise during the inspection period.',
        redirectPath:
          '.support/faqs/what-happens-if-the-buyer-is-unsatisfied-with-their-merchandise',
      },
      {
        title:
          'What if there is a disagreement during the transaction? What is dispute resolution?',
        description:
          'A Dispute is initiated when the buyer returns merchandise to the seller and the seller rejects the merchandise.',
        redirectPath:
          '.support/faqs/what-if-there-is-a-disagreement-during-the-transaction-what-is-dispute-resolution',
      },
    ],
  },
  {
    transactionStatus: 65,
    faqs: [
      {
        title:
          'What if there is a disagreement during the transaction? What is dispute resolution?',
        description:
          'A Dispute is initiated when the buyer returns merchandise to the seller and the seller rejects the merchandise.',
        redirectPath:
          '.support/faqs/what-if-there-is-a-disagreement-during-the-transaction-what-is-dispute-resolution',
      },
      {
        title: 'What happens if the buyer is unsatisfied with their merchandise?',
        description:
          'If the buyer is dissatisfied with a transaction, they have the option to “Accept” or “Reject” their merchandise during the inspection period.',
        redirectPath:
          '.support/faqs/what-happens-if-the-buyer-is-unsatisfied-with-their-merchandise',
      },
    ],
  },
  {
    transactionStatus: 70,
    faqs: [
      {
        title: 'How do buyers or sellers cancel transactions?',
        description:
          'Prior to the agreement of terms by both parties, the buyer or seller can cancel the transaction by clicking the “Cancel” button on the transaction detail screen.',
        redirectPath: '.support/faqs/how-do-buyers-or-sellers-cancel-transactions',
      },
      {
        title: 'Can a transaction be cancelled by someone other than the buyer or seller?',
        description:
          'Yes. We review transactions and may cancel a transaction if it does not meet the requirements for what can be sold through Escrow.com. We may also cancel a transaction if fraud is suspected.',
        redirectPath:
          '.support/faqs/can-a-transaction-be-cancelled-by-someone-other-than-the-buyer-or-seller',
      },
    ],
  },
  {
    transactionStatus: 75,
    faqs: [
      {
        title: 'How do buyers or sellers cancel transactions?',
        description:
          'Prior to the agreement of terms by both parties, the buyer or seller can cancel the transaction by clicking the “Cancel” button on the transaction detail screen.',
        redirectPath: '.support/faqs/how-do-buyers-or-sellers-cancel-transactions',
      },
      {
        title: 'Can a transaction be cancelled by someone other than the buyer or seller?',
        description:
          'Yes. We review transactions and may cancel a transaction if it does not meet the requirements for what can be sold through Escrow.com. We may also cancel a transaction if fraud is suspected.',
        redirectPath:
          '.support/faqs/can-a-transaction-be-cancelled-by-someone-other-than-the-buyer-or-seller',
      },
    ],
  },
];

const mapping = {
  buyer: buyerFAQMapping,
  seller: sellerFAQMapping,
  broker: brokerFAQMapping,
};

export default mapping;
