import Webhooks from 'spa/constants/WebhooksConstants';
import { createWebhooks } from 'spa/actions/WebhooksActions';

const initialState = {
  webhooks: [],
};

function webhooksReducer(state = initialState, action) {
  switch (action.type) {
    case Webhooks.GET_WEBHOOKS_REQUEST:
      return Object.assign({}, state, {
        display: {
          loading: true,
        },
      });
    case Webhooks.GET_WEBHOOKS_SUCCESS:
      return Object.assign({}, state, {
        display: {
          loading: false,
          success: true,
        },
        webhooks: action.webhooks,
      });
    case Webhooks.GET_WEBHOOKS_FAILURE:
      return Object.assign({}, state, {
        display: {
          loading: false,
          success: false,
        },
      });
    case createWebhooks.TRIGGER:
      return Object.assign({}, state, {
        create: {
          loading: true,
        },
      });
    case createWebhooks.SUCCESS:
      return Object.assign({}, state, {
        create: {
          loading: false,
          success: true,
        },
        webhooks: [...state.webhooks, action.payload],
      });
    case createWebhooks.FAILURE:
      return Object.assign({}, state, {
        create: {
          loading: false,
          success: false,
        },
      });
    case Webhooks.DELETE_WEBHOOKS_REQUEST:
      return Object.assign({}, state, {
        webhooks: state.webhooks.map((webhook) =>
          webhook.id === action.id ? { ...webhook, deleting: true } : webhook
        ),
      });
    case Webhooks.DELETE_WEBHOOKS_SUCCESS:
      return Object.assign({}, state, {
        webhooks: state.webhooks.filter((webhook) => webhook.id !== action.id),
      });
    case Webhooks.DELETE_WEBHOOKS_FAILURE:
      return state;
    default:
      return state;
  }
}

export default webhooksReducer;
