/* eslint-disable-line no-console */
import React, { Component } from 'react';
import V2CheckoutContainer from 'spa/containers/Checkout/V2';
import Header from 'spa/containers/Header';
import Footer from 'spa/containers/Footer';
import { gettext } from '../../../utils/filters';
import { initialiseOlarkByPage } from '../../../components/Olark';

class CheckoutPage extends Component {
  constructor() {
    super();
    initialiseOlarkByPage();
  }

  render() {
    return (
      <div>
        <Header placeholder={gettext('Pay with Escrow.com')} />
        <div className="checkout section section--small section--dark">
          <div className="checkout-content section-container">
            <div className="checkout-inner">
              <V2CheckoutContainer dataTrackingPrefix="checkout" />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default CheckoutPage;
