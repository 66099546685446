import React from 'react';
import CreditCardPaymentContainer from 'spa/containers/CreditCardPayment';

const CreditCardPaymentPage = () => (
  <div className="section section--small section--dark">
    <div className="section-container">
      <div className="account-inner">
        <section className="account-section">
          <CreditCardPaymentContainer />
        </section>
      </div>
    </div>
  </div>
);

export default CreditCardPaymentPage;
