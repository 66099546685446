import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Checkbox = ({
  name,
  id,
  label,
  onClick,
  checked,
  disabled,
  showLabel,
  className,
  flipped,
}) => (
  <div
    className={classnames('customCheckbox', className, {
      'customCheckbox--flipped': flipped,
    })}
  >
    <input
      type="checkbox"
      id={id}
      name={name}
      className="customCheckbox-input"
      onChange={onClick}
      checked={checked}
      disabled={disabled}
    />
    <label htmlFor={id} className="customCheckbox-label">
      <span className={showLabel ? 'customCheckbox-label-inner' : 'is-accessibly-hidden'}>
        {label}
      </span>
    </label>
  </div>
);

Checkbox.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  showLabel: PropTypes.bool,
};

export default Checkbox;
