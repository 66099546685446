import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  V3KYCMobilePages as Pages,
  KYC_FILES as FILES,
  KYC_BUTTONS as BUTTONS,
  KYC_TITLES as TITLES,
  KYC_SUBTITLES as SUBTITLES,
  KYC_PAGE_STATES,
} from 'spa/constants/VerificationConstants';
import {
  kycSelectedIDSelector,
  kycImageSelector,
  updateKycImageId,
} from 'spa/features/kyc/kycSlice';
import { useUploadFileMutation } from 'spa/features/kyc/kycApi';
import { ERROR_TYPES } from 'spa/constants/VerificationConstants';
import { dataURLtoFile } from 'spa/../utils/PhotoCapture';

import MobilePage from '../../components/MobilePage';
import KYCAlert from '../../components/KYCAlert';
import PhotoReview from '../../components/PhotoReview';
import RetakePhotoButton from '../../components/ExtraButtons/RetakePhotoButton';

const { INITIAL, LOADING, ERROR, SUCCESS } = KYC_PAGE_STATES;

const IDCaptureReviewPage = ({ back }) => {
  const dispatch = useDispatch();

  const [pageState, setPageState] = useState(INITIAL);
  const [onSubmit, setOnSubmit] = useState(null);

  const [prevPageTrigger, setPrevPageTrigger] = useState(false);

  const key = useMemo(() => (back ? FILES.ID_BACK : FILES.ID_FRONT), [back]);

  const id = useSelector(kycSelectedIDSelector);
  const kycImageDataUrl = useSelector(kycImageSelector(key));

  const nextPage = useMemo(
    () =>
      back || (id && id.type === 'passport')
        ? Pages.ID_BACK_UPLOAD_SUCCESS
        : Pages.ID_FRONT_UPLOAD_SUCCESS,
    [back, id]
  );

  const title = useMemo(() => {
    if (id && id.type !== 'passport') {
      return back ? TITLES.ID_BACK : TITLES.ID_FRONT;
    }

    return TITLES.PASSPORT;
  }, [back, id]);

  const subtitle = useMemo(() => {
    if (id && id.type !== 'passport') {
      back ? SUBTITLES.ID_BACK : SUBTITLES.ID_FRONT;
    }
    return SUBTITLES.ID;
  }, [back, id]);

  const [uploadFile, { data, isLoading, isError, error, isSuccess, reset }] =
    useUploadFileMutation();

  const handleUpload = useCallback(async () => {
    const capturedFile = await dataURLtoFile(kycImageDataUrl, `${key}.png`);
    await uploadFile(capturedFile);
  }, [key, kycImageDataUrl, uploadFile]);

  useEffect(() => {
    if (isSuccess && data) {
      const uploadId = data.id;
      if (uploadId) {
        dispatch(updateKycImageId({ fileKey: key, id: uploadId }));
        setPageState(SUCCESS);
      }
    } else if (isError) {
      setOnSubmit(() => reset);
      setPageState(ERROR);
    } else if (isLoading) {
      setPageState(LOADING);
    } else {
      setOnSubmit(() => handleUpload);
      setPageState(INITIAL);
    }
  }, [data, dispatch, key, handleUpload, isError, isLoading, isSuccess, reset]);

  return (
    <MobilePage
      title={pageState === LOADING ? null : title}
      subtitle={pageState === LOADING ? null : subtitle}
      ctaText={pageState === INITIAL ? BUTTONS.SubmitPhoto : BUTTONS.TryAgain}
      onSubmit={onSubmit}
      nextPage={nextPage}
      nextPageTrigger={pageState === SUCCESS}
      disableButtons={pageState === LOADING}
      noButton={pageState === LOADING ? true : null}
      ExtraButton={(props) => (
        <RetakePhotoButton
          {...props}
          onClick={() => {
            setPrevPageTrigger(true);
          }}
        >
          Retake photo
        </RetakePhotoButton>
      )}
      prevPageTrigger={prevPageTrigger}
    >
      {error && (
        <KYCAlert
          isError={error}
          errorType={ERROR_TYPES.PHOTO_UPLOAD_FAILED}
          xRequestId={error.xRequestId}
        />
      )}
      <PhotoReview fileKey={key} parentPageState={pageState} />
    </MobilePage>
  );
};
export default IDCaptureReviewPage;
