import React from 'react';
import PropTypes from 'prop-types';

const CollapsableContentLink = ({ link }) => (
  <a className="collapsableContent-body-link" href={link}>
    {link}
  </a>
);

CollapsableContentLink.propTypes = {
  link: PropTypes.string,
};

export default CollapsableContentLink;
