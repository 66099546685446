import React from 'react';
import { Avatar } from '@mui/material';

const LabeledAvatar = (props) => (
  <div className="materialUI-avatar">
    <Avatar {...props}>{props.children}</Avatar>
    {props.label && <div className="materialUI-avatar-label">{props.label}</div>}
  </div>
);

export default LabeledAvatar;
