import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { EscrowAlert } from 'spa/features/ui/EscrowAlert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { SmallText, MediumText } from 'spa/features/ui/EscrowTypography';
import { usePatchTransactionMutation } from '../../transactionApi';
import { transactionSelector } from '../../transactionSlice';
import { EscrowButton } from 'spa/features/ui/EscrowButton';

export const ConfirmHandover = ({ transactionId }) => {
  const theme = useTheme();
  const isMobileViewport = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState(false);
  const transaction = useSelector(transactionSelector(transactionId));
  const [patchTransaction, { isLoading, error }] = usePatchTransactionMutation();
  const [errorMsg, setErrorMsg] = useState('');

  if (!transaction) {
    return null;
  }

  const receivingRole = transaction.transactionStatus === 25 ? 'buyer' : 'seller';
  const shipActionAPI = transaction.transactionStatus === 25 ? 'ship' : 'ship_return';
  const shipActionCta = transaction.transactionStatus === 25 ? 'handover' : 'return';
  const shipActionBody = transaction.transactionStatus === 25 ? 'handed over' : 'returned';
  const transactionType = transaction.transactionTypeCode.replace('_', ' ');

  const handleModalClose = () => {
    if (isLoading) {
      return;
    }
    setOpen(false);
  };

  const handleAccept = async () => {
    try {
      await patchTransaction({
        id: transaction.id,
        action: shipActionAPI,
      }).unwrap();
      setOpen(false);
    } catch (e) {
      let errorMsgTmp =
        "The handover couldn't be confirmed due to a technical issue. Please refresh the page and try again.";
      if (e.xRequestId) {
        errorMsgTmp += ` If the issue persists, contact support@escrow.com and provide the id: ${e.xRequestId}`;
      }
      setErrorMsg(errorMsgTmp);
    }
  };

  return (
    <React.Fragment>
      <EscrowButton variant="contained" onClick={() => setOpen(true)}>
        Confirm {shipActionCta} of {transactionType}
      </EscrowButton>
      <Dialog
        fullScreen={isMobileViewport}
        open={open}
        onClose={handleModalClose}
        PaperProps={{
          sx: {
            maxWidth: '685px',
          },
        }}
      >
        {isMobileViewport && (
          <IconButton
            sx={{ marginLeft: 'auto' }}
            onClick={handleModalClose}
            aria-label="Close Handover Confirmation Modal"
          >
            <CloseIcon sx={{ color: 'mono.dark', height: 24, width: 24 }} />
          </IconButton>
        )}

        {error && (
          <EscrowAlert>
            <AlertTitle>Unable to confirm handover of {transactionType}</AlertTitle>
            {errorMsg}
          </EscrowAlert>
        )}

        <DialogTitle>
          <MediumText>
            {' '}
            Confirm {shipActionCta} of {transactionType}{' '}
          </MediumText>
        </DialogTitle>
        <DialogContent>
          <SmallText>
            Once you indicate that the {transactionType} has been {shipActionBody} to the{' '}
            {receivingRole}, they will be instructed to confirm their receipt.
          </SmallText>
        </DialogContent>
        <DialogActions>
          {!isMobileViewport && <EscrowButton onClick={handleModalClose}>Back</EscrowButton>}
          <EscrowButton
            sx={{ width: isMobileViewport ? '100%' : 'auto' }}
            variant="contained"
            disabled={isLoading}
            onClick={handleAccept}
          >
            Confirm {shipActionCta}
          </EscrowButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
