import React, { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { EscrowButton } from 'spa/features/ui/EscrowButton';
import { validateKYCFile } from '../../../../../../utils/FormValidation';

const UploadFileButton = ({ children, disabled, sx, control, onSubmit, onUploadFile }) => {
  const fileInputRef = useRef(null);
  const _onUploadFile = (file) => {
    if (onUploadFile) {
      onUploadFile(file);
    }
  };

  return (
    <Controller
      control={control}
      name="file-upload"
      render={({ field }) => (
        <EscrowButton
          variant="text"
          disabled={disabled}
          style={{ ...sx, backgroundColor: 'transparent', textDecoration: 'underline' }}
          {...field}
          // to force it to work as a file upload button
          component="label"
        >
          {children}
          <input
            ref={fileInputRef}
            type="file"
            accept="application/pdf"
            style={{ display: 'none' }}
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) {
                const errors = validateKYCFile(file);
                if (errors.length === 0) {
                  _onUploadFile(file);
                  if (onSubmit) {
                    onSubmit();
                  }
                } else {
                  // TODO: Add error handling
                  // eslint-disable-next-line no-console
                  console.log('Error when selecting file', errors);
                }
              } else {
                // eslint-disable-next-line no-console
                console.log('Error when selecting file');
              }
            }}
          />
        </EscrowButton>
      )}
    />
  );
};

export default UploadFileButton;
