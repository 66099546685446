import React from 'react';
import { Section, SectionTitle, SectionDesc, SectionImage } from 'spa/components/Section';
import A from 'spa/components/A';
import AccentedText from 'spa/components/AccentedText';
import { urlFor } from '../../../routeConfig';

const WooCommerce = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktopSmall-9">
        <h1 className="integrationsPortal-title">Escrow.com Extension for WooCommerce</h1>
        <Section>
          <SectionTitle>Overview</SectionTitle>
          <SectionDesc>
            You can now add Escrow.com payments directly to your WooCommerce store with our
            extension for WooCommerce.
          </SectionDesc>
          <SectionDesc>
            Escrow.com provides a secure payment solution for websites, marketplaces, classified
            sites, shopping carts, and mobile apps with no chargebacks, ever.
          </SectionDesc>
          <SectionDesc>
            Escrow.com is especially useful in situations where buyers and sellers do not know each
            other, do not trust each other, or where large sums of money are exchanging hands. Rates
            are as low as 0.89%.
          </SectionDesc>
          <SectionDesc>
            <a
              href="https://www.escrow.com/what-is-escrow"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more about Escrow.com and its many benefits here.
            </a>
          </SectionDesc>
        </Section>
        <Section>
          <SectionTitle>Installation</SectionTitle>
          <SectionDesc>
            We recommend that you install and/or update the Escrow.com Extension for WooCommerce
            from within the WordPress admin panel. This may be done by opening the WordPress Admin
            Panel, navigating to <AccentedText>Plugins</AccentedText>, then navigating to{' '}
            <AccentedText>Add New</AccentedText>, and then searching for{' '}
            <AccentedText>Escrow.com</AccentedText> via the search box in the upper right-hand
            corner of the page. The Escrow.com plugin should then appear, which you may proceed to
            install or update via the buttons in the resulting listing. Here is what this looks
            like.
          </SectionDesc>
          <SectionImage
            src="../build/images/plugins/wordpress-plugin-directory-search-escrow.png"
            alt="WordPress Plugin Directory Search Escrow"
          />
          <SectionDesc>
            Please note that the naming convention (the slug in WordPress-speak) of our plugin
            within WordPress is <AccentedText>woo-escrow-gateway</AccentedText>. This differs from
            the <AccentedText>woocommerce-escrow-gateway</AccentedText> with which we initially
            published our plugin independent of WordPress. For this reason, if you do run into a
            situation where more than one version of our plugin are simultaneously installed, it is
            likely due to this. To remedy this situation, please ensure that the official WordPress
            version (the <AccentedText>woo-escrow-gateway</AccentedText> one) is installed.
          </SectionDesc>
        </Section>
        <Section>
          <SectionTitle>Manual Installation</SectionTitle>
          <SectionDesc>
            To install the Escrow.com Extension for WooCommerce manually, download the latest
            version from the links provided in the Release History section below. You may also
            download the plugin directly from{' '}
            <a
              href="https://wordpress.org/plugins/woo-escrow-gateway/"
              target="_blank"
              rel="noopener noreferrer"
            >
              our page in the WordPress Plugin Directory
            </a>
            . Once you have downloaded the desired version, click on{' '}
            <AccentedText>Plugins</AccentedText> in WordPress, then click on{' '}
            <AccentedText>Add New</AccentedText>, and then click on{' '}
            <AccentedText>Upload Plugin</AccentedText>. This will display a form for uploading the
            .zip file you downloaded earlier. Click on <AccentedText>Choose File</AccentedText> to
            browse to the .zip file, select the .zip file, and click{' '}
            <AccentedText>Open</AccentedText>. Now click <AccentedText>Install Now</AccentedText> to
            start the installation. If all goes well, Escrow.com should now appear as an option
            under the Checkout tab of the WooCommerce Settings page.
          </SectionDesc>
          <SectionDesc>Here is what the WordPress plugin upload page looks like.</SectionDesc>
          <SectionImage
            src="../build/images/plugins/wordpress-upload-plugin.png"
            alt="WordPress Upload Plugin Page"
          />
        </Section>
        <Section>
          <SectionTitle>Release History</SectionTitle>
          <SectionDesc>
            If you prefer to install plugins manually, you may download the desired version from the
            table below and upload it via the WordPress Upload Plugin form.
          </SectionDesc>
          <table className="integrationsPortal-section-table pluginTable">
            <thead className="apiTable-header">
              <tr className="apiTable-row">
                <th className="apiTable-cell">Download</th>
                <th className="apiTable-cell">Version</th>
                <th className="apiTable-cell">Released</th>
                <th className="apiTable-cell">Notes</th>
              </tr>
            </thead>
            <tbody>
              <tr className="apiTable-row">
                <td className="apiTable-cell" data-cell-title="Download">
                  <a
                    href="https://downloads.wordpress.org/plugin/woo-escrow-gateway.2.4.0.zip"
                    data-tracking-section="plugins"
                    data-tracking-subsection="woocommerce"
                    data-tracking-label="download-2.4.0"
                  >
                    Download Extension
                  </a>
                </td>
                <td className="apiTable-cell" data-cell-title="Version">
                  2.4.0
                </td>
                <td className="apiTable-cell" data-cell-title="Released">
                  2021-03-12
                </td>
                <td className="apiTable-cell" data-cell-title="Notes">
                  Added a fix when calling the function get_cart in woocommerce admin view
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell" data-cell-title="Download">
                  <a
                    href="https://downloads.wordpress.org/plugin/woo-escrow-gateway.2.3.0.zip"
                    data-tracking-section="plugins"
                    data-tracking-subsection="woocommerce"
                    data-tracking-label="download-2.3.0"
                  >
                    Download Extension
                  </a>
                </td>
                <td className="apiTable-cell" data-cell-title="Version">
                  2.3.0
                </td>
                <td className="apiTable-cell" data-cell-title="Released">
                  2019-06-04
                </td>
                <td className="apiTable-cell" data-cell-title="Notes">
                  The British pound (GBP) has been added to the Currency drop down list on the
                  Escrow.com settings page in WooCommerce. When selected all transactions will be
                  denominated in GBP.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell" data-cell-title="Download">
                  <a
                    href="https://downloads.wordpress.org/plugin/woo-escrow-gateway.2.2.0.zip"
                    data-tracking-section="plugins"
                    data-tracking-subsection="woocommerce"
                    data-tracking-label="download-2.2.0"
                  >
                    Download Extension
                  </a>
                </td>
                <td className="apiTable-cell" data-cell-title="Version">
                  2.2.0
                </td>
                <td className="apiTable-cell" data-cell-title="Released">
                  2018-12-12
                </td>
                <td className="apiTable-cell" data-cell-title="Notes">
                  The Australian dollar (AUD) has been added to the Currency drop down list on the
                  Escrow.com settings page in WooCommerce. When selected all transactions will be
                  denominated in AUD.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell" data-cell-title="Download">
                  <a
                    href="https://downloads.wordpress.org/plugin/woo-escrow-gateway.2.1.0.zip"
                    data-tracking-section="plugins"
                    data-tracking-subsection="woocommerce"
                    data-tracking-label="download-2.1.0"
                  >
                    Download Extension
                  </a>
                </td>
                <td className="apiTable-cell" data-cell-title="Version">
                  2.1.0
                </td>
                <td className="apiTable-cell" data-cell-title="Released">
                  2018-10-05
                </td>
                <td className="apiTable-cell" data-cell-title="Notes">
                  The buyer{"'"}s address is no longer required by the Escrow.com extension. If you
                  wish to disable the address fields on your checkout page, you may now make this
                  change to your WooCommerce installation.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell" data-cell-title="Download">
                  <a
                    href="https://downloads.wordpress.org/plugin/woo-escrow-gateway.2.0.0.zip"
                    data-tracking-section="plugins"
                    data-tracking-subsection="woocommerce"
                    data-tracking-label="download-2.0.0"
                  >
                    Download Extension
                  </a>
                </td>
                <td className="apiTable-cell" data-cell-title="Version">
                  2.0.0
                </td>
                <td className="apiTable-cell" data-cell-title="Released">
                  2018-08-07
                </td>
                <td className="apiTable-cell" data-cell-title="Notes">
                  Buyers placing orders on the WooCommerce checkout page are now redirected to the
                  new streamlined Escrow Pay workflow. This allows the buyer to register, verify,
                  and pay all from a single page on Escrow.com. This replaces the old workflow which
                  unnecessarily bounced the buyer around to various parts of Escrow.com.
                  <br />
                  <br />
                  When the shopping cart contains products from a single vendor at the time of
                  checkout, the buyer is redirected directly to Escrow.com, not to the WooCommerce
                  order receipt page (the buyer used to have to click through to Escrow.com from
                  here).
                  <br />
                  <br />
                  When the shopping cart contains products from multiple vendors at the time of
                  checkout, the buyer is redirected to the WooCommerce order receipt page, which
                  displays a button to the Escrow Pay workflow for each vendor in the order.
                  <br />
                  <br />
                  The Escrow Pay workflow redirects the buyer back to the WooCommerce order detail
                  page when they have finished paying for their order on Escrow.com.
                  <br />
                  <br />
                  To avoid a potential issue when upgrading to version 2.0.0, please re-save your
                  settings on the Escrow.com settings page. Make sure that the desired Escrow API
                  URL is selected; note that this now contains only the root URL (e.g.
                  https://api.escrow.com/), not any additional path information (e.g.
                  https://api.escrow.com/2017-09-01/).
                  <br />
                  <br />
                  More information about Escrow Pay may be found at{' '}
                  <a href="https://www.escrow.com/pay">www.escrow.com/pay</a>
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell" data-cell-title="Download">
                  <a
                    href="https://downloads.wordpress.org/plugin/woo-escrow-gateway.1.5.1.zip"
                    data-tracking-section="plugins"
                    data-tracking-subsection="woocommerce"
                    data-tracking-label="download-1.5.1"
                  >
                    Download Extension
                  </a>
                </td>
                <td className="apiTable-cell" data-cell-title="Version">
                  1.5.1
                </td>
                <td className="apiTable-cell" data-cell-title="Released">
                  2018-06-28
                </td>
                <td className="apiTable-cell" data-cell-title="Notes">
                  Fixed a bug where a non-one-day inspection period would lead to an HTTP 422
                  exception when tax was charged on the checkout page.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell" data-cell-title="Download">
                  <a
                    href="https://downloads.wordpress.org/plugin/woo-escrow-gateway.1.5.0.zip"
                    data-tracking-section="plugins"
                    data-tracking-subsection="woocommerce"
                    data-tracking-label="download-1.5.0"
                  >
                    Download Extension
                  </a>
                </td>
                <td className="apiTable-cell" data-cell-title="Version">
                  1.5.0
                </td>
                <td className="apiTable-cell" data-cell-title="Released">
                  2018-06-25
                </td>
                <td className="apiTable-cell" data-cell-title="Notes">
                  Milestone transactions are now supported. See{' '}
                  <AccentedText>Transaction Type</AccentedText> on the Escrow.com settings page.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell" data-cell-title="Download">
                  <a
                    href="https://downloads.wordpress.org/plugin/woo-escrow-gateway.1.4.1.zip"
                    data-tracking-section="plugins"
                    data-tracking-subsection="woocommerce"
                    data-tracking-label="download-1.4.1"
                  >
                    Download Extension
                  </a>
                </td>
                <td className="apiTable-cell" data-cell-title="Version">
                  1.4.1
                </td>
                <td className="apiTable-cell" data-cell-title="Released">
                  2018-05-29
                </td>
                <td className="apiTable-cell" data-cell-title="Notes">
                  Item description now contains the perma-link to the product in WooCommerce instead
                  of repeating the item name. The <AccentedText>Tax</AccentedText> item is now more
                  accurately called <AccentedText>Tax collected by seller</AccentedText>.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell" data-cell-title="Download">
                  <a
                    href="https://downloads.wordpress.org/plugin/woo-escrow-gateway.1.4.0.zip"
                    data-tracking-section="plugins"
                    data-tracking-subsection="woocommerce"
                    data-tracking-label="download-1.4.0"
                  >
                    Download Extension
                  </a>
                </td>
                <td className="apiTable-cell" data-cell-title="Version">
                  1.4.0
                </td>
                <td className="apiTable-cell" data-cell-title="Released">
                  2018-05-16
                </td>
                <td className="apiTable-cell" data-cell-title="Notes">
                  Tax total from shopping cart now may be included as a line item in every
                  Escrow.com transaction. See <AccentedText>Tax Options</AccentedText> on the
                  Escrow.com settings page in the WooCommerce admin.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell" data-cell-title="Download">
                  <a
                    href="https://downloads.wordpress.org/plugin/woo-escrow-gateway.1.3.0.zip"
                    data-tracking-section="plugins"
                    data-tracking-subsection="woocommerce"
                    data-tracking-label="download-1.3.0"
                  >
                    Download Extension
                  </a>
                </td>
                <td className="apiTable-cell" data-cell-title="Version">
                  1.3.0
                </td>
                <td className="apiTable-cell" data-cell-title="Released">
                  2018-02-15
                </td>
                <td className="apiTable-cell" data-cell-title="Notes">
                  Address data of the buyer is now sent to Escrow.com when an order is placed. This
                  means that new buyers will not need to re-enter address data on Escrow.com. The
                  address data of existing Escrow.com users is not overwritten.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell" data-cell-title="Download">
                  <a
                    href="https://downloads.wordpress.org/plugin/woo-escrow-gateway.1.2.1.zip"
                    data-tracking-section="plugins"
                    data-tracking-subsection="woocommerce"
                    data-tracking-label="download-1.2.1"
                  >
                    Download Extension
                  </a>
                </td>
                <td className="apiTable-cell" data-cell-title="Version">
                  1.2.1
                </td>
                <td className="apiTable-cell" data-cell-title="Released">
                  2018-02-09
                </td>
                <td className="apiTable-cell" data-cell-title="Notes">
                  Use smaller Escrow.com icon. This works better on themes that do not automatically
                  resize the icons on the checkout page.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell" data-cell-title="Download">
                  <a
                    href="https://downloads.wordpress.org/plugin/woo-escrow-gateway.1.2.0.zip"
                    data-tracking-section="plugins"
                    data-tracking-subsection="woocommerce"
                    data-tracking-label="download-1.2.0"
                  >
                    Download Extension
                  </a>
                </td>
                <td className="apiTable-cell" data-cell-title="Version">
                  1.2.0
                </td>
                <td className="apiTable-cell" data-cell-title="Released">
                  2018-02-09
                </td>
                <td className="apiTable-cell" data-cell-title="Notes">
                  Buyer experience streamlined via automatic agreement by the vendor and addition of
                  payment button on order receipt that redirects buyer into payment wizard on
                  Escrow.com.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell" data-cell-title="Download">
                  <a
                    href="https://downloads.wordpress.org/plugin/woo-escrow-gateway.1.1.1.zip"
                    data-tracking-section="plugins"
                    data-tracking-subsection="woocommerce"
                    data-tracking-label="download-1.1.1"
                  >
                    Download Extension
                  </a>
                </td>
                <td className="apiTable-cell" data-cell-title="Version">
                  1.1.1
                </td>
                <td className="apiTable-cell" data-cell-title="Released">
                  2018-01-15
                </td>
                <td className="apiTable-cell" data-cell-title="Notes">
                  Added Escrow.com icon to the Escrow.com payment option on the checkout page.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell" data-cell-title="Download">
                  <a
                    href="https://downloads.wordpress.org/plugin/woo-escrow-gateway.1.1.0.zip"
                    data-tracking-section="plugins"
                    data-tracking-subsection="woocommerce"
                    data-tracking-label="download-1.1.0"
                  >
                    Download Extension
                  </a>
                </td>
                <td className="apiTable-cell" data-cell-title="Version">
                  1.1.0
                </td>
                <td className="apiTable-cell" data-cell-title="Released">
                  2018-01-02
                </td>
                <td className="apiTable-cell" data-cell-title="Notes">
                  WC Vendors Pro <AccentedText>Fixed</AccentedText>,{' '}
                  <AccentedText>Fixed + Fee</AccentedText>, <AccentedText>Percentage</AccentedText>,
                  and <AccentedText>Percentage + Fee</AccentedText> commission scenarios are now
                  supported. Commissions set in WC Vendors Pro take precedence over commissions set
                  in WC Vendors which take precedence over commissions set on the Escrow.com
                  settings page.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell" data-cell-title="Download">
                  <a
                    href="https://downloads.wordpress.org/plugin/woo-escrow-gateway.1.0.3.zip"
                    data-tracking-section="plugins"
                    data-tracking-subsection="woocommerce"
                    data-tracking-label="download-1.0.3"
                  >
                    Download Extension
                  </a>
                </td>
                <td className="apiTable-cell" data-cell-title="Version">
                  1.0.3
                </td>
                <td className="apiTable-cell" data-cell-title="Released">
                  2017-12-11
                </td>
                <td className="apiTable-cell" data-cell-title="Notes">
                  Added setting to display the Escrow.com payment option on the checkout page only
                  when all the items in the shopping cart have been tagged with a custom product
                  attribute named <AccentedText>escrowable</AccentedText> where the value is set to{' '}
                  <AccentedText>true</AccentedText>.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell" data-cell-title="Download">
                  <a
                    href="https://downloads.wordpress.org/plugin/woo-escrow-gateway.1.0.2.zip"
                    data-tracking-section="plugins"
                    data-tracking-subsection="woocommerce"
                    data-tracking-label="download-1.0.2"
                  >
                    Download Extension
                  </a>
                </td>
                <td className="apiTable-cell" data-cell-title="Version">
                  1.0.2
                </td>
                <td className="apiTable-cell" data-cell-title="Released">
                  2017-12-05
                </td>
                <td className="apiTable-cell" data-cell-title="Notes">
                  WooCommerce order status now correctly updates to{' '}
                  <AccentedText>Processing</AccentedText> upon order creation.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell" data-cell-title="Download">
                  <a
                    href="https://downloads.wordpress.org/plugin/woo-escrow-gateway.1.0.1.zip"
                    data-tracking-section="plugins"
                    data-tracking-subsection="woocommerce"
                    data-tracking-label="download-1.0.1"
                  >
                    Download Extension
                  </a>
                </td>
                <td className="apiTable-cell" data-cell-title="Version">
                  1.0.1
                </td>
                <td className="apiTable-cell" data-cell-title="Released">
                  2017-12-04
                </td>
                <td className="apiTable-cell" data-cell-title="Notes">
                  Debug and error messages now written to the WooCommerce logs. These logs may be
                  viewed in the WooCommerce admin under <AccentedText>WooCommerce</AccentedText> /{' '}
                  <AccentedText>Status</AccentedText> / <AccentedText>Logs</AccentedText>.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell" data-cell-title="Download">
                  <a
                    href="https://downloads.wordpress.org/plugin/woo-escrow-gateway.1.0.0.zip"
                    data-tracking-section="plugins"
                    data-tracking-subsection="woocommerce"
                    data-tracking-label="download-1.0.0"
                  >
                    Download Extension
                  </a>
                </td>
                <td className="apiTable-cell" data-cell-title="Version">
                  1.0.0
                </td>
                <td className="apiTable-cell" data-cell-title="Released">
                  2017-11-28
                </td>
                <td className="apiTable-cell" data-cell-title="Notes">
                  Initial Release
                </td>
              </tr>
            </tbody>
          </table>
        </Section>
        <Section>
          <SectionTitle>Compatibility</SectionTitle>
          <SectionDesc>
            The Escrow.com Extension for WooCommerce has been tested with the following software. It
            is not guaranteed to work with older versions.
          </SectionDesc>
          <ul>
            <li>WooCommerce 3.0</li>
            <li>WordPress 4.0</li>
            <li>PHP 5.6</li>
          </ul>
        </Section>
        <Section>
          <SectionTitle>Multi-Vendor Support</SectionTitle>
          <SectionDesc>
            As of version 1.0.0,{' '}
            <a href="https://www.wcvendors.com/" target="_blank" rel="noopener noreferrer">
              WC Vendors
            </a>{' '}
            and{' '}
            <a
              href="https://www.wcvendors.com/product/wc-vendors-pro/"
              target="_blank"
              rel="noopener noreferrer"
            >
              WC Vendors Pro
            </a>{' '}
            are the only supported multi-vendor extensions.
          </SectionDesc>
          <SectionDesc>
            To enable WC Vendors, you need to select it from the Multi-Vendor Extension drop down
            list in the Vendor Settings section of the Escrow.com settings page. See below for more
            information.
          </SectionDesc>
          <SectionDesc>
            If you use an alternative multi-vendor extension to WooCommerce, please let us know at{' '}
            <A link={{ type: 'email', route: 'mailto:plugins@escrow.com' }}>plugins@escrow.com</A>{' '}
            and we will look into adding support for it.
          </SectionDesc>
        </Section>
        <Section>
          <SectionTitle>Settings</SectionTitle>
          <SectionDesc>
            This section lists each of the settings in the Escrow.com Extension for WooCommerce. To
            navigate to the Escrow.com settings page within WooCommerce, click on{' '}
            <AccentedText>WooCommerce</AccentedText> / <AccentedText>Settings</AccentedText> /{' '}
            <AccentedText>Checkout</AccentedText> / <AccentedText>Escrow.com</AccentedText>.
          </SectionDesc>
          <SectionDesc>
            Here is what the Escrow.com settings page looks like in WooCommerce.
          </SectionDesc>
          <SectionImage
            src="../build/images/plugins/woocommerce-escrow-settings.png"
            alt="Escrow.com Settings Page in WooCommerce"
          />
          <h3>API Settings</h3>
          <h4>Escrow Email</h4>
          <SectionDesc>
            This field should contain the email address you used to sign up for Escrow.com Platform
            API. If you do not have an account, you may{' '}
            <A link={{ type: 'internal', route: urlFor('integrations_signup') }}>
              create a new account here
            </A>
            .
          </SectionDesc>
          <h4>Escrow API Key</h4>
          <SectionDesc>
            This field should contain the API Key you wish to use with your WooCommerce store.
            Please note that the plugin must match the environment you mave targeted with the Escrow
            API URL setting. You may create and manage your Escrow API Keys on the{' '}
            <A link={{ type: 'internal', route: urlFor('integrations_api') }}>API Keys page</A>. For
            reference the API Keys page looks like this:
          </SectionDesc>
          <SectionImage src="../build/images/plugins/api-key-management.png" alt="API Keys page" />
          <h4>Escrow API URL</h4>
          <SectionDesc>
            This drop down list allows you to select the environment you wish to target.
          </SectionDesc>
          <table className="integrationsPortal-section-table pluginTable">
            <thead className="apiTable-header">
              <tr className="apiTable-row">
                <th className="apiTable-cell">Environment</th>
                <th className="apiTable-cell">Purpose</th>
                <th className="apiTable-cell">URL</th>
              </tr>
            </thead>
            <tbody>
              <tr className="apiTable-row">
                <td className="apiTable-cell" data-cell-title="Environment">
                  Production
                </td>
                <td className="apiTable-cell" data-cell-title="Purpose">
                  For creating live orders
                </td>
                <td className="apiTable-cell" data-cell-title="URL">
                  <AccentedText>https://api.escrow.com/2017-09-01/</AccentedText>
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell" data-cell-title="Environment">
                  Sandbox
                </td>
                <td className="apiTable-cell" data-cell-title="Purpose">
                  For testing your integration
                </td>
                <td className="apiTable-cell" data-cell-title="URL">
                  <AccentedText>https://api.escrow-sandbox.com/2017-09-01/</AccentedText>
                </td>
              </tr>
            </tbody>
          </table>
          <h3>Checkout Settings</h3>
          <h4>Enabled/Disabled</h4>
          <SectionDesc>
            This checkbox controls whether the Escrow.com payment option appears on the checkout
            page.
          </SectionDesc>
          <h4>Enable When</h4>
          <SectionDesc>
            Introduced in version 1.0.3, this field allows you to display the Escrow.com payment
            option on the checkout page only when all the items in the shopping cart have been
            tagged with a customer product attribute named <AccentedText>escrowable</AccentedText>{' '}
            where the value of that custom product attribute is set to{' '}
            <AccentedText>true</AccentedText>.
          </SectionDesc>
          <SectionDesc>
            When <AccentedText>Enable Always</AccentedText> is selected, the Escrow.com payment
            option will always be displayed in the shopping cart (with the exception that the above{' '}
            <AccentedText>Enable/Disable</AccentedText> will override this when unchecked).
          </SectionDesc>
          <SectionDesc>
            When <AccentedText>Enable Only When All Items Escrowable</AccentedText> is selected, the
            Escrow.com payment option will only be displayed in the shopping cart when all the items
            in the shopping cart have the <AccentedText>escrowable</AccentedText> custom product
            attribute set to <AccentedText>true</AccentedText>. The following image illustrates how
            to add a custom product attribute.
          </SectionDesc>
          <SectionImage
            src="../build/images/plugins/woocommerce-escrowable-attribute.png"
            alt="Escrowable Customer Product Attribute"
          />
          <h4>Checkout Title</h4>
          <SectionDesc>
            This field allows you to change the title of the Escrow.com payment option on the
            checkout page.
          </SectionDesc>
          <h4>Checkout Description</h4>
          <SectionDesc>
            This field allows you to change the description of the Escrow.com payment option on the
            checkout page.
          </SectionDesc>
          <h3>Transaction Settings</h3>
          <h4>Currency</h4>
          <SectionDesc>
            This drop down list controls which currency is configured on each transaction that gets
            created on Escrow.com via this integration. Currencies supported at the moment are{' '}
            <AccentedText>USD</AccentedText> and <AccentedText>EURO</AccentedText>.
          </SectionDesc>
          <h4>Escrow Fee Paid By</h4>
          <SectionDesc>
            This drop down list controls who pays the escrow fees on each transaction that gets
            created on Escrow.com via this integration. Either the Buyer pays the escrow fees, the
            Seller pays the escrow fees, or the escrow fees are split 50/50 between the Buyer and
            the Seller.
          </SectionDesc>
          <h4>Inspection Period</h4>
          <SectionDesc>
            This drop down list controls how long the inspection period is for each transaction that
            gets created on Escrow.com via this integration.
          </SectionDesc>
          <h4>Tax Options</h4>
          <SectionDesc>
            This drop down list controls whether tax is included in each Escrow.com transactions.
            The <AccentedText>Exclude Tax (Default)</AccentedText> selection - which has been the
            only available tax behavior up until this version - means that the tax total in the
            shopping cart will not be included in the Escrow.com transactions that get generated
            when the user places an order from the checkout page. The{' '}
            <AccentedText>Include Tax as a Line Item</AccentedText> selection means that that tax
            total from the shopping cart will be included as a line item in each Escrow.com
            transaction that gets created.
          </SectionDesc>
          <h4>Transaction Type</h4>
          <SectionDesc>
            This drop down list controls which type of transactions get created on Escrow.com via
            this integration. At the moment, transaction types are limited to{' '}
            <AccentedText>General Merchandise</AccentedText> and{' '}
            <AccentedText>Domain Name</AccentedText>.
          </SectionDesc>
          <h3>Vendor Settings</h3>
          <h4>Multi-Vendor Extension</h4>
          <SectionDesc>
            This drop down list allows you to specify a multi-vendor extension. At the moment, only{' '}
            <AccentedText>WC Vendors</AccentedText> is supported.
          </SectionDesc>
          <h4>Commission %</h4>
          <SectionDesc>
            This field allows you to specify a commission amount in percent. For example, you may
            enter a value of 5 to result in a 5% broker commission on each transaction. This only
            works in multi-vendor scenarios.
          </SectionDesc>
          <h4>Commission $ Max</h4>
          <SectionDesc>
            This field allows you to cap the broker commission at a certain dollar amount. For
            example, enter 299 to result in a maximum $299 broker commission per target level (see
            below).
          </SectionDesc>
          <h4>Commission $ Max Target</h4>
          <SectionDesc>
            This drop down list allows you to restrict the maximum commission at either the item
            level or the vendor level.
          </SectionDesc>
        </Section>
        <Section>
          <SectionTitle>Troubleshooting</SectionTitle>
          <SectionDesc>
            The Escrow.com Extension for WooCommerce logs the response of each call to the Escrow
            API in the default error log in WordPress when debug mode has been enabled. Debug mode
            may be enabled in WordPress by setting WP_DEBUG equal to true.
          </SectionDesc>
          <SectionDesc>
            The Escrow.com Extension for WooCommerce utilizes curl behind the scenes. This requires
            the installation of a certificate bundle. Most environments will have this configured
            correctly by default, but if this is not the case, you will need to download the
            cacert.pem certificate bundle and point your php.ini file to it. For example, in Windows
            your php.ini file may need to include the following (where{' '}
            <AccentedText>C:\certificates</AccentedText> is the location where you saved your
            certificate bundle):
          </SectionDesc>
          <SectionDesc>
            <AccentedText>[curl]</AccentedText>
            <br />
            <AccentedText>curl.cainfo = C:\certificates\cacert.pem</AccentedText>
          </SectionDesc>
          <SectionDesc>
            If you are having issues or noticed a bug, please let us know at{' '}
            <A link={{ type: 'email', route: 'mailto:plugins@escrow.com' }}>plugins@escrow.com</A>.
          </SectionDesc>
        </Section>
      </div>
    </div>
  </div>
);

export default WooCommerce;
