const apiErrors = {
  UNPROCESSABLE_ENTITY: 'APIConstants.UNPROCESSABLE_ENTITY',
  NOT_FOUND: 'APIConstants.NOT_FOUND',
  BAD_REQUEST: 'APIConstants.BAD_REQUEST',
  UNAUTHORIZED: 'APIConstants.UNAUTHORIZED',
  FORBIDDEN: 'APIConstants.FORBIDDEN',
  NOT_ACCEPTABLE: 'APIConstants.NOT_ACCEPTABLE',
  GONE: 'APIConstants.GONE',
  INTERNAL_SERVER_ERROR: 'APIConstants.INTERNAL_SERVER_ERROR',
  UNHANDLED: 'APIConstants.UNHANDLED',
};

export default {
  ...apiErrors,
  API_ERROR: 'APIConstants.API_ERROR',
  API_ERRORS: Object.values(apiErrors),
  DISBURSEMENT_ALREADY_SET_API_ERROR_MESSAGE:
    'Disbursement method already set for this transaction.',
  GENERIC_API_ERROR_MESSAGE:
    "Sorry, due to technical issues we couldn't process your request at this time. Please try again, or contact us directly via support@escrow.com so we can help you personally.",
};
