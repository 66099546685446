var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], {"dev":false,"autoescape":true,"throwOnUndefined":false,"trimBlocks":false,"lstripBlocks":false});
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["navigation/integrationsLanding/headerLoggedIn.html"] = require( "navigation/integrationsLanding/headerLoggedIn.html" );
dependencies["navigation/headerLoggedIn.html"] = require( "navigation/headerLoggedIn.html" );
dependencies["navigation/headerLoggedOut.html"] = require( "navigation/headerLoggedOut.html" );
dependencies["navigation/v2/headerLoggedOut.html"] = require( "navigation/v2/headerLoggedOut.html" );




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/navigation/defaultHeader.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"path")) {
output += "\n  ";
var t_1;
t_1 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"path");
frame.set("current_page", t_1, true);
if(frame.topLevel) {
context.setVariable("current_page", t_1);
}
if(frame.topLevel) {
context.addExport("current_page", t_1);
}
output += "\n";
;
}
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "g")) {
output += "\n  ";
var t_2;
t_2 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "g")),"user");
frame.set("authenticated", t_2, true);
if(frame.topLevel) {
context.setVariable("authenticated", t_2);
}
if(frame.topLevel) {
context.addExport("authenticated", t_2);
}
output += "\n";
;
}
output += "\n\n";
var t_3;
t_3 = env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "sticky"),runtime.contextOrFrameLookup(context, frame, "True"));
frame.set("sticky", t_3, true);
if(frame.topLevel) {
context.setVariable("sticky", t_3);
}
if(frame.topLevel) {
context.addExport("sticky", t_3);
}
output += "\n";
var t_4;
t_4 = env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "scrollTop"),runtime.contextOrFrameLookup(context, frame, "True"));
frame.set("scrollTop", t_4, true);
if(frame.topLevel) {
context.setVariable("scrollTop", t_4);
}
if(frame.topLevel) {
context.addExport("scrollTop", t_4);
}
output += "\n\n<div data-component=\"navigation-header\" data-header=\"";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "header_type"),"default"), env.opts.autoescape);
output += "\" ";
if(runtime.contextOrFrameLookup(context, frame, "funnel")) {
output += " data-funnel";
;
}
output += ">\n  ";
if(!runtime.contextOrFrameLookup(context, frame, "hide_legacy_header")) {
output += "\n    ";
if(runtime.contextOrFrameLookup(context, frame, "authenticated")) {
output += "\n        ";
if(runtime.contextOrFrameLookup(context, frame, "header_type") == "integrations_landing" || runtime.contextOrFrameLookup(context, frame, "header_type") == "integrations_portal") {
output += "\n          ";
env.getTemplate("navigation/integrationsLanding/headerLoggedIn.html", false, "escrow_www/templates/navigation/defaultHeader.html", null, function(t_7,t_5) {
if(t_7) { cb(t_7); return; }
t_5.render(context.getVariables(), frame, function(t_8,t_6) {
if(t_8) { cb(t_8); return; }
output += t_6
output += "\n        ";
})});
}
else {
output += "\n          ";
env.getTemplate("navigation/headerLoggedIn.html", false, "escrow_www/templates/navigation/defaultHeader.html", null, function(t_11,t_9) {
if(t_11) { cb(t_11); return; }
t_9.render(context.getVariables(), frame, function(t_12,t_10) {
if(t_12) { cb(t_12); return; }
output += t_10
output += "\n        ";
})});
}
output += "\n    ";
;
}
else {
output += "\n      ";
if(runtime.contextOrFrameLookup(context, frame, "amp")) {
output += "\n        ";
env.getTemplate("navigation/headerLoggedOut.html", false, "escrow_www/templates/navigation/defaultHeader.html", null, function(t_15,t_13) {
if(t_15) { cb(t_15); return; }
t_13.render(context.getVariables(), frame, function(t_16,t_14) {
if(t_16) { cb(t_16); return; }
output += t_14
output += "\n      ";
})});
}
else {
output += "\n        ";
env.getTemplate("navigation/v2/headerLoggedOut.html", false, "escrow_www/templates/navigation/defaultHeader.html", null, function(t_19,t_17) {
if(t_19) { cb(t_19); return; }
t_17.render(context.getVariables(), frame, function(t_20,t_18) {
if(t_20) { cb(t_20); return; }
output += t_18
output += "\n      ";
})});
}
output += "\n    ";
;
}
output += "\n  ";
;
}
output += "\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/navigation/defaultHeader.html"] , dependencies)