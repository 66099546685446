import { connect } from 'react-redux';
import PaymentVerificationInstructions from 'spa/components/PaymentVerification/Instructions';
import { getCardDetails as getCardDetailsRoutine } from 'spa/actions/PaymentsActions';
import { cardByIdSelector } from 'spa/selectors/PaymentSelectors';
import { transactionByIdSelector } from 'spa/selectors/TransactionSelectors';

import { getTransactionById as getTransactionByIdRoutine } from 'spa/actions/TransactionActions';

const mapStateToProps = (state, ownProps) => ({
  card: cardByIdSelector(state, ownProps.cardId),
  transaction: transactionByIdSelector(state, ownProps.transId),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getCardDetails: () =>
    dispatch(getCardDetailsRoutine.trigger({ cardId: ownProps.cardId, transId: ownProps.transId })),
  getTransaction: () => dispatch(getTransactionByIdRoutine.trigger({ transId: ownProps.transId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentVerificationInstructions);
