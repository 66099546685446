import React, { useState } from 'react';
import { Field } from 'react-final-form';
import PhoneNumberInput from 'react-phone-input-2';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { required, validateVATNumber } from 'spa/components/form/validate';
import TransactionConstants from 'spa/constants/TransactionConstants';
import { useWindowDimensions } from 'spa/hooks';
import { Input } from '../../../MaterialUI';
import { HiddenField, DatePickerField } from '../../../StartTransaction/Fields';
import AddressFieldSet from './AddressFieldSet';
import AccountTypeSwitcher from './AccountTypeSwitcher';
import { phoneValidate } from '../../../form/validate';
import Heading from '../Heading';
import OlarkApi from '../../../../../utils/OlarkApi';

const CheckboxField = ({ name, label, validate, disabled }) => (
  <Field name={name} type="checkbox" validate={validate}>
    {({ input }) => (
      <FormControlLabel
        classes={{ root: 'createTransaction-checkbox-label kyc-checkbox' }}
        control={<Checkbox {...input} disabled={disabled} />}
        label={<span className="createTransaction-default-text">{label}</span>}
      />
    )}
  </Field>
);

const InputField = ({
  name,
  validate,
  label,
  initialValue,
  onChange,
  placeholder,
  children,
  select,
  selectHandleOlark,
  selectBlurOnClose,
  ...props
}) => {
  const { width } = useWindowDimensions();
  const isMobileView = width < TransactionConstants.DESKTOP_VIEW_WIDTH;

  return (
    <Field name={name} validate={validate} initialValue={initialValue}>
      {({ input, meta }) => (
        <Input
          {...input}
          onChange={(e) => {
            onChange && onChange(e);
            input.onChange(e);
          }}
          select={select}
          {...((select && {
            SelectProps: {
              MenuProps: { PaperProps: { sx: { maxHeight: 200 } } },
              onOpen: () => {
                selectHandleOlark && isMobileView && OlarkApi.toggleConsoleVisibility(false);
              },
              onClose: () => {
                selectHandleOlark && isMobileView && OlarkApi.toggleConsoleVisibility(true);
                selectBlurOnClose && input.onBlur();
              },
            },
          }) ||
            {})}
          label={label}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && !!meta.error ? meta.error : null}
          placeholder={placeholder}
          {...props}
        >
          {children}
        </Input>
      )}
    </Field>
  );
};

const PhoneNumberInputField = ({ name, placeholder, country, disabled }) => {
  const [touched, setTouched] = useState(false);

  const getPhoneNumberInputClass = (meta) => {
    if (disabled) {
      return 'react-tel-input-disabled';
    } else if (touched && meta.error) {
      return 'react-tel-input-invalid-number';
    }

    return '';
  };

  return (
    <Field name={name} validate={phoneValidate(0, true)}>
      {({ input, meta }) => (
        <FormControl fullWidth>
          <PhoneNumberInput
            country={country}
            disableCountryGuess
            enableSearch
            enableTerritories
            placeholder={placeholder}
            {...input}
            inputClass={getPhoneNumberInputClass(disabled, touched, meta)}
            onBlur={() => setTouched(true)}
            disabled={disabled}
            isValid={!(touched && !!meta.error)}
          />
          {touched && !!meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  );
};

const DropdownSelect = (props) => {
  const { label, items } = props;
  return (
    <FormControl fullWidth sx={{ 'margin-top': '5px' }}>
      <InputLabel>{label}</InputLabel>
      <Select SelectProps={{ MenuProps: { PaperProps: { sx: { maxHeight: 200 } } } }} {...props}>
        {items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const VATFieldSet = ({ initialCountry, options }) => (
  <React.Fragment>
    <Heading text="VAT information" hasDivider />
    <div className="kyc-inline-fields-container">
      <div className="kyc-inline-field--half">
        <InputField
          name="vat-country-code"
          label="Country Code"
          select
          initialValue={initialCountry}
        >
          {options.map((alpha2) => (
            <MenuItem key={alpha2} value={alpha2}>
              {alpha2}
            </MenuItem>
          ))}
        </InputField>
      </div>
      <div className="kyc-inline-field--half">
        <InputField name="vat-number" label="VAT Number (Optional)" validate={validateVATNumber} />
      </div>
    </div>
  </React.Fragment>
);

const OccupationField = () => (
  <React.Fragment>
    <Heading text="Work information" hasDivider />
    <InputField name="occupation" label="Occupation" validate={required} />
  </React.Fragment>
);

const LocalNameFields = ({ language, disabled }) => (
  <React.Fragment>
    <Heading text="Name in native alphabet" hasDivider />
    <div className="kyc-inline-fields-container">
      <div className="kyc-inline-field--half">
        <InputField
          name="local-fname"
          label={`First name (in ${language})`}
          validate={required}
          disabled={disabled}
        />
      </div>
      <div className="kyc-inline-field--half">
        <InputField
          name="local-lname"
          label={`Last name (in ${language})`}
          validate={required}
          disabled={disabled}
        />
      </div>
    </div>
  </React.Fragment>
);

export {
  InputField,
  HiddenField,
  DatePickerField,
  PhoneNumberInputField,
  AddressFieldSet,
  DropdownSelect,
  VATFieldSet,
  LocalNameFields,
  OccupationField,
  AccountTypeSwitcher,
  CheckboxField,
};
