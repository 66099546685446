/* eslint-disable */

/*!
 * vdom-virtualize
 * Copyright 2014 by Marcel Klehr <mklehr@gmx.net>
 *
 * (MIT LICENSE)
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
var VNode = require('virtual-dom/vnode/vnode'),
  VText = require('virtual-dom/vnode/vtext');

module.exports = createVNode;

function createVNode(domNode, key) {
  key = key || null; // XXX: Leave out `key` for now... merely used for (re-)ordering

  if (domNode.nodeType == 1) return createFromElement(domNode, key);
  if (domNode.nodeType == 3) return createFromTextNode(domNode, key);
  return;
}

createVNode.fromHTML = function (html, key) {
  var domNode = document.createElement('div'); // create container
  domNode.innerHTML = html; // browser parses HTML into DOM tree
  var child = domNode.children.length ? domNode.children[0] : domNode.firstChild;
  return createVNode(child, key);
};

function createFromTextNode(tNode) {
  return new VText(tNode.nodeValue);
}

function createFromElement(el) {
  var tagName = el.tagName,
    namespace = el.namespaceURI == 'http://www.w3.org/1999/xhtml' ? null : el.namespaceURI,
    properties = getElementProperties(el),
    children = [];

  for (var i = 0; i < el.childNodes.length; i++) {
    children.push(createVNode(el.childNodes[i] /*, i*/));
  }

  return new VNode(tagName, properties, children, null, namespace);
}

function getElementProperties(el) {
  var obj = {};

  var attributes = tagMapping[el.tagName.toLowerCase()] || [];
  attributes.concat(globals).forEach(function (propName) {
    if (!el[propName]) return;
    // https://msdn.microsoft.com/en-us/library/cc848861%28v=vs.85%29.aspx
    // The img element does not support the HREF content attribute.
    // In addition, the href property is read-only for the img Document Object Model (DOM) object
    if (el.tagName.toLowerCase() === 'img' && propName === 'href') {
      return;
    }

    // Special case: attributes
    // these are a NamedNodeMap, but we can just convert them to a hash for vdom,
    // because of https://github.com/Matt-Esch/virtual-dom/blob/master/vdom/apply-properties.js#L57
    if ('attributes' == propName) {
      var atts = Array.prototype.slice.call(el[propName]);
      var hash = atts.reduce(function (o, a) {
        var name = a.name;
        if (obj[name]) return o;
        o[name] = el.getAttribute(a.name);
        return o;
      }, {});
      return (obj[propName] = hash);
    }
    if ('tabIndex' == propName && el.tabIndex === -1) return;

    // Special case: contentEditable
    // browser use 'inherit' by default on all nodes, but does not allow setting it to ''
    // diffing virtualize dom will trigger error
    // ref: https://github.com/Matt-Esch/virtual-dom/issues/176
    if ('contentEditable' == propName && el[propName] === 'inherit') return;

    if ('object' === typeof el[propName]) return;

    // default: just copy the property
    obj[propName] = el[propName];
    return;
  });

  return obj;
}

const globals = [
  'accessKey',
  'className',
  'contentEditable',
  'data',
  'dir',
  'draggable',
  'id',
  'lang',
  'scrollLeft',
  'scrollTop',
  'scrolling',
  'spellCheck',
  'style',
  'tabIndex',
  'title',

  // ???
  'cellPadding',
  'cellSpacing',

  'value',
  'autoCapitalize',
  'autoCorrect',
  'property',
  'attributes',
];

const tagMapping = {
  a: ['download', 'href', 'hrefLang', 'rel', 'target', 'type'],
  area: ['alt', 'download', 'href', 'hrefLang', 'rel', 'target', 'type'],
  audio: ['autoPlay', 'controls', 'crossOrigin', 'loop', 'mediaGroup', 'muted', 'preload', 'src'],
  base: ['href', 'target'],
  button: ['formNoValidate', 'name', 'type'],
  col: ['span'],
  colgroup: ['span'],
  embed: ['src', 'type'],
  fieldset: ['name'],
  form: ['action', 'autoComplete', 'encType', 'method', 'noValidate', 'target'],
  iframe: ['sandbox', 'src', 'srcDoc'],
  img: ['alt', 'crossOrigin', 'src', 'srcSet'],
  input: [
    'accept',
    'alt',
    'autoComplete',
    'checked',
    'formNoValidate',
    'list',
    'max',
    'min',
    'multiple',
    'name',
    'pattern',
    'placeholder',
    'readOnly',
    'required',
    'src',
    'step',
    'type',
  ],
  label: ['htmlFor'],
  link: ['crossOrigin', 'href', 'hrefLang', 'rel', 'type'],
  keygen: ['name'],
  menu: ['label', 'type'],
  menuitem: ['checked', 'icon', 'label', 'radioGroup', 'type'],
  meta: ['content', 'httpEquiv'],
  meter: ['max', 'min'],
  object: ['type'],
  ol: ['start', 'type'],
  optgroup: ['label'],
  option: ['label', 'selected'],
  output: ['htmlFor', 'name'],
  progress: ['max'],
  script: ['async', 'crossOrigin', 'defer', 'src', 'type'],
  select: ['autoComplete', 'multiple', 'name', 'required', 'type'],
  source: ['src', 'type'],
  style: ['type'],
  td: ['colSpan', 'rowSpan'],
  th: ['colSpan', 'rowSpan', 'scope'],
  textarea: ['autoComplete', 'name', 'placeholder', 'readOnly', 'required'],
  track: ['label', 'src'],
  video: [
    'autoPlay',
    'controls',
    'crossOrigin',
    'loop',
    'mediaGroup',
    'muted',
    'poster',
    'preload',
    'src',
  ],
};

/* eslint-enable */
