import React from 'react';
import { useDispatch } from 'react-redux';
import { clearKycImages } from 'spa/features/kyc/kycSlice';
import MobilePage from '../../components/MobilePage';

const VerificationSuccessPage = () => {
  const dispatch = useDispatch();

  return (
    <MobilePage
      title={`Verification Successful`}
      ctaText="View my transactions"
      onSubmit={() => {
        dispatch(clearKycImages());
        window.location.href = `/transactions`;
      }}
    />
  );
};

export default VerificationSuccessPage;
