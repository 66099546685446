import React, { useEffect, useState } from 'react';

import { V3KYCMobilePages as Pages } from 'spa/constants/VerificationConstants';
import MobilePage from '../../components/MobilePage';
import UploadSuccess from '../../components/UploadSuccess';

const SelfieUploadSuccessPage = () => {
  const [nextPageTrigger, setNextPageTrigger] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setNextPageTrigger(true);
    }, 1500);
  }, []);

  // TODO T297643: Update the copy for uploadedText
  return (
    <MobilePage nextPage={Pages.ACCOUNT_TYPE} nextPageTrigger={nextPageTrigger} noButton>
      <UploadSuccess uploadedText={'Upload successful'} />
    </MobilePage>
  );
};

export default SelfieUploadSuccessPage;
