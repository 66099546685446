export default {
  AFG: { name: 'Afghanistan', countryCode: 93 },
  ALA: { name: 'Åland Islands', countryCode: 35818 },
  ALB: { name: 'Albania', countryCode: 355 },
  DZA: { name: 'Algeria', countryCode: 213 },
  ASM: { name: 'American Samoa', countryCode: 1684 },
  AND: { name: 'Andorra', countryCode: 376 },
  AGO: { name: 'Angola', countryCode: 244 },
  AIA: { name: 'Anguilla', countryCode: 1264 },
  ATG: { name: 'Antigua and Barbuda', countryCode: 1268 },
  ARG: { name: 'Argentina', countryCode: 54 },
  ARM: { name: 'Armenia', countryCode: 374 },
  ABW: { name: 'Aruba', countryCode: 297 },
  AUS: { name: 'Australia', countryCode: 61 },
  AUT: { name: 'Austria', countryCode: 43 },
  AZE: { name: 'Azerbaijan', countryCode: 994 },
  BHS: { name: 'Bahamas', countryCode: 1242 },
  BHR: { name: 'Bahrain', countryCode: 973 },
  BGD: { name: 'Bangladesh', countryCode: 880 },
  BRB: { name: 'Barbados', countryCode: 1246 },
  BLR: { name: 'Belarus', countryCode: 375 },
  BEL: { name: 'Belgium', countryCode: 32 },
  BLZ: { name: 'Belize', countryCode: 501 },
  BEN: { name: 'Benin', countryCode: 229 },
  BMU: { name: 'Bermuda', countryCode: 1441 },
  BTN: { name: 'Bhutan', countryCode: 975 },
  BOL: { name: 'Bolivia, Plurinational State of', countryCode: 591 },
  BES: { name: 'Bonaire, Sint Eustatius and Saba', countryCode: 599 },
  BIH: { name: 'Bosnia and Herzegovina', countryCode: 387 },
  BWA: { name: 'Botswana', countryCode: 267 },
  BRA: { name: 'Brazil', countryCode: 55 },
  IOT: { name: 'British Indian Ocean Territory', countryCode: 246 },
  BRN: { name: 'Brunei Darussalam', countryCode: 673 },
  BGR: { name: 'Bulgaria', countryCode: 359 },
  BFA: { name: 'Burkina Faso', countryCode: 226 },
  BDI: { name: 'Burundi', countryCode: 257 },
  KHM: { name: 'Cambodia', countryCode: 855 },
  CMR: { name: 'Cameroon', countryCode: 237 },
  CAN: { name: 'Canada', countryCode: 1 },
  CPV: { name: 'Cabo Verde', countryCode: 238 },
  CYM: { name: 'Cayman Islands', countryCode: 1345 },
  CAF: { name: 'Central African Republic', countryCode: 236 },
  TCD: { name: 'Chad', countryCode: 235 },
  CHL: { name: 'Chile', countryCode: 56 },
  CHN: { name: 'China', countryCode: 86 },
  CXR: { name: 'Christmas Island', countryCode: 61 },
  CCK: { name: 'Cocos (Keeling) Islands', countryCode: 61 },
  COL: { name: 'Colombia', countryCode: 57 },
  COM: { name: 'Comoros', countryCode: 269 },
  COG: { name: 'Congo', countryCode: 242 },
  COD: { name: 'Congo, Democratic Republic of the', countryCode: 243 },
  COK: { name: 'Cook Islands', countryCode: 682 },
  CRI: { name: 'Costa Rica', countryCode: 506 },
  CIV: { name: "Côte d'Ivoire", countryCode: 225 },
  HRV: { name: 'Croatia', countryCode: 385 },
  CUB: { name: 'Cuba', countryCode: 53 },
  CUW: { name: 'Curaçao', countryCode: 599 },
  CYP: { name: 'Cyprus', countryCode: 357 },
  CZE: { name: 'Czech Republic', countryCode: 420 },
  DNK: { name: 'Denmark', countryCode: 45 },
  DJI: { name: 'Djibouti', countryCode: 253 },
  DMA: { name: 'Dominica', countryCode: 1767 },
  DOM: { name: 'Dominican Republic', countryCode: 1809 },
  ECU: { name: 'Ecuador', countryCode: 593 },
  EGY: { name: 'Egypt', countryCode: 20 },
  SLV: { name: 'El Salvador', countryCode: 503 },
  GNQ: { name: 'Equatorial Guinea', countryCode: 240 },
  ERI: { name: 'Eritrea', countryCode: 291 },
  EST: { name: 'Estonia', countryCode: 372 },
  ETH: { name: 'Ethiopia', countryCode: 251 },
  FLK: { name: 'Falkland Islands (Malvinas)', countryCode: 500 },
  FRO: { name: 'Faroe Islands', countryCode: 298 },
  FJI: { name: 'Fiji', countryCode: 679 },
  FIN: { name: 'Finland', countryCode: 358 },
  FRA: { name: 'France', countryCode: 33 },
  GUF: { name: 'French Guiana', countryCode: 594 },
  PYF: { name: 'French Polynesia', countryCode: 689 },
  GAB: { name: 'Gabon', countryCode: 241 },
  GMB: { name: 'Gambia', countryCode: 220 },
  GEO: { name: 'Georgia', countryCode: 995 },
  DEU: { name: 'Germany', countryCode: 49 },
  GHA: { name: 'Ghana', countryCode: 233 },
  GIB: { name: 'Gibraltar', countryCode: 350 },
  GRC: { name: 'Greece', countryCode: 30 },
  GRL: { name: 'Greenland', countryCode: 299 },
  GRD: { name: 'Grenada', countryCode: 1473 },
  GLP: { name: 'Guadeloupe', countryCode: 590 },
  GUM: { name: 'Guam', countryCode: 1671 },
  GTM: { name: 'Guatemala', countryCode: 502 },
  GGY: { name: 'Guernsey', countryCode: 441481 },
  GIN: { name: 'Guinea', countryCode: 224 },
  GNB: { name: 'GuineaBissau', countryCode: 245 },
  GUY: { name: 'Guyana', countryCode: 592 },
  HTI: { name: 'Haiti', countryCode: 509 },
  VAT: { name: 'Holy See', countryCode: 379 },
  HND: { name: 'Honduras', countryCode: 504 },
  HKG: { name: 'Hong Kong', countryCode: 852 },
  HUN: { name: 'Hungary', countryCode: 36 },
  ISL: { name: 'Iceland', countryCode: 354 },
  IND: { name: 'India', countryCode: 91 },
  IDN: { name: 'Indonesia', countryCode: 62 },
  IRN: { name: 'Iran, Islamic Republic of', countryCode: 98 },
  IRQ: { name: 'Iraq', countryCode: 964 },
  IRL: { name: 'Ireland', countryCode: 353 },
  IMN: { name: 'Isle of Man', countryCode: 441624 },
  ISR: { name: 'Israel', countryCode: 972 },
  ITA: { name: 'Italy', countryCode: 39 },
  JAM: { name: 'Jamaica', countryCode: 1876 },
  JPN: { name: 'Japan', countryCode: 81 },
  JEY: { name: 'Jersey', countryCode: 441534 },
  JOR: { name: 'Jordan', countryCode: 962 },
  KAZ: { name: 'Kazakhstan', countryCode: 7 },
  KEN: { name: 'Kenya', countryCode: 254 },
  KIR: { name: 'Kiribati', countryCode: 686 },
  PRK: { name: "Korea, Democratic People's Republic of", countryCode: 850 },
  KOR: { name: 'Korea, Republic of', countryCode: 82 },
  RKS: { name: 'Kosovo', countryCode: 383 },
  KWT: { name: 'Kuwait', countryCode: 965 },
  KGZ: { name: 'Kyrgyzstan', countryCode: 996 },
  LAO: { name: "Lao People's Democratic Republic", countryCode: 856 },
  LVA: { name: 'Latvia', countryCode: 371 },
  LBN: { name: 'Lebanon', countryCode: 961 },
  LSO: { name: 'Lesotho', countryCode: 266 },
  LBR: { name: 'Liberia', countryCode: 231 },
  LBY: { name: 'Libya', countryCode: 218 },
  LIE: { name: 'Liechtenstein', countryCode: 423 },
  LTU: { name: 'Lithuania', countryCode: 370 },
  LUX: { name: 'Luxembourg', countryCode: 352 },
  MAC: { name: 'Macao', countryCode: 853 },
  MKD: { name: 'Macedonia, the former Yugoslav Republic of', countryCode: 389 },
  MDG: { name: 'Madagascar', countryCode: 261 },
  MWI: { name: 'Malawi', countryCode: 265 },
  MYS: { name: 'Malaysia', countryCode: 60 },
  MDV: { name: 'Maldives', countryCode: 960 },
  MLI: { name: 'Mali', countryCode: 223 },
  MLT: { name: 'Malta', countryCode: 356 },
  MHL: { name: 'Marshall Islands', countryCode: 692 },
  MTQ: { name: 'Martinique', countryCode: 596 },
  MRT: { name: 'Mauritania', countryCode: 222 },
  MUS: { name: 'Mauritius', countryCode: 230 },
  MYT: { name: 'Mayotte', countryCode: 262 },
  MEX: { name: 'Mexico', countryCode: 52 },
  FSM: { name: 'Micronesia, Federated States of', countryCode: 691 },
  MDA: { name: 'Moldova, Republic of', countryCode: 373 },
  MCO: { name: 'Monaco', countryCode: 377 },
  MNG: { name: 'Mongolia', countryCode: 976 },
  MNE: { name: 'Montenegro', countryCode: 382 },
  MSR: { name: 'Montserrat', countryCode: 1664 },
  MAR: { name: 'Morocco', countryCode: 212 },
  MOZ: { name: 'Mozambique', countryCode: 258 },
  MMR: { name: 'Myanmar', countryCode: 95 },
  NAM: { name: 'Namibia', countryCode: 264 },
  NRU: { name: 'Nauru', countryCode: 674 },
  NPL: { name: 'Nepal', countryCode: 977 },
  NLD: { name: 'Netherlands', countryCode: 31 },
  NCL: { name: 'New Caledonia', countryCode: 687 },
  NZL: { name: 'New Zealand', countryCode: 64 },
  NIC: { name: 'Nicaragua', countryCode: 505 },
  NER: { name: 'Niger', countryCode: 227 },
  NGA: { name: 'Nigeria', countryCode: 234 },
  NIU: { name: 'Niue', countryCode: 683 },
  NFK: { name: 'Norfolk Island', countryCode: 672 },
  MNP: { name: 'Northern Mariana Islands', countryCode: 1670 },
  NOR: { name: 'Norway', countryCode: 47 },
  OMN: { name: 'Oman', countryCode: 968 },
  PAK: { name: 'Pakistan', countryCode: 92 },
  PLW: { name: 'Palau', countryCode: 680 },
  PSE: { name: 'Palestine, State of', countryCode: 970 },
  PAN: { name: 'Panama', countryCode: 507 },
  PNG: { name: 'Papua New Guinea', countryCode: 675 },
  PRY: { name: 'Paraguay', countryCode: 595 },
  PER: { name: 'Peru', countryCode: 51 },
  PHL: { name: 'Philippines', countryCode: 63 },
  PCN: { name: 'Pitcairn', countryCode: 870 },
  POL: { name: 'Poland', countryCode: 48 },
  PRT: { name: 'Portugal', countryCode: 351 },
  PRI: { name: 'Puerto Rico', countryCode: 1787 },
  QAT: { name: 'Qatar', countryCode: 974 },
  REU: { name: 'Réunion', countryCode: 262 },
  ROU: { name: 'Romania', countryCode: 40 },
  RUS: { name: 'Russian Federation', countryCode: 7 },
  RWA: { name: 'Rwanda', countryCode: 250 },
  BLM: { name: 'Saint Barthélemy', countryCode: 590 },
  SHN: { name: 'Saint Helena, Ascension and Tristan da Cunha', countryCode: 290 },
  KNA: { name: 'Saint Kitts and Nevis', countryCode: 1869 },
  LCA: { name: 'Saint Lucia', countryCode: 1758 },
  MAF: { name: 'Saint Martin (French part)', countryCode: 590 },
  SPM: { name: 'Saint Pierre and Miquelon', countryCode: 508 },
  VCT: { name: 'Saint Vincent and the Grenadines', countryCode: 1784 },
  WSM: { name: 'Samoa', countryCode: 685 },
  SMR: { name: 'San Marino', countryCode: 378 },
  STP: { name: 'Sao Tome and Principe', countryCode: 239 },
  SAU: { name: 'Saudi Arabia', countryCode: 966 },
  SEN: { name: 'Senegal', countryCode: 221 },
  SRB: { name: 'Serbia', countryCode: 381 },
  SYC: { name: 'Seychelles', countryCode: 248 },
  SLE: { name: 'Sierra Leone', countryCode: 232 },
  SGP: { name: 'Singapore', countryCode: 65 },
  SXM: { name: 'Sint Maarten (Dutch part)', countryCode: 599 },
  SVK: { name: 'Slovakia', countryCode: 421 },
  SVN: { name: 'Slovenia', countryCode: 386 },
  SLB: { name: 'Solomon Islands', countryCode: 677 },
  SOM: { name: 'Somalia', countryCode: 252 },
  ZAF: { name: 'South Africa', countryCode: 27 },
  SSD: { name: 'South Sudan', countryCode: 211 },
  ESP: { name: 'Spain', countryCode: 34 },
  LKA: { name: 'Sri Lanka', countryCode: 94 },
  SDN: { name: 'Sudan', countryCode: 249 },
  SUR: { name: 'Suriname', countryCode: 597 },
  SJM: { name: 'Svalbard and Jan Mayen', countryCode: 47 },
  SWZ: { name: 'Swaziland', countryCode: 268 },
  SWE: { name: 'Sweden', countryCode: 46 },
  CHE: { name: 'Switzerland', countryCode: 41 },
  SYR: { name: 'Syrian Arab Republic', countryCode: 963 },
  TWN: { name: 'Taiwan, Province of China', countryCode: 886 },
  TJK: { name: 'Tajikistan', countryCode: 992 },
  TZA: { name: 'Tanzania, United Republic of', countryCode: 255 },
  THA: { name: 'Thailand', countryCode: 66 },
  TLS: { name: 'TimorLeste', countryCode: 670 },
  TGO: { name: 'Togo', countryCode: 228 },
  TKL: { name: 'Tokelau', countryCode: 690 },
  TON: { name: 'Tonga', countryCode: 676 },
  TTO: { name: 'Trinidad and Tobago', countryCode: 1868 },
  TUN: { name: 'Tunisia', countryCode: 216 },
  TUR: { name: 'Turkey', countryCode: 90 },
  TKM: { name: 'Turkmenistan', countryCode: 993 },
  TCA: { name: 'Turks and Caicos Islands', countryCode: 1649 },
  TUV: { name: 'Tuvalu', countryCode: 688 },
  UGA: { name: 'Uganda', countryCode: 256 },
  UKR: { name: 'Ukraine', countryCode: 380 },
  ARE: { name: 'United Arab Emirates', countryCode: 971 },
  GBR: { name: 'United Kingdom', countryCode: 44 },
  USA: { name: 'United States', countryCode: 1 },
  URY: { name: 'Uruguay', countryCode: 598 },
  UZB: { name: 'Uzbekistan', countryCode: 998 },
  VUT: { name: 'Vanuatu', countryCode: 678 },
  VEN: { name: 'Venezuela, Bolivarian Republic of', countryCode: 58 },
  VNM: { name: 'Viet Nam', countryCode: 84 },
  VGB: { name: 'Virgin Islands, British', countryCode: 1284 },
  VIR: { name: 'Virgin Islands, U.S.', countryCode: 1340 },
  WLF: { name: 'Wallis and Futuna', countryCode: 681 },
  ESH: { name: 'Western Sahara', countryCode: 212 },
  YEM: { name: 'Yemen', countryCode: 967 },
  ZMB: { name: 'Zambia', countryCode: 260 },
  ZWE: { name: 'Zimbabwe', countryCode: 263 },
};
