import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
} from '@mui/material';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from 'react';
import { EscrowAlert } from 'spa/features/ui/EscrowAlert';
import { EscrowButton } from 'spa/features/ui/EscrowButton';
import { MediumText, SmallText } from 'spa/features/ui/EscrowTypography';
import TransactionConstants from 'spa/constants/TransactionConstants';
import { useForm, Controller } from 'react-hook-form';
import { usePatchTransactionMutation } from '../../transactionApi';
import { transactionSelector } from '../../transactionSlice';
import { SelectItem } from '../select-item/SelectItem';
import { CustomFormControl } from './CustomFormControl';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';

const escrowFeePayersMapping = {
  Buyer: 'buyer',
  Seller: 'seller',
  '50% Buyer / 50% Seller': 'both',
};

const currencySymbols = {
  usd: '$',
  aud: 'A$',
  gbp: '£',
  eur: '€',
  cad: 'CA$',
};

const shippingMethodMapping = {
  'Standard shipping': 'standard_shipping',
  'Cargo shipping': 'cargo_shipping',
  'No shipping needed': 'no_shipping',
};

const { TRANSACTION_TYPES } = TransactionConstants;

const ShippingFeeInput = React.forwardRef(({ value, onChange, inputProps, currency }, ref) => {
  const shippingFeeLabel = `Shipping fee (${currency.toUpperCase()})`;
  const handleBlur = (event) => {
    const sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');
    const formattedValue = parseFloat(sanitizedValue).toFixed(2);
    onChange(formattedValue);
  };

  const handleChange = (event) => {
    const sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');
    onChange(sanitizedValue);
  };

  return (
    <TextField
      ref={ref}
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
      label={shippingFeeLabel}
      type="text"
      InputProps={inputProps}
      data-testid="shipping-fee"
    />
  );
});

const ModifyTransactionContent = ({ transactionId, userRole, handleClose, setPage, setValue }) => {
  const theme = useTheme();
  const isMobileViewport = useMediaQuery(theme.breakpoints.down('md'));
  const [patchTransaction, { isLoading, error }] = usePatchTransactionMutation();
  const transaction = useSelector(transactionSelector(transactionId));
  const [errorMsg, setErrorMsg] = useState('');
  if (!transaction) {
    return null;
  }
  const otherPartyText = userRole === 'buyer' ? 'seller' : 'buyer';
  const {
    inspectionDays,
    escrowFeePayers,
    fees,
    parties,
    concierge,
    currency,
    shippingType,
    shippingFeePayer,
    transactionTypeCode,
  } = transaction;

  const { control, watch, handleSubmit } = useForm({
    defaultValues: {
      inspectionPeriod: inspectionDays,
      concierge: concierge || false,
      escrowFeePayer: escrowFeePayers.length === 1 ? escrowFeePayers[0] : 'both',
      shippingMethod: shippingType || 'no_shipping',
      shippingFeePayer: shippingFeePayer || 'seller',
      modificationReason: '',
      shippingFee: parties.buyer.email ? fees[parties.buyer.email].shippingFee || '0.00' : '0.00',
    },
  });

  const handleBack = () => {
    if (isLoading) {
      return;
    }
    setPage('action_selection');
    setValue('');
  };

  const handleConfirmModification = async (data) => {
    try {
      await patchTransaction({
        id: transactionId,
        action: 'modify_terms',
        modify_terms_information: {
          modification_reason: data.modificationReason,
          items: [
            {
              type: transactionTypeCode,
              inspection_period: data.inspectionPeriod * 86400,
              ...((transactionTypeCode === TRANSACTION_TYPES.GENERAL_MERCHANDISE ||
                transactionTypeCode === TRANSACTION_TYPES.MOTOR_VEHICLE) && {
                shipping_type: data.shippingMethod,
              }),
              ...(transactionTypeCode === TRANSACTION_TYPES.DOMAIN_NAME && {
                extra_attributes: { concierge: data.concierge },
              }),
              fees: [
                ...(data.escrowFeePayer === 'seller' && [
                  {
                    payer_customer: parties.seller.email,
                    type: 'escrow',
                    split: 1,
                  },
                ]),
                ...(data.escrowFeePayer === 'buyer' && [
                  {
                    payer_customer: parties.buyer.email,
                    type: 'escrow',
                    split: 1,
                  },
                ]),
                ...(data.escrowFeePayer === 'both' && [
                  {
                    payer_customer: parties.seller.email,
                    type: 'escrow',
                    split: 0.5,
                  },
                  {
                    payer_customer: parties.buyer.email,
                    type: 'escrow',
                    split: 0.5,
                  },
                ]),
              ],
            },
            ...(data.shippingMethod &&
              data.shippingMethod !== 'no_shipping' && [
                {
                  type: 'shipping_fee',
                  schedule: [
                    {
                      ...(data.shippingFeePayer === 'seller' && {
                        amount: 0,
                        payer_customer: parties.seller.email,
                      }),
                      ...(data.shippingFeePayer === 'buyer' && {
                        amount: data.shippingFee,
                        payer_customer: parties.buyer.email,
                      }),
                    },
                  ],
                },
              ]),
          ],
        },
      }).unwrap();
      handleClose();
    } catch (e) {
      // RTK handles the error
      let errorMsgTmp =
        "The transaction terms couldn't be modified due to a technical issue. Please refresh the page and try again.";
      if (e.xRequestId) {
        errorMsgTmp += ` If the issue persists, contact support@escrow.com and provide the id: ${e.xRequestId}`;
      }
      setErrorMsg(errorMsgTmp);
    }
  };

  return (
    <React.Fragment>
      {isMobileViewport && (
        <IconButton
          sx={{ marginLeft: 'auto' }}
          onClick={handleBack}
          aria-label="Close Cancel Transaction Modal"
        >
          <CloseIcon sx={{ color: 'mono.dark', height: 24, width: 24 }} />
        </IconButton>
      )}
      {error && (
        <EscrowAlert>
          <AlertTitle>Unable to modify transaction terms</AlertTitle>
          {errorMsg}
        </EscrowAlert>
      )}
      <DialogTitle>
        <MediumText>Modify the terms of your transaction</MediumText>
      </DialogTitle>
      <DialogContent>
        <SmallText>
          After your confirmation, we will notify the {otherPartyText} to review the updates you
          have made.
        </SmallText>
        <Box sx={{ mt: 2 }} component="form" onSubmit={handleSubmit(handleConfirmModification)}>
          <CustomFormControl>
            <InputLabel id="inspection-period-label">Inspection period (days)</InputLabel>
            <Controller
              name="inspectionPeriod"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  id="inspection-period"
                  label="Inspection period (days)"
                  data-testid="inspection-period"
                >
                  {Array.from({ length: 30 }, (_, i) => i + 1).map((num) => (
                    <MenuItem key={num} value={num}>
                      {num}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </CustomFormControl>
          <CustomFormControl>
            <InputLabel id="escrow-fee-payer-label">Escrow fee payer</InputLabel>
            <Controller
              name="escrowFeePayer"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  id="escrow-fee-payer"
                  label="Escrow fee payer"
                  data-testid="escrow-fee-payer"
                >
                  {Object.entries(escrowFeePayersMapping).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </CustomFormControl>
          {transactionTypeCode === TRANSACTION_TYPES.DOMAIN_NAME && (
            <CustomFormControl>
              <InputLabel id="concierge-label">Upgrade to Concierge Service</InputLabel>
              <Controller
                name="concierge"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="concierge"
                    label="Upgrade to Concierge Service"
                    data-testid="concierge"
                    notched
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                )}
              />
            </CustomFormControl>
          )}
          {(transactionTypeCode === TRANSACTION_TYPES.GENERAL_MERCHANDISE ||
            transactionTypeCode === TRANSACTION_TYPES.MOTOR_VEHICLE) && (
            <React.Fragment>
              <CustomFormControl>
                <InputLabel id="shipping-method-label">Shipping method</InputLabel>
                <Controller
                  name="shippingMethod"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="shipping-method"
                      label="Shipping method"
                      data-testid="shipping-method"
                    >
                      {Object.entries(shippingMethodMapping).map(([key, value]) => (
                        <MenuItem key={key} value={value}>
                          {key}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </CustomFormControl>
              {(watch('shippingMethod') === 'standard_shipping' ||
                watch('shippingMethod') === 'cargo_shipping') && (
                <React.Fragment>
                  <CustomFormControl>
                    <InputLabel id="shipping-fee-payer-label">Shipping fee payer</InputLabel>
                    <Controller
                      name="shippingFeePayer"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          id="shipping-fee-payer"
                          label="Shipping fee payer"
                          data-testid="shipping-fee-payer"
                        >
                          <MenuItem value={'buyer'}>Buyer</MenuItem>
                          <MenuItem value={'seller'}>Seller</MenuItem>
                        </Select>
                      )}
                    />
                  </CustomFormControl>
                  {watch('shippingFeePayer') === 'buyer' && (
                    <CustomFormControl>
                      <Controller
                        name="shippingFee"
                        control={control}
                        render={({ field }) => (
                          <ShippingFeeInput
                            {...field}
                            value={field.value}
                            id="buyer-shipping-fee"
                            label="Shipping fee"
                            inputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {currency && currencySymbols[currency]}
                                </InputAdornment>
                              ),
                            }}
                            currency={currency}
                          />
                        )}
                      />
                    </CustomFormControl>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          <CustomFormControl>
            <Controller
              name="modificationReason"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="modification-reason"
                  label="Reason for modification"
                  data-testid="modification-reason"
                />
              )}
            />
          </CustomFormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        {!isMobileViewport && <EscrowButton onClick={handleBack}>Back</EscrowButton>}
        <EscrowButton
          variant="contained"
          sx={{ width: isMobileViewport ? '100%' : 'auto' }}
          disabled={isLoading}
          onClick={handleSubmit(handleConfirmModification)}
        >
          Confirm modification
        </EscrowButton>
      </DialogActions>
    </React.Fragment>
  );
};

const CancelTransactionContent = ({ transactionId, setPage, setValue, handleClose }) => {
  const theme = useTheme();
  const isMobileViewport = useMediaQuery(theme.breakpoints.down('md'));
  const [textInput, setTextInput] = useState('');
  const [patchTransaction, { isLoading, error }] = usePatchTransactionMutation();
  const [errorMsg, setErrorMsg] = useState('');

  const handleInput = (event) => {
    setTextInput(event.target.value);
  };

  const handleBack = () => {
    if (isLoading) {
      return;
    }
    setPage('action_selection');
    setValue('');
  };

  const handleCancel = async () => {
    try {
      await patchTransaction({
        id: transactionId,
        action: 'cancel',
        ...(textInput && { cancel_information: { cancellation_reason: textInput } }),
      }).unwrap();
      handleClose();
    } catch (e) {
      // RTK handles the error
      let errorMsgTmp =
        "The transaction couldn't be cancelled due to a technical issue. Please refresh the page and try again.";
      if (e.xRequestId) {
        errorMsgTmp += ` If the issue persists, contact support@escrow.com and provide the id: ${e.xRequestId}`;
      }
      setErrorMsg(errorMsgTmp);
    }
  };

  return (
    <React.Fragment>
      {isMobileViewport && (
        <IconButton
          sx={{ marginLeft: 'auto' }}
          onClick={handleBack}
          aria-label="Close Cancel Transaction Modal"
        >
          <CloseIcon sx={{ color: 'mono.dark', height: 24, width: 24 }} />
        </IconButton>
      )}
      {error && (
        <EscrowAlert>
          <AlertTitle>Unable to cancel the transaction</AlertTitle>
          {errorMsg}
        </EscrowAlert>
      )}
      <DialogTitle>
        <MediumText>Are you sure you want to cancel this transaction?</MediumText>
      </DialogTitle>
      <DialogContent>
        <SmallText>
          Please provide a reason for the cancellation of this transaction. Once cancelled, this
          transaction cannot be reinstated.
        </SmallText>
        <Box sx={{ marginTop: '24px' }}>
          <TextField
            fullWidth
            label="Cancellation reason"
            variant="outlined"
            value={textInput}
            onChange={handleInput}
            inputProps={{ 'data-testid': 'cancellation-reason' }}
            sx={{
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.secondaryLight.dark,
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: theme.palette.secondaryLight.dark,
              },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {!isMobileViewport && <EscrowButton onClick={handleBack}>Back</EscrowButton>}
        <EscrowButton
          sx={{ width: isMobileViewport ? '100%' : 'auto' }}
          variant="containedError"
          disabled={isLoading || !textInput}
          onClick={handleCancel}
        >
          Cancel transaction
        </EscrowButton>
      </DialogActions>
    </React.Fragment>
  );
};

const ActionSelectionContent = ({ value, setValue, handleContinue, handleClose }) => {
  const theme = useTheme();
  const isMobileViewport = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <React.Fragment>
      {isMobileViewport && (
        <IconButton
          sx={{ marginLeft: 'auto' }}
          onClick={handleClose}
          aria-label="Close Action Selection Modal"
        >
          <CloseIcon sx={{ color: 'mono.dark', height: 24, width: 24 }} />
        </IconButton>
      )}
      <DialogTitle>
        <MediumText>Please select how you will modify the transaction</MediumText>
      </DialogTitle>
      <DialogContent>
        <SmallText>
          You may opt to modify the transaction terms or you may cancel this transaction. If you
          select to modify the terms, we will notify the seller and request for their agreement.
        </SmallText>
        <List>
          <SelectItem
            label="Modify transaction terms"
            value="modify_terms"
            checked={value === 'modify_terms'}
            onCheck={setValue}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <SmallText>
                <strong>Modify transaction terms</strong>
              </SmallText>
            </Box>
          </SelectItem>
          <SelectItem
            label="Cancel transaction"
            value="cancel_transaction"
            checked={value === 'cancel_transaction'}
            onCheck={setValue}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <SmallText>
                <strong>Cancel transaction</strong>
              </SmallText>
            </Box>
          </SelectItem>
        </List>
      </DialogContent>
      <DialogActions>
        {!isMobileViewport && <EscrowButton onClick={handleClose}>Back</EscrowButton>}
        <EscrowButton
          sx={{ width: isMobileViewport ? '100%' : 'auto' }}
          variant="contained"
          disabled={!value}
          onClick={handleContinue}
        >
          Continue
        </EscrowButton>
      </DialogActions>
    </React.Fragment>
  );
};

export const ModifyTransaction = ({ transactionId, userRole }) => {
  const theme = useTheme();
  const isMobileViewport = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const [page, setPage] = useState('');

  const handleContinue = () => {
    switch (value) {
      case 'modify_terms':
        setPage('modify_terms');
        break;
      case 'cancel_transaction':
        setPage('cancel_transaction');
        break;
      default:
        setPage('action_selection');
    }
  };

  const handleClose = () => {
    setOpen(false);
    setPage('');
    setValue('');
  };

  const renderModalContent = () => {
    switch (page) {
      case 'modify_terms':
        return (
          <ModifyTransactionContent
            transactionId={transactionId}
            userRole={userRole}
            handleClose={handleClose}
            setPage={setPage}
            setValue={setValue}
          />
        );
      case 'cancel_transaction':
        return (
          <CancelTransactionContent
            transactionId={transactionId}
            setPage={setPage}
            setValue={setValue}
            handleClose={handleClose}
          />
        );
      default:
        return (
          <ActionSelectionContent
            value={value}
            setValue={setValue}
            handleContinue={handleContinue}
            setOpen={setOpen}
            handleClose={handleClose}
          />
        );
    }
  };
  return (
    <React.Fragment>
      <EscrowButton onClick={() => setOpen(true)}>Modify transaction</EscrowButton>
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="md"
          PaperProps={{
            sx: {
              width: isMobileViewport ? 'auto' : '685px',
            },
          }}
          fullScreen={isMobileViewport}
        >
          {renderModalContent()}
        </Dialog>
      )}
    </React.Fragment>
  );
};
