import React from 'react';
import classnames from 'classnames';

const DropdownBubble = ({ visible = false, horizontalPosition, onClose, children }) => (
  <div
    className={classnames({
      bubble: true,
      'is-active': visible,
      middle: horizontalPosition === 'middle',
      start: horizontalPosition === 'start',
    })}
    onClick={(e) => e.stopPropagation()}
    role="button"
    tabIndex="0"
  >
    {onClose && (
      <div className="bubble-close" onClick={onClose} role="button" tabIndex="0">
        &times;
      </div>
    )}
    {children}
  </div>
);

export default DropdownBubble;
