import ErrorLoggingConstants from 'spa/constants/ErrorLoggingConstants';

export const logWarning = (message) => ({
  type: ErrorLoggingConstants.LOG_WARNING,
  message,
});

export const logError = (message) => ({
  type: ErrorLoggingConstants.LOG_ERROR,
  message,
});

export default {
  logWarning,
  logError,
};
