import React from 'react';
import ReactSVG from 'react-svg';
import Alert from '@mui/material/Alert';
import { AlertTitle } from '@mui/material';
import { MediumText, SmallText } from 'spa/features/ui/EscrowTypography';
import { EmailText } from '../information/EmailText';

const upsellMapping = {
  concierge: {
    title: <MediumText>The Concierge Service is applied for this transaction</MediumText>,
    body: (
      <SmallText>
        Escrow.com will assist in the safe and secure transfer of the domain name.
      </SmallText>
    ),
    contactInfo: (
      <SmallText>
        Contact <EmailText email="concierge@escrow.com" /> for any concerns.
      </SmallText>
    ),
  },
  titleCollection: {
    title: <MediumText>The Title Collection service is applied for this transaction</MediumText>,
    body: (
      <SmallText>
        Escrow.com will assist in the safe delivery of the motor vehicle's title.
      </SmallText>
    ),
    contactInfo: (
      <SmallText>
        Contact <EmailText email="vehicleservices@escrow.com" /> for any concerns.
      </SmallText>
    ),
  },
  lienHolder: {
    title: <MediumText>The Lien Payoff Service is applied for this transaction</MediumText>,
    body: (
      <SmallText>Escrow.com will assist in sending the payoff check to the lien holder.</SmallText>
    ),
    contactInfo: (
      <SmallText>
        Contact <EmailText email="vehicleservices@escrow.com" /> for any concerns.
      </SmallText>
    ),
  },
  domainNamePromote: {
    title: <MediumText>The Concierge Service may be applied to this transaction</MediumText>,
    body: (
      <SmallText>
        Escrow.com may assist in the safe and secure transfer of the domain name.
      </SmallText>
    ),
    contactInfo: (
      <SmallText>
        Contact <EmailText email="concierge@escrow.com" /> to avail the{' '}
        <a href="/domains/domain-concierge">Concierge</a> service.
      </SmallText>
    ),
  },
  motorVehiclePromote: {
    title: (
      <MediumText>
        The Lien Payoff Service or the Title Collection Service may be applied to this transaction
      </MediumText>
    ),
    body: (
      <SmallText>
        Escrow.com may assist in sending the payoff check to the lien holder via our
        <a href="/support/faqs/what-is-the-lien-payoff-service"> Lien Payoff </a> service or assist
        in the safe delivery of the motor vehicle's title via our
        <a href="/support/faqs/what-is-the-title-collection-service"> Title Collection </a> service.
      </SmallText>
    ),
    contactInfo: (
      <SmallText>
        Contact <EmailText email="vehicleservices@escrow.com" /> to avail any of these services.
      </SmallText>
    ),
  },
};

const UpsellBanner = ({ upsellType }) => {
  const { title, body, contactInfo } = upsellMapping[upsellType] || {};

  return (
    <Alert
      variant="outlined"
      data-testid={`${upsellType}-upsell-banner`}
      sx={{ bgcolor: 'background.paper', borderColor: 'secondaryLight.dark', borderRadius: '8px' }}
      icon={<ReactSVG src="../../../../build/images/global/icons/transaction-upsell.svg" />}
    >
      <AlertTitle>
        <MediumText>{title}</MediumText>
      </AlertTitle>
      {body}
      {contactInfo}
    </Alert>
  );
};

export default UpsellBanner;
