import React from 'react';
import { connect } from 'react-redux';

import { partnerIDSelector } from 'spa/selectors/UserSelectors';

import PrimaryLink from 'spa/components/PrimaryLink';

class ReferralLinksDisplay extends React.PureComponent {
  render() {
    const { partnerID } = this.props;
    return (
      <div className="integrationsPortal-referral">
        <div className="integrationsPortal-referral-row">
          <span className="integrationsPortal-referral-key">Your partner ID is</span>
          <div className="integrationsPortal-referral-value">{partnerID}</div>
        </div>
        <div className="integrationsPortal-referral-row">
          <span className="integrationsPortal-referral-key">Example Links:</span>
          <div className="integrationsPortal-referral-value">
            <div className="integrationsPortal-referral-link">www.escrow.com/?PID={partnerID}</div>
          </div>
        </div>
        <p className="integrationsPortal-referral-desc">
          If you wish to place an Escrow.com banner on your website we have a number of banners
          available for download:
        </p>
        <PrimaryLink
          link={{ type: 'app', route: '/integrations/portal/referrals/banners' }}
          text="Download Banner"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  partnerID: partnerIDSelector(state),
});

const ReferralLinksDisplayContainer = connect(mapStateToProps, null)(ReferralLinksDisplay);

export default ReferralLinksDisplayContainer;
