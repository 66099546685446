import React from 'react';
import window from 'window-shim';
import QueryString from 'query-string';
import { Modal } from 'escrow-common-js/dist/components';
import { connect } from 'react-redux';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { setTransactionDraft, changeBuyerEmail } from 'spa/actions/TransactionActions';
import {
  loadNextStepData,
  setTransactionId,
  setPaymentType,
  setPaymentDetails,
} from 'spa/actions/CheckoutActions';
import { loginSSO as loginSSORoutine } from 'spa/actions/AuthenticationActions';
import { setAdyenThreeDSResult } from 'spa/actions/PaymentsActions';
import { trackEscrowUserAction } from 'spa/actions/TrackingActions';
import { loginLoadingSelector } from 'spa/selectors/AuthenticationSelectors';
import { checkoutLoadingNextStepDataSelector } from 'spa/selectors/CheckoutSelectors';
import { CancelPaymentDialog, ProgressTracker } from 'spa/components/CheckoutDetails/V2';
import { Spinner } from 'spa/components/Indicators';
import CheckoutConstants from 'spa/constants/CheckoutConstants';
import PaymentConstants from 'spa/constants/PaymentConstants';
import AuthenticationConstants from 'spa/constants/AuthenticationConstants';
import { CHECKOUT_FLOW } from 'spa/context/KYCContext';
import { getRequiredNextPage } from 'spa/utils/Verification.js';
import CheckoutHeaderContainer from '../CheckoutHeaderContainer';
import AuthenticationContainer from '../../Authentication';
import PaymentStepContainer from './PaymentStepContainer';
import CustomerDetailsStepContainer from './CustomerDetailsStepContainer';
import ConfirmationStepContainer from './ConfirmationStepContainer';

import UserStore from '../../../../stores/UserStore';
import AuthenticationStore from '../../../../stores/AuthenticationStore';


class CheckoutContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: CheckoutConstants.STEP_INITIAL,
      currentStepPercentage: 0,
      flowComplete: false,
      showEKYCSuccess: false,
    };

    const { checkoutData, checkoutFrom, setDraft, setTransId } = props;
    if (checkoutData) {
      checkoutData.source = checkoutFrom;
      setDraft(checkoutData);
      if (checkoutData.id) {
        setTransId(checkoutData.id);
      }
    }

    this._renderStep = this._renderStep.bind(this);
    this._renderEKYCSuccessScreen = this._renderEKYCSuccessScreen.bind(this);
    this.getStepAfterInit = this.getStepAfterInit.bind(this);
    this.getStepAfterPayment = this.getStepAfterPayment.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.setStep = this.setStep.bind(this);
    this.setSubstep = this.setSubstep.bind(this);
    this.setStepPercentage = this.setStepPercentage.bind(this);
    this.toggleEKYCSuccessScreen = this.toggleEKYCSuccessScreen.bind(this);

    const transactionSource = checkoutFrom === 'binb' ? 'WWWBINB' : 'EscrowPayDraft';
    props.trackEscrowPayPageSource(transactionSource, 'v2');
    UserStore.addChangeListener(this.updateCustomerDetails.bind(this));
    AuthenticationStore.addChangeListener(this.updateCustomerDetails.bind(this));
  }

  componentDidMount() {
    const { auth, loginSSO, loadStepData } = this.props;
    loadStepData(this.state.currentStep);
    if (auth) {
      loginSSO(auth);
    }
  }

  componentDidUpdate(prevProps) {
    const { loginLoading } = this.props;
    if (prevProps.loginLoading && !loginLoading) {
      if (!AuthenticationStore.isAuthenticated()) {
        this.setStep(CheckoutConstants.STEP_AUTHENTICATE);
      }
    }
    if (this.state.currentStep === CheckoutConstants.STEP_INITIAL) {
      if (prevProps.nextStepLoading && !this.props.nextStepLoading) {
        this.nextStep();
      }
    }
  }

  componentWillUnmount() {
    AuthenticationStore.removeChangeListener(this.updateCustomerDetails.bind(this));
    UserStore.removeChangeListener(this.updateCustomerDetails.bind(this));
  }

  /**
   * 
   * @param {int} step  Represents a step in the checkout flow, e.g. STEP_CONFIRMATION: 3,
   */
  setStep(step) {
    this.setState({ currentStep: step, currentStepPercentage: 0 });
  }

  /**
   * This sets the specific page name of a substep, e.g. wire_payment_review
   * 
   * @param {string} substep
   */
  setSubstep(substep) {
    this.setState({ currentSubstep: substep});
  }

  setStepPercentage(percent) {
    this.setState({ currentStepPercentage: percent });
  }

  getStepAfterInit() {
    const { adyenThreeDSResult, nextStepLoading, redirectFromPaymentPortal } = this.props;

    if (!AuthenticationStore.isAuthenticated()) {
      return CheckoutConstants.STEP_AUTHENTICATE;
    }

    if (redirectFromPaymentPortal) {
      this.props.setPaymentType(redirectFromPaymentPortal);
      switch (redirectFromPaymentPortal) {
        case PaymentConstants.PAYMENT_METHODS.CREDIT_CARD:
          if (adyenThreeDSResult) {
            const { authenticated_credit_card: authenticatedCreditCard } = adyenThreeDSResult;
            this.props.setAdyenThreeDSResult(adyenThreeDSResult);
            this.props.setPaymentDetails(authenticatedCreditCard);
            if (
              adyenThreeDSResult.result_code === PaymentConstants.ADYEN_THREEDS_PASSED &&
              !nextStepLoading
            ) {
              return this.getStepAfterPayment();
            }
          }
          break;
        case PaymentConstants.PAYMENT_METHODS.DIRECT_DEBIT:
          return CheckoutConstants.STEP_PAYMENT;
        default:
          break;
      }
    }

    return CheckoutConstants.STEP_PAYMENT;
  }

  getStepAfterPayment() {
    return this.props.canSkipStep2
      ? CheckoutConstants.STEP_CONFIRMATION
      : CheckoutConstants.STEP_CUSTOMER_DETAILS;
  }

  nextStep() {
    const { loadStepData } = this.props;
    let nextStep = this.state.currentStep;
    switch (this.state.currentStep) {
      case CheckoutConstants.STEP_INITIAL:
        nextStep = this.getStepAfterInit();
        break;
      case CheckoutConstants.STEP_AUTHENTICATE:
        if (AuthenticationStore.isAuthenticated()) {
          nextStep = CheckoutConstants.STEP_PAYMENT;
        }
        break;
      case CheckoutConstants.STEP_PAYMENT:
        nextStep = this.getStepAfterPayment();
        break;
      case CheckoutConstants.STEP_CUSTOMER_DETAILS:
        nextStep = CheckoutConstants.STEP_CONFIRMATION;
        break;
      case CheckoutConstants.STEP_CONFIRMATION:
        nextStep = CheckoutConstants.STEP_FINISHED;
        break;
      default:
        nextStep = this.state.currentStep;
        break;
    }
    loadStepData(nextStep);
    if (nextStep === CheckoutConstants.STEP_FINISHED) {
      this.setState({ ...this.state, flowComplete: true });
    } else {
      this.setStep(nextStep);
    }
  }

  prevStep() {
    if (this.state.currentStep > CheckoutConstants.STEP_PAYMENT) {
      if (
        this.state.currentStep === CheckoutConstants.STEP_CONFIRMATION &&
        this.props.canSkipStep2
      ) {
        this.setState({ currentStep: CheckoutConstants.STEP_PAYMENT });
        return;
      }
      this.setState((prevState) => ({
        currentStep: prevState.currentStep - 1,
        currentStepPercentage: 0,
      }));
    }
  }

  updateCustomerDetails() {
    const custId = AuthenticationStore.getCustomerID();
    const custData = UserStore.getCustomerData(custId);
    if (custData) {
      this.props.changeBuyerEmail(custData.Email);
    }
  }

  toggleEKYCSuccessScreen(show) {
    this.setState({ showEKYCSuccess: show });
  }

  _renderEKYCSuccessScreen() {
    return (
      <Modal
        isOpen={this.state.showEKYCSuccess}
        paddingSize=""
        targetElementId="spa"
        modalSize="medium"
        exitButton={false}
        exitTimeout={2500}
        onBackdropClick={() => this.toggleEKYCSuccessScreen(false)}
        enableBackdropClick
        transparent
      >
        <div className="checkout-page-successScreen">
          <img
            alt="Successfully Verified"
            src="../../../../../build/images/escrow-pay/ekyc-success.gif"
          />
        </div>
      </Modal>
    );
  }

  _renderStep() {
    const { dataTrackingPrefix } = this.props;
    if (this.props.nextStepLoading || this.state.currentStep === CheckoutConstants.STEP_INITIAL) {
      return (
        <div className="checkout-card-placeholder">
          <Spinner />
        </div>
      );
    }
    switch (this.state.currentStep) {
      case CheckoutConstants.STEP_AUTHENTICATE:
        return (
          <AuthenticationContainer
            onSuccess={this.nextStep}
            loginPage={AuthenticationConstants.CHECKOUT_LOGIN_PAGE}
          />
        );
      case CheckoutConstants.STEP_PAYMENT:
        return (
          <PaymentStepContainer
            nextStep={this.nextStep}
            setStepPercentage={this.setStepPercentage}
            dataTrackingPrefix={dataTrackingPrefix}
          />
        );
      case CheckoutConstants.STEP_CUSTOMER_DETAILS:
        return (
          <CustomerDetailsStepContainer
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            setStepPercentage={this.setStepPercentage}
            toggleEKYCSuccessScreen={this.toggleEKYCSuccessScreen}
            dataTrackingPrefix={dataTrackingPrefix}
          />
        );
      case CheckoutConstants.STEP_CONFIRMATION:
        return (
          <ConfirmationStepContainer
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            setStepPercentage={this.setStepPercentage}
            setSubstep={this.setSubstep}
            dataTrackingPrefix={dataTrackingPrefix}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const { nextStepLoading, dataTrackingPrefix, cancelUrl } = this.props;
    const { currentStep, currentSubstep, flowComplete } = this.state;
    const displayCancelPaymentButton = (
      cancelUrl && (
        currentStep < CheckoutConstants.STEP_CONFIRMATION ||
        currentSubstep == 'payment_review'
      )
    );

    return (
      <React.Fragment>
        <div className="checkout-card checkout-card--roundedEdges">
          {this._renderEKYCSuccessScreen()}
          <div data-tracking-section={`${dataTrackingPrefix}-header`}>
            <CheckoutHeaderContainer />
          </div>
          {currentStep !== CheckoutConstants.STEP_AUTHENTICATE &&
            currentStep !== CheckoutConstants.STEP_INITIAL && (
              <div data-tracking-section={`${dataTrackingPrefix}-progress`}>
                <ProgressTracker
                  nextStepLoading={nextStepLoading}
                  currentStep={flowComplete ? CheckoutConstants.STEP_FINISHED : currentStep}
                  setStep={this.setStep}
                  currentStepPercentage={this.state.currentStepPercentage}
                />
              </div>
            )}
          <SwitchTransition>
            <CSSTransition
              classNames="checkout-step"
              key={nextStepLoading ? 'checkout-step-loading' : `checkout-step-${currentStep}`}
            >
              <div>{this._renderStep()}</div>
            </CSSTransition>
          </SwitchTransition>
        </div>
        { displayCancelPaymentButton && <CancelPaymentDialog cancelPaymentUrl={cancelUrl} /> }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = QueryString.parse(window.location.search);
  const redirectFromPaymentPortal = QueryString.parse(window.location.search).continue;
  const cancelUrl = QueryString.parse(window.location.search).cancel_url;

  return {
    checkoutData: (window.js_context || {}).checkout_data,
    checkoutFrom: (window.js_context || {}).checkout_from,
    adyenThreeDSResult: (window.js_context || {}).adyen_threeds_result,
    redirectFromPaymentPortal,
    cancelUrl,
    nextStepLoading: checkoutLoadingNextStepDataSelector(state),
    canSkipStep2: !getRequiredNextPage(CHECKOUT_FLOW),
    loginLoading: loginLoadingSelector(state),
    auth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loginSSO: (auth) => {
    dispatch(loginSSORoutine.trigger({ auth }));
  },
  setAdyenThreeDSResult: (result) => dispatch(setAdyenThreeDSResult(result)),
  setPaymentDetails: (result) => dispatch(setPaymentDetails(result)),
  setDraft: (draft) => {
    dispatch(setTransactionDraft(draft));
  },
  setTransId: (id) => {
    dispatch(setTransactionId(id));
  },
  changeBuyerEmail: (email) => {
    dispatch(changeBuyerEmail({ email }));
  },
  trackEscrowPayPageSource: (transactionSource, subsection) => {
    dispatch(
      trackEscrowUserAction({
        name: 'user_landing',
        section: transactionSource,
        subsection,
      })
    );
  },
  loadStepData: (nextStep) => dispatch(loadNextStepData({ nextStep })),
  setPaymentType: (paymentType) => dispatch(setPaymentType(paymentType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutContainer);
