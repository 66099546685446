import { gettext } from '../utils/filters';

export default {
  PASSWORD_SUGGESTIONS: [
    {
      label: gettext('At least 7 characters'),
      test: (password) => password && password.length >= 7 && password.length < 120,
    },
    {
      label: gettext('At least one UPPER case and one lower case character'),
      test: (password) => password && /[A-Z]/.test(password) && /[a-z]/.test(password),
    },
    {
      label: gettext('At least one number or special character'),
      test: (password) =>
        password && /[0-9`\-=;\[\]',\./~!@#\$%\^&\*\(\)_\+:\{\}\|"<>\?]/.test(password),
    },
  ],
};
