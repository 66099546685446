var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/icons/search.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
[], 
["specifier"], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
var t_2 = "";t_2 += "<svg class=\"icon icon--search ";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "specifier"), env.opts.autoescape);
t_2 += "\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n     viewbox=\"0 0 19.8 20\" enable-background=\"new 0 0 19.8 20\" xml:space=\"preserve\">\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M19.4,19.6c-0.5,0.5-1.3,0.5-1.7,0l-4.1-4.1c-1.4,1-3.1,1.6-5,1.6\n    C3.9,17.1,0,13.3,0,8.6S3.9,0,8.6,0c4.7,0,8.6,3.8,8.6,8.6c0,2-0.7,3.8-1.8,5.3l4.1,4.1C19.9,18.4,19.9,19.2,19.4,19.6z M8.6,2.1\n    C5,2.1,2.2,5,2.2,8.6C2.2,12.1,5,15,8.6,15c1.5,0,2.9-0.5,4-1.4c0.1-0.1,0.1-0.3,0.3-0.4c0.1-0.1,0.3-0.2,0.4-0.3\n    c1-1.1,1.7-2.6,1.7-4.3C15,5,12.1,2.1,8.6,2.1z\"/>\n</svg>";
;
frame = frame.pop();
return new runtime.SafeString(t_2);
});
context.addExport("search");
context.setVariable("search", macro_t_1);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/icons/search.html"] , dependencies)