import { createSlice } from '@reduxjs/toolkit';

import { processTransactionJson } from './utils/transactionResponse';
import { transactionFetched } from './transactionApi';

export const transactionSlice = createSlice({
  name: 'transactionV2',
  initialState: {
    data: {},
    cache: {
      transaction: {},
      extraDetails: {},
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(transactionFetched, (state, action) => {
      const { id } = action.payload;
      if (action.payload.transaction) {
        state.cache.transaction[id] = action.payload.transaction;
      }
      if (action.payload.extraDetails) {
        state.cache.extraDetails[id] = action.payload.extraDetails;
      }
      if (state.cache.transaction[id] && state.cache.extraDetails[id]) {
        state.data[id] = processTransactionJson(
          state.cache.transaction[id],
          state.cache.extraDetails[id]
        );
      }
    });
  },
});

export const transactionSliceSelector = (state) => state.transactionV2;
export const transactionSelector = (id) => (state) => transactionSliceSelector(state).data[id];
