/**
 * app.js
 *
 * This is the entry file for the SPA
 */
import '../common';
import '../search';
import '../components/Form';
import '../components/ButtonReactor';
import '../components/LanguageSelector';
import '../components/Navigation';
import '../components/Modal';
import '../stores/NavigationStore';

/* eslint-disable import/first */
import React from 'react';
import { createRoot } from 'react-dom/client';

import createRoutes from '../routes';
import SpaContainer from '../spa/spaContainer';

const containerElement = document.querySelector('[data-container="spa"]');
const root = createRoot(containerElement);
root.render(<SpaContainer createRoutes={createRoutes} />);
