import { TOGGLE_SIDEBAR } from 'spa/constants/HeaderConstants';

const initialState = {
  sidebarOpen: false,
};

const headerReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      document.body.classList.toggle('sidebar-active');
      return {
        sidebarOpen: !state.sidebarOpen,
      };
    default:
      return state;
  }
};

export default headerReducer;
