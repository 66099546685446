import window from 'window-shim';

import OlarkStore from '../stores/OlarkStore';
import OlarkActions from '../actions/OlarkActions';
import OlarkConstants from '../constants/OlarkConstants';
import OlarkApi from '../utils/OlarkApi';

import UserStore from '../stores/UserStore';
import AuthenticationStore from '../stores/AuthenticationStore';

const GENERAL_GROUP_ID = (window.config || {}).olark_general_group_id;
const CHECKOUT_GROUP_ID = (window.config || {}).olark_checkout_group_id;
const HOMEPAGE_GROUP_ID = (window.config || {}).olark_homepage_group_id;
const VT_GROUP_ID = (window.config || {}).olark_vt_group_id;
const MY_TRANSACTIONS_GROUP_ID = (window.config || {}).olark_my_transactions_group_id;
const START_TRANSACTION_GROUP_ID = (window.config || {}).olark_start_transaction_group_id;

function initOlark(groupId, groupName) {
  setTimeout(() => {
    OlarkActions.registerOlarkGroup(groupName, groupId);
  });
}

export function initialiseOlarkByPage() {
  if (OlarkApi.isAvailable()) {
    if (window.location.pathname.includes('/transaction/')) {
      initOlark(GENERAL_GROUP_ID, OlarkConstants.TRANSACTION_GROUP);
      return;
    }
    switch (window.location.pathname) {
      case '/':
        initOlark(HOMEPAGE_GROUP_ID, OlarkConstants.HOMEPAGE_GROUP);
        break;
      case '/checkout':
        initOlark(CHECKOUT_GROUP_ID, OlarkConstants.CHECKOUT_GROUP);
        break;
      case '/verify':
      case '/verify/verify-tired-1-accout-details':
      case '/verify/verify-tier-1-seller':
      case '/verify/verify-account':
      case '/verify/account':
      case '/verify/verify-tier-1-buyer':
        initOlark(VT_GROUP_ID, OlarkConstants.VT_GROUP);
        break;
      case '/transactions':
        initOlark(MY_TRANSACTIONS_GROUP_ID, OlarkConstants.MY_TRANSACTIONS_GROUP);
        break;
      case '/transaction/start':
        initOlark(START_TRANSACTION_GROUP_ID, OlarkConstants.START_TRANSACTION_GROUP);
        break;
      default:
        initOlark(GENERAL_GROUP_ID, OlarkConstants.GENERAL_GROUP);
        break;
    }
  }
}

function getState() {
  const currentGroup = OlarkStore.getCurrentGroup();
  return {
    customerID: AuthenticationStore.getCustomerID(),
    fullName: UserStore.getFullName(AuthenticationStore.getCustomerID()),
    currentGroup: currentGroup,
    olarkOperatorAvailable: OlarkStore.isOperatorAvailable(currentGroup),
    shouldDisplay: OlarkStore.getShouldDisplay(),
    isDisplayed: OlarkStore.getIsDisplayed(),
    isConversing: OlarkStore.getIsConversing(),
  };
}

export class Olark {
  constructor() {
    if (OlarkApi.isAvailable()) {
      this.state = getState();
      this._onChange = this._onChange.bind(this);
      this.displayHandler = this.displayHandler.bind(this);

      UserStore.addChangeListener(this._onChange);
      AuthenticationStore.addChangeListener(this._onChange);
      OlarkStore.addChangeListener(this._onChange);

      initialiseOlarkByPage();
      this.displayHandler();

      OlarkApi.isConversing((isConversing) => {
        OlarkActions.setIsConversing(isConversing);
      });
    }
  }

  _onChange() {
    this.state = getState();
    this.updateCustomerDetails();
    this.displayHandler();
  }

  updateCustomerDetails() {
    if (this.state.fullName) {
      OlarkApi.updateVisitorName(this.state.fullName);
    }
  }

  displayHandler() {
    const isConversing = this.state.isConversing;
    switch (this.state.currentGroup) {
      case OlarkConstants.HOMEPAGE_GROUP:
      case OlarkConstants.CHECKOUT_GROUP:
      case OlarkConstants.VT_GROUP:
      case OlarkConstants.MY_TRANSACTIONS_GROUP:
      case OlarkConstants.START_TRANSACTION_GROUP:
      case OlarkConstants.TRANSACTION_GROUP:
        OlarkApi.showConsole();
        setTimeout(() => {
          OlarkActions.setDisplayedState({
            isDisplayed: true,
          });
        });
        break;
      // if the group is not set, the user may still need to see olark
      default:
        // if is conversing is null, we havent determined if the customer is
        // conversing yet so dont decide what to do until we know
        if (isConversing == null) {
          break;
        } else if (this.state.shouldDisplay && !this.state.isDisplayed) {
          isConversing ? OlarkApi.showConsole() : OlarkApi.shrinkConsole();
        } else {
          OlarkApi.hideConsole();
        }
        break;
    }
  }
}

export default new Olark();
