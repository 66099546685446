import React, { useCallback } from 'react';
import AdyenActionCard from './AdyenActionCard';

const AdyenForm = (props) => {
  const { adyenCCAvailable, adyenAction, checkout } = props;
  const cardRef = useCallback(
    (node) => {
      if (node !== null && adyenCCAvailable) {
        checkout
          .create('card', {
            hasHolderName: true,
            holderNameRequired: true,
            billingAddressRequired: true,
            brands: ['mc', 'visa', 'amex'],
          })
          .mount(node);
      }
    },
    [adyenCCAvailable, checkout]
  );

  return (
    <div>
      <div key="card" ref={cardRef} />
      <AdyenActionCard adyenAction={adyenAction} adyenCheckout={checkout} />
    </div>
  );
};

export default AdyenForm;
