import React from 'react';
import { Field } from 'redux-form';
import Icon from 'spa/components/Icon';

import { required, email } from 'spa/components/form/validate';
import AuthenticationConstants from 'spa/constants/AuthenticationConstants';
import { Input } from 'spa/components/form';
import { AlternativeInput } from '../form/Input';
import { gettext } from '../../../utils/filters';

class LoginFieldset extends React.Component {
  constructor(props) {
    super(props);

    this.validators = {
      username: [required, email],
      password: [required],
    };
  }

  render() {
    const { INTEGRATIONS_LOGIN_PAGE } = AuthenticationConstants;
    const { lockEmailAddress, formStyle } = this.props;
    if (formStyle === INTEGRATIONS_LOGIN_PAGE) {
      return (
        <fieldset>
          <Field
            name="username"
            className="integrationsAuth-form-step"
            component={AlternativeInput}
            showErrorIcon
            label={gettext('Email Address')}
            validate={this.validators.username}
            suffix={<Icon name="ui-email" className="customInput-icon" />}
            disabled={lockEmailAddress}
          />
          <Field
            name="password"
            className="integrationsAuth-form-step"
            component={AlternativeInput}
            showErrorIcon
            label={gettext('Password')}
            type="password"
            validate={this.validators.password}
            suffix={<Icon name="ui-lock-alt" className="customInput-icon" />}
          />
        </fieldset>
      );
    }

    return (
      <fieldset>
        <Field
          name="username"
          component={Input}
          label={gettext('Please enter your email address')}
          requiredIndicator
          validate={this.validators.username}
          prefix={<Icon name="ui-email-alt" className="icon icon--email" />}
          disabled={lockEmailAddress}
        />
        <Field
          name="password"
          component={Input}
          label={gettext('Please enter your password')}
          requiredIndicator
          type="password"
          validate={this.validators.password}
          prefix={<Icon name="ui-secure" className="icon icon--secure" />}
        />
      </fieldset>
    );
  }
}

export default LoginFieldset;
