import React from 'react';

import A from 'spa/components/A';
import Icon from 'spa/components/Icon';
import { urlFor } from '../../../../routeConfig';

const config = [
  {
    icon: <Icon name="ui-overview" className="partnerDashboard-sidenav-icon" />,
    title: 'Overview',
    link: { type: 'app', route: urlFor('partner_dashboard') },
  },
  {
    icon: <Icon name="ui-viewlist" className="partnerDashboard-sidenav-icon" />,
    title: 'Transactions',
    link: { type: 'app', route: urlFor('partner_dashboard_transactions') },
  },
  {
    icon: <Icon name="ui-users" className="partnerDashboard-sidenav-icon" />,
    title: 'Users',
    link: { type: 'app', route: urlFor('partner_dashboard_users') },
  },
  {
    icon: <Icon name="ui-document" className="partnerDashboard-sidenav-icon" />,
    title: 'Reports',
    link: { type: 'app', route: urlFor('partner_dashboard_reports') },
  },
];

const SideNavigation = ({ currentLocation }) => (
  <div className="partnerDashboard-sidenav">
    <div className="partnerDashboard-sidenav-content">
      {config.map(({ icon, title, link, disabled }) => (
        <A
          key={title}
          className={`partnerDashboard-sidenav-link ${
            currentLocation === link.route ? 'is-active' : ''
          } ${disabled && 'is-disabled'}`}
          link={link}
          role="button"
          tabIndex={0}
        >
          <span className="partnerDashboard-sidenav-iconHolder">{icon}</span>
          <span> {title} </span>
        </A>
      ))}
    </div>
  </div>
);

export default SideNavigation;
