import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { bindRoutineToReduxForm } from 'redux-saga-routines';

import Breadcrumbs from 'spa/components/Breadcrumbs';
import { Select, Input, RadioGroup, FormError } from 'spa/components/form';
import {
  compose,
  exactLength,
  lengthRange,
  maxChars,
  numericOnly,
  required,
} from 'spa/components/form/validate';
import { Spinner } from 'spa/components/Indicators';
import DisbursementConstants from 'spa/constants/DisbursementConstants';
import { addAch } from 'spa/actions/DisbursementActions';
import { gettext } from '../../../utils/filters';

class ACHForm extends React.Component {
  constructor(props) {
    super(props);
    this.validators = {
      bankAbaRoutingNumber: [
        compose([
          exactLength(9, gettext('ABA Routing number')),
          numericOnly(gettext('ABA Routing number'), true),
        ]),
      ],
      bankName: [maxChars(40, gettext('Bank Name'))],
      accountName: [maxChars(150, gettext('Account Name'))],
      bankAccountNumber: [lengthRange(3, 30)],
    };
  }

  componentWillMount() {
    this.props.getDisbursementDetails();
  }

  render() {
    const { handleSubmit, validDisbursementNames, display, submitFailed, error, submitting } =
      this.props;

    const breadcrumbs = [
      {
        text: gettext('Your Account'),
        link: '/account-info',
      },
      {
        text: gettext('Disbursement Options'),
        link: '/account-info/disbursement-options',
      },
      {
        text: gettext('ACH/Electronic Check'),
      },
    ];

    return (
      <section
        className="section section--small section--mid"
        data-e2e-target={`${this.props.form}-disbursement-container`}
      >
        <div className="section-container">
          <div className="account-inner">
            <Breadcrumbs className="accountInfo-breadcrumbs" items={breadcrumbs} />
            <header className="account-section-header-content account-section-header-content--full">
              <div className="account-section-header-content">
                <h1 className="account-section-title">
                  {gettext('Disbursement by ACH/Electronic Check')}
                </h1>
                <p className="account-section-desc">
                  <span className="field-required">*</span> {gettext('denotes a required field')}
                </p>
                {submitFailed && error && <FormError error={error} />}
              </div>
            </header>
            <form
              className="disbursementForm"
              onSubmit={handleSubmit(bindRoutineToReduxForm(addAch))}
            >
              <div className="disbursementForm-inner">
                <fieldset className="disbursementForm-fieldset">
                  <h2 className="disbursementForm-heading">
                    {gettext('Beneficiary bank information')}
                  </h2>
                  {/* ABA Routing Number */}
                  <div className="disbursementForm-field">
                    <Field
                      name="abaRoutingNumber"
                      label={gettext('ABA Routing Number')}
                      component={Input}
                      validate={[required, ...this.validators.bankAbaRoutingNumber]}
                      disabled={submitting}
                      requiredIndicator
                    />
                  </div>
                  {/* Bank Name */}
                  <div className="disbursementForm-field">
                    <Field
                      name="bankName"
                      label={gettext('Bank Name')}
                      component={Input}
                      validate={[required, ...this.validators.bankName]}
                      disabled={submitting}
                      requiredIndicator
                    />
                  </div>
                  {/* Account Name */}
                  <div className="disbursementForm-field">
                    <Field
                      name="accountName"
                      label={gettext('Name On Account')}
                      component={Select}
                      options={
                        display.loading || !display.success
                          ? [{ value: 'loading', label: gettext('Loading...') }]
                          : validDisbursementNames.map((name) => ({ value: name, label: name }))
                      }
                      validate={[required, ...this.validators.accountName]}
                      disabled={submitting || display.loading || !display.success}
                      tooltip={gettext(`Please use the full official name
                      under which your bank account is registered,
                      exactly as it appears on your bank statement.`)}
                      requiredIndicator
                    />
                  </div>
                  {/* Account Number */}
                  <div className="disbursementForm-field">
                    <Field
                      name="accountNumber"
                      label={gettext('Account Number')}
                      component={Input}
                      validate={[required, ...this.validators.bankAccountNumber]}
                      disabled={submitting}
                      requiredIndicator
                    />
                  </div>
                  {/* Account Type */}
                  <div className="disbursementForm-field">
                    <RadioGroup
                      name="accountType"
                      formName={this.props.form}
                      label={gettext('Account Type')}
                      validate={[required]}
                      disabled={submitting}
                      requiredIndicator
                      options={[
                        {
                          label: gettext('Checking'),
                          value: 'checking',
                        },
                        {
                          label: gettext('Savings'),
                          value: 'savings',
                        },
                      ]}
                    />
                  </div>
                </fieldset>
                <button
                  type="submit"
                  className="disbursementForm-btn btn btn--secondary btn--large"
                  disabled={submitting}
                >
                  {submitting ? <Spinner /> : gettext('Save Changes')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
}

const ACHFormContainer = reduxForm({
  form: DisbursementConstants.ACH_FORM_NAME,
  enableReinitialize: true,
})(ACHForm);

export default ACHFormContainer;
