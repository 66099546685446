import React from 'react';
import { V3KYCMobilePages as Pages } from 'spa/constants/VerificationConstants';
import MobilePage from '../../components/MobilePage';

const POAInstructionsPage = () => (
  <MobilePage
    title="POA capture instructions"
    ctaText="Start Capture"
    nextPage={Pages.POA_CAPTURE}
  />
);

export default POAInstructionsPage;
