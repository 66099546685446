import React from 'react';
import QueryString from 'query-string';
import { connect } from 'react-redux';

import Header from 'spa/containers/Header';
import Footer from 'spa/containers/Footer';
import { KYCContainer } from 'spa/containers/KYC';
import { ConfirmationMessageBox } from 'spa/components/CheckoutDetails';
import { partnerKYCSuccess as partnerKYCSuccessRoutine } from 'spa/actions/VerificationActions';
import { fetchTokenCustomerDetails as fetchTokenCustomerDetailsRoutine } from 'spa/actions/LandingPageTokenActions';
import { LandingPageTokenHeader } from 'spa/components/LandingPageToken';
import { VerificationTierRequirement } from 'spa/constants/VerificationConstants';
import {
  custIdSelector,
  isCustomerReadySelector,
  customerIsCompanySelector,
  customerKyc2IsLocallySubmittedSelector,
  customerKyc3IsLocallySubmittedSelector,
  customerKyc1VerifiedSelector,
  customerKyc2SubmittedSelector,
  customerKyc3SubmittedSelector,
} from 'spa/selectors/CustomerSelectors';
import { tokenCustomerSelector } from 'spa/selectors/LandingPageTokenSelectors';
import { gettext } from '../../../utils/filters';
import AuthenticationActions from '../../../actions/AuthenticationActions';

class PartnerKYCPage extends React.Component {
  constructor(props) {
    super(props);

    const query = QueryString.parse(window.location.search);
    this.state = {
      token: query.token,
      tierLevelRequirement: query.tier_level,
    };
  }

  componentDidMount() {
    if (this.state.token) {
      this.props.fetchTokenCustomerDetails(this.state.token);
    }
  }

  renderSuccessPanel(token, tierLevelRequirement) {
    this.props.partnerKYCSuccess(token);

    let successMessage = (
      <span>
        {gettext('Documents Submitted')}
        <br />
        {gettext('We will notify you when your account is verified')}
      </span>
    );
    if (tierLevelRequirement === VerificationTierRequirement.NOTHING) {
      successMessage = <span>{gettext('Escrow Login Successful')}</span>;
    } else if (tierLevelRequirement === VerificationTierRequirement.CUSTOMER_DETAILS) {
      successMessage = <span>{gettext('Successfully submitted details')}</span>;
    }

    return (
      <div className="verifyPage-body">
        <LandingPageTokenHeader landingPageToken={token} />
        <div className="verifyPage-card">
          <ConfirmationMessageBox isCurrentStep heading={successMessage} />
        </div>
      </div>
    );
  }

  renderInvalidTokenCustomer(token, customer) {
    return (
      <div className="verifyPage-body">
        <LandingPageTokenHeader landingPageToken={token} />
        <div className="verifyPage-card">
          <div className="checkout-confirmation">
            <div className="checkout-confirmation-inner">
              <h3 className="checkout-confirmation-heading checkout-heading">
                {gettext('Token Mismatch')}
              </h3>
              <p className="checkout-confirmation-desc checkout-desc">
                Please login using the email <strong>{customer.email}</strong> to proceed.
              </p>
              <button
                className="integrationsPortal-removeBtn btn btn--secondary btn--hollow"
                onClick={() => AuthenticationActions.logout()}
              >
                {gettext('Logout')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      showSuccessPanel,
      isLoggedIn,
      isTier1Submitted,
      isTier2Submitted,
      isTier3Submitted,
      loggedInCustomerId,
      tokenCustomerDetails,
      isCompany,
    } = this.props;
    const { token, tierLevelRequirement } = this.state;
    const shouldShowSuccessPanel =
      (!tierLevelRequirement && showSuccessPanel) ||
      (tierLevelRequirement === VerificationTierRequirement.NOTHING && isLoggedIn) ||
      (tierLevelRequirement === VerificationTierRequirement.CUSTOMER_DETAILS && isTier1Submitted) ||
      (tierLevelRequirement === VerificationTierRequirement.PERSONAL_VERIFICATION &&
        isTier2Submitted) ||
      (tierLevelRequirement === VerificationTierRequirement.COMPANY_VERIFICATION &&
        isTier3Submitted) ||
      (tierLevelRequirement === VerificationTierRequirement.ACCOUNT_TYPE &&
        (isCompany ? isTier3Submitted : isTier2Submitted));

    return (
      <div>
        <Header placeholder={gettext('Get Verified with Escrow.com')} />
        <div className="checkout section section--small section--dark">
          <div className="checkout-content section-container">
            {(!isLoggedIn ||
              (!shouldShowSuccessPanel &&
                (!token ||
                  (tokenCustomerDetails && loggedInCustomerId === tokenCustomerDetails.id)))) && (
              <KYCContainer hideIntroHeader landingPageToken={token} />
            )}
            {isLoggedIn &&
              token &&
              tokenCustomerDetails &&
              loggedInCustomerId !== tokenCustomerDetails.id &&
              this.renderInvalidTokenCustomer(token, tokenCustomerDetails)}
            {isLoggedIn &&
              (!token ||
                (tokenCustomerDetails && loggedInCustomerId === tokenCustomerDetails.id)) &&
              shouldShowSuccessPanel &&
              this.renderSuccessPanel(token, tierLevelRequirement)}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  showSuccessPanel:
    isCustomerReadySelector(state) &&
    ((!customerIsCompanySelector(state) && customerKyc2IsLocallySubmittedSelector(state)) ||
      (customerIsCompanySelector(state) && customerKyc3IsLocallySubmittedSelector(state))),
  isLoggedIn: isCustomerReadySelector(state),
  isTier1Submitted: isCustomerReadySelector(state) && customerKyc1VerifiedSelector(state),
  isTier2Submitted:
    isCustomerReadySelector(state) &&
    (customerKyc2IsLocallySubmittedSelector(state) || customerKyc2SubmittedSelector(state)),
  isTier3Submitted:
    isCustomerReadySelector(state) &&
    (customerKyc3IsLocallySubmittedSelector(state) || customerKyc3SubmittedSelector(state)),
  tokenCustomerDetails: tokenCustomerSelector(state),
  loggedInCustomerId: custIdSelector(state),
  isCompany: customerIsCompanySelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  partnerKYCSuccess: (token) => {
    dispatch(partnerKYCSuccessRoutine({ token }));
  },
  fetchTokenCustomerDetails: (token) => {
    dispatch(fetchTokenCustomerDetailsRoutine({ token }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnerKYCPage);
