import deepcopy from 'clone';
import AppDispatcher from '../dispatcher/AppDispatcher';
import { ChangeEmitter } from '../utils/ChangeEmitter';
import MilestoneInputConstants from '../constants/MilestoneInputConstants';
import StartTransactionConstants from '../constants/StartTransactionConstants';
import FormStore from '../stores/FormStore';

export class MilestoneInputStore extends ChangeEmitter {
  constructor() {
    super();

    this.values = [];

    for (let i = 0; i < MilestoneInputConstants.NUM_MILESTONES; i++) {
      this.values.push({});
    }

    this.descriptionRow = 0;
    this.showAllRows = false;
    this.subtotal = 0;
    this.shippingTotal = 0;
  }

  getDescriptionRows() {
    const rowList = [];
    for (let i = 0; i < MilestoneInputConstants.NUM_MILESTONES; i++) {
      const milestone = (i + 1).toString(); // Milestones start at 1, not 0
      if (!!this.values[i].description || this.descriptionRow === milestone) {
        rowList.push(milestone);
      }
    }
    return rowList;
  }

  getShowAllRows() {
    return this.showAllRows;
  }

  getSubtotal() {
    return this.subtotal;
  }

  getShippingTotal() {
    return this.shippingTotal;
  }

  getTotal() {
    if (
      FormStore.currentFormState('signup-create')['shipment-method'] ===
      StartTransactionConstants.SHIPPING_METHOD_NONE
    ) {
      return this.subtotal;
    }
    return this.subtotal + this.shippingTotal;
  }

  getAllMilestones() {
    return deepcopy(this.values);
  }

  updateTotals() {
    this.subtotal = 0;
    this.shippingTotal = 0;
    for (const milestone of this.values) {
      this.subtotal += milestone.unitPrice || 0;
      this.shippingTotal += milestone.shippingPrice || 0;
    }
  }

  handleViewAction(action) {
    if (action.actionType === MilestoneInputConstants.MILESTONE_VALUES_CHANGED) {
      this._onMilestoneValuesChanged(action.values, action.milestone);
    } else if (action.actionType === MilestoneInputConstants.SHOW_ALL_MILESTONES) {
      this._onShowAllMilestones();
    } else if (action.actionType === MilestoneInputConstants.CHANGE_DESCRIPTION_ROW) {
      this._onChangeDescriptionRow(action.row);
    } else if (action.actionType === MilestoneInputConstants.CLEAR_SHIPPING_PRICE_VALUES) {
      this._onClearShippingPriceValues();
    }
  }

  _onMilestoneValuesChanged(values, milestone) {
    // The first milestone is number 1, but index 0
    Object.assign(this.values[milestone - 1], values);
    this.updateTotals();
    this.emitChange();
  }

  _onShowAllMilestones() {
    this.showAllRows = true;
    this.emitChange();
  }

  _onChangeDescriptionRow(row) {
    this.descriptionRow = row;
    this.emitChange();
  }

  _onClearShippingPriceValues() {
    for (const milestone of this.values) {
      delete milestone.shippingPrice;
    }
    this.updateTotals();
    this.emitChange();
  }
}

const milestoneInputStore = new MilestoneInputStore();
milestoneInputStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;
  const source = payload.source;

  if (source === 'VIEW_ACTION') {
    milestoneInputStore.handleViewAction(action);
  }
});

export default milestoneInputStore;
