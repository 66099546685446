import request from 'browser-request';

export class LanguageActions {
  constructor(location = window.location) {
    this.location = location;
  }

  change(locale) {
    request.post(
      {
        url: '/api/locale',
        form: { locale: locale },
      },
      (error, response) => {
        if (!error && response.status === 200) {
          this.location.reload();
        }
      }
    );
  }
}

export default new LanguageActions();
