import { connect } from 'react-redux';
import PaymentReviewForm from 'spa/components/CheckoutDetails/V2/PaymentReviewForm';
import {
  checkoutPaymentTypeSelector,
  checkoutPaymentDetailsSelector,
} from 'spa/selectors/CheckoutSelectors';
import {
  transactionOrDraftSelector,
  transactionCCPaymentGatewaySelector,
} from 'spa/selectors/TransactionSelectors';
import { customerSelector } from 'spa/selectors/CustomerSelectors';

const mapStateToProps = (state) => {
  const transaction = transactionOrDraftSelector(state);
  const paymentMethods = transaction.paymentMethods || {};
  const availablePaymentMethods = paymentMethods.available_payment_methods || [];
  const conditionalPaymentMethods = paymentMethods.conditionally_available_payment_methods || [];
  const paymentType = checkoutPaymentTypeSelector(state);
  const selectedPaymentMethod = [...availablePaymentMethods, ...conditionalPaymentMethods].find(
    (method) => method.type === paymentType
  );
  const total = selectedPaymentMethod
    ? selectedPaymentMethod.total
    : paymentMethods.total_without_payment_fee;

  return {
    paymentType,
    total,
    transaction,
    transactionCCPaymentGateway: transactionCCPaymentGatewaySelector(state, transaction.id),
    paymentDetails: checkoutPaymentDetailsSelector(state),
    custDetails: customerSelector(state),
  };
};
export default connect(mapStateToProps)(PaymentReviewForm);
