import React from 'react';
import { EditableBox } from 'spa/components/MaterialUI';
import TransactionConstants from 'spa/constants/TransactionConstants';
import {
  getTransactionTypeCategoryAndLabel,
  formatCurrency,
} from 'spa/components/StartTransaction/util';

const { TRANSACTION_TYPES } = TransactionConstants;

const Item = ({ name, values, onEdit, index, formValues, itemsInEdit, disabled }) => {
  const { inspectionPeriod, currency } = formValues;
  const {
    category,
    itemType,
    name: itemName,
    price,
    itemInspectionPeriod,
    description,
    year,
    make,
    model,
  } = values;
  const { label } = getTransactionTypeCategoryAndLabel(category, itemType);
  const inspectionPeriodForItem = itemInspectionPeriod || inspectionPeriod;
  const VehicleDescription =
    itemType === TRANSACTION_TYPES.MOTOR_VEHICLE && `${year} ${make} ${model}`;
  return (
    <EditableBox onClick={onEdit} label={`Item ${index + 1}`} disabled={itemsInEdit || disabled}>
      <div key={name} className="materialUI-box-content">
        <div className="content-header">
          <div className="content-title">{itemName}</div>
          <div className="createTransaction-fee-amount">
            {formatCurrency(price, currency.toUpperCase())}
          </div>
        </div>
        <div className="materialUI-box-content-italic">{label}</div>
        {VehicleDescription && <div>{VehicleDescription}</div>}
        {description && <div className="materialUI-box-content-description">{description}</div>}
        {inspectionPeriodForItem && (
          <div>{`Inspection Period: ${inspectionPeriodForItem} Day${
            inspectionPeriodForItem > 1 ? 's' : ''
          }`}</div>
        )}
      </div>
    </EditableBox>
  );
};
export default Item;
