import * as Sentry from '@sentry/react';

// map the environment values from window.config.environment to
// sentry's pre-configured environments
const environmentMap = {
  DEV: 'dev',
  STG: 'stg',
  SANDBOX: 'sandbox',
  PROD: 'production',
};

const getSentryEnvironment = (appEnvironment) => {
  const sentryEnvironment = environmentMap[appEnvironment];
  if (process.env.NODE_ENV !== 'production' && sentryEnvironment === 'dev') {
    return 'local_dev';
  }
  return sentryEnvironment;
};

const env = getSentryEnvironment(window.config.environment);
const shouldSendSentry = env !== 'local_dev';
// ensure we don't set release to NULL by mistake
const releaseTag = process.env.GIT_COMMIT ? `escrow-www@${process.env.GIT_COMMIT}` : undefined;

Sentry.init({
  dsn: 'https://462e23380f9c4fbe9cd97a41c4113791@o24245.ingest.sentry.io/1768787',
  environment: env,
  enabled: shouldSendSentry,
  release: releaseTag,
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // This doesn't matter right now because we're not using distributed tracing
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/api\.escrow\.com\//,
        /^https:\/\/api\.escrow-sandbox\.com\//,
        /^https:\/\/api\.escrow-dev\.net\//,
      ],
      // Ignore routing instrumentation due to old react router version
      // routingInstrumentation: null
    }),
    new Sentry.Replay({
      networkCaptureBodies: true,
      networkResponseHeaders: ['X-Request-Id'],
      networkDetailAllowUrls: ['https://api.escrow.com', 'https://www.escrow.com'],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.05,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0,
});

// setup global context if available
Sentry.configureScope((scope) => {
  const qaEnvMatch = document.cookie.match(/non_default_version=([^;]+)/);
  if (qaEnvMatch) {
    scope.setTag('non_default_version', qaEnvMatch[1]);
  }
  if (window.escrowUser) {
    scope.setUser({ id: window.escrowUser.id, email: window.escrowUser.email });
  }
});
