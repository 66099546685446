import React, { Fragment } from 'react';
import classnames from 'classnames';
import { Field, reduxForm, formValues } from 'redux-form';
import { bindRoutineToReduxForm } from 'redux-saga-routines';

import A from 'spa/components/A';
import { Spinner } from 'spa/components/Indicators';
import { Checkbox, FormError } from 'spa/components/form';
import PaymentConstants from 'spa/constants/PaymentConstants';
import TransactionConstants from 'spa/constants/TransactionConstants';
import TransactionSummaryContainer from 'spa/containers/TransactionSummary/TransactionSummaryContainer';
import { selectPaymentMethod as selectPaymentMethodRoutine } from 'spa/actions/PaymentsActions';
import { PaymentForm } from './PaymentForm';
import { gettext } from '../../../utils/filters';

class OutstandingPaymentForm extends PaymentForm {
  componentDidMount() {
    const {
      getTransactionAndPaymentMethods,
      getWireDetails,
      getOutstandingBalance,
      resetPaymentPageState,
      getLastBuyerBankEntry,
    } = this.props;

    resetPaymentPageState();
    getOutstandingBalance();
    getTransactionAndPaymentMethods();
    getWireDetails();
    getLastBuyerBankEntry();
  }

  renderPaymentOptions(paymentOptions) {
    const { paymentType, adyenCCAvailable, transactionCCPaymentGateway } = this.props;
    let paymentOptionHeader = gettext('Payment Summary');
    if (paymentOptions.length > 1) {
      paymentOptionHeader = gettext('Select Payment Method');
    }

    let wireDetailsHidden = 'is-hidden';
    let wireActive = '';
    let ccActive = '';
    let ccDetailsHidden = 'is-hidden';
    let poliActive = '';
    if (paymentType === PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER) {
      wireDetailsHidden = '';
      wireActive = 'is-active';
    } else if (paymentType === PaymentConstants.PAYMENT_METHODS.CREDIT_CARD) {
      ccActive = 'is-active';
      ccDetailsHidden = '';
    } else if (paymentType === PaymentConstants.PAYMENT_METHODS.POLI) {
      poliActive = 'is-active';
    }

    const optionsArray = [];
    paymentOptions.map((paymentOption) => {
      if (paymentOption.value === PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER) {
        optionsArray.push(this.renderWire(wireDetailsHidden, wireActive));
      } else if (paymentOption.value === PaymentConstants.PAYMENT_METHODS.CREDIT_CARD) {
        if (
          !(
            transactionCCPaymentGateway === PaymentConstants.CREDIT_CARD_GATEWAY.ADYEN &&
            !adyenCCAvailable
          )
        ) {
          optionsArray.push(this.renderCreditCard(ccDetailsHidden, ccActive));
        }
      } else if (paymentOption.value === PaymentConstants.PAYMENT_METHODS.POLI) {
        optionsArray.push(this.renderPoli(poliActive));
      }
    });

    if (optionsArray.length === 0) {
      optionsArray.push(this.renderWire(wireDetailsHidden, wireActive));
    }

    return (
      <div>
        <h3 className="checkout-heading">{paymentOptionHeader}</h3>
        <div className="listOptions" data-tracking-subsection="payment-method">
          {optionsArray}
        </div>
        <div className="checkout-paymentMethod field-label is-hidden">
          <span>
            {gettext('Pay via wire transfer')}{' '}
            <a href="/support/faqs/what-is-a-wire-transfer" target="_blank">
              (What is a wire?)
            </a>
          </span>
        </div>
      </div>
    );
  }

  render() {
    const {
      checkoutPaymentType,
      customerDetails,
      customerSavedCreditCardsLoading,
      fetchErrors,
      form,
      handleSubmit,
      outstandingBalance,
      paymentType,
      submitting,
      submissionError,
      transaction,
      isAdyenValid,
      transactionCCPaymentGateway,
    } = this.props;

    if (!transaction || !customerDetails || !outstandingBalance) {
      return fetchErrors ? <FormError error={fetchErrors} /> : <Spinner />;
    } else if (customerSavedCreditCardsLoading) {
      return <Spinner />;
    }

    const paymentOptions = this._getPaymentOptions(transaction);
    const submitLabelAction =
      paymentType === PaymentConstants.PAYMENT_METHODS.CREDIT_CARD ||
      paymentType === PaymentConstants.PAYMENT_METHODS.PAYPAL
        ? gettext('Pay')
        : gettext('Continue');
    const disableSubmitButton =
      paymentType === PaymentConstants.PAYMENT_METHODS.CREDIT_CARD &&
      transactionCCPaymentGateway === PaymentConstants.CREDIT_CARD_GATEWAY.ADYEN &&
      !isAdyenValid;

    return (
      <form name={form} onSubmit={handleSubmit(bindRoutineToReduxForm(selectPaymentMethodRoutine))}>
        <div
          className={classnames('checkout-card', 'card', {
            'is-disabled': submitting,
          })}
          ref={(section) => {
            this.Card = section;
          }}
        >
          <div className="checkout-card-content checkout-card-content--light">
            <h3 className="checkout-heading">{gettext('Order Summary')}</h3>
            <TransactionSummaryContainer
              transaction={transaction}
              payBy={checkoutPaymentType}
              viewerPartyEmail={customerDetails.email}
              viewerPartyRole={TransactionConstants.TRANSACTION_ROLES.BUYER}
              hideTotal={false}
              outstandingBalance={outstandingBalance}
            />
          </div>
        </div>

        <div
          className={classnames('checkout-card checkout-card-secondary', 'card', {
            'is-disabled': submitting,
          })}
          ref={(section) => {
            this.Card = section;
          }}
        >
          <div className="checkout-card-content checkout-card-content--light">
            {this.renderPaymentOptions(paymentOptions)}
            {paymentType !== PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER && (
              <Fragment>
                <div className="checkout-terms">
                  <span className="checkout-terms-inner">
                    <Field
                      name="acceptTerms"
                      label={
                        <div>
                          {gettext('I agree to terms of this transaction and ')}
                          <A
                            link={{
                              type: 'internal',
                              route: '/legal',
                              newTab: true,
                            }}
                            className="checkout-terms-link"
                          >
                            {gettext('General Escrow Instructions')}
                          </A>
                        </div>
                      }
                      component={Checkbox}
                      disabled={submitting}
                      validate={this.validators.acceptTerms}
                    />
                  </span>
                </div>
                {submissionError && (
                  <div className="checkout-submit-error">
                    <FormError error={submissionError} />
                  </div>
                )}

                <div className="checkout-actions">
                  <div className="checkout-actions-item checkout-actions-item--secondary" />
                  <div className="checkout-actions-item checkout-actions-item--primary">
                    <button
                      type="submit"
                      className="checkout-card-btn btn btn--secondary"
                      data-e2e-target="checkout-payment-agree-btn"
                      disabled={disableSubmitButton || submitting}
                      data-tracking-name="agree"
                    >
                      {submitting ? <Spinner /> : submitLabelAction}
                    </button>
                  </div>
                  <div className="checkout-actions-item" />
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: PaymentConstants.PAYMENT_SHORTAGES_FORM,
  enableReinitialize: true,
})(
  formValues(
    'paymentType',
    'buyerBankDetails.bankCountry',
    'buyerBankDetails.bankState',
    'buyerBankDetails.bankName',
    'buyerBankDetails.accountName',
    'buyerBankDetails.thirdPartyCheckbox'
  )(OutstandingPaymentForm)
);
