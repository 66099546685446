import React from 'react';
import RestoreIcon from '@mui/icons-material/Restore';
import Box from '@mui/material/Box';

import { EscrowPaper } from '../../../ui/EscrowPaper';
import { MediumText } from '../../../ui/EscrowTypography';

import TransactionHistoryList from './TransactionHistoryList';

const TransactionHistory = ({ history }) => (
  <EscrowPaper>
    <Box sx={{ display: 'flex', gap: '8px', marginBottom: '12px', alignItems: 'center' }}>
      <RestoreIcon sx={{ color: 'secondaryLight.dark', height: 20, width: 20 }} />
      <MediumText>History</MediumText>
    </Box>
    <TransactionHistoryList history={history} />
  </EscrowPaper>
);

export default TransactionHistory;
