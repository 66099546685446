import React, { Fragment } from 'react';
import moment from 'moment';
import { getCurrencyString, getCurrencyTagFromApiCurrency } from 'escrow-common-js/dist/utils';
import { Spinner } from 'spa/components/Indicators';
import { FormErrorBox } from 'spa/components/form';
import {
  DetailsRow,
  TransactionLineItemsTable,
} from 'spa/components/PartnerDashboard/SharedComponents';
import { Alpha2ToCountry } from 'spa/constants/ISOCountryCodes';
import { gettext } from '../../../utils/filters';
import { getTransactionTotal, getTransactionStatus } from '../../../utils/PartnerDashboard';

const UserDetails = ({ header, details }) => (
  <Fragment>
    <h4 className="partnerDashboard-details-sectionTitle">{header}</h4>
    <DetailsRow label={gettext('Name')} value={details.display_name} />
    <DetailsRow
      label={gettext('Location')}
      value={details.address && Alpha2ToCountry[details.address.country]}
    />
    <DetailsRow label={gettext('Email Address')} value={details.email} />
  </Fragment>
);

const TransactionDetailsOverview = ({ transaction }) => (
  <Fragment>
    <h4 className="partnerDashboard-details-sectionTitle">{gettext('Transaction Details')}</h4>
    <div className="grid">
      <div className="grid-col grid-col--tablet-6">
        <DetailsRow label={gettext('Transaction ID')} value={transaction.id} />
        {transaction.reference && (
          <DetailsRow label={gettext('External Reference ID')} value={transaction.reference} />
        )}
        <DetailsRow
          label={gettext('Date Created')}
          value={moment(transaction.creation_date).format('YYYY-MM-DD HH:mm:ss')}
        />
        <DetailsRow
          label={gettext('Date Closed')}
          value={
            transaction.close_date
              ? moment(transaction.close_date).format('YYYY-MM-DD HH:mm:ss')
              : 'N/A'
          }
        />
      </div>
      <div className="grid-col grid-col--tablet-6">
        <DetailsRow
          label={gettext('Total')}
          value={getCurrencyString(
            getTransactionTotal(transaction),
            getCurrencyTagFromApiCurrency(transaction.currency)
          )}
        />
        <DetailsRow label={gettext('Status')} value={getTransactionStatus(transaction)} />
      </div>
    </div>
  </Fragment>
);

const TransactionHoldingPenDetails = ({ holdingPen, error, loading }) => {
  const holdingPenMessages = {};
  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return (
      <div className="grid">
        <div className={`grid-col`}>
          <div className="card-inner">
            <FormErrorBox error={error} />
          </div>
        </div>
      </div>
    );
  }
  for (const key of Object.keys(holdingPen)) {
    holdingPenMessages[key] = holdingPen[key].filter((pen) => pen !== 'Other');
  }
  return (
    <Fragment>
      <div className="grid">
        <div className={`grid-col grid-col--tablet-6`}>
          <div className="card-inner">
            <h4 className="partnerDashboard-details-sectionTitle">
              {gettext('Payment Approval Blockers')}
            </h4>
            <div className="partnerDashboard-details-row">
              <div className="partnerDashboard-details-value">
                <ol>
                  {holdingPenMessages.PaymentSecured
                    ? holdingPenMessages.PaymentSecured.map((pen, index) => (
                        <li key={pen}>
                          {index + 1}. {pen}
                          {'.'}
                        </li>
                      ))
                    : 'None'}
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className={`grid-col grid-col--tablet-6`}>
          <div className="card-inner">
            <h4 className="partnerDashboard-details-sectionTitle">
              {gettext('Disbursement Blockers')}
            </h4>
            <div className="partnerDashboard-details-row">
              <div className="partnerDashboard-details-value">
                <ol>
                  {holdingPenMessages.DisburseFunds
                    ? holdingPenMessages.DisburseFunds.map((pen, index) => (
                        <li key={pen}>
                          {index + 1}. {pen}
                          {'.'}
                        </li>
                      ))
                    : 'None'}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-inner" />
    </Fragment>
  );
};

const TransactionDetails = ({
  loadTransactionPartyInfo,
  getTransactionData,
  getTransactionHoldingPenConditions,
  transaction,
  transactionId,
  holdingPen,
  holdingPenLoading,
  holdingPenError,
  handleBack,
}) => {
  if (!transaction) {
    getTransactionData({ ids: [transactionId] });
    return <Spinner />;
  }
  if (!holdingPen && !holdingPenLoading && !holdingPenError) {
    getTransactionHoldingPenConditions(transactionId);
  }
  if (transaction.parties.some((party) => !('details' in party))) {
    loadTransactionPartyInfo(transactionId);
    return <Spinner />;
  }

  const buyer = transaction.parties.find((party) => party.role === 'buyer');
  const seller = transaction.parties.find((party) => party.role === 'seller');
  const broker = transaction.parties.find((party) => party.role === 'broker');

  return (
    <div>
      <header className="partnerDashboard-details-header">
        <a href="#" className="partnerDashboard-details-backBtn" onClick={() => handleBack()}>
          ← {gettext('Back')}
        </a>
        <h2 className="partnerDashboard-details-title">{transaction.description}</h2>
        <h3 className="partnerDashboard-details-subtitle">
          {gettext('Transaction ID')} {transaction.id}
        </h3>
      </header>
      <div className="card">
        <div className="grid">
          <div className={`grid-col ${broker ? 'grid-col--4' : 'grid-col--tablet-6'}`}>
            <div className="card-inner">
              <UserDetails header={`Buyer Details`} details={buyer.details} />
            </div>
          </div>
          <div className={`grid-col ${broker ? 'grid-col--4' : 'grid-col--tablet-6'}`}>
            <div className="card-inner">
              <UserDetails header={`Seller Details`} details={seller.details} />
            </div>
          </div>
          {broker && (
            <div className="grid-col grid-col--4">
              <div className="card-inner">
                <UserDetails header={`Broker Details`} details={broker.details} />
              </div>
            </div>
          )}
        </div>
        <div className="card-inner">
          <TransactionDetailsOverview transaction={transaction} />
        </div>
        <TransactionHoldingPenDetails
          holdingPen={holdingPen}
          error={holdingPenError}
          loading={holdingPenLoading}
        />
        <div className="card-inner">
          <TransactionLineItemsTable transaction={transaction} />
        </div>
      </div>
    </div>
  );
};

export default TransactionDetails;
