import React from 'react';
import classNames from 'classnames';
import Icon from 'spa/components/Icon';

const ESCROW_STEPS = [
  {
    step: 1,
    icon: 'handshake',
    name: 'Agreement',
  },
  {
    step: 2,
    icon: 'credit-card',
    name: 'Payment',
  },
  {
    step: 3,
    icon: 'truck-fast',
    name: 'Shipping',
  },
  {
    step: 4,
    icon: 'magnify',
    name: 'Inspection',
  },
  {
    step: 5,
    icon: 'flag-variant',
    name: 'Closed',
  },
];

const WizardFlow = ({ step }) => (
  <div className="transactionStatus-wizardFlow">
    {ESCROW_STEPS.map((escrowStep) => (
      <div
        key={escrowStep.name}
        className={classNames('transactionStatus-wizardFlow-step', {
          'transactionStatus-wizardFlow-step--past': step > escrowStep.step,
          'transactionStatus-wizardFlow-step--active': step === escrowStep.step,
        })}
      >
        <div className="transactionStatus-wizardFlow-step-icon ">
          {step > escrowStep.step && <Icon name="check-bold" />}
          {step === escrowStep.step && <Icon name={escrowStep.icon} />}
        </div>
        <div className="transactionStatus-wizardFlow-step-desc">{escrowStep.name}</div>
      </div>
    ))}
  </div>
);

export default WizardFlow;
