import React from 'react';

import { useTheme } from '@mui/material/styles';

const ReminderIcon = ({ Icon, variant }) => {
  const theme = useTheme();

  return (
    <Icon
      sx={{
        width: '20px',
        height: '20px',
        color: variant === 'unselected' ? '#151515' : theme.palette.secondaryLight.dark,
      }}
    />
  );
};

export default ReminderIcon;
