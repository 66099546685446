import React, { Fragment } from 'react';
import classnames from 'classnames';
import ReactSVG from 'react-svg';
import { Spinner } from 'spa/components/Indicators';
import PropTypes from 'prop-types';

const ConfirmationContent = ({ error, success, logo, header, subheader }) => (
  <Fragment>
    <div className="checkout-redirect-icon-container">
      {logo}
      <h2
        className={classnames(['checkout-redirect-header'], {
          'is-error': error,
          'is-success': success,
        })}
      >
        {header}
      </h2>
    </div>
    <div className="checkout-page-subheader">{subheader}</div>
  </Fragment>
);

const CardBrandedConfirmation = ({
  children,
  loading,
  error,
  success,
  logo,
  header,
  subheader,
}) => (
  <div className="checkout checkout-redirect">
    <div className="checkout-redirect-icon-container">
      <ReactSVG src={`../../../../build/images/global/escrow-logo-v2.svg`} />
    </div>
    {loading ? (
      <Spinner />
    ) : (
      <div className="checkout-card">
        {children || (
          <ConfirmationContent
            error={error}
            success={success}
            logo={logo}
            header={header}
            subheader={subheader}
          />
        )}
      </div>
    )}
  </div>
);

CardBrandedConfirmation.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  logo: PropTypes.node,
  subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default CardBrandedConfirmation;
