const requiredDocsSelector = (state, transId, custId = null) => {
  if (state.additionalDocuments.requiredDocs) {
    return state.additionalDocuments.requiredDocs.filter((doc) => {
      if (custId) {
        return doc.trans_id === transId && doc.requested_from === custId;
      }
      return doc.trans_id === transId;
    });
  }
  return [];
};

const loadingSelector = (state) => state.additionalDocuments.loading;

export { requiredDocsSelector, loadingSelector };
