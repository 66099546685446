import React from 'react';
import Footer from 'spa/containers/Footer';
import AuthenticationContainer from 'spa/containers/Authentication';
import AuthenticationConstants from 'spa/constants/AuthenticationConstants';

class TestAuthPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginComplete: false,
    };
    this.handleLoginSuccess = this.handleLoginSuccess.bind(this);
  }

  handleLoginSuccess() {
    this.setState({ loginComplete: true });
  }

  render() {
    const { loginComplete } = this.state;

    if (loginComplete) {
      return (
        <div>
          <div className="verifyPage section section--mid">
            <div className="checkout-content section-container">
              <div className="verifyPage-body">
                <div className="verifyPage-card">
                  <h1>Login Success</h1>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      );
    }

    return (
      <div>
        <div className="verifyPage section section--mid">
          <div className="checkout-content section-container">
            <div className="verifyPage-body">
              <div className="verifyPage-card">
                <AuthenticationContainer
                  loginPage={AuthenticationConstants.TEST_LOGIN_PAGE}
                  onSuccess={this.handleLoginSuccess}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default TestAuthPage;
