import _ from 'lodash';
import {
  CountryToAlpha2,
  Alpha3ToAlpha2,
  Alpha2ToAlpha3,
  Alpha2ToCountry,
  FullNames,
  Alpha2Names,
  Alpha3Names,
  AlternateCountryToAlpha2,
  AlternateAlpha2ToAlpha2,
  AlternateFullNames,
  AlternateAlpha2Names,
} from 'spa/constants/ISOCountryCodes';

/**
 * Maps all form data keys to the appropriate POST request body keys
 *
 * Expects a mapping object for and pass it to formToRequestSchema
 * {
 *   <form-field-name>: '<api-body-key-name>'
 * }
 *
 * Note: Keys can define semantic nesting, i.e if the following
 * mapping exists
 *
 * {
 *   street1: 'personalAddress.lane1'
 * }
 *
 * Then the mapping function will produce the following object
 *
 * {
 *   personalAddress: {
 *     lane1: <street1 value>
 *   }
 * }
 *
 * See https://lodash.com/docs/4.17.4#set for more details
 * */
export function mapFormDataToRequestBody(formToRequestSchema, formData) {
  const apiBody = {};
  if (!formToRequestSchema) {
    throw new Error(`No mapping exists`);
  }

  for (const key of Object.keys(formData)) {
    if (formToRequestSchema[key]) {
      _.set(apiBody, formToRequestSchema[key], formData[key]);
    } else {
      throw new Error(`Key: ${key} does not have a mapping`);
    }
  }

  return apiBody;
}

export function extractData(mappingSchema = {}, data = {}) {
  const newData = {};
  for (const key of Object.keys(mappingSchema)) {
    const dataToMap = _.get(data, key);
    _.set(newData, mappingSchema[key], dataToMap);
  }
  return newData;
}

/**
 * Recursively removes all falsy and empty data from objects
 * If the entire subtree of an object is undefined, the subtree will be removed
 *
 * Returns a new object, the original is not modified
 * */
export function removeEmpty(obj) {
  if (_.isEmpty(obj)) {
    return obj;
  }
  const newObj = {};
  for (const key of Object.keys(obj)) {
    if (_.isPlainObject(obj[key])) {
      if (_.isEmpty(obj[key])) {
        newObj[key] = undefined;
      } else {
        newObj[key] = removeEmpty(obj[key]);
      }
    } else if (_.isArray(obj[key]) && _.isEmpty(obj[key])) {
      newObj[key] = undefined;
    } else {
      newObj[key] = obj[key];
    }
  }
  return _.pickBy(newObj, _.identity);
}

/**
 * Can be given an alpha2, alpha3 or full country name
 * Will attempt to find the corresponding country and return an object representing that country.
 *
 * The returned object will contain alpha2, alpha3 and full name
 * */
export const lookupCountry = (country = '') => {
  if (country.length === 2) {
    const foundAlpha2 = Alpha2Names.find((a2) => a2.toLowerCase() === country.toLowerCase());
    const foundAlternateAlpha2 = AlternateAlpha2Names.find(
      (alternateAlpha2) => alternateAlpha2.toLowerCase() === country.toLowerCase()
    );
    let alpha2 = null;
    if (foundAlpha2) {
      alpha2 = foundAlpha2;
    } else if (foundAlternateAlpha2) {
      alpha2 = AlternateAlpha2ToAlpha2[foundAlternateAlpha2];
    }
    if (alpha2) {
      return {
        alpha2,
        alpha3: Alpha2ToAlpha3[alpha2],
        name: Alpha2ToCountry[alpha2],
      };
    }
  } else if (country.length === 3) {
    const foundAlpha3 = Alpha3Names.find((a3) => a3.toLowerCase() === country.toLowerCase());
    if (foundAlpha3) {
      const alpha2 = Alpha3ToAlpha2[foundAlpha3];
      return {
        alpha2,
        alpha3: foundAlpha3,
        name: Alpha2ToCountry[alpha2],
      };
    }
  } else {
    const foundFullName = FullNames.find((name) => name.toLowerCase() === country.toLowerCase());
    const foundAlternateFullName = AlternateFullNames.find(
      (alternateName) => alternateName.toLowerCase() === country.toLowerCase()
    );
    let alpha2 = null;
    if (foundFullName) {
      alpha2 = CountryToAlpha2[foundFullName];
    } else if (foundAlternateFullName) {
      alpha2 = AlternateCountryToAlpha2[foundAlternateFullName];
    }
    if (alpha2) {
      return {
        alpha2,
        alpha3: Alpha2ToAlpha3[alpha2],
        name: Alpha2ToCountry[alpha2],
      };
    }
  }

  return {};
};

export default {
  mapFormDataToRequestBody,
  extractData,
};
