import React from 'react';
import classnames from 'classnames';
import { Button } from 'escrow-common-js/dist/components';
import { FormSection, reduxForm, formValues, reset } from 'redux-form';
import { bindRoutineToReduxForm } from 'redux-saga-routines';

import A from 'spa/components/A';
import { FormError } from 'spa/components/form';
import RenderIf from 'spa/components/RenderIf';
import { Alpha2VatCountries } from 'spa/constants/ISOCountryCodes';
import { submitCustomerDetails } from 'spa/actions/CheckoutActions';
import CheckoutConstants from 'spa/constants/CheckoutConstants';
import { Spinner } from 'spa/components/Indicators';
import LoginFieldset from '../Authentication/LoginFieldset';
import SignupFieldset from '../Authentication/SignupFieldset';
import CustomerDetailsFieldset from './CustomerDetailsFieldset';
import CustomerDetailsDisplay from './CustomerDetailsDisplay';
import VatFieldset from '../TaxDetails/VatFieldset';
import { asyncValidate } from './validate';
import { scrollTo } from '../../../utils/ScrollHelper';
import { gettext } from '../../../utils/filters';

/**
 * This file holds five different components
 * 1. LoginForm: If a user has an existing email but is not yet authenticated
 * 2. SignupForm: If a user does not have an existing email and not yet authenticated
 * 3. DetailsEditingForm: When we cannot load customer contact details
 * 4. DetailsDisplay: If a user has contact details and authenticated, we show the contact details
 */
class CustomerDetailsForm extends React.Component {
  constructor(props) {
    super(props);

    this.switchToLoginForm = this.switchToLoginForm.bind(this);
    this.switchToSignupForm = this.switchToSignupForm.bind(this);
    this.switchToEditableForm = this.switchToEditableForm.bind(this);
    this.renderVatSection = this.renderVatSection.bind(this);
    this.renderLoginForm = this.renderLoginForm.bind(this);
    this.renderFormError = this.renderFormError.bind(this);
    this.renderInfo = this.renderInfo.bind(this);
    this.renderForm = this.renderForm.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isCurrentStep && this.props.currentStep !== nextProps.currentStep) {
      scrollTo(this.Card);
    }
  }

  switchToLoginForm() {
    this.props.dispatch(reset(CheckoutConstants.CUSTOMER_DETAILS_FORM));
    return this.props.setCurrentStep(CheckoutConstants.STEP_LOGIN);
  }

  switchToSignupForm() {
    return this.props.setCurrentStep(CheckoutConstants.STEP_SIGNUP);
  }

  switchToEditableForm() {
    return this.props.setCurrentStep(CheckoutConstants.STEP_TIER1_VERIFICATION);
  }

  renderVatSection() {
    const isVatCountry = (c) => Alpha2VatCountries.includes(c);
    const custInVatCountry =
      isVatCountry(this.props.customerDetails.country) ||
      isVatCountry(this.props.customerDetails['company-country']);
    const shouldHideVatSection =
      !custInVatCountry ||
      (this.props.isCurrentStep && this.props.customerHasVatNumber) ||
      (!this.props.isCurrentStep && !this.props.vatDetails.vatNumber);
    return (
      <RenderIf checkFn={Boolean} fnArgs={[!shouldHideVatSection]}>
        <FormSection name="vatDetails">
          <VatFieldset required={false} />
        </FormSection>
      </RenderIf>
    );
  }

  renderTermsStatement() {
    const country = window.config.geoip_country;
    // users in Australia should see extra links in the Terms and Conditions statement
    if (country === 'AUS') {
      return (
        <div className="checkout-card-content--small checkout-card-content--center">
          <div className="signupForm-agreement">
            {gettext("By registering you agree to the Escrow.com's ")}
            <A
              link={{
                type: 'internal',
                route: '/legal',
                newTab: true,
              }}
              className="checkout-terms-link"
            >
              {gettext('Terms of Using the Escrow Platform')}
            </A>
            {gettext(', ')}
            <A
              link={{
                type: 'internal',
                route: '/legal',
                newTab: true,
              }}
              className="checkout-terms-link"
            >
              {gettext('Combined Financial Services Guide (FSG)')}
            </A>
            {gettext(' and ')}
            <A
              link={{
                type: 'internal',
                route: '/legal',
                newTab: true,
              }}
              className="checkout-terms-link"
            >
              {gettext('Product Disclosure Statement (PDS)')}
            </A>
            {gettext(' and ')}
            <A
              link={{
                type: 'internal',
                route: '/legal',
                newTab: true,
              }}
              className="checkout-terms-link"
            >
              {gettext('Privacy Policy')}
            </A>
            {gettext('. You consent to receiving these policies online.')}
          </div>
        </div>
      );
    }

    return (
      <div className="checkout-card-content--small checkout-card-content--center">
        <div className="signupForm-agreement">
          {gettext("By registering you agree to the Escrow.com's ")}
          <A
            link={{
              type: 'internal',
              route: '/legal',
              newTab: true,
            }}
            className="checkout-terms-link"
          >
            {gettext('Terms of Using the Escrow Platform')}
          </A>
          {gettext(' and ')}
          <A
            link={{
              type: 'internal',
              route: '/legal',
              newTab: true,
            }}
            className="checkout-terms-link"
          >
            {gettext('Privacy Policy')}
          </A>
        </div>
      </div>
    );
  }

  /**
   * Render only the login fieldset
   */
  renderLoginForm() {
    const { customerDetails, form, submitting, loadingCustomerData } = this.props;
    return (
      <div className="checkout-form--auth">
        <div className="checkout-card-content--small checkout-card-content--center">
          {this.renderFormError()}
          <LoginFieldset formName={form} lockEmailAddress={customerDetails.lock_email} />
          <Button
            type="submit"
            className="checkout-card-btn"
            data-e2e-target="checkout-login-btn"
            disabled={submitting}
            data-tracking-name="login"
          >
            {loadingCustomerData || submitting ? <Spinner /> : gettext('Login')}
          </Button>
        </div>
      </div>
    );
  }

  /**
   * Render only the signup fieldset
   */
  renderSignupForm() {
    const { customerDetails, form, submitting, loadingCustomerData } = this.props;
    return (
      <div className="checkout-form--auth">
        <div className="checkout-card-content--small checkout-card-content--center">
          {this.renderFormError()}
          <SignupFieldset formName={form} lockEmailAddress={customerDetails.lock_email} />
          <Button
            type="submit"
            className="checkout-card-btn"
            data-e2e-target="checkout-signup-btn"
            disabled={submitting}
            data-tracking-name="signup"
          >
            {loadingCustomerData || submitting ? <Spinner /> : gettext("Let's Get Started")}
          </Button>
        </div>
        {this.renderTermsStatement()}
      </div>
    );
  }

  /**
   * Renders only the customer info form
   */
  renderCustomerForm() {
    const {
      showCancel,
      isTier2Verified,
      isTier3Verified,
      submitting,
      anyTouched,
      prevStep,
      form,
      customerDetails,
      isCountryForEKyc,
      loadingCustomerData,
    } = this.props;

    if (loadingCustomerData && !submitting) {
      return (
        <div className="checkout-card-placeholder checkout-card-content--light">
          <Spinner />
        </div>
      );
    }

    return (
      <div className="checkout-form checkout-card-content--light">
        <div className="checkout-card-content--detailsForm checkout-card-content--center">
          {this.renderFormError()}
          <FormSection name="customerDetails">
            <CustomerDetailsFieldset
              {...{
                ...customerDetails,
                formName: form,
                formSectionName: 'customerDetails',
                isTier2Verified,
                isTier3Verified,
              }}
            />
          </FormSection>
          {this.renderVatSection()}
        </div>
        <div className="checkout-actions">
          <div className="checkout-actions-item checkout-actions-item--secondary">
            <a
              href="#"
              className={classnames('checkout-card-secondaryAction', {
                'is-hidden': !showCancel,
              })}
              onClick={prevStep}
              data-tracking-name="cancel"
            >
              {gettext('Cancel')}
            </a>
          </div>
          <div className="checkout-actions-item checkout-actions-item--primary">
            <Button
              type="submit"
              className="checkout-card-btn"
              data-e2e-target="checkout-edit-customerDetails-save-btn"
              disabled={!anyTouched || submitting}
              data-tracking-name="save"
            >
              {submitting ? (
                <Spinner />
              ) : (
                gettext(isCountryForEKyc ? 'Save and Next' : 'Save Changes')
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  renderFormError() {
    if (this.props.submitFailed && this.props.error) {
      return (
        <div className="checkout-submit-error">
          <FormError error={this.props.error} />
        </div>
      );
    }
    return null;
  }

  renderInfo() {
    return (
      <CustomerDetailsDisplay
        {...this.props.reduxCustomerDetails}
        editDetails={this.switchToEditableForm}
        nextStep={this.props.nextStep}
        ui={this.props.ui}
        hidePersonalLabel
      />
    );
  }

  renderForm() {
    let fieldset = null;
    if (this.props.currentStep === CheckoutConstants.STEP_LOGIN) {
      fieldset = this.renderLoginForm();
    } else if (this.props.currentStep === CheckoutConstants.STEP_SIGNUP) {
      fieldset = this.renderSignupForm();
    } else if (this.props.currentStep === CheckoutConstants.STEP_TIER1_VERIFICATION) {
      fieldset = this.renderCustomerForm();
    }
    return (
      <div>
        <form
          name={this.props.form}
          onSubmit={this.props.handleSubmit(bindRoutineToReduxForm(submitCustomerDetails))}
        >
          {fieldset}
        </form>
        {this.renderFormSwitchButtons()}
      </div>
    );
  }

  renderFormSwitchButtons() {
    if (this.props.currentStep === CheckoutConstants.STEP_LOGIN) {
      return (
        <div className="checkout-switcher checkout-card-content checkout-card-content--small">
          <a
            href="#"
            className="checkout-card-secondaryAction"
            data-e2e-target="checkout-switch-signup-btn"
            onClick={this.switchToSignupForm}
            data-tracking-name="signup"
            data-tracking-label="switch-auth-form"
          >
            {gettext('Create an Account')}
          </a>
        </div>
      );
    } else if (this.props.currentStep === CheckoutConstants.STEP_SIGNUP) {
      return (
        <div className="checkout-switcher checkout-card-content checkout-card-content--small">
          <a
            href="#"
            className="checkout-card-secondaryAction"
            data-e2e-target="checkout-switch-login"
            onClick={this.switchToLoginForm}
            data-tracking-name="login"
            data-tracking-label="switch-auth-form"
          >
            {gettext('Login to escrow.com')}
          </a>
        </div>
      );
    }
    return null;
  }

  render() {
    const { isCurrentStep, currentStep } = this.props;
    if (!isCurrentStep) {
      return null;
    }
    if (currentStep === CheckoutConstants.STEP_VIEW_DETAILS) {
      return this.renderInfo();
    }
    return this.renderForm();
  }
}

export default reduxForm({
  form: CheckoutConstants.CUSTOMER_DETAILS_FORM,
  asyncValidate: asyncValidate,
  asyncChangeFields: ['username'],
})(formValues('customerDetails', 'vatDetails', 'showCancel')(CustomerDetailsForm));
