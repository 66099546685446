const paymentsPageSelector = (state) => state.paymentsPage;

const paymentsPageWireDetailsSelector = (state) => paymentsPageSelector(state).wireDetails.data;
const paymentsPagePlaidClientSelector = (state) => paymentsPageSelector(state).plaidClient.data;
const paymentsPageCheckDetailsSelector = (state) => paymentsPageSelector(state).checkDetails.data;
const paymentsPageWireDetailsErrorSelector = (state) =>
  paymentsPageSelector(state).wireDetails.error;
const paymentsPageCheckDetailsErrorSelector = (state) =>
  paymentsPageSelector(state).checkDetails.error;

const paymentsPageShowWireDetailsFormSelector = (state) =>
  paymentsPageSelector(state).wireDetails.showForm;

const paymentsPagePaymentTypeSelector = (state) => paymentsPageSelector(state).paymentType;
const paymentsPageOutstandingBalanceSelector = (state) =>
  paymentsPageSelector(state).outstandingBalance.data;
const paymentsPageOutstandingBalanceErrorSelector = (state) =>
  paymentsPageSelector(state).outstandingBalance.error;

const paymentsPagePaymentSubmissionSelector = (state) =>
  paymentsPageSelector(state).paymentSubmission;
const paymentsPagePaymentSubmissionSuccessSelector = (state) =>
  paymentsPagePaymentSubmissionSelector(state).success;
const paymentsPagePaymentSubmissionErrorSelector = (state) =>
  paymentsPagePaymentSubmissionSelector(state).error;

export {
  paymentsPageCheckDetailsSelector,
  paymentsPageCheckDetailsErrorSelector,
  paymentsPageWireDetailsSelector,
  paymentsPageShowWireDetailsFormSelector,
  paymentsPageWireDetailsErrorSelector,
  paymentsPagePaymentTypeSelector,
  paymentsPageOutstandingBalanceSelector,
  paymentsPageOutstandingBalanceErrorSelector,
  paymentsPagePaymentSubmissionSelector,
  paymentsPagePaymentSubmissionSuccessSelector,
  paymentsPagePaymentSubmissionErrorSelector,
  paymentsPagePlaidClientSelector,
};
