import React, { useEffect } from 'react';
import window from 'window-shim';
import ReactSVG from 'react-svg';
import { gettext } from '../../../utils/filters';

const PaypalIcon = (
  <div className="checkout-confirmation-icon">
    <ReactSVG
      beforeInjection={(svg) => {
        svg.classList.add('checkout-confirmation-paypal');
      }}
      src="../../../../build/images/payment-methods/paypal.svg"
    />
  </div>
);

const PaypalRedirectionBox = (props) => {
  useEffect(() => {
    if (props.isCurrentStep) {
      window.scrollTo(0, 0);
    }
  }, [props.isCurrentStep]);

  if (!props.isCurrentStep) {
    return null;
  }

  return (
    <div className="checkout-confirmation">
      <div className="checkout-confirmation-inner">
        {PaypalIcon}
        <h3 className="checkout-confirmation-heading checkout-heading">{props.heading}</h3>
        <p className="checkout-confirmation-desc checkout-desc">
          {gettext('You will be redirected in a few seconds')}
        </p>
      </div>
    </div>
  );
};

export default PaypalRedirectionBox;
