import AppDispatcher from '../dispatcher/AppDispatcher';
import OlarkConstants from '../constants/OlarkConstants';

export class OlarkActions {
  registerOlarkGroup(groupName, groupId) {
    AppDispatcher.handleViewAction({
      actionType: OlarkConstants.REGISTER_OPERATOR_STATUS_CALLBACKS,
      attributes: {
        groupName: groupName,
        groupId: groupId,
      },
    });
  }

  startOlark(groupName) {
    AppDispatcher.handleViewAction({
      actionType: OlarkConstants.START_OLARK,
      attributes: {
        groupName: groupName,
      },
    });
  }

  // disployState is an object with keys isDisplayed and should display
  // if the key/value is not specified, isDisplayed/shouldDisplay will be
  // undefined which can essentially be interpretted as a trinary of
  // undefined: not initialised, true, false
  setDisplayedState(displayState) {
    AppDispatcher.handleViewAction({
      actionType: OlarkConstants.SET_OLARK_STATE,
      attributes: {
        isDisplayed: displayState.isDisplayed,
        shouldDisplay: displayState.shouldDisplay,
      },
    });
  }

  setIsConversing(isConversing) {
    AppDispatcher.handleViewAction({
      actionType: OlarkConstants.SET_IS_CONVERSING,
      attributes: {
        isConversing,
      },
    });
  }

  notifyKYCSubmission(isDisplayed) {
    AppDispatcher.handleServerAction({
      actionType: OlarkConstants.NOTIFY_KYC_SUBMISSION,
      attributes: {
        isDisplayed,
      },
    });
  }
}

export default new OlarkActions();
