import React from 'react';
import ReactSVG from 'react-svg';
import { gettext } from '../../../utils/filters';

export const LoadingError = ({ text, colorClass }) => (
  <div className="transactions-error">
    <ReactSVG src={`../../../../build/images/myTransactions/errorLoading.svg`} />
    <div className="transactions-error-text">{text}</div>
    <button
      type="button"
      className={`transactions-error-btn btn btn--${colorClass}`}
      onClick={() => window.location.reload()}
    >
      {gettext('Reload Page')}
    </button>
  </div>
);
