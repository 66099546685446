import { put, takeLatest, call, all } from 'redux-saga/effects';
import { fetchTokenCustomerDetails as fetchTokenCustomerDetailsRoutine } from 'spa/actions/LandingPageTokenActions';
import { prefillUsername } from 'spa/actions/AuthenticationActions';
import API from '../../api';

export function* fetchTokenCustomerDetails(action) {
  try {
    const { token } = action.payload;
    const [customer, returnUrl] = yield all([
      call(API.getLandingPageTokenCustomer, token),
      call(API.getLandingPageTokenReturnUrl, token),
    ]);

    yield put(fetchTokenCustomerDetailsRoutine.success({ customer, returnUrl }));
    yield put(prefillUsername(customer.email));
  } catch (error) {
    yield put(fetchTokenCustomerDetailsRoutine.failure(error));
  }
}

export function* watcher() {
  yield takeLatest(fetchTokenCustomerDetailsRoutine.TRIGGER, fetchTokenCustomerDetails);
}

export default [watcher];
