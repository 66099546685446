import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { VatCountrySelect, Input } from 'spa/components/form';
import { required, maxChars } from 'spa/components/form/validate';
import {
  Alpha2VatCountries,
  Alpha2ToCountry,
  CountryToAlpha2,
} from '../../constants/ISOCountryCodes';
import { createSelectOptions } from '../../../utils/select';
import { gettext } from '../../../utils/filters';

class VatFieldset extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.fieldData = {
      vatCountries: {},
    };
    for (const c of Alpha2VatCountries) {
      this.fieldData.vatCountries[Alpha2ToCountry[c]] = c;
    }

    // European Commission uses EL instead of GR for Greece
    this.fieldData.vatCountries[Alpha2ToCountry[CountryToAlpha2.Greece]] = 'EL';

    this.validators = {
      vatNumber: [maxChars(32, gettext('VAT Number'))],
    };

    this.requiredValidators = {
      vatCountry: this.props.required ? [required] : [],
      vatNumber: this.props.required ? [required] : [],
    };
  }

  render() {
    const e2e = this.props['data-e2e-target'];
    return (
      <div className="field">
        <label className="field-label">
          {gettext('VAT Information')}
          {this.props.required && <span className="field-required">*</span>}
        </label>
        <div className="defaultForm-group">
          <Field
            name="vatCountry"
            component={VatCountrySelect}
            options={createSelectOptions(this.fieldData.vatCountries)}
            className="field--minor"
            validate={[...this.requiredValidators.vatNumber]}
            placeholder={`-- ${gettext('country code')} --`}
            data-e2e-target={e2e ? `${e2e}-country` : null}
          />
          <Field
            name="vatNumber"
            component={Input}
            validate={[...this.requiredValidators.vatNumber, ...this.validators.vatNumber]}
            placeholder={gettext('VAT Number')}
            data-e2e-target={e2e ? `${e2e}-number` : null}
          />
        </div>
      </div>
    );
  }
}

VatFieldset.propTypes = {
  required: PropTypes.bool,
};

VatFieldset.defaultProps = {
  required: true,
};

export default VatFieldset;
