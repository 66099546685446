import React from 'react';
import PaymentConstants from 'spa/constants/PaymentConstants';

const WireTransferDetails = ({ wireDetails }) => {
  const detailsList = [];
  PaymentConstants.WIRE_FIELD_ORDER.map((field) => {
    const fieldList = Object.keys(wireDetails);
    if (fieldList.indexOf(field) > -1) {
      detailsList.push({
        name: PaymentConstants.apiWireNameMapping[field],
        value: wireDetails[field],
        key: field,
      });
    }
  });
  return (
    <div className="grid">
      <p>
        {' '}
        Below are the bank transfer details you need to manually process the transaction with your
        bank.{' '}
      </p>
      {detailsList.map((field) => (
        <div className="listOptions-content-item grid-col grid-col--tablet-4" key={field.key}>
          <h4 className="listOptions-content-title">{field.name}</h4>
          <span>{field.value}</span>
        </div>
      ))}
    </div>
  );
};
export default WireTransferDetails;
