import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, formValues } from 'redux-form';
import { ActionButton, FormError } from 'spa/components/form';
import VerificationConstants from 'spa/constants/VerificationConstants';
import { CompanyVerificationFieldset } from './VerificationDetailsFieldset';

import { gettext } from '../../../utils/filters';

const CompanyVerificationForm = (props) => (
  <form name={props.form} onSubmit={props.handleSubmit(props.onSubmit)}>
    <header className="verifyPage-card-header">
      <div>
        {props.title && <h4 className="verifyPage-card-header-title">{props.title}</h4>}
        {props.desc && <p className="verifyPage-card-header-desc">{props.desc}</p>}
      </div>
    </header>
    <div className="verifyPage-card-content">
      <div className="verifyPage-card-body">
        {props.submitFailed && props.error && <FormError error={props.error} />}
        <CompanyVerificationFieldset
          companyCountry={props.companyCountry}
          companyType={props.companyType}
          companyRelationship={props.companyRelationship}
        />
      </div>
    </div>

    <footer className="verifyPage-card-footer">
      <div className="verifyPage-actions">
        {props.onCancel && (
          <a
            href="#"
            role="button"
            className="verifyPage-secondaryBtn verifyPage-secondaryBtn--alt"
            onClick={props.onCancel}
            data-e2e-target="verification-company-cancel-button"
          >
            {gettext('Cancel')}
          </a>
        )}
        <ActionButton
          type="submit"
          className="verifyPage-primaryBtn btn btn--secondary"
          data-e2e-target="verification-company-submit-button"
          data-tracking-name={'submit'}
          loading={props.submitting}
          label={gettext('Save Changes')}
          disabled={props.uploading}
        />
      </div>
    </footer>
  </form>
);

CompanyVerificationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  uploading: PropTypes.bool,
};

CompanyVerificationForm.defaultProps = {
  uploading: false,
};

export default reduxForm({
  form: VerificationConstants.COMPANY_VERIFICATION_FORM,
})(formValues('companyType', 'companyRelationship')(CompanyVerificationForm));
