import React from 'react';
import TwoFactorAuthenticationContainer from 'spa/containers/TwoFactorAuthentication';

const TwoFactorAuthenticationPage = () => (
  <div>
    <div className="twoFactorAuth section section--small section--dark">
      <div className="twoFactorAuth-content section-container">
        <div className="twoFactorAuth-inner">
          <TwoFactorAuthenticationContainer />
        </div>
      </div>
    </div>
  </div>
);

export default TwoFactorAuthenticationPage;
