import React from 'react';
import PropTypes from 'prop-types';

import ChannelCard from './ChannelCard';

const ChannelCards = ({ channelCards, className, small }) => (
  <div className={`integrationsPortal-channels ${className}`}>
    {channelCards.map((channelCard) => (
      <ChannelCard
        onClick={channelCard.onClick}
        iconPath={channelCard.iconPath}
        title={channelCard.title}
        description={channelCard.description}
        cta={channelCard.cta}
        key={channelCard.key}
        small={small}
      />
    ))}
  </div>
);

ChannelCards.propTypes = {
  channelCards: PropTypes.array,
  className: PropTypes.string,
  small: PropTypes.bool,
};

export default ChannelCards;
