import * as Sentry from '@sentry/browser';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createHistory from 'history/createBrowserHistory';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { reducer as formReducer } from 'redux-form';

import additionalDocumentsReducer from 'spa/reducers/AdditionalDocumentsReducer';
import apiIntegrationReducer from 'spa/reducers/APIIntegrationReducer';
import authenticationReducer from 'spa/reducers/AuthenticationReducer';
import disbursementReducer from 'spa/reducers/DisbursementReducer';
import expandCollapseReducer from 'spa/reducers/ExpandCollapseReducer';
import modalReducer from 'spa/reducers/ModalReducer';
import notificationSettingReducer from 'spa/reducers/NotificationSettingReducer';
import transactionReducer from 'spa/reducers/TransactionReducer';
import userReducer from 'spa/reducers/UserReducer';
import webhooksReducer from 'spa/reducers/WebhooksReducer';
import customerReducer from 'spa/reducers/CustomerReducer';
import checkoutReducer from 'spa/reducers/CheckoutReducer';
import offerReducer from 'spa/reducers/OfferReducer';
import paymentReducer from 'spa/reducers/PaymentReducer';
import partnerReducer from 'spa/reducers/PartnerReducer';
import landingPageTokenReducer from 'spa/reducers/LandingPageTokenReducer';
import paymentsPageReducer from 'spa/reducers/PaymentsPageReducer';
import trackingReducer from 'spa/reducers/TrackingReducer';
import twoFactorReducer from 'spa/reducers/TwoFactorReducer';
import headerReducer from 'spa/reducers/HeaderReducer';
import tourReducer from 'spa/reducers/TourReducer';
import fileUploadReducer from 'spa/reducers/FileUploadReducer';
import { resetPasswordApi } from 'spa/features/reset-password/resetPasswordApi';
import { transactionApi } from 'spa/features/transaction/transactionApi';
import { authApi } from 'spa/features/auth/authApi';
import { transactionSlice } from 'spa/features/transaction/transactionSlice';
import { kycApi } from 'spa/features/kyc/kycApi';
import { kycSlice } from 'spa/features/kyc/kycSlice';
import { stripeApi } from 'spa/features/transaction/stripeApi'
import sagas from './sagas';

export const history = createHistory({
  basename: '/',
});

const sagaMiddleWare = createSagaMiddleware({
  onError: (error) => {
    Sentry.captureException(error);
  },
});

export const rootReducer = combineReducers({
  router: connectRouter(history),
  additionalDocuments: additionalDocumentsReducer,
  apiIntegration: apiIntegrationReducer,
  authentication: authenticationReducer,
  disbursement: disbursementReducer,
  expandCollapse: expandCollapseReducer,
  form: formReducer,
  modal: modalReducer,
  notificationSetting: notificationSettingReducer,
  transaction: transactionReducer,
  offer: offerReducer,
  user: userReducer,
  webhooksIntegration: webhooksReducer,
  customer: customerReducer,
  checkout: checkoutReducer,
  payment: paymentReducer,
  paymentsPage: paymentsPageReducer,
  tracking: trackingReducer,
  partner: partnerReducer,
  token: landingPageTokenReducer,
  twoFactorAuthentication: twoFactorReducer,
  header: headerReducer,
  tour: tourReducer,
  fileUpload: fileUploadReducer,
  [resetPasswordApi.reducerPath]: resetPasswordApi.reducer,
  [transactionApi.reducerPath]: transactionApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  transactionV2: transactionSlice.reducer,
  [kycApi.reducerPath]: kycApi.reducer,
  kyc: kycSlice.reducer,
  [stripeApi.reducerPath]: stripeApi.reducer,
});

export const setupStore = (preloadedState) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActionPaths: [
            'payload.form', // v2 verify page
            'payload.file', // v2 verify page
            'customer.ui.kyc.uploads', // v2 verify page
            'payload.values.upload-file-front', // v2 verify page
            'payload.values.upload-file-address', // v2 verify page
            'payload.values.upload-file-company_document', // v2 verify page
          ],
          ignoredPaths: [
            'payload.file', // v2 verify page
            'customer.ui.kyc.uploads', // v2 verify page
          ],
        },
        immutableCheck: {
          ignoredPaths: [
            'payload.values.upload-file-company_document', // v2 verify page
            'customer.ui.kyc.uploads', // v2 verify page
          ],
        },
      }).concat(
        routerMiddleware(history),
        sagaMiddleWare,
        resetPasswordApi.middleware,
        transactionApi.middleware,
        authApi.middleware,
        kycApi.middleware,
        stripeApi.middleware
      ),
    preloadedState,
  });

export const store = setupStore({});

sagas.forEach((saga) => sagaMiddleWare.run(saga));

export default store;
