import window from 'window-shim';
import pathToRegexp, { compile } from 'path-to-regexp';
import routeConfig from '../../spa_routes.json';

const flaskToReactRoute = (route) => route.replace(/<.*?:(.*?)>/g, ':$1');

const routes = Object.keys(routeConfig).map((routeName) => ({
  ...routeConfig[routeName],
  name: routeName,
  regex: pathToRegexp(flaskToReactRoute(routeConfig[routeName].route)),
}));

const routeFor = (name) => flaskToReactRoute(routes.find((r) => r.name === name).route);

const urlFor = (name, params = {}) => compile(routeFor(name))(params);

const getRouteFromPath = (pathname) => routes.find((r) => r.regex.test(pathname));

const baseUrl = window.config ? window.config.www_base_url : "https://www.escrow.com";
const apiDomain = (urlToParse) => urlToParse.indexOf("localhost") === -1 ? urlToParse.split(".") : ["https://", "escrow-sandbox", "com"];
const parseUrl = apiDomain(baseUrl);
const envDomainName = `${parseUrl[1]}.${parseUrl[2]}`;

export { routeConfig, routeFor, urlFor, getRouteFromPath, envDomainName };
