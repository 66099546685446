import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import Alert from 'spa/components/Alert';
import { Spinner } from 'spa/components/Indicators';
import OfferDropdown from 'spa/components/OfferDropdown';
import AuthenticationContainer from 'spa/containers/Authentication';
import { MakeAnOfferHeader } from 'spa/components/Offer';
import {
  isCustomerReadySelector,
  customerDetailsLoadingSelector,
} from 'spa/selectors/CustomerSelectors';
import {
  activeTransIdSelector,
  auctionIdSelector,
  currentAuctionSelector,
  getOffersByAuctionIdSelector,
  offerIdsByAuctionIdSelector,
  uiSelector,
} from 'spa/selectors/OfferSelectors';
import {
  loginLoadingSelector,
  login2faSuccessSelector,
} from 'spa/selectors/AuthenticationSelectors';
import {
  pushAuctionToken,
  pushAuctionSource,
  initialiseOfferManagement,
} from 'spa/actions/OfferActions';
import { prefillUsername } from 'spa/actions/AuthenticationActions';
import AuthenticationConstants from 'spa/constants/AuthenticationConstants';
import AuthenticationStore from '../../../stores/AuthenticationStore';
import { gettext } from '../../../utils/filters';
import OfferGroupingsContainer from './OfferGroupingsContainer';
import OfferListContainer from './OfferListContainer';

class OfferManagementContainer extends React.Component {
  constructor(props) {
    super(props);

    this.renderBody = this.renderBody.bind(this);
  }

  componentDidMount() {
    if (this.props.token) {
      this.props.pushAuctionToken(this.props.token);
      if (AuthenticationStore.isAuthenticated()) {
        this.props.initialiseOfferManagement(this.props.token);
      }
    }

    if (this.props.source) {
      this.props.pushAuctionSource(this.props.source);
    }

    if (this.props.email) {
      this.props.pushEmail(this.props.email);
    }
  }

  renderBody() {
    const { getOffers } = this.props.requests;
    const offerIds = this.props.offerIdsByAuctionId[this.props.activeAuctionId] || [];

    if (
      !getOffers.loading &&
      (!getOffers.error || getOffers.errorMessage === 'NO_OFFERS') &&
      offerIds.length === 0
    ) {
      return (
        <div className="offer-notice">
          <div className="offer-notice-body">
            <div className="offer-notice-image-container">
              <img
                className="offer-notice-image"
                alt="Auction Open"
                src="../../../../build/images/offer/offer_open.png"
              />
            </div>
            <h3>{gettext('Your Escrow Offer is live')}</h3>
            <div className="offer-detail-label">
              {gettext('Check back in to view your offers.')}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="grid">
        <div
          className={classnames('offer-nav grid-col', {
            'grid-col--desktopSmall-2': !this.props.activeTransId,
            'grid-col--desktopSmall-4': this.props.activeTransId,
          })}
        >
          <OfferGroupingsContainer />
        </div>
        <div className="offer-content grid-col grid-col--desktopSmall-8">
          <OfferListContainer />
        </div>
      </div>
    );
  }

  render() {
    const missingParameters = [];
    if (!this.props.source) missingParameters.push('source');
    if (!this.props.token) missingParameters.push('token');
    if (missingParameters.length > 0) {
      return (
        <Alert type="error">
          {gettext(
            'Missing required parameters [%(params)s]' +
              ' - please check your link or contact support@escrow.com',
            { params: missingParameters.join(', ') }
          )}
        </Alert>
      );
    }
    if (this.props.isCustomerDetailsLoading && this.props.isTwoFAReady) {
      return <Spinner />;
    }

    const { getAuction } = this.props.requests;

    if (!this.props.isCustomerReady || !this.props.isTwoFAReady) {
      return (
        <div className="checkout-inner">
          <div className="checkout-card" data-tracking-section="offer-management-auth-section">
            <div className="checkout-content section-container">
              <MakeAnOfferHeader auctionToken={this.props.token} />
              <OfferDropdown
                title={'Manage Offers'}
                token={this.props.token}
                viewerRole={'seller'}
              />
              <hr className="checkout-line" />
              <AuthenticationContainer
                onSuccess={() => this.props.initialiseOfferManagement(this.props.token)}
                loginPage={AuthenticationConstants.OFFER_MANAGEMENT_LOGIN_PAGE}
              />
            </div>
          </div>
        </div>
      );
    }

    if (getAuction.error) {
      if (getAuction.errorMessage === 'Sorry! This auction has already ended.') {
        return (
          <div className="offer-notice">
            <header className="offer-header">
              <h1 className="offer-notice-title">{gettext('Auction Closed')}</h1>
            </header>
            <div className="offer-notice-body">
              <div className="offer-notice-image-container">
                <img
                  className="offer-notice-image"
                  alt="Auction Closed"
                  src="../../../../build/images/offer/offer_closed.png"
                />
              </div>
              <h3>{gettext(getAuction.errorMessage)}</h3>
              <a href={`${window.config.www_base_url}/transactions`}>Go Back</a>
            </div>
          </div>
        );
      }
      return <Alert type="error">{getAuction.errorMessage}</Alert>;
    }
    if (getAuction.loading || !this.props.auction) {
      return <Spinner />;
    }

    return (
      <div data-tracking-label={this.props.auction.id}>
        <div>
          <header className="offer-header">
            <h1 className="offer-header-title">
              {gettext('Offers for')} {this.props.auction.description}
            </h1>
          </header>
          <div className="offer-body">{this.renderBody()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeAuctionId: auctionIdSelector(state),
  activeTransId: activeTransIdSelector(state),
  auction: currentAuctionSelector(state),
  isCustomerDetailsLoading: customerDetailsLoadingSelector(state),
  isCustomerReady: isCustomerReadySelector(state),
  isTwoFAReady: !loginLoadingSelector(state) && login2faSuccessSelector(state),
  offerIdsByAuctionId: offerIdsByAuctionIdSelector(state),
  requests: {
    getAuction: uiSelector(state).getAuction,
    getOffers: getOffersByAuctionIdSelector(state, auctionIdSelector(state)),
  },
});

const mapDispatchToProps = (dispatch) => ({
  initialiseOfferManagement: (auctionToken) => {
    dispatch(initialiseOfferManagement({ auctionToken, isMasked: true }));
  },
  pushAuctionToken: (auctionToken) => {
    dispatch(pushAuctionToken(auctionToken));
  },
  pushAuctionSource: (actionType) => {
    dispatch(pushAuctionSource(actionType));
  },
  pushEmail: (email) => {
    dispatch(prefillUsername(email));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OfferManagementContainer);
