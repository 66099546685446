import { createRoutine } from 'redux-saga-routines';

import VerificationConstants from 'spa/constants/VerificationConstants';

export const submitPersonalVerificationForm = createRoutine(
  VerificationConstants.SUBMIT_PERSONAL_VERIFICATION_FORM
);

export const submitElectronicVerificationForm = createRoutine(
  VerificationConstants.SUBMIT_ELECTRONIC_VERIFICATION_FORM
);

export const submitCompanyVerificationForm = createRoutine(
  VerificationConstants.SUBMIT_COMPANY_VERIFICATION_FORM
);

export const submitCustomerDetailsForm = createRoutine(
  VerificationConstants.SUBMIT_CUSTOMER_DETAILS_FORM
);

export const partnerKYCSuccess = createRoutine(VerificationConstants.PARTNER_KYC_SUCCESS);

export const kycGotoNextPage = createRoutine(VerificationConstants.KYC_GOTO_NEXT_PAGE);
export const kycGotoPrevPage = createRoutine(VerificationConstants.KYC_GOTO_PREV_PAGE);

export const kycSetSubheader = (payload) => ({
  type: VerificationConstants.KYC_SET_SUBHEADER,
  payload,
});
export const kycSetErrorAlert = (payload) => ({
  type: VerificationConstants.KYC_SET_ERROR_ALERT,
  payload,
});
export const kycClearErrorAlert = () => ({
  type: VerificationConstants.KYC_CLEAR_ERROR_ALERT,
});

export const kycClearFileUploads = () => ({
  type: VerificationConstants.KYC_CLEAR_FILE_UPLOADS,
});

export const kycLoadInitPage = (payload) => ({
  type: VerificationConstants.KYC_LOAD_INIT_PAGES,
  payload,
});

export const kycAddInitPages = (payload) => ({
  type: VerificationConstants.KYC_ADD_INIT_PAGES,
  payload,
});

export const kycDequeueInitPages = () => ({
  type: VerificationConstants.KYC_DEQUEUE_INIT_PAGES,
});

export const kycCheckDocumentsSubmitted = () => ({
  type: VerificationConstants.KYC_CHECK_DOCUMENTS_SUBMITTED,
});

export const kycSubmitCustomerDetails = createRoutine(
  VerificationConstants.KYC_SUBMIT_CUSTOMER_DETAILS
);
export const kycSubmitIndividual = createRoutine(VerificationConstants.KYC_SUBMIT_INDIVIDUAL);
export const kycSubmitCompany = createRoutine(VerificationConstants.KYC_SUBMIT_COMPANY);
export const kycUploadFile = createRoutine(VerificationConstants.KYC_UPLOAD_FILE);
export const kycSetIsCompany = createRoutine(VerificationConstants.KYC_SET_ISCOMPANY);
export const kycSubmitExtended = createRoutine(VerificationConstants.KYC_SUBMIT_EXTENDED);
