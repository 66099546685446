import React, { useEffect } from 'react';
import classnames from 'classnames';
import Icon from 'spa/components/Icon';
import CheckoutConstants from 'spa/constants/CheckoutConstants';
import { gettext } from '../../../../utils/filters';

export class StepButton extends React.Component {
  constructor(props) {
    super(props);
    this.title = '';
    this.step = 0;
  }

  isActive() {
    return this.props.currentStep === this.step;
  }

  isDone() {
    return this.props.currentStep > this.step;
  }

  isClickable() {
    // return this.isDone();
    return false;
  }

  render() {
    const buttonText = this.isDone() ? (
      <Icon className="checkout-progress-step-icon" name="ui-tick" />
    ) : (
      <div className="checkout-progress-step-icon">{this.step}</div>
    );
    const done = this.isDone();
    const active = this.isActive();
    const clickable = this.isClickable();
    const click = this.isClickable() ? () => this.props.setStep(this.step) : null;

    return (
      <div
        className={classnames('checkout-progress-step', {
          'is-clickable': clickable,
        })}
        onClick={click}
        role="presentation"
        key={this.title}
        data-tracking-name={`${this.trackingName}`}
      >
        <div
          className={classnames('checkout-progress-step-iconHolder', {
            'is-active': active,
            'is-done': done,
            'is-clickable': clickable,
          })}
        >
          {buttonText}
        </div>
        <div
          className={classnames('checkout-progress-step-title', {
            'is-active': active,
          })}
        >
          {this.title}
        </div>
      </div>
    );
  }
}

const StepProgressBar = ({ percent }) => (
  <div className="checkout-progress-bar">
    <span className="checkout-progress-bar-loader" style={{ width: `${percent}%` }} />
  </div>
);

class PaymentStepButton extends StepButton {
  constructor(props) {
    super(props);
    this.title = gettext('PAYMENT');
    this.step = CheckoutConstants.STEP_PAYMENT;
    this.trackingName = 'checkout-payment-section';
  }
}

class CustomerDetailsStepButton extends StepButton {
  constructor(props) {
    super(props);
    this.title = gettext('ESCROW');
    this.step = CheckoutConstants.STEP_CUSTOMER_DETAILS;
    this.trackingName = 'checkout-customer-details-section';
  }
}

class ConfirmationStepButton extends StepButton {
  constructor(props) {
    super(props);
    this.title = gettext('CONFIRM');
    this.step = CheckoutConstants.STEP_CONFIRMATION;
    this.trackingName = 'checkout-confirmation-section';
  }

  isActive() {
    return this.props.currentStep >= this.step;
  }
}

const ProgressTracker = (props) => {
  const setActiveStepLabel = (stopTransition) => {
    const progressTracker = document.querySelector('.checkout-progress');
    const activeStepIconHolder = document.querySelector(
      '.checkout-progress-step-iconHolder.is-active'
    );
    const activeStepLabel = document.querySelector('.checkout-progress-step-activeLabel');
    activeStepLabel.style.transitionProperty = stopTransition ? 'none' : '';
    if (!(props.currentStep === CheckoutConstants.STEP_PAYMENT && props.nextStepLoading)) {
      activeStepLabel.style.left = `${
        activeStepIconHolder.getBoundingClientRect().x -
        4 -
        progressTracker.getBoundingClientRect().x
      }px`;
      activeStepLabel.style.opacity = 1;
    }
  };

  const setProgressBars = (stopTransition) => {
    const progressTracker = document.querySelector('.checkout-progress');
    const iconHolders = document.querySelectorAll('.checkout-progress-step-iconHolder');
    const progressBars = document.querySelectorAll('.checkout-progress-bar');
    progressBars[0].style.transitionProperty = stopTransition ? 'none' : '';
    progressBars[1].style.transitionProperty = stopTransition ? 'none' : '';

    const barWidth =
      iconHolders[1].getBoundingClientRect().x -
      (iconHolders[0].getBoundingClientRect().x + iconHolders[0].getBoundingClientRect().width) -
      20;
    progressBars[0].style.left = `${
      iconHolders[0].getBoundingClientRect().x +
      iconHolders[0].getBoundingClientRect().width +
      10 -
      progressTracker.getBoundingClientRect().x
    }px`;
    progressBars[1].style.left = `${
      iconHolders[1].getBoundingClientRect().x +
      iconHolders[1].getBoundingClientRect().width +
      10 -
      progressTracker.getBoundingClientRect().x
    }px`;
    progressBars[0].style.width = `${barWidth}px`;
    progressBars[1].style.width = `${barWidth}px`;
  };

  const paymentStepPercentage =
    props.currentStep === CheckoutConstants.STEP_PAYMENT ? props.currentStepPercentage * 100 : 100;
  const customerDetailsStepPercentage =
    props.currentStep === CheckoutConstants.STEP_CUSTOMER_DETAILS
      ? props.currentStepPercentage * 100
      : 0;

  const initUI = (stopTransition) => {
    setActiveStepLabel(stopTransition);
    setProgressBars(stopTransition);
  };

  useEffect(() => {
    initUI(false);
    window.addEventListener('resize', () => initUI(true));
  });

  return (
    <div className="checkout-progress">
      <div className="checkout-progress-step-activeLabel" />
      <PaymentStepButton {...props} />
      <StepProgressBar percent={paymentStepPercentage} />
      <CustomerDetailsStepButton {...props} />
      <StepProgressBar
        percent={
          props.currentStep !== CheckoutConstants.STEP_PAYMENT &&
          props.currentStep !== CheckoutConstants.STEP_CUSTOMER_DETAILS
            ? 100
            : customerDetailsStepPercentage
        }
      />
      <ConfirmationStepButton {...props} />
    </div>
  );
};

export default ProgressTracker;
