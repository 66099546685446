import React from 'react';

import FooterButton from './FooterButton';

const Footer = () => (
  <footer className="integrationsPortal-footer">
    <h3 className="integrationsPortal-footer-title">Need help?</h3>
    <p className="integrationsPortal-footer-desc">
      If you need help with your integration, please contact us at{' '}
      <a href="mailto:developers@escrow.com" className="integrationsPortal-footer-link">
        developers@escrow.com
      </a>{' '}
      and we are more than happy to help.
    </p>
    <div className="integrationsPortal-footer-nav">
      <FooterButton link={{ type: 'internal', route: '/api/docs', newTab: true }}>
        View Documentation
      </FooterButton>
      <FooterButton link={{ type: 'app', route: '/integrations/portal/faqs' }}>
        Frequently Asked Questions
      </FooterButton>
    </div>
  </footer>
);

export default Footer;
