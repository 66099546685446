import { fetchTokenCustomerDetails as fetchTokenCustomerDetailsRoutine } from 'spa/actions/LandingPageTokenActions';
const initialState = {
  token: null,
  customer: null,
  returnUrl: null,
};

function landingPageTokenReducer(state = initialState, action) {
  switch (action.type) {
    case fetchTokenCustomerDetailsRoutine.SUCCESS:
      return {
        ...state,
        customer: action.payload.customer,
        returnUrl: action.payload.returnUrl.return_url,
      };
    default:
      return state;
  }
}

export default landingPageTokenReducer;
