import React from 'react';
import { Modal } from 'escrow-common-js/dist/components';
import ReactSVG from 'react-svg';

const CoverModal = ({ display = true, onClose, onNext }) => (
  <Modal
    isOpen={display}
    paddingSize=""
    targetElementId="spa"
    exitButton={false}
    enableBackdropClick
    onBackdropClick={onClose}
    cardClassName="transactions-modal-fullscreen-card"
  >
    <div className="transactions-modal-fullscreen-content">
      <ReactSVG
        className="transactions-modal-fullscreen--svg"
        src="../build/images/global/icons/escrow-shield.svg"
        beforeInjection={(svg) => {
          svg.classList.add('transactions-modal-fullscreen--svg--size');
        }}
      />
      <div className="transactions-modal-fullscreen--title">Welcome to Escrow.com!</div>
      <div className="transactions-modal-fullscreen--description">
        Let’s walk you through your updated transactions page. This should take less than a minute.
      </div>
      <button
        className="btn btn--secondary"
        onClick={(e) => {
          e.stopPropagation();
          onNext();
        }}
      >
        {"Let's get started"}
      </button>
      <a
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
        role="button"
        tabIndex="0"
      >
        Skip
      </a>
    </div>
  </Modal>
);

export default CoverModal;
