var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/icons/upload.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
["class"], 
[], 
function (l_class, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("class", l_class);
var t_2 = "";t_2 += "<svg width=\"54\" height=\"54\" viewBox=\"0 0 54 54\" xmlns=\"http://www.w3.org/2000/svg\" class=\"";
t_2 += runtime.suppressValue(l_class, env.opts.autoescape);
t_2 += "\"><title>Upload Icon</title><g fill-rule=\"evenodd\"><path d=\"M35 .35c-7.624 0-14.288 4.54-17.168 11.37-1.336-.45-2.744-.68-4.165-.68C6.315 11.04.333 17.037.333 24.407c0 6.452 4.59 11.854 10.667 13.092V31.93c-3.1-1.106-5.333-4.047-5.333-7.524 0-4.42 3.59-8.02 8-8.02 1.52 0 3.016.444 4.314 1.284.735.476 1.647.558 2.447.232.808-.326 1.4-1.026 1.6-1.87C23.432 9.944 28.767 5.694 35 5.694c7.352 0 13.333 5.995 13.333 13.365 0 4.35-2.114 8.182-5.333 10.625v6.255c6.296-3.01 10.667-9.433 10.667-16.878C53.667 8.745 45.29.35 35 .35z\"/><path d=\"M39.552 33.208L27 20.626 14.448 33.208l3.77 3.78 6.115-6.13v22.95h5.334V30.86l6.114 6.13\"/></g></svg>";
;
frame = frame.pop();
return new runtime.SafeString(t_2);
});
context.addExport("upload");
context.setVariable("upload", macro_t_1);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/icons/upload.html"] , dependencies)