import _ from 'lodash';
import validator from 'validator';
import moment from 'moment';
import libphonenumber from 'google-libphonenumber';
import cardValidator from 'card-validator';
import { Currency } from 'escrow-common-js/dist/constants';
import { Alpha3ToAlpha2, Alpha2ToCountry, CountryToAlpha2 } from 'spa/constants/ISOCountryCodes';
import DisbursementConstants from 'spa/constants/DisbursementConstants';
import TransactionConstants from 'spa/constants/TransactionConstants';
import { getPhoneCountry } from 'spa/components/StartTransaction/util';
import API from '../../../api/API';
import signupFormConstants from '../../../constants/SignupFormConstants';
import { gettext } from '../../../utils/filters';
import config from '../../../utils/config';

/**
 * Validators for redux-form which take a value and return
 * undefined for no errors, or a string/list of errors
 * */

export const maxChars = (max, label) => (value) =>
  !value || value.length <= max ? undefined : `${label} must not exceed ${max} characters`;

export const validateIban = (value, allValues) => {
  if (DisbursementConstants.ibanRegexByCountry[allValues.country]) {
    return !value ||
      DisbursementConstants.ibanRegexByCountry[allValues.country].test(value.replace(/\s+/g, ''))
      ? undefined
      : `Should satisfy the IBAN format used in ${Alpha2ToCountry[allValues.country]}`;
  }
  return !value || value.replace(/\s+/g, '').length <= 40
    ? undefined
    : `IBAN must be a maximum of 40 characters`;
};

export const validateSwift = (countrySelector) => (value, allValues) => {
  if (!value) {
    return undefined;
  }

  let swiftRegex;
  const country = countrySelector(allValues);
  if (value.length === 8) {
    swiftRegex = new RegExp(/^[a-zA-Z]{6}[0-9a-zA-Z]{2}$/);
  } else if (value.length === 11) {
    swiftRegex = new RegExp(/^[a-zA-Z]{6}[0-9a-zA-Z]{5}$/);
  } else {
    return 'Must be either 8 or 11 characters long';
  }

  if (swiftRegex.test(value)) {
    const countryCode = value.substring(4, 6).toUpperCase();
    if (Alpha2ToCountry[countryCode]) {
      return country === countryCode
        ? undefined
        : `Country code ${countryCode} in SWIFT code does not match your selected country's code ${country}`;
    }
    return `Country code ${countryCode} in SWIFT code is invalid`;
  }
  return 'Invalid format';
};

export const validateABA = (value) => {
  const abaRegEx = new RegExp(/^[0-3]\d{8}$/);
  if (value.length !== 9) return 'ABA Routing number must be exactly 9 characters in length';
  if (!validator.isNumeric(value, true))
    return 'ABA Routing number must be numeric with no additional spaces of punctuation';
  if (!abaRegEx.test(value)) return 'ABA Routing number must start with either 0, 1, 2, or 3.';
};

export const minChars = (min, label) => (value) =>
  !value || value.length >= min ? undefined : `${label} must be a minimum of ${min} characters`;

export const exactLength = (length, label) => (value) =>
  !value || value.length === length
    ? undefined
    : `${label} must be exactly ${length} characters in length`;

export const numericOnly =
  (label, allowLeadingZeroes = true) =>
  (value) =>
    !value ||
    validator.isNumeric(value, {
      allow_leading_zeroes: allowLeadingZeroes,
    })
      ? undefined
      : `${label}${gettext(' must be numeric with no additional spaces or punctuation.')}`;

export const isDecimal = (label) => (value) =>
  !value ||
  validator.isDecimal(value, {
    decimal_digits: '1,2',
  })
    ? undefined
    : `Please provide a valid ${label}`;

export const required = (value) => (value ? undefined : gettext('Required'));

export const noStartTrailWhitespace = (value) => {
  const whitespaceRegex = new RegExp(/(^\s)|(\s$)/);
  return !whitespaceRegex.test(value) ? undefined : 'Invalid characters at the start/end';
};

export const isValidName = (value) => {
  if (!value) return undefined;
  const filteredValue = String(value).replace(' ', '').replace('-', '');
  let validatorResult;
  try {
    validatorResult = validator.isAlpha(filteredValue, config.locale);
  } catch (err) {
    validatorResult = validator.isAlpha(filteredValue, 'en-US');
  }
  return validatorResult ? undefined : 'Invalid characters';
};

// whilst the backend does more complex validation, on the frontend, we will
// just validate the scheme and no spaces or quotations
export const urlSchemeRegex = new RegExp(/^(http|https):\/\//);

export const urlScheme = (value) =>
  !value || urlSchemeRegex.test(value)
    ? undefined
    : 'Missing scheme (url must start with http://|https://)';

export const urlFormatRegex = new RegExp(/[ "]/);

export const urlFormat = (value) =>
  !value || !urlFormatRegex.test(value) ? undefined : 'Cannot contain ␣ (space) or "';

export const upperCase = (value) =>
  !value || value.toUpperCase() === value ? undefined : 'Must contain only upper case letters';

// inclusive check
export const lengthRange = (min, max) => (value) =>
  !value || (value.length >= min && value.length <= max)
    ? undefined
    : `Must be between ${min} and ${max} characters`;

export const multipleLengths =
  (validLengths = []) =>
  (value) =>
    !value || validLengths.includes(value.length)
      ? undefined
      : `Must have a length which is any of the following : ${JSON.stringify(validLengths)}`;

function emptyListToUndefined(list) {
  if (list.length === 0) {
    return undefined;
  }
  return list;
}

// validator wrapper
export const email = (value) =>
  !value || validator.isEmail(value) ? undefined : gettext('Please enter a valid email address.');

export const passwordLength = (value) =>
  !value || signupFormConstants.PASSWORD_SUGGESTIONS[0].test(value)
    ? undefined
    : signupFormConstants.PASSWORD_SUGGESTIONS[0].label;

export const passwordUpperLowerCase = (value) =>
  !value || signupFormConstants.PASSWORD_SUGGESTIONS[1].test(value)
    ? undefined
    : signupFormConstants.PASSWORD_SUGGESTIONS[1].label;

export const passwordNumberSpecialCharacter = (value) =>
  !value || signupFormConstants.PASSWORD_SUGGESTIONS[2].test(value)
    ? undefined
    : signupFormConstants.PASSWORD_SUGGESTIONS[2].label;

export const minMaxInt =
  (min, max, allowLeadingZeroes = true) =>
  (value) =>
    !value ||
    validator.isInt(value, {
      min: min,
      max: max,
      allow_leading_zeroes: allowLeadingZeroes,
    })
      ? undefined
      : `Must be an integer between ${min} and ${max}`;

export const phone =
  (...pathToCountryField) =>
  (value, allValues) => {
    let country;
    if (!value) return undefined;
    const phUtil = libphonenumber.PhoneNumberUtil.getInstance();
    try {
      country = _.get(allValues, pathToCountryField);
      const region = Alpha3ToAlpha2[country] || 'ZZ';
      const phoneNumber = phUtil.parseAndKeepRawInput(value, region);
      if (phUtil.isValidNumber(phoneNumber) && value === phoneNumber.getRawInput()) {
        return undefined;
      }
    } catch (error) {
      return 'Must be a valid phone number';
    }
    return 'Must be a valid phone number';
  };

export const date = (value) => {
  if (!value) return undefined;

  // Check if the date string contains invalid characters
  if (isNaN(Date.parse(value))) {
    return 'Please enter a valid date';
  }

  // Check if leap year and the date actually exists
  const d = moment(value, 'YYYY-MM-DD');
  if (d === null || !d.isValid()) {
    return 'Please enter a valid date';
  }

  return undefined;
};

export const age18 = (value) => {
  if (!value) return undefined;
  if (isNaN(Date.parse(value))) return undefined;

  const dob = new Date(value);
  const now = new Date();
  const diff = new Date(now.getTime() - dob.getTime()).getUTCFullYear() - 1970;
  if (diff < 18) {
    return 'You must be over 18 to use our service';
  }

  return undefined;
};

export const cardSecurityCode = (value, { cardNumber }) => {
  if (!value || !cardNumber) {
    return undefined;
  }

  const cardValid = cardValidator.number(cardNumber);

  if (!cardValid.isValid) {
    return 'Please enter a valid card number first.';
  }

  if (cardValid.card.code.size !== value.length) {
    return `The security code should be ${cardValid.card.code.size} numbers for this card type`;
  }
};

export const cardNumber = (value) => {
  if (!value) {
    return undefined;
  }

  const cardValid = cardValidator.number(value);
  if (!cardValid.card) {
    return 'Please enter a valid card number.';
  }

  if (!cardValid.isPotentiallyValid) {
    return 'Please enter a valid card number.';
  }

  if (!cardValid.isValid) {
    return 'Please enter a valid card number.';
  }

  if (!['american-express', 'master-card', 'visa'].includes(cardValid.card.type)) {
    return `${cardValid.card.niceType} is currently not supported. Please enter a valid card number.`;
  }
};

export const cardNumberBrand =
  (currency = null) =>
  (value) => {
    const cardValid = cardValidator.number(value);
    if (!cardValid.card) {
      return 'Please enter a valid card number.';
    }
    if (currency && currency === Currency.CAD && cardValid.card.type === 'american-express') {
      return 'American Express Credit Cards are not supported with CAD transactions';
    }
  };

export const cardExpiryDate = (value, { expirationMonth, expirationYear }) => {
  if (!expirationMonth && !expirationYear) {
    return undefined;
  }

  const dateToday = new Date();
  const minExpDate = new Date(new Date(dateToday).setMonth(dateToday.getMonth() + 1));
  const inputDate = new Date(expirationYear, expirationMonth);

  if (inputDate < minExpDate) {
    return 'Please enter a valid expiration date';
  }
};

// Compose takes in multiple validation functions and returns an array of triggered errors
export const compose =
  (validators = []) =>
  (value) =>
    emptyListToUndefined(validators.map((fn) => fn(value)).filter((v) => Boolean(v)));

// Recommended use with react-final-form to display only 1 validation error at a time
// Order of passed in validators matters here, since the first encountered failed validation gets returned
export const composeValidators =
  (validators = []) =>
  (value) =>
    validators.reduce((error, fn) => error || fn(value), undefined);

export const validateBankAccountNumber = (value, allValues) => {
  const maxLength = allValues.country === CountryToAlpha2['New Zealand'] ? 13 : 30;
  const rules = [lengthRange(3, maxLength)];
  return compose(rules)(value);
};
export const strRequired = (value) =>
  !required(value) && value.trim().length > 0 ? undefined : gettext('Required');
export const intValidate = (value) =>
  required(value) || (validator.isInt(value, { min: 0 }) ? undefined : 'Invalid Input');
export const priceValidate = (value) =>
  required(value) ||
  (value.match(/^((([1-9]\d*)|0)(\.\d{1,2})?)$/) && parseFloat(value) > 0
    ? undefined
    : 'Invalid Price');
export const vehicleYearValidate = (value) =>
  strRequired(value) ||
  (value.length !== 4 ? 'Year should have exact 4 digits' : undefined) ||
  (validator.isInt(String(value), { min: 1886, max: moment().year() })
    ? undefined
    : 'Invalid Year');
export const vinValidate = (value) =>
  !value
    ? undefined
    : strRequired(value) ||
      maxChars(17, 'VIN')(value) ||
      (value.match(/^[a-zA-Z0-9]{11,17}$/)
        ? undefined
        : 'Invalid VIN, should be 11 to 17 alphanumeric characters.');
export const odometerValidate = (value) =>
  !value
    ? undefined
    : strRequired(value) ||
      maxChars(10, 'Odometer')(value) ||
      (validator.isInt(String(value), { min: 0, max: 9999999999 })
        ? undefined
        : 'Invalid Odometer');
export const vehicleMakeValidate = (value) =>
  strRequired(value) ||
  maxChars(50, 'Make')(value) ||
  (value.match(/[0-9]/) ? 'Invalid Make' : undefined);
export const DNHTermValidate = (value, otherValues, otherFieldName) => {
  if (!value && !otherValues[otherFieldName]) {
    return `Minimum term length is 3 months.`;
  }
  return undefined;
};
export const inspectionPeriodValidate = (value) =>
  required(value) ||
  (validator.isInt(value, { min: 1, max: 30 })
    ? undefined
    : 'Please enter a number between 1 and 30.');
export const validateItemDescription = (value, itemType) => {
  if (itemType && itemType !== TransactionConstants.TRANSACTION_TYPES.MOTOR_VEHICLE) {
    return strRequired(value);
  }
};

export const validateTransactionPartyEmails = (value, otherPartyEmail, brokerEmail) => {
  const emailInvalid = strRequired(value) || email(value);
  if (emailInvalid) {
    return emailInvalid;
  }
  const partiesShareEmail =
    otherPartyEmail && value.toLowerCase() === otherPartyEmail.toLowerCase()
      ? 'Transaction parties should have different emails.'
      : undefined;
  const brokerShallEmail =
    brokerEmail && value.toLowerCase() === brokerEmail.toLowerCase()
      ? 'Identical with broker email address.'
      : undefined;
  return partiesShareEmail || brokerShallEmail;
};

export const minDate = (min, label) => (value) =>
  min.startOf('day') <= value.startOf('day')
    ? undefined
    : `${label} cannot be before ${min.format('MM/DD/YYYY')}`;

export const maxDate = (max, label) => (value) =>
  value.startOf('day') <= max.startOf('day')
    ? undefined
    : `${label} cannot be after ${max.format('MM/DD/YYYY')}`;

export const dnhDateValidate = (min, max) => (value) =>
  required(value) ||
  date(value) ||
  minDate(min, 'First payment date')(value) ||
  maxDate(max, 'First payment date')(value);

export const validatePhone = (value, fieldIndex = 0) => {
  const countryCode = getPhoneCountry(fieldIndex);
  if (countryCode && value) {
    try {
      const phUtil = libphonenumber.PhoneNumberUtil.getInstance();
      const number = phUtil.parseAndKeepRawInput(
        value && value[0] === '+' ? value : `+${value}`,
        countryCode.toUpperCase()
      );
      if (!phUtil.isValidNumber(number)) {
        return 'Invalid phone number.';
      }
    } catch (error) {
      return 'Invalid phone number.';
    }
  }
};

export const phoneValidate =
  (fieldIndex, requiredField = false) =>
  (value) => {
    if (requiredField && !value) {
      return 'Required';
    }
    return validatePhone(value, fieldIndex);
  };

// for use in the custom FileUpload component in redux-form FieldArray
export const oneOrMoreFiles = (value) => {
  if (value && value.length > 0) {
    return undefined;
  }
  return gettext('At least 1 file is required.');
};

export const validateVATNumber = async (value, allValues) => {
  if (!value) {
    return undefined;
  }
  try {
    await API.postTaxNumber('vat', `${allValues['vat-country-code']}${value}`, true);
    return undefined;
  } catch (error) {
    if (allValues['vat-country-code'] === 'GB') {
      return value.length === 9 && validator.isInt(value)
        ? undefined
        : 'VAT Number must be exactly 9 digits in length';
    }
    return 'Invalid VAT information';
  }
};

export default {
  email,
  passwordLength,
  passwordUpperLowerCase,
  passwordNumberSpecialCharacter,
  minMaxInt,
  phone,
  maxChars,
  required,
  noStartTrailWhitespace,
  urlSchemeRegex,
  urlScheme,
  urlFormatRegex,
  urlFormat,
  upperCase,
  lengthRange,
  compose,
  numericOnly,
  cardNumber,
  cardSecurityCode,
  cardExpiryDate,
  minDate,
  maxDate,
  dnhDateValidate,
  oneOrMoreFiles,
  validateVATNumber,
};
