var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/icons/phone.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
[], 
["specifier"], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
var t_2 = "";t_2 += "<svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n     viewbox=\"0 0 20.1 20\" enable-background=\"new 0 0 20.1 20\" xml:space=\"preserve\" class=\"icon icon--phone ";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "specifier"), env.opts.autoescape);
t_2 += "\">\n    <path d=\"M14.8,20c-0.5,0-1-0.1-1.5-0.3C7.2,17.6,2.3,12.6,0.3,6.5c-0.6-1.9,0-3.9,1.6-5.1l1-0.8C3.5,0.2,4.1,0,4.8,0\n    C6,0,7,0.7,7.6,1.7l1.7,3.2c0.2,0.3,0.2,0.7,0.2,1c0,0.5-0.2,1.1-0.6,1.5L8.6,7.8C8.2,8.2,8.2,8.7,8.4,9.2c0.6,1.1,1.5,2,2.6,2.6\n    c0.4,0.2,1,0.2,1.4-0.2l0.4-0.3c0.7-0.6,1.7-0.7,2.5-0.3l3.2,1.7c1,0.5,1.7,1.6,1.7,2.8c0,0.8-0.3,1.6-0.9,2.2l-1.1,1.1\n    C17.2,19.5,16,20,14.8,20z M4.8,1.9C4.5,1.9,4.3,2,4.1,2.1l-1,0.8c-0.9,0.7-1.3,1.9-0.9,3C3.9,11.5,8.4,16,13.9,18\n    c1,0.3,2.1,0.1,2.8-0.7l1.1-1.1c0.2-0.2,0.4-0.5,0.4-0.9c0-0.5-0.3-0.9-0.7-1.1l-3.2-1.7c-0.1-0.1-0.3,0-0.4,0l-0.4,0.3\n    c-0.9,0.9-2.4,1.1-3.5,0.5c-1.5-0.7-2.6-1.9-3.4-3.4C6.1,8.9,6.3,7.5,7.2,6.5l0.3-0.4c0,0,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.1L5.9,2.6\n    C5.7,2.1,5.3,1.9,4.8,1.9z\"/>\n</svg>";
;
frame = frame.pop();
return new runtime.SafeString(t_2);
});
context.addExport("phone");
context.setVariable("phone", macro_t_1);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/icons/phone.html"] , dependencies)