import { connect } from 'react-redux';
import UserControl from 'spa/components/HeaderV3/UserControl';
import {
  custEmailSelector,
  custFullNameSelector,
  customerCompanyNameSelector,
} from '../../selectors/CustomerSelectors';
import AuthenticationStore from '../../../stores/AuthenticationStore';
import UserStore from '../../../stores/UserStore';

const mapStateToProps = (state) => ({
  custEmail: custEmailSelector(state),
  custFullName: custFullNameSelector(state),
  custCompanyName: customerCompanyNameSelector(state),
  kycActionRequired: UserStore.kycActionRequired(AuthenticationStore.getCustomerID()),
});

export default connect(mapStateToProps)(UserControl);
