import React from 'react';
import { Field } from 'redux-form';
import Icon from 'spa/components/Icon';

const RadioCard = ({ type, name, id, value, iconPath, title, desc, disabled }) => (
  <div className={`radioCard radioCard--${type}`}>
    <Field
      name={name}
      component="input"
      type="radio"
      value={value}
      className="radioCard-input is-accessibly-hidden"
      id={id}
      disabled={disabled}
    />

    <label htmlFor={id} className="radioCard-label">
      {iconPath && (
        <span className="radioCard-figure">
          <Icon name={iconPath} className="radioCard-icon" />
        </span>
      )}
      <div className="radioCard-content">
        <span className="radioCard-title">{title}</span>
        {desc && <span className="radioCard-desc">{desc}</span>}
      </div>
    </label>
  </div>
);

export default RadioCard;
