import React, { useEffect } from 'react';
import classnames from 'classnames';
import Icon from 'spa/components/Icon';
import { debounce } from 'lodash';

const SearchBarSpinner = () => (
  <div className="transactions-summary-options-search-spinner-wrapper">
    <div className="transactions-summary-options-search-spinner" />
  </div>
);

const SearchBarClearButton = ({ clearSearch, searchInput }) => (
  <div role="button" tabIndex={0} onClick={() => clearSearch()}>
    <Icon
      name="clear"
      className={classnames(['icon', 'transactions-icon', 'transactions-icon--clear'], {
        'is-disabled': !searchInput,
      })}
    />
  </div>
);

const SearchBar = ({ searchInput, status, handleChange, handleEnter, clearSearch, loading }) => {
  useEffect(() => clearSearch(), [status, clearSearch]);

  return (
    <div className="transactions-summary-options-search">
      <Icon name="magnify" className="transactions-icon--search transactions-icon icon" />
      <input
        type="text"
        className="transactions-summary-options-search-input"
        placeholder="Search transaction"
        value={searchInput}
        onChange={(event) => handleChange(event)}
        onKeyDown={(event) => handleEnter(event)}
        disabled={loading}
      />
      {loading ? (
        <SearchBarSpinner />
      ) : (
        <SearchBarClearButton clearSearch={clearSearch} searchInput={searchInput} />
      )}
    </div>
  );
};
class TransactionSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: props.currentFilters.search || '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.debouncedSearch = debounce((searchInput) => this.props.onSearch(searchInput), 1000);
  }

  /* eslint-disable react/no-did-update-set-state */
  componentDidUpdate(prevProps) {
    if (this.props.loading !== prevProps.loading && this.props.loading) {
      this.setState({
        ...this.state,
        searchInput: this.props.currentFilters.search || '',
      });
    }
  }

  handleSearch(searchInput) {
    if (!searchInput) {
      this.debouncedSearch();
      return;
    }
    if (searchInput.trim()) {
      this.debouncedSearch(searchInput.trim());
    }
  }

  handleChange(event) {
    const searchInput = event.target.value;
    this.setState({
      searchInput,
    });
    this.handleSearch(searchInput);
  }

  handleEnter(event) {
    const { searchInput } = this.state;
    if (event.key !== 'Enter') return;
    this.handleSearch(searchInput);
  }

  clearSearch() {
    if (this.props.currentFilters.search) {
      this.props.onSearch('');
    }
  }

  render() {
    return (
      <SearchBar
        searchInput={this.state.searchInput}
        status={this.props.currentFilters.status}
        handleChange={this.handleChange}
        handleEnter={this.handleEnter}
        clearSearch={this.clearSearch}
        loading={this.props.loading}
      />
    );
  }
}

export default TransactionSearch;
