import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg';
import PropTypes from 'prop-types';
import InputOtpForm from 'spa/components/TwoFactorAuthentication/InputOtpForm';
import TwoFactorConstants from 'spa/constants/TwoFactorConstants';
import { sendSMSTwoFactor as sendSMSTwoFactorRoutine } from 'spa/actions/TwoFactorActions';
import { twoFactorSmsCodeSentSelector } from 'spa/selectors/TwoFactorSelectors';
import { gettext } from '../../../utils/filters';

const getMessage = (configType) => {
  if (configType === TwoFactorConstants.TYPE_AUTH_APP) {
    return `Protecting your account is our top priority. Please confirm your account by
      entering the authentication code sent to your app.`;
  }
  return `Protecting your account is our top priority. Please confirm your account by
      entering the authorization code sent to your mobile phone.`;
};

const getHeader = (actionType) => {
  if (actionType === TwoFactorConstants.ACTION_DISABLE) {
    return 'Disable two-factor authentication';
  }
  return 'Enter two-factor authentication';
};

function InputContainer(props) {
  const { configType, actionType, callback, navigateMain, loading, codeSent, sendSMSTwoFactor } =
    props;

  useEffect(() => {
    if (!codeSent && configType === TwoFactorConstants.TYPE_SMS) {
      sendSMSTwoFactor();
    }
  }, [codeSent, configType, sendSMSTwoFactor]);

  return (
    <div className="twoFactorCard">
      <div className="twoFactorAuth-card-header">
        <ReactSVG
          beforeInjection={(svg) => {
            svg.classList.add('twoFactorAuth-page-icon');
          }}
          src="../../../../../build/images/global/icons/ui-lock.svg"
        />
        <h2 className="twoFactorAuth-card-header-title twoFactorAuth-card-header-title--auth">
          {gettext(getHeader(actionType))}
        </h2>
        <div className="twoFactorAuth-page-subheader twoFactorAuth-page-subheader--auth">
          {gettext(getMessage(configType))}
        </div>
      </div>
      <div className="twoFactorAuth-page">
        <InputOtpForm
          actionType={actionType}
          configType={configType}
          navigateMain={navigateMain}
          callback={callback}
          loading={loading}
        />
      </div>
    </div>
  );
}

InputContainer.propTypes = {
  actionType: PropTypes.string.isRequired,
  configType: PropTypes.string.isRequired,
  navigateMain: PropTypes.func,
  callback: PropTypes.func,
};

const mapStateToProps = (state) => ({
  codeSent: twoFactorSmsCodeSentSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  sendSMSTwoFactor: () => dispatch(sendSMSTwoFactorRoutine.trigger()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InputContainer);
