import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { updateTaskRequest } from 'spa/actions/TaskActions';

import { ChannelCards } from 'spa/components/ChannelCards';
import { Section, SectionDesc } from 'spa/components/Section';

import { urlFor } from '../../../routeConfig';

class ButtonActions extends React.PureComponent {
  render() {
    const { handleClick } = this.props;

    const channelCards = [
      {
        onClick: handleClick(urlFor('integrations_api_getstarted'), 'start_api_integration', 'api'),
        title: 'API',
        iconPath: 'integrationPortal/api-icon',
        description:
          'Integrate our modern Escrow API into your marketplace or website and start providing your customers with a truly secure way to pay.',
        cta: 'Set up API',
        key: 'setup_api',
      },
      {
        onClick: handleClick(
          urlFor('integrations_buttons_getstarted'),
          'start_buttons_integration',
          'buttons'
        ),
        title: 'Buttons',
        iconPath: 'integrationPortal/button-icon',
        description:
          'Listing items for sale on a website? Paste a short code snippet to your website to allow anyone to pay you securely via Escrow.com.',
        cta: 'Create Buttons',
        key: 'create_buttons',
      },
      {
        onClick: handleClick(
          urlFor('integrations_referrals_getstarted'),
          'start_referrals_integration',
          'referrals'
        ),
        title: 'Referral Links',
        iconPath: 'integrationPortal/referral-link-icon',
        description:
          'Versatile link or banner you can use on your website to direct traffic to Escrow.com and allow your users to set up their own transactions.',
        cta: 'Create Links',
        key: 'create_links',
      },
    ];

    return (
      <Section>
        <SectionDesc>
          Welcome to the Escrow.com integration portal, where you can learn about integration
          options, set up a new integration or manage your existing integration.
        </SectionDesc>
        <ChannelCards channelCards={channelCards} />
      </Section>
    );
  }
}

ButtonActions.propTypes = {
  handleClick: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  handleClick: (route, task, list) => () => {
    dispatch(updateTaskRequest(task, 'complete', list));
    dispatch(push(route));
  },
});

const ButtonActionsContainer = connect(null, mapDispatchToProps)(ButtonActions);

export default ButtonActionsContainer;
