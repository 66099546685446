import Errors from 'spa/constants/APIConstants';

class APIError {
  constructor(errors, requestId, errorCode = null) {
    this.type = Errors.API_ERROR;
    this.errors = errors;
    this.errorCode = errorCode;
    this.requestId = requestId;
  }
}

export class Unauthorized extends APIError {
  constructor(errors, requestId) {
    super(errors, requestId);
    this.type = Errors.UNAUTHORIZED;
  }
}

export class UnprocessableEntity extends APIError {
  constructor(errors, requestId) {
    super(errors, requestId);
    this.type = Errors.UNPROCESSABLE_ENTITY;
  }
}

export class NotFound extends APIError {
  constructor(errors, requestId) {
    super(errors, requestId);
    this.type = Errors.NOT_FOUND;
  }
}

export class BadRequest extends APIError {
  constructor(errors, requestId) {
    super(errors, requestId);
    this.type = Errors.BAD_REQUEST;
  }
}

export class Forbidden extends APIError {
  constructor(errors, requestId, errorCode = null) {
    super(errors, requestId, errorCode);
    this.type = Errors.FORBIDDEN;
  }
}

export class NotAcceptable extends APIError {
  constructor(errors, requestId) {
    super(errors, requestId);
    this.type = Errors.NOT_ACCEPTABLE;
  }
}

export class Gone extends APIError {
  constructor(errors, requestId) {
    super(errors, requestId);
    this.type = Errors.GONE;
  }
}

export class InternalServerError extends APIError {
  constructor(errors, requestId) {
    super(errors, requestId);
    this.type = Errors.INTERNAL_SERVER_ERROR;
    if (!this.errors) {
      this.errors = {};
    }
    this.errors.error = `${this.errors.error || 'Internal Server Error.'}
      To help us assist you, please quote reference id: ${this.requestId}`;
  }
}

export class Unhandled extends APIError {
  constructor(errors, requestId) {
    super(errors, requestId);
    this.type = Errors.UNHANDLED;
    if (!this.errors) {
      this.errors = {};
    }
    this.errors.error = `${this.errors.error || 'Unknown Error.'}
      To help us assist you, please quote reference id: ${this.requestId}`;
  }
}
