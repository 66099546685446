import classnames from 'classnames';
import moment from 'moment';
import React from 'react';

import OfferConstants from 'spa/constants/OfferConstants';
import CounterOfferForm from 'spa/components/Offer/CounterOfferForm';
import OfferControlPanel from 'spa/containers/Offer/OfferControlPanel';
import { getCurrencyString } from 'escrow-common-js/dist/utils';
import { getTimePeriodShort } from '../../../utils/date';
import { gettext } from '../../../utils/filters';

class OfferItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCounterOfferForm: false,
    };
    this.handleMakeCounterOfferToggle = this.handleMakeCounterOfferToggle.bind(this);
  }

  handleMakeCounterOfferToggle(expectedState) {
    // Toggle if expected state is not set
    if (!expectedState) {
      this.setState({
        ...this.state,
        showCounterOfferForm: !this.state.showCounterOfferForm,
      });
    } else {
      this.setState({
        ...this.state,
        showCounterOfferForm: expectedState.showCounterOfferForm,
      });
    }
  }

  handleGetOfferParty(offer) {
    let offerParty;

    if (offer.offerTransaction) {
      offerParty = offer.offerTransaction.parties.find((party) => party.id === offer.byParty.id);
      if (offerParty) return offerParty.customer;
    }

    return offer.byParty.customer;
  }

  renderCounterOfferForm(isOwnOffer) {
    const { currency, offer, role } = this.props;
    const label = isOwnOffer ? gettext('Your offer') : gettext('Your counteroffer');
    return (
      <CounterOfferForm
        form={`${OfferConstants.CREATE_COUNTER_OFFER_FORM}-${offer.id}`}
        label={label}
        role={role}
        currency={currency}
        transactionId={offer.transactionId}
        toggleFormAction={this.handleMakeCounterOfferToggle}
      />
    );
  }

  renderBaseOfferCard() {
    const { actions, auctionId, control, currency, offer, viewerEmail } = this.props;
    const offerOwner = this.handleGetOfferParty(offer);
    const counterOffer = viewerEmail === offerOwner;
    const message = offer.note;
    const showCounterOfferForm = this.state.showCounterOfferForm;
    const expiryMs = offer.dateCreated.getTime() + offer.expiryPeriod * 1000 - Date.now();
    const expired = expiryMs < 0;
    const expiry = expired ? gettext('Expired') : getTimePeriodShort(expiryMs);
    return (
      <div className={classnames('offer-card card', { 'offer-card--invert': counterOffer })}>
        <div className="card-inner">
          <header className="offer-card-header">
            <span className="offer-card-name">
              {counterOffer ? (
                <span>{gettext('You made an offer')}</span>
              ) : (
                <span>
                  {offer.byParty.displayName || offer.byParty.customer} {gettext('made an offer')}
                </span>
              )}
            </span>
            <span className="offer-card-date">
              {moment(offer.dateCreated).format('MMM DD YYYY [at] HH:mm:ss')}
            </span>
          </header>
          <div className="grid">
            <div className={`grid-col grid-col--tablet-${control ? '8' : '4'}`}>
              <div className="offer-detail">
                <span className="offer-detail-label">{gettext('Offer amount')}</span>
                <span className="offer-detail-value">
                  {getCurrencyString(offer.noFeeAmount, currency)}
                </span>
              </div>
              {expiry && control && (
                <div className="offer-detail">
                  <span className="offer-detail-label">{gettext('Expiry')}</span>
                  <span className="offer-detail-value">{expiry}</span>
                </div>
              )}
              {message && control && (
                <div className="offer-detail">
                  <span className="offer-detail-label">{gettext('Message')}</span>
                  <span className="offer-detail-value offer-detail-value--small">{message}</span>
                </div>
              )}
            </div>
            <div className={`grid-col grid-col--tablet-${control ? '4' : '8'}`}>
              {message && !control && (
                <div className="offer-detail">
                  <span className="offer-detail-label">{gettext('Message')}</span>
                  <span className="offer-detail-value offer-detail-value--small">{message}</span>
                </div>
              )}
              {control && (
                <OfferControlPanel
                  actions={{
                    accept: actions.accept,
                    reject: actions.reject,
                    cancel: actions.cancel,
                    toggleCounterOffer: this.handleMakeCounterOfferToggle,
                  }}
                  auctionId={auctionId}
                  counterOffer={counterOffer}
                  offerOwner={offerOwner}
                  offer={offer}
                />
              )}
            </div>
          </div>
          {showCounterOfferForm && (
            <div className="offer-form">{this.renderCounterOfferForm(counterOffer)}</div>
          )}
        </div>
      </div>
    );
  }

  renderStatusCardDetail(offer, isCounterOffer) {
    const offerOwner = offer.byParty.displayName || offer.byParty.customer;
    if (!offer.status) {
      return null;
    } else if (offer.status.accepted && isCounterOffer) {
      return {
        title: gettext('Your offer was accepted'),
        inverted: false,
      };
    } else if (offer.status.accepted && !isCounterOffer) {
      return {
        title: gettext('You accepted an offer'),
        inverted: true,
      };
    } else if (offer.status.rejected && isCounterOffer) {
      return {
        title: gettext('Your offer was rejected'),
        inverted: false,
      };
    } else if (offer.status.rejected && !isCounterOffer) {
      return {
        title: gettext('You rejected an offer'),
        inverted: true,
      };
    } else if (offer.status.canceled && isCounterOffer) {
      return {
        title: gettext('You canceled the offer'),
        inverted: true,
      };
    } else if (offer.status.canceled && !isCounterOffer) {
      return {
        title: gettext('%s canceled the offer', offerOwner),
        inverted: false,
      };
    }
    return null;
  }

  renderStatusCard() {
    const { actions, currency, offer, viewerEmail, isLatestOffer, auctionId } = this.props;
    const control = isLatestOffer;
    const offerOwner = this.handleGetOfferParty(offer);
    const counterOffer = viewerEmail === offerOwner;
    const message = offer.note;
    const detail = this.renderStatusCardDetail(offer, counterOffer);

    if (!isLatestOffer || !detail) {
      return null;
    }

    return (
      <div className={classnames('offer-card card', { 'offer-card--invert': detail.inverted })}>
        <div className="card-inner">
          <header className="offer-card-header">
            <span className="offer-card-name">
              <span>{detail.title}</span>
            </span>
            <span className="offer-card-date">
              {moment(offer.dateClosed).format('MMM DD YYYY [at] HH:mm:ss')}
            </span>
          </header>
          <div className="grid">
            <div className={`grid-col grid-col--tablet-${control ? '8' : '4'}`}>
              <div className="offer-detail">
                <span className="offer-detail-label">{gettext('Offer amount')}</span>
                <span className="offer-detail-value">
                  {getCurrencyString(offer.noFeeAmount, currency)}
                </span>
              </div>
              {message && control && (
                <div className="offer-detail">
                  <span className="offer-detail-label">{gettext('Message')}</span>
                  <span className="offer-detail-value offer-detail-value--small">{message}</span>
                </div>
              )}
            </div>
            <div className={`grid-col grid-col--tablet-${control ? '4' : '8'}`}>
              {message && !control && (
                <div className="offer-detail">
                  <span className="offer-detail-label">{gettext('Message')}</span>
                  <span className="offer-detail-value offer-detail-value--small">{message}</span>
                </div>
              )}
              {control && (
                <OfferControlPanel
                  actions={{
                    pay: actions.pay,
                    view: actions.view,
                  }}
                  auctionId={auctionId}
                  counterOffer={counterOffer}
                  offerOwner={offerOwner}
                  offer={offer}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderStatusCard()}
        {this.renderBaseOfferCard()}
      </div>
    );
  }
}

export default OfferItem;
