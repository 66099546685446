import localStorage from 'local-storage';

export default function getIsExistingUser() {
  let isExistingUser = false;
  try {
    isExistingUser = localStorage.get('isExistingUser');
  } catch (err) {
    isExistingUser = false;
  }
  return Boolean(isExistingUser);
}
