import React from 'react';

const TabFilter = (props) => (
  <a
    href="#"
    className={`contentNav-tab contentNav-tab--small${props.active ? ` is-active-tab` : ''}`}
    onClick={props.handleClick}
  >
    {props.label}
  </a>
);

export default TabFilter;
