var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/icons/ui-exit.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
[], 
["classes"], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("classes", kwargs.hasOwnProperty("classes") ? kwargs["classes"] : "");
var t_2 = "";t_2 += "\n<svg class=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "classes"), env.opts.autoescape);
t_2 += "\" width=\"16\" height=\"16\" viewBox=\"1 1 24 24\" xmlns=\"http://www.w3.org/2000/svg\"><g fill-rule=\"evenodd\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/><path d=\"m22,7.3l-7.7,7.7l-1.3,-1.3l7.7,-7.7l-3.7,0l0,-2l7,0l0,7l-2,0l0,-3.7zm-8,-3.3l0,2l-5.8,0c-1.2,0 -2.2,1 -2.2,2.2l0,11.6c0,1.2 1,2.2 2.2,2.2l11.6,0c1.2,0 2.2,-1 2.2,-2.2l0,-5.8l2,0l0,5.7c0,2.4 -2,4.3 -4.3,4.3l-11.4,0c-2.4,0 -4.3,-2 -4.3,-4.3l0,-11.4c0,-2.4 2,-4.3 4.3,-4.3l5.7,0z\"/></g></svg>\n";
;
frame = frame.pop();
return new runtime.SafeString(t_2);
});
context.addExport("exit");
context.setVariable("exit", macro_t_1);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/icons/ui-exit.html"] , dependencies)