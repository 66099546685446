import React from 'react';
import IconButton from '@mui/material/IconButton';
import RestoreIcon from '@mui/icons-material/Restore';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

import { MediumText } from '../../../ui/EscrowTypography';

import TransactionHistoryList from './TransactionHistoryList';
import TransactionStepper from '../TransactionStepper';

const TransactionHistoryModal = ({ history, transactionSteps, handleClose, isOpen }) => (
  <Box
    sx={{
      minHeight: '100%',
      minWidth: '100vw',
      backgroundColor: '#FFFFFF',
      position: 'absolute',
      zIndex: '98', //  1 lower than the header
      display: isOpen ? 'block' : 'none',
      left: 0,
      right: 0,
      padding: '16px',
    }}
  >
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        gap: '8px',
        marginBottom: '12px',
        alignItems: 'center',
      }}
    >
      <RestoreIcon sx={{ color: 'secondaryLight.dark', height: 20, width: 20 }} />
      <MediumText>History</MediumText>
      <IconButton
        sx={{ marginLeft: 'auto' }}
        onClick={handleClose}
        aria-label="Close History Modal"
      >
        <CloseIcon sx={{ color: 'mono.dark', height: 24, width: 24 }} />
      </IconButton>
    </Box>
    {transactionSteps && <TransactionStepper steps={transactionSteps} variant={'detailsPage'} />}
    <TransactionHistoryList history={history} testidPrefix="modal-" />
  </Box>
);

export default TransactionHistoryModal;
