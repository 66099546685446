import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment';
import CustomerTable from 'spa/components/PartnerDashboard/tables/EnhancedTable';
import {
  customersCursorSelector,
  customersDataSelector,
  customersErrorSelector,
  customerListPageUiSelector,
  customerListPageFilterValueIdsSelector,
  customerListPageUserCountSelector,
  customerTableFilterValueSelector,
  customersListLoadingSelector,
} from 'spa/selectors/PartnerSelectors';
import { updateCustomerTableFilter as updateCustomerTableFilterAction } from 'spa/actions/PartnerActions';
import { apiVerificationStatuses } from 'spa/constants/VerificationConstants';
import { Alpha2ToCountry } from 'spa/constants/ISOCountryCodes';
import { applyFilters } from '../../../utils/PartnerDashboard';
import { urlFor } from '../../../routeConfig';

const columns = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'dateOfBirth', numeric: false, disablePadding: false, label: 'Date of Birth' },
  { id: 'country', numeric: false, disablePadding: false, label: 'Country' },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email Address' },
  { id: 'contactNumber', numeric: false, disablePadding: false, label: 'Contact Number' },
  { id: 'kycStatus', numeric: false, disablePadding: false, label: 'KYC Status' },
];

const mapDataToTable = (customers) => {
  let data = [];
  if (customers) {
    data = customers.map((customer) => {
      const country = customer.address ? Alpha2ToCountry[customer.address.country] : 'N/A';
      let kycStatus = 'Not Verified';
      if (customer.verification.company.status === apiVerificationStatuses.VERIFIED) {
        kycStatus = 'Company Verified';
      } else if (customer.verification.personal.status === apiVerificationStatuses.VERIFIED) {
        kycStatus = 'Personal Verified';
      }

      return {
        id: customer.id,
        name: customer.display_name || 'N/A',
        dateOfBirth: customer.date_of_birth
          ? moment(customer.date_of_birth).format('YYYY-MM-DD')
          : 'N/A',
        country: country || 'N/A',
        email: customer.email,
        contactNumber: customer.phone_number || 'N/A',
        kycStatus,
      };
    });
  }
  return data;
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  columns,
  error: customersErrorSelector(state) || customerListPageUiSelector(state).error,
  entries: applyFilters(
    state,
    ownProps.filters,
    customersDataSelector,
    customerListPageFilterValueIdsSelector
  ),
  entryCountTotal: customerListPageUserCountSelector(state),
  nextCursor: customersCursorSelector(state),
  mapDataToTable,
  tableFilter: customerTableFilterValueSelector(state),
  loading: customersListLoadingSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  viewDetails: (customerId) =>
    dispatch(push(urlFor('partner_dashboard_users_details', { customer_id: customerId }))),
  updateTableFilter: (payload) => dispatch(updateCustomerTableFilterAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerTable);
