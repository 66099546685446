import request from 'browser-request';

import AuthenticationActions from './AuthenticationActions';
import FormActions from './FormActions';
import FormConstants from '../constants/FormConstants';
import InlineAuthConstants from '../constants/InlineAuthConstants';
import AuthenticationConstants from '../constants/AuthenticationConstants';
import ErrorMessages from '../constants/ErrorMessages';
import ModifyTransactionStore from '../stores/ModifyTransactionStore';
import AppDispatcher from '../dispatcher/AppDispatcher';

export class InlineAuthActions {
  validateEmail(formName, fieldName, fieldType, contents, override, related) {
    // This enforces distinct email addresses for buyer and seller
    // Mark as invalid if they are the same
    if (contents === related) {
      AppDispatcher.handleViewAction({
        actionType: FormConstants.FIELD_INVALID,
        attributes: {
          form: formName,
          field: fieldName,
          contents: contents,
        },
      });
    } else {
      FormActions.validate(formName, fieldName, fieldType, contents, override);
    }
  }

  submit(data, formName, inlineAuthType) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.SUBMISSION_START,
      name: formName,
    });

    data.inlineAuthType = inlineAuthType;

    // Register or login
    if (data.registered) {
      AuthenticationActions.login(data['email-address'], data.password, formName, data);
    } else if (data.password) {
      AuthenticationActions.signup(data, formName);
    } else if (formName === InlineAuthConstants.FORM_ADWORDS_LANDING) {
      AppDispatcher.handleViewAction({
        actionType: InlineAuthConstants.SIGNUP_STATES.IN_PROGRESS,
      });
      AuthenticationActions.signupShadow(
        data['email-address'],
        AuthenticationConstants.REGISTER_SOURCES.ADWORDS_LANDING,
        formName
      );
    }
  }

  agree(formName, transID, custEmail, authMethod) {
    if (ModifyTransactionStore.modified()) {
      this.agreeWithModify(
        formName,
        transID,
        custEmail,
        authMethod,
        ModifyTransactionStore.getModifiedFields()
      );
    } else {
      this.agreeWithoutModify(formName, transID, custEmail, authMethod);
    }
  }

  agreeWithoutModify(formName, transID, custEmail, authMethod) {
    request.post(
      {
        url: window.config.agree_api_endpoint,
        json: {
          TransID: transID,
          CustEmail: custEmail,
          PartnerID: 0,
        },
        contentType: 'application/json',
        withCredentials: true,
      },
      this.handleAgreeResponse.bind(this, formName, transID, authMethod)
    );
  }

  agreeWithModify(formName, transID, custEmail, authMethod, modifiedFields) {
    request.post(
      {
        url: window.config.agree_api_endpoint,
        json: {
          TransID: transID,
          CustEmail: custEmail,
          PartnerID: 0,
          ModifiedFields: modifiedFields,
        },
        contentType: 'application/json',
        withCredentials: true,
      },
      this.handleAgreeResponse.bind(this, formName, transID, authMethod)
    );
  }

  handleAgreeResponse(formName, transID, authMethod, error, response, body) {
    if (!error && response.status === 200 && body.TransactionAgreed) {
      AppDispatcher.handleServerAction({
        actionType: FormConstants.SUBMISSION_SUCCESS,
        name: formName,
      });
    } else {
      AppDispatcher.handleServerAction({
        actionType: FormConstants.SUBMISSION_FAILURE,
        name: formName,
        title: ErrorMessages.AGREEMENT_FAIL,
      });
    }
  }
}

export default new InlineAuthActions();
