import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';

import {
  useSendSMSCodeMutation,
  useUpdateCustomerPhoneNumberMutation,
} from 'spa/features/kyc/kycApi';
import { savePhoneNumber } from 'spa/features/kyc/kycSlice';
import { V3KYCMobilePages as Pages } from 'spa/constants/VerificationConstants';
import { validatePhone } from 'spa/components/form/validate';
import { required } from 'spa/features/kyc/utils';
import { ERROR_TYPES } from 'spa/constants/VerificationConstants';

import PhoneNumberField from '../../components/PhoneNumberField';
import MobilePage from '../../components/MobilePage';
import MobilePageFormControl from '../../components/MobilePageFormControl';
import KYCAlert from '../../components/KYCAlert';

const PhoneNumberPage = () => {
  const dispatch = useDispatch();
  const [sendSMSCode, { isLoading: isLoadingSMS, error: smsError, isSuccess: isSuccessSMS }] =
    useSendSMSCodeMutation();
  const [
    updateCustomerPhoneNumber,
    { isLoading: isLoadingPhone, error: phoneError, isSuccess: isSuccessPhone },
  ] = useUpdateCustomerPhoneNumberMutation();
  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm();

  const isLoading = isLoadingSMS || isLoadingPhone;
  const isSuccess = isSuccessSMS && isSuccessPhone;
  const submissionError = phoneError || smsError;

  const onSubmitNumber = useCallback(
    ({ phoneNumberInput }) => {
      const phoneNumber =
        phoneNumberInput && phoneNumberInput[0] === '+' ? phoneNumberInput : `+${phoneNumberInput}`;

      // eslint-disable-next-line no-console
      console.log(`phoneNumber`, phoneNumber);

      if (isValid) {
        dispatch(savePhoneNumber({ phoneNumber }));
        updateCustomerPhoneNumber(phoneNumber);
        sendSMSCode(phoneNumber);
      }
    },
    [dispatch, isValid, sendSMSCode, updateCustomerPhoneNumber]
  );

  return (
    <MobilePage
      title={'First, enter your phone number'}
      subtitle={'We’ll send you a 6-digit code to confirm.'}
      ctaText="Send code"
      onSubmit={handleSubmit((data) => {
        if (isValid) {
          onSubmitNumber(data);
        }
      })}
      nextPage={Pages.SMS_CODE}
      dynamicTitleFontSize="6vw"
      disableButtons={isLoading}
      nextPageTrigger={isSuccess}
    >
      <form>
        {submissionError && (
          <KYCAlert
            sx={{ marginBottom: '16px' }}
            isError={submissionError}
            errorType={ERROR_TYPES.SUBMISSION_FAILED}
            xRequestId={submissionError.xRequestId}
          />
        )}
        <MobilePageFormControl>
          <Controller
            name="phoneNumberInput"
            control={control}
            defaultValue=""
            rules={{ validate: { validatePhone, required } }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <PhoneNumberField
                value={value}
                onChange={onChange}
                error={error}
                disabled={isLoading}
              />
            )}
          />
        </MobilePageFormControl>
      </form>
    </MobilePage>
  );
};

export default PhoneNumberPage;
