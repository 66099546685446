import React, { Component } from 'react';
import { Spinner } from 'spa/components/Indicators';
import TriggerChargeForm from 'spa/components/PaymentVerification/TriggerChargeForm';
import { gettext } from '../../../utils/filters';

class VerificationInstructionsSection extends Component {
  constructor(props) {
    super(props);

    this.getCardDetails = this.getCardDetails.bind(this);
    this.getTransaction = this.getTransaction.bind(this);
  }

  componentDidMount() {
    this.getCardDetails();
    this.getTransaction();
  }

  getCardDetails() {
    this.props.getCardDetails();
  }

  getTransaction() {
    this.props.getTransaction();
  }

  render() {
    const { card, transaction } = this.props;

    return (
      <div>
        {card && transaction.description && !card.loading ? (
          <div>
            <h1 className="account-section-title">
              {' '}
              {gettext('Please authenticate ownership of your card')}{' '}
            </h1>
            <p>
              {gettext(`In order to proceed with transaction`)}{' '}
              <strong>{transaction.description}</strong>
              {gettext(`, we would like you to authenticate the ownership of your card. Authenticating
              your card is a quick and simple process that will only take a few minutes. To prove you
              own the card`)}{' '}
              <strong>
                {card.bin}****{card.last_four_digits}
              </strong>
              {''}
              {gettext(`, we need to charge two small amounts to your card. We just need you to
              identify and confirm the amounts. The charges will be instantly refunded to you.`)}
            </p>
            <TriggerChargeForm
              initialValues={{ cardId: this.props.cardId, transId: this.props.transId }}
            />
            <br />
            <br />
            <p>
              {' '}
              {gettext("Can't authenticate your card? Please contact ")}
              <a href="/contact-us">{gettext('support')}</a> {gettext('and tell us why.')}{' '}
            </p>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

export default VerificationInstructionsSection;
