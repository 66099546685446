import formatNumber from 'format-number';

export function formatFileSize(size) {
  let formattedSize;
  const MEGABYTE = 1000000;
  const KILOBYTE = 1000;
  if (size < MEGABYTE) {
    const formatter = formatNumber({
      round: 2,
      padRight: 2,
      suffix: ' KB',
    });
    formattedSize = formatter(size / KILOBYTE);
  } else {
    const formatter = formatNumber({
      round: 2,
      padRight: 2,
      suffix: ' MB',
    });
    formattedSize = formatter(size / MEGABYTE);
  }
  return formattedSize;
}

export default {
  formatFileSize,
};
