import React from 'react';

import DisplayTaskList from 'spa/containers/DisplayTaskList';
import ButtonActions from 'spa/components/ButtonActions';
import Footer from 'spa/components/Footer';
import { SectionTitle, SectionDesc } from 'spa/components/Section';

const ButtonsGetStarted = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktop-9">
        <h1 className="integrationsPortal-title">Buttons</h1>
        <ButtonActions>
          <SectionTitle>Buttons</SectionTitle>
          <SectionDesc>
            Buttons are a short snippet of code that you can easily paste on your website or in a
            marketplace listing like eBay, to provide potential buyers a simple and quick way to
            checkout and pay you safely through Escrow.com. The available buttons are Buy It Now
            buttons which the buyer can use to buy your item at a fixed price set by you, and Make
            An Offer buttons where the buyer can submit an offer for your item.
          </SectionDesc>
        </ButtonActions>
        <DisplayTaskList list="buttons">
          <SectionTitle>Buttons Integration Checklist</SectionTitle>
        </DisplayTaskList>
        <Footer />
      </div>
    </div>
  </div>
);

export default ButtonsGetStarted;
