import React from 'react';
import ReactSVG from 'react-svg';
import { V3KYCMobilePages as Pages } from 'spa/constants/VerificationConstants';
import { Stack } from '@mui/material';
import { SmallText } from 'spa/features/ui/EscrowTypography';
import MobilePage from '../../components/MobilePage';

const SelfieInstructionsPage = () => (
  <MobilePage
    title="Take a quick selfie"
    subtitle="Almost there! Next we need you to take a photo of your face to verify your identity."
    ctaText="Take a selfie"
    nextPage={Pages.SELFIE_CAPTURE}
  >
    <img
      style={{
        borderRadius: '1rem',
        marginBottom: '2rem',
        width: '50%',
        height: 'auto',
        alignSelf: 'center',
      }}
      src={`spa/../../build/images/kyc/selfie-instruction.gif`}
      alt="Please capture your selfie"
    />
    <SmallText sx={{ fontSize: '16px', fontWeight: '600' }}>Reminders:</SmallText>
    <Stack direction="column" sx={{ gap: '8px' }}>
      <Stack direction="row" sx={{ gap: '8px' }}>
        <ReactSVG src="spa/../../build/images/kyc/lit-room.svg" />
        <SmallText sx={{ fontSize: '16px' }}>You are in a well lit room</SmallText>
      </Stack>
      <Stack direction="row" sx={{ gap: '8px' }}>
        <ReactSVG src="spa/../../build/images/kyc/no-glasses.svg" />
        <SmallText sx={{ fontSize: '16px' }}>Remove any glasses, fask masks or hats</SmallText>
      </Stack>
      <Stack direction="row" sx={{ gap: '8px' }}>
        <ReactSVG src="spa/../../build/images/kyc/stay-in-frame.svg" />
        <SmallText sx={{ fontSize: '16px' }}>
          Stay still inside the frame. No need to smile
        </SmallText>
      </Stack>
    </Stack>
  </MobilePage>
);

export default SelfieInstructionsPage;
