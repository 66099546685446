import React from 'react';
import PropTypes from 'prop-types';
import { trimAndConcat, formatPhone, formatDob, formatCityStatePostcode } from './utils';
import { gettext } from '../../../utils/filters';

const PersonalDetails = (props) => {
  const nameDisplay = trimAndConcat(' ', props.firstName, props.middleName, props.lastName);
  const emailDisplay = (props.custEmail || '').trim();
  const phoneDisplay = formatPhone(props.phoneNumber);
  const dobDisplay = formatDob(props.dateOfBirth);

  const customerAddressLine1Display = (props.personalAddress.line1 || '').trim();
  const customerAddressLine2Display = (props.personalAddress.line2 || '').trim();
  const customerCityStatePostcodeDisplay = formatCityStatePostcode(
    props.personalAddress.city,
    props.personalAddress.state,
    props.personalAddress.zip
  );
  const customerCountryDisplay = (props.personalAddress.country || '').trim();
  const hasCustomerAddress = Boolean(
    customerAddressLine1Display && customerCityStatePostcodeDisplay && customerCountryDisplay
  );

  return (
    <div>
      <div className="verifyPage-card-body">
        <header className="verifyPage-form-header">
          <div>
            <h3 className="verifyPage-form-title">
              {gettext(props.label || 'Customer Information')}
            </h3>
          </div>
        </header>
        <table className="verifyPage-table">
          <tbody>
            <tr className="verifyPage-table-row">
              <td className="verifyPage-table-key">{gettext('Name')}</td>
              <td className="verifyPage-table-value">{nameDisplay || '-'}</td>
            </tr>
            <tr>
              <td className="verifyPage-table-key">{gettext('Email')}</td>
              <td className="verifyPage-table-value">{emailDisplay || '-'}</td>
            </tr>
            <tr>
              <td className="verifyPage-table-key">{gettext('Phone Number')}</td>
              <td className="verifyPage-table-value">{phoneDisplay || '-'}</td>
            </tr>
            <tr>
              <td className="verifyPage-table-key">{gettext('Date of Birth')}</td>
              <td className="verifyPage-table-value">{dobDisplay || '-'}</td>
            </tr>
            <tr>
              <td className="verifyPage-table-key">{gettext('Billing Address')}</td>
              <td className="verifyPage-table-value">
                {!hasCustomerAddress && '-'}
                {customerAddressLine1Display}
                {Boolean(customerAddressLine1Display) && <br />}
                {customerAddressLine2Display}
                {Boolean(customerAddressLine2Display) && <br />}
                {customerCityStatePostcodeDisplay}
                {Boolean(customerCityStatePostcodeDisplay) && <br />}
                {customerCountryDisplay}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {props.documentFields && props.documentFields.length > 0 && (
        <div className="verifyPage-card-body">
          <header className="verifyPage-form-header">
            <div>
              <h3 className="verifyPage-form-title">{gettext('Document Information')}</h3>
            </div>
          </header>
          <table className="verifyPage-table">
            <tbody>
              {props.documentFields.map((field) => (
                <tr key={field.label} className="verifyPage-table-row">
                  <td className="verifyPage-table-key">{gettext(field.label)}</td>
                  <td className="verifyPage-table-value">{field.value || '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

PersonalDetails.propTypes = {
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  custEmail: PropTypes.string,
  phoneNumber: PropTypes.string,
  dateOfBirth: PropTypes.string,
  personalAddress: PropTypes.shape({
    line1: PropTypes.string,
    line2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    zip: PropTypes.string,
  }),
};

export default PersonalDetails;
