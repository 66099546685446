import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { getV4Customer as getV4CustomerRoutine } from 'spa/actions/UserActions';
import { getTransactionById as getTransactionByIdAction } from 'spa/actions/TransactionActions';
import { TransactionLineItemsTable } from 'spa/components/PartnerDashboard/SharedComponents';
import TransactionEscrowTerms from 'spa/components/TransactionStatus/EscrowTerms';
import TransactionHistory from 'spa/components/TransactionStatus/TransactionHistory';
import { transactionByIdSelector } from 'spa/selectors/TransactionSelectors';
import API from '../../../api';
import AuthenticationStore from '../../../stores/AuthenticationStore';
import Spinner from '../../components/Indicators/Spinner';
import { isCustomerReadySelector } from '../../selectors/CustomerSelectors';
import WizardFlow from '../../components/TransactionStatus/WizardFlow';
import TransactionConstants from '../../constants/TransactionConstants';

const { TRANSACTION_PROGRESS_STATUS_CODES, BUYER_MESSAGE_PER_STATUS, SELLER_MESSAGE_PER_STATUS } =
  TransactionConstants;

const getTransactionProgress = (statusCode) => {
  const stepProgress = Object.values(TRANSACTION_PROGRESS_STATUS_CODES).find((progress) =>
    progress.status_codes.includes(statusCode)
  );
  return stepProgress ? stepProgress.step : 1;
};
const BUYER = 'buyer';
const SELLER = 'seller';

const TransactionStatusTitle = (props) => {
  const { transactionStep, transactionStatus, transactionId, customerId, legacyTransaction } =
    props;
  let headerData = {};

  let userRole = '';
  let description = '';
  let cta = 'Continue';
  let ctaUrl = `${window.config.www_base_url}/transaction/${transactionId}`;

  if (
    legacyTransaction.transactions.buyer &&
    parseInt(legacyTransaction.transactions.buyer.id, 10) === customerId
  )
    userRole = BUYER;
  else if (
    legacyTransaction.transactions.seller &&
    parseInt(legacyTransaction.transactions.seller.id, 10) === customerId
  )
    userRole = SELLER;

  if (userRole === BUYER) {
    headerData = BUYER_MESSAGE_PER_STATUS.find((s) => s.code === transactionStatus);
    description = headerData.description;
    cta = headerData.cta;
    ctaUrl = headerData.url(transactionId);
  } else if (userRole === SELLER) {
    headerData = SELLER_MESSAGE_PER_STATUS.find((s) => s.code === transactionStatus);
    description = headerData.description;
    cta = headerData.cta;
    ctaUrl = headerData.url(transactionId);
  }

  return (
    <div className="transactionStatus-title">
      <h2>{transactionStep}</h2>
      <p>{description}</p>
      <br />
      <a
        href={ctaUrl}
        className="checkout-card-btn btn btn--secondary"
        data-e2e-target="checkout-payment-agree-btn"
        data-tracking-name="status-to-next-step"
      >
        {cta}
      </a>
    </div>
  );
};

const TransactionStatusPage = (props) => {
  const {
    transactionId,
    customerId,
    v4Transaction,
    v4Customer,
    areCustomerDetailsReady,
    getTransactionAndPaymentMethods,
    getCustomer,
  } = props;
  const [timelineNotes, setTimelineNotes] = useState([]);
  const [legacyTransaction, setLegacyTransaction] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState(null);

  useEffect(() => {
    getTransactionAndPaymentMethods();
    getCustomer();
    API.getTransactionTimelineEntries(transactionId).then((result) => {
      setTimelineNotes(result);
    });
    API.getLegacyTransactionById(transactionId).then((result) => {
      setLegacyTransaction(result);
    });
    API.getTransactionProgress(transactionId).then((result) => {
      setTransactionStatus(result);
    });
  }, [getTransactionAndPaymentMethods, getCustomer, transactionId]);

  return (
    <div className="accountInfo">
      <div className="transactionStatus-backAction">
        <a href={`${window.config.www_base_url}/transactions`}>← Back To My Transactions Page</a>
      </div>
      <section className="section section--small section--mid">
        {areCustomerDetailsReady &&
        legacyTransaction &&
        transactionStatus &&
        !isEmpty(v4Transaction) &&
        v4Customer ? (
          <div className="section-container">
            <div className="transactionStatus-header">
              <div className="transactionStatus-header--text">
                <h6>Transaction #{transactionId}</h6>
                <h4>{v4Transaction.description}</h4>
              </div>
              <WizardFlow
                step={getTransactionProgress(legacyTransaction.transactions.status_code)}
              />
            </div>
            <div className="account-inner">
              <TransactionStatusTitle
                transactionStep={transactionStatus.transaction_progress}
                transactionStatus={transactionStatus.transaction_status}
                transactionId={transactionId}
                customerId={customerId}
                legacyTransaction={legacyTransaction}
              />
              <div className="checkout-card checkout-card-secondary card">
                <div className="checkout-card-content checkout-card-content--light">
                  <TransactionLineItemsTable transaction={v4Transaction} showTitle={false} />
                  <br />
                  <TransactionEscrowTerms
                    transaction={v4Transaction}
                    buyer={legacyTransaction.transactions.buyer}
                    seller={legacyTransaction.transactions.seller}
                  />
                  <br />
                  {timelineNotes.length && <TransactionHistory timelineNotes={timelineNotes} />}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </section>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const transactionId = parseInt(ownProps.match.params.transaction_id, 10);
  const customerId = AuthenticationStore.getCustomerID();
  return {
    transactionId,
    customerId,
    v4Transaction: transactionByIdSelector(state, transactionId),
    v4Customer: (state, customerId),
    areCustomerDetailsReady: isCustomerReadySelector(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const transactionId = parseInt(ownProps.match.params.transaction_id, 10);
  const customerId = AuthenticationStore.getCustomerID();
  return {
    getTransactionAndPaymentMethods: () =>
      dispatch(getTransactionByIdAction.trigger({ transId: transactionId })),
    getCustomer: () => {
      dispatch(getV4CustomerRoutine({ custId: customerId }));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TransactionStatusPage));
