import React from 'react';
import PropTypes from 'prop-types';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/styles';

export const Code = ({ children, language }) => (
  <div className="code">
    <div className="code-snippet">
      <SyntaxHighlighter
        language={language}
        showLineNumbers
        wrapLines
        style={docco}
        lineNumberContainerStyle={{
          padding: '13px 0',
          marginRight: '10px',
          backgroundColor: '#F0F2F5',
        }}
        customStyle={{
          display: 'flex',
          padding: '0',
          backgroundColor: '#fff',
        }}
      >
        {children}
      </SyntaxHighlighter>
    </div>
  </div>
);

Code.propTypes = {
  language: PropTypes.string,
};

Code.defaultProps = {
  language: 'bash',
};

export default Code;
