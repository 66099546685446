import React from 'react';
import { FormSection, reduxForm } from 'redux-form';
import { Spinner } from 'spa/components/Indicators';
import { bindRoutineToReduxForm } from 'redux-saga-routines';
import { apiToCurrencyMapping } from 'escrow-common-js/dist/constants';

import { submitCreditCardPayment as submitCreditCardPaymentRoutine } from 'spa/actions/TransactionActions';
import CreditCardLogo from 'spa/components/CreditCardLogo';
import CreditCardFieldset from './CreditCardFieldset';
import BillingSummary from './BillingSummary';

const CreditCardForm = ({
  error,
  submitFailed,
  billingLineItems,
  billingTotal,
  isLoading,
  handleSubmit,
  submitting,
  dataError,
  dataErrorMessage,
  customerDetails,
  subTotal,
  transaction,
  savedCards,
}) => (
  <div>
    {isLoading ? (
      <Spinner />
    ) : (
      <form onSubmit={handleSubmit(bindRoutineToReduxForm(submitCreditCardPaymentRoutine))}>
        <section className="card">
          <div className="card-inner">
            <header className="card-header card-header--xsmall">
              <h2 className="card-heading">Card Payment</h2>
              <div className="card-header-action">
                <CreditCardLogo type="visa" />
                <CreditCardLogo type="mastercard" />
                <CreditCardLogo type="amex" />
              </div>
            </header>
            <CreditCardFieldset
              customerDetails={customerDetails}
              savedCards={savedCards}
              currency={apiToCurrencyMapping[transaction.currency]}
            />
          </div>
        </section>
        <FormSection name="billing-summary">
          <BillingSummary
            billingTotal={billingTotal}
            billingLineItems={billingLineItems}
            isSubmitting={submitting}
            error={error}
            submitFailed={submitFailed}
            dataError={dataError}
            dataErrorMessage={dataErrorMessage}
            subTotal={subTotal}
            transaction={transaction}
          />
        </FormSection>
      </form>
    )}
  </div>
);

export default reduxForm({
  form: 'creditCardForm',
  initialValues: {
    cardId: 'new',
  },
})(CreditCardForm);
