import React from 'react';
import ReactSVG from 'react-svg';
import PropTypes from 'prop-types';

const CreditCardLogo = ({ type, size = 'medium', cardNumber, display }) => (
  <div className="creditCardLogo-container">
    <span className={`creditCardLogo creditCardLogo--${type} creditCardLogo--${size}`} />
    {cardNumber && (
      <span className="creditCardLogo-text">
        <span className="creditCardLogo-type">{display || type}</span>
        <span className="creditCardLogo-truncate">
          <ReactSVG
            src="../../../../build/images/global/truncate.svg"
            beforeInjection={(svg) => {
              svg.classList.add('creditCardLogo-truncate-svg');
            }}
          />
        </span>
        <span>{cardNumber}</span>
      </span>
    )}
  </div>
);

CreditCardLogo.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  cardNumber: PropTypes.string,
  display: PropTypes.string,
};

export default CreditCardLogo;
