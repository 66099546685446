import React from 'react';
import { Checkbox, Chip } from '@mui/material';
import { Field } from 'react-final-form';

const recommendedServices = ['concierge', 'titleCollection', 'lienHolder'];

const ExtraServiceCheck = (props) => {
  const {
    name,
    serviceName,
    title,
    price,
    description,
    disabled,
    cascadeChange = () => {},
  } = props;

  return (
    <Field name={name} type="checkbox">
      {({ input }) => (
        <div
          className="materialUI-border createTransaction-check createTransaction-check-container"
          data-disabled={disabled}
          role="button"
          tabIndex={0}
          onClick={() => {
            cascadeChange(!input.checked);
            !disabled && input.onChange(!input.checked);
          }}
        >
          <div className="createTransaction-check-row">
            <div className="createTransaction-check-row-header">
              <Checkbox checked={input.checked} disabled={disabled} />
              <div className="createTransaction-check-row-title">{title}</div>
            </div>
            {!recommendedServices.includes(serviceName) && (
              <div className="createTransaction-check-row-title createTransaction-check-row-price">
                {price}
              </div>
            )}
            {recommendedServices.includes(serviceName) && (
              <Chip
                label="Recommended"
                className="createTransaction-check-row-recommendationChip"
              />
            )}
          </div>
          <div className="createTransaction-check-row-description">{description}</div>
        </div>
      )}
    </Field>
  );
};

export default ExtraServiceCheck;
