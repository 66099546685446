import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { bindRoutineToReduxForm } from 'redux-saga-routines';

import { createWebhooks } from 'spa/actions/WebhooksActions';
import { Input } from 'spa/components/form';
import { required, urlScheme, urlFormat } from 'spa/components/form/validate';
import { Section } from 'spa/components/Section';
import { ErrorIndicator, Spinner } from 'spa/components/Indicators';
import {
  DashboardCard,
  DashboardCardHeader,
  DashboardCardBody,
} from 'spa/components/DashboardCard';

class CreateWebhooks extends React.PureComponent {
  render() {
    const { handleSubmit, error, submitting } = this.props;

    return (
      <Section>
        <DashboardCard>
          <DashboardCardHeader title="Create Webhook" />
          <DashboardCardBody>
            <form onSubmit={handleSubmit(bindRoutineToReduxForm(createWebhooks))}>
              <Field
                name="url"
                label="new webhook url"
                data-e2e-target="create-webhook-url-field"
                component={Input}
                validate={[required, urlScheme, urlFormat]}
                disabled={submitting}
              />
              {!submitting && error && <ErrorIndicator>{error}</ErrorIndicator>}
              <button
                className="btn btn--secondary accountForm-btn"
                type="submit"
                data-e2e-target="create-webhook-url-submit"
                disabled={submitting}
              >
                {submitting ? <Spinner /> : 'Create Webhook'}
              </button>
            </form>
          </DashboardCardBody>
        </DashboardCard>
      </Section>
    );
  }
}

const CreateWebhooksContainer = reduxForm({
  form: 'createWebhooks',
})(CreateWebhooks);

export default CreateWebhooksContainer;
