import React, { Fragment } from 'react';
import ReactSVG from 'react-svg';
import Icon from 'spa/components/Icon';

const HeaderLogo = () => (
  <Fragment>
    <a className="headerV3-logo media--hidden@mobile" href="/transactions">
      <ReactSVG src={`../../../../build/images/global/escrow-logo-v2.svg`} />
    </a>
    <div className="headerV3-shield media--available@mobile">
      <Icon name="escrow-shield" />
    </div>
  </Fragment>
);

export default HeaderLogo;
