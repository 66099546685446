import React from 'react';
import PropTypes from 'prop-types';
import { trimAndConcat, formatCityStatePostcode, formatDisplayNameType } from './utils';
import { gettext } from '../../../utils/filters';

const CompanyDetails = (props) => {
  const isCompany = props.isCompany;
  const nameDisplay = trimAndConcat(' ', props.firstName, props.middleName, props.lastName);
  const companyNameDisplay = (props.companyName || '').trim();
  const companyAddressLine1Display = (props.companyAddress.line1 || '').trim();
  const companyAddressLine2Display = (props.companyAddress.line2 || '').trim();
  const companyCityStatePostcodeDisplay = formatCityStatePostcode(
    props.companyAddress.city,
    props.companyAddress.state,
    props.companyAddress.zip
  );
  const companyCountryDisplay = (props.companyAddress.country || '').trim();
  const hasCompanyAddress = Boolean(
    companyAddressLine1Display && companyCityStatePostcodeDisplay && companyCountryDisplay
  );

  const hasCompanyInformation = Boolean(
    companyNameDisplay && companyAddressLine1Display && companyCityStatePostcodeDisplay
  );
  const nameTypeDisplay = formatDisplayNameType(
    hasCompanyInformation,
    isCompany,
    nameDisplay,
    companyNameDisplay
  );
  return (
    <div>
      <header className="verifyPage-card-header">
        {props.title && <h3 className="checkout-heading">{props.title}</h3>}
      </header>
      <div className="verifyPage-card-body">
        {props.label && (
          <header className="verifyPage-form-header">
            <div>
              <h3 className="verifyPage-form-title">{gettext(props.label)}</h3>
            </div>
          </header>
        )}
        <table className="verifyPage-table">
          <tbody>
            <tr className="verifyPage-table-row">
              <td className="verifyPage-table-key">{gettext('Registered Name')}</td>
              <td className="verifyPage-table-value">{companyNameDisplay || '-'}</td>
            </tr>
            <tr>
              <td className="verifyPage-table-key">{gettext('Name')}</td>
              <td className="verifyPage-table-value">{nameTypeDisplay || '-'}</td>
            </tr>
            <tr>
              <td className="verifyPage-table-key">{gettext('Address Line 1')}</td>
              <td className="verifyPage-table-value">
                {!hasCompanyAddress && '-'}
                {companyAddressLine1Display}
                {Boolean(companyAddressLine1Display) && <br />}
              </td>
            </tr>
            <tr>
              <td className="verifyPage-table-key">{gettext('Address Line 2')}</td>
              <td className="verifyPage-table-value">
                {!hasCompanyAddress && '-'}
                {companyAddressLine2Display}
                {Boolean(companyAddressLine2Display) && <br />}
              </td>
            </tr>
            <tr>
              <td className="verifyPage-table-key">{gettext('Post Code')}</td>
              <td className="verifyPage-table-value">
                {!hasCompanyAddress && '-'}
                {companyCityStatePostcodeDisplay}
                {Boolean(companyCityStatePostcodeDisplay) && <br />}
              </td>
            </tr>
            <tr>
              <td className="verifyPage-table-key">{gettext('Country')}</td>
              <td className="verifyPage-table-value">
                {!hasCompanyAddress && '-'}
                {companyCountryDisplay}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

CompanyDetails.propTypes = {
  isCompany: PropTypes.bool,
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  companyName: PropTypes.string,
  companyAddress: PropTypes.shape({
    line1: PropTypes.string,
    line2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    zip: PropTypes.string,
  }),

  title: PropTypes.string,
};

export default CompanyDetails;
