import React from 'react';
import PropTypes from 'prop-types';
import OfferControlPanel from 'spa/containers/Offer/OfferControlPanel';
import { currencies } from 'escrow-common-js/dist/constants';
import { offerDataPropTypes, offerActionPropTypes } from './models';
import { gettext } from '../../../utils/filters';

const OfferDetail = ({ auctionId, offer, actions, currency }) => (
  <div>
    <h3>
      {gettext('Offer Details')} : {offer.id}
    </h3>
    <p>Amount: {offer.noFeeAmount}</p>
    <OfferControlPanel actions={actions} auctionId={auctionId} offer={offer} currency={currency} />
  </div>
);

OfferDetail.propTypes = {
  auctionId: PropTypes.number,
  currency: PropTypes.oneOf(Object.keys(currencies)),
  offer: offerDataPropTypes,
  actions: offerActionPropTypes,
};

export default OfferDetail;
