import React from 'react';
import PropTypes from 'prop-types';
import { PulseLoader } from 'escrow-common-js/dist/components';
import { Field, reduxForm, formValues, FormSection } from 'redux-form';
import { ApiCurrency, Currency } from 'escrow-common-js/dist/constants';
import { getCurrencyString } from 'escrow-common-js/dist/utils';

import Breadcrumbs from 'spa/components/Breadcrumbs';
import { Input, Select, Checkbox, TextArea, FormError } from 'spa/components/form';
import {
  exactLength,
  minChars,
  maxChars,
  required,
  validateABA,
  validateBankAccountNumber,
  validateIban,
  validateSwift,
} from 'spa/components/form/validate';
import RenderIf from 'spa/components/RenderIf';
import DisbursementConstants from 'spa/constants/DisbursementConstants';
import { CountryToAlpha2 } from 'spa/constants/ISOCountryCodes';
import A from 'spa/components/A';
import { createSelectOptions } from '../../../utils/select';
import { gettext } from '../../../utils/filters';
import { lookupCountry } from '../../../utils/DataMapping';

class InternationalWireForm extends React.Component {
  constructor(props) {
    super(props);
    this.validators = {
      bankName: [maxChars(40, gettext('Bank Name'))],
      accountName: [maxChars(150, gettext('Account Name'))],
      accountNumber: [validateBankAccountNumber],
      swiftOrBicCode: [validateSwift((allValues) => allValues.country)],
      iban: [validateIban],
      clabeNumber: [maxChars(18, gettext('CLABE Number'))],
      bsb: [maxChars(6, gettext('BSB'))],
      sortCode: [maxChars(6, gettext('Sort Code'))],
      brstnCode: [maxChars(9, gettext('BRSTN Code'))],
      bankNumber: [exactLength(2, gettext('Bank Number'))],
      branchNumber: [exactLength(4, gettext('Branch Number'))],
      suffix: [minChars(2, gettext('Suffix')), maxChars(3, gettext('Suffix'))],
      ifscCode: [maxChars(11, gettext('IFSC Code'))],
      financialInstitutionNumber: [exactLength(3, gettext('Financial Institution Number'))],
      transitNumber: [exactLength(5, gettext('Transit Number'))],
      bankAbaRoutingNumber: [validateABA],
      intermediarySwiftOrBicCode: [
        validateSwift((allValues) => allValues.intermediaryBank.country),
      ],
    };
  }

  componentDidMount() {
    this.props.initialize(this.props.initialValues);
  }

  getPageTitle() {
    return gettext('Disbursement by Wire/Bank Transfer');
  }

  getCurrencyOptions(availableCurrencies, transactionCurrency) {
    if (transactionCurrency) {
      return [{ value: transactionCurrency, label: transactionCurrency.toUpperCase() }];
    }
    return availableCurrencies.map((name) => ({
      value: name,
      label: name.toUpperCase(),
    }));
  }

  getDisbursementFee(country, currency) {
    if (currency === ApiCurrency[Currency.EUR]) {
      return getCurrencyString(15, Currency.EUR);
    }
    if (currency === ApiCurrency[Currency.AUD]) {
      if (country === 'AU') {
        return getCurrencyString(10, Currency.AUD);
      }
      return getCurrencyString(20, Currency.AUD);
    }
    if (currency === ApiCurrency[Currency.GBP]) {
      if (country === 'GB') {
        return getCurrencyString(10, Currency.GBP);
      }
      return getCurrencyString(20, Currency.GBP);
    }
    if (country === 'US') {
      return getCurrencyString(10, Currency.USD);
    }
    if (currency === ApiCurrency[Currency.USD]) {
      return getCurrencyString(20, Currency.USD);
    }
    if (currency === ApiCurrency[Currency.CAD]) {
      if (country === 'CA') {
        return getCurrencyString(10, Currency.CAD);
      }
      return getCurrencyString(20, Currency.CAD);
    }
  }

  renderCountrySpecificFields() {
    return (
      <div>
        {/* ABA Routing Number */}
        <RenderIf
          checkFn={(c, cur) =>
            DisbursementConstants.fieldCountries.ABA_ROUTING.includes(c) && cur === 'usd'
          }
          fnArgs={[this.props.country, this.props.currency]}
        >
          <div className="disbursementForm-field">
            <Field
              name="bankAbaRoutingNumber"
              label={gettext('ABA Routing number')}
              component={Input}
              validate={[required, ...this.validators.bankAbaRoutingNumber]}
              disabled={this.props.submitting}
              requiredIndicator
            />
          </div>
        </RenderIf>
        {/* SWIFT/BIC Code */}
        <RenderIf
          checkFn={(c, cur) =>
            !(DisbursementConstants.fieldCountries.ABA_ROUTING.includes(c) && cur === 'usd')
          }
          fnArgs={[this.props.country, this.props.currency]}
        >
          <div className="disbursementForm-field">
            <Field
              name="swiftOrBicCode"
              label={gettext('SWIFT/BIC Code')}
              component={Input}
              validate={[required, ...this.validators.swiftOrBicCode]}
              disabled={this.props.submitting}
              tooltip={
                <div>
                  <A
                    link={{
                      type: 'external',
                      route: 'https://www2.swift.com/bsl/',
                      newTab: true,
                    }}
                  >
                    {gettext('Click here')}
                  </A>{' '}
                  {gettext('to get your bank’s SWIFT/BIC code')}
                </div>
              }
              requiredIndicator
            />
          </div>
        </RenderIf>
        {/* Account Number */}
        <RenderIf
          checkFn={(c) => DisbursementConstants.fieldCountries.ACCOUNT_NUMBER.includes(c)}
          fnArgs={[this.props.country]}
        >
          <div className="disbursementForm-field">
            <Field
              name="accountNumber"
              label={gettext('Account Number')}
              component={Input}
              validate={[required, ...this.validators.accountNumber]}
              disabled={this.props.submitting}
              requiredIndicator
            />
          </div>
        </RenderIf>
        {/* IBAN */}
        <RenderIf
          checkFn={(c) => DisbursementConstants.fieldCountries.IBAN.includes(c)}
          fnArgs={[this.props.country]}
        >
          <div className="disbursementForm-field">
            <Field
              name="iban"
              label={gettext('IBAN')}
              component={Input}
              validate={[required, ...this.validators.iban]}
              disabled={this.props.submitting}
              requiredIndicator
            />
          </div>
        </RenderIf>
        {/* CLABE Number */}
        <RenderIf
          checkFn={(c) => DisbursementConstants.fieldCountries.CLABE_NUMBER.includes(c)}
          fnArgs={[this.props.country]}
        >
          <div className="disbursementForm-field">
            <Field
              name="clabeNumber"
              label={gettext('CLABE Number')}
              component={Input}
              validate={[required, ...this.validators.clabeNumber]}
              disabled={this.props.submitting}
              requiredIndicator
            />
          </div>
        </RenderIf>
        {/* BSB Number */}
        <RenderIf
          checkFn={(c) => DisbursementConstants.fieldCountries.BSB.includes(c)}
          fnArgs={[this.props.country]}
        >
          <div className="disbursementForm-field">
            <Field
              name="bsb"
              label={gettext('BSB')}
              component={Input}
              validate={[required, ...this.validators.bsb]}
              disabled={this.props.submitting}
              requiredIndicator
            />
          </div>
        </RenderIf>
      </div>
    );
  }

  renderOptionalFields() {
    return (
      <div>
        {/* Sort Code */}
        <RenderIf
          checkFn={(c) => DisbursementConstants.fieldCountries.SORT_CODE.includes(c)}
          fnArgs={[this.props.country]}
        >
          <div className="disbursementForm-field">
            <Field
              name="sortCode"
              label={gettext('Sort Code')}
              component={Input}
              validate={[required, ...this.validators.sortCode]}
              disabled={this.props.submitting}
              requiredIndicator
            />
          </div>
        </RenderIf>
        {/* BRSTN Code */}
        <RenderIf
          checkFn={(c) => DisbursementConstants.fieldCountries.BRSTN_CODE.includes(c)}
          fnArgs={[this.props.country]}
        >
          <div className="disbursementForm-field">
            <Field
              name="brstnCode"
              label={gettext('BRSTN Code')}
              component={Input}
              validate={[...this.validators.brstnCode]}
              disabled={this.props.submitting}
            />
          </div>
        </RenderIf>
        {/* Bank Number */}
        <RenderIf
          checkFn={(c) => DisbursementConstants.fieldCountries.BANK_NUMBER.includes(c)}
          fnArgs={[this.props.country]}
        >
          <div className="disbursementForm-field">
            <Field
              name="bankNumber"
              label={gettext('Bank Number')}
              component={Input}
              validate={[required, ...this.validators.bankNumber]}
              disabled={this.props.submitting}
              requiredIndicator
            />
          </div>
        </RenderIf>
        {/* Branch Number */}
        <RenderIf
          checkFn={(c) => DisbursementConstants.fieldCountries.BRANCH_NUMBER.includes(c)}
          fnArgs={[this.props.country]}
        >
          <div className="disbursementForm-field">
            <Field
              name="branchNumber"
              label={gettext('Branch Number')}
              component={Input}
              validate={[required, ...this.validators.branchNumber]}
              disabled={this.props.submitting}
              requiredIndicator
            />
          </div>
        </RenderIf>
        {/* Suffix */}
        <RenderIf
          checkFn={(c) => DisbursementConstants.fieldCountries.SUFFIX.includes(c)}
          fnArgs={[this.props.country]}
        >
          <div className="disbursementForm-field">
            <Field
              name="suffix"
              label={gettext('Suffix')}
              component={Input}
              validate={[required, ...this.validators.suffix]}
              disabled={this.props.submitting}
            />
          </div>
        </RenderIf>
        {/* IFSC Code */}
        <RenderIf
          checkFn={(c) => DisbursementConstants.fieldCountries.IFSC_CODE.includes(c)}
          fnArgs={[this.props.country]}
        >
          <div className="disbursementForm-field">
            <Field
              name="ifscCode"
              label={gettext('IFSC Code')}
              component={Input}
              validate={[...this.validators.ifscCode]}
              disabled={this.props.submitting}
            />
          </div>
        </RenderIf>
        {/* Financial Institution Number */}
        <RenderIf
          checkFn={(c) =>
            DisbursementConstants.fieldCountries.FINANCIAL_INSTITUTION_NUMBER.includes(c)
          }
          fnArgs={[this.props.country]}
        >
          <div className="disbursementForm-field">
            <Field
              name="financialInstitutionNumber"
              label={gettext('Financial Institution Number')}
              component={Input}
              validate={[required, ...this.validators.financialInstitutionNumber]}
              disabled={this.props.submitting}
              requiredIndicator
            />
          </div>
        </RenderIf>
        {/* Transit Number */}
        <RenderIf
          checkFn={(c) => DisbursementConstants.fieldCountries.TRANSIT_NUMBER.includes(c)}
          fnArgs={[this.props.country]}
        >
          <div className="disbursementForm-field">
            <Field
              name="transitNumber"
              label={gettext('Branch/Transit Number')}
              component={Input}
              validate={[required, ...this.validators.transitNumber]}
              disabled={this.props.submitting}
              requiredIndicator
            />
          </div>
        </RenderIf>
      </div>
    );
  }

  renderIntermediaryBankFields() {
    return (
      <RenderIf checkFn={(v) => !!v} fnArgs={[this.props.intermediaryBankNeeded]}>
        <h2 className="disbursementForm-heading">{gettext('Intermediary bank information')}</h2>
        <FormSection name="intermediaryBank">
          {/* Country */}
          <div className="disbursementForm-field">
            <Field
              name="country"
              label={gettext('Country')}
              component={Select}
              options={createSelectOptions(CountryToAlpha2)}
              validate={[required]}
              disabled={this.props.submitting}
              requiredIndicator
            />
          </div>
          <RenderIf checkFn={Boolean} fnArgs={[this.props['intermediaryBank.country']]}>
            <div>
              <RenderIf
                checkFn={(c) => DisbursementConstants.fieldCountries.ABA_ROUTING.includes(c)}
                fnArgs={[this.props['intermediaryBank.country']]}
              >
                <div className="disbursementForm-field">
                  <Field
                    name="abaRoutingNumber"
                    label={gettext('ABA Routing Number')}
                    component={Input}
                    validate={[required, ...this.validators.bankAbaRoutingNumber]}
                    disabled={this.props.submitting}
                    requiredIndicator
                  />
                </div>
              </RenderIf>
              <RenderIf
                checkFn={(c) => !DisbursementConstants.fieldCountries.ABA_ROUTING.includes(c)}
                fnArgs={[this.props['intermediaryBank.country']]}
              >
                <div className="disbursementForm-field">
                  <Field
                    name="swiftCode"
                    label={gettext('SWIFT Code')}
                    component={Input}
                    disabled={this.props.submitting}
                    validate={[required, ...this.validators.intermediarySwiftOrBicCode]}
                    requiredIndicator
                  />
                </div>
              </RenderIf>
              {/* Bank Name */}
              <div className="disbursementForm-field">
                <Field
                  name="bankName"
                  label={gettext('Bank Name')}
                  component={Input}
                  validate={[required, ...this.validators.bankName]}
                  disabled={this.props.submitting}
                  requiredIndicator
                />
              </div>
            </div>
          </RenderIf>
        </FormSection>
      </RenderIf>
    );
  }

  render() {
    const {
      submitting,
      handleSubmit,
      submitFailed,
      validDisbursementNames,
      availableCurrencies,
      transactionCurrency,
      display,
      error,
    } = this.props;

    const breadcrumbs = [
      {
        text: gettext('Your Account'),
        link: '/account-info',
      },
      {
        text: gettext('Disbursement Options'),
        link: '/account-info/disbursement-options',
      },
      {
        text: `${gettext('Wire/Bank Transfer')}`,
      },
    ];

    return (
      <section
        className="section section--small section--mid"
        data-e2e-target={`${this.props.form}-disbursement-container`}
      >
        <div className="section-container">
          <div className="account-inner">
            <Breadcrumbs className="accountInfo-breadcrumbs" items={breadcrumbs} />
            <header className="account-section-header-content account-section-header-content--full">
              <div className="account-section-header-content">
                <h1 className="account-section-title">{this.getPageTitle()}</h1>
                <p className="account-section-desc">
                  <span className="field-required">*</span> {gettext('denotes a required field')}
                </p>
                {submitFailed && error && <FormError error={error} />}
              </div>
            </header>
            {!(display && display.loading === false) ? (
              <PulseLoader loading />
            ) : (
              <form className="disbursementForm" onSubmit={handleSubmit}>
                <div className="disbursementForm-inner">
                  <fieldset className="disbursementForm-fieldset">
                    <h2 className="disbursementForm-heading">
                      {gettext('Beneficiary bank information')}
                    </h2>
                    {/* Country */}
                    <div className="disbursementForm-field">
                      <Field
                        name="country"
                        label={gettext('Country')}
                        component={Select}
                        options={createSelectOptions(CountryToAlpha2)}
                        validate={[required]}
                        disabled={submitting}
                        requiredIndicator
                      />
                    </div>
                    {/* Currency */}
                    <div className="disbursementForm-field">
                      <Field
                        name="currency"
                        label={gettext('Currency')}
                        component={Select}
                        options={
                          display.loading || !display.success
                            ? [{ value: 'loading', label: gettext('Loading...') }]
                            : this.getCurrencyOptions(availableCurrencies, transactionCurrency)
                        }
                        placeholder="Select a currency"
                        validate={[required]}
                        disabled={this.props.submitting}
                        requiredIndicator
                      />
                    </div>
                    <RenderIf checkFn={Boolean} fnArgs={[this.props.currency]}>
                      {/* Bank Name */}
                      <div className="disbursementForm-field">
                        <Field
                          name="bankName"
                          label={gettext('Bank Name')}
                          component={Input}
                          validate={[required, ...this.validators.bankName]}
                          disabled={submitting}
                          requiredIndicator
                        />
                      </div>
                      {/* Account Name */}
                      <div className="disbursementForm-field">
                        <Field
                          name="accountName"
                          label={gettext('Beneficiary Account Name')}
                          component={Select}
                          options={
                            display.loading || !display.success
                              ? [{ value: 'loading', label: gettext('Loading...') }]
                              : validDisbursementNames.map((name) => ({ value: name, label: name }))
                          }
                          validate={[required, ...this.validators.accountName]}
                          disabled={submitting || display.loading || !display.success}
                          tooltip={gettext(`Please use the full official name
                      under which your bank account is registered,
                      exactly as it appears on your bank statement.`)}
                          requiredIndicator
                        />
                      </div>

                      {/* Country specific fields */}
                      {this.renderCountrySpecificFields()}
                      {/* Optional fields */}
                      {this.renderOptionalFields()}

                      {/* Additional Information */}
                      <div className="disbursementForm-field">
                        <Field
                          name="additionalInformation"
                          label={gettext('Additional Information')}
                          component={TextArea}
                          disabled={submitting}
                          tooltip={gettext(`Some banks or countries require certain specific
                        information to route funds to your account.
                        Please contact your bank for more information.
                      `)}
                        />
                      </div>
                      <RenderIf
                        checkFn={(c, cur) =>
                          !(
                            DisbursementConstants.fieldCountries.ABA_ROUTING.includes(c) &&
                            cur === 'usd'
                          )
                        }
                        fnArgs={[this.props.country, this.props.currency]}
                      >
                        {/* Checkbox for intermediary bank */}
                        <div className="disbursementForm-field">
                          <Field
                            name="intermediaryBankNeeded"
                            label={gettext('Funds will be going through an intermediary bank')}
                            component={Checkbox}
                            disabled={submitting}
                            tooltip={gettext(`For international wires, an intermediary bank may be involved
                          in routing the funds to your account. Please contact your bank for more
                          information.
                        `)}
                          />
                        </div>
                        {/* Intermediary Bank Fields */}
                        {this.renderIntermediaryBankFields()}
                      </RenderIf>

                      <RenderIf
                        checkFn={(country, currency) =>
                          Boolean(this.getDisbursementFee(country, currency))
                        }
                        fnArgs={[this.props.country, this.props.currency]}
                      >
                        <div className="disbursementForm-fee-text">
                          {gettext(`${(this.props.currency || '').toUpperCase()} transfer to a
                          bank account in ${lookupCountry(this.props.country).name} will incur a
                          ${this.getDisbursementFee(
                            this.props.country,
                            this.props.currency
                          )} fee.`)}
                        </div>
                      </RenderIf>
                    </RenderIf>
                  </fieldset>
                  <button
                    className="disbursementForm-btn btn btn--secondary btn--large"
                    disabled={submitting || !this.props.currency}
                  >
                    {submitting ? <PulseLoader loading /> : gettext('Save Changes')}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </section>
    );
  }
}

const InternationalWireFormContainer = reduxForm({
  initialValues: {
    country: CountryToAlpha2[Object.keys(CountryToAlpha2)[0]],
  },
  enableReinitialize: true,
})(
  formValues(
    'country',
    'currency',
    'intermediaryBankNeeded',
    'intermediaryBank.country'
  )(InternationalWireForm)
);

InternationalWireFormContainer.propTypes = {
  form: PropTypes.string.isRequired,
};

export default InternationalWireFormContainer;
