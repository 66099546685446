import React from 'react';
import Icon from 'spa/components/Icon';

const FormWarningBox = ({ warning }) => (
  <div className="defaultForm-messageBox defaultForm-messageBox--warning">
    <div className="defaultForm-messageBox-icon-wrapper">
      <Icon name="ui-alert-circle" className="defaultForm-messageBox-icon" />
    </div>
    <span className="defaultForm-messageBox-msg">{warning}</span>
  </div>
);

export default FormWarningBox;
