import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const A = ({ children, link, dispatch, ...attributes }) => {
  let onClick = () => true;
  if (link.onClickAction) {
    onClick = () => dispatch(link.onClickAction);
  }

  if (link.type === 'app') {
    return (
      <Link
        to={link.route}
        role="button"
        tabIndex={0}
        {...attributes}
        {...(link.newTab && { target: '_blank' })}
        onClick={onClick}
      >
        {children}
      </Link>
    );
  }
  return (
    <a
      href={link.route}
      role="button"
      tabIndex={0}
      {...attributes}
      {...(link.newTab && { target: '_blank' })}
      onClick={onClick}
    >
      {children}
    </a>
  );
};

A.propTypes = {
  children: PropTypes.node,
  link: PropTypes.shape({
    type: PropTypes.string,
    route: PropTypes.string,
    newTab: PropTypes.bool,
    onClickAction: PropTypes.object, // an action to be dispatched
  }).isRequired,
};

export default connect()(A);
