import React from 'react';
import classnames from 'classnames';
import InfoTooltip from './InfoTooltip';
import FieldError from './FieldError';

import Alpha3ToPhone from '../../constants/PhoneCountryCodes';

class PhoneSelect extends React.Component {
  constructor(props) {
    super(props);

    const options = {};
    for (const [key, value] of Object.entries(Alpha3ToPhone)) {
      options[key] = {
        prefix: `+${value.countryCode}`,
        label: `${value.name} +${value.countryCode}`,
      };
    }

    this.state = {
      selectedValue: props.input.value,
    };

    this.options = options;
    this.handleChange = this.handleChange.bind(this);
    this.renderSelectOptions = this.renderSelectOptions.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ selectedValue: nextProps.input.value });
  }

  handleChange(event) {
    const { input } = this.props;
    this.setState({ selectedValue: event.target.value });
    input.onChange(event.target.value);
  }

  renderSelectOptions() {
    const domOptions = [];
    for (const [key, value] of Object.entries(this.options)) {
      const innerHTML = this.state.selectedValue === key ? value.prefix : value.label;

      domOptions.push(
        <option key={key} value={key} data-country={key}>
          {innerHTML}
        </option>
      );
    }
    return domOptions;
  }

  render() {
    const {
      label,
      input,
      meta: { error, touched, warning },
      requiredIndicator,
      tooltip,
      prefix,
      className,
      disabled,
      children,
    } = this.props;

    const classes = classnames('defaultSelect--form', {
      'defaultSelect--flags': this.state.selectedValue,
      'defaultSelect-phonePrefix': this.state.selectedValue,
    });
    const options = this.renderSelectOptions();

    return (
      <div
        className={classnames('field', className, {
          'is-invalid': (error || warning) && touched,
        })}
        key={input.name}
      >
        {label && (
          <label htmlFor={input.name} className="field-label">
            {label}
            {children}
            {requiredIndicator && <span className="field-required">*</span>}
            {tooltip && <InfoTooltip id={`${name}-field-input-tooltip`} message={tooltip} />}
          </label>
        )}
        <div className="field-input">
          {prefix && <div className="field-prefix">{prefix}</div>}
          <div className={`defaultSelect ${classes}`}>
            <span
              className="defaultSelect-flag"
              data-select-icon={input.name}
              data-select-value={this.state.selectedValue}
            />
            <select
              id={input.name}
              name={input.name}
              className="defaultSelect-select defaultSelect-select--small"
              data-target="field-focusable"
              onChange={this.handleChange}
              value={this.state.selectedValue}
              data-e2e-target={this.props['data-e2e-target']}
              disabled={disabled}
            >
              {this.props.placeholder && (
                <option value="" disabled>
                  {this.props.placeholder}
                </option>
              )}
              {options}
            </select>
          </div>
        </div>
        {error && touched && <FieldError message={error} />}
        {warning && touched && <FieldError message={warning} />}
      </div>
    );
  }
}

export default PhoneSelect;
