export default {
  MIN_BREAKPOINT: {
    MOBILE: 480,
    TABLET: 680,
    DESKTOP: 960,
  },
  MAX_BREAKPOINT: {
    PHONE: 479,
    MOBILE: 679,
    TABLET: 959,
  },
};
