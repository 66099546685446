import AppDispatcher from '../dispatcher/AppDispatcher';
import ModalConstants from '../constants/ModalConstants';
import NavigationActions from './NavigationActions';

export class ModalActions {
  open(name) {
    AppDispatcher.handleViewAction({
      actionType: ModalConstants.MODAL_OPEN,
      name: name,
    });
  }

  toggle(name) {
    AppDispatcher.handleViewAction({
      actionType: ModalConstants.MODAL_TOGGLE,
      name: name,
    });
  }

  close(name) {
    AppDispatcher.handleViewAction({
      actionType: ModalConstants.MODAL_CLOSE,
      name: name,
    });
    NavigationActions.closeModal(name);
  }

  registerModal(name, persistent) {
    AppDispatcher.handleViewAction({
      actionType: ModalConstants.MODAL_REGISTER,
      name: name,
      persistent: persistent,
    });
  }
}

export default new ModalActions();
