import React from 'react';
import DropdownBubble from 'spa/components/DropdownBubble/DropdownBubble';

const TransactionsTourStep = ({
  visible,
  onNext,
  onClose,
  title,
  step,
  buttonText,
  horizontalPosition,
  children,
}) => (
  <DropdownBubble
    visible={visible}
    horizontalPosition={horizontalPosition || 'left'}
    onClose={onClose}
  >
    <div className="transactions-modal-steps-title">{title}</div>
    <div className="transactions-modal-steps-description">{children}</div>
    <div className="transactions-modal-steps-pagination">{`${step} of 5`}</div>
    <button onClick={onNext} className="btn btn--secondary transactions-modal-steps-button">
      {buttonText || 'Next'}
    </button>
  </DropdownBubble>
);

export default TransactionsTourStep;
