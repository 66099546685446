import ModalConstants from 'spa/constants/ModalConstants';

function modalReducer(state = {}, action) {
  switch (action.type) {
    case ModalConstants.INIT_MODAL:
      return Object.assign({}, state, {
        [action.name]: {
          show: false,
        },
      });
    case ModalConstants.TOGGLE_MODAL:
      return Object.assign({}, state, {
        [action.name]: {
          show: !state[action.name].show,
        },
      });
    default:
      return state;
  }
}

export default modalReducer;
