import React from 'react';
import { connect } from 'react-redux';

import {
  acceptOfferFromManagement,
  rejectOffer,
  cancelOffer,
  payOfferTransaction,
} from 'spa/actions/OfferActions';
import { custEmailSelector } from 'spa/selectors/CustomerSelectors';
import {
  activeTransIdSelector,
  auctionsByIdSelector,
  auctionIdSelector,
  auctionTokenSelector,
  auctionTransIdsSelector,
  offersByTransIdSelector,
  getOffersByAuctionIdSelector,
} from 'spa/selectors/OfferSelectors';
import Alert from 'spa/components/Alert';
import { Spinner } from 'spa/components/Indicators';
import { OfferList } from 'spa/components/Offer';
import { getCurrencyTagFromApiCurrency } from 'escrow-common-js/dist/utils';

const OfferListContainer = (props) => {
  if (!props.auctionsById || !props.auctionId || !props.auctionsById[props.auctionId]) {
    return null;
  }
  const auctionBody = props.auctionsById[props.auctionId];
  const currency = getCurrencyTagFromApiCurrency(auctionBody.currency);
  const transId =
    props.activeTransId || (props.auctionTransIds.length > 0 && props.auctionTransIds[0]);
  const offers = props.offersByTransId ? props.offersByTransId[transId] || [] : [];
  offers.sort((a, b) => b.dateCreated - a.dateCreated);

  const { getOffers } = props.requests;
  const viewerParty = auctionBody.parties.filter(
    (party) => party.customer === props.viewerEmail && ['buyer', 'seller'].includes(party.role)
  );
  const viewerRole = viewerParty.length === 0 ? 'buyer' : viewerParty[0].role;

  if (getOffers.error) {
    return <Alert type="error">{getOffers.errorMessage}</Alert>;
  }
  if (getOffers.loading) {
    return <Spinner />;
  }

  return (
    <div data-tracking-section="offer-management-offer-list-section">
      <OfferList
        auctionId={props.auctionId}
        offers={offers}
        currency={currency}
        actions={{
          accept: props.acceptOffer,
          reject: props.rejectOffer,
          cancel: props.cancelOffer,
          pay: props.payOfferTransaction,
        }}
        viewerEmail={props.viewerEmail}
        viewerRole={viewerRole}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  auctionsById: auctionsByIdSelector(state),
  auctionId: auctionIdSelector(state),
  auctionToken: auctionTokenSelector(state),
  auctionTransIds: auctionTransIdsSelector(state),
  activeTransId: activeTransIdSelector(state),
  offersByTransId: offersByTransIdSelector(state),
  viewerEmail: custEmailSelector(state),
  requests: {
    getOffers: getOffersByAuctionIdSelector(state, auctionIdSelector(state)),
  },
});

const mapDispatchToProps = (dispatch) => ({
  acceptOffer: (auctionId, offerId) => {
    dispatch(acceptOfferFromManagement({ auctionId, offerId }));
  },
  rejectOffer: (auctionId, offerId, transactionId) => {
    dispatch(rejectOffer({ auctionId, offerId, transactionId }));
  },
  cancelOffer: (auctionId, offerId, transactionId) => {
    dispatch(cancelOffer({ auctionId, offerId, transactionId }));
  },
  payOfferTransaction: (transactionId) => {
    dispatch(payOfferTransaction({ transactionId }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OfferListContainer);
