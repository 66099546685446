import React from 'react';

const AccountHeader = ({ name }) => (
  <header className="pageHeader">
    <div className="pageHeader-inner section-container">
      <div className="pageHeader-content">
        <h1 className="pageHeader-title">Account Settings</h1>
        {name ? (
          <p className="pageHeader-desc">
            Welcome, <strong>{name}</strong>
          </p>
        ) : (
          <p className="pageHeader-desc">&nbsp;</p>
        )}
      </div>
    </div>
  </header>
);

export default AccountHeader;
