import React, { Fragment } from 'react';

import PhoneNumberInput from 'react-phone-input-2';
import { FieldError } from './FieldError';
import { errorMessage } from '../../utils';

const PhoneNumberField = ({ disabled, error, onChange, value }) => (
  <Fragment>
    <PhoneNumberInput
      disabled={disabled}
      inputClass={`react-tel-input${disabled ? '-disabled' : ''}${error ? '-invalid-number' : ''}`}
      disableCountryGuess
      enableSearch
      onChange={onChange}
      value={value}
      enableTerritories
    />
    <FieldError error={error} text={errorMessage(error)} />
  </Fragment>
);

export default PhoneNumberField;
