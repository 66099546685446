import velocity from 'velocity-animate';
import document from 'document-shim';
import window from 'window-shim';
import QueryString from 'query-string';

import { GlobalEventHandler } from '../utils/EventHandler';
import AuthenticationActions from '../actions/AuthenticationActions';
import ModalActions from '../actions/ModalActions';
import NavigationActions from '../actions/NavigationActions';
import NavigationConstants from '../constants/NavigationConstants';
import TransactionActions from '../actions/TransactionActions';
import FormActions from '../actions/FormActions';
import APIKeyActions from '../actions/APIKeyActions';
import SearchConstants from '../constants/SearchConstants';
import SearchInputActions from '../actions/SearchInput';
import SearchInputStore from '../stores/SearchInputStore';

export default class ButtonReactor {
  navigateLoggedIn(event) {
    event.preventDefault();
    const loginPage = event.target.getAttribute('data-auth-required');
    const authPage = event.target.getAttribute('href');
    if (authPage) {
      AuthenticationActions.setAuthenticationUrl(authPage);
      if (loginPage) {
        NavigationActions.setLoginLocation(loginPage);
      }
      window.location.href = authPage + window.location.search;
    } else {
      NavigationActions.navigateLoggedIn(loginPage);
    }
  }

  openModal(event) {
    event.preventDefault();
    let name;
    let toggle;
    function getModal(element) {
      name = element.attributes.getNamedItem('data-target-modal');
      toggle = element.attributes.getNamedItem('data-target-modal-toggle') !== null;
      if (name === null) {
        return getModal(element.parentElement);
      }
      return name.value;
    }

    getModal(event.target);
    const modalName = `modal-${name.value}`;
    if (toggle) {
      ModalActions.toggle(modalName);
    } else if (modalName === 'modal-searchOverlay') {
      if (SearchInputStore.getInput() === '') {
        SearchInputActions.invalidate();
      } else {
        ModalActions.open(modalName);
      }
    } else {
      ModalActions.open(modalName);
    }
    return true;
  }

  search(event) {
    event.preventDefault();

    function getTerm(element) {
      const name = element.attributes.getNamedItem('data-search-term');
      if (name === null) {
        return getTerm(element.parentElement);
      }
      return name.value;
    }

    const searchTerm = getTerm(event.target);
    SearchInputActions.search(searchTerm, SearchConstants.CATEGORY_MENU);
    SearchInputActions.updateInputs();
    ModalActions.open('modal-searchOverlay');
  }

  scrollTo(event, matchedElement) {
    event.preventDefault();
    const targetSelector = matchedElement.getAttribute('data-scroll-to');
    velocity(document.querySelector(targetSelector), 'scroll', { duration: 500, offset: -90 });

    const targetFocusSelector = matchedElement.getAttribute('data-focus');
    if (targetFocusSelector) {
      document.querySelector(targetFocusSelector).focus();
    }
  }

  formSave(event) {
    event.preventDefault();
    FormActions.saveForm(event.target.getAttribute('data-form-save'));
  }

  formReset(event) {
    event.preventDefault();
    FormActions.resetForm(event.target.getAttribute('data-form-reset'));
  }

  loginContinue() {
    NavigationActions.loginRedirect();
  }

  startTransaction(event) {
    event.preventDefault();
    NavigationActions.startTransactionRedirect();
  }

  clear24HrNotice(event) {
    event.preventDefault();
    const query = QueryString.parse(window.location.search);
    TransactionActions.clear24HrNotice(event.target.getAttribute('data-form-name'), query.tid);
  }

  partnerLandingPageStart(event) {
    event.preventDefault();
    NavigationActions.partnerLandingPageStart(
      NavigationConstants[event.target.getAttribute('data-transaction-category')]
    );
  }

  removeAPIKey(event, matchedElement) {
    event.preventDefault();
    const apiKeyID = matchedElement.getAttribute('data-api-key-remove');
    APIKeyActions.removeAPIKey(apiKeyID);
  }

  reloadPage() {
    window.location.reload();
  }
}

const buttonReactor = new ButtonReactor();

GlobalEventHandler.addEventListener(
  'click',
  '[data-auth-required]',
  buttonReactor.navigateLoggedIn
);

GlobalEventHandler.addEventListener('click', '[data-target-modal]', buttonReactor.openModal);

GlobalEventHandler.addEventListener('click', '[data-search-term]', buttonReactor.search);

GlobalEventHandler.addEventListener('click', '[data-scroll-to]', buttonReactor.scrollTo);

GlobalEventHandler.addEventListener('click', '[data-form-save]', buttonReactor.formSave);

GlobalEventHandler.addEventListener('click', '[data-form-reset]', buttonReactor.formReset);

GlobalEventHandler.addEventListener(
  'click',
  '[data-target="login-redirect"]',
  buttonReactor.loginContinue
);

GlobalEventHandler.addEventListener(
  'click',
  '[data-target="start-transaction"]',
  buttonReactor.startTransaction
);

GlobalEventHandler.addEventListener(
  'click',
  '[data-target="clear-24-hr-notice"]',
  buttonReactor.clear24HrNotice
);

GlobalEventHandler.addEventListener(
  'click',
  '[data-target="partner-landing-page-start"]',
  buttonReactor.partnerLandingPageStart
);

GlobalEventHandler.addEventListener('click', '[data-api-key-remove]', buttonReactor.removeAPIKey);

GlobalEventHandler.addEventListener(
  'click',
  '[data-target="window-reload"]',
  buttonReactor.reloadPage
);
