import { connect } from 'react-redux';
import { buyerBankDetailsSubmission } from 'spa/selectors/PaymentSelectors';
import { checkoutGetWireDetailsSelector } from 'spa/selectors/CheckoutSelectors';
import WireTransferFieldset from '../../components/WireTransfer/WireTransferFieldset';

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const mapStateToProps = (state) => ({
  bankSubmitting: buyerBankDetailsSubmission(state).submitting,
  buyerBankSubmissionError: buyerBankDetailsSubmission(state).error,
  buyerBankSubmissionErrorMsg: buyerBankDetailsSubmission(state).errorMessage,
  getWireDetailsError: checkoutGetWireDetailsSelector(state).error,
  getWireDetailsErrorMsg: checkoutGetWireDetailsSelector(state).errorMessage,
});

export default connect(mapStateToProps, mapDispatchToProps)(WireTransferFieldset);
