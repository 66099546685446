import React, { Fragment, useState, useEffect } from 'react';
import classnames from 'classnames';
import API from '../../../api';

const LandingPageTokenHeader = (props) => {
  const { landingPageToken } = props;
  const [renderLogo, setRenderLogo] = useState(false);
  const [isUpgradedPartner, setIsUpgradedPartner] = useState(null);

  useEffect(() => {
    if (landingPageToken) {
      API.isUpgradedPartnerToken(landingPageToken).then((response) => {
        setIsUpgradedPartner(response);
      });
    }
  }, [landingPageToken]);

  return (
    <Fragment>
      <div className={classnames('checkout-header', { 'is-hidden': !renderLogo })}>
        <div className="checkout-header-inner">
          {landingPageToken && (
            <div className="checkout-header-logo">
              <img
                className="checkout-header-logo-img"
                alt="Partner Logo"
                src={`/api/landing-page-token/${landingPageToken}/logo`}
                onLoad={() => setRenderLogo(true)}
              />
            </div>
          )}
        </div>
      </div>
      {isUpgradedPartner && (
        <div className="checkout-header-copy">
          To ensure the security of your Milestone Payment, you are required to create an Escrow.com
          account. You will be redirected back to Freelancer.com once you have created this account.
        </div>
      )}
    </Fragment>
  );
};

export default LandingPageTokenHeader;
