export default {
  DAILY: {
    text: 'Day',
    value: 'daily',
    format: ['days', 'YYYY-MM-DD'],
  },
  MONTHLY: {
    text: 'Month',
    value: 'monthly',
    format: ['months', 'YYYY-MM'],
  },
  YEARLY: {
    text: 'Year',
    value: 'yearly',
    format: ['years', 'YYYY'],
  },
  RANGE: {
    THIS_YEAR: { value: 'THIS_YEAR', label: 'This Year', format: 'MMM' },
    LAST_YEAR: { value: 'LAST_YEAR', label: 'Last Year', format: 'MMM' },
    LAST_30DAYS: { value: 'LAST_30DAYS', label: 'Last 30 Days', format: 'MMM Do' },
    ALL_TIME: { value: 'ALL_TIME', label: 'All Time', format: 'YYYY' },
  },
};
