import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  useMediaQuery,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { EscrowAlert } from 'spa/features/ui/EscrowAlert';
import AlertTitle from '@mui/material/AlertTitle';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { usePatchTransactionMutation } from '../../transactionApi';
import { transactionSelector } from '../../transactionSlice';
import { ContentCopy } from '../item-acceptance/ItemAcceptance';
import { EscrowButton, EscrowLinkButton } from 'spa/features/ui/EscrowButton';
import { useTheme } from '@mui/material/styles';

const CONTENT = {
  domain_name: {
    buyer: {
      title: 'Confirm rejection of the domain name',
      body: 'By rejecting the domain name, you will have to process a return to the seller. Please provide a reason for your rejection below.',
    },
    seller: {
      title: 'Confirm rejection of the returned domain name',
      body: 'By rejecting the returned domain name, this transaction will undergo dispute. Please provide a reason for your rejection below.',
    },
  },
  motor_vehicle: {
    buyer: {
      title: 'Confirm rejection of the ${transactionType}',
      body: 'By rejecting the ${transactionType}, you will have to process a return to the seller. Please provide a reason for your rejection below.',
    },
    seller: {
      title: 'Confirm rejection of the returned ${transactionType}',
      body: 'By rejecting the returned ${transactionType}, this transaction will undergo dispute. Please provide a reason for your rejection below.',
    },
  },
  general_merchandise: {
    buyer: {
      title: 'Confirm rejection of the ${transactionType}',
      body: 'By rejecting the ${transactionType}, you will have to process a return to the seller. Please provide a reason for your rejection below.',
    },
    seller: {
      title: 'Confirm rejection of the returned ${transactionType}',
      body: 'By rejecting the returned ${transactionType}, this transaction will undergo dispute. Please provide a reason for your rejection below.',
    },
  },
};

export const ItemRejection = ({ transactionId, userRole }) => {
  const theme = useTheme();
  const isMobileViewport = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState(false);
  const [textInput, setTextInput] = useState('');
  const [patchTransaction, { isLoading, error }] = usePatchTransactionMutation();
  const transaction = useSelector(transactionSelector(transactionId));
  const [errorMsg, setErrorMsg] = useState('');
  if (!transaction) {
    return null;
  }

  const { title, body } = CONTENT[transaction.transactionTypeCode][userRole] || {};

  const handleInput = (event) => {
    const value = event.target.value;
    setTextInput(value);
  };

  const handleModalClose = () => {
    if (isLoading) {
      return;
    }
    setTextInput('');
    setOpen(false);
  };

  const handleReject = async () => {
    try {
      await patchTransaction({
        id: transaction.id,
        ...(userRole === 'buyer' && { action: 'reject' }),
        ...(userRole === 'seller' && { action: 'reject_return' }),
        ...(textInput !== '' && { rejection_information: { rejection_reason: textInput } }),
      }).unwrap();
      setOpen(false);
    } catch (e) {
      let errorMsgTmp =
        "Your rejection couldn't be confirmed due to a technical issue. Please refresh the page and try again.";
      if (e.xRequestId) {
        errorMsgTmp += ` If the issue persists, contact support@escrow.com and provide the id: ${e.xRequestId}`;
      }
      setErrorMsg(errorMsgTmp);
    }
  };

  if (transaction.usesEbayAuthenticator) {
    let rejectUrl = 'https://www.ebay.com';
    if (transaction.merchantUrl) {
      const match = transaction.merchantUrl.match(/orderid=([0-9-]+)/);
      if (match !== null) {
        rejectUrl = `https://www.ebay.com/vod/FetchOrderDetails?orderid=${match[1]}`;
      }
    }
    return (
      <EscrowLinkButton
        variant="text"
        to={rejectUrl}
        buttonText="Proceed to rejection"
        disabled={isLoading}
        hard
      />
    );
  }
  return (
    <React.Fragment>
      <EscrowButton
        sx={{ paddingLeft: '12px', paddingRight: '12px' }}
        onClick={() => setOpen(true)}
      >
        Reject
      </EscrowButton>
      <Dialog open={open} onClose={handleModalClose} maxWidth="md" fullScreen={isMobileViewport}>
        {isMobileViewport && (
          <IconButton
            sx={{ marginLeft: 'auto' }}
            onClick={handleModalClose}
            aria-label="Close Item Rejection Modal"
          >
            <CloseIcon sx={{ color: 'mono.dark', height: 24, width: 24 }} />
          </IconButton>
        )}
        {error && (
          <EscrowAlert>
            <AlertTitle>
              Unable to reject the {transaction.transactionTypeCode.replace('_', ' ')}
            </AlertTitle>
            {errorMsg}
          </EscrowAlert>
        )}
        <DialogTitle>
          {title && (
            <ContentCopy text={title} transactionType={transaction.transactionTypeCode} isTitle />
          )}
        </DialogTitle>
        <DialogContent>
          {body && <ContentCopy text={body} transactionType={transaction.transactionTypeCode} />}
          <Box sx={{ marginTop: '24px' }}>
            <TextField
              fullWidth
              label="Rejection reason (Optional)"
              variant="outlined"
              value={textInput}
              onChange={handleInput}
              sx={{
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.secondaryLight.dark,
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: theme.palette.secondaryLight.dark,
                },
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          {!isMobileViewport && <EscrowButton onClick={handleModalClose}>Back</EscrowButton>}
          <EscrowButton
            sx={{ width: isMobileViewport ? '100%' : 'auto' }}
            variant="containedError"
            disabled={isLoading}
            onClick={handleReject}
          >
            Reject {transaction.transactionTypeCode.replace(/_/g, ' ')}
          </EscrowButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
