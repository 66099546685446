import { delay } from 'redux-saga';
import { put, take, takeLatest, call, race, select } from 'redux-saga/effects';

import PaymentConstants from 'spa/constants/PaymentConstants';
import {
  submitCreditCardPayment as submitCreditCardPaymentRoutine,
  submitPoliPayment as submitPoliPaymentRoutine,
  submitPlaidPayment as submitPlaidPaymentRoutine,
} from 'spa/actions/TransactionActions';
import {
  getOutstandingBalance as getOutstandingBalanceRoutine,
  fetchTransactionAndPaymentMethods as fetchTransactionAndPaymentMethodsRoutine,
  selectPaymentMethod as selectPaymentMethodRoutine,
} from 'spa/actions/PaymentsActions';
import { transactionDraftSelector } from 'spa/selectors/TransactionSelectors';
import { getPaymentMethods, getTransactionById } from 'spa/actions/TransactionActions';
import { logError } from 'spa/actions/ErrorLoggingActions';
import APIConstants from 'spa/constants/APIConstants';
import TransactionConstants from 'spa/constants/TransactionConstants';
import ErrorMessages from '../../constants/ErrorMessages';

import API from '../../api';

export function* getOutstandingBalance(action) {
  try {
    const outstandingBalance = yield call(API.getOutstandingBalance, action.payload.transId);
    if (outstandingBalance.outstanding_balance <= 0) {
      throw new Error();
    }
    yield put(getOutstandingBalanceRoutine.success(outstandingBalance.outstanding_balance));
  } catch (error) {
    yield put(getOutstandingBalanceRoutine.failure());
    yield put(logError(error));
  }
}

function* fetchTransactionAndPaymentMethods(action) {
  const transId = action.payload.transId;
  yield put(fetchTransactionAndPaymentMethodsRoutine.request());
  yield put(getTransactionById({ transId }));
  const { getTransactionFailure } = yield race({
    success: take(getTransactionById.SUCCESS),
    getTransactionFailure: take(getTransactionById.FAILURE),
  });
  if (getTransactionFailure) {
    const error = { type: 'get-transaction-failure' };
    yield put(fetchTransactionAndPaymentMethodsRoutine.failure({ error }));
  } else {
    yield put(getPaymentMethods({ transId }));
    const { getPaymentMethodsFailure } = yield race({
      success: take(getPaymentMethods.SUCCESS),
      getPaymentMethodsFailure: take(getPaymentMethods.FAILURE),
    });
    if (getPaymentMethodsFailure) {
      const error = { type: 'get-payment-methods-failure' };
      yield put(fetchTransactionAndPaymentMethodsRoutine.failure({ error }));
    } else {
      yield put(fetchTransactionAndPaymentMethodsRoutine.success());
    }
  }
  yield put(fetchTransactionAndPaymentMethodsRoutine.fulfill());
}

export function* selectPaymentMethodSaga(action) {
  try {
    const {
      props: { isOutstandingBalance: isOutstandingPayment },
      skipRedirect,
      v2Flow,
    } = action.payload;
    let {
      props: { transactionId },
      values: { paymentType, paymentId },
    } = action.payload;

    let draft;

    if (!transactionId) {
      draft = yield select(transactionDraftSelector);
      transactionId = draft.id;
    }

    let landingPage = '';
    let paymentConfirmationData = '';

    if (!paymentId) {
      const [isSaved, savedMethod, id] = paymentType.match(/^saved-(\w+)-(\w+)/) || [];
      if (isSaved) {
        paymentType = savedMethod;
        paymentId = id;
      } else {
        paymentId = 'new';
      }
    }

    switch (paymentType) {
      case PaymentConstants.PAYMENT_METHODS.DIRECT_DEBIT: {
        if (draft && draft.return_url) {
          landingPage = draft.return_url;
        } else {
          landingPage = `${window.config.www_base_url}/transaction/${transactionId}`;
        }
        yield put(
          submitPlaidPaymentRoutine.trigger({
            transId: transactionId,
            plaidAccountId: paymentId,
            isOutstandingPayment,
          })
        );
        const { ddFailure } = yield race({
          ddSuccess: take(submitPlaidPaymentRoutine.SUCCESS),
          ddFailure: take(submitPlaidPaymentRoutine.FAILURE),
        });
        if (ddFailure) {
          throw ddFailure;
        }
        break;
      }
      case PaymentConstants.PAYMENT_METHODS.CHECK:
      case PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER:
        if (draft && draft.return_url) {
          landingPage = draft.return_url;
        } else {
          landingPage = `${window.config.www_base_url}/transaction/${transactionId}`;
        }
        yield call(API.setPaymentMethod, transactionId, paymentType);
        break;
      case PaymentConstants.PAYMENT_METHODS.PAYPAL: {
        if (v2Flow) {
          const orderCapturedData = yield call(API.paypalCaptureOrder, paymentId, transactionId);
          const paypalPaymentId = orderCapturedData.purchase_units[0].payments.captures[0].id;
          yield call(API.setPaymentMethod, transactionId, paymentType);
          yield call(API.paypalSuccess, transactionId, paypalPaymentId);
          break;
        }
        let returnUrl;
        let redirectType;
        if (draft) {
          returnUrl = draft.return_url;
          redirectType = draft.redirect_type;
        }
        paymentConfirmationData = yield call(
          API.getPaypalPortal,
          transactionId,
          returnUrl,
          redirectType
        );
        landingPage = paymentConfirmationData.landing_page;
        break;
      }
      case PaymentConstants.PAYMENT_METHODS.CREDIT_CARD: {
        if (draft && draft.return_url) {
          landingPage = draft.return_url;
        } else {
          landingPage = `${window.config.www_base_url}/transaction/${transactionId}`;
        }
        yield put(
          submitCreditCardPaymentRoutine.trigger({
            values: {
              ...action.payload.values,
              cardId: paymentId,
            },
            transId: transactionId,
            returnUrl: landingPage,
            skipRedirect: true,
            isOutstandingPayment,
          })
        );
        const { ccFailure, timeout } = yield race({
          ccSuccess: take(submitCreditCardPaymentRoutine.SUCCESS),
          ccFailure: take(submitCreditCardPaymentRoutine.FAILURE),
          timeout: delay(30000),
        });
        if (ccFailure) {
          throw ccFailure;
        } else if (timeout) {
          throw new Error('CC Payment Timed out');
        }
        break;
      }
      case PaymentConstants.PAYMENT_METHODS.POLI: {
        yield put(
          submitPoliPaymentRoutine.trigger({
            transId: transactionId,
            userFlow: TransactionConstants.POLI_PAYMENT_FLOWS.escrow_pay,
            isOutstandingPayment: true,
          })
        );
        const { poliSuccess, poliFailure } = yield race({
          poliSuccess: take(submitPoliPaymentRoutine.SUCCESS),
          poliFailure: take(submitPoliPaymentRoutine.FAILURE),
        });
        if (poliFailure) {
          throw poliFailure;
        }
        landingPage = poliSuccess.payload.landing_url;
        break;
      }
      default:
        landingPage = `${window.config.www_base_url}/transactions`;
    }

    yield put(selectPaymentMethodRoutine.success());
    if (!skipRedirect && landingPage) {
      yield delay(3000);
      yield window.location.assign(landingPage);
    }
  } catch (error) {
    let errorMessage = ErrorMessages.TECHNICAL_DIFFICULTIES;
    if (error.type === APIConstants.BAD_REQUEST) {
      errorMessage = error.errors.error;
    } else if (error.type === APIConstants.UNPROCESSABLE_ENTITY) {
      errorMessage = error.errors.error;
    } else if (error.type === APIConstants.UNHANDLED) {
      errorMessage = error.errors.error;
    } else if (error.type === submitCreditCardPaymentRoutine.FAILURE) {
      errorMessage = error.payload.errors._error;
    } else if (error.type === submitPlaidPaymentRoutine.FAILURE) {
      errorMessage = error.payload.errors._error;
    }

    yield put(selectPaymentMethodRoutine.failure(errorMessage));
  }
  yield put(selectPaymentMethodRoutine.fulfill());
}

export function* paymentsPageWatcher() {
  yield takeLatest(getOutstandingBalanceRoutine.TRIGGER, getOutstandingBalance);
  yield takeLatest(
    fetchTransactionAndPaymentMethodsRoutine.TRIGGER,
    fetchTransactionAndPaymentMethods
  );
  yield takeLatest(selectPaymentMethodRoutine.TRIGGER, selectPaymentMethodSaga);
}

export default [paymentsPageWatcher];
