import React from 'react';
import { Field } from 'redux-form';
import { Currency } from 'escrow-common-js/dist/constants';

import Select from './Select';

const CurrencySelect = () => (
  <Field
    name="currency"
    component={Select}
    label="Currency Type"
    flag="USD"
    options={Object.values(Currency).map((currency) => ({ value: currency, label: currency }))}
  />
);

export default CurrencySelect;
