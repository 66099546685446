export default {
  SEARCH_FIELD_CHANGED: 'SEARCH_FIELD_CHANGED',
  SELECT_CATEGORY: 'SELECT_CATEGORY',

  VEHICLE_TRANSACTION_CATEGORIES: [
    {
      label: 'Automobiles, Cars & Trucks',
      value: 'automobiles_cars_and_trucks',
    },
    {
      label: 'Boats',
      value: 'boats',
    },
    {
      label: 'Motorcycles',
      value: 'motorcycles',
    },
    {
      label: 'Airplanes',
      value: 'airplanes',
    },
    {
      label: 'Other Motor Vehicles',
      value: 'other_motor_vehicles',
    },
  ],

  GENERAL_TRANSACTION_CATEGORIES: [
    {
      label: 'Antiques & Collectibles',
      value: 'antiques_and_collectibles',
    },
    {
      label: 'Appliances',
      value: 'appliances',
    },
    {
      label: 'Adult Material',
      value: 'adult_material',
    },
    {
      label: 'Alcohol & Wine',
      value: 'alcohol_and_wine',
    },
    {
      label: 'Arts & Crafts',
      value: 'arts_and_crafts',
    },
    {
      label: 'Automotive or Industrial Parts',
      value: 'automotive_or_industrial_parts',
    },
    {
      label: 'Beauty & Health',
      value: 'beauty_and_health',
    },
    {
      label: 'Books & Magazines',
      value: 'books_and_magazines',
    },
    {
      label: 'Cigarettes & Tobacco',
      value: 'cigarettes_and_tobacco',
    },
    {
      label: 'Clothing & Accessories',
      value: 'clothing_and_accessories',
    },
    {
      label: 'Computer Hardware & Software',
      value: 'computer_hardware_and_software',
    },
    {
      label: 'Cosmetic Injectables',
      value: 'cosmetic_injectables',
    },
    {
      label: 'Cryptocurrency',
      value: 'cryptocurrency',
    },
    {
      label: 'Cryptocurrency Contracts & Hardware',
      value: 'cryptocurrency_contracts_and_hardware',
    },
    {
      label: 'Electronics',
      value: 'electronics',
    },
    {
      label: 'Gambling',
      value: 'gambling',
    },
    {
      label: 'Home & Garden',
      value: 'home_and_garden',
    },
    {
      label: 'Food',
      value: 'food',
    },
    {
      label: 'Fx & Currency',
      value: 'fx_and_currency',
    },
    {
      label: 'Financial Instruments',
      value: 'financial_instruments',
    },
    {
      label: 'Fine Art',
      value: 'fine_art',
    },
    {
      label: 'Furniture',
      value: 'furniture',
    },
    {
      label: 'Guns & Ammunition',
      value: 'guns_and_ammunition',
    },
    {
      label: 'Heavy Equipment & Machinery',
      value: 'heavy_equipment_and_machinery',
    },
    {
      label: 'Intellectual Property',
      value: 'intellectual_property',
    },
    {
      label: 'IP Addresses',
      value: 'ip_addresses',
    },
    {
      label: 'Jewellery & Watches',
      value: 'jewellery_and_watches',
    },
    {
      label: 'Mobile Apps',
      value: 'mobile_apps',
    },
    {
      label: 'Movies & Music',
      value: 'movies_and_music',
    },
    {
      label: 'Pets & Livestock',
      value: 'pets_and_livestock',
    },
    {
      label: 'Prescription Drugs',
      value: 'prescription_drugs',
    },
    {
      label: 'Real Estate',
      value: 'real_estate',
    },
    {
      label: 'Services',
      value: 'services',
    },
    {
      label: 'Social Media Accounts',
      value: 'social_media_accounts',
    },
    {
      label: 'Sports & Recreation',
      value: 'sports_and_recreation',
    },
    {
      label: 'Tickets & Events',
      value: 'tickets_and_events',
    },
    {
      label: 'Tools & Hardware',
      value: 'tools_and_hardware',
    },
    {
      label: 'Toys & Hobbies',
      value: 'toys_and_hobbies',
    },
    {
      label: 'Video Games & Consoles',
      value: 'video_games_and_consoles',
    },
  ],

  PROHIBITED_CATEGORIES: [
    'cryptocurrency',
    'gambling',
    'fx_and_currency',
    'financial_instruments',
    'guns_and_ammunition',
    'prescription_drugs',
    'real_estate',
  ],
};
