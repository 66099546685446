import React from 'react';
import classnames from 'classnames';
import { Button } from 'escrow-common-js/dist/components';
import { reduxForm, formValues } from 'redux-form';
import { bindRoutineToReduxForm } from 'redux-saga-routines';
import { FormError } from 'spa/components/form';
import { Spinner } from 'spa/components/Indicators';
import { verifyTier2AndTier3 } from 'spa/actions/UserActions';
import { submitElectronicVerificationForm } from 'spa/actions/VerificationActions';
import CheckoutConstants from 'spa/constants/CheckoutConstants';

import { ElectronicVerificationForm } from 'spa/components/CustomerVerification';
import {
  IdVerificationFieldset,
  AddressVerificationFieldset,
  CompanyVerificationFieldset,
} from 'spa/components/CustomerVerification/VerificationDetailsFieldset';

import { scrollTo } from '../../../utils/ScrollHelper';
import { gettext } from '../../../utils/filters';

class VerificationDetailsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      personalEdit: false,
      companyEdit: false,
      ekycEdit: false,
    };
    this._enableTier2Edit = this._enableTier2Edit.bind(this);
    this._enableTier3Edit = this._enableTier3Edit.bind(this);
    this._backButton = this._backButton.bind(this);
    this.submitFunction = this.submitFunction.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isCurrentStep && this.props.currentStep !== nextProps.currentStep) {
      scrollTo(this.Card);
    }
  }

  _renderTier2VerificationForm() {
    if (!this.state.personalEdit && !this.state.companyEdit && !this.state.ekycEdit) {
      return <div>{this._renderTier2CompleteCard()}</div>;
    } else if (!this.state.personalEdit) {
      return null;
    }

    const hideAddressVerificationFieldset = this.props.idContainsAddress;
    return (
      <div data-tracking-subsection="personal">
        <IdVerificationFieldset />
        <AddressVerificationFieldset hide={hideAddressVerificationFieldset} />
      </div>
    );
  }

  _renderTier2EkycForm() {
    const message =
      'We protect both sides of the transaction by verifying the identity of all users. Please provide supporting documentation to verify your customer details. This verification of your personal details does not involve a credit check and will not impact your credit score.';
    return (
      <div data-tracking-subsection="personal-details">
        <ElectronicVerificationForm
          disableEKYC={this.props.ekycDisabled}
          onSubmit={bindRoutineToReduxForm(submitElectronicVerificationForm)}
          onCancel={this._backButton}
          onCancelMsg="Back"
          onSubmitLater={() => {
            this.props.moveToNextStep();
            this._backButton();
          }}
          onSubmitSuccess={this._backButton}
          title={gettext('Document Verification')}
          desc={message}
          personalCountry={this.props.personalCountry}
          showSampleDocTooltips
          checkoutMode
          {...(this.props.ekycDisabled && {
            initialValues: { verificationMethod: 'upload-document' },
          })}
        />
      </div>
    );
  }

  _renderTier3VerificationForm() {
    if (!this.props.isCompany) {
      return null;
    }
    if (!this.state.companyEdit && !this.state.personalEdit && !this.state.ekycEdit) {
      return <div>{this._renderTier3CompleteCard()}</div>;
    } else if (!this.state.companyEdit) {
      return null;
    }

    return (
      <div data-tracking-subsection="company">
        <CompanyVerificationFieldset
          companyCountry={this.props.companyCountry}
          companyType={this.props.companyType}
          companyRelationship={this.props.companyRelationship}
        />
      </div>
    );
  }

  _enableTier2Edit() {
    if (this.props.forEKyc) {
      this.setState({ ...this.state, ekycEdit: true });
    } else {
      this.setState({ ...this.state, personalEdit: true });
    }
  }

  _renderTier2CompleteCard() {
    const { isTier2Verified, isTier2Submitted, isTier2Rejected } = this.props;
    let status = gettext('Not Verified');
    let editText = gettext('Verify now');
    let cardClass = '';

    if (isTier2Verified) {
      status = gettext('Verified');
      editText = gettext('Edit details');
      cardClass = 'is-verified';
    }
    if (isTier2Submitted) {
      status = gettext('Pending');
      editText = gettext('Edit details');
      cardClass = 'is-pending';
    }
    if (isTier2Rejected) {
      status = gettext('Rejected');
      editText = gettext('Verify now');
      cardClass = 'is-rejected';
    }
    return (
      <div className={`verifyCard card checkout-verificationsCard ${cardClass}`}>
        <header className="verifyCard-header card-inner">
          <h4 className="verifyCard-header-title">{gettext('Personal Verification')}</h4>
          <span className="verifyCard-status">{status}</span>
        </header>
        <div className="verifyCard-content card-inner">
          <div>
            <p className="verifyCard-desc">
              {gettext(
                'Verify your identity to allow payments over $3,000 to and from your ' +
                  'personal account or to pay with a Credit Card, Debit Card or Paypal for ' +
                  'transactions under $5,000.'
              )}
            </p>
            <p className="verifyCard-note">
              {gettext('Required Documents: Government issued ID, Proof of address')}
            </p>
            {!isTier2Verified && (
              <a
                href="#"
                className="verifyCard-cta"
                data-e2e-target="checkout-personal-verifyNow-link"
                onClick={this._enableTier2Edit}
                data-tracking-name={
                  this.props.isTier2Submitted || this.props.isTier2Verified ? 'edit' : 'verify'
                }
                data-tracking-subsection="personal"
              >
                {editText}
              </a>
            )}
          </div>
        </div>
      </div>
    );
  }

  _enableTier3Edit() {
    this.setState({ ...this.state, companyEdit: true });
  }

  _renderTier3CompleteCard() {
    const { isTier3Verified, isTier3Submitted, isTier3Rejected } = this.props;
    let status = gettext('Not Verified');
    let editText = gettext('Verify now');
    let cardClass = '';

    if (isTier3Verified) {
      status = gettext('Verified');
      editText = gettext('Edit details');
      cardClass = 'is-verified';
    }
    if (isTier3Submitted) {
      status = gettext('Pending');
      editText = gettext('Edit details');
      cardClass = 'is-pending';
    }
    if (isTier3Rejected) {
      status = gettext('Rejected');
      editText = gettext('Verify now');
      cardClass = 'is-rejected';
    }
    return (
      <div className={`verifyCard card checkout-verificationsCard ${cardClass}`}>
        <header className="verifyCard-header card-inner">
          <h4 className="verifyCard-header-title">{gettext('Company Verification')}</h4>
          <span className="verifyCard-status">{status}</span>
        </header>
        <div className="verifyCard-content card-inner">
          <div>
            <p className="verifyCard-desc">
              {gettext(
                'Verify your company to allow payments to and from your company bank accounts.'
              )}
            </p>
            <p className="verifyCard-note">
              {gettext(
                'Required Documents: Government issued ID, Proof of address, Company ' +
                  'registration documents'
              )}
            </p>
            {!isTier3Verified && (
              <a
                href="#"
                className="verifyCard-cta"
                data-e2e-target="checkout-company-verifyNow-link"
                onClick={this._enableTier3Edit}
                data-tracking-name={
                  this.props.isTier3Submitted || this.props.isTier3Verified ? 'edit' : 'verify'
                }
                data-tracking-subsection="company"
              >
                {editText}
              </a>
            )}
          </div>
        </div>
      </div>
    );
  }

  _renderTier2and3ActionButtons() {
    const {
      submitting,
      isCompany,
      nextStepLoading,
      isTier2Verified,
      isTier2Submitted,
      isTier3Verified,
      isTier3Submitted,
    } = this.props;
    let buttonText = gettext('Continue');
    let currentSubsection = '';
    if (this.state.personalEdit) {
      currentSubsection = 'personal';
    } else if (this.state.companyEdit) {
      currentSubsection = 'company';
    }
    if (this.state.personalEdit || this.state.companyEdit) {
      buttonText = gettext('Submit');
    }
    return (
      <div
        className="verifyPage-actions verifyPage-actions--rightHeavy"
        data-tracking-subsection={currentSubsection}
      >
        <a
          href="#"
          className={`checkout-card-secondaryAction`}
          onClick={this._backButton}
          data-tracking-name="back"
        >
          {gettext('Back')}
        </a>
        <div className="verifyPage-actions-subgroup">
          <a
            href="#"
            className={classnames(['checkout-card-secondaryAction'], {
              'is-hidden':
                this.inEditMode() ||
                !this.props.kycRequired ||
                (isTier2Submitted && isTier3Submitted) ||
                (isTier2Verified && isCompany && isTier3Verified) ||
                (isTier2Verified && !isCompany),
            })}
            onClick={this.props.moveToNextStep}
            data-tracking-name="submit-later"
          >
            {gettext('Submit Later')}
          </a>
          <Button
            type="submit"
            className="checkout-card-btn"
            disabled={
              nextStepLoading ||
              submitting ||
              (!this.inEditMode() &&
                this.props.kycRequired &&
                (!isTier2Verified || (isCompany && !isTier3Verified)))
            }
            data-e2e-target="checkout-verification-continue-submit-btn"
            data-tracking-name={this.inEditMode() ? 'submit' : 'continue'}
          >
            {nextStepLoading || submitting ? <Spinner /> : gettext(buttonText)}
          </Button>
        </div>
      </div>
    );
  }

  _backButton() {
    if (this.state.personalEdit || this.state.companyEdit || this.state.ekycEdit) {
      this.setState({
        ...this.state,
        personalEdit: false,
        companyEdit: false,
        ekycEdit: false,
      });
    } else {
      this.props.moveToPrevStep();
    }
  }

  submitFunction(values, dispatch, props) {
    let kycType = 'tier2';
    if (this.state.companyEdit) {
      kycType = 'tier3';
    }
    if (this.state.personalEdit || this.state.companyEdit) {
      return bindRoutineToReduxForm(verifyTier2AndTier3)(
        {
          ...values,
          kycType,
        },
        dispatch,
        props
      )
        .then(() => {
          this._backButton();
          return Promise.resolve();
        })
        .catch((err) => Promise.reject(err));
    }
    this.props.moveToNextStep();
  }

  inEditMode() {
    return this.state.personalEdit || this.state.companyEdit || this.state.ekycEdit;
  }

  render() {
    const { handleSubmit, submitFailed, error } = this.props;
    if (!this.props.isCurrentStep) {
      return null;
    }
    return this.props.ekycDataLoading ? (
      <div className="checkout-card-placeholder">
        <Spinner />
      </div>
    ) : (
      <div
        className={classnames('checkout-card-content--light', {
          'checkout-card-content': !this.state.ekycEdit,
        })}
      >
        <div>
          {submitFailed && error && <FormError error={error} />}
          {this.state.ekycEdit ? (
            this._renderTier2EkycForm()
          ) : (
            <form name={this.props.form} onSubmit={handleSubmit(this.submitFunction)}>
              {this._renderTier2VerificationForm()}
              {this._renderTier3VerificationForm()}
              {this._renderTier2and3ActionButtons()}
            </form>
          )}
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: CheckoutConstants.VERIFICATION_DETAILS_FORM_NAME,
})(formValues('idContainsAddress', 'companyType', 'companyRelationship')(VerificationDetailsForm));
