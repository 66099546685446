import React, { Fragment } from 'react';
import classnames from 'classnames';
import { CountryToAlpha2 } from 'escrow-common-js/dist/constants';
import { Field, FieldArray } from 'redux-form';
import { GeoipAlpha3 } from 'spa/constants/ISOCountryCodes';
import { Select, FileUpload, Checkbox, InfoTooltip, Input } from 'spa/components/form';
import A from 'spa/components/A';
import { required, oneOrMoreFiles } from 'spa/components/form/validate';
import { SampleVerificationDocument } from 'spa/components/CustomerVerification';
import {
  idTypeOptions,
  addressProofTypeOptions,
  businessTypeOptions,
  businessTypeOptionsAUS,
  businessTypeAUSDocumentNotes,
  companyRelationshipOptions,
  representativeExtraProof,
  companyProofTypeOptions,
  companyProofTypeOptionsAUS,
  individualVerificationFieldOptions,
} from 'spa/constants/VerificationConstants';
import { gettext } from '../../../utils/filters';
import { validateKYCFile } from '../../../utils/FormValidation';

export const PersonalDetailsFieldset = () => (
  <fieldset>
    <header className="verifyPage-form-header">
      <div className="verifyPage-form-group">
        <h3 className="verifyPage-form-title">{gettext('Customer Information')}</h3>
        <div className="grid grid--tight">
          <div className="grid-col grid-col--tablet-6">
            <Field
              name="firstName"
              component={Input}
              label={gettext('First Name')}
              validate={[required]}
            />
          </div>
          <div className="grid-col grid-col--tablet-6">
            <Field
              name="lastName"
              component={Input}
              label={gettext('Last Name')}
              validate={[required]}
            />
          </div>
          <div className="grid-col grid-col--tablet-6">
            <Field
              name="dateOfBirth"
              component={Input}
              label={gettext('Date of Birth')}
              validate={[required]}
            />
          </div>
          <div className="grid-col grid-col--tablet-6">
            <Field
              name="phoneNumber"
              component={Input}
              label={gettext('Phone Number')}
              validate={[required]}
            />
          </div>
          <div className="grid-col grid-col--tablet-12">
            <Field
              name="email"
              component={Input}
              label={gettext('Email')}
              validate={[required]}
              readOnly
            />
          </div>
        </div>
      </div>
      <div className="verifyPage-form-group">
        <h3 className="verifyPage-form-title">{gettext('Billing Information')}</h3>
        <div className="grid grid--tight">
          <div className="grid-col grid-col--tablet-12">
            <Field
              name="addressLine1"
              component={Input}
              label={gettext('Address Line 1')}
              validate={[required]}
            />
          </div>
          <div className="grid-col grid-col--tablet-12">
            <Field
              name="addressLine2"
              component={Input}
              label={gettext('Address Line 2')}
              validate={[required]}
            />
          </div>
          <div className="grid-col grid-col--tablet-6">
            <Field name="city" component={Input} label={gettext('City')} validate={[required]} />
          </div>
          <div className="grid-col grid-col--tablet-6">
            <Field name="state" component={Input} label={gettext('State')} validate={[required]} />
          </div>
          <div className="grid-col grid-col--tablet-9">
            <Field
              name="country"
              component={Input}
              label={gettext('Country')}
              validate={[required]}
            />
          </div>
          <div className="grid-col grid-col--tablet-3">
            <Field
              name="postcode"
              component={Input}
              label={gettext('Postcode')}
              validate={[required]}
            />
          </div>
        </div>
      </div>
    </header>
  </fieldset>
);

export const CompanyDetailsFieldset = () => (
  <fieldset>
    <header className="verifyPage-form-header">
      <div className="verifyPage-form-group">
        <h3 className="verifyPage-form-title">{gettext('Company Information')}</h3>
        <div className="grid grid--tight">
          <div className="grid-col grid-col--tablet-12">
            <Field
              name="companyName"
              component={Input}
              label={gettext('Registered Name')}
              validate={[required]}
            />
          </div>
          <div className="grid-col grid-col--tablet-12">
            <Field
              name="companyDisplayName"
              component={Input}
              label={gettext('Last Name')}
              validate={[required]}
            />
          </div>
          <div className="grid-col grid-col--tablet-12">
            <Field
              name="companyPhoneNumber"
              component={Input}
              label={gettext('Phone Number')}
              validate={[required]}
            />
          </div>
        </div>
      </div>
      <div className="verifyPage-form-group">
        <h3 className="verifyPage-form-title">{gettext('Billing Information')}</h3>
        <div className="grid grid--tight">
          <div className="grid-col grid-col--tablet-12">
            <Field
              name="companyAddressLine1"
              component={Input}
              label={gettext('Address Line 1')}
              validate={[required]}
            />
          </div>
          <div className="grid-col grid-col--tablet-12">
            <Field
              name="companyAddressLine2"
              component={Input}
              label={gettext('Address Line 2')}
              validate={[required]}
            />
          </div>
          <div className="grid-col grid-col--tablet-6">
            <Field
              name="companyCity"
              component={Input}
              label={gettext('City')}
              validate={[required]}
            />
          </div>
          <div className="grid-col grid-col--tablet-6">
            <Field
              name="companyState"
              component={Input}
              label={gettext('State')}
              validate={[required]}
            />
          </div>
          <div className="grid-col grid-col--tablet-9">
            <Field
              name="companyCountry"
              component={Input}
              label={gettext('Country')}
              validate={[required]}
            />
          </div>
          <div className="grid-col grid-col--tablet-3">
            <Field
              name="companyPostcode"
              component={Input}
              label={gettext('Postcode')}
              validate={[required]}
            />
          </div>
        </div>
      </div>
    </header>
  </fieldset>
);

export const PhotoVerificationFieldset = () => (
  <fieldset className="verifyPage-form-group">
    <header className="verifyPage-form-header">
      <div>
        <h3 className="verifyPage-form-title">{gettext('Photo Verification')}</h3>
      </div>
    </header>
    <FieldArray
      name="photoVerificationFile"
      component={FileUpload}
      label={gettext('Uploaded Files')}
      fileValidators={[validateKYCFile]}
      uploadEndpoint={`${window.config.kyc_api_endpoint}/File`}
      validate={[oneOrMoreFiles]}
      data-tracking-name="upload"
      data-tracking-value="photo"
      data-e2e-target="verification-upload-photo-button"
    />
  </fieldset>
);

export const VerificationMethodFieldset = ({
  countryAlpha2,
  onChange,
  disableEKYC,
  disableKYC,
}) => {
  const verificationMethodLabel = gettext('please select a verification method');
  return (
    <fieldset className="verifyPage-form-group">
      <Field
        name="verificationMethod"
        component={Select}
        label={gettext('Verification Method')}
        placeholder={`-- ${verificationMethodLabel} --`}
        validate={[required]}
        options={individualVerificationFieldOptions(countryAlpha2, disableEKYC, disableKYC)}
        onChange={onChange}
        disabled={disableEKYC}
        requiredIndicator
        data-tracking-name="choose-verification-method"
        data-e2e-target="verification-method-type-field"
      />
    </fieldset>
  );
};

export const DocumentFieldsFieldSet = ({ document, onFieldChange }) => {
  if (document && document.fields.length) {
    return (
      <fieldset className="verifyPage-form-group">
        <h3 className="verifyPage-form-title">{`${document.displayName} Information`}</h3>
        {document.fields.map((field) => {
          if (field.required) {
            return (
              <Fragment key={`${document.name}-${field.name}`}>
                <Field
                  name={field.name}
                  component={field.type === 'dropdown' ? Select : Input}
                  label={gettext(field.displayName)}
                  validate={[required, ...(field.validators || [])]}
                  className={classnames({ 'document-field--withHint': Boolean(field.hint) })}
                  onChange={(event, newValue) => onFieldChange(field, newValue)}
                  {...(field.type === 'dropdown' && {
                    placeholder: `-- Select ${field.displayName} --`,
                  })}
                  {...(field.options && { options: field.options })}
                />
                {field.hint && <div className="document-hint">{field.hint}</div>}
              </Fragment>
            );
          }
        })}
      </fieldset>
    );
  }
  return null;
};

export const IdVerificationFieldset = ({ withSubtitle, withTooltip }) => {
  const idTypeLabel = gettext('please select an ID type');
  return (
    <fieldset className="verifyPage-form-group">
      <header className="verifyPage-form-header">
        <div>
          <h3
            className={classnames({
              'verifyPage-form-title': true,
              'verifyPage-form-title--withSubtitle': withSubtitle,
            })}
          >
            {gettext('Proof of Identity')}
            {withTooltip && (
              <InfoTooltip
                className="verifyPage-tooltip"
                id="proof-identity"
                place="right"
                edgeToEdge
              >
                <SampleVerificationDocument
                  title={gettext('Example of an acceptable ID')}
                  description={gettext(
                    'Please make sure you take a high-resolution photo of your ID in a well-lighted room'
                  )}
                  imgAlt={gettext('Driver License Example')}
                  imgSrc="../build/images/verify/example-driver-license.png"
                />
              </InfoTooltip>
            )}
          </h3>
          {withSubtitle && (
            <h3 className="verifyPage-form-subtitle">
              {gettext('Your proof of identity should show your date of birth.')}
            </h3>
          )}
        </div>
      </header>
      <Field
        name="idProofType"
        component={Select}
        label={gettext('ID Type')}
        placeholder={`-- ${idTypeLabel} --`}
        validate={[required]}
        options={idTypeOptions}
        requiredIndicator
        data-tracking-name="choose-id-type"
        data-e2e-target="verification-id-type-field"
      />
      <FieldArray
        name="idProofFile"
        component={FileUpload}
        label={gettext('Upload Document')}
        fileValidators={[validateKYCFile]}
        uploadEndpoint={`${window.config.kyc_api_endpoint}/File`}
        validate={[oneOrMoreFiles]}
        data-tracking-name="upload"
        data-tracking-value="id"
        data-e2e-target="verification-upload-id-file-button"
      />
      <Field
        name="idContainsAddress"
        component={Checkbox}
        label={gettext('The uploaded file shows my current address')}
        data-e2e-target="verification-id-has-address-field"
      />
    </fieldset>
  );
};

export const AddressVerificationFieldset = ({ hide, withSubtitle, withTooltip }) => {
  const documentTypeLabel = gettext('please select a document type');
  return (
    <fieldset className={`verifyPage-form-group${hide ? ' is-hidden' : ''}`}>
      <header className="verifyPage-form-header">
        <h3
          className={classnames({
            'verifyPage-form-title': true,
            'verifyPage-form-title--withSubtitle': withSubtitle,
          })}
        >
          {gettext('Proof of Address')}
          {withTooltip && (
            <InfoTooltip className="verifyPage-tooltip" id="proof-address" place="right" edgeToEdge>
              <SampleVerificationDocument
                title={gettext('Example of an acceptable document')}
                description={gettext(
                  'Please make sure you take a high-resolution photo of your document in a well-lighted room'
                )}
                imgAlt={gettext('Bank Statement Example')}
                imgSrc="../build/images/verify/example-bank-statement.png"
              />
            </InfoTooltip>
          )}
        </h3>
        {withSubtitle && (
          <h3 className="verifyPage-form-subtitle">
            {gettext(
              'Your proof of address should show the same address in your personal details.'
            )}
          </h3>
        )}
      </header>
      <Field
        name="addressProofType"
        component={Select}
        label={gettext('Document Type')}
        placeholder={`-- ${documentTypeLabel} --`}
        options={addressProofTypeOptions}
        requiredIndicator
        data-e2e-target="verification-address-type-field"
        {...(!hide && { validate: [required] })}
      />
      <FieldArray
        name="addressProofFile"
        component={FileUpload}
        label={gettext('Upload Document')}
        uploadEndpoint={`${window.config.kyc_api_endpoint}/File`}
        data-tracking-name="upload"
        data-tracking-value="address"
        data-e2e-target="verification-upload-address-file-button"
        {...(!hide && { validate: [oneOrMoreFiles], fileValidators: [validateKYCFile] })}
      />
    </fieldset>
  );
};

export const CompanyVerificationFieldset = ({
  companyType,
  companyRelationship,
  companyCountry,
}) => {
  const businessTypeLabel = gettext('please select a business type');
  const relationshipLabel = gettext('please select your relationship to the company');
  const documentTypeLabel = gettext('please select a document type');
  const notes =
    companyCountry === CountryToAlpha2.Australia && businessTypeAUSDocumentNotes[companyType];
  const documentTypeOptions =
    companyCountry === CountryToAlpha2.Australia
      ? companyProofTypeOptionsAUS
      : companyProofTypeOptions;
  return (
    <fieldset>
      <header className="verifyPage-form-header">
        <h3 className="verifyPage-form-title">
          {gettext('Proof of Company Details')}
          <InfoTooltip
            id="proof-company"
            message={gettext(
              'All companies are required to provide proof of registration for transactions over $3,000'
            )}
          />
        </h3>
      </header>
      <Field
        name="companyType"
        component={Select}
        label={gettext('Company Type')}
        options={
          companyCountry === CountryToAlpha2.Australia
            ? businessTypeOptionsAUS
            : businessTypeOptions
        }
        placeholder={`-- ${businessTypeLabel} --`}
        validate={[required]}
        requiredIndicator
        data-tracking-name="business-type"
        data-e2e-target="verification-business-type-field"
      />
      <Field
        name="companyRelationship"
        component={Select}
        label={gettext('Your Relationship to the Company')}
        options={companyRelationshipOptions}
        placeholder={`-- ${relationshipLabel} --`}
        validate={[required]}
        requiredIndicator
        data-tracking-name="company-relationship"
        data-e2e-target="verification-company-relationship-field"
      />
      <Field
        name="companyProofType"
        component={Select}
        label={gettext('Document Type')}
        placeholder={`-- ${documentTypeLabel} --`}
        validate={[required]}
        options={(documentTypeOptions[companyType] || documentTypeOptions.default).concat(
          representativeExtraProof[companyRelationship] || representativeExtraProof.default
        )}
        requiredIndicator
        data-tracking-name="company-document-type"
        data-e2e-target="verification-company-document-type-field"
      />
      {notes && (
        <div className="verifyPage-note">
          <div className="field-label">
            <strong>NOTES:</strong>
          </div>
          <div>
            The document uploaded must have the following information for us to successfully verify:
          </div>
          <ul className="verifyPage-note-list">
            {notes.map((item) => (
              <div className="verifyPage-note-list-item" key={item}>
                {item}
              </div>
            ))}
          </ul>
        </div>
      )}
      <FieldArray
        name="companyProofFile"
        component={FileUpload}
        label={gettext('Uploaded Files')}
        fileValidators={[validateKYCFile]}
        uploadEndpoint={`${window.config.kyc_api_endpoint}/File`}
        validate={[oneOrMoreFiles]}
        data-tracking-name="upload"
        data-tracking-value="company-proof"
        data-e2e-target="verification-upload-company-document-button"
      />
    </fieldset>
  );
};

export const ReadAndAgreedFieldset = () => (
  <Fragment>
    <fieldset>
      <Field
        name="readAndAgreed"
        component={Checkbox}
        label={`${gettext('I have read and agreed to the')} `}
        data-e2e-target="verification-id-has-address-field"
      >
        {GeoipAlpha3 === 'AUS' && (
          <Fragment>
            <A
              link={{
                type: 'internal',
                route: '/legal',
                newTab: true,
              }}
            >
              {gettext('Product and Disclosure Statement (PDS)')}
            </A>
            <span> and the </span>
          </Fragment>
        )}
        <A
          link={{
            type: 'internal',
            route: '/legal',
            newTab: true,
          }}
        >
          {gettext('Privacy Policy')}
        </A>
        <span>.</span>
      </Field>
    </fieldset>
  </Fragment>
);

export default {
  VerificationMethodFieldset,
  DocumentFieldsFieldSet,
  IdVerificationFieldset,
  AddressVerificationFieldset,
  CompanyVerificationFieldset,
  PersonalDetailsFieldset,
  CompanyDetailsFieldset,
  PhotoVerificationFieldset,
  ReadAndAgreedFieldset,
};
