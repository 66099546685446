import React from 'react';
import GuitarOffersList from './GuitarOffersList';

const GuitarOffers = () => (
  <div>
    <section className="sectionHero guitar-hero guitar-hero--listing">
      <div className="guitar-hero-inner">
        <div className="section-container guitar-section-container">
          <header className="guitar-header guitar-header--listing">
            <a href="#" className="guitar-header-logo">
              <img
                alt="Escrow.com"
                src="../build/images/guitar/escrow-logo.svg"
                className="guitar-header-logo-img"
              />
            </a>
          </header>
          <div className="grid grid--verticalCenter">
            <div className="grid-col grid-col--desktopSmall-8">
              <h1 className="sectionHero-title sectionHero-title--large">
                Guess the value to win this guitar
              </h1>
              <GuitarOffersList />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default GuitarOffers;
