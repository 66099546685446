import React, { Fragment, useEffect } from 'react';
import moment from 'moment';
import { Alpha2ToCountry } from 'spa/constants/ISOCountryCodes';

import { maxRowsPerPage } from 'spa/components/PartnerDashboard/tables/EnhancedTable';
import withLoadingSpinner from 'spa/components/LoadingSpinner';
import KYCStatus from 'spa/components/KycStatus';
import CreditCardLogo from 'spa/components/CreditCardLogo';
import TransactionTable from 'spa/containers/PartnerDashboard/TransactionTable';
import { apiVerificationStatuses } from 'spa/constants/VerificationConstants';
import { DetailsRow } from 'spa/components/PartnerDashboard/SharedComponents';
import { Spinner } from 'spa/components/Indicators';
import { gettext } from '../../../utils/filters';

const PersonalDetails = (props) => {
  const { customer } = props;
  const verificationStatus = customer.verification.personal.status;
  return (
    <Fragment>
      <div className="grid">
        <div className="grid-col grid-col--tablet-6">
          <div className="card-inner">
            <h4 className="partnerDashboard-details-sectionTitle">{gettext('Personal Details')}</h4>
            <DetailsRow label={gettext('Customer ID')} value={customer.id} />
            <DetailsRow
              label={gettext('Date of Birth')}
              value={customer.date_of_birth && moment(customer.date_of_birth).format('YYYY-MM-DD')}
            />
            <DetailsRow
              label={gettext('Address')}
              value={
                verificationStatus === 'verified' &&
                `${customer.address.line1}, ${customer.address.city}`
              }
            />
            <DetailsRow
              label={gettext('Location')}
              value={verificationStatus === 'verified' && Alpha2ToCountry[customer.address.country]}
            />
          </div>
        </div>
        <div className="grid-col grid-col--tablet-6">
          <div className="card-inner">
            <DetailsRow label={gettext('Email Address')} value={customer.email} />
            <DetailsRow label={gettext('Phone Number')} value={customer.phone_number} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const PaymentsInMethod = ({ card }) => (
  <div className="partnerDashboard-card-inner">
    <div className="partnerDashboard-details-row">
      <div className="partnerDashboard-details-label">{gettext('Card Number')}</div>
      <div className="partnerDashboard-details-value partnerDashboard-details-cardDetails">
        <CreditCardLogo
          type={card.brand === 'American Express' ? 'amex' : card.brand.toLowerCase()}
          size="small"
        />
        <span>****{card.last_four_digits}</span>
      </div>
    </div>
    <div className="partnerDashboard-details-row">
      <div className="partnerDashboard-details-label">{gettext('Expiry')}</div>
      <div className="partnerDashboard-details-value">
        {card.expiry_month}/{card.expiry_year}
      </div>
    </div>
  </div>
);

const PaymentsInMethodsList = withLoadingSpinner(({ data, error }) => (
  <Fragment>
    <div>{error}</div>
    {data.length === 0 && !error ? (
      <div>{gettext('No saved Payments In Methods for the user')}</div>
    ) : (
      <div>
        {data.map((card) => (
          <PaymentsInMethod card={card} key={card.id} />
        ))}
      </div>
    )}
  </Fragment>
));

const PaymentsOutMethod = ({ disbursement }) => (
  <div className="partnerDashboard-card-inner" key={disbursement.id}>
    {disbursement.type !== 'transfer_account' && (
      <div className="partnerDashboard-details-row">
        <div className="partnerDashboard-details-label">{gettext('Bank Name')}</div>
        <div className="partnerDashboard-details-value">{disbursement.bank_name}</div>
      </div>
    )}
    <div className="partnerDashboard-details-row">
      <div className="partnerDashboard-details-label">{gettext('Account Name')}</div>
      <div className="partnerDashboard-details-value">{disbursement.account_name}</div>
    </div>
    {disbursement.type !== 'transfer_account' && (
      <div className="partnerDashboard-details-row">
        <div className="partnerDashboard-details-label">{gettext('Country')}</div>
        <div className="partnerDashboard-details-value">
          {Alpha2ToCountry[disbursement.bank_address.country]}
        </div>
      </div>
    )}
    <div className="partnerDashboard-details-row">
      <div className="partnerDashboard-details-label">{gettext('Currency')}</div>
      <div className="partnerDashboard-details-value">{disbursement.currency.toUpperCase()}</div>
    </div>
  </div>
);

const PaymentsOutMethodsList = withLoadingSpinner(({ data, error }) => (
  <Fragment>
    <div>{error}</div>
    {data.length === 0 && !error ? (
      <div>{gettext('No saved Payments Out Methods for user')}</div>
    ) : (
      <div>
        {data.map((disbursement) => (
          <PaymentsOutMethod disbursement={disbursement} key={disbursement.id} />
        ))}
      </div>
    )}
  </Fragment>
));

const UserDetailsPage = ({
  customer,
  getCustomerData,
  customerPaymentsIn,
  customerPaymentsOut,
  getUserTransactions,
  getUserTransactionsCount,
  handleBack,
  nextCursor,
  retrievePaymentsIn,
  retrievePaymentsOut,
}) => {
  useEffect(() => {
    if (!customer) {
      getCustomerData();
    }
  }, [customer, getCustomerData]);

  useEffect(() => {
    retrievePaymentsIn();
    retrievePaymentsOut();
  }, [retrievePaymentsIn, retrievePaymentsOut]);

  if (!customer) {
    return <Spinner />;
  }

  const filters = { customer_ids: [customer.id] };

  return (
    <div>
      <header className="partnerDashboard-details-header">
        <a
          href="#"
          role="button"
          className="partnerDashboard-details-backBtn"
          onClick={() => handleBack()}
        >
          ← {gettext('Back')}
        </a>
        <h2 className="partnerDashboard-details-title">
          <strong>{customer.display_name || customer.email}</strong>
          <KYCStatus
            className="partnerDashboard-userStatus"
            status={customer.verification.personal.status}
            hideIcon={
              customer.verification.personal.status === apiVerificationStatuses.NOT_VERIFIED
            }
          />
        </h2>
        <h3 className="partnerDashboard-details-subtitle">
          {gettext('Customer ID')} {customer.id}
        </h3>
      </header>
      <div className="card">
        <PersonalDetails customer={customer} />
        <div className="card-inner">
          <div className="grid">
            <div className="grid-col grid-col--tablet-6">
              <h4 className="partnerDashboard-details-sectionTitle">
                {gettext('Pay In Method/s')}
              </h4>
              <PaymentsInMethodsList
                isLoading={customerPaymentsIn.loading}
                data={customerPaymentsIn.data}
                error={customerPaymentsIn.error}
              />
            </div>
            <div className="grid-col grid-col--tablet-6">
              <h4 className="partnerDashboard-details-sectionTitle">
                {gettext('Pay Out Method/s')}
              </h4>
              <PaymentsOutMethodsList
                isLoading={customerPaymentsOut.loading}
                data={customerPaymentsOut.data}
                error={customerPaymentsOut.error}
              />
            </div>
          </div>
        </div>
        <div className="card-inner">
          <h4 className="partnerDashboard-details-sectionTitle">Recent Transactions</h4>
          <TransactionTable
            filters={filters}
            getData={() =>
              getUserTransactions({
                filters,
                limit: maxRowsPerPage,
                cursor: nextCursor,
              })
            }
            getDataTotals={() =>
              getUserTransactionsCount({
                filters,
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default UserDetailsPage;
