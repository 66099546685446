import React from 'react';
import { useTheme } from '@mui/material/styles';
import { SmallText } from 'spa/features/ui/EscrowTypography';

const UploadSuccess = ({ uploadedText }) => {
  const theme = useTheme();

  return (
    <div
      className="camera-upload-container"
      style={{ backgroundColor: theme.palette.white, top: '20%', bottom: '20%' }}
    >
      <img
        className="camera-upload-icon"
        src={`spa/../../build/images/kyc/upload-success-tick-only.gif`}
        alt={uploadedText}
      />
      <SmallText sx={{ fontSize: '16px', fontWeight: '600' }}>{uploadedText}</SmallText>
    </div>
  );
};

export default UploadSuccess;
