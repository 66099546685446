import React from 'react';
import TwoFactorConstants from 'spa/constants/TwoFactorConstants';
import ConfigContainer from './ConfigContainer';
import SetupAuthenticatorAppContainer from './SetupAuthenticatorAppContainer';
import ConfigCompleteContainer from './ConfigCompleteContainer';
import InputContainer from './InputContainer';
import SetupSMSContainer from './SetupSMSContainer';

class TwoFactorAuthenticationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: TwoFactorConstants.STEP_MAIN,
      configType: TwoFactorConstants.TYPE_AUTH_APP,
      actionType: TwoFactorConstants.ACTION_SETUP,
    };

    this._renderStep = this._renderStep.bind(this);
    this.setupAuthAppStep = this.setupAuthAppStep.bind(this);
    this.setupSMSStep = this.setupSMSStep.bind(this);
    this.disableAuthAppStep = this.disableAuthAppStep.bind(this);
    this.disableSMSStep = this.disableSMSStep.bind(this);
    this.navigateMain = this.navigateMain.bind(this);
    this.navigateDone = this.navigateDone.bind(this);
  }

  setupAuthAppStep() {
    this.setState({
      currentStep: TwoFactorConstants.STEP_AUTH_APP,
      configType: TwoFactorConstants.TYPE_AUTH_APP,
      actionType: TwoFactorConstants.ACTION_SETUP,
    });
  }

  setupSMSStep() {
    this.setState({
      currentStep: TwoFactorConstants.STEP_SMS,
      configType: TwoFactorConstants.TYPE_SMS,
      actionType: TwoFactorConstants.ACTION_SETUP,
    });
  }

  disableAuthAppStep() {
    this.setState({
      currentStep: TwoFactorConstants.STEP_DISABLE_AUTH_APP,
      configType: TwoFactorConstants.TYPE_AUTH_APP,
      actionType: TwoFactorConstants.ACTION_DISABLE,
    });
  }

  disableSMSStep() {
    this.setState({
      currentStep: TwoFactorConstants.STEP_DISABLE_SMS,
      configType: TwoFactorConstants.TYPE_SMS,
      actionType: TwoFactorConstants.ACTION_DISABLE,
    });
  }

  navigateMain() {
    this.setState({ currentStep: TwoFactorConstants.STEP_MAIN });
  }

  navigateDone() {
    this.setState({ currentStep: TwoFactorConstants.STEP_DONE });
  }

  _renderStep() {
    switch (this.state.currentStep) {
      case TwoFactorConstants.STEP_MAIN:
        return (
          <ConfigContainer
            setupAuthAppStep={this.setupAuthAppStep}
            setupSMSStep={this.setupSMSStep}
            disableAuthAppStep={this.disableAuthAppStep}
            disableSMSStep={this.disableSMSStep}
          />
        );
      case TwoFactorConstants.STEP_AUTH_APP:
        return (
          <SetupAuthenticatorAppContainer
            navigateDone={this.navigateDone}
            navigateMain={this.navigateMain}
            configType={this.state.configType}
          />
        );
      case TwoFactorConstants.STEP_SMS:
        return (
          <SetupSMSContainer
            navigateMain={this.navigateMain}
            configType={this.state.configType}
            navigateDone={this.navigateDone}
          />
        );
      case TwoFactorConstants.STEP_DISABLE_AUTH_APP:
      case TwoFactorConstants.STEP_DISABLE_SMS:
        return (
          <InputContainer
            navigateMain={this.navigateMain}
            callback={this.navigateDone}
            configType={this.state.configType}
            actionType={this.state.actionType}
          />
        );
      case TwoFactorConstants.STEP_DONE:
      case TwoFactorConstants.STEP_DISABLE_DONE:
        return (
          <ConfigCompleteContainer
            navigateMain={this.navigateMain}
            configType={this.state.configType}
            actionType={this.state.actionType}
          />
        );
      default:
        return null;
    }
  }

  render() {
    return (
      <div className="twoFactorAuth-card">
        <div>{this._renderStep()}</div>
      </div>
    );
  }
}

export default TwoFactorAuthenticationContainer;
