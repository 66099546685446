import React, { useState, Fragment } from 'react';
import { useForm } from 'react-final-form';
import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { InputField, PhoneNumberInputField } from 'spa/components/StartTransaction/Fields';
import { Alpha3ToAlpha2 } from 'spa/constants/ISOCountryCodes';
import TransactionConstants from 'spa/constants/TransactionConstants';
import { useWindowDimensions } from 'spa/hooks';
import AuthenticationStore from '../../../stores/AuthenticationStore';
import { validateTransactionPartyEmails } from '../form/validate';

const { TRANSACTION_ROLES, DESKTOP_VIEW_WIDTH } = TransactionConstants;

const MISING_BUYER_DESC =
  "If you don't have a buyer, you can still create a transaction and we'll provide you a link that you can share to any potential buyer.";
const DetailFields = ({ partyRole, disabled, emailValidator, fieldIndex = 0, country = 'us' }) => (
  <div className="createTransaction-inline-fields-container">
    <div className="createTransaction-inline-field--half">
      <InputField
        name={`${partyRole}Email`}
        label="Email"
        disabled={disabled}
        validate={emailValidator}
      />
    </div>
    <div className="createTransaction-inline-field--half">
      <div className="createTransaction-phoneNumber">
        <PhoneNumberInputField
          name={`${partyRole}Phone`}
          disabled={disabled}
          placeholder="Phone number (optional)"
          fieldIndex={fieldIndex}
          country={country}
        />
      </div>
    </div>
  </div>
);

const TransactionTerms = ({ values, submitting }) => {
  const form = useForm();
  const { role: myRole, items, buyerEmail, sellerEmail } = values;
  const userEmail = AuthenticationStore.getEmail();
  const [haveBuyerDetail, setHaveBuyerDetail] = useState(true);
  const { width } = useWindowDimensions();
  const country =
    Alpha3ToAlpha2[window.config.geoip_country] &&
    Alpha3ToAlpha2[window.config.geoip_country].toLowerCase();

  const onHaveBuyerToggle = () => {
    if (haveBuyerDetail) {
      form.change('buyerEmail', '');
    }
    setHaveBuyerDetail(!haveBuyerDetail);
  };

  const isMilestone = items[0].isMilestone || items[0].isDNH;

  const getEmailValidator = (fieldRole) => {
    if (myRole !== TRANSACTION_ROLES.BROKER) {
      return (value) => validateTransactionPartyEmails(value, userEmail);
    }
    if (fieldRole === TRANSACTION_ROLES.BUYER) {
      return (value) => validateTransactionPartyEmails(value, sellerEmail, userEmail);
    }
    return (value) => validateTransactionPartyEmails(value, buyerEmail, userEmail);
  };

  return (
    <div className="createTransaction-check-container">
      {(myRole === TRANSACTION_ROLES.BUYER || myRole === TRANSACTION_ROLES.BROKER) && (
        <Fragment>
          <div className="createTransaction-subform--header">Seller details</div>
          <DetailFields
            partyRole={TRANSACTION_ROLES.SELLER}
            disabled={submitting}
            emailValidator={getEmailValidator(TRANSACTION_ROLES.SELLER)}
            country={country}
          />
        </Fragment>
      )}
      {(myRole === TRANSACTION_ROLES.SELLER || myRole === TRANSACTION_ROLES.BROKER) && (
        <Fragment>
          <div className="createTransaction-subform--header">Buyer details</div>
          {myRole === TRANSACTION_ROLES.SELLER && !isMilestone && (
            <div className="createTransaction-buyer-check-container" data-checked={haveBuyerDetail}>
              <div className="createTransaction-check-container createTransaction-check-row-header createTransaction-check-row-header-left">
                <FormControlLabel
                  control={
                    <Checkbox
                      data-tracking-subsection="hasBuyer"
                      data-tracking-name="Has Buyer"
                      data-tracking-value={haveBuyerDetail}
                      disabled={submitting}
                      checked={haveBuyerDetail}
                      onClick={onHaveBuyerToggle}
                    />
                  }
                  label={
                    <span className="createTransaction-default-text">
                      <span>I have a buyer for this transaction</span>
                      {width >= DESKTOP_VIEW_WIDTH && (
                        <div className="createTransaction-default-text-icon">
                          <Tooltip title={MISING_BUYER_DESC} placement="top" arrow>
                            <HelpIcon color="disabled" fontSize="small" />
                          </Tooltip>
                        </div>
                      )}
                    </span>
                  }
                />
              </div>
            </div>
          )}
          {(haveBuyerDetail || myRole === TRANSACTION_ROLES.BROKER || isMilestone) && (
            <DetailFields
              partyRole={TRANSACTION_ROLES.BUYER}
              disabled={submitting}
              emailValidator={getEmailValidator(TRANSACTION_ROLES.BUYER)}
              fieldIndex={myRole === TRANSACTION_ROLES.BROKER ? 1 : 0}
              country={country}
            />
          )}
        </Fragment>
      )}
    </div>
  );
};

export default TransactionTerms;
