import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Form } from 'redux-form';
import { bindRoutineToReduxForm } from 'redux-saga-routines';
import { submitOfferForm } from 'spa/actions/OfferActions';
import { FormError, ActionButton } from 'spa/components/form';
import { currencies } from 'escrow-common-js/dist/constants';
import CreateOfferFieldset from './CreateOfferFieldset';

import { gettext } from '../../../utils/filters';

const CounterOfferForm = ({
  form,
  error,
  submitting,
  handleSubmit,
  label,
  currency,
  role,
  toggleFormAction,
}) => (
  <Form name={form} onSubmit={handleSubmit(bindRoutineToReduxForm(submitOfferForm))}>
    {error && <FormError error={error} />}
    <CreateOfferFieldset label={label} currency={currency} role={role} />
    <div className="offer-actionList">
      <ActionButton
        className="offer-actionList-btn btn btn--secondary"
        name="submitMakeOffer"
        type="submit"
        label={gettext('Submit offer')}
        loading={submitting}
        data-tracking-name="submit-offer"
      />
      <a
        href="#"
        className="checkout-card-secondaryAction"
        onClick={toggleFormAction}
        data-tracking-name="cancel-make-offer"
      >
        {gettext('Cancel')}
      </a>
    </div>
  </Form>
);

CounterOfferForm.propTypes = {
  role: PropTypes.oneOf(['buyer', 'seller']).isRequired,
  label: PropTypes.string,
  currency: PropTypes.oneOf(Object.keys(currencies)),
};

export default reduxForm({
  destroyOnUnmount: true,
  onSubmitSuccess: (result, dispatch, props) => props.toggleFormAction(),
})(CounterOfferForm);
