import React from 'react';
import window from 'window-shim';
import PropTypes from 'prop-types';

const Currency = ({ code, amount, withTag }) => {
  const currencyTable = window.config.currencies[code.toUpperCase()];
  if (!currencyTable) {
    throw Error(`Currency ${code} is not supported`);
  }

  const currencyName = withTag ? ` ${code.toUpperCase()}` : '';

  return (
    <span>
      {currencyTable.symbol}
      {amount.toLocaleString('en', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })}
      {currencyName}
    </span>
  );
};

Currency.propTypes = {
  code: PropTypes.string,
  amount: PropTypes.number,
};

export default Currency;
