import moment from 'moment';
import { formatPhoneNumberInternational } from '../../../utils/parse-phone';
import { gettext } from '../../../utils/filters';

// Function for trimming and concatenating each field
export const trimAndConcat = (separator, ...strings) => {
  const processedStrings = strings.map((s) => (s || '').trim());
  const filteredStrings = processedStrings.filter((s) => Boolean(s));
  return filteredStrings.join(separator);
};

// All these format function will return empty string '' if the format is unsuccessful
export const formatPhone = (phoneNumber) => formatPhoneNumberInternational(phoneNumber) || '';

export const formatDob = (isoString) => {
  const dob = moment.utc(isoString);
  if (!dob.isValid()) {
    throw new Error('Cannot format malformed date of birth');
  }
  return dob.format('M/D/YYYY');
};

export const formatCityStatePostcode = (city, state, postcode) => {
  const trimmedCity = trimAndConcat('', city);
  const trimmedState = trimAndConcat('', state);
  const trimmedPostcode = trimAndConcat('', postcode);
  const addressConcat = [];
  if (trimmedCity && trimmedState && trimmedPostcode) {
    addressConcat.push(`${trimmedCity}, ${trimmedState} ${trimmedPostcode}`);
  } else {
    if (trimmedCity) {
      addressConcat.push(trimmedCity);
    }
    if (trimmedState) {
      addressConcat.push(trimmedState);
    }
    if (trimmedPostcode) {
      addressConcat.push(trimmedPostcode);
    }
  }
  return addressConcat.join(' ');
};

export const formatDisplayNameType = (
  hasCompanyInformation,
  isCompany,
  customerName,
  companyName
) => {
  if (!hasCompanyInformation) {
    return '';
  }
  if (isCompany) {
    return `${customerName} (${companyName})`;
  }
  return `${gettext('Representative Name')} (${customerName})`;
};
