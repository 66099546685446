import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getWindow } from '../../../utils/globals';

const window = getWindow();

export const resetPasswordApi = createApi({
  reducerPath: 'resetPasswordApi',
  baseQuery: fetchBaseQuery({ baseUrl: window.config.internal_endpoint }),
  endpoints: (builder) => ({
    requestPasswordReset: builder.mutation({
      query: (data) => ({
        url: '/password/forgot',
        method: 'POST',
        body: data,
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: '/password/change',
        method: 'POST',
        body: data,
      }),
    }),
    checkTokenValid: builder.query({
      query: (token) => ({
        url: `/password/token/${token}`,
      }),
    }),
  }),
});

export const {
  useRequestPasswordResetMutation,
  useResetPasswordMutation,
  useCheckTokenValidQuery,
} = resetPasswordApi;
