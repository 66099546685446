import { connect } from 'react-redux';
import QueryString from 'query-string';
import {
  checkoutPaymentTypeSelector,
  checkoutTransIdSelector,
  checkoutPaymentDetailsSelector,
} from 'spa/selectors/CheckoutSelectors';
import { getSavedCreditCards, getSavedPlaidAccounts } from 'spa/actions/UserActions';
import {
  customerSavedPlaidAccountsSelector,
  customerSavedCreditCardsSelector,
  customerSavedCardsLoadingSelector,
  customerSavedPlaidLoadingSelector,
  customerSavedCardsCalledOnceSelector,
} from 'spa/selectors/CustomerSelectors';
import { setPaymentDetails } from 'spa/actions/CheckoutActions';

import {
  transactionByIdSelector,
  transactionDraftSelector,
  transactionAdyenAdditionalActionSelector,
  transactionAdyenCheckoutSelector,
  transactionHasAdyenCCSelector,
  transactionCCPaymentGatewaySelector,
} from 'spa/selectors/TransactionSelectors';

import {
  adyenIsValidSelector,
  adyenThreeDSResultCodeSelector,
  adyenThreeDSResultSelector,
} from 'spa/selectors/PaymentSelectors';
import {
  initPlaidClient as initPlaidClientRoutine,
  launchPlaidClient as launchPlaidClientRoutine,
  setAdyenPaymentToken,
} from 'spa/actions/PaymentsActions';
import PaymentConstants from 'spa/constants/PaymentConstants';
import PaymentDetailForm from 'spa/components/CheckoutDetails/V2/PaymentDetailForm';
import ErrorMessages from '../../../../constants/ErrorMessages';

import collectBrowserInfo from '../../../../utils/BrowserInfo';

const mapStateToProps = (state, ownProps) => {
  const transId = checkoutTransIdSelector(state);
  const draft = transactionDraftSelector(state);
  let transaction = draft;
  if (transId) {
    transaction = transactionByIdSelector(state, transId);
  }

  let paymentPortalRedirectError = '';
  const redirectFromPaymentPortal = QueryString.parse(window.location.search).continue;
  const adyenThreeDSResult = adyenThreeDSResultSelector(state);
  const adyenThreeDSResultCode = adyenThreeDSResultCodeSelector(state);
  if (
    adyenThreeDSResult &&
    adyenThreeDSResultCode !== PaymentConstants.ADYEN_THREEDS_PASSED &&
    redirectFromPaymentPortal === PaymentConstants.PAYMENT_METHODS.CREDIT_CARD
  ) {
    paymentPortalRedirectError = ErrorMessages.ADYEN_THREEDS_FAILED;
  }

  return {
    ...ownProps,
    transaction,
    adyenThreeDSResultCode,
    paymentPortalRedirectError,
    transactionCCPaymentGateway: transactionCCPaymentGatewaySelector(state, transaction.id),
    paymentType: checkoutPaymentTypeSelector(state),
    paymentDetails: checkoutPaymentDetailsSelector(state),
    savedPlaidAccounts: customerSavedPlaidAccountsSelector(state),
    savedCreditCards: customerSavedCreditCardsSelector(state),
    savedPlaidAccountLoading: customerSavedPlaidLoadingSelector(state),
    savedCreditCardsLoading: customerSavedCardsLoadingSelector(state),
    savedCreditCardsCalled: customerSavedCardsCalledOnceSelector(state),
    isAdyenValid: adyenIsValidSelector(state),
    adyenCCAvailable: transactionHasAdyenCCSelector(transaction),
    adyenAction: transactionAdyenAdditionalActionSelector(transaction),
    adyenCheckout: transactionAdyenCheckoutSelector(transaction),
  };
};
const mapDispatchToProps = (dispatch) => ({
  initPlaidClient: (options) => dispatch(initPlaidClientRoutine.trigger(options)),
  launchPlaidClient: () => dispatch(launchPlaidClientRoutine.trigger()),
  getSavedPlaidAccounts: () => dispatch(getSavedPlaidAccounts.trigger()),
  setPaymentDetails: (details) => dispatch(setPaymentDetails(details)),
  getSavedCreditCards: () => dispatch(getSavedCreditCards.trigger()),
  setAdyenPaymentToken: (adyenCCId) =>
    dispatch(setAdyenPaymentToken({ adyenCCId, browserInfo: collectBrowserInfo() })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetailForm);
