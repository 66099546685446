import React from 'react';
import InfoTooltip from './InfoTooltip';
import FieldError from './FieldError';

const TextArea = ({
  input,
  meta: { error, warning, touched },
  label,
  requiredIndicator,
  tooltip,
  children,
  note,
  className,
  ...attributes
}) => {
  const { name } = input;
  let inputClass = 'field';
  if (error && touched) {
    inputClass += ' is-invalid';
  }
  return (
    <div className={`${inputClass} ${className}`}>
      <label htmlFor={name} className="field-label">
        {label}
        {children}
        {requiredIndicator && <span className="field-required">*</span>}
        {tooltip && <InfoTooltip id={`${name}-field-input-tooltip`} message={tooltip} />}
        {note && <span className="field-label-note">{note}</span>}
      </label>
      <div className="field-input">
        <div className="field-input-inner">
          <textarea {...input} id={name} className="defaultTextarea" {...attributes}>
            {input.value}
          </textarea>
        </div>
        {error && touched && <FieldError message={error} />}
        {warning && touched && <FieldError message={warning} />}
      </div>
    </div>
  );
};

export default TextArea;
