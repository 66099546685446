import React from 'react';
import { Field } from 'redux-form';

const listOption = ({
  key,
  active,
  value,
  onChange,
  title,
  subtitle,
  content,
  contentHidden,
  logo,
}) => (
  <div key={key} className={`listOptions-item ${active ? 'is-active' : ''}`}>
    <div className="listOptions-header">
      <label className="defaultRadio">
        <Field
          className="defaultRadio-input"
          name="paymentType"
          value={value}
          component="input"
          type="radio"
          data-e2e-target={`checkout-${key}-radio-btn`}
          onChange={onChange}
          data-tracking-name={key}
        />
        <div className="defaultRadio-pseudo" />
        <div className="defaultRadio-icon">{logo}</div>
        <span className="defaultRadio-label">
          <span className="defaultRadio-title">{title}</span>
          <span className="defaultRadio-hint">{subtitle}</span>
        </span>
      </label>
    </div>
    {!contentHidden && content && (
      <div className={`listOptions-content`}>
        <div>{content}</div>
      </div>
    )}
  </div>
);

export default listOption;
