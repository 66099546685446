import 'react-dates/initialize';
import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DayPickerRangeController } from 'react-dates';

const dateFormatStr = 'YYYY-MM-DD';
const endOfToday = moment().endOf('day');
const defaultDatePresets = {
  TODAY: {
    text: 'Today',
    value: 'TODAY',
    rangeStart: moment(),
    rangeEnd: endOfToday,
  },
  LAST_7_DAYS: {
    text: 'Last 7 Days',
    value: 'LAST_7_DAYS',
    rangeStart: moment().subtract(6, 'days').startOf('day'),
    rangeEnd: endOfToday,
  },
  LAST_30_DAYS: {
    text: 'Last 30 Days',
    value: 'LAST_30_DAYS',
    rangeStart: moment().subtract(29, 'days').startOf('day'),
    rangeEnd: endOfToday,
  },
  THIS_YEAR: {
    text: 'This Year',
    value: 'THIS_YEAR',
    rangeStart: moment().startOf('year'),
    rangeEnd: endOfToday,
  },
  CUSTOM: {
    text: 'Custom Date Range',
    value: 'CUSTOM',
    rangeStart: null,
    rangeEnd: null,
  },
};

const DatePresetRadioField = ({ value, label, ...props }) => (
  <label key={value} className="defaultRadio dateRangePicker-withPresets-radio">
    <input
      className="defaultRadio-input"
      name="DatePreset"
      type="radio"
      id={value}
      value={value}
      {...props}
    />
    <div className="defaultRadio-pseudo" />
    <span className="defaultRadio-label">{label}</span>
  </label>
);

const CustomDateInputField = ({ name, label, ...props }) => (
  <div className="field grid-col grid-col--tablet-6">
    <label htmlFor={name} className="field-label">
      {label}
    </label>
    <div className="field-input">
      <input id={name} name={name} className="defaultInput" type="text" {...props} />
    </div>
  </div>
);

class DateRangePickerWithPresets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: 'startDate',
      dateInput: {
        startDate: '',
        endDate: '',
        focusedMonth: moment(),
      },
    };

    this.onFocusChange = this.onFocusChange.bind(this);
    this.onDatesChange = this.onDatesChange.bind(this);
    this.onPresetChange = this.onPresetChange.bind(this);
    this.onInputFieldFocus = this.onInputFieldFocus.bind(this);
    this.onInputFieldChange = this.onInputFieldChange.bind(this);
    this.onInputFieldBlur = this.onInputFieldBlur.bind(this);
    this.onInputFieldKeyDown = this.onInputFieldKeyDown.bind(this);
  }

  onFocusChange(focusedInput) {
    this.setState({ focusedInput: !focusedInput ? 'startDate' : focusedInput });
  }

  onDatesChange({ startDate, endDate }) {
    const { onDateChange, datePresets } = this.props;
    onDateChange(startDate, endDate, datePresets.CUSTOM.value);
    this.setState({
      dateInput: {
        ...this.state.dateInput,
        focusedMonth: startDate || endDate || moment(),
      },
    });
  }

  onPresetChange(event) {
    const { datePresets, onDateChange } = this.props;
    const { dateInput } = this.state;
    const selectedPreset = event.target.value;
    let startDate;
    let endDate;

    if (selectedPreset in datePresets) {
      startDate = this.props.startDate;
      endDate = this.props.endDate;

      if (selectedPreset !== datePresets.CUSTOM.value) {
        const { rangeStart, rangeEnd } = datePresets[selectedPreset];
        startDate = rangeStart;
        endDate = rangeEnd;
      }

      onDateChange(startDate, endDate, selectedPreset);
      this.setState({
        dateInput: {
          ...dateInput,
          focusedMonth: startDate || endDate || moment(),
        },
      });
    }
  }

  onInputFieldFocus(event) {
    const { name: dateType } = event.target;
    const { startDate, endDate } = this.props;
    const dateObj = dateType === 'startDate' ? startDate : endDate;

    this.setState({
      selectedPreset: this.props.datePresets.CUSTOM.value,
      dateInput: {
        ...this.state.dateInput,
        [dateType]: dateObj ? dateObj.format(dateFormatStr) : '',
      },
    });
  }

  onInputFieldChange(event) {
    const { name: dateType, value } = event.target;
    this.setState({
      selectedPreset: this.props.datePresets.CUSTOM.value,
      dateInput: {
        ...this.state.dateInput,
        [dateType]: value,
      },
    });
  }

  onInputFieldBlur(event) {
    const { name: dateType, value } = event.target;
    let { focusedMonth } = this.state.dateInput;
    const {
      onDateChange,
      startDate,
      endDate,
      datePresets,
      dayPickerRangeProps: { isOutsideRange },
    } = this.props;

    const valueAsDate = moment(value);
    if (value === '') {
      const newStartDate = dateType === 'startDate' ? null : startDate;
      const newEndDate = dateType === 'endDate' ? null : endDate;
      const newPreset = !newStartDate && !newEndDate ? '' : datePresets.CUSTOM.value;

      focusedMonth = newStartDate || newEndDate || moment();
      onDateChange(newStartDate, newEndDate, newPreset);
    } else if (valueAsDate.isValid() && (!isOutsideRange || !isOutsideRange(valueAsDate))) {
      const newStartDate = dateType === 'startDate' ? valueAsDate : startDate;
      const newEndDate = dateType === 'endDate' ? valueAsDate : endDate;
      const newPreset = datePresets.CUSTOM.value;

      if (!newStartDate || !newEndDate || newEndDate.isAfter(newStartDate)) {
        focusedMonth = valueAsDate;
        onDateChange(newStartDate, newEndDate, newPreset);
      }
    }

    this.setState({ dateInput: { startDate: '', endDate: '', focusedMonth } });
  }

  onInputFieldKeyDown(event) {
    if (event.key === 'Enter') {
      event.target.blur();
    }
  }

  render() {
    const { focusedInput, dateInput } = this.state;
    const { dateRangeLabel, dayPickerRangeProps, startDate, endDate, selectedPreset, datePresets } =
      this.props;

    const startDateStr = startDate ? startDate.format(dateFormatStr) : '';
    const endDateStr = endDate ? endDate.format(dateFormatStr) : '';

    return (
      <div className="dateRangePicker-withPresets grid grid--verticalStretch">
        <div className="grid-col grid-col--tablet-6">
          <div className="dateRangePicker-withPresets-title">
            <h3>{dateRangeLabel}</h3>
          </div>
          <div className="dateRangePicker-withPresets-radio-group">
            {Object.values(datePresets).map((preset) => (
              <DatePresetRadioField
                key={preset.value}
                value={preset.value}
                label={preset.text}
                checked={selectedPreset === preset.value}
                onChange={this.onPresetChange}
              />
            ))}
          </div>

          <div className="dateRangePicker-withPresets-input-group">
            <div className="grid grid--tight">
              <CustomDateInputField
                name="startDate"
                label="Start Date"
                value={dateInput.startDate}
                placeholder={startDateStr}
                onFocus={this.onInputFieldFocus}
                onChange={this.onInputFieldChange}
                onBlur={this.onInputFieldBlur}
                onKeyDown={this.onInputFieldKeyDown}
              />
              <CustomDateInputField
                name="endDate"
                label="End Date"
                value={dateInput.endDate}
                placeholder={endDateStr}
                onFocus={this.onInputFieldFocus}
                onChange={this.onInputFieldChange}
                onBlur={this.onInputFieldBlur}
                onKeyDown={this.onInputFieldKeyDown}
              />
            </div>
          </div>
        </div>
        <div className="dateRangePicker-withPresets-calendar grid-col grid-col--tablet-6">
          <DayPickerRangeController
            key={dateInput.focusedMonth.format('YYYY-MM')}
            initialVisibleMonth={() => dateInput.focusedMonth}
            numberOfMonths={1}
            transitionDuration={0}
            hideKeyboardShortcutsPanel
            focusedInput={focusedInput}
            onDatesChange={this.onDatesChange}
            onFocusChange={this.onFocusChange}
            startDate={startDate}
            endDate={endDate}
            {...dayPickerRangeProps}
          />
        </div>
      </div>
    );
  }
}

DateRangePickerWithPresets.propTypes = {
  onDateChange: PropTypes.func.isRequired,
  datePresets: PropTypes.object,
  dateRangeLabel: PropTypes.string,
  dayPickerRangeProps: PropTypes.object,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  selectedPreset: PropTypes.string,
};

DateRangePickerWithPresets.defaultProps = {
  datePresets: defaultDatePresets,
  dateRangeLabel: 'Date Range',
  dayPickerRangeProps: null,
  startDate: null,
  endDate: null,
  selectedPreset: '',
};

export default DateRangePickerWithPresets;
