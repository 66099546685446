import window from 'window-shim';

import {
  UnprocessableEntity,
  NotFound,
  BadRequest,
  Unauthorized,
  Forbidden,
  NotAcceptable,
  Gone,
  InternalServerError,
  Unhandled,
} from './Exceptions';
import NavigationStore from '../stores/NavigationStore';

function handleError(response, throwOnUnauthorised = false) {
  if (!response) return;
  if (response.ok) return response;

  switch (response.status) {
    case 400 /* BadRequest */:
      return response.json().then((error) => {
        throw new BadRequest(error, response.headers.get('X-Request-ID'));
      });

    case 401 /* Unauthorized */:
      if (throwOnUnauthorised) {
        return response
          .json()
          .then((message) => {
            throw new Unauthorized(message, response.headers.get('X-Request-ID'));
          })
          .catch((error) => {
            throw new Unauthorized(error, response.headers.get('X-Request-ID'));
          });
      }
      if (NavigationStore.pageType === 'integrations_portal') {
        window.location = `${window.config.www_base_url}/integrations/login`;
      } else {
        window.location = `${window.config.www_base_url}/login-page`;
      }
      return response;

    case 403 /* Forbidden */:
      return response.json().then((result) => {
        throw new Forbidden(
          result.errors ? result.errors : result.error,
          response.headers.get('X-Request-ID'),
          result.error_code
        );
      });

    case 404 /* NotFound */:
      return response.json().then((result) => {
        throw new NotFound(
          result.errors ? result.errors : result.error,
          response.headers.get('X-Request-ID')
        );
      });

    case 406 /* NotAcceptable */:
      return response.json().then(({ errors }) => {
        throw new NotAcceptable(errors, response.headers.get('X-Request-ID'));
      });

    case 410 /* Gone */:
      return response.json().then((error) => {
        throw new Gone(error, response.headers.get('X-Request-ID'));
      });

    case 422 /* UnprocessableEntity */:
      return response.json().then(({ errors }) => {
        throw new UnprocessableEntity(errors, response.headers.get('X-Request-ID'));
      });

    case 500 /* InternalServerError */:
      return response.json().then((error) => {
        throw new InternalServerError(error, response.headers.get('X-Request-ID'));
      });

    default:
      /* Unhandled */
      return response.json().then((error) => {
        throw new Unhandled(error, response.headers.get('X-Request-ID'));
      });
  }
}

const handleErrorThrowOnUnauthorized = (response) => handleError(response, true);

export { handleError, handleErrorThrowOnUnauthorized };
