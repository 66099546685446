import React from 'react';

import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTheme } from '@mui/material/styles';

import { useIsMobileViewport } from 'spa/components/MaterialUI/Theme';
import { SmallText, MediumText, LargeText } from '../../../ui/EscrowTypography';
import { getNavigator } from '../../../../../utils/globals';
import { getTypeTextSubstrings } from '../../utils/transactionTypeFormatting';
import { EmailOrYouText, EmailText } from './EmailText';
import { isOtherPartyConfidential } from '../../utils/confidentiality';

export const getTypeText = (typeCode, multipleItems) => {
  const { text, itemText } = getTypeTextSubstrings(typeCode, multipleItems);

  return (
    <React.Fragment>
      {text}
      <SmallText component="span" variant="bold">
        {itemText}
      </SmallText>
    </React.Fragment>
  );
};

const NameText = ({ transactionRole: role, parties }) => (
  <SmallText variant="bold">{parties[role].name}</SmallText>
);

const NameAndEmailText = ({ transactionRole: role, userRole, parties }) => (
  <React.Fragment>
    <NameText transactionRole={role} parties={parties} />{' '}
    {parties[role].name ? (
      <React.Fragment>
        (<EmailOrYouText transactionRole={role} userRole={userRole} parties={parties} />)
      </React.Fragment>
    ) : (
      <EmailText email={parties[role].email} />
    )}
  </React.Fragment>
);

const TransparentInformationCopy = ({
  userRole,
  parties,
  transactionTypeCode,
  multipleItems,
  broker,
}) => (
  <React.Fragment>
    <NameAndEmailText transactionRole="buyer" userRole={userRole} parties={parties} /> is buying{' '}
    {getTypeText(transactionTypeCode, multipleItems)} from{' '}
    <NameAndEmailText transactionRole="seller" userRole={userRole} parties={parties} />.{' '}
    {broker && (
      <React.Fragment>
        The broker for this transaction is{' '}
        <NameAndEmailText userRole={userRole} transactionRole="broker" parties={parties} />.{' '}
      </React.Fragment>
    )}
  </React.Fragment>
);

const ConfidentialInformationCopy = ({ userRole, parties, transactionTypeCode, multipleItems }) => (
  <React.Fragment>
    {userRole === 'seller' ? (
      <React.Fragment>
        <NameAndEmailText transactionRole="seller" userRole={userRole} parties={parties} /> is{' '}
        selling {getTypeText(transactionTypeCode, multipleItems)} for{' '}
      </React.Fragment>
    ) : (
      <React.Fragment>
        <NameAndEmailText userRole={userRole} transactionRole="buyer" parties={parties} /> is buying{' '}
        {getTypeText(transactionTypeCode, multipleItems)} from{' '}
      </React.Fragment>
    )}
    a transaction brokered by{' '}
    <NameAndEmailText transactionRole="broker" userRole={userRole} parties={parties} />.{' '}
  </React.Fragment>
);

const BrokerCopy = ({ userRole, parties, transactionTypeCode, multipleItems }) => (
  <React.Fragment>
    You are the broker for {getTypeText(transactionTypeCode, multipleItems)} transaction where{' '}
    <NameAndEmailText transactionRole="buyer" userRole={userRole} parties={parties} /> is the buyer,{' '}
    and <NameAndEmailText transactionRole="seller" userRole={userRole} parties={parties} /> is the{' '}
    seller.{' '}
  </React.Fragment>
);

const BuyerOrSellerCopy = ({
  userRole,
  parties,
  transactionTypeCode,
  multipleItems,
  otherPartyIsConfidential,
  broker,
}) =>
  otherPartyIsConfidential && broker ? (
    <ConfidentialInformationCopy
      userRole={userRole}
      parties={parties}
      transactionTypeCode={transactionTypeCode}
      multipleItems={multipleItems}
    />
  ) : (
    <TransparentInformationCopy
      userRole={userRole}
      parties={parties}
      transactionTypeCode={transactionTypeCode}
      multipleItems={multipleItems}
      broker={broker}
    />
  );

const InspectionPeriodCopy = ({ inspectionDays }) => (
  <React.Fragment>
    The <SmallText variant="bold">inspection period</SmallText> for this transaction is{' '}
    <SmallText variant="bold">
      {inspectionDays} calendar {inspectionDays > 1 ? 'days' : 'day'}
    </SmallText>
    .
  </React.Fragment>
);

const TransactionInformation = ({
  transactionTitle,
  transactionId,
  transactionTypeCode,
  inspectionDays,
  parties,
  multipleItems,
  userRole,
  partyHiddenFrom,
  sx,
}) => {
  const broker = parties.broker;

  const handleCopyId = async () => getNavigator().clipboard.writeText(transactionId);

  const otherPartyIsConfidential = isOtherPartyConfidential(userRole, partyHiddenFrom);

  const isMobileViewport = useIsMobileViewport();
  const theme = useTheme();

  return (
    <Stack spacing="12px" sx={sx}>
      {!isMobileViewport && (
        <LargeText sx={{ overflowWrap: 'break-word' }}>{transactionTitle}</LargeText>
      )}
      {isMobileViewport && (
        <MediumText sx={{ overflowWrap: 'break-word', color: theme.palette.secondary.dark }}>
          {transactionTitle}
        </MediumText>
      )}
      <Stack direction="row">
        <SmallText>Transaction #{transactionId}</SmallText>
        <IconButton
          aria-label="Copy Transaction Id"
          onClick={handleCopyId}
          sx={{ padding: 0, marginLeft: '8px' }}
        >
          <ContentCopyIcon
            sx={{ width: '20px', height: '20px', color: 'secondary.dark', transform: 'scaleY(-1)' }}
          />
        </IconButton>
      </Stack>
      <SmallText>
        {userRole === 'broker' ? (
          <BrokerCopy
            userRole={userRole}
            parties={parties}
            transactionTypeCode={transactionTypeCode}
            multipleItems={multipleItems}
          />
        ) : (
          <BuyerOrSellerCopy
            userRole={userRole}
            parties={parties}
            transactionTypeCode={transactionTypeCode}
            multipleItems={multipleItems}
            otherPartyIsConfidential={otherPartyIsConfidential}
            broker={broker}
          />
        )}
        <InspectionPeriodCopy inspectionDays={inspectionDays} />
      </SmallText>
    </Stack>
  );
};

export default TransactionInformation;
