import window from 'window-shim';
import document from 'document-shim';

import ButtonCreationConstants from '../constants/ButtonCreationConstants';
import AuthenticationStore from '../stores/AuthenticationStore';
import { createButtonSnippet } from './create-button-snippet';

const createButton = (button) => {
  const userID = AuthenticationStore.getCustomerID();

  const buttonData = {
    type: ButtonCreationConstants.DOMAIN_TRANSACTION_TYPE,
    non_initiator_email: AuthenticationStore.getEmail(),
    non_initiator_id: userID,
    non_initiator_role: 'seller',
    title: button.title,
    currency: button.currency,
    domain: button.domain_name,
    price: button.price,
    concierge: button.concierge,
    with_content: button.with_content,
    inspection_period: button.inspection_period,
    fee_payer: button.fee_payer,
  };

  return createButtonSnippet(buttonData, button.button_style, button.button_text, true)
    .buttonSnippetCode;
};

const buttonsDownload = (buttonsDataList) => {
  const lines = [
    'title,currency,price,domain_name,concierge,with_content,' +
      'inspection_period,fee_payer,button_style,button_text,html\n',
  ];

  for (const button of buttonsDataList) {
    const buttonSnippet = createButton(button);
    let row = `"${button.title}",`;
    row += `"${button.currency}",`;
    row += `"${button.price}",`;
    row += `"${button.domain_name}",`;
    row += `"${button.concierge}",`;
    row += `"${button.with_content}",`;
    row += `"${button.inspection_period}",`;
    row += `"${button.fee_payer}",`;
    row += `"${button.button_style}",`;
    row += `"${button.button_text}",`;
    row += `"${buttonSnippet}"\n`;
    lines.push(row);
  }

  const blob = new Blob(lines, { type: 'text/csv' });
  const blobURL = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', blobURL);
  link.setAttribute('download', `${window.js_context.file_name}_escrow_buttons.csv`);
  document.body.appendChild(link);
  link.click();
  window.top.close();
};

if (document.querySelector('[data-component="buttons-download"]')) {
  buttonsDownload(window.js_context.button_items);
}

export default buttonsDownload;
