import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Code } from 'spa/components/Code';
import PrimaryLink from 'spa/components/PrimaryLink';
import A from 'spa/components/A';
import {
  Section,
  SectionTitle,
  SectionDesc,
  SectionSubTitle,
  SectionList,
  SectionListItem,
  SectionImage,
} from 'spa/components/Section';
import AccentedText from 'spa/components/AccentedText';
import { urlFor, envDomainName } from '../../../routeConfig';

const EscrowIdVerifyDocs = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktopSmall-9">
        <h1 className="integrationsPortal-title">Escrow ID Verify Documentation</h1>
        <Section>
          <SectionTitle>What is Escrow ID Verify?</SectionTitle>
          <SectionDesc>
            {"Escrow.com's"} Know Your Customer (KYC) program ensures the safety of all transactions
            by confirming customer identity through documentation collection. This helps prevent
            fraud, money laundering and the financing of terrorism.
          </SectionDesc>
          <SectionDesc>
            Escrow ID Verify is the ideal solution for any partner who requires to verify the
            identity of their customers whilst handling transactions on Escrow.
          </SectionDesc>
          <SectionList>
            <SectionListItem>
              A transaction must first be set up between a buyer and seller.
            </SectionListItem>
            <SectionListItem>
              The transaction create response indicates whether a customer in the transaction
              requires additional verification.
            </SectionListItem>
            <SectionListItem>
              The partner makes a request to verify the identity of a given customer.
            </SectionListItem>
            <SectionListItem>
              The verify response provides a unique URL the partner would send the customer to.
            </SectionListItem>
            <SectionListItem>
              Customers provide their details and submit the required ID verification documents and
              are then returned back to a URL provided by the partner.
            </SectionListItem>
          </SectionList>
          <SectionDesc>Here is a screenshot of the adding details step:</SectionDesc>
          <SectionImage
            src="../build/images/escrowIdVerify/escrow-id-details-step.png"
            alt="escrow id verify details step"
          />
          <SectionDesc>
            Here is a screenshot of the uploading verification documents step:
          </SectionDesc>
          <SectionImage
            src="../build/images/escrowIdVerify/escrow-id-upload-step.png"
            alt="escrow id verify upload step"
          />
          <br />
        </Section>
        <Section>
          <SectionTitle id="transaction-simple">Calling the Escrow ID Verify API</SectionTitle>
          <SectionDesc>
            To request a verification of a customer, a transaction must first be created between the
            buyer and seller. Please refer to the
            <A
              link={{
                type: 'app',
                route: urlFor('api_guide_create_transaction'),
              }}
            >
              {' '}
              transaction create documentation{' '}
            </A>
            for more details on how to perform this step. Ater making the transaction create
            request, the response body will contain details about the newly created transaction.
            Within the response, there is a <AccentedText>parties</AccentedText> field which is an
            array of party objects that are involved in the transaction.
          </SectionDesc>
          <SectionSubTitle>Example Transaction Create Response</SectionSubTitle>
          <Code language="json">
            {`{
  "creation_date": "2023-09-30T00:00:00.000000+00:00",
  "currency": "usd",
  "description": "My Escrow Transaction",
  "id": 123456789,
  "items": [ ... ],
  "parties": [
    {
      "agreed": false,
      "customer": "buyer@escrow.com",
      "id": 123,
      "initiator": false,
      "next_step": "...",
      "role": "buyer",
      "verification_required": true
    },
    {
      "agreed": false,
      "customer": "seller@escrow.com",
      "disbursement_method_selected": false,
      "id": 321,
      "initiator": false,
      "next_step": "...",
      "role": "seller"
    }
  ]
}`}
          </Code>
          <SectionDesc>
            If a party requires additional verification, the
            <AccentedText>verification_required</AccentedText> field will appear within the party
            object. To request the verification of a party, you must provide the transaction id, the
            party id that you wish to verify, as well as a return URL which you wish the customer to
            return to after they complete the verification proccess. Using this information you may
            request the verification by performing a POST request as seen below:
          </SectionDesc>

          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.`+envDomainName+`/integration/2019-03-01/transaction/123456789/verify" \\
    -X POST \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
    -d '
    {
        "party_id": 123,
        "return_url": "http://www.return-url.com/landing"
    }'
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.post(
    'https://api.`+envDomainName+`/integration/2019-03-01/transaction/123456789/verify',
    auth=('email address', 'api-key'),
    json={
        "party_id": 123,
        "return_url": "http://www.return-url.com/landing"
    },
)
`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.`+envDomainName+`/integration/2019-03-01/transaction/123456789/verify")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Post.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
  request.body = {
    "party_id": 123,
    "return_url": "http://www.return-url.com/landing"
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.`+envDomainName+`/integration/2019-03-01/transaction/123456789/verify',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'party_id' => 123,
            'return_url' => 'http://www.return-url.com/landing',
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                party_id = 123,
                return_url = "http://www.return-url.com/landing",
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.`+envDomainName+`/integration/2019-03-01/transaction/123456789/verify"),
                Method = HttpMethod.Post,
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
          <SectionSubTitle>Example Response</SectionSubTitle>
          <Code language="json">
            {`{
  "landing_page": "https://www.`+envDomainName+`/verify-landing?token=dd42db00-b769-4c62-a2c7-8dba71eb2405",
  "token": "dd42db00-b769-4c62-a2c7-8dba71eb2405"
}`}
          </Code>
          <br />
          <br />
          <SectionSubTitle>landing_page attribute</SectionSubTitle>
          <SectionDesc>
            The <AccentedText>landing_page</AccentedText> attribute contains the link to which the
            customer should be redirected to after they complete the verification proccess.
          </SectionDesc>
          <SectionSubTitle>token attribute</SectionSubTitle>
          <SectionDesc>
            The <AccentedText>token</AccentedText> attribute contains the token which is used to
            identify the customer verification request. While it is embedded in the{' '}
            <AccentedText>landing_page</AccentedText>, it is split out here for convenience.
          </SectionDesc>
          <SectionDesc>
            For help on making API calls and configuring transactions, please review the links
            below.
          </SectionDesc>
          <SectionTitle>Company Logo on Verification Page</SectionTitle>
          <SectionDesc>
            Partners are able to add their company logo alongside the verification page that is
            presented to customers. This can be achieved by uploading a company logo
            <A
              link={{
                type: 'app',
                route: urlFor('integrations_logos_getstarted'),
              }}
            >
              {' '}
              here
            </A>
            .
          </SectionDesc>
        </Section>
        <footer className="integrationsPortal-footer">
          <h3 className="integrationsPortal-footer-title">{'Relevant Resources'}</h3>
          <nav className="integrationsPortal-footer-steps">
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="API Basics"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_basics'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Creating transactions"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_create_transaction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Partner Logo Management"
                link={{
                  type: 'app',
                  route: urlFor('integrations_logos_getstarted'),
                }}
              />
            </div>
          </nav>
        </footer>
      </div>
    </div>
  </div>
);

export default EscrowIdVerifyDocs;
