import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Icon from 'spa/components/Icon';
import Sidebar from 'spa/containers/Sidebar';
import {
  customerIsKycCompleted,
  isCustomerReadySelector,
  isCustomerExtendedKycRequirementLoading,
} from 'spa/selectors/CustomerSelectors';
import HeaderLogo from './HeaderLogo';
import HeaderTitle from './HeaderTitle';
import { gettext } from '../../../utils/filters';
import LoggedInNav from './LoggedInNav';

const MyTransactionsHeader = (props) => {
  const { title, toggleSidebar, isKycCompleted, isCustomerReady } = props;

  const menuButton = () => (
    <button className="headerV3-menuBtn media--available@tablet" onClick={() => toggleSidebar()}>
      <Icon name="menu" className="headerV3-menu-icon" />
    </button>
  );

  const links =
    isCustomerReady && isKycCompleted
      ? [
          { text: 'My Transactions', link: '/transactions' },
          { text: 'My Integrations', link: '/integrations/portal' },
          { text: 'Help', link: '/helpdesk' },
          { text: 'Contact Us', link: '/contact-us' },
        ]
      : [
          { text: 'My Transactions', link: '/transactions' },
          { text: 'My Integrations', link: '/integrations/portal' },
          { text: 'Get Verified', link: '/verify' },
          { text: 'Help', link: '/helpdesk' },
          { text: 'Contact Us', link: '/contact-us' },
        ];

  const navLinks = () => (
    <ul className="headerV3-nav media--hidden@tablet">
      {links.map((item) => (
        <li key={item.link} className="headerV3-nav-item">
          <a
            key={item.link}
            className={classNames(
              'headerV3-nav-link',
              window.location.pathname === item.link ? 'is-active' : ''
            )}
            href={item.link}
          >
            {gettext(item.text)}
          </a>
        </li>
      ))}
    </ul>
  );

  return (
    <div>
      <header className="headerV3 is-header-scrollTop is-sticky" data-sticky-header>
        <div className="headerV3-primary">
          <div className="headerV3-container section-container">
            <div className="headerV3-inner">
              {menuButton()}
              <HeaderLogo />
              <HeaderTitle title={title} />
              {navLinks()}
            </div>
            <LoggedInNav />
          </div>
        </div>
      </header>
      <Sidebar />
    </div>
  );
};

const mapStatesToProps = (state) => ({
  isKycCompleted: customerIsKycCompleted(state),
  isCustomerReady:
    isCustomerReadySelector(state) && !isCustomerExtendedKycRequirementLoading(state),
});

MyTransactionsHeader.propTypes = {
  title: PropTypes.string.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

export default connect(mapStatesToProps)(MyTransactionsHeader);
