import { useEffect } from 'react';

export const useRecaptchaV3 = (siteKey) => {
  useEffect(() => {
    if (siteKey) {
      const script = document.createElement('script');
      script.src = `https://www.recaptcha.net/recaptcha/api.js?render=${siteKey}`;
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [siteKey]);
};
