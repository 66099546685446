import React from 'react';
import { CountryToAlpha2 } from 'spa/constants/ISOCountryCodes';
import { RegionsByCountry } from 'escrow-common-js/dist/constants';
import { Field, change } from 'redux-form';
import { bindRoutineToReduxForm } from 'redux-saga-routines';
import { Checkbox, Input, Select } from 'spa/components/form';
import { submitUserWireDetails } from 'spa/actions/PaymentsActions';
import { email, required, maxChars } from 'spa/components/form/validate';
import { Spinner } from 'spa/components/Indicators';
import { gettext } from '../../../utils/filters';
import { createSelectOptions } from '../../../utils/select';

class WireTransferFieldset extends React.Component {
  constructor(props) {
    super(props);

    this.validators = {
      state: [maxChars(250, gettext('State'))],
    };

    this.handleCountrySelect = this.handleCountrySelect.bind(this);
    this.submitFuction = this.submitFuction.bind(this);
  }

  getStateSelectOptions(country) {
    const states = RegionsByCountry[country];
    const result = [];

    if (states) {
      for (const state of states) {
        const unique = states.filter((s) => s.name === state.name).length === 1;
        result.push({
          value: state.code,
          label: unique ? state.name : `${state.name} (${state.type})`,
        });
      }
    }
    return result;
  }

  handleCountrySelect() {
    this.props.dispatch(change(this.props.formName, `buyerBankDetails.bankState`, ''));
  }

  submitFuction(values, dispatch, props) {
    const { transId } = this.props;
    return bindRoutineToReduxForm(submitUserWireDetails)(
      {
        ...values.buyerBankDetails,
        transId,
      },
      dispatch,
      props
    );
  }

  renderStateField() {
    const { bankSubmitting } = this.props;
    const states = this.getStateSelectOptions(this.props.bankCountry);
    if (states.length > 0) {
      return (
        <div className="grid-col grid-col--tablet-6">
          <Field
            name="bankState"
            label={gettext('State')}
            placeholder={gettext('Please select state')}
            component={Select}
            options={states}
            validate={[required]}
            disabled={bankSubmitting}
            data-e2e-target="bank-state-field"
          />
        </div>
      );
    }
  }

  renderCountryField() {
    const { bankSubmitting } = this.props;
    return (
      <div className="grid-col grid-col grid-col--tablet-6">
        <Field
          name="bankCountry"
          onChange={this.handleCountrySelect}
          label={gettext('Country')}
          placeholder={gettext('Please select country')}
          component={Select}
          options={createSelectOptions(CountryToAlpha2)}
          validate={[required]}
          disabled={bankSubmitting}
          data-e2e-target="bank-country"
        />
      </div>
    );
  }

  renderThirdPartyField() {
    const { thirdPartyCheckbox, bankSubmitting } = this.props;
    return (
      <div>
        <span>
          <Field
            name="thirdPartyCheckbox"
            label={<div>{gettext('These wire details belong to another person')}</div>}
            component={Checkbox}
            disabled={false}
          />
        </span>
        {thirdPartyCheckbox && (
          <div className="listOptions-thirdPartyEmail">
            <h4 className="listOptions-content-title">
              {gettext('Please provide the email address of the account holder')}
            </h4>
            <Field
              name="thirdPartyEmail"
              component={Input}
              label={gettext('Email Address')}
              validate={[required, email]}
              disabled={bankSubmitting}
              placeholder={gettext('Please enter email address')}
              data-e2e-target="third-party-email"
            />
          </div>
        )}
      </div>
    );
  }

  renderErrorMessage() {
    const {
      buyerBankSubmissionError,
      buyerBankSubmissionErrorMsg,
      getWireDetailsError,
      getWireDetailsErrorMsg,
    } = this.props;
    if (buyerBankSubmissionError) {
      return (
        <h4 className="listOptions-content-title--error">{gettext(buyerBankSubmissionErrorMsg)}</h4>
      );
    } else if (getWireDetailsError) {
      return (
        <h4 className="listOptions-content-title--error">{gettext(getWireDetailsErrorMsg)}</h4>
      );
    }
  }

  renderFields() {
    const { bankSubmitting, handleSubmit, onExit } = this.props;
    return (
      <div>
        <h4 className="listOptions-content-title">
          {gettext(
            'To match your incoming payment with your Escrow transaction, please provide your bank details.'
          )}
        </h4>
        {this.renderErrorMessage()}
        <Field
          name="accountName"
          component={Input}
          label={gettext('Account Name')}
          validate={[required]}
          disabled={bankSubmitting}
          placeholder={gettext('Please enter account name')}
          data-e2e-target="account-name"
        />
        <Field
          name="bankName"
          component={Input}
          label={gettext('Bank/Money Transmitter Name')}
          validate={[required]}
          disabled={bankSubmitting}
          placeholder={gettext('Please enter bank/money transmitter name')}
          data-e2e-target="bank-name"
        />
        <div className="grid">
          {this.renderCountryField()}
          {this.renderStateField()}
        </div>
        {this.renderThirdPartyField()}
        <div className="checkout-card-wire-btnWrapper">
          {onExit ? (
            <button
              onClick={onExit}
              type="button"
              className="checkout-card-wire-btn checkout-card-wire-btn--rightHeavy btn btn--secondary btn--hollow"
              data-e2e-target="get-wire-details-submit-btn"
              data-tracking-name={'wire-details-submit'}
            >
              {gettext('Cancel')}
            </button>
          ) : null}
          <button
            onClick={handleSubmit(this.submitFuction)}
            type="button"
            className="checkout-card-wire-btn btn btn--secondary"
            disabled={bankSubmitting}
            data-e2e-target="get-wire-details-submit-btn"
            data-tracking-name={'wire-details-submit'}
          >
            {bankSubmitting ? <Spinner /> : gettext('Next')}
          </button>
        </div>
      </div>
    );
  }

  render() {
    return <fieldset>{this.renderFields()}</fieldset>;
  }
}

export default WireTransferFieldset;
