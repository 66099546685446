import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Code } from 'spa/components/Code';
import PrimaryLink from 'spa/components/PrimaryLink';
import { Section, SectionTitle, SectionDesc } from 'spa/components/Section';
import AccentedText from 'spa/components/AccentedText';

import { urlFor, envDomainName } from '../../../routeConfig';

const ShipTransaction = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktopSmall-9">
        <h1 className="integrationsPortal-title">API Guide :: Shipping a transaction</h1>
        <Section>
          <SectionTitle>Shipping a transaction</SectionTitle>
          <SectionDesc>
            Once a transaction has been funded, Escrow.com will instruct the seller to ship the
            goods. Shipment can be both physical delivery and non physical delivery.
          </SectionDesc>
          <SectionDesc>
            {`
            Physical delivery refers to items that are shipped via courier and can be tracked via a
            tracking number. Non physical delivery is for electronic items such as domain names.
            While these don't require a tracking number, we do accept other ways of determining that
            the buyer has received the goods. For example, with domain names we track the changes to
            the WHOIS information.`}
          </SectionDesc>
        </Section>
        <Section>
          <SectionTitle>Marking all items as shipped</SectionTitle>
          <SectionDesc>
            Marking a transaction and all of its items as shipped is a simple patch request to the
            transaction endpoint with the <AccentedText>action</AccentedText> attribute set to
            <AccentedText>ship</AccentedText>. There is also an optional field
            <AccentedText>tracking_information</AccentedText> within the
            <AccentedText>shipping_information</AccentedText> field that allows you to enter
            free-form tracking information.
          </SectionDesc>
          <SectionDesc>
            If the API call is successful, it will return the updated transaction object.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.${envDomainName}/2017-09-01/transaction/29292" \\
    -X PATCH \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
    -d '
{
  "action": "ship",
  "shipping_information": {
    "tracking_information": {
      "carrier": "UPS",
      "tracking_id": "1Z999AA10123456784",
      "carrier_contact": "1-234-567-8912"
    }
  }
}'
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.patch(
    'https://api.${envDomainName}/2017-09-01/transaction/29292',
    auth=('email address', 'api-key'),
    json={
        'action': 'ship',
        'shipping_information': {
            'tracking_information': {
                'carrier': 'UPS',
                'tracking_id': '1Z999AA10123456784',
                'carrier_contact': '1-234-567-8912',
            },
        },
    },
)
`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.${envDomainName}/2017-09-01/transaction/29292")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Patch.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
  request.body = {
    'action': 'ship',
    'shipping_information': {
        'tracking_information': {
            'carrier': 'UPS',
            'tracking_id': '1Z999AA10123456784',
            'carrier_contact': '1-234-567-8912'
        }
    }
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.${envDomainName}/2017-09-01/transaction/29292',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_CUSTOMREQUEST => 'PATCH',
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'action' => 'ship',
            'shipping_information' => array(
                'tracking_information' => array(
                    'carrier' => 'UPS',
                    'tracking_id' => '1Z999AA10123456784',
                    'carrier_contact'=> '1-234-567-8912',
                )
            )
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                action = "ship",
                shipping_information = new
                {
                    tracking_information = new
                    {
                        carrier_contact = "1-234-567-8912",
                        carrier = "UPS",
                        tracking_id = "1Z999AA10123456784",
                    },
                },
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.${envDomainName}/2017-09-01/transaction/29292"),
                Method = new HttpMethod("PATCH"),
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
        </Section>
        <Section>
          <SectionTitle>Marking domain name items as shipped</SectionTitle>
          <SectionDesc>
            The ship endpoint is also used to mark a domain name as transferred. For domains, you
            must indicate the authorization type you used to transfer the domain. The allowed values
            for <AccentedText>authorization_type</AccentedText> are
            <AccentedText>push</AccentedText>, <AccentedText>authorization_code</AccentedText> or
            <AccentedText>username_password</AccentedText>.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.${envDomainName}/2017-09-01/transaction/29292" \\
    -X PATCH \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
    -d '
{
  "action": "ship",
  "shipping_information": {
    "authorization_type": "push"
  }
}'
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.patch(
    'https://api.${envDomainName}/2017-09-01/transaction/29292',
    auth=('email address', 'api-key'),
    json={
        'action': 'ship',
        'shipping_information': {
            'authorization_type': 'push',
        },
    },
)
`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.${envDomainName}/2017-09-01/transaction/29292")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Patch.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
  request.body = {
    'action': 'ship',
    'shipping_information': {
      'authorization_type': 'push'
    }
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.${envDomainName}/2017-09-01/transaction/29292',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_CUSTOMREQUEST => 'PATCH',
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'action' => 'ship',
            'shipping_information' => array(
                'authorization_type' => 'push',
            )
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                action = "ship",
                shipping_information = new
                {
                    authorization_type = "push",
                },
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.${envDomainName}/2017-09-01/transaction/29292"),
                Method = new HttpMethod("PATCH"),
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
        </Section>
        <Section>
          <SectionTitle>Marking individual items as shipped</SectionTitle>
          <SectionDesc>
            For milestone transactions, you must mark individual items on a transaction as shipped.
            The request is similar to marking all of the items as shipped on a transaction, however
            you perform the patch request on the item subresource of the transaction.
          </SectionDesc>
          <SectionDesc>
            If the API call is successful, it will return the updated transaction object.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.${envDomainName}/2017-09-01/transaction/29292/item/12345" \\
    -X PATCH \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
    -d '
{
  "action": "ship",
  "shipping_information": {
    "tracking_information": {
      "carrier": "UPS",
      "tracking_id": "1Z999AA10123456784",
      "carrier_contact": "1-234-567-8912",
    },
  }
}'
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.patch(
    'https://api.${envDomainName}/2017-09-01/transaction/29292/item/12345',
    auth=('email address', 'api-key'),
    json={
        'action': 'ship',
        'shipping_information': {
            'tracking_information': {
                'carrier': 'UPS',
                'tracking_id': '1Z999AA10123456784',
                'carrier_contact': '1-234-567-8912',
            },
        },
    },
)
`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.${envDomainName}/2017-09-01/transaction/29292/item/12345")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Patch.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
  request.body = {
    'action': 'ship',
    'shipping_information': {
      'tracking_information': {
        'carrier': 'UPS',
        'tracking_id': '1Z999AA10123456784',
        'carrier_contact': '1-234-567-8912'
      }
    }
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.${envDomainName}/2017-09-01/transaction/29292/item/12345',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_CUSTOMREQUEST => 'PATCH',
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'action' => 'ship',
            'shipping_information' => array(
                'tracking_information' => array(
                    'carrier' => 'UPS',
                    'tracking_id' => '1Z999AA10123456784',
                    'carrier_contact'=> '1-234-567-8912',
                )
            )
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                action = "ship",
                shipping_information = new
                {
                    tracking_information = new
                    {
                        carrier_contact = "1-234-567-8912",
                        carrier = "UPS",
                        tracking_id = "1Z999AA10123456784",
                    },
                },
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.${envDomainName}/2017-09-01/transaction/29292/item/\
12345"),
                Method = new HttpMethod("PATCH"),
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
        </Section>
        <footer className="integrationsPortal-footer">
          <h3 className="integrationsPortal-footer-title">{'Next steps'}</h3>
          <nav className="integrationsPortal-footer-steps">
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Receive items in a transaction"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_receive_items'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Full API reference Document"
                link={{
                  type: 'external',
                  route: window.config.api_docs_page,
                }}
              />
            </div>
          </nav>
        </footer>
      </div>
    </div>
  </div>
);

export default ShipTransaction;
