import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PaymentShortagesContainer from 'spa/containers/PaymentShortages';
import PaymentConfirmationContainer from 'spa/containers/Payments/PaymentConfirmation';
import { transactionByIdSelector } from 'spa/selectors/TransactionSelectors';
import { gettext } from '../../../utils/filters';
import Spinner from '../../components/Indicators/Spinner';
import { paymentsPagePaymentSubmissionSuccessSelector } from '../../selectors/PaymentsPageSelectors';
import { customerSelector, isCustomerReadySelector } from '../../selectors/CustomerSelectors';

const PaymentShortagesPage = (props) => {
  const {
    transaction,
    transactionId,
    customerId,
    areCustomerDetailsReady,
    isSubmissionSuccessful,
  } = props;

  useEffect(() => {
    if (transaction && Object.keys(transaction).length > 0) {
      const items = transaction.items || [];
      const schedules = items.reduce((acc, item) => [...acc, ...(item.schedule || [])], []);
      const isSecured = schedules.every((schedule) =>
        schedule.status ? schedule.status.secured : false
      );

      if (isSecured) {
        window.location.href = `${window.config.www_base_url}/transaction/${transaction.id}`;
      }
    }
  }, [transaction]);

  return (
    <div className="accountInfo">
      <section className="section section--small section--mid">
        <div className="section-container">
          <div className="account-inner">
            {isSubmissionSuccessful ? (
              <div className="checkout-card checkout-card-secondary card">
                <div className="checkout-card-content checkout-card-content--light">
                  <PaymentConfirmationContainer message="Success! Your transaction's outstanding balance payment has been sent. Please wait for Escrow.com to confirm your payment" />
                </div>
              </div>
            ) : (
              <Fragment>
                <header className="account-section-header-content account-section-header-content--full">
                  <div className="account-section-header-content">
                    <h1 className="account-section-title">{gettext('Select a payment method')}</h1>
                    <p className="account-section-desc">
                      {gettext(
                        'Only payments by cards that are under your name or your company name are accepted.'
                      )}
                    </p>
                    <p className="account-section-desc account-section-note">
                      <span className="field-required">*</span>{' '}
                      {gettext('denotes a required field')}
                    </p>
                  </div>
                </header>
                {areCustomerDetailsReady ? (
                  <PaymentShortagesContainer
                    transactionId={transactionId}
                    customerId={customerId}
                    isOutstandingBalance
                  />
                ) : (
                  <Spinner />
                )}
              </Fragment>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const transactionId = parseInt(ownProps.match.params.transaction_id, 10);
  return {
    ...ownProps,
    transactionId,
    customerId: customerSelector(state).custId,
    transaction: transactionByIdSelector(state, transactionId),
    areCustomerDetailsReady: isCustomerReadySelector(state),
    isSubmissionSuccessful: paymentsPagePaymentSubmissionSuccessSelector(state),
  };
};

export default withRouter(connect(mapStateToProps, null)(PaymentShortagesPage));
