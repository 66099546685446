import React from 'react';
import { connect } from 'react-redux';
import {
  buyItNowCta,
  pushAuctionToken,
  pushAuctionSource,
  initialiseMakeAnOffer,
} from 'spa/actions/OfferActions';
import {
  auctionTokenSelector,
  auctionsByIdSelector,
  auctionIdSelector,
  uiSelector,
  viewSelector,
  buyItNowRequestSelector,
} from 'spa/selectors/OfferSelectors';
import Alert from 'spa/components/Alert';
import { Spinner } from 'spa/components/Indicators';
import { MakeAnOfferHeader, MakeAnOfferPanel, MakeAnOfferSuccessPanel } from 'spa/components/Offer';
import Footer from 'spa/containers/Footer';
import { custEmailSelector, isCustomerReadySelector } from 'spa/selectors/CustomerSelectors';
import OfferConstants from 'spa/constants/OfferConstants';

import { gettext } from '../../../utils/filters';
import { getPartiesByRole } from '../../../utils/V4Transaction';

class MakeAnOfferContainer extends React.Component {
  componentDidMount() {
    if (window.config.recaptcha_site_key) {
      this.setupRecaptchaV3(window.config.recaptcha_site_key);
    }

    if (this.props.token) {
      this.props.pushAuctionToken(this.props.token);
      this.props.initialiseMakeAnOffer(this.props.token);
    }

    if (this.props.source) {
      this.props.pushAuctionSource(this.props.source);
    }

    this.renderMakeAnOfferForm = this.renderMakeAnOfferForm.bind(this);
  }

  setupRecaptchaV3(siteKey) {
    const script = document.createElement('script');
    script.src = `https://www.recaptcha.net/recaptcha/api.js?render=${siteKey}`;
    script.async = true;
    document.body.appendChild(script);
  }

  renderMakeAnOfferForm() {
    const { auctionId, auctionsById, custEmail } = this.props;
    const { getAuction } = this.props.requests;
    const auction = auctionsById[auctionId];

    if (getAuction.loading) {
      return <Spinner />;
    }
    let errorMessage;
    const sellerEmail = auction ? getPartiesByRole(auction, 'seller')[0].customer : null;
    const brokerEmail = auction ? (getPartiesByRole(auction, 'broker')[0] || {}).customer : null;

    if (getAuction.error) {
      errorMessage = getAuction.errorMessage;
    } else if (auction) {
      if (sellerEmail === custEmail) {
        errorMessage = gettext('Cannot make a new offer as the seller party.');
      } else if (brokerEmail === custEmail) {
        errorMessage = gettext('Cannot make a new offer as the initiating party.');
      }
    }

    if (errorMessage) {
      if (errorMessage === 'Sorry! This auction has already ended.') {
        return (
          <div className="offer-notice">
            <header className="offer-header">
              <h1 className="offer-notice-title">{gettext('Auction Closed')}</h1>
            </header>
            <div className="offer-notice-body">
              <div className="offer-notice-image-container offer-notice-image-container--inverted">
                <img
                  className="offer-notice-image"
                  alt="Auction Closed"
                  src="../../../../build/images/offer/offer_closed.png"
                />
              </div>
              <h3>{gettext(errorMessage)}</h3>
              <a href={`${window.config.www_base_url}/transactions`}>Go Back</a>
            </div>
          </div>
        );
      }
      return (
        <Alert type="error">
          <strong>{errorMessage}</strong>
        </Alert>
      );
    }

    return (
      <MakeAnOfferPanel
        auctionToken={this.props.auctionToken}
        auctionsById={this.props.auctionsById}
        auctionId={this.props.auctionId}
        getAuctionUi={getAuction}
        isAuthenticated={this.props.isAuthenticated}
        auctionRole={'buyer'}
        buyItNowAction={this.props.buyItNowCta}
        buyItNowUi={this.props.buyItNowUi}
        geoIpCountry={window.config.geoip_country}
      />
    );
  }

  renderMakeOfferSuccessView() {
    return <MakeAnOfferSuccessPanel />;
  }

  render() {
    let body = null;
    if (this.props.currentView === OfferConstants.MAKE_OFFER_VIEW) {
      body = this.renderMakeAnOfferForm();
    } else if (this.props.currentView === OfferConstants.MAKE_OFFER_SUCCESS_VIEW) {
      body = this.renderMakeOfferSuccessView();
    }

    return (
      <div data-tracking-label={this.props.auctionToken}>
        <div className="checkout-inner">
          <div className="checkout-card">
            <MakeAnOfferHeader auctionToken={this.props.auctionToken} />
            {body}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const auctionId = auctionIdSelector(state);
  return {
    auctionId,
    auctionToken: auctionTokenSelector(state),
    auctionsById: auctionsByIdSelector(state),
    buyItNowUi: buyItNowRequestSelector(state, auctionId),
    currentView: viewSelector(state),
    custEmail: custEmailSelector(state),
    isAuthenticated: isCustomerReadySelector(state),
    requests: {
      getAuction: uiSelector(state).getAuction,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  pushAuctionToken: (auctionToken) => {
    dispatch(pushAuctionToken(auctionToken));
  },
  pushAuctionSource: (actionType) => {
    dispatch(pushAuctionSource(actionType));
  },
  initialiseMakeAnOffer: (token) => {
    dispatch(initialiseMakeAnOffer(token));
  },
  buyItNowCta: (auctionId, auctionToken) => {
    dispatch(buyItNowCta(auctionId, auctionToken));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MakeAnOfferContainer);
