import React, { Fragment } from 'react';
import { reduxForm, Field } from 'redux-form';
import { bindRoutineToReduxForm } from 'redux-saga-routines';
import { Button } from 'escrow-common-js/dist/components';
import { validateTwoFactor } from 'spa/actions/TwoFactorActions';
import TwoFactorConstants from 'spa/constants/TwoFactorConstants';
import { InputOtp, FormErrorBox } from 'spa/components/form';
import { Spinner } from 'spa/components/Indicators';
import { gettext } from '../../../utils/filters';

class SetupTwoFactorVerificationForm extends React.Component {
  constructor(props) {
    super(props);
    this._renderForm = this._renderForm.bind(this);
  }

  _renderForm() {
    const { configType, navigateMain, pristine, submitting } = this.props;

    return (
      <Fragment>
        <Field
          className="twoFactorAuth-page-input"
          name="code2fa"
          component={InputOtp}
          autoFocus={configType !== TwoFactorConstants.TYPE_SMS}
        />
        {configType === TwoFactorConstants.TYPE_SMS && (
          <div className="twoFactorAuth-page-subheader twoFactorAuth-page-subheader--auth">
            {gettext(
              `It may take a minute to receive your code. Haven't received it? Click
              the "Send Code" button to resend it.`
            )}
          </div>
        )}
        <div className="twoFactorAuth-page-footer">
          <a
            className="twoFactorAuth-page-backBtn twoFactorAuth-page-backBtn--secondary"
            role="button"
            tabIndex={0}
            onClick={navigateMain}
          >
            {gettext('Back')}
          </a>
          <Button
            className="twoFactorAuth-page-btn-next twoFactorAuth-page-btn"
            type="submit"
            disabled={pristine || submitting}
          >
            {submitting ? <Spinner /> : gettext('Verify')}
          </Button>
        </div>
      </Fragment>
    );
  }

  render() {
    const { form, handleSubmit, error, submitFailed } = this.props;

    return (
      <form name={form} onSubmit={handleSubmit(bindRoutineToReduxForm(validateTwoFactor))}>
        {submitFailed && error && <FormErrorBox error={error} />}
        {this._renderForm()}
      </form>
    );
  }
}

export default reduxForm({
  form: TwoFactorConstants.VERIFY_FORM_NAME,
  enableReinitialize: false,
  destroyOnUnmount: true,
  onSubmitSuccess: (result, dispatch, props) => props.navigateDone(),
})(SetupTwoFactorVerificationForm);
