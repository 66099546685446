import { createRoutine } from 'redux-saga-routines';

import AuthenticationConstants from 'spa/constants/AuthenticationConstants';

export const formLoginSignup = createRoutine(AuthenticationConstants.ACTION_FORM_LOGIN_SIGNUP);

export const login = createRoutine(AuthenticationConstants.ACTION_LOGIN);
export const loginSSO = createRoutine(AuthenticationConstants.ACTION_LOGIN_SSO);

export const signup = createRoutine(AuthenticationConstants.ACTION_SIGNUP);

export const twoFAValidate = createRoutine(AuthenticationConstants.TWO_FA_VALIDATE);

export const updateContactDetails = createRoutine(
  AuthenticationConstants.ACTION_UPDATE_CONTACT_DETAILS
);

export const setMode = (mode) => ({
  type: AuthenticationConstants.ACTION_SET_MODE,
  payload: mode,
});

export const prefillUsername = (username) => ({
  type: AuthenticationConstants.PREFILL_USERNAME,
  payload: username,
});

export default {
  formLoginSignup,
  login,
  loginSSO,
  signup,
  updateContactDetails,
  setMode,
  prefillUsername,
};
