import React from 'react';

const PaymentConfirmationFooter = () => (
  <div className="checkout-confirmation-header">
    <h2 className="checkout-confirmation-header-heading checkout-confirmation-header-heading--light">
      What happens next?
    </h2>
    <p className="checkout-confirmation-header-subheading">
      {`Once you've received your confirmation email, we'll send you another email when your order is on its way.`}
    </p>
  </div>
);

export default PaymentConfirmationFooter;
