import PartnerConstants from 'spa/constants/PartnerConstants';
import INTERVAL from 'spa/constants/IntervalConstants';
import moment from 'moment';

import { gettext } from '../utils/filters';

const { DEFAULT_START_DATE } = PartnerConstants;

export const applyFilters = (state, filters, dataSelector, filterValueIdsSelector) => {
  const applicableFilters = Object.entries(filters).filter((filter) => filter[1].length > 0) || [];
  if (applicableFilters.length > 0) {
    let data = dataSelector(state) || [];
    applicableFilters.map(([filterCategory, filterValues]) => {
      filterValues.map((filterValue) => {
        data = data.filter((entry) =>
          (filterValueIdsSelector(state, filterCategory, filterValue) || []).find(
            (id) => id === entry.id
          )
        );
      });
    });
    return data;
  }
  return dataSelector(state);
};

export const hasActiveFilters = (filter) =>
  Object.values(filter).reduce((hasFilters, cur) => hasFilters || cur.length > 0, false);

export const getTransactionTotal = ({ items }) =>
  items.reduce((scheduleTotal, item) => {
    const itemPriceTotal = item.schedule.reduce(
      (itemPrice, schedule) => itemPrice + parseFloat(schedule.amount),
      0.0
    );
    return scheduleTotal + itemPriceTotal;
  }, 0.0);

export const getTransactionStatus = ({
  close_date: closeDate,
  is_cancelled: isCancelled,
  is_draft: isDraft,
}) => {
  if (isDraft && isCancelled) return gettext('Draft');
  if (isCancelled) return gettext('Cancelled');
  if (closeDate) return gettext('Completed');
  return gettext('Ongoing');
};

export const getDisplayStartDateByInterval = (interval) => {
  if (interval === INTERVAL.YEARLY.value) return moment(DEFAULT_START_DATE);
  if (interval === INTERVAL.DAILY.value) return moment().subtract(1, 'month').add(1, 'day');

  return moment().subtract(1, 'year').add(1, 'month');
};

export const getIntervalByDisplayRange = (range) => {
  if (range === INTERVAL.RANGE.ALL_TIME.value) return INTERVAL.YEARLY.value;
  if (range === INTERVAL.RANGE.LAST_30DAYS.value) return INTERVAL.DAILY.value;
  return INTERVAL.MONTHLY.value;
};

export const getDisplayRange = (range) => {
  if (range === INTERVAL.RANGE.ALL_TIME.value) return [moment(DEFAULT_START_DATE), moment()];
  if (range === INTERVAL.RANGE.LAST_30DAYS.value) return [moment().subtract(30, 'day'), moment()];

  if (range === INTERVAL.RANGE.LAST_YEAR.value)
    return [
      moment().startOf('year').subtract(1, 'year'),
      moment().startOf('year').subtract(1, 'month').format('YYYY-MM-DD'),
    ];

  return [
    moment().startOf('year').format('YYYY-MM-DD'),
    moment().startOf('year').add(1, 'year').subtract(1, 'month').format('YYYY-MM-DD'),
  ];
};
