import { GlobalEventHandler } from '../utils/EventHandler';

/**
 * Sidebar toggle
 * - Open and closes the sidebar
 */

function openSideBar() {
  document.querySelector('html').classList.toggle('is-sidebar-active');
}

/**
 * Accordion
 */

function _openContent(trigger, el, heightLimit) {
  el.style.height = `${heightLimit}px`;
  trigger.classList.add('is-collapsed');
}

function _closeContent(trigger, el) {
  el.style.height = '0px';
  trigger.classList.remove('is-collapsed');
}

function toggleContent(event, element) {
  if (element.classList.contains('is-collapsed')) {
    _closeContent(element, element.nextElementSibling);
  } else {
    const contentHeight = element.nextElementSibling.querySelectorAll(
      '[data-taget="accordion-content"]'
    )[0].offsetHeight;

    for (const e of document.querySelectorAll('[data-target="accordion-trigger"]')) {
      _closeContent(element, e.nextElementSibling);
      e.classList.remove('is-collapsed');
    }

    _openContent(element, element.nextElementSibling, contentHeight);
  }
}

GlobalEventHandler.addEventListener('click', '[data-sidebar-toggle]', openSideBar);

GlobalEventHandler.addEventListener('click', '[data-target="accordion-trigger"]', toggleContent);
