import React from 'react';
import { connect } from 'react-redux';

import { getWebhooksRequest, deleteWebhooksRequest } from 'spa/actions/WebhooksActions';
import {
  webhooksSelector,
  loadingSelector,
  successSelector,
} from 'spa/selectors/WebhooksSelectors';

import { Section } from 'spa/components/Section';
import {
  DashboardCard,
  DashboardCardHeader,
  DashboardCardBody,
} from 'spa/components/DashboardCard';
import { Spinner } from 'spa/components/Indicators';

class DisplayWebhooks extends React.Component {
  componentWillMount() {
    this.props.getWebhook();
  }

  render() {
    const { webhooks, create, display, deleteWebhook } = this.props;
    return (
      <Section>
        <DashboardCard>
          <DashboardCardHeader title="Your Webhooks" />
          <DashboardCardBody>
            {display.success && !display.loading ? (
              <div>
                <table className="dashboardTable">
                  <thead className="dashboardTable-header">
                    <tr className="dashboardTable-row">
                      <th className="dashboardTable-heading">Url</th>
                      <th className="dashboardTable-heading" />
                    </tr>
                  </thead>
                  <tbody>
                    {webhooks.length ? (
                      webhooks.map((webhook) => (
                        <tr className="dashboardTable-row" key={webhook.id}>
                          <td className="dashboardTable-cell">{webhook.url}</td>
                          <td className="dashboardTable-cell dashboardTable-cell--action">
                            {webhook.deleting ? (
                              <span className="dashboardTable-loader">
                                <Spinner />
                              </span>
                            ) : (
                              <a
                                onClick={deleteWebhook(webhook.id)}
                                role="button"
                                tabIndex={0}
                                className="dashboardTable-removeBtn"
                              >
                                Delete
                              </a>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <em>You have no webhooks configured</em>
                    )}
                  </tbody>
                </table>
                {create.loading && <Spinner />}
              </div>
            ) : (
              <Spinner />
            )}
          </DashboardCardBody>
        </DashboardCard>
      </Section>
    );
  }
}

const mapStateToProps = (state) => ({
  webhooks: webhooksSelector(state),
  create: {
    loading: loadingSelector(state, 'create'),
    success: successSelector(state, 'create'),
  },
  display: {
    loading: loadingSelector(state, 'display'),
    success: successSelector(state, 'display'),
  },
});

const mapDispatchToProps = (dispatch) => ({
  deleteWebhook: (id) => () => dispatch(deleteWebhooksRequest(id)),
  getWebhook: () => dispatch(getWebhooksRequest()),
});

const DisplayWebhooksContainer = connect(mapStateToProps, mapDispatchToProps)(DisplayWebhooks);

export default DisplayWebhooksContainer;
