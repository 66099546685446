import React, { Fragment } from 'react';
import classnames from 'classnames';
import { Button } from '@mui/material';
import Spinner from './Spinner';

const SubmitButton = ({ disabled, loading, children, fullWidth, muiVariant, ...props }) => {
  const submitButtonContent = (
    <Fragment>
      <div
        className={classnames('createTransaction-submit-text', {
          'is-animated': loading,
          disabled,
        })}
      >
        {children || 'Start transaction'}
      </div>
      <div className="createTransaction-submit-spinner-wrapper">
        {loading && <Spinner animateIn />}
      </div>
    </Fragment>
  );

  return (
    <Fragment>
      {muiVariant && !loading ? (
        <Button
          className={'MuiButton'}
          fullWidth={fullWidth}
          color="primary"
          variant={muiVariant}
          {...props}
        >
          {children}
        </Button>
      ) : (
        <button
          disabled={disabled || loading}
          className={classnames(
            'createTransaction-submit-button',
            {
              'is-animated': loading,
            },
            { 'createTransaction-submit-button--fullWidth': fullWidth }
          )}
          {...props}
        >
          {submitButtonContent}
        </button>
      )}
    </Fragment>
  );
};

export default SubmitButton;
