import React, { Fragment, useRef, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useForm } from 'react-final-form';
import { priceValidate } from 'spa/components/form/validate';
import TransactionConstants from 'spa/constants/TransactionConstants';

const { DESKTOP_VIEW_WIDTH, CSV_UPLOAD_ERROR } = TransactionConstants;

const BulkInput = ({
  onSubmit,
  onSuccess,
  onFailure,
  name,
  disabled,
  currentIndex,
  width,
  category,
  broker,
}) => {
  const csvInput = useRef(null);
  const [processing, setProcessing] = useState(false);
  const form = useForm();
  const onClick = () => {
    csvInput.current.click();
  };
  const downloadExample = () => {
    const example = [
      ['Item Name', 'Item Description', 'Price', 'Broker Commission'],
      ['abc.com', 'a news site', '123.00', '10.00'],
      ['johnwick.com', 'superhero', '5123.00', '10.00'],
      ['escrow.com', "world's most secure payment method", '10000.00', '10.00'],
    ];
    // No broker fee if not broker
    const data = example.map((row) => (broker ? row : row.slice(0, -1)).join(',')).join('\n');
    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${data}`);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'example.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const onBulkInsertCsv = (files) => {
    const file = files[0];
    if (!file) {
      return;
    }
    setProcessing(true);
    onSubmit();
    const reader = new FileReader();
    reader.addEventListener('load', (x) => {
      let items = x.target.result.split('\n');
      if (items.length < 1) {
        setProcessing(false);
        onFailure();
        return;
      }
      if (items[0].trim().match(/^.*name.*description.*price.*$/i)) {
        items = items.slice(1);
      }
      const itemDetails = items
        .map((item) => item.trim())
        .filter((item) => item)
        .map((item) => item.split(','));
      if (itemDetails.length < 1) {
        form.mutators.setItemsError(CSV_UPLOAD_ERROR);
        setProcessing(false);
        onFailure();
        return;
      }

      const error = itemDetails.some(([itemName, itemDescription, price, brokerFee]) => {
        if (
          !itemName ||
          !itemDescription ||
          !price ||
          priceValidate(price) ||
          (broker && (!brokerFee || priceValidate(brokerFee)))
        ) {
          return true;
        }
      });
      if (error) {
        form.mutators.setItemsError(CSV_UPLOAD_ERROR);
        setProcessing(false);
        onFailure();
        return;
      }

      setTimeout(() => {
        form.mutators.concat(
          name,
          itemDetails.map(([itemName, itemDescription, price, brokerCommission]) => ({
            name: itemName,
            description: itemDescription,
            price,
            ...(brokerCommission && { brokerCommission }),
            category: category,
            itemType: 'domain_name',
          }))
        );
        form.mutators.remove(name, currentIndex);
        onSuccess();
      }, 250);
    });
    reader.readAsText(file);
  };
  const ExampleCSVButton = (
    <div className="createTransaction-button-container-right createTransaction-button-container-inline">
      <Button
        onClick={downloadExample}
        fullWidth={width < DESKTOP_VIEW_WIDTH}
        size="large"
        disabled={disabled}
      >
        Download csv template
      </Button>
    </div>
  );
  const UploadCSVButton = (
    <div className="createTransaction-button-container-right createTransaction-button-container-inline">
      <input
        type="file"
        accept=".csv"
        ref={csvInput}
        hidden
        onChange={(event) => onBulkInsertCsv(event.target.files)}
      />
      <LoadingButton
        onClick={onClick}
        loading={processing}
        endIcon={<UploadFileIcon />}
        variant="outlined"
        color="primary"
        loadingPosition="end"
        fullWidth={width <= DESKTOP_VIEW_WIDTH}
        disabled={disabled}
        size="large"
      >
        Bulk upload by csv
      </LoadingButton>
    </div>
  );
  return (
    <Fragment>
      {width >= DESKTOP_VIEW_WIDTH && ExampleCSVButton}
      {width >= DESKTOP_VIEW_WIDTH && UploadCSVButton}

      {width < DESKTOP_VIEW_WIDTH && UploadCSVButton}
      {width < DESKTOP_VIEW_WIDTH && ExampleCSVButton}
    </Fragment>
  );
};
export default BulkInput;
