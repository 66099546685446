import { debounce } from 'lodash';
import { required, email } from 'spa/components/form/validate';
import CheckoutConstants from 'spa/constants/CheckoutConstants';
import API from '../../../api/API';
import { gettext } from '../../../utils/filters';

const debouncedCheckIfEmailExist = debounce(
  (username, resolve, reject) =>
    API.checkIfEmailExist(username).then((isEmailExist) => {
      if (isEmailExist === true) {
        return reject({
          username: gettext('The email address is already registered with Escrow.com.'),
        });
      }
      return resolve();
    }),
  200
);

export function loginFormAsyncValidate(values, dispatch, props) {
  if (props.isAuthenticated) {
    return Promise.resolve();
  }
  if (props.currentStep === CheckoutConstants.STEP_LOGIN || values.loginMode) {
    return Promise.resolve();
  }
  if (required(values.username) !== undefined) {
    return Promise.resolve();
  }
  if (email(values.username) !== undefined) {
    return Promise.resolve();
  }

  return new Promise((resolve, reject) =>
    debouncedCheckIfEmailExist(values.username, resolve, reject)
  );
}

export const asyncValidate = loginFormAsyncValidate;
