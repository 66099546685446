import { take, takeEvery, put, select } from 'redux-saga/effects';
import { push, LOCATION_CHANGE } from 'connected-react-router';

import { userSelector, userLoadingSelector } from 'spa/selectors/UserSelectors';
import {
  mostRecentStartedTaskSelector,
  transformedTaskListSelector,
  makeTaskSelector,
} from 'spa/selectors/TaskSelectors';
import User from 'spa/constants/UserConstants';
import NavigationActions from '../../actions/NavigationActions';

import { getRouteFromPath, routeFor } from '../../routeConfig';

function* awaitUser() {
  if (select(userLoadingSelector)) {
    yield take(User.GET_USER_SUCCESS);
  }
  const user = yield select(userSelector);
  return user;
}

export function* navigate(action) {
  const { pathname } = action.payload.location;
  const route = getRouteFromPath(pathname);

  if (route && route.title) {
    document.title = `${route.title} - Escrow.com`;
  } else {
    document.title = 'Escrow.com';
  }

  if (pathname === routeFor('integrations_home')) {
    // await the user to determine where to redirect to
    yield awaitUser();
    // app root redirects
    const startAPIIntegrationTask = yield select(makeTaskSelector('start_api_integration'));
    const apiTaskList = yield select(transformedTaskListSelector, 'api');
    const integration = yield select(mostRecentStartedTaskSelector);
    // if the start_api_integration task is complete, but the task list is not
    // redirect to api
    if (
      startAPIIntegrationTask.completion_date && // completionDate implies completion
      apiTaskList.complete !== apiTaskList.total
    ) {
      yield put(push(routeFor('integrations_api_getstarted')));
    } else if (integration) {
      yield put(push(integration));
    } else {
      yield put(push(routeFor('integrations_getstarted')));
    }
  }
}

export function changeNavigationBar(action) {
  const { pathname } = action.payload.location;
  const spaRouteData = getRouteFromPath(pathname);
  if (spaRouteData && spaRouteData.page_type) {
    NavigationActions.setPageType(spaRouteData.page_type);
  } else {
    NavigationActions.setPageType('default');
  }
}

export function* navigateWatcher() {
  yield takeEvery(LOCATION_CHANGE, navigate);
  yield takeEvery(LOCATION_CHANGE, changeNavigationBar);
}

export default [navigateWatcher];
