import React, { useCallback, useState } from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { UsernameInput } from 'spa/components/form/Input';
import Footer from 'spa/containers/Footer';
import { Spinner } from 'spa/components/Indicators';
import { useForm } from 'react-hook-form';
import { Modal } from 'escrow-common-js/dist/components';
import ReactSVG from 'react-svg';
import { FormError } from 'spa/components/form';
import { useRequestPasswordResetMutation } from '../resetPasswordApi';
import { gettext } from '../../../../utils/filters';
import { urlFor } from '../../../../routeConfig';

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getFieldState,
  } = useForm({
    mode: 'onTouched',
  });

  const [
    triggerRequestPasswordReset,
    { isLoading: requestPasswordResetLoading, error: requestPasswordResetError },
  ] = useRequestPasswordResetMutation();

  const closeModal = () => {
    setModalOpen(false);
    reset();
  };

  const navigateToLogin = useCallback(() => {
    dispatch(push(urlFor('login_page')));
  }, [dispatch]);

  const onSubmit = useCallback(
    async (data) => {
      try {
        await triggerRequestPasswordReset(data).unwrap();
        setModalOpen(true);
      } catch (error) {
        // Do nothing as we already handle the request error elsewhere
      }
    },
    [triggerRequestPasswordReset]
  );

  return (
    <div>
      <Modal
        isOpen={modalOpen}
        paddingSize=""
        targetElementId="spa"
        exitButton={false}
        enableBackdropClick
        onBackdropClick={closeModal}
        cardClassName="transactions-modal-fullscreen-card"
      >
        <div className="transactions-modal-fullscreen-content">
          <ReactSVG
            className="transactions-modal-fullscreen--svg"
            src="../build/images/global/icons/escrow-shield.svg"
            beforeInjection={(svg) => {
              svg.classList.add('transactions-modal-fullscreen--svg--size');
            }}
          />
          <div className="transactions-modal-fullscreen--title">
            Password reset email has been sent
          </div>
          <div className="transactions-modal-fullscreen--description">
            {`Please check your email for instructions to reset your password.`}
          </div>
          <button
            className="btn btn--secondary"
            onClick={(e) => {
              e.stopPropagation();
              closeModal();
            }}
          >
            {'Close'}
          </button>
        </div>
      </Modal>
      <div className="verifyPage section section--mid">
        <div className="checkout-content section-container">
          <div className="verifyPage-body">
            <div className="verifyPage-card">
              {requestPasswordResetError && (
                <div className="checkout-authError">
                  <FormError error="We couldn't find an account with that email address. Please try again." />
                </div>
              )}
              <div className="checkout-card-content signupForm-card checkout-card-content--auth">
                <div>
                  <h2 className="checkout-heading">Reset your password</h2>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <UsernameInput
                      label="email"
                      register={register}
                      required
                      errors={errors}
                      isDirty={getFieldState('email').isDirty}
                    />
                  </form>
                </div>
                <div className="checkout-card-btn-container">
                  <button
                    type="submit"
                    className="checkout-card-btn checkout-card-btn--auth btn btn--secondary"
                    onClick={handleSubmit(onSubmit)}
                    disabled={requestPasswordResetLoading}
                  >
                    {requestPasswordResetLoading ? <Spinner /> : gettext('Continue')}
                  </button>
                </div>
              </div>
              <div className="checkout-switcher checkout-card-content signupForm-card">
                <button
                  className="checkout-card-btn checkout-card-btn--auth btn btn--secondary btn--hollow"
                  onClick={navigateToLogin}
                >
                  Back to Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ForgotPasswordPage;
