import React from 'react';
import _ from 'lodash';

import {
  CollapsableContent,
  CollapsableContentBody,
  CollapsableContentTitle,
} from 'spa/components/CollapsableContent';
import { SectionTitle, SectionDesc } from 'spa/components/Section';
import HintBanner from 'spa/components/HintBanner';
import ExpandCollapse from 'spa/components/ExpandCollapse';

import content from './content';

const FAQPage = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktopSmall-9">
        <header className="integrationsPortal-header">
          <h1 className="integrationsPortal-title">FAQ</h1>
          <ExpandCollapse name="faq-content" collapseText="Collapse All" expandText="Expand All" />
        </header>
        <SectionDesc>
          This section covers Frequently Asked Questions relating to the API, developers portal and
          available integrations. Help and FAQ’s relating to the general escrow process and website
          are available here.
        </SectionDesc>
        {content.map((section) => (
          <section key={section.key} className="integrationsPortal-faqSection">
            <SectionTitle>{section.title}</SectionTitle>
            {section.faqs.map((faq) => (
              <CollapsableContent key={faq.key} expandCollapseSubscriptionName="faq-content">
                <CollapsableContentTitle>{faq.question}</CollapsableContentTitle>
                <CollapsableContentBody>
                  {faq.answer}
                  {!_.isEmpty(faq.hints) &&
                    faq.hints.map((hint) => (
                      <HintBanner
                        key={hint.key}
                        className="integrationsPortal-hintBanner"
                        title={hint.title}
                        desc={hint.desc}
                        icon={hint.warning ? 'ui-alert-circle' : 'ui-zap'}
                        warning={hint.warning}
                      />
                    ))}
                </CollapsableContentBody>
              </CollapsableContent>
            ))}
          </section>
        ))}
      </div>
    </div>
  </div>
);

export default FAQPage;
