import { sprintf } from 'sprintf-js';
import { escape } from 'nunjucks/src/lib';
import window from 'window-shim';
import runtime from 'nunjucks/src/runtime';

/**
 * Given an object, converts the keys and values into xml attributes suitable
 * for applying to html elements.
 *
 * The keys aren't escaped, however the values are escaped.
 *
 * @example
 * const output = xmlattr({'data-target': 'login'});
 * console.log(output); // data-target="login"
 *
 * @example
 * <div {{attrs|xmlattr}}></div>
 *
 * @param {Object} obj The object we want to convert to xml attributes
 * @return {String}
 */
export function xmlattr(obj) {
  const output = [];
  if (obj) {
    for (const key of Object.keys(obj)) {
      output.push(sprintf('%s=%s', key, escape(obj[key])));
    }
  }
  return runtime.markSafe(output.join(' '));
}

/**
 * A printf implementation for nunjucks
 *
 * @example
 * {{"hello %s"|format("world")}}
 */
export function format(str, vars) {
  return sprintf(str, vars);
}

/**
 * Given an object, set the attribute and key, without modifying the original
 * object.
 *
 * This is useful in templates where you want to pass an object from one macro
 * to another, but want to add an attribute to the object.
 *
 * @example
 * {% defaultMacro(attrs|setattr('key', 'value')) %}
 *
 * @param {Object} obj The original object we want to extend
 * @param {String} attribute The name of the attribute we want to set
 * @param {Object} value The value of the attribute we want to set
 * @return {Object}
 */
export function setattr(obj, attribute, value) {
  return Object.assign({}, obj, { [attribute]: value });
}

/**
 * A *slightly* simple implementation of the gettext function
 * It now handles string interpolation after lookup
 *
 * @param {String} str the string to be translated
 * @param {Varargs} args multiple arguments accepted, which will be propagated to sprintf
 * @param {String} a translated string
 */
export function gettext(str, ...args) {
  if (typeof window === 'undefined' || !window.config || !window.config.translations) {
    return str;
  }

  let translation = window.config.translations[str] || str;
  /* If there is no extra argument, calling sprintf will throw an error */
  if (args.length > 0) {
    translation = sprintf(translation, ...args);
  }
  return translation;
}

/**
 * A *very* simple implementation of the flask url_for function
 *
 * @param {String} endpoint the endpoint for which a url is to be given
 */
export function urlfor(endpoint) {
  if (typeof window === 'undefined' || !window.config || !window.config.url_map) {
    throw new Error('url_for was unable to access the window.config.url_map object');
  }

  const fullEndpoint = `views.${window.config.language_subpath}${endpoint}`;
  const url = window.config.url_map[fullEndpoint] || '';
  return url;
}

/**
 * Implementation for converting 'USD', 'EUR' and 'AUD' to their symbol '$', '€', '$' respectively
 *
 * @example
 * {{'USD'|to_symbol}} which returns '$'
 *
 * @param {String} currency tag in uppercase
 */
export function tosymbol(currencyTag) {
  if (typeof window === 'undefined' || !window.config || !window.config.currencies) {
    throw new Error('to_symbol was unable to access the window.config.currencies object');
  }

  const currency = window.config.currencies[currencyTag];
  if (!currency) {
    throw new Error(`does not support currency tag: ${currencyTag}`);
  }

  return currency.symbol;
}
