/* eslint-disable no-console */

import { takeLatest } from 'redux-saga/effects';

import ErrorLoggingConstants from 'spa/constants/ErrorLoggingConstants';

export function logWarning(action) {
  console.warn(action.message);
}

export function logError(action) {
  console.error(action.message);
}

export function* watcher() {
  yield takeLatest(ErrorLoggingConstants.LOG_WARNING, logWarning);
  yield takeLatest(ErrorLoggingConstants.LOG_ERROR, logError);
}

export default [watcher];
