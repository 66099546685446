import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import { TableBody } from '@mui/material';
import { formatCurrency } from 'spa/components/StartTransaction/util';

import { EscrowPaper } from '../../../ui/EscrowPaper';
import { MediumText, SmallText } from '../../../ui/EscrowTypography';
import { EscrowLeftCell, EscrowRightCell } from '../../../ui/EscrowTable';

const TransactionPaymentProcessingDetails = ({ transaction, paymentMethods }) => {
  const { currency, waiveEscrowFee, waiveIntermediaryFee, waiveIntermediaryFeeV4API } = transaction;

  const { internationalWireTransfer, creditCard, domesticWireTransfer, ach } = useMemo(() => {
    const result = {
      internationalWireTransfer: { available: false, amount: 0 },
      creditCard: { available: false, amount: 0 },
      domesticWireTransfer: { available: false, amount: 0 },
      ach: { available: false, amount: 0 },
    };
    if (paymentMethods !== undefined) {
      for (const paymentMethod of paymentMethods.available_payment_methods) {
        if (paymentMethod.type === 'wire_transfer') {
          const intermediary = paymentMethod.fees.filter((fee) => fee.type === 'intermediary')[0];
          if (intermediary && intermediary.amount !== undefined) {
            result.internationalWireTransfer.available = true;
            result.internationalWireTransfer.amount = parseFloat(intermediary.amount);
          } else {
            result.domesticWireTransfer.available = true;
          }
        }
        if (paymentMethod.type === 'credit_card' || paymentMethod.type === 'paypal') {
          result.creditCard.available = true;
          const creditCardFee = paymentMethod.fees.filter((fee) => fee.type === 'credit_card')[0];
          if (creditCardFee && creditCardFee.amount !== undefined) {
            result.creditCard.amount = parseFloat(creditCardFee.amount);
          }
        }
        if (paymentMethod.type === 'direct_debit') {
          result.ach.available = true;
        }
      }
    }
    return result;
  }, [paymentMethods]);

  return (
    <Box data-testid="payment-processing-fees">
      <EscrowPaper
        sx={{
          gap: '32px',
        }}
      >
        <Box sx={{ display: 'flex', gap: '8px', marginBottom: '12px', alignItems: 'center' }}>
          <LocalOfferOutlinedIcon sx={{ color: 'secondaryLight.dark', height: 20, width: 20 }} />
          <MediumText>Payment processing fees</MediumText>
        </Box>
        <Box>
          <SmallText>
            Depending on the payment method you will use, there may be additional processing fees as
            outlined below.
          </SmallText>
        </Box>
        <Table>
          <TableBody sx={{ padding: '0' }}>
            {ach.available ? (
              <TableRow sx={{ padding: '0', borderBottom: 'none' }}>
                <EscrowLeftCell>
                  <SmallText>ACH</SmallText>
                </EscrowLeftCell>
                <EscrowRightCell>
                  <SmallText>FREE</SmallText>
                </EscrowRightCell>
              </TableRow>
            ) : null}
            {creditCard.available ? (
              <TableRow sx={{ padding: '0', borderBottom: 'none' }}>
                <EscrowLeftCell>
                  <SmallText>Credit card or Paypal</SmallText>
                </EscrowLeftCell>
                <EscrowRightCell>
                  {!waiveEscrowFee &&
                  !waiveIntermediaryFee &&
                  !waiveIntermediaryFeeV4API &&
                  creditCard.amount ? (
                    <SmallText>+ {formatCurrency(creditCard.amount, currency)}</SmallText>
                  ) : (
                    <SmallText>FREE</SmallText>
                  )}
                </EscrowRightCell>
              </TableRow>
            ) : null}
            {internationalWireTransfer.available ? (
              <TableRow sx={{ padding: '0', borderBottom: 'none' }}>
                <EscrowLeftCell>
                  <SmallText>International wire transfer</SmallText>
                </EscrowLeftCell>
                <EscrowRightCell>
                  {!waiveEscrowFee &&
                  !waiveIntermediaryFee &&
                  !waiveIntermediaryFeeV4API &&
                  internationalWireTransfer.amount ? (
                    <SmallText>
                      + {formatCurrency(internationalWireTransfer.amount, currency)}
                    </SmallText>
                  ) : (
                    <SmallText>FREE</SmallText>
                  )}
                </EscrowRightCell>
              </TableRow>
            ) : null}
            {domesticWireTransfer.available ? (
              <TableRow sx={{ padding: '0', borderBottom: 'none' }}>
                <EscrowLeftCell>
                  <SmallText>Domestic wire transfer</SmallText>
                </EscrowLeftCell>
                <EscrowRightCell>
                  <SmallText>FREE</SmallText>
                </EscrowRightCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </EscrowPaper>
    </Box>
  );
};

export default TransactionPaymentProcessingDetails;
