var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/icons/secure.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_2 = "";t_2 += "<svg version=\"1.1\" id=\"Layer_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n     viewbox=\"0 0 20.1 24.7\" enable-background=\"new 0 0 20.1 24.7\" xml:space=\"preserve\" class=\"icon icon--secure\">\n    <path d=\"M16.2,9.3V6.2C16.2,2.8,13.4,0,10,0C6.6,0,3.9,2.8,3.9,6.2v3.1H1.5C0.7,9.3,0,10,0,10.8v12.3c0,0.9,0.7,1.5,1.5,1.5h17\n    c0.9,0,1.5-0.7,1.5-1.5V10.8c0-0.9-0.7-1.5-1.5-1.5H16.2z M6.2,6.2C6.2,4,7.9,2.3,10,2.3c2.1,0,3.9,1.7,3.9,3.9v3.1H6.2V6.2z\n     M17.8,22.4H2.3V11.6h15.4V22.4z\"/>\n</svg>";
;
frame = frame.pop();
return new runtime.SafeString(t_2);
});
context.addExport("secure");
context.setVariable("secure", macro_t_1);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/icons/secure.html"] , dependencies)