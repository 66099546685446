import React, { Component } from 'react';
import queryString from 'query-string';
import { gettext } from '../../../utils/filters';

class PaymentVerificationSuccess extends Component {
  constructor() {
    super();

    this.state = {
      transId: queryString.parse(window.location)['trans-id'],
    };
  }
  componentDidMount() {
    const { transId } = this.state;
    let url = `${window.config.www_base_url}/transaction/${transId}`;
    if (!transId) {
      url = `${window.config.www_base_url}/transactions`;
      window.location.href = url;
    }
    setTimeout(() => {
      window.location.href = url;
    }, 5000);
  }

  render() {
    const { transId } = this.state;
    const url = transId
      ? `${window.config.www_base_url}/transaction/${transId}`
      : `${window.config.www_base_url}/transactions`;
    return (
      <section
        className="section section--small section--mid"
        data-e2e-target={`additional-documents-form-container`}
      >
        <div className="section-container">
          <div className="account-inner">
            <h1 className="account-section-title"> {gettext('Verification Successful!')} </h1>
            <br />
            <p>{gettext('Redirecting back to your transaction...')}</p>
            <p>
              {gettext('Click ')}
              <a href={url}>here</a> {gettext('to redirect now.')}
            </p>
            <p>
              {' '}
              {gettext('Having a problem? Please contact ')}
              <a href="/contact-us">support</a> {gettext("and tell us what's wrong.")}{' '}
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default PaymentVerificationSuccess;
