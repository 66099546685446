export default {
  LOGIN: 'LOGIN',
  SIGNUP: 'SIGNUP',
  VERIFY: 'VERIFY',
  LOGOUT: 'LOGOUT',
  EMAIL_EXISTS: 'EMAIL_EXISTS',
  UNVERIFY: 'UNVERIFY',
  SET_AUTH_URL: 'SET_AUTH_URL',
  SIGNUP_SHADOW_SUCCESS: 'SIGNUP_SHADOW_SUCCESS',
  SIGNUP_SHADOW_FAILURE: 'SIGNUP_SHADOW_FAILURE',
  REGISTER_SOURCES: {
    ADWORDS_LANDING: 1,
    START_INITIATOR: 2,
    START_NON_INITIATOR: 3,
    INTEGRATIONS: 4,
  },
};
