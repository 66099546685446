import React from 'react';
import Icon from 'spa/components/Icon';

const ConfirmationHeader = ({ showCheck = false, headingText, subheadingText = '', children }) => (
  <div className="checkout-confirmation-header">
    {showCheck && (
      <div className="checkout-confirmation-header-iconHolder">
        <Icon className="checkout-confirmation-header-icon" name="ui-circle-tick" />
      </div>
    )}
    <h2 className="checkout-confirmation-header-heading">{headingText}</h2>
    {subheadingText && <p className="checkout-confirmation-header-subheading">{subheadingText}</p>}
    {children}
  </div>
);

export default ConfirmationHeader;
