var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["icons/chevron-down.html"] = require( "icons/chevron-down.html" );
dependencies["icons/ui-search.html"] = require( "icons/ui-search.html" );
dependencies["icons/ui-close.html"] = require( "icons/ui-close.html" );
dependencies["components/escrowLogo-v2.html"] = require( "components/escrowLogo-v2.html" );
dependencies["components/escrowLogo.html"] = require( "components/escrowLogo.html" );
dependencies["navigation/v2/startTransactionUpsell.html"] = require( "navigation/v2/startTransactionUpsell.html" );
dependencies["navigation/v2/expandableSearch.html"] = require( "navigation/v2/expandableSearch.html" );




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/navigation/v2/headerLoggedOut.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("icons/chevron-down.html", false, "escrow_www/templates/navigation/v2/headerLoggedOut.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(context.getVariables(), frame, function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(t_1.hasOwnProperty("chevronDown")) {
var t_4 = t_1.chevronDown;
} else {
cb(new Error("cannot import 'chevronDown'")); return;
}
context.setVariable("arrowDownIcon", t_4);
env.getTemplate("icons/ui-search.html", false, "escrow_www/templates/navigation/v2/headerLoggedOut.html", false, function(t_6,t_5) {
if(t_6) { cb(t_6); return; }
t_5.getExported(context.getVariables(), frame, function(t_7,t_5) {
if(t_7) { cb(t_7); return; }
if(t_5.hasOwnProperty("search")) {
var t_8 = t_5.search;
} else {
cb(new Error("cannot import 'search'")); return;
}
context.setVariable("searchIcon", t_8);
env.getTemplate("icons/ui-close.html", false, "escrow_www/templates/navigation/v2/headerLoggedOut.html", false, function(t_10,t_9) {
if(t_10) { cb(t_10); return; }
t_9.getExported(context.getVariables(), frame, function(t_11,t_9) {
if(t_11) { cb(t_11); return; }
if(t_9.hasOwnProperty("close")) {
var t_12 = t_9.close;
} else {
cb(new Error("cannot import 'close'")); return;
}
context.setVariable("closeIcon", t_12);
if(runtime.contextOrFrameLookup(context, frame, "header_type") == "integrations_landing" || runtime.contextOrFrameLookup(context, frame, "header_type") == "integrations_portal") {
output += "\n  ";
var t_13;
t_13 = (lineno = 5, colno = 27, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".integrations_login"]));
frame.set("login_link", t_13, true);
if(frame.topLevel) {
context.setVariable("login_link", t_13);
}
if(frame.topLevel) {
context.addExport("login_link", t_13);
}
output += "\n  ";
var t_14;
t_14 = (lineno = 6, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".integrations_signup"]));
frame.set("signup_link", t_14, true);
if(frame.topLevel) {
context.setVariable("signup_link", t_14);
}
if(frame.topLevel) {
context.addExport("signup_link", t_14);
}
output += "\n";
;
}
else {
output += "\n  ";
var t_15;
t_15 = (lineno = 8, colno = 27, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".login_page"]));
frame.set("login_link", t_15, true);
if(frame.topLevel) {
context.setVariable("login_link", t_15);
}
if(frame.topLevel) {
context.addExport("login_link", t_15);
}
output += "\n  ";
var t_16;
t_16 = (lineno = 9, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".signup_page"]));
frame.set("signup_link", t_16, true);
if(frame.topLevel) {
context.setVariable("signup_link", t_16);
}
if(frame.topLevel) {
context.addExport("signup_link", t_16);
}
output += "\n";
;
}
output += "\n\n<header class=\"headerV2 ";
if(runtime.contextOrFrameLookup(context, frame, "scrollTop")) {
output += "is-header-scrollTop";
;
}
output += "\" data-sticky-header data-header-logged-out data-header>\n  <div class=\"headerV2-primary\">\n    <div class=\"headerV2-container section-container\">\n      <div class=\"headerV2-inner\">\n        <button class=\"headerV2-menuBtn media--available@tablet\" aria-label=\"";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_17 = "";t_17 += "Open Menu";
cb(null, t_17);
;
return t_17;
}
), true && env.opts.autoescape);
output += "\"data-header-menu-toggle>\n          ";
output += runtime.suppressValue((lineno = 17, colno = 24, runtime.callWrap(t_4, "arrowDownIcon", context, [runtime.makeKeywordArgs({"specifier": "headerV2-menuBtn-icon"})])), env.opts.autoescape);
output += "\n        </button>\n        <a href=\"";
output += runtime.suppressValue((lineno = 19, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".index"])), env.opts.autoescape);
output += "\" class=\"headerV2-logo\" title=\"";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_18 = "";t_18 += "Go to home page";
cb(null, t_18);
;
return t_18;
}
), true && env.opts.autoescape);
output += "\">\n          <span class=\"media--available@tablet\">\n            ";
env.getTemplate("components/escrowLogo-v2.html", false, "escrow_www/templates/navigation/v2/headerLoggedOut.html", null, function(t_21,t_19) {
if(t_21) { cb(t_21); return; }
t_19.render(context.getVariables(), frame, function(t_22,t_20) {
if(t_22) { cb(t_22); return; }
output += t_20
output += "\n          </span>\n          <span class=\"media--hidden@tablet\">\n            ";
env.getTemplate("components/escrowLogo.html", false, "escrow_www/templates/navigation/v2/headerLoggedOut.html", null, function(t_25,t_23) {
if(t_25) { cb(t_25); return; }
t_23.render(context.getVariables(), frame, function(t_26,t_24) {
if(t_26) { cb(t_26); return; }
output += t_24
output += "\n          </span>\n        </a>\n        <nav class=\"headerV2-nav\">\n          <ul class=\"headerV2-primaryNav\">\n            <li class=\"headerV2-primaryNav-item headerV2-primaryNav-upsell media--available@tablet\">\n                <div class=\"headerV2-upsell media--hidden@mobile\">\n                  <div class=\"headerV2-upsell-content\">\n                    <h3 class=\"headerV2-upsell-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_27 = "";t_27 += "Start A Transaction With Escrow";
cb(null, t_27);
;
return t_27;
}
), true && env.opts.autoescape);
output += "</h3>\n                  </div>\n                  <div class=\"headerV2-upsell-action\">\n                    <a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "signup_link"), env.opts.autoescape);
output += "?loginLocation=transaction-choice\" class=\"btn btn--secondary\" data-e2e-target=\"header-nav-start-transaction\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_28 = "";t_28 += "Get Started Now";
cb(null, t_28);
;
return t_28;
}
), true && env.opts.autoescape);
output += "</a>\n                  </div>\n                </div>\n                <a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "signup_link"), env.opts.autoescape);
output += "?loginLocation=transaction-choice\" class=\"btn btn--secondary btn--block media--available@mobile\" data-e2e-target=\"header-nav-start-transaction\">\n                    ";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_29 = "";t_29 += "Start A Transaction";
cb(null, t_29);
;
return t_29;
}
), true && env.opts.autoescape);
output += "\n                </a>\n            </li>\n            <li role=\"tab\" tabindex=\"0\" class=\"headerV2-primaryNav-item\" aria-controls=\"header-consumer-tab\" id=\"header-consumer\"  data-header-nav-item>\n              <span class=\"headerV2-primaryNav-title\">\n                <span class=\"headerV2-primaryNav-text\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_30 = "";t_30 += "Consumer";
cb(null, t_30);
;
return t_30;
}
), true && env.opts.autoescape);
output += "</span>\n                ";
output += runtime.suppressValue((lineno = 45, colno = 30, runtime.callWrap(t_4, "arrowDownIcon", context, [runtime.makeKeywordArgs({"specifier": "headerV2-primaryNav-icon"})])), env.opts.autoescape);
output += "\n              </span>\n              <div class=\"headerV2-subnav\" role=\"tabpanel\" aria-labelledby=\"header-consumer\" id=\"header-consumer-tab\">\n                <div class=\"headerV2-subnav-container\">\n                  <ul class=\"headerV2-subnav-list\">\n                    <li class=\"headerV2-subnav-item\">\n                      <a href=\"";
output += runtime.suppressValue((lineno = 51, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".what-is-escrow"])), env.opts.autoescape);
output += "\" class=\"headerV2-subnav-link\" data-e2e-target=\"header-nav-consumer-get-started\">\n                        <h3 class=\"headerV2-subnav-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_31 = "";t_31 += "Learn More";
cb(null, t_31);
;
return t_31;
}
), true && env.opts.autoescape);
output += "</h3>\n                        <p class=\"headerV2-subnav-desc\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_32 = "";t_32 += "How to use Escrow.com";
cb(null, t_32);
;
return t_32;
}
), true && env.opts.autoescape);
output += "</p>\n                      </a>\n                    </li>\n                    <li class=\"headerV2-subnav-item\">\n                      <a href=\"";
output += runtime.suppressValue((lineno = 57, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".why-escrowcom/benefits"])), env.opts.autoescape);
output += "\" class=\"headerV2-subnav-link\" data-e2e-target=\"header-nav-consumer-benefits\">\n                        <h3 class=\"headerV2-subnav-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_33 = "";t_33 += "Benefits";
cb(null, t_33);
;
return t_33;
}
), true && env.opts.autoescape);
output += "</h3>\n                        <p class=\"headerV2-subnav-desc\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_34 = "";t_34 += "See the advantages of using Escrow";
cb(null, t_34);
;
return t_34;
}
), true && env.opts.autoescape);
output += "</p>\n                      </a>\n                    </li>\n                    <li class=\"headerV2-subnav-item\">\n                      <a href=\"";
output += runtime.suppressValue((lineno = 63, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".fee-calculator"])), env.opts.autoescape);
output += "\" class=\"headerV2-subnav-link\" data-e2e-target=\"header-nav-consumer-fees\">\n                        <h3 class=\"headerV2-subnav-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_35 = "";t_35 += "Fees";
cb(null, t_35);
;
return t_35;
}
), true && env.opts.autoescape);
output += "</h3>\n                        <p class=\"headerV2-subnav-desc\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_36 = "";t_36 += "Low transparent fees";
cb(null, t_36);
;
return t_36;
}
), true && env.opts.autoescape);
output += "</p>\n                      </a>\n                    </li>\n                  </ul>\n                  ";
env.getTemplate("navigation/v2/startTransactionUpsell.html", false, "escrow_www/templates/navigation/v2/headerLoggedOut.html", null, function(t_39,t_37) {
if(t_39) { cb(t_39); return; }
t_37.render(context.getVariables(), frame, function(t_40,t_38) {
if(t_40) { cb(t_40); return; }
output += t_38
output += "\n                </div>\n              </div>\n            </li>\n            <li role=\"tab\" tabindex=\"0\" class=\"headerV2-primaryNav-item\" aria-controls=\"header-broker-tab\" id=\"header-broker\"  data-header-nav-item>\n              <span class=\"headerV2-primaryNav-title\">\n                <span class=\"headerV2-primaryNav-text\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_41 = "";t_41 += "Broker";
cb(null, t_41);
;
return t_41;
}
), true && env.opts.autoescape);
output += "</span>\n                ";
output += runtime.suppressValue((lineno = 76, colno = 30, runtime.callWrap(t_4, "arrowDownIcon", context, [runtime.makeKeywordArgs({"specifier": "headerV2-primaryNav-icon"})])), env.opts.autoescape);
output += "\n              </span>\n              <div class=\"headerV2-subnav\" role=\"tabpanel\" aria-labelledby=\"header-broker\" id=\"header-broker-tab\">\n                <div class=\"headerV2-subnav-container\">\n                  <ul class=\"headerV2-subnav-list\">\n                    <li class=\"headerV2-subnav-item\">\n                      <a href=\"";
output += runtime.suppressValue((lineno = 82, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".what-is-escrow/how-it-works-broker"])), env.opts.autoescape);
output += "\" class=\"headerV2-subnav-link\" data-e2e-target=\"header-nav-broker-get-started\">\n                        <h3 class=\"headerV2-subnav-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_42 = "";t_42 += "Learn More";
cb(null, t_42);
;
return t_42;
}
), true && env.opts.autoescape);
output += "</h3>\n                        <p class=\"headerV2-subnav-desc\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_43 = "";t_43 += "Learn how broker Escrow works";
cb(null, t_43);
;
return t_43;
}
), true && env.opts.autoescape);
output += "</p>\n                      </a>\n                    </li>\n                    <li class=\"headerV2-subnav-item\">\n                      <a href=\"";
output += runtime.suppressValue((lineno = 88, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".broker"])), env.opts.autoescape);
output += "\" class=\"headerV2-subnav-link\" data-e2e-target=\"header-nav-broker-become-a-broker\">\n                        <h3 class=\"headerV2-subnav-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_44 = "";t_44 += "Become a Broker";
cb(null, t_44);
;
return t_44;
}
), true && env.opts.autoescape);
output += "</h3>\n                        <p class=\"headerV2-subnav-desc\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_45 = "";t_45 += "Act as a trusted third party";
cb(null, t_45);
;
return t_45;
}
), true && env.opts.autoescape);
output += "</p>\n                      </a>\n                    </li>\n                    <li class=\"headerV2-subnav-item\">\n                      <a href=\"";
output += runtime.suppressValue((lineno = 94, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".fee-calculator"])), env.opts.autoescape);
output += "\" class=\"headerV2-subnav-link\" data-e2e-target=\"header-nav-broker-fees\">\n                        <h3 class=\"headerV2-subnav-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_46 = "";t_46 += "Fees";
cb(null, t_46);
;
return t_46;
}
), true && env.opts.autoescape);
output += "</h3>\n                        <p class=\"headerV2-subnav-desc\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_47 = "";t_47 += "Low transparent fees";
cb(null, t_47);
;
return t_47;
}
), true && env.opts.autoescape);
output += "</p>\n                      </a>\n                    </li>\n                  </ul>\n                  ";
env.getTemplate("navigation/v2/startTransactionUpsell.html", false, "escrow_www/templates/navigation/v2/headerLoggedOut.html", null, function(t_50,t_48) {
if(t_50) { cb(t_50); return; }
t_48.render(context.getVariables(), frame, function(t_51,t_49) {
if(t_51) { cb(t_51); return; }
output += t_49
output += "\n                </div>\n              </div>\n            </li>\n            <li role=\"tab\" tabindex=\"0\" class=\"headerV2-primaryNav-item\" aria-controls=\"header-business-tab\" id=\"header-business\"  data-header-nav-item>\n              <span class=\"headerV2-primaryNav-title\">\n                <span class=\"headerV2-primaryNav-text\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_52 = "";t_52 += "Business";
cb(null, t_52);
;
return t_52;
}
), true && env.opts.autoescape);
output += "</span>\n                ";
output += runtime.suppressValue((lineno = 107, colno = 30, runtime.callWrap(t_4, "arrowDownIcon", context, [runtime.makeKeywordArgs({"specifier": "headerV2-primaryNav-icon"})])), env.opts.autoescape);
output += "\n              </span>\n              <div class=\"headerV2-subnav\" role=\"tabpanel\" aria-labelledby=\"header-business\" id=\"header-business-tab\">\n                <div class=\"headerV2-subnav-container\">\n                  <ul class=\"headerV2-subnav-list\">\n                    <li class=\"headerV2-subnav-item\">\n                      <a href=\"";
output += runtime.suppressValue((lineno = 113, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".what-is-escrow"])), env.opts.autoescape);
output += "\" class=\"headerV2-subnav-link\" data-e2e-target=\"header-nav-businesss-get-started\">\n                        <h3 class=\"headerV2-subnav-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_53 = "";t_53 += "Learn More";
cb(null, t_53);
;
return t_53;
}
), true && env.opts.autoescape);
output += "</h3>\n                        <p class=\"headerV2-subnav-desc\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_54 = "";t_54 += "How Escrow works for your business";
cb(null, t_54);
;
return t_54;
}
), true && env.opts.autoescape);
output += "</p>\n                      </a>\n                    </li>\n                    <li class=\"headerV2-subnav-item\">\n                      <a href=\"";
output += runtime.suppressValue((lineno = 119, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".partners"])), env.opts.autoescape);
output += "\" class=\"headerV2-subnav-link\" data-e2e-target=\"header-nav-businesss-become-a-partner\">\n                        <h3 class=\"headerV2-subnav-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_55 = "";t_55 += "Become a Partner";
cb(null, t_55);
;
return t_55;
}
), true && env.opts.autoescape);
output += "</h3>\n                        <p class=\"headerV2-subnav-desc\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_56 = "";t_56 += "Grow your business and revenue";
cb(null, t_56);
;
return t_56;
}
), true && env.opts.autoescape);
output += "</p>\n                      </a>\n                    </li>\n                    <li class=\"headerV2-subnav-item\">\n                      <a href=\"";
output += runtime.suppressValue((lineno = 125, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".why-escrowcom/benefits"])), env.opts.autoescape);
output += "\" class=\"headerV2-subnav-link\" data-e2e-target=\"header-nav-businesss-benefits\">\n                        <h3 class=\"headerV2-subnav-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_57 = "";t_57 += "Benefits";
cb(null, t_57);
;
return t_57;
}
), true && env.opts.autoescape);
output += "</h3>\n                        <p class=\"headerV2-subnav-desc\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_58 = "";t_58 += "See the advantages of using Escrow";
cb(null, t_58);
;
return t_58;
}
), true && env.opts.autoescape);
output += "</p>\n                      </a>\n                    </li>\n                    <li class=\"headerV2-subnav-item\">\n                      <a href=\"";
output += runtime.suppressValue((lineno = 131, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".fee-calculator"])), env.opts.autoescape);
output += "\" class=\"headerV2-subnav-link\" data-e2e-target=\"header-nav-help-fees\">\n                        <h3 class=\"headerV2-subnav-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_59 = "";t_59 += "Fees";
cb(null, t_59);
;
return t_59;
}
), true && env.opts.autoescape);
output += "</h3>\n                        <p class=\"headerV2-subnav-desc\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_60 = "";t_60 += "Low transparent fees";
cb(null, t_60);
;
return t_60;
}
), true && env.opts.autoescape);
output += "</p>\n                      </a>\n                    </li>\n                    <li class=\"headerV2-subnav-item\">\n                      <a href=\"";
output += runtime.suppressValue((lineno = 137, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".pay"])), env.opts.autoescape);
output += "\" class=\"headerV2-subnav-link\" data-e2e-target=\"header-nav-businesss-escrow-pay\">\n                        <h3 class=\"headerV2-subnav-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_61 = "";t_61 += "Escrow Pay";
cb(null, t_61);
;
return t_61;
}
), true && env.opts.autoescape);
output += "</h3>\n                        <p class=\"headerV2-subnav-desc\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_62 = "";t_62 += "Simplest way to add escrow payments";
cb(null, t_62);
;
return t_62;
}
), true && env.opts.autoescape);
output += "</p>\n                      </a>\n                    </li>\n                    <li class=\"headerV2-subnav-item\">\n                      <a href=\"";
output += runtime.suppressValue((lineno = 143, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".offer"])), env.opts.autoescape);
output += "\" class=\"headerV2-subnav-link\" data-e2e-target=\"header-nav-businesss-escrow-offer\">\n                        <h3 class=\"headerV2-subnav-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_63 = "";t_63 += "Escrow Offer";
cb(null, t_63);
;
return t_63;
}
), true && env.opts.autoescape);
output += "</h3>\n                        <p class=\"headerV2-subnav-desc\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_64 = "";t_64 += "Allow negotiation for your transactions";
cb(null, t_64);
;
return t_64;
}
), true && env.opts.autoescape);
output += "</p>\n                      </a>\n                    </li>\n                    <li class=\"headerV2-subnav-item\">\n                      <a href=\"";
output += runtime.suppressValue((lineno = 149, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".button_creation"])), env.opts.autoescape);
output += "\" class=\"headerV2-subnav-link\" data-e2e-target=\"header-nav-businesss-escrow-buttons\">\n                        <h3 class=\"headerV2-subnav-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_65 = "";t_65 += "Escrow Buttons";
cb(null, t_65);
;
return t_65;
}
), true && env.opts.autoescape);
output += "</h3>\n                        <p class=\"headerV2-subnav-desc\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_66 = "";t_66 += "Create a button for Escrow transaction";
cb(null, t_66);
;
return t_66;
}
), true && env.opts.autoescape);
output += "</p>\n                      </a>\n                    </li>\n                    <li class=\"headerV2-subnav-item\">\n                      <a href=\"";
output += runtime.suppressValue((lineno = 155, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".integrations"])), env.opts.autoescape);
output += "\" class=\"headerV2-subnav-link\" data-e2e-target=\"header-nav-businesss-escrow-api\">\n                        <h3 class=\"headerV2-subnav-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_67 = "";t_67 += "Escrow API";
cb(null, t_67);
;
return t_67;
}
), true && env.opts.autoescape);
output += "</h3>\n                        <p class=\"headerV2-subnav-desc\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_68 = "";t_68 += "Get all benefits of Escrow via API";
cb(null, t_68);
;
return t_68;
}
), true && env.opts.autoescape);
output += "</p>\n                      </a>\n                    </li>\n                  </ul>\n                  ";
env.getTemplate("navigation/v2/startTransactionUpsell.html", false, "escrow_www/templates/navigation/v2/headerLoggedOut.html", null, function(t_71,t_69) {
if(t_71) { cb(t_71); return; }
t_69.render(context.getVariables(), frame, function(t_72,t_70) {
if(t_72) { cb(t_72); return; }
output += t_70
output += "\n                </div>\n              </div>\n            </li>\n            <li role=\"tab\" tabindex=\"0\" class=\"headerV2-primaryNav-item\" aria-controls=\"header-developer-tab\" id=\"header-developer\" data-header-nav-item>\n              <span class=\"headerV2-primaryNav-title\">\n                <span class=\"headerV2-primaryNav-text\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_73 = "";t_73 += "Developer";
cb(null, t_73);
;
return t_73;
}
), true && env.opts.autoescape);
output += "</span>\n                ";
output += runtime.suppressValue((lineno = 168, colno = 30, runtime.callWrap(t_4, "arrowDownIcon", context, [runtime.makeKeywordArgs({"specifier": "headerV2-primaryNav-icon"})])), env.opts.autoescape);
output += "\n              </span>\n              <div class=\"headerV2-subnav\" role=\"tabpanel\" aria-labelledby=\"header-developer\" id=\"header-developer-tab\">\n                <div class=\"headerV2-subnav-container\">\n                  <ul class=\"headerV2-subnav-list\">\n                    <li class=\"headerV2-subnav-item\">\n                      <a href=\"";
output += runtime.suppressValue((lineno = 174, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".integrations"])), env.opts.autoescape);
output += "\" class=\"headerV2-subnav-link\" data-e2e-target=\"header-nav-developer-api-integration\">\n                        <h3 class=\"headerV2-subnav-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_74 = "";t_74 += "API Integration";
cb(null, t_74);
;
return t_74;
}
), true && env.opts.autoescape);
output += "</h3>\n                        <p class=\"headerV2-subnav-desc\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_75 = "";t_75 += "Learn about Escrow API";
cb(null, t_75);
;
return t_75;
}
), true && env.opts.autoescape);
output += "</p>\n                      </a>\n                    </li>\n                    <li class=\"headerV2-subnav-item\">\n                      <a href=\"";
output += runtime.suppressValue((lineno = 180, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".api_guide_basics"])), env.opts.autoescape);
output += "\" class=\"headerV2-subnav-link\" data-e2e-target=\"header-nav-developer-api-documentation\">\n                        <h3 class=\"headerV2-subnav-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_76 = "";t_76 += "API Documentation";
cb(null, t_76);
;
return t_76;
}
), true && env.opts.autoescape);
output += "</h3>\n                        <p class=\"headerV2-subnav-desc\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_77 = "";t_77 += "Built by developers for developers";
cb(null, t_77);
;
return t_77;
}
), true && env.opts.autoescape);
output += "</p>\n                      </a>\n                    </li>\n                    <li class=\"headerV2-subnav-item\">\n                      <a href=\"";
output += runtime.suppressValue((lineno = 186, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".pay"])), env.opts.autoescape);
output += "\" class=\"headerV2-subnav-link\" data-e2e-target=\"header-nav-developer-escrow-pay\">\n                        <h3 class=\"headerV2-subnav-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_78 = "";t_78 += "Escrow Pay";
cb(null, t_78);
;
return t_78;
}
), true && env.opts.autoescape);
output += "</h3>\n                        <p class=\"headerV2-subnav-desc\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_79 = "";t_79 += "Escrow payment with one API call";
cb(null, t_79);
;
return t_79;
}
), true && env.opts.autoescape);
output += "</p>\n                      </a>\n                    </li>\n                    <li class=\"headerV2-subnav-item\">\n                      <a href=\"";
output += runtime.suppressValue((lineno = 192, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".offer"])), env.opts.autoescape);
output += "\" class=\"headerV2-subnav-link\" data-e2e-target=\"header-nav-developer-escrow-offer\">\n                        <h3 class=\"headerV2-subnav-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_80 = "";t_80 += "Escrow Offer";
cb(null, t_80);
;
return t_80;
}
), true && env.opts.autoescape);
output += "</h3>\n                        <p class=\"headerV2-subnav-desc\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_81 = "";t_81 += "Receive offers via API";
cb(null, t_81);
;
return t_81;
}
), true && env.opts.autoescape);
output += "</p>\n                      </a>\n                    </li>\n                    <li class=\"headerV2-subnav-item\">\n                      <a href=\"";
output += runtime.suppressValue((lineno = 198, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".button_creation"])), env.opts.autoescape);
output += "\" class=\"headerV2-subnav-link\" data-e2e-target=\"header-nav-developer-escrow-buttons\">\n                        <h3 class=\"headerV2-subnav-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_82 = "";t_82 += "Escrow Buttons";
cb(null, t_82);
;
return t_82;
}
), true && env.opts.autoescape);
output += "</h3>\n                        <p class=\"headerV2-subnav-desc\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_83 = "";t_83 += "Add Escrow Buttons to your website";
cb(null, t_83);
;
return t_83;
}
), true && env.opts.autoescape);
output += "</p>\n                      </a>\n                    </li>\n                  </ul>\n                  ";
env.getTemplate("navigation/v2/startTransactionUpsell.html", false, "escrow_www/templates/navigation/v2/headerLoggedOut.html", null, function(t_86,t_84) {
if(t_86) { cb(t_86); return; }
t_84.render(context.getVariables(), frame, function(t_87,t_85) {
if(t_87) { cb(t_87); return; }
output += t_85
output += "\n                </div>\n              </div>\n            </li>\n            <li role=\"tab\" tabindex=\"0\" class=\"headerV2-primaryNav-item\" aria-controls=\"header-help-tab\" id=\"header-help\" data-header-nav-item>\n              <span class=\"headerV2-primaryNav-title\">\n                <span class=\"headerV2-primaryNav-text\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_88 = "";t_88 += "Help";
cb(null, t_88);
;
return t_88;
}
), true && env.opts.autoescape);
output += "</span>\n                ";
output += runtime.suppressValue((lineno = 211, colno = 30, runtime.callWrap(t_4, "arrowDownIcon", context, [runtime.makeKeywordArgs({"specifier": "headerV2-primaryNav-icon"})])), env.opts.autoescape);
output += "\n              </span>\n              <div class=\"headerV2-subnav\" role=\"tabpanel\" aria-labelledby=\"header-help\" id=\"header-help-tab\">\n                <div class=\"headerV2-subnav-container\">\n                  <ul class=\"headerV2-subnav-list\">\n                    <li class=\"headerV2-subnav-item\">\n                      <a href=\"";
output += runtime.suppressValue((lineno = 217, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".what-is-escrow"])), env.opts.autoescape);
output += "\" class=\"headerV2-subnav-link\" data-e2e-target=\"header-nav-help-what-is-escrow\">\n                        <h3 class=\"headerV2-subnav-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_89 = "";t_89 += "What is Escrow? ";
cb(null, t_89);
;
return t_89;
}
), true && env.opts.autoescape);
output += "</h3>\n                        <p class=\"headerV2-subnav-desc\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_90 = "";t_90 += "Learn how Escrow.com works";
cb(null, t_90);
;
return t_90;
}
), true && env.opts.autoescape);
output += "</p>\n                      </a>\n                    </li>\n                    <li class=\"headerV2-subnav-item\">\n                      <a href=\"";
output += runtime.suppressValue((lineno = 223, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".fee-calculator"])), env.opts.autoescape);
output += "\" class=\"headerV2-subnav-link\" data-e2e-target=\"header-nav-help-fees\">\n                        <h3 class=\"headerV2-subnav-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_91 = "";t_91 += "Fees";
cb(null, t_91);
;
return t_91;
}
), true && env.opts.autoescape);
output += "</h3>\n                        <p class=\"headerV2-subnav-desc\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_92 = "";t_92 += "Low transparent fees";
cb(null, t_92);
;
return t_92;
}
), true && env.opts.autoescape);
output += "</p>\n                      </a>\n                    </li>\n                    <li class=\"headerV2-subnav-item\">\n                      <a href=\"";
output += runtime.suppressValue((lineno = 229, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".contact-us"])), env.opts.autoescape);
output += "\" class=\"headerV2-subnav-link\" data-e2e-target=\"header-nav-help-contact-us\">\n                        <h3 class=\"headerV2-subnav-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_93 = "";t_93 += "Contact Us";
cb(null, t_93);
;
return t_93;
}
), true && env.opts.autoescape);
output += "</h3>\n                        <p class=\"headerV2-subnav-desc\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_94 = "";t_94 += "Get in touch with us";
cb(null, t_94);
;
return t_94;
}
), true && env.opts.autoescape);
output += "</p>\n                      </a>\n                    </li>\n                    <li class=\"headerV2-subnav-item\">\n                      <a href=\"tel:";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "support_phone_number"), env.opts.autoescape);
output += "\" class=\"headerV2-subnav-link\" data-e2e-target=\"header-nav-help-call-us\">\n                        <h3 class=\"headerV2-subnav-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_95 = "";t_95 += "Call Us";
cb(null, t_95);
;
return t_95;
}
), true && env.opts.autoescape);
output += "</h3>\n                        <p class=\"headerV2-subnav-desc\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "support_phone_label"), env.opts.autoescape);
output += "</p>\n                      </a>\n                    </li>\n                    <li class=\"headerV2-subnav-item\">\n                      <a href=\"";
output += runtime.suppressValue((lineno = 241, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".helpdesk"])), env.opts.autoescape);
output += "\" class=\"headerV2-subnav-link\" data-e2e-target=\"header-nav-help-help-desk\">\n                        <h3 class=\"headerV2-subnav-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_96 = "";t_96 += "Help Desk";
cb(null, t_96);
;
return t_96;
}
), true && env.opts.autoescape);
output += "</h3>\n                        <p class=\"headerV2-subnav-desc\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_97 = "";t_97 += "Find answers to FAQs";
cb(null, t_97);
;
return t_97;
}
), true && env.opts.autoescape);
output += "</p>\n                      </a>\n                    </li>\n                    <li class=\"headerV2-subnav-item\">\n                      <a href=\"";
output += runtime.suppressValue((lineno = 247, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".about-us"])), env.opts.autoescape);
output += "\" class=\"headerV2-subnav-link\" data-e2e-target=\"header-nav-help-about-us\">\n                        <h3 class=\"headerV2-subnav-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_98 = "";t_98 += "About Us";
cb(null, t_98);
;
return t_98;
}
), true && env.opts.autoescape);
output += "</h3>\n                        <p class=\"headerV2-subnav-desc\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_99 = "";t_99 += "Learn about our company";
cb(null, t_99);
;
return t_99;
}
), true && env.opts.autoescape);
output += "</p>\n                      </a>\n                    </li>\n                    <li class=\"headerV2-subnav-item media--available@mobile\">\n                      <a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "login_link"), env.opts.autoescape);
output += "\" class=\"headerV2-subnav-link\" data-e2e-target=\"header-nav-help-mobile-login\">\n                        <h3 class=\"headerV2-subnav-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_100 = "";t_100 += "Login";
cb(null, t_100);
;
return t_100;
}
), true && env.opts.autoescape);
output += "</h3>\n                      </a>\n                    </li>\n                    <li class=\"headerV2-subnav-item media--available@mobile\">\n                      <a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "signup_link"), env.opts.autoescape);
output += "\" class=\"headerV2-subnav-link\">\n                        <h3 class=\"headerV2-subnav-title\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_101 = "";t_101 += "Signup";
cb(null, t_101);
;
return t_101;
}
), true && env.opts.autoescape);
output += " &#8594;</h3>\n                      </a>\n                    </li>\n                  </ul>\n                  ";
env.getTemplate("navigation/v2/startTransactionUpsell.html", false, "escrow_www/templates/navigation/v2/headerLoggedOut.html", null, function(t_104,t_102) {
if(t_104) { cb(t_104); return; }
t_102.render(context.getVariables(), frame, function(t_105,t_103) {
if(t_105) { cb(t_105); return; }
output += t_103
output += "\n                </div>\n              </div>\n            </li>\n          </ul>\n          ";
if(!runtime.contextOrFrameLookup(context, frame, "disable_navbar_search")) {
output += "\n          <div class=\"headerV2-search media--hidden@mobile\">\n            ";
env.getTemplate("navigation/v2/expandableSearch.html", false, "escrow_www/templates/navigation/v2/headerLoggedOut.html", null, function(t_108,t_106) {
if(t_108) { cb(t_108); return; }
t_106.render(context.getVariables(), frame, function(t_109,t_107) {
if(t_109) { cb(t_109); return; }
output += t_107
output += "\n          </div>\n          ";
})});
}
output += "\n          <ul class=\"headerV2-authNav\">\n            <li class=\"headerV2-authNav-item\">\n              <a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "login_link"), env.opts.autoescape);
output += "\" class=\"headerV2-authNav-link\" data-e2e-target=\"header-nav-login\">\n                <span class=\"headerV2-authNav-text\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_110 = "";t_110 += "Login";
cb(null, t_110);
;
return t_110;
}
), true && env.opts.autoescape);
output += "</span>\n              </a>\n            </li>\n            <li class=\"headerV2-authNav-item\">\n              <a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "signup_link"), env.opts.autoescape);
output += "\" class=\"headerV2-authNav-link\" data-e2e-target=\"header-nav-signup\">\n                <span class=\"headerV2-authNav-text\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_111 = "";t_111 += "Signup";
cb(null, t_111);
;
return t_111;
}
), true && env.opts.autoescape);
output += " &#8594;</span>\n              </a>\n            </li>\n          </ul>\n        </nav>\n      </div>\n    </div>\n  </div>\n</header>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/navigation/v2/headerLoggedOut.html"] , dependencies)