import React from 'react';
import TransactionTable from 'spa/components/TransactionTable/TransactionTable';
import { getTransactionSummary } from '../../../utils/V4Transaction';

const TransactionSummaryContainer = ({
  transaction,
  payBy,
  viewerPartyEmail,
  viewerPartyRole,
  hideTotal,
  showNoPrices,
  showTerms = true,
  outstandingBalance = null,
}) => {
  const transactionSummary = getTransactionSummary(transaction, viewerPartyEmail, viewerPartyRole, {
    payBy,
  });
  return (
    <TransactionTable
      transactionSummary={transactionSummary}
      viewerPartyRole={viewerPartyRole}
      config={{ hideTotal, showNoPrices, showTerms }}
      outstandingBalance={outstandingBalance}
    />
  );
};

export default TransactionSummaryContainer;
