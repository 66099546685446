import React from 'react';
import PropTypes from 'prop-types';

const SectionListItem = ({ children }) => (
  <li className="integrationsPortal-section-list-item">{children}</li>
);

SectionListItem.propTypes = {
  children: PropTypes.node,
};

export default SectionListItem;
