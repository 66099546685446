import { difference } from 'lodash';
import { CountryToAlpha2 } from './ISOCountryCodes';

const DisbursementConstants = {
  // form names
  INTERNATIONAL_WIRE_FORM_NAME: 'international-wire-form',
  ACH_FORM_NAME: 'ach-form',

  // redux action types
  DISBURSEMENT_FORM_SUBMIT: 'DISBURSEMENT_FORM_SUBMIT',
  GET_DISBURSEMENT_DETAILS_REQUEST: 'GET_DISBURSEMENT_DETAILS_REQUEST',
  GET_DISBURSEMENT_DETAILS_SUCCESS: 'GET_DISBURSEMENT_DETAILS_SUCCESS',
  GET_DISBURSEMENT_DETAILS_FAILURE: 'GET_DISBURSEMENT_DETAILS_FAILURE',
  GET_CUST_INFO: 'GET_CUST_INFO',

  // User flow types
  GET_USER_FLOW: 'GET_USER_FLOW',
  CUSTOMER_EDIT_FLOW: 'customer-edit',
  TRANSACTION_CREATE_FLOW: 'transaction-create',
};

DisbursementConstants.apiMapping = {
  [DisbursementConstants.ACH_FORM_NAME]: {
    abaRoutingNumber: 'bank_aba_routing_number',
    bankName: 'bank_name',
    accountName: 'account_name',
    accountNumber: 'bank_account_number',
    accountType: 'account_type',
  },
  [DisbursementConstants.INTERNATIONAL_WIRE_FORM_NAME]: {
    bankAbaRoutingNumber: 'bank_aba_routing_number',
    bankName: 'bank_name',
    accountName: 'account_name',
    accountNumber: 'bank_account_number',
    iban: 'bank_iban',
    clabeNumber: 'bank_account_number',
    bsb: 'bank_branch_number',
    sortCode: 'bank_sort_code',
    bankNumber: 'bank_branch_number',
    branchNumber: 'bank_branch_number',
    country: 'bank_address.country',
    swiftOrBicCode: 'bank_swift_code',
    additionalInformation: 'additional_information',
    intermediaryBankAbaRoutingNumber: 'intermediary_bank.bank_aba_routing_number',
    intermediaryBankSwiftCode: 'intermediary_bank.bank_swift_code',
    intermediaryBankBankName: 'intermediary_bank.bank_name',
    intermediaryBankCountry: 'intermediary_bank.bank_address.country',
    currency: 'currency',
    clearingSystemCode: 'clearing_system_code',
    memberId: 'member_id',
    financialInstitutionNumber: 'financial_institution_number',
    transitNumber: 'transit_number',
  },
};

DisbursementConstants.reverseApiMapping = {
  [DisbursementConstants.ACH_FORM_NAME]: {
    bank_aba_routing_number: 'abaRoutingNumber',
    bank_name: 'bankName',
    account_name: 'accountName',
    bank_account_number: 'accountNumber',
    account_type: 'accountType',
    bank_swift_code: '',
  },
  [DisbursementConstants.INTERNATIONAL_WIRE_FORM_NAME]: {
    bank_aba_routing_number: 'routingOrTransitCode',
    bank_name: 'bankName',
    account_name: 'accountName',
    'bank_address.country': 'country',
    bank_account_number: ['clabeNumber', 'accountNumber'],
    bank_iban: 'iban',
    additional_information: 'additionalInformation',
    'intermediary_bank.bank_aba_routing_number': 'intermediaryBank.abaRoutingNumberOrSwiftCode',
    'intermediary_bank.bank_swift_code': 'intermediaryBank.abaRoutingNumberOrSwiftCode',
    currency: 'currency',
  },
};

// field specific countries
DisbursementConstants.fieldCountries = {
  IBAN: [
    CountryToAlpha2.Andorra,
    CountryToAlpha2.Austria,
    CountryToAlpha2.Bahrain,
    CountryToAlpha2.Belgium,
    CountryToAlpha2['Bosnia and Herzegovina'],
    CountryToAlpha2.Bulgaria,
    CountryToAlpha2.Croatia,
    CountryToAlpha2.Cyprus,
    CountryToAlpha2.Czechia,
    CountryToAlpha2.Denmark,
    CountryToAlpha2.Estonia,
    CountryToAlpha2['Faroe Islands'],
    CountryToAlpha2.Finland,
    CountryToAlpha2.France,
    CountryToAlpha2.Georgia,
    CountryToAlpha2.Germany,
    CountryToAlpha2.Gibraltar,
    CountryToAlpha2.Greece,
    CountryToAlpha2.Greenland,
    CountryToAlpha2.Guernsey,
    CountryToAlpha2.Hungary,
    CountryToAlpha2.Iceland,
    CountryToAlpha2.Ireland,
    CountryToAlpha2['Isle of Man'],
    CountryToAlpha2.Italy,
    CountryToAlpha2.Jersey,
    CountryToAlpha2.Jordan,
    CountryToAlpha2.Kazakhstan,
    CountryToAlpha2.Kuwait,
    CountryToAlpha2.Latvia,
    CountryToAlpha2.Lebanon,
    CountryToAlpha2.Liechtenstein,
    CountryToAlpha2.Lithuania,
    CountryToAlpha2.Luxembourg,
    CountryToAlpha2['Macedonia, Republic of'],
    CountryToAlpha2.Malta,
    CountryToAlpha2['Moldova, Republic of'],
    CountryToAlpha2.Monaco,
    CountryToAlpha2.Montenegro,
    CountryToAlpha2.Netherlands,
    CountryToAlpha2.Norway,
    CountryToAlpha2['Palestine, State of'],
    CountryToAlpha2.Poland,
    CountryToAlpha2.Portugal,
    CountryToAlpha2.Qatar,
    CountryToAlpha2.Romania,
    CountryToAlpha2['San Marino'],
    CountryToAlpha2['Saudi Arabia'],
    CountryToAlpha2.Slovakia,
    CountryToAlpha2.Slovenia,
    CountryToAlpha2.Spain,
    CountryToAlpha2.Sweden,
    CountryToAlpha2.Switzerland,
    CountryToAlpha2.Tunisia,
    CountryToAlpha2.Turkey,
    CountryToAlpha2['United Arab Emirates'],
    CountryToAlpha2['United Kingdom'],
  ],
  CLABE_NUMBER: [CountryToAlpha2.Mexico],
  BSB: [CountryToAlpha2.Australia],
  SORT_CODE: [],
  BRSTN_CODE: [CountryToAlpha2.Philippines],
  BANK_NUMBER: [CountryToAlpha2['New Zealand']],
  BRANCH_NUMBER: [CountryToAlpha2['New Zealand']],
  SUFFIX: [CountryToAlpha2['New Zealand']],
  IFSC_CODE: [CountryToAlpha2.India],
  FINANCIAL_INSTITUTION_NUMBER: [CountryToAlpha2.Canada],
  TRANSIT_NUMBER: [CountryToAlpha2.Canada],
  ABA_ROUTING: [CountryToAlpha2['United States']],
};

// clearing system code and member id mapping (iso20022)
DisbursementConstants.clearingSystemCode = {
  [CountryToAlpha2.Australia]: 'AUBSB',
  [CountryToAlpha2.Austria]: 'ATBLZ',
  [CountryToAlpha2.Canada]: 'CACPA',
  [CountryToAlpha2.Germany]: 'DEBLZ',
  [CountryToAlpha2.India]: 'INFSC',
  [CountryToAlpha2.Italy]: 'ITNCC',
  [CountryToAlpha2['New Zealand']]: 'NZNCC',
  [CountryToAlpha2.Spain]: 'ESNCC',
  [CountryToAlpha2.Switzerland]: 'CHBCC',
  [CountryToAlpha2['United Kingdom']]: 'GBDSC',
  [CountryToAlpha2['United States']]: 'USABA',
};

DisbursementConstants.memberIdConstructor = {
  [CountryToAlpha2.Australia]: (formData) => formData.bsb || '',
  [CountryToAlpha2.Canada]: (formData) =>
    `0${formData.financialInstitutionNumber || ''}${formData.transitNumber || ''}`,
  [CountryToAlpha2.India]: (formData) => formData.ifscCode || '',
  [CountryToAlpha2['New Zealand']]: (formData) =>
    `${formData.bankNumber || ''}${formData.branchNumber || ''}`,
  [CountryToAlpha2['United States']]: (formData) => formData.bankAbaRoutingNumber || '',
};

DisbursementConstants.ibanToParsedFields = {
  [CountryToAlpha2.Austria]: (iban) => {
    const ibanNoWhitespace = iban.replace(/\s+/g, '');
    return {
      memberId: ibanNoWhitespace.substring(4, 9),
      accountNumber: ibanNoWhitespace.substring(9, 20),
    };
  },
  [CountryToAlpha2.Germany]: (iban) => {
    const ibanNoWhitespace = iban.replace(/\s+/g, '');
    return {
      memberId: ibanNoWhitespace.substring(4, 12),
      accountNumber: ibanNoWhitespace.substring(12, 22),
    };
  },
  [CountryToAlpha2.Italy]: (iban) => {
    const ibanNoWhitespace = iban.replace(/\s+/g, '');
    return {
      memberId: ibanNoWhitespace.substring(5, 15),
      accountNumber: ibanNoWhitespace.substring(15, 27),
    };
  },
  [CountryToAlpha2.Spain]: (iban) => {
    const ibanNoWhitespace = iban.replace(/\s+/g, '');
    return {
      memberId: ibanNoWhitespace.substring(4, 12),
      accountNumber: ibanNoWhitespace.substring(14, 24),
    };
  },
  [CountryToAlpha2.Switzerland]: (iban) => {
    const ibanNoWhitespace = iban.replace(/\s+/g, '');
    return {
      memberId: ibanNoWhitespace.substring(4, 9),
      accountNumber: ibanNoWhitespace.substring(9, 21),
    };
  },
  [CountryToAlpha2['United Kingdom']]: (iban) => {
    const ibanNoWhitespace = iban.replace(/\s+/g, '');
    return {
      bic: ibanNoWhitespace.substring(4, 8),
      memberId: ibanNoWhitespace.substring(8, 14),
      accountNumber: ibanNoWhitespace.substring(14, 22),
    };
  },
};

// Account number is shown when the country doesn't exist in both the
// IBAN or CLABE Number country list
DisbursementConstants.fieldCountries.ACCOUNT_NUMBER = difference(
  Object.values(CountryToAlpha2),
  [].concat(
    DisbursementConstants.fieldCountries.IBAN,
    DisbursementConstants.fieldCountries.CLABE_NUMBER
  )
);

DisbursementConstants.ibanRegexByCountry = {
  [CountryToAlpha2.Andorra]: new RegExp(/^[aA][dD][0-9]{10}[0-9a-zA-Z]{12}$/),
  [CountryToAlpha2.Austria]: new RegExp(/^[aA][tT][0-9]{18}$/),
  [CountryToAlpha2.Bahrain]: new RegExp(/^[bB][hH][0-9]{2}[a-zA-Z]{4}[0-9a-zA-Z]{14}$/),
  [CountryToAlpha2.Belgium]: new RegExp(/^[bB][eE][0-9]{14}$/),
  [CountryToAlpha2['Bosnia and Herzegovina']]: new RegExp(/^[bB][aA][0-9]{18}$/),
  [CountryToAlpha2.Bulgaria]: new RegExp(/^[bB][gG][0-9]{2}[a-zA-Z]{4}[0-9]{6}[0-9a-zA-Z]{8}$/),
  [CountryToAlpha2.Croatia]: new RegExp(/^[hH][rR][0-9]{7}[0-9a-zA-Z]{12}$/),
  [CountryToAlpha2.Cyprus]: new RegExp(/^[cC][yY][0-9]{10}[0-9a-zA-Z]{16}$/),
  [CountryToAlpha2.Czechia]: new RegExp(/^[cC][zZ][0-9]{22}$/),
  [CountryToAlpha2.Denmark]: new RegExp(/^[dD][kK][0-9]{16}$/),
  [CountryToAlpha2.Estonia]: new RegExp(/^[eE]{2}[0-9]{18}$/),
  [CountryToAlpha2['Faroe Islands']]: new RegExp(/^[fF][oO][0-9]{16}$/),
  [CountryToAlpha2.Finland]: new RegExp(/^[fF][iI][0-9]{16}$/),
  [CountryToAlpha2.France]: new RegExp(/^[fF][rR][0-9]{12}[0-9a-zA-Z]{11}[0-9]{2}$/),
  [CountryToAlpha2.Georgia]: new RegExp(/^[gG][eE][0-9]{2}[a-zA-Z]{2}[0-9]{16}$/),
  [CountryToAlpha2.Germany]: new RegExp(/^[dD][eE][0-9]{20}$/),
  [CountryToAlpha2.Gibraltar]: new RegExp(/^[gG][iI][0-9]{2}[a-zA-Z]{4}[0-9a-zA-Z]{15}$/),
  [CountryToAlpha2.Greece]: new RegExp(/^[gG][rR][0-9]{9}[0-9a-zA-Z]{16}$/),
  [CountryToAlpha2.Greenland]: new RegExp(/^[gG][lL][0-9]{16}$/),
  [CountryToAlpha2.Hungary]: new RegExp(/^[hH][uU][0-9]{26}$/),
  [CountryToAlpha2.Iceland]: new RegExp(/^[iI][sS][0-9]{24}$/),
  [CountryToAlpha2.Ireland]: new RegExp(/^[iI][eE][0-9]{2}[a-zA-Z]{4}[0-9]{14}$/),
  [CountryToAlpha2.Italy]: new RegExp(/^[iI][tT][0-9]{2}[a-zA-Z][0-9]{10}[0-9a-zA-Z]{12}$/),
  [CountryToAlpha2.Jordan]: new RegExp(/^[jJ][oO][0-9]{2}[a-zA-Z]{4}[0-9]{4}[0-9a-zA-Z]{18}$/),
  [CountryToAlpha2.Kazakhstan]: new RegExp(/^[kK][zZ][0-9]{5}[0-9a-zA-Z]{13}$/),
  [CountryToAlpha2.Kuwait]: new RegExp(/^[kK][wW][0-9]{2}[a-zA-Z]{4}[0-9a-zA-Z]{22}$/),
  [CountryToAlpha2.Latvia]: new RegExp(/^[lL][vV][0-9]{2}[a-zA-Z]{4}[0-9a-zA-Z]{13}$/),
  [CountryToAlpha2.Lebanon]: new RegExp(/^[lL][bB][0-9]{6}[0-9a-zA-Z]{20}$/),
  [CountryToAlpha2.Liechtenstein]: new RegExp(/^[lL][iI][0-9]{7}[0-9a-zA-Z]{12}$/),
  [CountryToAlpha2.Lithuania]: new RegExp(/^[lL][tT][0-9]{18}$/),
  [CountryToAlpha2.Luxembourg]: new RegExp(/^[lL][uU][0-9]{5}[0-9a-zA-Z]{13}$/),
  [CountryToAlpha2['Macedonia, Republic of']]: new RegExp(/^[mM][kK][0-9]{5}[0-9a-zA-Z]{12}$/),
  [CountryToAlpha2.Malta]: new RegExp(/^[mM][tT][0-9]{2}[a-zA-Z]{4}[0-9]{5}[0-9a-zA-Z]{18}$/),
  [CountryToAlpha2['Moldova, Republic of']]: new RegExp(
    /^[mM][dD][0-9]{2}[a-zA-Z]{2}[0-9a-zA-Z]{18}$/
  ),
  [CountryToAlpha2.Monaco]: new RegExp(/^[mM][cC][0-9]{12}[0-9a-zA-Z]{11}[0-9]{2}$/),
  [CountryToAlpha2.Montenegro]: new RegExp(/^[mM][eE][0-9]{20}$/),
  [CountryToAlpha2.Netherlands]: new RegExp(/^[nN][lL][0-9]{2}[a-zA-Z]{4}[0-9]{10}$/),
  [CountryToAlpha2.Norway]: new RegExp(/^[nN][oO][0-9]{13}$/),
  [CountryToAlpha2['Palestine, State of']]: new RegExp(
    /^[pP][sS][0-9]{2}[a-zA-Z]{4}[0-9a-zA-Z]{21}$/
  ),
  [CountryToAlpha2.Poland]: new RegExp(/^[pP][lL][0-9]{26}$/),
  [CountryToAlpha2.Qatar]: new RegExp(/^[qQ][aA][0-9]{2}[a-zA-Z]{4}[0-9a-zA-Z]{21}$/),
  [CountryToAlpha2.Portugal]: new RegExp(/^[pP][tT][0-9]{23}$/),
  [CountryToAlpha2.Romania]: new RegExp(/^[rR][oO][0-9]{2}[a-zA-Z]{4}[0-9a-zA-Z]{16}$/),
  [CountryToAlpha2['San Marino']]: new RegExp(
    /^[sS][mM][0-9]{2}[a-zA-Z][0-9]{9}[0-9a-zA-Z]{11}[0-9]{2}$/
  ),
  [CountryToAlpha2['Saudi Arabia']]: new RegExp(/^[sS][aA][0-9]{4}[0-9a-zA-Z]{18}$/),
  [CountryToAlpha2.Slovakia]: new RegExp(/^[sS][kK][0-9]{22}$/),
  [CountryToAlpha2.Slovenia]: new RegExp(/^[sS][iI][0-9]{17}$/),
  [CountryToAlpha2.Spain]: new RegExp(/^[eE][sS][0-9]{22}$/),
  [CountryToAlpha2.Sweden]: new RegExp(/^[sS][eE][0-9]{22}$/),
  [CountryToAlpha2.Switzerland]: new RegExp(/^[cC][hH][0-9]{7}[0-9a-zA-Z]{12}$/),
  [CountryToAlpha2.Tunisia]: new RegExp(/^[tT][nN][0-9]{22}$/),
  [CountryToAlpha2.Turkey]: new RegExp(/^[tT][rR][0-9]{8}[a-zA-Z0-9]{16}$/),
  [CountryToAlpha2['United Arab Emirates']]: new RegExp(/^[aA][eE][0-9]{21}$/),
  [CountryToAlpha2['United Kingdom']]: new RegExp(/^[gG][bB][0-9]{2}[a-zA-Z]{4}[0-9]{14}$/),
};

export default DisbursementConstants;
