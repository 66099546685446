import React, { Fragment } from 'react';
import moment from 'moment';

const TransactionEscrowTerms = ({ timelineNotes }) => (
  <Fragment>
    <h4 className="partnerDashboard-details-sectionTitle">Transaction History</h4>
    <div className="partnerDashboard-details-tableContainer">
      <table className="transactionTable">
        <tbody className="transactionTable-body">
          {timelineNotes.map(({ date_added: dateAdded, notes }) => (
            <tr key={`timeline-notes-${dateAdded}`} className="transactionTable-row">
              <td className="transactionTable-cell">
                {moment(dateAdded).format('MMMM DD YYYY HH:mm a')}
              </td>
              <td className="transactionTable-cell">{notes}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </Fragment>
);

export default TransactionEscrowTerms;
