import React from 'react';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg';
import PropTypes from 'prop-types';
import { Button } from 'escrow-common-js/dist/components';
import A from 'spa/components/A';
import { Spinner } from 'spa/components/Indicators';
import { twoFactorSelector, twoFactorLoadingSelector } from 'spa/selectors/TwoFactorSelectors';
import { getTwoFactorStatus } from 'spa/actions/TwoFactorActions';
import { gettext } from '../../../utils/filters';

class ConfigContainer extends React.Component {
  constructor(props) {
    super(props);
    this.renderLoading = this.renderLoading.bind(this);
    this.renderAuthAppSection = this.renderAuthAppSection.bind(this);
    this.renderSMSSection = this.renderSMSSection.bind(this);
  }

  componentDidMount() {
    this.props.getTwoFactorStatus();
  }

  renderLoading() {
    return (
      <div className="twoFactorAuth-card-placeholder">
        <Spinner />
      </div>
    );
  }

  renderAuthAppSection() {
    const { type2faStatuses, disableAuthAppStep, setupAuthAppStep } = this.props;

    return (
      <div className="twoFactorAuth-page-inner">
        <div className="twoFactorAuth-page-inner-content">
          <div className="twoFactorAuth-page-icon-frame twoFactorAuth-page-inner-item">
            <ReactSVG
              beforeInjection={(svg) => {
                svg.classList.add('twoFactorAuth-page-icon');
              }}
              src="../../../../build/images/global/icons/google-auth-icon.svg"
            />
          </div>
          <div className="twoFactorAuth-page-inner-item-text">
            <h4 className="twoFactorAuth-page-inner-header">Authenticator App</h4>
            <div className="twoFactorAuth-page-inner-desc">
              {gettext('Generate security codes via an authenticator app')}
            </div>
          </div>
          <div className="twoFactorAuth-page-inner-item-btn">
            <Button
              className="twoFactorAuth-page-inner-btn"
              color={type2faStatuses.authAppEnabled ? 'dangerHollow' : 'primaryHollow'}
              role="button"
              onClick={type2faStatuses.authAppEnabled ? disableAuthAppStep : setupAuthAppStep}
            >
              {type2faStatuses.authAppEnabled ? gettext('Disable') : gettext('Setup')}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  renderSMSSection() {
    const { type2faStatuses, disableSMSStep, setupSMSStep } = this.props;
    if (type2faStatuses.smsEnabled) {
      return (
        <div className="twoFactorAuth-page-inner">
          <div className="twoFactorAuth-page-inner-content">
            <div className="twoFactorAuth-page-icon-frame twoFactorAuth-page-inner-item">
              <ReactSVG
                beforeInjection={(svg) => {
                  svg.classList.add('twoFactorAuth-page-icon');
                }}
                src="../../../../build/images/global/icons/ui-sms.svg"
              />
            </div>
            <div className="twoFactorAuth-page-inner-item-text">
              <h4 className="twoFactorAuth-page-inner-header">SMS Authentication</h4>
              <div className="twoFactorAuth-page-inner-desc">
                {gettext('Use SMS to receive security codes')}
              </div>
            </div>
            <div className="twoFactorAuth-page-inner-item-btn">
              <Button
                className="twoFactorAuth-page-inner-btn"
                color={type2faStatuses.smsEnabled ? 'dangerHollow' : 'primaryHollow'}
                role="button"
                onClick={type2faStatuses.smsEnabled ? disableSMSStep : setupSMSStep}
              >
                {type2faStatuses.smsEnabled ? gettext('Disable') : gettext('Setup')}
              </Button>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    if (this.props.loading) {
      return this.renderLoading();
    }

    return (
      <div>
        <div className="twoFactorAuth-card-header">
          <h2 className="twoFactorAuth-card-header-title twoFactorAuth-card-header-title--auth">
            {gettext('Configure Two-Factor Authentication')}
          </h2>
        </div>
        <div className="twoFactorAuth-page">
          <div className="twoFactorAuth-page-subheader twoFactorAuth-page-subheader--auth">
            {gettext(`Two-factor authentication increases the security on your account. Whenever
              you sign in to your account, you'll need to enter your password and a security code.`)}
          </div>
          <div>
            {this.renderAuthAppSection()}
            {this.renderSMSSection()}
          </div>
          <div className="twoFactorAuth-page-footer">
            <A
              link={{ type: 'internal', route: '/account-info' }}
              className="twoFactorAuth-page-backBtn twoFactorAuth-page-backBtn--secondary"
            >
              {gettext('Back')}
            </A>
          </div>
        </div>
      </div>
    );
  }
}

ConfigContainer.propTypes = {
  type2faStatuses: PropTypes.object,
  disableAuthAppStep: PropTypes.func,
  setupAuthAppStep: PropTypes.func,
  disableSMSStep: PropTypes.func,
  setupSMSStep: PropTypes.func,
};

const mapStateToProps = (state) => ({
  type2faStatuses: twoFactorSelector(state),
  loading: twoFactorLoadingSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getTwoFactorStatus: () => dispatch(getTwoFactorStatus.trigger()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigContainer);
