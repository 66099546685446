import React, { Component } from 'react';
import QueryString from 'query-string';
import Breadcrumbs from 'spa/components/Breadcrumbs';
import PaymentVerificationSection from 'spa/containers/PaymentVerification/Verification';

import { gettext } from '../../../utils/filters';

const breadcrumbs = [
  {
    text: gettext('Your Account'),
    link: '/account-info',
  },
  {
    text: gettext('Verify Payment'),
  },
];

class PaymentVerificationPage extends Component {
  constructor() {
    super();
    this.state = {
      cardId: null,
      transId: null,
      cardVerificationId: null,
    };
  }
  componentDidMount() {
    this.getQueryString();
  }

  getQueryString() {
    const cardId = QueryString.parse(window.location.search)['card-id'];
    const transId = QueryString.parse(window.location.search)['trans-id'];
    const cardVerificationId = QueryString.parse(window.location.search)['card-verification-id'];
    this.setState({ cardId, transId, cardVerificationId });
  }

  render() {
    const { cardId, transId, cardVerificationId } = this.state;
    return (
      <section
        className="section section--small section--mid"
        data-e2e-target={`additional-documents-form-container`}
      >
        <div className="section-container">
          <div className="account-inner">
            <Breadcrumbs className="accountInfo-breadcrumbs" items={breadcrumbs} />
            {cardId && transId && cardVerificationId ? (
              <PaymentVerificationSection
                cardId={cardId}
                transId={transId}
                cardVerificationId={cardVerificationId}
              />
            ) : null}
          </div>
        </div>
      </section>
    );
  }
}

export default PaymentVerificationPage;
