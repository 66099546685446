import React from 'react';
import { Button } from 'escrow-common-js/dist/components';
import { reduxForm, formValues, Field } from 'redux-form';
import { bindRoutineToReduxForm } from 'redux-saga-routines';
import { Input, Checkbox, PhoneSelect } from 'spa/components/form';
import { phone, required } from 'spa/components/form/validate';
import { submitReceiveSms } from 'spa/actions/CheckoutActions';
import CheckoutConstants from '../../../constants/CheckoutConstants';

const phoneNumValidation = phone('primaryPhoneCountry');

const ReceiveSmsForm = (props) => {
  const { isReceiveSms, handleSubmit, form, submitSmsLoading, submitSmsSuccess, submittedSmsNum } =
    props;

  const normalizePhone = (value) => {
    const re = /^[0-9]*$/;

    if (re.test(value)) {
      return value;
    }
  };

  return (
    <form
      name={form}
      onSubmit={handleSubmit(bindRoutineToReduxForm(submitReceiveSms))}
      className="checkout-page-form--wide"
      data-tracking-subsection="receive-sms-form"
    >
      {submitSmsSuccess ? (
        <p>{`We will send your updates to ${submittedSmsNum}`}</p>
      ) : (
        <div>
          <div className="checkout-page-checkbox checkout-page-checkbox--compact">
            <Field
              name="isReceiveSms"
              component={Checkbox}
              label={'I want to receive transaction updates via SMS'}
              data-e2e-target="toggle-sms-notification"
            />
          </div>
          {isReceiveSms && (
            <div>
              <div className="field">
                <label className="field-label">
                  {'SMS NOTIFICATION'}
                  <span className="field-required">*</span>
                </label>
                <div className="defaultForm-group">
                  <Field
                    name="primaryPhoneCountry"
                    component={PhoneSelect}
                    className="field--phoneSelect"
                    validate={[required]}
                    placeholder={`Country Code`}
                    data-e2e-target="phone-country-select"
                  />
                  <Field
                    name="primaryPhoneNumber"
                    type="tel"
                    component={Input}
                    placeholder={'Phone Number'}
                    validate={[required, phoneNumValidation]}
                    normalize={normalizePhone}
                  />
                </div>
                <div className="checkout-page-form-actions">
                  <div className="checkout-page-form-action checkout-page-form-action--secondary" />
                  <Button
                    type="submit"
                    color="primaryHollow"
                    className="checkout-page-form-action checkout-page-form-action--small checkout-page-form-action--strong"
                    loading={submitSmsLoading}
                    data-e2e-target="phone-submit"
                  >
                    {'Submit'}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </form>
  );
};

export default reduxForm({
  form: CheckoutConstants.RECEIVE_SMS_FORM_NAME,
  enableReinitialize: true,
})(formValues('isReceiveSms')(ReceiveSmsForm));
