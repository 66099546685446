import React from 'react';

import { CustomFormControl } from 'spa/features/transaction/components/modify-transaction/CustomFormControl';

const MobilePageFormControl = ({ children, sx }) => (
  <CustomFormControl sx={{ marginBottom: '24px', marginTop: '0px', ...sx }}>
    {children}
  </CustomFormControl>
);

export default MobilePageFormControl;
