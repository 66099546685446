import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'escrow-common-js/dist/components';
import window from 'window-shim';
import { connect } from 'react-redux';
import { Field, change } from 'redux-form';

import { documents, RegionsByCountry } from 'escrow-common-js/dist/constants';
import { Input, Select, PhoneSelect, DateOfBirthField, Checkbox } from 'spa/components/form';
import { required, maxChars, phone, date, age18 } from 'spa/components/form/validate';
import { CountryToAlpha2, Alpha2ToAlpha3 } from 'spa/constants/ISOCountryCodes';
import AuthenticationStore from '../../../stores/AuthenticationStore';
import { gettext } from '../../../utils/filters';
import { createSelectOptions } from '../../../utils/select';
import AutocompleteAddressConstants from '../../../constants/AutocompleteAddressConstants';
import API from '../../../api';

class CustomerDetailsFieldset extends React.Component {
  constructor(props) {
    super(props);
    this.line1Ref = null;
    this.companyLine1Ref = null;
    this.autocomplete = null;
    this.autocompleteCompany = null;

    this.config = {
      embedded: props.embedded || false,
    };

    this.validators = {
      address_line_1: [maxChars(60, gettext('Address Line 1'))],
      address_line_2: [maxChars(60, gettext('Address Line 2'))],
      city: [maxChars(30, gettext('City'))],
      state: [maxChars(50, gettext('State'))],
      country: [maxChars(2, gettext('Country'))],
      zipcode: [maxChars(15, gettext('Post Code'))],
      first_name: [maxChars(50, gettext('First Name'))],
      last_name: [maxChars(50, gettext('Last Name'))],
      company_name: [maxChars(50, gettext('Company Name'))],
      date_of_birth: [date, age18],
      phone: phone('customerDetails', 'primary-phone-country'),
    };

    documents.map((doc) =>
      doc.fields.map((field) => {
        this.validators[`${doc.name}_${field.name}`] = (field.validators || []).map((validator) =>
          validator(doc)
        );
        if (doc.required && field.required) {
          this.validators[`${doc.name}_${field.name}`].push(required);
        }
      })
    );

    this.handleEnterKey = this.handleEnterKey.bind(this);
    this.normalizePhone = this.normalizePhone.bind(this);
    this.handleCountrySelect = this.handleCountrySelect.bind(this);
    this.handleCompanyCountrySelect = this.handleCompanyCountrySelect.bind(this);
  }

  componentDidMount() {
    if (AuthenticationStore.isAuthenticated()) {
      API.getCustomerNationalDocuments().then((nationalDocuments) => {
        (nationalDocuments || []).map((doc) =>
          this.props.dispatch(
            change(this.props.formName, `customerDetails.${doc.type}-${doc.field}`, doc.value)
          )
        );
      });
    }
  }

  componentDidUpdate() {
    if (this.line1Ref && !this.autocomplete && window.google) {
      const line1Input = this.line1Ref.getRenderedComponent().inputRef;
      this.autocomplete = new window.google.maps.places.Autocomplete(line1Input, {
        types: ['address'],
      });
      this.autocomplete.addListener('place_changed', this.fillAddress.bind(this, false));
      line1Input.addEventListener('keydown', this.handleEnterKey);
    }

    if (this.companyLine1Ref && !this.autocompleteCompany && window.google) {
      const companyLine1Input = this.companyLine1Ref.getRenderedComponent().inputRef;
      this.autocompleteCompany = new window.google.maps.places.Autocomplete(companyLine1Input, {
        types: ['address'],
      });
      this.autocompleteCompany.addListener('place_changed', this.fillAddress.bind(this, true));
      companyLine1Input.addEventListener('keydown', this.handleEnterKey);
    }

    if (!this.line1Ref && this.autocomplete) {
      window.google.maps.event.clearInstanceListeners(this.autocomplete);
      this.autocomplete = null;
    }

    if (!this.companyLine1Ref && this.autocompleteCompany) {
      window.google.maps.event.clearInstanceListeners(this.autocompleteCompany);
      this.autocompleteCompany = null;
    }
  }

  componentWillUnmount() {
    if (this.line1Ref) {
      const line1Input = this.line1Ref.getRenderedComponent().inputRef;
      line1Input.removeEventListener('keydown', this.handleEnterKey);
    }

    if (this.companyLine1Ref) {
      const companyLine1Input = this.companyLine1Ref.getRenderedComponent().inputRef;
      companyLine1Input.removeEventListener('keydown', this.handleEnterKey);
    }
  }

  getStateSelectOptions(country) {
    const states = RegionsByCountry[country];
    const result = [];

    if (states) {
      for (const state of states) {
        const unique = states.filter((s) => s.name === state.name).length === 1;
        const uniqueName = unique ? state.name : `${state.name} (${state.type})`;
        result.push({ value: uniqueName, label: uniqueName });
      }
    }
    return result;
  }

  handleEnterKey(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  handleCountrySelect(event, newValue) {
    this.props.dispatch(change(this.props.formName, `${this.props.formSectionName}.state`, ''));
    if (this.props['primary-phone-country'] !== Alpha2ToAlpha3[newValue]) {
      this.props.dispatch(
        change(this.props.formName, `${this.props.formSectionName}.primary-phone-number`, '')
      );
      this.props.dispatch(
        change(
          this.props.formName,
          `${this.props.formSectionName}.primary-phone-country`,
          Alpha2ToAlpha3[newValue]
        )
      );
    }
  }

  handleCompanyCountrySelect() {
    this.props.dispatch(
      change(this.props.formName, `${this.props.formSectionName}.company-state`, '')
    );
  }

  fillAddress(isCompany) {
    const place = isCompany ? this.autocompleteCompany.getPlace() : this.autocomplete.getPlace();
    if (!place || !place.address_components) {
      return null;
    }

    const address = place.address_components;

    const addressDict = {
      Street1: '',
      Street2: '',
      City: '',
      State: '',
      Zip: '',
      Country: '',
    };

    for (const component of address) {
      const mappedType = AutocompleteAddressConstants.GOOGLE_MAPS_FIELDS[component.types[0]];
      if (mappedType === 'Country') {
        addressDict[mappedType] = component.short_name;
      } else {
        addressDict[mappedType] += `${component.long_name} `;
      }
    }

    let addressStr = `${this.props.formSectionName}.`;
    if (isCompany) {
      addressStr += 'company-';
    }

    this.props.dispatch(
      change(this.props.formName, `${addressStr}address-line-1`, addressDict.Street1.trim())
    );
    this.props.dispatch(
      change(this.props.formName, `${addressStr}address-line-2`, addressDict.Street2.trim())
    );
    this.props.dispatch(change(this.props.formName, `${addressStr}city`, addressDict.City.trim()));
    this.props.dispatch(
      change(this.props.formName, `${addressStr}state`, addressDict.State.trim())
    );
    this.props.dispatch(
      change(this.props.formName, `${addressStr}post-code`, addressDict.Zip.trim())
    );
    this.props.dispatch(
      change(this.props.formName, `${addressStr}country`, addressDict.Country.trim())
    );
  }

  normalizePhone(value) {
    const re = /^[0-9]*$/;

    if (re.test(value)) {
      return value;
    }
  }

  renderBorderedTitle(title) {
    return <h3 className="checkout-heading-title">{title}</h3>;
  }
  renderActionButtons() {
    if (this.config.embedded) {
      return null;
    }

    return (
      <div className="checkout-actions">
        <div className="checkout-actions-item checkout-actions-item--secondary">
          <a href="#" className="checkout-card-secondaryAction">
            {gettext('Cancel')}
          </a>
        </div>
        <div className="checkout-actions-item checkout-actions-item--primary">
          <Button className="checkout-card-btn">{gettext('Save Changes')}</Button>
        </div>
        <div className="checkout-actions-item" />
      </div>
    );
  }

  renderStateField() {
    const states = this.getStateSelectOptions(this.props.country);
    if (states.length > 0) {
      return (
        <div className="grid-col grid-col--tablet-6">
          <Field
            name="state"
            label={gettext('State')}
            placeholder={`-- ${gettext('please select your state')} --`}
            component={Select}
            options={states}
            validate={[required]}
            requiredIndicator
          />
        </div>
      );
    }
    return (
      <div className="grid-col grid-col--tablet-6">
        <Field
          name="state"
          component={Input}
          label={gettext('State')}
          validate={[required, ...this.validators.state]}
          requiredIndicator
        />
      </div>
    );
  }

  renderCompanyStateField() {
    const states = this.getStateSelectOptions(this.props['company-country']);
    if (states.length > 0) {
      return (
        <div className="grid-col grid-col--tablet-6">
          <Field
            name="company-state"
            label={gettext('State')}
            placeholder={`-- ${gettext("please select your company's state")} --`}
            component={Select}
            options={states}
            validate={[required]}
            requiredIndicator
          />
        </div>
      );
    }
    return (
      <div className="grid-col grid-col--tablet-6">
        <Field
          name="company-state"
          component={Input}
          label={gettext('State')}
          validate={[required, ...this.validators.state]}
          requiredIndicator
        />
      </div>
    );
  }

  render() {
    return (
      <fieldset>
        <Fragment>
          {this.renderBorderedTitle('Country')}
          <div className="checkout-form-group">
            <Field
              name="country"
              placeholder={`-- ${gettext('please select your country')} --`}
              component={Select}
              options={createSelectOptions(CountryToAlpha2)}
              onChange={this.handleCountrySelect}
              validate={[required]}
              requiredIndicator
            />
          </div>
        </Fragment>
        {this.renderBorderedTitle('Customer Information')}
        <div className="grid grid--tight">
          <div className="grid-col grid-col--tablet-6">
            <Field
              name="first-name"
              component={Input}
              label={gettext('First Name')}
              validate={[required, ...this.validators.first_name]}
              requiredIndicator
              disabled={this.props.isTier2Verified}
            />
          </div>
          <div className="grid-col grid-col--tablet-6">
            <Field
              name="last-name"
              component={Input}
              label={gettext('Last Name')}
              validate={[required, ...this.validators.last_name]}
              requiredIndicator
              disabled={this.props.isTier2Verified}
            />
          </div>
        </div>
        <Field
          name="date-of-birth"
          component={DateOfBirthField}
          label={gettext('Date of Birth')}
          validate={[required, ...this.validators.date_of_birth]}
          disabled={this.props.isTier2Verified}
          requiredIndicator
        />
        <div className="field">
          <label className="field-label">
            {gettext('Primary Phone Number')}
            <span className="field-required">*</span>
          </label>
          <div className="defaultForm-group">
            <Field
              name="primary-phone-country"
              component={PhoneSelect}
              className="field--phoneSelect"
              validate={[required]}
              placeholder={`-- ${gettext('country code')} --`}
            />
            <Field
              name="primary-phone-number"
              type="tel"
              component={Input}
              validate={[required, this.validators.phone]}
              normalize={this.normalizePhone}
            />
          </div>
        </div>
        {this.renderBorderedTitle('Billing Information')}
        <Field
          name="address-line-1"
          component={Input}
          label={gettext('Address Line 1')}
          validate={[required, ...this.validators.address_line_1]}
          ref={(node) => {
            this.line1Ref = node;
          }}
          forwardRef
          requiredIndicator
        />
        <Field
          name="address-line-2"
          component={Input}
          label={gettext('Address Line 2 (Optional)')}
          validate={[...this.validators.address_line_2]}
        />
        <div className="grid grid--tight">
          <div className="grid-col grid-col--tablet-6">
            <Field
              name="city"
              component={Input}
              label={gettext('City')}
              validate={[required, ...this.validators.city]}
              requiredIndicator
            />
          </div>
          {this.renderStateField()}
          <div className="grid-col grid-col--tablet-12">
            <Field
              name="post-code"
              component={Input}
              label={gettext('Zip Code / Post Code')}
              validate={[...this.validators.zipcode]}
            />
          </div>
        </div>
        <Field
          name="company"
          component={Checkbox}
          label={gettext('I am buying on behalf of a company')}
        />

        {this.props.company && (
          <div>
            <Field
              name="company-name"
              component={Input}
              label={gettext('Company Name')}
              validate={[required, ...this.validators.company_name]}
              disabled={this.props.isTier3Verified}
              requiredIndicator
            />
            <Field
              name="company-address-line-1"
              component={Input}
              label={gettext('Address Line 1')}
              validate={[required, ...this.validators.address_line_1]}
              ref={(node) => {
                this.companyLine1Ref = node;
              }}
              forwardRef
              requiredIndicator
            />
            <Field
              name="company-address-line-2"
              component={Input}
              label={gettext('Address Line 2 (Optional)')}
              validate={[...this.validators.address_line_2]}
            />
            <div className="grid grid--tight">
              <div className="grid-col grid-col--tablet-6">
                <Field
                  name="company-city"
                  component={Input}
                  label={gettext('City')}
                  validate={[required, ...this.validators.city]}
                  requiredIndicator
                />
              </div>
              {this.renderCompanyStateField()}
            </div>
            <Field
              name="company-post-code"
              component={Input}
              label={gettext('Zip Code / Post Code')}
              validate={[...this.validators.zipcode]}
            />
            <Field
              name="company-country"
              label={gettext('Country')}
              placeholder={`-- ${gettext("please select your company's country")} --`}
              component={Select}
              onChange={this.handleCompanyCountrySelect}
              options={createSelectOptions(CountryToAlpha2)}
              validate={[required]}
              requiredIndicator
            />
          </div>
        )}
      </fieldset>
    );
  }
}

CustomerDetailsFieldset.propTypes = {
  company: PropTypes.bool,
  formName: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(null, mapDispatchToProps)(CustomerDetailsFieldset);
