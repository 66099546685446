import { createAPIKeys } from 'spa/actions/APIIntegrationActions';
import APIIntegration from 'spa/constants/APIIntegrationConstants';

const initialState = {
  apiKeys: [],
};

function apiIntegrationReducer(state = initialState, action) {
  switch (action.type) {
    case APIIntegration.GET_APIKEY_REQUEST:
      return Object.assign({}, state, {
        display: {
          loading: true,
        },
      });
    case APIIntegration.GET_APIKEY_SUCCESS:
      return Object.assign({}, state, {
        display: {
          loading: false,
          success: true,
        },
        apiKeys: action.apiKeys,
      });
    case APIIntegration.GET_APIKEY_FAILURE:
      return Object.assign({}, state, {
        display: {
          loading: false,
          success: false,
        },
      });
    case createAPIKeys.TRIGGER:
      return Object.assign({}, state, {
        create: {
          loading: true,
        },
      });
    case createAPIKeys.SUCCESS:
      return Object.assign({}, state, {
        create: {
          loading: false,
          success: true,
        },
        apiKeys: [...state.apiKeys, action.payload],
      });
    case createAPIKeys.FAILURE:
      return Object.assign({}, state, {
        create: {
          loading: false,
          success: false,
        },
      });
    case APIIntegration.DELETE_APIKEY_REQUEST:
      return Object.assign({}, state, {
        apiKeys: state.apiKeys.map((apiKey) =>
          apiKey.id === action.id ? { ...apiKey, deleting: true } : apiKey
        ),
      });
    case APIIntegration.DELETE_APIKEY_SUCCESS:
      return Object.assign({}, state, {
        apiKeys: state.apiKeys.filter((apiKey) => apiKey.id !== action.apiKey.id),
      });
    case APIIntegration.DELETE_APIKEY_FAILURE:
      return state;
    default:
      return state;
  }
}

export default apiIntegrationReducer;
