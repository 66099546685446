import { createRoutine } from 'redux-saga-routines';

import OfferConstants from 'spa/constants/OfferConstants';

export const pushAuctionId = (auctionId) => ({
  type: OfferConstants.PUSH_AUCTION_ID,
  payload: auctionId,
});

export const pushAuctionToken = (auctionToken) => ({
  type: OfferConstants.PUSH_AUCTION_TOKEN,
  payload: auctionToken,
});

export const pushAuctionSource = (actionType) => ({
  type: OfferConstants.PUSH_AUCTION_SOURCE,
  payload: actionType,
});

export const setTransId = (transId) => ({
  type: OfferConstants.SET_TRANS_ID,
  payload: transId,
});

export const changeView = (view) => ({
  type: OfferConstants.CHANGE_VIEW,
  payload: view,
});

export const buyItNowCta = (auctionId, auctionToken) => ({
  type: OfferConstants.BUY_IT_NOW_CTA,
  payload: {
    auctionToken,
    auctionId,
  },
});

export const getAuction = createRoutine(OfferConstants.GET_AUCTION);

export const initialiseMakeAnOffer = createRoutine(OfferConstants.INIT_MAKE_AN_OFFER);

export const initialiseOfferManagement = createRoutine(OfferConstants.INIT_OFFER_MANAGEMENT);

export const getAuctionOffers = createRoutine(OfferConstants.GET_AUCTION_OFFERS);

export const cancelAuction = createRoutine(OfferConstants.CANCEL_AUCTION);

export const acceptOffer = createRoutine(OfferConstants.ACCEPT_OFFER);

export const acceptOfferFromManagement = createRoutine(OfferConstants.ACCEPT_OFFER_FROM_MANAGEMENT);

export const rejectOffer = createRoutine(OfferConstants.REJECT_OFFER);

export const cancelOffer = createRoutine(OfferConstants.CANCEL_OFFER);

export const submitOffer = createRoutine(OfferConstants.SUBMIT_OFFER);

export const submitCounterOffer = createRoutine(OfferConstants.SUBMIT_COUNTER_OFFER);

export const submitOfferForm = createRoutine(OfferConstants.SUBMIT_OFFER_FORM);

export const buyItNow = createRoutine(OfferConstants.BUY_IT_NOW);

export const payOfferTransaction = createRoutine(OfferConstants.PAY_OFFER_TRANSACTION);
