import React from 'react';
import QueryString from 'query-string';
import { withRouter } from 'react-router-dom';
import Footer from 'spa/containers/Footer';
import EmailVerificatonContainer from 'spa/containers/EmailVerificationRequired/EmailVerificatonContainer';
import { urlFor } from '../../../routeConfig';

import AuthenticationStore from '../../../stores/AuthenticationStore';

const EmailVerificationPage = ({ history, location }) => {
  const authenticated = AuthenticationStore.isAuthenticated();

  if (!authenticated) {
    history.push({
      pathname: urlFor('login_page'),
      search: `?${QueryString.stringify({
        loginLocation: location.pathname,
      })}`,
    });
  }

  return (
    <div>
      <div className="verifyPage section section--mid">
        <div className="checkout-content section-container">
          <div className="verifyPage-body">
            <div className="verifyPage-card">
              <EmailVerificatonContainer
                onEmailVerified={() =>
                  window.location.assign(
                    `${window.config.www_base_url}${urlFor('transactions_page')}`
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(EmailVerificationPage);
