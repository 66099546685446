import React from 'react';
import PropTypes from 'prop-types';
import { gettext } from '../../../utils/filters';

const HeaderTitle = (props) => {
  const { title } = props;

  return <div className="headerV3-label media--available@mobile">{gettext(title)}</div>;
};

HeaderTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default HeaderTitle;
