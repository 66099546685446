const NotificationSettingConstants = {
  // form names
  NOTIFICATION_SETTINGS_FORM_NAME: 'notification-settings-form',

  // redux action types
  SET_NOTIFICATION_SETTINGS: 'SET_NOTIFICATION_SETTINGS',
  GET_NOTIFICATION_SETTINGS: 'GET_NOTIFICATION_SETTINGS',

  // error message
  CONFLICT_ERROR_MESSAGE: `The provided link was given for a user that does not match 
    the currently logged in user. Please sign out and try again.`,
};

NotificationSettingConstants.availableSettings = [
  {
    label: 'Transaction Event Notifications',
    fieldName: 'transactionEvent',
    tooltip: {
      message: 'This includes events relating to particular transactions.',
      id: 'transactionEventTooltip',
    },
  },
  {
    label: 'Account Event Notifications',
    fieldName: 'accountEvent',
    tooltip: {
      message: 'This includes events relating to customer accounts.',
      id: 'accountEventTooltip',
    },
  },
  {
    label: 'Milestone Event Notifications',
    fieldName: 'milestoneEvent',
    tooltip: {
      message: 'This includes events relating to particular milestones.',
      id: 'milestoneEventTooltip',
    },
  },
  {
    label: 'Marketing Emails',
    fieldName: 'marketing',
    tooltip: {
      message: 'This includes emails send by Escrow.com for marketing purposes.',
      id: 'marketingTooltip',
    },
  },
  {
    label: 'Transaction SMS Notifications',
    fieldName: 'transactionSmsEvent',
    tooltip: {
      message: 'This includes SMSes sent by Escrow.com relating to particular transactions',
      id: 'marketingTooltip',
    },
  },
];

export default NotificationSettingConstants;
