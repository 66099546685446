import React from 'react';
import classnames from 'classnames';
import libphonenumber from 'google-libphonenumber';
import PropTypes from 'prop-types';
import { reduxForm, formValues } from 'redux-form';

import { FormError } from 'spa/components/form';
import { Button } from 'escrow-common-js/dist/components';
import { AccountTypeFieldset, ContactDetailsFieldset } from 'spa/components/ContactDetails';
import VerificationConstants from 'spa/constants/VerificationConstants';
import { gettext } from '../../../utils/filters';
import { extractData, lookupCountry } from '../../../utils/DataMapping';
import { parsePhoneNumber } from '../../../utils/parse-phone';
import { Alpha2ToAlpha3 } from '../../constants/ISOCountryCodes';

const customerStateToFormMapping = {
  'personalAddress.line1': 'addressLine1',
  'personalAddress.line2': 'addressLine2',
  'personalAddress.city': 'city',
  'personalAddress.state': 'state',
  'personalAddress.zip': 'postCode',
  'personalAddress.country': 'country',
  firstName: 'firstName',
  middleName: 'middleName',
  lastName: 'lastName',
  localFName: 'localFName',
  localMName: 'localMName',
  localLName: 'localLName',
  companyName: 'companyName',
  'companyAddress.line1': 'companyAddressLine1',
  'companyAddress.line2': 'companyAddressLine2',
  'companyAddress.city': 'companyCity',
  'companyAddress.state': 'companyState',
  'companyAddress.zip': 'companyPostCode',
  email: 'email',
  isCompany: 'isCompany',
};

export const customerStateToForm = (input) => {
  let formCustomerDetails = {};
  formCustomerDetails = {
    ...formCustomerDetails,
    ...extractData(customerStateToFormMapping, input),
  };

  if (input.personalAddress.country) {
    const country = input.personalAddress.country;
    formCustomerDetails.country = lookupCountry(country).alpha2 || 'ZZ';
  }

  if (input.companyAddress.country) {
    const companyCountry = input.companyAddress.country;
    formCustomerDetails.companyCountry = lookupCountry(companyCountry).alpha2 || 'ZZ';
  }

  if (input.dateOfBirth) {
    const isDOBValid = Date.parse(input.dateOfBirth);
    formCustomerDetails = {
      ...formCustomerDetails,
      dateOfBirth: isDOBValid ? input.dateOfBirth : '',
    };
  }

  if (input.phoneNumber) {
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    const parsedPhone = parsePhoneNumber(input.phoneNumber, input.personalAddress.country);
    const primaryPhoneNumber = parsedPhone ? String(parsedPhone.getNationalNumber()) : '';
    const primaryPhoneCountry = parsedPhone
      ? Alpha2ToAlpha3[phoneUtil.getRegionCodeForNumber(parsedPhone)]
      : '';
    formCustomerDetails = {
      ...formCustomerDetails,
      primaryPhoneNumber: primaryPhoneNumber,
      primaryPhoneCountry: primaryPhoneCountry,
    };
  }

  if (input.isCompany) {
    formCustomerDetails.accountType = VerificationConstants.ACCOUNT_TYPE_COMPANY;
  } else {
    formCustomerDetails.accountType = VerificationConstants.ACCOUNT_TYPE_INDIVIDUAL;
  }

  return formCustomerDetails;
};

const ContactDetailsForm = (props) => {
  const forEkyc = !props.disableEKYC && props.isCountryForEKyc;
  return (
    <form name={props.form} onSubmit={props.handleSubmit(props.submitFunction)}>
      <AccountTypeFieldset title="Account Type" />
      <div className="verifyPage-card">
        <header className="verifyPage-card-header">
          <div>
            {props.title && <h4 className="verifyPage-card-header-title">{props.title}</h4>}
          </div>
        </header>
        <div className="verifyPage-card-content">
          <div className="verifyPage-card-body">
            {props.submitFailed && props.error && <FormError error={props.error} />}
            <ContactDetailsFieldset
              formName={props.form}
              country={props.country}
              companyCountry={props.companyCountry}
              accountType={props.accountType}
              primaryPhoneCountry={props.primaryPhoneCountry}
              disableVatDetails={props.disableVatDetails}
              disableNameAndDob={props.disableNameAndDob}
              disableCompanyName={props.disableCompanyName}
            />
            <footer className="verifyPage-card-footer">
              <div className="verifyPage-actions">
                {props.showCancel && (
                  <a
                    href="#"
                    role="button"
                    className="verifyPage-secondaryBtn verifyPage-secondaryBtn--alt"
                    data-tracking-name={'cancel'}
                    data-e2e-target="customer-details-cancel-button"
                    onClick={props.onCancel}
                  >
                    {gettext('Cancel')}
                  </a>
                )}
                <Button
                  type="submit"
                  className={classnames('verifyPage-primaryBtn', 'btn', 'btn--secondary', {
                    'verifyPage-primaryBtn--full': !props.showCancel,
                  })}
                  data-e2e-target="customer-details-save-button"
                  data-tracking-name={'submit'}
                  loading={props.submitting}
                  label={gettext(forEkyc ? 'Save and Next' : 'Save Changes')}
                >
                  {gettext(forEkyc ? 'Save and Next' : 'Save Changes')}
                </Button>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </form>
  );
};

ContactDetailsForm.propTypes = {
  disableVatDetails: PropTypes.bool,
  disableNameAndDob: PropTypes.bool,
  disableCompanyName: PropTypes.bool,
};

export default reduxForm({
  form: VerificationConstants.CUSTOMER_DETAILS_FORM,
  enableReinitialize: false,
})(
  formValues(
    'primaryPhoneCountry',
    'country',
    'companyCountry',
    'accountType',
    'showCancel'
  )(ContactDetailsForm)
);
