import classNames from 'classnames/dedupe';
import deepcopy from 'clone';

import HeaderTemplate from 'templates/navigation/defaultHeader.html';

import virtualize from '../utils/virtualize';
import AuthenticationStore from '../stores/AuthenticationStore';
import UserStore from '../stores/UserStore';
import FormStore from '../stores/FormStore';
import AuthenticationActions from '../actions/AuthenticationActions';
import UserActions from '../actions/UserActions';
import Component from '../utils/Component';
import { headerIsSticky, headerScrollTop, updateStickyNav } from '../navigation/sticky-nav';
import NavigationActions from '../actions/NavigationActions';
import NavigationStore from '../stores/NavigationStore';
import NavigationConstants from '../constants/NavigationConstants';
import SearchConstants from '../constants/SearchConstants';
import SearchInput from './SearchInput';

function getState() {
  return {
    authenticated: AuthenticationStore.isAuthenticated(),
    formSubmitting: FormStore.formIsSubmitting('login-form'),
    sticky: headerIsSticky(),
    scrollTop: headerScrollTop(),
    kycActionRequired: UserStore.kycActionRequired(AuthenticationStore.getCustomerID()),
    isKycCompleted: UserStore.isKycCompleted(AuthenticationStore.getCustomerID()),
    customerData: UserStore.getCustomerData(AuthenticationStore.getCustomerID()),
    ignoreMissingCustomerData: NavigationStore.getIgnoreMissingCustomerData(),
    pageType: NavigationStore.pageType,
    isSearchOpen: NavigationStore.searchOpen,
  };
}

function shouldRenderLoggedInHeader() {
  const AUTH_PAGES = ['login', 'signup'];
  const isAuthPage = (partialUrl) => window.location.href.includes(partialUrl);
  return !AUTH_PAGES.some(isAuthPage);
}

export class Navigation extends Component {
  constructor(element, type) {
    super();
    this.template = virtualize(element);
    this.funnel = element.getAttribute('data-funnel') !== null;
    this.type = type;
    this.setState(getState());
    this._onChange = this._onChange.bind(this);
    this.addEventListener('click', '[data-target="logout"]', this._onClick.bind(this));
    this.addEventListener('mouseover', '[data-header-nav-item]', this._onNavItemHover.bind(this));
    this.addEventListener('mouseout', '[data-header-nav-item]', this._onNavItemLeave.bind(this));
    this.addEventListener('focus', '[data-header-search]', this._onSearchFocus.bind(this));
    this.addEventListener('blur', '[data-header-search]', this._onSearchBlur.bind(this));
    this.addEventListener('click', '[data-header-menu-toggle]', this._onMenuToggle.bind(this));
    this._onChange();
  }

  _onNavItemHover() {
    const headerElement = document.querySelector('[data-header]');
    headerElement.className = classNames(headerElement.className, {
      'is-navHover': true,
    });
  }

  _onNavItemLeave() {
    const headerElement = document.querySelector('[data-header]');
    headerElement.className = classNames(headerElement.className, {
      'is-navHover': false,
    });
  }

  _onSearchFocus() {
    NavigationActions.searchOpen();
    updateStickyNav();
  }

  _onSearchBlur() {
    NavigationActions.searchClose();
  }

  _onMenuToggle() {
    document.body.classList.toggle('is-mobileNavActive');
  }

  _onClick(event) {
    event.preventDefault();
    AuthenticationActions.logout();
  }

  _onChange() {
    this.setState(getState());
    if (
      this.state.authenticated &&
      !this.state.customerData &&
      !this.state.ignoreMissingCustomerData
    ) {
      UserActions.getCustomerData(AuthenticationStore.getCustomerID());
    }
  }

  componentHasMounted() {
    AuthenticationStore.addChangeListener(this._onChange);
    UserStore.addChangeListener(this._onChange);
    NavigationStore.addChangeListener(this._onChange);

    const searchInputElement = this.rootNode.querySelector('[data-target="searchOverlay-input"]');
    if (searchInputElement) {
      this.searchComponent = new SearchInput(searchInputElement, {
        category: SearchConstants.CATEGORY_MENU,
        searchLocation: SearchConstants.LOCATION_HEADER,
      }).replace(searchInputElement);
    }
  }

  componentWillDismount() {
    AuthenticationStore.removeChangeListener(this._onChange);
    UserStore.removeChangeListener(this._onChange);
    NavigationStore.removeChangeListener(this._onChange);
  }

  isSearchDisabled(pathname) {
    const disabledSet = new Set();
    disabledSet.add(window.config.url_map['views.en.helpdesk']);
    disabledSet.add(window.config.url_map['views.cn.helpdesk']);
    return disabledSet.has(pathname);
  }

  render() {
    const vhtml = deepcopy(this.template);

    const headerElement = document.querySelector('[data-header]');
    if (headerElement) {
      headerElement.className = classNames(headerElement.className, {
        'is-searchActive': this.state.isSearchOpen,
      });
    }

    if (
      this.state.authenticated === false ||
      (this.state.authenticated && !this.state.formSubmitting)
    ) {
      const customerData = this.state.customerData || {};
      const headerHtml = virtualize.fromHTML(
        HeaderTemplate.render({
          header_type: this.state.pageType ? this.state.pageType : this.type,
          authenticated: this.state.authenticated && shouldRenderLoggedInHeader(),
          sticky: this.state.sticky,
          scrollTop: this.state.scrollTop,
          current_page: document.location.pathname,
          funnel: this.funnel,
          email: customerData.Email,
          userName: `${customerData.FirstName} ${customerData.LastName}`,
          companyName: customerData.CompanyName,
          kycActionRequired: this.state.kycActionRequired,
          isKycCompleted: this.state.isKycCompleted,
          support_phone_number: window.config.support_phone_number,
          support_phone_label: window.config.support_phone_label,
          disable_navbar_search: this.isSearchDisabled(document.location.pathname),
          config: {
            ESCROW_LOGGEDIN_LANDING_PAGE: window.config.loggedin_landing_page,
            ESCROW_TRANSACTION_CHOICE: window.config.transaction_choice,
            ESCROW_ECART_URL: window.config.ecart_url,
            MYESCROW_BASEURL: window.config.my_escrow_base_url,
          },
        })
      ).children;
      vhtml.children = NavigationConstants.HIDE_LEGACY_HEADER_PAGE_TYPES.includes(
        this.state.pageType
      )
        ? []
        : headerHtml;
      if (this.state.pageType === 'offer_management' && this.state.authenticated)
        vhtml.children = headerHtml;
    }

    return vhtml;
  }
}

setTimeout(() => {
  for (const e of document.querySelectorAll('[data-component="navigation-header"]')) {
    const type = e.getAttribute('data-header');
    new Navigation(e, type).replace(e);
  }
});
