import React from 'react';
import { Button } from '@mui/material';
import { useWindowDimensions } from 'spa/hooks';
import TransactionConstants from 'spa/constants/TransactionConstants';
import { SubmitButton } from 'spa/components/StartTransaction';
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';

const KYCPrimaryButton = ({ isDisabled, isLoading, onPrimaryClick, primaryText, type }) => {
  const { width } = useWindowDimensions();
  return (
    <SubmitButton
      disabled={isDisabled}
      loading={isLoading}
      fullWidth={width < TransactionConstants.DESKTOP_VIEW_WIDTH}
      onClick={onPrimaryClick}
      type={type}
    >
      {primaryText || 'Submit'}
    </SubmitButton>
  );
};

const KYCSecondaryButton = ({ onSecondaryClick, isDisabled, secondaryText }) => {
  const { width } = useWindowDimensions();

  return (
    <Button
      variant="text"
      color="primary"
      fullWidth={width < TransactionConstants.DESKTOP_VIEW_WIDTH}
      size="large"
      startIcon={width > TransactionConstants.DESKTOP_VIEW_WIDTH && <ArrowBackSharpIcon />}
      onClick={onSecondaryClick}
      disabled={isDisabled}
    >
      {secondaryText || 'Cancel'}
    </Button>
  );
};

const ActionButtons = ({
  withBack = false,
  onPrimaryClick,
  onSecondaryClick,
  primaryText,
  secondaryText,
  isDisabled,
  isLoading = false,
  type,
}) => {
  if (withBack) {
    return (
      <div className="kyc-actions">
        <KYCSecondaryButton
          onSecondaryClick={onSecondaryClick}
          isDisabled={isLoading}
          secondaryText={secondaryText}
        />
        <KYCPrimaryButton
          isDisabled={isDisabled || isLoading}
          isLoading={isLoading}
          onPrimaryClick={onPrimaryClick}
          primaryText={primaryText}
          type={type}
        />
      </div>
    );
  }

  return (
    <div className="kyc-actions kyc-actions--center">
      <KYCPrimaryButton
        isDisabled={isDisabled}
        isLoading={isLoading}
        onPrimaryClick={onPrimaryClick}
        primaryText={primaryText}
        type={type}
      />
    </div>
  );
};

export default ActionButtons;
