import React from 'react';
import ReactSVG from 'react-svg';
import { Button } from 'escrow-common-js/dist/components';
import classnames from 'classnames';
import { Field, reduxForm } from 'redux-form';
import TwoFactorConstants from 'spa/constants/TwoFactorConstants';
import { gettext } from '../../../utils/filters';

const AuthenticationMethod = ({
  id,
  name,
  title,
  subtitle,
  paymentMethodEta,
  content,
  showContent,
  badgeText,
  alternate,
  small,
  logo,
  disabled,
}) => (
  <label
    key={id}
    className={classnames(
      'twoFactorAuth-method',
      { 'is-disabled': disabled },
      {
        alternate: alternate,
      },
      { 'twoFactorAuth-method--small': small }
    )}
  >
    {logo && <div className="twoFactorAuth-method-logo">{logo}</div>}
    <div className="twoFactorAuth-method-text">
      <span className="twoFactorAuth-method-text-title">{title}</span>
      {badgeText && <span className="twoFactorAuth-method-text-badge">{badgeText}</span>}
      {subtitle && <p className="twoFactorAuth-method-text-detail">{subtitle}</p>}
      {paymentMethodEta && <p className="twoFactorAuth-method-text-detail">{paymentMethodEta}</p>}
      {showContent && content}
    </div>
    <div>
      {!disabled && (
        <Field
          name={name}
          type="radio"
          component="input"
          className="twoFactorAuth-method-radio"
          value={id}
          data-tracking-name={name}
        />
      )}
      <div className="twoFactorAuth-method-pseudo" />
    </div>
  </label>
);

const methodSelectionForm = (props) => {
  const { onSubmit, onCancel } = props;
  const availableMethods = [
    {
      title: 'Authenticator App',
      value: TwoFactorConstants.TYPE_AUTH_APP,
      subtitle: 'Use the Google Authenticator App to generate a security code.',
      logo: <ReactSVG src="../../../../build/images/global/icons/google-auth-icon.svg" />,
    },
    {
      title: 'SMS Authentication',
      value: TwoFactorConstants.TYPE_SMS,
      subtitle: 'Use a text message (SMS) to receive a security code.',
      logo: <ReactSVG src="../../../../build/images/global/icons/ui-sms.svg" />,
    },
  ];
  return (
    <div className="twoFactorCard">
      <div className="twoFactorAuth-card-header">
        <h2 className="twoFactorAuth-card-header-title twoFactorAuth-card-header-title--auth">
          Select your verification method
        </h2>
        <div className="twoFactorAuth-page-subheader twoFactorAuth-page-subheader--auth">
          {gettext(`You currently have multiple verification method set up. Select the verification
          method you prefer.`)}
        </div>
      </div>
      <form name={props.form} onSubmit={props.handleSubmit((values) => onSubmit(values.method))}>
        <div className="twoFactorAuth-page">
          {availableMethods.map((method) => (
            <AuthenticationMethod
              key={method.value}
              title={method.title}
              id={method.value}
              subtitle={method.subtitle}
              logo={method.logo}
              name="method"
            />
          ))}
          <div className="twoFactorAuth-page-footer">
            <a
              className="twoFactorAuth-page-backBtn twoFactorAuth-page-backBtn--secondary"
              role="button"
              tabIndex={0}
              onClick={onCancel}
            >
              {gettext('Back')}
            </a>
            <Button className="twoFactorAuth-page-btn-next twoFactorAuth-page-btn" type="submit">
              {gettext('Submit')}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default reduxForm({
  form: TwoFactorConstants.METHOD_SELECTION_FORM_NAME,
  enableReinitialize: false,
  destroyOnUnmount: false,
})(methodSelectionForm);
