import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { getTypeTextSubstrings } from '../../utils/transactionTypeFormatting';

export const itemRejectionBannerText = (role, itemText) => {
  let title;
  let body;

  switch (role) {
    case 'buyer':
      title = `You have rejected the ${itemText}`;
      body = `In order to process your refund, the ${itemText} must safely be transferred back to the seller.`;
      break;
    case 'seller':
      title = `The buyer has rejected the ${itemText}`;
      body = `In order to process the buyer’s refund we have instructed them to safely return the ${itemText} to you.`;
      break;
    default:
      // broker copy is the default if value of role is unhandled
      title = `The buyer has rejected the ${itemText}`;
      body = `In order to process the buyer’s refund we have instructed them to safely return the ${itemText} to the seller.`;
      break;
  }

  return { title, body };
};

const ItemRejectionBanner = ({ role, transactionTypeCode, multipleItems }) => {
  const { itemText } = getTypeTextSubstrings(transactionTypeCode, multipleItems);
  const { title, body } = itemRejectionBannerText(role, itemText);

  return (
    <Alert
      variant="outlined"
      severity="error"
      data-testid="item-rejection-banner"
      sx={{ bgcolor: 'background.paper' }}
    >
      <AlertTitle> {title} </AlertTitle>
      {body}
    </Alert>
  );
};

export default ItemRejectionBanner;
