import React from 'react';
import { Section, SectionTitle, SectionDesc } from 'spa/components/Section';
import A from 'spa/components/A';

import { urlFor } from '../../../routeConfig';

const Plugins = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktopSmall-9">
        <h1 className="integrationsPortal-title">Plugins</h1>
        <Section>
          <SectionTitle>A Faster Way to Integrate</SectionTitle>
          <SectionDesc>
            To help businesses add Escrow.com payments to their online stores, we have started to
            create plugins for the major ecommerce platforms. While you are welcome to integrate
            directly with our API, the fastest way to add the safety and security of Escrow.com to
            your online business is to simple install a plugin and configure it to your liking.
          </SectionDesc>
          <SectionDesc>
            Over the following weeks and months we plan on adding new plugins. If you run an
            ecommerce store and we have yet to create a plugin for your platform, please email us at{' '}
            <A link={{ type: 'email', route: 'mailto:plugins@escrow.com' }}>plugins@escrow.com</A>{' '}
            to let us know which plugin or extension you would like us to work on next.
          </SectionDesc>
        </Section>
        <Section>
          <SectionTitle>Available Plugins</SectionTitle>
          <SectionDesc>
            <A link={{ type: 'internal', route: urlFor('plugins_woocommerce') }}>WooCommerce</A>
          </SectionDesc>
        </Section>
      </div>
    </div>
  </div>
);

export default Plugins;
