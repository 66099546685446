import scrollToComponent from 'react-scroll-to-component';

export const scrollTo = (ref) => {
  scrollToComponent(ref, {
    offset: -70,
    align: 'top',
    duration: 300,
    ease: 'linear',
  });
};
