import React from 'react';
import { V3KYCDesktopPages as Pages } from 'spa/constants/VerificationConstants';
import { KYC_BUTTONS } from 'spa/constants/VerificationConstants';
import DesktopPage from '../components/DesktopPage';

const SMSCodePage = () => (
  <DesktopPage
    title="SMS Code Page"
    ctaText="Send Code"
    extraButtons={[KYC_BUTTONS.ResendSMS]}
    nextPage={Pages.V2_KYC}
  />
);

export default SMSCodePage;
