import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Code } from 'spa/components/Code';
import PrimaryLink from 'spa/components/PrimaryLink';
import { Section, SectionTitle, SectionDesc, SectionSubTitle } from 'spa/components/Section';
import AccentedText from 'spa/components/AccentedText';

import { urlFor, envDomainName } from '../../../routeConfig';

const ListTransactions = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktopSmall-9">
        <h1 className="integrationsPortal-title">API Guide :: Listing your transactions</h1>
        <Section>
          <SectionTitle>Listing your transactions</SectionTitle>
          <SectionDesc>
            {` It is possible to list all of the transactions that you are involved in. Simply call
              the `}
            <AccentedText>/2017-09-01/transaction</AccentedText>
            {` endpoint. This will return a list
              of 10 transactions. In order to retrieve more, you must call the API multiple times
              and increment the `}
            <AccentedText>page</AccentedText>
            {' parameter.'}
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.${envDomainName}/2017-09-01/transaction" \\
    -X GET \\
    -u "email-address:your-api-key"
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.get(
    'https://api.${envDomainName}/2017-09-01/transaction',
    auth=('email address', 'api-key'),
)
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="ruby">
                {`require 'net/http'
require 'json'

uri = URI("https://api.${envDomainName}/2017-09-01/transaction")

http = Net::HTTP.new(uri.host, uri.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Get.new(uri.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "my-password")
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
  CURLOPT_URL => 'https://api.${envDomainName}/2017-09-01/transaction',
  CURLOPT_RETURNTRANSFER => 1,
  CURLOPT_USERPWD => 'email-address:your-password',
  CURLOPT_HTTPHEADER => array(
      'Content-Type: application/json'
  ),
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue(
                "application/json"));
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.${envDomainName}/2017-09-01/transaction"),
                Method = HttpMethod.Get,
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
          <SectionSubTitle>Example Response</SectionSubTitle>
          <Code language="json">
            {`
    "page": 1,
    "page_count": 1,
    "per_page": 10,
    "transactions": [{
        "id": 3300003,
        "parties": [
            {
                "customer": "michael.nyqvist@test.escrow.com",
                "agreed": true,
                "role": "buyer"
            },
            {
                "customer": "keanu.reaves@test.escrow.com",
                "agreed": false,
                "role": "seller"
            }
        ],
        "currency": "usd",
        "description": "The sale of johnwick.com",
        "items": [
            {
                "status": {
                    "received": false,
                    "rejected_returned": false,
                    "rejected": false,
                    "received_returned": false,
                    "shipped": false,
                    "accepted": false,
                    "shipped_returned": false,
                    "accepted_returned": false
                },
                "extra_attributes": {
                    "concierge": false,
                    "with_content": false
                },
                "description": "johnwick.com",
                "schedule": [
                    {
                        "payer_customer": "michael.nyqvist@test.escrow.com",
                        "status": {
                            "secured": false
                        },
                        "amount": "1000.00",
                        "beneficiary_customer": "keanu.reaves@test.escrow.com"
                    }
                ],
                "title": "johnwick.com",
                "fees": [
                    {
                        "payer_customer": "michael.nyqvist@test.escrow.com",
                        "amount": "32.50",
                        "type": "escrow"
                    }
                ],
                "type": "domain_name",
                "id": 3870000,
                "inspection_period": 259200,
                "quantity": 1
            }
        ]
    }]
}`}
          </Code>
        </Section>
        <footer className="integrationsPortal-footer">
          <h3 className="integrationsPortal-footer-title">{'Next steps'}</h3>
          <nav className="integrationsPortal-footer-steps">
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Agree to a transaction"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_agree_transaction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Fetch transaction details"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_fetch_transaction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Create a customer"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_create_customer'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Full API reference Document"
                link={{
                  type: 'external',
                  route: window.config.api_docs_page,
                }}
              />
            </div>
          </nav>
        </footer>
      </div>
    </div>
  </div>
);

export default ListTransactions;
