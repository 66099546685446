import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  V3KYCMobilePages as Pages,
  KYC_FILES as FILES,
  KYC_BUTTONS as BUTTONS,
  KYC_TITLES as TITLES,
  KYC_SUBTITLES as SUBTITLES,
  KYC_PAGE_STATES,
} from 'spa/constants/VerificationConstants';
import {
  kycImageSelector,
  kycImagesSelector,
  kycSelectedIDSelector,
  kycSelectedPOASelector,
  updateKycImageId,
} from 'spa/features/kyc/kycSlice';
import { useUploadFileMutation, useVerifyCustomerMutation } from 'spa/features/kyc/kycApi';
import { ERROR_TYPES } from 'spa/constants/VerificationConstants';
import { dataURLtoFile } from 'spa/../utils/PhotoCapture';

import MobilePage from '../../components/MobilePage';
import KYCAlert from '../../components/KYCAlert';
import PhotoReview from '../../components/PhotoReview';
import RetakePhotoButton from '../../components/ExtraButtons/RetakePhotoButton';

const key = FILES.ADDRESS;

const { INITIAL, LOADING, ERROR, SUCCESS } = KYC_PAGE_STATES;

const POACaptureReviewPage = () => {
  const dispatch = useDispatch();

  const kycImageDataUrl = useSelector(kycImageSelector(key));
  const kycImages = useSelector(kycImagesSelector);
  const kycId = useSelector(kycSelectedIDSelector);
  const kycPOA = useSelector(kycSelectedPOASelector);

  const [pageState, setPageState] = useState(INITIAL);
  const [onSubmit, setOnSubmit] = useState(null);
  const [retryVerify, setRetryVerify] = useState(false);

  const [prevPageTrigger, setPrevPageTrigger] = useState(false);

  const [
    uploadFile,
    {
      data,
      isLoading: isLoadingUpload,
      isError: isErrorUpload,
      error: uploadError,
      isSuccess: isSuccessUpload,
      reset,
    },
  ] = useUploadFileMutation();

  const [
    verifyCustomerT2,
    {
      isLoading: isLoadingVerify,
      isError: isErrorVerify,
      error: verifyError,
      reset: resetVerify,
      isSuccess: isSuccessVerify,
      isUninitialized: isUninitializedVerify,
    },
  ] = useVerifyCustomerMutation();

  const handleUpload = useCallback(async () => {
    const capturedFile = await dataURLtoFile(kycImageDataUrl, `${key}.png`);
    await uploadFile(capturedFile);
  }, [kycImageDataUrl, uploadFile]);

  const verifyT2KYC = useCallback(
    async (poaFileId) => {
      const t2DocumentsObject = [];
      const IdProofFiles = kycImages[FILES.ID_BACK]
        ? [kycImages[FILES.ID_FRONT].id, kycImages[FILES.ID_BACK].id]
        : [kycImages[FILES.ID_FRONT].id];

      t2DocumentsObject.push({
        file_ids: IdProofFiles,
        proof_type: kycId.type,
        proof_for: ['id'],
      });
      t2DocumentsObject.push({
        file_ids: [poaFileId],
        proof_type: kycPOA.type,
        proof_for: ['address'],
      });

      const payload = {
        account: {
          type: 'individual',
        },
        documents: t2DocumentsObject,
      };
      await verifyCustomerT2(payload);
    },
    [kycId.type, kycImages, kycPOA.type, verifyCustomerT2]
  );

  useEffect(() => {
    // Reset image id on initial page load
    dispatch(updateKycImageId({ fileKey: key, id: null }));
  }, [dispatch]);

  useEffect(() => {
    // Only trigger these side effects if the file has not yet been added to the state
    const poaFileId = kycImages[key].id;

    if (!poaFileId) {
      if (isSuccessUpload && data) {
        const uploadId = data.id;
        if (uploadId) {
          dispatch(updateKycImageId({ fileKey: key, id: uploadId }));
          verifyT2KYC(uploadId);
        }
      } else if (isErrorUpload) {
        setOnSubmit(() => reset);
        setPageState(ERROR);
      } else if (isLoadingUpload) {
        setPageState(LOADING);
      } else {
        setOnSubmit(() => handleUpload);
        setPageState(INITIAL);
      }
    }
  }, [
    data,
    dispatch,
    handleUpload,
    isErrorUpload,
    isLoadingUpload,
    isSuccessUpload,
    reset,
    setOnSubmit,
    setPageState,
    kycImages,
    verifyT2KYC,
  ]);

  useEffect(() => {
    // Only trigger these side effects if the file upload succeeded
    const poaFileId = kycImages[key].id;

    if (poaFileId) {
      if (!isUninitializedVerify) {
        if (isSuccessVerify) {
          setPageState(SUCCESS);
        } else if (isErrorVerify) {
          setOnSubmit(() => resetVerify);
          setRetryVerify(true);
          setPageState(ERROR);
        } else if (isLoadingVerify) {
          setPageState(LOADING);
        }
      } else if (retryVerify) {
        setOnSubmit(() => verifyT2KYC(poaFileId));
        setRetryVerify(false);
      }
    }
  }, [
    isSuccessVerify,
    isErrorVerify,
    isLoadingVerify,
    isUninitializedVerify,
    setPageState,
    setOnSubmit,
    resetVerify,
    kycImages,
    verifyT2KYC,
    retryVerify,
  ]);

  return (
    <MobilePage
      title={pageState === LOADING ? null : TITLES.POA}
      subtitle={pageState === LOADING ? null : SUBTITLES.POA}
      ctaText={pageState === INITIAL ? BUTTONS.SubmitPhoto : BUTTONS.TryAgain}
      onSubmit={onSubmit}
      nextPage={Pages.POA_UPLOAD_SUCCESS}
      PhotoUpload
      nextPageTrigger={pageState === SUCCESS}
      disableButtons={pageState === LOADING}
      noButton={pageState === LOADING ? true : null}
      ExtraButton={(props) => (
        <RetakePhotoButton
          {...props}
          onClick={() => {
            setPrevPageTrigger(true);
          }}
        >
          Retake photo
        </RetakePhotoButton>
      )}
      prevPageTrigger={prevPageTrigger}
    >
      {uploadError && (
        <KYCAlert
          isError={uploadError}
          errorType={ERROR_TYPES.PHOTO_UPLOAD_FAILED}
          xRequestId={uploadError.xRequestId}
        />
      )}
      {verifyError && (
        <KYCAlert
          isError={verifyError}
          errorType={ERROR_TYPES.SUBMISSION_FAILED}
          xRequestId={verifyError.xRequestId}
        />
      )}
      <PhotoReview fileKey={key} parentPageState={pageState} />
    </MobilePage>
  );
};

export default POACaptureReviewPage;
