import React from 'react';
import { DashboardCard, DashboardCardBody } from 'spa/components/DashboardCard';
import Footer from 'spa/components/Footer';
import PartnerLogoContainer from 'spa/containers/PartnerLogo';
import PartnerLogoSettingsContainer from 'spa/containers/PartnerLogo/SettingsForm';
import { Section } from 'spa/components/Section';

const LogosGetStartedPage = () => (
  <div>
    <h1 className="integrationsPortal-title">Partner Logo Management</h1>
    <p className="integrationsPortal-section-desc">
      The logo is used on Escrow Pay, Transactional emails and other locations that the partner logo
      is supported.
    </p>
    <Section>
      <DashboardCard>
        <DashboardCardBody>
          <PartnerLogoContainer />
        </DashboardCardBody>
      </DashboardCard>
    </Section>
    <PartnerLogoSettingsContainer />
    <Footer />
  </div>
);

export default LogosGetStartedPage;
