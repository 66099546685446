import React, { useState } from 'react';
import { V3KYCMobilePages as Pages, KYC_FILES } from 'spa/constants/VerificationConstants';
import CameraPage from '../camera/CameraPage';
import MobilePage from '../../components/MobilePage';

const CompanyCapturePage = () => {
  const [triggerNextPage, setTriggerNextPage] = useState(null);

  return (
    <MobilePage nextPageTrigger={triggerNextPage} nextPage={Pages.COMPANY_CAPTURE_REVIEW}>
      <CameraPage fileKey={KYC_FILES.COMPANY_DOCUMENT} setTriggerNextPage={setTriggerNextPage} />
    </MobilePage>
  );
};

export default CompanyCapturePage;
