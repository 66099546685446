var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["components/simpleTag.html"] = require( "components/simpleTag.html" );
dependencies["components/search.html"] = require( "components/search.html" );
dependencies["icons/search.html"] = require( "icons/search.html" );




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/search/popularSearches.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("components/simpleTag.html", false, "escrow_www/templates/search/popularSearches.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(context.getVariables(), frame, function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("tag", t_1);
env.getTemplate("components/search.html", false, "escrow_www/templates/search/popularSearches.html", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(context.getVariables(), frame, function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(t_4.hasOwnProperty("searchResult")) {
var t_7 = t_4.searchResult;
} else {
cb(new Error("cannot import 'searchResult'")); return;
}
context.setVariable("searchResult", t_7);
env.getTemplate("icons/search.html", false, "escrow_www/templates/search/popularSearches.html", false, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
t_8.getExported(context.getVariables(), frame, function(t_10,t_8) {
if(t_10) { cb(t_10); return; }
if(t_8.hasOwnProperty("search")) {
var t_11 = t_8.search;
} else {
cb(new Error("cannot import 'search'")); return;
}
context.setVariable("searchIcon", t_11);
var t_12;
t_12 = [{"term": (lineno = 5, colno = 17, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Fees"])),"link": "/fee-calculator"},{"term": (lineno = 6, colno = 17, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["What Is Escrow?"])),"link": "/what-is-escrow"},{"term": (lineno = 7, colno = 17, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Payment types"])),"link": "/support/payment-options"},{"term": (lineno = 8, colno = 17, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Payments processing time"])),"link": "/support/faqs/how-quickly-are-incoming-payments-processed"},{"term": (lineno = 9, colno = 17, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Contact Us"])),"link": "/contact-us"}];
frame.set("popularSearches", t_12, true);
if(frame.topLevel) {
context.setVariable("popularSearches", t_12);
}
if(frame.topLevel) {
context.addExport("popularSearches", t_12);
}
var macro_t_13 = runtime.makeMacro(
[], 
["values"], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("values", kwargs.hasOwnProperty("values") ? kwargs["values"] : runtime.contextOrFrameLookup(context, frame, "popularSearches"));
var t_14 = "";frame = frame.push();
var t_17 = runtime.contextOrFrameLookup(context, frame, "values");
if(t_17) {var t_16 = t_17.length;
for(var t_15=0; t_15 < t_17.length; t_15++) {
var t_18 = t_17[t_15];
frame.set("value", t_18);
frame.set("loop.index", t_15 + 1);
frame.set("loop.index0", t_15);
frame.set("loop.revindex", t_16 - t_15);
frame.set("loop.revindex0", t_16 - t_15 - 1);
frame.set("loop.first", t_15 === 0);
frame.set("loop.last", t_15 === t_16 - 1);
frame.set("loop.length", t_16);
t_14 += "\n\n    ";
t_14 += runtime.suppressValue((lineno = 16, colno = 16, runtime.callWrap(runtime.memberLookup((t_1),"default"), "tag[\"default\"]", context, [runtime.memberLookup((t_18),"term"),runtime.makeKeywordArgs({"icon": (lineno = 16, colno = 44, runtime.callWrap(t_11, "searchIcon", context, [])),"attrs": {"data-search-term": runtime.memberLookup((t_18),"term")}})])), env.opts.autoescape);
;
}
}
frame = frame.pop();
;
frame = frame.pop();
return new runtime.SafeString(t_14);
});
context.addExport("popularSearchTag");
context.setVariable("popularSearchTag", macro_t_13);
var macro_t_19 = runtime.makeMacro(
[], 
["values"], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("values", kwargs.hasOwnProperty("values") ? kwargs["values"] : runtime.contextOrFrameLookup(context, frame, "popularSearches"));
var t_20 = "";frame = frame.push();
var t_23 = runtime.contextOrFrameLookup(context, frame, "values");
if(t_23) {var t_22 = t_23.length;
for(var t_21=0; t_21 < t_23.length; t_21++) {
var t_24 = t_23[t_21];
frame.set("value", t_24);
frame.set("loop.index", t_21 + 1);
frame.set("loop.index0", t_21);
frame.set("loop.revindex", t_22 - t_21);
frame.set("loop.revindex0", t_22 - t_21 - 1);
frame.set("loop.first", t_21 === 0);
frame.set("loop.last", t_21 === t_22 - 1);
frame.set("loop.length", t_22);
t_20 += "\n\n    ";
t_20 += runtime.suppressValue((lineno = 25, colno = 22, runtime.callWrap(runtime.memberLookup((t_1),"defaultInvert"), "tag[\"defaultInvert\"]", context, [runtime.memberLookup((t_24),"term"),runtime.makeKeywordArgs({"icon": (lineno = 25, colno = 50, runtime.callWrap(t_11, "searchIcon", context, [])),"attrs": {"data-search-term": runtime.memberLookup((t_24),"term")}})])), env.opts.autoescape);
;
}
}
frame = frame.pop();
;
frame = frame.pop();
return new runtime.SafeString(t_20);
});
context.addExport("popularSearchTagInvert");
context.setVariable("popularSearchTagInvert", macro_t_19);
var macro_t_25 = runtime.makeMacro(
[], 
["values"], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("values", kwargs.hasOwnProperty("values") ? kwargs["values"] : runtime.contextOrFrameLookup(context, frame, "popularSearches"));
var t_26 = "";frame = frame.push();
var t_29 = runtime.contextOrFrameLookup(context, frame, "values");
if(t_29) {var t_28 = t_29.length;
for(var t_27=0; t_27 < t_29.length; t_27++) {
var t_30 = t_29[t_27];
frame.set("value", t_30);
frame.set("loop.index", t_27 + 1);
frame.set("loop.index0", t_27);
frame.set("loop.revindex", t_28 - t_27);
frame.set("loop.revindex0", t_28 - t_27 - 1);
frame.set("loop.first", t_27 === 0);
frame.set("loop.last", t_27 === t_28 - 1);
frame.set("loop.length", t_28);
t_26 += "\n\n    ";
t_26 += runtime.suppressValue((lineno = 34, colno = 16, runtime.callWrap(t_7, "searchResult", context, [runtime.makeKeywordArgs({"title": runtime.memberLookup((t_30),"term"),"link": runtime.memberLookup((t_30),"link")})])), env.opts.autoescape);
;
}
}
frame = frame.pop();
;
frame = frame.pop();
return new runtime.SafeString(t_26);
});
context.addExport("popularSearchResults");
context.setVariable("popularSearchResults", macro_t_25);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/search/popularSearches.html"] , dependencies)