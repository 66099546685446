import { isEmpty } from 'lodash';
import { select, put, call, race, take, takeLatest } from 'redux-saga/effects';
import { reset, SubmissionError } from 'redux-form';

import {
  addCustomerVatNumber as accountInfoPageAddVatNumberRoutine,
  getV4Customer as getV4CustomerRoutine,
  fluxGetCustomerRequest,
  setUserDetailsFromV4,
  setUserDetailsFromV2,
  updateIndividualVerificationStatus,
  updateCompanyVerificationStatus,
  deleteTaxNumber as deleteTaxNumberRoutine,
  submitPartnerLogo as submitPartnerLogoRoutine,
  listPartnerLogos as listPartnerLogosRoutine,
  removePartnerLogo as removePartnerLogoRoutine,
  setCustomerTaxNumber as setCustomerTaxNumberRoutine,
  setExtendedKycRequirement as setExtendedKycRequirementRoutine,
  verifyCustomerInformation as verifyCustomerInformationRoutine,
  electronicallyVerifyCustomer as electronicallyVerifyCustomerRoutine,
  getSavedCreditCards as getSavedCreditCardsRoutine,
  getUserEKYCData as getUserEKYCDataRoutine,
  getSavedPlaidAccounts as getSavedPlaidAccountsRoutine,
  getUserVerificationStatuses as getUserVerificationStatusesRoutine,
} from 'spa/actions/UserActions';
import { transactionOrDraftSelector } from 'spa/selectors/TransactionSelectors';
import APIConstants from 'spa/constants/APIConstants';
import {
  custIdSelector,
  partnerLogosSelector,
  customerKyc2ApprovedSelector,
  custEmailSelector,
} from 'spa/selectors/CustomerSelectors';
import { extractData, removeEmpty } from '../../utils/DataMapping';
import API from '../../api';
import AppDispatcher from '../../dispatcher/AppDispatcher';
import FluxUserConstants from '../../constants/UserConstants';
import UserConstants from '../constants/UserConstants';
import Store from '../../store';
import { gettext } from '../../utils/filters';
import { getCountryDocument } from '../../utils/IdentificationDocuments';
import ErrorMessages from '../../constants/ErrorMessages';

AppDispatcher.register((payload) => {
  const action = payload.action;
  switch (action.actionType) {
    case FluxUserConstants.CUSTOMER_DATA_FETCH_TRIGGER: {
      Store.dispatch(fluxGetCustomerRequest());
      break;
    }
    case FluxUserConstants.CUSTOMER_DATA_FETCH: {
      Store.dispatch(setUserDetailsFromV2(action.customerID, action.data));
      Store.dispatch(setExtendedKycRequirementRoutine.trigger());
      Store.dispatch(getV4CustomerRoutine.trigger({ custId: action.customerID }));
      Store.dispatch(fluxGetCustomerRequest.success());
      break;
    }
    case FluxUserConstants.CUSTOMER_DATA_FETCH_FAILURE: {
      Store.dispatch(fluxGetCustomerRequest.failure());
      break;
    }
    default:
      break;
  }
});

export function* validateVatNumber(number) {
  try {
    yield call(API.validateTaxNumber, 'vat', number);
  } catch (error) {
    let vatErrors;
    if (error.type === APIConstants.UNPROCESSABLE_ENTITY) {
      vatErrors = removeEmpty(
        extractData(UserConstants.RESPONSE_SCHEMAS.ADD_TAX_NUMBER, error.errors)
      );
    }
    if (isEmpty(vatErrors)) {
      vatErrors = {
        vatNumber: 'VAT number is invalid',
      };
    }
    throw vatErrors;
  }
}

export function* accountInfoPageAddVatNumber(action) {
  yield put(accountInfoPageAddVatNumberRoutine.request());

  const formData = action.payload.values;
  const apiRequest = {
    taxType: 'vat',
    taxNumber: `${formData.vatCountry}${formData.vatNumber}`,
  };

  yield put(setCustomerTaxNumberRoutine.trigger(apiRequest));
  const { setCustomerTaxSuccess, setCustomerTaxFailure } = yield race({
    setCustomerTaxSuccess: take(setCustomerTaxNumberRoutine.SUCCESS),
    setCustomerTaxFailure: take(setCustomerTaxNumberRoutine.FAILURE),
  });

  if (setCustomerTaxSuccess) {
    yield put(accountInfoPageAddVatNumberRoutine.success());
  }

  if (setCustomerTaxFailure) {
    const reduxFormError = { vatNumber: setCustomerTaxFailure.payload };
    yield put(accountInfoPageAddVatNumberRoutine.failure(new SubmissionError(reduxFormError)));
  }

  yield put(accountInfoPageAddVatNumberRoutine.fulfill());
}

/* The function used to update a tax number (e.g. 'vat') for a customer
 *
 * @param action.taxType oneOf('vat', 'gst')
 * @param action.taxNumber string the specific tax number
 *
 * @return null on success
 * @return error message on failure
 */
export function* setCustomerTaxNumber(action) {
  yield put(setCustomerTaxNumberRoutine.request(action.payload));

  const { taxNumber, taxType } = action.payload;
  let updatedTaxNumber = null;
  try {
    updatedTaxNumber = yield call(API.postTaxNumber, taxType, taxNumber);
  } catch (error) {
    let errorMessage = APIConstants.GENERIC_API_ERROR_MESSAGE;
    if (error.type === APIConstants.BAD_REQUEST) {
      errorMessage = gettext('The provided tax number has been already registered.');
    } else if (error.type === APIConstants.UNPROCESSABLE_ENTITY) {
      errorMessage = gettext('Tax number is invalid.');
    }

    if (error.requestId) {
      errorMessage += ` To help us assist you, please quote reference id: ${error.requestId}`;
    }
    yield put(setCustomerTaxNumberRoutine.failure(errorMessage));
    return;
  }

  if (updatedTaxNumber) {
    yield put(setUserDetailsFromV4({ tax_numbers: [updatedTaxNumber] }));
    yield put(setCustomerTaxNumberRoutine.success());
  }

  yield put(setCustomerTaxNumberRoutine.fulfill());
}

/* The function is an API saga used to upload a personal/company verification information
 *
 * @param action.payload { Files: [<file_id>], TextInputs: [{ Key: <IDType>, Value: <ProofType>}] }
 *
 * @return null on success
 * @return error message on failure
 */
export function* verifyCustomerInformation(action) {
  yield put(verifyCustomerInformationRoutine.request());
  const apiBody = action.payload;
  try {
    yield call(API.submitUserKyc, apiBody);
  } catch (error) {
    let errorMessage = ErrorMessages.VERIFY_KYC_FAIL;
    if (error.type === APIConstants.UNAUTHORIZED) {
      errorMessage = ErrorMessages.SESSION_EXPIRY;
    }
    yield put(verifyCustomerInformationRoutine.failure(errorMessage));
    return;
  }
  yield put(verifyCustomerInformationRoutine.success());
}

export function* electronicallyVerifyCustomer(action) {
  yield put(electronicallyVerifyCustomerRoutine.request());
  const apiBody = action.payload;
  const customerId = yield select(custIdSelector);
  try {
    yield call(API.submitUserEKyc, customerId, apiBody);
  } catch (error) {
    let errorMessage = APIConstants.VERIFY_KYC_FAIL;
    if (error.type === APIConstants.UNAUTHORIZED) {
      errorMessage = ErrorMessages.SESSION_EXPIRY;
    } else if (error.type === APIConstants.INTERNAL_SERVER_ERROR) {
      errorMessage = error.errors.error;
    } else if (error.type === APIConstants.FORBIDDEN) {
      errorMessage = error.errors;
    } else if (error.type === APIConstants.BAD_REQUEST) {
      if (error.errors.attempts_left > 0) {
        errorMessage = ErrorMessages.VERIFY_EKYC_FAIL.ATTEMPTS_LEFT(error.errors.attempts_left);
      } else {
        errorMessage = ErrorMessages.VERIFY_EKYC_FAIL.MAX_ATTEMPTS;
      }
      yield put(
        electronicallyVerifyCustomerRoutine.failure({
          rejected: true,
          message: errorMessage,
          attempts_left: error.errors.attempts_left,
        })
      );
      return;
    }
    yield put(electronicallyVerifyCustomerRoutine.failure(errorMessage));
    return;
  }
  yield put(electronicallyVerifyCustomerRoutine.success());
}

export function* setExtendedKycRequirement() {
  yield put(setExtendedKycRequirementRoutine.request());
  try {
    const customerId = yield select(custIdSelector);
    const response = yield call(API.getExtendedKycRequirement, customerId);
    yield put(setExtendedKycRequirementRoutine.success(response));
  } catch (error) {
    yield put(setExtendedKycRequirementRoutine.failure(error));
  }
}

export const processEKycSubmissionFields = (fields) => {
  if (fields && fields.length > 0) {
    const countryDoc = getCountryDocument(fields[0].country_code, fields[0].document_type);
    const countryDocFields = (countryDoc && countryDoc.fields) || [];

    const processedFields = fields.reduce((docFields, field) => {
      const docField = countryDocFields.find((f) => f.name === field.field_type);
      const displayName = (docField && docField.displayName) || field.field_type;
      const value =
        docField.type !== 'dropdown' && field.field_value.length > 4
          ? `${'*'.repeat(4)}${field.field_value.slice(-4)}`
          : field.field_value;
      return [
        ...docFields,
        {
          label: displayName,
          value,
        },
      ];
    }, []);
    return [{ label: 'Document', value: countryDoc.displayName }, ...processedFields];
  }
  return [];
};

export function* getUserEKYCData(action) {
  yield put(getUserEKYCDataRoutine.request());
  try {
    let custId = yield select(custIdSelector);
    if (!custId) {
      yield take(UserConstants.SET_USER_DETAILS_FROM_V2);
      custId = yield select(custIdSelector);
    }
    const tier2Approved = yield select(customerKyc2ApprovedSelector);
    if (action.payload && action.payload.getFields && tier2Approved) {
      const ekycFields = yield call(API.getUserApprovedEKycFields, custId);
      const processedFields = processEKycSubmissionFields(ekycFields);
      yield put(
        updateIndividualVerificationStatus({
          electronicVerification: {
            submittedFields: processedFields,
          },
        })
      );
    }
    const ekycEligibility = yield call(API.getUserEKYCEligibility, custId);
    if (ekycEligibility.checks && ekycEligibility.eligibility) {
      yield put(
        updateIndividualVerificationStatus({
          electronicVerification: {
            attempts: ekycEligibility.checks.attempts,
            eligibility: ekycEligibility.eligibility,
          },
        })
      );
    }
    yield put(getUserEKYCDataRoutine.success());
  } catch (error) {
    yield put(getUserEKYCDataRoutine.failure(error));
  }
}

export function* getUserVerificationStatuses() {
  yield put(getUserVerificationStatusesRoutine.request());
  try {
    let custId = yield select(custIdSelector);
    if (!custId) {
      yield take(UserConstants.SET_USER_DETAILS_FROM_V2);
      custId = yield select(custIdSelector);
    }
    const email = yield select(custEmailSelector);
    const { personal, company } = yield call(API.getCustomerVerificationStatus, email);
    yield put(
      updateIndividualVerificationStatus({ holdingPenRequired: personal.holding_pen_required })
    );
    yield put(
      updateCompanyVerificationStatus({ holdingPenRequired: company.holding_pen_required })
    );
    yield put(getUserVerificationStatusesRoutine.success());
  } catch (error) {
    yield put(getUserVerificationStatusesRoutine.failure(error));
  }
}

export function* v4GetCustomer(action) {
  const custId = action.payload.custId;
  yield put(getV4CustomerRoutine.request(action.payload));

  try {
    const customerData = yield call(API.getV4Customer, custId);
    yield put(setUserDetailsFromV4(customerData));
    yield put(getV4CustomerRoutine.success());
  } catch (error) {
    yield put(getV4CustomerRoutine.failure(error));
  }
}

export function* v4DeleteTaxNumber(action) {
  try {
    const taxNumberId = action.payload.taxNumberId;

    yield put(deleteTaxNumberRoutine.request());
    yield call(API.deleteTaxNumber, taxNumberId);

    yield put(deleteTaxNumberRoutine.success({ taxNumberId }));
  } catch (error) {
    yield put(deleteTaxNumberRoutine.failure(error));
  }
}

export function* submitPartnerLogo(action) {
  yield put(submitPartnerLogoRoutine.request());

  try {
    const customerId = yield select(custIdSelector);
    const formData = action.payload.values;
    const submissionIds = formData.logoFiles.map((l) => l.fileId);
    yield call(API.submitPartnerLogos, customerId, submissionIds);
    yield put(listPartnerLogosRoutine());
    yield put(reset(UserConstants.PARTNER_LOGO_FORM));
    yield put(submitPartnerLogoRoutine.success());
  } catch (error) {
    const reduxFormError = { _error: APIConstants.GENERIC_API_ERROR_MESSAGE };
    if (error.type === APIConstants.NOT_FOUND) {
      reduxFormError._error = gettext('Failed to submit logos because files are not uploaded');
    }
    yield put(submitPartnerLogoRoutine.failure(new SubmissionError(reduxFormError)));
  }

  yield put(submitPartnerLogoRoutine.fulfill());
}

export function* listPartnerLogos() {
  yield put(listPartnerLogosRoutine.request());

  try {
    const customerId = yield select(custIdSelector);
    const response = yield call(API.listPartnerLogos, customerId);
    yield put(listPartnerLogosRoutine.success(response));
  } catch (error) {
    const reduxFormError = { _error: APIConstants.GENERIC_API_ERROR_MESSAGE };
    yield put(listPartnerLogosRoutine.failure(new SubmissionError(reduxFormError)));
  }

  yield put(listPartnerLogosRoutine.fulfill());
}

export function* removePartnerLogo() {
  yield put(removePartnerLogoRoutine.request());

  try {
    const customerId = yield select(custIdSelector);
    const submissions = yield select(partnerLogosSelector);

    if (submissions && submissions.length > 0) {
      yield call(API.removePartnerLogo, customerId, submissions[0].id);
      yield put(listPartnerLogosRoutine());
    }
    yield put(removePartnerLogoRoutine.success());
  } catch (error) {
    const reduxFormError = { _error: APIConstants.GENERIC_API_ERROR_MESSAGE };
    if (error.type === APIConstants.NOT_FOUND) {
      reduxFormError._error = gettext('Failed to remove logo because it does not exist');
    }
    yield put(removePartnerLogoRoutine.failure(new SubmissionError(reduxFormError)));
  }

  yield put(removePartnerLogoRoutine.fulfill());
}

export function* getSavedPlaidAccounts() {
  try {
    const plaidAccounts = yield call(API.getSavedPlaidAccounts);
    yield put(getSavedPlaidAccountsRoutine.success({ savedPlaidAccounts: plaidAccounts }));
  } catch (error) {
    yield put(getSavedPlaidAccountsRoutine.failure());
  }
}

export function* getSavedCreditCards(action) {
  try {
    let currency;
    let customerId;
    if (action && action.payload) {
      currency = action.payload.currency;
      customerId = action.payload.customerId;
    } else {
      const transaction = yield select(transactionOrDraftSelector);
      currency = transaction.currency;
      customerId = yield select(custIdSelector);
    }
    const savedCardsData = yield call(
      API.getCustSavedCreditCards,
      customerId,
      currency,
      true,
      true
    );
    yield put(getSavedCreditCardsRoutine.success({ savedCreditCards: savedCardsData }));
  } catch (error) {
    yield put(getSavedCreditCardsRoutine.failure());
  }
}

export function* watcher() {
  yield takeLatest(accountInfoPageAddVatNumberRoutine.TRIGGER, accountInfoPageAddVatNumber);
  yield takeLatest(setCustomerTaxNumberRoutine.TRIGGER, setCustomerTaxNumber);
  yield takeLatest(getV4CustomerRoutine.TRIGGER, v4GetCustomer);
  yield takeLatest(deleteTaxNumberRoutine.TRIGGER, v4DeleteTaxNumber);
  yield takeLatest(submitPartnerLogoRoutine.TRIGGER, submitPartnerLogo);
  yield takeLatest(listPartnerLogosRoutine.TRIGGER, listPartnerLogos);
  yield takeLatest(removePartnerLogoRoutine.TRIGGER, removePartnerLogo);
  yield takeLatest(verifyCustomerInformationRoutine.TRIGGER, verifyCustomerInformation);
  yield takeLatest(electronicallyVerifyCustomerRoutine.TRIGGER, electronicallyVerifyCustomer);
  yield takeLatest(setExtendedKycRequirementRoutine.TRIGGER, setExtendedKycRequirement);
  yield takeLatest(getSavedCreditCardsRoutine.TRIGGER, getSavedCreditCards);
  yield takeLatest(getUserEKYCDataRoutine.TRIGGER, getUserEKYCData);
  yield takeLatest(getSavedPlaidAccountsRoutine.TRIGGER, getSavedPlaidAccounts);
  yield takeLatest(getUserVerificationStatusesRoutine.TRIGGER, getUserVerificationStatuses);
}

export default [watcher];
