import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'escrow-common-js/dist/components';
import { FormError } from 'spa/components/form';
import { PersonalDetails, CompanyDetails } from 'spa/components/ContactDetails';
import { gettext } from '../../../utils/filters';

const CustomerDetailsDisplay = (props) => (
  <div className="checkout-form checkout-card-content--light">
    <div className="checkout-group">
      <PersonalDetails
        label={gettext('Personal')}
        firstName={props.firstName}
        middleName={props.middleName}
        lastName={props.lastName}
        custEmail={props.custEmail}
        phoneNumber={props.phoneNumber}
        dateOfBirth={props.dateOfBirth}
        personalAddress={props.personalAddress}
      />
      {props.isCompany && (
        <CompanyDetails
          label={gettext('Company')}
          isCompany={props.isCompany}
          firstName={props.firstName}
          middleName={props.middleName}
          lastName={props.lastName}
          companyName={props.companyName}
          companyAddress={props.companyAddress}
        />
      )}
      <div className="checkout-actions checkout-actions--accountDetails">
        {!props.ui.showCustomerDetails.error && (
          <Fragment>
            <div />
            <div className="verifyPage-actions-subgroup">
              <div>
                <Button
                  data-e2e-target="checkout-edit-customerDetails-link"
                  onClick={props.editDetails}
                  data-tracking-name="edit"
                  className="checkout-card-btn btn btn--secondary btn--hollow"
                >
                  {gettext('Edit Details')}
                </Button>
              </div>
              <div>
                <Button
                  data-e2e-target="checkout-custdetails-continue-btn"
                  onClick={props.nextStep}
                  data-tracking-name="continue"
                  className="checkout-card-btn"
                >
                  {gettext('Confirm')}
                </Button>
              </div>
            </div>
          </Fragment>
        )}
        {props.ui.showCustomerDetails.error && (
          <FormError error={props.ui.showCustomerDetails.errorMessage} />
        )}
      </div>
    </div>
  </div>
);

CustomerDetailsDisplay.propTypes = {
  isCompany: PropTypes.bool,
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  custEmail: PropTypes.string,
  phoneNumber: PropTypes.string,
  dateOfBirth: PropTypes.string,
  personalAddress: PropTypes.shape({
    line1: PropTypes.string,
    line2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    zip: PropTypes.string,
  }),
  companyName: PropTypes.string,
  companyAddress: PropTypes.shape({
    line1: PropTypes.string,
    line2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    zip: PropTypes.string,
  }),

  editDetails: PropTypes.func,
  nextStep: PropTypes.func,
};

export default CustomerDetailsDisplay;
