import React from 'react';
import InfoTooltip from './InfoTooltip';
import FieldError from './FieldError';

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.renderSelectOption = this.renderSelectOption.bind(this);
  }

  renderSelectOption(option) {
    return (
      <option key={option.value} value={option.value} disabled={option.disabled}>
        {option.label}
      </option>
    );
  }

  render() {
    const {
      label,
      input,
      options,
      meta: { error, touched, warning },
      requiredIndicator,
      tooltip,
      prefix,
      flag,
      className,
      children,
      ...attributes
    } = this.props;
    let dropdownClass = 'field';
    if (error && touched) {
      dropdownClass += ' is-invalid';
    }

    let dataTrackingValue = this.props['data-tracking-value'];
    if (!dataTrackingValue) {
      dataTrackingValue = input.value;
    }

    return (
      <div
        className={`${dropdownClass} ${className}`}
        key={input.name}
        data-tracking-value={input.value}
      >
        {label && (
          <label htmlFor={input.name} className="field-label">
            {label}
            {children}
            {requiredIndicator && <span className="field-required">*</span>}
            {tooltip && <InfoTooltip id={`${name}-field-input-tooltip`} message={tooltip} />}
          </label>
        )}
        <div className="field-input">
          {prefix && <div className="field-prefix">{prefix}</div>}
          <div className={`defaultSelect defaultSelect--form ${flag && 'defaultSelect--flags'}`}>
            {flag && <span className="defaultSelect-flag" data-select-value={flag} />}
            <select id={input.name} className="defaultSelect-select" {...input} {...attributes}>
              {this.props.placeholder && (
                <option value="" disabled>
                  {this.props.placeholder}
                </option>
              )}
              {options.map(this.renderSelectOption)}
            </select>
          </div>
        </div>
        {error && touched && <FieldError message={error} />}
        {warning && touched && <FieldError message={warning} />}
      </div>
    );
  }
}

export default Select;
