import React from 'react';
import { connect } from 'react-redux';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import QueryString from 'query-string';
import { setPaymentType } from 'spa/actions/CheckoutActions';
import { checkoutPaymentTypeSelector } from 'spa/selectors/CheckoutSelectors';
import CheckoutConstants from 'spa/constants/CheckoutConstants';
import PaymentConstants from 'spa/constants/PaymentConstants';

import { checkoutTransIdSelector } from 'spa/selectors/CheckoutSelectors';
import {
  transactionByIdSelector,
  transactionDraftSelector,
} from 'spa/selectors/TransactionSelectors';

import PaymentMethodSelectionContainer from './PaymentMethodSelectionContainer';
import PaymentDetailContainer from './PaymentDetailContainer';

class PaymentStepContainer extends React.Component {
  constructor(props) {
    super(props);
    let initPage = 0;
    let initPageName = 'payment_selection';
    const redirectFromPaymentPortal = QueryString.parse(window.location.search).continue;

    switch (redirectFromPaymentPortal) {
      case PaymentConstants.PAYMENT_METHODS.DIRECT_DEBIT:
      case PaymentConstants.PAYMENT_METHODS.CREDIT_CARD:
        initPage = 1;
        initPageName = 'account_selection';
        break;
      default:
        break;
    }
    this.state = {
      page: initPage,
      pageName: initPageName,
    };
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this._setPage = this._setPage.bind(this);
    this._renderPage = this._renderPage.bind(this);
  }
  getFlow() {
    const { paymentType } = this.props;
    switch (paymentType) {
      case PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER:
        return CheckoutConstants.PAYMENT_WIRE_FLOW_PAGES;
      case PaymentConstants.PAYMENT_METHODS.CREDIT_CARD:
        return CheckoutConstants.PAYMENT_CC_FLOW_PAGES;
      case PaymentConstants.PAYMENT_METHODS.PAYPAL:
        return CheckoutConstants.PAYMENT_PAYPAL_FLOW_PAGES;
      case PaymentConstants.PAYMENT_METHODS.DIRECT_DEBIT:
        return CheckoutConstants.PAYMENT_DIRECT_DEBIT_FLOW_PAGES;
      default:
        return null;
    }
  }
  _setPage(flow, page) {
    if (page >= flow.length) {
      this.props.nextStep();
    }
    const pageName = flow[page];
    if (!pageName) {
      return;
    }
    const pageCount = flow.length;
    const percentProgress = page / pageCount;
    this.setState({ page: page, pageName: pageName }, () =>
      this.props.setStepPercentage(percentProgress)
    );
  }

  prevPage() {
    if (this.state.page === 0) {
      return;
    }
    const flow = this.getFlow();
    if (flow) {
      this._setPage(flow, this.state.page - 1);
    }
  }

  nextPage() {
    const flow = this.getFlow();
    if (flow) {
      this._setPage(flow, this.state.page + 1);
    }
  }

  _renderPage() {
    switch (this.state.pageName) {
      default:
      case 'payment_selection':
        return (
          <PaymentMethodSelectionContainer
            nextPage={this.nextPage}
            initialValues={{ paymentType: this.props.paymentType }}
          />
        );
      case 'account_selection':
        return (
          <PaymentDetailContainer
            nextPage={this.nextPage}
            prevPage={this.prevPage}
            initialValues={{ paymentType: this.props.paymentType }}
          />
        );
    }
  }

  render() {
    return (
      <SwitchTransition>
        <CSSTransition classNames="checkout-step" key={this.state.pageName}>
          <div
            className="checkout-page"
            data-tracking-section={`${this.props.dataTrackingPrefix}-step-payment`}
          >
            {this._renderPage()}
          </div>
        </CSSTransition>
      </SwitchTransition>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const transId = checkoutTransIdSelector(state);
  const draft = transactionDraftSelector(state);
  let transaction = draft;
  if (transId) {
    transaction = transactionByIdSelector(state, transId);
  }
  return {
    ...ownProps,
    transaction,
    paymentType: checkoutPaymentTypeSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setPaymentType: (paymentType) => dispatch(setPaymentType(paymentType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentStepContainer);
