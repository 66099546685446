import React, { Fragment } from 'react';
import ReactSVG from 'react-svg';
import { bindRoutineToReduxForm } from 'redux-saga-routines';
import CheckoutConstants from 'spa/constants/CheckoutConstants';
import PaymentConstants from 'spa/constants/PaymentConstants';
import { reduxForm, Field, formValues } from 'redux-form';
import { Spinner } from 'spa/components/Indicators';
import A from 'spa/components/A';
import { Checkbox, FormErrorBox } from 'spa/components/form';
import Currency from 'spa/components/Currency';
import { agreePaymentReview as agreePaymentReviewRoutine } from 'spa/actions/CheckoutActions';
import CreditCardLogo from 'spa/components/CreditCardLogo';
import { getCurrencyTagFromApiCurrency } from 'escrow-common-js/dist/utils';
import { Button } from 'escrow-common-js/dist/components';
import { gettext } from '../../../../utils/filters';
import ErrorMessages from '../../../../constants/ErrorMessages';

const getCtaLabel = (paymentType) =>
  paymentType === PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER
    ? 'Agree & continue'
    : 'Agree & pay';

const getAgreePayTracking = (paymentType) => {
  const { WIRE_TRANSFER, PAYPAL, CREDIT_CARD, DIRECT_DEBIT } = PaymentConstants.PAYMENT_METHODS;
  switch (paymentType) {
    case WIRE_TRANSFER:
      return 'agree-pay-wire';
    case PAYPAL:
      return 'agree-pay-pp';
    case CREDIT_CARD:
      return 'agree-pay-cc';
    case DIRECT_DEBIT:
      return 'agree-pay-ach';
    default:
      return 'agree-pay';
  }
};

const BillingAddress = (completeAddress) => (
  <div>
    <h3 className="checkout-paymentReview-title">{gettext('Billing Address')}</h3>
    <p>{completeAddress}</p>
  </div>
);

class PaymentReviewPanel extends React.Component {
  constructor(props) {
    super(props);
    this._renderBillingAddress = this._renderBillingAddress.bind(this);
    this._renderPaymentDetails = this._renderPaymentDetails.bind(this);
    this.onBack = this.onBack.bind(this);
  }
  onBack() {
    this.props.prevPage();
  }
  _renderHeader(paymentType, error) {
    let header;
    let subHeader;
    switch (paymentType) {
      case PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER:
        header = 'Confirm Details';
        subHeader =
          'Review your transaction details before agreeing and viewing the wire instructions.';
        break;
      case PaymentConstants.PAYMENT_METHODS.CREDIT_CARD:
        header = 'Complete Payment';
        subHeader = 'Review your transaction details and proceed with your payment.';
      default:
        header = 'Complete Payment';
        subHeader = 'Review your transaction details and confirm the payment.';
    }

    return (
      <div>
        <h3 className="checkout-page-header">{gettext(header)}</h3>
        <p className="checkout-page-subheader">{gettext(subHeader)}</p>
        {error && <FormErrorBox error={error} />}
      </div>
    );
  }
  _renderAgreeCheckboxLabel(paymentType) {
    let agreeText;
    switch (paymentType) {
      case PaymentConstants.PAYMENT_METHODS.DIRECT_DEBIT:
        agreeText = 'I give authority to debit my account as well as agree to the Escrow.com ';
        break;
      default:
        agreeText = 'I agree to the Escrow.com ';
    }

    return (
      <div className="checkout-confirmation-legal">
        {gettext(agreeText)}
        <A
          link={{
            type: 'internal',
            route: '/legal',
            newTab: true,
          }}
        >
          {gettext('terms and conditions')}
        </A>
        {gettext(', ')}
        <A
          link={{
            type: 'internal',
            route: '/legal',
            newTab: true,
          }}
        >
          {gettext('privacy policy')}
        </A>
        {gettext(', and ')}
        <A
          link={{
            type: 'internal',
            route: '/legal',
            newTab: true,
          }}
        >
          {gettext('General Escrow Instructions.')}
        </A>
      </div>
    );
  }
  _renderPaymentDetails(paymentType) {
    const { paymentDetails } = this.props;
    let title;
    let detail = '';
    let logo;
    let logoCode;
    switch (paymentType) {
      case PaymentConstants.PAYMENT_METHODS.CREDIT_CARD:
        title = 'Credit Card';
        // if (paymentDetails) {
        //   switch (paymentDetails.brand) {
        //     case 'mc':
        //       logoCode = 'mastercard';
        //       break;
        //     case 'American Express':
        //       logoCode = 'amex';
        //       break;
        //     default:
        //       // logoCode = paymentDetails.brand.toLowerCase();
        //       logoCode ='';
        //   }
        //   // detail = `**** ${paymentDetails.last_four_digits}`;
        //   detail = '';
        //   // logo = <CreditCardLogo type={logoCode} />;
        //   logo = <ReactSVG src="../../../../build/images/payment-methods/card-v2.svg" />;
        //   break;
        // }
        logo = <ReactSVG src="../../../../build/images/payment-methods/card-v2.svg" />;
        break;
      case PaymentConstants.PAYMENT_METHODS.DIRECT_DEBIT:
        title = 'Bank Debit (ACH)';
        detail = paymentDetails.mask ? `**** ${paymentDetails.mask}` : '';
        logo = <ReactSVG src="../../../../build/images/payment-methods/ach.svg" />;
        break;
      case PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER:
        title = 'Wire Transfer';
        logo = <ReactSVG src="../../../../build/images/payment-methods/wire-v2.svg" />;
        break;
      case PaymentConstants.PAYMENT_METHODS.PAYPAL:
        title = 'PayPal';
        logo = <ReactSVG src="../../../../build/images/payment-methods/paypal.svg" />;
        break;
      default:
        title = 'Wire Transfer';
        logo = <ReactSVG src="../../../../build/images/payment-methods/wire-v2.svg" />;
        break;
    }
    return (
      <div className="checkout-paymentOption checkout-paymentOption--single">
        <div className="checkout-paymentOption-logo">{logo}</div>
        <div className="checkout-paymentOption-text">
          <span className="checkout-paymentOption-text-title">{title}</span>
          <p className="checkout-paymentOption-text-detail">{detail}</p>
        </div>
      </div>
    );
  }
  _renderPaymentFrequency(paymentType) {
    switch (paymentType) {
      case PaymentConstants.PAYMENT_METHODS.CREDIT_CARD:
        return (
          <div>
            <h3 className="checkout-paymentReview-title">{gettext('Payment Frequency')}</h3>
            <p>{gettext('Once Off')}</p>
          </div>
        );
      case PaymentConstants.PAYMENT_METHODS.DIRECT_DEBIT:
        return (
          <div>
            <h3 className="checkout-paymentReview-title">{gettext('Payment Frequency')}</h3>
            <p>{gettext('Once Off')}</p>
          </div>
        );
      default:
        return <div />;
    }
  }
  _renderBillingAddress(paymentType) {
    const { transactionCCPaymentGateway, custDetails } = this.props;
    const completeAddress = ['line1', 'line2', 'city', 'state', 'zip', 'country']
      .map((field) => {
        if (custDetails.isCompany) {
          return custDetails.companyAddress[field];
        }
        return custDetails.personalAddress[field];
      })
      .filter((add) => !!add)
      .join(', ');

    switch (paymentType) {
      case PaymentConstants.PAYMENT_METHODS.CREDIT_CARD:
        if (transactionCCPaymentGateway === PaymentConstants.CREDIT_CARD_GATEWAY.ADYEN) {
          return;
        }
        return BillingAddress(completeAddress);
      default:
        return BillingAddress(completeAddress);
    }
  }
  _renderLoading(paymentType) {
    switch (paymentType) {
      case PaymentConstants.PAYMENT_METHODS.DIRECT_DEBIT:
        return (
          <div className="checkout-paymentLoading-container">
            <img
              className="checkout-paymentLoading-spinner"
              alt="Submitting ACH payment"
              src="../../../../../build/images/escrow-pay/ach_payment_loading.gif"
            />
          </div>
        );
      default:
        return this._render();
    }
  }
  _render() {
    const {
      paymentType,
      total,
      transaction: { currency },
      error,
      submitFailed,
      submitting,
    } = this.props;
    const disabled =
      !this.props.readAndAgreed ||
      submitting ||
      error === ErrorMessages.DIRECT_DEBIT_INSUFFICIENT_BALANCE;
    return (
      <Fragment>
        {this._renderHeader(paymentType, error)}
        <div className="checkout-paymentReview">
          <h3 className="checkout-paymentReview-title">{gettext('Payment Method')}</h3>
          <p>{this._renderPaymentDetails(paymentType)}</p>
          {this._renderBillingAddress(paymentType)}
          {this._renderPaymentFrequency(paymentType)}
          <h3 className="checkout-paymentReview-title">{gettext('Payment Amount')}</h3>
          <p>
            <Currency
              code={getCurrencyTagFromApiCurrency(currency)}
              amount={parseFloat(total)}
              withTag
            />
          </p>
        </div>
        <hr />
        <div className="checkout-page-checkbox">
          <Field
            name="readAndAgreed"
            component={Checkbox}
            label={this._renderAgreeCheckboxLabel(paymentType)}
            data-e2e-target="payment-review-authorization"
          />
        </div>{' '}
        <div className="checkout-page-form-actions">
          <a
            tabIndex={0}
            role="button"
            className={`checkout-page-form-action checkout-page-form-action--secondary ${
              submitFailed || submitting ? `is-disabled` : ``
            }`}
            onClick={() => {
              if (!submitFailed && !submitting) this.onBack();
            }}
            data-e2e-target="checkout-form-action"
            data-tracking-name="back"
          >
            {gettext('Back')}
          </a>
          {submitFailed && (
            <a
              tabIndex={0}
              role="button"
              className="checkout-page-form-action checkout-page-form-action--secondary"
              onClick={this.onBack}
              data-e2e-target="checkout-form-action"
              data-tracking-name="change-payment-method"
            >
              {gettext('Change payment method')}
            </a>
          )}
          <Button
            type="submit"
            className="checkout-page-form-action"
            disabled={disabled}
            data-e2e-target="checkout-form-action"
            data-tracking-name={getAgreePayTracking(paymentType)}
          >
            {submitting ? <Spinner /> : gettext(getCtaLabel(paymentType))}
          </Button>
        </div>
      </Fragment>
    );
  }

  render() {
    return (
      <form
        name={this.props.form}
        onSubmit={this.props.handleSubmit(bindRoutineToReduxForm(agreePaymentReviewRoutine))}
        data-tracking-subsection="payment-review"
      >
        <div className="checkout-confirmation-container">
          {this.props.submitting ? this._renderLoading(this.props.paymentType) : this._render()}
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: CheckoutConstants.PAYMENT_REVIEW_FORM,
  enableReinitialize: false,
  destroyOnUnmount: true,
  onSubmitSuccess: (result, dispatch, props) => props.nextPage(props.paymentType),
})(formValues('readAndAgreed')(PaymentReviewPanel));
