import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Code } from 'spa/components/Code';
import PrimaryLink from 'spa/components/PrimaryLink';
import { Section, SectionTitle, SectionDesc, SectionSubTitle } from 'spa/components/Section';
import HintBanner from 'spa/components/HintBanner';

import { urlFor } from '../../../routeConfig';

const VerifyCustomer = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktopSmall-9">
        <h1 className="integrationsPortal-title">API Guide :: Verifying a customer</h1>
        <Section>
          <SectionTitle>
            Checking verification eligibility for a customer on Escrow.com
          </SectionTitle>
          <SectionDesc>
            Verifying a customer on Escrow.com is quite straight forward. As long as a customer has
            been created, they can undergo our automatic verification process. The first step is to
            check if they are eligible for automatic verification.
          </SectionDesc>
          <HintBanner
            className="integrationsPortal-hintBanner"
            title="Note"
            desc="While you are welcome to skip checking for eligibility, it is highly recommended
            to do so first to not encounter any errors in the latter steps."
            icon="ui-alert-circle"
          />
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.escrow.com/2017-09-01/customer/<int:customer_id>/electronic_verification/eligibility"  \\
  -u email-address:api-key`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="python">
                {`import requests

requests.get(
  'https://api.escrow.com/2017-09-01/customer/<int:customer_id>/electronic_verification/eligibility',
  auth=('email-address', \
'api-key'),
)`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="ruby">
                {`require 'net/http'
require 'json'

uri = URI("https://api.escrow.com/2017-09-01/customer/<int:customer_id>/electronic_verification/eligibility")

http = Net::HTTP.new(uri.host, uri.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Get.new(uri.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address",\
"api-key")
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
  CURLOPT_URL => 'https://api.escrow.com/2017-09-01/customer/<int:customer_id>/electronic_verification/eligibility',
  CURLOPT_RETURNTRANSFER => 1,
  CURLOPT_USERPWD => 'email-address:api-key',
  CURLOPT_HTTPHEADER => array(
      'Content-Type: application/json'
  ),
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue(
                "application/json"));
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.escrow.com/2017-09-01/customer/<int:customer_id>/electronic_verification/eligibility"),
                Method = HttpMethod.Get,
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
          <SectionSubTitle>Example Response</SectionSubTitle>
          <Code language="json">
            {`{
    "checks": {
        "attempts": 0,
        "is_country_supported": true,
        "kyc_tier_allowed": true
    },
    "eligibility": true,
    "message": "User is eligible for electronic verification"
}`}
          </Code>
        </Section>
        <Section>
          <SectionTitle>Verifying a customer</SectionTitle>
          <SectionDesc>
            As long as a customer is eligible, the customer can undergo our automatic verification
            process. They can do this by submitting the correct documents available for their
            country of origin. Each document per country and the fields we require are listed below:
          </SectionDesc>

          <SectionTitle>Document Configuration</SectionTitle>
          <SectionSubTitle>Australia</SectionSubTitle>
          <strong>drivers-licence</strong>
          <br />
          <Code language="shell">
            {`state: (AlphaCode2)
number: (Driver\'s License Number)`}
          </Code>
          <br />

          <strong>medicare</strong>
          <br />
          <Code language="shell">
            {`number: (Medicare Number)
reference: (Reference Number)
color: (Green/Blue/Yellow)
month: (Text, Expiry Month, 01 to 12)
year`}
          </Code>
          <br />

          <strong>passport</strong>
          <Code language="shell">
            {`number: (Passport Number)
country: (AlphaCode2, Country of Issue)`}
          </Code>
          <br />
          <br />

          <SectionSubTitle>United States of America</SectionSubTitle>
          <strong>social-security-number</strong>
          <Code language="shell">{`number: (Last for digits of SSN)`}</Code>
          <br />
          <br />

          <SectionSubTitle>China</SectionSubTitle>
          <strong>chinese-citizen-id</strong>
          <Code language="shell">{`number: (18 Digit ID Number)`}</Code>
          <br />
          <br />

          <SectionSubTitle>Italy</SectionSubTitle>
          <strong>codice-fiscal</strong>
          <Code language="shell">
            {`number: (Italian Tax Code Number)
city: (AlphaCode2, City of Issue)
province: (AlphaCode2, Province of Issue)`}
          </Code>
          <br />
          <br />

          <SectionSubTitle>Sweden</SectionSubTitle>
          <strong>pin</strong>
          <Code language="shell">{`number: (10 or 12 digit Personnummer)`}</Code>
          <br />
          <br />

          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="shell">
                {`curl "https://api.escrow.com/2017-09-01/customer" \\
    -X POST \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
    -d '
    {
      "document":"passport",
      "fields":[
        {"name":"country","value":"AU"},
        {"name":"number","value":"D4567845"}
      ]
    }'
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.post(
  'https://api.escrow.com/2017-09-01/customer/<int:customer_id>/electronic_verification',
  auth=('email-address', 'api-key'),
  json={
    "document":"passport",
    "fields":[
      {"name":"country","value":"AU"},
      {"name":"number","value":"D4567845"}
    ]
  }
)
`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.escrow.com/2017-09-01/customer/<int:customer_id>/electronic_verification")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Post.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
  request.body = {
    "document": "passport",
    "fields": [
      {"name":"country","value":"AU"},
      {"name":"number","value":"D4567845"}
    ]
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>

            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.escrow.com/2017-09-01/customer/<int:customer_id>/electronic_verification',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:api-key',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'document':'passport',
            'fields': array([
              array('name':'country','value':'AU'),
              array('name':'number','value':'D4567845')
            ])
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:your-password");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
              document = passport,
              fields = [
                new {name = country, value = AU},
                new {name = number, value = D4567845}
              ]
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.escrow.com/2017-09-01/customer/<int:customer_id>/electronic_verification"),
                Method = HttpMethod.Post,
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
        </Section>
        <footer className="integrationsPortal-footer">
          <h3 className="integrationsPortal-footer-title">{'Next steps'}</h3>
          <nav className="integrationsPortal-footer-steps">
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Create a transaction"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_create_transaction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Full API reference Document"
                link={{
                  type: 'external',
                  route: window.config.api_docs_page,
                }}
              />
            </div>
          </nav>
        </footer>
      </div>
    </div>
  </div>
);

export default VerifyCustomer;
