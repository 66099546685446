/* eslint-disable prettier/prettier */
import React, { useRef } from 'react';
import classnames from 'classnames';
import validator from 'validator';

import InfoTooltip from './InfoTooltip';
import FieldError from './FieldError';
import { gettext } from '../../../utils/filters';
import Icon from '../Icon';

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = null;
  }

  render() {
    const {
      input,
      meta: { error, warning, touched },
      label,
      hideNonEmptyErrors,
      requiredIndicator,
      tooltip,
      className,
      prefix,
      suffix,
      ...attributes
    } = this.props;

    const { name } = input;
    const showError = error && touched && (error === gettext('Required') || !hideNonEmptyErrors);
    const showWarning =
      warning && touched && (warning === gettext('Required') || !hideNonEmptyErrors);
    return (
      <div
        className={classnames(className, {
          field: true,
          'is-invalid': error && touched,
          'field--suffix': Boolean(suffix),
        })}
      >
        {label && (
          <label htmlFor={name} className="field-label">
            {label}
            {requiredIndicator && <span className="field-required">*</span>}
            {tooltip && <InfoTooltip id={`${name}-field-input-tooltip`} message={tooltip} />}
          </label>
        )}
        <div className="field-input">
          {prefix && <div className="field-prefix">{prefix}</div>}
          <input
            {...input}
            id={name}
            className="defaultInput"
            {...attributes}
            ref={(node) => {
              this.inputRef = node;
            }}
          />
          {suffix && <div className="field-prefix">{suffix}</div>}
        </div>
        {showError && <FieldError message={error} />}
        {showWarning && <FieldError message={warning} />}
      </div>
    );
  }
}

const AlternativeInput = (props) => {
  const {
    input,
    meta: { error, warning, touched },
    label,
    hideNonEmptyErrors,
    requiredIndicator,
    tooltip,
    className,
    prefix,
    suffix,
    showErrorIcon,
    ...attributes
  } = props;

  const { name } = input;
  const inputRef = useRef();

  const showError = error && touched && (error === gettext('Required') || !hideNonEmptyErrors);
  const showWarning =
    warning && touched && (warning === gettext('Required') || !hideNonEmptyErrors);

  return (
    <div className={className}>
      <div
        className={classnames('customInput', 'field', {
          'is-invalid': error && touched,
          'is-valid': (!error && touched) || attributes.disabled,
        })}
      >
        {prefix}
        <input
          {...input}
          {...attributes}
          id={`field-${name}`}
          ref={inputRef}
          className="customInput-field"
        />
        {label && (
          <label className="customInput-label" htmlFor={`field-${name}`}>
            {label}
            {requiredIndicator && <span className="field-required">*</span>}
            {tooltip && <InfoTooltip id={`${name}-field-input-tooltip`} message={tooltip} />}
          </label>
        )}
        {suffix}
        {showError && <FieldError message={error} showIcon={showErrorIcon} />}
        {showWarning && <FieldError message={warning} showIcon={showErrorIcon} />}
      </div>
    </div>
  );
};

const UsernameInput = ({ label, register, required, errors, isDirty }) => (
  <div
    className={classnames('field', {
      'is-invalid': errors[label] && isDirty,
      'is-valid': !errors[label] && isDirty,
    })}
  >
    <label htmlFor="username" className="field-label">
      Please enter your email address
      <span className="field-required">*</span>
    </label>
    <div className="field-input">
      <div className="field-prefix">
        <Icon name="ui-email-alt" className="icon icon--email" />
      </div>
      <input
        {...register(label, {
          required: {
            value: required,
            message: 'Required',
          },
          validate: {
            email: (value) => validator.isEmail(value) || 'Please enter a valid email address',
          },
        })}
        className="defaultInput"
      />
    </div>
    {errors && errors[label] && <FieldError message={errors[label].message} />}
  </div>
);

const PasswordInput = ({
  copy,
  label,
  register,
  required,
  errors,
  passwordValidation,
  getValues,
  matchId,
  isDirty,
}) => (
  <div
    className={classnames('field', {
      'is-invalid': errors[label] && isDirty,
      'is-valid': !errors[label] && isDirty,
    })}
  >
    <label htmlFor="password" className="field-label">
      {copy}
      {required ? <span className="field-required">*</span> : null}
    </label>
    <div className="field-input">
      <div className="field-prefix">
        <Icon name="ui-secure" className="icon icon--secure" />
      </div>
      {passwordValidation ? (
        <input
          {...register(label, {
            required: {
              value: required,
              message: 'Required',
            },
            validate: {
              length: (value) => value.length >= 7 || 'Password must be at least 7 characters',
              upperCase: (value) =>
                value.match(/(?=.*[A-Z])\w+/) !== null ||
                'Password must have at least one UPPER case character',
              lowerCase: (value) =>
                value.match(/(?=.*[a-z])\w+/) !== null ||
                'Password must have at least one lower case character',
              numberOrSpecial: (value) =>
                value.match(/(?=.*[0-9`\-=;\[\]',\./~!@#\$%\^&\*\(\)_\+:\{\}\|"<>\?]).+/) !==
                  null || 'Password must have at least one number or special character',
            },
          })}
          type="password"
          className="defaultInput"
        />
      ) : (
        <input
          {...register(label, {
            required: {
              value: required,
              message: 'Required',
            },
            validate: {
              match: (value) => value === getValues(matchId) || 'Passwords must match',
            },
          })}
          type="password"
          className="defaultInput"
        />
      )}
    </div>
    {errors && errors[label] && <FieldError message={errors[label].message} />}
  </div>
);

export default Input;
export { AlternativeInput, UsernameInput, PasswordInput };
