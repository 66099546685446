import React from 'react';
import InfoTooltip from './InfoTooltip';
import FieldError from './FieldError';

const Checkbox = (props) => {
  const { label, tooltip, input, meta, disabled, children } = props;
  const { name } = input;
  const { error, touched } = meta;

  let checkboxClass = 'field';
  if (error && touched) {
    checkboxClass += ' is-invalid';
  }
  return (
    <div className={checkboxClass}>
      <div className="customCheckbox">
        <input
          type="checkbox"
          id={name}
          {...input}
          checked={input.value}
          disabled={Boolean(disabled)}
          className="customCheckbox-input"
          data-e2e-target={props['data-e2e-target']}
        />
        <label htmlFor={name} className="customCheckbox-label">
          <span className="customCheckbox-label-inner">
            <span>
              {label}
              {children}
            </span>
            {tooltip && <InfoTooltip id={`${name}-field-input-tooltip`} message={tooltip} />}
          </span>
        </label>
        {error && touched && <FieldError message={error} />}
      </div>
    </div>
  );
};

export default Checkbox;
