import React from 'react';

import { FormError } from 'spa/components/form';
import TransactionSummaryContainer from 'spa/containers/TransactionSummary/TransactionSummaryContainer';
import { getPartiesByRole } from '../../../utils/V4Transaction';

const BillingSummary = ({
  isSubmitting,
  error,
  submitFailed,
  dataError,
  dataErrorMessage,
  transaction,
}) => {
  const viewerParty = getPartiesByRole(transaction, 'buyer')[0];
  return (
    <section className="card card--stacked">
      <div className="card-inner">
        {dataError && dataErrorMessage ? (
          <FormError error={dataErrorMessage} />
        ) : (
          <div>
            <h2 className="card-heading">Billing Summary</h2>
            <TransactionSummaryContainer
              transaction={transaction}
              payBy="credit_card"
              viewerPartyEmail={viewerParty.customer}
              viewerPartyRole={viewerParty.role}
              hideTotal={false}
            />
            {submitFailed && error && <FormError error={error} />}
            <button
              type="submit"
              className="btn btn--block btn--secondary"
              data-e2e-target="credit-card-submit-btn"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting Payment...' : 'Submit Payment'}
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default BillingSummary;
