import React from 'react';
import { gettext } from '../../../../utils/filters';

import { FormErrorBox } from 'spa/components/form';
import { Spinner } from 'spa/components/Indicators';
import { SmallText } from 'spa/features/ui/EscrowTypography';

const StripeConfirmation = ({ transaction, confirmStripePayment, confirmStripePaymentStatus }) => {
    const { id } = transaction;
    const { loading, errorMessage } = confirmStripePaymentStatus;

    return (
      <div>
        {errorMessage && <FormErrorBox error={errorMessage} />}
        <div className="checkout-confirmation-header">
          <h2 className="checkout-confirmation-header-heading">
            You're almost there! Complete your payment for this transaction
          </h2>
          <p className="checkout-confirmation-header-subheading">
            The reference for this transaction is <SmallText sx={{ display: 'inline', fontSize: '16px', fontWeight: '600' }}>#{id}</SmallText>.<br/>
            You will be redirected to our payment page by clicking on the button below.
          </p>
        </div>
        <button
          className="checkout-card-btn checkout-card-btn--contained btn btn--secondary"
          onClick={() => confirmStripePayment()}
          disabled={loading}
        >
          {loading ? <Spinner /> : gettext("Proceed to payment")}
        </button>
      </div>
    );
};


export default StripeConfirmation;
