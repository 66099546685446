import React from 'react';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { bindRoutineToReduxForm } from 'redux-saga-routines';
import TwoFactorConstants from 'spa/constants/TwoFactorConstants';
import { Input, PhoneSelect, FormErrorBox, FormMessageBox } from 'spa/components/form';
import { Spinner } from 'spa/components/Indicators';
import { phone } from 'spa/components/form/validate';
import { normalizePhone } from 'spa/components/form/normalise';
import { enableSMSTwoFactor } from 'spa/actions/TwoFactorActions';
import { twoFactorSmsNotificationSelector } from 'spa/selectors/TwoFactorSelectors';
import PhoneCountryCodes from 'spa/constants/PhoneCountryCodes';
import { formatPhoneNumber } from '../../../utils/parse-phone';
import { gettext } from '../../../utils/filters';

class SendSMSCodeForm extends React.Component {
  constructor(props) {
    super(props);
    this._renderForm = this._renderForm.bind(this);
    this._renderSmsNotification = this._renderSmsNotification.bind(this);
  }

  _renderForm() {
    const { submitting, showNotification } = this.props;
    return (
      <div className="twoFactorAuth-page-sms-content">
        <div className="twoFactorAuth-page-sms-input">
          <div className="field">
            <label className="field-label">{gettext('SMS Notification')}</label>
            <div className="twoFactorAuth-page-sms-field">
              <div className="defaultForm-group">
                <Field
                  name="phoneCountry"
                  component={PhoneSelect}
                  className="field--phoneSelect"
                  placeholder={gettext('Country')}
                />
                <Field
                  name="phoneNumber"
                  component={Input}
                  normalize={normalizePhone}
                  placeholder={gettext('Phone Number')}
                />
              </div>
              <div className="twoFactorAuth-page-sms-field-btn">
                <button
                  className="btn btn--secondary btn--hollow twoFactorAuth-page-sms-btn"
                  type="submit"
                  disabled={submitting || showNotification}
                >
                  {submitting ? <Spinner /> : gettext('Send Code')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderSmsNotification() {
    const { formValues } = this.props;
    const phonePrefix = PhoneCountryCodes[formValues.phoneCountry].countryCode;
    const phoneSuffix = formValues.phoneNumber;
    const phoneNumber = formatPhoneNumber(phonePrefix, phoneSuffix);
    return (
      <FormMessageBox
        message={`${gettext(
          'A text message with the verification code has been sent to'
        )} ${phoneNumber}.`}
      />
    );
  }

  render() {
    const { form, handleSubmit, error, submitFailed, showNotification } = this.props;

    return (
      <form name={form} onSubmit={handleSubmit(bindRoutineToReduxForm(enableSMSTwoFactor))}>
        {submitFailed && error && <FormErrorBox error={error} />}
        {showNotification && this._renderSmsNotification()}
        {this._renderForm()}
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.phoneNumber) {
    errors.phoneNumber = 'Phone number cannot be empty';
  } else {
    errors.phoneNumber = phone('phoneCountry')(values.phoneNumber, values);
  }
  return errors;
};

const mapStateToProps = (state) => ({
  showNotification: twoFactorSmsNotificationSelector(state),
  formValues: getFormValues(TwoFactorConstants.SEND_SMS_FORM_NAME)(state),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: TwoFactorConstants.SEND_SMS_FORM_NAME,
    enableReinitialize: true,
    destroyOnUnmount: true,
    validate,
  })(SendSMSCodeForm)
);
