import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { kycFlowSelector, saveFlowSelection } from 'spa/features/kyc/kycSlice';
import { useTheme } from '@mui/material/styles';
import {
  PhoneIphone,
  Laptop,
  CameraAltOutlined,
  PhotoCameraFrontOutlined,
  AccessTimeOutlined,
  UploadFileOutlined,
  BadgeOutlined,
  SecurityOutlined,
} from '@mui/icons-material';
import { Card, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { V3KYCDesktopPages as Pages } from 'spa/constants/VerificationConstants';
import { SmallText } from 'spa/features/ui/EscrowTypography';
import ReminderIcon from 'spa/features/kyc/mobile/components/ReminderIcon';
import DesktopPage from '../components/DesktopPage';

const cardStyle = {
  padding: '2rem',
  cursor: 'pointer',
  borderRadius: '1rem',
  display: 'flex',
  flexDirection: 'column',
};

const imageStyle = {
  marginTop: '2rem',
  marginBottom: '2rem',
};

const SelectionPage = () => {
  const dispatch = useDispatch();

  const flowSelection = useSelector(kycFlowSelector);

  const theme = useTheme();

  const [nextPage, setNextPage] = useState(null);

  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    dispatch(saveFlowSelection({ flowSelection: data.flow }));
  };

  const handleSelectionChange = (event) => {
    const data = event.target.value;
    if (data === 'mobile') {
      setNextPage(Pages.MOBILE_TRANSFER);
    } else if (data === 'desktop') {
      setNextPage(Pages.PHONE_NUMBER);
    }
  };

  return (
    <DesktopPage
      title="How would you like to verify your account?"
      ctaText="Continue"
      onSubmit={handleSubmit(onSubmit)}
      nextPage={nextPage}
    >
      <form>
        <Controller
          name="flow"
          defaultValue={flowSelection}
          control={control}
          render={({ field: { onChange, value } }) => (
            <RadioGroup sx={{ alignItems: 'center' }} defaultValue={flowSelection} value={value}>
              <Stack direction="row" spacing={2}>
                <Card
                  variant="outlined"
                  style={{
                    ...cardStyle,
                    border:
                      value === 'mobile' ? `1px solid ${theme.palette.secondaryLight.dark}` : null,
                  }}
                  onClick={() => {
                    onChange('mobile');
                    handleSelectionChange({ target: { value: 'mobile' } });
                  }}
                >
                  <FormControlLabel
                    value="mobile"
                    label={
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <ReminderIcon
                          Icon={PhoneIphone}
                          variant={value === 'mobile' ? null : 'unselected'}
                        />
                        <SmallText
                          variant="bold"
                          sx={{
                            margin: '0px 10px',
                            color: value === 'mobile' ? theme.palette.secondaryLight.dark : null,
                          }}
                        >
                          Use my mobile device
                        </SmallText>
                      </div>
                    }
                    control={
                      <Radio
                        sx={{
                          '&.Mui-checked': {
                            color: theme.palette.secondaryLight.dark,
                          },
                        }}
                      />
                    }
                  />
                  <img
                    src="spa/../../build/images/kyc/select_mobile_flow.png"
                    alt="select mobile flow"
                    style={imageStyle}
                  />
                  <Stack direction="column" sx={{ gap: '8px' }}>
                    <Stack direction="row" sx={{ gap: '8px' }}>
                      <ReminderIcon
                        Icon={CameraAltOutlined}
                        variant={value === 'mobile' ? null : 'unselected'}
                      />
                      <SmallText>Use your camera to scan your ID</SmallText>
                    </Stack>
                    <Stack direction="row" sx={{ gap: '8px' }}>
                      <ReminderIcon
                        Icon={PhotoCameraFrontOutlined}
                        variant={value === 'mobile' ? null : 'unselected'}
                      />
                      <SmallText>Take a quick selfie to verify</SmallText>
                    </Stack>
                    <Stack direction="row" sx={{ gap: '8px' }}>
                      <ReminderIcon
                        Icon={AccessTimeOutlined}
                        variant={value === 'mobile' ? null : 'unselected'}
                      />
                      <SmallText>Quick and easy</SmallText>
                    </Stack>
                  </Stack>
                </Card>
                <Card
                  variant="outlined"
                  style={{
                    ...cardStyle,
                    border:
                      value === 'desktop' ? `1px solid ${theme.palette.secondaryLight.dark}` : null,
                  }}
                  onClick={() => {
                    onChange('desktop');
                    handleSelectionChange({ target: { value: 'desktop' } });
                  }}
                >
                  <FormControlLabel
                    value="desktop"
                    label={
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <ReminderIcon
                          Icon={Laptop}
                          variant={value === 'desktop' ? null : 'unselected'}
                        />
                        <SmallText
                          variant="bold"
                          sx={{
                            margin: '0px 10px',
                            color: value === 'desktop' ? theme.palette.secondaryLight.dark : null,
                          }}
                        >
                          On my computer
                        </SmallText>
                      </div>
                    }
                    control={
                      <Radio
                        sx={{
                          '&.Mui-checked': {
                            color: theme.palette.secondaryLight.dark,
                          },
                        }}
                      />
                    }
                  />
                  <img
                    src="spa/../../build/images/kyc/select_desktop_flow.png"
                    alt="select desktop flow"
                    style={imageStyle}
                  />
                  <Stack direction="column" sx={{ gap: '8px' }}>
                    <Stack direction="row" sx={{ gap: '8px' }}>
                      <ReminderIcon
                        Icon={UploadFileOutlined}
                        variant={value === 'desktop' ? null : 'unselected'}
                      />
                      <SmallText>Upload files from your computer</SmallText>
                    </Stack>
                    <Stack direction="row" sx={{ gap: '8px' }}>
                      <ReminderIcon
                        Icon={BadgeOutlined}
                        variant={value === 'desktop' ? null : 'unselected'}
                      />
                      <SmallText>Upload a photo of your ID</SmallText>
                    </Stack>
                    <Stack direction="row" sx={{ gap: '8px' }}>
                      <ReminderIcon
                        Icon={SecurityOutlined}
                        variant={value === 'desktop' ? null : 'unselected'}
                      />
                      <SmallText>Verify securely</SmallText>
                    </Stack>
                  </Stack>
                </Card>
              </Stack>
            </RadioGroup>
          )}
        />
      </form>
    </DesktopPage>
  );
};

export default SelectionPage;
