import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spinner } from 'spa/components/Indicators';
import { twoFactorLoadingSelector } from 'spa/selectors/TwoFactorSelectors';
import { customerPhoneNumberSelector } from 'spa/selectors/CustomerSelectors';
import SetupTwoFactorVerificationForm from 'spa/components/TwoFactorAuthentication/SetupTwoFactorVerificationForm';
import SendSMSCodeForm from 'spa/components/TwoFactorAuthentication/SendSMSCodeForm';
import { gettext } from '../../../utils/filters';

class SetupSMSContainer extends React.Component {
  constructor(props) {
    super(props);
    this.renderLoading = this.renderLoading.bind(this);
  }

  renderLoading() {
    return (
      <div className="twoFactorAuth-card-placeholder">
        <Spinner />
      </div>
    );
  }

  render() {
    const { loading, navigateDone, navigateMain, configType } = this.props;

    if (loading) {
      return this.renderLoading();
    }

    return (
      <div>
        <div className="twoFactorAuth-card-header">
          <h2 className="twoFactorAuth-card-header-title twoFactorAuth-card-header-title--auth">
            {gettext("Let's setup your phone")}
          </h2>
        </div>
        <div className="twoFactorAuth-page">
          <div className="twoFactorAuth-page-step">
            <div className="twoFactorAuth-page-step-icon">
              <div className="twoFactorAuth-page-step-icon-text">1</div>
            </div>
            <div className="twoFactorAuth-page-step-text">
              <span>{gettext("We'll text you a verification code to confirm your number")}</span>
              <p className="twoFactorAuth-page-step-text-hint">
                {gettext("Note: Setting up 2fa will update your profile's phone number")}
              </p>
            </div>
          </div>
          <SendSMSCodeForm
            initialValues={{
              phoneNumber: this.props.customerPhoneNumber.phoneNumber,
              phoneCountry: this.props.customerPhoneNumber.phoneCountry,
            }}
          />
          <div className="twoFactorAuth-page-step">
            <div className="twoFactorAuth-page-step-icon">
              <div className="twoFactorAuth-page-step-icon-text">2</div>
            </div>
            <div className="twoFactorAuth-page-step-text">
              {gettext('Enter the code sent to your phone')}
            </div>
          </div>
          <SetupTwoFactorVerificationForm
            navigateDone={navigateDone}
            navigateMain={navigateMain}
            configType={configType}
          />
        </div>
      </div>
    );
  }
}

SetupSMSContainer.propTypes = {
  navigateDone: PropTypes.func.isRequired,
  navigateMain: PropTypes.func.isRequired,
  configType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  loading: twoFactorLoadingSelector(state),
  customerPhoneNumber: customerPhoneNumberSelector(state),
});

export default connect(mapStateToProps)(SetupSMSContainer);
