import React from 'react';
import PropTypes from 'prop-types';

import TaskListItem from './TaskListItem';

const TaskList = ({ taskList, updateTask }) =>
  !!taskList.length && (
    <div className="taskList">
      {taskList.map((task) => (
        <TaskListItem key={task.name} task={task} updateTask={updateTask} />
      ))}
    </div>
  );

TaskList.propTypes = {
  taskList: PropTypes.array,
  updateTask: PropTypes.func,
};

export default TaskList;
