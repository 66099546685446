import React from 'react';
import PropTypes from 'prop-types';

const SectionList = ({ children }) => (
  <ul className="integrationsPortal-section-list">{children}</ul>
);

SectionList.propTypes = {
  children: PropTypes.node,
};

export default SectionList;
