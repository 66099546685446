import React, { useState, useEffect } from 'react';

import Currency from 'spa/components/Currency';
import { gettext } from '../../../utils/filters';
import API from '../../../api';
import Spinner from '../Indicators/Spinner';

const TransactionLineItemTerm = ({ label, value }) => (
  <div className="orderSummaryTable-item-detail" key={`${label}_${value}`}>
    <strong>{label}</strong> {value}
  </div>
);

const getEscrowFeePayer = (escrowFeeSplit) => {
  let escrowFeePayer = '';
  if (escrowFeeSplit.buyer === 1.0) {
    escrowFeePayer = gettext('Buyer');
  } else if (escrowFeeSplit.seller === 1.0) {
    escrowFeePayer = gettext('Seller');
  } else if (escrowFeeSplit.broker === 1.0) {
    escrowFeePayer = gettext('Broker');
  } else {
    const feeStatuses = [];

    if (escrowFeeSplit.buyer > 0.0) {
      feeStatuses.push(`${escrowFeeSplit.buyer * 100}% ${gettext('Buyer')}`);
    }

    if (escrowFeeSplit.seller > 0.0) {
      feeStatuses.push(`${escrowFeeSplit.seller * 100}% ${gettext('Seller')}`);
    }

    if (escrowFeeSplit.broker > 0.0) {
      feeStatuses.push(`${escrowFeeSplit.broker * 100}% ${gettext('Broker')}`);
    }

    escrowFeePayer = feeStatuses.join(' / ');
  }

  return escrowFeePayer;
};

const getDomainNameTerms = ({ item }) => [
  {
    key: 'withContent',
    label: `${gettext('With content')}:`,
    value: item.otherTerms.withContent ? gettext('Yes') : gettext('No'),
  },
  {
    key: 'concierge',
    label: `${gettext('With concierge')}:`,
    value: item.otherTerms.concierge ? gettext('Yes') : gettext('No'),
  },
];

const getDnhTerms = ({ item }) => [
  {
    key: 'numPayments',
    label: `${gettext('Number of payments')}:`,
    value: item.otherTerms.numPayments,
  },
];

const getTransactionTypeTerms = ({ item }) => {
  switch (item.type) {
    case 'domain_name':
      return getDomainNameTerms({ item });
    case 'domain_name_holding':
      return getDnhTerms({ item });
    default:
      return [];
  }
};

const TransactionLineItemTerms = ({ item }) => {
  const inspectionPeriod = item.inspectionPeriodSeconds
    ? item.inspectionPeriodSeconds / 86400
    : null;
  const daysLabel = inspectionPeriod !== 1 ? gettext('days') : gettext('day');
  const escrowFeePayer = getEscrowFeePayer(item.escrowFeeSplitsByRole);

  let terms = [];
  if (inspectionPeriod) {
    terms.push({
      key: 'inspectionPeriod',
      label: `${gettext('Inspection period')}:`,
      value: `${inspectionPeriod} ${daysLabel}`,
    });
  }
  if (escrowFeePayer) {
    terms.push({
      key: 'escrowFeeSplit',
      label: `${gettext('Escrow fee paid by')}:`,
      value: escrowFeePayer,
    });
  }
  const transactionTypeTerms = getTransactionTypeTerms({ item });
  terms = [...terms, ...transactionTypeTerms];

  return (
    <div>
      {terms.map((term) => (
        <TransactionLineItemTerm key={term.key} label={term.label} value={term.value} />
      ))}
    </div>
  );
};

const TransactionLineItemRow = ({
  item,
  totalPrice,
  currency,
  itemsAreMany,
  showNoPrices,
  showTerms,
}) => {
  let output;
  if (showNoPrices) {
    output = (
      <tr>
        <td className="paymentBreakdown-cell" colSpan="3">
          <div className="paymentBreakdown-title">{item.title}</div>
          <div className="paymentBreakdown-description">{item.description}</div>
          {showTerms && <TransactionLineItemTerms item={item} />}
        </td>
        <td className="paymentBreakdown-cell paymentBreakdown-quantity">{item.quantity}</td>
      </tr>
    );
  } else if (itemsAreMany) {
    output = (
      <tr>
        <td className="paymentBreakdown-cell">
          <div className="paymentBreakdown-title">{item.title}</div>
          <div className="paymentBreakdown-description">{item.description}</div>
          {showTerms && <TransactionLineItemTerms item={item} />}
        </td>
        <td className="paymentBreakdown-cell paymentBreakdown-quantity">{item.quantity}</td>
        <td className="paymentBreakdown-cell paymentBreakdown-value">
          <Currency code={currency} amount={totalPrice / (item.quantity || 1)} withTag />
        </td>
        <td className="paymentBreakdown-cell paymentBreakdown-value">
          <Currency code={currency} amount={totalPrice} withTag />
        </td>
      </tr>
    );
  } else {
    output = (
      <tr>
        <td className="paymentBreakdown-cell" colSpan="3">
          <div className="paymentBreakdown-title">{item.title}</div>
          <div className="paymentBreakdown-description">{item.description}</div>
          {showTerms && <TransactionLineItemTerms item={item} />}
        </td>
        <td className="paymentBreakdown-cell paymentBreakdown-value">
          <Currency code={currency} amount={totalPrice} withTag />
        </td>
      </tr>
    );
  }
  return output;
};

const TransactionFeeItemRow = ({ currency, title, totalPrice, type }) => (
  <tr className={`paymentBreakdown-${type}`}>
    <td className="paymentBreakdown-cell paymentBreakdown-value" colSpan="4">
      <div className="paymentBreakdown-details">
        <div className="paymentBreakdown-details-title">{title}</div>
        <div>
          <Currency code={currency} amount={totalPrice} withTag />
        </div>
      </div>
    </td>
  </tr>
);

const TransactionTable = ({ transactionSummary, viewerPartyRole, config, outstandingBalance }) => {
  let tableHeader;
  if (config.showNoPrices) {
    tableHeader = (
      <tr>
        <td className="paymentBreakdown-header" colSpan="3">
          Item Description
        </td>
        <td className="paymentBreakdown-header paymentBreakdown-quantity">Quantity</td>
      </tr>
    );
  } else if (transactionSummary.lineItemData.some((item) => item.quantity > 1)) {
    tableHeader = (
      <tr>
        <td className="paymentBreakdown-header">Item Description</td>
        <td className="paymentBreakdown-header paymentBreakdown-quantity">Quantity</td>
        <td className="paymentBreakdown-header paymentBreakdown-header--value">Unit Price</td>
        <td className="paymentBreakdown-header paymentBreakdown-header--value">Total Price</td>
      </tr>
    );
  } else {
    tableHeader = (
      <tr>
        <td className="paymentBreakdown-header" colSpan="3">
          Item Description
        </td>
        <td className="paymentBreakdown-header paymentBreakdown-header--value">Total Price</td>
      </tr>
    );
  }
  const [combineBrokerAndEscrowFee, setCombineBrokerAndEscrowFee] = useState();
  useEffect(() => {
    try {
      if (transactionSummary.id) {
        API.shouldCombineBrokerAndEscrowFee(transactionSummary.id)
          .then((result) => setCombineBrokerAndEscrowFee(result))
          .catch(() => setCombineBrokerAndEscrowFee(false));
      } else {
        throw new Error('No fees available in transaction draft');
      }
    } catch (error) {
      setCombineBrokerAndEscrowFee(false);
    }
  }, [transactionSummary.id]);

  const brokerFee = transactionSummary.feeLineItemDataSquashed.find(
    (item) => item.type === 'broker_fee'
  );
  const escrowFee = transactionSummary.feeData.find((fee) => fee.type === 'escrow');
  const isLoading = combineBrokerAndEscrowFee !== false && !combineBrokerAndEscrowFee;
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div>
      <table className="paymentBreakdown">
        <thead>{tableHeader}</thead>
        <tbody>
          {transactionSummary.lineItemData
            .filter((item) => !item.isFeeItem)
            .map((item) => (
              <TransactionLineItemRow
                key={item.key}
                title={item.title}
                description={item.description}
                quantity={item.quantity}
                item={item}
                totalPrice={
                  viewerPartyRole === 'buyer' ? item.rawAmountPaid : item.rawAmountReceived
                }
                currency={transactionSummary.currency}
                itemsAreMany={transactionSummary.lineItemData.some((i) => i.quantity > 1)}
                showNoPrices={config.showNoPrices}
                showTerms={config.showTerms}
              />
            ))}
          {!config.showNoPrices && [
            <TransactionFeeItemRow
              key="subtotal"
              title="Subtotal"
              currency={transactionSummary.currency}
              totalPrice={
                viewerPartyRole === 'buyer'
                  ? transactionSummary.nonFeeLineItemTotalPaid
                  : transactionSummary.nonFeeLineItemTotalReceived
              }
            />,
            transactionSummary.feeLineItemDataSquashed
              .filter((item) => (combineBrokerAndEscrowFee ? item.type !== 'broker_fee' : true))
              .filter((item) => item.rawAmountPaid > 0)
              .map((item) => (
                <TransactionFeeItemRow
                  key={item.key}
                  currency={transactionSummary.currency}
                  title={item.typeDisplayName}
                  totalPrice={item.rawAmountPaid}
                />
              )),
            transactionSummary.feeData
              .filter((item) => (combineBrokerAndEscrowFee ? item.type !== 'escrow' : true))
              .filter((item) => item.amountInclTax !== 0)
              .map((item) => (
                <TransactionFeeItemRow
                  key={item.type}
                  currency={transactionSummary.currency}
                  title={item.displayName}
                  totalPrice={item.amountInclTax}
                />
              )),
            combineBrokerAndEscrowFee && brokerFee && escrowFee && (
              <TransactionFeeItemRow
                key="broker_escrow_fee"
                currency={transactionSummary.currency}
                title="Processing Fee"
                totalPrice={brokerFee.totalAmountPaid + escrowFee.amountInclTax}
              />
            ),
            !config.hideTotal && (
              <TransactionFeeItemRow
                title="Total"
                key="total"
                currency={transactionSummary.currency}
                totalPrice={
                  viewerPartyRole === 'buyer'
                    ? transactionSummary.totalAmountPaid
                    : transactionSummary.totalAmountReceived
                }
                type="total"
              />
            ),
            outstandingBalance && (
              <TransactionFeeItemRow
                title="Outstanding Balance"
                key="outstanding-balance"
                currency={transactionSummary.currency}
                totalPrice={outstandingBalance}
              />
            ),
          ]}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionTable;
