import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Code } from 'spa/components/Code';
import PrimaryLink from 'spa/components/PrimaryLink';
import {
  Section,
  SectionTitle,
  SectionSubTitle,
  SectionDesc,
  SectionList,
  SectionListItem,
} from 'spa/components/Section';
import AccentedText from 'spa/components/AccentedText';

import { urlFor, envDomainName } from '../../../routeConfig';

const FetchAuction = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktopSmall-9">
        <h1 className="integrationsPortal-title">
          Escrow Offer Guide :: Retrieving auction details
        </h1>
        <Section>
          <SectionTitle>Retrieving auction details</SectionTitle>
          <SectionDesc>
            Use this endpoint to retrieve information about an auction. This endpoint does not
            require authentication. Here are the list of attributes which we return:
          </SectionDesc>
          <SectionList>
            <SectionListItem>
              <AccentedText>id</AccentedText>
            </SectionListItem>
            <SectionListItem>
              <AccentedText>creation_date</AccentedText>
            </SectionListItem>
            <SectionListItem>
              <AccentedText>currency</AccentedText>
            </SectionListItem>
            <SectionListItem>
              <AccentedText>description</AccentedText>
            </SectionListItem>
            <SectionListItem>
              <AccentedText>items</AccentedText>
            </SectionListItem>
            <SectionListItem>
              <AccentedText>parties</AccentedText>
            </SectionListItem>
            <SectionListItem>
              <AccentedText>include_buy_now</AccentedText>
            </SectionListItem>
            <SectionListItem>
              <AccentedText>lising_reference</AccentedText>
            </SectionListItem>
            <SectionListItem>
              <AccentedText>return_url</AccentedText>
            </SectionListItem>
          </SectionList>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.`+envDomainName+`/integration/2018-08-01/auction/2d2afb9f-364f-4f9f-82a5-803344d60432" \\
    -X GET \\
    -H "Content-Type: application/json"
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.get(
  'https://api.`+envDomainName+`/integration/2018-08-01/auction/2d2afb9f-364f-4f9f-82a5-803344d60432',
)
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="ruby">
                {`require 'net/http'
require 'json'

uri = URI("https://api.`+envDomainName+`/integration/2018-08-01/auction/2d2afb9f-364f-4f9f-82a5-803344d60432")

http = Net::HTTP.new(uri.host, uri.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Get.new(uri.request_uri).tap {|request|
  request["content-type"] = 'application/json'
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
  CURLOPT_URL => 'https://api.`+envDomainName+`/integration/2018-08-01/auction/2d2afb9f-364f-4f9f-82a5-803344d60432',
  CURLOPT_RETURNTRANSFER => 1,
  CURLOPT_HTTPHEADER => array(
      'Content-Type: application/json'
  ),
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue(
                "application/json"));
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.`+envDomainName+`/integration/2018-08-01/auction/2d2afb9f-364f-4f9f-82a5-803344d60432"),
                Method = HttpMethod.Get,
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
          <SectionSubTitle>Example Response</SectionSubTitle>
          <Code language="json">
            {`{
        "creation_date": "2019-01-07T06:25:50.503000",
        "currency":"usd",
        "description":"2008 BMW 328xi",
        "include_buy_now": true,
        "listing_reference":"listing-xyz456",
        "id": 123123,
        "parties":[
           {
             "customer":"buyer_user",
             "role":"buyer",
             "agreed": true,
             "initiator": true
           },
           {
              "customer":"john.wick@test.escrow.com",
              "role":"seller",
              "agreed": true
           },
           {
              "customer":"john.wick@test.escrow.com",
              "role":"partner",
              "agreed": true
           },
        ],
        "items": [
             {
                 "title": "2008 BMW 328xi",
                 "description": "A great car for the snow",
                 "type": "motor_vehicle",
                 "inspection_period": 259200,
                 "quantity": 1,
                 "schedule": [
                     {
                         "amount": 5000.0,
                         "payer_customer": "buyer_user",
                         "beneficiary_customer": "john.wick@test.escrow.com"
                     }
                 ],
                 "extra_attributes": {
                     "make": "BMW",
                     "model": "328xi",
                     "year": 2008
                 }
             }
        ]
}`}
          </Code>
        </Section>
        <footer className="integrationsPortal-footer">
          <h3 className="integrationsPortal-footer-title">{'Next steps'}</h3>
          <nav className="integrationsPortal-footer-steps">
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Fetch transaction details"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_fetch_transaction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Full API reference Document"
                link={{
                  type: 'external',
                  route: window.config.api_docs_page,
                }}
              />
            </div>
          </nav>
        </footer>
      </div>
    </div>
  </div>
);

export default FetchAuction;
