import immutable from 'immutable';
import window from 'window-shim';
import document from 'document-shim';
import QueryString from 'query-string';
import History from 'html5-history-api';
import localStorage from 'local-storage';

import API from '../api/API';
import AppDispatcher from '../dispatcher/AppDispatcher';
import { ChangeEmitter } from '../utils/ChangeEmitter';
import AuthenticationStore from './AuthenticationStore';
import CalculatorConstants from '../constants/Calculator';
import FormConstants from '../constants/FormConstants';
import CalculatorStore from './Calculator';
import NavigationConstants from '../constants/NavigationConstants';
import VerifyUserConstants from '../constants/VerifyUserConstants';
import StartTransactionConstants from '../constants/StartTransactionConstants';
import AuthenticationConstants from '../constants/AuthenticationConstants';

import getIsExistingUser from '../utils/isExistingUser';
import { urlfor } from '../utils/filters';
import { urlFor, getRouteFromPath } from '../routeConfig';

export class NavigationStore extends ChangeEmitter {
  constructor() {
    super();

    const currentAbTests = localStorage.get('currentABTests');
    if (currentAbTests && currentAbTests[NavigationConstants.CHECKOUT_REDIRECT_AB_TEST_NAME]) {
      this.inCheckoutRedirect =
        currentAbTests[NavigationConstants.CHECKOUT_REDIRECT_AB_TEST_NAME] === 'b';
    }

    this.authRequired = window.auth_required;
    this.state = new immutable.Map(History.state);
    this.ignoreMissingCustomerData = false;
  }

  getIgnoreMissingCustomerData() {
    return this.ignoreMissingCustomerData;
  }

  setIgnoreMissingCustomerData(val) {
    this.ignoreMissingCustomerData = val;
  }

  loginLocation() {
    let loginLocation;
    // The loginLocation value in the current query takes first preference
    const query = QueryString.parse(window.location.search);
    try {
      loginLocation = localStorage.get('loginLocation');
      localStorage.remove('loginLocation');
    } catch (err) {
      loginLocation = '';
    }

    if (query.loginLocation) {
      loginLocation = query.loginLocation;
    }

    return loginLocation;
  }

  setPageType(pageType) {
    this.pageType = pageType;
    this.emitChange();
  }

  setLoginLocation(page) {
    localStorage.set('loginLocation', page);
  }

  pushOffsiteUrl(page) {
    this.setLoginLocation(page);

    if (
      page === 'calculator-transaction' &&
      (CalculatorStore.getData().type === CalculatorConstants.GENERAL_TRANSACTION_TYPE ||
        CalculatorStore.getData().type === CalculatorConstants.MOTOR_VEHICLE_TRANSACTION_TYPE) &&
      CalculatorStore.getData().role !== CalculatorConstants.ROLE_BROKER
    ) {
      // Navigate to new start page funnel for non-broker GM & MV transactions
      const query = QueryString.parse(window.location.search);

      // Append query parameter to start transaction page for MV category
      if (CalculatorStore.getData().type === CalculatorConstants.MOTOR_VEHICLE_TRANSACTION_TYPE) {
        query.category = 'vehicle';
      }

      this.calculatorTransaction(
        false,
        `${window.config.transaction_choice}?${QueryString.stringify(query)}`
      );
    } else if (page === 'general-merchandise') {
      this.calculatorTransaction(false, `${window.config.transaction_choice}`, {
        type: CalculatorConstants.GENERAL_TRANSACTION_TYPE,
      });
    } else if (AuthenticationStore.isAuthenticated()) {
      if (page === 'calculator-transaction') {
        this.calculatorTransaction();
      } else if (page === 'domain-transaction') {
        this.domainTransaction();
      } else if (page === 'transaction-choice') {
        window.location.href = window.config.transaction_choice;
      }
    } else if (page === 'calculator-transaction' || page === 'transaction-choice') {
      if (AuthenticationStore.authenticationUrl()) {
        window.location.href = AuthenticationStore.authenticationUrl() + window.location.search;
      } else {
        this.returningCustomerRedirect();
      }
    } else {
      this.returningCustomerRedirect();
    }
  }

  domainTransaction() {
    const queryStr = QueryString.stringify({
      ttype: CalculatorConstants.DOMAIN_TRANSACTION_TYPE,
      role: 'seller',
    });

    window.location.href = `${window.config.transaction_choice}?${queryStr}`;
  }

  calculatorTransaction(signup, page, forceValues = {}) {
    const url = page || window.config.transaction_choice;
    const values = {
      ...CalculatorStore.getData(),
      ...forceValues,
    };
    const query = QueryString.parse(QueryString.extract(url));
    query.ttype = values.type;
    query.role = values.role;
    query.cur = values.currency;
    query.amount = values.amount;
    query.description = values.description;

    if (signup) {
      query.signup = 'start';
    }
    const baseUrl = url.replace(/\?.*/g, '');
    window.location.href = `${baseUrl}?${QueryString.stringify(query)}`;
  }

  transactionInformation(tid) {
    if (tid) {
      window.location.href = `${window.config.www_base_url}/transaction/${tid}`;
    } else {
      this.redirectMyTransactions();
    }
  }

  startTransactionPage(email) {
    const queryStr = QueryString.stringify({
      email: email,
    });
    window.location.href = `${urlfor('.user_page_start_transaction')}?${queryStr}`;
  }

  push(state, title, url) {
    this.state = this.state.merge(state);
    History.pushState(this.state.toJS(), title, url);
  }

  pop(state) {
    this.state = new immutable.Map(state);
  }

  replace(state) {
    this.state = new immutable.Map(state);
    History.replaceState(this.state.toJS(), null, null);
  }

  closeModal(name) {
    if (this.getState().modal === name) {
      if (
        name === 'modal-contact' ||
        name === 'modal-sales' ||
        name === 'modal-bug-report-success'
      ) {
        this.reloadPage();
      }
      const state = this.getState();
      delete state.modal;
      this.replace(state);
    }
  }

  getState() {
    return this.state.toJS();
  }

  /*
   * If a login location has been defined, we should check if it exists in
   * the config, otherwise assume it is a relative path
   * We need to convert the templates dashed strings to underscores to
   * match the format used by config
   */
  redirectToLoginLocation(loginLocation) {
    const newLocation = loginLocation.replace(/-/g, '_');
    if (newLocation in window.config) {
      window.location = window.config[newLocation];
    } else {
      window.location = window.location.origin + loginLocation;
    }
  }

  /*
   * If document.referrer is set, use it to set a query param that is later
   * used to redirect the user such that they don't see the login page again
   * on clicking back. If the document.referrer is equal to the current page,
   * fall back to the homepage instead
   */
  setPreAuthLocation() {
    const query = QueryString.parse(window.location.search);
    const currentPage = `${window.location.origin}${window.location.pathname}`;
    const referrer = document.referrer.split('?')[0];
    const excludedLocations = [
      currentPage,
      window.location.origin + urlfor('.login_page'),
      window.location.origin + urlfor('.signup_page'),
    ];
    if (document.referrer && excludedLocations.indexOf(referrer) < 0) {
      query.preAuthLocation = document.referrer;
    } else if (excludedLocations.indexOf(referrer) >= 0) {
      query.preAuthLocation = urlfor('.index');
    }
    History.replaceState({}, '', `?${QueryString.stringify(query)}`);
  }

  loginRedirect() {
    this.setPreAuthLocation();
    const query = QueryString.parse(window.location.search);
    const loginLocation = this.loginLocation();

    localStorage.set('isExistingUser', true);
    if (query.TID || query.tid) {
      // Preference TID over all other redirects
      window.location = `${window.config.www_base_url}/transaction/${query.TID || query.tid}`;
    } else if (loginLocation === 'calculator-transaction') {
      this.calculatorTransaction(false);
    } else if (loginLocation === 'domain-transaction') {
      this.domainTransaction();
    } else if (loginLocation) {
      // Do not allow regular logins go transaction choice
      this.redirectToLoginLocation(loginLocation);
    } else {
      // Normal login -> My transactions page
      window.location = window.config.loggedin_landing_page;
    }
  }

  redirectMyTransactions() {
    window.location = window.config.loggedin_landing_page;
  }

  signupRedirect() {
    this.setPreAuthLocation();
    const query = QueryString.parse(window.location.search);
    const loginLocation = this.loginLocation();

    localStorage.set('isExistingUser', true);
    if (query.TID || query.tid) {
      // Preference TID over all other redirects
      window.location = `${window.config.www_base_url}/transaction/${query.TID || query.tid}`;
    } else if (loginLocation === 'calculator-transaction') {
      this.calculatorTransaction(true);
    } else if (loginLocation === 'domain-transaction') {
      this.domainTransaction();
    } else if (/\/integrations/.exec(loginLocation) && query.i) {
      window.location = `${window.config.integrations_portal}?signup=true`;
    } else if (loginLocation && loginLocation.startsWith('/buttons/create')) {
      window.location = `${window.location.origin}${loginLocation}`;
    } else if (loginLocation) {
      this.redirectToLoginLocation(loginLocation);
    } else {
      window.location = urlfor('.signup_redirect');
    }
  }

  startTransactionRedirect() {
    this.setLoginLocation('transaction-choice');
    this.returningCustomerRedirect();
  }

  returningCustomerRedirect() {
    if (getIsExistingUser()) {
      window.location = urlfor('.login_page');
    } else {
      window.location = urlfor('.signup_page');
    }
  }

  partnerLandingPageStart(category) {
    if (
      category === NavigationConstants.GENERAL_TRANSACTION_TYPE ||
      category === NavigationConstants.VEHICLE_TRANSACTION_TYPE
    ) {
      window.location = `${window.config.transaction_choice}?category=${category}`;
    } else {
      this.startTransactionRedirect();
    }
  }

  accountInfoRedirect() {
    const query = QueryString.parse(window.location.search);
    if (query.TID || query.tid) {
      // Preference TID over all other redirects
      window.location = `${window.config.www_base_url}/transaction/${query.TID || query.tid}`;
    } else {
      window.location = urlfor('.account-info');
    }
  }

  reloadPage() {
    window.location.reload(true);
  }

  homepageRedirect() {
    window.location = urlfor('.index');
  }

  verifyTierRedirect(formName) {
    let queryStr = '';
    if (formName === VerifyUserConstants.METHOD_TIER_1) {
      const query = QueryString.parse(window.location.search);
      if (query.TID || query.tid) {
        window.location = `${window.config.www_base_url}/transaction/${query.TID || query.tid}`;
      } else {
        window.location = urlfor('.verify-logged-in');
      }
    } else if (
      formName === VerifyUserConstants.METHOD_TIER_2 ||
      formName === VerifyUserConstants.METHOD_TIER_3
    ) {
      queryStr = '?newSubmission=true';
      window.location = `${urlfor('.verify-logged-in')}${queryStr}`;
    } else {
      window.location = urlfor('.verify-logged-in');
    }
  }

  agreeSuccess() {
    let requestorRole;
    let agreedParties;
    if (window.js_context) {
      requestorRole = window.js_context.requestor_role || '';
      agreedParties = window.js_context.agreed_parties || {};
    }

    // Update agree parties
    agreedParties[requestorRole] = true;

    const query = QueryString.parse(window.location.search);
    if (query.tid && requestorRole === 'seller') {
      this.settlementPaymentChoiceRedirect(query.tid);
    } else if (this.inCheckoutRedirect && query.tid && requestorRole === 'buyer') {
      // Check if transaction has all parties agreed
      const isCheckoutRedirectDisabled = Boolean(window.js_context.is_checkout_redirect_disabled);
      if (isCheckoutRedirectDisabled || Object.values(agreedParties).includes(false)) {
        window.location = `${window.config.www_base_url}/transaction/${query.TID || query.tid}`;
      } else {
        this.redirectCheckoutPage(query.tid, query.token);
      }
    } else if (query.tid) {
      this.redirectVerifyTier1Buyer(query.tid, 'transaction');
    } else {
      window.location.href = window.config.my_transactions_page;
    }
  }

  startTransactionSuccess(tid, role) {
    if (role === StartTransactionConstants.ROLE_SELLER) {
      this.settlementPaymentChoiceRedirect(tid);
    } else {
      this.redirectVerifyTier1Buyer(tid);
    }
  }

  redirectCheckoutPage(tid, ttoken) {
    const queryStr = { tid, ttoken };
    window.location = `${urlFor('checkout_page_default')}?${QueryString.stringify(queryStr)}`;
  }

  redirectVerifyTier1Buyer(tid, success) {
    const queryStr = {
      tid: tid,
    };
    if (success) {
      queryStr.success = success;
    }
    window.location = `${urlfor('.verify-tier-1-buyer')}?${QueryString.stringify(queryStr)}`;
  }

  redirectVerifyTier2Buyer(tid) {
    window.location = `${urlfor('.verify-tier-2-buyer')}?tid=${tid}`;
  }

  settlementPaymentChoiceRedirect(transID, settlementChoice = null) {
    if (transID) {
      const options = {
        TID: transID,
      };
      if (settlementChoice) {
        options.cmd = 'save';
        options.pmtChoice = settlementChoice;
      }

      const redirectQueryString = QueryString.stringify(options);
      window.location = `${window.config.settlement_payment_choice}?${redirectQueryString}`;
    } else {
      this.verifyTierRedirect();
    }
  }

  redirectVerifyTier1Disbursement(method) {
    window.location = `${urlfor('.account-info')}/disbursement-options/${method}`;
  }

  submissionSuccess(action) {
    if (action.name === 'login-form') {
      this.loginRedirect();
    } else if (action.name === 'signup-customer' || action.name === 'signup-partner') {
      API.sendVerificationEmail();
      this.signupRedirect();
    } else if (action.name === 'signup-create') {
      this.startTransactionSuccess(action.transactionID, action.role);
    } else if (action.name === 'login-agree' || action.name === 'register-agree') {
      this.agreeSuccess();
    } else if (
      action.name === 'already-agreed-login' ||
      action.name === 'already-agreed-register'
    ) {
      const query = QueryString.parse(window.location.search);
      this.transactionInformation(query.tid);
    } else if (
      action.name === VerifyUserConstants.METHOD_TIER_1 ||
      action.name === VerifyUserConstants.METHOD_TIER_2 ||
      action.name === VerifyUserConstants.METHOD_TIER_3
    ) {
      this.verifyTierRedirect(action.name);
    } else if (action.name === VerifyUserConstants.METHOD_TIER_1_SELLER) {
      const query = QueryString.parse(window.location.search);
      this.settlementPaymentChoiceRedirect(query.transId, query.settlementChoice);
    } else if (action.name === VerifyUserConstants.METHOD_TIER_1_BUYER) {
      const query = QueryString.parse(window.location.search);
      this.redirectVerifyTier1Buyer(query.tid, query.success);
    } else if (action.name === VerifyUserConstants.METHOD_TIER_1_DISBURSEMENT) {
      const query = QueryString.parse(window.location.search);
      this.redirectVerifyTier1Disbursement(query.method);
    } else if (
      action.name === VerifyUserConstants.ACCOUNT_INFO_PERSONAL_FORM ||
      action.name === VerifyUserConstants.ACCOUNT_INFO_COMPANY_FORM ||
      action.name === VerifyUserConstants.EDIT_PASSWORD_FORM ||
      action.name === VerifyUserConstants.METHOD_TIER_1_ACCOUNT_DETAILS
    ) {
      this.accountInfoRedirect();
    } else if (action.name === 'clear-24-hr-notice-form') {
      const query = QueryString.parse(window.location.search);
      this.transactionInformation(query.tid);
    }
  }

  setSearchOpen() {
    this.searchOpen = true;
    this.emitChange();
  }

  setSearchClose() {
    this.searchOpen = false;
    this.emitChange();
  }

  handleViewAction(action) {
    const actionType = action.actionType;
    if (actionType === NavigationConstants.NAVIGATE_LOGGED_IN) {
      this.pushOffsiteUrl(action.page);
    } else if (actionType === NavigationConstants.CALCULATOR_TRANSACTION) {
      this.calculatorTransaction(action.signup);
    } else if (actionType === NavigationConstants.DOMAIN_TRANSACTION) {
      this.domainTransaction();
    } else if (actionType === NavigationConstants.HISTORY_PUSH) {
      this.push(action.state, action.title, action.url);
    } else if (actionType === NavigationConstants.HISTORY_POP) {
      this.pop(action.state);
    } else if (actionType === NavigationConstants.HISTORY_REPLACE) {
      this.replace(action.state);
    } else if (actionType === NavigationConstants.CLOSE_MODAL) {
      this.closeModal(action.name);
    } else if (actionType === NavigationConstants.SET_LOGIN_LOCATION) {
      this.setLoginLocation(action.page);
    } else if (actionType === NavigationConstants.START_TRANSACTION) {
      this.startTransactionPage(action.email);
    } else if (actionType === NavigationConstants.START_TRANSACTION_REDIRECT) {
      this.startTransactionRedirect(action.isExistingUser);
    } else if (actionType === NavigationConstants.PARTNER_LANDING_PAGE_START) {
      this.partnerLandingPageStart(action.attributes.category);
    } else if (actionType === NavigationConstants.SET_PAGE_TYPE) {
      this.setPageType(action.pageType);
    } else if (actionType === NavigationConstants.ACTION_SEARCH_OPEN) {
      this.setSearchOpen();
    } else if (actionType === NavigationConstants.ACTION_SEARCH_CLOSE) {
      this.setSearchClose();
    }
  }

  handleServerAction(action) {
    const actionType = action.actionType;
    if (actionType === FormConstants.SUBMISSION_SUCCESS) {
      this.submissionSuccess(action);
    } else if (actionType === NavigationConstants.LOGIN_REDIRECT) {
      this.loginRedirect();
    } else if (actionType === NavigationConstants.PARTNER_LOGIN_REDIRECT) {
      window.location = window.config.integrations_portal;
    } else if (actionType === NavigationConstants.SIGNUP_REDIRECT) {
      this.signupRedirect();
    } else if (actionType === AuthenticationConstants.LOGOUT) {
      if (this.authRequired) {
        this.homepageRedirect();
      } else if (getRouteFromPath(window.location.pathname)) {
        window.location.reload();
      }
    }
  }
}

const navStore = new NavigationStore();
navStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;
  const source = payload.source;

  if (source === 'VIEW_ACTION') {
    navStore.handleViewAction(action);
  } else if (source === 'SERVER_ACTION') {
    navStore.handleServerAction(action);
  }
});

export default navStore;
