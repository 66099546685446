import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  Typography,
} from '@mui/material';
import { EscrowAlert } from 'spa/features/ui/EscrowAlert';
import AlertTitle from '@mui/material/AlertTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { MediumText, SmallText } from 'spa/features/ui/EscrowTypography';
import { transactionSelector } from '../../transactionSlice';
import { SelectItem } from '../select-item/SelectItem';
import { useSetTransactionDisbursementMethodMutation } from '../../transactionApi';
import { EscrowButton, EscrowLinkButton } from 'spa/features/ui/EscrowButton';
import { ApiCurrency, Currency, CountryToAlpha2 } from 'escrow-common-js/dist/constants';

const DisbursementMethodTypes = {
  ach: 'ach',
  wire_transfer: 'wire_transfer',
  wire_transfer_international: 'wire_transfer_international',
  transfer_account: 'transfer_account',
};

const maskAccountNumber = (accountNumber) => {
  const lastFour = accountNumber.slice(-4);
  return `**** ${lastFour}`;
};

const formatDisbursementMethodType = (type) => {
  switch (type) {
    case DisbursementMethodTypes.ach:
      return 'ACH (US Only)';
    case DisbursementMethodTypes.wire_transfer:
      return 'Domestic wire transfer';
    case DisbursementMethodTypes.wire_transfer_international:
      return 'International wire transfer';
    case DisbursementMethodTypes.transfer_account:
      return 'Transfer Account';
    default:
      return '';
  }
};

const DisbursementFeeTextMapping = {
  [ApiCurrency[Currency.USD]]: {
    [DisbursementMethodTypes.wire_transfer]: '$10.00 USD',
    [DisbursementMethodTypes.wire_transfer_international]: '$20.00 USD',
  },
  [ApiCurrency[Currency.EUR]]: {
    [DisbursementMethodTypes.wire_transfer]: '€15.00 EUR',
    [DisbursementMethodTypes.wire_transfer_international]: '€15.00 EUR',
  },
  [ApiCurrency[Currency.GBP]]: {
    [DisbursementMethodTypes.wire_transfer]: '£10.00 GBP',
    [DisbursementMethodTypes.wire_transfer_international]: '£20.00 GBP',
  },
  [ApiCurrency[Currency.CAD]]: {
    [DisbursementMethodTypes.wire_transfer]: 'CA$10.00 CAD',
    [DisbursementMethodTypes.wire_transfer_international]: 'CA$20.00 CAD',
  },
  [ApiCurrency[Currency.AUD]]: {
    [DisbursementMethodTypes.wire_transfer]: 'A$10.00 AUD',
    [DisbursementMethodTypes.wire_transfer_international]: 'A$20.00 AUD',
  },
};

const getDisbursementMethodType = (currency, bankCountry) => {
  if (currency === ApiCurrency[Currency.USD] && bankCountry === CountryToAlpha2['United States']) {
    return DisbursementMethodTypes.wire_transfer;
  }
  if (currency === ApiCurrency[Currency.GBP] && bankCountry === CountryToAlpha2['United Kingdom']) {
    return DisbursementMethodTypes.wire_transfer;
  }
  if (currency === ApiCurrency[Currency.CAD] && bankCountry === CountryToAlpha2.Canada) {
    return DisbursementMethodTypes.wire_transfer;
  }
  if (currency === ApiCurrency[Currency.AUD] && bankCountry === CountryToAlpha2.Australia) {
    return DisbursementMethodTypes.wire_transfer;
  }
  return DisbursementMethodTypes.wire_transfer_international;
};

const getDisbursementFeeText = (currency, bankCountry) =>
  DisbursementFeeTextMapping[currency][getDisbursementMethodType(currency, bankCountry)];

export const SelectDisbursementMethod = ({ transactionId, disbursementMethods }) => {
  const theme = useTheme();
  const isMobileViewport = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const [forceShowOptions, setForceShowOptions] = useState(false);
  const transaction = useSelector(transactionSelector(transactionId));
  const [errorMsg, setErrorMsg] = useState('');

  const [setTransactionDisbursementMethod, { isLoading, error }] =
    useSetTransactionDisbursementMethodMutation();

  const transactionCurrency = useMemo(() => {
    if (!transaction) {
      return;
    }
    if (transaction.currency === 'eur') {
      return ApiCurrency[Currency.EUR];
    }
    return transaction.currency;
  }, [transaction]);

  const savedDisbursementMethods = useMemo(() => {
    if (
      !disbursementMethods ||
      !disbursementMethods.saved_disbursement_methods ||
      !transactionCurrency
    ) {
      return;
    }
    return disbursementMethods.saved_disbursement_methods.filter(
      (disbursementMethod) =>
        disbursementMethod.currency === transactionCurrency &&
        Object.values(DisbursementMethodTypes).includes(disbursementMethod.type)
    );
  }, [disbursementMethods, transactionCurrency]);
  const handleNext = () => {
    setValue('');
    setForceShowOptions(true);
  };

  const handleBack = () => {
    setValue('');
    setForceShowOptions(false);
  };

  const handleDialogClose = () => {
    if (isLoading) {
      return;
    }
    setOpen(false);
    setValue('');
    setForceShowOptions(false);
  };

  const handleSetDisbursementMethod = async () => {
    if (
      !value ||
      value === 'add_disbursement_method' ||
      value === 'add_ach_disbursement_method' ||
      value === 'add_wire_transfer_disbursement_method'
    ) {
      return;
    }

    try {
      await setTransactionDisbursementMethod({
        transId: transaction.id,
        id: value,
      }).unwrap();
      setOpen(false);
    } catch (e) {
      let errorMsgTmp =
        "Your transfer method couldn't be set due to a technical issue. Please refresh the page and try again.";
      if (e.xRequestId) {
        errorMsgTmp += ` If the issue persists, contact support@escrow.com and provide the id: ${e.xRequestId}`;
      }
      setErrorMsg(errorMsgTmp);
    }
  };

  const showAddOptions =
    (savedDisbursementMethods && savedDisbursementMethods.length === 0) || forceShowOptions;

  let ctaButton;
  if (value === 'add_ach_disbursement_method') {
    ctaButton = (
      <EscrowLinkButton
        to={`/account-info/disbursement-options/ach?trans_id=${transactionId}&user_flow=transaction-create`}
        buttonText="Continue"
        disabled={!value || isLoading}
        hard
      />
    );
  } else if (value === 'add_wire_transfer_disbursement_method' || showAddOptions) {
    ctaButton = (
      <EscrowLinkButton
        to={`/account-info/disbursement-options/international-wire?trans_id=${transactionId}&user_flow=transaction-create`}
        buttonText="Continue"
        disabled={!value || isLoading}
        hard
      />
    );
  } else if (!value || value === 'add_disbursement_method') {
    ctaButton = (
      <EscrowButton
        sx={{ width: isMobileViewport ? '100%' : 'auto' }}
        variant="contained"
        onClick={handleNext}
        disabled={!value || isLoading}
      >
        Next
      </EscrowButton>
    );
  } else {
    ctaButton = (
      <EscrowButton
        sx={{ width: isMobileViewport ? '100%' : 'auto' }}
        variant="contained"
        onClick={handleSetDisbursementMethod}
        disabled={!value || isLoading}
      >
        Select disbursement method
      </EscrowButton>
    );
  }

  let AddNewDisbursementOption;
  if (showAddOptions) {
    AddNewDisbursementOption = (
      <React.Fragment>
        {transaction && transactionCurrency === ApiCurrency[Currency.USD] && (
          <SelectItem
            value="add_ach_disbursement_method"
            label="Add a new ACH disbursement method (US Banks only)"
            checked={value === 'add_ach_disbursement_method'}
            onCheck={setValue}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <SmallText sx={{ marginBottom: '2px' }}>
                <strong>Add a new ACH disbursement method (US Banks only)</strong>
              </SmallText>
              <SmallText>
                Funds will arrive after <strong>1-3 business days</strong>
              </SmallText>
              <SmallText>No additional fees</SmallText>
            </Box>
          </SelectItem>
        )}
        <SelectItem
          value="add_wire_transfer_disbursement_method"
          label="Add a new Wire Transfer disbursement method"
          checked={value === 'add_wire_transfer_disbursement_method'}
          onCheck={setValue}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <SmallText sx={{ marginBottom: '2px' }}>
              <strong>Add a new Wire Transfer disbursement method</strong>
            </SmallText>
            <SmallText>
              Funds will arrive after <strong>1-5 business days</strong>
            </SmallText>
            <SmallText>Additional fees may apply</SmallText>
          </Box>
        </SelectItem>
      </React.Fragment>
    );
  } else {
    AddNewDisbursementOption = (
      <SelectItem
        value="add_disbursement_method"
        label="Add a new disbursement method"
        checked={value === 'add_disbursement_method'}
        onCheck={setValue}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <SmallText>
            <strong>Add a new disbursement method</strong>
          </SmallText>
        </Box>
      </SelectItem>
    );
  }

  return (
    <React.Fragment>
      <EscrowButton variant="contained" onClick={() => setOpen(true)}>
        Select disbursement method
      </EscrowButton>
      {open && savedDisbursementMethods && (
        <Dialog open={open} onClose={handleDialogClose} maxWidth="md" fullScreen={isMobileViewport}>
          {isMobileViewport && (
            <IconButton
              sx={{ marginLeft: 'auto' }}
              onClick={handleDialogClose}
              aria-label="Close Select Disbursement Modal"
            >
              <CloseIcon sx={{ color: 'mono.dark', height: 24, width: 24 }} />
            </IconButton>
          )}
          {error && (
            <EscrowAlert>
              <AlertTitle>Unable to set the disbursement method</AlertTitle>
              {errorMsg}
            </EscrowAlert>
          )}
          <DialogTitle>
            <MediumText>Select your disbursement method for the transaction</MediumText>
          </DialogTitle>
          <DialogContent>
            <SmallText>
              Your proceeds will be sent to your selected disbursement method{' '}
              <SmallText variant="bold">once the transaction is complete</SmallText>. Additional
              fees may apply.
            </SmallText>
            <List>
              {!showAddOptions &&
                savedDisbursementMethods.map((disbursementMethod) => (
                  <SelectItem
                    key={disbursementMethod.id}
                    value={disbursementMethod.id}
                    label={disbursementMethod.account_name}
                    checked={value === disbursementMethod.id}
                    onCheck={setValue}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <SmallText sx={{ marginBottom: '2px' }}>
                        <strong>
                          {disbursementMethod.type === DisbursementMethodTypes.transfer_account
                            ? disbursementMethod.account_name
                            : disbursementMethod.bank_name}{' '}
                          (
                          {disbursementMethod.bank_account_number
                            ? maskAccountNumber(disbursementMethod.bank_account_number)
                            : maskAccountNumber(disbursementMethod.bank_iban)}
                          )
                        </strong>
                      </SmallText>
                      <SmallText>
                        {disbursementMethod.type === DisbursementMethodTypes.ach ||
                        disbursementMethod.type === DisbursementMethodTypes.transfer_account
                          ? formatDisbursementMethodType(disbursementMethod.type)
                          : formatDisbursementMethodType(
                              getDisbursementMethodType(
                                disbursementMethod.currency,
                                disbursementMethod.bank_address.country
                              )
                            )}
                      </SmallText>
                      {disbursementMethod.type === DisbursementMethodTypes.ach ? (
                        <div>
                          <SmallText>
                            Funds will arrive after <strong>1-3 business days</strong>
                          </SmallText>
                          <SmallText> No additional fees </SmallText>
                        </div>
                      ) : disbursementMethod.type === DisbursementMethodTypes.transfer_account ? (
                        <div>
                          <SmallText>
                            Funds will arrive after <strong>1 business day</strong>
                          </SmallText>
                          <SmallText> Additional fees may apply </SmallText>
                        </div>
                      ) : (
                        <div>
                          <SmallText>
                            Funds will arrive after <strong>1-5 business days</strong>
                          </SmallText>
                          {getDisbursementFeeText(
                            disbursementMethod.currency,
                            disbursementMethod.bank_address.country
                          ) && (
                            <SmallText>
                              {getDisbursementFeeText(
                                disbursementMethod.currency,
                                disbursementMethod.bank_address.country
                              )}{' '}
                              additional fee
                            </SmallText>
                          )}
                        </div>
                      )}
                    </Box>
                  </SelectItem>
                ))}
              {AddNewDisbursementOption}
            </List>
          </DialogContent>
          <DialogActions>
            {(savedDisbursementMethods && savedDisbursementMethods.length === 0) ||
            !forceShowOptions ? (
              !isMobileViewport && (
                <EscrowButton
                  sx={{ width: isMobileViewport ? '100%' : 'auto' }}
                  variant="text"
                  onClick={handleDialogClose}
                >
                  Cancel
                </EscrowButton>
              )
            ) : (
              <EscrowButton
                sx={{ width: isMobileViewport ? '100%' : 'auto' }}
                variant="text"
                onClick={handleBack}
              >
                Back
              </EscrowButton>
            )}
            {ctaButton}
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
};
