import React from 'react';
import PropTypes from 'prop-types';

const CardRow = ({ label, actionComponent }) => (
  <div className="apiDashboard-entry card-inner">
    <h3 className="apiDashboard-entry-title">{label}</h3>
    <span className="apiDashboard-actionComponent">{actionComponent}</span>
  </div>
);

CardRow.propTypes = {
  label: PropTypes.node,
  actionComponent: PropTypes.node,
};

export default CardRow;
