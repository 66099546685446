import React, { Component } from 'react';
import { Spinner } from 'spa/components/Indicators';
import VerifyChargeForm from 'spa/components/PaymentVerification/VerifyChargeForm';
import { gettext } from '../../../utils/filters';

class PaymentVerificationForm extends Component {
  componentDidMount() {
    this.getCardVerificationDetails();
  }

  getCardVerificationDetails() {
    this.props.getCardVerificationDetails();
  }

  render() {
    const { transId, cardId, cardVerificationId, charge } = this.props;

    return (
      <div>
        {charge && (!charge.loading || 'attempts_left' in charge) ? (
          <div>
            <h1 className="account-section-title">
              {' '}
              {gettext('Please authenticate ownership of your card')}{' '}
            </h1>
            {charge.attempts_left && charge.attempts_left > 0 ? (
              <div>
                <p>
                  {gettext(
                    `Please enter the two amounts we charged to your card. They should appear under the `
                  )}{' '}
                  <strong>{'pending transactions'} </strong>{' '}
                  {gettext(` area of your card statement.`)}{' '}
                </p>
                <VerifyChargeForm initialValues={{ cardId, transId, cardVerificationId }} />
                <br />
                <p>{gettext(`You have ${charge.attempts_left} remaining attempt/s.`)}</p>{' '}
              </div>
            ) : (
              <p>
                <br />
                {gettext(`You have run out of attempts. Please click `)}
                <a
                  href={`${window.config.www_base_url}/card-verification?card-id=${cardId}&trans-id=${transId}`}
                >
                  here
                </a>
                {gettext(` to try again.`)}
              </p>
            )}
            <p>
              {' '}
              {gettext("Can't authenticate your card? Please contact ")}
              <a href="/contact-us">support</a> {gettext('and tell us why.')}{' '}
            </p>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

export default PaymentVerificationForm;
