import React from 'react';
import ReactSVG from 'react-svg';

const FailureIcon = (
  <div className="checkout-confirmation-icon">
    <ReactSVG src={`../../../../build/images/global/fail-icon.svg`} />
  </div>
);

const FailureMessageBox = (props) => {
  if (!props.isCurrentStep) {
    return null;
  }

  return (
    <div className="checkout-confirmation">
      <div className="checkout-confirmation-inner">
        {FailureIcon}
        <h3 className="checkout-confirmation-heading checkout-heading">{props.heading}</h3>
      </div>
    </div>
  );
};

export default FailureMessageBox;
