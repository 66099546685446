var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/icons/favorite.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_2 = "";t_2 += "<svg class=\"icon icon--favorite\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n   viewBox=\"0 841.9 20 20\" enable-background=\"new 0 841.9 20 20\" xml:space=\"preserve\">\n  <g class=\"icon-full\">\n    <path d=\"M10,841.9c-0.3,0-0.6,0.2-0.7,0.5l-2.5,5.5l-6.2,1.3c-0.2,0-0.5,0.2-0.6,0.5c0,0.3,0,0.6,0.2,0.8l4.3,4.3l-1.2,6.1\n      c-0.1,0.3,0,0.6,0.3,0.8c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3-0.1l5.6-2.5l5.6,2.5c0.1,0,0.2,0.1,0.3,0.1\n      c0.2,0,0.4-0.1,0.5-0.2c0.2-0.2,0.3-0.5,0.3-0.8l-1.2-6.2l4.3-4.3c0.2-0.1,0.3-0.4,0.2-0.7s-0.3-0.5-0.6-0.6l-6.2-1.2l-2.5-5.5\n      C10.6,842.1,10.3,841.9,10,841.9L10,841.9z\"/>\n  </g>\n  <g class=\"icon-outline\">\n    <path d=\"M10,845.3l1.5,3.3c0.2,0.6,0.7,1,1.3,1.1l4.2,0.8l-2.9,2.9c-0.4,0.4-0.6,1.1-0.5,1.7l0.8,4.1l-3.7-1.6\n      c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.5,0.1-0.8,0.2l-3.7,1.6l0.8-4.1c0.1-0.6-0.1-1.2-0.5-1.7L3,850.6l4.2-0.8\n      c0.6-0.1,1.1-0.5,1.3-1.1L10,845.3 M10,841.9c-0.3,0-0.6,0.2-0.7,0.5l-2.5,5.5l-6.2,1.2c-0.3,0.1-0.5,0.3-0.6,0.5\n      c-0.1,0.3,0,0.6,0.2,0.8l4.3,4.3l-1.2,6.2c-0.1,0.3,0,0.6,0.3,0.8c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3-0.1l5.6-2.5l5.6,2.5\n      c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.4-0.1,0.5-0.2c0.2-0.2,0.3-0.5,0.3-0.8l-1.2-6.2l4.3-4.3c0.2-0.2,0.3-0.5,0.2-0.8\n      c-0.1-0.3-0.3-0.5-0.6-0.6l-6.2-1.2l-2.5-5.5C10.6,842.1,10.3,841.9,10,841.9L10,841.9z\"/>\n  </g>\n</svg>";
;
frame = frame.pop();
return new runtime.SafeString(t_2);
});
context.addExport("favorite");
context.setVariable("favorite", macro_t_1);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/icons/favorite.html"] , dependencies)