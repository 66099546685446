export default {
  NAVIGATE_LOGGED_IN: 'NAVIGATE_LOGGED_IN',
  CALCULATOR_TRANSACTION: 'CALCULATOR_TRANSACTION',
  DOMAIN_TRANSACTION: 'DOMAIN_TRANSACTION',
  HISTORY_POP: 'HISTORY_POP',
  HISTORY_PUSH: 'HISTORY_PUSH',
  HISTORY_REPLACE: 'HISTORY_REPLACE',
  CLOSE_MODAL: 'CLOSE_MODAL',
  SET_LOGIN_LOCATION: 'SET_LOGIN_LOCATION',
  START_TRANSACTION: 'START_TRANSACTION',
  LOGIN_REDIRECT: 'LOGIN_REDIRECT',
  PARTNER_LOGIN_REDIRECT: 'PARTNER_LOGIN_REDIRECT',
  SIGNUP_REDIRECT: 'SIGNUP_REDIRECT',
  START_TRANSACTION_REDIRECT: 'START_TRANSACTION_REDIRECT',
  PARTNER_LANDING_PAGE_START: 'PARTNER_LANDING_PAGE_START',
  GENERAL_TRANSACTION_TYPE: 'general',
  VEHICLE_TRANSACTION_TYPE: 'vehicle',
  SET_PAGE_TYPE: 'NAVIGATE_SET_HEADER_TYPE',
  CHECKOUT_REDIRECT_AB_TEST_NAME: 'checkout-redirect',
  HIDE_LEGACY_HEADER_PAGE_TYPES: [
    'checkout_portal',
    'verify_landing',
    'legal_landing',
    'offer_management',
    'payments_main_funnel',
    'no_header',
  ],
  ACTION_SEARCH_OPEN: 'NavigationConstants.ACTION_SEARCH_OPEN',
  ACTION_SEARCH_CLOSE: 'NavigationConstants.ACTION_SEARCH_CLOSE',
};
