import React, { useState } from 'react';

import { SmallText } from 'spa/features/ui/EscrowTypography';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';


const CancelPaymentDialog = (props) => {
    const [open, setOpen] = useState(false);
    const { cancelPaymentUrl } = props;

    return (
      <React.Fragment>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          pt: '30px',
        }}>
          <Button
            disableRipple
            onClick={() => setOpen(true)}
            variant="text"
            sx={{ 
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <SmallText sx={{ color: 'red', textDecoration: 'underline' }}>
              Cancel payment
            </SmallText>
          </Button>
          <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>
              <SmallText sx={{ fontWeight: 500, fontSize: '24px' }}>Cancel your payment?</SmallText>
            </DialogTitle>
            <DialogContent>
              <SmallText>
                After confirming your cancellation, you'll be redirected to the store checkout. 
                If you change your mind, you can select Escrow Pay again.
              </SmallText>
            </DialogContent>
            <DialogActions sx={{ padding: "2.0rem" }}>
              <Button variant="outlined" color="success" sx={{ textTransform: 'none' }} onClick={() => setOpen(false)}>
                <SmallText>No</SmallText>
              </Button>
              <Button variant="contained" color="error" sx={{ textTransform: 'none' }} onClick={() => { window.location.href = cancelPaymentUrl }}>
                <SmallText sx={{ color: 'white' }}>Yes</SmallText>
              </Button>
            </DialogActions>
          </Dialog>
        </Box>  
      </React.Fragment>
    );
  }

export default CancelPaymentDialog;
