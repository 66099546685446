import React from 'react';
import { connect } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { checkoutPaymentTypeSelector } from 'spa/selectors/CheckoutSelectors';
import EscrowTheme from 'spa/components/MaterialUI/Theme';
import V2KYC from 'spa/containers/KYC/V2/V2KYC';
import { KYCContext, CHECKOUT_FLOW } from 'spa/context/KYCContext.js';
import PaymentConstants from 'spa/constants/PaymentConstants';

const KYCCheckoutContainer = ({
  onNextPage,
  onEkycSubmission,
  forceEkycDisabled,
  forceCountry,
}) => (
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <EscrowTheme>
      <KYCContext.Provider
        value={{
          flow: CHECKOUT_FLOW,
          forceEkycDisabled,
          forceCountry,
          onNextPage,
          onEkycSubmission,
        }}
      >
        <V2KYC />
      </KYCContext.Provider>
    </EscrowTheme>
  </LocalizationProvider>
);

const mapStateToProps = (state) => ({
  forceCountry:
    checkoutPaymentTypeSelector(state) === PaymentConstants.PAYMENT_METHODS.DIRECT_DEBIT && 'us',
  forceEkycDisabled: ![PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER].includes(
    checkoutPaymentTypeSelector(state)
  ),
});

export default connect(mapStateToProps)(KYCCheckoutContainer);
