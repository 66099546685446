import React from 'react';
import InfoTooltip from './InfoTooltip';
import FieldError from './FieldError';

class VatCountrySelect extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (state.lastInputValue !== props.input.value && state.selectedValue !== props.input.value) {
      return {
        selectedValue: props.input.value,
        lastInputValue: props.input.value,
      };
    }

    return {
      lastInputValue: props.input.value,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedValue: props.input.value,
      lastInputValue: props.input.value,
    };

    this.handleChange = this.handleChange.bind(this);
    this.renderSelectOptions = this.renderSelectOptions.bind(this);
  }

  handleChange(event) {
    this.setState({
      selectedValue: event.target.value,
    });

    this.props.input.onChange(event);
  }

  renderSelectOptions() {
    const { options } = this.props;
    return options.map((option) => (
      <option key={option.value} value={option.value}>
        {this.state.selectedValue === option.value
          ? option.value
          : `${option.label} (${option.value})`}
      </option>
    ));
  }

  render() {
    const {
      label,
      input,
      meta: { error, touched, warning },
      requiredIndicator,
      tooltip,
      prefix,
      flag,
      className,
      children,
    } = this.props;
    let dropdownClass = 'field';
    if (error && touched) {
      dropdownClass += ' is-invalid';
    }

    return (
      <div className={`${dropdownClass} ${className}`} key={input.name}>
        {label && (
          <label htmlFor={input.name} className="field-label">
            {label}
            {children}
            {requiredIndicator && <span className="field-required">*</span>}
            {tooltip && <InfoTooltip id={`${name}-field-input-tooltip`} message={tooltip} />}
          </label>
        )}
        <div className="field-input">
          {prefix && <div className="field-prefix">{prefix}</div>}
          <div className={`defaultSelect defaultSelect--form ${flag && 'defaultSelect--flags'}`}>
            {flag && <span className="defaultSelect-flag" data-select-value={flag} />}
            <select
              id={input.name}
              className="defaultSelect-select"
              {...input}
              onChange={this.handleChange}
              data-e2e-target={this.props['data-e2e-target']}
            >
              {this.props.placeholder && (
                <option value="" disabled>
                  {this.props.placeholder}
                </option>
              )}
              {this.renderSelectOptions()}
            </select>
          </div>
        </div>
        {error && touched && <FieldError message={error} />}
        {warning && touched && <FieldError message={warning} />}
      </div>
    );
  }
}

export default VatCountrySelect;
