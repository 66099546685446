import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Grid from '@mui/material/Grid';
import { sum } from 'lodash';

import { SmallText, MediumText } from 'spa/features/ui/EscrowTypography';
import { formatCurrency } from 'spa/components/StartTransaction/util';
import PaymentConstants from 'spa/constants/PaymentConstants';
import { EscrowButton, EscrowLinkButton } from 'spa/features/ui/EscrowButton';
import { calculateFees } from '../../utils/transactionResponse';
import { useGetTransactionWireInstructionsQuery } from '../../transactionApi';
import { transactionSelector } from '../../transactionSlice';

export const WireInstructions = ({ transactionId }) => {
  const theme = useTheme();
  const isMobileViewport = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState(false);
  const transaction = useSelector(transactionSelector(transactionId));
  const customer = useSelector((state) => state.customer);
  const {
    data: wireInstructions,
    isLoading,
    error,
  } = useGetTransactionWireInstructionsQuery(transactionId);

  const handleModalClose = () => {
    if (isLoading) {
      return;
    }
    setOpen(false);
  };

  const transferDetails = useMemo(() => {
    if (!wireInstructions) {
      return null;
    }
    const detailsList = [];

    const fieldList = Object.keys(wireInstructions);
    PaymentConstants.WIRE_FIELD_ORDER.map((field) => {
      if (fieldList.includes(field)) {
        if (
          field !== 'bank_account_number' ||
          wireInstructions.bank_account_number !== wireInstructions.credit_account_number
        ) {
          detailsList.push({
            name: PaymentConstants.apiWireNameMapping[field],
            value: wireInstructions[field],
            key: field,
          });
        }
      }
    });
    return detailsList;
  }, [wireInstructions]);

  const transactionTotal = useMemo(() => {
    if (!transaction || !customer) {
      return 0;
    }
    const transactionFees = calculateFees(transaction, customer.custEmail);
    const itemTotal = transaction.transactionItems.reduce(
      (runningTotal, item) => runningTotal + item.amount,
      0
    );

    // User is only shown this modal if they are the buyer so we
    // never subtract the transaction fee
    return itemTotal + sum(Object.values(transactionFees));
  }, [transaction, customer]);

  return (
    <React.Fragment>
      <EscrowButton variant="outlined" onClick={() => setOpen(true)}>
        View wire instructions
      </EscrowButton>
      <Dialog
        fullScreen={isMobileViewport}
        open={open}
        onClose={handleModalClose}
        PaperProps={{ sx: { maxWidth: { md: '685px' }, width: { xs: '100vw', md: '100%' } } }}
      >
        {isMobileViewport && (
          <IconButton
            sx={{ marginLeft: 'auto' }}
            onClick={handleModalClose}
            aria-label="Close Wire Transfer Instructions Modal"
          >
            <CloseIcon sx={{ color: 'mono.dark', height: 24, width: 24 }} />
          </IconButton>
        )}

        <DialogTitle>
          <MediumText>Wire transfer instructions</MediumText>
        </DialogTitle>
        <DialogContent>
          <SmallText sx={{ marginBottom: '16px' }}>
            Set up a wire transfer of{' '}
            <SmallText variant="bold">
              {transaction
                ? formatCurrency(transactionTotal, transaction.currency)
                : transactionTotal}
            </SmallText>{' '}
            to our bank account found below.
          </SmallText>
          <Grid container>
            {transferDetails &&
              transferDetails.map((detail) => (
                <React.Fragment key={detail.key}>
                  <Grid item xs={12} md={6} sx={{ marginBottom: '8px' }}>
                    <SmallText variant="bold">{detail.name.trim()}:</SmallText>
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ marginBottom: '8px' }}>
                    <SmallText>{detail.value}</SmallText>
                  </Grid>
                </React.Fragment>
              ))}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}>
          {error && (
            <Typography color={'red'} sx={{ paddingRight: '8px' }}>
              An error has occured. Please try again.
            </Typography>
          )}
          <EscrowLinkButton
            variant="text"
            to={`/transaction/${transactionId}/wire-instructions`}
            hard
            newTab
          >
            <FileDownloadOutlinedIcon />
            Download as PDF
          </EscrowLinkButton>
          <EscrowButton
            variant="contained"
            onClick={handleModalClose}
            sx={{ width: { xs: '100%', md: 'auto' } }}
          >
            Done
          </EscrowButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
