import { createRoutine } from 'redux-saga-routines';

import PartnerConstants from 'spa/constants/PartnerConstants';

export const getTransactionList = createRoutine(PartnerConstants.GET_TRANSACTIONS);
export const getTransactionStatistics = createRoutine(PartnerConstants.GET_TRANSACTION_STATISTICS);
export const getCustomerList = createRoutine(PartnerConstants.GET_CUSTOMERS);
export const getCustomerStatistics = createRoutine(PartnerConstants.GET_CUSTOMER_STATISTICS);
export const getTransactionPartyInfo = createRoutine(PartnerConstants.GET_TRANSACTION_PARTY_INFO);
export const getReportsList = createRoutine(PartnerConstants.GET_REPORTS_LIST);
export const getReportsCount = createRoutine(PartnerConstants.GET_REPORTS_COUNT);

export const pushTransactionCountByInitiationDate = (payload) => ({
  type: PartnerConstants.PUSH_TRANSACTION_COUNT_BY_INITIATION_DATE,
  payload,
});
export const pushTransactionCountByCloseDate = (payload) => ({
  type: PartnerConstants.PUSH_TRANSACTION_COUNT_BY_CLOSE_DATE,
  payload,
});
export const pushOngoingTransactionCount = (payload) => ({
  type: PartnerConstants.PUSH_ONGOING_TRANSACTION_COUNT,
  payload,
});
export const pushTransactionListPageTransactionCount = (payload) => ({
  type: PartnerConstants.PUSH_TRANSACTION_LIST_PAGE_TRANSACTION_COUNT,
  payload,
});
export const resetTransactionListPageTransactionCount = (payload) => ({
  type: PartnerConstants.RESET_TRANSACTION_LIST_PAGE_TRANSACTION_COUNT,
  payload,
});
export const pushFilteredTransactionData = (payload) => ({
  type: PartnerConstants.PUSH_FILTERED_TRANSACTION_DATA,
  payload,
});
export const pushCustomerCountByDateCreated = (payload) => ({
  type: PartnerConstants.PUSH_CUSTOMER_COUNT_BY_DATE_CREATED,
  payload,
});
export const pushUserListPageUsersCount = (payload) => ({
  type: PartnerConstants.PUSH_USER_LIST_PAGE_USERS_COUNT,
  payload,
});
export const resetUserListPageUsersCount = (payload) => ({
  type: PartnerConstants.RESET_USER_LIST_PAGE_USERS_COUNT,
  payload,
});
export const pushFilteredCustomerData = (payload) => ({
  type: PartnerConstants.PUSH_FILTERED_CUSTOMER_DATA,
  payload,
});

export const updateTransactionListFilter = (payload) => ({
  type: PartnerConstants.UPDATE_TRANSACTION_LIST_FILTER,
  payload,
});

export const updateTransactionTableFilter = (payload) => ({
  type: PartnerConstants.UPDATE_TRANSACTION_TABLE_FILTER,
  payload,
});

export const updateCustomerTableFilter = (payload) => ({
  type: PartnerConstants.UPDATE_CUSTOMER_TABLE_FILTER,
  payload,
});

export const updateReportsTableFilter = (payload) => ({
  type: PartnerConstants.UPDATE_REPORTS_TABLE_FILTER,
  payload,
});

export const getCustomerPaymentsIn = createRoutine(PartnerConstants.GET_CUSTOMER_PAYMENTS_IN);
export const getCustomerPaymentsOut = createRoutine(PartnerConstants.GET_CUSTOMER_PAYMENTS_OUT);

export const getTransactionHoldingPenConditions = createRoutine(
  PartnerConstants.GET_TRANSACTION_HOLDING_PEN_CONDITIONS
);

export const getPartnerLogoSettings = createRoutine(PartnerConstants.GET_PARTNER_LOGO_SETTINGS);
export const updatePartnerLogoSettings = createRoutine(
  PartnerConstants.UPDATE_PARTNER_LOGO_SETTINGS
);
