import React from 'react';
import { withRouter } from 'react-router-dom';
import ErrorMessageBox from 'spa/components/ErrorMessageBox';
import { ConfirmationMessageBox } from 'spa/components/CheckoutDetails';
import MainFunnelHeader from 'spa/components/MainFunnelHeader';

const AdyenResultPage = () => {
  const { paymentResult = {}, returnUrl, paymentOrigin, isOutstandingPayment } = window.js_context;
  const { resultCode, refusalReason } = paymentResult;
  const payment = isOutstandingPayment ? 'outstanding balance payment' : 'payment';
  let success = false;
  let message;

  switch (resultCode) {
    case 'Authorised':
    case 'Pending':
    case 'Received':
      setTimeout(() => location.assign(returnUrl), 3000);
      message = `Success! Your transaction's ${payment} has been sent. Please wait for Escrow.com to confirm your payment`;
      success = true;
      break;
    case 'Refused':
      setTimeout(() => location.assign(paymentOrigin), 3000);
      message = 'Your payment was refused.  Please try again with a different payment method.';
      break;
    case 'Error':
      message = `The following error occured while processing your payment: ${refusalReason}`;
      break;
    default:
      message =
        'An unexpected error has occured while processing your payment.  Please contact support@escrow.com';
  }

  return (
    <div>
      <MainFunnelHeader />
      <div className="accountInfo">
        <section className="section section--small section--mid">
          <div className="section-container">
            <div className="account-inner">
              <div className="checkout-card checkout-card-secondary card">
                <div className="checkout-card-content checkout-card-content--light">
                  {success ? (
                    <ConfirmationMessageBox isCurrentStep heading={message} />
                  ) : (
                    <ErrorMessageBox isCurrentStep heading={message} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default withRouter(AdyenResultPage);
