import React from 'react';
import ReactSVG from 'react-svg';

const Icon = ({ name, className, wrapper = 'div' }) => (
  <ReactSVG
    src={`../../../../build/images/global/icons/${name}.svg`}
    beforeInjection={(svg) => {
      if (className) {
        svg.classList.add(...className.split(' '));
      }
    }}
    wrapper={wrapper}
  />
);

export default Icon;
