import classNames from 'domkit/classNames';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, getFormMeta, getFormSyncErrors, getFormSyncWarnings } from 'redux-form';
import FieldError from './FieldError';

class RadioGroup extends React.Component {
  constructor(props) {
    super(props);
    this.renderRadioOptions = this.renderRadioOptions.bind(this);
  }

  renderRadioOptions(option) {
    const e2e = this.props['data-e2e-target'];
    return (
      <label key={option.value} className="defaultRadio defaultRadio--inline">
        <Field
          className="defaultRadio-input"
          name={this.props.name}
          value={option.value}
          validate={this.props.validate}
          disabled={this.props.submitting || option.disabled}
          component="input"
          type="radio"
          data-e2e-target={e2e ? `${e2e}-${option.value}` : null}
        />
        <div className="defaultRadio-pseudo" />
        <span className="defaultRadio-label">{option.label}</span>
      </label>
    );
  }

  render() {
    const { label, options, requiredIndicator, className } = this.props;
    const radioGroupClass = classNames({
      field: true,
      'is-invalid': this.props.error && this.props.touched,
    });
    return (
      <div className={`${radioGroupClass} ${className}`}>
        {label && (
          <label className="field-label">
            {label}
            {requiredIndicator && <span className="field-required">*</span>}
          </label>
        )}
        <div className="field-input">{options.map(this.renderRadioOptions)}</div>
        {this.props.error && this.props.touched && <FieldError message={this.props.error} />}
        {this.props.warning && this.props.touched && <FieldError message={this.props.warning} />}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  touched: (getFormMeta(ownProps.formName)(state) || {})[ownProps.name],
  warning: (getFormSyncWarnings(ownProps.formName)(state) || {})[ownProps.name],
  error: (getFormSyncErrors(ownProps.formName)(state) || {})[ownProps.name],
});

const RadioGroupContainer = connect(mapStateToProps)(RadioGroup);

RadioGroupContainer.propTypes = {
  formName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default RadioGroupContainer;
