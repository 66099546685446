import React, { useState, useCallback, Fragment } from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { PasswordInput } from 'spa/components/form/Input';
import Footer from 'spa/containers/Footer';
import { Spinner } from 'spa/components/StartTransaction';
import { Spinner as ButtonSpinner } from 'spa/components/Indicators';
import { useForm } from 'react-hook-form';
import { Modal } from 'escrow-common-js/dist/components';
import ReactSVG from 'react-svg';
import { withRouter } from 'react-router-dom';
import { useCheckTokenValidQuery, useResetPasswordMutation } from '../resetPasswordApi';
import { gettext } from '../../../../utils/filters';
import { urlFor } from '../../../../routeConfig';

const ResetPasswordForm = ({
  handleSubmit,
  onSubmit,
  register,
  errors,
  getFieldState,
  getValues,
  passwordResetLoading,
}) => (
  <Fragment>
    <h2 className="checkout-heading">Reset your password</h2>
    <form onSubmit={handleSubmit(onSubmit)}>
      <PasswordInput
        copy="Enter a new password"
        label="newPassword"
        register={register}
        errors={errors}
        passwordValidation
        required
        isDirty={getFieldState('newPassword').isDirty}
      />
      <PasswordInput
        copy="Re-enter your new password"
        label="repeatedNewPassword"
        register={register}
        errors={errors}
        getValues={getValues}
        matchId="newPassword"
        isDirty={getFieldState('repeatedNewPassword').isDirty}
        required
      />
      {/* Because of the dom elements used for the form we need this hidden
    input to capture enter key press for form submission  */}
      <input type="submit" hidden />
    </form>
    <div className="checkout-card-btn-container">
      <button
        type="submit"
        className="checkout-card-btn checkout-card-btn--auth btn btn--secondary"
        onClick={handleSubmit(onSubmit)}
        disabled={passwordResetLoading}
      >
        {passwordResetLoading ? <ButtonSpinner /> : gettext('Continue')}
      </button>
    </div>
  </Fragment>
);

const ResetTokenExpiredMessage = () => (
  <div className="checkout-card-placeholder">
    The password reset link has expired.
    <br />
    Please request for a new one <a href="/forgot/password">here.</a>{' '}
  </div>
);

const ResetPasswordPage = (props) => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const {
    register,
    handleSubmit,
    getFieldState,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
  });

  const [triggerPasswordReset, { isLoading: passwordResetLoading, error: passwordResetError }] =
    useResetPasswordMutation();

  const { error: tokenInvalidError, isLoading: checkingTokenValidity } = useCheckTokenValidQuery(
    props.match.params.token
  );

  const onSubmit = useCallback(
    async (data) => {
      try {
        await triggerPasswordReset({
          token: props.match.params.token,
          new_password: data.newPassword,
        }).unwrap();
        reset();
        setModalOpen(true);
      } catch (error) {
        // Do nothing as the exception is already handled elsewhere
      }
    },
    [triggerPasswordReset, props.match.params.token, reset]
  );

  const closeModal = () => {
    setModalOpen(false);
    dispatch(push(urlFor('login_page')));
  };

  return (
    <div>
      <Modal
        isOpen={modalOpen}
        paddingSize=""
        targetElementId="spa"
        exitButton={false}
        enableBackdropClick
        onBackdropClick={closeModal}
        cardClassName="transactions-modal-fullscreen-card"
      >
        <div className="transactions-modal-fullscreen-content">
          <ReactSVG
            className="transactions-modal-fullscreen--svg"
            src="../../build/images/global/icons/escrow-shield.svg"
            beforeInjection={(svg) => {
              svg.classList.add('transactions-modal-fullscreen--svg--size');
            }}
          />
          <div className="transactions-modal-fullscreen--title">
            You&apos;ve successfully updated your password.
          </div>
          <button
            className="btn btn--secondary"
            onClick={(e) => {
              e.stopPropagation();
              closeModal();
            }}
          >
            {'Close'}
          </button>
        </div>
      </Modal>
      <div className="verifyPage section section--mid">
        <div className="checkout-content section-container">
          <div className="verifyPage-body">
            <div className="verifyPage-card">
              <div className="checkout-card-content signupForm-card checkout-card-content--auth">
                {checkingTokenValidity ? (
                  <div className="checkout-card-placeholder">
                    <Spinner />
                  </div>
                ) : (
                  <div>
                    {tokenInvalidError || passwordResetError ? (
                      <ResetTokenExpiredMessage />
                    ) : (
                      <ResetPasswordForm
                        handleSubmit={handleSubmit}
                        onSubmit={onSubmit}
                        register={register}
                        errors={errors}
                        getFieldState={getFieldState}
                        getValues={getValues}
                        passwordResetLoading={passwordResetLoading}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className="checkout-switcher checkout-card-content signupForm-card" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(ResetPasswordPage);
