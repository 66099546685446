var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/icons/right.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_2 = "";t_2 += "<svg version=\"1.1\" class=\"icon icon--right\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewbox=\"0 0 20 20\" enable-background=\"new 0 0 20 20\" xml:space=\"preserve\">\n<path d=\"M10,20C4.5,20,0,15.5,0,10C0,4.5,4.5,0,10,0s10,4.5,10,10C20,15.5,15.5,20,10,20z M10,1.9c-4.5,0-8.1,3.6-8.1,8.1\n\tc0,4.5,3.6,8.1,8.1,8.1s8.1-3.6,8.1-8.1C18.1,5.5,14.5,1.9,10,1.9z M9.1,14.1L9.1,14.1c-0.2,0.2-0.4,0.2-0.6,0.2\n\tc-0.5,0-0.9-0.4-0.9-0.9c0-0.3,0.1-0.5,0.3-0.7l0,0l3-2.7l-3-2.7l0,0C7.6,7.1,7.5,6.8,7.5,6.6c0-0.5,0.4-0.9,0.9-0.9\n\tc0.2,0,0.5,0.1,0.6,0.2l0,0l3.8,3.4c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7L9.1,14.1z\"/>\n</svg>";
;
frame = frame.pop();
return new runtime.SafeString(t_2);
});
context.addExport("right");
context.setVariable("right", macro_t_1);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/icons/right.html"] , dependencies)