const notificationSettingSelector = (state) => state.notificationSetting;

const notificationPreferencesSelector = (state) =>
  notificationSettingSelector(state).preferences || {};

const containerSelector = (state) => notificationSettingSelector(state).display || {};

const loadingSelector = (state) => containerSelector(state).loading;

const successSelector = (state) => containerSelector(state).success;

export { notificationPreferencesSelector, loadingSelector, successSelector };
