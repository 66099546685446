import request from 'browser-request';
import window from 'window-shim';

import AppDispatcher from '../dispatcher/AppDispatcher';
import AuthenticationConstants from '../constants/AuthenticationConstants';
import ErrorMessages from '../constants/ErrorMessages';
import FormConstants from '../constants/FormConstants';
import StartTransactionActions from './StartTransactionActions';
import InlineAuthActions from '../actions/InlineAuthActions';
import NavigationConstants from '../constants/NavigationConstants';
import TrackingActions from '../actions/TrackingActions';
import API from '../api/API';

export class AuthenticationActions {
  signup(data, formName) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.SUBMISSION_START,
      name: formName,
    });

    let countryCode = '';
    let phone = '';
    if (data.phone) {
      phone = data.phone;
      if (data['phone-country'] && data['phone-country-prefix']) {
        countryCode = data['phone-country'];
        const prefix = data['phone-country-prefix'];
        if (data.phone.indexOf(prefix) === -1) {
          phone = prefix + data.phone.replace(/^0+/, '');
        }
      }
    }

    const userData = {
      CompanyName: data['company-name'] || data['issue-company-name'],
      WebsiteUrl: data['website-url'],
      BriefDescription: data['service-description'],
      MonthlySalesRevenue: data['monthly-sales'],
      ShowCompanyNameInsteadOfName: false,
      FirstName: data['first-name'],
      MiddleName: data['middle-name'],
      LastName: data['last-name'],
      EmailAddress: data['email-address'],
      ConfirmEmailAddress: data['email-address'],
      Pwd: data.password,
      ConfirmPwd: data.password,
      MotherMaidenName: '',
      Address1: data['address-one'],
      Address2: data['address-two'],
      City: data.city,
      State: data.state,
      OtherState: data['other-state'],
      ZipCode: data.postcode,
      Country: data.country,
      Phone: phone,
      CountryCode: countryCode,
      PaymentsToCompanyName: false,
      IsPartner: formName === 'signup-partner' || formName === 'partner-signup-form',
    };

    if (formName === 'partner-signup-form') {
      userData.RegisterSource = AuthenticationConstants.REGISTER_SOURCES.INTEGRATIONS;
    }

    // undefined fields must be blank strings
    for (const val in userData) {
      if (!userData[val] && userData[val] !== false) {
        userData[val] = '';
      }
    }

    const securityQuestions = {
      SecurityQuestion: FormConstants.SECURITY_QUESTIONS[data['security-question-one-question']],
      Answer: data['security-question-one-answer'],
      SecurityQuestion2: FormConstants.SECURITY_QUESTIONS[data['security-question-two-question']],
      Answer2: data['security-question-two-answer'],
      SecurityQuestion3: FormConstants.SECURITY_QUESTIONS[data['security-question-three-question']],
      Answer3: data['security-question-three-answer'],
    };

    // Add security questions for partner signup only
    let apiData = {};
    if (formName === 'signup-partner') {
      apiData = Object.assign(userData, securityQuestions);
    } else {
      apiData = userData;
    }

    return request.post(
      {
        url: window.config.signup_api_endpoint,
        json: {
          registrationModel: JSON.stringify(apiData),
        },
        contentType: 'application/json;charset=UTF-8',
        withCredentials: true,
      },
      (error, response, body) => {
        if (
          !error &&
          response.status === 200 &&
          body &&
          body.HasData &&
          body.ClientData &&
          body.ClientData.IsRegistered
        ) {
          TrackingActions.track(
            {
              event: 'escrow_user_action',
              action: 'conversion',
              label: 'signup',
            },
            true
          );
          if (
            formName === 'signup-create' ||
            formName === 'login-agree' ||
            formName === 'register-agree' ||
            formName === 'partner-login-form' ||
            formName === 'partner-signup-form'
          ) {
            this.login(
              apiData.EmailAddress,
              apiData.Pwd,
              formName,
              data,
              AuthenticationConstants.SIGNUP
            );
          } else {
            AppDispatcher.handleServerAction({
              actionType: FormConstants.SUBMISSION_SUCCESS,
              name: formName,
            });
          }
        } else {
          let title;
          if (response.status !== 200 || !body) {
            title = ErrorMessages.TECHNICAL_DIFFICULTIES;
          } else if (!body.HasData) {
            title = ErrorMessages.INCOMPLETE_FORM;
          } else if (body.ClientData && body.ClientData.UserExist) {
            title = ErrorMessages.EMAIL_ALREADY_EXISTS_SIMPLE;
          }
          AppDispatcher.handleServerAction({
            actionType: FormConstants.SUBMISSION_FAILURE,
            name: formName,
            title: title,
            response: response,
          });
        }
      }
    );
  }

  signupShadow(email, source, formName) {
    const userData = {
      EmailAddress: email,
      RegisterSource: source,
      IsNonRegisteredUser: true,
    };

    request.post(
      {
        url: window.config.signup_api_endpoint,
        json: {
          registrationModel: JSON.stringify(userData),
        },
        contentType: 'application/json;charset=UTF-8',
        withCredentials: true,
      },
      (error, response, body) => {
        if (!error && response.status === 200 && body && body.HasData && body.ClientData) {
          AppDispatcher.handleServerAction({
            actionType: AuthenticationConstants.SIGNUP_SHADOW_SUCCESS,
            attributes: {
              email: email,
            },
          });
        } else {
          AppDispatcher.handleServerAction({
            actionType: AuthenticationConstants.SIGNUP_SHADOW_FAILURE,
          });
          if (formName) {
            AppDispatcher.handleServerAction({
              actionType: FormConstants.SUBMISSION_FAILURE,
              name: formName,
              title: ErrorMessages.TECHNICAL_DIFFICULTIES,
              response: response,
            });
          }
        }
      }
    );
  }

  userExists(email, formName) {
    API.checkIfEmailExist(email).then((exists) => {
      AppDispatcher.handleServerAction({
        actionType: AuthenticationConstants.EMAIL_EXISTS,
        form: formName,
        email: email,
        exists: exists,
      });
    });
  }

  _signup(username, password, additionalFields = {}) {
    const signupData = {
      CompanyName: '',
      WebsiteUrl: '',
      BriefDescription: '',
      MonthlySalesRevenue: '',
      ShowCompanyNameInsteadOfName: false,
      FirstName: '',
      MiddleName: '',
      LastName: '',
      EmailAddress: username,
      ConfirmEmailAddress: username,
      Pwd: password,
      ConfirmPwd: password,
      MotherMaidenName: '',
      Address1: '',
      Address2: '',
      City: '',
      State: '',
      OtherState: '',
      ZipCode: '',
      Country: '',
      Phone: '',
      CountryCode: '',
      PaymentsToCompanyName: false,
      IsPartner: false,
      ...additionalFields,
    };

    return window
      .fetch(window.config.signup_api_endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify({
          registrationModel: JSON.stringify(signupData),
        }),
        credentials: 'include',
      })
      .then((response) =>
        response.json().then((body) => {
          if (response.status === 200 && body.HasData) {
            return window.Promise.resolve();
          }
          return window.Promise.reject({ response, body });
        })
      );
  }

  _login(username, password) {
    return window
      .fetch(`${window.config.www_api_url}/api/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
        credentials: 'include',
      })
      .then((response) =>
        response
          .json()
          .then((body) => {
            if (response.status === 200) {
              // Reset the user_has_legacy_buttons when a new user logs in
              document.cookie = `user_has_legacy_buttons=; domain=${window.config.cookie_domain}; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
              return window.Promise.resolve(body);
            } else if (response.status === 403) {
              return window.Promise.reject(ErrorMessages.LOGIN_FAIL);
            }
            return window.Promise.reject();
          })
          .catch((error) => {
            if (error === ErrorMessages.LOGIN_FAIL) {
              return window.Promise.reject(error);
            }
            return window.Promise.reject(ErrorMessages.TECHNICAL_DIFFICULTIES);
          })
      );
  }

  login(username, password, formName, data, authMethod = AuthenticationConstants.LOGIN) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.SUBMISSION_START,
      name: formName,
    });
    return this._login(username, password).then(
      (body) => {
        AppDispatcher.handleServerAction({
          actionType: AuthenticationConstants.VERIFY,
          data: body,
        });
        if (formName === 'signup-create') {
          StartTransactionActions.startTransaction(formName);
        } else if (formName === 'login-agree' || formName === 'register-agree') {
          InlineAuthActions.agree(formName, data.tid, data['email-address'], authMethod);
        } else if (formName === 'partner-login-form' || formName === 'partner-signup-form') {
          AppDispatcher.handleServerAction({
            actionType: NavigationConstants.PARTNER_LOGIN_REDIRECT,
          });
        } else {
          AppDispatcher.handleServerAction({
            actionType: NavigationConstants.LOGIN_REDIRECT,
          });
        }
      },
      (error) => {
        AppDispatcher.handleServerAction({
          actionType: AuthenticationConstants.UNVERIFY,
        });
        AppDispatcher.handleServerAction({
          actionType: FormConstants.SUBMISSION_FAILURE,
          name: formName,
          title: error,
        });
      }
    );
  }

  verify() {
    request.get(
      {
        url: '/api/auth/verify',
        json: true,
      },
      (error, response, body) => {
        if (!error && response.status === 200) {
          AppDispatcher.handleServerAction({
            actionType: AuthenticationConstants.VERIFY,
            data: body,
          });
        } else {
          AppDispatcher.handleServerAction({
            actionType: AuthenticationConstants.UNVERIFY,
            data: body,
          });
        }
      }
    );
  }

  logout() {
    request.post(
      {
        url: '/api/auth/logout',
      },
      (error, response) => {
        if (!error && response.status === 200) {
          AppDispatcher.handleServerAction({
            actionType: AuthenticationConstants.LOGOUT,
          });
        }
      }
    );
  }

  setAuthenticationUrl(name) {
    AppDispatcher.handleViewAction({
      actionType: AuthenticationConstants.SET_AUTH_URL,
      name: name,
    });
  }
}

export default new AuthenticationActions();
