import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { updateTaskRequest } from 'spa/actions/TaskActions';
import { userLoadingSelector, userErrorSelector } from 'spa/selectors/UserSelectors';
import { makeTaskListSelector, updatingSelector } from 'spa/selectors/TaskSelectors';

import { Section } from 'spa/components/Section';
import { TaskList } from 'spa/components/TaskList';
import ProgressBar from 'spa/components/ProgressBar';
import { ErrorIndicator, Spinner } from 'spa/components/Indicators';

class DisplayTaskList extends React.PureComponent {
  render() {
    const {
      children,
      error,
      loading,
      taskList: { subtasks },
      updateTask,
    } = this.props;

    if (loading && !subtasks.length) {
      return <Spinner />;
    }

    return (
      <Section>
        {children}
        {error ? (
          <ErrorIndicator />
        ) : (
          subtasks.map((task) => (
            <div key={task.name} className="integrationsPortal-tasks">
              <div className="integrationsPortal-progress">
                <h3 className="integrationsPortal-progress-title integrationsPortal-section-subTitle">
                  {task.label}
                </h3>
                <span className="integrationsPortal-progress-bar">
                  <ProgressBar value={task.progressBarValue} label />
                </span>
              </div>
              <TaskList taskList={task.subtasks} updateTask={updateTask} />
            </div>
          ))
        )}
      </Section>
    );
  }
}

DisplayTaskList.propTypes = {
  children: PropTypes.node,
};

const makeMapStateToProps = () => {
  const taskListSelector = makeTaskListSelector();
  const mapStateToProps = (state, props) => ({
    loading: userLoadingSelector(state),
    error: userErrorSelector(state),
    taskList: taskListSelector(state, props.list),
    updating: updatingSelector(state, props.list),
  });
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  updateTask: (name, status, taskList) => dispatch(updateTaskRequest(name, status, taskList)),
});

const DisplayTaskListContainer = connect(makeMapStateToProps, mapDispatchToProps)(DisplayTaskList);

export default DisplayTaskListContainer;
