import React, { Fragment, forwardRef } from 'react';
import moment from 'moment';

import { MobileDatePicker, DatePicker as DesktopDatePicker } from '@mui/x-date-pickers';

export const DatePickerField = forwardRef((props, ref) => {
  const { error, helperText, mobile, onChange, ignoreTimezone } = props;

  const DatePicker = mobile ? MobileDatePicker : DesktopDatePicker;
  return (
    <Fragment>
      <DatePicker
        {...props}
        ref={ref}
        slotProps={{
          textField: {
            error: error,
            helperText: helperText,
          },
        }}
        onChange={(date) => {
          const formattedDate =
            (ignoreTimezone && moment(date).utcOffset(0, true).startOf('day')) || date;
          onChange(formattedDate);
        }}
      />
    </Fragment>
  );
});
