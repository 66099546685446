import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ReportsTable from 'spa/components/PartnerDashboard/tables/EnhancedTable';
import Icon from 'spa/components/Icon';
import {
  reportsCursorSelector,
  reportsDataSelector,
  reportsErrorSelector,
  reportsCountSelector,
  reportsTableFilterValueSelector,
  reportsListLoadingSelector,
} from 'spa/selectors/PartnerSelectors';
import { updateReportsTableFilter as updateReportsTableFilterAction } from 'spa/actions/PartnerActions';
import API from '../../../api/API';

const columns = [
  { id: 'filename', numeric: false, disablePadding: true, label: 'Filename' },
  { id: 'dateRequested', numeric: false, disablePadding: false, label: 'Date Requested' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' },
];
const mapDataToTable = (reports) => {
  if (reports) {
    return reports.map((report) => ({
      filename: report.filename || 'Pending',
      dateRequested: moment(report.date_requested).format('YYYY-MM-DD HH:mm:ss'),
      status: report.status === 'success' ? 'Generated' : 'Pending',
      actions:
        report.status === 'success' ? (
          <button
            className="partnerDashboard-downloadBtn btn"
            onClick={async () => {
              const { download_url: downloadUrl } = await API.downloadPartnerReport(
                report.id,
                true
              );
              window.location.href = downloadUrl;
            }}
          >
            <Icon name="ui-download" className="icon" />
            <span className="partnerDashboard-downloadBtn-text">Download</span>
          </button>
        ) : (
          ''
        ),
    }));
  }
  return [];
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  columns,
  error: reportsErrorSelector(state),
  entries: reportsDataSelector(state),
  entryCountTotal: reportsCountSelector(state),
  nextCursor: reportsCursorSelector(state),
  enableLink: false,
  mapDataToTable,
  tableFilter: reportsTableFilterValueSelector(state),
  loading: reportsListLoadingSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateTableFilter: (payload) => dispatch(updateReportsTableFilterAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsTable);
