import DisbursementConstants from 'spa/constants/DisbursementConstants';
import CheckoutConstants from 'spa/constants/CheckoutConstants';
import UserConstants from 'spa/constants/UserConstants';
import {
  fluxGetCustomerRequest,
  getV4Customer as getV4CustomerRoutine,
  deleteTaxNumber as deleteTaxNumberRoutine,
  listPartnerLogos as listPartnerLogosRoutine,
  removePartnerLogo as removePartnerLogoRoutine,
  setExtendedKycRequirement as setExtendedKycRequirementRoutine,
  getSavedCreditCards as getSavedCreditCardsRoutine,
  getUserEKYCData as getUserEKYCDataRoutine,
  getSavedPlaidAccounts as getSavedPlaidAccountsRoutine,
  getUserVerificationStatuses as getUserVerificationStatusesRoutine,
} from 'spa/actions/UserActions';
import {
  kycGotoNextPage as kycGotoNextPageRoutine,
  kycGotoPrevPage as kycGotoPrevPageRoutine,
  kycSubmitIndividual as kycSubmitIndividualRoutine,
  kycSubmitCustomerDetails as kycSubmitCustomerDetailsRoutine,
  kycUploadFile as kycUploadFileRoutine,
  kycSetIsCompany as kycSetIsCompanyRoutine,
  kycSubmitExtended as kycSubmitExtendedRoutine,
  kycSubmitCompany as kycSubmitCompanyRoutine,
} from 'spa/actions/VerificationActions';
import { sendVerificationEmail } from 'spa/actions/TwoFactorActions';
import VerificationConstants from 'spa/constants/VerificationConstants';

const initialState = {
  isCompany: false,
  personalAddress: {},
  companyAddress: {},
  companyName: '',
  custEmail: '',
  custId: null,
  requireExtendedKyc: null,
  verificationStatus: {},
  ui: {
    getExtendedKycRequirement: {
      loading: false,
    },
    v2GetCustomer: {
      loading: false,
    },
    v4GetCustomer: {
      loading: false,
      success: false,
    },
    v4DeleteTaxNumber: {
      loading: false,
    },
    partnerLogos: {
      loading: false,
    },
    savedCreditCards: {
      loading: false,
      calledOnce: false,
    },
    ekycData: {
      loading: false,
      success: false,
    },
    savedPlaidAccounts: {
      loading: false,
    },
    emailVerification: {
      loading: false,
      success: false,
    },
    kyc: {
      loading: false,
      success: false,
      subheaderText: '',
      error: null,
      pages: [],
      initPages: [],
      uploads: {},
    },
    kycSubmit: {
      loading: false,
      success: false,
    },
    verificationStatus: {
      loading: false,
      success: false,
    },
  },
  taxNumbers: [],
  partnerLogos: null,
  savedCreditCards: [],
  savedPlaidAccounts: [],
  emailVerification: {
    verified: true,
  },
};

function customerReducer(state = initialState, action) {
  let personalAddress = {};
  let companyAddress = {};
  switch (action.type) {
    case DisbursementConstants.GET_CUST_INFO:
      return {
        ...state,
        personalAddress: action.custData.PersonalAddress,
      };
    case setExtendedKycRequirementRoutine.REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          getExtendedKycRequirement: {
            loading: true,
          },
        },
      };
    case setExtendedKycRequirementRoutine.SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          getExtendedKycRequirement: {
            loading: false,
          },
        },
        requireExtendedKyc: action.payload.extended_kyc,
        extendedKycSubmitted: action.payload.extended_kyc_submitted,
        extendedKycVerified: action.payload.extended_kyc_verified,
        extendedKycRejected: action.payload.extended_kyc_rejected,
      };
    case setExtendedKycRequirementRoutine.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          getExtendedKycRequirement: {
            loading: false,
          },
        },
      };
    case UserConstants.SET_USER_DETAILS_FROM_V2:
      personalAddress = action.v2CustomerData.PersonalAddress || {};
      companyAddress = action.v2CustomerData.CompanyAddress || {};
      return {
        ...state,
        custId: action.custId,
        custEmail: action.v2CustomerData.Email,
        verificationStatus: {
          ...state.verificationStatus,
          hasContactDetails: action.v2CustomerData.VerificationStatus.HasContactDetails,
          company: {
            isApproved: action.v2CustomerData.VerificationStatus.Company.IsApproved,
            isInProgress: action.v2CustomerData.VerificationStatus.Company.IsInProgress,
            isSubmitted: action.v2CustomerData.VerificationStatus.Company.IsSubmitted,
          },
          individual: {
            ...state.verificationStatus.individual,
            isApproved: action.v2CustomerData.VerificationStatus.Individual.IsApproved,
            isInProgress: action.v2CustomerData.VerificationStatus.Individual.IsInProgress,
            isSubmitted: action.v2CustomerData.VerificationStatus.Individual.IsSubmitted,
          },
        },
        isCompany: action.v2CustomerData.IsCompany,
        companyName: action.v2CustomerData.CompanyName,
        firstName: action.v2CustomerData.FirstName,
        middleName: action.v2CustomerData.MiddleName,
        lastName: action.v2CustomerData.LastName,
        localFName: action.v2CustomerData.LocalFName,
        localMName: action.v2CustomerData.LocalMName,
        localLName: action.v2CustomerData.LocalLName,
        dateOfBirth: action.v2CustomerData.DateOfBirth,
        phoneNumber: action.v2CustomerData.Phone,
        showCreditCardAuthorizationForm: action.v2CustomerData.ShowCreditCardAuthorizationForm,
        email: action.v2CustomerData.Email,
        personalAddress: {
          city: personalAddress.City,
          country: personalAddress.Country,
          state: personalAddress.State,
          line1: personalAddress.Street1,
          line2: personalAddress.Street2,
          zip: personalAddress.Zip,
        },
        companyAddress: {
          city: companyAddress.City,
          country: companyAddress.Country,
          state: companyAddress.State,
          line1: companyAddress.Street1,
          line2: companyAddress.Street2,
          zip: companyAddress.Zip,
        },
      };
    case UserConstants.SET_USER_DETAILS_FROM_V4:
      return {
        ...state,
        taxNumbers: action.v4CustomerData.tax_numbers || [],
        displayName: action.v4CustomerData.display_name,
        emailVerification: {
          verified:
            !!action.v4CustomerData.customer_email_verification &&
            action.v4CustomerData.customer_email_verification.verified,
        },
      };
    case CheckoutConstants.ACTION_SET_REDUX_CUSTOMER_DETAILS:
      personalAddress = action.payload.PersonalAddress || {};
      companyAddress = action.payload.CompanyAddress || {};
      return {
        ...state,
        verificationStatus: {
          ...state.verificationStatus,
          hasContactDetails: true,
        },
        firstName: action.payload.FirstName,
        lastName: action.payload.LastName,
        dateOfBirth: action.payload.DateOfBirth,
        phoneNumber: action.payload.Phone,
        isCompany: action.payload.IsCompany,
        companyName: action.payload.CompanyName,
        personalAddress: {
          city: personalAddress.City,
          country: personalAddress.Country,
          state: personalAddress.State,
          line1: personalAddress.Street1,
          line2: personalAddress.Street2,
          zip: personalAddress.Zip,
        },
        companyAddress: {
          city: companyAddress.City,
          country: companyAddress.Country,
          state: companyAddress.State,
          line1: companyAddress.Street1,
          line2: companyAddress.Street2,
          zip: companyAddress.Zip,
        },
      };
    case UserConstants.SET_USER_HAS_CONTACT_DETAILS:
      return {
        ...state,
        verificationStatus: {
          ...state.verificationStatus,
          hasContactDetails: action.payload.hasContactDetails,
        },
      };
    case UserConstants.UPDATE_INDIVIDUAL_VERIFICATION_STATUS:
      return {
        ...state,
        verificationStatus: {
          ...state.verificationStatus,
          individual: {
            ...state.verificationStatus.individual,
            ...action.payload,
            ...(action.payload.electronicVerification && {
              electronicVerification: {
                ...state.verificationStatus.individual.electronicVerification,
                ...action.payload.electronicVerification,
              },
            }),
          },
        },
      };
    case UserConstants.UPDATE_COMPANY_VERIFICATION_STATUS:
      return {
        ...state,
        verificationStatus: {
          ...state.verificationStatus,
          company: {
            ...state.verificationStatus.company,
            ...action.payload,
          },
        },
      };
    case fluxGetCustomerRequest.TRIGGER:
      return {
        ...state,
        ui: {
          ...state.ui,
          v2GetCustomer: {
            loading: true,
          },
        },
      };
    case getV4CustomerRoutine.TRIGGER:
    case getV4CustomerRoutine.REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          v4GetCustomer: {
            ...state.ui.v4GetCustomer,
            loading: true,
          },
        },
      };
    case fluxGetCustomerRequest.SUCCESS:
    case fluxGetCustomerRequest.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          v2GetCustomer: {
            loading: false,
          },
        },
      };
    case getV4CustomerRoutine.SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          v4GetCustomer: {
            loading: false,
            success: true,
          },
        },
      };
    case getV4CustomerRoutine.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          v4GetCustomer: {
            loading: false,
            success: state.ui.v4GetCustomer.success || false,
          },
        },
      };
    case deleteTaxNumberRoutine.TRIGGER:
      return {
        ...state,
        ui: {
          ...state.ui,
          v4DeleteTaxNumber: {
            loading: true,
          },
        },
      };
    case deleteTaxNumberRoutine.SUCCESS: {
      const newState = {
        ...state,
        ui: {
          ...state.ui,
          v4DeleteTaxNumber: {
            loading: false,
          },
        },
        taxNumbers: [...state.taxNumbers],
      };

      newState.taxNumbers = newState.taxNumbers.filter(
        (taxNumber) => String(taxNumber.id) !== String(action.payload.taxNumberId)
      );
      return newState;
    }
    case deleteTaxNumberRoutine.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          v4DeleteTaxNumber: {
            loading: false,
          },
        },
      };
    case listPartnerLogosRoutine.REQUEST:
    case removePartnerLogoRoutine.REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          partnerLogos: {
            loading: true,
          },
        },
      };
    case listPartnerLogosRoutine.SUCCESS:
      return {
        ...state,
        partnerLogos: action.payload.submissions,
      };
    case listPartnerLogosRoutine.FULFILL:
    case removePartnerLogoRoutine.FULFILL:
      return {
        ...state,
        ui: {
          ...state.ui,
          partnerLogos: {
            loading: false,
          },
        },
      };
    case getSavedPlaidAccountsRoutine.REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          savedPlaidAccounts: {
            loading: true,
          },
        },
      };
    case getSavedPlaidAccountsRoutine.TRIGGER:
      return {
        ...state,
        ui: {
          ...state.ui,
          savedPlaidAccounts: {
            loading: true,
          },
        },
      };
    case getSavedPlaidAccountsRoutine.SUCCESS:
      return {
        ...state,
        savedPlaidAccounts: action.payload.savedPlaidAccounts,
        ui: {
          ...state.ui,
          savedPlaidAccounts: {
            loading: false,
          },
        },
      };
    case getSavedPlaidAccountsRoutine.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          savedPlaidAccounts: {
            loading: false,
          },
        },
      };

    case getSavedCreditCardsRoutine.TRIGGER:
      return {
        ...state,
        ui: {
          ...state.ui,
          savedCreditCards: {
            calledOnce: true,
            loading: true,
          },
        },
      };
    case getSavedCreditCardsRoutine.SUCCESS:
      return {
        ...state,
        savedCreditCards: action.payload.savedCreditCards,
        ui: {
          ...state.ui,
          savedCreditCards: {
            ...state.ui.savedCreditCards,
            loading: false,
          },
        },
      };
    case getSavedCreditCardsRoutine.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          savedCreditCards: {
            ...state.ui.savedCreditCards,
            loading: false,
          },
        },
      };
    case getUserEKYCDataRoutine.TRIGGER:
    case getUserEKYCDataRoutine.REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          ekycData: {
            loading: true,
          },
        },
      };
    case getUserEKYCDataRoutine.SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          ekycData: {
            loading: false,
            success: true,
          },
        },
      };
    case getUserEKYCDataRoutine.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          ekycData: {
            loading: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case getUserVerificationStatusesRoutine.TRIGGER:
    case getUserVerificationStatusesRoutine.REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          verificationStatus: {
            loading: true,
          },
        },
      };
    case getUserVerificationStatusesRoutine.SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          verificationStatus: {
            loading: false,
            success: true,
          },
        },
      };
    case getUserVerificationStatusesRoutine.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          verificationStatus: {
            loading: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case sendVerificationEmail.REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          emailVerification: {
            loading: true,
            success: false,
          },
        },
      };
    case sendVerificationEmail.SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          emailVerification: {
            loading: false,
            success: true,
          },
        },
      };
    case sendVerificationEmail.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          emailVerification: {
            loading: false,
            success: false,
          },
        },
      };
    case kycGotoPrevPageRoutine.REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          kyc: {
            ...state.ui.kyc,
            loading: true,
          },
        },
      };
    case kycGotoPrevPageRoutine.SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          kyc: {
            ...state.ui.kyc,
            loading: false,
            success: true,
            pages: state.ui.kyc.pages.slice(0, -1),
          },
        },
      };
    case kycGotoPrevPageRoutine.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          kyc: {
            ...state.ui.kyc,
            loading: false,
            success: false,
          },
        },
      };
    case kycGotoNextPageRoutine.REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          kyc: {
            ...state.ui.kyc,
            loading: true,
          },
        },
      };
    case kycGotoNextPageRoutine.SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          kyc: {
            ...state.ui.kyc,
            loading: false,
            success: true,
            pages: [
              ...(action.payload.purgeHistory ? [] : state.ui.kyc.pages),
              action.payload.nextPage,
            ],
          },
        },
      };
    case kycGotoNextPageRoutine.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          kyc: {
            ...state.ui.kyc,
            loading: false,
            success: false,
          },
        },
      };
    case VerificationConstants.KYC_SET_SUBHEADER:
      return {
        ...state,
        ui: {
          ...state.ui,
          kyc: {
            ...state.ui.kyc,
            subheaderText: action.payload,
          },
        },
      };
    case VerificationConstants.KYC_SET_ERROR_ALERT:
      return {
        ...state,
        ui: {
          ...state.ui,
          kyc: {
            ...state.ui.kyc,
            error: action.payload,
          },
        },
      };
    case VerificationConstants.KYC_CLEAR_ERROR_ALERT:
      return {
        ...state,
        ui: {
          ...state.ui,
          kyc: {
            ...state.ui.kyc,
            error: null,
          },
        },
      };
    case kycSetIsCompanyRoutine.TRIGGER:
    case kycSubmitCustomerDetailsRoutine.TRIGGER:
    case kycSubmitCustomerDetailsRoutine.REQUEST:
    case kycSubmitIndividualRoutine.TRIGGER:
    case kycSubmitExtendedRoutine.TRIGGER:
    case kycSubmitCompanyRoutine.TRIGGER:
      return {
        ...state,
        ui: {
          ...state.ui,
          kycSubmit: {
            loading: true,
            success: false,
          },
        },
      };
    case kycSetIsCompanyRoutine.SUCCESS:
    case kycSubmitCustomerDetailsRoutine.SUCCESS:
    case kycSubmitIndividualRoutine.SUCCESS:
    case kycSubmitExtendedRoutine.SUCCESS:
    case kycSubmitCompanyRoutine.SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          kycSubmit: {
            loading: false,
            success: true,
          },
        },
      };
    case kycSetIsCompanyRoutine.FAILURE:
    case kycSubmitCustomerDetailsRoutine.FAILURE:
    case kycSubmitIndividualRoutine.FAILURE:
    case kycSubmitExtendedRoutine.FAILURE:
    case kycSubmitCompanyRoutine.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          kycSubmit: {
            loading: false,
            success: false,
          },
        },
      };
    case kycUploadFileRoutine.TRIGGER:
    case kycUploadFileRoutine.REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          kyc: {
            ...state.ui.kyc,
            uploads: {
              ...state.ui.kyc.uploads,
              [action.payload.proofFor]: {
                ...state.ui.kyc.uploads[action.payload.proofFor],
                loading: true,
                success: false,
              },
            },
          },
        },
      };
    case kycUploadFileRoutine.SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          kyc: {
            ...state.ui.kyc,
            uploads: {
              ...state.ui.kyc.uploads,
              [action.payload.proofFor]: {
                id: action.payload.id,
                file: action.payload.file,
                loading: false,
                success: true,
              },
            },
          },
        },
      };
    case kycUploadFileRoutine.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          kyc: {
            ...state.ui.kyc,
            uploads: {
              ...state.ui.kyc.uploads,
              [action.payload.proofFor]: {
                file: action.payload.file,
                loading: false,
                success: false,
              },
            },
          },
        },
      };
    case VerificationConstants.KYC_CLEAR_FILE_UPLOADS:
      return {
        ...state,
        ui: {
          ...state.ui,
          kyc: {
            ...state.ui.kyc,
            uploads: {},
          },
        },
      };
    case VerificationConstants.KYC_ADD_INIT_PAGES:
      return {
        ...state,
        ui: {
          ...state.ui,
          kyc: {
            ...state.ui.kyc,
            initPages: [...state.ui.kyc.initPages, ...action.payload],
          },
        },
      };
    case VerificationConstants.KYC_DEQUEUE_INIT_PAGES:
      return {
        ...state,
        ui: {
          ...state.ui,
          kyc: {
            ...state.ui.kyc,
            initPages: state.ui.kyc.initPages.slice(1),
          },
        },
      };

    default:
      return state;
  }
}

export default customerReducer;
