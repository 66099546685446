import { zonedTimeToUtc } from 'date-fns-tz';

export function parseAmount(amount) {
  if (amount) {
    return parseFloat(amount);
  }
  return 0;
}

export function parseDate(dateString) {
  if (dateString) {
    return new Date(zonedTimeToUtc(dateString.slice(0, -'+00:00'.length), 'America/Los_Angeles'));
  }
  return undefined;
}
