import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ApartmentOutlined, SecurityOutlined, ArticleOutlined } from '@mui/icons-material';

import ReminderIcon from 'spa/features/kyc/mobile/components/ReminderIcon';
import {
  saveCompanyDocument,
  kycCompanyDetailsSelector,
  kycCompanyDocumentSelector,
  updateKycImageId,
  updateUploadedFilesInfo,
} from 'spa/features/kyc/kycSlice';
import { useUploadFileMutation } from 'spa/features/kyc/kycApi';
import MobilePageFormControl from 'spa/features/kyc/mobile/components/MobilePageFormControl';
import {
  V3KYCMobilePages as Pages,
  KYC_FILES as FILES,
  businessTypeOptions,
  businessTypeOptionsAUS,
  companyRelationshipOptions,
  companyProofTypeOptions,
  companyProofTypeOptionsAUS,
} from 'spa/constants/VerificationConstants';
import { SmallText, MediumText } from 'spa/features/ui/EscrowTypography';
import { ERROR_TYPES } from 'spa/constants/VerificationConstants';

import MobilePage from '../../components/MobilePage';
import UploadFileButton from '../../components/ExtraButtons/UploadFileButton';
import KYCAlert from '../../components/KYCAlert';

const CompanyDocumentSelectionPage = () => {
  const dispatch = useDispatch();

  const selectedCompanyDocument = useSelector(kycCompanyDocumentSelector);
  const companyCountry = useSelector(kycCompanyDetailsSelector).country;

  const theme = useTheme();

  const [nextPageTrigger, setNextPageTrigger] = useState(false);
  const [nextPage, setNextPage] = useState(Pages.COMPANY_CAPTURE);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [
    uploadFile,
    {
      data: uploadData,
      isSuccess: isSuccessUpload,
      isLoading: isLoadingUpload,
      error: uploadError,
    },
  ] = useUploadFileMutation();

  const {
    handleSubmit,
    watch,
    resetField,
    control,
    formState: { isValid, errors },
  } = useForm({
    // this is required due to react warning about uncontrolled input
    defaultValues: {
      companyType: selectedCompanyDocument.companyType,
      documentType: selectedCompanyDocument.documentType,
      relationship: selectedCompanyDocument.relationship,
    },
  });

  const selectedCompanyType = watch('companyType') || null;

  const onSubmit = useCallback(
    ({ companyType, documentType, relationship }) => {
      dispatch(saveCompanyDocument({ companyType, documentType, relationship }));
    },
    [dispatch]
  );

  // eslint-disable-next-line no-console
  console.log('errors: ', errors);
  // eslint-disable-next-line no-console
  console.log('isValid', isValid);

  // eslint-disable-next-line no-console
  console.log(
    'uploadData, isSuccessUpload, isLoadingUpload, error',
    uploadData,
    isSuccessUpload,
    isLoadingUpload,
    uploadError
  );

  useEffect(() => {
    resetField('documentType', { documentType: '' });
  }, [resetField, selectedCompanyType]);

  useEffect(() => {
    if (isSubmitting && isSuccessUpload && uploadData) {
      const uploadId = uploadData.id;
      if (uploadId) {
        dispatch(updateKycImageId({ fileKey: FILES.COMPANY_DOCUMENT, id: uploadId }));
        setNextPage(Pages.COMPANY_FILE_REVIEW);
        setNextPageTrigger(true);
      }
    }
  }, [uploadData, dispatch, isSuccessUpload, isSubmitting]);

  useEffect(() => {
    if (isSubmitting && uploadError) {
      setIsSubmitting(false);
    }
  }, [isSubmitting, uploadError]);

  const disabled = isLoadingUpload || isSubmitting;

  return (
    <form>
      <MobilePage
        title="Select a company document"
        ctaText="Take a photo"
        subtitle="Please select a company document to upload."
        onSubmit={handleSubmit((data) => {
          if (isValid) {
            onSubmit(data);
            setNextPage(Pages.COMPANY_CAPTURE);
            setNextPageTrigger(true);
          }
        })}
        nextPage={nextPage}
        nextPageTrigger={nextPageTrigger}
        disableButtons={disabled}
        ExtraButton={(props) => (
          <UploadFileButton
            {...props}
            control={control}
            fileKey={FILES.COMPANY_DOCUMENT}
            onSubmit={handleSubmit((formData) => {
              if (isValid) {
                onSubmit(formData);
                // To allow time for the file to API calls to end
                // before moving to the next page,
                // we instead set the Submit state to true
                setIsSubmitting(true);
              }
            })}
            onUploadFile={(file) => {
              if (isValid) {
                uploadFile(file);
                dispatch(
                  updateUploadedFilesInfo({
                    fileKey: FILES.COMPANY_DOCUMENT,
                    fileName: file.name,
                    fileSize: file.size,
                  })
                );
              }
            }}
            disabled={disabled}
          >
            Upload a file
          </UploadFileButton>
        )}
      >
        {uploadError && (
          <KYCAlert
            isError={uploadError}
            errorType={ERROR_TYPES.PHOTO_UPLOAD_FAILED}
            xRequestId={uploadError.xRequestId}
          />
        )}
        <div>
          <Stack direction="column">
            <MobilePageFormControl>
              <InputLabel id="company-type-label">Company type</InputLabel>
              <Controller
                name="companyType"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select {...field} label="Company type">
                    {(companyCountry === 'Australia'
                      ? businessTypeOptionsAUS
                      : businessTypeOptions
                    ).map(({ label, value: companyType }) => (
                      <MenuItem key={companyType} value={companyType}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </MobilePageFormControl>
            {selectedCompanyType && (
              <MobilePageFormControl>
                <InputLabel id="company-document-type">Document type</InputLabel>
                <Controller
                  name="documentType"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select {...field} id="documentType" label="Document type">
                      {(companyCountry === 'Australia'
                        ? companyProofTypeOptionsAUS[selectedCompanyType]
                        : companyProofTypeOptions[selectedCompanyType]
                      ).map(({ label, value: documentType }) => (
                        <MenuItem key={documentType} value={documentType}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </MobilePageFormControl>
            )}
            <MobilePageFormControl>
              <InputLabel id="company-relation-label">Your relationship to the company</InputLabel>
              <Controller
                name="relationship"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select {...field} label="Your relationship to the company">
                    {companyRelationshipOptions.map(({ label, value: companyRelation }) => (
                      <MenuItem key={companyRelation} value={companyRelation}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </MobilePageFormControl>
          </Stack>
          <MediumText
            style={{
              paddingTop: '12px',
              paddingBottom: '12px',
              color: theme.palette.secondary.dark,
              fontSize: '16px',
            }}
          >
            Reminders:
          </MediumText>
          <Stack direction="column" sx={{ gap: '8px' }}>
            <Stack direction="row" sx={{ gap: '8px' }}>
              <ReminderIcon Icon={ApartmentOutlined} />
              <SmallText sx={{ fontSize: '16px' }}>
                The document must contain the company’s name and address.
              </SmallText>
            </Stack>
            <Stack direction="row" sx={{ gap: '8px' }}>
              <ReminderIcon Icon={SecurityOutlined} />
              <SmallText sx={{ fontSize: '16px' }}>
                Any information must not be altered or edited in any way.
              </SmallText>
            </Stack>
            <Stack direction="row" sx={{ gap: '8px' }}>
              <ReminderIcon Icon={ArticleOutlined} />
              <SmallText sx={{ fontSize: '16px' }}>
                The image or document must not be cropped.
              </SmallText>
            </Stack>
          </Stack>
        </div>
      </MobilePage>
    </form>
  );
};

export default CompanyDocumentSelectionPage;
