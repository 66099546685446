import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { EscrowAlert } from 'spa/features/ui/EscrowAlert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { SmallText, MediumText } from 'spa/features/ui/EscrowTypography';
import { usePatchTransactionMutation } from '../../transactionApi';
import { transactionSelector } from '../../transactionSlice';
import { EscrowButton, EscrowLinkButton } from 'spa/features/ui/EscrowButton';
import { useTheme } from '@mui/material/styles';

// ShipId for 'other' shipper inputs are set to 100
const OTHER_SHIPPER_ID = 100;

const SelectField = ({ value, options, handleSelect }) => {
  const theme = useTheme();
  return (
    <FormControl
      variant="outlined"
      sx={{
        marginTop: '8px',
        marginBottom: '6px',
        '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.secondaryLight.dark,
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: theme.palette.secondaryLight.dark,
        },
      }}
    >
      <InputLabel id="select-shipping-company-label"> Shipping Company </InputLabel>
      <Select
        name="select-shipping-company"
        data-testid="select-shipping-company"
        labelId="select-shipping-company-label"
        value={value}
        label="Shipping Company"
        onChange={handleSelect}
        sx={{
          width: '100%',
          maxHeight: '52px',
          display: 'flex',
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
        <MenuItem key={OTHER_SHIPPER_ID} value={OTHER_SHIPPER_ID}>
          Other shipping company
        </MenuItem>
      </Select>
    </FormControl>
  );
};

const CustomTextField = ({ name, label, value, handleTextInput }) => {
  const theme = useTheme();
  return (
    <Box>
      <TextField
        fullWidth
        name={name}
        label={label}
        variant="outlined"
        value={value}
        onChange={handleTextInput}
        sx={{
          margin: '8px 0',
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.secondaryLight.dark,
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: theme.palette.secondaryLight.dark,
          },
        }}
      />
    </Box>
  );
};

export const AddTrackingInformation = ({ transactionId }) => {
  const theme = useTheme();
  const isMobileViewport = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const [shippingCompany, setShippingCompany] = useState(null);
  const [isOtherCompany, setIsOtherCompany] = useState(false);
  const [otherShippingCompany, setOtherShippingCompany] = useState('');
  const [trackingNumber, setTrackingNumber] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const transaction = useSelector(transactionSelector(transactionId));
  const [patchTransaction, { isLoading, error }] = usePatchTransactionMutation();
  const [errorMsg, setErrorMsg] = useState('');

  if (!transaction) {
    return null;
  }

  const requiredFieldValues =
    shippingCompany &&
    trackingNumber &&
    (!isOtherCompany || (otherShippingCompany && contactNumber));
  const shippingCompanyOptions = transaction.shippingCompanies;
  const receivingRole = transaction.transactionStatus === 25 ? 'buyer' : 'seller';
  const shipAction = transaction.transactionStatus === 25 ? 'ship' : 'ship_return';

  const clearTextFields = () => {
    setOtherShippingCompany('');
    setTrackingNumber('');
    setContactNumber('');
  };

  const handleModalClose = () => {
    if (isLoading) {
      return;
    }
    setOpen(false);
    setShippingCompany(null);
    setIsOtherCompany(false);
    clearTextFields('');
  };

  const handleAccept = async () => {
    try {
      await patchTransaction({
        id: transaction.id,
        action: shipAction,
        shipping_information: {
          tracking_information: {
            carrier: isOtherCompany ? otherShippingCompany : shippingCompany.name,
            tracking_id: trackingNumber,
            ...(!isOtherCompany && { carrier_id: shippingCompany.id.toString() }),
            ...(!isOtherCompany && { carrier_contact: contactNumber }),
          },
        },
      }).unwrap();
      setOpen(false);
    } catch (e) {
      // RTK handles the error
      let errorMsgTmp =
        "The tracking information couldn't be added due to a technical issue. Please refresh the page and try again.";
      if (e.xRequestId) {
        errorMsgTmp += ` If the issue persists, contact support@escrow.com and provide the id: ${e.xRequestId}`;
      }
      setErrorMsg(errorMsgTmp);
    }
  };

  const handleSelect = (event) => {
    const selectedId = event.target.value;
    const isOtherCompany = selectedId === OTHER_SHIPPER_ID;

    if (!isOtherCompany) {
      const shippingCompany = shippingCompanyOptions.find((company) => company.id === selectedId);
      setShippingCompany(shippingCompany);
    } else {
      setShippingCompany({ id: selectedId, name: 'other' });
    }
    setIsOtherCompany(isOtherCompany);
    clearTextFields();
  };

  const handleTextInput = (event) => {
    const value = event.target.value;

    switch (event.target.name) {
      case 'otherShippingCompany':
        setOtherShippingCompany(value);
        break;
      case 'trackingNumber':
        setTrackingNumber(value);
        break;
      case 'contactNumber':
        setContactNumber(value);
        break;
      default:
    }
  };

  if (transaction.usesEbayAuthenticator) {
    return (
      <EscrowLinkButton
        variant="outlined"
        to={transaction.merchantUrl ? transaction.merchantUrl : 'https://www.ebay.com'}
        buttonText="Continue to shipping instructions"
        disabled={isLoading}
        hard
      />
    );
  }
  return (
    <React.Fragment>
      <EscrowButton variant="contained" onClick={() => setOpen(true)}>
        Provide tracking information
      </EscrowButton>
      <Dialog
        fullScreen={isMobileViewport}
        open={open}
        onClose={handleModalClose}
        PaperProps={{
          sx: {
            maxWidth: '685px',
          },
        }}
      >
        {isMobileViewport && (
          <IconButton
            sx={{ marginLeft: 'auto' }}
            onClick={handleModalClose}
            aria-label="Close Tracking Information Modal"
          >
            <CloseIcon sx={{ color: 'mono.dark', height: 24, width: 24 }} />
          </IconButton>
        )}
        {error && (
          <EscrowAlert>
            <AlertTitle>Unable to add the tracking information</AlertTitle>
            {errorMsg}
          </EscrowAlert>
        )}
        <DialogTitle>
          <MediumText> Please provide your tracking information </MediumText>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <SmallText>
              By providing the tracking information, you confirm that you have arranged the delivery
              for this transaction. The {receivingRole} will be instructed to confirm receipt after.
            </SmallText>
            <FormGroup sx={{ mt: 2 }}>
              <SelectField
                name="shippingCompany"
                value={shippingCompany ? shippingCompany.id : ''}
                handleSelect={handleSelect}
                options={shippingCompanyOptions}
              />
              {isOtherCompany && (
                <CustomTextField
                  name="otherShippingCompany"
                  label="Name of shipping company"
                  value={otherShippingCompany}
                  handleTextInput={handleTextInput}
                />
              )}
              {shippingCompany && (
                <CustomTextField
                  name="trackingNumber"
                  label="Tracking number"
                  value={trackingNumber}
                  handleTextInput={handleTextInput}
                />
              )}
              {isOtherCompany && (
                <CustomTextField
                  name="contactNumber"
                  label="Contact number of shipping company"
                  value={contactNumber}
                  handleTextInput={handleTextInput}
                />
              )}
            </FormGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          {!isMobileViewport && <EscrowButton onClick={handleModalClose}>Back</EscrowButton>}
          <EscrowButton
            sx={{ width: isMobileViewport ? '100%' : 'auto' }}
            variant="contained"
            disabled={isLoading || !requiredFieldValues}
            onClick={handleAccept}
          >
            Add tracking information
          </EscrowButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
