import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';

import Webhooks from 'spa/constants/WebhooksConstants';
import APIConstants from 'spa/constants/APIConstants';
import {
  getWebhooksSuccess,
  getWebhooksFailure,
  createWebhooks,
  deleteWebhooksSuccess,
  deleteWebhooksFailure,
} from 'spa/actions/WebhooksActions';
import { apiError } from 'spa/actions/APIActions';

import API from '../../api';

export function* getWebhooks() {
  try {
    const webhooks = yield call(API.getWebhooks);
    yield put(getWebhooksSuccess(webhooks));
  } catch (err) {
    if (err.error) {
      yield put(getWebhooksFailure(err.error));
    }
    yield put(apiError(err.message));
  }
}

export function* createWebhook(action) {
  try {
    const { url } = action.payload.values;
    const webhook = yield call(API.createWebhook, url);
    yield put(createWebhooks.success(webhook));
  } catch (err) {
    let formError = null;
    if (err.type === APIConstants.UNPROCESSABLE_ENTITY) {
      const {
        errors: { error: _error, ...rest },
      } = err;
      formError = new SubmissionError({
        _error,
        ...rest,
      });
    } else {
      formError = new SubmissionError({
        _error: APIConstants.GENERIC_API_ERROR_MESSAGE,
      });
    }
    yield put(apiError(err.message));
    yield put(createWebhooks.failure(formError));
  }
  yield put(createWebhooks.fulfill());
}

export function* deleteWebhook(action) {
  try {
    const { id } = yield action;
    yield call(API.deleteWebhook, id);
    yield put(deleteWebhooksSuccess(id));
  } catch (err) {
    if (err.error) {
      yield put(deleteWebhooksFailure(err.error));
    }
    yield put(apiError(err.message));
  }
}

export function* webHookWatcher() {
  yield takeLatest(Webhooks.GET_WEBHOOKS_REQUEST, getWebhooks);
  yield takeLatest(createWebhooks.TRIGGER, createWebhook);
  yield takeEvery(Webhooks.DELETE_WEBHOOKS_REQUEST, deleteWebhook);
}

export default [webHookWatcher];
