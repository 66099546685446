import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import CreditCardLogo from 'spa/components/CreditCardLogo';

const SavedCreditCard = ({ savedCardInfo, onCardIdChange, fieldName, fieldValueModifier }) => {
  let logoCode;
  let display;
  if (savedCardInfo.brand === 'American Express') {
    logoCode = 'amex';
    display = 'AMEX';
  } else {
    logoCode = savedCardInfo.brand.toLowerCase();
  }

  return (
    <div className="listOptions-item">
      <div className="listOptions-header">
        <label className="listOptions-radio defaultRadio">
          <Field
            className="defaultRadio-input"
            name={fieldName}
            value={fieldValueModifier(savedCardInfo.id.toString())}
            component="input"
            type="radio"
            onChange={onCardIdChange}
          />
          <div className="defaultRadio-pseudo" />
          <CreditCardLogo
            type={logoCode}
            cardNumber={savedCardInfo.last_four_digits}
            display={display}
          />
        </label>
      </div>
    </div>
  );
};
export default function SavedCreditCardField({
  savedCards,
  onCardIdChange,
  fieldName = 'cardId',
  fieldValueModifier = (e) => e,
}) {
  return (
    <Fragment>
      {savedCards.map((savedCard) => (
        <SavedCreditCard
          key={savedCard.id}
          savedCardInfo={savedCard}
          onCardIdChange={onCardIdChange}
          fieldName={fieldName}
          fieldValueModifier={fieldValueModifier}
        />
      ))}
    </Fragment>
  );
}
