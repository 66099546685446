import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'spa/components/Indicators';

class ActionButton extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.isLoading = this.isLoading.bind(this);
    this.isDisabled = this.isDisabled.bind(this);
  }

  handleClick() {
    // Allow action to be undefined
    // When used in redux form, submission is handled by onsubmit, not by button onclick
    if (this.props.action) {
      this.props.action(...this.props.actionArgs);
    }

    // Allow multiple actions to be triggered by a singleClick
    if (this.props.actionList) {
      this.props.actionList.map((actionFunc, index) => {
        const actionArgs = this.props.actionArgsList[index];
        if (actionFunc && actionArgs) {
          actionFunc(...actionArgs);
        }
      });
    }
  }

  // can be overridden
  isLoading() {
    return Boolean(this.props.loading);
  }

  // can be overridden
  isDisabled() {
    return Boolean(this.props.loading || this.props.disabled);
  }

  render() {
    const caption = this.isLoading() ? <Spinner /> : this.props.label;
    return (
      <button
        className={this.props.className}
        disabled={this.isDisabled()}
        onClick={this.handleClick}
        type={this.props.type}
        data-e2e-target={this.props['data-e2e-target']}
        data-tracking-name={this.props['data-tracking-name']}
        data-tracking-label={this.props['data-tracking-label']}
        data-tracking-value={this.props['data-tracking-value']}
      >
        {caption}
      </button>
    );
  }
}

ActionButton.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  action: PropTypes.func,
  actionArgs: PropTypes.array,
  actionList: PropTypes.arrayOf(PropTypes.func),
  actionArgsList: PropTypes.arrayOf(PropTypes.array),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  'data-tracking-name': PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  'data-tracking-label': PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  'data-tracking-value': PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ActionButton.defaultProps = {
  type: 'button',
  label: '',
  loading: false,
  className: '',
  action: null,
  actionArgs: [],
  actionList: null,
  actionArgsList: [],
};

export default ActionButton;
