import Errors from 'spa/constants/APIConstants';
import { gettext } from './filters';
import ErrorMessages from '../constants/ErrorMessages';

export const errorWithRequestId = (requestId, message) =>
  [message, ErrorMessages.ASSISTANT_MESSAGE_FOLLOWED_BY_REQUEST_ID, requestId].join(' ');

export const defaultApiExceptionMessage = (error, defaultMessage = null) => {
  const message =
    defaultMessage ||
    gettext("Sorry, due to technical issues we couldn't process your request at this time.");
  let errorMessage;
  if (Errors.API_ERRORS.includes(error.type) && error.requestId) {
    // Additional default error handlers can be added here
    errorMessage = errorWithRequestId(error.requestId, message);
  }
  return errorMessage || `${message} ${ErrorMessages.PLEASE_TRY_AGAIN_OR_CONTACT}`;
};
