import React from 'react';
import PropTypes from 'prop-types';
import { currencies } from 'escrow-common-js/dist/constants';
import OfferItem from './OfferItem';
import { offerActionPropTypes, offerPropType } from './models';

const OfferList = ({ auctionId, offers, actions, currency, viewerEmail, viewerRole }) => (
  <div>
    {offers.map((offer, index) => (
      <OfferItem
        key={offer.id}
        actions={actions}
        auctionId={auctionId}
        isLatestOffer={index === 0}
        control={index === 0 && !Object.values(offer.status).reduce((acc, cv) => acc || cv, false)}
        offer={offer}
        currency={currency}
        viewerEmail={viewerEmail}
        role={viewerRole}
      />
    ))}
  </div>
);

OfferList.propTypes = {
  auctionId: PropTypes.number,
  offers: PropTypes.arrayOf(offerPropType),
  currency: PropTypes.oneOf(Object.keys(currencies)),
  actions: offerActionPropTypes,
  viewerEmail: PropTypes.string,
  viewerRole: PropTypes.oneOf(['buyer', 'seller']).isRequired,
};

export default OfferList;
