import ExpandCollapseConstants from 'spa/constants/ExpandCollapseConstants';

function initExpandCollapseAction(name, expanded) {
  return {
    type: ExpandCollapseConstants.INIT_EXPAND_COLLAPSE,
    name,
    expanded,
  };
}

function toggleExpandCollapseAction(name) {
  return {
    type: ExpandCollapseConstants.TOGGLE_EXPAND_COLLAPSE,
    name,
  };
}

export { initExpandCollapseAction, toggleExpandCollapseAction };
