import React from 'react';
import { withRouter } from 'react-router-dom';
import EscrowTheme from 'spa/components/MaterialUI/Theme';
import TransactionDetailsPage from './TransactionDetailsPage';

/**
 * This component was created so we could test the page prior to
 * setting up the router in the test environment. Since it's now
 * set up **this component is no longer needed**.
 */
const TransactionDetailsRoute = (props) => (
  <EscrowTheme>
    <TransactionDetailsPage transactionId={props.match.params.transaction_id} />
  </EscrowTheme>
);

export default withRouter(TransactionDetailsRoute);
