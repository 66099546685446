import React, { Fragment } from 'react';

import { useTheme } from '@mui/material/styles';

import { FormHelperText } from '@mui/material';

export const FieldError = ({ error, text, sx }) => {
  const theme = useTheme();

  return (
    <Fragment>
      {error ? (
        <FormHelperText sx={{ color: theme.palette.error.main, ...sx }}>{text}</FormHelperText>
      ) : null}
    </Fragment>
  );
};
