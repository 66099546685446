import {
  getTransactionList as getTransactionListRoutine,
  getTransactionStatistics as getTransactionStatisticsRoutine,
  getCustomerList as getCustomerListRoutine,
  getCustomerStatistics as getCustomerStatisticsRoutine,
  getTransactionPartyInfo as getTransactionPartyInfoRoutine,
  getCustomerPaymentsIn as getCustomerPaymentsInRoutine,
  getCustomerPaymentsOut as getCustomerPaymentsOutRoutine,
  getReportsList as getReportsListRoutine,
  getReportsCount as getReportsCountRoutine,
  getPartnerLogoSettings as getPartnerLogoSettingsRoutine,
  updatePartnerLogoSettings as updatePartnerLogoSettingsRoutine,
  getTransactionHoldingPenConditions as getTransactionHoldingPenConditionsRoutine,
} from 'spa/actions/PartnerActions';

import PartnerConstants from 'spa/constants/PartnerConstants';

const initialState = {
  customers: {
    error: null,
    data: [],
    nextCursor: null,
    loading: false,
    statistics: {
      customerCount: null,
    },
  },
  reports: {
    error: null,
    data: [],
    loading: false,
    nextCursor: null,
    total: null,
  },
  transactions: {
    error: null,
    data: [],
    nextCursor: null,
    loading: false,
    holdingPen: {},
    statistics: {
      transactionCount: {
        aggregates: null,
        data: null,
      },
      ongoingTransactionCount: null,
      completedTransactionCount: null,
    },
  },
  partnerDashboardUi: {
    transactionListPage: {
      error: null,
      loading: false,
      status: null,
      transactionCount: null,
      openDateRange: {
        startDate: null,
        endDate: null,
        selectedPreset: '',
      },
      closeDateRange: {
        startDate: null,
        endDate: null,
        selectedPreset: '',
      },
      filters: {
        status: [],
      },
      isGeneratingReport: false,
      tableFilterValues: {
        order: 'desc',
        orderBy: 'id',
        selected: [],
        currentPageIndex: 0,
        rowsPerPage: 5,
      },
    },
    customerListPage: {
      error: null,
      loading: false,
      verification_status: null,
      customerCount: null,
      tableFilterValues: {
        order: 'desc',
        orderBy: 'id',
        selected: [],
        currentPageIndex: 0,
        rowsPerPage: 5,
      },
    },
    reportsListPage: {
      tableFilterValues: {
        order: 'desc',
        orderBy: 'id',
        selected: [],
        currentPageIndex: 0,
        rowsPerPage: 5,
      },
    },
  },
  paymentsInMethods: {
    error: null,
    data: [],
    loading: false,
  },
  paymentsOutMethods: {
    error: null,
    data: [],
    loading: false,
  },
  partnerLogos: {
    settings: {
      error: null,
      loading: false,
      data: {
        enableCobrandedEmails: false,
      },
    },
  },
};

function partnerReducer(state = initialState, action) {
  switch (action.type) {
    case getTransactionListRoutine.TRIGGER:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          error: null,
          loading: true,
        },
        partnerDashboardUi: {
          ...state.partnerDashboardUi,
          transactionListPage: {
            ...state.partnerDashboardUi.transactionListPage,
            error: null,
          },
        },
      };
    case getTransactionListRoutine.SUCCESS:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          data: [
            ...state.transactions.data,
            ...action.payload.transactions.filter(
              (transaction) =>
                !state.transactions.data.find(
                  (stateTransaction) => stateTransaction.id === transaction.id
                )
            ),
          ],
          nextCursor: action.payload.next_cursor,
          loading: false,
        },
      };
    case getTransactionListRoutine.FAILURE:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          error: action.payload,
          loading: false,
        },
      };
    case getTransactionHoldingPenConditionsRoutine.TRIGGER:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          holdingPen: {
            ...state.transactions.holdingPen,
            [action.payload.transactionId]: {
              data: null,
              loading: true,
              error: false,
            },
          },
        },
      };
    case getTransactionHoldingPenConditionsRoutine.SUCCESS:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          holdingPen: {
            ...state.transactions.holdingPen,
            [action.payload.transactionId]: {
              data: action.payload.holdingPen,
              loading: false,
              error: false,
            },
          },
        },
      };
    case getTransactionHoldingPenConditionsRoutine.FAILURE:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          holdingPen: {
            ...state.transactions.holdingPen,
            [action.payload.transactionId]: {
              data: null,
              loading: false,
              error: action.payload.error,
            },
          },
        },
      };
    case PartnerConstants.PUSH_TRANSACTION_COUNT_BY_INITIATION_DATE:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          statistics: {
            ...state.transactions.statistics,
            transactionCount: {
              aggregates: action.payload.aggregates,
              data: {
                ...state.transactions.statistics.transactionCount.data,
                [action.payload.interval]: action.payload.data,
              },
            },
          },
        },
      };
    case PartnerConstants.PUSH_TRANSACTION_COUNT_BY_CLOSE_DATE:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          statistics: {
            ...state.transactions.statistics,
            completedTransactionCount: action.payload,
          },
        },
      };
    case PartnerConstants.PUSH_ONGOING_TRANSACTION_COUNT:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          statistics: {
            ...state.transactions.statistics,
            ongoingTransactionCount: action.payload,
          },
        },
      };
    case PartnerConstants.PUSH_TRANSACTION_LIST_PAGE_TRANSACTION_COUNT:
      return {
        ...state,
        partnerDashboardUi: {
          ...state.partnerDashboardUi,
          transactionListPage: {
            ...state.partnerDashboardUi.transactionListPage,
            transactionCount: action.payload.aggregates.total,
          },
        },
      };
    case PartnerConstants.RESET_TRANSACTION_LIST_PAGE_TRANSACTION_COUNT:
      return {
        ...state,
        partnerDashboardUi: {
          ...state.partnerDashboardUi,
          transactionListPage: {
            ...state.partnerDashboardUi.transactionListPage,
            transactionCount: null,
          },
        },
      };
    case PartnerConstants.UPDATE_TRANSACTION_LIST_FILTER:
      return {
        ...state,
        partnerDashboardUi: {
          ...state.partnerDashboardUi,
          transactionListPage: {
            ...state.partnerDashboardUi.transactionListPage,
            ...action.payload,
          },
        },
      };
    case PartnerConstants.UPDATE_TRANSACTION_TABLE_FILTER:
      return {
        ...state,
        partnerDashboardUi: {
          ...state.partnerDashboardUi,
          transactionListPage: {
            ...state.partnerDashboardUi.transactionListPage,
            tableFilterValues: {
              ...state.partnerDashboardUi.transactionListPage.tableFilterValues,
              ...action.payload,
            },
          },
        },
      };
    case getTransactionStatisticsRoutine.FAILURE:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          statistics: {
            transactionCount: null,
            completedTransactionCount: null,
            ongoingTransactionCount: null,
          },
        },
      };
    case getCustomerListRoutine.TRIGGER:
      return {
        ...state,
        customers: {
          ...state.customers,
          error: null,
          loading: true,
        },
        partnerDashboardUi: {
          ...state.partnerDashboardUi,
          customerListPage: {
            ...state.partnerDashboardUi.customerListPage,
            error: null,
          },
        },
      };
    case getCustomerListRoutine.SUCCESS:
      return {
        ...state,
        customers: {
          ...state.customers,
          data: [
            ...state.customers.data,
            ...action.payload.customers.filter(
              (customer) =>
                !state.customers.data.find((stateCustomer) => stateCustomer.id === customer.id)
            ),
          ],
          nextCursor: action.payload.next_cursor,
          loading: false,
        },
      };
    case PartnerConstants.UPDATE_CUSTOMER_TABLE_FILTER:
      return {
        ...state,
        partnerDashboardUi: {
          ...state.partnerDashboardUi,
          customerListPage: {
            ...state.partnerDashboardUi.customerListPage,
            tableFilterValues: {
              ...state.partnerDashboardUi.customerListPage.tableFilterValues,
              ...action.payload,
            },
          },
        },
      };
    case getCustomerStatisticsRoutine.FAILURE:
      return {
        ...state,
        customers: {
          ...state.customers,
          error: action.payload,
        },
      };
    case getCustomerListRoutine.FAILURE:
      return {
        ...state,
        customers: {
          ...state.customers,
          error: action.payload,
          loading: false,
        },
      };
    case PartnerConstants.PUSH_CUSTOMER_COUNT_BY_DATE_CREATED:
      return {
        ...state,
        customers: {
          ...state.customers,
          statistics: {
            customerCount: action.payload,
          },
        },
      };
    case PartnerConstants.PUSH_USER_LIST_PAGE_USERS_COUNT:
      return {
        ...state,
        partnerDashboardUi: {
          ...state.partnerDashboardUi,
          customerListPage: {
            ...state.partnerDashboardUi.customerListPage,
            customerCount: action.payload.aggregates.total,
          },
        },
      };
    case PartnerConstants.RESET_USER_LIST_PAGE_USERS_COUNT:
      return {
        ...state,
        partnerDashboardUi: {
          ...state.partnerDashboardUi,
          customerListPage: {
            ...state.partnerDashboardUi.customerListPage,
            customerCount: null,
          },
        },
      };
    case getTransactionPartyInfoRoutine.SUCCESS:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          data: state.transactions.data.map((transDataItem) => {
            if (transDataItem.id === parseInt(action.payload.transactionId, 10)) {
              return {
                ...transDataItem,
                parties: transDataItem.parties.map((party) => ({
                  ...party,
                  details: action.payload.transactionPartyInfo[party.id],
                })),
              };
            }

            return transDataItem;
          }),
        },
      };
    case PartnerConstants.PUSH_FILTERED_TRANSACTION_DATA:
      return {
        ...state,
        partnerDashboardUi: {
          ...state.partnerDashboardUi,
          transactionListPage: {
            ...state.partnerDashboardUi.transactionListPage,
            ...Object.entries(action.payload.filters).reduce(
              (filterCategoryAndData, [filterCategory, filterValues]) => ({
                ...filterCategoryAndData,
                [filterCategory]: {
                  ...state.partnerDashboardUi.transactionListPage[filterCategory],
                  ...filterValues.reduce(
                    (filterValueAndTransactionIds, filterValue) => ({
                      ...filterValueAndTransactionIds,
                      [filterValue]: [
                        ...((state.partnerDashboardUi.transactionListPage[filterCategory] &&
                          state.partnerDashboardUi.transactionListPage[filterCategory][
                            filterValue
                          ]) ||
                          []),
                        ...action.payload.transactions.map((transaction) => transaction.id),
                      ],
                    }),
                    {}
                  ),
                },
              }),
              {}
            ),
          },
        },
      };
    case PartnerConstants.PUSH_FILTERED_CUSTOMER_DATA:
      return {
        ...state,
        partnerDashboardUi: {
          ...state.partnerDashboardUi,
          customerListPage: {
            ...state.partnerDashboardUi.customerListPage,
            ...Object.entries(action.payload.filters).reduce(
              (filterCategoryAndData, [filterCategory, filterValues]) => ({
                ...filterCategoryAndData,
                [filterCategory]: {
                  ...state.partnerDashboardUi.customerListPage[filterCategory],
                  ...filterValues.reduce(
                    (filterValueAndCustomerIds, filterValue) => ({
                      ...filterValueAndCustomerIds,
                      [filterValue]: [
                        ...((state.partnerDashboardUi.customerListPage[filterCategory] &&
                          state.partnerDashboardUi.customerListPage[filterCategory][filterValue]) ||
                          []),
                        ...action.payload.customers.map((customer) => customer.id),
                      ],
                    }),
                    {}
                  ),
                },
              }),
              {}
            ),
          },
        },
      };
    case getCustomerPaymentsInRoutine.TRIGGER:
      return {
        ...state,
        paymentsInMethods: {
          ...state.paymentsInMethods,
          loading: true,
          data: [],
        },
      };
    case getCustomerPaymentsInRoutine.SUCCESS:
      return {
        ...state,
        paymentsInMethods: {
          ...state.paymentsInMethods,
          data: action.payload,
        },
      };
    case getCustomerPaymentsInRoutine.FAILURE:
      return {
        ...state,
        paymentsInMethods: {
          ...state.paymentsInMethods,
          error: action.payload,
        },
      };
    case getCustomerPaymentsInRoutine.FULFILL:
      return {
        ...state,
        paymentsInMethods: {
          ...state.paymentsInMethods,
          loading: false,
        },
      };
    case getCustomerPaymentsOutRoutine.TRIGGER:
      return {
        ...state,
        paymentsOutMethods: {
          ...state.paymentsOutMethods,
          loading: true,
          data: [],
        },
      };
    case getCustomerPaymentsOutRoutine.SUCCESS:
      return {
        ...state,
        paymentsOutMethods: {
          ...state.paymentsOutMethods,
          data: action.payload,
        },
      };
    case getCustomerPaymentsOutRoutine.FAILURE:
      return {
        ...state,
        paymentsOutMethods: {
          ...state.paymentsOutMethods,
          error: action.payload,
        },
      };
    case getCustomerPaymentsOutRoutine.FULFILL:
      return {
        ...state,
        paymentsOutMethods: {
          ...state.paymentsOutMethods,
          loading: false,
        },
      };
    case getReportsListRoutine.TRIGGER:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: true,
        },
      };
    case getReportsListRoutine.SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          data: [
            ...state.reports.data,
            ...(action.payload.reports || []).filter(
              (report) => !state.reports.data.find((stateReport) => stateReport.id === report.id)
            ),
          ],
          nextCursor: action.payload.next_cursor,
        },
      };
    case getReportsCountRoutine.SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          total: action.payload,
        },
      };
    case getReportsListRoutine.FAILURE:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          error: action.payload,
        },
      };
    case PartnerConstants.UPDATE_REPORTS_TABLE_FILTER:
      return {
        ...state,
        partnerDashboardUi: {
          ...state.partnerDashboardUi,
          reportsListPage: {
            ...state.partnerDashboardUi.reportsListPage,
            tableFilterValues: {
              ...state.partnerDashboardUi.reportsListPage.tableFilterValues,
              ...action.payload,
            },
          },
        },
      };
    case getPartnerLogoSettingsRoutine.TRIGGER:
    case updatePartnerLogoSettingsRoutine.TRIGGER:
      return {
        ...state,
        partnerLogos: {
          ...state.partnerLogos,
          settings: {
            ...state.partnerLogos.settings,
            loading: true,
          },
        },
      };
    case getPartnerLogoSettingsRoutine.SUCCESS:
      return {
        ...state,
        partnerLogos: {
          ...state.partnerLogos,
          settings: {
            ...state.partnerLogos.settings,
            error: null,
            loading: false,
            data: {
              ...state.partnerLogos.settings.data,
              ...action.payload,
            },
          },
        },
      };
    case updatePartnerLogoSettingsRoutine.SUCCESS:
      return {
        ...state,
        partnerLogos: {
          ...state.partnerLogos,
          settings: {
            ...state.partnerLogos.settings,
            error: null,
            loading: false,
          },
        },
      };
    case getPartnerLogoSettingsRoutine.FAILURE:
    case updatePartnerLogoSettingsRoutine.FAILURE:
      return {
        ...state,
        partnerLogos: {
          ...state.partnerLogos,
          settings: {
            ...state.partnerLogos.settings,
            error: action.payload,
            loading: false,
          },
        },
      };
    default:
      return state;
  }
}

export default partnerReducer;
