import React from 'react';
import { Field } from 'redux-form';
import { Input, Select, CreditCardNumberInput } from 'spa/components/form';
import {
  required,
  numericOnly,
  cardNumber,
  cardNumberBrand,
  cardSecurityCode,
  cardExpiryDate,
} from 'spa/components/form/validate';
import CreditCardFieldsetSavedCard from './CreditCardFieldsetSavedCard';
import { gettext } from '../../../utils/filters';

class CreditCardFieldset extends React.Component {
  constructor(props) {
    super(props);

    this.validators = {
      card_number: [cardNumber, cardNumberBrand(props.currency)],
      card_expiration: [cardExpiryDate],
      security_code: [numericOnly(gettext('Security Code')), cardSecurityCode],
    };

    this.options = {
      years: [],
      months: [
        { value: '1', label: '01' },
        { value: '2', label: '02' },
        { value: '3', label: '03' },
        { value: '4', label: '04' },
        { value: '5', label: '05' },
        { value: '6', label: '06' },
        { value: '7', label: '07' },
        { value: '8', label: '08' },
        { value: '9', label: '09' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' },
      ],
    };

    this.state = {
      cardType: 'unknown',
      showCardForm: true,
    };

    const thisYear = new Date().getFullYear();
    const yearMaxOffset = 11;

    for (let x = 0; x <= yearMaxOffset; x++) {
      this.options.years.push({
        label: thisYear + x,
        value: thisYear + x,
      });
    }

    this.onCardTypeChange = this.onCardTypeChange.bind(this);
    this.onCardIdChange = this.onCardIdChange.bind(this);
  }

  onCardTypeChange(cardType) {
    this.setState({ cardType });
  }

  onCardIdChange(event, cardId) {
    this.setState({ showCardForm: cardId === 'new' });
  }

  renderSelection() {
    return (
      <div className="listOptions listOptions--simple">
        <CreditCardFieldsetSavedCard
          savedCards={this.props.savedCards}
          onCardIdChange={this.onCardIdChange}
        />
        <div className="listOptions-item">
          <div className="listOptions-header">
            <label className="listOptions-radio defaultRadio">
              <Field
                className="defaultRadio-input"
                name="cardId"
                value="new"
                component="input"
                onChange={this.onCardIdChange}
                type="radio"
              />
              <div className="defaultRadio-pseudo" />
              <span className="listOptions-header-title">
                {gettext('Add a new credit/debit card')}
              </span>
            </label>
          </div>
          {this.state.showCardForm && (
            <div className="listOptions-content">
              {this.renderFields()}
              <p className="listOptions-content-footNote">
                <strong>NOTE:</strong> You agree to authorize the use of your card for this payment
                and future payments.
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderFields() {
    const { customerDetails } = this.props;
    const billToOptions = [];
    if (customerDetails) {
      billToOptions.push({
        value: 'personal',
        label: `${customerDetails['first-name']} ${customerDetails['last-name']}`,
      });

      if (customerDetails.company) {
        billToOptions.push({
          value: 'company',
          label: customerDetails['company-name'],
        });
      }
    }

    return (
      <div>
        {billToOptions && billToOptions.length > 0 && (
          <Field
            name="billTo"
            component={Select}
            label={gettext('Bill To')}
            validate={[required]}
            placeholder={gettext('Please choose option')}
            data-e2e-target="bill-to"
            requiredIndicator
            options={billToOptions}
          />
        )}

        <div className="grid">
          <div className="grid-col grid-col grid-col--tablet-6">
            <Field
              name="cardNumber"
              component={CreditCardNumberInput}
              label={gettext('Card Number')}
              validate={[required, ...this.validators.card_number]}
              data-e2e-target="card-number"
              requiredIndicator
              onCreditCardTypeChanged={this.onCardTypeChange}
              autoComplete="off"
              cardType={this.state.cardType}
            />
          </div>
          <div className="grid-col grid-col grid-col--tablet-6">
            <Field
              name="nameOnCard"
              component={Input}
              label={gettext('Name On Card')}
              validate={[required]}
              data-e2e-target="name-on-card"
              tooltip="Using a name or company other than the configure Escrow.com account name may require further verification for both parties."
              requiredIndicator
            />
          </div>
        </div>
        <div className="grid">
          <div className="grid-col grid-col--tablet-6">
            <label className="field-label">
              {gettext('Expiration Date')} <span className="field-required">*</span>
            </label>
            <div className="defaultForm-group">
              <Field
                name="expirationMonth"
                component={Select}
                validate={[required, ...this.validators.card_expiration]}
                placeholder="MM"
                data-e2e-target="card-expiration-month"
                options={this.options.months}
              />
              <Field
                name="expirationYear"
                component={Select}
                validate={[required]}
                placeholder="YY"
                data-e2e-target="card-expiration-year"
                options={this.options.years}
              />
            </div>
          </div>
          <div className="grid-col grid-col--tablet-6">
            <Field
              onChange={(e) => (e.target.validity.valid ? e.target.value : e.preventDefault())}
              name="securityCode"
              component={Input}
              label={gettext('Security Code')}
              validate={[required, ...this.validators.security_code]}
              pattern="[0-9]{0,4}"
              tooltip="The security code is a 3-4 digit number on the back or front of your card."
              data-e2e-target="card-security-code"
              requiredIndicator
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <fieldset
        ref={(section) => {
          this.Card = section;
        }}
      >
        {this.props.savedCards ? this.renderSelection() : this.renderFields()}
      </fieldset>
    );
  }
}

export default CreditCardFieldset;
