import { gettext } from '../utils/filters';

export default {
  SELECT_ROLE: 'SELECT_ROLE',
  SELECT_RETURNING: 'SELECT_RETURNING',
  SELECT_SHIPPING: 'SELECT_SHIPPING',
  SELECT_SHIPPING_PAYER: 'SELECT_SHIPPING_PAYER',
  TOGGLE_ADVANCED: 'TOGGLE_ADVANCED',
  UPDATE_FIELDS: 'UPDATE_FIELDS',
  SHOW_ERROR: 'SHOW_ERROR',
  ROLE_SELLER: 'seller',
  ROLE_BUYER: 'buyer',
  SHIPPING_METHOD_NONE: '4',
  SHIPPING_METHOD_STANDARD: '1',
  SHIPPING_METHOD_DOMAIN: '2',
  SHIPPING_METHOD_CARGO: '3',
  SHIPPING_PAYER_SELLER: '1',
  SHIPPING_PAYER_BUYER: '0',
  CURRENCY_USD: 'USD',
  CURRENCY_EUR: 'EUR',
  DOMAIN_TRANSACTION_TYPE: '0',
  GENERAL_TRANSACTION_TYPE: '1',
  VEHICLE_TRANSACTION_TYPE: '2',
  MILESTONE_TRANSACTION_TYPE: '3',
  DOMAIN_CONCIERGE_SELECTED: 'DNC',
  TITLE_COLLECTION_SERVICE: 13,
  LIEN_HOLDER_SERVICE: 20,
  WWW_SOURCE_TRACKING: 2,
  HIDE_ADVANCED_OPTIONS: gettext('- Hide advanced options'),
  CREATE_TO_PAID_RATE: 0.42, // accuratate as of 170420,
  MULTI_STEP_PROGRESSION: 'MULTI_STEP_PROGRESSION',
};
