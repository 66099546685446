import moment from 'moment';
import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';
import libphonenumber from 'google-libphonenumber';
import TransactionConstants from 'spa/constants/TransactionConstants';
import CheckoutConstants from 'spa/constants/CheckoutConstants';

import { extractData } from '../../utils/DataMapping';
import { gettext } from '../../utils/filters';
import { parsePhoneNumber, parseInvalidNumber } from '../../utils/parse-phone';
import { Alpha2ToAlpha3 } from '../constants/ISOCountryCodes';

const draftPartyMappingSchema = {
  'address.line1': 'address-line-1',
  'address.line2': 'address-line-2',
  'address.city': 'city',
  'address.state': 'state',
  'address.country': 'country',
  'address.post_code': 'post-code',
  first_name: 'first-name',
  last_name: 'last-name',
  company: 'company-name',
};

const baseSelector = (state) => state.transaction || {};
const tourStatusSelector = (state) => baseSelector(state).toured;
const transactionErrorSelector = (state) => baseSelector(state).error;
const transactionLoadingSelector = (state) => baseSelector(state).loading;
const transactionFiltersSelector = (state) => baseSelector(state).filters;
const transactionsSelector = (state) => baseSelector(state).transactions;
const transactionsTotalCountSelector = (state) => baseSelector(state).totalCount;
const transactionDraftSelector = (state) => baseSelector(state).draft || {};
const transactionUISelector = (state) => baseSelector(state).ui;
const transactionFromDraftSelector = (state) => transactionDraftSelector(state).transaction || {};
const transactionPaymentMethodsSelector = (state) =>
  transactionFromDraftSelector(state).paymentMethods;

const transactionByIdSelector = (state, transactionId) => {
  const transactions = baseSelector(state).transactionsById;
  if (!transactions) return {};
  return transactions[transactionId] || {};
};

const transactionOrDraftSelector = (state) => {
  const draft = transactionDraftSelector(state);
  const transactionById = transactionByIdSelector(state, draft.id);
  if (!isEmpty(transactionById)) {
    return transactionById;
  }
  return draft;
};

const transactionBuyerEmailSelector = (transaction) => {
  if (!transaction) return '';

  const buyer = (transaction.parties || []).find((party) => party.role === 'buyer');
  const buyerEmail = buyer ? buyer.customer || '' : '';
  return buyerEmail;
};

const draftBuyerRequiresKycSelector = (transaction) => {
  if (!transaction) return false;

  const buyer = (transaction.parties || []).find((party) => party.role === 'buyer');
  const buyerKycRequired = buyer ? buyer.verification_required || false : false;
  return buyerKycRequired;
};

const calculateItemFeeTotal = (item, payerEmail = '') => {
  let feeTotal = 0.0;

  // It only adds up fee that pays by buyer, excluding the 'credit_card' and 'intermediary' fee
  if (item.fees) {
    item.fees
      .filter((fee) => (payerEmail ? fee.payer_customer === payerEmail : true))
      .filter((fee) => !TransactionConstants.EXCLUDE_ITEM_FEE_TYPES.includes(fee.type))
      .map((fee) => {
        feeTotal += parseFloat(fee.amount) || 0.0;
      });
  }
  return feeTotal;
};

const calculateItemTotal = (item, payerEmail = '', includeFees = false) => {
  let total = 0.0;
  if (item.schedule) {
    item.schedule
      .filter((schedule) => (payerEmail ? schedule.payer_customer === payerEmail : true))
      .map((schedule) => {
        total += parseFloat(schedule.amount) || 0.0;
      });
  }
  if (includeFees) {
    total += calculateItemFeeTotal(item, payerEmail);
  }
  return total;
};

const calculateItemTaxTotalByTaxType = (item = {}, payerEmail = '') => {
  let taxTotalByType = {};
  if (item.fees) {
    taxTotalByType = item.fees
      .filter((fee) => (payerEmail ? fee.payer_customer === payerEmail : true))
      .reduce((accumulator, fee) => {
        for (const tax of fee.taxes || []) {
          if (!accumulator[tax.type]) {
            accumulator[tax.type] = parseFloat(tax.amount);
          } else {
            accumulator[tax.type] += parseFloat(tax.amount);
          }
        }
        return accumulator;
      }, {});
  }
  return taxTotalByType;
};

const transactionDraftTotalSelector = (state, includeCustomerFees = true) => {
  let total = 0.0;

  const draft = transactionDraftSelector(state);
  if (!draft || !draft.items) return total;

  const buyerEmail = transactionBuyerEmailSelector(draft.transaction || draft);

  let items = draft.items;
  if (!includeCustomerFees) {
    items = items.filter((i) => !CheckoutConstants.CUSTOMER_FEE_TYPES.includes(i.type));
  }

  items.map((item) => {
    total += calculateItemTotal(item, buyerEmail, true);
  });

  return total;
};

const transactionLineItemsSelector = (transaction) => {
  const result = [];

  if (!transaction) return result;

  if (transaction.items) {
    const buyerEmail = transactionBuyerEmailSelector(transaction);
    transaction.items
      .filter((item) => !CheckoutConstants.CUSTOMER_FEE_TYPES.includes(item.type))
      .map((item) => {
        const amount = calculateItemTotal(item, buyerEmail);
        result.push({
          title: item.title || '',
          type: item.type || '',
          amount: amount,
          key: `${item.id}`,
          currency: transaction.currency,
          description: item.description || '',
          quantity: item.quantity,
        });
      });
  }

  return result;
};

const transactionTaxTotalByTypeSelector = (transaction) => {
  if (!transaction) return {};
  const buyerEmail = transactionBuyerEmailSelector(transaction);
  let taxTotalByType;
  if (transaction.items) {
    taxTotalByType = transaction.items
      .map((item) => calculateItemTaxTotalByTaxType(item, buyerEmail))
      .reduce((accumulator, itemTaxTotal) => {
        for (const taxType of Object.keys(itemTaxTotal)) {
          if (!accumulator[taxType]) {
            accumulator[taxType] = itemTaxTotal[taxType];
          } else {
            accumulator[taxType] += itemTaxTotal[taxType];
          }
        }
        return accumulator;
      }, {});
  }
  return taxTotalByType || {};
};

const transactionTaxTotalsSelector = (transaction) => {
  if (!transaction) return [];
  const taxTotalByType = transactionTaxTotalByTypeSelector(transaction);
  return Object.keys(taxTotalByType).map((taxType) => ({
    key: `${taxType}_${taxTotalByType[taxType]}`,
    type: taxType,
    amount: taxTotalByType[taxType],
    title: taxType.toUpperCase(),
    currency: transaction.currency,
  }));
};

const transactionFeesSelector = (transaction, fullFeeBreakdown = false) => {
  const result = {
    knownFees: [],
    conditionalFees: {},
  };

  if (!transaction) return result;

  if (transaction.items) {
    const buyerEmail = transactionBuyerEmailSelector(transaction);

    // Extract broker fee, partner fee and shipping fee
    const feeTotals = {}; // For transactions with split fees on multiple milestones
    transaction.items
      .filter((item) => CheckoutConstants.CUSTOMER_FEE_TYPES.includes(item.type))
      .map((item) => {
        const amount = calculateItemTotal(item, buyerEmail);

        if (feeTotals[item.type]) {
          feeTotals[item.type] += amount;
        } else {
          feeTotals[item.type] = amount;
        }
      });

    Object.keys(feeTotals).forEach((feeType) => {
      result.knownFees.push({
        title: CheckoutConstants.LINE_ITEM_TYPES[feeType],
        type: feeType,
        amount: feeTotals[feeType],
        key: `${feeType}_${feeTotals[feeType]}`,
        currency: transaction.currency,
      });
    });

    // Extract other fees dumped into escrow fee ex. title collection, lien holder
    if (fullFeeBreakdown) {
      transaction.items[0].fees
        .filter(
          (fee) =>
            (CheckoutConstants.TRANSACTION_FEE_TYPES.includes(fee.type) ||
              CheckoutConstants.LINE_ITEM_TYPES[fee.type]) &&
            fee.payer_customer === buyerEmail
        )
        .map((fee) => {
          if (fee.type === 'domain_name_holding') fee.type = `${fee.type}_fee`;
          result.knownFees.push({
            title: CheckoutConstants.FEE_LINE_ITEM_TYPES[fee.type],
            type: fee.type,
            amount: parseFloat(fee.amount),
            key: `${fee.type}_${fee.amount}`,
            currency: transaction.currency,
          });
        });
    } else {
      // Extract escrow fee
      const escrowFee = transaction.items.reduce(
        (accumulator, currentItem) => accumulator + calculateItemFeeTotal(currentItem, buyerEmail),
        0.0
      );
      const taxesByType = transactionTaxTotalByTypeSelector(transaction);
      let escrowFeeTitle = gettext('Escrow Fee');
      if (Object.keys(taxesByType).length > 0) {
        escrowFeeTitle += ` with ${Object.keys(taxesByType).join(', ').toUpperCase()}`;
      }
      result.knownFees.push({
        title: escrowFeeTitle,
        type: 'escrow_fee',
        amount: escrowFee,
        key: 'escrowFee',
        currency: transaction.currency,
      });
    }
  }
  // Extract intermediary fee, paypal fee and credit card fee
  if (transaction.paymentMethods) {
    const paymentMethods = transaction.paymentMethods;
    if (paymentMethods.available_payment_methods) {
      const paymentTotalWithoutFee = parseFloat(paymentMethods.total_without_payment_fee) || 0.0;
      paymentMethods.available_payment_methods.map((paymentMethod) => {
        const paymentTotal = parseFloat(paymentMethod.total) || 0.0;
        const paymentFee = paymentTotal - paymentTotalWithoutFee;
        result.conditionalFees[paymentMethod.type] = {
          title: TransactionConstants.PAYMENT_FEE_TYPES[paymentMethod.type],
          type: paymentMethod.type,
          amount: paymentFee,
          key: `${paymentMethod.type}_${paymentFee}`,
          currency: transaction.currency,
        };
      });
    }
  }

  return result;
};

const transactionTotalsSelector = (transaction) => {
  const result = {};

  if (!transaction || !transaction.paymentMethods) return result;

  const paymentMethods = transaction.paymentMethods;
  if (paymentMethods.available_payment_methods) {
    paymentMethods.available_payment_methods.map((paymentMethod) => {
      result[paymentMethod.type] = parseFloat(paymentMethod.total) || 0.0;
    });
  }

  if (paymentMethods.conditionally_available_payment_methods) {
    paymentMethods.conditionally_available_payment_methods.map((paymentMethod) => {
      result[paymentMethod.type] = parseFloat(paymentMethod.total) || 0.0;
    });
  }
  return result;
};

const draftBuyerDetailsSelector = (state) => {
  const transactionDraft = transactionDraftSelector(state);
  let buyerDraftDetails = {};
  // extract the buyer party object
  const buyerPartyData = transactionDraft.parties.find((party) => party.role === 'buyer');

  if (buyerPartyData) {
    // extract address and name data
    buyerDraftDetails = {
      ...buyerDraftDetails,
      ...extractData(draftPartyMappingSchema, buyerPartyData),
    };
    // extract DOB
    if (buyerPartyData.date_of_birth) {
      const isDOBValid = Date.parse(buyerPartyData.date_of_birth);
      buyerDraftDetails = {
        ...buyerDraftDetails,
        'date-of-birth': isDOBValid ? buyerPartyData.date_of_birth : '',
      };
    }
    // extract phone
    if (buyerPartyData.phone_number) {
      const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
      const parsedPhone = parsePhoneNumber(buyerPartyData.phone_number, buyerDraftDetails.country);
      let primaryPhoneNumber;
      let primaryPhoneCountry;

      if (parsedPhone !== null) {
        primaryPhoneNumber = parsedPhone.getNationalNumber();
        primaryPhoneCountry = Alpha2ToAlpha3[phoneUtil.getRegionCodeForNumber(parsedPhone)];
      } else {
        const invalidPhoneParse = parseInvalidNumber(buyerPartyData.phone_number);
        primaryPhoneCountry = invalidPhoneParse
          ? Alpha2ToAlpha3[invalidPhoneParse.phoneCountry]
          : '';
        primaryPhoneNumber = invalidPhoneParse
          ? invalidPhoneParse.phoneNumber
          : buyerPartyData.phone_number;
      }

      buyerDraftDetails = {
        ...buyerDraftDetails,
        'primary-phone-number': primaryPhoneNumber,
        'primary-phone-country': primaryPhoneCountry,
      };
    }

    // check if the customer is acting as a company
    buyerDraftDetails.company = Boolean(buyerPartyData.company);
    buyerDraftDetails.lock_email = Boolean(buyerPartyData.lock_email);
  }

  return buyerDraftDetails;
};

const transactionsViewSelector = createSelector(transactionsSelector, (transactions) =>
  transactions.map((transaction) => ({
    id: transaction.id,
    description: transaction.description,
    initiationDate: moment(transaction.initiation_date).format('MMM DD, YYYY'),
    currency: transaction.currency,
    total: parseFloat(transaction.total),
    role: transaction.role,
    statusCode: transaction.status_code,
    type: transaction.transaction_type,
    isDraft: transaction.is_draft,
    isOffer: transaction.is_offer,
    isInDispute: transaction.is_in_dispute,
    isAwaitingDisbursementInfo: transaction.is_awaiting_disbursement_info,
    actionsByParty: transaction.actions_by_party || {},
    token: transaction.token,
    ...(transaction.milestone_details && {
      milestoneStatuses: transaction.milestone_details.map((ms) => ms.status_code),
    }),
  }))
);

const transactionAdyenDataSelector = (transaction) => transaction.adyenData || {};

const transactionAdyenAdditionalActionSelector = (transaction) =>
  transactionAdyenDataSelector(transaction).action;

const transactionAdyenCheckoutSelector = (transaction) =>
  transactionAdyenDataSelector(transaction).checkout;

const transactionHasAdyenCCSelector = (transaction) =>
  (transactionAdyenDataSelector(transaction).paymentMethods || []).some(
    (method) => method.name === 'Credit Card'
  );

const transactionCCPaymentGatewaySelector = (state, transId) => {
  const transaction = transactionByIdSelector(state, transId);
  const {
    available_payment_methods: availablePaymentMethods = [],
    conditionally_available_payment_methods: conditionalPaymentMethods = [],
  } = transaction.paymentMethods || {};
  const { payment_gateway: paymentGateway } =
    [...availablePaymentMethods, ...conditionalPaymentMethods].find(
      ({ type }) => type === 'credit_card'
    ) || {};
  return paymentGateway;
};

export {
  transactionsSelector,
  tourStatusSelector,
  transactionsViewSelector,
  transactionErrorSelector,
  transactionFiltersSelector,
  transactionLoadingSelector,
  transactionsTotalCountSelector,
  transactionDraftSelector,
  transactionDraftTotalSelector,
  draftBuyerDetailsSelector,
  transactionUISelector,
  transactionLineItemsSelector,
  transactionFeesSelector,
  transactionTotalsSelector,
  transactionByIdSelector,
  transactionTaxTotalsSelector,
  transactionOrDraftSelector,
  transactionPaymentMethodsSelector,
  transactionBuyerEmailSelector,
  draftBuyerRequiresKycSelector,
  transactionAdyenDataSelector,
  transactionAdyenAdditionalActionSelector,
  transactionAdyenCheckoutSelector,
  transactionHasAdyenCCSelector,
  transactionCCPaymentGatewaySelector,
};
