import React, { Fragment, useEffect } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { getTransactions as getTransactionsRoutine } from 'spa/actions/TransactionActions';
import { nextStep, finishTour } from 'spa/actions/TourActions';
import { transactionFiltersSelector } from 'spa/selectors/TransactionSelectors';
import { tourStepSelector } from 'spa/selectors/TourStepSelector';
import TransactionConstants from 'spa/constants/TransactionConstants';
import ReactTooltip from 'react-tooltip';
import TourStep from 'spa/components/TransactionsTour/TransactionsTourStep';
import { gettext } from '../../../utils/filters';

const TransactionTabs = ({ currentTab, getTransactionsByStatus, currentStep, next, close }) => {
  useEffect(() => {
    getTransactionsByStatus('outstanding', true);
  }, [getTransactionsByStatus]);

  const tabViews = [
    {
      label: 'all',
      tooltip: 'All of your transactions',
    },
    {
      label: 'outstanding',
      tooltip: 'Transactions that require your action',
    },
    {
      label: 'open',
      tooltip: 'Transactions that are currently in progress',
    },
    {
      label: 'closed',
      tooltip: 'Transactions that are cancelled or completed',
    },
  ];
  return (
    <ul className="transactions-tab">
      {tabViews.map((tab) => (
        <Fragment key={tab.label}>
          <li
            className={classnames('transactions-tab-item', {
              'is-active': tab.label === 'all' ? currentTab === null : tab.label === currentTab,
            })}
            data-tip={tab.tooltip}
          >
            <a
              className="transactions-tab-item-link"
              role="button"
              onClick={() => getTransactionsByStatus(tab.label === 'all' ? null : tab.label)}
              tabIndex={0}
              data-tracking-section="transactions-tab"
              data-tracking-action="click"
              data-tracking-label={tab.label}
            >
              {gettext(tab.label === 'outstanding' ? 'action required' : tab.label)}
            </a>
            {tab.label === 'outstanding' && (
              <TourStep
                visible={currentStep === 1}
                onNext={next}
                onClose={close}
                title="tabs"
                step={1}
              >
                Transactions are now grouped depending on their status. The{' '}
                <span>Action Required</span> tab will include transactions that require any action
                from you.
              </TourStep>
            )}
            {tab.label === 'open' && (
              <TourStep
                visible={currentStep === 2}
                onNext={next}
                onClose={close}
                title="tabs"
                step={2}
              >
                Your <span>Open</span> transactions are those that are still ongoing, including the
                ones that require your action.
              </TourStep>
            )}
            {tab.label === 'closed' && (
              <TourStep
                visible={currentStep === 3}
                onNext={next}
                onClose={close}
                title="tabs"
                step={3}
              >
                Your <span>Closed</span> transactions are those that were completed or cancelled.
              </TourStep>
            )}
          </li>
          {currentStep <= 0 && (
            <ReactTooltip
              className="transactions media--hidden@tablet"
              place="bottom"
              effect="solid"
              offset={{ top: 9 }}
            />
          )}
        </Fragment>
      ))}
    </ul>
  );
};
const mapStateToProps = (state) => ({
  currentTab: transactionFiltersSelector(state).status,
  currentStep: tourStepSelector(state, TransactionConstants.TOUR_NAME),
});

const mapDispatchToProps = (dispatch) => ({
  getTransactionsByStatus: (status, isFirstFetch = false) =>
    dispatch(
      getTransactionsRoutine.trigger({
        filters: {
          status,
          search: null,
          role: [],
          type: [],
          amount: { min: null, max: null },
        },
        refresh: true,
        isFirstFetch,
      })
    ),
  next: () => dispatch(nextStep(TransactionConstants.TOUR_NAME)),
  close: () => dispatch(finishTour(TransactionConstants.TOUR_NAME)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionTabs);
