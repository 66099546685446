import PropTypes from 'prop-types';

export const offerDataPropTypes = PropTypes.shape({
  transaction_id: PropTypes.number,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.string,
      offer_information: PropTypes.shape({
        no_fee_amount: PropTypes.number,
      }),
    })
  ),
});

export const offerActionPropTypes = PropTypes.shape({
  accept: PropTypes.func,
  reject: PropTypes.func,
  cancel: PropTypes.func,
  pay: PropTypes.func,
});

const offerStatusPropType = PropTypes.shape({
  accepted: PropTypes.boolean,
  canceled: PropTypes.boolean,
  rejected: PropTypes.boolean,
});

const auctionPartyPropType = PropTypes.shape({
  id: PropTypes.number,
  customer: PropTypes.string,
  role: PropTypes.string,
});

export const offerPropType = PropTypes.shape({
  id: PropTypes.number,
  byParty: auctionPartyPropType,
  dateClosed: PropTypes.object,
  dateCreated: PropTypes.object,
  noFeeAmount: PropTypes.number,
  status: offerStatusPropType,
});
