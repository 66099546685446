import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  initExpandCollapseAction,
  toggleExpandCollapseAction,
} from 'spa/actions/ExpandCollapseActions';
import { makeExpandedSelector } from 'spa/selectors/ExpandCollapseSelectors';
import Icon from 'spa/components/Icon';

class ExpandCollapse extends React.PureComponent {
  constructor(props) {
    super(props);
    this.props.initExpandCollapse();
  }

  render() {
    const { expandText, collapseText, expanded, toggleExpandCollapse } = this.props;
    return (
      <a
        className={`integrationsPortal-toggleBtn ${expanded && 'is-expanded'}`}
        role="button"
        tabIndex={0}
        onClick={toggleExpandCollapse}
      >
        {expanded ? <span>{collapseText}</span> : <span>{expandText}</span>}
        <Icon name="ui-chevron-down" className="integrationsPortal-toggleBtn-icon" />
      </a>
    );
  }
}

ExpandCollapse.propTypes = {
  expandText: PropTypes.string,
  collapseText: PropTypes.string,
};

ExpandCollapse.defaultProps = {
  initExpand: false,
};

const makeMapStateToProps = () => {
  const expandedSelector = makeExpandedSelector();
  const mapStateToProps = (state, props) => ({
    expanded: expandedSelector(state, props.name),
  });
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch, props) => ({
  toggleExpandCollapse: () => dispatch(toggleExpandCollapseAction(props.name)),
  initExpandCollapse: () => dispatch(initExpandCollapseAction(props.name, props.initExpand)),
});

const ExpandCollapseContainer = connect(makeMapStateToProps, mapDispatchToProps)(ExpandCollapse);

export default ExpandCollapseContainer;
