import React, { Fragment } from 'react';

import MyTransactionsHeader from 'spa/components/HeaderV3/MyTransactionsHeader';
import V2KYC from './V2KYC';

const V2KYCContainer = ({ isMobile }) => (
  <Fragment>
    <MyTransactionsHeader
      title={'Verify your identity'}
      toggleSidebar={() => {
        isMobile;
      }}
    />
    <div className="section--mid kyc-page">
      <div className="section-container">
        <V2KYC />
      </div>
    </div>
  </Fragment>
);

export default V2KYCContainer;
