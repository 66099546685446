var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["components/search.html"] = require( "components/search.html" );




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/js/searchresults.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div>";
env.getTemplate("components/search.html", false, "escrow_www/templates/js/searchresults.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(context.getVariables(), frame, function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(t_1.hasOwnProperty("searchResultCategory")) {
var t_4 = t_1.searchResultCategory;
} else {
cb(new Error("cannot import 'searchResultCategory'")); return;
}
context.setVariable("searchResultCategory", t_4);
if(runtime.contextOrFrameLookup(context, frame, "error")) {
output += "\n        <h2 class=\"searchOverlay-resultsCategory\" data-target=\"searchOverlay-searchPrompt\">\n            ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "error"), env.opts.autoescape);
output += "\n        </h2>\n    ";
;
}
else {
output += "\n        ";
output += runtime.suppressValue((lineno = 7, colno = 28, runtime.callWrap(t_4, "searchResultCategory", context, [env.getFilter("safe").call(context, runtime.contextOrFrameLookup(context, frame, "input")),runtime.contextOrFrameLookup(context, frame, "results")])), env.opts.autoescape);
output += "\n    ";
;
}
output += "\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/js/searchresults.html"] , dependencies)