import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { bindRoutineToReduxForm } from 'redux-saga-routines';
import { createAPIKeys } from 'spa/actions/APIIntegrationActions';

import { Section } from 'spa/components/Section';
import { Input } from 'spa/components/form';
import { required, maxChars } from 'spa/components/form/validate';
import {
  DashboardCard,
  DashboardCardHeader,
  DashboardCardBody,
} from 'spa/components/DashboardCard';
import { ErrorIndicator, Spinner } from 'spa/components/Indicators';
import A from 'spa/components/A';
import { gettext } from '../../../utils/filters';

const maxChars250 = maxChars(250, 'API key names');

const AgreeTerms = () => (
  <p className="integrationsAuth-terms">
    {gettext('By signing up a new account with Escrow.com you agree to our')}{' '}
    <A
      link={{ type: 'internal', route: '/legal', newTab: true }}
      className="integrationsAuth-terms-link"
    >
      {gettext('Terms of Using the Escrow Platform')}
    </A>{' '}
    {gettext('and')}{' '}
    <A
      link={{
        type: 'internal',
        route: '/legal',
        newTab: true,
      }}
      className="integrationsAuth-terms-link"
    >
      {gettext('API Terms and Conditions')}
    </A>
  </p>
);

class CreateAPIKeys extends React.PureComponent {
  render() {
    const { handleSubmit, error, submitting } = this.props;

    return (
      <Section>
        <DashboardCard>
          <DashboardCardHeader title={gettext('Generate API Key')} />
          <DashboardCardBody>
            <form onSubmit={handleSubmit(bindRoutineToReduxForm(createAPIKeys))}>
              <Field
                name="name"
                label="new key name"
                data-e2e-target="generate-api-key-field"
                component={Input}
                validate={[required, maxChars250]}
                disabled={submitting}
              />
              {!submitting && error && <ErrorIndicator>{error}</ErrorIndicator>}
              <button
                className="btn btn--secondary accountForm-btn"
                type="submit"
                data-e2e-target="generate-api-key-submit"
                disabled={submitting}
              >
                {submitting ? <Spinner /> : gettext('Generate API Key')}
              </button>
              <AgreeTerms />
            </form>
          </DashboardCardBody>
        </DashboardCard>
      </Section>
    );
  }
}

const CreateAPIKeysContainer = reduxForm({
  form: 'createAPIKeys',
})(CreateAPIKeys);

export default CreateAPIKeysContainer;
export { AgreeTerms };
