import { changeBuyerEmail } from 'spa/actions/TransactionActions';
import {
  showCustomerDetails,
  moveToNextStep,
  loadNextStepData,
  sendEscrowPayBuyerReminder,
  getCheckoutPartnerData,
  confirmPaymentMethod,
  confirmStripePaymentRoutine,
  submitReceiveSms,
  agreePaymentReview,
  getCheckoutLabels,
} from 'spa/actions/CheckoutActions';
import PaymentConstants from 'spa/constants/PaymentConstants';
import { getWireDetails } from 'spa/actions/PaymentsActions';
import CheckoutConstants from 'spa/constants/CheckoutConstants';

const initialState = {
  step: CheckoutConstants.STEP_INIT,
  head: CheckoutConstants.STEP_INIT, // The furthest step a customer has viewed
  ui: {
    showCustomerDetails: {
      error: false,
      errorMessage: '',
    },
    changeBuyerEmail: {
      loading: false,
      success: false,
      errorMessage: '',
    },
    moveToNextStep: {
      loading: false,
    },
    loadNextStepData: {
      loading: true,
    },
    getWireDetails: {
      error: false,
      errorMessage: '',
    },
    confirmPaymentMethod: {
      loading: false,
      success: false,
      errorMessage: '',
    },
    confirmStripePayment: {
      loading: false,
      success: false,
      errorMessage: '',
    },
    submitReceiveSms: {
      loading: false,
      success: false,
      errorMessage: '',
      phoneNumber: null,
    },
    agreePaymentReview: {
      loading: false,
      success: false,
      errorMessage: '',
    },
  },
  partner: {
    loading: false,
    success: false,
    error: null,
  },
  labels: {
    loading: false,
    success: false,
    error: null,
    labelData: {},
  },
  transId: null,
  reminderSent: false,
  paymentType: undefined,
  paymentDetails: {},
  authorizedPayments: Object.values(PaymentConstants.PAYMENT_METHODS).reduce(
    (obj, paymentMethod) => ({ ...obj, [paymentMethod]: null }),
    {}
  ),
};

function CheckoutReducer(state = initialState, action) {
  switch (action.type) {
    case CheckoutConstants.ACTION_SET_STEP:
      if (
        action.payload &&
        CheckoutConstants.STEP_ORDER[action.payload] >= CheckoutConstants.STEP_ORDER[state.head]
      ) {
        return {
          ...state,
          step: action.payload,
          head: action.payload,
        };
      }
      return {
        ...state,
        step: action.payload,
      };

    case CheckoutConstants.SET_PAYMENT_DETAILS:
      return {
        ...state,
        paymentDetails: {
          ...action.payload,
        },
      };
    case CheckoutConstants.SET_AUTHORIZED_PAYMENT:
      return {
        ...state,
        authorizedPayments: {
          ...state.authorizedPayments,
          [action.payload.paymentType]: { ...action.payload.details },
        },
      };
    case CheckoutConstants.SET_PAYMENT_TYPE:
      return {
        ...state,
        paymentType: action.payload.paymentType,
      };
    case getWireDetails.SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          getWireDetails: {
            error: false,
            errorMessage: '',
          },
        },
        wireDetails: action.payload,
      };

    case getWireDetails.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          getWireDetails: {
            error: true,
            errorMessage: action.payload.errorMessage,
          },
        },
      };

    case showCustomerDetails.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          showCustomerDetails: {
            error: true,
            errorMessage: action.payload.errorMessage,
          },
        },
      };

    case CheckoutConstants.SET_TRANSACTION_ID:
      return {
        ...state,
        transId: action.payload.transId,
      };

    case changeBuyerEmail.TRIGGER:
      return {
        ...state,
        ui: {
          ...state.ui,
          changeBuyerEmail: {
            loading: true,
            success: false,
          },
        },
      };

    case changeBuyerEmail.SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          changeBuyerEmail: {
            loading: false,
            success: true,
          },
        },
      };

    case changeBuyerEmail.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          changeBuyerEmail: {
            loading: false,
            success: false,
            errorMessage: action.payload.errorMessage,
          },
        },
      };

    case moveToNextStep.TRIGGER:
      return {
        ...state,
        ui: {
          ...state.ui,
          moveToNextStep: {
            loading: true,
          },
        },
      };

    case moveToNextStep.SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          moveToNextStep: {
            loading: false,
          },
        },
      };

    case moveToNextStep.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          moveToNextStep: {
            loading: false,
          },
        },
      };
    case loadNextStepData.TRIGGER:
      return {
        ...state,
        ui: {
          ...state.ui,
          loadNextStepData: {
            loading: true,
          },
        },
      };

    case loadNextStepData.SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          loadNextStepData: {
            loading: false,
          },
        },
      };

    case loadNextStepData.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          loadNextStepData: {
            loading: false,
          },
        },
      };
    case sendEscrowPayBuyerReminder.SUCCESS:
      return {
        ...state,
        reminderSent: true,
      };
    case getCheckoutPartnerData.TRIGGER:
      return {
        ...state,
        partner: {
          ...state.partner,
          loading: true,
        },
      };
    case getCheckoutPartnerData.SUCCESS:
      return {
        ...state,
        partner: {
          loading: false,
          success: true,
          partnerData: action.payload,
        },
      };
    case getCheckoutPartnerData.FAILURE:
      return {
        ...state,
        partner: {
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    case getCheckoutLabels.TRIGGER:
      return {
        ...state,
        labels: {
          ...state.labels,
          loading: true,
        },
      };
    case getCheckoutLabels.SUCCESS:
      return {
        ...state,
        labels: {
          ...state.labels,
          loading: false,
          success: true,
          labelData: action.payload,
        },
      };
    case getCheckoutLabels.FAILURE:
      return {
        ...state,
        labels: {
          ...state.labels,
          loading: false,
          success: false,
          labelData: action.payload,
        },
      };
    case confirmPaymentMethod.TRIGGER:
      return {
        ...state,
        ui: {
          ...state.ui,
          confirmPaymentMethod: {
            loading: true,
            success: false,
            errorMessage: '',
          },
        },
      };
    case confirmPaymentMethod.SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          confirmPaymentMethod: {
            loading: false,
            success: true,
            errorMessage: '',
          },
        },
      };
    case confirmPaymentMethod.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          confirmPaymentMethod: {
            loading: false,
            success: false,
            errorMessage: action.payload,
          },
        },
      };
      case confirmStripePaymentRoutine.TRIGGER:
        return {
          ...state,
          ui: {
            ...state.ui,
            confirmStripePayment: {
              loading: true,
              success: false,
              errorMessage: '',
            },
          },
        };
      case confirmStripePaymentRoutine.SUCCESS:
        return {
          ...state,
          ui: {
            ...state.ui,
            confirmStripePayment: {
              loading: false,
              success: true,
              errorMessage: '',
            },
          },
        };
      case confirmStripePaymentRoutine.FAILURE:
        return {
          ...state,
          ui: {
            ...state.ui,
            confirmStripePayment: {
              loading: false,
              success: false,
              errorMessage: action.payload,
            },
          },
        };
    case submitReceiveSms.TRIGGER:
      return {
        ...state,
        ui: {
          ...state.ui,
          submitReceiveSms: {
            loading: true,
            success: false,
            errorMessage: '',
          },
        },
      };
    case submitReceiveSms.SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          submitReceiveSms: {
            loading: false,
            success: true,
            errorMessage: '',
            phoneNumber: action.payload.phone_number,
          },
        },
      };
    case submitReceiveSms.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          submitReceiveSms: {
            loading: false,
            success: false,
            errorMessage: action.payload,
          },
        },
      };
    case agreePaymentReview.TRIGGER:
      return {
        ...state,
        ui: {
          ...state.ui,
          agreePaymentReview: {
            loading: true,
            success: false,
            errorMessage: '',
          },
        },
      };
    case agreePaymentReview.SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          agreePaymentReview: {
            loading: false,
            success: true,
            errorMessage: '',
          },
        },
      };
    case agreePaymentReview.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          agreePaymentReview: {
            loading: false,
            success: false,
            errorMessage: action.payload,
          },
        },
      };
    default:
      return state;
  }
}

export default CheckoutReducer;
