import React from 'react';
import classnames from 'classnames';

const Spinner = ({ animateIn }) => (
  <div
    className={classnames('createTransaction-submit-spinner', {
      'is-animated': animateIn,
    })}
  />
);

export default Spinner;
