import {
  customerExtendedKycVerified,
  customerIsCompanySelector,
  customerKyc1VerifiedSelector,
  customerKyc2ApprovedSelector,
  customerKyc2InProgressSelector,
  customerKyc2HoldingPenRequiredSelector,
  customerKyc2SubmittedSelector,
  customerKyc3ApprovedSelector,
  customerKyc3InProgressSelector,
  customerKyc3HoldingPenRequiredSelector,
  customerKyc3SubmittedSelector,
  customerRequireExtendedKycSelector,
  customerSubmittedExtendedKyc,
  customerExtendedKycRejected,
  customerHasDocumentsSubmittedSelector,
} from 'spa/selectors/CustomerSelectors';
import { checkoutPaymentTypeSelector } from 'spa/selectors/CheckoutSelectors';
import { KYCPages } from 'spa/constants/VerificationConstants';
import { CHECKOUT_FLOW } from 'spa/context/KYCContext';
import PaymentConstants from 'spa/constants/PaymentConstants';
import store from '../../store';

const getBaseNextPage = (allowOverride, forceT2 = false, forceT3 = false) => {
  const state = store.getState();

  const isTier1Verified = customerKyc1VerifiedSelector(state);
  const isTier2Required = customerKyc2HoldingPenRequiredSelector(state) || forceT2;
  const isTier3Required = customerKyc3HoldingPenRequiredSelector(state) || forceT3;
  const isTier2InProgress = customerKyc2InProgressSelector(state);
  const isTier3InProgress = customerKyc3InProgressSelector(state);
  const isTier2Approved = customerKyc2ApprovedSelector(state);
  const isTier3Approved = customerKyc3ApprovedSelector(state);
  const isTier3Submitted = customerKyc3SubmittedSelector(state);
  const isTier2Submitted = customerKyc2SubmittedSelector(state);
  const isExtendedRequired = customerRequireExtendedKycSelector(state);
  const isExtendedSubmitted = customerSubmittedExtendedKyc(state);
  const isExtendedApproved = customerExtendedKycVerified(state);
  const isExtendedRejected = customerExtendedKycRejected(state);
  const hasCustomerSubmittedDocuments = customerHasDocumentsSubmittedSelector(state);
  const paymentMethod = checkoutPaymentTypeSelector(state);

  if (!isTier1Verified) {
    return KYCPages.INDIVIDUAL_DETAILS_FORM;
  }
  if (!allowOverride) return null;
  const requireExtended =
    isExtendedRequired && !isExtendedApproved && (!isExtendedSubmitted || isExtendedRejected);
  const uploadDocumentForAchRequired =
    isTier2Approved &&
    !hasCustomerSubmittedDocuments &&
    paymentMethod === PaymentConstants.PAYMENT_METHODS.DIRECT_DEBIT;
  if (
    ((isTier2Required || requireExtended) && !isTier2InProgress && !isTier2Approved) ||
    uploadDocumentForAchRequired
  ) {
    // Leaving rejected condition here for future use
    const isTier2Rejected = isTier2Submitted; // eslint-disable-line no-unused-vars
    return KYCPages.INDIVIDUAL_VERIFICATION_FORM;
  }
  if (requireExtended) {
    return KYCPages.EXTENDED_INDIVIDUAL_VERIFICATION_FORM;
  }
  if (isTier3Required && !isTier3InProgress && !isTier3Approved) {
    // Leaving rejected condition here for future use
    const isTier3Rejected = isTier3Submitted; // eslint-disable-line no-unused-vars
    return KYCPages.COMPANY_VERIFICATION_FORM;
  }
  return null;
};

export const getRequiredNextPage = (flowContext) => {
  const state = store.getState();
  const isCustomerIndividual = !customerIsCompanySelector(state);
  const allowOverride = flowContext === CHECKOUT_FLOW;
  const forceT2 = flowContext === CHECKOUT_FLOW;
  const forceT3 = flowContext === CHECKOUT_FLOW && !isCustomerIndividual;
  return getBaseNextPage(allowOverride, forceT2, forceT3);
};

export const getVerifyButtonNextPage = (fromCompany) =>
  getBaseNextPage(true, !fromCompany, fromCompany);
