import TourConstants from 'spa/constants/TourConstants';

function tourReducer(state = {}, { type, ...action }) {
  switch (type) {
    case TourConstants.INIT:
      return {
        ...state,
        [action.name]: {
          step: 1,
        },
      };
    case TourConstants.NEXT_STEP:
      return {
        ...state,
        [action.name]: {
          step: state[action.name].step + 1,
        },
      };
    case TourConstants.JUMP:
      return {
        ...state,
        [action.name]: {
          step: action.step,
        },
      };
    case TourConstants.FINISH:
      return {
        ...state,
        [action.name]: {
          step: 0,
        },
      };
    default:
      return state;
  }
}

export default tourReducer;
