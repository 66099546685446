import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  Typography,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { EscrowAlert } from 'spa/features/ui/EscrowAlert';
import AlertTitle from '@mui/material/AlertTitle';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { MediumText, SmallText } from 'spa/features/ui/EscrowTypography';
import { usePatchTransactionMutation } from '../../transactionApi';
import { transactionSelector } from '../../transactionSlice';
import { EscrowButton } from 'spa/features/ui/EscrowButton';
import { useTheme } from '@mui/material/styles';

const CONTENT = {
  buyer: {
    domain_name: {
      title: 'Confirm acceptance of the domain name',
      body: 'By accepting the domain name, you authorize Escrow.com to release the funds of this transaction to the seller. Please review the **Whois** information of the domain name and ensure that you are the registered owner.',
      firstCheckbox: 'I confirm that the domain name is already registered under my name.',
      secondCheckbox:
        'I authorize Escrow.com to release the funds of this transaction to the seller.',
    },
    motor_vehicle: {
      title: 'Confirm acceptance of the ${transactionType}',
      body: 'By accepting the ${transactionType}, you authorize Escrow.com to release the funds of this transaction to the seller. Please ensure that you are satisfied with the condition of the ${transactionType} that you have received.',
      firstCheckbox:
        'I confirm that the motor vehicle I purchased is in the condition that was advertised, and acknowledge that I had the opportunity to conduct enough inspection and assessment.',
      secondCheckbox: 'I provide Escrow.com the authority to release the funds to the seller.',
    },
    general_merchandise: {
      title: 'Confirm acceptance of the ${transactionType}',
      body: 'By accepting the ${transactionType}, you authorize Escrow.com to release the funds of this transaction to the seller. Please ensure that you are satisfied with the condition of the ${transactionType} that you have received.',
      firstCheckbox:
        'I confirm that the general merchandise is in the condition that was described, and acknowledge that I have had the opportunity to conduct an inspection of my purchase.',
      secondCheckbox: 'I provide Escrow.com the authority to release the funds to the seller.',
    },
  },
  seller: {
    domain_name: {
      title: 'Confirm acceptance of the returned domain name',
      body: 'By accepting the returned domain name, you authorize Escrow.com to release the refund to the buyer. Please review the **Whois** information of the domain name and ensure that you are now the registered owner.',
      firstCheckbox: 'I confirm that the domain name is already registered under my name.',
      secondCheckbox: 'I provide Escrow.com the authority to release the refund to the buyer.',
    },
    motor_vehicle: {
      title: 'Confirm acceptance of the returned ${transactionType}',
      body: 'By accepting the returned ${transactionType}, you authorize Escrow.com to release the refund to the buyer.',
      firstCheckbox:
        'I confirm that the motor vehicle was returned by the buyer in a satisfactory condition.',
      secondCheckbox: 'I provide Escrow.com the authority to release the refund to the buyer.',
    },
    general_merchandise: {
      title: 'Confirm acceptance of the returned ${transactionType}',
      body: 'By accepting the returned ${transactionType}, you authorize Escrow.com to release the refund to the buyer.',
      firstCheckbox:
        'I confirm that the general merchandise was returned by the buyer in a satisfactory condition.',
      secondCheckbox: 'I provide Escrow.com the authority to release the refund to the buyer.',
    },
  },
};

export const ContentCopy = ({ text, transactionType, isTitle }) => {
  const transactionTypeText = transactionType.replace(/_/g, ' ');
  const descriptionSubbed = text.replace(/\${transactionType}/g, transactionTypeText);
  const descriptionElements = [];
  let prevIdx = 0;
  for (const match of descriptionSubbed.matchAll(/\*\*(.+?)\*\*/g)) {
    descriptionElements.push(
      <React.Fragment key={descriptionElements.length}>
        {descriptionSubbed.substring(prevIdx, match.index)}
      </React.Fragment>
    );
    descriptionElements.push(
      <SmallText key={descriptionElements.length} component="span" variant="bold">
        {match[1]}
      </SmallText>
    );
    prevIdx = match.index + match[0].length;
  }
  descriptionElements.push(descriptionSubbed.substring(prevIdx));
  if (isTitle) {
    return <MediumText>{descriptionElements}</MediumText>;
  }
  return <SmallText>{descriptionElements}</SmallText>;
};

const CheckboxItem = ({ name, checked, handleCheck }) => {
  const theme = useTheme();
  return (
    <Checkbox
      checked={checked}
      onChange={handleCheck}
      name={name}
      sx={{
        padding: '6px',
        color: theme.palette.secondary.dark,
        '&.Mui-checked': {
          color: theme.palette.secondaryLight.dark,
        },
      }}
    />
  );
};

export const ItemAcceptanceConfirmation = ({ transactionId, userRole }) => {
  const theme = useTheme();
  const isMobileViewport = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const transaction = useSelector(transactionSelector(transactionId));
  const [checked, setChecked] = useState({
    isFirstBoxTicked: false,
    isSecondBoxTicked: false,
  });
  const [patchTransaction, { isLoading, error }] = usePatchTransactionMutation();
  const { isFirstBoxTicked, isSecondBoxTicked } = checked;
  const [errorMsg, setErrorMsg] = useState('');
  if (!transaction) {
    return null;
  }

  const { title, body, firstCheckbox, secondCheckbox } = CONTENT[userRole]
    ? CONTENT[userRole][transaction.transactionTypeCode] || {}
    : {};

  const handleCheck = (event) => {
    setChecked({ ...checked, [event.target.name]: event.target.checked });
  };

  const handleModalClose = () => {
    if (isLoading) {
      return;
    }
    setOpen(false);
    setChecked({
      isFirstBoxTicked: false,
      isSecondBoxTicked: false,
    });
  };

  const handleAccept = async () => {
    try {
      await patchTransaction({
        id: transaction.id,
        ...(userRole === 'buyer' && { action: 'accept' }),
        ...(userRole === 'seller' && { action: 'accept_return' }),
      }).unwrap();
      setOpen(false);
    } catch (e) {
      // RTK handles the error
      let errorMsgTmp =
        "Your acceptance couldn't be confirmed due to a technical issue. Please refresh the page and try again.";
      if (e.xRequestId) {
        errorMsgTmp += ` If the issue persists, contact support@escrow.com and provide the id: ${e.xRequestId}`;
      }
      setErrorMsg(errorMsgTmp);
    }
  };

  return (
    <React.Fragment>
      <EscrowButton variant="contained" onClick={() => setOpen(true)}>
        Accept
      </EscrowButton>
      <Dialog
        fullScreen={isMobileViewport}
        open={open}
        onClose={handleModalClose}
        PaperProps={{
          sx: {
            minHeight: '325px',
            maxWidth: '685px',
          },
        }}
      >
        {isMobileViewport && (
          <IconButton
            sx={{ marginLeft: 'auto' }}
            onClick={handleModalClose}
            aria-label="Close Item Acceptance Modal"
          >
            <CloseIcon sx={{ color: 'mono.dark', height: 24, width: 24 }} />
          </IconButton>
        )}
        {error && (
          <EscrowAlert>
            <AlertTitle>
              Unable to confirm the {transaction.transactionTypeCode.replace('_', ' ')} acceptance
            </AlertTitle>
            {errorMsg}
          </EscrowAlert>
        )}
        <DialogTitle>
          {title && (
            <ContentCopy text={title} transactionType={transaction.transactionTypeCode} isTitle />
          )}
        </DialogTitle>
        <DialogContent>
          {body && <ContentCopy text={body} transactionType={transaction.transactionTypeCode} />}
          <Box sx={{ padding: '12px 12px 0 12px', display: 'flex', flexDirection: 'column' }}>
            <FormGroup>
              <FormControlLabel
                sx={{ marginTop: '8px' }}
                control={
                  <CheckboxItem
                    name="isFirstBoxTicked"
                    checked={isFirstBoxTicked}
                    handleCheck={handleCheck}
                  />
                }
                label={<SmallText>{firstCheckbox}</SmallText>}
              />
              <FormControlLabel
                sx={{ marginTop: '8px' }}
                control={
                  <CheckboxItem
                    name="isSecondBoxTicked"
                    checked={isSecondBoxTicked}
                    handleCheck={handleCheck}
                  />
                }
                label={<SmallText>{secondCheckbox}</SmallText>}
              />
            </FormGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          {!isMobileViewport && <EscrowButton onClick={handleModalClose}>Back</EscrowButton>}
          <EscrowButton
            variant="contained"
            sx={{ width: isMobileViewport ? '100%' : 'auto' }}
            disabled={isLoading || !isFirstBoxTicked || !isSecondBoxTicked}
            onClick={handleAccept}
          >
            Accept and release funds
          </EscrowButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
