import classNames from 'classnames/dedupe';
import deepcopy from 'clone';
import h from 'virtual-dom/h';
import select from 'vtree-select';
import virtualize from '../utils/virtualize';

import Component from '../utils/Component';
import SearchConstants from '../constants/SearchConstants';
import NavigationStore from '../stores/NavigationStore';
import SearchInputStore from '../stores/SearchInputStore';
import SearchInputAction from '../actions/SearchInput';
import SearchResultsStore from '../stores/SearchResultsStore';
import TrackingActions from '../actions/TrackingActions';
import ModalActions from '../actions/ModalActions';

function getState() {
  const results = SearchResultsStore.getResults(SearchConstants.CATEGORY_MENU);
  return {
    value: SearchInputStore.getInput() || '',
    error: SearchInputStore.getError(),
    searchSelection: SearchInputStore.searchSelection,
    searchHoverText: SearchInputStore.searchHoverText,
    searchHoverIdx: SearchInputStore.searchHoverIdx,
    shouldUpdate: SearchInputStore.shouldUpdate,
    searchResults: results.results,
    searchTerms: results.terms,
    searchSuggestion: results.suggestion,
    searchOpen: NavigationStore.searchOpen,
  };
}

export default class SearchInput extends Component {
  constructor(element, props) {
    super();
    this.props = props;
    this.template = virtualize(element);
    this._onInputChange = this._onInputChange.bind(this);
    this._onChange = this._onChange.bind(this);
    this.addEventListener(
      'mouseover',
      '[data-component="search-dropdown-item"]',
      this._onSearchHover.bind(this)
    );
    this.addEventListener(
      'mousedown',
      '[data-component="search-searchResults"]',
      this._onSearchSelect.bind(this)
    );
    // mousedown doesn't work on iOS, use touchstart instead
    this.addEventListener(
      'touchstart',
      '[data-component="search-searchResults"]',
      this._onSearchSelect.bind(this)
    );
    this.addEventListener(
      'keyup',
      '[data-component="search-searchBox"]',
      this._onInputChange.bind(this)
    );
    this.addEventListener(
      'keydown',
      '[data-component="search-searchBox"]',
      this._onInputKeyDown.bind(this)
    );
    this.addEventListener(
      'blur',
      '[data-component="search-searchBox"]',
      this._onInputBlur.bind(this)
    );
    // iOS focusing
    this.addEventListener(
      'click',
      '[data-component="search-searchBox"]',
      this._onInputClick.bind(this)
    );
    this.addEventListener(
      'focus',
      '[data-component="search-searchBox"]',
      this._onInputFocus.bind(this)
    );
    this.setState(getState());
  }

  createDropdownResult(title, route) {
    if (title === this.state.searchHoverText) {
      const dropdownLink = h(
        'a.dropdown-link.is-dropdown-item-hover',
        { attributes: { 'data-value': route } },
        title
      );
      return h(
        'li.dropdown-item.is-dropdown-item-hover',
        { attributes: { 'data-component': 'search-dropdown-item' } },
        dropdownLink
      );
    }
    const dropdownLink = h('a.dropdown-link', { attributes: { 'data-value': route } }, title);
    return h(
      'li.dropdown-item',
      { attributes: { 'data-component': 'search-dropdown-item' } },
      dropdownLink
    );
  }

  componentHasMounted() {
    SearchInputStore.addChangeListener(this._onChange);
    SearchResultsStore.addChangeListener(this._onChange);
    NavigationStore.addChangeListener(this._onChange);
    SearchInputAction.updateInputs();
  }

  componentWillDismount() {
    SearchInputStore.removeChangeListener(this._onChange);
    SearchResultsStore.removeChangeListener(this._onChange);
    NavigationStore.removeChangeListener(this._onChange);
  }

  _onSearchHover(event) {
    if (!event.target.text) {
      return;
    }
    const results = this.rootNode.querySelector('[data-component="search-searchResults"]').children;
    let idx = 0;
    for (let i = 0; i < results.length; i++) {
      if (results[i].children[0].text === event.target.text) {
        idx = i;
        break;
      }
    }

    SearchInputAction.searchHover(event.target.text, idx);
  }

  // When the search button is actually pressed
  // open the searchOverlay results
  _onSearchSelect(event) {
    if (!event.target.text) {
      SearchInputAction.invalidate();
    } else {
      SearchInputAction.searchSelect(event.target.text, this.props.category);
      ModalActions.open('modal-searchOverlay');
    }
  }

  _onInputClick(event) {
    event.target.focus();
  }

  _onInputFocus(event) {
    // Select contents of input box after focus
    // Using experimental Web API Selection because execCommand breaks for firefox
    const currentSelection = window.getSelection();
    currentSelection.removeAllRanges();
    const selection = document.createRange();
    selection.selectNodeContents(event.target);
    currentSelection.addRange(selection);
  }

  _onInputKeyDown(event) {
    if (event.keyCode === 13) {
      if (this.state.value === '') {
        SearchInputAction.invalidate();
      } else {
        // On Enter keypress, select hovered (or first result)
        const results = this.rootNode.querySelector('[data-component="search-searchResults"]');
        if (results) {
          let result;
          if (this.state.searchHoverIdx !== -1) {
            result = results.children[this.state.searchHoverIdx].children[0];
          }
          if (result) {
            SearchInputAction.searchSelect(result.text, this.props.category);
          } else {
            SearchInputAction.searchSelect(this.state.value, this.props.category);
          }
          ModalActions.open('modal-searchOverlay');
        }
      }
      event.preventDefault();
    }
  }

  // Handles input changes in the search results
  _onInputChange(event) {
    let modifier = 0;
    if (event.keyCode === 38) {
      // Up key
      modifier = -1;
    } else if (event.keyCode === 40) {
      // Down key
      modifier = 1;
    } else if (event.keyCode !== 13) {
      // else handle all normal inputs by
      const value =
        event.target.nodeName === 'INPUT' ? event.target.value : event.target.textContent;
      if (value === '') {
        // responsively searching for the search term
        SearchInputAction.invalidate();
      } else {
        SearchInputAction.search(value, this.props.category);
      }
    }
    if (modifier !== 0) {
      const results = this.rootNode.querySelector(
        '[data-component="search-searchResults"]'
      ).children;
      const lastResult = results.length - 1;
      if (this.state.searchHoverIdx + modifier > lastResult) {
        SearchInputAction.searchHover(results[0].children[0].text, 0);
      } else if (this.state.searchHoverIdx + modifier < 0) {
        SearchInputAction.searchHover(results[lastResult].children[0].text, lastResult);
      } else {
        SearchInputAction.searchHover(
          results[this.state.searchHoverIdx + modifier].children[0].text,
          this.state.searchHoverIdx + modifier
        );
      }
      event.preventDefault();
    }
    return true;
  }

  _onInputBlur(event) {
    SearchInputAction.updateInputs();
    if (event.target.value) {
      TrackingActions.track({
        event: 'escrow_user_action',
        section: 'search-input',
        action: 'input',
        value: event.target.value,
      });
    }
    return true;
  }

  _onChange() {
    this.setState(getState());
  }

  render() {
    const vhtml = deepcopy(this.template, false);
    // Updating DOM needed here to avoid contentEditable bug with virtualDOM
    const input = this.rootNode.querySelector('[data-component="search-searchBox"]');
    if (input && this.state.shouldUpdate) {
      if (this.state.searchSelection !== '') {
        if (input.nodeName === 'INPUT') {
          input.value = this.state.searchSelection;
        } else {
          input.textContent = this.state.searchSelection;
        }
      } else if (input.nodeName === 'INPUT') {
        input.value = this.state.value;
      } else {
        input.textContent = this.state.value;
      }
    }

    const field = select('[data-target="field"]')(vhtml)[0];
    field.properties.className = classNames(field.properties.className, {
      'is-invalid': this.state.error,
    });

    const errorField = select('[data-target="field-error"]')(vhtml);
    if (errorField && field.properties.className.search('searchOverlay-search') !== -1) {
      errorField[0].properties.className = classNames(errorField[0].properties.className, {
        'is-hidden': true,
      });
    }

    const dropdown = select('[data-component="search-searchResults"]')(vhtml);
    const isDropdownOwner = this.props.searchLocation === SearchConstants.LOCATION_HEADER;

    if (dropdown && isDropdownOwner) {
      const searchResultsEmpty = this.state.value.trim() === '';
      if (searchResultsEmpty || !this.state.searchOpen) {
        dropdown[0].properties.className = classNames(dropdown[0].properties.className.split(' '), {
          'is-hidden': true,
          'is-active': false,
        });
      } else {
        dropdown[0].properties.className = classNames(dropdown[0].properties.className.split(' '), {
          'is-hidden': false,
          'is-active': true,
        });
      }

      dropdown[0].children = [];
      if (this.state.searchResults.length > 0) {
        for (const result of this.state.searchResults) {
          dropdown[0].children.push(this.createDropdownResult(result.title, result.route));
        }
      } else {
        dropdown[0].children.push(this.createDropdownResult(this.state.value, ''));
      }
    }

    const suggestion = select('[data-component="searchOverlay-suggestion"]')(vhtml);
    if (suggestion) {
      if (
        this.state.searchResults.length <= SearchConstants.SEARCH_SUGGESTION_MAX_RESULTS &&
        this.state.searchSuggestion !== this.state.searchTerms.toLowerCase()
      ) {
        suggestion[0].properties.textContent = `Did you mean: "${this.state.searchSuggestion}"?`;
        suggestion[0].properties.className = classNames(
          suggestion[0].properties.className.split(' '),
          { 'search-suggestion--active': true }
        );
      } else {
        suggestion[0].properties.textContent = 'Did you mean: ';
      }
    }

    return vhtml;
  }
}

setTimeout(() => {
  for (const e of document.querySelectorAll('[data-target="searchOverlay-input"]')) {
    const component = new SearchInput(e, { category: SearchConstants.CATEGORY_MENU });
    component.replace(e);
  }
});
