export default {
  METHOD_INDIVIDUAL: 'verify-individual',
  METHOD_TIER_1: 'verify-tier-1',
  METHOD_TIER_2: 'verify-tier-2',
  METHOD_TIER_3: 'verify-tier-3',
  METHOD_TIER_1_SELLER: 'verify-tier-1-seller',
  METHOD_TIER_1_BUYER: 'verify-tier-1-buyer',
  METHOD_TIER_1_ACCOUNT_DETAILS: 'verify-tier-1-account-details',
  METHOD_TIER_1_DISBURSEMENT: 'verify-tier-1-disbursement',
  METHOD_COMPANY: 'verify-company',
  METHOD_COMPANY_ONLY: 'verify-company-only',
  UPDATE_USERNAME: 'edit-display-name',
  ACCOUNT_INFO_PERSONAL_FORM: 'account-info-personal',
  ACCOUNT_INFO_COMPANY_FORM: 'account-info-company',
  EDIT_PASSWORD_FORM: 'edit-password-form',
  METHOD_ADDRESS: 'verify-address',
  METHOD_PERSONAL: 'verify-personal',
  METHOD_SOCIAL_SECURITY: 'social-security',
  INITIALISE_FORM: 'VERIFY_INITIALISE_FORM',
  NAVIGATION_STATE: 'VERIFY_NAVIGATION_STATE',
  NAVIGATION_STATE_INITIAL: 'VERIFY_STATE_INITIAL',
  NAVIGATION_STATE_FORM: 'VERIFY_STATE_FORM',
  SELECT_KYC_METHOD: 'VERIFY_SELECT_KYC_METHOD',
  VERIFY_TOGGLE_TIER: 'VERIFY_TOGGLE_TIER',
  COMPANY_AS_SHIPPING_FIELD: 'company-as-shipping',
  PERSONAL_AS_SHIPPING_FIELD: 'personal-as-shipping',
  COMPANY_RELATIONSHIP_FIELD: 'CompanyRelationship',
  VERIFY_SUBMIT_KYC: 'submit-kyc',
  VERIFY_SUBMIT_CUSTOMER_KYC: 'submit-customer-kyc',
  START_OTP_SMS_REQUEST: 'START_OTP_SMS_REQUEST',
  OTP_SMS_REQUEST_SUCCESS: 'OTP_SMS_REQUEST_SUCCESS',
  OTP_SMS_REQUEST_FAILURE: 'OTP_SMS_REQUEST_FAILURE',
  OTP_SMS_VERIFY_START: 'OTP_SMS_VERIFY_START',
  OTP_SMS_VERIFY_SUCCESS: 'OTP_SMS_VERIFY_SUCCESS',
  OTP_SMS_VERIFY_FAILURE: 'OTP_SMS_VERIFY_FAILURE',
  OTP_CODE_MALFORMED: 'OTP_CODE_MALFORMED',
  TOGGLE_PHONE_VERIFICATION_STRATEGY: 'TOGGLE_PHONE_VERIFICATION_STRATEGY',
  PHONE_VERIFY_UNKNOWN_ERROR: 'PHONE_VERIFY_UNKNOWN_ERROR',
  // Blank object template which mirrors what /Customer/<custid> GET returns
  CUSTOMER_DETAILS: {
    DateOfBirth: '',
    FirstName: '',
    MiddleName: '',
    LastName: '',
    LocalFName: '',
    LocalMName: '',
    LocalLName: '',
    Username: '',
    DisplayName: '',
    DisplayNameType: '',
    Email: '',
    Phone: '',
    PhoneAlternate: '',
    CompanyName: '',
    IsCompany: false,
    UseUsernameAsDisplayName: false,
    IssueCheckInCompanyName: 'false',
    PersonalAddress: {
      City: '',
      Country: '',
      State: '',
      Street1: '',
      Street2: '',
      Zip: '',
    },
    CompanyAddress: null,
    ShippingAddress: null,
  },
  VERIFICATION: {
    phone: false,
    verified_phones: [],
  },
  // legacy fields object that used to be sent to the Address/KYC endpoints
  ADDRESS_FIELDS: {
    City: '',
    Country: '',
    DateOfBirth: '',
    FirstName: '',
    LastName: '',
    Phone: '',
    PhoneAlternate: '',
    State: '',
    Street1: '',
    Street2: '',
    Zip: '',
  },
  PERSONAL_ADDRESS_FIELDS: {
    City: '',
    Country: '',
    State: '',
    Street1: '',
    Street2: '',
    Zip: '',
  },
  KYC_FIELDS: [
    'IDType',
    'AddressProofType',
    'CompanyProofType',
    'CompanyName',
    'CompanyType',
    'CompanyRelationship',
    'IdContainsAddress',
    'HasPhotoVerification',
  ],
  COMPANY_ADDRESS_FIELDS: {
    CompanyAddressCity: '',
    CompanyAddressCountry: '',
    CompanyAddressState: '',
    CompanyAddressStreet1: '',
    CompanyAddressStreet2: '',
    CompanyAddressZip: '',
  },
  SHIPPING_ADDRESS_FIELDS: {
    ShippingAddressCity: '',
    ShippingAddressCountry: '',
    ShippingAddressState: '',
    ShippingAddressStreet1: '',
    ShippingAddressStreet2: '',
    ShippingAddressZip: '',
  },
  LS_KYC_DATA_KEY: 'kycFormData',
  REQUIRED_FILE_BOXES: {
    'verify-tier-1': [],
    'verify-tier-2': ['verify-tier-2-identity-box', 'verify-tier-2-address-box'],
    'verify-tier-3': ['verify-tier-3-company-details-box'],
  },
  EXTENDED_KYC_BOX: {
    'verify-tier-2': ['verify-tier-2-photo-verification-box'],
  },
};
