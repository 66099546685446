import React, { useCallback } from 'react';

const AdyenActionCard = (props) => {
  const { adyenAction, adyenCheckout } = props;

  const actionRef = useCallback(
    (node) => {
      if (node !== null && adyenAction) {
        adyenCheckout.createFromAction(adyenAction).mount(node);
      }
    },
    [adyenAction, adyenCheckout]
  );

  return <div key="action" ref={actionRef} />;
};

export default AdyenActionCard;
