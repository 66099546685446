import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { customerDisplayNameSelector } from 'spa/selectors/CustomerSelectors';
import INTERVAL from 'spa/constants/IntervalConstants';

import WelcomeWidget from 'spa/components/PartnerDashboard/widgets/WelcomeWidget';
import TransactionsOverview from 'spa/components/PartnerDashboard/widgets/TransactionsOverview';
import UsersOverview from 'spa/components/PartnerDashboard/widgets/UsersOverview';

const OverviewPage = (props) => (
  <Fragment>
    <div className="grid grid--verticalStretch">
      <div className="grid-col grid-col--tablet-6 integrationsPortal-card">
        <WelcomeWidget name={props.displayName} />
      </div>
      <div className="grid-col grid-col--tablet-6 integrationsPortal-card">
        <UsersOverview interval={INTERVAL.MONTHLY.value} />
      </div>
    </div>
    <div className="grid grid--verticalStretch">
      <TransactionsOverview interval={INTERVAL.MONTHLY.value} />
    </div>
  </Fragment>
);

const mapStateToProps = (state) => ({
  displayName: customerDisplayNameSelector(state),
});

export default connect(mapStateToProps, null)(OverviewPage);
