import throttle from 'lodash/throttle';

import AppDispatcher from '../dispatcher/AppDispatcher';
import TrackingConstants from '../constants/TrackingConstants';

export class TrackingActions {
  constructor() {
    this.flush = throttle(this.flush.bind(this), 500);
  }

  flush() {
    AppDispatcher.handleViewAction({
      actionType: TrackingConstants.TRACK_FLUSH,
    });
  }

  track(event, wait = false) {
    AppDispatcher.handleViewAction({
      actionType: TrackingConstants.TRACK_EVENT,
      attributes: {
        event: event,
        wait: wait,
      },
    });

    setTimeout(this.flush, 500);
  }

  trackABTest(experimentName, bucket) {
    AppDispatcher.handleViewAction({
      actionType: TrackingConstants.TRACK_AB_TEST,
      attributes: {
        experimentName,
        bucket,
      },
    });
  }

  trackVerificationCodeRequest(formName, type, success, failureInfo) {
    this.track({
      event: 'escrow_user_action',
      section: formName,
      action: `verify-code-request-${type}`,
      value: success ? 'success' : `failure: ${failureInfo}`,
      name: 'phone-verification',
      label: 'code-request',
    });
  }

  trackPhoneVerificationAttempt(formName, reason) {
    this.track({
      event: 'escrow_user_action',
      section: formName,
      action: 'phone-code-verify-request',
      value: reason,
      name: 'phone-verification',
      label: 'code-verify',
    });
  }
}

export default new TrackingActions();
