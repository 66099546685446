import React from 'react';
import { kycGotoNextPage as kycGotoNextPageRoutine } from 'spa/actions/VerificationActions';
import { connect } from 'react-redux';
import { getVerifyButtonNextPage } from 'spa/utils/Verification';
import SubmitButton from '../../StartTransaction/SubmitButton';

const VerifyButton = ({ verificationStatusText, company, fullWidth, loadNextPage }) => {
  let text = '';

  switch (verificationStatusText) {
    case 'Under Review':
    case '':
      text = 'Verify now';
      break;
    case 'Rejected':
      text = 'Resubmit Verification';
      break;
    case 'Additional Document Required':
    case 'Document Required':
      text = fullWidth ? 'Upload document' : 'Upload requested document';
      break;
    default:
      break;
  }

  if (text) {
    return (
      <SubmitButton
        fullWidth={fullWidth}
        onClick={() => loadNextPage({ nextPage: getVerifyButtonNextPage(company) })}
        muiVariant="contained"
        data-testid="kyc-details-verify-button"
      >
        {text}
      </SubmitButton>
    );
  }

  return null;
};

const mapDispatchToProps = (dispatch) => ({
  loadNextPage: (nextPage) => dispatch(kycGotoNextPageRoutine.trigger(nextPage)),
});

export default connect(null, mapDispatchToProps)(VerifyButton);
