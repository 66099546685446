import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import ReactSVG from 'react-svg';
import { Stack } from '@mui/material';

import {
  V3KYCMobilePages as Pages,
  KYC_FILES as FILES,
  KYC_BUTTONS as BUTTONS,
  KYC_TITLES as TITLES,
  KYC_SUBTITLES as SUBTITLES,
} from 'spa/constants/VerificationConstants';
import { kycUploadedFileInfoSelector } from 'spa/features/kyc/kycSlice';
import { SmallText } from 'spa/features/ui/EscrowTypography';
import { useUploadFileMutation, useVerifyCustomerMutation } from 'spa/features/kyc/kycApi';
import {
  kycImagesSelector,
  kycSelectedIDSelector,
  kycSelectedPOASelector,
  updateKycImageId,
  updateUploadedFilesInfo,
} from 'spa/features/kyc/kycSlice';
import { ERROR_TYPES } from 'spa/constants/VerificationConstants';

import MobilePage from '../../components/MobilePage';
import UploadFileButton from '../../components/ExtraButtons/UploadFileButton';
import KYCAlert from '../../components/KYCAlert';

const fileKey = FILES.ADDRESS;

const POAFileReviewPage = () => {
  const dispatch = useDispatch();

  const poaFileInfo = useSelector(kycUploadedFileInfoSelector(fileKey));
  const poaFileName = poaFileInfo && poaFileInfo.fileName ? poaFileInfo.fileName : null;
  const poaFileSize = poaFileInfo && poaFileInfo.fileSize ? poaFileInfo.fileSize : null;

  const kycImages = useSelector(kycImagesSelector);

  const kycId = useSelector(kycSelectedIDSelector);
  const kycPOA = useSelector(kycSelectedPOASelector);

  const [nextPageTrigger, setNextPageTrigger] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileName, setFileName] = useState('');
  const [fileSize, setFileSize] = useState(0);

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm();

  const [
    uploadFile,
    {
      data: uploadData,
      isSuccess: isSuccessUpload,
      isLoading: isLoadingUpload,
      error: uploadError,
    },
  ] = useUploadFileMutation();

  // eslint-disable-next-line no-console
  console.log(
    'uploadData, isSuccessUpload, isLoadingUpload, error',
    uploadData,
    isSuccessUpload,
    isLoadingUpload,
    uploadError
  );

  const [
    verifyCustomerT2,
    {
      isLoading: isLoadingVerify,
      isError: isErrorVerify,
      error: verifyError,
      isSuccess: isSuccessVerify,
    },
  ] = useVerifyCustomerMutation();

  // eslint-disable-next-line no-console
  console.log(
    'isLoadingVerify, isErrorVerify, verifyError, isSuccessVerify',
    isLoadingVerify,
    isErrorVerify,
    verifyError,
    isSuccessVerify
  );

  const verifyT2KYC = useCallback(async () => {
    const t2DocumentsObject = [];
    const IdProofFiles = kycImages[FILES.ID_BACK]
      ? [kycImages[FILES.ID_FRONT].id, kycImages[FILES.ID_BACK].id]
      : [kycImages[FILES.ID_FRONT].id];

    t2DocumentsObject.push({
      file_ids: IdProofFiles,
      proof_type: kycId.type,
      proof_for: ['id'],
    });
    t2DocumentsObject.push({
      file_ids: [kycImages[FILES.ADDRESS].id],
      proof_type: kycPOA.type,
      proof_for: ['address'],
    });

    const payload = {
      account: {
        type: 'individual',
      },
      documents: t2DocumentsObject,
    };
    await verifyCustomerT2(payload);
  }, [kycId.type, kycImages, kycPOA.type, verifyCustomerT2]);

  useEffect(() => {
    if (isSuccessUpload && uploadData) {
      const uploadId = uploadData.id;
      if (uploadId) {
        dispatch(updateKycImageId({ fileKey: FILES.ADDRESS, id: uploadId }));
        dispatch(updateUploadedFilesInfo({ fileKey, fileName, fileSize }));
      }
    }
  }, [uploadData, dispatch, isSuccessUpload, fileName, fileSize]);

  useEffect(() => {
    if (isSubmitting && isSuccessVerify) {
      setNextPageTrigger(true);
    }
  }, [isSubmitting, isSuccessVerify]);

  useEffect(() => {
    if (isSubmitting && verifyError) {
      setIsSubmitting(false);
    }
  }, [isSubmitting, verifyError]);

  const disabled = isLoadingUpload || isSubmitting;

  return (
    <form>
      <MobilePage
        title={TITLES.UPLOADED_FILE}
        subtitle={SUBTITLES.UPLOADED_FILE}
        ctaText={verifyError ? BUTTONS.TryAgain : BUTTONS.SubmitFile}
        nextPageTrigger={nextPageTrigger}
        onSubmit={handleSubmit(() => {
          if (isValid) {
            // To allow time for the file to API calls to end
            // before moving to the next page,
            // we instead set the Submit state to true
            setIsSubmitting(true);
            verifyT2KYC();
          }
        })}
        nextPage={Pages.POA_UPLOAD_SUCCESS}
        disableButtons={disabled}
        ExtraButton={(props) => (
          <UploadFileButton
            {...props}
            fileKey={fileKey}
            control={control}
            onUploadFile={(file) => {
              uploadFile(file);
              setFileName(file.name);
              setFileSize(file.size);
            }}
            disabled={disabled}
          >
            Replace file
          </UploadFileButton>
        )}
      >
        {uploadError && (
          <KYCAlert
            isError={uploadError}
            errorType={ERROR_TYPES.FILE_UPLOAD_FAILED}
            xRequestId={uploadError.xRequestId}
          />
        )}
        {verifyError && (
          <KYCAlert
            isError={verifyError}
            errorType={ERROR_TYPES.SUBMISSION_FAILED}
            xRequestId={verifyError.xRequestId}
          />
        )}
        <Stack direction="row" sx={{ gap: '8px' }}>
          <ReactSVG src="spa/../../build/images/kyc/file-display-thumbnail.svg" />
          <Stack direction="column">
            <Fragment>
              <SmallText sx={{ fontSize: '16px', fontWeight: '600' }}>{poaFileName}</SmallText>
              <SmallText sx={{ fontSize: '16px' }}>
                {(poaFileSize / 1000 ** 2).toFixed(2)} MB
              </SmallText>
            </Fragment>
          </Stack>
        </Stack>
      </MobilePage>
    </form>
  );
};

export default POAFileReviewPage;
