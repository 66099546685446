import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'spa/components/Icon';

const ChannelCard = ({ onClick, iconPath, title, description, cta, small }) => (
  <div className="integrationsPortal-channels-item">
    <a
      onClick={onClick}
      className={`channelCard ${small && 'channelCard--small'}`}
      role="button"
      tabIndex={0}
    >
      <div className="channelCard-inner">
        {iconPath && (
          <span className="channelCard-icon">
            <Icon name={iconPath} className="channelCard-icon-img" />
          </span>
        )}
        <div className="channelCard-content">
          {title && <span className="channelCard-title">{title}</span>}
          <span className="channelCard-description">{description}</span>
          <span className="btn btn--secondary channelCard-cta">{cta}</span>
        </div>
      </div>
    </a>
  </div>
);

ChannelCard.propTypes = {
  onClick: PropTypes.func,
  iconPath: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  cta: PropTypes.string,
  small: PropTypes.bool,
};

export default ChannelCard;
