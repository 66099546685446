import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';

class UserPage extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { route } = this.props;

    return <div>{renderRoutes(route.routes)}</div>;
  }
}

UserPage.propTypes = {
  location: PropTypes.object,
  route: PropTypes.object,
};

const UserPageContainer = withRouter(UserPage);

export default UserPageContainer;
