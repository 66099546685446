import { connect } from 'react-redux';
import { setPaymentType, setPaypalOrderDetails } from 'spa/actions/CheckoutActions';
import {
  checkoutTransIdSelector,
  checkoutAuthorizedPayments,
} from 'spa/selectors/CheckoutSelectors';
import { transactionByIdSelector } from 'spa/selectors/TransactionSelectors';
import { PaymentMethodSelectionForm } from 'spa/components/CheckoutDetails/V2';
import { customerCountrySelector } from 'spa/selectors/CustomerSelectors';

export const mapStateToProps = (state, ownProps) => {
  const transId = checkoutTransIdSelector(state);
  const paymentMethods = (transactionByIdSelector(state, transId) || {}).paymentMethods || {};
  const availablePaymentMethods = paymentMethods.available_payment_methods || [];
  const conditionalPaymentMethods = paymentMethods.conditionally_available_payment_methods || [];
  const authorizedPayments = checkoutAuthorizedPayments(state);
  return {
    ...ownProps,
    customerCountry: customerCountrySelector(state),
    authorizedPayments,
    paymentMethods: [...availablePaymentMethods, ...conditionalPaymentMethods],
    totalWithoutPaymentFee: paymentMethods.total_without_payment_fee,
    currency: transactionByIdSelector(state, transId).currency,
    transId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setPaymentType: (paymentType) => dispatch(setPaymentType(paymentType)),
  setPaypalOrderDetails: (orderDetails) => dispatch(setPaypalOrderDetails(orderDetails)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodSelectionForm);
