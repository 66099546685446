import React from 'react';
import { gettext } from '../../../utils/filters';

const getCurrentYear = new Date().getFullYear();

const Footer = () => (
  <footer className="simpleFooter">
    <div className="section-container">
      <nav className="simpleFooter-nav">
        <a href="/legal" target="_blank" className="simpleFooter-nav-link">
          {gettext('Privacy Policy')}
        </a>
        <a href="/escrow-licenses" target="_blank" className="simpleFooter-nav-link">
          {gettext('Licenses and Complaints')}
        </a>
        <a href="/legal" target="_blank" className="simpleFooter-nav-link">
          {gettext('Legal')}
        </a>
      </nav>
      <p className="simpleFooter-copyright">
        {gettext(`Copyright © 1999-${getCurrentYear} Escrow.com, Inc. All rights reserved`)}
      </p>
    </div>
  </footer>
);

export default Footer;
