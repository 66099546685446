import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import formatNumber from 'format-number';
import InfoTooltip from './InfoTooltip';
import FieldError from './FieldError';
import { gettext } from '../../../utils/filters';

// This component will return a format of ISO8601 date string (e.g. '2018-02-04')
// It can be initialised by an ISO8601 date string (e.g. '2018-2-04')
class DateOfBirthField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      year: '',
      month: '',
      day: '',
    };

    if (Date.parse(props.input.value)) {
      const dob = moment.utc(props.input.value);
      this.state.year = dob.year();
      this.state.month = dob.month() + 1;
      this.state.day = dob.date();
    }

    this.options = {
      months: window.config.months.map((month) => ({
        label: month.name,
        value: parseInt(month.value, 10).toString(),
      })),
    };

    this.handleYearChange = this.normalizeYearChange().bind(this);
    this.handleMonthChange = this.handleFieldChange('month').bind(this);
    this.handleDayChange = this.normalizeDayChange().bind(this);
  }

  componentDidMount() {
    this.props.input.onChange(this.getDateStringFromState(this.state));
  }

  getDateStringFromState(state) {
    const format = formatNumber({ padLeft: 2 });
    if (!state.year || !state.month || !state.day) return '';
    return `${state.year}-${format(state.month)}-${format(state.day)}`;
  }

  handleFieldChange(field) {
    return (event) => {
      const newState = { ...this.state, [field]: event.target.value };
      this.setState(newState);
      this.props.input.onChange(this.getDateStringFromState(newState));
    };
  }

  normalizeDayChange() {
    return (event) => {
      let day;
      const re = /^[0-9]{0,2}$/;

      if (event.target.value === '') {
        this.handleFieldChange('day')(event);
      } else if (re.test(event.target.value)) {
        day = parseInt(event.target.value, 10);

        if (day >= 1 && day <= 31) {
          this.handleFieldChange('day')(event);
        }
      }
    };
  }

  normalizeYearChange() {
    return (event) => {
      const re = /^[0-9]{0,4}$/;

      if (re.test(event.target.value)) {
        this.handleFieldChange('year')(event);
      }
    };
  }

  renderMonthOption() {
    return this.options.months.map((opt) => (
      <option key={opt.value} value={opt.value}>
        {opt.label.substr(0, 3)}
      </option>
    ));
  }

  render() {
    const {
      label,
      input,
      meta: { error, touched, warning },
      disabled,
      requiredIndicator,
      tooltip,
      prefix,
      className,
      children,
    } = this.props;

    const isInvalid = (error || warning) && touched;
    const e2e = this.props['data-e2e-target'];

    return (
      <div
        className={classnames('field field--group', className, { 'is-invalid': isInvalid })}
        key={input.name}
      >
        {label && (
          <label htmlFor={input.name} className="field-label">
            {label}
            {children}
            {requiredIndicator && <span className="field-required">*</span>}
            {tooltip && <InfoTooltip id={`${name}-field-input-tooltip`} message={tooltip} />}
          </label>
        )}

        {prefix && <div className="field-prefix">{prefix}</div>}
        <div className="defaultForm-group defaultForm-group--mobile">
          <div className="field field--month">
            <div className="field-input">
              <div className="defaultSelect defaultSelect--form">
                <select
                  id={`${input.name}-month`}
                  className="defaultSelect-select"
                  value={this.state.month}
                  disabled={disabled}
                  onChange={this.handleMonthChange}
                  data-e2e-target={e2e ? `${e2e}-month` : null}
                >
                  <option value="" disabled>
                    {gettext('Month')}
                  </option>
                  {this.renderMonthOption()}
                </select>
              </div>
            </div>
          </div>

          <div className="field">
            <div className="field-input">
              <input
                id={`${input.name}-day`}
                className="defaultInput"
                placeholder={gettext('Day')}
                value={this.state.day}
                disabled={disabled}
                onChange={this.handleDayChange}
                data-e2e-target={e2e ? `${e2e}-day` : null}
              />
            </div>
          </div>

          <div className="field">
            <div className="field-input">
              <input
                id={`${input.name}-year`}
                className="defaultInput"
                placeholder={gettext('Year')}
                value={this.state.year}
                disabled={disabled}
                onChange={this.handleYearChange}
                data-e2e-target={e2e ? `${e2e}-year` : null}
              />
            </div>
          </div>
        </div>
        {error && touched && <FieldError message={error} />}
        {warning && touched && <FieldError message={warning} />}
      </div>
    );
  }
}

export default DateOfBirthField;
