var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/components/buttons.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
[], 
["text", "style", "specifier", "mutators", "attrs", "icon", "link", "external", "title", "type", "radio"], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("text", kwargs.hasOwnProperty("text") ? kwargs["text"] : "");
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutators", kwargs.hasOwnProperty("mutators") ? kwargs["mutators"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("icon", kwargs.hasOwnProperty("icon") ? kwargs["icon"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("external", kwargs.hasOwnProperty("external") ? kwargs["external"] : "");
frame.set("title", kwargs.hasOwnProperty("title") ? kwargs["title"] : "");
frame.set("type", kwargs.hasOwnProperty("type") ? kwargs["type"] : "");
frame.set("radio", kwargs.hasOwnProperty("radio") ? kwargs["radio"] : "");
var t_2 = "";if(runtime.contextOrFrameLookup(context, frame, "link")) {
t_2 += "<a href=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "link"), env.opts.autoescape);
t_2 += "\" class=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "style"), env.opts.autoescape);
t_2 += " ";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mutators"), env.opts.autoescape);
t_2 += " ";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "specifier"), env.opts.autoescape);
t_2 += " ";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "modifier_class"), env.opts.autoescape);
t_2 += "\"";
t_2 += runtime.suppressValue(env.getFilter("xmlattr").call(context, runtime.contextOrFrameLookup(context, frame, "attrs")), env.opts.autoescape);
t_2 += " ";
if(runtime.contextOrFrameLookup(context, frame, "external")) {
t_2 += "target=\"_blank\" rel=\"nofollow external noopener\"";
;
}
if(runtime.contextOrFrameLookup(context, frame, "title")) {
t_2 += "title=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "title"), env.opts.autoescape);
t_2 += "\"";
;
}
if(runtime.contextOrFrameLookup(context, frame, "type")) {
t_2 += "type=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "type"), env.opts.autoescape);
t_2 += "\"";
;
}
t_2 += ">";
;
}
else {
t_2 += "\n<button class=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "style"), env.opts.autoescape);
t_2 += " ";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mutators"), env.opts.autoescape);
t_2 += " ";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "specifier"), env.opts.autoescape);
t_2 += "\"";
if(runtime.contextOrFrameLookup(context, frame, "title")) {
t_2 += "title=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "title"), env.opts.autoescape);
t_2 += "\"";
;
}
if(runtime.contextOrFrameLookup(context, frame, "type")) {
t_2 += "type=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "type"), env.opts.autoescape);
t_2 += "\"";
;
}
t_2 += " ";
t_2 += runtime.suppressValue(env.getFilter("xmlattr").call(context, runtime.contextOrFrameLookup(context, frame, "attrs")), env.opts.autoescape);
t_2 += ">";
;
}
if(runtime.contextOrFrameLookup(context, frame, "icon")) {
t_2 += "<span class=\"btn-icon\">";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "icon"), env.opts.autoescape);
t_2 += "</span>";
;
}
t_2 += "\n";
if(runtime.contextOrFrameLookup(context, frame, "radio")) {
t_2 += "<input type=\"radio\" name=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "radio"), env.opts.autoescape);
t_2 += "\">";
;
}
t_2 += "\n";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "text"), env.opts.autoescape);
t_2 += "\n";
if(runtime.contextOrFrameLookup(context, frame, "link")) {
t_2 += "</a>";
;
}
else {
t_2 += "\n</button>";
;
}
;
frame = frame.pop();
return new runtime.SafeString(t_2);
});
context.setVariable("_button", macro_t_1);
output += "\n\n";
var macro_t_3 = runtime.makeMacro(
["text"], 
["style", "specifier", "mutators", "attrs", "icon", "link", "external", "title", "type", "radio"], 
function (l_text, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("text", l_text);
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutators", kwargs.hasOwnProperty("mutators") ? kwargs["mutators"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("icon", kwargs.hasOwnProperty("icon") ? kwargs["icon"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("external", kwargs.hasOwnProperty("external") ? kwargs["external"] : "");
frame.set("title", kwargs.hasOwnProperty("title") ? kwargs["title"] : "");
frame.set("type", kwargs.hasOwnProperty("type") ? kwargs["type"] : "");
frame.set("radio", kwargs.hasOwnProperty("radio") ? kwargs["radio"] : "");
var t_4 = "";t_4 += runtime.suppressValue((lineno = 21, colno = 8, runtime.callWrap(macro_t_1, "_button", context, [l_text,"btn",runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutators"),runtime.contextOrFrameLookup(context, frame, "attrs"),runtime.contextOrFrameLookup(context, frame, "icon"),runtime.contextOrFrameLookup(context, frame, "link"),runtime.contextOrFrameLookup(context, frame, "external"),runtime.contextOrFrameLookup(context, frame, "title"),runtime.contextOrFrameLookup(context, frame, "type"),runtime.contextOrFrameLookup(context, frame, "radio")])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_4);
});
context.addExport("default");
context.setVariable("default", macro_t_3);
output += "\n\n";
var macro_t_5 = runtime.makeMacro(
["text"], 
["style", "specifier", "mutators", "attrs", "icon", "link", "external", "title", "type", "radio"], 
function (l_text, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("text", l_text);
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutators", kwargs.hasOwnProperty("mutators") ? kwargs["mutators"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("icon", kwargs.hasOwnProperty("icon") ? kwargs["icon"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("external", kwargs.hasOwnProperty("external") ? kwargs["external"] : "");
frame.set("title", kwargs.hasOwnProperty("title") ? kwargs["title"] : "");
frame.set("type", kwargs.hasOwnProperty("type") ? kwargs["type"] : "");
frame.set("radio", kwargs.hasOwnProperty("radio") ? kwargs["radio"] : "");
var t_6 = "";t_6 += runtime.suppressValue((lineno = 25, colno = 8, runtime.callWrap(macro_t_1, "_button", context, [l_text,"btn btn--primary",runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutators"),runtime.contextOrFrameLookup(context, frame, "attrs"),runtime.contextOrFrameLookup(context, frame, "icon"),runtime.contextOrFrameLookup(context, frame, "link"),runtime.contextOrFrameLookup(context, frame, "external"),runtime.contextOrFrameLookup(context, frame, "title"),runtime.contextOrFrameLookup(context, frame, "type"),runtime.contextOrFrameLookup(context, frame, "radio")])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_6);
});
context.addExport("primary");
context.setVariable("primary", macro_t_5);
output += "\n\n";
var macro_t_7 = runtime.makeMacro(
["text"], 
["style", "specifier", "mutators", "attrs", "icon", "link", "external", "title", "type", "radio"], 
function (l_text, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("text", l_text);
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutators", kwargs.hasOwnProperty("mutators") ? kwargs["mutators"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("icon", kwargs.hasOwnProperty("icon") ? kwargs["icon"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("external", kwargs.hasOwnProperty("external") ? kwargs["external"] : "");
frame.set("title", kwargs.hasOwnProperty("title") ? kwargs["title"] : "");
frame.set("type", kwargs.hasOwnProperty("type") ? kwargs["type"] : "");
frame.set("radio", kwargs.hasOwnProperty("radio") ? kwargs["radio"] : "");
var t_8 = "";t_8 += runtime.suppressValue((lineno = 29, colno = 8, runtime.callWrap(macro_t_1, "_button", context, [l_text,"btn btn--primary btn--hollow",runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutators"),runtime.contextOrFrameLookup(context, frame, "attrs"),runtime.contextOrFrameLookup(context, frame, "icon"),runtime.contextOrFrameLookup(context, frame, "link"),runtime.contextOrFrameLookup(context, frame, "external"),runtime.contextOrFrameLookup(context, frame, "title"),runtime.contextOrFrameLookup(context, frame, "type"),runtime.contextOrFrameLookup(context, frame, "radio")])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_8);
});
context.addExport("primaryHollow");
context.setVariable("primaryHollow", macro_t_7);
output += "\n\n";
var macro_t_9 = runtime.makeMacro(
["text"], 
["style", "specifier", "mutators", "attrs", "icon", "link", "external", "title", "type", "radio"], 
function (l_text, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("text", l_text);
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutators", kwargs.hasOwnProperty("mutators") ? kwargs["mutators"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("icon", kwargs.hasOwnProperty("icon") ? kwargs["icon"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("external", kwargs.hasOwnProperty("external") ? kwargs["external"] : "");
frame.set("title", kwargs.hasOwnProperty("title") ? kwargs["title"] : "");
frame.set("type", kwargs.hasOwnProperty("type") ? kwargs["type"] : "");
frame.set("radio", kwargs.hasOwnProperty("radio") ? kwargs["radio"] : "");
var t_10 = "";t_10 += runtime.suppressValue((lineno = 33, colno = 8, runtime.callWrap(macro_t_1, "_button", context, [l_text,"btn btn--secondary",runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutators"),runtime.contextOrFrameLookup(context, frame, "attrs"),runtime.contextOrFrameLookup(context, frame, "icon"),runtime.contextOrFrameLookup(context, frame, "link"),runtime.contextOrFrameLookup(context, frame, "external"),runtime.contextOrFrameLookup(context, frame, "title"),runtime.contextOrFrameLookup(context, frame, "type")])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_10);
});
context.addExport("secondary");
context.setVariable("secondary", macro_t_9);
output += "\n\n";
var macro_t_11 = runtime.makeMacro(
["text"], 
["style", "specifier", "mutators", "attrs", "icon", "link", "external", "title", "type", "radio"], 
function (l_text, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("text", l_text);
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutators", kwargs.hasOwnProperty("mutators") ? kwargs["mutators"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("icon", kwargs.hasOwnProperty("icon") ? kwargs["icon"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("external", kwargs.hasOwnProperty("external") ? kwargs["external"] : "");
frame.set("title", kwargs.hasOwnProperty("title") ? kwargs["title"] : "");
frame.set("type", kwargs.hasOwnProperty("type") ? kwargs["type"] : "");
frame.set("radio", kwargs.hasOwnProperty("radio") ? kwargs["radio"] : "");
var t_12 = "";t_12 += runtime.suppressValue((lineno = 37, colno = 8, runtime.callWrap(macro_t_1, "_button", context, [l_text,"btn btn--clear",runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutators"),runtime.contextOrFrameLookup(context, frame, "attrs"),runtime.contextOrFrameLookup(context, frame, "icon"),runtime.contextOrFrameLookup(context, frame, "link"),runtime.contextOrFrameLookup(context, frame, "external"),runtime.contextOrFrameLookup(context, frame, "title"),runtime.contextOrFrameLookup(context, frame, "type"),runtime.contextOrFrameLookup(context, frame, "radio")])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_12);
});
context.addExport("clear");
context.setVariable("clear", macro_t_11);
output += "\n\n";
var macro_t_13 = runtime.makeMacro(
["text"], 
["style", "specifier", "mutators", "attrs", "icon", "link", "external", "title", "type", "radio"], 
function (l_text, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("text", l_text);
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutators", kwargs.hasOwnProperty("mutators") ? kwargs["mutators"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("icon", kwargs.hasOwnProperty("icon") ? kwargs["icon"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("external", kwargs.hasOwnProperty("external") ? kwargs["external"] : "");
frame.set("title", kwargs.hasOwnProperty("title") ? kwargs["title"] : "");
frame.set("type", kwargs.hasOwnProperty("type") ? kwargs["type"] : "");
frame.set("radio", kwargs.hasOwnProperty("radio") ? kwargs["radio"] : "");
var t_14 = "";t_14 += runtime.suppressValue((lineno = 41, colno = 8, runtime.callWrap(macro_t_1, "_button", context, [l_text,"btn btn--link",runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutators"),runtime.contextOrFrameLookup(context, frame, "attrs"),runtime.contextOrFrameLookup(context, frame, "icon"),runtime.contextOrFrameLookup(context, frame, "link"),runtime.contextOrFrameLookup(context, frame, "external"),runtime.contextOrFrameLookup(context, frame, "title"),runtime.contextOrFrameLookup(context, frame, "type"),runtime.contextOrFrameLookup(context, frame, "radio")])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_14);
});
context.addExport("linkDefault");
context.setVariable("linkDefault", macro_t_13);
output += "\n\n";
var macro_t_15 = runtime.makeMacro(
["text"], 
["style", "specifier", "mutators", "attrs", "icon", "link", "external", "title", "type", "radio"], 
function (l_text, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("text", l_text);
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutators", kwargs.hasOwnProperty("mutators") ? kwargs["mutators"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("icon", kwargs.hasOwnProperty("icon") ? kwargs["icon"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("external", kwargs.hasOwnProperty("external") ? kwargs["external"] : "");
frame.set("title", kwargs.hasOwnProperty("title") ? kwargs["title"] : "");
frame.set("type", kwargs.hasOwnProperty("type") ? kwargs["type"] : "");
frame.set("radio", kwargs.hasOwnProperty("radio") ? kwargs["radio"] : "");
var t_16 = "";t_16 += runtime.suppressValue((lineno = 45, colno = 8, runtime.callWrap(macro_t_1, "_button", context, [l_text,"btn btn--linkInvert",runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutators"),runtime.contextOrFrameLookup(context, frame, "attrs"),runtime.contextOrFrameLookup(context, frame, "icon"),runtime.contextOrFrameLookup(context, frame, "link"),runtime.contextOrFrameLookup(context, frame, "external"),runtime.contextOrFrameLookup(context, frame, "title"),runtime.contextOrFrameLookup(context, frame, "type"),runtime.contextOrFrameLookup(context, frame, "radio")])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_16);
});
context.addExport("linkInvert");
context.setVariable("linkInvert", macro_t_15);
output += "\n\n";
var macro_t_17 = runtime.makeMacro(
["text"], 
["style", "specifier", "mutators", "attrs", "icon", "link", "external", "title", "type", "radio"], 
function (l_text, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("text", l_text);
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutators", kwargs.hasOwnProperty("mutators") ? kwargs["mutators"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("icon", kwargs.hasOwnProperty("icon") ? kwargs["icon"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("external", kwargs.hasOwnProperty("external") ? kwargs["external"] : "");
frame.set("title", kwargs.hasOwnProperty("title") ? kwargs["title"] : "");
frame.set("type", kwargs.hasOwnProperty("type") ? kwargs["type"] : "");
frame.set("radio", kwargs.hasOwnProperty("radio") ? kwargs["radio"] : "");
var t_18 = "";t_18 += runtime.suppressValue((lineno = 49, colno = 8, runtime.callWrap(macro_t_1, "_button", context, [l_text,"btn btn--warning",runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutators"),runtime.contextOrFrameLookup(context, frame, "attrs"),runtime.contextOrFrameLookup(context, frame, "icon"),runtime.contextOrFrameLookup(context, frame, "link"),runtime.contextOrFrameLookup(context, frame, "external"),runtime.contextOrFrameLookup(context, frame, "title"),runtime.contextOrFrameLookup(context, frame, "type")])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_18);
});
context.addExport("warning");
context.setVariable("warning", macro_t_17);
output += "\n\n\n<!-- Create transaction buttons -->\n\n";
var macro_t_19 = runtime.makeMacro(
[], 
["text", "style", "specifier", "mutators", "attrs", "icon", "link"], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("text", kwargs.hasOwnProperty("text") ? kwargs["text"] : "");
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutators", kwargs.hasOwnProperty("mutators") ? kwargs["mutators"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("icon", kwargs.hasOwnProperty("icon") ? kwargs["icon"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
var t_20 = "";if(runtime.contextOrFrameLookup(context, frame, "text") == "") {
var t_21;
t_21 = (lineno = 56, colno = 34, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Get started now"]));
frame.set("text", t_21, true);
if(frame.topLevel) {
context.setVariable("text", t_21);
}
if(frame.topLevel) {
context.addExport("text", t_21);
}
;
}
t_20 += "\n";
if(!runtime.contextOrFrameLookup(context, frame, "link")) {
t_20 += "\n\t";
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "attrs")),"data-auth-required")) {
var t_22;
t_22 = env.getFilter("setattr").call(context, runtime.contextOrFrameLookup(context, frame, "attrs"),"data-auth-required","transaction-choice");
frame.set("attrs", t_22, true);
if(frame.topLevel) {
context.setVariable("attrs", t_22);
}
if(frame.topLevel) {
context.addExport("attrs", t_22);
}
;
}
t_20 += "\n";
;
}
t_20 += "\n";
t_20 += runtime.suppressValue((lineno = 60, colno = 8, runtime.callWrap(macro_t_1, "_button", context, [runtime.contextOrFrameLookup(context, frame, "text"),"btn btn--secondary",runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutators"),runtime.contextOrFrameLookup(context, frame, "attrs"),runtime.contextOrFrameLookup(context, frame, "icon"),runtime.contextOrFrameLookup(context, frame, "link")])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_20);
});
context.addExport("startTransaction");
context.setVariable("startTransaction", macro_t_19);
output += "\n\n";
var macro_t_23 = runtime.makeMacro(
[], 
["text", "style", "specifier", "mutators", "attrs", "icon"], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("text", kwargs.hasOwnProperty("text") ? kwargs["text"] : "");
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutators", kwargs.hasOwnProperty("mutators") ? kwargs["mutators"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("icon", kwargs.hasOwnProperty("icon") ? kwargs["icon"] : "");
var t_24 = "";if(runtime.contextOrFrameLookup(context, frame, "text") == "") {
var t_25;
t_25 = (lineno = 64, colno = 34, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Get started now"]));
frame.set("text", t_25, true);
if(frame.topLevel) {
context.setVariable("text", t_25);
}
if(frame.topLevel) {
context.addExport("text", t_25);
}
;
}
t_24 += "\n";
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "attrs")),"data-auth-required")) {
var t_26;
t_26 = env.getFilter("setattr").call(context, runtime.contextOrFrameLookup(context, frame, "attrs"),"data-auth-required","transaction-choice");
frame.set("attrs", t_26, true);
if(frame.topLevel) {
context.setVariable("attrs", t_26);
}
if(frame.topLevel) {
context.addExport("attrs", t_26);
}
;
}
t_24 += "\n";
t_24 += runtime.suppressValue((lineno = 66, colno = 8, runtime.callWrap(macro_t_1, "_button", context, [runtime.contextOrFrameLookup(context, frame, "text"),"btn btn--secondary",runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutators"),runtime.contextOrFrameLookup(context, frame, "attrs"),runtime.contextOrFrameLookup(context, frame, "icon")])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_24);
});
context.addExport("startTransactionSecondary");
context.setVariable("startTransactionSecondary", macro_t_23);
output += "\n\n";
var macro_t_27 = runtime.makeMacro(
[], 
["text", "style", "specifier", "mutators", "attrs", "icon"], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("text", kwargs.hasOwnProperty("text") ? kwargs["text"] : "");
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutators", kwargs.hasOwnProperty("mutators") ? kwargs["mutators"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("icon", kwargs.hasOwnProperty("icon") ? kwargs["icon"] : "");
var t_28 = "";if(runtime.contextOrFrameLookup(context, frame, "text") == "") {
var t_29;
t_29 = (lineno = 70, colno = 34, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Get started now"]));
frame.set("text", t_29, true);
if(frame.topLevel) {
context.setVariable("text", t_29);
}
if(frame.topLevel) {
context.addExport("text", t_29);
}
;
}
t_28 += "\n";
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "attrs")),"data-auth-required")) {
var t_30;
t_30 = env.getFilter("setattr").call(context, runtime.contextOrFrameLookup(context, frame, "attrs"),"data-auth-required","transaction-choice");
frame.set("attrs", t_30, true);
if(frame.topLevel) {
context.setVariable("attrs", t_30);
}
if(frame.topLevel) {
context.addExport("attrs", t_30);
}
;
}
t_28 += "\n";
t_28 += runtime.suppressValue((lineno = 72, colno = 8, runtime.callWrap(macro_t_1, "_button", context, [runtime.contextOrFrameLookup(context, frame, "text"),"btn btn--secondary",runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutators"),runtime.contextOrFrameLookup(context, frame, "attrs"),runtime.contextOrFrameLookup(context, frame, "icon")])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_28);
});
context.addExport("startTransactionLight");
context.setVariable("startTransactionLight", macro_t_27);
output += "\n\n";
var macro_t_31 = runtime.makeMacro(
[], 
["text", "style", "specifier", "mutators", "link", "attrs", "icon"], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("text", kwargs.hasOwnProperty("text") ? kwargs["text"] : "");
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "btn--large");
frame.set("mutators", kwargs.hasOwnProperty("mutators") ? kwargs["mutators"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "/sales");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("icon", kwargs.hasOwnProperty("icon") ? kwargs["icon"] : "");
var t_32 = "";if(runtime.contextOrFrameLookup(context, frame, "text") == "") {
var t_33;
t_33 = (lineno = 76, colno = 34, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Get in Touch"]));
frame.set("text", t_33, true);
if(frame.topLevel) {
context.setVariable("text", t_33);
}
if(frame.topLevel) {
context.addExport("text", t_33);
}
;
}
t_32 += "\n";
t_32 += runtime.suppressValue((lineno = 77, colno = 8, runtime.callWrap(macro_t_1, "_button", context, [runtime.contextOrFrameLookup(context, frame, "text"),"btn btn--primary btn--hollow",runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutators"),runtime.contextOrFrameLookup(context, frame, "attrs"),runtime.contextOrFrameLookup(context, frame, "icon"),runtime.contextOrFrameLookup(context, frame, "link")])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_32);
});
context.addExport("becomePartner");
context.setVariable("becomePartner", macro_t_31);
output += "\n\n";
var macro_t_34 = runtime.makeMacro(
[], 
["text", "style", "specifier", "mutators", "attrs", "icon", "link"], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("text", kwargs.hasOwnProperty("text") ? kwargs["text"] : "");
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutators", kwargs.hasOwnProperty("mutators") ? kwargs["mutators"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("icon", kwargs.hasOwnProperty("icon") ? kwargs["icon"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
var t_35 = "";if(!runtime.contextOrFrameLookup(context, frame, "text")) {
var t_36;
t_36 = (lineno = 83, colno = 32, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Get started now"]));
frame.set("text", t_36, true);
if(frame.topLevel) {
context.setVariable("text", t_36);
}
if(frame.topLevel) {
context.addExport("text", t_36);
}
;
}
t_35 += "\n";
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "attrs")),"data-target")) {
var t_37;
t_37 = env.getFilter("setattr").call(context, runtime.contextOrFrameLookup(context, frame, "attrs"),"data-target","start-transaction");
frame.set("attrs", t_37, true);
if(frame.topLevel) {
context.setVariable("attrs", t_37);
}
if(frame.topLevel) {
context.addExport("attrs", t_37);
}
;
}
t_35 += "\n";
t_35 += runtime.suppressValue((lineno = 85, colno = 8, runtime.callWrap(macro_t_1, "_button", context, [runtime.contextOrFrameLookup(context, frame, "text"),"btn btn--secondary",runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutators"),runtime.contextOrFrameLookup(context, frame, "attrs"),runtime.contextOrFrameLookup(context, frame, "icon"),runtime.contextOrFrameLookup(context, frame, "link")])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_35);
});
context.addExport("startTransactionPartnerLanding");
context.setVariable("startTransactionPartnerLanding", macro_t_34);
output += "\n\n";
var macro_t_38 = runtime.makeMacro(
["inputs"], 
["text", "style", "specifier", "mutators", "attrs", "icon", "action"], 
function (l_inputs, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("inputs", l_inputs);
frame.set("text", kwargs.hasOwnProperty("text") ? kwargs["text"] : "");
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "btn--large");
frame.set("mutators", kwargs.hasOwnProperty("mutators") ? kwargs["mutators"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("icon", kwargs.hasOwnProperty("icon") ? kwargs["icon"] : "");
frame.set("action", kwargs.hasOwnProperty("action") ? kwargs["action"] : "");
var t_39 = "";t_39 += "<form action=\"";
t_39 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "action"), env.opts.autoescape);
t_39 += "\" method=\"post\">\n        ";
frame = frame.push();
var t_42 = l_inputs;
if(t_42) {var t_41 = t_42.length;
for(var t_40=0; t_40 < t_42.length; t_40++) {
var t_43 = t_42[t_40];
frame.set("input", t_43);
frame.set("loop.index", t_40 + 1);
frame.set("loop.index0", t_40);
frame.set("loop.revindex", t_41 - t_40);
frame.set("loop.revindex0", t_41 - t_40 - 1);
frame.set("loop.first", t_40 === 0);
frame.set("loop.last", t_40 === t_41 - 1);
frame.set("loop.length", t_41);
t_39 += "\n            <input type=\"hidden\" name=\"";
t_39 += runtime.suppressValue(runtime.memberLookup((t_43),"name"), env.opts.autoescape);
t_39 += "\" value=\"";
t_39 += runtime.suppressValue(runtime.memberLookup((t_43),"value"), env.opts.autoescape);
t_39 += "\">\n        ";
;
}
}
frame = frame.pop();
t_39 += "\n\n        ";
t_39 += runtime.suppressValue((lineno = 94, colno = 16, runtime.callWrap(macro_t_1, "_button", context, [runtime.contextOrFrameLookup(context, frame, "text"),"btn btn--secondary",runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutators"),runtime.contextOrFrameLookup(context, frame, "attrs"),runtime.contextOrFrameLookup(context, frame, "icon")])), env.opts.autoescape);
t_39 += "\n    </form>";
;
frame = frame.pop();
return new runtime.SafeString(t_39);
});
context.addExport("post");
context.setVariable("post", macro_t_38);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/components/buttons.html"] , dependencies)