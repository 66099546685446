import AppDispatcher from '../dispatcher/AppDispatcher';
import { ChangeEmitter } from '../utils/ChangeEmitter';
import ModalActions from '../actions/ModalActions';
import NavigationActions from '../actions/NavigationActions';
import ModalConstants from '../constants/ModalConstants';
import NavigationStore from '../stores/NavigationStore';
import NavigationConstants from '../constants/NavigationConstants';
import TrackingActions from '../actions/TrackingActions';

export class ModalStore extends ChangeEmitter {
  constructor() {
    super();

    this.modal = NavigationStore.getState().modal;
    this.handleViewAction = this.handleViewAction.bind(this);
    this.modals = [];
  }

  openModal(name) {
    this.modal = name;
    setTimeout(() => {
      TrackingActions.track({
        event: 'escrow_user_action',
        section: name,
        action: 'modal-open',
      });
    });

    if (this.modals[name]) {
      // setTimeout() to run action in a different context to avoid
      // dispatching in middle of disatch
      setTimeout(() => {
        NavigationActions.pushState(
          {
            modal: name,
          },
          null,
          null
        );
      });
    }
    this.emitChange();
  }

  closeModal(name) {
    this.modal = false;
    setTimeout(() => {
      TrackingActions.track({
        event: 'escrow_user_action',
        section: name,
        action: 'modal-close',
      });
    });
  }

  showingModal() {
    return this.modal;
  }

  registerModal(name, persistent) {
    this.modals[name] = persistent;
  }

  handleViewAction(action) {
    const actionType = action.actionType;
    if (actionType === ModalConstants.MODAL_OPEN) {
      this.openModal(action.name);
    } else if (actionType === ModalConstants.MODAL_CLOSE) {
      this.closeModal(action.name);
      this.emitChange();
    } else if (actionType === NavigationConstants.HISTORY_POP) {
      AppDispatcher.waitFor([NavigationStore.dispatchToken]);
      this.modal = NavigationStore.getState().modal;
      this.emitChange();
    } else if (actionType === ModalConstants.MODAL_TOGGLE) {
      if (this.showingModal()) {
        setTimeout(() => {
          ModalActions.close(action.name);
        });
      } else {
        setTimeout(() => {
          ModalActions.open(action.name);
        });
      }
    } else if (actionType === ModalConstants.MODAL_REGISTER) {
      this.registerModal(action.name, action.persistent);
    }
  }
}

const modalStore = new ModalStore();
modalStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;
  const source = payload.source;

  if (source === 'VIEW_ACTION') {
    modalStore.handleViewAction(action);
  }
});

export default modalStore;
