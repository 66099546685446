import { documents } from 'escrow-common-js/dist/constants';

export const getNationalDocuments = (country) =>
  documents.filter((doc) => doc.country === country && doc.enabled);

export const getCountryDocument = (country, document) =>
  documents.find((doc) => doc.country === country && doc.name === document);

export const countryHasDocuments = (country) => {
  const docs = getNationalDocuments(country);
  return docs.length > 0;
};
