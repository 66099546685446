import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';

import { customerSelector } from 'spa/selectors/CustomerSelectors';
import SideNavBar from 'spa/components/PartnerDashboard/navigation/SideNavBar';
import UserActions from '../../../actions/UserActions';
import AuthenticationStore from '../../../stores/AuthenticationStore';

class PartnerDashboard extends Component {
  componentDidMount() {
    const { partner } = this.props;
    if (!partner.custId) {
      UserActions.getCustomerData(AuthenticationStore.getCustomerID());
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { route, location } = this.props;

    return (
      <div className="partnerDashboard-container">
        <SideNavBar currentLocation={location.pathname} />
        <main className="partnerDashboard-content">{renderRoutes(route.routes)}</main>
      </div>
    );
  }
}

PartnerDashboard.propTypes = {
  location: PropTypes.object,
  route: PropTypes.object,
};

const mapStateToProps = (state) => ({
  partner: customerSelector(state),
});

const PartnerDashboardContainer = withRouter(connect(mapStateToProps)(PartnerDashboard));

export default PartnerDashboardContainer;
