import { createRoutine } from 'redux-saga-routines';

import DisbursementConstants from 'spa/constants/DisbursementConstants';

export const addDisbursementDetails = (formData, formName) => ({
  type: DisbursementConstants.DISBURSEMENT_FORM_SUBMIT,
  formData,
  formName,
});

export const getDisbursementDetailsRequest = () => ({
  type: DisbursementConstants.GET_DISBURSEMENT_DETAILS_REQUEST,
});

export const getDisbursementDetailsSuccess = (disbursementDetails) => ({
  type: DisbursementConstants.GET_DISBURSEMENT_DETAILS_SUCCESS,
  disbursementDetails,
});

export const getDisbursementDetailsFailure = (message) => ({
  type: DisbursementConstants.GET_DISBURSEMENT_DETAILS_FAILURE,
  message,
});

export const getCustomerInformation = (custData) => ({
  type: DisbursementConstants.GET_CUST_INFO,
  custData,
});

export const addDomesticWire = createRoutine(DisbursementConstants.DOMESTIC_WIRE_FORM_NAME);
export const addInternationalWire = createRoutine(
  DisbursementConstants.INTERNATIONAL_WIRE_FORM_NAME
);
export const addEuroWire = createRoutine(DisbursementConstants.INTERNATIONAL_WIRE_EURO_FORM_NAME);
export const addAch = createRoutine(DisbursementConstants.ACH_FORM_NAME);

export const getUserFlow = createRoutine(DisbursementConstants.GET_USER_FLOW);

export default {
  addDisbursementDetails,
  getDisbursementDetailsRequest,
  getDisbursementDetailsSuccess,
  getDisbursementDetailsFailure,
  getCustomerInformation,
  addDomesticWire,
  addInternationalWire,
  addEuroWire,
  addAch,
  getUserFlow,
};
