import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { ActionButton } from 'spa/components/form';
import Modal from 'spa/components/Modal';
import { FormError } from 'spa/components/form';
import { offerActionPropTypes, offerDataPropTypes } from 'spa/components/Offer/models';
import {
  acceptRequestFromManagementSelector,
  payTransactionRequestSelector,
  rejectRequestSelector,
  cancelRequestSelector,
  auctionsByIdSelector,
} from 'spa/selectors/OfferSelectors';
import { custEmailSelector } from 'spa/selectors/CustomerSelectors';
import { toggleModalAction } from 'spa/actions/ModalActions';
import { getCurrencyTagFromApiCurrency, getCurrencyString } from 'escrow-common-js/dist/utils';
import { gettext } from '../../../utils/filters';

const createRejectModalName = (offerId) => `offerRejectModal-${offerId}`;

const createCancelModalName = (offerId) => `offerCancelModal-${offerId}`;

const createCounterOfferText = (isOfferOwner) =>
  isOfferOwner ? gettext('Make another offer') : gettext('Make counteroffer');

const OfferControlPanel = ({
  auctionId,
  offer,
  actions,
  counterOffer,
  offerOwner,
  requests,
  toggleModal,
  auctionCurrency,
  custEmail,
}) => {
  const payAction = (actions || {}).pay;
  const acceptAction = (actions || {}).accept;
  const rejectAction = (actions || {}).reject;
  const cancelAction = (actions || {}).cancel;
  const toggleCounterOffer = (actions || {}).toggleCounterOffer;
  const expiryMs = offer.dateCreated.getTime() + offer.expiryPeriod * 1000 - Date.now();
  const expired = expiryMs < 0;
  const closed = Boolean(offer.dateClosed);
  let role = offer.byParty.role;

  if (custEmail !== offerOwner) {
    role = role === 'buyer' ? 'seller' : 'buyer';
  }

  const showAcceptReject = !(expired || counterOffer || closed);
  const showCancel = !(expired || !counterOffer || closed);
  const showView = !expired && (offer.status || {}).accepted && role === 'seller';
  const showPay = !expired && (offer.status || {}).accepted && role === 'buyer';

  const disableButtons =
    requests.accept.loading || requests.reject.loading || requests.cancel.loading;
  const errorMessage =
    requests.accept.errorMessage ||
    requests.reject.errorMessage ||
    requests.cancel.errorMessage ||
    requests.pay.errorMessage;
  const rejectModalName = createRejectModalName(offer.id);
  const cancelModalName = createCancelModalName(offer.id);

  const isOfferOwner = custEmail === offerOwner;
  const { transactionId } = offer;

  return (
    <div className="offer-actionList">
      {errorMessage && <FormError error={errorMessage} />}
      {showAcceptReject && acceptAction && (
        <div className="offer-actionList-item">
          <ActionButton
            className="offer-actionList-btn btn btn--secondary btn--block"
            label={gettext('Accept offer')}
            actionList={[acceptAction, toggleCounterOffer]}
            actionArgsList={[[auctionId, offer.id], [{ showCounterOfferForm: false }]]}
            loading={requests.accept.loading}
            disabled={disableButtons}
            data-tracking-name="accept-offer"
            data-tracking-value={offer.id}
          />
        </div>
      )}
      {!expired && toggleCounterOffer && (
        <div className="offer-actionList-item">
          <ActionButton
            className="offer-actionList-btn btn btn--secondary btn--block btn--hollow"
            label={createCounterOfferText(isOfferOwner)}
            action={toggleCounterOffer}
            disabled={disableButtons}
            data-tracking-name="make-offer"
            data-tracking-value={offer.id}
          />
        </div>
      )}
      {showAcceptReject && rejectAction && (
        <div>
          <div className="offer-actionList-item">
            <ActionButton
              className="offer-actionList-btn btn btn--warning btn--block btn--hollow"
              label={gettext('Decline offer')}
              action={toggleModal}
              actionArgs={[rejectModalName]}
              loading={requests.reject.loading}
              disabled={disableButtons}
              data-tracking-name="decline-offer-open-modal"
              data-tracking-value={offer.id}
            />
          </div>
          <Modal
            name={createRejectModalName(offer.id)}
            modifier="contentCenter"
            title={gettext(
              'Decline offer of %s by %s',
              getCurrencyString(offer.noFeeAmount, auctionCurrency),
              offer.byParty.displayName || offerOwner
            )}
            description={gettext(
              `Declining this offer will prevent both you and the other party from
             making future offers to each other on this item.
             Please counter the offer if you are unhappy with the offered amount.`
            )}
          >
            <ActionButton
              className="offer-actionList-btn btn btn--warning btn"
              label={gettext('Decline offer')}
              actionList={[rejectAction, toggleCounterOffer]}
              actionArgsList={[
                [auctionId, offer.id, offer.transactionId],
                [{ showCounterOfferForm: false }],
              ]}
              loading={requests.reject.loading}
              disabled={disableButtons}
              data-tracking-name="decline-offer"
              data-tracking-value={offer.id}
            />
          </Modal>
        </div>
      )}
      {showCancel && cancelAction && (
        <div>
          <div className="offer-actionList-item">
            <ActionButton
              className="offer-actionList-btn btn btn--warning btn--block btn--hollow"
              label={gettext('Cancel offer')}
              action={toggleModal}
              actionArgs={[cancelModalName]}
              loading={requests.cancel.loading}
              disabled={disableButtons}
              data-tracking-name="cancel-offer-open-modal"
              data-tracking-value={offer.id}
            />
          </div>
          <Modal
            name={createCancelModalName(offer.id)}
            modifier="contentCenter"
            title={gettext(
              'Cancel offer of %s',
              getCurrencyString(offer.noFeeAmount, auctionCurrency)
            )}
            description={gettext(
              `Cancelling this offer will prevent both you and the other party from
             making future offers to each other on this item.
             Please make another offer if you are unhappy with the offered amount.`
            )}
          >
            <ActionButton
              className="offer-actionList-btn btn btn--warning btn"
              label={gettext('Cancel offer')}
              actionList={[cancelAction, toggleCounterOffer]}
              actionArgsList={[
                [auctionId, offer.id, offer.transactionId],
                [{ showCounterOfferForm: false }],
              ]}
              loading={requests.cancel.loading}
              disabled={disableButtons}
              data-tracking-name="cancel-offer"
              data-tracking-value={offer.id}
            />
          </Modal>
        </div>
      )}
      {showPay && payAction && (
        <div className="offer-actionList-item">
          <ActionButton
            className="offer-actionList-btn btn btn--secondary btn--block"
            label={gettext('Pay now')}
            action={payAction}
            actionArgs={[offer.transactionId]}
            loading={requests.pay.loading}
            disabled={requests.pay.loading || requests.pay.error}
            data-tracking-name="pay-offer-transaction"
            data-tracking-value={offer.id}
          />
        </div>
      )}
      {showView && transactionId && (
        <div className="offer-actionList-item">
          <a
            className="offer-actionList-btn btn btn--secondary btn--block"
            href={`${window.config.www_base_url}/transaction/${transactionId}`}
            data-tracking-name="view-offer-transaction"
            data-tracking-value={offer.id}
          >
            {gettext('View transaction')}
          </a>
        </div>
      )}
    </div>
  );
};

OfferControlPanel.propTypes = {
  auctionId: PropTypes.number,
  offer: offerDataPropTypes,
  actions: offerActionPropTypes,
};

const mapStateToProps = (state, ownProps) => {
  const auctionBody = auctionsByIdSelector(state)[ownProps.auctionId];
  return {
    auctionCurrency: getCurrencyTagFromApiCurrency(auctionBody.currency),
    requests: {
      accept: acceptRequestFromManagementSelector(state, ownProps.offer.id),
      reject: rejectRequestSelector(state, ownProps.offer.id),
      cancel: cancelRequestSelector(state, ownProps.offer.id),
      pay: payTransactionRequestSelector(state, ownProps.offer.transactionId),
    },
    custEmail: custEmailSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: (modalName) => dispatch(toggleModalAction(modalName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OfferControlPanel);
