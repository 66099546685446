import React from 'react';

import AuthenticationContainer from 'spa/containers/Authentication';
import { LandingPageTokenHeader } from 'spa/components/LandingPageToken';
import AuthenticationConstants from 'spa/constants/AuthenticationConstants';

const IntegrationsLandingContainer = (props) => (
  <div className="checkout-card">
    <div className="checkout-card-body--light">
      <LandingPageTokenHeader landingPageToken={props.token} />
      <AuthenticationContainer
        onSuccess={() => window.location.assign(props.returnUrl)}
        lockEmailAddress
        loginPage={AuthenticationConstants.INTEGRATIONS_LANDING_LOGIN_PAGE}
      />
    </div>
  </div>
);

export default IntegrationsLandingContainer;
