import React from 'react';
import window from 'window-shim';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg';
import queryString from 'query-string';
import { head } from 'lodash';

import A from 'spa/components/A';
import {
  auctionIdSelector,
  auctionsByIdSelector,
  auctionTokenSelector,
  auctionSourceSelector,
  offersByIdSelector,
} from 'spa/selectors/OfferSelectors';
import { urlFor } from '../../../routeConfig';
import { gettext } from '../../../utils/filters';

const MakeAnOfferSuccessPanel = (props) => (
  <div className="checkout-confirmation" data-tracking-subsection="make-an-offer-success-section">
    <div className="checkout-confirmation-inner">
      <div className="checkout-confirmation-icon">
        <ReactSVG src={`../../../../build/images/global/confirmation-success-icon.svg`} />
      </div>

      <h3 className="checkout-confirmation-heading checkout-heading">
        {gettext('Your offer has been submitted')}.
      </h3>
      <br />

      <A
        className="checkout-card-btn checkout-card-btn--contained btn btn--secondary"
        name="offerManagementLink"
        link={{
          type: 'app',
          route: `${urlFor('offer_management')}?${queryString.stringify({
            token: props.auctionToken,
            source: props.auctionSource || window.js_context.source,
            email: props.buyerEmail,
          })}`,
        }}
        data-tracking-name="manage-your-offer"
      >
        {gettext('Manage your Offer')}
      </A>

      {props.auction && props.auction.return_url ? (
        <div>
          <A
            className="checkout-confirmation-desc checkout-desc"
            link={{
              route: props.auction.return_url,
              newTab: true,
            }}
            data-tracking-name="return-to-merchant"
            data-tracking-value={props.auction.return_url}
          >
            {gettext('Return to')} {props.auction.return_url}
          </A>
        </div>
      ) : null}
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  auction: auctionsByIdSelector(state)[auctionIdSelector(state)],
  auctionToken: auctionTokenSelector(state),
  auctionSource: auctionSourceSelector(state),
  buyerEmail: head(Object.values(offersByIdSelector(state))).byParty.customer,
});

export default connect(mapStateToProps)(MakeAnOfferSuccessPanel);
