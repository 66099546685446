import React, { useState } from 'react';
import CreateIcon from '@mui/icons-material/Create';
import TransactionConstants from 'spa/constants/TransactionConstants';
import { useWindowDimensions } from 'spa/hooks';

const EditableBox = ({ children, disabled, onClick, label }) => {
  const { width } = useWindowDimensions();
  const [showEditBackdrop, setShowEditBackdrop] = useState(false);
  return (
    <div
      className="materialUI-box-border materialUI-box-container"
      data-e2e-name="item-edit-box"
      data-e2e-label={label}
      onMouseEnter={() =>
        width >= TransactionConstants.DESKTOP_VIEW_WIDTH && !disabled && setShowEditBackdrop(true)
      }
      onMouseLeave={() =>
        width >= TransactionConstants.DESKTOP_VIEW_WIDTH && !disabled && setShowEditBackdrop(false)
      }
    >
      <div className="materialUI-border">
        {showEditBackdrop && width >= TransactionConstants.DESKTOP_VIEW_WIDTH && (
          <div
            className="materialUI-box-edit-backdrop"
            role="button"
            tabIndex={0}
            onClick={() => !disabled && onClick()}
          >
            <CreateIcon sx={{ fontSize: 40 }} color="white" />
            <div className="materialUI-box-edit-text">Edit item</div>
          </div>
        )}
        {children}
        {width < TransactionConstants.DESKTOP_VIEW_WIDTH && (
          <a
            className="materialUI-box-edit-text"
            aria-disabled={disabled}
            role="button"
            tabIndex={0}
            onClick={() => !disabled && onClick()}
          >
            Update item
          </a>
        )}
      </div>
    </div>
  );
};

export default EditableBox;
