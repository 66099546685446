import React, { useState } from 'react';
import { capitalize } from 'lodash';
import { Field, useForm } from 'react-final-form';
import { useWindowDimensions } from 'spa/hooks';
import TransactionConstants from 'spa/constants/TransactionConstants';
import { Heading } from 'spa/components/CustomerVerification/V2';
import { FormControlLabel, Radio } from '@mui/material';
import VerificationConstants from 'spa/constants/VerificationConstants';
import { ConfirmationModal } from 'spa/components/StartTransaction/Fields';

const AccountTypeSwitcher = ({ onConfirm, noModal, isSubmitting, disabled }) => {
  const [open, setOpen] = useState(false);
  const form = useForm();
  const { value: selectedAccountType } = form.getFieldState('account-type') || {};
  const { width } = useWindowDimensions();
  const mobile = width < TransactionConstants.DESKTOP_VIEW_WIDTH;

  const handleClose = () => {
    if (isSubmitting) return;
    setOpen(false);
    if (selectedAccountType === VerificationConstants.ACCOUNT_TYPE_COMPANY) {
      form.change('account-type', VerificationConstants.ACCOUNT_TYPE_INDIVIDUAL);
    } else if (selectedAccountType === VerificationConstants.ACCOUNT_TYPE_INDIVIDUAL) {
      form.change('account-type', VerificationConstants.ACCOUNT_TYPE_COMPANY);
    }
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm(selectedAccountType, handleClose);
    } else {
      handleClose();
    }
  };

  const handleCheck = (event) => {
    const targetValue = event.target.value;

    form.change('account-type', targetValue);

    if (noModal) {
      if (onConfirm) {
        onConfirm(selectedAccountType);
      }
    } else {
      setOpen(true);
    }
  };

  return (
    <React.Fragment>
      <ConfirmationModal
        title={`Switch account type to ${capitalize(selectedAccountType)}?`}
        text={
          <React.Fragment>
            You are about to switch your account type to{' '}
            <strong>{capitalize(selectedAccountType)}</strong>.
            <br />
            Please confirm if you wish to proceed.
          </React.Fragment>
        }
        isOpen={open}
        onClose={handleClose}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        fullWidth={mobile}
        isSubmitting={isSubmitting}
      />
      <Heading text="Account type" hasDivider />
      <div className="kyc-inline-fields-container">
        <div className="kyc-inline-field--half">
          <div className="kyc-account-type">
            <div className="materialUI-border kyc-account-type-box">
              <div className="kyc-account-type-box-content">
                <Field
                  name="account-type"
                  type="radio"
                  value={VerificationConstants.ACCOUNT_TYPE_INDIVIDUAL}
                >
                  {({ input }) => (
                    <FormControlLabel
                      control={<Radio {...input} onChange={handleCheck} disabled={disabled} />}
                      label={
                        <div className="kyc-account-type-box-content-label">
                          <div className="kyc-account-type-box-content-title">Individual</div>
                          <div className="kyc-account-type-box-content-text">
                            Buy or sell under your own name.
                          </div>
                        </div>
                      }
                    />
                  )}
                </Field>
              </div>
            </div>
          </div>
        </div>
        <div className="kyc-inline-field--half">
          <div className="kyc-account-type">
            <div className="materialUI-border kyc-account-type-box">
              <div className="kyc-account-type-box-content">
                <Field
                  name="account-type"
                  type="radio"
                  value={VerificationConstants.ACCOUNT_TYPE_COMPANY}
                >
                  {({ input }) => (
                    <FormControlLabel
                      control={<Radio {...input} onChange={handleCheck} disabled={disabled} />}
                      label={
                        <div className="kyc-account-type-box-content-label">
                          <div className="kyc-account-type-box-content-title">Company</div>
                          <div className="kyc-account-type-box-content-text">
                            {"Buy or sell under your business' name."}
                          </div>
                        </div>
                      }
                    />
                  )}
                </Field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AccountTypeSwitcher;
