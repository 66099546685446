import React from 'react';

import { EscrowLinkButton } from 'spa/features/ui/EscrowButton';

export const CreditCardInstructions = ({ paymentLink }) => {
  return paymentLink && (
    <React.Fragment>
      <EscrowLinkButton
        variant="outlined"
        to={paymentLink}
        hard
        newTab
      >
        Complete payment
      </EscrowLinkButton>
    </React.Fragment>
  );
};
