import OfferConstants from 'spa/constants/OfferConstants';
import {
  acceptOffer as acceptOfferAction,
  acceptOfferFromManagement,
  rejectOffer as rejectOfferAction,
  cancelOffer as cancelOfferAction,
  getAuction as getAuctionAction,
  getAuctionOffers as getAuctionOffersAction,
  cancelAuction as cancelAuctionAction,
  submitOffer as submitOfferAction,
  submitCounterOffer as submitCounterOfferAction,
  buyItNow as buyItNowAction,
  payOfferTransaction as payOfferTransactionAction,
} from 'spa/actions/OfferActions';

const initialState = {
  view: null,
  activeTransId: null,
  auctionId: null,
  auctionToken: null,
  auctionSource: null,
  offersById: null,
  offerIdsByAuctionId: null,
  auctionsById: null,
  ui: {
    getAuction: {
      error: false,
      loading: false,
      errorMessage: null,
    },
    cancelAuction: {
      error: false,
      loading: false,
      errorMessage: null,
    },
    offerAction: {
      error: false,
      loading: false,
      errorMessage: null,
    },
  },
  request: {
    getAuctionOffersByAuctionId: {},
    acceptOfferById: {},
    rejectOfferById: {},
    cancelOfferById: {},
    buyItNowByAuctionId: {},
    submitOfferByAuctionId: {},
    payTransactionByTransactionId: {},
  },
};

const apiOfferToReduxOffer = (offer) => ({
  byParty: {
    customer: offer.by_party.customer,
    displayName: offer.by_party.display_name,
    id: offer.by_party.id,
    role: offer.by_party.role,
  },
  dateCreated: new Date(offer.date_created),
  dateClosed: offer.date_closed ? new Date(offer.date_closed) : null,
  expiryPeriod: offer.expiry_period,
  id: offer.id,
  noFeeAmount: offer.no_fee_amount,
  note: offer.note,
  status: {
    accepted: offer.status.accepted,
    canceled: offer.status.canceled,
    rejected: offer.status.rejected,
  },
  transactionId: offer.transaction_id,
  offerTransaction: offer.offerTransaction,
});

export default function OfferReducer(state = initialState, action) {
  switch (action.type) {
    case OfferConstants.CHANGE_VIEW:
      return {
        ...state,
        view: action.payload,
      };
    case OfferConstants.PUSH_AUCTION_ID:
      return {
        ...state,
        auctionId: action.payload,
      };
    case OfferConstants.PUSH_AUCTION_TOKEN:
      return {
        ...state,
        auctionToken: action.payload,
      };
    case OfferConstants.PUSH_AUCTION_SOURCE:
      return {
        ...state,
        auctionSource: action.payload,
      };
    case OfferConstants.SET_TRANS_ID:
      return {
        ...state,
        activeTransId: action.payload,
      };
    case getAuctionAction.REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          getAuction: {
            error: false,
            loading: true,
            errorMessage: null,
          },
        },
      };
    case getAuctionAction.SUCCESS:
      return {
        ...state,
        auctionsById: {
          ...(state.auctionById || {}),
          [action.payload.id]: action.payload,
        },
        ui: {
          ...state.ui,
          getAuction: {
            error: false,
            loading: false,
            errorMessage: null,
          },
        },
      };
    case getAuctionAction.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          getAuction: {
            error: true,
            loading: false,
            errorMessage: action.payload,
          },
        },
      };
    case getAuctionOffersAction.REQUEST:
      return {
        ...state,
        request: {
          ...state.request,
          getAuctionOffersByAuctionId: {
            ...state.request.getAuctionOffersByAuctionId,
            [action.payload.auctionId]: {
              error: false,
              loading: true,
              errorMessage: null,
            },
          },
        },
      };
    case getAuctionOffersAction.SUCCESS: {
      const offersById = action.payload.offers.reduce((result, offer) => {
        result[offer.id] = apiOfferToReduxOffer(offer);
        return result;
      }, {});
      const offerIdsForAuction = action.payload.offers.map((offer) => offer.id);
      return {
        ...state,
        offerIdsByAuctionId: {
          ...(state.offerIdsByAuctionId || {}),
          [action.payload.auctionId]: offerIdsForAuction,
        },
        offersById: {
          ...(state.offersById || {}),
          ...offersById,
        },
        request: {
          ...state.request,
          getAuctionOffersByAuctionId: {
            ...state.request.getAuctionOffersByAuctionId,
            [action.payload.auctionId]: {
              error: false,
              loading: false,
              errorMessage: null,
            },
          },
        },
      };
    }
    case getAuctionOffersAction.FAILURE:
      return {
        ...state,
        request: {
          ...state.request,
          getAuctionOffersByAuctionId: {
            ...state.request.getAuctionOffersByAuctionId,
            [action.payload.auctionId]: {
              error: true,
              loading: false,
              errorMessage: action.payload.errorMessage,
            },
          },
        },
      };
    case cancelAuctionAction.REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          cancelAuction: {
            error: false,
            loading: true,
            errorMessage: null,
          },
        },
      };
    case cancelAuctionAction.SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          cancelAuction: {
            error: false,
            loading: false,
            errorMessage: null,
          },
        },
      };
    case cancelAuctionAction.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          cancelAuction: {
            error: true,
            loading: false,
            errorMessage: action.payload,
          },
        },
      };
    case acceptOfferAction.REQUEST:
      return {
        ...state,
        request: {
          ...state.request,
          acceptOfferById: {
            ...state.request.acceptOfferById,
            [action.payload.offerId]: {
              error: false,
              loading: true,
              errorMessage: null,
            },
          },
        },
      };
    case acceptOfferAction.SUCCESS:
      return {
        ...state,
        offersById: {
          ...state.offersById,
          [action.payload.offer.id]: apiOfferToReduxOffer(action.payload.offer),
        },
        request: {
          ...state.request,
          acceptOfferById: {
            ...state.request.acceptOfferById,
            [action.payload.offer.id]: {
              error: false,
              loading: false,
              errorMessage: null,
            },
          },
        },
      };
    case acceptOfferAction.FAILURE:
      return {
        ...state,
        request: {
          ...state.request,
          acceptOfferById: {
            ...state.request.acceptOfferById,
            [action.payload.offerId]: {
              error: true,
              loading: false,
              errorMessage: action.payload.errorMessage,
            },
          },
        },
      };
    case acceptOfferFromManagement.REQUEST:
      return {
        ...state,
        request: {
          ...state.request,
          acceptOfferFromManagement: {
            ...state.request.acceptOfferFromManagement,
            [action.payload.offerId]: {
              error: false,
              loading: true,
              errorMessage: null,
            },
          },
        },
      };
    case acceptOfferFromManagement.SUCCESS:
      return {
        ...state,
        offersById: {
          ...state.offersById,
          [action.payload.offer.id]: apiOfferToReduxOffer(action.payload.offer),
        },
        request: {
          ...state.request,
          acceptOfferFromManagement: {
            ...state.request.acceptOfferFromManagement,
            [action.payload.offer.id]: {
              error: false,
              loading: false,
              errorMessage: null,
            },
          },
        },
      };
    case acceptOfferFromManagement.FAILURE:
      return {
        ...state,
        request: {
          ...state.request,
          acceptOfferFromManagement: {
            ...state.request.acceptOfferFromManagement,
            [action.payload.offerId]: {
              error: true,
              loading: false,
              errorMessage: action.payload.errorMessage,
            },
          },
        },
      };
    case rejectOfferAction.REQUEST:
      return {
        ...state,
        request: {
          ...state.request,
          rejectOfferById: {
            ...state.request.rejectOfferById,
            [action.payload.offerId]: {
              error: false,
              loading: true,
              errorMessage: null,
            },
          },
        },
      };
    case rejectOfferAction.SUCCESS:
      return {
        ...state,
        offersById: {
          ...state.offersById,
          [action.payload.offer.id]: apiOfferToReduxOffer(action.payload.offer),
        },
        request: {
          ...state.request,
          rejectOfferById: {
            ...state.request.rejectOfferById,
            [action.payload.offer.id]: {
              error: false,
              loading: false,
              errorMessage: null,
            },
          },
        },
      };
    case rejectOfferAction.FAILURE:
      return {
        ...state,
        request: {
          ...state.request,
          rejectOfferById: {
            ...state.request.rejectOfferById,
            [action.payload.offerId]: {
              error: true,
              loading: false,
              errorMessage: action.payload.errorMessage,
            },
          },
        },
      };
    case cancelOfferAction.REQUEST:
      return {
        ...state,
        request: {
          ...state.request,
          cancelOfferById: {
            ...state.request.cancelOfferById,
            [action.payload.offerId]: {
              error: false,
              loading: true,
              errorMessage: null,
            },
          },
        },
      };
    case cancelOfferAction.SUCCESS:
      return {
        ...state,
        offersById: {
          ...state.offersById,
          [action.payload.offer.id]: apiOfferToReduxOffer(action.payload.offer),
        },
        request: {
          ...state.request,
          cancelOfferById: {
            ...state.request.cancelOfferById,
            [action.payload.offer.id]: {
              error: false,
              loading: false,
              errorMessage: null,
            },
          },
        },
      };
    case cancelOfferAction.FAILURE:
      return {
        ...state,
        request: {
          ...state.request,
          cancelOfferById: {
            ...state.request.cancelOfferById,
            [action.payload.offerId]: {
              error: true,
              loading: false,
              errorMessage: action.payload.errorMessage,
            },
          },
        },
      };
    case buyItNowAction.REQUEST:
      return {
        ...state,
        request: {
          ...state.request,
          buyItNowByAuctionId: {
            ...state.request.buyItNowByAuctionId,
            [action.payload.auctionId]: {
              error: false,
              loading: true,
              errorMessage: null,
            },
          },
        },
      };
    case buyItNowAction.SUCCESS:
      return {
        ...state,
        auctionsById: {
          ...(state.auctionsById || {}),
          [action.payload.auctionId]: {
            ...((state.auctionsById || {})[action.payload.auctionId] || {}),
            buyItNowUrl: action.payload.buyItNowUrl,
            buyItNowToken: action.payload.buyItNowToken,
          },
        },
        request: {
          ...state.request,
          buyItNowByAuctionId: {
            ...state.request.buyItNowByAuctionId,
            [action.payload.auctionId]: {
              error: false,
              loading: false,
              errorMessage: null,
            },
          },
        },
      };
    case buyItNowAction.FAILURE:
      return {
        ...state,
        request: {
          ...state.request,
          buyItNowByAuctionId: {
            ...state.request.buyItNowByAuctionId,
            [action.payload.auctionId]: {
              error: true,
              loading: false,
              errorMessage: action.payload.errorMessage,
            },
          },
        },
      };
    case payOfferTransactionAction.REQUEST:
      return {
        ...state,
        request: {
          ...state.request,
          payTransactionByTransactionId: {
            ...state.request.payTransactionByTransactionId,
            [action.payload.transactionId]: {
              error: false,
              loading: true,
              errorMessage: null,
            },
          },
        },
      };
    case payOfferTransactionAction.SUCCESS:
      return {
        ...state,
        request: {
          ...state.request,
          payTransactionByTransactionId: {
            ...state.request.payTransactionByTransactionId,
            [action.payload.transactionId]: {
              error: false,
              loading: false,
              errorMessage: null,
            },
          },
        },
      };
    case payOfferTransactionAction.FAILURE:
      return {
        ...state,
        request: {
          ...state.request,
          payTransactionByTransactionId: {
            ...state.request.payTransactionByTransactionId,
            [action.payload.transactionId]: {
              error: true,
              loading: false,
              errorMessage: action.payload.errorMessage,
            },
          },
        },
      };
    case submitOfferAction.REQUEST:
    case submitCounterOfferAction.REQUEST:
      return {
        ...state,
        request: {
          ...state.request,
          submitOfferByAuctionId: {
            ...state.request.submitOfferByAuctionId,
            [action.payload.auctionId]: {
              error: false,
              loading: true,
              errorMessage: null,
            },
          },
        },
      };
    case submitOfferAction.SUCCESS:
    case submitCounterOfferAction.SUCCESS:
      return {
        ...state,
        offersById: {
          ...state.offersById,
          [action.payload.offer.id]: apiOfferToReduxOffer(action.payload.offer),
        },
        request: {
          ...state.request,
          submitOfferByAuctionId: {
            ...state.request.submitOfferByAuctionId,
            [action.payload.auctionId]: {
              error: false,
              loading: false,
              errorMessage: null,
            },
          },
        },
      };
    case submitOfferAction.FAILURE:
    case submitCounterOfferAction.FAILURE:
      return {
        ...state,
        request: {
          ...state.request,
          submitOfferByAuctionId: {
            ...state.request.submitOfferByAuctionId,
            [action.payload.auctionId]: {
              error: true,
              loading: false,
              errorMessage: action.payload.errorMessage,
            },
          },
        },
      };
    default:
      return state;
  }
}
