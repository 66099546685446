import React, { Component } from 'react';
import QueryString from 'query-string';
import Breadcrumbs from 'spa/components/Breadcrumbs';
import PaymentVerificationInstructions from 'spa/containers/PaymentVerification/Instructions';

import { gettext } from '../../../utils/filters';

const breadcrumbs = [
  {
    text: gettext('Your Account'),
    link: '/account-info',
  },
  {
    text: gettext('Verify Payment'),
  },
];

class PaymentVerificationInstructionsPage extends Component {
  constructor() {
    super();
    this.state = {
      cardId: null,
      transId: null,
    };
  }
  componentDidMount() {
    this.getQueryString();
  }

  getQueryString() {
    const cardId = QueryString.parse(window.location.search)['card-id'];
    const transId = QueryString.parse(window.location.search)['trans-id'];
    this.setState({ cardId, transId });
  }

  render() {
    return (
      <section
        className="section section--small section--mid"
        data-e2e-target={`additional-documents-form-container`}
      >
        <div className="section-container">
          <div className="account-inner">
            <Breadcrumbs className="accountInfo-breadcrumbs" items={breadcrumbs} />
            {this.state.cardId && this.state.transId ? (
              <PaymentVerificationInstructions
                cardId={this.state.cardId}
                transId={this.state.transId}
              />
            ) : null}
          </div>
        </div>
      </section>
    );
  }
}

export default PaymentVerificationInstructionsPage;
