import React from 'react';
import { AlertTitle, Alert } from '@mui/material';

const ErrorAlert = ({ title, message, type }) => (
  <Alert severity={type || 'error'}>
    {title && <AlertTitle>{title}</AlertTitle>}
    {message}
  </Alert>
);

export default ErrorAlert;
