import React from 'react';
import { connect } from 'react-redux';
import { bindRoutineToReduxForm } from 'redux-saga-routines';

import DisbursementConstants from 'spa/constants/DisbursementConstants';
import InternationalWireForm from 'spa/components/DisbursementDetails/InternationalWireForm';
import { addInternationalWire } from 'spa/actions/DisbursementActions';
import { getTransactionById } from 'spa/actions/TransactionActions';
import { transactionByIdSelector } from 'spa/selectors/TransactionSelectors';
import {
  abaRoutingNumberSelector,
  accountNameSelector,
  additionalInformationSelector,
  brstnCodeSelector,
  ifscCodeSelector,
  financialInstitutionNumberSelector,
  transitNumberSelector,
  bankAbaRoutingSelector,
  bankAccountNumberSelector,
  bankAccountSuffixSelector,
  bankBranchSelector,
  bankBranchNumberSelector,
  bankCountrySelector,
  bankIbanSelector,
  bankNameSelector,
  bankNumberSelector,
  bankSortCodeSelector,
  bankSwiftCodeSelector,
  currencySelector,
  intermediaryBankSelector,
  editedDisbursementMethodSelector,
  validDisbursementNamesSelector,
  customerCountrySelector,
  loadingSelector,
  successSelector,
  availableCurrencySelector,
  userFlowSelector,
} from 'spa/selectors/DisbursementSelectors';
import {
  getDisbursementDetailsRequest,
  getCustomerInformation,
  getUserFlow as getUserFlowAction,
} from 'spa/actions/DisbursementActions';
import UserStore from '../../../stores/UserStore';
import AuthenticationStore from '../../../stores/AuthenticationStore';

class InternationalWireContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      v4GetTransactionDone: false,
    };
    UserStore.addChangeListener(this.onUserDetailsChange.bind(this));
    AuthenticationStore.addChangeListener(this.onUserDetailsChange.bind(this));
  }

  componentDidMount() {
    this.props.getDisbursementDetails();
    this.props.getUserFlow();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.initialValues.userFlow &&
      nextProps.initialValues.userFlow.type === 'transaction-create' &&
      !this.state.v4GetTransactionDone
    ) {
      this.setState({
        v4GetTransactionDone: true,
      });
      this.props.dispatchGetTransactionById(nextProps.initialValues.userFlow.transId);
    }
  }

  componentWillUnmount() {
    AuthenticationStore.removeChangeListener(this.onUserDetailsChange.bind(this));
    UserStore.removeChangeListener(this.onUserDetailsChange.bind(this));
  }

  onUserDetailsChange() {
    const custId = AuthenticationStore.getCustomerID();
    const custData = UserStore.getCustomerData(custId);
    this.props.getCustomerInformation(custData);
  }

  render() {
    return (
      <InternationalWireForm
        {...this.props}
        form={DisbursementConstants.INTERNATIONAL_WIRE_FORM_NAME}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const initialValues = {
    country: customerCountrySelector(state),
    accountName: validDisbursementNamesSelector(state)[0],
    intermediaryBank: {
      country: customerCountrySelector(state),
    },
    userFlow: userFlowSelector(state),
    currency: transactionByIdSelector(state, userFlowSelector(state).transId).currency,
  };
  const editedMethod = editedDisbursementMethodSelector(state);
  if (editedMethod) {
    const intermediaryBank = intermediaryBankSelector(editedMethod);
    initialValues.accountName = accountNameSelector(editedMethod);
    initialValues.accountNumber = bankAccountNumberSelector(editedMethod);
    initialValues.suffix = bankAccountSuffixSelector(editedMethod);
    initialValues.additionalInformation = additionalInformationSelector(editedMethod);
    initialValues.brstnCode = brstnCodeSelector(editedMethod);
    initialValues.ifscCode = ifscCodeSelector(editedMethod);
    initialValues.financialInstitutionNumber = financialInstitutionNumberSelector(editedMethod);
    initialValues.transitNumber = transitNumberSelector(editedMethod);
    initialValues.bankName = bankNameSelector(editedMethod);
    initialValues.bankNumber = bankNumberSelector(editedMethod);
    initialValues.branchNumber = bankBranchSelector(editedMethod);
    initialValues.bsb = bankBranchNumberSelector(editedMethod);
    initialValues.clabeNumber = bankAccountNumberSelector(editedMethod);
    initialValues.country = bankCountrySelector(editedMethod);
    initialValues.iban = bankIbanSelector(editedMethod);
    initialValues.sortCode = bankSortCodeSelector(editedMethod);
    initialValues.swiftOrBicCode = bankSwiftCodeSelector(editedMethod);
    initialValues.currency = currencySelector(editedMethod);
    initialValues.bankAbaRoutingNumber = abaRoutingNumberSelector(editedMethod);
    initialValues.intermediaryBankNeeded = Boolean(intermediaryBank);
    initialValues.intermediaryBank = {
      abaRoutingNumber: bankAbaRoutingSelector(intermediaryBank || {}),
      swiftCode: bankSwiftCodeSelector(intermediaryBank || {}),
      bankName: bankNameSelector(intermediaryBank || {}),
      country: bankCountrySelector(intermediaryBank || {}) || customerCountrySelector(state),
    };
  }
  return {
    validDisbursementNames: validDisbursementNamesSelector(state),
    availableCurrencies: availableCurrencySelector(state),
    transactionCurrency: transactionByIdSelector(state, userFlowSelector(state).transId).currency,
    display: {
      loading: loadingSelector(state, 'display'),
      success: successSelector(state, 'display'),
    },
    initialValues: initialValues,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDisbursementDetails: () => {
    dispatch(getDisbursementDetailsRequest());
  },
  getCustomerInformation: (custData) => {
    dispatch(getCustomerInformation(custData));
  },
  getUserFlow: () => {
    dispatch(getUserFlowAction());
  },
  dispatchGetTransactionById: (transId) => {
    dispatch(getTransactionById({ transId }));
  },
  onSubmit: bindRoutineToReduxForm(addInternationalWire),
});

export default connect(mapStateToProps, mapDispatchToProps)(InternationalWireContainer);
