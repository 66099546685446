import React, { Fragment } from 'react';
import ReactSVG from 'react-svg';

const StepContent = [
  {
    iconName: 'action_required',
    title: 'Tabs',
    description: (
      <Fragment>
        Transactions are now grouped depending on their status. The <span>Action Required</span> tab
        will include transactions that require any action from you.
      </Fragment>
    ),
  },
  {
    iconName: 'open',
    title: 'Tabs',
    description: (
      <Fragment>
        Your <span>Open</span> transactions are those that are still ongoing, including the ones
        that require your action.
      </Fragment>
    ),
  },
  {
    iconName: 'closed',
    title: 'Tabs',
    description: (
      <Fragment>
        Your <span>Closed</span> transactions are those that were completed or cancelled.
      </Fragment>
    ),
  },
  {
    iconName: 'filter',
    title: 'Filters',
    description: (
      <Fragment>
        In order to help you narrow down on your transactions, we have added a way to do filtering.
        You may choose to either filter by <span>Transaction Type, Price Range,</span> or your{' '}
        <span>Role</span> in the transaction.
      </Fragment>
    ),
  },
  {
    iconName: 'sort',
    title: 'Sort',
    description: (
      <Fragment>
        And one more thing! You may click on any of the column headers to <span>sort</span> your
        transactions by the information in that column.
      </Fragment>
    ),
  },
];

const WelcomeSlide = ({ skipTour, jumpToStep }) => (
  <div className="transactions-modal@mobile-container">
    <div className="transactions-modal@mobile-icon-container">
      <ReactSVG
        className="transactions-modal-fullscreen--svg"
        src="../build/images/global/icons/escrow-shield.svg"
        beforeInjection={(svg) => {
          svg.classList.add('transactions-modal-fullscreen--svg--size');
        }}
      />
    </div>
    <div className="transactions-modal@mobile-content">
      <div className="transactions-modal-fullscreen--title">Welcome to Escrow.com!</div>
      <div className="transactions-modal@mobile-content-description">
        Let’s walk you through your updated transactions page. This should take less than a minute.
      </div>
    </div>
    <div className="transactions-modal@mobile-actions transactions-modal@mobile-actions--short">
      <button
        className="btn btn--secondary transactions-modal@mobile-actions-button"
        onClick={() => {
          jumpToStep(1);
        }}
      >
        {"Let's get started"}
      </button>
      <a
        className="transactions-modal@mobile-actions-anchor"
        role="button"
        tabIndex={0}
        onClick={skipTour}
      >
        Skip
      </a>
    </div>
  </div>
);

const TourStepSlide = ({ activeIndex, jumpToStep, step }) => (
  <div className="transactions-modal@mobile-container">
    <div className="transactions-modal@mobile-icon-container" key={step}>
      <img
        alt={StepContent[step - 1].iconName}
        src={`../build/images/transactionTour/${StepContent[step - 1].iconName}_tab.png`}
      />
    </div>
    <div className="transactions-modal@mobile-content">
      <div className="transactions-modal-fullscreen--title">{StepContent[step - 1].title}</div>
      <div className="transactions-modal@mobile-content-description">
        {StepContent[step - 1].description}
      </div>
    </div>
    <div className="transactions-modal@mobile-actions">
      <ul className="transactions-modal@mobile-actions-steppers">
        {Array.from(Array(5).keys()).map((i) => (
          <li key={i} className="transactions-modal@mobile-actions-stepper">
            <button
              disabled={i + 1 === activeIndex}
              onClick={() => jumpToStep(i + 1)}
              className="transactions-modal@mobile-actions-stepper-button"
            />
          </li>
        ))}
      </ul>
      <button
        className="btn btn--secondary transactions-modal@mobile-actions-button"
        onClick={() => {
          jumpToStep(activeIndex + 1);
        }}
      >
        {step !== 5 ? 'Next' : 'Done'}
      </button>
    </div>
  </div>
);

export { WelcomeSlide, TourStepSlide };
