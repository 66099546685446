import _ from 'lodash';
import deepcopy from 'clone';
import document from 'document-shim';

import AppDispatcher from '../dispatcher/AppDispatcher';
import { ChangeEmitter } from '../utils/ChangeEmitter';
import StartTransactionConstants from '../constants/StartTransactionConstants';
import AuthenticationConstants from '../constants/AuthenticationConstants';
import CalculatorConstants from '../constants/Calculator';
import CalculatorStore from '../stores/Calculator';
import CategorySearchStore from '../stores/CategorySearchStore';
import AuthenticationStore from './AuthenticationStore';
import DomainInputStore from './DomainInputStore';
import MilestoneInputStore from './MilestoneInputStore';
import FormStore from './FormStore';
import getIsExistingUser from '../utils/isExistingUser';

export class StartTransactionStore extends ChangeEmitter {
  constructor() {
    super();
    this.handleViewAction = this.handleViewAction.bind(this);
    this.role = CalculatorStore.getData().role || CalculatorConstants.ROLE_SELLER;
    this.shippingPayer = StartTransactionConstants.SHIPPING_PAYER_BUYER;
    if (location.search.indexOf('category=vehicle') >= 0) {
      this.advanced = true;
    } else {
      this.advanced = false;
    }
    this.emailExists = {};
    this.returning = getIsExistingUser();
    this.formData = {};
    this.errorsMsgs = [];
    this.multiStepProgress = {
      buyerAuthenticated: false,
      sellerAuthenticated: false,
    };
  }

  validFields(formName) {
    const validFields = FormStore.validFields(formName);
    validFields['domain-add-name'] = DomainInputStore.getPendingDomain().validName;
    validFields.price = DomainInputStore.getPendingDomain().validPrice || validFields.price;
    return validFields;
  }

  invalidFieldCount(name) {
    const validFields = this.validFields(name);
    let invalidFieldCount = 0;
    for (const key of Object.keys(validFields)) {
      invalidFieldCount += validFields[key] === false;
    }
    return invalidFieldCount;
  }

  parseFormData(data, transactionType) {
    this.formData = {
      Partner: { PartnerId: this.getPID() },
      Buyer: {},
      Seller: {},
      LineItems: [],
      EscrowPayment: data['fee-payer'],
      InspectionLength: data['inspection-period'],
      Currency: data.currency,
      CommissionType: '1',
      TermsLocked: false,
      AllowReject: true,
      Source: StartTransactionConstants.WWW_SOURCE_TRACKING,
    };
    const form = this.formData;

    // Add in Domain specific fields
    if (transactionType === StartTransactionConstants.DOMAIN_TRANSACTION_TYPE) {
      form.Title = data['title-domain'];
      form.Description = data['title-domain'];
      form.TransactionType = StartTransactionConstants.DOMAIN_TRANSACTION_TYPE;
      form.Fulfillment = StartTransactionConstants.SHIPPING_METHOD_DOMAIN;
      form.ShipmentFee = '0';
      form.ShipmentPayment = '0';
      form.DomainNameType = data['domain-type'];
      form.Concierge = data['domain-concierge'];
      form.LineItems = [];

      const domains = DomainInputStore.getAllDomains();
      for (const domain of domains) {
        form.LineItems.push({
          ItemName: domain.url,
          Description: domain.url,
          Quantity: '1',
          Price: domain.price,
          Accept: true,
          SellComm: '0',
          BuyComm: '0',
        });
      }
    } else if (transactionType === StartTransactionConstants.VEHICLE_TRANSACTION_TYPE) {
      const carTitle = `${data.vehiclemakeyear} ${data.make} ${data.model}`;
      form.Description = carTitle;
      form.Title = carTitle;
      form.Fulfillment = data['shipment-method'];
      form.PartnerTransID = '0';
      form.ShipmentFee = data['shipment-fee'] || '0';
      form.ShipmentPayment = data['shipping-payer'];
      form.TransactionType = StartTransactionConstants.VEHICLE_TRANSACTION_TYPE;
      form.VehicleLineItem = {
        ItemName: carTitle,
        Model: data.model,
        Make: data.make,
        Year: data.vehiclemakeyear,
        VIN: data.vin,
        Odometer: data.odometer,
        OtherNotes: data.notes,
        ExpirationDate: data.regoexpiry,
        Description: carTitle,
        Quantity: '1',
        Price: data.price,
        Accept: true,
        SellComm: '0',
        BuyComm: '0',
        Category: CategorySearchStore.categoryValue,
        CustomCategory: CategorySearchStore.customCategoryValue,
      };

      // Title collection and lien payoff not available for Euro transactions
      if (data.currency !== StartTransactionConstants.CURRENCY_EUR) {
        if (data['additional-services'] === 'title-collection') {
          form.ServiceType = StartTransactionConstants.TITLE_COLLECTION_SERVICE;
        } else if (data['additional-services'] === 'lien-payoff') {
          form.ServiceType = StartTransactionConstants.LIEN_HOLDER_SERVICE;
        }
      }

      for (const val in this.formData.VehicleLineItem) {
        if (this.formData.VehicleLineItem[val] === undefined) {
          this.formData.VehicleLineItem[val] = '';
        }
      }
    } else if (transactionType === StartTransactionConstants.MILESTONE_TRANSACTION_TYPE) {
      form.Title = data['title-milestone'];
      form.Description = data['title-milestone'];
      form.ShipmentFee = MilestoneInputStore.getShippingTotal().toString();
      form.ShipmentPayment = data['shipping-payer'];
      form.Fulfillment = data['shipment-method'];
      form.TransactionType = StartTransactionConstants.MILESTONE_TRANSACTION_TYPE;

      const milestones = MilestoneInputStore.getAllMilestones();
      for (const milestone of milestones) {
        if (!_.isEmpty(milestone)) {
          form.LineItems.push({
            ItemName: milestone.title,
            Description: milestone.description || milestone.title,
            Quantity: '1',
            Price: milestone.unitPrice ? milestone.unitPrice.toString() : '0',
            Accept: true,
            SellComm: '0',
            BuyComm: '0',
            DeliveryMethod: data['shipment-method'],
            ShippingFee: milestone.shippingPrice ? milestone.shippingPrice.toString() : '0',
            InspectionPeriod: data['inspection-period'],
          });
        }
      }
    } else {
      // General transaction specific fields
      form.Title = data.title;
      form.Description = data.description;
      form.TransactionType = StartTransactionConstants.GENERAL_TRANSACTION_TYPE;
      form.Fulfillment = data['shipment-method'];
      form.ShipmentFee = data['shipment-fee'] || '0';
      form.ShipmentPayment = data['shipping-payer'];
      form.ShipmentFreightlancer = data['shipping-freightlancer'] === '1';
      form.LineItems = [
        {
          ItemName: data.title,
          Description: data.description,
          Quantity: '1',
          Price: data.price,
          Accept: true,
          SellComm: '0',
          BuyComm: '0',
          Category: CategorySearchStore.categoryValue,
          CustomCategory: CategorySearchStore.customCategoryValue,
        },
      ];
    }

    // Clear the shipment fee when shipping payer is seller
    if (
      data['shipping-payer'] === StartTransactionConstants.SHIPPING_PAYER_SELLER ||
      data['shipment-method'] === StartTransactionConstants.SHIPPING_METHOD_NONE
    ) {
      this.formData.ShipmentFee = '0';
    }

    const userInfo = {
      Email: AuthenticationStore.getEmail(),
      Initiator: true,
      CompanyChk: false,
      AutoAgree: false,
      AgreementChecked: false,
    };

    const otherInfo = {
      Email: data['email-address-other'],
      Initiator: false,
      CompanyChk: false,
      AutoAgree: false,
      AgreementChecked: false,
    };

    if (data['non-initiator-phone']) {
      otherInfo.CountryCode = data['non-initiator-phone-country'];
      const prefix = data['non-initiator-phone-country-prefix'];
      if (data['non-initiator-phone'].indexOf(prefix) === -1) {
        otherInfo.PhoneNumber = prefix + data['non-initiator-phone'].replace(/^0+/, '');
      } else {
        otherInfo.PhoneNumber = data['non-initiator-phone'];
      }
    }

    if (data['transaction-role'] === StartTransactionConstants.ROLE_SELLER) {
      this.formData.Buyer = otherInfo;
      this.formData.Seller = userInfo;
    } else {
      this.formData.Buyer = userInfo;
      this.formData.Seller = otherInfo;
    }

    // undefined fields must be blank strings
    for (const val in this.formData) {
      if (this.formData[val] === undefined) {
        this.formData[val] = '';
      }
    }
  }

  // Add username from AuthenticationStore - needed when user is already
  // logged in and their email address is not in the form
  getFormData() {
    const data = this.formData;
    if (data.Seller.Initiator === true) {
      data.Seller.Email = AuthenticationStore.getEmail();
    } else {
      data.Buyer.Email = AuthenticationStore.getEmail();
    }
    return data;
  }

  getRole() {
    const data = this.formData;
    if (data.Seller.Initiator === true) {
      return StartTransactionConstants.ROLE_SELLER;
    }
    return StartTransactionConstants.ROLE_BUYER;
  }

  getNonInitiator() {
    let user;
    const data = this.formData;
    if (data.Seller.Initiator) {
      user = deepcopy(data.Buyer);
      user.Role = StartTransactionConstants.ROLE_BUYER;
    } else {
      user = deepcopy(data.Seller);
      user.Role = StartTransactionConstants.ROLE_SELLER;
    }
    return user;
  }

  /*
   * Reads and validates the _idp_v11 cookie, default to pid = 0
   */
  getPID() {
    const pidCookie = document.cookie.match(/_idp_v11=([^;]+)/);
    if (pidCookie && pidCookie[1]) {
      const pid = pidCookie[1];
      // Ensure PID is non-negative integer
      if (Number.isInteger(Number(pid)) && pid >= 0) {
        return pid;
      }
    }
    return '0';
  }

  handleViewAction(action) {
    if (action.actionType === StartTransactionConstants.SELECT_ROLE) {
      this.role = action.role;
      this.emitChange();
    } else if (action.actionType === StartTransactionConstants.SELECT_RETURNING) {
      if (action.returning === 'register') {
        this.returning = false;
      } else if (action.returning === 'login') {
        this.returning = true;
      }
      this.emitChange();
    } else if (action.actionType === StartTransactionConstants.SELECT_SHIPPING) {
      this.shipmentMethod = action.value;
      this.emitChange();
    } else if (action.actionType === StartTransactionConstants.SELECT_SHIPPING_PAYER) {
      this.shippingPayer = action.value;
      this.emitChange();
    } else if (action.actionType === StartTransactionConstants.TOGGLE_ADVANCED) {
      this.advanced = !this.advanced;
      this.emitChange();
    } else if (action.actionType === StartTransactionConstants.UPDATE_FIELDS) {
      this.parseFormData(action.data, action.transactionType);
    }
  }

  handleServerAction(action) {
    if (action.actionType === AuthenticationConstants.EMAIL_EXISTS) {
      this.emailExists[action.email] = action.exists;
      this.emitChange();
    } else if (action.actionType === StartTransactionConstants.MULTI_STEP_PROGRESSION) {
      this.multiStepProgress[action.stepComplete] = true;
      this.emitChange();
    }
  }
}

const startTransactionStore = new StartTransactionStore();
startTransactionStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;
  const source = payload.source;

  if (source === 'VIEW_ACTION') {
    startTransactionStore.handleViewAction(action);
  } else if (source === 'SERVER_ACTION') {
    startTransactionStore.handleServerAction(action);
  }
});

export default startTransactionStore;
