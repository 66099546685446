import AppDispatcher from '../dispatcher/AppDispatcher';
import SearchConstants from '../constants/SearchConstants';
import { ChangeEmitter } from '../utils/ChangeEmitter';

export class SearchInputStore extends ChangeEmitter {
  constructor() {
    super();

    this.input = '';
    this.error = null;
    this.handleViewAction = this.handleViewAction.bind(this);
    this.searchSelection = '';
    this.searchHoverText = '';
    this.searchHoverIdx = -1;
    this.shouldUpdate = false;
  }

  getInput() {
    return this.input;
  }

  getError() {
    return this.error;
  }

  handleViewAction(action) {
    if (action.actionType === SearchConstants.SEARCH_INPUT) {
      this.input = action.value;
      this.searchSelection = '';
      this.searchHoverText = '';
      this.searchHoverIdx = -1;
      this.error = null;
      this.emitChange();
    } else if (action.actionType === SearchConstants.SEARCH_INVALID) {
      this.searchSelection = '';
      this.input = '';
      this.error = action.error;
      this.emitChange();
    } else if (action.actionType === SearchConstants.SEARCH_RESET) {
      this.error = null;
      this.input = '';
      this.emitChange();
    } else if (action.actionType === SearchConstants.SEARCH_SELECT) {
      this.input = action.text;
      this.searchSelection = action.text;
      this.searchHoverText = '';
      this.searchHoverIdx = -1;
      this.emitChange();
    } else if (action.actionType === SearchConstants.SEARCH_HOVER) {
      this.searchHoverText = action.value;
      this.searchHoverIdx = action.idx;
      this.emitChange();
    } else if (action.actionType === SearchConstants.SEARCH_UPDATE_INPUTS) {
      this.shouldUpdate = true;
      this.emitChange();
      this.shouldUpdate = false;
    }
  }
}

const searchInputStore = new SearchInputStore();
searchInputStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;
  const source = payload.source;

  if (source === 'VIEW_ACTION') {
    searchInputStore.handleViewAction(action);
  }
});
export default searchInputStore;
