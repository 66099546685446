import React from 'react';
import PropTypes from 'prop-types';

const RenderIf = ({ checkFn, fnArgs, children }) => {
  if (checkFn(...fnArgs)) {
    return <div>{children}</div>;
  }
  return null;
};

RenderIf.propTypes = {
  checkFn: PropTypes.func,
  fnArgs: PropTypes.array,
  children: PropTypes.node.isRequired,
};

RenderIf.defaultProps = {
  checkFn: () => true,
  fnArgs: [],
};

export default RenderIf;
