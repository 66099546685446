import { getCurrencyTagFromApiCurrency } from 'escrow-common-js/dist/utils';

const apiFeeToDisplayName = {
  concierge: 'Concierge Service Fee',
  credit_card: 'Payment Processing Fee',
  paypal: 'Payment Processing Fee',
  disbursement: 'Disbursement Fee',
  domain_name_holding: 'Domain Name Holding Fee',
  escrow: 'Escrow Fee',
  intermediary: 'Payment Processing Fee',
  lien_holder_payoff: 'Lien Holder Payoff Fee',
  motor_vehicle: 'Motor Vehicle Fee',
  other: 'Other Fee',
  title_collection: 'Title Collection Fee',
};

const apiItemTypeToDisplayName = {
  domain_name: 'Domain Name',
  domain_name_holding: 'Domain Name Holding',
  general_merchandise: 'General Merchandise',
  milestone: 'Milestone',
  motor_vehicle: 'Motor Vehicle',
  broker_fee: 'Broker Fee',
  shipping_fee: 'Shipping Fee',
  partner_fee: 'Partner Fee',
};

const apiFeeItemTypes = new Set(['broker_fee', 'shipping_fee', 'partner_fee']);

const apiPaymentFeeTypes = new Set(['credit_card', 'intermediary']);

const payByMethods = new Set(['credit_card', 'paypal', 'wire_transfer']);

const sum = (arr = []) => arr.reduce((a, b) => a + b, 0.0);

export function getV4PartyData(v4Transaction, partyId) {
  return v4Transaction.parties.find((p) => p.id === partyId);
}

export function getV4PartyDataByEmailAndRole(v4Transaction, email, role) {
  return v4Transaction.parties.find((p) => p.customer === email && p.role === role);
}

export function getV4CustomerRoles(v4Transaction, email) {
  return (v4Transaction.parties || []).filter((p) => p.customer === email).map((p) => p.role);
}

export function getFeeSplitByRole(v4Transaction, lineItem, feeType) {
  const emailToRoleNonPartner = {};
  for (const party of v4Transaction.parties) {
    if (party.role !== 'partner') {
      emailToRoleNonPartner[party.customer] = party.role;
    }
  }

  const roleSplits = { buyer: 0.0, seller: 0.0, broker: 0.0 };
  const fees = lineItem.fees.filter((fee) => fee.type === feeType);
  if (fees.length === 0) {
    return { buyer: 0.0, seller: 0.0, broker: 0.0 };
  }
  const splitSpecified = fees.some((fee) => !isNaN(fee.split));
  if (!splitSpecified) {
    return { buyer: 1.0, seller: 0.0, broker: 0.0 };
  }
  for (const fee of fees) {
    if (fee.split) {
      roleSplits[emailToRoleNonPartner[fee.payer_customer]] += fee.split;
    }
  }

  return roleSplits;
}

export function getOtherTerms(v4Item) {
  const terms = {};
  if (v4Item.type === 'domain_name') {
    terms.concierge = Boolean(v4Item.extra_attributes && v4Item.extra_attributes.concierge);
    terms.withContent = Boolean(v4Item.extra_attributes && v4Item.extra_attributes.with_content);
  } else if (v4Item.type === 'domain_name_holding') {
    terms.numPayments = v4Item.schedule.length;
  }
  return terms;
}

export function getLineItemData(v4Transaction, partyEmail, partyRole) {
  const viewerPartyData = getV4PartyDataByEmailAndRole(v4Transaction, partyEmail, partyRole);
  const results = [];

  for (const lineItem of v4Transaction.items) {
    const amountPaid = sum(
      (lineItem.schedule || [])
        .filter((scheduleItem) => scheduleItem.payer_customer === viewerPartyData.customer)
        .map((scheduleItem) => parseFloat(scheduleItem.amount))
    );
    const amountReceived = sum(
      (lineItem.schedule || [])
        .filter((scheduleItem) => scheduleItem.beneficiary_customer === viewerPartyData.customer)
        .map((scheduleItem) => parseFloat(scheduleItem.amount))
    );

    const totalAmountPaid = amountPaid - amountReceived;
    const totalAmountReceived = amountReceived - amountPaid;

    results.push({
      key:
        lineItem.id ||
        lineItem.parent_item_id +
          lineItem.type +
          lineItem.title +
          lineItem.description +
          lineItem.quantity +
          amountPaid +
          amountReceived,
      id: lineItem.id,
      parentItemId: lineItem.parent_item_id,
      title: lineItem.title || '',
      description: lineItem.description || '',
      type: lineItem.type,
      typeDisplayName: apiItemTypeToDisplayName[lineItem.type] || lineItem.type,
      isFeeItem: apiFeeItemTypes.has(lineItem.type),
      buyerRejected: (lineItem.status || {}).rejected,
      quantity: lineItem.quantity || 1,
      rawAmountPaid: amountPaid,
      rawAmountReceived: amountReceived,
      totalAmountPaid,
      totalAmountReceived,
      inspectionPeriodSeconds: lineItem.inspection_period,
      escrowFeeSplitsByRole: getFeeSplitByRole(v4Transaction, lineItem, 'escrow'),
      otherTerms: getOtherTerms(lineItem),
      extraAttributes: lineItem.extra_attributes || {},
    });
  }

  return results;
}

export function getFeeLineItemDataSquashed(lineItems) {
  const result = [];

  const feeItemTypesInTransaction = [...apiFeeItemTypes].filter((t) =>
    lineItems.some((i) => i.type === t)
  );
  for (const feeItemType of feeItemTypesInTransaction) {
    const feeItems = lineItems.filter((i) => i.type === feeItemType);
    const item = {
      key: null,
      type: feeItemType,
      title: '',
      description: '',
      typeDisplayName: apiItemTypeToDisplayName[feeItemType] || feeItemType,
      isFeeItem: true,
      rawAmountPaid: 0.0,
      rawAmountReceived: 0.0,
      totalAmountPaid: 0.0,
      totalAmountReceived: 0.0,
    };

    result.push(
      feeItems.reduce(
        (squashed, nextItem) => ({
          ...squashed,
          key: squashed.key || nextItem.key,
          title: squashed.title || nextItem.title,
          description: squashed.description || nextItem.description,
          rawAmountPaid: squashed.rawAmountPaid + nextItem.rawAmountPaid,
          rawAmountReceived: squashed.rawAmountReceived + nextItem.rawAmountReceived,
          totalAmountPaid: squashed.totalAmountPaid + nextItem.totalAmountPaid,
          totalAmountReceived: squashed.totalAmountReceived + nextItem.totalAmountReceived,
        }),
        item
      )
    );
  }

  return result;
}

export function getFeeData(v4Transaction, partyEmail, partyRole, config) {
  const viewerPartyData = getV4PartyDataByEmailAndRole(v4Transaction, partyEmail, partyRole);
  const { payBy } = config;
  const feesExclTaxes = {};
  const feeTaxes = {};
  const taxTypesPresent = new Set();
  let feeSuffix = '';

  // extract the fee totals without tax and the
  // tax totals for each fee type
  for (const lineItem of v4Transaction.items) {
    // Skip fees where only split is specified (i.e. draft transactions)
    const fees = (lineItem.fees || []).filter((f) => f.amount || f.amount_without_taxes);
    for (const feeObj of fees) {
      if (feeObj.payer_customer === viewerPartyData.customer) {
        if (!feesExclTaxes[feeObj.type]) {
          feesExclTaxes[feeObj.type] = 0.0;
        }
        feesExclTaxes[feeObj.type] += parseFloat(feeObj.amount_without_taxes);

        for (const taxObj of feeObj.taxes || []) {
          taxTypesPresent.add(taxObj.type);
          if (!feeTaxes[feeObj.type]) {
            feeTaxes[feeObj.type] = {};
          }
          if (!feeTaxes[feeObj.type][taxObj.type]) {
            feeTaxes[feeObj.type][taxObj.type] = 0.0;
          }
          feeTaxes[feeObj.type][taxObj.type] += parseFloat(taxObj.amount);
        }
      }
    }
  }

  if (payBy && payByMethods.has(payBy) && v4Transaction.paymentMethods) {
    for (const feeType of apiPaymentFeeTypes) {
      delete feesExclTaxes[feeType];
      delete feeTaxes[feeType];
    }
    switch (payBy) {
      case 'wire_transfer': {
        const wireTransferMethod = v4Transaction.paymentMethods.available_payment_methods.filter(
          (method) => method.type === 'wire_transfer'
        );
        if (wireTransferMethod.length > 0) {
          feesExclTaxes.intermediary =
            wireTransferMethod[0].total - v4Transaction.paymentMethods.total_without_payment_fee;
        }
        break;
      }
      case 'credit_card': {
        const creditCardMethod = v4Transaction.paymentMethods.available_payment_methods.filter(
          (method) => method.type === 'credit_card'
        );

        const conditionalCreditCardMethod = v4Transaction.paymentMethods
          .conditionally_available_payment_methods
          ? v4Transaction.paymentMethods.conditionally_available_payment_methods.filter(
              (method) => method.type === 'credit_card'
            )
          : [];

        if (creditCardMethod.length > 0) {
          feesExclTaxes.credit_card =
            creditCardMethod[0].total - v4Transaction.paymentMethods.total_without_payment_fee;
        } else if (conditionalCreditCardMethod.length > 0) {
          feesExclTaxes.credit_card =
            conditionalCreditCardMethod[0].total -
            v4Transaction.paymentMethods.total_without_payment_fee;
        }
        break;
      }
      case 'paypal': {
        const paypalMethod = v4Transaction.paymentMethods.available_payment_methods.filter(
          (method) => method.type === 'paypal'
        );

        const conditionalPaypalMethod = v4Transaction.paymentMethods
          .conditionally_available_payment_methods
          ? v4Transaction.paymentMethods.conditionally_available_payment_methods.filter(
              (method) => method.type === 'paypal'
            )
          : [];

        if (paypalMethod.length > 0) {
          feesExclTaxes.paypal =
            paypalMethod[0].total - v4Transaction.paymentMethods.total_without_payment_fee;
        } else if (conditionalPaypalMethod.length > 0) {
          feesExclTaxes.paypal =
            conditionalPaypalMethod[0].total -
            v4Transaction.paymentMethods.total_without_payment_fee;
        }
        break;
      }
      default:
        break;
    }
  }

  if (taxTypesPresent.size > 1) {
    throw Error('getFeeData does not support multiple tax types');
  } else if (taxTypesPresent.size === 1) {
    feeSuffix = ` with ${taxTypesPresent.values().next().value.toUpperCase()}`;
  }

  const finalResult = [];

  for (const feeType of Object.keys(feesExclTaxes)) {
    const feeAmountExclTax = feesExclTaxes[feeType];
    finalResult.push({
      type: feeType,
      displayName: (apiFeeToDisplayName[feeType] || feeType) + feeSuffix,
      amountExclTax: feeAmountExclTax,
      taxByType: feeTaxes[feeType],
      amountInclTax: feeAmountExclTax + sum(Object.values(feeTaxes[feeType] || {})),
      totalTaxAmount: sum(Object.values(feeTaxes[feeType] || {})),
    });
  }
  return finalResult;
}

export function getTransactionSummary(v4Transaction, viewerPartyEmail, viewerPartyRole, config) {
  const viewerPartyData = getV4PartyDataByEmailAndRole(
    v4Transaction,
    viewerPartyEmail,
    viewerPartyRole
  );

  // get base data
  const lineItemData = getLineItemData(v4Transaction, viewerPartyEmail, viewerPartyRole);
  const feeLineItemDataSquashed = getFeeLineItemDataSquashed(lineItemData);
  const feeData = getFeeData(v4Transaction, viewerPartyEmail, viewerPartyRole, config);

  // calculate non-fee based line item totals
  const nonFeeLineItemTotalPaid = sum(
    lineItemData
      .filter((item) => !apiFeeItemTypes.has(item.type))
      .filter((item) => !item.buyerRejected)
      .map((item) => item.totalAmountPaid)
  );
  const nonFeeLineItemTotalReceived = sum(
    lineItemData
      .filter((item) => !apiFeeItemTypes.has(item.type))
      .filter((item) => !item.buyerRejected)
      .map((item) => item.totalAmountReceived)
  );

  // calculate fee based line item totals
  const feeLineItemTotalPaid = sum(
    lineItemData
      .filter((item) => apiFeeItemTypes.has(item.type))
      .filter((item) => !item.buyerRejected)
      .map((item) => item.totalAmountPaid)
  );
  const feeLineItemTotalReceived = sum(
    lineItemData
      .filter((item) => apiFeeItemTypes.has(item.type))
      .filter((item) => !item.buyerRejected)
      .map((item) => item.totalAmountReceived)
  );

  // calculate the refund totals
  const nonFeeLineItemTotalRefund = sum(
    lineItemData
      .filter((item) => !apiFeeItemTypes.has(item.type))
      .filter((item) => item.buyerRejected)
      .map((item) => item.totalAmountPaid)
  );
  const feeLineItemTotalRefund = sum(
    lineItemData
      .filter((item) => apiFeeItemTypes.has(item.type))
      .filter((item) => item.buyerRejected)
      .map((item) => item.totalAmountPaid)
  );

  // calculate overall line item totals
  const lineItemTotalPaid = nonFeeLineItemTotalPaid + feeLineItemTotalPaid;
  const lineItemTotalReceived = nonFeeLineItemTotalReceived + feeLineItemTotalReceived;
  const lineItemTotalRefund = nonFeeLineItemTotalRefund + feeLineItemTotalRefund;

  // find the transaction fee total
  const viewerPartyFeeTotal = sum(feeData.map((fee) => fee.amountInclTax));

  // create summary
  return {
    title: v4Transaction.description,
    id: v4Transaction.id,
    currency: getCurrencyTagFromApiCurrency(v4Transaction.currency),
    lineItemData,
    feeLineItemDataSquashed,
    feeData,
    nonFeeLineItemTotalPaid,
    nonFeeLineItemTotalReceived,
    feeLineItemTotalPaid,
    feeLineItemTotalReceived,
    nonFeeLineItemTotalRefund,
    feeLineItemTotalRefund,
    lineItemTotalAmountPaid: lineItemTotalPaid,
    lineItemTotalAmountReceived: lineItemTotalReceived,
    viewerPartyFeeTotal,
    totalAmountPaid: lineItemTotalPaid + viewerPartyFeeTotal,
    totalAmountReceived: lineItemTotalReceived - viewerPartyFeeTotal,
    totalAmountRefunded: viewerPartyData.role === 'buyer' ? lineItemTotalRefund : 0.0,
  };
}

export function partyRequiresKyc(v4Transaction, partyId) {
  const viewerPartyData = getV4PartyData(v4Transaction, partyId);
  return Boolean(viewerPartyData && viewerPartyData.verification_required);
}

export function getPartiesByRole(v4Transaction, role) {
  return (v4Transaction.parties || []).filter((p) => p.role === role);
}
export function getInitiatorParty(v4Transaction) {
  return (v4Transaction.parties || []).filter((p) => p.initiator);
}
