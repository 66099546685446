import deepcopy from 'clone';

import { ChangeEmitter } from '../utils/ChangeEmitter';
import UserConstants from '../constants/UserConstants';
import AppDispatcher from '../dispatcher/AppDispatcher';

export class UserStore extends ChangeEmitter {
  constructor() {
    super();
    this.handleViewAction = this.handleViewAction.bind(this);
    this.handleServerAction = this.handleServerAction.bind(this);
    this.kycStatus = {};
    this.customerData = {};
    this.needsUpdate = {};
  }

  _updateKycStatus(customerID, kycStatus) {
    this.kycStatus[customerID] = deepcopy(kycStatus);
  }

  _updateCustomerData(customerID, data) {
    this.customerData[customerID] = deepcopy(data);
  }

  getKycStatus(customerID) {
    return this.kycStatus[customerID];
  }

  getCustomerData(customerID) {
    return this.customerData[customerID];
  }

  isKycCompleted(customerID) {
    const custData = this.getCustomerData(customerID);
    if (custData && custData.VerificationStatus) {
      return custData.VerificationStatus.IsKYCCompleted;
    }
    return false;
  }

  kycActionRequired(customerID) {
    const custData = this.getCustomerData(customerID);
    if (custData) {
      return custData.VerificationStatus.IsKYCActionRequired;
    }
    return true;
  }

  kycInProgress(customerID) {
    const kycStatus = this.getKycStatus(customerID);
    return kycStatus && kycStatus.IsInProgress;
  }

  _setUpdateRequirements(customerID, needsUpdate) {
    this.needsUpdate[customerID] = needsUpdate;
  }

  /**
   * Checks if the customer has incomplete personal details
   */
  custNeedsUpdate(customerID) {
    return this.needsUpdate[customerID];
  }

  getFullName(customerID) {
    const custData = this.getCustomerData(customerID) || {};
    let fullName = custData.FirstName || '';
    fullName += custData.LastName ? ` ${custData.LastName}` : '';
    return fullName;
  }

  handleViewAction() {}

  handleServerAction(action) {
    const actionType = action.actionType;
    if (actionType === UserConstants.KYC_STATUS_FETCH) {
      this._updateKycStatus(action.customerID, action.data);
      this.emitChange();
    } else if (actionType === UserConstants.CUSTOMER_DATA_FETCH) {
      this._updateCustomerData(action.customerID, action.data);
      this.emitChange();
    } else if (actionType === UserConstants.CUSTOMER_DETAILS_UPDATE_REQUIRED) {
      this._setUpdateRequirements(action.customerID, action.needsUpdate);
      this.emitChange();
    }
  }
}

const userStore = new UserStore();
userStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;
  const source = payload.source;

  if (source === 'VIEW_ACTION') {
    userStore.handleViewAction(action);
  } else if (source === 'SERVER_ACTION') {
    userStore.handleServerAction(action);
  }
});

export default userStore;
