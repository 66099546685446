import React from 'react';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MediaQueryConstants from 'spa/constants/MediaQueryConstants';

const Theme = (props) => {
  const theme = createTheme({
    palette: {
      primary: { main: '#3CB95D', light: '#42C31D', dark: '#217F2D', contrastText: '#FFF' },
      secondary: { main: '#002B49', light: '#01426A', dark: '#001D42' },
      secondaryLight: { main: '#2F80ED', light: '#468EF0', dark: '#225CAB', contrastText: '#FFF' },
      mono: { main: '#7F8C8D', dark: '#4F5759', xdark: '#2B3340', xxlight: '#f5f7f9' },
      error: { main: '#D30000', contrastText: '#FFF' },
      warning: { main: '#677702' },
      success: { main: '#007F00' },
      disabled: { main: '#555555' },
      white: { main: '#FFFFFF' },
    },
    typography: {
      fontSize: 22,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            margin: '1rem 0',
            textTransform: 'none',
            fontSize: '1.6rem',
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: '0 9px',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          standard: {
            ':focus': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          root: {
            width: '0',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            whiteSpace: 'unset',
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            '&:before': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover',
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            padding: '8px',
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            paddingRight: '24px',
            paddingLeft: '24px',
            flexDirection: { xs: 'column', md: 'row' },
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: MediaQueryConstants.MIN_BREAKPOINT.MOBILE,
        md: MediaQueryConstants.MIN_BREAKPOINT.TABLET,
        lg: MediaQueryConstants.MIN_BREAKPOINT.DESKTOP,
        xl: 1200,
      },
    },
  });
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export function useIsMobileViewport() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('md'));
}

export default Theme;
