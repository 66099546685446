import React from 'react';

import ReferralLinksDisplay from 'spa/containers/ReferralLinksDisplay';
import DisplayTaskList from 'spa/containers/DisplayTaskList';
import Footer from 'spa/components/Footer';
import { Section, SectionTitle, SectionDesc } from 'spa/components/Section';

const ReferralLinksGetStartedPage = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktop-9">
        <h1 className="integrationsPortal-title">Referral links</h1>
        <Section>
          <SectionTitle>What are referral links?</SectionTitle>
          <SectionDesc>
            Referral links are the lowest effort integration option which allow you to direct
            traffic from your website or blog to Escrow.com. If your customers have a need to pay
            for your products or products on your marketplace, we recommend you look at the API
            integration instead.
          </SectionDesc>
          <ReferralLinksDisplay />
        </Section>
        <DisplayTaskList list="referrals">
          <SectionTitle>Referral Links Integration Checklist</SectionTitle>
        </DisplayTaskList>
        <Footer />
      </div>
    </div>
  </div>
);

export default ReferralLinksGetStartedPage;
