// New header sticky nav
let header;
let announcements;
let bannerOffset;

// Calculate the header offset due to the variable height banner
export function setStickyNavOffset() {
  header = document.querySelector('[data-sticky-header]');
  announcements = document.querySelector('.announcements');
  if (announcements) {
    bannerOffset = document.querySelector('.announcements').clientHeight;
  }
}

export function headerIsSticky() {
  return window.pageYOffset > bannerOffset;
}

export function headerScrollTop() {
  return window.pageYOffset === 0;
}

export function updateStickyNav() {
  if (!header || !document.body.contains(header)) {
    setStickyNavOffset();
  }
  if (header) {
    if (headerIsSticky()) {
      header.classList.add('is-sticky');
    } else {
      header.classList.remove('is-sticky');
    }
    if (headerScrollTop()) {
      header.classList.add('is-header-scrollTop');
    } else {
      header.classList.remove('is-header-scrollTop');
    }
  }
}

window.onscroll = updateStickyNav;

// The offset needs to be recalculated when the height of the banner may change
// (when text wraps due to width resize)
window.onresize = setStickyNavOffset;
