import React, { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { composeValidators, noStartTrailWhitespace, required } from 'spa/components/form/validate';
import {
  kycGotoPrevPage as kycGotoPrevPageRoutine,
  kycSetSubheader,
  kycSubmitCustomerDetails as kycSubmitCustomerDetailsRoutine,
} from 'spa/actions/VerificationActions';
import { Alpha3ToAlpha2 } from 'spa/constants/ISOCountryCodes';
import VerificationConstants from 'spa/constants/VerificationConstants';
import ActionButtons from 'spa/components/CustomerVerification/V2/ActionButtons';
import {
  customerIsCompanySelector,
  customerKycLoadingUiSelector,
  customerKycSubmitLoadingSelector,
  buyerCheckoutDetailsSelector,
  customerKyc3ApprovedSelector,
  customerCompanyNameSelector,
  customerKycHasPrevPagesSelector,
} from 'spa/selectors/CustomerSelectors';
import { KYCContext } from 'spa/context/KYCContext';
import Heading from './Heading';
import { InputField, AddressFieldSet } from './Fields';

const CompanyDetailsForm = (props) => {
  const {
    isSubmitting,
    loadPrevPage,
    submitIndividualDetails,
    companyDetails,
    isCompanyInformationVerified,
    hasPrevPages,
    hasCompanyName,
    setSubheaderText,
  } = props;
  const [isEditting, setIsEditting] = useState(hasCompanyName);
  useEffect(() => {
    if (!isSubmitting) {
      setIsEditting(hasCompanyName);
    }
  }, [hasCompanyName, isSubmitting]);
  const kycContext = useContext(KYCContext);

  useEffect(() => {
    setSubheaderText(`We protect both sides of the transaction by verifying the identity of all users.
Your verified identity must be the same as the account holder's name on any bank account used to pay or receive funds from Escrow.com
`);
  }, [setSubheaderText]);
  const defaultCountry =
    Alpha3ToAlpha2[window.config.geoip_country] &&
    Alpha3ToAlpha2[window.config.geoip_country].toLowerCase();
  const [showAutocompleteAddressError, setShowAutocompleteAddressError] = useState(false);
  const onFormSubmit = (values, form) => {
    submitIndividualDetails({
      values,
      form,
      formName: VerificationConstants.COMPANY_DETAILS_FORM,
      kycContext,
    });
  };
  return (
    <div>
      <Form
        onSubmit={onFormSubmit}
        subscription={{
          values: true,
          submitting: true,
          hasValidationErrors: true,
        }}
        initialValues={{
          'account-type': VerificationConstants.ACCOUNT_TYPE_COMPANY,
          ...companyDetails,
        }}
        mutators={{
          clearAutocompleteAddressError: ([prefix], state) => {
            state.fields[`${prefix}address`].data.error = undefined;
            state.fields[`${prefix}city`].data.error = undefined;
            state.fields[`${prefix}state`].data.error = undefined;
          },
        }}
      >
        {({ handleSubmit, hasValidationErrors }) => (
          <form onSubmit={handleSubmit} name={VerificationConstants.COMPANY_DETAILS_FORM}>
            <Heading text="Business Details" hasDivider />
            <InputField
              name="company-name"
              label="Company Name"
              validate={composeValidators([required, noStartTrailWhitespace])}
              disabled={isSubmitting || isCompanyInformationVerified}
            />
            <AddressFieldSet
              type="company"
              defaultCountry={companyDetails['company-country'] || defaultCountry || 'us'}
              showError={showAutocompleteAddressError}
              setShowError={setShowAutocompleteAddressError}
              disabled={isSubmitting}
            />
            <ActionButtons
              secondaryText="Back"
              primaryText="Save and Next"
              isLoading={isSubmitting}
              isDisabled={hasValidationErrors}
              type="submit"
              withBack={hasPrevPages || isEditting}
              onSecondaryClick={() => loadPrevPage()}
              onPrimaryClick={() => {
                setShowAutocompleteAddressError(true);
              }}
            />
          </form>
        )}
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isCompany: customerIsCompanySelector(state),
  isLoading: customerKycLoadingUiSelector(state),
  isSubmitting: customerKycSubmitLoadingSelector(state),
  companyDetails: buyerCheckoutDetailsSelector(state),
  hasCompanyName: Boolean(customerCompanyNameSelector(state)),
  isCompanyInformationVerified: customerKyc3ApprovedSelector(state),
  hasPrevPages: customerKycHasPrevPagesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadPrevPage: () => dispatch(kycGotoPrevPageRoutine.trigger()),
  setSubheaderText: (subheaderText) => dispatch(kycSetSubheader(subheaderText)),
  submitIndividualDetails: (payload) => dispatch(kycSubmitCustomerDetailsRoutine.trigger(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetailsForm);
