import classNames from 'classnames';
import React from 'react';

const MessageBox = ({ children, type }) => {
  const className = classNames('alert', { 'alert--error': type === 'error' });

  return <div className={className}>{children}</div>;
};

export default MessageBox;
