import React, { useRef, useState, useMemo, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress, Grid, Stack, IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import {
  saveCurrentPage,
  pushPreviousPage,
  popPreviousPage,
  kycCurrentPageSelector,
  kycPreviousPagesSelector,
} from 'spa/features/kyc/kycSlice';
import { LargeText, SmallText } from 'spa/features/ui/EscrowTypography';
import { EscrowButton } from 'spa/features/ui/EscrowButton';

const cameraTopBarStyle = {
  position: 'absolute',
  zIndex: 3,
  backgroundColor: '#fff',
  top: 0,
  left: 0,
  width: '100%',
  height: '6%',
  alignItems: 'center',
  paddingLeft: '2rem',
  paddingRight: '2rem',
};

const MobilePage = ({
  children,
  title,
  subtitle,
  ctaText,
  onSubmit,
  nextPage,
  nextPageTrigger,
  prevPageTrigger,
  noButton,
  dynamicTitleFontSize,
  dynamicSubtitleFontSize,
  disableButtons,
  ExtraButton,
  HeaderIcon,
  titleAlignment = 'initial',
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const currentPage = useSelector(kycCurrentPageSelector);
  const previousPages = useSelector(kycPreviousPagesSelector);

  const titleRef = useRef();
  const subtitleRef = useRef();

  const [isTitleOverflowed, setIsTitleOverflowed] = useState(false);
  const [isSubtitleOverflowed, setIsSubtitleOverflowed] = useState(false);

  const escrowButtonStyle = useMemo(() => {
    const buttonWidth = ExtraButton ? 'auto' : '100%';
    return {
      padding: '12px',
      fontSize: '18px',
      width: buttonWidth,
      margin: '2px',
      borderRadius: '8px',
    };
  }, [ExtraButton]);

  const onNextPage = useCallback(
    (page) => {
      dispatch(pushPreviousPage({ page: currentPage }));
      dispatch(saveCurrentPage({ page }));
    },
    [currentPage, dispatch]
  );

  const onPrevPage = useCallback(() => {
    dispatch(
      saveCurrentPage({ page: previousPages[previousPages.length ? previousPages.length - 1 : ''] })
    );
    dispatch(popPreviousPage());
  }, [previousPages, dispatch]);

  useEffect(() => {
    setIsTitleOverflowed(titleRef.current && titleRef.current.clientHeight > 32);
    setIsSubtitleOverflowed(subtitleRef.current && subtitleRef.current.clientHeight > 24);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('nextPagetrigger', nextPageTrigger);
    if (nextPageTrigger) {
      onNextPage(nextPage);
    }
  }, [nextPageTrigger, onNextPage, nextPage]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('prevPagetrigger', prevPageTrigger);
    if (prevPageTrigger) {
      onPrevPage();
    }
  }, [prevPageTrigger, onPrevPage]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      sx={{
        minHeight: '100vh',
        height: '100%',
        width: '100%',
        padding: '24px 24px 40px',
        border: '0px',
        backgroundColor: theme.palette.white,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
      }}
    >
      <Grid sx={{ paddingBottom: '24px', paddingTop: '24px', width: '100%' }} item>
        <Stack sx={{ gap: '24px' }} direction="column">
          <Grid
            container
            direction="row"
            sx={!(title || subtitle || ctaText) ? { ...cameraTopBarStyle } : null}
          >
            <Grid item xs="auto" alignItems="flex-start" sx={{ width: 'fit-content' }}>
              <IconButton disabled={disableButtons} onClick={onPrevPage} sx={{ padding: '0px' }}>
                <ArrowBack sx={{ width: '24px', height: '24px' }} />
              </IconButton>
            </Grid>
            <Grid item xs container alignItems="center" justifyContent="flex-end">
              <CircularProgress
                size={24}
                variant="determinate"
                value={25}
                sx={{ color: theme.palette.secondaryLight.dark }}
              />
            </Grid>
          </Grid>
          <Stack direction="column">
            {HeaderIcon && <HeaderIcon />}
            <Stack
              direction="column"
              sx={
                subtitle
                  ? { gap: '8px', textAlign: `${titleAlignment}` }
                  : { textAlign: `${titleAlignment}` }
              }
            >
              {title && (
                <LargeText
                  sx={
                    isTitleOverflowed && dynamicTitleFontSize
                      ? { fontSize: `clamp(20px, ${dynamicTitleFontSize}, 24px)` }
                      : null
                  }
                  ref={titleRef}
                >
                  {title}
                </LargeText>
              )}
              {subtitle && (
                <SmallText
                  sx={
                    isSubtitleOverflowed && dynamicSubtitleFontSize
                      ? { fontSize: `clamp(12px, ${dynamicSubtitleFontSize}, 16px)` }
                      : null
                  }
                  ref={subtitleRef}
                >
                  {subtitle}
                </SmallText>
              )}
            </Stack>
          </Stack>
          {children}
        </Stack>
      </Grid>
      {!noButton && (onSubmit || nextPage) ? (
        <Grid
          item
          xs
          container
          alignItems="flex-end"
          style={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}
        >
          {ExtraButton && <ExtraButton sx={escrowButtonStyle} disabled={disableButtons} />}
          {ctaText && (
            <EscrowButton
              key="continue"
              disabled={disableButtons}
              onClick={(dataOnSubmit) => {
                onSubmit ? onSubmit(dataOnSubmit) : null;
                nextPage && nextPageTrigger === undefined ? onNextPage(nextPage) : null;
              }}
              variant="contained"
              disableHoverEffect
              color="primary"
              sx={{ ...escrowButtonStyle }}
            >
              {ctaText}
            </EscrowButton>
          )}
        </Grid>
      ) : null}
    </Grid>
  );
};

export default MobilePage;
