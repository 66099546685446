import { gettext } from '../utils/filters';

const PLEASE_TRY_AGAIN_OR_CONTACT = gettext(
  'Please try again, or contact us directly via support@escrow.com so we can help you personally.'
);

export default {
  PLEASE_TRY_AGAIN_OR_CONTACT,
  EMAIL_NOT_FOUND: gettext(
    "Your email address was not found in our system, please select 'Register an Account', or try again, or contact us directly via support@escrow.com so we can help you personally"
  ),
  EMAIL_ALREADY_EXISTS: gettext(
    "Your chosen email address is already in our system, please select 'I am a returning user', or try a different email address"
  ),
  EMAIL_ALREADY_EXISTS_SIMPLE: gettext('Email address is already registered with Escrow.com'),
  TRANSACTION_FAILED: gettext('Your transaction was not started. Please try again.'),
  TECHNICAL_DIFFICULTIES: `${gettext(
    "Sorry, due to technical issues we couldn't process your request at this time."
  )} ${PLEASE_TRY_AGAIN_OR_CONTACT}`,
  INCOMPLETE_FORM: gettext('Please complete all required fields in the form.'),
  TURNSTILE_FAILED: gettext(
    'The account login failed due to a technical issue on our end. Please refresh the page and try again. If the issue persists, please contact support@escrow.com and provide the reference: 300100.'
  ),
  AGREEMENT_FAIL: gettext('Agreement unsuccessful, please try again.'),
  INVALID_SUBMISSION: gettext('Invalid submission, please try again.'),
  LOGIN_FAIL: gettext(
    'The email address and password combination you entered is incorrect. Please try again.'
  ),
  VERIFY_KYC_FAIL: gettext('Documents were not submitted, please try again.'),
  VERIFY_EKYC_FAIL: {
    ATTEMPTS_LEFT: (attemptsLeft) =>
      gettext(
        `Verification failed. You have ${attemptsLeft} attempt${
          attemptsLeft > 1 ? 's' : ''
        } left. \nPlease review the document information you provided and try again.`
      ),
    MAX_ATTEMPTS: gettext(
      'We were unable to verify your identity using the information provided. \nYou may opt to finish your account verification by uploading a document, or you may opt to submit later.'
    ),
  },
  DISABLED_EKYC:
    'You may finish your account verification by uploading a document, or you may opt to submit later.',
  VERIFY_ADDRESS_FAIL: gettext('Contact details were not updated, please try again.'),
  VERIFY_SUBMISSION_FAIL: gettext('This form was not successfully submitted, please try again.'),
  PASSWORD_CHANGE_FAILED: gettext('Your password was not updated, please try again.'),
  SESSION_EXPIRY: gettext('Your session has expired. Please login again.'),
  CLEAR_24_HR_NOTICE: gettext(
    'Your transaction was not cleared from 24-hour notice, please try again.'
  ),

  DIRECT_DEBIT_INSUFFICIENT_BALANCE: gettext(
    'Please confirm the funds of your selected account and retry your payment. Alternatively, you may choose a different account or change your payment method.'
  ),

  DIRECT_DEBIT_SUBMIT_FAILED: gettext(
    'There was an error charging your account, please try again later.'
  ),

  CREDIT_CARD_SUBMIT_FAILED: gettext(
    'There was an error charging your card, please check the details and try again.'
  ),
  TOO_MANY_CREDIT_CARD_ATTEMPTS: gettext(
    'You have exceeded the maximum credit card payments for the day.  Please try again tomorrow or contact support@escrow.com .'
  ),

  ASSISTANT_MESSAGE_FOLLOWED_BY_REQUEST_ID: gettext(
    'To help us assist you, please contact support@escrow.com and quote the reference id:'
  ),
  CC_ERROR_CODE_MAPPING: {
    DECLINED: gettext(
      'Your card was declined, please check your card details before trying again.'
    ),
    CLIENT_TIMEOUT: gettext(
      'An error occurred while processing your card. Try again in a little bit.'
    ),
    INVALID_CARD_NUMBER: gettext(
      'Your card was declined, please check your card details before trying again.'
    ),
    INVALID_EXPIRATION_DATE: gettext(
      'Your card was declined, please check your card details before trying again.'
    ),
    CSC_CODE_MISMATCH: gettext(
      'Your card was declined, please check your card details before trying again.'
    ),
    DECLINED_BY_FRAUD_FILTERS: gettext(
      'Your card was declined, please check your card details before trying again.'
    ),
    FLAGGED_BY_FRAUD_FILTERS: gettext(
      'Your card was declined, please check your card details before trying again.'
    ),
  },

  ADYEN_THREEDS_FAILED: gettext(
    "The 3D Secure authentication for your credit card failed. Your card wasn't charged and you may try again."
  ),
  ADYEN_THREEDS_FAILED_ON_SAVING: gettext(
    "The 3D Secure authentication for your credit card failed. Your card wasn't saved and you may try again."
  ),

  TWO_FACTOR_CODE_FAIL: gettext('The code you have entered is incorrect. Please try again.'),
  TWO_FACTOR_SEND_SMS_FAIL: gettext(
    'Something went wrong while trying to send a code to your number. Please try again.'
  ),
  SELLER_FEES_EXCEEDED_TOTAL:
    'Fees paid by the seller must not exceed the total cost of the transaction',
};
