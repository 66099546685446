import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { reduxForm, formValues, change, untouch, stopSubmit } from 'redux-form';
import { ActionButton, FormErrorBox, FormMessageBox } from 'spa/components/form';
import VerificationConstants from 'spa/constants/VerificationConstants';
import {
  VerificationMethodFieldset,
  DocumentFieldsFieldSet,
  IdVerificationFieldset,
  AddressVerificationFieldset,
  ReadAndAgreedFieldset,
} from './VerificationDetailsFieldset';
import ErrorMessages from '../../../constants/ErrorMessages';
import { getNationalDocuments } from '../../../utils/IdentificationDocuments';
import { gettext } from '../../../utils/filters';

class ElectronicVerificationForm extends React.Component {
  constructor(props) {
    super(props);
    this.documents = {};
    this.handleVerificationMethodSelect = this.handleVerificationMethodSelect.bind(this);
    this.handleDocumentFieldChange = this.handleDocumentFieldChange.bind(this);
    const countryDocuments = getNationalDocuments(props.personalCountry);
    countryDocuments.map((doc) => {
      const fieldsWithValidators = doc.fields.reduce((fields, field) => {
        if (field.required) {
          const validators = (field.validators || []).map(
            (validator) => (value, allValues) => validator(doc)(value, allValues)
          );
          return [...fields, { ...field, validators }];
        }
        return fields;
      }, []);
      this.documents[doc.name] = { ...doc, fields: fieldsWithValidators };
    });
  }

  handleVerificationMethodSelect(oldDocument) {
    if (oldDocument && oldDocument !== 'upload-document') {
      this.documents[oldDocument].fields.map((field) => {
        if (field.toggleFieldsOnChange) {
          field.toggleFieldsOnChange.map((fieldToChange) => {
            this.documents[oldDocument].fields.map((f) => {
              if (f.name === fieldToChange.field) f.required = false;
            });
          });
        }
        if (field.required) {
          this.props.dispatch(change(this.props.form, field.name, null));
          this.props.dispatch(untouch(this.props.form, field.name));
        }
      });
    }
  }

  handleDocumentFieldChange(changedField, newValue) {
    const { dependents, toggleFieldsOnChange } = changedField;
    if (dependents) {
      dependents.map((dependent) => {
        this.props.dispatch(change(this.props.form, dependent, null));
        this.props.dispatch(untouch(this.props.form, dependent));
      });
    }
    if (toggleFieldsOnChange) {
      toggleFieldsOnChange.map((fieldToToggle) => {
        const countryDocuments = getNationalDocuments(this.props.personalCountry);
        countryDocuments.map((doc) => {
          const fieldsWithValidators = doc.fields.reduce((fields, field) => {
            if (field.name === fieldToToggle.field) {
              field.required = fieldToToggle.isEnabled(newValue);
            }
            if (field.required) {
              const validators = (field.validators || []).map(
                (validator) => (value, allValues) => validator(doc)(value, allValues)
              );
              return [...fields, { ...field, validators }];
            }
            return fields;
          }, []);
          this.documents[doc.name] = { ...doc, fields: fieldsWithValidators };
        });
      });
    }
  }

  render() {
    const { props } = this;
    return (
      <form name={props.form} onSubmit={props.handleSubmit(this.props.onSubmit)}>
        <header className="verifyPage-card-header">
          <div className="verifyPage-card-header-container">
            {props.title && <h4 className="verifyPage-card-header-title">{props.title}</h4>}
            {props.desc && !props.disableEKYC && (
              <p className="verifyPage-card-header-desc">{props.desc}</p>
            )}
            {props.disableEKYC && props.error !== ErrorMessages.VERIFY_EKYC_FAIL.MAX_ATTEMPTS && (
              <FormMessageBox message={ErrorMessages.DISABLED_EKYC} />
            )}
            {props.submitFailed && props.error && <FormErrorBox error={props.error} />}
          </div>
        </header>
        <div className="verifyPage-card-content">
          <div className="verifyPage-card-body">
            <VerificationMethodFieldset
              disableEKYC={props.disableEKYC}
              countryAlpha2={props.personalCountry}
              onChange={(event, newValue, previousValue) => {
                this.handleVerificationMethodSelect(previousValue);
                props.onDocumentChange && props.onDocumentChange(event);
                props.submitFailed && props.dispatch(stopSubmit(props.form));
              }}
            />
            {props.verificationMethod && props.verificationMethod !== 'upload-document' && (
              <DocumentFieldsFieldSet
                document={this.documents[props.verificationMethod]}
                onFieldChange={this.handleDocumentFieldChange}
                formProps={props}
              />
            )}
            {props.verificationMethod === 'upload-document' && (
              <Fragment>
                <IdVerificationFieldset withTooltip={props.showSampleDocTooltips} withSubtitle />
                <AddressVerificationFieldset
                  hide={props.idContainsAddress}
                  withTooltip={props.showSampleDocTooltips}
                  withSubtitle
                />
              </Fragment>
            )}
          </div>
        </div>

        <footer className="verifyPage-card-footer">
          <div className="verifyPage-readAndAgreed">
            <ReadAndAgreedFieldset />
          </div>
          <div className="verifyPage-actions verifyPage-actions--rightHeavy">
            {this.props.onCancel && (
              <a
                href="#"
                role="button"
                className={classnames({
                  'verifyPage-secondaryBtn--alt': !props.checkoutMode,
                  'checkout-card-secondaryAction': props.checkoutMode,
                })}
                onClick={this.props.onCancel}
                data-e2e-target="verification-ekyc-cancel-button"
              >
                {gettext(props.onCancelMsg || 'Cancel')}
              </a>
            )}
            <div className="verifyPage-actions-subgroup">
              {props.onSubmitLater && (
                <a
                  href="#"
                  role="button"
                  className={classnames({
                    'verifyPage-secondaryBtn--alt': !props.checkoutMode,
                    'checkout-card-secondaryAction': props.checkoutMode,
                  })}
                  onClick={props.onSubmitLater}
                  data-e2e-target="verification-ekyc-cancel-button"
                >
                  {gettext('Submit Later')}
                </a>
              )}
              <ActionButton
                type="submit"
                className="btn btn--secondary"
                data-e2e-target="verification-ekyc-submit-button"
                data-tracking-name={'submit'}
                loading={props.submitting}
                label={gettext('Verify')}
                disabled={!props.verificationMethod || !props.readAndAgreed || this.props.uploading}
              />
            </div>
          </div>
        </footer>
      </form>
    );
  }
}

ElectronicVerificationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  uploading: PropTypes.bool,
};

ElectronicVerificationForm.defaultProps = {
  uploading: false,
};

export default reduxForm({
  form: VerificationConstants.ELECTRONIC_VERIFICATION_FORM,
})(
  formValues(
    'idContainsAddress',
    'verificationMethod',
    'readAndAgreed',
    'state'
  )(ElectronicVerificationForm)
);
