/* eslint no-console: "off", no-unused-vars: "off" */
import window from 'window-shim';
import QueryString from 'query-string';
import { isEmpty, pick } from 'lodash';
import deepcopy from 'clone';
import { delay } from 'redux-saga';
import { put, take, takeLatest, call, select, race } from 'redux-saga/effects';
import { SubmissionError, initialize, reset, unregisterField, change } from 'redux-form';

import { RegionsByCountry } from 'escrow-common-js/dist/constants';
import PaymentConstants from 'spa/constants/PaymentConstants';
import UserConstants from 'spa/constants/UserConstants';
import APIConstants from 'spa/constants/APIConstants';
import CheckoutConstants from 'spa/constants/CheckoutConstants';
import VerificationConstants from 'spa/constants/VerificationConstants';
import AuthenticationConstants from 'spa/constants/AuthenticationConstants';
import PhoneCountryCodes from 'spa/constants/PhoneCountryCodes';
import { Alpha2ToAlpha3 } from 'spa/constants/ISOCountryCodes';
import {
  areVerificationDetailsEmptySelector,
  buyerCheckoutDetailsSelector,
  customerCompanyNameSelector,
  customerTaxNumbersSelector,
  custIdSelector,
  customerKyc1VerifiedSelector,
  customerEKycDataSuccess,
  customerHasDocumentsSubmittedSelector,
  customerSavedPlaidAccountsSelector,
  customerSavedCreditCardsSelector,
} from 'spa/selectors/CustomerSelectors';
import {
  draftBuyerDetailsSelector,
  transactionBuyerEmailSelector,
  transactionDraftSelector,
  transactionByIdSelector,
  transactionCCPaymentGatewaySelector,
} from 'spa/selectors/TransactionSelectors';
import { modeSelector } from 'spa/selectors/AuthenticationSelectors';
import {
  checkoutNextStepSelector,
  checkoutPrevStepSelector,
  checkoutStepSelector,
  checkoutPaymentTypeSelector,
  checkoutTokenSelector,
  checkoutTransactionTokenSelector,
  checkoutChangeBuyerEmailLoadingSelector,
  checkoutTransIdSelector,
  checkoutReminderSentSelector,
  checkoutPartnerSuccessSelector,
  checkoutPaymentDetailsSelector,
} from 'spa/selectors/CheckoutSelectors';
import { registeredFieldsSelector } from 'spa/selectors/FormSelectors';
import {
  getUserSuccess,
  getUserFailure,
  verifyTier2AndTier3 as verifyTier2AndTier3Routine,
  setUserHasContactDetails,
  updateIndividualVerificationStatus,
  updateCompanyVerificationStatus,
  fluxGetCustomerRequest,
  setCustomerTaxNumber as setCustomerTaxNumberRoutine,
  getUserEKYCData as getUserEKYCDataRoutine,
} from 'spa/actions/UserActions';
import {
  login as loginRoutine,
  signup as signupRoutine,
  twoFAValidate as twoFAValidateRoutine,
} from 'spa/actions/AuthenticationActions';
import { formLoginSignupErrorHandler } from 'spa/sagas/AuthenticationSagas';
import {
  setStep,
  setReduxCustomerInformation,
  setTransactionId,
  setPaymentDetails,
  setAuthorizedPayment,
  agreeStripePaymentRoutine,
  confirmStripePaymentRoutine,
  saveCustomerDetails as saveCustomerDetailsRoutine,
  submitCustomerDetails as submitCustomerDetailsRoutine,
  authenticateCustomer as authenticateCustomerRoutine,
  showCustomerDetails as showCustomerDetailsRoutine,
  moveToNextStep as moveToNextStepActionCreator,
  loadNextStepData as loadNextStepDataRoutine,
  selectPaymentMethod as usePaymentMethodRoutine,
  agreeAndPay as agreeAndPayRoutine,
  fetchTransactionAndPaymentMethods as fetchTransactionAndPaymentMethodsRoutine,
  returnToLogin as returnToLoginRoutine,
  setPaymentType,
  sendEscrowPayBuyerReminder as sendEscrowPayBuyerReminderRoutine,
  getCheckoutPartnerData as getCheckoutPartnerDataRoutine,
  confirmPaymentMethod as confirmPaymentMethodRoutine,
  agreeWirePayment as agreeWirePaymentRoutine,
  submitReceiveSms as submitReceiveSmsRoutine,
  agreePaymentReview as agreePaymentReviewRoutine,
  submitPaymentDetails as submitPaymentDetailsRoutine,
  getCheckoutLabels as getCheckoutLabelsRoutine,
} from 'spa/actions/CheckoutActions';
import {
  getWireDetails as getWireDetailsRoutine,
  getLastBuyerBankEntry as getLastBuyerBankEntryRoutine,
  getCheckDetails as getCheckDetailsRoutine,
  selectPaymentMethod as selectPaymentMethodRoutine,
  initPlaidClient as initPlaidClientRoutine,
  launchPlaidClient as launchPlaidClientRoutine,
  saveCreditCard as saveCreditCardRoutine,
  setAdyenData,
  setAdyenAction,
} from 'spa/actions/PaymentsActions';
import { adyenFormDataSelector } from 'spa/selectors/PaymentSelectors';
import {
  changeDraftBuyerEmail,
  getPaymentMethods,
  getTransactionById,
  setTransactionDraft,
  changeBuyerEmail,
} from 'spa/actions/TransactionActions';
import { logError } from 'spa/actions/ErrorLoggingActions';

import API from '../../api';
import AuthenticationStore from '../../stores/AuthenticationStore';
import NavigationStore from '../../stores/NavigationStore';
import ErrorMessages from '../../constants/ErrorMessages';
import { mapFormDataToRequestBody } from '../../utils/DataMapping';
import { defaultApiExceptionMessage } from '../../utils/error';
import { gettext } from '../../utils/filters';
import UserActions from '../../actions/UserActions';
import { validateVatNumber } from './UserSaga';
import { urlFor } from '../../routeConfig';

const processRawFormData = (formData, userEmail) => {
  const processedData = deepcopy(formData);

  if (processedData['primary-phone-country'] && processedData['primary-phone-number']) {
    const phonePrefix = PhoneCountryCodes[processedData['primary-phone-country']].countryCode;
    const phoneSuffix = processedData['primary-phone-number'];
    processedData.phone = `+${phonePrefix}${phoneSuffix}`;
    delete processedData['primary-phone-country'];
    delete processedData['primary-phone-number'];
  }

  if (processedData.country) {
    const personalCountry = PhoneCountryCodes[Alpha2ToAlpha3[processedData.country]].name;
    processedData.country = personalCountry;
  }

  if (processedData['company-country']) {
    const companyCountry = PhoneCountryCodes[Alpha2ToAlpha3[processedData['company-country']]].name;
    processedData['company-country'] = companyCountry;
  }

  processedData['is-company'] = Boolean(processedData.company);
  delete processedData.company;

  if (processedData['first-name'] && processedData['last-name']) {
    processedData['display-name'] = `${processedData['first-name']} ${processedData['last-name']}`;
  }

  if (userEmail) {
    processedData.email = userEmail;
  }

  processedData['display-name-type'] = 'name';
  processedData['issue-check-in-company-name'] = false;
  processedData['shipping-address'] = null;
  processedData['use-username-as-display-name'] = false;
  processedData.username = null;

  return processedData;
};

const getCommonAPIErrorMessage = (error) => {
  let errorMessage = ErrorMessages.TECHNICAL_DIFFICULTIES;
  if (error.type === APIConstants.BAD_REQUEST) {
    errorMessage = error.errors.error;
  } else if (error.type === APIConstants.UNAUTHORIZED) {
    errorMessage = 'Your session has expired, please log in again.';
  } else if (error.type === APIConstants.FORBIDDEN) {
    errorMessage = `This action isn't allowed at the current time.
      ${ErrorMessages.ASSISTANT_MESSAGE_FOLLOWED_BY_REQUEST_ID} ${error.requestId}`;
  } else if (error.type === APIConstants.GONE) {
    errorMessage = 'The transaction has already been created and the checkout page is expired.';
  } else if (error.type === APIConstants.UNPROCESSABLE_ENTITY) {
    errorMessage = `An error occurred in the format of the request.
      ${ErrorMessages.ASSISTANT_MESSAGE_FOLLOWED_BY_REQUEST_ID} ${error.requestId}`;
  } else if (error.type === APIConstants.INTERNAL_SERVER_ERROR) {
    errorMessage = error.errors.error;
  } else if (error.type === APIConstants.UNHANDLED) {
    errorMessage = error.errors.error;
  } else if (error.type === 'same-parties') {
    errorMessage = `You must use a different user as the buyer and seller in the transaction`;
  }

  return errorMessage
}

export function* getUser() {
  try {
    const result = yield call(API.getUser);
    yield put(getUserSuccess(result.user));
  } catch (err) {
    if (err.type === APIConstants.UNAUTHORIZED) {
      yield put(returnToLoginRoutine());
    } else {
      yield put(getUserFailure(err.message));
    }
  }
}

function* fetchTransactionAndPaymentMethods(action) {
  const transId = action.payload.transId;
  yield put(fetchTransactionAndPaymentMethodsRoutine.request());
  yield put(getTransactionById({ transId }));
  const { getTransactionFailure } = yield race({
    success: take(getTransactionById.SUCCESS),
    getTransactionFailure: take(getTransactionById.FAILURE),
  });
  if (getTransactionFailure) {
    const error = { type: 'get-transaction-failure' };
    yield put(fetchTransactionAndPaymentMethodsRoutine.failure({ error }));
  } else {
    yield put(getPaymentMethods({ transId }));
    const { getPaymentMethodsFailure } = yield race({
      success: take(getPaymentMethods.SUCCESS),
      getPaymentMethodsFailure: take(getPaymentMethods.FAILURE),
    });
    if (getPaymentMethodsFailure) {
      const error = { type: 'get-payment-methods-failure' };
      yield put(fetchTransactionAndPaymentMethodsRoutine.failure({ error }));
    } else {
      yield put(fetchTransactionAndPaymentMethodsRoutine.success());
    }
  }
  yield put(fetchTransactionAndPaymentMethodsRoutine.fulfill());
}

export function* patchBuyerEmail(action) {
  try {
    const draft = yield select(transactionDraftSelector);
    const parties = draft.parties;
    const partyEmails = parties
      .filter((p) => p.role !== 'partner' && p.role !== 'buyer')
      .map((p) => p.customer);
    const partyEmailSet = new Set(partyEmails);
    const newEmail = action.payload.email;
    if (partyEmailSet.has(newEmail)) {
      const error = {
        type: 'same-parties',
      };
      throw error;
    }
    const draftBuyerEmail = yield call(transactionBuyerEmailSelector, draft);
    const query = QueryString.parse(window.location.search);
    const checkoutToken = query.token || window.js_context.checkout_token;
    let newDraftId;
    if (newEmail !== draftBuyerEmail) {
      const newDraft = yield call(API.patchCheckoutBuyer, checkoutToken, newEmail);
      newDraftId = newDraft.id;
      yield put(setTransactionId(newDraft.id));
      yield put(setTransactionDraft(newDraft));
      if (!action.payload.skipGetTransaction) {
        yield put(fetchTransactionAndPaymentMethodsRoutine.trigger({ transId: newDraft.id }));
        yield race({
          success: take(fetchTransactionAndPaymentMethodsRoutine.SUCCESS),
          failure: take(fetchTransactionAndPaymentMethodsRoutine.FAILURE),
        });
      }
    }
    yield put.resolve(changeDraftBuyerEmail(newEmail));
    yield put(showCustomerDetailsRoutine.success());
    yield put(changeBuyerEmail.success());

    const reminderSent = yield select(checkoutReminderSentSelector);
    if (!reminderSent) {
      let transId = newDraftId;
      if (!transId) {
        transId = yield select(checkoutTransIdSelector);
      }
      yield put(
        sendEscrowPayBuyerReminderRoutine({
          transactionId: transId,
        })
      );
    }
  } catch (error) {
    let errorMessage = '';
    if (error.type === APIConstants.UNAUTHORIZED) {
      yield put(returnToLoginRoutine());
    } else if (error.type === 'same-parties') {
      errorMessage = 'You must use a different user as the buyer and seller in the transaction.';
    }
    if (error.type === 'get-transaction-failure') {
      errorMessage = 'Failed to get transaction.';
    }
    if (error.type === 'get-payment-methods-failure') {
      errorMessage = 'Failed to pull payment information.';
    }
    yield put(showCustomerDetailsRoutine.failure({ errorMessage }));
    yield put(changeBuyerEmail.failure({ errorMessage }));
  }
}

export function* sendEscrowPayBuyerReminderSaga(action) {
  const transactionId = action.payload.transactionId;
  yield put(
    sendEscrowPayBuyerReminderRoutine.request({
      transactionId,
    })
  );

  try {
    yield call(API.sendEscrowPayBuyerReminder, transactionId);
    yield put(
      sendEscrowPayBuyerReminderRoutine.success({
        transactionId,
      })
    );
  } catch (apiError) {
    yield put(
      sendEscrowPayBuyerReminderRoutine.failure({
        transactionId,
        errorMessage: defaultApiExceptionMessage(
          apiError,
          gettext('Failed to send escrow pay buyer reminder email.')
        ),
      })
    );
  }

  yield put(sendEscrowPayBuyerReminderRoutine.fulfill());
}

export function* waitForData() {
  const areVerificationDetailsEmpty = yield select(areVerificationDetailsEmptySelector);
  const isAuthed = AuthenticationStore.isAuthenticated();
  if (isAuthed && areVerificationDetailsEmpty) {
    yield race([take(UserConstants.SET_USER_DETAILS_FROM_V2), delay(20000)]);
  }
  const buyerEmailLoading = yield select(checkoutChangeBuyerEmailLoadingSelector);
  const checkoutTransId = yield select(checkoutTransIdSelector);
  if (isAuthed && (buyerEmailLoading || !checkoutTransId)) {
    yield race([take(changeBuyerEmail.SUCCESS), delay(20000)]);
  }
}

export function* loadNextStepData(action) {
  yield call(waitForData);
  const { nextStep } = action.payload;

  try {
    const ekycDataLoaded = yield select(customerEKycDataSuccess);
    const draft = yield select(transactionDraftSelector);
    const token = yield select(checkoutTokenSelector);
    const ttoken = yield select(checkoutTransactionTokenSelector);

    const hasPartnerData = yield select(checkoutPartnerSuccessSelector);
    if (!hasPartnerData && draft.id && (token || ttoken)) {
      yield put(getCheckoutPartnerDataRoutine.trigger({ token, ttoken, transactionId: draft.id }));
      const { getCheckoutPartnerFailure } = yield race({
        success: take(getCheckoutPartnerDataRoutine.SUCCESS),
        getCheckoutPartnerFailure: take(getCheckoutPartnerDataRoutine.FAILURE),
      });
      if (getCheckoutPartnerFailure) {
        const error = { type: 'get-checkout-partner-failure' };
        yield put(logError(error));
      }
    }

    if (AuthenticationStore.isAuthenticated()) {
      const selectedPaymentType = yield select(checkoutPaymentTypeSelector);

      if (!ekycDataLoaded) {
        yield put(getUserEKYCDataRoutine.trigger());
        yield take(getUserEKYCDataRoutine.SUCCESS);
      }
      const custId = AuthenticationStore.getCustomerID();
      const hasSubmittedDocs = yield select(customerHasDocumentsSubmittedSelector);
      if (hasSubmittedDocs === null) {
        const checkDocSubmission = yield call(API.userHasDocumentsSubmitted, custId);
        yield put(
          updateIndividualVerificationStatus({
            hasDocumentsSubmitted: checkDocSubmission.has_submitted_t2_docs,
          })
        );
      }

      const transactionsById = yield select(transactionByIdSelector, draft.id);
      if (isEmpty(transactionsById)) {
        yield put(getTransactionById({ transId: draft.id }));
        const { getTransactionFailure } = yield race({
          success: take(getTransactionById.SUCCESS),
          getTransactionFailure: take(getTransactionById.FAILURE),
        });
        if (getTransactionFailure) {
          const error = { type: 'get-transaction-failure' };
          throw error;
        }

        if (!transactionsById.paymentMethods) {
          yield put(getPaymentMethods({ transId: draft.id }));
          const { getPaymentMethodsFailure } = yield race({
            success: take(getPaymentMethods.SUCCESS),
            getPaymentMethodsFailure: take(getPaymentMethods.FAILURE),
          });
          if (getPaymentMethodsFailure) {
            const error = { type: 'get-payment-methods-failure' };
            throw error;
          }
        }
      }

      if (nextStep === CheckoutConstants.STEP_CUSTOMER_DETAILS) {
        const kyc1Verified = yield select(customerKyc1VerifiedSelector);
        if (!kyc1Verified && selectedPaymentType === PaymentConstants.PAYMENT_METHODS.PAYPAL) {
          const paymentDetails = yield select(checkoutPaymentDetailsSelector);
          const {
            payer: { name, address },
          } = paymentDetails;
          const state = (
            (RegionsByCountry[address.country_code] || []).find(
              (region) => region.code === address.admin_area_1
            ) || {}
          ).name;
          yield put(
            initialize(
              CheckoutConstants.CUSTOMER_DETAILS_STEP_FORM,
              {
                'first-name': name.given_name,
                'last-name': name.surname,
                country: address.country_code || 'US',
                city: address.admin_area_2,
                state,
                'post-code': address.postal_code,
                'address-line-1': address.address_line_1,
              },
              { updateUnregisteredFields: true }
            )
          );
        }
      } else if (nextStep === CheckoutConstants.STEP_CONFIRMATION) {
        yield put(getWireDetailsRoutine.trigger({ transId: draft.id }));
        yield put(getCheckDetailsRoutine.trigger({ transId: draft.id }));

        if (!selectedPaymentType) {
          yield put(setPaymentType('wire_transfer'));
        }
      }
    }
    yield put(loadNextStepDataRoutine.success());
  } catch (error) {
    yield put(logError(error));
    yield put(loadNextStepDataRoutine.failure());
  }
}

export function* moveToNextStep() {
  yield call(waitForData);

  try {
    const query = QueryString.parse(window.location.search);
    const nextStep = yield select(checkoutNextStepSelector);
    const ekycDataLoaded = yield select(customerEKycDataSuccess);
    const draft = yield select(transactionDraftSelector);
    const token = yield select(checkoutTokenSelector) || query.token;
    const ttoken = query.ttoken;

    const hasPartnerData = yield select(checkoutPartnerSuccessSelector);
    if (!hasPartnerData && draft.id) {
      yield put(getCheckoutPartnerDataRoutine.trigger({ token, ttoken, transactionId: draft.id }));
      const { getCheckoutPartnerFailure } = yield race({
        success: take(getCheckoutPartnerDataRoutine.SUCCESS),
        getCheckoutPartnerFailure: take(getCheckoutPartnerDataRoutine.FAILURE),
      });
      if (getCheckoutPartnerFailure) {
        const error = { type: 'get-checkout-partner-failure' };
        yield put(logError(error));
      }
    }

    if (
      nextStep !== CheckoutConstants.STEP_LOGIN &&
      nextStep !== CheckoutConstants.STEP_SIGNUP &&
      !ekycDataLoaded
    ) {
      yield put(getUserEKYCDataRoutine.trigger());
    }
    if (nextStep === CheckoutConstants.STEP_PAYMENT_METHOD) {
      take(showCustomerDetailsRoutine.SUCCESS);
      yield put(setTransactionId(draft.id));
      yield put(getTransactionById({ transId: draft.id }));
      const { getTransactionFailure } = yield race({
        success: take(getTransactionById.SUCCESS),
        getTransactionFailure: take(getTransactionById.FAILURE),
      });
      if (getTransactionFailure) {
        const error = { type: 'get-transaction-failure' };
        throw error;
      }
      yield put(getPaymentMethods({ transId: draft.id }));
      const { getPaymentMethodsFailure } = yield race({
        success: take(getPaymentMethods.SUCCESS),
        getPaymentMethodsFailure: take(getPaymentMethods.FAILURE),
      });
      if (getPaymentMethodsFailure) {
        const error = { type: 'get-payment-methods-failure' };
        throw error;
      }
      yield put(getWireDetailsRoutine.trigger({ transId: draft.id }));
      yield put(getCheckDetailsRoutine.trigger({ transId: draft.id }));
      const selectedPaymentType = yield select(checkoutPaymentTypeSelector);
      if (!selectedPaymentType) {
        yield put(setPaymentType('wire_transfer'));
      }
      const custId = yield select(custIdSelector);
      yield put(getLastBuyerBankEntryRoutine.trigger({ customerId: custId }));
    }
    yield put(setStep(nextStep));
    yield put(moveToNextStepActionCreator.success());
  } catch (error) {
    yield put(logError(error));
    yield put(moveToNextStepActionCreator.failure());
  }
}

export function* moveToPrevStep() {
  try {
    const prevStep = yield select(checkoutPrevStepSelector);
    yield put(setStep(prevStep));
  } catch (error) {
    yield put(logError(error));
  }
}

export function* setPaypalOrderDetailsSaga(action) {
  yield put(
    setAuthorizedPayment({
      paymentType: PaymentConstants.PAYMENT_METHODS.PAYPAL,
      details: action.payload,
    })
  );
  yield put(setPaymentDetails(action.payload));
}

export function* setStepSaga(action) {
  const step = action.payload;

  if (
    step === CheckoutConstants.STEP_SIGNUP ||
    step === CheckoutConstants.STEP_TIER1_VERIFICATION
  ) {
    // Reinitialise the form
    const draft = yield select(transactionDraftSelector);
    const buyer = (draft.parties || []).find((party) => party.role === 'buyer');
    const buyerCheckoutDetails = yield select(buyerCheckoutDetailsSelector);
    const buyerDraftDetails = yield select(draftBuyerDetailsSelector);
    const buyerDetails = buyerCheckoutDetails || buyerDraftDetails;
    const custTaxNumbers = yield select(customerTaxNumbersSelector);
    const custVatNumbers = (custTaxNumbers || []).filter((n) => n.type === 'vat');
    // remove the alpha-2 code
    const existingVatNumber = custVatNumbers[0] ? custVatNumbers[0].number.slice(2) : undefined;
    const existingVatCountry = custVatNumbers[0] ? custVatNumbers[0].number.slice(0, 2) : undefined;
    const hasTier1Details = yield select(customerKyc1VerifiedSelector);

    yield put(
      initialize(
        CheckoutConstants.CUSTOMER_DETAILS_FORM,
        {
          username:
            buyer && buyer.customer !== CheckoutConstants.BUYER_PLACEHOLDER
              ? buyer.customer || ''
              : '',
          customerDetails: {
            ...buyerDetails,
          },
          vatDetails: {
            vatCountry: existingVatCountry,
            vatNumber: existingVatNumber,
          },
          showCancel: hasTier1Details,
        },
        { updateUnregisteredFields: true }
      )
    );
    yield put(reset(CheckoutConstants.CUSTOMER_DETAILS_FORM));
  }
}

function* handleLoginAndRegister(username, password, signupMode) {
  const formData = { username, password };
  try {
    if (!signupMode) {
      yield put(loginRoutine.trigger(formData));
      const { loginSuccess, loginFailure } = yield race({
        loginSuccess: take(loginRoutine.SUCCESS),
        loginFailure: take(loginRoutine.FAILURE),
      });
      if (loginSuccess) {
        if (!AuthenticationStore.isAuthenticated()) {
          yield take(twoFAValidateRoutine.SUCCESS);
        }
      } else {
        throw loginFailure.payload;
      }
    } else {
      yield put(signupRoutine.trigger(formData));
      const { signupFailure } = yield race({
        signupSuccess: take(signupRoutine.SUCCESS),
        signupFailure: take(signupRoutine.FAILURE),
      });
      if (signupFailure) {
        throw signupFailure.payload;
      }
    }
    yield put(changeBuyerEmail({ email: username, skipGetTransaction: signupMode }));
    yield race([take(changeBuyerEmail.SUCCESS), delay(20000)]);
  } catch (error) {
    if (error.type === APIConstants.UNAUTHORIZED) {
      yield put(returnToLoginRoutine());
    }
    throw formLoginSignupErrorHandler(error);
  }
}

export function* handleTier1Submission(formData) {
  let processedError = null;
  try {
    const customerEmail = AuthenticationStore.getEmail();
    const customerID = AuthenticationStore.getCustomerID();
    if (!formData || !customerEmail || !customerID) {
      throw new Error(ErrorMessages.INCOMPLETE_FORM);
    }

    const processedData = yield call(processRawFormData, formData, customerEmail);
    const customerInformation = yield call(
      mapFormDataToRequestBody,
      CheckoutConstants.apiMapping[CheckoutConstants.MAPPING_CUSTOMER_INFORMATION],
      processedData
    );
    customerInformation.Source = 'checkout';

    yield call(API.updateCustomerDetails, customerID, customerInformation);
    yield put(setReduxCustomerInformation(customerInformation));
    yield put(setUserHasContactDetails(true));
  } catch (error) {
    let errorMessage = ErrorMessages.VERIFY_SUBMISSION_FAIL;
    if (error.type === APIConstants.BAD_REQUEST) {
      errorMessage = error.errors.Message;
    } else if (error.type === APIConstants.UNAUTHORIZED) {
      errorMessage = ErrorMessages.SESSION_EXPIRY;
      yield put(returnToLoginRoutine({ delay: true }));
    } else if (error.type === APIConstants.INTERNAL_SERVER_ERROR) {
      errorMessage = error.errors.error;
    } else if (error.type === APIConstants.UNHANDLED) {
      errorMessage = error.errors.error;
    } else if (error === ErrorMessages.INCOMPLETE_FORM) {
      errorMessage = ErrorMessages.INCOMPLETE_FORM;
    }

    processedError = { _error: errorMessage };
  }

  if (processedError) {
    throw processedError;
  }
}

export function* handleVatDetailsSubmission(vatData, errorSubsection) {
  if (vatData && vatData.vatNumber) {
    const apiRequest = {
      taxType: 'vat',
      taxNumber: `${vatData.vatCountry}${vatData.vatNumber}`,
    };
    yield put(setCustomerTaxNumberRoutine.trigger(apiRequest));

    const { setCustomerTaxFailure } = yield race({
      setCustomerTaxSuccess: take(setCustomerTaxNumberRoutine.SUCCESS),
      setCustomerTaxFailure: take(setCustomerTaxNumberRoutine.FAILURE),
    });

    if (setCustomerTaxFailure) {
      const reduxFormError = {
        [errorSubsection]: {
          vatNumber: setCustomerTaxFailure.payload,
        },
      };
      throw reduxFormError;
    }
  }
}

export function* validateVatNumberForCheckout(vatData, errorSubsection) {
  let processedError = null;
  try {
    if (vatData && vatData.vatNumber) {
      yield call(validateVatNumber, `${vatData.vatCountry}${vatData.vatNumber}`);
    }
  } catch (error) {
    processedError = {
      [errorSubsection]: {
        ...error,
      },
    };
  }

  if (processedError) {
    throw processedError;
  }
}

export function* authenticateCustomer(action) {
  try {
    const { username, password } = action.payload.values;
    const mode = yield select(modeSelector);
    const loginMode = mode === AuthenticationConstants.MODE_LOGIN;
    NavigationStore.setIgnoreMissingCustomerData(true);
    yield call(handleLoginAndRegister, username, password, !loginMode);
    UserActions.getCustomerData(AuthenticationStore.getCustomerID());
    NavigationStore.setIgnoreMissingCustomerData(false);
    const { getCustomerFailure } = yield race({
      getCustomerSuccess: take(fluxGetCustomerRequest.SUCCESS),
      getCustomerFailure: take(fluxGetCustomerRequest.FAILURE),
    });
    if (getCustomerFailure) {
      const error = { _error: APIConstants.GENERIC_API_ERROR_MESSAGE };
      throw new SubmissionError(error);
    }
    yield put(authenticateCustomerRoutine.success());
  } catch (error) {
    const reduxFormError = error;
    yield put(authenticateCustomerRoutine.failure(reduxFormError));
  }
  yield put(authenticateCustomerRoutine.fulfill());
}

export function* handleSaveCustomerDetails(action) {
  try {
    const v4Customer = {};
    const isCompany =
      action.payload.values.accountType === VerificationConstants.ACCOUNT_TYPE_COMPANY;
    const registeredFields = yield select(registeredFieldsSelector, action.payload.props.form);
    const customerData = pick(action.payload.values, [
      ...registeredFields,
      'address-line-1',
      'address-line-2',
      'city',
      'state',
      'post-code',
      'country',
      'company-address-line-1',
      'company-address-line-2',
      'company-city',
      'company-state',
      'company-post-code',
      'company-country',
      'primary-phone-country',
      'primary-phone-number',
    ]);
    v4Customer.address = {
      state: customerData.state,
      country: customerData.country,
    };
    if (customerData['company-country']) {
      v4Customer.company = {
        address: {
          country: customerData['company-country'],
          state: customerData['company-state'],
        },
      };
    }
    customerData.company = isCompany;
    delete customerData.hasAutoCompleted;
    delete customerData.accountType;
    delete customerData.manualAddress;
    delete customerData.manualCompanyAddress;
    delete customerData['complete-address'];
    delete customerData['company-complete-address'];

    yield call(handleTier1Submission, customerData);
    yield call(API.updateCustomerV4, v4Customer);

    const draft = yield select(transactionDraftSelector);
    yield put(getPaymentMethods({ transId: draft.id }));
    yield put(
      change(
        CheckoutConstants.CUSTOMER_DETAILS_STEP_FORM,
        'complete-address',
        [
          customerData['address-line-1'],
          customerData['address-line-2'],
          customerData.city,
          customerData.state,
          customerData['post-code'],
        ]
          .filter((add) => !!add)
          .join(', ')
      )
    );
    yield put(change(CheckoutConstants.CUSTOMER_DETAILS_STEP_FORM, 'hasAutoCompleted', true));
    const { getPaymentMethodsFailure } = yield race({
      success: take(getPaymentMethods.SUCCESS),
      getPaymentMethodsFailure: take(getPaymentMethods.FAILURE),
    });
    if (getPaymentMethodsFailure) {
      const error = { type: 'get-payment-methods-failure' };
      throw error;
    }

    yield put(saveCustomerDetailsRoutine.success());
  } catch (error) {
    const reduxFormError = error;
    yield put(saveCustomerDetailsRoutine.failure(new SubmissionError(reduxFormError)));
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
  yield put(saveCustomerDetailsRoutine.fulfill());
}

export function* handleSubmitCustomerDetails(action) {
  try {
    yield put(unregisterField(action.payload.props.form, 'showCancel'));
    const currentStep = yield select(checkoutStepSelector);
    const initialAuthenticationState = AuthenticationStore.isAuthenticated();
    const registeredFields = yield select(registeredFieldsSelector, action.payload.props.form);
    const customerData = pick(action.payload.values, registeredFields).customerDetails;
    const vatData = pick(action.payload.values, registeredFields).vatDetails;
    const v4Customer = {};
    let vatOrTier1SubmissionError = null;

    // we submit email and password only if customer is intent to login or signup
    if (
      currentStep === CheckoutConstants.STEP_LOGIN ||
      currentStep === CheckoutConstants.STEP_SIGNUP
    ) {
      const { username, password } = action.payload.values;
      NavigationStore.setIgnoreMissingCustomerData(true);
      yield call(validateVatNumberForCheckout, vatData, 'vatDetails');
      yield call(
        handleLoginAndRegister,
        username,
        password,
        currentStep === CheckoutConstants.STEP_SIGNUP
      );
    }

    // now submit the users tier 1 account details
    if (currentStep === CheckoutConstants.STEP_TIER1_VERIFICATION || initialAuthenticationState) {
      v4Customer.address = {
        state: customerData.state,
        country: customerData.country,
      };
      if (customerData['company-country']) {
        v4Customer.company = {
          address: {
            country: customerData['company-country'],
            state: customerData['company-state'],
          },
        };
      }
      try {
        yield call(handleVatDetailsSubmission, vatData, 'vatDetails');
        yield call(handleTier1Submission, customerData);
        const transId = yield select(checkoutTransIdSelector);
        if (transId) {
          yield put(fetchTransactionAndPaymentMethodsRoutine.trigger({ transId }));
          yield race({
            success: take(fetchTransactionAndPaymentMethodsRoutine.SUCCESS),
            failure: take(fetchTransactionAndPaymentMethodsRoutine.FAILURE),
          });
        }
      } catch (e) {
        vatOrTier1SubmissionError = e;
      }
    }

    if (!initialAuthenticationState) {
      UserActions.getCustomerData(AuthenticationStore.getCustomerID());
      NavigationStore.setIgnoreMissingCustomerData(false);
      // wait for get customer to finish
      const { getCustomerFailure } = yield race({
        getCustomerSuccess: take(fluxGetCustomerRequest.SUCCESS),
        getCustomerFailure: take(fluxGetCustomerRequest.FAILURE),
      });
      if (getCustomerFailure) {
        const error = { _error: APIConstants.GENERIC_API_ERROR_MESSAGE };
        throw error;
      }
    }

    if (vatOrTier1SubmissionError) {
      throw vatOrTier1SubmissionError;
    }

    // Store national documents & ISO country/states after customer is created
    try {
      yield call(API.updateCustomerV4, v4Customer);
    } catch (updateCustomerV4Error) {
      const reduxFormError = new SubmissionError(updateCustomerV4Error);
      yield put(submitCustomerDetailsRoutine.failure(new SubmissionError(reduxFormError)));
      return;
    }

    yield put(submitCustomerDetailsRoutine.success());
    // Determine the next step in the checkout page
    yield put(moveToNextStepActionCreator());
  } catch (error) {
    const reduxFormError = error;
    yield put(submitCustomerDetailsRoutine.failure(new SubmissionError(reduxFormError)));
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
  yield put(submitCustomerDetailsRoutine.fulfill());
}

const isApiBodyEmpty = (apiBody) => apiBody.Files.length === 0 && apiBody.TextInputs.length === 0;

const processVerifyKyc2Form = (formData) => {
  const apiBody = {
    Files: [],
    TextInputs: [],
  };

  // Detect if the form contains Tier2 verification
  if (formData.idProofType) {
    apiBody.TextInputs.push({ Key: 'IDType', Value: formData.idProofType });
    if (formData.idContainsAddress) {
      apiBody.TextInputs.push(
        { Key: 'AddressProofType', Value: formData.idProofType },
        { Key: 'IdContainsAddress', Value: 'true' }
      );
    } else {
      apiBody.TextInputs.push(
        { Key: 'AddressProofType', Value: formData.addressProofType },
        { Key: 'IdContainsAddress', Value: 'false' }
      );
    }

    const allFileData = [].concat(formData.idProofFile || [], formData.addressProofFile || []);
    for (const fileData of allFileData) {
      apiBody.Files.push(fileData.fileId);
    }
  }

  return apiBody;
};

const processVerifyKyc3Form = (formData, companyName) => {
  const apiBody = {
    Files: [],
    TextInputs: [],
  };

  // Detect if the form contains Tier3 verification
  if (formData.companyProofType && formData.companyType && formData.companyRelationship) {
    apiBody.TextInputs.push(
      { Key: 'CompanyProofType', Value: formData.companyProofType },
      { Key: 'CompanyName', Value: companyName },
      { Key: 'CompanyType', Value: formData.companyType },
      { Key: 'CompanyRelationship', Value: formData.companyRelationship }
    );

    for (const fileData of formData.companyProofFile || []) {
      apiBody.Files.push(fileData.fileId);
    }
  }

  return apiBody;
};

export function* verifyTier2AndTier3Saga(action) {
  yield put(verifyTier2AndTier3Routine.request());
  try {
    const formData = action.payload.values;
    if (formData.kycType === 'tier2') {
      const tier2VerificationBody = yield call(processVerifyKyc2Form, formData);
      if (!isApiBodyEmpty(tier2VerificationBody)) {
        yield call(API.submitUserKyc, tier2VerificationBody);
        yield put(
          updateIndividualVerificationStatus({
            isSubmitted: true,
            isLocallySubmitted: true,
          })
        );
      }
    }

    if (formData.kycType === 'tier3') {
      const companyName = yield select(customerCompanyNameSelector);
      const tier3VerificationBody = yield call(processVerifyKyc3Form, formData, companyName);
      if (!isApiBodyEmpty(tier3VerificationBody)) {
        yield call(API.submitUserKyc, tier3VerificationBody);
        yield put(
          updateCompanyVerificationStatus({
            isSubmitted: true,
            isLocallySubmitted: true,
          })
        );
      }
    }

    yield put(verifyTier2AndTier3Routine.success());
  } catch (error) {
    if (error.type === APIConstants.UNAUTHORIZED) {
      yield put(returnToLoginRoutine());
    } else {
      const reduxFormError = { _error: ErrorMessages.VERIFY_KYC_FAIL };
      yield put(verifyTier2AndTier3Routine.failure(new SubmissionError(reduxFormError)));
    }
  }
  yield put(verifyTier2AndTier3Routine.fulfill());
}

export function* getV4TransactionByToken(token) {
  let transactionData = {};
  try {
    transactionData = yield call(API.getTransactionByToken, token);
  } catch (error) {
    if (error.type === APIConstants.UNAUTHORIZED) {
      yield put(returnToLoginRoutine());
    } else {
      yield put(logError(error));
    }
  }
  return transactionData;
}

/**
 * Finds transaction ID from the ether
 * 
 * Tries to generate an id by creating a transaction from draft.
 * If that isn't applicable, it'll try to search in the window context,
 * or call a different API to fetch tid by token.
 */
export function* getOrCreateTransactionID() {
  const query = QueryString.parse(window.location.search);
  const checkoutFrom = window.js_context.checkout_from;
  const checkoutToken = query.token || window.js_context.checkout_token;
  const draft = yield select(transactionDraftSelector);

  let transactionId = 0;
  try {
    if (checkoutFrom === 'binb' || checkoutFrom === 'draft') {
      draft.source = checkoutFrom === 'binb' ? 'WWWBINB' : 'EscrowPayDraft';
      const transactionData = yield call(API.upgradeDraftToTransaction, draft, checkoutToken);
      transactionId = transactionData.id;
    } else if (checkoutFrom === 'transaction') {
      transactionId = window.js_context.checkout_data.id || query.tid;
    }
  } catch (error) {
    if (error.type === APIConstants.GONE) {
      const transactionData = yield call(getV4TransactionByToken, checkoutToken);
      transactionId = transactionData.id;
    } else {
      throw error;
    }
  }

  return transactionId;
 }

/**
 * Calls the PaymentMethodsFund endpoint from API,
 * before redirecting the user to the hosted stripe payments page.
 *
 * The payment's status is based on the user's manual confirmation,
 * not from Stripe's confirmation. Similar assumptions with wire transfer.
 */
export function* confirmStripePayment(action) {
  yield put(confirmStripePaymentRoutine.request());

  try {
    const transactionID = yield call(getOrCreateTransactionID);
    yield call(API.setPaymentMethod, transactionID, action.payload.paymentMethod);

    const paymentDetails = yield select(checkoutPaymentDetailsSelector);
    const { stripe: { link } } = paymentDetails;

    yield window.location.assign(link);

    // The "success" state is after redirect
    // yield put(confirmStripePaymentRoutine.success());
  } catch (error) {
    if (error.type === APIConstants.UNAUTHORIZED) {
      yield put(returnToLoginRoutine());
    }
    const errorMessage = getCommonAPIErrorMessage(error);
    yield put(confirmStripePaymentRoutine.failure(errorMessage));
  }
}

export function* confirmPaymentMethod(action) {
  yield put(confirmPaymentMethodRoutine.request());

  try {
    const checkoutFrom = window.js_context.checkout_from;
    const query = QueryString.parse(window.location.search);
    let transactionId = 0;
    let transactionData = {};
    const checkoutToken = query.token || window.js_context.checkout_token;
    const draft = yield select(transactionDraftSelector);
    try {
      if (checkoutFrom === 'binb' || checkoutFrom === 'draft') {
        draft.source = checkoutFrom === 'binb' ? 'WWWBINB' : 'EscrowPayDraft';
        transactionData = yield call(API.upgradeDraftToTransaction, draft, checkoutToken);
        transactionId = transactionData.id;
      } else if (checkoutFrom === 'transaction') {
        transactionId = window.js_context.checkout_data.id || query.tid;
        transactionData = yield call(API.getTransactionById, transactionId);
      }
    } catch (error) {
      if (error.type === APIConstants.GONE) {
        transactionData = yield call(getV4TransactionByToken, checkoutToken);
        transactionId = transactionData.id;
      } else if (error.type === APIConstants.UNAUTHORIZED) {
        yield put(returnToLoginRoutine());
      } else {
        throw error;
      }
    }
    yield call(API.setPaymentMethod, transactionId, action.payload.paymentMethod);

    yield put(confirmPaymentMethodRoutine.success());
  } catch (error) {
    let errorMessage = ErrorMessages.TECHNICAL_DIFFICULTIES;
    if (error.type === APIConstants.BAD_REQUEST) {
      errorMessage = error.errors.error;
    } else if (error.type === APIConstants.UNAUTHORIZED) {
      errorMessage = 'Your session has expired, please log in again.';
      yield put(returnToLoginRoutine());
    } else if (error.type === APIConstants.FORBIDDEN) {
      errorMessage = `This action isn't allowed at the current time.
        ${ErrorMessages.ASSISTANT_MESSAGE_FOLLOWED_BY_REQUEST_ID} ${error.requestId}`;
    } else if (error.type === APIConstants.GONE) {
      errorMessage = 'The transaction has already been created and the checkout page is expired.';
    } else if (error.type === APIConstants.UNPROCESSABLE_ENTITY) {
      errorMessage = `An error occurred in the format of the request.
        ${ErrorMessages.ASSISTANT_MESSAGE_FOLLOWED_BY_REQUEST_ID} ${error.requestId}`;
    } else if (error.type === APIConstants.INTERNAL_SERVER_ERROR) {
      errorMessage = error.errors.error;
    } else if (error.type === APIConstants.UNHANDLED) {
      errorMessage = error.errors.error;
    } else if (error.type === 'same-parties') {
      errorMessage = `You must use a different user as the buyer and seller in the transaction`;
    }

    yield put(confirmPaymentMethodRoutine.failure(errorMessage));
  }

  yield put(confirmPaymentMethodRoutine.fulfill());
}

export function* agreeStripePayment(){
  yield put(agreeStripePaymentRoutine.request());

  try {
    const transactionId = yield call(getOrCreateTransactionID);

    yield call(API.setTransactionStatus, transactionId, 15);

    const { payment_link_url: link } = yield call(API.createStripePaymentLink, transactionId);

    yield put(setPaymentDetails({stripe: { link }}));

    yield put(agreeStripePaymentRoutine.success());
  } catch (error) {
    if (error.type === APIConstants.UNAUTHORIZED) {
      yield put(returnToLoginRoutine());
    }

    const errorMessage = getCommonAPIErrorMessage(error);
    yield put(agreeStripePaymentRoutine.failure(errorMessage));
  }
  yield put(agreeStripePaymentRoutine.fulfill());
}

export function* agreeWirePayment() {
  yield put(agreeWirePaymentRoutine.request());
  const query = QueryString.parse(window.location.search);
  const checkoutFrom = window.js_context.checkout_from;
  const checkoutToken = query.token || window.js_context.checkout_token;
  const draft = yield select(transactionDraftSelector);

  try {
    try {
      if (checkoutFrom === 'binb' || checkoutFrom === 'draft') {
        draft.source = checkoutFrom === 'binb' ? 'WWWBINB' : 'EscrowPayDraft';
        const { id } = yield call(API.upgradeDraftToTransaction, draft, checkoutToken);
        yield call(API.setTransactionStatus, id, 15);
      }
    } catch (error) {
      if (error.type !== APIConstants.GONE) {
        throw error;
      }
    }
    yield put(agreeWirePaymentRoutine.success());
  } catch (error) {
    let errorMessage = ErrorMessages.TECHNICAL_DIFFICULTIES;
    if (error.type === APIConstants.BAD_REQUEST) {
      errorMessage = error.errors.error;
    } else if (error.type === APIConstants.UNAUTHORIZED) {
      errorMessage = 'Your session has expired, please log in again.';
      yield put(returnToLoginRoutine());
    } else if (error.type === APIConstants.FORBIDDEN) {
      errorMessage = `This action isn't allowed at the current time.
        ${ErrorMessages.ASSISTANT_MESSAGE_FOLLOWED_BY_REQUEST_ID} ${error.requestId}`;
    } else if (error.type === APIConstants.UNPROCESSABLE_ENTITY) {
      errorMessage = `An error occurred in the format of the request.
        ${ErrorMessages.ASSISTANT_MESSAGE_FOLLOWED_BY_REQUEST_ID} ${error.requestId}`;
    } else if (error.type === APIConstants.INTERNAL_SERVER_ERROR) {
      errorMessage = error.errors.error;
    } else if (error.type === APIConstants.UNHANDLED) {
      errorMessage = error.errors.error;
    } else if (error.type === 'same-parties') {
      errorMessage = `You must use a different user as the buyer and seller in the transaction`;
    }
    yield put(agreeWirePaymentRoutine.failure(errorMessage));
  }
  yield put(agreeWirePaymentRoutine.fulfill());
}

export function* submitReceiveSms(action) {
  yield put(submitReceiveSmsRoutine.request());

  const { primaryPhoneCountry, primaryPhoneNumber } = action.payload.values;

  const phonePrefix = PhoneCountryCodes[primaryPhoneCountry].countryCode;
  const phoneNumber = `+${phonePrefix}${primaryPhoneNumber}`;

  try {
    const custData = yield call(API.updateCustomerV4, { phone_number: phoneNumber });
    yield put(submitReceiveSmsRoutine.success(custData));
  } catch (error) {
    yield put(submitReceiveSmsRoutine.failure(error));
  }
  yield put(submitReceiveSmsRoutine.fulfill());
}

export function* agreeAndPay() {
  yield put(agreeAndPayRoutine.request());
  const paymentType = yield select(checkoutPaymentTypeSelector);
  const paymentDetails = yield select(checkoutPaymentDetailsSelector);

  try {
    const checkoutFrom = window.js_context.checkout_from;
    const query = QueryString.parse(window.location.search);
    let transactionId = 0;
    let transactionData = {};
    const checkoutToken = query.token || window.js_context.checkout_token;
    const draft = yield select(transactionDraftSelector);
    try {
      if (checkoutFrom === 'binb' || checkoutFrom === 'draft') {
        draft.source = checkoutFrom === 'binb' ? 'WWWBINB' : 'EscrowPayDraft';
        transactionData = yield call(API.upgradeDraftToTransaction, draft, checkoutToken);
        transactionId = transactionData.id;
      } else if (checkoutFrom === 'transaction') {
        transactionId = window.js_context.checkout_data.id || query.tid;
        transactionData = yield call(API.getTransactionById, transactionId);
      }
    } catch (error) {
      if (error.type === APIConstants.GONE) {
        transactionData = yield call(getV4TransactionByToken, checkoutToken);
        transactionId = transactionData.id;
      } else if (error.type === APIConstants.UNAUTHORIZED) {
        yield put(returnToLoginRoutine());
      } else {
        throw error;
      }
    }

    const paymentMethodData = yield call(API.getPaymentMethods, transactionId);
    transactionData.paymentMethods = paymentMethodData;
    if (paymentMethodData.selected_payment_method) {
      yield put(moveToNextStepActionCreator());
    }

    const payload = {
      values: { paymentType, paymentId: paymentDetails.id },
      skipRedirect: true,
      props: {},
      v2Flow: true,
    };

    yield put(selectPaymentMethodRoutine(payload));

    const { failure } = yield race({
      success: take(selectPaymentMethodRoutine.SUCCESS),
      failure: take(selectPaymentMethodRoutine.FAILURE),
    });
    if (failure) {
      throw failure;
    }
    yield put(agreeAndPayRoutine.success());
  } catch (error) {
    let errorMessage = ErrorMessages.TECHNICAL_DIFFICULTIES;
    if (error.type === APIConstants.BAD_REQUEST) {
      errorMessage = error.errors.error;
    } else if (error.type === APIConstants.UNAUTHORIZED) {
      errorMessage = 'Your session has expired, please log in again.';
      yield put(returnToLoginRoutine());
    } else if (error.type === APIConstants.FORBIDDEN) {
      errorMessage = `This action isn't allowed at the current time.
        ${ErrorMessages.ASSISTANT_MESSAGE_FOLLOWED_BY_REQUEST_ID} ${error.requestId}`;
    } else if (error.type === APIConstants.GONE) {
      errorMessage = 'The transaction has already been created and the checkout page is expired.';
    } else if (error.type === APIConstants.UNPROCESSABLE_ENTITY) {
      errorMessage = `An error occurred in the format of the request.
        ${ErrorMessages.ASSISTANT_MESSAGE_FOLLOWED_BY_REQUEST_ID} ${error.requestId}`;
    } else if (error.type === APIConstants.INTERNAL_SERVER_ERROR) {
      errorMessage = error.errors.error;
    } else if (error.type === APIConstants.UNHANDLED) {
      errorMessage = error.errors.error;
    } else if (error.type === 'same-parties') {
      errorMessage = `You must use a different user as the buyer and seller in the transaction`;
    } else if (error.type === selectPaymentMethodRoutine.FAILURE) {
      errorMessage = error.payload;
    }

    yield put(agreeAndPayRoutine.failure(errorMessage));
  }

  yield put(agreeAndPayRoutine.fulfill());
}

export function* agreeAndSelectPaymentMethod(action) {
  yield put(usePaymentMethodRoutine.request());

  try {
    const checkoutFrom = window.js_context.checkout_from;
    const query = QueryString.parse(window.location.search);
    let transactionId = 0;
    let transactionData = {};
    const checkoutToken = query.token || window.js_context.checkout_token;
    const draft = yield select(transactionDraftSelector);
    try {
      if (checkoutFrom === 'binb' || checkoutFrom === 'draft') {
        draft.source = checkoutFrom === 'binb' ? 'WWWBINB' : 'EscrowPayDraft';
        transactionData = yield call(API.upgradeDraftToTransaction, draft, checkoutToken);
        transactionId = transactionData.id;
      } else if (checkoutFrom === 'transaction') {
        transactionId = window.js_context.checkout_data.id || query.tid;
        transactionData = yield call(API.getTransactionById, transactionId);
      }
    } catch (error) {
      if (error.type === APIConstants.GONE) {
        transactionData = yield call(getV4TransactionByToken, checkoutToken);
        transactionId = transactionData.id;
      } else if (error.type === APIConstants.UNAUTHORIZED) {
        yield put(returnToLoginRoutine());
      } else {
        throw error;
      }
    }

    const paymentMethodData = yield call(API.getPaymentMethods, transactionId);
    transactionData.paymentMethods = paymentMethodData;
    if (paymentMethodData.selected_payment_method) {
      yield put(moveToNextStepActionCreator());
    }

    if (
      !draft.redirect_type ||
      draft.redirect_type === CheckoutConstants.REDIRECT_TYPES.automatic ||
      action.payload.values.paymentType === PaymentConstants.PAYMENT_METHODS.PAYPAL
    ) {
      yield put(selectPaymentMethodRoutine(action.payload));
    } else {
      yield put(selectPaymentMethodRoutine({ ...action.payload, skipRedirect: true }));
    }

    const { failure } = yield race({
      success: take(selectPaymentMethodRoutine.SUCCESS),
      failure: take(selectPaymentMethodRoutine.FAILURE),
    });
    if (failure) {
      throw failure;
    }
    yield put(usePaymentMethodRoutine.success());
  } catch (error) {
    let errorMessage = ErrorMessages.TECHNICAL_DIFFICULTIES;
    if (error.type === APIConstants.BAD_REQUEST) {
      errorMessage = error.errors.error;
    } else if (error.type === APIConstants.UNAUTHORIZED) {
      errorMessage = 'Your session has expired, please log in again.';
      yield put(returnToLoginRoutine());
    } else if (error.type === APIConstants.FORBIDDEN) {
      errorMessage = `This action isn't allowed at the current time.
        ${ErrorMessages.ASSISTANT_MESSAGE_FOLLOWED_BY_REQUEST_ID} ${error.requestId}`;
    } else if (error.type === APIConstants.GONE) {
      errorMessage = 'The transaction has already been created and the checkout page is expired.';
    } else if (error.type === APIConstants.UNPROCESSABLE_ENTITY) {
      errorMessage = `An error occurred in the format of the request.
        ${ErrorMessages.ASSISTANT_MESSAGE_FOLLOWED_BY_REQUEST_ID} ${error.requestId}`;
    } else if (error.type === APIConstants.INTERNAL_SERVER_ERROR) {
      errorMessage = error.errors.error;
    } else if (error.type === APIConstants.UNHANDLED) {
      errorMessage = error.errors.error;
    } else if (error.type === 'same-parties') {
      errorMessage = `You must use a different user as the buyer and seller in the transaction`;
    } else if (error.type === selectPaymentMethodRoutine.FAILURE) {
      errorMessage = error.payload;
    }

    yield put(usePaymentMethodRoutine.failure(errorMessage));
  }

  yield put(usePaymentMethodRoutine.fulfill());
}

export function* agreePaymentReview() {
  const { auth } = QueryString.parse(window.location.search);
  yield put(agreePaymentReviewRoutine.request());
  const paymentType = yield select(checkoutPaymentTypeSelector);
  let errorMessage;
  switch (paymentType) {
    case PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER: {
      yield put(agreeWirePaymentRoutine());
      const { success, fail } = yield race({
        success: take(agreeWirePaymentRoutine.SUCCESS),
        fail: take(agreeWirePaymentRoutine.FAILURE),
      });
      if (fail) {
        errorMessage = fail.payload;
      }
      if (success && auth) {
        const transId = yield select(checkoutTransIdSelector);
        yield call(API.sendBuyerCreatePasswordEmail, transId);
      }
      break;
    }
    case PaymentConstants.PAYMENT_METHODS.CREDIT_CARD: {
      yield put(agreeStripePaymentRoutine());
      const { success, fail } = yield race({
        success: take(agreeStripePaymentRoutine.SUCCESS),
        fail: take(agreeStripePaymentRoutine.FAILURE),
      });
      if (fail) {
        errorMessage = fail.payload;
      }
      if (success && auth) {
        const transId = yield select(checkoutTransIdSelector);
        yield call(API.sendBuyerCreatePasswordEmail, transId);
      }
      break;
    }
    default: {
      yield put(agreeAndPayRoutine());
      const { success, fail } = yield race({
        success: take(agreeAndPayRoutine.SUCCESS),
        fail: take(agreeAndPayRoutine.FAILURE),
      });
      if (fail) {
        errorMessage = fail.payload;
      }
      if (success && auth) {
        const transId = yield select(checkoutTransIdSelector);
        yield call(API.sendBuyerCreatePasswordEmail, transId);
      }
    }
  }

  if (errorMessage) {
    const reduxFormError = {
      _error: errorMessage,
    };
    yield put(agreePaymentReviewRoutine.failure(new SubmissionError(reduxFormError)));
  } else {
    yield put(agreePaymentReviewRoutine.success());
  }
  yield put(agreePaymentReviewRoutine.fulfill());
}

export function* returnToLogin(action) {
  const token = yield select(checkoutTokenSelector);
  const delayLogin = action.payload && action.payload.delay;
  if (token) {
    setTimeout(
      () => {
        window.location.assign(`${urlFor('checkout_page_default')}?token=${token}`);
      },
      delayLogin ? 3000 : 0
    );
  } else {
    setTimeout(
      () => {
        window.location.assign(`${window.config.www_base_url}/login-page`);
      },
      delayLogin ? 3000 : 0
    );
  }
}

export function* getCheckoutPartnerDataSaga(action) {
  const { token, ttoken, transactionId } = action.payload;
  yield put(getCheckoutPartnerDataRoutine.request());
  try {
    const partnerData = yield call(API.getCheckoutPartner, token, ttoken, transactionId);
    if (partnerData) yield put(getCheckoutPartnerDataRoutine.success(partnerData.partner));
  } catch (error) {
    yield put(getCheckoutPartnerDataRoutine.failure(error));
    yield put(logError(error));
  }
}

export function* submitPaymentDetails(action) {
  const { accountId, newCreditCardDetails } = action.payload.values;
  const paymentType = yield select(checkoutPaymentTypeSelector);
  const custId = yield select(custIdSelector);
  try {
    switch (paymentType) {
      case PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER:
        break;
      case PaymentConstants.PAYMENT_METHODS.CREDIT_CARD:
      // Temporarily removing for Stripe Payment Link
      // 
      //   const transactionId = yield select(checkoutTransIdSelector);
      //   const paymentGateway = yield select(transactionCCPaymentGatewaySelector, transactionId);
      //   if (paymentGateway === PaymentConstants.CREDIT_CARD_GATEWAY.ADYEN) {
      //     const transaction = yield select(transactionByIdSelector, transactionId);
      //     const adyenData = yield select(adyenFormDataSelector);
      //     const currentUrlParams = QueryString.parse(window.location.search);
      //     const checkoutToken = currentUrlParams.token || window.js_context.checkout_token;
      //     const returnUrlParams = new URLSearchParams({
      //       ...currentUrlParams,
      //       ...(checkoutToken && { token: checkoutToken }),
      //       continue: PaymentConstants.PAYMENT_METHODS.CREDIT_CARD,
      //     });
      //     const returnUrl = `${location.pathname}?${returnUrlParams.toString()}`;
      //     const {
      //       available_payment_methods: availablePaymentMethods = [],
      //       conditionally_available_payment_methods: conditionalPaymentMethods = [],
      //     } = transaction.paymentMethods || {};
      //     const { total: amount } = [...availablePaymentMethods, ...conditionalPaymentMethods].find(
      //       ({ type }) => type === 'credit_card'
      //     );

      //     let response = {};
      //     let _error;
      //     try {
      //       response = yield call(
      //         API.authenticateAdyenPayment,
      //         transactionId,
      //         custId,
      //         amount,
      //         adyenData,
      //         returnUrl
      //       );
      //       const { resultCode, refusalReason, action: additionalAction } = response;
      //       if (additionalAction) {
      //         yield put(setAdyenAction({ action: additionalAction }));
      //         yield put(setAdyenData({ transactionId, adyenData: response }));
      //         return;
      //       }
      //       const { additionalData: { cardSummary, paymentMethod } = {} } = response;

      //       switch (resultCode) {
      //         case 'AuthenticationNotRequired':
      //           yield put(
      //             setPaymentDetails({
      //               last_four_digits: cardSummary,
      //               brand: paymentMethod,
      //             })
      //           );
      //           break;
      //         case 'Error':
      //           _error = `The following error occured while processing your payment: ${refusalReason}`;
      //           break;
      //         default:
      //           _error = 'An unexpected error occurred.  Please contact support@escrow.com';
      //       }
      //     } catch (error) {
      //       if (error.type === APIConstants.BAD_REQUEST) {
      //         _error = `We currently do not support the card type you have chosen. Please see ${window.config.www_base_url}/learn-more/how-escrow-payments-work/credit-card for more information.`;
      //       } else throw error;
      //     }
      //     if (_error) {
      //       const reduxFormError = { _error };
      //       throw reduxFormError;
      //     }
      //     break;
      //   }
      //   if (!!accountId && accountId !== 'new') {
      //     const savedCreditCards = yield select(customerSavedCreditCardsSelector);
      //     yield put(
      //       setPaymentDetails(
      //         savedCreditCards.find((card) => card.id.toString() === accountId.toString())
      //       )
      //     );
      //   } else {
      //     yield put(
      //       saveCreditCardRoutine.trigger({
      //         customerID: custId,
      //         formData: {
      //           ...newCreditCardDetails,
      //           billTo: 'personal',
      //         },
      //       })
      //     );
      //     const { success, failure } = yield race({
      //       success: take(saveCreditCardRoutine.SUCCESS),
      //       failure: take(saveCreditCardRoutine.FAILURE),
      //     });
      //     if (failure) {
      //       throw failure;
      //     }
      //     yield put(setPaymentDetails(success.payload));
      //   }
      //   break;
      // }
        break;
      case PaymentConstants.PAYMENT_METHODS.PAYPAL:
        break;
      case PaymentConstants.PAYMENT_METHODS.DIRECT_DEBIT: {
        if (!!accountId && accountId !== 'new') {
          const { valid: isValidAccount } = yield call(API.checkPlaidAccountValidity, accountId);
          if (isValidAccount) {
            const savedPlaidAccounts = yield select(customerSavedPlaidAccountsSelector);
            setPaymentDetails(
              savedPlaidAccounts.find((account) => account.id.toString() === accountId.toString())
            );
          } else {
            yield put(initPlaidClientRoutine.trigger({ accountId }));
            const { initPlaidSuccess, initPlaidFailure } = yield race({
              initPlaidSuccess: take(initPlaidClientRoutine.SUCCESS),
              initPlaidFailure: take(initPlaidClientRoutine.FAILURE),
            });
            if (initPlaidFailure) {
              throw initPlaidFailure;
            }
            if (initPlaidSuccess) {
              yield put(launchPlaidClientRoutine.trigger());
              const { launchPlaidSuccess, launchPlaidFailure } = yield race({
                launchPlaidSuccess: take(launchPlaidClientRoutine.SUCCESS),
                launchPlaidFailure: take(launchPlaidClientRoutine.FAILURE),
              });
              if (launchPlaidFailure) {
                throw launchPlaidFailure;
              }
              if (launchPlaidSuccess) {
                const savedPlaidAccounts = yield select(customerSavedPlaidAccountsSelector);
                setPaymentDetails(
                  savedPlaidAccounts.find(
                    (account) => account.id.toString() === accountId.toString()
                  )
                );
              }
            }
          }
        } else {
          yield put(launchPlaidClientRoutine.trigger());
          const { launchPlaidFailure } = yield race({
            launchPlaidSuccess: take(launchPlaidClientRoutine.SUCCESS),
            launchPlaidFailure: take(launchPlaidClientRoutine.FAILURE),
          });
          if (launchPlaidFailure) {
            throw launchPlaidFailure;
          }
        }
        break;
      }
      default:
        break;
    }
    yield put(submitPaymentDetailsRoutine.success());
  } catch (err) {
    const reduxError = { _error: err._error || ErrorMessages.TECHNICAL_DIFFICULTIES };
    if (err.type === saveCreditCardRoutine.FAILURE) {
      reduxError._error = err.payload;
    }
    if (
      err.type === launchPlaidClientRoutine.FAILURE &&
      err.payload &&
      err.payload.display_message
    ) {
      reduxError._error = err.payload.display_message;
    }
    yield put(submitPaymentDetailsRoutine.failure(new SubmissionError(reduxError)));
  }
  yield put(submitPaymentDetailsRoutine.fulfill());
}

export function* getCheckoutLabels() {
  const transId = yield select(checkoutTransIdSelector);
  const token = yield select(checkoutTokenSelector);
  yield put(getCheckoutLabelsRoutine.request());
  try {
    const flags = yield call(API.getCheckoutLabels, transId, token);
    if (flags) yield put(getCheckoutLabelsRoutine.success(flags));
  } catch (error) {
    yield put(getCheckoutLabelsRoutine.failure(error));
    yield put(logError(error));
  }
}

export function* checkoutWatcher() {
  yield takeLatest(UserConstants.GET_USER_REQUEST, getUser);
  yield takeLatest(submitCustomerDetailsRoutine.TRIGGER, handleSubmitCustomerDetails);
  yield takeLatest(saveCustomerDetailsRoutine.TRIGGER, handleSaveCustomerDetails);
  yield takeLatest(authenticateCustomerRoutine.TRIGGER, authenticateCustomer);
  yield takeLatest(verifyTier2AndTier3Routine.TRIGGER, verifyTier2AndTier3Saga);
  yield takeLatest(moveToNextStepActionCreator.TRIGGER, moveToNextStep);
  yield takeLatest(loadNextStepDataRoutine.TRIGGER, loadNextStepData);
  yield takeLatest(CheckoutConstants.ACTION_MOVE_TO_PREV_STEP, moveToPrevStep);
  yield takeLatest(changeBuyerEmail.TRIGGER, patchBuyerEmail);
  yield takeLatest(usePaymentMethodRoutine.TRIGGER, agreeAndSelectPaymentMethod);
  yield takeLatest(agreeAndPayRoutine.TRIGGER, agreeAndPay);
  yield takeLatest(returnToLoginRoutine.TRIGGER, returnToLogin);
  yield takeLatest(CheckoutConstants.ACTION_SET_STEP, setStepSaga);
  yield takeLatest(CheckoutConstants.SET_PAYPAL_ORDER_DETAILS, setPaypalOrderDetailsSaga);
  yield takeLatest(
    fetchTransactionAndPaymentMethodsRoutine.TRIGGER,
    fetchTransactionAndPaymentMethods
  );
  yield takeLatest(sendEscrowPayBuyerReminderRoutine.TRIGGER, sendEscrowPayBuyerReminderSaga);
  yield takeLatest(getCheckoutPartnerDataRoutine.TRIGGER, getCheckoutPartnerDataSaga);
  yield takeLatest(confirmPaymentMethodRoutine.TRIGGER, confirmPaymentMethod);
  yield takeLatest(agreeWirePaymentRoutine.TRIGGER, agreeWirePayment);
  yield takeLatest(submitReceiveSmsRoutine.TRIGGER, submitReceiveSms);
  yield takeLatest(agreePaymentReviewRoutine.TRIGGER, agreePaymentReview);
  yield takeLatest(submitPaymentDetailsRoutine.TRIGGER, submitPaymentDetails);
  yield takeLatest(getCheckoutLabelsRoutine.TRIGGER, getCheckoutLabels);
  yield takeLatest(agreeStripePaymentRoutine.TRIGGER, agreeStripePayment);
  yield takeLatest(confirmStripePaymentRoutine.TRIGGER, confirmStripePayment);
}

export default [checkoutWatcher];
