import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Code } from 'spa/components/Code';
import AccentedText from 'spa/components/AccentedText';
import PrimaryLink from 'spa/components/PrimaryLink';
import { Section, SectionTitle, SectionDesc, SectionSubTitle } from 'spa/components/Section';
import HintBanner from 'spa/components/HintBanner';
import { urlFor, envDomainName } from '../../../routeConfig';

const CreateOffer = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktopSmall-9">
        <h1 className="integrationsPortal-title">Escrow Offer API :: Create Offer</h1>
        <Section>
          <SectionTitle>Making an offer on an auction</SectionTitle>
          <SectionDesc>
            Escrow Offer API has 2 endpoints for making offers on an auction: using the token or the
            auction id.
          </SectionDesc>
          <HintBanner
            className="integrationsPortal-hintBanner"
            title="Note"
            desc="Offers will automatically expire in 14 days if they are not accepted."
            icon="ui-alert-circle"
          />
          <SectionSubTitle>Making offer using token</SectionSubTitle>
          <SectionDesc>
            Use this endpoint to make an offer as user specified in the{' '}
            <AccentedText>buyer_email</AccentedText> attribute.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.`+envDomainName+`/integration/2018-08-01/auction/2d2afb9f-364f-4f9f-82a5-803344d60432/offer" \\
    -X POST \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
    -d '
    {
        "no_fee_amount": "500.50",
        "buyer_email": "your email",
        "note": "I really want it?"
    }'
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.post(
    'https://api.`+envDomainName+`/integration/2018-08-01/auction/2d2afb9f-364f-4f9f-82a5-803344d60432/offer',
    auth=('email address', 'api-key'),
    json={
        "no_fee_amount": "500.50",
        "buyer_email": "your email",
        "note": "I really want it?",
    }
)
`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.`+envDomainName+`/integration/2018-08-01/auction/2d2afb9f-364f-4f9f-82a5-803344d60432/offer")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Post.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
  request.body = {
      "no_fee_amount": "500.50",
      "buyer_email": "your email",
      "note": "I really want it?"
    }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.`+envDomainName+`/integration/2018-08-01/auction/2d2afb9f-364f-4f9f-82a5-803344d60432/offer',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'no_fee_amount' => '500.50',
            'buyer_email' => 'your email',
            'note' => 'I really want it?'
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                no_fee_amount = "500.50",
                buyer_email = "your email",
                note = "I really want it?",
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.`+envDomainName+`/integration/2018-08-01/auction/2d2afb9f-364f-4f9f-82a5-803344d60432/offer"),
                Method = HttpMethod.Post,
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
          <SectionSubTitle>Adjust offer using auction id</SectionSubTitle>
          <SectionDesc>
            Use this endpoint to adjust an offer or make a counter offer as the authenticated user
            using the <AccentedText>transaction_id</AccentedText> obtained from the previous
            endpoint.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.`+envDomainName+`/integration/2018-08-01/auction/123123/offer" \\
    -X POST \\
    -H "Content-Type: application/json" \\
    -d '
    {
        "no_fee_amount": "500.50",
        "transaction_id": 12345,
        "note": "I really want it?"
    }'
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.post(
    'https://api.`+envDomainName+`/integration/2018-08-01/auction/123123/offer',
    json={
        "no_fee_amount": "500.50",
        "transaction_id": 12345,
        "note": "I really want it?",
    }
)
`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.`+envDomainName+`/integration/2018-08-01/auction/123123/offer")
http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Post.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.body = {
      "no_fee_amount": "500.50",
      "transaction_id": 12345,
      "note": "I really want it?"
    }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.`+envDomainName+`/integration/2018-08-01/auction/123123/offer',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'no_fee_amount' => '500.50',
            'transaction_id': 12345,
            'note' => 'I really want it?'
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                no_fee_amount = "500.50",
                transaction_id = "12345",
                note = "I really want it?",
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.`+envDomainName+`/integration/2018-08-01/auction/123123/offer"),
                Method = HttpMethod.Post,
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
          <SectionSubTitle>Example Response</SectionSubTitle>
          <Code language="json">
            {`{
    "auction_id": 123123,
    "by_party": {
        "customer": "lois.dee@nomina.tor",
        "display_name": "Lois D. Nominator",
        "id": 444444,
        "role": "buyer"
    },
    "date_closed": null,
    "date_created": "2019-05-09T07:49:48.532617+00:00",
    "expiry_period": 1209600,
    "id": 12014,
    "no_fee_amount": "500.50",
    "note": "I really want it?",
    "status": {
        "accepted": false,
        "canceled": false,
        "rejected": false
    },
    "transaction_id": 1464147
}`}
          </Code>
        </Section>
        <footer className="integrationsPortal-footer">
          <h3 className="integrationsPortal-footer-title">{'Relevant Resources'}</h3>
          <nav className="integrationsPortal-footer-steps">
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="API Basics"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_basics'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Creating auctions"
                link={{
                  type: 'app',
                  route: urlFor('escrow_offer_docs'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Webhooks"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_webhooks'),
                }}
              />
            </div>
          </nav>
        </footer>
      </div>
    </div>
  </div>
);

export default CreateOffer;
