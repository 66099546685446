import QueryString from 'query-string';
import {
  transactionDraftSelector,
  draftBuyerRequiresKycSelector,
  transactionOrDraftSelector,
} from './TransactionSelectors';
import {
  customerKyc1VerifiedSelector,
  customerKyc2ApprovedSelector,
  customerKyc3ApprovedSelector,
  customerIsCompanySelector,
} from './CustomerSelectors';
import CheckoutConstants from '../constants/CheckoutConstants';
import AuthenticationStore from '../.././stores/AuthenticationStore';

const checkoutSelector = (state) => state.checkout;

const checkoutTransIdSelector = (state) => checkoutSelector(state).transId;

const checkoutPartnerSelector = (state) => checkoutSelector(state).partner;
const checkoutPartnerLoadingSelector = (state) => checkoutPartnerSelector(state).loading;
const checkoutPartnerSuccessSelector = (state) => checkoutPartnerSelector(state).success;
const checkoutPartnerDataSelector = (state) => checkoutPartnerSelector(state).partnerData;

const checkoutLabelsSelector = (state) => checkoutSelector(state).labels;
const checkoutLabelsLoadingSelector = (state) => checkoutLabelsSelector(state).loading;
const checkoutLabelsSuccessSelector = (state) => checkoutLabelsSelector(state).success;
const checkoutLabelsDataSelector = (state) => checkoutLabelsSelector(state).labelData;

const checkoutReminderSentSelector = (state) => checkoutSelector(state).reminderSent;

const checkoutStepSelector = (state) => checkoutSelector(state).step;

const checkoutHeadSelector = (state) => checkoutSelector(state).head;

const checkoutPaymentTypeSelector = (state) => checkoutSelector(state).paymentType;
const checkoutPaymentDetailsSelector = (state) => checkoutSelector(state).paymentDetails;
const checkoutAuthorizedPayments = (state) => checkoutSelector(state).authorizedPayments;

const checkoutNextStepSelector = (state) => {
  const kyc1 = customerKyc1VerifiedSelector(state);
  const buyerHasAccount = transactionDraftSelector(state).does_buyer_email_exist || false;
  const buyerDoneKyc =
    customerKyc2ApprovedSelector(state) &&
    (!customerIsCompanySelector(state) || customerKyc3ApprovedSelector(state));

  // Derive next step according to current step
  const currentStep = checkoutStepSelector(state);
  let nextStep = currentStep;

  switch (currentStep) {
    case CheckoutConstants.STEP_INIT:
      if (!AuthenticationStore.isAuthenticated() && buyerHasAccount) {
        nextStep = CheckoutConstants.STEP_LOGIN;
      } else if (!AuthenticationStore.isAuthenticated() && !buyerHasAccount) {
        nextStep = CheckoutConstants.STEP_SIGNUP;
      } else if (
        kyc1 &&
        (!draftBuyerRequiresKycSelector(transactionOrDraftSelector(state)) || buyerDoneKyc)
      ) {
        nextStep = CheckoutConstants.STEP_PAYMENT_METHOD;
      } else if (!kyc1) {
        nextStep = CheckoutConstants.STEP_TIER1_VERIFICATION;
      } else {
        nextStep = CheckoutConstants.STEP_VIEW_DETAILS;
      }
      break;

    case CheckoutConstants.STEP_LOGIN:
      if (AuthenticationStore.isAuthenticated()) {
        if (
          kyc1 &&
          (!draftBuyerRequiresKycSelector(transactionOrDraftSelector(state)) || buyerDoneKyc)
        ) {
          nextStep = CheckoutConstants.STEP_PAYMENT_METHOD;
        } else if (!kyc1) {
          nextStep = CheckoutConstants.STEP_TIER1_VERIFICATION;
        } else {
          nextStep = CheckoutConstants.STEP_VIEW_DETAILS;
        }
      }
      break;

    case CheckoutConstants.STEP_SIGNUP:
      if (AuthenticationStore.isAuthenticated()) {
        nextStep = CheckoutConstants.STEP_TIER1_VERIFICATION;
      }
      break;

    case CheckoutConstants.STEP_TIER1_VERIFICATION:
      if (kyc1) {
        nextStep = CheckoutConstants.STEP_VIEW_DETAILS;
      }
      break;

    case CheckoutConstants.STEP_VIEW_DETAILS:
      if (draftBuyerRequiresKycSelector(transactionOrDraftSelector(state))) {
        nextStep = CheckoutConstants.STEP_TIER2_TIER3_VERIFICATION;
      } else if (kyc1 || buyerDoneKyc) {
        nextStep = CheckoutConstants.STEP_PAYMENT_METHOD;
      }
      break;

    case CheckoutConstants.STEP_TIER2_TIER3_VERIFICATION:
      nextStep = CheckoutConstants.STEP_PAYMENT_METHOD;
      break;

    case CheckoutConstants.STEP_PAYMENT_METHOD:
      nextStep = CheckoutConstants.STEP_FINAL;
      break;

    default:
      nextStep = currentStep;
      break;
  }

  return nextStep;
};

const checkoutPrevStepSelector = (state) => {
  const currentStep = checkoutStepSelector(state);
  let prevStep = currentStep;

  switch (currentStep) {
    case CheckoutConstants.STEP_TIER1_VERIFICATION:
      prevStep = CheckoutConstants.STEP_VIEW_DETAILS;
      break;

    case CheckoutConstants.STEP_TIER2_TIER3_VERIFICATION:
      prevStep = CheckoutConstants.STEP_VIEW_DETAILS;
      break;

    case CheckoutConstants.STEP_PAYMENT_METHOD:
      prevStep = CheckoutConstants.STEP_TIER2_TIER3_VERIFICATION;
      break;

    default:
      prevStep = currentStep;
      break;
  }

  return prevStep;
};

const checkoutUiSelector = (state) => checkoutSelector(state).ui;

const checkoutTokenSelector = () =>
  QueryString.parse(window.location.search).token || (window.js_context || {}).checkout_token;

const checkoutTransactionTokenSelector = () => QueryString.parse(window.location.search).ttoken;
const checkoutTransactionIdSelector = () => QueryString.parse(window.location.search).tid;

const checkoutChangeBuyerEmailSelector = (state) => checkoutUiSelector(state).changeBuyerEmail;

const checkoutChangeBuyerEmailLoadingSelector = (state) =>
  checkoutChangeBuyerEmailSelector(state).loading;

const checkoutNextStepLoadingSelector = (state) => checkoutUiSelector(state).moveToNextStep.loading;

const checkoutLoadingNextStepDataSelector = (state) =>
  checkoutUiSelector(state).loadNextStepData.loading;

const checkoutGetWireDetailsSelector = (state) => checkoutUiSelector(state).getWireDetails;

const checkoutConfirmPaymentMethodSelector = (state) =>
  checkoutUiSelector(state).confirmPaymentMethod;

const checkoutConfirmStripePaymentSelector = (state) => 
  checkoutUiSelector(state).confirmStripePayment;

const checkoutSubmitReceiveSmsSelector = (state) => checkoutUiSelector(state).submitReceiveSms;

const checkoutAgreePaymentReviewSelector = (state) => checkoutUiSelector(state).agreePaymentReview;

export {
  checkoutStepSelector,
  checkoutHeadSelector,
  checkoutNextStepSelector,
  checkoutPrevStepSelector,
  checkoutLoadingNextStepDataSelector,
  checkoutTransIdSelector,
  checkoutPartnerLoadingSelector,
  checkoutPartnerSuccessSelector,
  checkoutPartnerDataSelector,
  checkoutLabelsLoadingSelector,
  checkoutLabelsSuccessSelector,
  checkoutLabelsDataSelector,
  checkoutUiSelector,
  checkoutPaymentTypeSelector,
  checkoutTokenSelector,
  checkoutTransactionIdSelector,
  checkoutTransactionTokenSelector,
  checkoutChangeBuyerEmailLoadingSelector,
  checkoutNextStepLoadingSelector,
  checkoutReminderSentSelector,
  checkoutGetWireDetailsSelector,
  checkoutConfirmPaymentMethodSelector,
  checkoutConfirmStripePaymentSelector,
  checkoutSubmitReceiveSmsSelector,
  checkoutPaymentDetailsSelector,
  checkoutAgreePaymentReviewSelector,
  checkoutAuthorizedPayments,
};
