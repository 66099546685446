import deepcopy from 'clone';
import validator from 'validator';
import _ from 'lodash';
import localStorage from 'local-storage';

import AppDispatcher from '../dispatcher/AppDispatcher';
import { ChangeEmitter } from '../utils/ChangeEmitter';
import CalculatorConstants from '../constants/Calculator';

export class CalculatorStore extends ChangeEmitter {
  constructor() {
    super();

    this.amount = 0;
    this.currency = 'USD';
    this.type = '';
    this.role = 'seller';
    this.description = '';
    this.transactionStarted = false;
    this.handleViewAction = this.handleViewAction.bind(this);
    this.searchQuery = '';
    this.searchSelection = '';
    this.searchHoverText = '';
    this.searchHoverIdx = -1;
    this.searchKeystrokes = 0;
    this.paymentMethod = '';
    this.paymentMethodOptions = CalculatorConstants.PAYMENT_METHODS;
    this.typeLocked = false;
    this.roleLocked = false;
  }

  isValidUrl(url) {
    const trimmedUrl = url.trim();
    return validator.isURL(trimmedUrl) && !validator.isEmail(trimmedUrl);
  }

  getCurrency() {
    return this.currency;
  }

  getPaymentMethod() {
    return this.paymentMethod;
  }

  getPaymentMethodOptions() {
    return deepcopy(this.paymentMethodOptions);
  }

  getType() {
    return this.type;
  }

  getBaseFee() {
    if (this.amount > 10000000) {
      return Math.max(0.007 * this.amount, 90000);
    } else if (this.amount > 5000000) {
      return Math.max(0.009 * this.amount, 47500);
    } else if (this.amount > 3000000) {
      return Math.max(0.0095 * this.amount, 30000);
    } else if (this.amount > 1000000) {
      return Math.max(0.01 * this.amount, 12000);
    } else if (this.amount > 500000) {
      return Math.max(0.012 * this.amount, 7500);
    } else if (this.amount > 200000) {
      return Math.max(0.015 * this.amount, 3800);
    } else if (this.amount > 50000) {
      return Math.max(0.019 * this.amount, 1200);
    } else if (this.amount > 5000) {
      return Math.max(0.024 * this.amount, 130);
    }

    return Math.max(0.026 * this.amount, 50);
  }

  getFee() {
    if (
      this.paymentMethod === CalculatorConstants.CREDIT_PAYPAL_METHOD &&
      this.currency === CalculatorConstants.USD_CURRENCY
    ) {
      return Math.max(this.getBaseFee() + this.amount * 0.0305, 50);
    }

    return this.getBaseFee();
  }

  // As of development for T298709, it was found that this method is
  // deprecated and not being called anywhere outside specs.
  getConciergeFee() {
    if (this.paymentMethod === CalculatorConstants.CREDIT_PAYPAL_METHOD) {
      return Math.max(this.getBaseFee() * 2 + this.amount * 0.0305, 100);
    }
    return Math.max(this.getBaseFee() * 2, 100);
  }

  getData() {
    return localStorage.get('calculatorValues') || {};
  }

  lockRole(role) {
    this.role = role;
    this.roleLocked = true;
  }

  lockType(type) {
    this.type = type;
    this.typeLocked = true;
  }

  setRole(role) {
    if (!this.roleLocked) {
      this.role = role;
    }
  }

  setType(type) {
    if (!this.typeLocked) {
      this.type = type;
    }
  }

  storeValues() {
    localStorage.set('calculatorValues', {
      amount: this.amount,
      currency: this.currency,
      role: this.role,
      type: this.type,
      fee: this.getFee(),
      description: this.description,
    });
  }

  updatePaymentMethodOptions() {
    let newOptions;
    if (this.currency === 'EUR' || this.currency === 'CAD' || this.amount > 5000) {
      newOptions = CalculatorConstants.PAYMENT_METHODS.slice(0, 1);
    } else if (this.amount > 2000) {
      newOptions = CalculatorConstants.PAYMENT_METHODS.slice(0, 2);
    } else {
      newOptions = CalculatorConstants.PAYMENT_METHODS;
    }

    // When the payment method options change, check whether the selected option
    // has been removed from the list and if so, update the selected option to
    // the default (first in the list)
    if (!_.isEqual(newOptions, this.paymentMethodOptions)) {
      const method = this.paymentMethod;
      if (!newOptions.find((element) => element.value === method)) {
        this.paymentMethod = CalculatorConstants.PAYMENT_METHODS[0].value;
      }
    }
    this.paymentMethodOptions = newOptions;
  }

  handleViewAction(action) {
    const actionType = action.actionType;

    if (actionType === CalculatorConstants.CALCULATOR_VALUE_UPDATED) {
      const values = action.values;
      this.amount = values.amount;
      this.currency = values.currency;
      this.setRole(values.role);
      this.description = values.description || this.description;
      this.setType(values.type || this.type);
      this.storeValues();
      this.updatePaymentMethodOptions();
      this.emitChange();
    } else if (actionType === CalculatorConstants.CALCULATOR_SEARCH) {
      this.searchQuery = action.query;
      this.searchSelection = '';
      if (this.isValidUrl(this.searchQuery)) {
        this.setType(CalculatorConstants.DOMAIN_TRANSACTION_TYPE);
      } else {
        this.setType('');
      }
      // Search query changed, reset hover position
      this.searchHoverText = '';
      this.searchHoverIdx = -1;
      this.storeValues();
      this.emitChange();
    } else if (actionType === CalculatorConstants.SEARCH_SELECT) {
      this.searchQuery = action.text;
      this.searchSelection = action.text;
      this.setType(action.value || this.type);
      this.storeValues();
      this.emitChange();
    } else if (actionType === CalculatorConstants.SEARCH_HOVER) {
      this.searchHoverText = action.value;
      this.searchHoverIdx = action.idx;
      this.storeValues();
      this.emitChange();
    } else if (actionType === CalculatorConstants.SEARCH_KEYSTROKE_INC) {
      this.searchKeystrokes++;
    } else if (actionType === CalculatorConstants.SEARCH_KEYSTROKE_CLEAR) {
      this.searchKeystrokes = 0;
    } else if (actionType === CalculatorConstants.PAYMENT_METHOD_CHANGED) {
      this.paymentMethod = action.method;
      this.emitChange();
    }
  }
}

const calculatorStore = new CalculatorStore();
calculatorStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;
  const source = payload.source;

  if (source === 'VIEW_ACTION') {
    calculatorStore.handleViewAction(action);
  }
});

export default calculatorStore;
