import React from 'react';
import { EscrowButton } from 'spa/features/ui/EscrowButton';
import { usePatchTransactionMutation } from '../../transactionApi';

export const TransactionAgreement = ({ transactionId }) => {
  const [patchTransaction, { isLoading }] = usePatchTransactionMutation();

  const handleAgree = async () => {
    try {
      await patchTransaction({
        id: transactionId,
        ...{ action: 'agree' },
      }).unwrap();
    } catch (e) {
      // RTK handles the error
    }
  };

  return (
    <EscrowButton variant="contained" disabled={isLoading} onClick={handleAgree}>
      Agree to transaction
    </EscrowButton>
  );
};
