import deepcopy from 'clone';
import libphonenumber from 'google-libphonenumber';
import Mailcheck from 'mailcheck';

import AppDispatcher from '../dispatcher/AppDispatcher';
import { ChangeEmitter } from '../utils/ChangeEmitter';
import FormConstants from '../constants/FormConstants';
import AuthenticationConstants from '../constants/AuthenticationConstants';
import TrackingActions from '../actions/TrackingActions';
import validate from '../utils/FormValidation';

export class FormStore extends ChangeEmitter {
  constructor() {
    super();

    this.handleServerAction = this.handleServerAction.bind(this);
    this.formsSubmitting = {};
    this.formsSubmitted = {};
    this.validationState = {};
    this.showValidation = {};
    this.showError = {};
    this.formErrorTitle = {};
    this.formState = {};
    this.fieldHint = {};
    this.lastSubmissionFailure = {};
    this.formImmutableFields = {};
  }

  handleServerAction(action) {
    if (action.actionType === FormConstants.SUBMISSION_FAILURE) {
      if (action.name !== 'login-form' && action.name !== 'partner-login-form') {
        this.formsSubmitting[action.name] = false;
        this.showError[action.name] = true;
        if (action.title) {
          this.formErrorTitle[action.name] = action.title;
        }
      }
      this.lastSubmissionFailure[action.name] = Date.now();
      this.emitChange();
    } else if (action.actionType === FormConstants.SUBMISSION_SUCCESS) {
      this._onSubmissionSuccess(action);
    } else if (action.actionType === AuthenticationConstants.EMAIL_EXISTS) {
      if (
        action.form !== 'signup-create' &&
        (action.form !== 'aaa-start-transaction' || action.form !== 'namescon-start-transaction')
      ) {
        if (!this.validationState[action.form]) {
          this.validationState[action.form] = {};
        }
        this.validationState[action.form]['email-address'] = !action.exists;
        // invalid field - mark it as mutable to let users fix the error
        if (action.exists) {
          if (this.formImmutableFields[action.form]) {
            delete this.formImmutableFields[action.form]['email-address'];
          }
        }

        if (!this.emailExist[action.form]) {
          this.emailExist[action.form] = {};
        }
        this.emailExist[action.form] = action.email;
        this.emitChange();
      }
      if (!this.emailExist[action.form]) {
        this.emailExist[action.form] = {};
      }
      this.emailExist[action.form] = action.email;
      this.emitChange();
    }
  }

  updateFieldContents(fieldAttributes) {
    const { form, field, contents } = fieldAttributes;
    if (!this.formState[form]) {
      this.formState[form] = {};
    }
    this.formState[form][field] = contents;
  }

  updateValidation(validation, fieldAttributes) {
    const { form, field, contents, type } = fieldAttributes;
    if (!this.validationState[form]) {
      this.validationState[form] = {};
    }

    Object.assign(this.validationState[form], validation);

    // invalid field - mark it as mutable to let users fix the error
    if (!this.validationState[form][field] && this.formImmutableFields[form]) {
      delete this.formImmutableFields[form][field];
    }

    if (this.validationState[form][field]) {
      delete this.formErrorTitle[form];

      if (form === 'signup-create' && field === 'email-address-other') {
        this.getEmailSuggestions(form, field, contents);
      } else if (type === 'tel') {
        const phExt = this.formState[form][`${field}-prefix`];
        if (phExt) {
          this.libPhoneValidate(form, field, phExt, contents);
        }
      }
    }

    if (form === 'verify-tier-2' && field === 'IdContainsAddress') {
      if (contents === 'true') {
        this.validationState[form].AddressProofType = true;
      } else {
        this.validationState[form].AddressProofType = false;
      }
    }

    if (form === 'button-creation' && field === 'return_url') {
      this.fieldHint[form] = {};
      if (contents) {
        const urlRegex = new RegExp(/^(http|https):\/\//);
        if (urlRegex.test(contents)) {
          this.validationState[form][field] = true;
        } else {
          this.validationState[form][field] = false;
        }
      } else {
        this.validationState[form][field] = true;
      }
    }
  }

  fieldChanged(fieldAttributes) {
    this.updateFieldContents(fieldAttributes);
    if (!('skipValidation' in fieldAttributes) || !fieldAttributes.skipValidation) {
      const validation = validate(this.formState[fieldAttributes.form], fieldAttributes);
      this.updateValidation(validation, fieldAttributes);
    }
  }

  handleViewAction(action) {
    if (action.actionType === FormConstants.FIELD_CHANGED) {
      this.fieldChanged(action.attributes);
      this.emitChange();
    } else if (action.actionType === FormConstants.FIELDS_CHANGED) {
      for (const field of action.attributes.fields) {
        this.fieldChanged(field);
      }
      this.emitChange();
    } else if (action.actionType === FormConstants.FIELD_VALID) {
      this.updateFieldContents(action.attributes);
      this.updateValidation({ [action.attributes.field]: true }, action.attributes);
      this.emitChange();
    } else if (action.actionType === FormConstants.FIELDS_VALID) {
      for (const field of action.attributes.fields) {
        this.updateFieldContents(field);
        this.updateValidation({ [action.attributes.field]: true }, action.attributes);
      }
      this.emitChange();
    } else if (action.actionType === FormConstants.FIELD_INVALID) {
      this.updateFieldContents(action.attributes);
      this.updateValidation({ [action.attributes.field]: false }, action.attributes);
      this.emitChange();
    } else if (action.actionType === FormConstants.FIELDS_INVALID) {
      for (const field of action.attributes.fields) {
        this.updateFieldContents(field);
        this.updateValidation({ [action.attributes.field]: false }, action.attributes);
      }
      this.emitChange();
    } else if (action.actionType === FormConstants.SUBMISSION_START) {
      this.formsSubmitting[action.name] = true;
      this.emitChange();
    } else if (action.actionType === FormConstants.CLEAR_FIELD_VALIDATION) {
      if (typeof this.validationState[action.attributes.form] !== 'undefined') {
        delete this.validationState[action.attributes.form][action.attributes.field];
      }
      this.emitChange();
    } else if (action.actionType === FormConstants.SHOW_VALIDATION_STATE) {
      this._onShowValidationState(action);
    } else if (action.actionType === FormConstants.SHOW_VALIDATION_STATES) {
      this._onShowValidationStates(action);
    } else if (action.actionType === FormConstants.HIDE_VALIDATION_STATES) {
      this._onHideValidationStates(action);
    } else if (action.actionType === FormConstants.SHOW_FORM_ERROR) {
      this._onShowFormError(action);
    } else if (action.actionType === FormConstants.CLEAR_FORM_ERROR) {
      this._onClearFormError(action);
    } else if (action.actionType === FormConstants.FORM_STATE_CHANGED) {
      const form = action.attributes.form;
      const field = action.attributes.field;
      const value = action.attributes.contents;
      if (!this.formState[form]) {
        this.formState[form] = {};
      }
      this.formState[form][field] = value;
      delete this.formErrorTitle[action.form];
      this.emitChange();
    } else if (action.actionType === FormConstants.FORM_RESET) {
      this._onResetForm(action.formName);
      this.emitChange();
    } else if (action.actionType === FormConstants.FORM_STATES_CHANGED) {
      this._onFormStatesChanged(action);
    } else if (action.actionType === FormConstants.CLEAR_FIELD_VALIDATIONS) {
      this._onFormClearValidations(action);
    } else if (action.actionType === FormConstants.RESET_FIELDS) {
      this._onResetFields(action);
    } else if (action.actionType === FormConstants.SET_IMMUTABLE_FIELD) {
      if (!this.formImmutableFields[action.formName]) {
        this.formImmutableFields[action.formName] = {};
      }
      this.formImmutableFields[action.formName][action.fieldName] = action.value;
      this.emitChange();
    } else if (action.actionType === FormConstants.SET_IMMUTABLE_FIELDS) {
      if (!this.formImmutableFields[action.formName]) {
        this.formImmutableFields[action.formName] = {};
      }
      Object.assign(this.formImmutableFields[action.formName], action.fields);
      this.emitChange();
    } else if (action.actionType === FormConstants.CLEAR_IMMUTABLE_FIELDS) {
      for (const field of action.fields) {
        delete this.formImmutableFields[action.formName][field];
      }
      this.emitChange();
    } else if (action.actionType === FormConstants.SET_FIELD_VALIDATION_STATE) {
      this.updateValidation(
        {
          [action.fieldName]: action.isValid,
        },
        {
          form: action.formName,
          field: action.fieldName,
        }
      );
      this.emitChange();
    }
  }

  /**
   * This handles the FORM_STATES_CHANGED action from the FormActions object.
   *
   * @param {Object} action The given action object
   */
  _onFormStatesChanged(action) {
    const { form, contents } = action.attributes;
    if (!this.formState[form]) {
      this.formState[form] = {};
    }

    for (const field of Object.keys(contents)) {
      this.formState[form][field] = contents[field];
    }

    delete this.formErrorTitle[action.form];
    this.emitChange();
  }

  /**
   * This handles clearing the validation state for multiple fields within a
   * form.
   *
   * @param {Object} action The given action object
   */
  _onFormClearValidations(action) {
    const { form, fields } = action.attributes;
    for (const field of fields) {
      if (typeof this.validationState[form] !== 'undefined') {
        delete this.validationState[form][field];
      }
    }
    this.emitChange();
  }

  /**
   * This handles clearing the validation and resetting values for multiple
   * fields within a form.
   *
   * @param {Object} action The given action object
   */
  _onResetFields(action) {
    const { form, fields, contents } = action.attributes;
    for (const field of fields) {
      if (typeof this.validationState[form] !== 'undefined') {
        delete this.validationState[form][field];
      }
      if (typeof this.formState[form] === 'undefined') {
        this.formState[form] = {};
      }
      this.formState[form][field] = contents[fields.indexOf(field)];
    }
    this.emitChange();
  }

  /**
   * This handles the SHOW_VALIDATION_STATE action from the FormActions object.
   *
   * @param {Object} action The given action object
   */
  _onShowValidationState(action) {
    const { form, field, type, value } = action.attributes;
    if (!this.showValidation[form]) {
      this.showValidation[form] = {};
    }
    if (field === 'Country' || field === 'CompanyAddressCountry') {
      this.showValidation[form][field] = true;
    }
    if (type !== 'select-one' || type !== 'radio') {
      this.showValidation[form][field] = true;
    }

    if (form === 'signup-customer' || form === 'signup-partner' || form === 'partner-signup-form') {
      let trackingValue;
      if (type === 'radio' || type === 'checkbox') {
        trackingValue = value;
      } else {
        trackingValue = this.validationState[form][field] ? 'is-valid' : 'is-invalid';
      }
      setTimeout(() => {
        TrackingActions.track({
          event: 'escrow_user_action',
          section: form,
          label: field,
          action: 'field-visited',
          value: trackingValue,
        });
      });
    }
    this.emitChange();
  }

  /**
   * This handles showing the validation state for multiple fields
   * within a form.
   *
   * @param {Object} action The given action object
   */
  _onShowValidationStates(action) {
    const { form, fields } = action.attributes;
    for (const field of fields) {
      if (!this.showValidation[form]) {
        this.showValidation[form] = {};
      }
      this.showValidation[form][field] = true;
    }
    this.emitChange();
  }

  /**
   * This handles clearing the show validation state for multiple fields
   * within a form.
   *
   * @param {Object} action The given action object
   */
  _onHideValidationStates(action) {
    const { form, fields } = action.attributes;
    for (const field of fields) {
      if (typeof this.showValidation[form] !== 'undefined') {
        delete this.showValidation[form][field];
      }
    }
    this.emitChange();
  }

  /**
   * This handles the SUBMISSION_SUCCESS action from the FormActions object.
   *
   * @param {Object} action The given action object
   */
  _onSubmissionSuccess(action) {
    setTimeout(() => {
      TrackingActions.track({
        event: 'escrow_user_action',
        section: action.name,
        action: 'form-submission',
        value: 'success',
      });
    });

    this.formsSubmitting[action.name] = false;
    this.formsSubmitted[action.name] = true;
    this.emitChange();
  }

  /**
   * This handles the SHOW_FORM_ERROR action from the FormActions object.
   *
   * @param {Object} action The given action object
   */
  _onShowFormError(action) {
    setTimeout(() => {
      TrackingActions.track({
        event: 'escrow_user_action',
        section: action.form,
        action: 'form-submission',
        value: 'validation-error',
      });
    });

    this.showError[action.form] = action.showError;
    this.formErrorTitle[action.form] = action.title;
    this.formsSubmitting[action.form] = false;
    this.emitChange();
  }

  _onClearFormError(action) {
    delete this.formErrorTitle[action.form];
  }

  _onResetForm(formName) {
    delete this.formState[formName];
    delete this.validationState[formName];
    delete this.showError[formName];
    delete this.formErrorTitle[formName];
    this.formsSubmitted[formName] = false;
    delete this.showValidation[formName];
  }

  currentFormState(name) {
    if (typeof this.formState[name] === 'undefined') {
      this.formState[name] = {};
    }
    return deepcopy(this.formState[name]);
  }

  formIsSubmitting(name) {
    return !!this.formsSubmitting[name];
  }

  lastFailedSubmission(name) {
    return this.lastSubmissionFailure[name];
  }

  showFormError(name) {
    return !!this.showError[name];
  }

  showValidationStatus(name) {
    if (typeof this.showValidation[name] === 'undefined') {
      this.showValidation[name] = {};
    }
    return deepcopy(this.showValidation[name]);
  }

  errorTitle(name) {
    return this.formErrorTitle[name];
  }

  emailExist(name) {
    return this.emailExist[name];
  }

  submissionSuccess(name) {
    return !!this.formsSubmitted[name];
  }

  validFields(name) {
    if (typeof this.validationState[name] === 'undefined') {
      this.validationState[name] = {};
    }
    return deepcopy(this.validationState[name]);
  }

  invalidFieldCount(name) {
    const validFields = this.validFields(name);
    let invalidFieldCount = 0;
    for (const key of Object.keys(validFields)) {
      invalidFieldCount += validFields[key] === false;
    }
    return invalidFieldCount;
  }

  immutableFields(name) {
    if (typeof this.formImmutableFields[name] === 'undefined') {
      this.formImmutableFields[name] = {};
    }
    return deepcopy(this.formImmutableFields[name]);
  }

  fieldHints(name) {
    if (typeof this.fieldHint[name] === 'undefined') {
      this.fieldHint[name] = {};
    }
    return deepcopy(this.fieldHint[name]);
  }

  libPhoneValidate(form, field, phExt, ph) {
    const phUtil = new libphonenumber.PhoneNumberUtil();

    // libphone parsing and validation needs to be in a try catch block as
    // the library will throw a NumberFormatException when trying to parse
    // numbers it is unable to parse
    try {
      const parsedNumber = phUtil.parse(phExt + ph, null);
      if (!phUtil.isValidNumber(parsedNumber)) {
        if (!this.fieldHint[form]) {
          this.fieldHint[form] = {};
        }
        this.fieldHint[form][field] = {
          type: FormConstants.PHONE_HINT,
          phone: ph,
        };
      } else if (this.fieldHint[form]) {
        delete this.fieldHint[form][field];
      }
    } catch (e) {
      if (!this.fieldHint[form]) {
        this.fieldHint[form] = {};
      }
      this.fieldHint[form][field] = {
        type: FormConstants.PHONE_HINT,
        phone: ph,
      };
    }
  }

  getEmailSuggestions(form, field, email) {
    const hint = this.fieldHint;
    const emitChange = () => {
      this.emitChange();
    };

    Mailcheck.run({
      email: email,
      suggested: (suggestion) => {
        if (!hint[form]) {
          hint[form] = {};
        }
        hint[form][field] = {
          type: FormConstants.EMAIL_HINT,
          email: email,
          hint: suggestion.full,
        };
        emitChange();
      },
    });
  }
}

const formStore = new FormStore();
formStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;
  const source = payload.source;

  if (source === 'SERVER_ACTION') {
    formStore.handleServerAction(action);
  }

  if (source === 'VIEW_ACTION') {
    formStore.handleViewAction(action);
  }
});

export default formStore;
