import React from 'react';

import { updateTaskRequest } from 'spa/actions/TaskActions';

import A from 'spa/components/A';

import { urlFor } from '../../routeConfig';

export const taskData = {
  terms_and_conditions: {
    description: (
      <span>
        You have read through, understood and agree to our{' '}
        <A link={{ type: 'internal', route: '/legal', newTab: true }}>API Terms and Conditions</A>.
      </span>
    ),
  },
  phone_verification: {
    description: (
      <span>
        Use your preferred number so we can easily reach you in the future. You can complete your
        account details and verify your phone number{' '}
        <A link={{ type: 'internal', route: '/account-info/personal' }}>here</A>.
      </span>
    ),
  },
  company_verification: {
    description: (
      <span>
        Escrow.com requires every partner to complete identity and company verification before we
        can start processing transactions for you. The process is simple, usually taking less than 5
        minutes to submit. You can submit your request{' '}
        <A link={{ type: 'internal', route: '/verify' }}>here</A> and read more about why we verify
        your identity{' '}
        <A link={{ type: 'internal', route: '/support/identity-verification' }}>here</A>.
      </span>
    ),
  },
  settlement_method: {
    description: (
      <span>
        We require you to add a settlement method to your account so that we can pay you proceeds of
        any transactions you are involved in. Add your settlement method{' '}
        <A link={{ type: 'internal', route: '/account-info/disbursement-options' }}>here</A>.
      </span>
    ),
  },
  company_info: {
    description: (
      <span>
        Complete this simple 10 minute{' '}
        <A
          link={{
            type: 'external',
            route: 'https://goo.gl/forms/NTBXfWcAvHaU6eMW2',
            newTab: true,
            onClickAction: updateTaskRequest('company_info', 'complete', 'account'),
          }}
        >
          survey
        </A>{' '}
        to tell us more about how you intend to use Escrow.com, so we can understand your needs
        better and help you successfully integrate with us.
      </span>
    ),
  },
  create_sandbox_account: {
    description: (
      <span>
        To perform tests during the development phase of your integration you will need to have an
        account in our developer sandbox environment. This is hosted in{' '}
        <A link={{ type: 'external', route: 'https://www.escrow-sandbox.com/integrations/signup' }}>
          www.escrow-sandbox.com
        </A>{' '}
        so you need to create a new account there.
      </span>
    ),
  },
  create_sandbox_api_key: {
    description: (
      <span>
        Each call to the Escrow API must be secured with an API key. You can create a sandbox API
        key after you log in to your sandbox account.
      </span>
    ),
  },
  api_create_sandbox_transaction: {
    description: (
      <span>
        Use the{' '}
        <A link={{ type: 'internal', route: '/api/docs/create-transaction' }}>create transaction</A>{' '}
        endpoint to create your first test transaction in the sandbox environment.
      </span>
    ),
  },
  api_agree_sandbox_transaction: {
    description: (
      <span>
        Use the{' '}
        <A link={{ type: 'internal', route: '/api/docs/agree-transaction' }}>API endpoints</A> to
        progress through the various stages of the transaction in the sandbox environment until the
        transaction is complete.
      </span>
    ),
  },
  ihelper_approve_payment: {
    description: (
      <span>
        Use the{' '}
        <A link={{ type: 'internal', route: '/integration-helper/docs/approve-payments' }}>
          Integration Helper
        </A>{' '}
        to approve a payment in the sandbox environment to progress the transaction past the
        transaction funding stage.
      </span>
    ),
  },
  api_check_sandbox_transaction_status: {
    description: (
      <span>
        You can check the{' '}
        <A link={{ type: 'internal', route: '/api/docs/fetch-transaction' }}>
          status of a transaction
        </A>{' '}
        at any point or use{' '}
        <A link={{ type: 'internal', route: '/integrations/portal/webhooks' }}>webhooks</A> to
        receive updates when the status of a transaction changes.
      </span>
    ),
  },
  create_api_keys: {
    description: (
      <span>
        Just like in the sandbox environment, production API calls must be secured with your API
        key. API keys are specific to an environment, so you may not use a sandbox API key in
        production or a production API key in sandbox.
      </span>
    ),
  },
  create_production_transaction: {
    description: (
      <span>
        Once you’ve created your first real transaction, your integration should be all set!
        Congratulations on completing your Escrow API integration successfully.
      </span>
    ),
  },
  bin_create_button: {
    description: (
      <span>
        Use our <A link={{ type: 'internal', route: '/buttons/create' }}>button creation widget</A>{' '}
        to generate the code snippet that you will install on your website or marketplace listing.
      </span>
    ),
  },
  bin_install_button: {
    description: (
      <span>
        Paste the code snippet on your website or marketplace listing. Each time a buyer clicks
        through the button to purchase your item, they will be provided with the transaction terms
        you set in the previous step.
      </span>
    ),
  },
  referrals_install_link: {
    description: (
      <span>
        Use the referral link available on this page and install it on your website to direct
        traffic to Escrow.com.
      </span>
    ),
  },
};

export const startTasks = {
  api: {
    route: urlFor('integrations_api_getstarted'),
    name: 'start_api_integration',
  },
  buttons: {
    route: urlFor('integrations_buttons_getstarted'),
    name: 'start_buttons_integration',
  },
  referrals: {
    route: urlFor('integrations_referrals_getstarted'),
    name: 'start_referrals_integration',
  },
};
