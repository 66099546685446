import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import Currency from 'spa/components/Currency';
import { getCurrencyTagFromApiCurrency } from 'escrow-common-js/dist/utils';
import { getTimePeriodShort } from '../../../utils/date';
import { gettext } from '../../../utils/filters';
import { setTransId } from '../../actions/OfferActions';
import { custEmailSelector } from '../../selectors/CustomerSelectors';
import {
  activeTransIdSelector,
  auctionIdSelector,
  auctionsByIdSelector,
  auctionTransIdsSelector,
  offersByTransIdSelector,
} from '../../selectors/OfferSelectors';

const getOfferParty = (offer) => {
  let offerParty;

  if (offer.offerTransaction) {
    offerParty = offer.offerTransaction.parties.find((party) => party.id === offer.byParty.id);
    if (offerParty) return offerParty.customer;
  }

  return offer.byParty.customer;
};

const getOfferSummary = (viewerEmail) => (offerList) => {
  const offerListCopy = [...offerList];
  let otherParty;
  let displayOffer;

  offerListCopy.sort((a, b) => b.dateCreated - a.dateCreated);
  const newestNonViewerOffer = offerListCopy.find((offer) => getOfferParty(offer) !== viewerEmail);
  const newestViewerOffer = offerListCopy.find((offer) => getOfferParty(offer) === viewerEmail);

  if (newestNonViewerOffer && newestViewerOffer) {
    if (newestNonViewerOffer.dateCreated > newestViewerOffer.dateCreated) {
      displayOffer = newestNonViewerOffer;
      otherParty = newestNonViewerOffer.byParty.displayName
        ? newestNonViewerOffer.byParty.displayName
        : newestNonViewerOffer.byParty.customer;
    } else {
      otherParty = 'You';
      displayOffer = newestViewerOffer;
    }
  } else if (newestNonViewerOffer) {
    otherParty = newestNonViewerOffer.byParty.displayName
      ? newestNonViewerOffer.byParty.displayName
      : newestNonViewerOffer.byParty.customer;
    displayOffer = newestNonViewerOffer;
  } else {
    otherParty = 'You';
    displayOffer = newestViewerOffer;
  }

  return {
    newestNonViewerOffer: displayOffer,
    newestOffer: offerListCopy[0],
    otherParty,
    transactionId: offerListCopy[0].transactionId,
  };
};

const getSortedOfferSummariesForViewer = (offerLists, viewerEmail) => {
  const offerSummaries = offerLists
    .map(getOfferSummary(viewerEmail))
    .filter((summary) => summary.newestNonViewerOffer);
  const sortedOfferSummaries = [...offerSummaries];
  sortedOfferSummaries.sort((a, b) => b.newestOffer.dateCreated - a.newestOffer.dateCreated);
  return sortedOfferSummaries;
};

class OfferTabs extends React.Component {
  constructor(props) {
    super(props);

    this.getSortedOfferSummaries.bind(this);
  }

  componentDidMount() {
    this.selectOfferIfNoneSelected();
  }

  componentDidUpdate() {
    this.selectOfferIfNoneSelected();
  }

  getSortedOfferSummaries() {
    const { auctionTransIds, offersByTransId, viewerEmail } = this.props;
    const offerLists = auctionTransIds.map((tId) => offersByTransId[tId]);
    return getSortedOfferSummariesForViewer(offerLists, viewerEmail);
  }

  selectOfferIfNoneSelected() {
    if (!this.props.activeTransId) {
      const summaries = this.getSortedOfferSummaries();
      if (summaries.length !== 0) {
        this.props.setTransId(summaries[0].transactionId);
      }
    }
  }

  render() {
    const { activeAuctionId, activeTransId, auctionsById } = this.props;
    const sortedOfferSummaries = this.getSortedOfferSummaries();
    if (sortedOfferSummaries.length === 0) {
      return null;
    }

    const auction = auctionsById[activeAuctionId];

    return (
      <div className="card" data-tracking-section="offer-management-grouping-section">
        <div className="offer-nav-header card-inner">
          <h2 className="offer-nav-title">{gettext('Offers')}</h2>
        </div>
        <ul className="offer-tabs">
          {sortedOfferSummaries.map((summary) => (
            <li
              className={classnames('offer-tabs-item', {
                'is-active': summary.transactionId === activeTransId,
              })}
              key={summary.transactionId}
            >
              <a
                href="#"
                className="offer-tabs-link"
                onClick={() => {
                  this.props.setTransId(summary.transactionId);
                }}
                data-tracking-name="offer-group-link"
                data-tracking-value={summary.transactionId}
              >
                <span className="offer-tabs-name">{summary.otherParty}</span>
                <span className="offer-tabs-note">
                  <span>
                    {getTimePeriodShort(Date.now() - summary.newestNonViewerOffer.dateCreated)}
                  </span>
                  <span className="offer-tabs-price">
                    <Currency
                      code={getCurrencyTagFromApiCurrency(auction.currency)}
                      amount={summary.newestNonViewerOffer.noFeeAmount}
                    />
                  </span>
                </span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeAuctionId: auctionIdSelector(state),
  activeTransId: activeTransIdSelector(state),
  auctionsById: auctionsByIdSelector(state),
  auctionTransIds: auctionTransIdsSelector(state),
  viewerEmail: custEmailSelector(state),
  offersByTransId: offersByTransIdSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  setTransId: (transId) => {
    dispatch(setTransId(transId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OfferTabs);
