import React from 'react';

import AccountHeader from 'spa/components/AccountHeader';
import Breadcrumbs from 'spa/components/Breadcrumbs';
import VatContainer from 'spa/containers/TaxDetails/vat';
import UserStore from '../../../stores/UserStore';
import AuthenticationStore from '../../../stores/AuthenticationStore';

class NotificationSettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
    };
    this._onChange = this._onChange.bind(this);
    AuthenticationStore.addChangeListener(this._onChange);
    UserStore.addChangeListener(this._onChange);
  }

  componentWillUnmount() {
    AuthenticationStore.removeChangeListener(this._onChange);
    UserStore.removeChangeListener(this._onChange);
  }

  _onChange() {
    const custId = AuthenticationStore.getCustomerID();
    this.setState({
      name: UserStore.getFullName(custId),
    });
  }

  renderBreadCrumbs() {
    const breadcrumbs = [
      {
        text: 'Your Account',
        link: '/account-info',
      },
      {
        text: 'Tax Details',
      },
    ];

    return <Breadcrumbs className="accountInfo-breadcrumbs" items={breadcrumbs} />;
  }

  render() {
    return (
      <div className="accountInfo">
        <AccountHeader name={this.state.name} />
        <div className="section-container">
          {this.renderBreadCrumbs()}
          <VatContainer />
        </div>
      </div>
    );
  }
}

export default NotificationSettingsPage;
