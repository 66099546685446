import _ from 'lodash';

import TaskConstants from 'spa/constants/TaskConstants';

const updateTask = (key, name, taskList, updates) => ({
  [key]: taskList.map((task) => {
    // find and update the task
    if (task.name === name) {
      // for each key in updates, if it is defined, add it to the task, otherwise
      // remove it
      const toRemove = _.pickBy(updates, _.isUndefined);
      const toAdd = _.omitBy(updates, _.isUndefined);
      const updatedTask = {
        ...task,
        ...toAdd,
      };
      return _.omit(updatedTask, Object.keys(toRemove));
    }
    // also search for the update task in subtasks
    if (task.subtasks && !_.isEmpty(task.subtasks)) {
      return {
        ...task,
        ...updateTask('subtasks', name, task.subtasks, updates),
      };
    }
    return task;
  }),
});

// task_list vs taskList
// both are used here. Anything that comes FROM api is snake_case
// because we reuse the name from the action in fulfill, it is NOT from the API
// and hence is still camelCase
function taskReducer(state = {}, action) {
  switch (action.type) {
    case TaskConstants.UPDATE_TASK_REQUEST: {
      const { taskList, name } = action;
      const updates = { updating: true };
      return Object.assign({}, state, updateTask(taskList, name, state[taskList], updates), {
        updating: taskList,
      });
    }
    case TaskConstants.UPDATE_TASK_SUCCESS: {
      const { task_list: taskList, name, completion_date } = action;
      const updates = { completion_date };
      return Object.assign({}, state, updateTask(taskList, name, state[taskList], updates));
    }
    case TaskConstants.UPDATE_TASK_FAILURE:
      return state;
    case TaskConstants.UPDATE_TASK_FULFILLED: {
      const { taskList, name } = action;
      const updates = { updating: false };
      return Object.assign({}, state, updateTask(taskList, name, state[taskList], updates), {
        updating: null,
      });
    }
    default:
      return state;
  }
}

export default taskReducer;
