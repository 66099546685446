import React from 'react';
import TextTruncate from 'react-text-truncate';
import Currency from 'spa/components/Currency';
import { gettext } from '../../../utils/filters';

class LineItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFullDescription: false,
    };

    this.showFullDescription = this.showFullDescription.bind(this);
  }

  showFullDescription() {
    this.setState({ isFullDescription: true });
  }

  renderDescriptionButton() {
    return (
      <div>
        <a href="#" onClick={this.showFullDescription}>
          {gettext('Show More')}
        </a>
      </div>
    );
  }

  renderTerm(label, value) {
    return (
      <div className="orderSummaryTable-item-detail" key={`${label}_${value}`}>
        <strong>{label}</strong> {value}
      </div>
    );
  }

  renderAdditionalTerms(additionalTerms) {
    const renderedAdditionalTerms = [];
    const withContentLabel = gettext('With content');
    const withConciergeLabel = gettext('With concierge');
    for (const [key, value] of Object.entries(additionalTerms)) {
      switch (key) {
        case 'withContent':
          renderedAdditionalTerms.push(
            this.renderTerm(`${withContentLabel}:`, value ? gettext('Yes') : gettext('No'))
          );
          break;
        case 'concierge':
          renderedAdditionalTerms.push(
            this.renderTerm(`${withConciergeLabel}:`, value ? gettext('Yes') : gettext('No'))
          );
          break;
        case 'numPayments':
          renderedAdditionalTerms.push(this.renderTerm(`${gettext('Number of payments')}:`, value));
          break;
        default:
          break;
      }
    }
    return renderedAdditionalTerms;
  }

  renderItemDescription() {
    return (
      <div>
        {this.props.title && <h4 className="orderSummaryTable-item-title">{this.props.title}</h4>}
        {this.props.description && (
          <div className="orderSummaryTable-item-desc">
            <TextTruncate
              containerClassName="checkout-details-description"
              line={this.state.isFullDescription ? 0 : 2}
              truncateText="..."
              text={this.props.description}
              textTruncateChild={this.renderDescriptionButton()}
            />
          </div>
        )}
      </div>
    );
  }

  renderTerms(inspectionPeriod, escrowFeeSplit, additionalTerms) {
    let escrowFeePayer = '';
    if (escrowFeeSplit.buyer === 1.0) {
      escrowFeePayer = gettext('Buyer');
    } else if (escrowFeeSplit.seller === 1.0) {
      escrowFeePayer = gettext('Seller');
    } else if (escrowFeeSplit.broker === 1.0) {
      escrowFeePayer = gettext('Broker');
    } else {
      const feeStatuses = [];

      if (escrowFeeSplit.buyer > 0.0) {
        feeStatuses.push(`${escrowFeeSplit.buyer * 100}% ${gettext('Buyer')}`);
      }

      if (escrowFeeSplit.seller > 0.0) {
        feeStatuses.push(`${escrowFeeSplit.seller * 100}% ${gettext('Seller')}`);
      }

      if (escrowFeeSplit.broker > 0.0) {
        feeStatuses.push(`${escrowFeeSplit.broker * 100}% ${gettext('Broker')}`);
      }

      escrowFeePayer = feeStatuses.join(' / ');
    }

    const escrowFeePaidByLabel = gettext('Escrow fee paid by');
    const daysLabel = gettext('day(s)');
    return (
      <div>
        {inspectionPeriod &&
          this.renderTerm(`${gettext('Inspection Period')}:`, `${inspectionPeriod} ${daysLabel}`)}
        {escrowFeePayer && this.renderTerm(`${escrowFeePaidByLabel}:`, escrowFeePayer)}
        {additionalTerms && this.renderAdditionalTerms(additionalTerms)}
      </div>
    );
  }

  render() {
    const { escrowFeeSplit, inspectionPeriod, currency, price, additionalTerms } = this.props;

    return (
      <tr className="orderSummaryTable-item">
        <td data-title="Item Description" className="orderSummaryTable-item-cell">
          {this.renderItemDescription()}
          {this.renderTerms(inspectionPeriod, escrowFeeSplit, additionalTerms)}
        </td>
        <td data-title="Price" className="orderSummaryTable-item-cell">
          <div className="orderSummaryTable-item-unitPrice">
            <span className="orderSummaryTable-item-label media--available@mobile">
              {gettext('Price')}:
            </span>
            <span className="orderSummaryTable-price">
              <Currency code={currency} amount={price} withTag />
            </span>
          </div>
        </td>
      </tr>
    );
  }
}

export default LineItem;
