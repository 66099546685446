/* eslint-disable no-console, react-hooks/rules-of-hooks, react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-final-form';
import { Checkbox, FormControlLabel, MenuItem } from '@mui/material';
import { CountryToAlpha2 } from 'spa/constants/ISOCountryCodes';
import {
  required,
  maxChars,
  composeValidators,
  noStartTrailWhitespace,
} from 'spa/components/form/validate';
import { buildCompleteAddress } from 'spa/selectors/CustomerSelectors';
import { Input } from 'spa/components/MaterialUI';
import { RegionsByCountry } from 'escrow-common-js/dist/constants';
import TransactionConstants from 'spa/constants/TransactionConstants';
import { useWindowDimensions } from 'spa/hooks';
import { gettext } from '../../../../../utils/filters';
import { InputField, HiddenField, handleOlarkDisplay } from '.';
import AutocompleteAddressConstants, {
  formFieldMapping,
} from '../../../../../constants/AutocompleteAddressConstants';

export const getStateSelectOptions = (country) => {
  const states = RegionsByCountry[country];
  const result = [];

  if (states) {
    for (const state of states) {
      const unique = states.filter((s) => s.name === state.name).length === 1;
      const uniqueName = unique ? state.name : `${state.name} (${state.type})`;
      result.push({ value: uniqueName, label: uniqueName });
    }
  }
  return result;
};

const ModeSwitcher = ({ checked, onToggle, disabled }) => (
  <div className="kyc-address-toggler">
    <FormControlLabel
      classes={{ root: 'createTransaction-checkbox-label' }}
      control={<Checkbox onChange={onToggle} checked={checked} disabled={disabled} />}
      label={<span className="createTransaction-default-text">Enter address manually</span>}
    />
  </div>
);

const AutocompleteAddress = ({
  fieldPrefix,
  form,
  values,
  submitValidationError,
  onAddressAutocomplete,
  showError,
  setShowError,
  disabled,
}) => {
  const [addressAutoComplete, setAddressAutoComplete] = useState(false);
  const [isAutoFillingCountry, setIsAutoFillingCountry] = useState(false);
  const [isEmpty, setIsEmpty] = useState(!values[`${fieldPrefix}complete-address`]);
  const autoCompleteFieldRef = useRef();
  const { width } = useWindowDimensions();
  const isMobileView = width < TransactionConstants.DESKTOP_VIEW_WIDTH;

  const clearManualAddress = () => {
    Object.keys(formFieldMapping).forEach((key) => {
      if (key !== 'country') {
        form.change(`${fieldPrefix}${key}`, null);
      }
    });
    form.change(`${fieldPrefix}full-country`, null);
  };

  const clearAllAddressFields = () => {
    autoCompleteFieldRef.current.value = null;
    setIsEmpty(true);
    clearManualAddress();
  };

  const fillAddress = (autocomplete) => () => {
    const place = autocomplete.getPlace();
    if (!place || !place.address_components) {
      return clearManualAddress();
    }
    onAddressAutocomplete();
    const address = place.address_components;
    const addressDict = {
      Street1: '',
      Street2: '',
      City: '',
      State: '',
      Zip: '',
      Country: '',
    };

    const addressSortedAscSpecific = address.reverse();

    addressSortedAscSpecific.forEach((component) => {
      const mappedType = AutocompleteAddressConstants.GOOGLE_MAPS_FIELDS[component.types[0]];
      if (mappedType === 'Country') {
        addressDict[mappedType] = component.short_name;
        form.change(`${fieldPrefix}full-country`, component.long_name);
      } else if (mappedType === 'State') {
        if (!addressDict.State) {
          const matchedIsoStates = getStateSelectOptions(addressDict.Country).filter(
            (ISOstate) =>
              ISOstate.value.toLowerCase().includes(component.long_name.toLowerCase()) ||
              ISOstate.value.toLowerCase().includes(component.short_name.toLowerCase())
          );
          if (matchedIsoStates.length === 1) {
            addressDict.State = matchedIsoStates[0].value;
          }
        }
      } else {
        addressDict[mappedType] += `${component.long_name} `;
      }
    });
    setIsAutoFillingCountry(true);
    Object.entries(formFieldMapping).forEach(([key, fields]) => {
      form.change(
        `${fieldPrefix}${key}`,
        fields
          .map((f) => addressDict[f])
          .join(' ')
          .trim()
      );
    });
  };

  useEffect(() => {
    if (window.google && autoCompleteFieldRef.current && !addressAutoComplete) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        autoCompleteFieldRef.current,
        {
          types: ['address'],
          componentRestrictions: values[`${fieldPrefix}country`]
            ? { country: values[`${fieldPrefix}country`] }
            : null,
        }
      );
      autocomplete.addListener('place_changed', fillAddress(autocomplete));
      setAddressAutoComplete(autocomplete);
    }
  }, [window.google, [autoCompleteFieldRef.current], addressAutoComplete]);

  const onAutocompleteEnterPressed = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    autoCompleteFieldRef.current &&
      autoCompleteFieldRef.current.addEventListener('keydown', onAutocompleteEnterPressed);
    return () =>
      autoCompleteFieldRef.current &&
      autoCompleteFieldRef.current.removeEventListener('keydown', onAutocompleteEnterPressed);
  }, [autoCompleteFieldRef.current]);

  useEffect(() => {
    if (addressAutoComplete && values[`${fieldPrefix}country`]) {
      addressAutoComplete.setComponentRestrictions({
        country: values[`${fieldPrefix}country`],
      });
      if (isAutoFillingCountry) {
        setIsAutoFillingCountry(false);
      } else {
        clearAllAddressFields();
      }
    }
  }, [values[`${fieldPrefix}country`]]);

  return (
    <React.Fragment>
      <Input
        inputRef={autoCompleteFieldRef}
        defaultValue={values[`${fieldPrefix}complete-address`]}
        label="Address"
        onChange={() => {
          clearManualAddress();
          // handleOlarkDisplay(false, isMobileView);
        }}
        onBlur={(e) => {
          setShowError(true);
          setIsEmpty(!e.target.value);
          form.change(`${fieldPrefix}complete-address`, e.target.value);
          // handleOlarkDisplay(true, isMobileView);
        }}
        error={showError && (isEmpty || submitValidationError)}
        helperText={showError && (isEmpty ? 'Required' : submitValidationError)}
        disabled={disabled}
      />
      <HiddenField
        name={`${fieldPrefix}full-country`}
        fieldProps={{
          initialValue: values[`${fieldPrefix}full-country`],
        }}
      />
      {Object.keys(formFieldMapping).map((key) => (
        <HiddenField
          key={key}
          name={`${fieldPrefix}${key}`}
          fieldProps={{
            initialValue: values[`${fieldPrefix}${key}`],
            validate: key.includes('post-code') ? undefined : required,
          }}
        />
      ))}
    </React.Fragment>
  );
};

const ManualAddressFieldSet = ({ fieldPrefix, country, disabled, setAddressRegionToEmpty }) => {
  const cityField = (
    <InputField
      name={`${fieldPrefix}city`}
      label="City"
      validate={composeValidators([
        required,
        maxChars(30, gettext('City')),
        noStartTrailWhitespace,
      ])}
      disabled={disabled}
    />
  );

  const stateSelectOptions = getStateSelectOptions(country).map(({ label, value }) => (
    <MenuItem key={label} value={value}>
      {label}
    </MenuItem>
  ));

  const hasStates = stateSelectOptions.length > 0;

  const statesField = (
    <InputField
      name={`${fieldPrefix}state`}
      label="State/Province/Region"
      select
      selectHandleOlark
      validate={composeValidators([required, noStartTrailWhitespace])}
      disabled={disabled}
    >
      {stateSelectOptions}
    </InputField>
  );

  const hiddenStatesField = <HiddenField name={`${fieldPrefix}state`} />;

  if (!hasStates) setAddressRegionToEmpty();

  const zipField = (
    <InputField
      name={`${fieldPrefix}post-code`}
      label="Zip/Postal code"
      validate={noStartTrailWhitespace}
      disabled={disabled}
    />
  );

  return (
    <React.Fragment>
      <InputField
        name={`${fieldPrefix}address`}
        label="Address"
        validate={composeValidators([
          required,
          maxChars(60, gettext('Address')),
          noStartTrailWhitespace,
        ])}
        disabled={disabled}
      />
      {hasStates ? cityField : null}
      <div className="kyc-inline-fields-container">
        <div className="kyc-inline-field--half">
          {hasStates ? (
            statesField
          ) : (
            <React.Fragment>
              {cityField}
              {hiddenStatesField}
            </React.Fragment>
          )}
        </div>
        <div className="kyc-inline-field--half">{zipField}</div>
      </div>
    </React.Fragment>
  );
};

const AddressFieldSet = ({
  type,
  defaultCountry,
  lockCountry,
  showError,
  setShowError,
  disabled,
}) => {
  const [manualMode, setManualMode] = useState(false);
  const form = useForm();
  const fieldPrefix = type === 'company' ? 'company-' : '';
  const { values, errors } = form.getState();

  const onModeToggle = () => {
    const newManualMode = !manualMode;
    if (
      !newManualMode &&
      (form.getFieldState(`${fieldPrefix}address`).dirty ||
        form.getFieldState(`${fieldPrefix}city`).dirty ||
        form.getFieldState(`${fieldPrefix}state`).dirty ||
        form.getFieldState(`${fieldPrefix}post-code`).dirty)
    ) {
      form.change(`${fieldPrefix}complete-address`, buildCompleteAddress(values, fieldPrefix));
    }
    setManualMode(newManualMode);
  };

  const AddressModeCheckbox = () => (
    <ModeSwitcher onToggle={onModeToggle} checked={manualMode} disabled={disabled} />
  );

  const autoCompleteFieldError =
    Object.keys(formFieldMapping).filter((key) => errors && errors[`${fieldPrefix}${key}`]).length >
    0
      ? 'Additional information required. Please complete the address manually.'
      : undefined;

  const setAddressRegionToEmpty = () => form.change(`${fieldPrefix}state`, '');

  return (
    <React.Fragment>
      {manualMode && <AddressModeCheckbox disabled={disabled} />}
      <InputField
        name={`${fieldPrefix}country`}
        select
        selectHandleOlark
        validate={required}
        initialValue={defaultCountry.toUpperCase()}
        label="Country"
        disabled={disabled || lockCountry}
      >
        {Object.entries(CountryToAlpha2).map(([name, alpha2]) => (
          <MenuItem key={alpha2} value={alpha2}>
            {name}
          </MenuItem>
        ))}
      </InputField>
      {!manualMode && (
        <AutocompleteAddress
          fieldPrefix={fieldPrefix}
          form={form}
          values={values}
          submitValidationError={autoCompleteFieldError}
          onAddressAutocomplete={() => form.mutators.clearAutocompleteAddressError(fieldPrefix)}
          showError={showError}
          setShowError={setShowError}
          disabled={disabled}
        />
      )}
      {manualMode && (
        <ManualAddressFieldSet
          fieldPrefix={fieldPrefix}
          country={values[`${fieldPrefix}country`]}
          disabled={disabled}
          setAddressRegionToEmpty={setAddressRegionToEmpty}
        />
      )}
      {!manualMode && <AddressModeCheckbox disabled={disabled} />}
    </React.Fragment>
  );
};

export default AddressFieldSet;
