import React from 'react';
import { format } from 'date-fns-tz';
import { parse } from 'date-fns';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Box from '@mui/material/Box';

import { SmallText, SmallLink } from '../../../ui/EscrowTypography';

const timeZoneToOffset = {
  PDT: '-0700',
};

/**
 * Replaces all dates in the note with a localized date.
 *
 * Currently supports PDT dates.
 */
function localizeDates(note) {
  let localizedNote = note;
  const dateRegex =
    /((?:Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday),\s+(?:January|February|March|April|May|June|July|August|September|October|November|December)\s+\d{1,2}(?:st|nd|rd|th)?\s+\d{4},\s+\d{2}:\d{2}:\d{2})\s(\w*)/g;
  try {
    for (const match of [...note.matchAll(dateRegex)]) {
      const dateStr = `${match[1]} ${timeZoneToOffset[match[2]]}`;

      const date = parse(dateStr, 'EEEE, MMMM do yyyy, HH:mm:ss xx', new Date());
      const formattedDate = format(date, 'EEEE, MMMM do yyyy, h:mm a z');
      localizedNote = note.replace(match[0], formattedDate);
    }
  } catch (err) {
    // we don't want to crash the app if we can't localize the dates
    return note;
  }
  return localizedNote;
}

/**
 *
 * @param history transaction history
 * @param truncated whether or not to truncate the history to a single entry, default is false
 * @param seeMoreHandler function to handle the see more button shown when the history is truncated
 */
const TransactionHistoryList = ({
  history,
  truncated = false,
  seeMoreHandler,
  testidPrefix = '',
}) => {
  const truncatedLogs = history.length - 1;
  let historyLogs = history;
  if (truncated) {
    historyLogs = historyLogs.slice(0, 1);
  }
  return (
    <React.Fragment>
      <List sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        {historyLogs.map(({ dateAdded, notes }, index) => (
          <ListItem disablePadding key={dateAdded + notes}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <SmallText data-testid={`${testidPrefix}history-date-${index}`} variant="bold">
                {format(dateAdded, 'MMMM d, yyyy, h:mm a z')}
              </SmallText>
              <SmallText data-testid={`${testidPrefix}history-notes-${index}`}>
                {localizeDates(notes)}
              </SmallText>
            </Box>
          </ListItem>
        ))}
      </List>
      {truncated && truncatedLogs > 0 && (
        <SmallLink onClick={seeMoreHandler}>
          See {truncatedLogs} more update{truncatedLogs > 1 ? 's' : ''}
        </SmallLink>
      )}
    </React.Fragment>
  );
};

export default TransactionHistoryList;
