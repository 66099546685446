import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { V3KYCMobilePages as Pages } from 'spa/constants/VerificationConstants';
import { kycSelectedIDSelector } from 'spa/features/kyc/kycSlice';
import MobilePage from '../../components/MobilePage';

const IDInstructionsPage = ({ back }) => {
  const id = useSelector(kycSelectedIDSelector);

  const side = useMemo(() => {
    if (id && id.type !== 'passport') {
      return back ? 'of the back ' : 'of the front ';
    }

    return '';
  }, [back, id]);

  return (
    <MobilePage
      title={`Take a photo ${side}of your ID`}
      ctaText="Continue"
      nextPage={back ? Pages.ID_BACK_CAPTURE : Pages.ID_FRONT_CAPTURE}
    >
      <img
        style={{ borderRadius: '1rem' }}
        src={`spa/../../build/images/kyc/id-instruction-${back ? 'back' : 'front'}.gif`}
        alt="Please capture your ID"
      />
    </MobilePage>
  );
};

export default IDInstructionsPage;
