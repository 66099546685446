import React from 'react';
import PropTypes from 'prop-types';

const CardBody = ({ children }) => <div className="card-inner">{children}</div>;

CardBody.propTypes = {
  children: PropTypes.node,
};

export default CardBody;
