import _, { invert } from 'lodash';
import libphonenumber from 'google-libphonenumber';
import { EKYC_MAX_ATTEMPTS } from 'escrow-common-js/dist/constants';

import { extractData, lookupCountry } from '../../utils/DataMapping';
import { countryHasDocuments } from '../../utils/IdentificationDocuments';
import { parsePhoneNumber } from '../../utils/parse-phone';
import {
  Alpha2ToAlpha3,
  Alpha3ToAlpha2,
  AlternateAlpha2ToAlpha2,
  CountryToAlpha2,
} from '../constants/ISOCountryCodes';
import AuthenticationStore from '../.././stores/AuthenticationStore';

const buyerDetailsMappingSchema = {
  'personalAddress.line1': 'address-line-1',
  'personalAddress.line2': 'address-line-2',
  'personalAddress.city': 'city',
  'personalAddress.state': 'state',
  'personalAddress.zip': 'post-code',
  firstName: 'first-name',
  middleName: 'middle-name',
  lastName: 'last-name',
  localFName: 'local-fname',
  localLName: 'local-lname',
  companyName: 'company-name',
  'companyAddress.line1': 'company-address-line-1',
  'companyAddress.line2': 'company-address-line-2',
  'companyAddress.city': 'company-city',
  'companyAddress.state': 'company-state',
  'companyAddress.zip': 'company-post-code',
  showCreditCardAuthorizationForm: 'show-cc-authorization-form',
  email: 'email',
};

const verifyDateOfBirth = (dateOfBirth) => (Date.parse(dateOfBirth) ? dateOfBirth : '');

const baseSelector = (state) => state.customer;

const areVerificationDetailsEmptySelector = (state) =>
  _.isEmpty(baseSelector(state).verificationStatus);

const customerKyc1VerifiedSelector = (state) =>
  _.get(baseSelector(state), 'verificationStatus.hasContactDetails', false);

const customerKyc2SubmittedSelector = (state) =>
  _.get(baseSelector(state), 'verificationStatus.individual.isSubmitted', false);

const customerKyc3SubmittedSelector = (state) =>
  _.get(baseSelector(state), 'verificationStatus.company.isSubmitted', false);

const customerKyc2IsLocallySubmittedSelector = (state) =>
  _.get(baseSelector(state), 'verificationStatus.individual.isLocallySubmitted', false);

const customerKyc3IsLocallySubmittedSelector = (state) =>
  _.get(baseSelector(state), 'verificationStatus.company.isLocallySubmitted', false);

const customerKyc2ApprovedSelector = (state) =>
  _.get(baseSelector(state), 'verificationStatus.individual.isApproved', false);

const customerKyc2InProgressSelector = (state) =>
  _.get(baseSelector(state), 'verificationStatus.individual.isInProgress', false);

const customerHasDocumentsSubmittedSelector = (state) =>
  _.get(baseSelector(state), 'verificationStatus.individual.hasDocumentsSubmitted', null);

const customerKyc3ApprovedSelector = (state) =>
  _.get(baseSelector(state), 'verificationStatus.company.isApproved', false);

const customerKyc3InProgressSelector = (state) =>
  _.get(baseSelector(state), 'verificationStatus.company.isInProgress', false);

const customerKyc2HoldingPenRequiredSelector = (state) =>
  _.get(baseSelector(state), 'verificationStatus.individual.holdingPenRequired', false);

const customerKyc3HoldingPenRequiredSelector = (state) =>
  _.get(baseSelector(state), 'verificationStatus.company.holdingPenRequired', false);

const customerKyc2RejectedSelector = (state) =>
  !customerKyc2InProgressSelector(state) &&
  !customerKyc2ApprovedSelector(state) &&
  customerKyc2SubmittedSelector(state);

const customerKyc3RejectedSelector = (state) =>
  !customerKyc3InProgressSelector(state) &&
  !customerKyc3ApprovedSelector(state) &&
  customerKyc3SubmittedSelector(state);

const customerIsCompanySelector = (state) => baseSelector(state).isCompany || false;

const customerCompanyNameSelector = (state) => baseSelector(state).companyName || '';

const customerRequireExtendedKycSelector = (state) =>
  baseSelector(state).requireExtendedKyc || false;

const customerSubmittedExtendedKyc = (state) => baseSelector(state).extendedKycSubmitted || false;

const customerExtendedKycVerified = (state) => baseSelector(state).extendedKycVerified || false;

const customerExtendedKycRejected = (state) => baseSelector(state).extendedKycRejected || false;

const isCustomerExtendedKycRequirementLoading = (state) =>
  baseSelector(state).ui.getExtendedKycRequirement.loading;

const customerIsKycCompleted = (state) => {
  const isKycCompleted =
    (!customerIsCompanySelector(state) && customerKyc2ApprovedSelector(state)) ||
    customerKyc3ApprovedSelector(state);
  const isExtendedKycCompleted =
    !customerRequireExtendedKycSelector(state) || customerExtendedKycVerified(state);
  return isKycCompleted && isExtendedKycCompleted;
};

const customerTaxNumbersSelector = (state) => baseSelector(state).taxNumbers;

export const buildCompleteAddress = (fields, fieldPrefix = '') =>
  [
    fields[`${fieldPrefix}address-line-1`],
    fields[`${fieldPrefix}address-line-2`],
    fields[`${fieldPrefix}city`],
    fields[`${fieldPrefix}state`],
    fields[`${fieldPrefix}post-code`],
  ]
    .filter((add) => !!add)
    .join(', ');

// To accomodate pre KYC V2 customers who previously submitted line 2 details
const buildSingleLineAddress = (fields, fieldPrefix = '') =>
  [fields[`${fieldPrefix}address-line-1`], fields[`${fieldPrefix}address-line-2`]]
    .filter((add) => !!add)
    .join(', ');

const buyerCheckoutDetailsSelector = (state) => {
  const customerDetails = baseSelector(state);
  if (
    !customerDetails.firstName &&
    !customerDetails.lastName &&
    !customerDetails.personalAddress.line1 &&
    !customerDetails.personalAddress.country
  ) {
    return null;
  }
  let buyerDetails = {};

  // extract address and name data
  buyerDetails = {
    ...buyerDetails,
    ...extractData(buyerDetailsMappingSchema, customerDetails),
  };
  // extract country (from full name to alpha2)
  if (customerDetails.personalAddress.country) {
    const country = customerDetails.personalAddress.country;
    buyerDetails.country = lookupCountry(country).alpha2 || 'ZZ';
  }
  if (customerDetails.companyAddress.country) {
    const companyCountry = customerDetails.companyAddress.country;
    buyerDetails['company-country'] = lookupCountry(companyCountry).alpha2 || 'ZZ';
  }
  // extract DOB
  if (customerDetails.dateOfBirth) {
    buyerDetails = {
      ...buyerDetails,
      'date-of-birth': verifyDateOfBirth(customerDetails.dateOfBirth),
    };
  }
  // extract phone number
  if (customerDetails.phoneNumber) {
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    const parsedPhone = parsePhoneNumber(
      customerDetails.phoneNumber,
      CountryToAlpha2[customerDetails.personalAddress.country]
    );
    const primaryPhoneNumber = parsedPhone ? String(parsedPhone.getNationalNumber()) : '';
    const primaryPhoneCountry = parsedPhone
      ? Alpha2ToAlpha3[phoneUtil.getRegionCodeForNumber(parsedPhone)]
      : '';
    buyerDetails = {
      ...buyerDetails,
      'primary-phone-number': primaryPhoneNumber,
      'primary-phone-country': primaryPhoneCountry,
      'complete-primary-phone-number': customerDetails.phoneNumber,
    };
  }
  // extract vat details
  let vatCountryDefault = buyerDetails.country || buyerDetails['company-country'];
  const alternateAlpha2Map = invert(AlternateAlpha2ToAlpha2);
  vatCountryDefault = alternateAlpha2Map[vatCountryDefault] || vatCountryDefault;
  const custVatNumbers = (customerTaxNumbersSelector(state) || []).filter((n) => n.type === 'vat');
  // remove the alpha-2 code
  const existingVatNumber = custVatNumbers[0] ? custVatNumbers[0].number.slice(2) : undefined;
  const existingVatCountry = custVatNumbers[0] ? custVatNumbers[0].number.slice(0, 2) : undefined;
  buyerDetails = {
    ...buyerDetails,
    'vat-country-code': existingVatCountry || vatCountryDefault,
    'vat-number': existingVatNumber,
  };
  // generate pre-fills for AddressFieldSet
  buyerDetails.address = buildSingleLineAddress(buyerDetails);
  buyerDetails[`company-address`] = buildSingleLineAddress(buyerDetails, 'company-');
  buyerDetails[`complete-address`] = buildCompleteAddress(buyerDetails);
  buyerDetails[`company-complete-address`] = buildCompleteAddress(buyerDetails, 'company-');
  // check if the customer is acting as a company
  buyerDetails.company = Boolean(customerDetails.isCompany);
  buyerDetails.lock_email = Boolean(customerDetails.lock_email);

  return buyerDetails;
};

const customerDetailsLoadingSelector = (state) =>
  baseSelector(state).ui.v4GetCustomer.loading ||
  baseSelector(state).ui.v2GetCustomer.loading ||
  baseSelector(state).ui.v4DeleteTaxNumber.loading;

const partnerLogosLoadingSelector = (state) => baseSelector(state).ui.partnerLogos.loading;

const custIdSelector = (state) => baseSelector(state).custId;

const custEmailSelector = (state) => baseSelector(state).custEmail;

const custFullNameSelector = (state) => {
  const customerDetails = baseSelector(state);
  if (!customerDetails.firstName && !customerDetails.lastName) return null;
  return `${customerDetails.firstName} ${customerDetails.lastName}`;
};

const customerDateOfBirthSelector = (state) => verifyDateOfBirth(baseSelector(state).dateOfBirth);

const customerDisplayNameSelector = (state) => baseSelector(state).displayName;

const customerAddressLine1Selector = (state) => baseSelector(state).personalAddress.line1;

const customerAddressLine2Selector = (state) => baseSelector(state).personalAddress.line2;

const customerAddressCitySelector = (state) => baseSelector(state).personalAddress.city;

const customerAddressStateSelector = (state) => baseSelector(state).personalAddress.state;

const customerAddressCountryNameSelector = (state) => baseSelector(state).personalAddress.country;

const customerCompanyAddressLine1Selector = (state) => baseSelector(state).companyAddress.line1;

const customerCompanyAddressLine2Selector = (state) => baseSelector(state).companyAddress.line2;

const customerCompanyAddressCitySelector = (state) => baseSelector(state).companyAddress.city;

const customerCompanyAddressStateSelector = (state) => baseSelector(state).companyAddress.state;

const customerCompanyAddressPostCodeSelector = (state) => baseSelector(state).companyAddress.zip;

const customerCompanyAddressCountryNameSelector = (state) =>
  baseSelector(state).companyAddress.country;

const customerCountrySelector = (state) =>
  CountryToAlpha2[_.get(baseSelector(state), 'personalAddress.country', undefined)] ||
  Alpha3ToAlpha2[window.config.geoip_country] ||
  undefined;

const customerCompanyCountrySelector = (state) =>
  CountryToAlpha2[_.get(baseSelector(state), 'companyAddress.country', undefined)] || undefined;

const partnerLogosSelector = (state) => baseSelector(state).partnerLogos;

const isCustomerReadySelector = (state) =>
  AuthenticationStore.isAuthenticated() &&
  !customerDetailsLoadingSelector(state) &&
  Boolean(custIdSelector(state));

const CustomerV4GetStatusSelector = (state) => baseSelector(state).ui.v4GetCustomer.success;

const customerSavedCreditCardsSelector = (state) => baseSelector(state).savedCreditCards;
const customerSavedCardsLoadingSelector = (state) =>
  baseSelector(state).ui.savedCreditCards.loading;
const customerSavedCardsCalledOnceSelector = (state) =>
  baseSelector(state).ui.savedCreditCards.calledOnce;

const customerEKycSelector = (state) =>
  _.get(baseSelector(state), 'verificationStatus.individual.electronicVerification', {});

const customerEKycAttemptsSelector = (state) => customerEKycSelector(state).attempts;

const customerEKycEligibilitySelector = (state) => customerEKycSelector(state).eligibility;

const customerEKycFieldsSelector = (state) => customerEKycSelector(state).submittedFields;

const customerEKycAllowedSelector = (state, userCountry) => {
  const isCountrySupported = countryHasDocuments(userCountry || customerCountrySelector(state));
  const tierAllowed = !customerKyc2ApprovedSelector(state);

  return isCountrySupported && tierAllowed;
};

const customerEKycDataLoading = (state) => baseSelector(state).ui.ekycData.loading;
const customerEKycDataSuccess = (state) => baseSelector(state).ui.ekycData.success;

const customerKycDocumentsSubmittedIsReady = (state) =>
  'hasDocumentsSubmitted' in _.get(baseSelector(state), 'verificationStatus.individual', {});

const customerVerificationStatusLoading = (state) =>
  baseSelector(state).ui.verificationStatus.loading;
const customerVerificationStatusSuccess = (state) =>
  baseSelector(state).ui.verificationStatus.success;

const customerEKycDisabledSelector = (state) =>
  customerEKycAttemptsSelector(state) >= EKYC_MAX_ATTEMPTS;
const customerSavedPlaidAccountsSelector = (state) => baseSelector(state).savedPlaidAccounts;
const customerSavedPlaidLoadingSelector = (state) =>
  baseSelector(state).ui.savedPlaidAccounts.loading;
const customerSavedPaymentLoadingSelector = (state) =>
  customerSavedCardsLoadingSelector(state) || customerSavedPlaidLoadingSelector(state);

const customerEmailVerificationSending = (state) =>
  baseSelector(state).ui.emailVerification.loading;
const customerEmailVerificationSent = (state) => baseSelector(state).ui.emailVerification.success;

const customerEmailVerificationStatusSelector = (state) =>
  _.get(baseSelector(state), 'emailVerification.verified', false);

const customerPhoneNumberSelector = (state) => {
  const customerDetails = baseSelector(state);
  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  const parsedPhone = parsePhoneNumber(
    customerDetails.phoneNumber,
    CountryToAlpha2[customerDetails.personalAddress.country]
  );
  const primaryPhoneNumber = parsedPhone ? String(parsedPhone.getNationalNumber()) : '';
  const primaryPhoneCountry = parsedPhone
    ? Alpha2ToAlpha3[phoneUtil.getRegionCodeForNumber(parsedPhone)]
    : '';

  return {
    phoneNumber: primaryPhoneNumber,
    phoneCountry: primaryPhoneCountry,
  };
};

const customerKycUiSelector = (state) => baseSelector(state).ui.kyc;
const customerKycLoadingUiSelector = (state) => customerKycUiSelector(state).loading;
const customerKycSuccessUiSelector = (state) => customerKycUiSelector(state).success;
const customerKycPagesSelector = (state) => customerKycUiSelector(state).pages;
const customerKycInitPagesSelector = (state) => customerKycUiSelector(state).initPages;
const customerKycHasPrevPagesSelector = (state) => customerKycPagesSelector(state).length > 1;
const customerKycCurrentPageSelector = (state) => customerKycUiSelector(state).pages.slice(-1)[0];
const customerKycSubheaderTextSelector = (state) => customerKycUiSelector(state).subheaderText;
const customerKycErrorAlertSelector = (state) => customerKycUiSelector(state).error;
const customerKycUploadedFilesSelector = (state) => customerKycUiSelector(state).uploads;
const customerKycFileForProofSelector = (state, proofFor) =>
  customerKycUiSelector(state).uploads[proofFor] || {};

const customerKycSubmitLoadingSelector = (state) => baseSelector(state).ui.kycSubmit.loading;
const customerKycSubmitSuccessSelector = (state) => baseSelector(state).ui.kycSubmit.success;

export {
  baseSelector as customerSelector,
  areVerificationDetailsEmptySelector,
  customerKyc1VerifiedSelector,
  customerKyc2SubmittedSelector,
  customerKyc3SubmittedSelector,
  customerKyc2IsLocallySubmittedSelector,
  customerKyc3IsLocallySubmittedSelector,
  customerKyc2ApprovedSelector,
  customerKyc2InProgressSelector,
  customerHasDocumentsSubmittedSelector,
  customerKyc3ApprovedSelector,
  customerKyc3InProgressSelector,
  customerEKycFieldsSelector,
  customerEKycAllowedSelector,
  customerEKycDisabledSelector,
  customerEKycDataLoading,
  customerEKycDataSuccess,
  customerVerificationStatusLoading,
  customerVerificationStatusSuccess,
  customerKyc2HoldingPenRequiredSelector,
  customerKyc3HoldingPenRequiredSelector,
  customerKyc2RejectedSelector,
  customerKyc3RejectedSelector,
  customerIsKycCompleted,
  isCustomerExtendedKycRequirementLoading,
  customerIsCompanySelector,
  customerCompanyNameSelector,
  buyerCheckoutDetailsSelector,
  customerDetailsLoadingSelector,
  customerTaxNumbersSelector,
  custIdSelector,
  custEmailSelector,
  custFullNameSelector,
  customerDateOfBirthSelector,
  customerAddressLine1Selector,
  customerAddressLine2Selector,
  customerAddressCitySelector,
  customerAddressStateSelector,
  customerAddressCountryNameSelector,
  customerCompanyAddressLine1Selector,
  customerCompanyAddressLine2Selector,
  customerCompanyAddressCitySelector,
  customerCompanyAddressStateSelector,
  customerCompanyAddressPostCodeSelector,
  customerCompanyAddressCountryNameSelector,
  customerCountrySelector,
  customerCompanyCountrySelector,
  partnerLogosSelector,
  partnerLogosLoadingSelector,
  isCustomerReadySelector,
  customerRequireExtendedKycSelector,
  customerSavedCreditCardsSelector,
  customerSavedCardsLoadingSelector,
  customerSavedCardsCalledOnceSelector,
  customerSubmittedExtendedKyc,
  customerExtendedKycVerified,
  customerExtendedKycRejected,
  customerDisplayNameSelector,
  customerSavedPlaidAccountsSelector,
  customerSavedPlaidLoadingSelector,
  customerSavedPaymentLoadingSelector,
  customerPhoneNumberSelector,
  customerEmailVerificationSending,
  customerEmailVerificationSent,
  customerEmailVerificationStatusSelector,
  CustomerV4GetStatusSelector,
  customerKycLoadingUiSelector,
  customerKycSuccessUiSelector,
  customerKycPagesSelector,
  customerKycInitPagesSelector,
  customerKycHasPrevPagesSelector,
  customerKycCurrentPageSelector,
  customerKycSubheaderTextSelector,
  customerKycErrorAlertSelector,
  customerKycSubmitLoadingSelector,
  customerKycSubmitSuccessSelector,
  customerKycUploadedFilesSelector,
  customerKycFileForProofSelector,
  customerKycDocumentsSubmittedIsReady,
  customerEKycEligibilitySelector,
};
