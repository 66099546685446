import _ from 'lodash';
import createElement from 'virtual-dom/create-element';
import deepcopy from 'clone';
import diff from 'virtual-dom/diff';
import patch from 'virtual-dom/patch';
import virtualize from '../utils/virtualize';

import { EventHandler } from './EventHandler';
import { updateChildrenCount } from './vdom-hacks';

/**
 * This is the main class that we use for rendering components on the page. It
 * is inspired by React Components, but has a few differences.
 *
 * - It uses virtual-dom for it's shadow dom, patching and updating.
 * - It isn't restricted to using jsx, you if you can generate virtual dom
 *   nodes, you can use this class. Even if you only have raw html, you can then
 *   use the `virtualize` module to convert that to vdom nodes.
 */
export default class Component {
  constructor() {
    /**
     * This contains the reference to the DOM element that this component is
     * interacting with.
     *
     * @protected
     * @type {Element}
     */
    this.rootNode = undefined;
    /**
     * This contains the current state of the Component. This should only be
     * updated via 'setState', as calling that will also mean the component will
     * re-render.
     *
     * @type {Object}
     */
    this.state = {};
    /**
     * This is the parent of the component. This is optional and is only
     * required if you're using subcomponents.
     *
     * This gets set when you call mountAsChild()
     */
    this.parent = null;
    this._eventHandler = new EventHandler();
    this._mounted = false;
    this._debouncedRender = _.debounce(this._render.bind(this), 100);
  }

  /**
   * This will set the state for the component. It will also cause a re-render
   * (not a full re-render, but it will cause a comparison of virtual-doms)
   *
   * @param {Object} state The new state object
   * @param Bool forceRender Set to true if you want to force a render
   * regardless of whether there was any change in state detected.
   * @param Bool debounceRender Will render 100ms after the last time this
   * function is called with debounceRender = true
   */
  setState(state, forceRender, debounceRender = false) {
    const original = deepcopy(this.state);
    Object.assign(this.state, state);

    // Only re-render the component if the component is mounted and the state
    // has changed.
    //
    // Only re rendering when the state has changed saves us many costly render
    // cycles, as generating the templates can be quite slow.
    if (this._mounted && (forceRender || !_.isEqual(original, this.state))) {
      this.componentWillUpdate();
      // If we have a parent, get the parent to render us.
      if (this.parent) {
        this.parent._render();
      } else if (debounceRender) {
        this._debouncedRender();
      } else {
        this._render();
      }

      this.componentDidUpdate(original);
    }
  }

  addEventListener(event, selector, func) {
    return this._eventHandler.addEventListener(event, selector, func);
  }

  removeEventListeners(event, selector) {
    return this._eventHandler.removeEventListeners(event, selector);
  }

  _render() {
    const newTree = this.render();
    updateChildrenCount(newTree);

    if (!this._tree) {
      this._tree = newTree;
    }
    if (!this.rootNode) {
      this.rootNode = createElement(newTree);
    }

    const patches = diff(this._tree, newTree);
    this.rootNode = patch(this.rootNode, patches);
    this._tree = newTree;
  }

  /**
   * This will append the component to the given element.
   *
   * @param {Element} element The DOM node that the component will be appended
   * to
   */
  placeInto(element) {
    this._render();

    this.componentWillMount();

    element.innerHTML = '';
    element.appendChild(this.rootNode);

    this._eventHandler.setEventListeners(this.rootNode);
    this._mounted = true;
    this.componentHasMounted();
    this.componentDidUpdate();
  }

  /**
   * This will replace the given element with the component.
   *
   * This will first virtualize the html for the current element, then it will
   * create perform patches based on the diffing algorithm. Therefore if there
   * are no changes to be made (or only minimal changes) the entire element will
   * not be removed from the page.
   *
   * @param {Element} element The element you wish to replace with this
   * component
   *
   * @param {Vtree} element If you have already created a vtree representation
   * of the DOM, then you can pass it in here so that the Component won't have
   * to virtualise the element again.
   */
  replace(element, vtree) {
    if (vtree) {
      this._tree = vtree;
    } else {
      this._tree = virtualize(element);
    }
    this.rootNode = element;

    this.componentWillMount();
    this._render();
    this._eventHandler.setEventListeners(this.rootNode);
    this._mounted = true;
    this.componentHasMounted();
    this.componentDidUpdate();
  }

  /**
   * This will "mount" the component as a child component.
   *
   * This is a nasty hack, as it needs to be run during 'componentHadMounted'
   * from the parent component.
   *
   * We need to revisit this, and make it nicer.
   */
  mountAsChild(parent, container) {
    this.parent = parent;
    this.componentWillMount();
    this.rootNode = container;
    this.parent._render();
    this._eventHandler.setEventListeners(this.rootNode);
    this._mounted = true;
    this.componentHasMounted();
  }

  /**
   * This method will be called just before the component is inserted into the
   * dom tree.
   */
  componentWillMount() {}
  /**
   * This method will be called after the component has been inserted into the
   * page and all of the event listeners have been registered.
   *
   * This is where you should register your listeners to your Flux stores.
   */
  componentHasMounted() {}
  /**
   * This method will be called before the component is removed from the page.
   *
   * This is where you should deregister your listeners on your Flux stores.
   *
   * NOTE: this is not currently used anywhere, but will be used when
   * hot-reloading of the components with webpack is implemented.
   */
  componentWillDismount() {}

  componentWillUpdate() {}

  componentDidUpdate() {}
}
