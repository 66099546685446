import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'spa/components/Icon';
import A from 'spa/components/A';

const NavLink = ({ navLink: { label, iconPath, link }, location }) => (
  <A
    className={classnames('integrationsPortal-nav-link', {
      'is-active': location.pathname === link.route,
    })}
    data-sidebar-toggle
    link={link}
    role="button"
    tabIndex={0}
  >
    <span className="integrationsPortal-nav-iconHolder">
      {iconPath && <Icon name={iconPath} className="integrationsPortal-nav-icon" />}
    </span>
    <span>{label}</span>
  </A>
);

NavLink.propTypes = {
  navLink: PropTypes.shape({
    label: PropTypes.string,
    iconPath: PropTypes.string,
    link: PropTypes.shape({
      type: PropTypes.string,
      route: PropTypes.string,
    }).isRequired,
  }),
};

export default NavLink;
