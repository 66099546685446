import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import MediaQueryConstants from 'spa/constants/MediaQueryConstants';
import HeaderV3 from 'spa/containers/HeaderV3';
import { TransactionTabs, TransactionTable } from 'spa/containers/Transactions';
import { DEFAULT_ESCROW_PAGE } from 'spa/constants/HeaderConstants';
import { tourStepSelector } from 'spa/selectors/TourStepSelector';
import TransactionConstants from 'spa/constants/TransactionConstants';
import CoverModal from 'spa/components/TransactionsTour/CoverModal';
import {
  WelcomeSlide,
  TourStepSlide,
} from 'spa/components/TransactionsTour/TransactionTourSlidesMobile';
import { MobileSliderWrapper } from 'spa/components/Slider/index';
import { initTour, nextStep, finishTour, jumpToStep } from 'spa/actions/TourActions';
import { tourStatusSelector } from 'spa/selectors/TransactionSelectors';
import {
  checkTourStatus as checkTourStatusRoutine,
  setToured as setTouredRoutine,
} from 'spa/actions/TransactionActions';
import { gettext } from '../../../utils/filters';

const TransactionsPage = ({
  tourInit,
  jumpToTourStep,
  closeTour,
  currentStep,
  shouldShowTour,
  checkTourStatus,
  setV2Toured,
}) => {
  const { TOUR_STEP } = TransactionConstants;
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    checkTourStatus();
  }, [checkTourStatus]);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [showModal]);

  useEffect(() => {
    if (shouldShowTour) {
      setShowModal(true);
    }
  }, [shouldShowTour]);

  const onModalClose = () => {
    setShowModal(false);
    setV2Toured();
    tourInit();
  };

  const onTourSkip = () => {
    setShowModal(false);
    setV2Toured();
  };

  const onMobileSlideChange = (slideIndex) => {
    if (slideIndex === TOUR_STEP.OUTSTANDING) {
      if (currentStep === TOUR_STEP.READY) {
        tourInit();
        setV2Toured();
      } else {
        jumpToTourStep(TOUR_STEP.OUTSTANDING);
      }
    } else if (slideIndex === TOUR_STEP.DONE) {
      setShowModal(false);
      closeTour();
    } else {
      jumpToTourStep(slideIndex);
    }
  };

  return (
    <Fragment>
      <MediaQuery minWidth={MediaQueryConstants.MIN_BREAKPOINT.DESKTOP}>
        <CoverModal
          onClose={onTourSkip}
          onNext={onModalClose}
          display={showModal && currentStep === TOUR_STEP.READY}
        />
      </MediaQuery>
      <MobileSliderWrapper display={showModal} onSlideChange={onMobileSlideChange}>
        <WelcomeSlide skipTour={onTourSkip} />
        <TourStepSlide step={TOUR_STEP.OUTSTANDING} />
        <TourStepSlide step={TOUR_STEP.OPEN} />
        <TourStepSlide step={TOUR_STEP.CLOSED} />
        <TourStepSlide step={TOUR_STEP.FILTER} />
        <TourStepSlide step={TOUR_STEP.SORT} />
      </MobileSliderWrapper>
      <HeaderV3 title={gettext('My Transactions')} pageType={DEFAULT_ESCROW_PAGE} />
      <div className="transactions">
        <header className="transactions-header">
          <h1 className="transactions-title">{gettext('My Transactions')}</h1>
          <TransactionTabs />
        </header>
        <div className="section--small section-container">
          <TransactionTable />
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  currentStep: tourStepSelector(state, TransactionConstants.TOUR_NAME),
  shouldShowTour: !tourStatusSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  checkTourStatus: () => dispatch(checkTourStatusRoutine.trigger()),
  setV2Toured: () => dispatch(setTouredRoutine.trigger()),
  tourInit: () => dispatch(initTour(TransactionConstants.TOUR_NAME)),
  nextTourStep: () => dispatch(nextStep(TransactionConstants.TOUR_NAME)),
  jumpToTourStep: (step) => dispatch(jumpToStep(TransactionConstants.TOUR_NAME, step)),
  closeTour: () => dispatch(finishTour(TransactionConstants.TOUR_NAME)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsPage);
