import deepcopy from 'clone';

import TransactionConstants from 'spa/constants/TransactionConstants';
import { selectPaymentMethod as selectPaymentMethodAction } from 'spa/actions/PaymentsActions';
import {
  getTransactions,
  checkTourStatus as checkTourStatusAction,
  setToured as setTouredAction,
  getTransactionById as getTransactionByIdAction,
  getPaymentMethods as getPaymentMethodsAction,
  getCreditCardPageData as getCreditCardPageDataAction,
  getAdyenMethods as getAdyenMethodsAction,
} from 'spa/actions/TransactionActions';
import PaymentConstants from 'spa/constants/PaymentConstants';

const initialState = {
  error: null,
  loading: true,
  transactions: [],
  transactionsById: {},
  toured: true,
  filters: {
    id: null,
    limit: TransactionConstants.LOAD_MORE_INCREMENT,
    role: [],
    type: [],
    amount: { min: null, max: null },
    status: undefined,
    nextCursor: null,
    sortBy: 'id',
    sortDirection: 'desc',
  },
  totalCount: 0,
  draft: {
    parties: [],
    currency: 'usd', // 'usd' | 'euro'
    description: '', // str
    source: '', // 'draft' | 'transaction'
    items: [],
    transaction: {}, // V4 create transaction response
  },
  ui: {
    selectPaymentMethod: {
      success: false,
      error: false,
      errorMessage: '',
    },
    getPaymentMethods: {
      loading: true,
      error: false,
      errorMessage: '',
      errorType: '',
    },
    getTransactionById: {
      loading: true,
      error: false,
      errorMessage: '',
      errorType: '',
    },
    getCreditCardPageData: {
      loading: true,
      error: false,
      errorMessage: '',
    },
    getAdyenMethods: {
      loading: true,
      error: false,
      errorMessage: '',
      errorType: '',
    },
  },
};

function transactionReducer(state = initialState, action) {
  let draft = {};
  let buyer = {};
  let oldBuyerEmail = '';
  switch (action.type) {
    case getTransactions.TRIGGER:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case getTransactions.SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        totalCount: action.payload.refresh ? action.payload.count : state.totalCount,
        filters: {
          ...state.filters,
          nextCursor: action.payload.next_cursor
            ? action.payload.next_cursor[action.payload.next_cursor.length - 1]
            : null,
        },
        transactions: [
          ...(action.payload.refresh ? [] : state.transactions),
          ...action.payload.transactions,
        ],
      };
    case getTransactions.FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case TransactionConstants.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters,
        },
      };
    case getPaymentMethodsAction.TRIGGER:
      return {
        ...state,
        ui: {
          ...state.ui,
          getPaymentMethods: {
            loading: true,
            error: false,
            errorMessage: '',
            errorType: '',
          },
        },
      };
    case getPaymentMethodsAction.SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          getPaymentMethods: {
            ...state.ui.getPaymentMethods,
            loading: false,
          },
        },
        transactionsById: {
          [action.payload.transactionId]: {
            ...state.transactionsById[action.payload.transactionId],
            paymentMethods: action.payload.paymentsData,
          },
        },
      };
    case getPaymentMethodsAction.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          getPaymentMethods: {
            error: true,
            loading: false,
            errorMessage: action.payload.errorMessage,
            errorType: action.payload.errorType,
          },
        },
      };
    case selectPaymentMethodAction.SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          selectPaymentMethod: {
            success: true,
            error: false,
            errorMessage: '',
          },
        },
      };
    case getAdyenMethodsAction.TRIGGER:
      return {
        ...state,
        ui: {
          ...state.ui,
          getAdyenMethods: {
            loading: true,
            error: false,
            errorMessage: '',
            errorType: '',
          },
        },
      };
    case getAdyenMethodsAction.SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          getAdyenMethods: {
            ...state.ui.getAdyenMethods,
            loading: false,
          },
        },
        transactionsById: {
          ...state.transactionsById,
          [action.payload.transactionId]: {
            ...state.transactionsById[action.payload.transactionId],
            adyenData: {
              ...state.transactionsById[action.payload.transactionId].adyenData,
              ...action.payload.adyenData,
            },
          },
        },
      };
    case getAdyenMethodsAction.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          getAdyenMethods: {
            error: true,
            loading: false,
            errorMessage: action.payload.errorMessage,
            errorType: action.payload.errorType,
          },
        },
      };
    case PaymentConstants.SET_ADYEN_DATA:
      return {
        ...state,
        transactionsById: {
          ...state.transactionsById,
          [action.payload.transactionId]: {
            ...state.transactionsById[action.payload.transactionId],
            adyenData: {
              ...state.transactionsById[action.payload.transactionId].adyenData,
              ...action.payload.adyenData,
            },
          },
        },
      };
    case selectPaymentMethodAction.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          selectPaymentMethod: {
            error: true,
            errorMessage: action.payload,
          },
        },
      };
    case TransactionConstants.SET_TRANSACTION_DRAFT:
      return {
        ...state,
        draft: action.draft,
      };
    case TransactionConstants.CHANGE_DRAFT_BUYER_EMAIL:
      draft = deepcopy(state.draft);
      buyer = (draft.parties || []).find((party) => party.role === 'buyer');
      oldBuyerEmail = buyer.customer;
      buyer.customer = action.email;
      for (const item of draft.items) {
        for (const fee of item.fees) {
          if (fee.payer_customer === oldBuyerEmail) {
            fee.payer_customer = action.email;
          }
        }
        for (const schedule of item.schedule) {
          if (schedule.payer_customer === oldBuyerEmail) {
            schedule.payer_customer = action.email;
          }
          if (schedule.beneficiary_customer === oldBuyerEmail) {
            schedule.beneficiary_customer = action.email;
          }
        }
      }
      return {
        ...state,
        draft: draft,
      };
    case getTransactionByIdAction.TRIGGER:
      return {
        ...state,
        ui: {
          ...state.ui,
          getTransactionById: {
            error: false,
            loading: true,
            errorMessage: '',
            errorType: '',
          },
        },
      };
    case getTransactionByIdAction.SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          getTransactionById: {
            ...state.ui.getTransactionById,
            loading: false,
          },
        },
        loading: false,
        transactionsById: {
          [action.payload.id]: action.payload,
        },
      };
    case getTransactionByIdAction.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          getTransactionById: {
            error: true,
            loading: false,
            errorMessage: action.payload.errorMessage,
            errorType: action.payload.errorType,
          },
        },
      };
    case getCreditCardPageDataAction.TRIGGER:
      return {
        ...state,
        ui: {
          ...state.ui,
          getCreditCardPageData: {
            error: false,
            loading: true,
            errorMessage: '',
          },
        },
      };
    case getCreditCardPageDataAction.SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          getCreditCardPageData: {
            ...state.ui.getCreditCardPageData,
            loading: false,
          },
        },
      };
    case getCreditCardPageDataAction.FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          getCreditCardPageData: {
            error: true,
            loading: false,
            errorMessage: action.payload,
          },
        },
      };
    case checkTourStatusAction.SUCCESS:
      return {
        ...state,
        toured: action.payload,
      };
    case setTouredAction.SUCCESS:
      return {
        ...state,
        toured: true,
      };
    case setTouredAction.FAILURE:
      return {
        ...state,
        toured: action.payload.message === TransactionConstants.TOUR_FLAG_ADDING.SKIPPED_MESSAGE,
      };
    default:
      return state;
  }
}

export default transactionReducer;
