import window from 'window-shim';

import API from '../api';
import { fetchRequest } from '../utils/FetchUtils';
import AppDispatcher from '../dispatcher/AppDispatcher';
import { ChangeEmitter } from '../utils/ChangeEmitter';
import OlarkApi from '../utils/OlarkApi';
import OlarkActions from '../actions/OlarkActions';
import OlarkConstants from '../constants/OlarkConstants';

import AuthenticationStore from '../stores/AuthenticationStore';

export class OlarkStore extends ChangeEmitter {
  constructor() {
    super();

    this.operatorGroupState = {};
    this.currentGroup = undefined;
    this.shouldDisplay = false;
    this.isDisplayed = false;
  }

  // Registers callbacks for olark operator state change
  _registerOperatorStatusCallbacks() {
    if (OlarkApi.isAvailable()) {
      OlarkApi.onOperatorsAvailable(() => {
        for (const groupName of Object.keys(this.operatorGroupState)) {
          this.operatorGroupState[groupName] = true;
        }
        this.emitChange();
      });
      OlarkApi.onOperatorsAway(() => {
        for (const groupName of Object.keys(this.operatorGroupState)) {
          this.operatorGroupState[groupName] = false;
        }
        this.emitChange();
      });
    }
  }

  _initialiseGroup(groupName, groupId) {
    this.currentGroup = groupName;
    OlarkApi.setOperatorGroup(groupId);
    this.operatorGroupState[groupName] = false;
    return OlarkApi.isOperatorGroupAvailable(groupId).then((isAvailable) => {
      this.operatorGroupState[groupName] = isAvailable;
      setTimeout(() => {
        OlarkActions.startOlark(groupName);
      });
    });
  }

  _startOlark(groupName) {
    if (this.isOperatorAvailable()) {
      switch (groupName) {
        case OlarkConstants.GENERAL_GROUP:
          this.setShouldDisplay(false);
          break;
        case OlarkConstants.HOMEPAGE_GROUP:
        case OlarkConstants.VT_GROUP:
        case OlarkConstants.CHECKOUT_GROUP:
        case OlarkConstants.MY_TRANSACTIONS_GROUP:
        case OlarkConstants.START_TRANSACTION_GROUP:
        case OlarkConstants.TRANSACTION_GROUP:
          this.setShouldDisplay(true);
          break;
        default:
          break;
      }
    }
  }

  setShouldDisplay(shouldDisplay) {
    this.shouldDisplay = shouldDisplay;
  }

  setDisplayedState(isDisplayed, shouldDisplay) {
    // checks for undefined, ie the param was not specified in the action
    if (isDisplayed != null) {
      this.isDisplayed = isDisplayed;
    }
    if (shouldDisplay != null) {
      this.shouldDisplay = shouldDisplay;
    }
  }

  setIsConversing(isConversing) {
    this.isConversing = isConversing;
  }

  getCurrentGroup() {
    return this.currentGroup;
  }

  isOperatorAvailable() {
    return this.operatorGroupState[this.currentGroup];
  }

  getShouldDisplay() {
    let shouldDisplay = false;
    switch (this.currentGroup) {
      case OlarkConstants.GENERAL_GROUP:
        shouldDisplay = this.isConversing;
        break;
      case OlarkConstants.HOMEPAGE_GROUP:
      case OlarkConstants.VT_GROUP:
      case OlarkConstants.CHECKOUT_GROUP:
        shouldDisplay = this.shouldDisplay;
        break;
      default:
        break;
    }
    return shouldDisplay;
  }

  getIsDisplayed() {
    return this.isDisplayed;
  }

  getIsConversing() {
    return this.isConversing;
  }

  /**
   * Get a new token from the .net api for sending secure messages
   */
  getSecureToken() {
    const customerID = AuthenticationStore.getCustomerID();
    return new Promise((onResolve, onReject) => {
      fetchRequest(
        'POST',
        `${window.config.customer_api_endpoint}/${customerID}/token/olarkkyc`,
        ''
      )
        .then((response) => {
          response
            .json()
            .then((jsonData) => {
              onResolve(jsonData.Token);
            })
            .catch((err) => {
              onReject(err);
            });
        })
        .catch((err) => {
          onReject(err);
        });
    });
  }

  notifyKYCSubmission() {
    if (!OlarkApi.isAvailable()) {
      return;
    }
    OlarkApi.showConsole();
    this.getSecureToken().then((token) => {
      API.getOlarkKycUrl().then((kycUrl) => {
        const customerID = AuthenticationStore.getCustomerID();
        const url = `${kycUrl}/${customerID}?token=${token}`;
        OlarkApi.notifyOperator(url);
      });
    });
  }

  handleViewAction(action) {
    const actionType = action.actionType;
    if (actionType === OlarkConstants.REGISTER_OPERATOR_STATUS_CALLBACKS) {
      if (OlarkApi.isAvailable()) {
        this._initialiseGroup(action.attributes.groupName, action.attributes.groupId);
      }
    } else if (actionType === OlarkConstants.START_OLARK) {
      this._startOlark(action.attributes.groupName);
      this.emitChange();
      this._registerOperatorStatusCallbacks();
    } else if (actionType === OlarkConstants.SET_DISPLAYED_STATE) {
      this.setDisplayedState(action.attributes.isDisplayed);
      this.emitChange();
    } else if (action.actionType === OlarkConstants.SET_IS_CONVERSING) {
      this.setIsConversing(action.attributes.isConversing);
      this.emitChange();
    }
  }

  handleServerAction(action) {
    const actionType = action.actionType;
    if (actionType === OlarkConstants.NOTIFY_KYC_SUBMISSION) {
      this.notifyKYCSubmission();
    }
  }
}

const olarkStore = new OlarkStore();
olarkStore.dispatchtoken = AppDispatcher.register((payload) => {
  const action = payload.action;
  const source = payload.source;

  if (source === 'VIEW_ACTION') {
    olarkStore.handleViewAction(action);
  } else if (source === 'SERVER_ACTION') {
    olarkStore.handleServerAction(action);
  }
});

export default olarkStore;
