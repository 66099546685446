import React from 'react';
import EscrowTheme from 'spa/components/MaterialUI/Theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { KYCContext, VERIFY_FLOW, CHECKOUT_FLOW, TIER_1_FLOW } from 'spa/context/KYCContext';
import V2KYCContainer from 'spa/containers/KYC/V2';
import V3KYCContainer from 'spa/containers/KYC/V3';
import { initialiseOlarkByPage } from '../../../components/Olark';
import { getWindowUrlParams } from '../../../utils/globals';

class KYCPage extends React.Component {
  constructor(props) {
    super(props);
    initialiseOlarkByPage();
  }

  render() {
    const urlParams = getWindowUrlParams();
    const shadow = urlParams ? urlParams.get('v3_kyc') === 'true' : false;
    const qaEnv = urlParams ? urlParams.get('qa_env') : '';
    if (shadow && qaEnv) {
      document.cookie = `non_default_version=${qaEnv}`;
    }
    let flow = VERIFY_FLOW;
    let redirect;
    if (urlParams) {
      const flowParam = urlParams.get('flow');
      if (flowParam === 'checkout') {
        flow = CHECKOUT_FLOW;
      }
      if (flowParam === 'tier1') {
        flow = TIER_1_FLOW;
      }
      redirect = urlParams.get('redirect');
    }
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <EscrowTheme>
          <KYCContext.Provider value={{ flow: flow, redirect: redirect }}>
            {shadow ? <V3KYCContainer /> : <V2KYCContainer />}
          </KYCContext.Provider>
        </EscrowTheme>
      </LocalizationProvider>
    );
  }
}

export default KYCPage;
