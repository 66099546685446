import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';

export const SmallText = styled(Typography)(({ variant }) => ({
  fontStyle: 'normal',
  fontWeight: variant === 'bold' ? 500 : 400,
  fontSize: 14,
  lineHeight: '143%',
  letterSpacing: '0.15px',
  whiteSpace: 'pre-line',
  color: 'rgba(37, 33, 59, 0.87)',
}));

export const MediumText = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '150%',
  letterSpacing: '0.15px',
  color: theme.palette.secondaryLight.dark,
}));

export const LargeText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '24px',
  lineHeight: '133.4%',
  color: theme.palette.secondary.dark,
}));

const SmallLink = styled(Link)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '143%',
  letterSpacing: '0.15px',
  whiteSpace: 'pre-line',
  color: theme.palette.secondaryLight.main,
}));

SmallLink.defaultProps = {
  ...SmallLink.defaultProps,
  target: '_blank',
  rel: 'noopener noreferrer',
};

export { SmallLink };
