/* eslint-disable no-shadow */
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  twoFAStatusSelector,
  loginLoadingSelector,
  loginSuccessSelector,
} from 'spa/selectors/AuthenticationSelectors';
import {
  transactionDraftSelector,
  draftBuyerDetailsSelector,
} from 'spa/selectors/TransactionSelectors';
import { buyerCheckoutDetailsSelector } from 'spa/selectors/CustomerSelectors';
import {
  checkoutChangeBuyerEmailLoadingSelector,
  checkoutPartnerLoadingSelector,
  checkoutPartnerDataSelector,
} from 'spa/selectors/CheckoutSelectors';
import { useRecaptchaV3 } from 'spa/containers/Authentication/hooks/useRecaptchaV3';
import { modeSelector } from 'spa/selectors/AuthenticationSelectors';
import { setMode, login as loginRoutine } from 'spa/actions/AuthenticationActions';
import AuthenticationForm from 'spa/components/Authentication/AuthenticationForm';
import ConfigCompleteContainer from 'spa/containers/TwoFactorAuthentication/ConfigCompleteContainer';
import InputContainer from 'spa/containers/TwoFactorAuthentication/InputContainer';
import TwoFactorConstants from 'spa/constants/TwoFactorConstants';
import AuthenticationConstants from 'spa/constants/AuthenticationConstants';
import CheckoutConstants from 'spa/constants/CheckoutConstants';
import MethodSelectionForm from 'spa/components/TwoFactorAuthentication/MethodSelectionForm';
import NavigationActions from '../../../actions/NavigationActions';
import AuthenticationStore from '../../../stores/AuthenticationStore';

const AuthenticationContainer = (props) => {
  const {
    loginPage,
    mode,
    setMode,
    onSuccess,
    loading,
    twoFAStatuses,
    emailPasswordVerified,
    lockEmailAddress,
    cancelAuthentication,
    ...additionalProps
  } = props;

  useRecaptchaV3(window.config.recaptcha_site_key);

  const twoFARequired = twoFAStatuses.authenticator || twoFAStatuses.sms;
  const authenticated = AuthenticationStore.isAuthenticated();
  const authenticatedWith2fa =
    emailPasswordVerified &&
    (twoFAStatuses.has_valid_cust_device_token || !Object.values(twoFAStatuses).some((b) => b));
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [isCodeNeeded, setIsCodeNeeded] = useState(false);
  useEffect(() => {
    const options = [
      {
        type: TwoFactorConstants.TYPE_AUTH_APP,
        value: twoFAStatuses.authenticator,
      },
      { type: TwoFactorConstants.TYPE_SMS, value: twoFAStatuses.sms },
    ].filter((o) => o.value);
    if (options.length === 1) {
      setSelectedMethod(options[0].type);
    }
  }, [twoFAStatuses.authenticator, twoFAStatuses.sms, selectedMethod]);

  const redirectPage = useCallback(() => {
    if (mode === AuthenticationConstants.MODE_LOGIN) {
      NavigationActions.loginRedirect();
    } else {
      NavigationActions.signupRedirect();
    }
  }, [mode]);

  const loginComplete = useCallback(() => {
    if (loginPage === AuthenticationConstants.DEFAULT_LOGIN_PAGE) {
      redirectPage();
    } else {
      onSuccess();
    }
  }, [loginPage, redirectPage, onSuccess]);

  const onCancel = () => {
    setIsCodeNeeded(false);
    cancelAuthentication();
  };

  useEffect(() => {
    const modeString = window.location.pathname.split('/').pop();

    switch (loginPage) {
      case AuthenticationConstants.DEFAULT_LOGIN_PAGE:
      case AuthenticationConstants.INTEGRATIONS_LOGIN_PAGE:
        if (modeString.includes('signup')) {
          setMode(AuthenticationConstants.MODE_SIGNUP);
        } else {
          setMode(AuthenticationConstants.MODE_LOGIN);
        }
        break;

      case AuthenticationConstants.CHECKOUT_LOGIN_PAGE:
        if (!additionalProps.checkoutBuyerExists) {
          setMode(AuthenticationConstants.MODE_SIGNUP);
        } else {
          setMode(AuthenticationConstants.MODE_LOGIN);
        }
        break;

      default:
        break;
    }
  }, [additionalProps.checkoutBuyerExists, loginPage, setMode]);

  useEffect(() => {
    if (!authenticatedWith2fa && twoFARequired) {
      setIsCodeNeeded(true);
    }
  }, [authenticatedWith2fa, twoFARequired]);

  useEffect(
    () => {
      if (authenticated) {
        loginComplete();
      }
    },
    [authenticated] // eslint-disable-line  react-hooks/exhaustive-deps
  );

  if (authenticatedWith2fa && isCodeNeeded) {
    return (
      <ConfigCompleteContainer
        configType={selectedMethod}
        actionType={TwoFactorConstants.ACTION_VERIFY}
      />
    );
  }

  // 2fa methods
  if (!authenticatedWith2fa && twoFARequired) {
    if (selectedMethod === null) {
      return <MethodSelectionForm onSubmit={setSelectedMethod} onCancel={onCancel} />;
    }
    return (
      <InputContainer
        navigateMain={() => {
          if (twoFAStatuses.authenticator && twoFAStatuses.sms) {
            setSelectedMethod(null);
          } else {
            onCancel();
          }
        }}
        configType={selectedMethod}
        actionType={TwoFactorConstants.ACTION_VERIFY}
        loading={loading || authenticatedWith2fa}
      />
    );
  }

  return (
    <AuthenticationForm
      loading={loading || emailPasswordVerified || additionalProps.loadingCustomerData}
      loginPage={loginPage}
      lockEmailAddress={lockEmailAddress}
      mode={mode}
      setMode={setMode}
      partner={additionalProps.partner}
      initialValues={additionalProps.initialValues}
    />
  );
};

AuthenticationContainer.propTypes = {
  loginPage: PropTypes.string.isRequired,
  lockEmailAddress: PropTypes.bool,
  onSuccess: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  let additionalProps = {};
  if (ownProps.loginPage === AuthenticationConstants.CHECKOUT_LOGIN_PAGE) {
    const draft = transactionDraftSelector(state);
    const buyer = (draft.parties || []).find((party) => party.role === 'buyer');
    let buyerEmail = buyer ? buyer.customer || '' : '';
    if (buyer && buyer.customer === CheckoutConstants.BUYER_PLACEHOLDER) {
      buyerEmail = '';
    }
    const buyerDetails = buyerCheckoutDetailsSelector(state) || draftBuyerDetailsSelector(state);
    additionalProps = {
      loadingCustomerData:
        checkoutChangeBuyerEmailLoadingSelector(state) || checkoutPartnerLoadingSelector(state),
      partner: checkoutPartnerDataSelector(state),
      checkoutBuyerExists: draft.does_buyer_email_exist || false,
      initialValues: {
        username: buyerEmail,
        ...buyerDetails,
      },
    };
  }
  return {
    mode: modeSelector(state),
    loading: loginLoadingSelector(state),
    emailPasswordVerified: loginSuccessSelector(state),
    twoFAStatuses: twoFAStatusSelector(state),
    ...additionalProps,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setMode: (mode) => dispatch(setMode(mode)),
  cancelAuthentication: () => dispatch(loginRoutine.failure()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationContainer);
