import { getNotificationSettings } from 'spa/actions/NotificationSettingActions';

const initialState = {
  preferences: [],
};

function notificationSettingReducer(state = initialState, action) {
  switch (action.type) {
    case getNotificationSettings.TRIGGER:
      return Object.assign({}, state, {
        display: {
          loading: true,
        },
      });
    case getNotificationSettings.SUCCESS:
      return Object.assign({}, state, {
        display: {
          loading: false,
          success: true,
        },
        preferences: action.payload,
      });
    case getNotificationSettings.FAILURE:
      return Object.assign({}, state, {
        display: {
          loading: false,
          success: false,
        },
      });
    default:
      return state;
  }
}

export default notificationSettingReducer;
