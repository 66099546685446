import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import TransactionDetails from 'spa/components/PartnerDashboard/TransactionDetails';
import {
  transactionsDataSelector,
  transactionHoldingPenDataSelector,
  transactionHoldingPenErrorSelector,
  transactionHoldingPenLoadingSelector,
} from 'spa/selectors/PartnerSelectors';
import {
  getTransactionPartyInfo as getTransactionPartyInfoRoutine,
  getTransactionList as getTransactionListRoutine,
  getTransactionHoldingPenConditions as getTransactionHoldingPenConditionsRoutine,
} from 'spa/actions/PartnerActions';
import { urlFor } from '../../../routeConfig';

const mapStateToProps = (state, ownProps) => {
  const transactionId = parseInt(ownProps.match.params.transaction_id, 10);
  const transaction = transactionsDataSelector(state).find((trans) => trans.id === transactionId);
  return {
    transaction,
    transactionId,
    holdingPen: transactionHoldingPenDataSelector(state, transactionId),
    holdingPenError: transactionHoldingPenErrorSelector(state, transactionId),
    holdingPenLoading: transactionHoldingPenLoadingSelector(state, transactionId),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTransactionData: (payload) => dispatch(getTransactionListRoutine.trigger(payload)),
  handleBack: () => dispatch(push(urlFor('partner_dashboard_transactions'))),
  loadTransactionPartyInfo: (transactionId) =>
    dispatch(getTransactionPartyInfoRoutine.trigger(transactionId)),
  getTransactionHoldingPenConditions: (transactionId) =>
    dispatch(getTransactionHoldingPenConditionsRoutine.trigger({ transactionId })),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TransactionDetails));
