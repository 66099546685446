import additionalDocumentsSaga from 'spa/sagas/AdditionalDocumentsSagas';
import apiIntegrationSaga from 'spa/sagas/APIIntegrationSaga';
import authenticationSaga from 'spa/sagas/AuthenticationSagas';
import navigationSaga from 'spa/sagas/NavigationSaga';
import taskSaga from 'spa/sagas/TaskSaga';
import checkoutSaga from 'spa/sagas/CheckoutSaga';
import transactionSaga from 'spa/sagas/TransactionSaga';
import webhookSaga from 'spa/sagas/WebhooksSaga';
import addDisbursementSaga from 'spa/sagas/DisbursementSaga';
import errorLoggingSaga from 'spa/sagas/ErrorLoggingSaga';
import notificationSettingSaga from 'spa/sagas/NotificationSettingSaga';
import trackingSaga from 'spa/sagas/TrackingSaga';
import userSaga from 'spa/sagas/UserSaga';
import offerSaga from 'spa/sagas/OfferSaga';
import paymentSaga from 'spa/sagas/PaymentSagas';
import verificationSaga from 'spa/sagas/VerificationSaga';
import partnerSagas from 'spa/sagas/PartnerSagas';
import landingPageTokenSagas from 'spa/sagas/LandingPageTokenSagas';
import paymentsPageSaga from 'spa/sagas/PaymentsPageSaga';
import twoFactorSaga from 'spa/sagas/TwoFactorSaga';

import { routinePromiseWatcherSaga } from 'redux-saga-routines';

const sagas = [
  ...additionalDocumentsSaga,
  ...apiIntegrationSaga,
  ...authenticationSaga,
  ...checkoutSaga,
  ...taskSaga,
  ...transactionSaga,
  ...webhookSaga,
  ...addDisbursementSaga,
  ...navigationSaga,
  ...errorLoggingSaga,
  ...notificationSettingSaga,
  ...trackingSaga,
  ...userSaga,
  ...offerSaga,
  ...paymentSaga,
  ...verificationSaga,
  ...partnerSagas,
  ...landingPageTokenSagas,
  ...paymentsPageSaga,
  ...twoFactorSaga,
  routinePromiseWatcherSaga,
];

export default sagas;
