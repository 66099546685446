import _ from 'lodash';
import React from 'react';
import { reduxForm, formValues } from 'redux-form';
import { connect } from 'react-redux';
import { bindRoutineToReduxForm } from 'redux-saga-routines';

import A from 'spa/components/A';
import Breadcrumbs from 'spa/components/Breadcrumbs';
import NotificationSetting from 'spa/components/NotificationSetting';
import NotificationSettingConstants from 'spa/constants/NotificationSettingConstants';
import {
  getNotificationSettings,
  setNotificationSettings,
} from 'spa/actions/NotificationSettingActions';
import {
  notificationPreferencesSelector,
  loadingSelector,
  successSelector,
} from 'spa/selectors/NotificationSettingSelectors';
import { Spinner } from 'spa/components/Indicators';
import Icon from 'spa/components/Icon';

class NotificationSettings extends React.Component {
  constructor(props) {
    super(props);
    this.createForm = this.createForm.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(getNotificationSettings());
  }

  createNotificationSettings(notificationPreferences, disabled) {
    return NotificationSettingConstants.availableSettings.map((elem) => (
      <NotificationSetting
        key={elem.fieldName}
        label={elem.label}
        active={
          this.props[elem.fieldName] === undefined
            ? notificationPreferences[_.snakeCase(elem.fieldName)]
            : this.props[elem.fieldName]
        }
        fieldName={elem.fieldName}
        disabled={disabled}
        tooltip={'tooltip' in elem && elem.tooltip.message}
        tooltipId={'tooltip' in elem && elem.tooltip.id}
      />
    ));
  }

  createForm(handleSubmit, notificationPreferences, submitting, display) {
    let formContent;

    if (!display.success) {
      formContent = <Spinner />;
    } else if (notificationPreferences.error) {
      formContent = <span>{NotificationSettingConstants.CONFLICT_ERROR_MESSAGE}</span>;
    } else {
      formContent = (
        <form onSubmit={handleSubmit(bindRoutineToReduxForm(setNotificationSettings))}>
          <div>
            {this.createNotificationSettings(
              notificationPreferences,
              display.loading || submitting
            )}
          </div>
          <div className="accountForm-footer accountForm-footer--settings">
            <button
              className="accountForm-btn btn btn--secondary btn--large"
              type="submit"
              disabled={submitting}
            >
              {submitting ? <Spinner /> : 'Save Changes'}
            </button>
            <A
              link={{ type: 'internal', route: '/account-info' }}
              className="accountForm-btn accountForm-btn--cancel btn btn--clear btn--large"
            >
              Cancel
            </A>
          </div>
        </form>
      );
    }

    return formContent;
  }

  render() {
    const { handleSubmit, notificationPreferences, display, submitting } = this.props;

    const breadcrumbs = [
      {
        text: 'Your Account',
        link: '/account-info',
      },
      {
        text: 'Notification Settings',
      },
    ];

    return (
      <div className="section-container">
        <Breadcrumbs className="accountInfo-breadcrumbs" items={breadcrumbs} />
        <section className="accountInfo-card card">
          <div className="accountInfo-card-inner">
            <h2 className="accountForm-header accountForm-header--highlight">
              <span className="accountForm-header-iconHolder accountForm-header-iconHolder--inset">
                <Icon name="ui-mail" className="accountForm-header-icon" />
              </span>
              <span className="accountForm-header-title">Notification Settings</span>
            </h2>
            {this.createForm(handleSubmit, notificationPreferences, submitting, display)}
          </div>
        </section>
      </div>
    );
  }
}

const NotificationSettingsContainer = reduxForm({
  form: NotificationSettingConstants.NOTIFICATION_SETTINGS_FORM_NAME,
  enableReinitialize: true,
})(
  formValues(
    'transactionEvent',
    'accountEvent',
    'milestoneEvent',
    'marketing'
  )(NotificationSettings)
);

const mapStateToProps = (state) => {
  const initialValues = {};
  NotificationSettingConstants.availableSettings.map((elem) => {
    initialValues[elem.fieldName] =
      notificationPreferencesSelector(state)[_.snakeCase(elem.fieldName)];
  });

  return {
    notificationPreferences: notificationPreferencesSelector(state),
    display: {
      loading: loadingSelector(state),
      success: successSelector(state),
    },
    initialValues: initialValues,
  };
};

export default connect(mapStateToProps, null)(NotificationSettingsContainer);
