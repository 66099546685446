import window from 'window-shim';

import FormConstants from '../constants/FormConstants';
import ErrorMessages from '../constants/ErrorMessages';
import AppDispatcher from '../dispatcher/AppDispatcher';

export class TransactionActions {
  _post(url, body) {
    return window.fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      credentials: 'include',
    });
  }

  _clear24HrNotice(transID) {
    return this._post(`${window.config.clear_24hour_notice_api_endpoint}/${transID}`)
      .then((response) => {
        if (response.status !== 200) {
          return window.Promise.reject(ErrorMessages.CLEAR_24_HR_NOTICE);
        }
        return window.Promise.resolve();
      })
      .catch(() => window.Promise.reject(ErrorMessages.CLEAR_24_HR_NOTICE));
  }

  clear24HrNotice(formName, transID) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.SUBMISSION_START,
      name: formName,
    });

    return this._clear24HrNotice(transID).then(
      () => {
        AppDispatcher.handleServerAction({
          actionType: FormConstants.SUBMISSION_SUCCESS,
          name: formName,
        });
      },
      (errorMessage) => {
        AppDispatcher.handleServerAction({
          actionType: FormConstants.SUBMISSION_FAILURE,
          name: formName,
          title: errorMessage,
        });
      }
    );
  }
}

export default new TransactionActions();
