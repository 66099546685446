import React from 'react';
import { Link } from 'react-router-dom';
import Button, { buttonClasses } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const EscrowButton = styled(Button, {
  // this is to solve the issue https://legacy.reactjs.org/warnings/unknown-prop.html
  shouldForwardProp: (prop) => prop !== 'disableHoverEffect',
})(({ theme, disableHoverEffect }) => ({
  paddingLeft: '24px',
  paddingRight: '24px',
  fontSize: '14px',
  [`&.${buttonClasses.contained}`]: {
    backgroundColor: theme.palette.secondaryLight.dark,
    color: theme.palette.primary.contrastText,
    ...(disableHoverEffect
      ? {}
      : {
          ':hover': {
            backgroundColor: theme.palette.secondary.light,
          },
        }),
    [`&.${buttonClasses.disabled}`]: {
      backgroundColor: '#eaeaea',
      color: '#c0c0c0',
    },
  },
  [`&.${buttonClasses.outlined}`]: {
    borderColor: theme.palette.secondaryLight.dark,
    color: theme.palette.secondaryLight.dark,
    ':hover': {
      borderColor: theme.palette.secondaryLight.dark,
      backgroundColor: 'rgba(34, 92, 171, 0.04)',
    },
    [`&.${buttonClasses.disabled}`]: {
      borderColor: theme.palette.disabled.main,
    },
  },
  [`&.${buttonClasses.text}`]: {
    color: theme.palette.secondaryLight.dark,
    backgroundColor: theme.palette.white.main,
  },
  [`&.${buttonClasses.containedError}`]: {
    color: theme.palette.primary.contrastText,
    backgroundColor: 'rgba(244, 67, 54, 1)',
  },
  '&.Mui-disabled': {
    background: '#eaeaea',
    color: '#c0c0c0',
  },
}));

/**
 *
 * @param hard whether to use a hard link (a href) or a soft link (Link).
 * Because we render most headers and footers using flask/nunjucks templating which
 * aren't rendered on soft redirects you will likely want to use a hard link
 */
export const EscrowLinkButton = ({
  to,
  buttonText,
  children,
  disabled = false,
  hard,
  variant = 'contained',
  newTab = false,
}) => {
  if (disabled) {
    return (
      <EscrowButton
        data-testid="instruction-cta-primary"
        variant="contained"
        sx={{ width: { xs: '100%', md: 'auto' } }}
        disabled
      >
        {buttonText || children}
      </EscrowButton>
    );
  }

  const buttonElement = (
    <EscrowButton
      data-testid="instruction-cta-primary"
      variant={variant}
      sx={{ width: { xs: '100%', md: 'auto' } }}
    >
      {buttonText || children}
    </EscrowButton>
  );

  const additionalProps = {};
  if (newTab) {
    additionalProps.target = '_blank';
    additionalProps.rel = 'noopener noreferrer';
  }
  if (hard) {
    return (
      <a
        href={to}
        style={{
          textDecoration: 'none',
          '&:hover, &:focus': {
            textDecoration: 'none',
          },
          '&:focus': {
            outline: 'none',
          },
        }}
        {...additionalProps}
      >
        {buttonElement}
      </a>
    );
  }
  return (
    <Link
      to={to}
      style={{
        textDecoration: 'none',
        '&:hover, &:focus': {
          textDecoration: 'none',
        },
        '&:focus': {
          outline: 'none',
        },
      }}
      {...additionalProps}
    >
      {buttonElement}
    </Link>
  );
};
