import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';

import Navigation from 'spa/containers/Navigation';

class IntegrationsPortal extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { route } = this.props;

    return (
      <div className="integrationsPortal-container section-container">
        <div className="integrationsPortal-inner">
          <nav className="integrationsPortal-nav">
            <Navigation />
          </nav>
          <div className="integrationsPortal-content">{renderRoutes(route.routes)}</div>
        </div>
      </div>
    );
  }
}

IntegrationsPortal.propTypes = {
  location: PropTypes.object,
  route: PropTypes.object,
};

const IntegrationsPortalContainer = withRouter(IntegrationsPortal);

export default IntegrationsPortalContainer;
