import { connect } from 'react-redux';
import { PaypalRedirectionBox } from 'spa/components/Payments';
import {
  paymentsPagePaymentSubmissionSuccessSelector,
  paymentsPagePaymentTypeSelector,
} from 'spa/selectors/PaymentsPageSelectors';
import { gettext } from '../../../utils/filters';

const mapStateToProps = (state, ownProps) => ({
  isCurrentStep:
    paymentsPagePaymentSubmissionSuccessSelector(state) === true &&
    paymentsPagePaymentTypeSelector(state) === 'paypal',
  heading: gettext(ownProps.message),
});

export default connect(mapStateToProps)(PaypalRedirectionBox);
