import React from 'react';
import AuthenticationContainer from 'spa/containers/Authentication';
import AuthenticationConstants from 'spa/constants/AuthenticationConstants';
import Header from 'spa/components/Authentication/Integrations/Header';
import Quote from 'spa/components/Authentication/Integrations/Quote';
import NavigationActions from '../../../actions/NavigationActions';

const IntegrationsAuthPage = () => (
  <div className="integrationsAuth">
    <Header />
    <div className="section-container">
      <div className="grid integrationsAuth-container">
        <div className="integrationsAuth-upsell grid-col grid-col--6 media--hidden@mobile">
          <Quote />
        </div>
        <div className="integrationsAuth-formContainer grid-col grid-col--tablet-5 grid-col--flushRight">
          <AuthenticationContainer
            onSuccess={NavigationActions.loginIntegrationsRedirect}
            loginPage={AuthenticationConstants.INTEGRATIONS_LOGIN_PAGE}
          />
        </div>
      </div>
    </div>
  </div>
);

export default IntegrationsAuthPage;
