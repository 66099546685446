import React, { useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import {
  getCustomerStatistics as getCustomerStatisticsRoutine,
  pushCustomerCountByDateCreated,
} from 'spa/actions/PartnerActions';
import PartnerConstants from 'spa/constants/PartnerConstants';
import INTERVAL from 'spa/constants/IntervalConstants';
import {
  customersCountStatisticsAggregatesSelector,
  customersCountStatisticsTimeDataSelector,
} from 'spa/selectors/PartnerSelectors';
import A from 'spa/components/A';
import {
  BasicLineChart,
  getDateRangeDataSet,
  getChartLabels,
  getDatasetOptions,
  getChartValues,
} from 'spa/components/Charts/BasicCharts';
import { DashboardSummaryDetails, DashboardSummaryHeader } from '../SharedComponents';
import { getDisplayRange } from '../../../../utils/PartnerDashboard';
import { urlFor } from '../../../../routeConfig';
import { gettext } from '../../../../utils/filters';
import withLoadingSpinner from '../../LoadingSpinner';

const { CUSTOMER_STATISTICS_DATA_TYPE, DEFAULT_START_DATE } = PartnerConstants;

const getUserCountThisInterval = (data, interval) => {
  const [, format] = INTERVAL[interval.toUpperCase()].format;
  const countThisInterval = data.find((item) => item.label === moment().format(format));
  return countThisInterval ? countThisInterval.value : 0;
};

const UserCountChart = withLoadingSpinner(
  ({ customerCountData, customerCountAggregates, interval, startDate, endDate, labelFormat }) => {
    const data = getDateRangeDataSet(customerCountData, interval, startDate, endDate, labelFormat);
    const chartData = {
      labels: getChartLabels(data),
      datasets: [
        {
          ...getDatasetOptions(),
          data: getChartValues(data),
        },
      ],
    };

    return (
      <div className="partnerDashboard-summary-content">
        <div className="partnerDashboard-chartContainer">
          <BasicLineChart chartData={chartData} />
        </div>
        <div className="grid">
          <div className="grid-col grid-col--6">
            <DashboardSummaryDetails
              value={customerCountAggregates.total}
              label={gettext('Total users')}
            />
          </div>
          <div className="grid-col grid-col--6">
            <DashboardSummaryDetails
              value={getUserCountThisInterval(customerCountData, interval)}
              label={gettext(
                `New users this ${INTERVAL[interval.toUpperCase()].text.toLowerCase()}`
              )}
            />
          </div>
        </div>
      </div>
    );
  }
);

const UsersOverview = ({
  customerCountData,
  customerCountAggregates,
  interval,
  dateStart,
  dateEnd,
  getCustomerStatistics,
}) => {
  useEffect(() => {
    getCustomerStatistics({
      dataType: CUSTOMER_STATISTICS_DATA_TYPE.DATE_CREATED,
      interval: interval,
      dateStart: dateStart || DEFAULT_START_DATE,
      dateEnd: dateEnd || moment().format('YYYY-MM-DD'),
      dataLoaderAction: pushCustomerCountByDateCreated,
    });
  }, [interval, dateStart, dateEnd, getCustomerStatistics]);

  const [displayStartDate, displayEndDate] = getDisplayRange(INTERVAL.RANGE.THIS_YEAR.value);

  return (
    <div
      className="partnerDashboard-card partnerDashboard-summary"
      data-e2e-target="users-overview-card"
    >
      <div className="partnerDashboard-summary-content">
        <DashboardSummaryHeader title={gettext('Users Overview')}>
          <A
            className="partnerDashboard-summary-action"
            link={{ type: 'app', route: urlFor('partner_dashboard_users') }}
            role="button"
            tabIndex={0}
          >
            {gettext('View all users')} →
          </A>
        </DashboardSummaryHeader>

        <UserCountChart
          isLoading={!customerCountData}
          customerCountAggregates={customerCountAggregates}
          customerCountData={customerCountData}
          interval={interval}
          startDate={displayStartDate}
          endDate={displayEndDate}
          labelFormat={INTERVAL.RANGE[INTERVAL.RANGE.THIS_YEAR.value].format}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  customerCountData: customersCountStatisticsTimeDataSelector(state),
  customerCountAggregates: customersCountStatisticsAggregatesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCustomerStatistics: (payload) => dispatch(getCustomerStatisticsRoutine.trigger(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersOverview);
