var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["components/buttons.html"] = require( "components/buttons.html" );
dependencies["components/inputs.html"] = require( "components/inputs.html" );
dependencies["icons/phone.html"] = require( "icons/phone.html" );
dependencies["icons/search.html"] = require( "icons/search.html" );
dependencies["icons/verify.html"] = require( "icons/verify.html" );
dependencies["icons/go.html"] = require( "icons/go.html" );
dependencies["icons/chevron-down.html"] = require( "icons/chevron-down.html" );
dependencies["icons/chevron-right.html"] = require( "icons/chevron-right.html" );
dependencies["icons/menu.html"] = require( "icons/menu.html" );
dependencies["icons/warning.html"] = require( "icons/warning.html" );
dependencies["icons/ui-exit.html"] = require( "icons/ui-exit.html" );
dependencies["components/escrowLogo-v2.html"] = require( "components/escrowLogo-v2.html" );
dependencies["navigation/sidebarPartnerLoggedIn.html"] = require( "navigation/sidebarPartnerLoggedIn.html" );
dependencies["navigation/sidebarLoggedIn.html"] = require( "navigation/sidebarLoggedIn.html" );




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/navigation/headerLoggedIn.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("components/buttons.html", false, "escrow_www/templates/navigation/headerLoggedIn.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(context.getVariables(), frame, function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("button", t_1);
env.getTemplate("components/inputs.html", false, "escrow_www/templates/navigation/headerLoggedIn.html", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(context.getVariables(), frame, function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("input", t_4);
env.getTemplate("icons/phone.html", false, "escrow_www/templates/navigation/headerLoggedIn.html", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
t_7.getExported(context.getVariables(), frame, function(t_9,t_7) {
if(t_9) { cb(t_9); return; }
if(t_7.hasOwnProperty("phone")) {
var t_10 = t_7.phone;
} else {
cb(new Error("cannot import 'phone'")); return;
}
context.setVariable("phoneIcon", t_10);
env.getTemplate("icons/search.html", false, "escrow_www/templates/navigation/headerLoggedIn.html", false, function(t_12,t_11) {
if(t_12) { cb(t_12); return; }
t_11.getExported(context.getVariables(), frame, function(t_13,t_11) {
if(t_13) { cb(t_13); return; }
if(t_11.hasOwnProperty("search")) {
var t_14 = t_11.search;
} else {
cb(new Error("cannot import 'search'")); return;
}
context.setVariable("searchIcon", t_14);
env.getTemplate("icons/verify.html", false, "escrow_www/templates/navigation/headerLoggedIn.html", false, function(t_16,t_15) {
if(t_16) { cb(t_16); return; }
t_15.getExported(context.getVariables(), frame, function(t_17,t_15) {
if(t_17) { cb(t_17); return; }
if(t_15.hasOwnProperty("verify")) {
var t_18 = t_15.verify;
} else {
cb(new Error("cannot import 'verify'")); return;
}
context.setVariable("verifyIcon", t_18);
env.getTemplate("icons/go.html", false, "escrow_www/templates/navigation/headerLoggedIn.html", false, function(t_20,t_19) {
if(t_20) { cb(t_20); return; }
t_19.getExported(context.getVariables(), frame, function(t_21,t_19) {
if(t_21) { cb(t_21); return; }
if(t_19.hasOwnProperty("go")) {
var t_22 = t_19.go;
} else {
cb(new Error("cannot import 'go'")); return;
}
context.setVariable("goIcon", t_22);
env.getTemplate("icons/chevron-down.html", false, "escrow_www/templates/navigation/headerLoggedIn.html", false, function(t_24,t_23) {
if(t_24) { cb(t_24); return; }
t_23.getExported(context.getVariables(), frame, function(t_25,t_23) {
if(t_25) { cb(t_25); return; }
if(t_23.hasOwnProperty("chevronDown")) {
var t_26 = t_23.chevronDown;
} else {
cb(new Error("cannot import 'chevronDown'")); return;
}
context.setVariable("chevronDownIcon", t_26);
env.getTemplate("icons/chevron-right.html", false, "escrow_www/templates/navigation/headerLoggedIn.html", false, function(t_28,t_27) {
if(t_28) { cb(t_28); return; }
t_27.getExported(context.getVariables(), frame, function(t_29,t_27) {
if(t_29) { cb(t_29); return; }
if(t_27.hasOwnProperty("chevronRight")) {
var t_30 = t_27.chevronRight;
} else {
cb(new Error("cannot import 'chevronRight'")); return;
}
context.setVariable("chevronRightIcon", t_30);
env.getTemplate("icons/menu.html", false, "escrow_www/templates/navigation/headerLoggedIn.html", false, function(t_32,t_31) {
if(t_32) { cb(t_32); return; }
t_31.getExported(context.getVariables(), frame, function(t_33,t_31) {
if(t_33) { cb(t_33); return; }
if(t_31.hasOwnProperty("menu")) {
var t_34 = t_31.menu;
} else {
cb(new Error("cannot import 'menu'")); return;
}
context.setVariable("menuIcon", t_34);
env.getTemplate("icons/warning.html", false, "escrow_www/templates/navigation/headerLoggedIn.html", false, function(t_36,t_35) {
if(t_36) { cb(t_36); return; }
t_35.getExported(context.getVariables(), frame, function(t_37,t_35) {
if(t_37) { cb(t_37); return; }
if(t_35.hasOwnProperty("warning")) {
var t_38 = t_35.warning;
} else {
cb(new Error("cannot import 'warning'")); return;
}
context.setVariable("warningIcon", t_38);
env.getTemplate("icons/ui-exit.html", false, "escrow_www/templates/navigation/headerLoggedIn.html", false, function(t_40,t_39) {
if(t_40) { cb(t_40); return; }
t_39.getExported(context.getVariables(), frame, function(t_41,t_39) {
if(t_41) { cb(t_41); return; }
if(t_39.hasOwnProperty("exit")) {
var t_42 = t_39.exit;
} else {
cb(new Error("cannot import 'exit'")); return;
}
context.setVariable("exitIcon", t_42);
if(!runtime.contextOrFrameLookup(context, frame, "email") && runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "g")),"user")),"email")) {
output += "\n  ";
var t_43;
t_43 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "g")),"user")),"email");
frame.set("email", t_43, true);
if(frame.topLevel) {
context.setVariable("email", t_43);
}
if(frame.topLevel) {
context.addExport("email", t_43);
}
output += "\n";
;
}
output += "\n\n";
var t_44;
t_44 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "email")),0);
frame.set("userInitial", t_44, true);
if(frame.topLevel) {
context.setVariable("userInitial", t_44);
}
if(frame.topLevel) {
context.addExport("userInitial", t_44);
}
output += "\n\n<div>\n  <header class=\"header ";
if(runtime.contextOrFrameLookup(context, frame, "sticky")) {
output += "is-sticky ";
;
}
if(runtime.contextOrFrameLookup(context, frame, "scrollTop")) {
output += "is-header-scrollTop";
;
}
output += "\" data-sticky-header data-header-logged-in>\n    <div class=\"header-primary\" data-e2e-target=\"header-nav-container\">\n      <div class=\"header-inner section-container\">\n        <div class=\"header-logoContainer\">\n          <button data-sidebar-toggle class=\"header-menuBtn media--available@tablet\">\n            ";
output += runtime.suppressValue((lineno = 25, colno = 21, runtime.callWrap(t_34, "menuIcon", context, [runtime.makeKeywordArgs({"specifier": "header-menuBtn-icon"})])), env.opts.autoescape);
output += "\n          </button>\n          <a href=\"";
if(runtime.contextOrFrameLookup(context, frame, "header_type") == "partner_dashboard") {
output += runtime.suppressValue((lineno = 27, colno = 66, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".partner_dashboard"])), env.opts.autoescape);
;
}
else {
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "config")),"ESCROW_LOGGEDIN_LANDING_PAGE"), env.opts.autoescape);
output += "?hid=mt";
;
}
output += "\" class=\"header-logo logo\" title=\"";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_45 = "";t_45 += "Go to home page";
cb(null, t_45);
;
return t_45;
}
), true && env.opts.autoescape);
output += "\">\n            ";
env.getTemplate("components/escrowLogo-v2.html", false, "escrow_www/templates/navigation/headerLoggedIn.html", null, function(t_48,t_46) {
if(t_48) { cb(t_48); return; }
t_46.render(context.getVariables(), frame, function(t_49,t_47) {
if(t_49) { cb(t_49); return; }
output += t_47
output += "\n          </a>\n        </div>\n\n        ";
if(!runtime.contextOrFrameLookup(context, frame, "funnel")) {
output += "\n        <nav class=\"header-nav\">\n          <ul class=\"header-nav-list\">\n            ";
if(runtime.contextOrFrameLookup(context, frame, "header_type") != "partner_dashboard") {
output += "\n              <li class=\"header-nav-item media--hidden@tablet\">\n                <a class=\"header-nav-tab\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "config")),"ESCROW_LOGGEDIN_LANDING_PAGE"), env.opts.autoescape);
output += "?hid=mt\" data-e2e-target=\"header-nav-my-transactions\" data-tracking-section=\"header\" data-tracking-label=\"my-transactions\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_50 = "";t_50 += "My Transactions";
cb(null, t_50);
;
return t_50;
}
), true && env.opts.autoescape);
output += "</a>\n              </li>\n            ";
;
}
output += "\n            <li class=\"header-nav-item media--hidden@tablet\">\n              <a href=\"";
output += runtime.suppressValue((lineno = 41, colno = 31, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".integrations_home"])), env.opts.autoescape);
output += "\"\n                ";
if(runtime.contextOrFrameLookup(context, frame, "header_type") == "partner_dashboard") {
output += "target=\"_blank\"";
;
}
output += "\n                class=\"header-nav-tab\" data-e2e-target=\"header-nav-my-integrations\" data-tracking-section=\"header\" data-tracking-label=\"my-integrations\">\n                  ";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_51 = "";t_51 += "My Integrations";
cb(null, t_51);
;
return t_51;
}
), true && env.opts.autoescape);
output += " ";
if(runtime.contextOrFrameLookup(context, frame, "header_type") == "partner_dashboard") {
output += runtime.suppressValue((lineno = 44, colno = 94, runtime.callWrap(t_42, "exitIcon", context, ["header-nav-tab-icon"])), env.opts.autoescape);
;
}
output += "\n              </a>\n            </li>\n            ";
if(!runtime.contextOrFrameLookup(context, frame, "isKycCompleted")) {
output += "\n            <li class=\"header-nav-item media--hidden@tablet\">\n              <a href=\"";
output += runtime.suppressValue((lineno = 49, colno = 31, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".verify"])), env.opts.autoescape);
output += "\"\n                ";
if(runtime.contextOrFrameLookup(context, frame, "header_type") == "partner_dashboard") {
output += "target=\"_blank\"";
;
}
output += "\n                class=\"header-nav-tab\" data-e2e-target=\"header-nav-get-verified\" data-tracking-section=\"header\" data-tracking-label=\"get-verified\">\n                  ";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_52 = "";t_52 += "Get Verified";
cb(null, t_52);
;
return t_52;
}
), true && env.opts.autoescape);
output += " ";
if(runtime.contextOrFrameLookup(context, frame, "header_type") == "partner_dashboard") {
output += runtime.suppressValue((lineno = 52, colno = 91, runtime.callWrap(t_42, "exitIcon", context, ["header-nav-tab-icon"])), env.opts.autoescape);
;
}
output += "\n              </a>\n            </li>\n            ";
;
}
output += "\n            <li class=\"header-nav-item media--hidden@tablet\">\n              <a href=\"";
output += runtime.suppressValue((lineno = 57, colno = 31, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".helpdesk"])), env.opts.autoescape);
output += "\"\n                ";
if(runtime.contextOrFrameLookup(context, frame, "header_type") == "partner_dashboard") {
output += "target=\"_blank\"";
;
}
output += "\n                class=\"header-nav-tab";
if(runtime.contextOrFrameLookup(context, frame, "current_page") == "/helpdesk") {
output += " is-active";
;
}
output += "\" data-e2e-target=\"header-nav-help\" data-tracking-section=\"header\" data-tracking-label=\"help\">\n                  ";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_53 = "";t_53 += "Help";
cb(null, t_53);
;
return t_53;
}
), true && env.opts.autoescape);
output += " ";
if(runtime.contextOrFrameLookup(context, frame, "header_type") == "partner_dashboard") {
output += runtime.suppressValue((lineno = 60, colno = 83, runtime.callWrap(t_42, "exitIcon", context, ["header-nav-tab-icon"])), env.opts.autoescape);
;
}
output += "\n              </a>\n            </li>\n            <li class=\"header-nav-item media--hidden@tablet\">\n              <a href=\"";
output += runtime.suppressValue((lineno = 64, colno = 31, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".contact_us"])), env.opts.autoescape);
output += "\"\n                ";
if(runtime.contextOrFrameLookup(context, frame, "header_type") == "partner_dashboard") {
output += "target=\"_blank\"";
;
}
output += "\n                class=\"header-nav-tab";
if(runtime.contextOrFrameLookup(context, frame, "current_page") == "/contact-us") {
output += " is-active";
;
}
output += "\" data-e2e-target=\"header-nav-contact-us\" data-tracking-section=\"header\" data-tracking-label=\"contact-us\">\n                  ";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_54 = "";t_54 += "Contact Us";
cb(null, t_54);
;
return t_54;
}
), true && env.opts.autoescape);
output += " ";
if(runtime.contextOrFrameLookup(context, frame, "header_type") == "partner_dashboard") {
output += runtime.suppressValue((lineno = 67, colno = 89, runtime.callWrap(t_42, "exitIcon", context, ["header-nav-tab-icon"])), env.opts.autoescape);
;
}
output += "\n              </a>\n            </li>\n          </ul>\n\n          <div class=\"header-userControl\" data-target='user-control'>\n            <ul class=\"header-nav-list\">\n              ";
if(runtime.contextOrFrameLookup(context, frame, "header_type") != "partner_dashboard") {
output += "\n                <li class=\"header-nav-item media--hidden@tablet\">\n                  <a class=\"btn btn--secondary\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "config")),"ESCROW_TRANSACTION_CHOICE"), env.opts.autoescape);
output += "?hid=tc\" data-e2e-target=\"header-nav-start-transaction\" data-tracking-section=\"header\" data-tracking-label=\"start-transaction\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_55 = "";t_55 += "Start New Transaction";
cb(null, t_55);
;
return t_55;
}
), true && env.opts.autoescape);
output += "</a>\n                </li>\n              ";
;
}
output += "\n              <li class=\"header-nav-item\">\n                <span class=\"header-userControl-inner\">\n                  <a href=\"";
output += runtime.suppressValue((lineno = 81, colno = 35, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".account-info"])), env.opts.autoescape);
output += "\" class=\"header-nav-tab header-nav-tab--user\">\n                    <span class=\"avatar\" data-e2e-target=\"header-avatar\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "userInitial"), env.opts.autoescape);
output += "</span>\n                  </a>\n                  ";
output += runtime.suppressValue((lineno = 84, colno = 34, runtime.callWrap(t_26, "chevronDownIcon", context, ["icon--invert header-nav-arrow media--hidden@tablet"])), env.opts.autoescape);
output += "\n                  <span class=\"header-userControl-trigger media--hidden@tablet\" data-toggle-userMenu></span>\n                </span>\n\n                <div class=\"header-dropdown card media--hidden@tablet\" data-target='user-menu'>\n                  <div class=\"header-dropdown-content\">\n                    <div class=\"header-dropdown-content-icon\">\n                      <span class=\"header-dropdown-avatar avatar avatar--large avatar--light\" data-e2e-target=\"header-dropdown-avatar\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "userInitial"), env.opts.autoescape);
output += "</span>\n                    </div>\n                    <div class=\"header-dropdown-content-copy\">\n                      ";
if(runtime.contextOrFrameLookup(context, frame, "companyName")) {
output += "\n                      <span class=\"header-dropdown-companyName\" data-e2e-target=\"header-dropdown-companyName\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "companyName"), env.opts.autoescape);
output += "</span>\n                      ";
;
}
output += "\n                      <span class=\"header-dropdown-userName\" data-e2e-target=\"header-dropdown-userName\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "userName"), env.opts.autoescape);
output += "</span>\n                      <span class=\"header-dropdown-userEmail\" data-e2e-target=\"header-dropdown-email\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "email"), env.opts.autoescape);
output += "</span>\n                    </div>\n                  </div>\n                  <nav class=\"header-dropdown-nav\">\n                    <ul class=\"header-dropdown-nav-list\">\n                      ";
if(runtime.contextOrFrameLookup(context, frame, "kycActionRequired")) {
output += "\n                      <li class=\"header-dropdown-nav-item\">\n                        <a href=\"/verify\" class=\"header-dropdown-nav-link\" data-e2e-target=\"header-dropdown-verify-nav-link\" data-tracking-section=\"header\" data-tracking-label=\"verify-my-account\">\n                          ";
output += runtime.suppressValue((lineno = 106, colno = 38, runtime.callWrap(t_38, "warningIcon", context, [runtime.makeKeywordArgs({"specifier": "header-dropdown-nav-icon"})])), env.opts.autoescape);
output += "\n                          ";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_56 = "";t_56 += "Verify My Account";
cb(null, t_56);
;
return t_56;
}
), true && env.opts.autoescape);
output += "\n                          <span class=\"header-dropdown-nav-desc\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_57 = "";t_57 += "Accounts must be verified to make or receive payments";
cb(null, t_57);
;
return t_57;
}
), true && env.opts.autoescape);
output += "</span>\n                          <span class=\"header-dropdown-nav-arrow\">";
output += runtime.suppressValue((lineno = 109, colno = 83, runtime.callWrap(t_30, "chevronRightIcon", context, [])), env.opts.autoescape);
output += "</span>\n                        </a>\n                      </li>\n                      ";
;
}
output += "\n                      <li class=\"header-dropdown-nav-item\">\n                        <a href=\"";
output += runtime.suppressValue((lineno = 114, colno = 41, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".account-info"])), env.opts.autoescape);
output += "\" class=\"header-dropdown-nav-link\" data-e2e-target=\"header-dropdown-my-profile\" data-tracking-section=\"header\" data-tracking-label=\"my-profile\">\n                          ";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_58 = "";t_58 += "My Profile";
cb(null, t_58);
;
return t_58;
}
), true && env.opts.autoescape);
output += "\n                          <span class=\"header-dropdown-nav-arrow\">";
output += runtime.suppressValue((lineno = 116, colno = 83, runtime.callWrap(t_30, "chevronRightIcon", context, [])), env.opts.autoescape);
output += "</span>\n                        </a>\n                      </li>\n                      <li class=\"header-dropdown-nav-item\">\n                        <a href=\"#\" class=\"header-dropdown-nav-link\" data-target=\"logout\" data-e2e-target=\"header-dropdown-sign-out\" data-tracking-section=\"header\" data-tracking-label=\"sign-out\">\n                          ";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_59 = "";t_59 += "Sign out";
cb(null, t_59);
;
return t_59;
}
), true && env.opts.autoescape);
output += "\n                        </a>\n                      </li>\n                    </ul>\n                  </nav>\n                </div>\n              </li>\n            </ul>\n          </div>\n        </nav>\n        ";
;
}
output += "\n      </div>\n    </div>\n  </header>\n  ";
if(runtime.contextOrFrameLookup(context, frame, "header_type") == "partner_dashboard") {
output += "\n    ";
env.getTemplate("navigation/sidebarPartnerLoggedIn.html", false, "escrow_www/templates/navigation/headerLoggedIn.html", null, function(t_62,t_60) {
if(t_62) { cb(t_62); return; }
t_60.render(context.getVariables(), frame, function(t_63,t_61) {
if(t_63) { cb(t_63); return; }
output += t_61
output += "\n  ";
})});
}
else {
output += "\n    ";
env.getTemplate("navigation/sidebarLoggedIn.html", false, "escrow_www/templates/navigation/headerLoggedIn.html", null, function(t_66,t_64) {
if(t_66) { cb(t_66); return; }
t_64.render(context.getVariables(), frame, function(t_67,t_65) {
if(t_67) { cb(t_67); return; }
output += t_65
output += "\n  ";
})});
}
output += "\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/navigation/headerLoggedIn.html"] , dependencies)