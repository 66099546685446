import React from 'react';
import { SectionImage } from '../Section';

const LogoPreview = ({ logos, customerId }) => {
  if (!logos || logos.length === 0) return null;

  return (
    <div>
      <SectionImage src={`/api/customer/${customerId}/logo/${logos[0].id}`} alt="logo" />
    </div>
  );
};

export default LogoPreview;
