import React from 'react';

import { Stack } from '@mui/material';
import { ArticleOutlined, BadgeOutlined, SmartphoneOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import { V3KYCMobilePages as Pages } from 'spa/constants/VerificationConstants';
import { SmallText } from 'spa/features/ui/EscrowTypography';
import MobilePage from '../../components/MobilePage';

const iconStyles = (theme) => ({
  width: '24px',
  height: '24px',
  color: theme.palette.secondaryLight.dark,
  borderColor: theme.palette.mono.xxlight,
  fontSize: '16px',
});

const AccountSetupPage = () => {
  const theme = useTheme();
  const customIconStyles = iconStyles(theme);

  return (
    <MobilePage
      title={'Let’s set up your account'}
      subtitle={'This should only take a few minutes'}
      ctaText={'Let’s get started'}
      nextPage={Pages.PHONE_NUMBER}
      titleAlignment="center"
      HeaderIcon={(props) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            alt="Escrow logo"
            src="spa/../../build/images/kyc/escrow-shield-logo.svg"
            style={{
              minWidth: '114px',
            }}
            {...props}
          />
        </div>
      )}
    >
      <Stack direction="row" sx={{ gap: '12px' }}>
        <SmartphoneOutlined
          sx={{
            ...customIconStyles,
          }}
        />
        <Stack direction="column" sx={{ gap: '4px' }}>
          <SmallText sx={{ fontWeight: 600, fontSize: '16px' }}>Add your phone number</SmallText>
          <SmallText sx={{ fontSize: '16px' }}>
            Should we need to contact you about your purchase.
          </SmallText>
        </Stack>
      </Stack>

      <Stack direction="row" sx={{ gap: '12px' }}>
        <ArticleOutlined
          sx={{
            ...customIconStyles,
          }}
        />
        <Stack direction="column" sx={{ gap: '4px' }}>
          <SmallText sx={{ fontWeight: 600, fontSize: '16px' }}>Complete your profile</SmallText>
          <SmallText sx={{ fontSize: '16px' }}>
            This will help us verify your details and tailor our services.
          </SmallText>
        </Stack>
      </Stack>

      <Stack direction="row" sx={{ gap: '12px' }}>
        <BadgeOutlined
          sx={{
            ...customIconStyles,
          }}
        />
        <Stack direction="column" sx={{ gap: '4px' }}>
          <SmallText sx={{ fontWeight: 600, fontSize: '16px' }}>Verify your identity</SmallText>
          <SmallText sx={{ fontSize: '16px' }}>
            To create trust and protection across the purchase, we verify both seller and buyer. You
            will need to upload a government-issued ID to complete verification.
          </SmallText>
        </Stack>
      </Stack>
    </MobilePage>
  );
};

export default AccountSetupPage;
