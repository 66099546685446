import React, { isValidElement, useEffect, useRef, useState } from 'react';
import { Divider, Alert, Button, Dialog, Box } from '@mui/material';
import { FieldArray } from 'react-final-form-arrays';
import { Form, useForm } from 'react-final-form';
import moment from 'moment';
import _ from 'lodash';
import { formatPrice } from 'spa/components/form/format';
import { priceValidate } from 'spa/components/form/validate';
import { useWindowDimensions } from 'spa/hooks';
import { currencySymbols, InputField } from 'spa/components/StartTransaction/Fields';
import { formatCurrency } from 'spa/components/StartTransaction/util';
import TransactionConstants from 'spa/constants/TransactionConstants';
import { SubmitButton } from 'spa/components/StartTransaction';

const paymentFrequencyMap = {
  Monthly: 1,
  Quarterly: 3,
  Annual: 12,
};

/**
 *
 * @param year , number, 1-5
 * @param month , number, 0-11
 * @param totalPrice number
 * @param firstDate moment object
 * @param totalBuyerBrokerCommission Total amount of commission paid by the buyer
 *
 * Calculate payment amounts in cents to avoid float precision issues and to use Math.round
 */

const calculateSchedule = (
  year,
  month,
  paymentFrequency,
  totalPrice,
  firstDate,
  totalBuyerBrokerCommission
) => {
  if (
    !firstDate ||
    (!year && !month) ||
    !totalPrice ||
    !paymentFrequency ||
    Number(totalPrice) <= 0
  ) {
    return [];
  }
  const numOfMonth = (year || 0) * 12 + (month || 0);
  const periodReminder = numOfMonth % paymentFrequencyMap[paymentFrequency];
  const fullPaymentCount = (numOfMonth / paymentFrequencyMap[paymentFrequency]) | 0;

  const schedules = [];

  if (periodReminder > 0) {
    const firstPaymentAmount = Math.round(
      (totalPrice * 100 * paymentFrequencyMap[paymentFrequency]) / numOfMonth
    );
    const lastPaymentAmount = totalPrice * 100 - firstPaymentAmount * fullPaymentCount;
    _.range(fullPaymentCount).forEach((i) => {
      schedules.push({
        date: moment(firstDate)
          .utcOffset(0, true)
          .add(paymentFrequencyMap[paymentFrequency] * i, 'months')
          .startOf('day'),
        amount: (firstPaymentAmount / 100).toFixed(2),
      });
    });
    schedules.push({
      date: moment(firstDate)
        .utcOffset(0, true)
        .add(paymentFrequencyMap[paymentFrequency] * fullPaymentCount, 'months')
        .startOf('day'),
      amount: (lastPaymentAmount / 100).toFixed(2),
    });
  } else {
    const firstPaymentAmount = Math.round((totalPrice * 100) / fullPaymentCount);
    const lastPaymentAmount = totalPrice * 100 - firstPaymentAmount * (fullPaymentCount - 1);
    _.range(fullPaymentCount).forEach((i) => {
      schedules.push({
        date: moment(firstDate)
          .utcOffset(0, true)
          .add(paymentFrequencyMap[paymentFrequency] * i, 'months')
          .startOf('day'),
        amount:
          i === fullPaymentCount - 1
            ? (lastPaymentAmount / 100).toFixed(2)
            : (firstPaymentAmount / 100).toFixed(2),
      });
    });
  }
  return schedules.map((sched, i) => ({
    ...sched,
    commission: totalBuyerBrokerCommission && i === 0 ? totalBuyerBrokerCommission : '0.00',
  }));
};

const ErrorBox = ({ message }) => {
  if (typeof message === 'string' || isValidElement(message)) {
    return (
      <div className="createTransaction-check-container">
        <Alert severity="error">{message}</Alert>
      </div>
    );
  }
  return null;
};

function validateAmount(values, allValues, currency) {
  const { brokerCommissionPayer } = allValues;
  const { totalPrice, brokerCommission } = allValues.items.reduce(
    (accumulator, object) => {
      accumulator.totalPrice += parseFloat(object.price);
      accumulator.brokerCommission += parseFloat(object.brokerCommission);
      return accumulator;
    },
    { totalPrice: 0, brokerCommission: 0 }
  );
  if (!values) {
    return undefined;
  }
  const showBrokerFields =
    brokerCommissionPayer &&
    brokerCommissionPayer !== TransactionConstants.TRANSACTION_ROLES.SELLER;
  if (showBrokerFields) {
    const totalBuyerBrokerCommission = (
      brokerCommission / brokerCommissionPayer.split(',').length
    ).toFixed(2);
    const commissionTotal = values
      .reduce((acc, cur) => acc + parseFloat(cur.commission), 0)
      .toFixed(2);
    if (commissionTotal !== totalBuyerBrokerCommission) {
      return (
        <div>
          <div>
            Please ensure the sum of the broker commissions is equal to the total broker
            commission of ${totalBuyerBrokerCommission}.
          </div>
          <div>
            The current sum of the broker commissions ({currencySymbols[currency]}{commissionTotal}) is <strong>
              {parseFloat(totalBuyerBrokerCommission) > parseFloat(commissionTotal) ? 'lower' : 'higher'} than the
              total broker commission by {currencySymbols[currency]}
              {Math.abs(totalBuyerBrokerCommission - commissionTotal).toFixed(2)}.
            </strong>
          </div>
        </div>
      );
    }
  }
  const scheduleTotal = values.reduce((acc, cur) => acc + parseFloat(cur.amount), 0).toFixed(2);
  const desiredTotal = parseFloat(totalPrice).toFixed(2);
  if (scheduleTotal !== desiredTotal) {
    return (
      <div>
        <div>
          Please ensure the sum of the schedule amounts is equal to the total item price
          of {currencySymbols[currency]}{desiredTotal}.
        </div>
        <div>
          The current sum of schedule amounts ({currencySymbols[currency]}{scheduleTotal}) is <strong>
          {parseFloat(scheduleTotal) > parseFloat(desiredTotal) ? 'higher' : 'lower'} than the total item price by {currencySymbols[currency]}
          {Math.abs(scheduleTotal - desiredTotal).toFixed(2)}.
        </strong>
        </div>
      </div>
    );
  }

  if (allValues.paymentSchedule.some(
    (o) => parseFloat(o.amount) === 0
  )) {
    return (
      <div>
        Payment schedule amounts cannot be zero.
      </div>
    );
  }

  return undefined;
}

/**
 * Returns a function that can validate a schedule first/last period input field
 *
 * @param numPeriods Number of payments in the schedule
 * @returns {function(*): *} Validation function
 */
function getPeriodValidator(numPeriods) {
  return function periodValidator(period) {
    return (isNaN(period) || period < 1 || period > numPeriods);
  };
}

/**
 * Returns a function that can validate a schedule total amount input field
 *
 * @param total Amount to which the schedule should sum
 * @returns {function(*): *} Validation function
 */
function getAmountValidator(total) {
  return function amountValidator(potentialTotal) {
    return (isNaN(potentialTotal) || potentialTotal < 0 || potentialTotal > total);
  };
}

function formatInteger(num) {
  return Math.round(num);
}

/**
 * Handles an input-field set event for a first/last period
 *
 * @param evt React event object
 * @param setter Method to call with the field's new value, or with zero if invalid
 */
function setPeriod(evt, setter) {
  if (evt.target.value === "") {
    setter(0);
    return;
  }

  const parsed = Number.parseInt(evt.target.value, 10);
  if (isNaN(parsed)) {
    setter(0);
    return;
  }

  setter(Number.parseInt(evt.target.value, 10));
}

/**
 * Returns how much of a payment schedule's balance is OUTside the given period range.
 *
 * @param firstPeriod First period of range to exclude from summation, inclusive
 * @param lastPeriod Last period of range to exclude from summation, inclusive
 * @param paymentSchedule Payment schedule on which to calculate
 * @param totalPrice Total amount to which the schedule should sum
 * @param fieldToSum Name of the field in the schedule being summed
 * @return Amount to which all periods outside the given range would need to sum in order
 *         to balance the payment schedule
 */
function getRemainingFromSchedule(firstPeriod, lastPeriod, paymentSchedule, totalPrice, fieldToSum) {
  let accountedForTotal = 0.0;
  for (let i = 0; i < paymentSchedule.length; i++) {
    const thisValue = Number.parseFloat(paymentSchedule[i][fieldToSum]);
    if (i < firstPeriod - 1 || i > lastPeriod - 1) {
      accountedForTotal += thisValue;
    }
  }

  return totalPrice - accountedForTotal;
}

/**
 * Modal dialog box for bulk-updating DNH payment or broker commission schedules
 *
 * @param fullWidth Whether the dialog should fill the screen horizontally
 * @param shouldOpen If false, dialog is not visible
 * @param onClose Callback when dialog is closed - passed either null or an object
 *                representing the schedule update to apply
 * @param totalPrice Total amount of the payment schedule being updated
 * @param currency Currency of the payment schedule being updated
 * @param paymentSchedule Payment schedule object
 * @param scheduleFieldToUpdate Which field is being updated - 'commission' or 'amount'
 * @returns {JSX.Element}
 * @constructor
 */
const PaymentScheduleBulkUpdateDialog = ({
  fullWidth,
  shouldOpen,
  onClose,
  totalPrice,
  currency,
  paymentSchedule,
  scheduleFieldToUpdate,
}) => {
  const numPeriods = paymentSchedule && paymentSchedule.length;

  const defaultPeriod = scheduleFieldToUpdate === 'commission' ? 1 : 2;

  const [firstPeriod, setFirstPeriod] = useState(defaultPeriod);
  const [lastPeriod, setLastPeriod] = useState(-1);
  const [totalAmount, setTotalAmount] = useState(-1);
  const [validationError, setValidationError] = useState(null);
  const totalSetManually = useRef(false);

  // The component may load with numPeriods=0; if so, reload after periods set
  useEffect(() => {
    if (lastPeriod !== -1) {
      return;
    }
    if (lastPeriod === -1 && numPeriods) {
      setLastPeriod(numPeriods);
    }
  }, [lastPeriod, numPeriods, setLastPeriod]);

  // Validate all fields together
  useEffect(() => {
    if (totalSetManually.current && (isNaN(totalAmount) || totalAmount < 0 || totalAmount > totalPrice)) {
      setValidationError(`Total amount must be at most ${currencySymbols[currency]}${totalPrice}`);
      return;
    }

    if (firstPeriod <= 0 || firstPeriod > numPeriods) {
      setValidationError(`First period must be between 1 and ${numPeriods}`);
      return;
    }
    if (lastPeriod <= 0 || lastPeriod > numPeriods) {
      setValidationError(`Last period must be between 1 and ${numPeriods}`);
      return;
    }

    if (firstPeriod > lastPeriod) {
      setValidationError('First period must not be after last period');
      return;
    }

    if (!totalSetManually.current) {
      setTotalAmount(
        getRemainingFromSchedule(firstPeriod, lastPeriod, paymentSchedule, totalPrice, scheduleFieldToUpdate)
      );
      if (totalAmount < 0) {
        setValidationError("The other periods of the schedule already add up to more than the schedule's total");
        return;
      }
    }

    setValidationError(null);
  }, [firstPeriod, lastPeriod, totalAmount, numPeriods, totalPrice, currency, totalSetManually,
    paymentSchedule, scheduleFieldToUpdate, shouldOpen]);

  return (
    <Dialog
      maxWidth={'sm'}
      fullWidth={fullWidth}
      open={shouldOpen}
      onClose={() => {
        onClose(null);
      }}
    >
      <Box className="createTransaction-bulkschedule-modal">
        <div className="createTransaction-bulkschedule-modal-title">
          Update Schedule by Evenly Spreading {scheduleFieldToUpdate === "commission" ? "Commission" : "Payments"}
        </div>
        <div className="createTransaction-bulkschedule-modal-body">
          <div className="createTransaction-inline-fields-container">
            <div className={'createTransaction-inline-field--half'}>
              <InputField
                name={`bulk.${scheduleFieldToUpdate}.firstPeriod`}
                validate={getPeriodValidator(numPeriods)}
                label="From Payment Number"
                isNumeric
                format={formatInteger}
                formatOnBlur
                initialValue={`${defaultPeriod}`}
                onChange={(evt) => {
                  setPeriod(evt, setFirstPeriod);
                }}
              />
            </div>
            <div className={'createTransaction-inline-field--half'}>
              <InputField
                name={`bulk.${scheduleFieldToUpdate}.lastPeriod`}
                validate={getPeriodValidator(numPeriods)}
                label="To Payment Number"
                isNumeric
                format={formatInteger}
                formatOnBlur
                initialValue={`${numPeriods}`}
                onChange={(evt) => {
                  setPeriod(evt, setLastPeriod);
                }}
              />
            </div>
          </div>
          <InputField
            name={`bulk.${scheduleFieldToUpdate}.amount`}
            validate={getAmountValidator(totalPrice)}
            label="Amount to Spread (recommend blank)"
            defaultHelperText="Leave blank to use system calculated remaining balance,
            spread evenly across the above payment range."
            currency={currency}
            isNumeric
            formatOnBlur
            placeholder={totalAmount.toFixed(2)}
            onChange={(evt) => {
              if (evt.target.value === '') {
                setTotalAmount(-1);
                totalSetManually.current = false;
                return;
              }
              setTotalAmount(Number.parseFloat(evt.target.value));
              totalSetManually.current = true;
            }}
          />
          <div className="createTransaction-bulkschedule-modal-description">
            {validationError !== null && (
              <div className="createTransaction-bulkschedule-modal-error">{validationError}</div>
            )}
            {validationError === null && (
              <div>
                This will update the schedule with {lastPeriod - firstPeriod + 1} {scheduleFieldToUpdate === 'commission' ? "broker's commission" : ""} payment
                {lastPeriod > firstPeriod ? 's' : ''} of{' '}
                {currencySymbols[currency] +
                  formatPrice(totalAmount / (lastPeriod - firstPeriod + 1))}
                , starting on Payment {firstPeriod} and ending on Payment {lastPeriod} (out of{' '}
                {numPeriods} total payments).
              </div>
            )}
          </div>
        </div>
        <div className="createTransaction-bulkschedule-modal-buttons">
          <div className="createTransaction-bulkschedule-modal-buttons-cancel">
            <Button
              onClick={() => {
                onClose(null);
              }}
              variant="outlined"
              color="secondary"
              fullWidth={fullWidth}
              size="large"
            >
              Cancel
            </Button>
          </div>
          <SubmitButton
            disabled={validationError !== null}
            fullWidth={fullWidth}
            onClick={() => {
              const prevTotalAmount = totalAmount;
              if (!totalSetManually.current) {
                setTotalAmount(-1)
              }
              onClose({
                firstPeriod,
                lastPeriod,
                totalAmount: prevTotalAmount,
              });
            }}
          >
            Update Schedule
          </SubmitButton>
        </div>
      </Box>
    </Dialog>
  );
};

function keepOldPaymentAmountsIfPossible(oldSchedule, newSchedule) {
  if (oldSchedule.length !== newSchedule.length) {
    return newSchedule;
  }
  for (let i = 0; i < oldSchedule.length; i++) {
    newSchedule[i].amount = oldSchedule[i].amount;
  }
  return newSchedule;
}

const PaymentSchedule = ({
  year,
  month,
  paymentFrequency,
  firstDate,
  totalPrice,
  brokerCommission,
  paymentSchedule,
}) => {
  const { width } = useWindowDimensions();
  const isDesktopView = width >= TransactionConstants.DESKTOP_VIEW_WIDTH;
  const form = useForm();
  const formState = form.getState();
  const currency = form.getFieldState('currency').value;
  const brokerCommissionPayer = formState.values.brokerCommissionPayer;
  const showBrokerFields =
    brokerCommissionPayer &&
    brokerCommissionPayer !== TransactionConstants.TRANSACTION_ROLES.SELLER;
  let totalBuyerBrokerCommission = 0;
  if (showBrokerFields) {
    totalBuyerBrokerCommission = (
      brokerCommission / brokerCommissionPayer.split(',').length
    ).toFixed(2);
  }

  const [bulkUpdatingSchedule, setBulkUpdatingSchedule] = useState(null);

  /**
   * The lint rule on this effect's dependencies has been disabled as one of the recommended
   * dependencies causes this effect to trigger an infinite render + effect loop. This
   * arises from the mutation of the form inside this effect. A possible solution to this
   * is to move this logic to inside the calculateSchedule() function.
   */
  useEffect(
    () => {
      if (paymentSchedule && paymentSchedule.length > 0) {
        const newSchedule = paymentSchedule.map((sched, i) => ({
          ...sched,
          commission: i === 0 ? totalBuyerBrokerCommission : '0.00',
        }));
        form.change('paymentSchedule',
          keepOldPaymentAmountsIfPossible(paymentSchedule, newSchedule));
      }
    },
    [brokerCommission, brokerCommissionPayer, totalBuyerBrokerCommission] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
      const newSchedule = calculateSchedule(
        year,
        month,
        paymentFrequency,
        totalPrice,
        firstDate,
        totalBuyerBrokerCommission
      );
      if (newSchedule) {
        form.change('paymentSchedule', newSchedule);
      }
    }, [year, month, paymentFrequency, firstDate, totalPrice, form] // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (
    !firstDate ||
    (!year && !month) ||
    !totalPrice ||
    Number(totalPrice) <= 0 ||
    !paymentFrequency ||
    !form.getFieldState('firstPaymentDate') ||
    !form.getFieldState('firstPaymentDate').valid
  ) {
    return null;
  }

  const closeBulkUpdateModal = (committedObjectOrNull) => {
    const typeOfBulkUpdate = bulkUpdatingSchedule;

    setBulkUpdatingSchedule(false);

    if (committedObjectOrNull === null) {
      return;
    }

    const {firstPeriod, lastPeriod, totalAmount} = committedObjectOrNull;

    let totalApplied = 0.0;

    const newSchedule = paymentSchedule.map((sched, i) => {
      let amountToApplyHere = typeOfBulkUpdate === "amount" ? sched.amount : sched.commission;

      if (i >= firstPeriod - 1 && i <= lastPeriod - 1) {
        amountToApplyHere = totalAmount / (lastPeriod - firstPeriod + 1);

        // Handle 'rounding off' any leftover pennies in the total
        // it's important that we add the ROUNDED amount to the total,
        // so that we notice if the numbers - when rounded - don't add up
        totalApplied += Number.parseFloat(formatPrice(amountToApplyHere));
        if (i === lastPeriod - 1) {
          amountToApplyHere += totalAmount - totalApplied;
        }

        amountToApplyHere = formatPrice(amountToApplyHere);
      }

      const ret = {...sched};
      ret[typeOfBulkUpdate] = amountToApplyHere;
      return ret;
    });

    form.change('paymentSchedule', newSchedule);
  };

  const fullWidth = width < TransactionConstants.DESKTOP_VIEW_WIDTH;

  return (
    <div>
      <Form onSubmit={() => {}}
            key={`createTransaction-bulk-${totalPrice}-${paymentFrequency}-${paymentSchedule.length}`}
      >
        {() => (
          <div>
            <PaymentScheduleBulkUpdateDialog
              fullWidth={fullWidth}
              shouldOpen={bulkUpdatingSchedule === 'amount'}
              onClose={closeBulkUpdateModal}
              totalPrice={totalPrice}
              currency={currency}
              paymentSchedule={paymentSchedule}
              scheduleFieldToUpdate={'amount'}
            />
            <PaymentScheduleBulkUpdateDialog
              fullWidth={fullWidth}
              shouldOpen={bulkUpdatingSchedule === 'commission'}
              onClose={closeBulkUpdateModal}
              totalPrice={totalBuyerBrokerCommission}
              currency={currency}
              paymentSchedule={paymentSchedule}
              scheduleFieldToUpdate={'commission'}
            />
          </div>
        )}
      </Form>
      <div>
        <div className="createTransaction-subform--header">Payment schedule</div>
        <Divider variant="fullWidth" />
        <FieldArray
          name="paymentSchedule"
          validate={(values, allValues) => validateAmount(values, allValues, currency)}
          validateFields={[
            'items[0].price',
            'years',
            'months',
            'firstPaymentDate',
            'paymentFrequency',
          ]}
        >
          {({ fields, meta }) => (
            <div>
              {fields.map((name, i) => (
                <div key={name}>
                  {i === 1 && (
                    <div className="createTransaction-button-container-center">
                      {(!showBrokerFields ||
                        brokerCommissionPayer ===
                          TransactionConstants.TRANSACTION_ROLES.SELLER) && (
                          <Button size="small" style={{visibility: 'hidden'}}>Invisible</Button>
                        )}
                      <Button
                        onClick={() => {
                          setBulkUpdatingSchedule('amount');
                        }}
                        variant="outlined"
                        color="primary"
                        fullWidth={fullWidth}
                        size="small"
                      >
                        Spread payments
                      </Button>
                      {showBrokerFields && brokerCommissionPayer !== TransactionConstants.TRANSACTION_ROLES.SELLER && (
                        <Button
                          onClick={() => {
                            setBulkUpdatingSchedule('commission');
                          }}
                          variant="outlined"
                          color="primary"
                          fullWidth={fullWidth}
                          size="small"
                        >
                          Spread commission
                        </Button>
                      )}
                    </div>
                  )}
                  <div
                    className={
                      showBrokerFields ? null : 'createTransaction-inline-fields-container'
                    }
                  >
                    {(isDesktopView || showBrokerFields) && (
                      <div className="createTransaction-inline-field--half createTransaction-label-container">
                        Payment {i + 1} on {fields.value[i].date.format('MM/DD/YYYY')}
                      </div>
                    )}
                    <div
                      className={
                        showBrokerFields
                          ? 'createTransaction-inline-fields-container'
                          : 'createTransaction-inline-field--half'
                      }
                    >
                      <div
                        className={
                          showBrokerFields
                            ? 'createTransaction-inline-field--half'
                            : 'createTransaction-inline-field--wide'
                        }
                      >
                        <InputField
                          name={`${name}.amount`}
                          label={
                            isDesktopView || showBrokerFields
                              ? `Amount (${currency.toUpperCase()})`
                              : `Payment ${i + 1} on ${fields.value[i].date.format('MM/DD/YYYY')}`
                          }
                          isNumeric
                          currency={currency}
                          validate={priceValidate}
                          format={formatPrice}
                          formatOnBlur
                          touchBeforeError={false}
                          initialValue={fields.value[i].amount}
                        />
                      </div>
                      {showBrokerFields && (
                        <div className="createTransaction-inline-field--half">
                          <InputField
                            name={`${name}.commission`}
                            label={`Broker's Commission (${currency.toUpperCase()})`}
                            isNumeric
                            currency={currency}
                            format={formatPrice}
                            formatOnBlur
                            touchBeforeError={false}
                            initialValue={fields.value[i].commission}
                          />
                        </div>
                      )}
                    </div>
                    {showBrokerFields && (
                      <div className="createTransaction-label-container--right">
                        Total:{' '}
                        {formatCurrency(
                          Number(fields.value[i].amount) + Number(fields.value[i].commission),
                          currency
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))}
              {meta.error && <ErrorBox message={meta.error} />}
            </div>
          )}
        </FieldArray>
      </div>
    </div>
  );
};

export default PaymentSchedule;
