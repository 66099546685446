var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["components/inputs.html"] = require( "components/inputs.html" );
dependencies["components/buttons.html"] = require( "components/buttons.html" );
dependencies["icons/cancel.html"] = require( "icons/cancel.html" );
dependencies["icons/search.html"] = require( "icons/search.html" );
dependencies["icons/right.html"] = require( "icons/right.html" );
dependencies["dropdown.html"] = require( "dropdown.html" );
dependencies["search/popularSearches.html"] = require( "search/popularSearches.html" );




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/components/search.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("components/inputs.html", false, "escrow_www/templates/components/search.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(context.getVariables(), frame, function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("input", t_1);
env.getTemplate("components/buttons.html", false, "escrow_www/templates/components/search.html", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(context.getVariables(), frame, function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("button", t_4);
env.getTemplate("icons/cancel.html", false, "escrow_www/templates/components/search.html", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
t_7.getExported(context.getVariables(), frame, function(t_9,t_7) {
if(t_9) { cb(t_9); return; }
if(t_7.hasOwnProperty("cancel")) {
var t_10 = t_7.cancel;
} else {
cb(new Error("cannot import 'cancel'")); return;
}
context.setVariable("cancelIcon", t_10);
env.getTemplate("icons/search.html", false, "escrow_www/templates/components/search.html", false, function(t_12,t_11) {
if(t_12) { cb(t_12); return; }
t_11.getExported(context.getVariables(), frame, function(t_13,t_11) {
if(t_13) { cb(t_13); return; }
if(t_11.hasOwnProperty("search")) {
var t_14 = t_11.search;
} else {
cb(new Error("cannot import 'search'")); return;
}
context.setVariable("searchIcon", t_14);
env.getTemplate("icons/right.html", false, "escrow_www/templates/components/search.html", false, function(t_16,t_15) {
if(t_16) { cb(t_16); return; }
t_15.getExported(context.getVariables(), frame, function(t_17,t_15) {
if(t_17) { cb(t_17); return; }
if(t_15.hasOwnProperty("right")) {
var t_18 = t_15.right;
} else {
cb(new Error("cannot import 'right'")); return;
}
context.setVariable("rightIcon", t_18);
env.getTemplate("dropdown.html", false, "escrow_www/templates/components/search.html", false, function(t_20,t_19) {
if(t_20) { cb(t_20); return; }
t_19.getExported(context.getVariables(), frame, function(t_21,t_19) {
if(t_21) { cb(t_21); return; }
context.setVariable("dropdown", t_19);
var macro_t_22 = runtime.makeMacro(
[], 
["specifier", "name", "mutator", "suggestion_class", "attrs", "button_attrs", "input_attrs"], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("name", kwargs.hasOwnProperty("name") ? kwargs["name"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("suggestion_class", kwargs.hasOwnProperty("suggestion_class") ? kwargs["suggestion_class"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("button_attrs", kwargs.hasOwnProperty("button_attrs") ? kwargs["button_attrs"] : {});
frame.set("input_attrs", kwargs.hasOwnProperty("input_attrs") ? kwargs["input_attrs"] : {});
var t_23 = "";t_23 += "<form class=\"";
t_23 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "specifier"), env.opts.autoescape);
t_23 += " search defaultForm--inline ";
t_23 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mutator"), env.opts.autoescape);
t_23 += "\" ";
t_23 += runtime.suppressValue(env.getFilter("xmlattr").call(context, runtime.contextOrFrameLookup(context, frame, "attrs")), env.opts.autoescape);
t_23 += " role=\"search\">\n  <div class=\"defaultForm-group search-suggestion ";
t_23 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "suggestion_class"), env.opts.autoescape);
t_23 += "\" data-component=\"searchOverlay-suggestion\">Did you mean: </div>\n  <div class=\"search-container\">\n    ";
t_23 += runtime.suppressValue((lineno = 11, colno = 15, runtime.callWrap(t_14, "searchIcon", context, [runtime.makeKeywordArgs({"specifier": "search-icon icon--small"})])), env.opts.autoescape);
t_23 += "\n    ";
t_23 += runtime.suppressValue((lineno = 12, colno = 17, runtime.callWrap(runtime.memberLookup((t_1),"search"), "input[\"search\"]", context, ["search",runtime.makeKeywordArgs({"name": runtime.contextOrFrameLookup(context, frame, "name"),"placeholder": (lineno = 12, colno = 58, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Search"])),"specifier": "search-field","dropdown": (lineno = 12, colno = 122, runtime.callWrap(runtime.memberLookup((t_19),"dropdown"), "dropdown[\"dropdown\"]", context, [runtime.makeKeywordArgs({"specifier": "search-searchResults","mutators": "field-dropdown"})])),"attrs": runtime.contextOrFrameLookup(context, frame, "input_attrs")})])), env.opts.autoescape);
t_23 += "\n  </div>\n</form>";
;
frame = frame.pop();
return new runtime.SafeString(t_23);
});
context.addExport("default");
context.setVariable("default", macro_t_22);
output += "\n\n";
var macro_t_24 = runtime.makeMacro(
[], 
["specifier", "name", "mutator", "suggestion_class", "attrs", "button_attrs", "input_attrs"], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("name", kwargs.hasOwnProperty("name") ? kwargs["name"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("suggestion_class", kwargs.hasOwnProperty("suggestion_class") ? kwargs["suggestion_class"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("button_attrs", kwargs.hasOwnProperty("button_attrs") ? kwargs["button_attrs"] : {});
frame.set("input_attrs", kwargs.hasOwnProperty("input_attrs") ? kwargs["input_attrs"] : {});
var t_25 = "";t_25 += "<form class=\"";
t_25 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "specifier"), env.opts.autoescape);
t_25 += " search search--large defaultForm--inline ";
t_25 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mutator"), env.opts.autoescape);
t_25 += "\" ";
t_25 += runtime.suppressValue(env.getFilter("xmlattr").call(context, runtime.contextOrFrameLookup(context, frame, "attrs")), env.opts.autoescape);
t_25 += " role=\"search\">\n  <div class=\"search-suggestion ";
t_25 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "suggestion_class"), env.opts.autoescape);
t_25 += "\" data-component=\"searchOverlay-suggestion\">Did you mean: </div>\n  <div class=\"search-container\">\n    ";
t_25 += runtime.suppressValue((lineno = 21, colno = 15, runtime.callWrap(t_14, "searchIcon", context, [runtime.makeKeywordArgs({"specifier": "search-icon icon--small"})])), env.opts.autoescape);
t_25 += "\n    ";
t_25 += runtime.suppressValue((lineno = 22, colno = 17, runtime.callWrap(runtime.memberLookup((t_1),"search"), "input[\"search\"]", context, [runtime.makeKeywordArgs({"label": "search","name": runtime.contextOrFrameLookup(context, frame, "name"),"style": "field--large","placeholder": (lineno = 22, colno = 86, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["What are you looking for?"])),"dropdown": (lineno = 22, colno = 143, runtime.callWrap(runtime.memberLookup((t_19),"dropdown"), "dropdown[\"dropdown\"]", context, [runtime.makeKeywordArgs({"specifier": "search-searchResults","mutators": "field-dropdown"})])),"attrs": runtime.contextOrFrameLookup(context, frame, "input_attrs")})])), env.opts.autoescape);
t_25 += "\n  </div>\n</form>";
;
frame = frame.pop();
return new runtime.SafeString(t_25);
});
context.addExport("large");
context.setVariable("large", macro_t_24);
output += "\n\n";
var macro_t_26 = runtime.makeMacro(
[], 
["specifier", "mutator", "suggestion_class", "attrs", "button_attrs"], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("suggestion_class", kwargs.hasOwnProperty("suggestion_class") ? kwargs["suggestion_class"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("button_attrs", kwargs.hasOwnProperty("button_attrs") ? kwargs["button_attrs"] : {});
var t_27 = "";t_27 += "<form class=\"";
t_27 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "specifier"), env.opts.autoescape);
t_27 += " search search--huge defaultForm--inline ";
t_27 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mutator"), env.opts.autoescape);
t_27 += "\" ";
t_27 += runtime.suppressValue(env.getFilter("xmlattr").call(context, runtime.contextOrFrameLookup(context, frame, "attrs")), env.opts.autoescape);
t_27 += " role=\"search\">\n  <div class=\"search-suggestion ";
t_27 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "suggestion_class"), env.opts.autoescape);
t_27 += "\" data-component=\"searchOverlay-suggestion\">Did you mean: </div>\n  <div class=\"search-container\">\n    ";
t_27 += runtime.suppressValue((lineno = 31, colno = 15, runtime.callWrap(t_14, "searchIcon", context, [runtime.makeKeywordArgs({"specifier": "search-icon"})])), env.opts.autoescape);
t_27 += "\n    ";
t_27 += runtime.suppressValue((lineno = 32, colno = 17, runtime.callWrap(runtime.memberLookup((t_1),"search"), "input[\"search\"]", context, ["search",runtime.makeKeywordArgs({"style": "field--huge","specifier": "search-field","dropdown": (lineno = 32, colno = 101, runtime.callWrap(runtime.memberLookup((t_19),"dropdown"), "dropdown[\"dropdown\"]", context, [runtime.makeKeywordArgs({"specifier": "search-searchResults","mutators": "field-dropdown"})]))})])), env.opts.autoescape);
t_27 += "\n  </div>\n</form>";
;
frame = frame.pop();
return new runtime.SafeString(t_27);
});
context.addExport("huge");
context.setVariable("huge", macro_t_26);
output += "\n\n";
var macro_t_28 = runtime.makeMacro(
[], 
["title", "rubric", "link"], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("title", kwargs.hasOwnProperty("title") ? kwargs["title"] : "");
frame.set("rubric", kwargs.hasOwnProperty("rubric") ? kwargs["rubric"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "#");
var t_29 = "";t_29 += "<a href=\"";
t_29 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "link"), env.opts.autoescape);
t_29 += "\" class=\"searchOverlay-result\" data-target=\"searchOverlay-result\">\n  <span class=\"searchOverlay-resultIcon\">";
t_29 += runtime.suppressValue((lineno = 39, colno = 50, runtime.callWrap(t_18, "rightIcon", context, [])), env.opts.autoescape);
t_29 += "</span>\n  <div class=\"searchOverlay-resultTitle\">";
t_29 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "title"), env.opts.autoescape);
t_29 += "</div>\n  <div class=\"searchOverlay-more\" data-e2e-target=\"searchOverlay-learn-more\">";
t_29 += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_30 = "";t_30 += "Learn more";
cb(null, t_30);
;
return t_30;
}
), true && env.opts.autoescape);
t_29 += "</div>\n</a>";
;
frame = frame.pop();
return new runtime.SafeString(t_29);
});
context.addExport("searchResult");
context.setVariable("searchResult", macro_t_28);
output += "\n\n";
var macro_t_31 = runtime.makeMacro(
["search_term", "results"], 
["icon", "attrs"], 
function (l_search_term, l_results, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("search_term", l_search_term);
frame.set("results", l_results);
frame.set("icon", kwargs.hasOwnProperty("icon") ? kwargs["icon"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
var t_32 = "";t_32 += "<h2 class=\"searchOverlay-resultsCategory\" data-target=\"searchOverlay-categoryTitle\">\n    <span class=\"searchOverlay-resultsCategoryIcon\">";
t_32 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "icon"), env.opts.autoescape);
t_32 += "</span>\n    ";
t_32 += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_33 = "";t_33 += "results for '";
t_33 += runtime.suppressValue(l_search_term, env.opts.autoescape);
t_33 += "'";
cb(null, t_33);
;
return t_33;
}
), true && env.opts.autoescape);
t_32 += "\n  </h2>\n  ";
if(env.getFilter("length").call(context, l_results) > 0) {
frame = frame.push();
var t_36 = l_results;
if(t_36) {var t_35 = t_36.length;
for(var t_34=0; t_34 < t_36.length; t_34++) {
var t_37 = t_36[t_34];
frame.set("result", t_37);
frame.set("loop.index", t_34 + 1);
frame.set("loop.index0", t_34);
frame.set("loop.revindex", t_35 - t_34);
frame.set("loop.revindex0", t_35 - t_34 - 1);
frame.set("loop.first", t_34 === 0);
frame.set("loop.last", t_34 === t_35 - 1);
frame.set("loop.length", t_35);
t_32 += "\n      ";
t_32 += runtime.suppressValue((lineno = 52, colno = 18, runtime.callWrap(macro_t_28, "searchResult", context, [runtime.makeKeywordArgs({"title": runtime.memberLookup((t_37),"title"),"rubric": runtime.memberLookup((t_37),"rubric"),"link": runtime.memberLookup((t_37),"link")})])), env.opts.autoescape);
t_32 += "\n    ";
;
}
}
frame = frame.pop();
t_32 += "\n  ";
;
}
else {
t_32 += "\n    <div class=\"searchOverlay-empty\" data-target=\"searchOverlay-empty\">\n      ";
t_32 += runtime.suppressValue((lineno = 56, colno = 16, runtime.callWrap(t_10, "cancelIcon", context, [])), env.opts.autoescape);
t_32 += "\n      <strong>";
t_32 += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_38 = "";t_38 += "Sorry, we couldn't find any results for '";
t_38 += runtime.suppressValue(l_search_term, env.opts.autoescape);
t_38 += "'.";
cb(null, t_38);
;
return t_38;
}
), true && env.opts.autoescape);
t_32 += "</strong><br/>\n      ";
t_32 += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_39 = "";t_39 += "Please try another search, or click on one of our popular searches.";
cb(null, t_39);
;
return t_39;
}
), true && env.opts.autoescape);
t_32 += "\n      <div class=\"searchOverlay-emptySuggested\">\n        ";
env.getTemplate("search/popularSearches.html", false, "escrow_www/templates/components/search.html", null, function(t_42,t_40) {
if(t_42) { cb(t_42); return; }
t_40.render(context.getVariables(), frame, function(t_43,t_41) {
if(t_43) { cb(t_43); return; }
t_32 += t_41
t_32 += "\n      </div>\n    </div>\n  ";
})});
}
;
frame = frame.pop();
return new runtime.SafeString(t_32);
});
context.addExport("searchResultCategory");
context.setVariable("searchResultCategory", macro_t_31);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/components/search.html"] , dependencies)