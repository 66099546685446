import React from 'react';
import ReactSVG from 'react-svg';
import { gettext } from '../../utils/filters';

class Header extends React.Component {
  renderEscrowLogo() {
    return <ReactSVG src={`../../../../build/images/global/escrow-logo-v2.svg`} />;
  }

  render() {
    return (
      <div>
        <header className="simpleHeader simpleHeader--small">
          <div className="section-container">
            <div className="simpleHeader-inner">
              {this.renderEscrowLogo()}
              <span className="simpleHeader-detail">
                {this.props.userEmail ? (
                  <div>
                    <span className="simpleHeader-detail-label">{gettext('Logged in as')} </span>
                    <span className="simpleHeader-detail-value">{this.props.userEmail}</span>
                  </div>
                ) : (
                  <span className="simpleHeader-detail-label">{this.props.placeholder}</span>
                )}
              </span>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default Header;
