import { loadDocuments as loadDocumentsRoutine } from 'spa/actions/AdditionalDocumentsActions';
const initialState = {
  requiredDocs: null,
  loading: true,
};

function additionalDocumentsReducer(state = initialState, action) {
  switch (action.type) {
    case loadDocumentsRoutine.TRIGGER:
      return {
        ...state,
        loading: true,
      };
    case loadDocumentsRoutine.SUCCESS:
      return {
        ...state,
        loading: false,
        requiredDocs: action.payload.requiredDocs,
      };
    case loadDocumentsRoutine.FAILURE:
      return {
        ...state,
        loading: false,
        requiredDocs: [],
      };
    default:
      return state;
  }
}

export default additionalDocumentsReducer;
