import React from 'react';
import PropTypes from 'prop-types';
import window from 'window-shim';
import { connect } from 'react-redux';
import { FormSection, Field, change } from 'redux-form';

import { documents, RegionsByCountry } from 'escrow-common-js/dist/constants';
import VerificationConstants from 'spa/constants/VerificationConstants';
import { getTwoFactorStatus } from 'spa/actions/TwoFactorActions';
import { twoFactorSelector } from 'spa/selectors/TwoFactorSelectors';
import { Input, Select, PhoneSelect, DateOfBirthField, RadioGroup } from 'spa/components/form';
import { required, maxChars, phone, date, age18 } from 'spa/components/form/validate';
import { CountryToAlpha2, Alpha2VatCountries, Alpha2ToAlpha3 } from 'spa/constants/ISOCountryCodes';
import AuthenticationStore from '../../../stores/AuthenticationStore';
import VatFieldset from '../TaxDetails/VatFieldset';
import { gettext } from '../../../utils/filters';
import { createSelectOptions } from '../../../utils/select';
import AutocompleteAddressConstants from '../../../constants/AutocompleteAddressConstants';
import API from '../../../api';

export const hasVatDetails = (formData) => {
  if (!formData || !formData.vatDetails || !formData.vatDetails.vatNumber) {
    return false;
  }

  return Boolean(Alpha2VatCountries.includes(formData.country || formData.companyCountry));
};

class ContactDetailsFieldset extends React.Component {
  constructor(props) {
    super(props);
    this.line1Ref = null;
    this.companyLine1Ref = null;
    this.autocomplete = null;
    this.autocompleteCompany = null;

    this.validators = {
      addressLine1: [maxChars(60, gettext('Address Line 1'))],
      addressLine2: [maxChars(60, gettext('Address Line 2'))],
      city: [maxChars(30, gettext('City'))],
      state: [maxChars(250, gettext('State'))],
      country: [maxChars(2, gettext('Country'))],
      zipcode: [maxChars(15, gettext('Post Code'))],
      firstName: [maxChars(50, gettext('First Name'))],
      middleName: [maxChars(50, gettext('Middle Name'))],
      lastName: [maxChars(50, gettext('Last Name'))],
      localFName: [maxChars(250, gettext('Local First Name'))],
      localMName: [maxChars(250, gettext('Local Middle Name'))],
      localLName: [maxChars(250, gettext('Local Last Name'))],
      companyName: [maxChars(50, gettext('Company Name'))],
      dateOfBirth: [date, age18],
      phone: phone('primaryPhoneCountry'),
    };

    documents.map((doc) =>
      doc.fields.map((field) => {
        this.validators[`${doc.name}_${field.name}`] = (field.validators || []).map((validator) =>
          validator(doc)
        );
        if (doc.required && field.required) {
          this.validators[`${doc.name}_${field.name}`].push(required);
        }
      })
    );

    this.handleEnterKey = this.handleEnterKey.bind(this);
    this.normalizePhone = this.normalizePhone.bind(this);
    this.handleCountrySelect = this.handleCountrySelect.bind(this);
    this.handleCompanyCountrySelect = this.handleCompanyCountrySelect.bind(this);
  }

  componentDidMount() {
    this.bindAddressAutocomplete();

    if (AuthenticationStore.isAuthenticated()) {
      this.props.getTwoFactorStatus();
      API.getCustomerNationalDocuments().then((nationalDocuments) => {
        (nationalDocuments || []).map((doc) =>
          this.props.dispatch(change(this.props.formName, `${doc.type}-${doc.field}`, doc.value))
        );
      });
    }
  }

  componentDidUpdate() {
    this.bindAddressAutocomplete();
  }

  componentWillUnmount() {
    if (this.line1Ref) {
      const line1Input = this.line1Ref.getRenderedComponent().inputRef;
      line1Input.removeEventListener('keydown', this.handleEnterKey);
    }

    if (this.companyLine1Ref) {
      const companyLine1Input = this.companyLine1Ref.getRenderedComponent().inputRef;
      companyLine1Input.removeEventListener('keydown', this.handleEnterKey);
    }
  }

  getStateSelectOptions(country) {
    const states = RegionsByCountry[country];
    const result = [];

    if (states) {
      for (const state of states) {
        const unique = states.filter((s) => s.name === state.name).length === 1;
        const uniqueName = unique ? state.name : `${state.name} (${state.type})`;
        result.push({ value: uniqueName, label: uniqueName });
      }
    }
    return result;
  }

  bindAddressAutocomplete() {
    if (this.line1Ref && !this.autocomplete && window.google) {
      const line1Input = this.line1Ref.getRenderedComponent().inputRef;
      this.autocomplete = new window.google.maps.places.Autocomplete(line1Input, {
        types: ['address'],
        componentRestrictions: this.props.country ? { country: this.props.country } : null,
      });
      this.autocomplete.addListener('place_changed', this.fillAddress.bind(this, false));
      line1Input.addEventListener('keydown', this.handleEnterKey);
    }

    if (this.companyLine1Ref && !this.autocompleteCompany && window.google) {
      const companyLine1Input = this.companyLine1Ref.getRenderedComponent().inputRef;
      this.autocompleteCompany = new window.google.maps.places.Autocomplete(companyLine1Input, {
        types: ['address'],
        componentRestrictions: this.props.companyCountry
          ? { country: this.props.companyCountry }
          : null,
      });
      this.autocompleteCompany.addListener('place_changed', this.fillAddress.bind(this, true));
      companyLine1Input.addEventListener('keydown', this.handleEnterKey);
    }

    if (!this.line1Ref && this.autocomplete) {
      window.google.maps.event.clearInstanceListeners(this.autocomplete);
      this.autocomplete = null;
    }

    if (!this.companyLine1Ref && this.autocompleteCompany) {
      window.google.maps.event.clearInstanceListeners(this.autocompleteCompany);
      this.autocompleteCompany = null;
    }
  }

  handleEnterKey(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  handleCountrySelect(event, newValue) {
    // EU has different initials for Greece
    if (event.target.value === 'GR') {
      this.props.dispatch(change(this.props.formName, `vatDetails.vatCountry`, 'EL'));
    } else {
      this.props.dispatch(change(this.props.formName, `vatDetails.vatCountry`, event.target.value));
    }

    if (this.autocomplete) {
      this.autocomplete.setComponentRestrictions({ country: event.target.value });
    }

    this.props.dispatch(change(this.props.formName, `state`, ''));
    if (this.props.primaryPhoneCountry !== Alpha2ToAlpha3[newValue]) {
      this.props.dispatch(
        change(this.props.formName, 'primaryPhoneCountry', Alpha2ToAlpha3[newValue])
      );
      this.props.dispatch(change(this.props.formName, 'primaryPhoneNumber', ''));
    }
  }

  handleCompanyCountrySelect(event) {
    if (this.autocompleteCompany) {
      this.autocompleteCompany.setComponentRestrictions({ country: event.target.value });
    }

    this.props.dispatch(change(this.props.formName, `companyState`, ''));
  }

  fillAddress(isCompany) {
    const place = isCompany ? this.autocompleteCompany.getPlace() : this.autocomplete.getPlace();
    if (!place || !place.address_components) {
      return null;
    }

    const address = place.address_components;

    const addressDict = {
      Street1: '',
      Street2: '',
      City: '',
      State: '',
      Zip: '',
      Country: '',
    };

    for (const component of address) {
      const mappedType = AutocompleteAddressConstants.GOOGLE_MAPS_FIELDS[component.types[0]];
      if (mappedType === 'Country') {
        addressDict[mappedType] = component.short_name;
      } else {
        addressDict[mappedType] += `${component.long_name} `;
      }
    }

    let mapping = null;
    if (!isCompany) {
      mapping = {
        addressLine1: addressDict.Street1,
        addressLine2: addressDict.Street2,
        city: addressDict.City,
        state: addressDict.State,
        postCode: addressDict.Zip,
        country: addressDict.Country,
      };
    } else {
      mapping = {
        companyAddressLine1: addressDict.Street1,
        companyAddressLine2: addressDict.Street2,
        companyCity: addressDict.City,
        companyState: addressDict.State,
        companyPostCode: addressDict.Zip,
        companyCountry: addressDict.Country,
      };
    }

    const formPrefix = this.props.formSectionName ? `${this.props.formSectionName}.` : '';
    for (const fieldName of Object.keys(mapping)) {
      this.props.dispatch(
        change(this.props.formName, `${formPrefix}${fieldName}`, mapping[fieldName].trim())
      );
    }

    if (this.autocomplete && mapping && mapping.country) {
      this.autocomplete.setComponentRestrictions({
        country: mapping.country.trim(),
      });
    }

    if (this.autocompleteCompany && mapping && mapping.companyCountry) {
      this.autocomplete.setComponentRestrictions({
        country: mapping.companyCountry.trim(),
      });
    }
  }

  normalizePhone(value) {
    const re = /^[0-9]*$/;

    if (re.test(value)) {
      return value;
    }
  }

  renderTitle(titleText) {
    return <h3 className="verifyPage-form-title">{titleText}</h3>;
  }

  renderBillingAddressTitle() {
    if (!this.props.billingAddressTitle) {
      return null;
    }

    return (
      <h3 className="checkout-heading checkout-heading--small">{this.props.billingAddressTitle}</h3>
    );
  }

  renderLocalNames(languageString) {
    return (
      <div>
        <Field
          name="localFName"
          component={Input}
          label={gettext(`First Name (${languageString})`)}
          validate={[required, ...this.validators.localFName]}
          disabled={this.props.disableNameAndDob}
          requiredIndicator
          data-e2e-target="local-first-name-field"
        />
        <Field
          name="localLName"
          component={Input}
          label={gettext(`Last Name (${languageString})`)}
          validate={[required, ...this.validators.localLName]}
          disabled={this.props.disableNameAndDob}
          requiredIndicator
          data-e2e-target="local-last-name-field"
        />
      </div>
    );
  }

  renderPersonalInformationFields() {
    const languageString = VerificationConstants.CountriesWithLocalNames[this.props.country];
    return (
      <div>
        <div className="grid grid--tight">
          <div className="grid-col grid-col--tablet-4">
            <Field
              name="firstName"
              component={Input}
              label={gettext('First Name')}
              validate={[required, ...this.validators.firstName]}
              disabled={this.props.disableNameAndDob}
              requiredIndicator
              data-e2e-target="first-name-field"
            />
          </div>
          <div className="grid-col grid-col--tablet-4">
            <Field
              name="middleName"
              component={Input}
              label={gettext('Middle Name')}
              disabled={this.props.disableNameAndDob}
              validate={[...this.validators.middleName]}
              data-e2e-target="middle-name-field"
            />
          </div>
          <div className="grid-col grid-col--tablet-4">
            <Field
              name="lastName"
              component={Input}
              label={gettext('Last Name')}
              validate={[required, ...this.validators.lastName]}
              disabled={this.props.disableNameAndDob}
              requiredIndicator
              data-e2e-target="last-name-field"
            />
          </div>
        </div>
        {languageString && this.renderLocalNames(languageString)}
        <Field
          name="dateOfBirth"
          component={DateOfBirthField}
          label={gettext('Date of Birth')}
          validate={[required, ...this.validators.dateOfBirth]}
          disabled={this.props.disableNameAndDob}
          requiredIndicator
          data-e2e-target="date-of-birth-field"
        />
        <div className="field">
          <label className="field-label">
            {gettext('Primary Phone Number')}
            <span className="field-required">*</span>
          </label>
          <div className="defaultForm-group defaultForm-group--mobile">
            <Field
              name="primaryPhoneCountry"
              component={PhoneSelect}
              className="field--phoneSelect"
              validate={[required]}
              disabled={this.props.type2faStatuses.smsEnabled}
              placeholder={`-- ${gettext('country code')} --`}
              data-e2e-target="primary-phone-field-country"
            />
            <Field
              name="primaryPhoneNumber"
              component={Input}
              validate={[required, this.validators.phone]}
              disabled={this.props.type2faStatuses.smsEnabled}
              data-e2e-target="primary-phone-field-number"
              normalize={this.normalizePhone}
            />
          </div>
        </div>
      </div>
    );
  }

  renderCustomerAddressFields() {
    return (
      <div>
        <Field
          name="addressLine1"
          component={Input}
          label={gettext('Address Line 1')}
          validate={[required, ...this.validators.addressLine1]}
          ref={(node) => {
            this.line1Ref = node;
          }}
          forwardRef
          requiredIndicator
          data-e2e-target="address-line-1-field"
        />
        <Field
          name="addressLine2"
          component={Input}
          label={gettext('Address Line 2 (Optional)')}
          validate={[...this.validators.addressLine2]}
          data-e2e-target="address-line-2-field"
        />
        <div className="grid grid--tight">
          <div className="grid-col grid-col--tablet-6">
            <Field
              name="city"
              component={Input}
              label={gettext('City')}
              validate={[required, ...this.validators.city]}
              data-e2e-target="city-field"
              requiredIndicator
            />
          </div>
          {this.renderStateField()}
          <div className="grid-col grid-col--tablet-12">
            <Field
              name="postCode"
              component={Input}
              label={gettext('Zip Code / Post Code')}
              validate={[...this.validators.zipcode]}
              data-e2e-target="post-code-field"
            />
          </div>
        </div>
      </div>
    );
  }

  renderCountryField() {
    return (
      <div>
        <Field
          name="country"
          onChange={this.handleCountrySelect}
          label={gettext('Country')}
          placeholder={`-- ${gettext('please select your country')} --`}
          component={Select}
          options={createSelectOptions(CountryToAlpha2)}
          validate={[required]}
          requiredIndicator
          data-e2e-target="country-field"
        />
      </div>
    );
  }

  renderStateField() {
    const states = this.getStateSelectOptions(this.props.country);
    if (states.length > 0) {
      return (
        <div className="grid-col grid-col--tablet-6">
          <Field
            name="state"
            label={gettext('State')}
            placeholder={`-- ${gettext('please select your state')} --`}
            component={Select}
            options={states}
            validate={[required]}
            requiredIndicator
            data-e2e-target="state-field"
          />
        </div>
      );
    }
    return (
      <div className="grid-col grid-col--tablet-6">
        <Field
          name="state"
          component={Input}
          label={gettext('State')}
          validate={[required, ...this.validators.state]}
          requiredIndicator
          data-e2e-target="state-field"
        />
      </div>
    );
  }

  renderCompanyStateField() {
    const states = this.getStateSelectOptions(this.props.companyCountry);
    if (states.length > 0) {
      return (
        <div className="grid-col grid-col--tablet-6">
          <Field
            name="companyState"
            label={gettext('State')}
            placeholder={`-- ${gettext("please select your company's state")} --`}
            component={Select}
            options={states}
            validate={[required]}
            requiredIndicator
            data-e2e-target="company-state-field"
          />
        </div>
      );
    }
    return (
      <div className="grid-col grid-col--tablet-6">
        <Field
          name="companyState"
          component={Input}
          label={gettext('State')}
          validate={[required, ...this.validators.state]}
          requiredIndicator
          data-e2e-target="company-state-field"
        />
      </div>
    );
  }

  renderVatField() {
    return (
      <FormSection name="vatDetails">
        <VatFieldset required={false} data-e2e-target="vat-field" />
      </FormSection>
    );
  }

  renderAccountTypeRadioButtons() {
    return (
      <RadioGroup
        name="accountType"
        formName={VerificationConstants.CUSTOMER_DETAILS_FORM}
        className="startTransaction-auth-radioGroup"
        options={[
          {
            label: gettext('Individual'),
            value: VerificationConstants.ACCOUNT_TYPE_INDIVIDUAL,
          },
          {
            label: gettext('Company'),
            value: VerificationConstants.ACCOUNT_TYPE_COMPANY,
          },
        ]}
        data-e2e-target="account-type-field"
      />
    );
  }

  renderCompanyAddressFields() {
    return (
      <div>
        <Field
          name="companyCountry"
          onChange={this.handleCompanyCountrySelect}
          label={gettext('Country')}
          placeholder={`-- ${gettext("please select your company's country")} --`}
          component={Select}
          options={createSelectOptions(CountryToAlpha2)}
          validate={[required]}
          requiredIndicator
          data-e2e-target="company-country-field"
        />
        <Field
          name="companyName"
          component={Input}
          label={gettext('Company Name')}
          validate={[required, ...this.validators.companyName]}
          disabled={this.props.disableCompanyName}
          requiredIndicator
          data-e2e-target="company-name-field"
        />
        <Field
          name="companyAddressLine1"
          component={Input}
          label={gettext('Address Line 1')}
          validate={[required, ...this.validators.addressLine1]}
          ref={(node) => {
            this.companyLine1Ref = node;
          }}
          forwardRef
          requiredIndicator
          data-e2e-target="company-address-line-1-field"
        />
        <Field
          name="companyAddressLine2"
          component={Input}
          label={gettext('Address Line 2 (Optional)')}
          validate={[...this.validators.addressLine2]}
          data-e2e-target="company-address-line-2-field"
        />
        <div className="grid grid--tight">
          <div className="grid-col grid-col--tablet-6">
            <Field
              name="companyCity"
              component={Input}
              label={gettext('City')}
              validate={[required, ...this.validators.city]}
              requiredIndicator
              data-e2e-target="company-city-field"
            />
          </div>
          {this.renderCompanyStateField()}
        </div>
        <Field
          name="companyPostCode"
          component={Input}
          label={gettext('Zip Code / Post Code')}
          validate={[...this.validators.zipcode]}
          data-e2e-target="company-post-code-field"
        />
      </div>
    );
  }

  render() {
    const showVat =
      !this.props.disableVatDetails &&
      Alpha2VatCountries.includes(this.props.country || this.props.companyCountry);
    const isCompany = this.props.accountType === VerificationConstants.ACCOUNT_TYPE_COMPANY;

    return (
      <div>
        <header className="verifyPage-form-header">
          <div className="verifyPage-form-group">
            <fieldset>
              {this.renderCountryField()}
              {this.renderPersonalInformationFields()}
              {this.renderBillingAddressTitle()}
              {this.renderCustomerAddressFields()}
              {showVat && this.renderVatField()}
            </fieldset>
          </div>
        </header>
        <header className="verifyPage-form-header">
          <div className="verifyPage-form-group">
            {isCompany && this.renderTitle(gettext('Company Information'))}
            <fieldset>{isCompany && this.renderCompanyAddressFields()}</fieldset>
          </div>
        </header>
      </div>
    );
  }
}

ContactDetailsFieldset.propTypes = {
  formName: PropTypes.string.isRequired,
  accountType: PropTypes.oneOf([
    VerificationConstants.ACCOUNT_TYPE_INDIVIDUAL,
    VerificationConstants.ACCOUNT_TYPE_COMPANY,
  ]),
  country: PropTypes.string,
  companyCountry: PropTypes.string,
  billingAddressTitle: PropTypes.string,
  disableVatDetails: PropTypes.bool,
  disableNameAndDob: PropTypes.bool,
  disableCompanyName: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  type2faStatuses: twoFactorSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getTwoFactorStatus: () => dispatch(getTwoFactorStatus.trigger()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetailsFieldset);
