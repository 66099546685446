import React, { useState, useMemo, useEffect, useContext } from 'react';
import { Form } from 'react-final-form';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  required,
  age18,
  date,
  noStartTrailWhitespace,
  isValidName,
  composeValidators,
} from 'spa/components/form/validate';
import {
  kycGotoPrevPage as kycGotoPrevPageRoutine,
  kycSubmitCustomerDetails as kycSubmitCustomerDetailsRoutine,
} from 'spa/actions/VerificationActions';
import VerificationConstants from 'spa/constants/VerificationConstants';
import {
  Alpha3ToAlpha2,
  LocalNameCountries,
  Alpha2VatCountries,
} from 'spa/constants/ISOCountryCodes';
import {
  customerIsCompanySelector,
  customerKycSubmitLoadingSelector,
  customerKyc1VerifiedSelector,
  buyerCheckoutDetailsSelector,
  customerKyc2ApprovedSelector,
  customerKycHasPrevPagesSelector,
} from 'spa/selectors/CustomerSelectors';
import { draftBuyerDetailsSelector } from 'spa/selectors/TransactionSelectors';
import ActionButtons from 'spa/components/CustomerVerification/V2/ActionButtons';
import { KYCContext } from 'spa/context/KYCContext';
import {
  InputField,
  DatePickerField,
  PhoneNumberInputField,
  AddressFieldSet,
  VATFieldSet,
  OccupationField,
  LocalNameFields,
  AccountTypeSwitcher,
} from './Fields';
import Heading from './Heading';

const IndividualDetailsForm = (props) => {
  const {
    loadPrevPage,
    submitIndividualDetails,
    isCompany,
    isSubmitting,
    hasContactDetails,
    customerFieldValues,
    hasSubmittedVat,
    hasPrevPages,
    isPersonalInformationVerified,
  } = props;
  const [isEditting, setIsEditting] = useState(hasContactDetails);
  useEffect(() => {
    if (!isSubmitting) {
      setIsEditting(hasContactDetails);
    }
  }, [hasContactDetails, isSubmitting]);
  const kycContext = useContext(KYCContext);
  const { forceCountry } = kycContext;

  const defaultCountry =
    forceCountry ||
    (Alpha3ToAlpha2[window.config.geoip_country] &&
      Alpha3ToAlpha2[window.config.geoip_country].toLowerCase()) ||
    'us';
  const [showAutocompleteAddressError, setShowAutocompleteAddressError] = useState(false);

  const vatCountries = [...Alpha2VatCountries, 'EL'];
  const onFormSubmit = (values, form) => {
    submitIndividualDetails({
      values,
      form,
      formName: VerificationConstants.CUSTOMER_DETAILS_FORM,
      kycContext,
    });
  };
  const initalValues = useMemo(
    () => ({
      'account-type': isCompany
        ? VerificationConstants.ACCOUNT_TYPE_COMPANY
        : VerificationConstants.ACCOUNT_TYPE_INDIVIDUAL,
      ...customerFieldValues,
    }),
    [customerFieldValues, isCompany]
  );
  return (
    <Form
      onSubmit={onFormSubmit}
      subscription={{
        values: true,
        hasValidationErrors: true,
      }}
      initialValues={initalValues}
      mutators={{
        clearAutocompleteAddressError: ([prefix], state) => {
          state.fields[`${prefix}address`].data.error = undefined;
          state.fields[`${prefix}city`].data.error = undefined;
          state.fields[`${prefix}state`].data.error = undefined;
        },
      }}
    >
      {({ handleSubmit, hasValidationErrors, values, form }) => (
        <form onSubmit={handleSubmit} name={VerificationConstants.CUSTOMER_DETAILS_FORM}>
          {!isEditting && (
            <AccountTypeSwitcher noModal isCompany={isCompany} disabled={isSubmitting} />
          )}
          <Heading text="Details" hasDivider />
          <div className="kyc-inline-fields-container">
            <div className={'kyc-inline-field--narrow'}>
              <InputField
                name="first-name"
                label="First name"
                validate={composeValidators([required, noStartTrailWhitespace, isValidName])}
                disabled={isSubmitting || isPersonalInformationVerified}
              />
            </div>
            <div className={'kyc-inline-field--narrow'}>
              <InputField
                name="middle-name"
                label="Middle name (Optional)"
                validate={composeValidators([noStartTrailWhitespace, isValidName])}
                disabled={isSubmitting || isPersonalInformationVerified}
              />
            </div>
            <div className={'kyc-inline-field--narrow'}>
              <InputField
                name="last-name"
                label="Last name"
                validate={composeValidators([required, noStartTrailWhitespace, isValidName])}
                disabled={isSubmitting || isPersonalInformationVerified}
              />
            </div>
          </div>
          <div className="kyc-inline-fields-container">
            <div className="kyc-inline-field--half">
              <DatePickerField
                name="date-of-birth"
                label="Date of birth"
                maxDate={moment()}
                validate={composeValidators([required, age18, date])}
                disabled={isSubmitting || isPersonalInformationVerified}
                ignoreTimezone
              />
            </div>
            <div className="kyc-inline-field--half">
              <div className="kyc-phoneNumber">
                <PhoneNumberInputField
                  name="complete-primary-phone-number"
                  placeholder="Phone number"
                  country={(
                    values.country ||
                    Alpha3ToAlpha2[customerFieldValues['primary-phone-country']] ||
                    defaultCountry
                  ).toLowerCase()}
                  disabled={isSubmitting}
                />
              </div>
            </div>
          </div>
          <AddressFieldSet
            type="individual"
            defaultCountry={defaultCountry}
            lockCountry={!!forceCountry}
            showError={showAutocompleteAddressError}
            setShowError={setShowAutocompleteAddressError}
            disabled={isSubmitting}
          />
          {vatCountries.includes(values.country) && !hasSubmittedVat && (
            <VATFieldSet initialCountry={values.country} options={vatCountries} />
          )}
          {values.country === 'CA' && <OccupationField />}
          {Object.keys(LocalNameCountries).includes(values.country) && (
            <LocalNameFields
              language={LocalNameCountries[values.country]}
              disabled={isSubmitting}
            />
          )}
          <ActionButtons
            secondaryText="Back"
            primaryText="Save and next"
            isLoading={isSubmitting}
            isDisabled={hasValidationErrors}
            type="submit"
            withBack={hasPrevPages}
            onSecondaryClick={() => loadPrevPage()}
            onPrimaryClick={() => {
              setShowAutocompleteAddressError(true);
            }}
          />
        </form>
      )}
    </Form>
  );
};

const mapStateToProps = (state) => {
  const hasContactDetails = customerKyc1VerifiedSelector(state);
  const buyerDetails =
    buyerCheckoutDetailsSelector(state) || draftBuyerDetailsSelector(state) || {};
  return {
    customerFieldValues: buyerDetails,
    hasSubmittedVat: !!buyerDetails['vat-number'],
    hasContactDetails,
    isCompany: customerIsCompanySelector(state),
    isSubmitting: customerKycSubmitLoadingSelector(state),
    isPersonalInformationVerified: customerKyc2ApprovedSelector(state),
    hasPrevPages: customerKycHasPrevPagesSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadPrevPage: () => dispatch(kycGotoPrevPageRoutine.trigger()),
  submitIndividualDetails: (payload) => dispatch(kycSubmitCustomerDetailsRoutine.trigger(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndividualDetailsForm);
