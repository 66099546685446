import { gettext } from '../utils/filters';

export default {
  SUBMISSION_START: 'SUBMISSION_START',
  SUBMISSION_SUCCESS: 'SUBMISSION_SUCCESS',
  SUBMISSION_FAILURE: 'SUBMISSION_FAILURE',
  FIELD_CHANGED: 'FIELD_CHANGED',
  FIELDS_CHANGED: 'FIELDS_CHANGED',
  FIELD_VALID: 'FIELD_VALID',
  FIELDS_VALID: 'FIELDS_VALID',
  FIELD_INVALID: 'FIELD_INVALID',
  FIELDS_INVALID: 'FIELDS_INVALID',
  CLEAR_FIELD_VALIDATION: 'CLEAR_FIELD_VALIDATION',
  CLEAR_FIELD_VALIDATIONS: 'CLEAR_FIELD_VALIDATIONS',
  RESET_FIELDS: 'RESET_FIELDS',
  SHOW_VALIDATION_STATE: 'SHOW_VALIDATION_STATE',
  SHOW_VALIDATION_STATES: 'SHOW_VALIDATION_STATES',
  HIDE_VALIDATION_STATES: 'HIDE_VALIDATION_STATES',
  SET_FIELD_VALIDATION_STATE: 'SET_FIELD_VALIDATION_STATE',
  SHOW_FORM_ERROR: 'SHOW_FORM_ERROR',
  CLEAR_FORM_ERROR: 'CLEAR_FORM_ERROR',
  FORM_STATE_CHANGED: 'FORM_STATE_CHANGED',
  FORM_STATES_CHANGED: 'FORM_STATES_CHANGED',
  FORM_SAVE: 'FORM_SAVE',
  FORM_RESET: 'FORM_RESET',
  STATE_LIST_CHANGED: 'STATE_LIST_CHANGED',
  SECURITY_QUESTIONS: [
    gettext('Please choose a question'),
    gettext('What was the name of your elementary / primary school?'),
    gettext('What was your favorite place to visit as a child?'),
    gettext('In what city or town does your nearest sibling live?'),
    gettext('What was the name of your first stuffed animal or doll or action figure?'),
    gettext('What were the last four digits of your childhood telephone number?'),
    gettext('What is the name of your favorite childhood teacher?'),
  ],
  SET_IMMUTABLE_FIELD: 'SET_IMMUTABLE_FIELD',
  SET_IMMUTABLE_FIELDS: 'SET_IMMUTABLE_FIELDS',
  CLEAR_IMMUTABLE_FIELDS: 'CLEAR_IMMUTABLE_FIELDS',
  MILESTONE_MINIMUM_AMOUNT: 50,
  EMAIL_HINT: 'EMAIL_HINT',
  PHONE_HINT: 'PHONE_HINT',
  BLOCKED_COUNTRIES: [
    'Belarus',
    'Cuba',
    'Iran, Islamic Republic of',
    'Iraq',
    'Liberia',
    'Libya',
    'Moldova, Republic of',
    'Myanmar',
    'Nigeria',
    "Korea, Democratic People's Republic of",
    'Sierra Leone',
    'Sudan',
    'Syrian Arab Republic',
    'Zimbabwe',
  ],
};
