import React from 'react';
import classnames from 'classnames';
import QueryString from 'query-string';

import Alert from 'spa/components/Alert';
import OfferDropdown from 'spa/components/OfferDropdown';
import { Spinner } from 'spa/components/Indicators';
import OfferConstants from 'spa/constants/OfferConstants';
import { ActionButton, FormError } from 'spa/components/form';
import { getCurrencyTagFromApiCurrency, getCurrencyString } from 'escrow-common-js/dist/utils';
import CreateOfferForm from './CreateOfferForm';
import { getPartiesByRole, getTransactionSummary } from '../../../utils/V4Transaction';
import { gettext } from '../../../utils/filters';

const MakeAnOfferPanel = ({
  auctionsById,
  auctionId,
  auctionToken,
  getAuctionUi,
  buyItNowUi,
  buyItNowAction,
  isAuthenticated,
  auctionRole,
  geoIpCountry,
}) => {
  if (getAuctionUi.loading) {
    return <Spinner />;
  }

  if (getAuctionUi.error && getAuctionUi.errorMessage) {
    return (
      <Alert type="error">
        <strong>{getAuctionUi.errorMessage}</strong>
      </Alert>
    );
  }

  if (!auctionsById || !auctionId || !auctionsById[auctionId]) {
    return <Spinner />;
  }

  const auctionBody = auctionsById[auctionId];
  const currency = getCurrencyTagFromApiCurrency(auctionBody.currency);
  const buyerEmail = getPartiesByRole(auctionBody, 'buyer')[0].customer;
  const sellerEmail = getPartiesByRole(auctionBody, 'seller')[0].customer;
  const brokerEmail = (getPartiesByRole(auctionBody, 'broker')[0] || {}).customer;

  const viewerRole = auctionRole;
  const viewerEmail = auctionRole === 'seller' ? sellerEmail : buyerEmail;
  const { email } = QueryString.parse(window.location.search);

  const transactionSummary = getTransactionSummary(auctionBody, viewerEmail, viewerRole, {
    payBy: 'wire_transfer',
  });

  const total = transactionSummary.totalAmountPaid;
  const buyItNowForLabel = gettext('Buy it now for');

  return (
    <div
      className="checkout-card-body checkout-card-body--light"
      data-tracking-section="make-an-offer-section"
    >
      <OfferDropdown title={'Make Offer'} token={auctionToken} viewerRole={'buyer'} />
      <div
        className={classnames([
          'checkout-card-content',
          'checkout-card-content--small',
          'checkout-card-content--light',
          'checkout-card-content--offerBody',
        ])}
      >
        <div className="checkout-group">
          <div className={classnames({ 'checkout-group-item': !auctionBody.include_buy_now })}>
            <CreateOfferForm
              form={OfferConstants.CREATE_OFFER_FORM}
              label={gettext('Your offer to seller')}
              role={auctionRole}
              currency={currency}
              isAuthenticated={isAuthenticated}
              brokerEmail={brokerEmail}
              sellerEmail={sellerEmail}
              initialValues={{
                phoneCountry: geoIpCountry || 'USA',
                email,
              }}
            />
          </div>
        </div>
      </div>
      {auctionBody.include_buy_now && (
        <div
          className={classnames([
            'checkout-switcher',
            'checkout-card-content',
            'checkout-card-content--small',
            'checkout-card-content--light',
          ])}
        >
          <div className="field-label">{gettext("Can't Wait?")}</div>
          {buyItNowUi.errorMessage && <FormError error={buyItNowUi.errorMessage} />}
          <ActionButton
            className="checkout-card-btn checkout-card-btn--auth btn btn--secondary btn--hollow"
            name="buyItNow"
            type="button"
            label={`${buyItNowForLabel} ${getCurrencyString(total, currency)}`}
            loading={buyItNowUi.loading}
            disabled={buyItNowUi.loading}
            action={buyItNowAction}
            actionArgs={[auctionId, auctionToken]}
            data-tracking-name="buy-it-now"
          />
        </div>
      )}
    </div>
  );
};

export default MakeAnOfferPanel;
