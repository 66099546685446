import React from 'react';
import { connect } from 'react-redux';

import { getAPIKeyRequest, deleteAPIKeyRequest } from 'spa/actions/APIIntegrationActions';
import {
  apiKeysTransformation as apiKeysSelector,
  loadingSelector,
  successSelector,
} from 'spa/selectors/APIIntegrationSelectors';

import { Section } from 'spa/components/Section';
import {
  DashboardCard,
  DashboardCardHeader,
  DashboardCardBody,
} from 'spa/components/DashboardCard';
import { Spinner } from 'spa/components/Indicators';

class DisplayAPIKeys extends React.Component {
  componentWillMount() {
    this.props.getAPIKey();
  }

  render() {
    const { apiKeys, create, display, deleteAPIKey } = this.props;

    return (
      <Section>
        <DashboardCard>
          <DashboardCardHeader title="Your API Keys" />
          <DashboardCardBody>
            {display.success && !display.loading ? (
              <div>
                <table className="dashboardTable">
                  <thead className="dashboardTable-header">
                    <tr className="dashboardTable-row">
                      <th className="dashboardTable-heading">Name</th>
                      <th className="dashboardTable-heading">Token</th>
                      <th className="dashboardTable-heading">Date Created</th>
                      <th className="dashboardTable-heading" />
                    </tr>
                  </thead>
                  <tbody>
                    {apiKeys.length ? (
                      apiKeys.map((apiKey) => (
                        <tr className="dashboardTable-row" key={apiKey.id}>
                          <td className="dashboardTable-cell">{apiKey.name}</td>
                          <td className="dashboardTable-cell">{apiKey.key}</td>
                          <td className="dashboardTable-cell">{apiKey.dateCreated}</td>
                          <td className="dashboardTable-cell dashboardTable-cell--action">
                            {apiKey.deleting ? (
                              <span className="dashboardTable-loader">
                                <Spinner />
                              </span>
                            ) : (
                              <a
                                onClick={deleteAPIKey(apiKey.id)}
                                role="button"
                                tabIndex={0}
                                className="dashboardTable-removeBtn"
                              >
                                Delete key
                              </a>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <em>You have no api keys configured</em>
                    )}
                  </tbody>
                </table>
                {create.loading && <Spinner />}
              </div>
            ) : (
              <Spinner />
            )}
          </DashboardCardBody>
        </DashboardCard>
      </Section>
    );
  }
}

const mapStateToProps = (state) => ({
  apiKeys: apiKeysSelector(state),
  create: {
    loading: loadingSelector(state, 'create'),
    success: successSelector(state, 'create'),
  },
  display: {
    loading: loadingSelector(state, 'display'),
    success: successSelector(state, 'display'),
  },
});

const mapDispatchToProps = (dispatch) => ({
  deleteAPIKey: (id) => () => dispatch(deleteAPIKeyRequest(id)),
  getAPIKey: () => dispatch(getAPIKeyRequest()),
});

const DisplayAPIKeysContainer = connect(mapStateToProps, mapDispatchToProps)(DisplayAPIKeys);

export default DisplayAPIKeysContainer;
