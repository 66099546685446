import React from 'react';
import { V3KYCDesktopPages as Pages } from 'spa/constants/VerificationConstants';
import DesktopPage from '../components/DesktopPage';

const PhoneNumberPage = () => (
  <DesktopPage
    title="Phone number verification"
    subtitle="Please verify your phone number via SMS code."
    ctaText="Send Code"
    nextPage={Pages.SMS_CODE}
  />
);

export default PhoneNumberPage;
