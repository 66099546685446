import window from 'window-shim';
import QueryString from 'query-string';
import { SubmissionError } from 'redux-form';
import cardValidator from 'card-validator';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import AdyenCheckout from '@adyen/adyen-web';

import PaymentConstants from 'spa/constants/PaymentConstants';
import TransactionConstants from 'spa/constants/TransactionConstants';
import {
  setFilters,
  checkTourStatus as checkTourStatusRoutine,
  setToured as setTouredRoutine,
  getTransactions as getTransactionsRoutine,
  getTransactionById as getTransactionByIdRoutine,
  getTransactionExtraDetails as getTransactionExtraDetailsRoutine,
  getPaymentMethods as getPaymentMethodsRoutine,
  submitCreditCardPayment as submitCreditCardPaymentRoutine,
  getCreditCardPageData as getCreditCardPageDataRoutine,
  submitPoliPayment as submitPoliPaymentRoutine,
  submitPlaidPayment as submitPlaidPaymentRoutine,
  getAdyenMethods as getAdyenMethodsRoutine,
  startTransaction as startTransactionRoutine,
  fetchTransactionFeesSummary as fetchTransactionFeesSummaryRoutine,
} from 'spa/actions/TransactionActions';
import {
  trackFormSubmissionByName,
  trackFormSubmissionFailByName,
} from 'spa/actions/TrackingActions';
import { setAdyenFormData, setAdyenAction, setAdyenData } from 'spa/actions/PaymentsActions';
import { getSavedCreditCards as getSavedCreditCardsRoutine } from 'spa/actions/UserActions';
import { logError } from 'spa/actions/ErrorLoggingActions';
import {
  transactionFiltersSelector,
  transactionByIdSelector,
  transactionTotalsSelector,
  transactionUISelector,
  transactionAdyenAdditionalActionSelector,
  transactionCCPaymentGatewaySelector,
} from 'spa/selectors/TransactionSelectors';
import { adyenFormDataSelector, adyenThreeDSResultSelector } from 'spa/selectors/PaymentSelectors';
import { paymentsPageOutstandingBalanceSelector } from 'spa/selectors/PaymentsPageSelectors';
import APIConstants from 'spa/constants/APIConstants';
import NavigationStore from '../../stores/NavigationStore';
import AuthenticationStore from '../../stores/AuthenticationStore';

import API from '../../api';
import config from '../../utils/config';
import Store from '../../store';
import ErrorMessages from '../../constants/ErrorMessages';
import { formatFormToV4, isEscrowPay } from '../components/StartTransaction/util';

export function* getTransactions(action) {
  yield put(getTransactionsRoutine.request());
  try {
    const { filters: filterUpdates, refresh, isFirstFetch } = action.payload || {};
    let filters = yield select(transactionFiltersSelector);
    if (filterUpdates) {
      filters = {
        ...filters,
        ...filterUpdates,
        ...(refresh && { nextCursor: null }),
      };
      if (!isFirstFetch) {
        yield put(setFilters(filters));
      }
    }
    let transactions = yield call(API.getTransactions, filters);
    if (
      transactions.transactions.length === 0 &&
      isFirstFetch &&
      filters.status === 'outstanding'
    ) {
      const allTabFilters = { ...filters, status: null };
      transactions = yield call(API.getTransactions, allTabFilters);
      yield put(setFilters(allTabFilters));
    } else if (isFirstFetch && transactions.transactions.length > 0) {
      yield put(setFilters(filters));
    }
    yield put(getTransactionsRoutine.success({ ...transactions, refresh }));
  } catch (error) {
    yield put(getTransactionsRoutine.failure(error));
  }
}

function* checkV2TourStatus() {
  yield put(checkTourStatusRoutine.request());
  try {
    const response = yield call(API.checkTransactionTour, AuthenticationStore.customerID);
    yield put(checkTourStatusRoutine.success(JSON.stringify(response) !== '{}'));
  } catch (error) {
    yield put(checkTourStatusRoutine.failure(error));
  }
}

function* finishV2Tour() {
  yield put(setTouredRoutine.request());
  try {
    yield call(API.finishTransactionTour, AuthenticationStore.customerID);
    yield put(setTouredRoutine.success());
  } catch (error) {
    yield put(setTouredRoutine.failure(error));
  }
}

export function* getTransactionExtraDetails(action) {
  const { transId } = action.payload;
  try {
    const extraDetails = yield call(API.getTransactionExtraDetails, transId);
    yield put(getTransactionExtraDetailsRoutine.success(extraDetails));
  } catch (error) {
    yield put(getTransactionExtraDetailsRoutine.failure(error));
  }
}

export function* getV4TransactionById(action) {
  const { transId } = action.payload;
  try {
    const transactionData = yield call(API.getTransactionById, transId);

    yield put(getTransactionByIdRoutine.success(transactionData));
  } catch (error) {
    const errorType = error.type;
    let errorMessage = ErrorMessages.TECHNICAL_DIFFICULTIES;
    if (error.type === APIConstants.BAD_REQUEST) {
      errorMessage = error.errors.error;
    } else if (error.type === APIConstants.FORBIDDEN) {
      errorMessage = error.error;
    } else if (error.type === APIConstants.UNPROCESSABLE_ENTITY) {
      errorMessage = `An error occurred in the format of the request.
        ${ErrorMessages.ASSISTANT_MESSAGE_FOLLOWED_BY_REQUEST_ID} ${error.requestId}`;
    } else if (error.type === APIConstants.INTERNAL_SERVER_ERROR) {
      errorMessage = error.errors.error;
    } else if (error.type === APIConstants.UNHANDLED) {
      errorMessage = error.errors.error;
    }

    yield put(getTransactionByIdRoutine.failure({ errorMessage, errorType }));
  }
}

export function* getV4PaymentMethods(action) {
  try {
    const transactionId = action.payload.transId;
    const paymentsData = yield call(API.getPaymentMethods, transactionId);
    yield put(getPaymentMethodsRoutine.success({ paymentsData, transactionId }));
    const paymentMethods = [
      ...paymentsData.available_payment_methods,
      ...(paymentsData.conditionally_available_payment_methods || []),
    ];
    if (
      paymentMethods.some(
        (method) =>
          method.type === PaymentConstants.PAYMENT_METHODS.CREDIT_CARD &&
          method.payment_gateway === PaymentConstants.CREDIT_CARD_GATEWAY.ADYEN
      )
    ) {
      yield put(getAdyenMethodsRoutine.trigger({ transId: action.payload.transId }));
    }
  } catch (error) {
    const errorType = error.type;
    let errorMessage = ErrorMessages.TECHNICAL_DIFFICULTIES;
    if (error.type === APIConstants.BAD_REQUEST) {
      errorMessage = error.errors.error;
    } else if (error.type === APIConstants.FORBIDDEN) {
      errorMessage = error.error;
    } else if (error.type === APIConstants.UNPROCESSABLE_ENTITY) {
      errorMessage = `An error occurred in the format of the request.
        ${ErrorMessages.ASSISTANT_MESSAGE_FOLLOWED_BY_REQUEST_ID} ${error.requestId}`;
    } else if (error.type === APIConstants.INTERNAL_SERVER_ERROR) {
      errorMessage = error.errors.error;
    } else if (error.type === APIConstants.UNHANDLED) {
      errorMessage = error.errors.error;
    }

    yield put(getPaymentMethodsRoutine.failure({ errorMessage, errorType }));
  }
}

export function* getCreditCardPageData(action) {
  const transactionId = action.payload.transId;
  try {
    yield call(getV4TransactionById, action);
    yield call(getV4PaymentMethods, action);

    const transactionData = yield select(transactionByIdSelector, transactionId);
    yield put(
      getSavedCreditCardsRoutine.trigger({
        customerId: AuthenticationStore.getCustomerID(),
        currency: transactionData.currency,
      })
    );
    const { parties } = transactionData;
    const hasAllAgreed = (parties || []).every((party) => party.agreed);
    if (!parties || !hasAllAgreed) {
      yield call(NavigationStore.redirectMyTransactions);
      throw new Error("Involved parties haven't agreed to transaction. Redirecting.");
    }

    const { getTransactionById, getPaymentMethods } = yield select(transactionUISelector);
    if (getTransactionById.error || getPaymentMethods.error || !transactionData) {
      throw new Error('Failed to load credit card page information.');
    }

    const totals = yield transactionTotalsSelector(transactionData);
    if (!totals.credit_card) {
      throw new Error('This transaction is ineligible to use credit cards.');
    }

    yield put(getCreditCardPageDataRoutine.success());
  } catch (error) {
    const { getTransactionById, getPaymentMethods } = yield select(transactionUISelector);
    if (getTransactionById.errorType === APIConstants.FORBIDDEN) {
      yield call(NavigationStore.redirectMyTransactions);
    } else if (getPaymentMethods.errorType === APIConstants.FORBIDDEN) {
      yield call(NavigationStore.transactionInformation, transactionId);
    } else {
      yield put(getCreditCardPageDataRoutine.failure(error.message));
    }
  }
}

function* submitCreditCardPaypal(transactionId, action, returnUrl) {
  const { values, isOutstandingPayment = false } = action.payload;
  if (values.cardId && values.cardId !== 'new') {
    yield call(API.chargeSavedCreditCard, transactionId, values.cardId, isOutstandingPayment);
  } else {
    const cardData = cardValidator.number(values.cardNumber);
    const cardTypeLookup = {
      visa: 'Visa',
      'master-card': 'Mastercard',
      'american-express': 'American Express',
    };
    yield call(
      API.chargeCreditCard,
      transactionId,
      {
        card_number: values.cardNumber.replace(/ /g, ''),
        cvv: values.securityCode.trim(),
        expiry_date: {
          month: values.expirationMonth.trim(),
          year: values.expirationYear.trim(),
        },
        name_on_card: values.nameOnCard,
        brand: cardTypeLookup[cardData.card.type],
        bill_to: values.billTo,
        saved_by_customer: true,
      },
      isOutstandingPayment
    );
  }

  const skipRedirect = action.payload.skipRedirect;
  if (!skipRedirect) {
    if (returnUrl) {
      window.location.assign(returnUrl);
    } else {
      window.location.assign(`${window.config.www_base_url}/transaction/${transactionId}`);
    }
  }
  yield put(submitCreditCardPaymentRoutine.success());
}

function* submitCreditCardAdyen(transactionId, action, returnUrl) {
  const { isOutstandingPayment = false } = action.payload;
  const adyenData = yield select(adyenFormDataSelector);
  const transaction = yield select(transactionByIdSelector, transactionId);
  const previousAction = transactionAdyenAdditionalActionSelector(transaction);
  const customerId = AuthenticationStore.getCustomerID();
  const threeDS = yield select(adyenThreeDSResultSelector);
  const outstandingBalance = yield select(paymentsPageOutstandingBalanceSelector);
  const { threeds_result: threeDSResult } = threeDS || {};
  const {
    available_payment_methods: availablePaymentMethods = [],
    conditionally_available_payment_methods: conditionalPaymentMethods = [],
  } = transaction.paymentMethods || {};
  const { total } = [...availablePaymentMethods, ...conditionalPaymentMethods].find(
    ({ type }) => type === 'credit_card'
  );
  const amount = isOutstandingPayment ? outstandingBalance : total;

  let _error;
  let response = {};
  if (!previousAction) {
    try {
      response = threeDSResult
        ? yield call(API.authorizeAdyenPayment, transactionId, threeDSResult, isOutstandingPayment)
        : yield call(
            API.submitAdyenPayment,
            transactionId,
            customerId,
            amount,
            adyenData,
            isOutstandingPayment,
            returnUrl
          );
    } catch (error) {
      if (error.type === APIConstants.BAD_REQUEST) {
        if (error.errors.error === 'Adyen payment already made') {
          _error =
            'An unexpected error occurred.  Please contact support@escrow.com.  ' +
            `To help us assist you, please quote reference id: ${error.requestId}`;
        } else {
          _error = `We currently do not support the card type you have chosen. Please try a Visa, Mastercard or AMEX card. Please see ${window.config.www_base_url}/learn-more/how-escrow-payments-work/credit-card for more information.`;
        }
      }
    }
  }
  const { resultCode, refusalReason, action: additionalAction } = response;
  if (additionalAction) {
    yield put(setAdyenAction({ action: additionalAction }));
    yield put(setAdyenData({ transactionId, adyenData: response }));
    return;
  }
  switch (resultCode) {
    case 'Authorised':
    case 'Pending':
    case 'Received':
      if (!isOutstandingPayment) {
        yield call(
          API.setPaymentMethod,
          transactionId,
          PaymentConstants.PAYMENT_METHODS.CREDIT_CARD
        );
      }
      yield put(submitCreditCardPaymentRoutine.success());
      return;
    case 'Error':
      _error = `The following error occured while processing your payment: ${refusalReason}`;
      break;
    case 'Refused':
      _error = 'Your payment was refused.  Please try again with a different payment method.';
      break;
    default:
      if (!_error) {
        _error = 'An unexpected error occurred.  Please contact support@escrow.com';
      }
  }
  yield put(submitCreditCardPaymentRoutine.failure(new SubmissionError({ _error })));
}

export function* submitCreditCardPayment(action) {
  const query = QueryString.parse(window.location.search) || {};
  const transactionId = query.trans_id || action.payload.transId;
  const returnUrl = query.return_url || action.payload.returnUrl;
  const paymentGateway = yield select(transactionCCPaymentGatewaySelector, transactionId);
  try {
    if (paymentGateway === PaymentConstants.CREDIT_CARD_GATEWAY.ADYEN) {
      yield* submitCreditCardAdyen(transactionId, action, returnUrl);
    } else if (paymentGateway === PaymentConstants.CREDIT_CARD_GATEWAY.PAYPAL) {
      yield* submitCreditCardPaypal(transactionId, action, returnUrl);
    }
  } catch (error) {
    yield put(logError(error));

    // construct error message for the underlying form
    let formError;
    if (
      error &&
      error.type === APIConstants.INTERNAL_SERVER_ERROR &&
      error.errors &&
      error.errors.error &&
      error.errors.error.startsWith(`Customer ${window.escrowUser.id} has too many failed charges`)
    ) {
      formError = ErrorMessages.TOO_MANY_CREDIT_CARD_ATTEMPTS;
    } else if (
      error.errors.error_code &&
      error.errors.error_code in ErrorMessages.CC_ERROR_CODE_MAPPING
    ) {
      formError = ErrorMessages.CC_ERROR_CODE_MAPPING[error.errors.error_code];
    } else {
      formError = error.errors.error || ErrorMessages.CREDIT_CARD_SUBMIT_FAILED;
    }

    const reduxFormError = {
      _error: formError,
    };

    if (error && error.requestId && !reduxFormError._error.includes(error.requestId)) {
      reduxFormError._error =
        `${reduxFormError._error}` +
        ` To help us assist you, please quote reference id: ${error.requestId}`;
    }
    yield put(submitCreditCardPaymentRoutine.failure(new SubmissionError(reduxFormError)));
  }
  yield put(submitCreditCardPaymentRoutine.fulfill());
}

export function* submitPlaidPaymentSaga(action) {
  const { transId, plaidAccountId, isOutstandingPayment = false } = action.payload;
  let plaidPaymentResponse = null;
  yield put(submitPlaidPaymentRoutine.request());
  try {
    plaidPaymentResponse = yield call(
      API.submitPlaidPayment,
      transId,
      plaidAccountId,
      isOutstandingPayment
    );
  } catch (error) {
    yield put(logError(error));

    // construct error message for the underlying form
    let formError = ErrorMessages.DIRECT_DEBIT_SUBMIT_FAILED;
    if (
      error &&
      error.type === APIConstants.INTERNAL_SERVER_ERROR &&
      error.errors &&
      error.errors.error &&
      error.errors.error.startsWith('INSUFFICIENT_BALANCE')
    ) {
      formError = ErrorMessages.DIRECT_DEBIT_INSUFFICIENT_BALANCE;
    }
    const reduxFormError = {
      _error: formError,
    };

    if (
      error &&
      error.requestId &&
      reduxFormError._error !== ErrorMessages.DIRECT_DEBIT_INSUFFICIENT_BALANCE &&
      !reduxFormError._error.includes(error.requestId)
    ) {
      reduxFormError._error =
        `${reduxFormError._error}` +
        ` To help us assist you, please quote reference id: ${error.requestId}`;
    }
    yield put(submitPlaidPaymentRoutine.failure(new SubmissionError(reduxFormError)));
    return;
  }

  yield put(submitPlaidPaymentRoutine.success(plaidPaymentResponse));
  yield put(submitPlaidPaymentRoutine.fulfill());
}

export function* submitPoliPaymentSaga(action) {
  const { transId, userFlow, isOutstandingPayment = false } = action.payload;
  let poliPaymentResponse = null;
  yield put(submitPoliPaymentRoutine.request());
  try {
    poliPaymentResponse = yield call(
      API.submitPoliPayment,
      transId,
      userFlow,
      isOutstandingPayment
    );
  } catch (error) {
    yield put(submitPoliPaymentRoutine.failure(error));
    return;
  }

  yield put(submitPoliPaymentRoutine.success(poliPaymentResponse));
  yield put(submitPoliPaymentRoutine.fulfill());
}

export function* getAdyenMethods(action) {
  try {
    const transactionId = action.payload.transId;
    const customerId = AuthenticationStore.getCustomerID();
    const adyenPaymentMethods = yield call(API.getAdyenMethods, transactionId, customerId);
    const checkout = new AdyenCheckout({
      locale: 'en_US',
      environment: config.adyen_env,
      clientKey: config.adyen_client_key,
      paymentMethodsResponse: adyenPaymentMethods,
      onChange: (state) => Store.dispatch(setAdyenFormData(state)),
      onAdditionalDetails: (state) => Store.dispatch(setAdyenFormData(state)),
    });
    yield put(
      getAdyenMethodsRoutine.success({
        transactionId,
        adyenData: { ...adyenPaymentMethods, checkout },
      })
    );
  } catch (error) {
    const errorType = error.type;
    let errorMessage = ErrorMessages.TECHNICAL_DIFFICULTIES;
    if (error.type === APIConstants.BAD_REQUEST) {
      errorMessage = error.errors.error;
    } else if (error.type === APIConstants.FORBIDDEN) {
      errorMessage = error.error;
    } else if (error.type === APIConstants.UNPROCESSABLE_ENTITY) {
      errorMessage = `An error occurred in the format of the request.
        ${ErrorMessages.ASSISTANT_MESSAGE_FOLLOWED_BY_REQUEST_ID} ${error.requestId}`;
    } else if (error.type === APIConstants.INTERNAL_SERVER_ERROR) {
      errorMessage = error.errors.error;
    } else if (error.type === APIConstants.UNHANDLED) {
      errorMessage = error.errors.error;
    }

    yield put(getAdyenMethodsRoutine.failure({ errorMessage, errorType }));
  }
}

export function* startTransaction(action) {
  try {
    const transactionDetails = action.payload;
    const v4Details = formatFormToV4(transactionDetails);
    yield put(startTransactionRoutine.request(transactionDetails));
    if (isEscrowPay(transactionDetails)) {
      const createTransactionResponse = yield call(API.createCheckoutTransaction, v4Details);
      yield put(
        startTransactionRoutine.success({ ...transactionDetails, ...createTransactionResponse })
      );
      yield put(startTransactionRoutine.fulfill());
    } else {
      const createTransactionResponse = yield call(API.createTransaction, v4Details);
      yield put(
        startTransactionRoutine.success({ ...transactionDetails, ...createTransactionResponse })
      );
      yield put(startTransactionRoutine.fulfill());
    }
    yield put(trackFormSubmissionByName.trigger({ form: TransactionConstants.V3_FORM_NAME }));
  } catch (error) {
    const errorType = error.type;
    let errorMessage = ErrorMessages.TECHNICAL_DIFFICULTIES;
    if (error.type === APIConstants.BAD_REQUEST) {
      errorMessage = error.errors.error;
    } else if (error.type === APIConstants.FORBIDDEN) {
      errorMessage = error.error;
    } else if (error.type === APIConstants.UNPROCESSABLE_ENTITY) {
      const { errors: { items: itemsError } = {} } = error;
      const scheduleErrors = Object.values((Object.values(itemsError)[0] || {}).schedule || {});
      const firstItemScheduleError = (scheduleErrors[0] || [])[0];
      if (firstItemScheduleError === ErrorMessages.SELLER_FEES_EXCEEDED_TOTAL) {
        errorMessage = ErrorMessages.SELLER_FEES_EXCEEDED_TOTAL;
      } else {
        errorMessage = `An error occurred in the format of the request.
        ${ErrorMessages.ASSISTANT_MESSAGE_FOLLOWED_BY_REQUEST_ID} ${error.requestId}`;
      }
    } else if (error.type === APIConstants.INTERNAL_SERVER_ERROR) {
      errorMessage = error.errors.error;
    } else if (error.type === APIConstants.UNHANDLED) {
      errorMessage = error.errors.error;
    }

    yield put(
      startTransactionRoutine.failure({ errorType, errorMessage, requestId: error.requestId })
    );
    yield put(
      trackFormSubmissionFailByName.trigger({
        form: TransactionConstants.V3_FORM_NAME,
        error: errorMessage,
      })
    );
  }
}

export function* fetchTransactionFeesSummary(action) {
  try {
    const transactionDetails = action.payload;
    const v4Details = formatFormToV4(transactionDetails, true);
    yield put(fetchTransactionFeesSummaryRoutine.request(transactionDetails));
    const response = yield call(API.getTransactionFeesSummary, v4Details);
    yield put(fetchTransactionFeesSummaryRoutine.success(response));
    yield put(fetchTransactionFeesSummaryRoutine.fulfill());
  } catch (error) {
    const errorType = error.type;
    let errorMessage = ErrorMessages.TECHNICAL_DIFFICULTIES;
    if (error.type === APIConstants.BAD_REQUEST) {
      errorMessage = error.errors.error;
    } else if (error.type === APIConstants.FORBIDDEN) {
      errorMessage = error.error;
    } else if (error.type === APIConstants.UNPROCESSABLE_ENTITY) {
      errorMessage = `An error occurred in the format of the request.
        ${ErrorMessages.ASSISTANT_MESSAGE_FOLLOWED_BY_REQUEST_ID} ${error.requestId}`;
    } else if (error.type === APIConstants.INTERNAL_SERVER_ERROR) {
      errorMessage = error.errors.error;
    } else if (error.type === APIConstants.UNHANDLED) {
      errorMessage = error.errors.error;
    }

    yield put(fetchTransactionFeesSummaryRoutine.failure({ errorType, errorMessage }));
  }
}

function* transactionWatcher() {
  yield takeLatest(checkTourStatusRoutine.TRIGGER, checkV2TourStatus);
  yield takeLatest(setTouredRoutine.TRIGGER, finishV2Tour);
  yield takeLatest(getTransactionsRoutine.TRIGGER, getTransactions);
  yield takeLatest(getTransactionByIdRoutine.TRIGGER, getV4TransactionById);
  yield takeLatest(getTransactionExtraDetailsRoutine.TRIGGER, getTransactionExtraDetails);
  yield takeLatest(getPaymentMethodsRoutine.TRIGGER, getV4PaymentMethods);
  yield takeLatest(submitCreditCardPaymentRoutine.TRIGGER, submitCreditCardPayment);
  yield takeLatest(getCreditCardPageDataRoutine.TRIGGER, getCreditCardPageData);
  yield takeLatest(submitPoliPaymentRoutine.TRIGGER, submitPoliPaymentSaga);
  yield takeLatest(submitPlaidPaymentRoutine.TRIGGER, submitPlaidPaymentSaga);
  yield takeLatest(getAdyenMethodsRoutine.TRIGGER, getAdyenMethods);
  yield takeLatest(startTransactionRoutine.TRIGGER, startTransaction);
  yield takeLatest(fetchTransactionFeesSummaryRoutine.TRIGGER, fetchTransactionFeesSummary);
}

export default [transactionWatcher];
