import React from 'react';

import { InternationalWireDisbursementDetailsContainer } from 'spa/containers/DisbursementDetails';

const InternationalWireDisbursementDetails = () => (
  <div>
    <InternationalWireDisbursementDetailsContainer />
  </div>
);

export default InternationalWireDisbursementDetails;
