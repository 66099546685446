import { WIRE_INSTRUCTIONS_DISPLAY_ORDER } from 'escrow-common-js/dist/constants';
import { gettext } from '../../utils/filters';

const PaymentConstants = {
  LINE_ITEM_TYPES: {
    domain_name: gettext('Domain Name'),
    domain_name_holding: gettext('Domain Name Holding'),
    general_merchandise: gettext('General Merchandise'),
    milestone: gettext('Milestone'),
    motor_vehicle: gettext('Motor Vehicle'),
    broker_fee: gettext('Broker Fee'),
    partner_fee: gettext('Partner Fee'),
    shipping_fee: gettext('Shipping Fee'),
  },
  CUSTOMER_FEE_TYPES: ['broker_fee', 'partner_fee', 'shipping_fee'],

  FEE_LINE_ITEM_TYPES: {
    credit_card: gettext('Payment Processing Fee'),
    concierge: gettext('Concierge Fee'),
    dibursement: gettext('Disbursement Fee'),
    escrow: gettext('Escrow Fee'),
    intermediary: gettext('Payment Processing Fee'),
    lien_holder_payoff: gettext('Lien Holder Payoff Fee'),
    title_collection: gettext('Title Collection Fee'),
    domain_name_holding_fee: gettext('Domain Name Holding Fee'),
    other: gettext('Other Fee/s'),
  },

  TRANSACTION_FEE_TYPES: [
    'credit_card',
    'concierge',
    'dibursement',
    'domain_name_holding_fee',
    'escrow',
    'intermediary',
    'lien_holder_payoff',
    'title_collection',
    'other',
  ],

  PAYMENT_METHODS: {
    CHECK: 'check',
    PAYPAL: 'paypal',
    CREDIT_CARD: 'credit_card',
    DIRECT_DEBIT: 'direct_debit',
    WIRE_TRANSFER: 'wire_transfer',
    POLI: 'poli',
  },

  CUSTOMER_DETAILS_DISPLAY: 'Payment-customer-details-display',
  PAYMENT_FORM: 'PaymentConstants.PAYMENT_FORM',

  ACTION_SET_REDUX_CUSTOMER_DETAILS: 'Payment_action_set_redux_customer_details',

  MAPPING_CUSTOMER_INFORMATION: 'Payment_mapping_customer_information',

  BUYER_PLACEHOLDER: 'Payment_user',

  SET_PAYMENT_TYPE: 'PaymentConstants.SET_PAYMENT_TYPE',
  GET_WIRE_DETAILS: 'PaymentConstants.GET_WIRE_DETAILS',
  INIT_PLAID: 'PaymentConstants.INIT_PLAID',
  LAUNCH_PLAID: 'PaymentConstants.LAUNCH_PLAID',

  GET_CHECK_DETAILS: 'PaymentConstants.GET_CHECK_DETAILS',
  GET_LAST_BUYER_BANK_ENTRY: 'PaymentConstants.GET_LAST_BUYER_BANK_ENTRY',

  SUBMIT_USER_WIRE_DETAILS: 'PaymentConstants.SUBMIT_USER_WIRE_DETAILS',

  SHOW_WIRE_FORM: 'PaymentConstants.SHOW_WIRE_FORM',

  SET_TRANSACTION_ID: 'PaymentConstants.SET_TRANSACTION_ID',
  SELECT_PAYMENT_METHOD: 'PaymentConstants.SELECT_PAYMENT_METHOD',
  FETCH_TRANSACTION_AND_PAYMENT_METHODS: 'PaymentConstants.FETCH_TRANSACTION_AND_PAYMENT_METHODS',

  GET_CARD_DETAILS: 'PaymentConstants.GET_CARD_DETAILS',
  GET_CHARGE_DETAILS: 'PaymentConstants.GET_CHARGE_DETAILS',
  TRIGGER_CHARGE: 'PaymentConstants.TRIGGER_CHARGE',
  TRIGGER_CHARGE_FORM: 'PaymentConstants.TRIGGER_CHARGE_FORM',
  CREATE_TRIGGER_CHARGE_FORM: 'PaymentConstants.CREATE_TRIGGER_CHARGE_FORM',
  VERIFY_CHARGE: 'PaymentConstants.VERIFY_CHARGE',
  VERIFY_CHARGE_FORM: 'PaymentConstants.VERIFY_CHARGE_FORM',
  CREATE_VERIFY_CHARGE_FORM: 'PaymentConstants.CREATE_VERIFY_CHARGE_FORM',
  SAVE_CREDIT_CARD_FORM: 'PaymentConstants.SAVE_CREDIT_CARD_FORM',
  SAVE_CREDIT_CARD: 'PaymentConstants.SAVE_CREDIT_CARD',

  PAYMENT_SHORTAGES_FORM: 'PaymentConstants.PAYMENT_SHORTAGES_FORM',
  GET_OUTSTANDING_BALANCE: 'PaymentConstants.GET_OUTSTANDING_BALANCE',
  RESET_STATE: 'PaymentConstants.RESET_STATE',
  PROCESS_SUCCESSFUL_PAYPAL_PAYMENT: 'PaymentConstants.PROCESS_SUCCESSFUL_PAYPAL_PAYMENT',

  ADYEN_THREEDS_PASSED: 'AuthenticationFinished',
  INIT_ADYEN_CHECKOUT: 'PaymentConstants.INIT_ADYEN_CHECKOUT',
  SET_ADYEN_ACTION: 'PaymentConstants.SET_ADYEN_ACTION',
  SET_ADYEN_DATA: 'PaymentConstants.SET_ADYEN_DATA',
  SET_ADYEN_FORM_DATA: 'PaymentConstants.SET_ADYEN_FORM_DATA',
  SET_ADYEN_PAYMENT_TOKEN: 'PaymentConstants.SET_ADYEN_PAYMENT_TOKEN',
  SET_ADYEN_THREEDS_RESULT: 'PaymentConstants.SET_ADYEN_THREEDS_RESULT',

  CREDIT_CARD_GATEWAY: {
    ADYEN: 'Adyen',
    PAYPAL: 'PayPal',
  },
};

PaymentConstants.apiFeeNameMapping = {
  broker_fee: gettext('Broker Fee'),
  shipping_fee: gettext('Shipping Fee'),
  partner_fee: gettext('Partner Fee'),
};

PaymentConstants.apiWireNameMapping = {};
WIRE_INSTRUCTIONS_DISPLAY_ORDER.forEach((wireDetail) => {
  PaymentConstants.apiWireNameMapping[wireDetail.key] = gettext(wireDetail.displayName);
});

PaymentConstants.apiCheckNameMapping = {
  displayName: 'Check Beneficiary Address',
  key: 'check_beneficiary_address',
};

PaymentConstants.WIRE_FIELD_ORDER = WIRE_INSTRUCTIONS_DISPLAY_ORDER.map(
  (wireDetail) => wireDetail.key
);

export default PaymentConstants;
