import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { bindRoutineToReduxForm } from 'redux-saga-routines';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'escrow-common-js/dist/components';
import { disableTwoFactor, sendSMSTwoFactor } from 'spa/actions/TwoFactorActions';
import { twoFactorSmsNotificationSelector } from 'spa/selectors/TwoFactorSelectors';
import { customerSelector } from 'spa/selectors/CustomerSelectors';
import { twoFAValidate } from 'spa/actions/AuthenticationActions';
import TwoFactorConstants from 'spa/constants/TwoFactorConstants';
import { InputOtp, FormErrorBox, FormMessageBox } from 'spa/components/form';
import { Spinner } from 'spa/components/Indicators';
import { gettext } from '../../../utils/filters';

class InputOtpForm extends React.Component {
  constructor(props) {
    super(props);
    this._renderForm = this._renderForm.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    const { actionType } = this.props;
    if (actionType === TwoFactorConstants.ACTION_DISABLE) {
      return disableTwoFactor;
    }
    return twoFAValidate;
  }

  _renderSmsNotification() {
    const { actionType, phoneNumber } = this.props;
    const message =
      actionType === TwoFactorConstants.ACTION_DISABLE
        ? `${gettext('A text message with the verification code has been sent to')} ${phoneNumber}.`
        : gettext('A text message with the verification code has been sent to your phone number.');
    return <FormMessageBox message={message} />;
  }

  _renderForm() {
    const {
      navigateMain,
      pristine,
      submitting,
      configType,
      actionType,
      loading,
      showNotification,
    } = this.props;
    const spinning = submitting || loading;
    return (
      <Fragment>
        <Field className="twoFactorAuth-page-input" name="code2fa" component={InputOtp} autoFocus />
        {configType === TwoFactorConstants.TYPE_SMS && (
          <div className="twoFactorAuth-page-subheader twoFactorAuth-page-subheader--auth">
            {gettext("It may take a minute to receive your code. Haven't received it? ")}
            <a
              className={classnames('twoFactorAuth-page-resendBtn', {
                'twoFactorAuth-page-resendBtn-disable': showNotification,
              })}
              role="button"
              tabIndex={0}
              onClick={this.props.sendSMSTwoFactor}
            >
              {gettext('Resend a new code')}
            </a>
          </div>
        )}
        <div className="twoFactorAuth-page-footer">
          {[TwoFactorConstants.ACTION_DISABLE, TwoFactorConstants.ACTION_VERIFY].includes(
            actionType
          ) && (
            <a
              className="twoFactorAuth-page-backBtn twoFactorAuth-page-backBtn--secondary"
              role="button"
              tabIndex={0}
              onClick={navigateMain}
            >
              {gettext('Back')}
            </a>
          )}
          <Button
            className="twoFactorAuth-page-btn-next twoFactorAuth-page-btn"
            type="submit"
            disabled={pristine || spinning}
          >
            {spinning ? <Spinner /> : gettext('Verify')}
          </Button>
        </div>
      </Fragment>
    );
  }

  render() {
    const { form, submitFailed, error, handleSubmit, showNotification } = this.props;

    return (
      <form name={form} onSubmit={handleSubmit(bindRoutineToReduxForm(this.onSubmit()))}>
        {submitFailed && error && <FormErrorBox error={error} />}
        {showNotification && this._renderSmsNotification()}
        {this._renderForm()}
      </form>
    );
  }
}

InputOtpForm.propTypes = {
  configType: PropTypes.string.isRequired,
  actionType: PropTypes.string.isRequired,
  navigateMain: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  sendSMSTwoFactor: () => dispatch(sendSMSTwoFactor.trigger()),
});

const mapStateToProps = (state) => ({
  phoneNumber: customerSelector(state).phoneNumber,
  showNotification: twoFactorSmsNotificationSelector(state),
});

const onSuccess = (result, dispatch, props) => {
  if (props.callback) {
    props.callback();
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: TwoFactorConstants.VERIFY_FORM_NAME,
    enableReinitialize: false,
    destroyOnUnmount: true,
    onSubmitSuccess: onSuccess,
  })(InputOtpForm)
);
