import React from 'react';
import OtpInput from 'react-otp-input';

const InputOtp = (props) => (
  <div className="twoFactorAuth-page-otp">
    <OtpInput
      numInputs={6}
      placeholder={'000000'}
      isInputNum
      shouldAutoFocus={props.autoFocus}
      inputStyle={'twoFactorAuth-page-otp-input'}
      {...props.input}
    />
  </div>
);

export default InputOtp;
