import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Code } from 'spa/components/Code';
import PrimaryLink from 'spa/components/PrimaryLink';
import {
  Section,
  SectionTitle,
  SectionDesc,
  SectionSubTitle,
  SectionList,
  SectionListItem,
} from 'spa/components/Section';
import AccentedText from 'spa/components/AccentedText';
import HintBanner from 'spa/components/HintBanner';
import A from 'spa/components/A';

import { urlFor, envDomainName } from '../../../routeConfig';

const CreateTransaction = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktopSmall-9">
        <h1 className="integrationsPortal-title">API Guide :: Creating a transaction</h1>
        <Section>
          <SectionTitle id="transaction-simple">Creating your first transaction</SectionTitle>
          <SectionDesc>
            Creating transactions with the Escrow API is straight forward. Consider a simple
            transaction between myself and a seller, where I am buying a domain name for $1000.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.${envDomainName}/2017-09-01/transaction" \\
    -X POST \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
    -d '
{
    "parties": [
        {
            "role": "buyer",
            "customer": "me"
        },
        {
            "role": "seller",
            "customer": "keanu.reaves@test.escrow.com"
        }
    ],
    "currency": "usd",
    "description": "The sale of johnwick.com",
    "items": [
        {
            "title": "johnwick.com",
            "description": "johnwick.com",
            "type": "domain_name",
            "inspection_period": 259200,
            "quantity": 1,
            "schedule": [
                {
                    "amount": 1000.0,
                    "payer_customer": "me",
                    "beneficiary_customer": "keanu.reaves@test.escrow.com"
                }
            ],
            "extra_attributes": {
                "image_url": "https://i.ebayimg.com/images/g/RicAAOSwzO5e3DZs/s-l1600.jpg",
                "merchant_url": "https://www.ebay.com"
            }
        }
    ]
}'
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.post(
    'https://api.${envDomainName}/2017-09-01/transaction',
    auth=('email address', 'api-key'),
    json={
        "parties": [
            {
                "role": "buyer",
                "customer": "me"
            },
            {
                "role": "seller",
                "customer": "keanu.reaves@test.escrow.com"
            }
        ],
        "currency": "usd",
        "description": "The sale of johnwick.com",
        "items": [
            {
                "title": "johnwick.com",
                "description": "johnwick.com",
                "type": "domain_name",
                "inspection_period": 259200,
                "quantity": 1,
                "schedule": [
                    {
                        "amount": 1000.0,
                        "payer_customer": "me",
                        "beneficiary_customer": "keanu.reaves@test.escrow.com"
                    }
                ],
                "extra_attributes": {
                    "image_url": "https://i.ebayimg.com/images/g/RicAAOSwzO5e3DZs/s-l1600.jpg",
                    "merchant_url": "https://www.ebay.com"
                }
            }
        ]
    },
)
`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.${envDomainName}/2017-09-01/transaction")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Post.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
  request.body = {
    parties: [
      {
        role: "buyer",
        customer: "me"
      },
      {
        role: "seller",
        customer: "keanu.reaves@test.escrow.com"
      }
    ],
    currency: "usd",
    description: "The sale of johnwick.com",
    items: [
      {
        title: "johnwick.com",
        description: "johnwick.com",
        type: "domain_name",
        inspection_period: 259200,
        quantity: 1,
        schedule: [
          {
            amount: 1000.0,
            payer_customer: "me",
            beneficiary_customer: "keanu.reaves@test.escrow.com"
          }
        ],
        extra_attributes: {
            "image_url": "https://i.ebayimg.com/images/g/RicAAOSwzO5e3DZs/s-l1600.jpg",
            "merchant_url": "https://www.ebay.com"
        }
      }
    ]
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.${envDomainName}/2017-09-01/transaction',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'currency' => 'usd',
            'items' => array(
                array(
                    'description' => 'johnwick.com',
                    'schedule' => array(
                        array(
                            'payer_customer' => 'me',
                            'amount' => '1000.0',
                            'beneficiary_customer' => 'keanu.reaves@test.escrow.com',
                        ),
                    ),
                    'title' => 'johnwick.com',
                    'inspection_period' => '259200',
                    'type' => 'domain_name',
                    'quantity' => '1',
                    'extra_attributes' => array(
                        'image_url' => 'https://i.ebayimg.com/images/g/RicAAOSwzO5e3DZs/s-l1600.jpg',
                        'merchant_url' => 'https://www.ebay.com'
                    ),
                ),
            ),
            'description' => 'The sale of johnwick.com',
            'parties' => array(
                array(
                    'customer' => 'me',
                    'role' => 'buyer',
                ),
                array(
                    'customer' => 'keanu.reaves@test.escrow.com',
                    'role' => 'seller',
                ),
            ),
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                currency = "usd",
                description = "The sale of johnwick.com",
                items = new[]
                {
                    new
                    {
                        title = "johnwick.com",
                        description = "johnwick.com",
                        inspection_period = "259200",
                        type = "domain_name",
                        quantity = "1",
                        schedule = new[]
                        {
                            new
                            {
                                payer_customer = "me",
                                amount = "1000.0",
                                beneficiary_customer = "keanu.reaves@test.escrow.com",
                            },
                        },
                        extra_attributes = new
                        {
                            image_url = "https://i.ebayimg.com/images/g/RicAAOSwzO5e3DZs/s-l1600.jpg",
                            merchant_url = "https://www.ebay.com"
                        },
                    },
                },
                parties = new[]
                {
                    new
                    {
                        customer = "me",
                        role = "buyer",
                    },
                    new
                    {
                        customer = "keanu.reaves@test.escrow.com",
                        role = "seller",
                    },
                },
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.${envDomainName}/2017-09-01/transaction"),
                Method = HttpMethod.Post,
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>

          <SectionSubTitle>Example Response</SectionSubTitle>
          <Code language="json">
            {`{
    "id": 3300003,
    "parties": [
        {
            "customer": "michael.nyqvist@test.escrow.com",
            "agreed": true,
            "role": "buyer"
        },
        {
            "customer": "keanu.reaves@test.escrow.com",
            "agreed": false,
            "role": "seller"
        }
    ],
    "currency": "usd",
    "description": "The sale of johnwick.com",
    "items": [
        {
            "status": {
                "received": false,
                "rejected_returned": false,
                "rejected": false,
                "received_returned": false,
                "shipped": false,
                "accepted": false,
                "shipped_returned": false,
                "accepted_returned": false
            },
            "extra_attributes": {
                "concierge": false,
                "image_url": "https://i.ebayimg.com/images/g/RicAAOSwzO5e3DZs/s-l1600.jpg",
                "merchant_url": "https://www.ebay.com",
                "with_content": false
            },
            "description": "johnwick.com",
            "schedule": [
                {
                    "payer_customer": "michael.nyqvist@test.escrow.com",
                    "status": {
                        "secured": false
                    },
                    "amount": "1000.00",
                    "beneficiary_customer": "keanu.reaves@test.escrow.com"
                }
            ],
            "title": "johnwick.com",
            "fees": [
                {
                    "payer_customer": "michael.nyqvist@test.escrow.com",
                    "amount": "32.50",
                    "type": "escrow"
                }
            ],
            "type": "domain_name",
            "id": 3870000,
            "inspection_period": 259200,
            "quantity": 1
        }
    ]
}`}
          </Code>
          <br />
          <SectionDesc>
            We represent a transaction with a Transaction object. Within the transaction object, we
            have different attributes.
          </SectionDesc>
          <SectionSubTitle>parties attribute</SectionSubTitle>
          <SectionDesc>
            During transaction creation we use the parties attribute to determine the role of each
            customer in a transaction. There are four different roles: buyer, seller, broker and
            partner. It is important to set the customers to their correct role as it cannot be
            changed once the transaction has been started. Certain actions are only able to be
            performed be certain roles, such as accepting items.
          </SectionDesc>
          <SectionDesc>
            The buyer in the transaction is the customer that is purchasing the items in the
            transaction. They are required to fund the transaction and accept the items after the
            inspection period.
          </SectionDesc>
          <SectionDesc>
            The seller in the transaction is the customer that is providing the items in the
            transaction. They are required to ship the items to the buyer, and have their funds
            disbursed after the buyer accepts the items.
          </SectionDesc>
          <SectionDesc>
            The broker in a transaction is the user that is setting up a transaction on behalf of
            other users. Brokers are able to set a broker commission, and able to set who pays this.
            If a broker is participating in a transaction, we also support the privacy attribute,
            which conceals information about the seller from the buyer and information about the
            buyer from the seller. This includes contact details and transaction amounts. This
            allows the broker to have a broker commission that is visible to one party, both parties
            or neither party.
          </SectionDesc>
          <SectionDesc>
            Partners are similar to Brokers in a transaction, however approved partners are also
            able to perform actions on behalf of customers on a transaction. When a transaction is
            created on the Escrow API, the partner will automatically be set to the customer that
            makes the API call. There is no need to specify the party object for the partner.
          </SectionDesc>
          <HintBanner
            className="integrationsPortal-hintBanner"
            title="Note"
            desc="Currently there is a minimum of one buyer and one seller, and a maximum of
            one buyer, one seller, one broker and one partner is supported in a transaction. However
            the API may support more in the future."
            icon="ui-alert-circle"
          />
          <HintBanner
            className="integrationsPortal-hintBanner"
            title="Note"
            desc="It is a requirement for the customer or partner creating the transaction to
            be a party in the transaction. The calling customer will automatically be added as the
            partner in a created transaction, however we will not automatically assign the buyer,
            seller or broker roles to any other party. For those roles, it needs to be set
            specifically."
            icon="ui-alert-circle"
          />
          <br />
          <SectionDesc>
            {` Besides the role attribute on the party object, there is also the customer attribute.
            This is the email address of the customer, or simply `}
            <AccentedText>me</AccentedText>. The special value <AccentedText>me</AccentedText>
            {' is a shortcut value to refer to the current user that is making the request.'}
          </SectionDesc>
          <SectionDesc>
            For more information on the party object, please refer to the reference documentation on
            the Party Object.
          </SectionDesc>
          <SectionSubTitle>currency attribute</SectionSubTitle>
          <SectionDesc>
            The currency attribute assigns which currency is being used for the transaction.
            Currently the only currencies that are supported are <AccentedText>usd</AccentedText>,{' '}
            <AccentedText>aud</AccentedText>, <AccentedText>euro</AccentedText>,{' '}
            <AccentedText>gbp</AccentedText>, and <AccentedText>cad</AccentedText>. However, we may
            add more currencies in the future.
          </SectionDesc>
          <SectionSubTitle>description attribute</SectionSubTitle>
          <SectionDesc>
            This is the description for the transaction. Here you can describe what the transaction
            is for.
          </SectionDesc>
          <SectionSubTitle>items attribute</SectionSubTitle>
          <SectionDesc>
            Every transaction must have at least one item on it. Items can both be transferable
            items and non transferable items.
          </SectionDesc>
          <SectionDesc>
            Transferable items refer to goods or services that the buyer is purchasing. These
            include:
          </SectionDesc>
          <SectionList>
            <SectionListItem>Domain names</SectionListItem>
            <SectionListItem>General Merchandise</SectionListItem>
            <SectionListItem>Motor Vehicles</SectionListItem>
            <SectionListItem>Services</SectionListItem>
          </SectionList>
          <SectionDesc>
            Non transferable items refer to items in the transaction that facilitate the transfer of
            transferable items to the buyer. Such items include:
          </SectionDesc>
          <SectionList>
            <SectionListItem>Shipping fees</SectionListItem>
            <SectionListItem>Broker fees</SectionListItem>
            <SectionListItem>Partner fees</SectionListItem>
          </SectionList>
          <SectionDesc>
            Each item must contain at least one schedule object. This is how we determine the price
            of an item and who is paying for it. Currently the only item types that can have
            multiple schedules are <AccentedText>milestone</AccentedText> and
            <AccentedText>domain_name_holding</AccentedText>.
          </SectionDesc>
          <SectionDesc>Valid values for the item type are:</SectionDesc>
          <SectionList>
            <SectionListItem>
              <AccentedText>domain_name</AccentedText>
            </SectionListItem>
            <SectionListItem>
              <AccentedText>domain_name_holding</AccentedText>
            </SectionListItem>
            <SectionListItem>
              <AccentedText>general_merchandise</AccentedText>
            </SectionListItem>
            <SectionListItem>
              <AccentedText>milestone</AccentedText>
            </SectionListItem>
            <SectionListItem>
              <AccentedText>motor_vehicle</AccentedText>
            </SectionListItem>
            <SectionListItem>
              <AccentedText>broker_fee</AccentedText>
            </SectionListItem>
            <SectionListItem>
              <AccentedText>partner_fee</AccentedText>
            </SectionListItem>
            <SectionListItem>
              <AccentedText>shipping_fee</AccentedText>
            </SectionListItem>
          </SectionList>
          <HintBanner
            className="integrationsPortal-hintBanner"
            title="Note"
            desc="At the moment, the API only supports one type of transferable item type per
            transaction."
            icon="ui-alert-circle"
          />
          <SectionDesc>
            Each item can have a category field which gives classification for the type of item
            being sold.
          </SectionDesc>
          <SectionDesc>The valid categories are based on the type of item as follows:</SectionDesc>

          <table className="integrationsPortal-section-table apiTable">
            <thead className="apiTable-header">
              <tr className="apiTable-row">
                <th className="apiTable-cell">item_type</th>
                <th className="apiTable-cell">category</th>
              </tr>
            </thead>
            <tbody>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>motor_vehicle</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>automobiles_cars_and_trucks</AccentedText>
                  <br />
                  <AccentedText>boats</AccentedText>
                  <br />
                  <AccentedText>motorcycles</AccentedText>
                  <br />
                  <AccentedText>airplanes</AccentedText>
                  <br />
                  <AccentedText>other_motor_vehicles</AccentedText>
                  <br />
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>general_merchandise</AccentedText>
                  <br />
                  <AccentedText>milestone</AccentedText>
                  <br />
                  <AccentedText>domain_name</AccentedText>(with content)
                </td>
                <td className="apiTable-cell">
                  <AccentedText>antiques</AccentedText>
                  <br />
                  <AccentedText>appliances</AccentedText>
                  <br />
                  <AccentedText>alcohol_and_wine</AccentedText>
                  <br />
                  <AccentedText>arts_and_crafts</AccentedText>
                  <br />
                  <AccentedText>automotive_or_industrial_parts</AccentedText>
                  <br />
                  <AccentedText>beauty_and_health</AccentedText>
                  <br />
                  <AccentedText>books_and_magazines</AccentedText>
                  <br />
                  <AccentedText>business_and_internet</AccentedText>
                  <br />
                  <AccentedText>clothing_and_accessories</AccentedText>
                  <br />
                  <AccentedText>collectibles</AccentedText>
                  <br />
                  <AccentedText>computer_hardware_and_software</AccentedText>
                  <br />
                  <AccentedText>cosmetics</AccentedText>
                  <br />
                  <AccentedText>home_and_garden</AccentedText>
                  <br />
                  <AccentedText>fx_and_currency</AccentedText>
                  <br />
                  <AccentedText>financial_instruments</AccentedText>
                  <br />
                  <AccentedText>fine_art</AccentedText>
                  <br />
                  <AccentedText>furniture</AccentedText>
                  <br />
                  <AccentedText>heavy_equipment_and_machinery</AccentedText>
                  <br />
                  <AccentedText>jewellery_and_watches</AccentedText>
                  <br />
                  <AccentedText>mobile_apps</AccentedText>
                  <br />
                  <AccentedText>movies_and_music</AccentedText>
                  <br />
                  <AccentedText>phamacuticals</AccentedText>
                  <br />
                  <AccentedText>automobiles_cars_and_trucks</AccentedText>
                  <br />
                  <AccentedText>social_media_accounts</AccentedText>
                  <br />
                  <AccentedText>sports_and_recreation</AccentedText>
                  <br />
                  <AccentedText>tickets_and_events</AccentedText>
                  <br />
                  <AccentedText>tools_and_hardware</AccentedText>
                  <br />
                  <AccentedText>toys_and_hobbies</AccentedText>
                  <br />
                  <AccentedText>video_games_and_consoles</AccentedText>
                  <br />
                  <AccentedText>other_merchandise</AccentedText>
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
          <HintBanner
            className="integrationsPortal-hintBanner"
            title="Note"
            desc="We highly recommend providing a category to your items, as doing
            so can result in faster processing of your transactions."
            icon="ui-alert-circle"
          />
          <SectionSubTitle>fees attribute</SectionSubTitle>
          <SectionDesc>
            The fees attribute refers to the Escrow.com transaction fees. Examples of these fees
            include:
          </SectionDesc>
          <SectionList>
            <SectionListItem>General escrow fee</SectionListItem>
            <SectionListItem>Paypal deposit fee</SectionListItem>
            <SectionListItem>Creditcard deposit fee</SectionListItem>
            <SectionListItem>Wire disbursement fee</SectionListItem>
          </SectionList>
          <SectionSubTitle>type attribute</SectionSubTitle>
          <SectionDesc>The valid types on the fee object are as follows:</SectionDesc>
          <SectionList>
            <SectionListItem>
              <AccentedText>cheque_disbursement</AccentedText>
            </SectionListItem>
            <SectionListItem>
              <AccentedText>escrow</AccentedText>
            </SectionListItem>
            <SectionListItem>
              <AccentedText>paypal_deposit</AccentedText>
            </SectionListItem>
            <SectionListItem>
              <AccentedText>creditcard_deposit</AccentedText>
            </SectionListItem>
            <SectionListItem>
              <AccentedText>wire_disbursement</AccentedText>
            </SectionListItem>
          </SectionList>
          <SectionSubTitle>split attribute</SectionSubTitle>
          <SectionDesc>
            This attribute is only applicable to the fee type <AccentedText>escrow</AccentedText>
            and is only valid on transaction creation. This field is used to allow customers to
            determine who should pay the escrow fee. Valid values are decimal 0.5 (50% of the total)
            and 1 (100% of the total).
          </SectionDesc>
          <SectionDesc>
            When creating a transaction it is not valid to pass an amount for the fee object. You
            must pass in the split attribute to the fee object instead. The split specified on each
            fee item in the list must add up to 1.0.
          </SectionDesc>
          <SectionDesc>
            If no fee schedule is passed on transaction creation, by default the buyer will be set
            to pay 100% of the <AccentedText>escrow</AccentedText> type fee.
          </SectionDesc>
          <SectionDesc>
            If a fee schedule is passed on transaction creation, the same fee schedule must be
            included on each item in the transaction.
          </SectionDesc>
          <SectionSubTitle>schedule attribute</SectionSubTitle>
          <SectionDesc>
            Each fee object must contain at least one schedule object. This determines who is paying
            for the fees. Currently the buyer must pay for the following fee types:
          </SectionDesc>
          <SectionList>
            <SectionListItem>
              <AccentedText>paypal_deposit</AccentedText>
            </SectionListItem>
            <SectionListItem>
              <AccentedText>creditcard_deposit</AccentedText>
            </SectionListItem>
          </SectionList>
          <SectionDesc>And the seller must pay for the following fee types:</SectionDesc>
          <SectionList>
            <SectionListItem>
              <AccentedText>cheque_disbursement</AccentedText>
            </SectionListItem>
            <SectionListItem>
              <AccentedText>wire_disbursement</AccentedText>
            </SectionListItem>
          </SectionList>
          <SectionDesc>
            For more information, have a look at the
            <A link={{ type: 'internal', route: '/api/docs/reference#Schedule' }}> Schedule </A>
            documentation.
          </SectionDesc>
          <SectionSubTitle>additional atributes</SectionSubTitle>
          <SectionDesc>
            The <AccentedText>additional attributes</AccentedText> field for each item contains both
            the <AccentedText>image_url</AccentedText> and
            <AccentedText>merchant_url</AccentedText> fields.
          </SectionDesc>
          <SectionDesc>
            The <AccentedText>image_url</AccentedText> field specifies an image URL to be displayed
            on the Escrow Pay portal UX and any Escrow.com Transaction emails.
          </SectionDesc>
          <SectionDesc>
            The <AccentedText>merchant_url</AccentedText> field specifies a URL to be shown on the
            partner dashboard transaction page view as a reference to the listing.
          </SectionDesc>
          <SectionSubTitle>Schedule objects</SectionSubTitle>
          <SectionDesc>
            The schedule object is used for representing who is paying for an item or fee.
          </SectionDesc>
          <SectionSubTitle>amount</SectionSubTitle>
          <SectionDesc>
            This field is used for determining the price of items or fees. This is a decimal value.
          </SectionDesc>
          <SectionSubTitle>due_date</SectionSubTitle>
          <SectionDesc>
            In transactions with multiple payment schedules, such as Domain Name Holding or
            transactions with items delivered at multiple times, you specify the
            <AccentedText>due_date</AccentedText>. This field represents when Escrow.com expects a
            certain payment to be made, or delivery of an item to be made.
          </SectionDesc>
          <SectionDesc>
            This field is required on schedules on domain name holding and milestone items. Setting
            this field on other item types is an invalid request and will result in an error.
          </SectionDesc>
          <SectionSubTitle>payer_customer</SectionSubTitle>
          <SectionDesc>
            This is the email address of the customer who will be paying for the item.
          </SectionDesc>
          <SectionSubTitle>beneficiary_customer</SectionSubTitle>
          <SectionDesc>
            This is the email address of the customer who will be receiving the funds. For
            transferable items this will always be the seller, and for broker fees it will be the
            broker. For escrow fees it will always be the value <AccentedText>escrow</AccentedText>.
          </SectionDesc>
        </Section>
        <Section>
          <SectionTitle id="domain-name-with-content">
            Creating a domain name transaction with content
          </SectionTitle>
          <SectionDesc>
            Beyond the basic transaction, we can create a transaction for a domain name with
            content. We do this simply by setting the <AccentedText>extra_attribute</AccentedText>
            field <AccentedText>with_content</AccentedText> to true. You are able to create the
            transaction for multiple domain names at a time, simply pass multiple item objects.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.${envDomainName}/2017-09-01/transaction" \\
    -X POST \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
    -d '
{
    "parties": [
        {
            "role": "buyer",
            "customer": "me"
        },
        {
            "role": "seller",
            "customer": "keanu.reaves@test.escrow.com"
        }
    ],
    "currency": "usd",
    "description": "The sale of johnwick.com",
    "items": [
        {
            "title": "johnwick.com",
            "description": "johnwick.com",
            "type": "domain_name",
            "inspection_period": 259200,
            "quantity": 1,
            "extra_attributes": {
                "with_content": true
            },
            "schedule": [
                {
                    "amount": 1000.0,
                    "payer_customer": "me",
                    "beneficiary_customer": "keanu.reaves@test.escrow.com"
                }
            ],
            "fees": [
                {
                    "payer_customer": "me",
                    "type": "escrow",
                    "split": 0.5
                },
                {
                    "payer_customer": "keanu.reaves@test.escrow.com",
                    "type": "escrow",
                    "split": 0.5
                }
            ]
        }
    ]
}'
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.post(
    'https://api.${envDomainName}/2017-09-01/transaction',
    auth=('email address', 'api-key'),
    json={
        "parties": [
            {
                "role": "buyer",
                "customer": "me"
            },
            {
                "role": "seller",
                "customer": "keanu.reaves@test.escrow.com"
            }
        ],
        "currency": "usd",
        "description": "The sale of johnwick.com",
        "items": [
            {
                "title": "johnwick.com",
                "description": "johnwick.com",
                "type": "domain_name",
                "inspection_period": 259200,
                "quantity": 1,
                "extra_attributes": {
                  "with_content": True,
                },
                "schedule": [
                    {
                        "amount": 1000.0,
                        "payer_customer": "me",
                        "beneficiary_customer": "keanu.reaves@test.escrow.com"
                    }
                ],
                "fees": [
                    {
                        "payer_customer": "me",
                        "type": "escrow",
                        "split": 0.5
                    },
                    {
                        "payer_customer": "keanu.reaves@test.escrow.com",
                        "type": "escrow",
                        "split": 0.5
                    }
                ]
            }
        ]
    },
)
`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.${envDomainName}/2017-09-01/transaction")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Post.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
  request.body = {
      "parties": [
          {
              "role": "buyer",
              "customer": "me"
          },
          {
              "role": "seller",
              "customer": "keanu.reaves@test.escrow.com"
          }
      ],
      "currency": "usd",
      "description": "The sale of johnwick.com",
      "items": [
          {
              "title": "johnwick.com",
              "description": "johnwick.com",
              "type": "domain_name",
              "inspection_period": 259200,
              "quantity": 1,
              "extra_attributes": {
                "with_content": true,
              },
              "schedule": [
                  {
                      "amount": 1000.0,
                      "payer_customer": "me",
                      "beneficiary_customer": "keanu.reaves@test.escrow.com"
                  }
              ],
              "fees": [
                  {
                      "payer_customer": "me",
                      "type": "escrow",
                      "split": 0.5
                  },
                  {
                      "payer_customer": "keanu.reaves@test.escrow.com",
                      "type": "escrow",
                      "split": 0.5
                  }
              ]
          }
      ]
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.${envDomainName}/2017-09-01/transaction',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'currency' => 'usd',
            'items' => array(
                array(
                    'fees' => array(
                        array(
                            'payer_customer' => 'me',
                            'type' => 'escrow',
                            'split' => '0.5',
                        ),
                        array(
                            'payer_customer' => 'keanu.reaves@test.escrow.com',
                            'type' => 'escrow',
                            'split' => '0.5',
                        ),
                    ),
                    'extra_attributes' => array(
                        'with_content' => true,
                    ),
                    'inspection_period' => '259200',
                    'description' => 'johnwick.com',
                    'title' => 'johnwick.com',
                    'schedule' => array(
                        array(
                            'payer_customer' => 'me',
                            'amount' => '1000.0',
                            'beneficiary_customer' => 'keanu.reaves@test.escrow.com',
                        ),
                    ),
                    'type' => 'domain_name',
                    'quantity' => '1',
                ),
            ),
            'description' => 'The sale of johnwick.com',
            'parties' => array(
                array(
                    'customer' => 'me',
                    'role' => 'buyer',
                ),
                array(
                    'customer' => 'keanu.reaves@test.escrow.com',
                    'role' => 'seller',
                ),
            ),
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                currency = "usd",
                description = "The sale of johnwick.com",
                items = new[]
                {
                    new
                    {
                        description = "johnwick.com",
                        title = "johnwick.com",
                        type = "domain_name",
                        quantity = "1",
                        inspection_period = "259200",
                        extra_attributes = new
                        {
                            with_content = true,
                        },
                        schedule = new[]
                        {
                            new
                            {
                                payer_customer = "me",
                                amount = "1000.0",
                                beneficiary_customer = "keanu.reaves@test.escrow.com",
                            },
                        },
                        fees = new[]
                        {
                            new
                            {
                                payer_customer = "me",
                                type = "escrow",
                                split = "0.5",
                            },
                            new
                            {
                                payer_customer = "keanu.reaves@test.escrow.com",
                                type = "escrow",
                                split = "0.5",
                            },
                        },
                    },
                },
                parties = new[]
                {
                    new
                    {
                        customer = "me",
                        role = "buyer",
                    },
                    new
                    {
                        customer = "keanu.reaves@test.escrow.com",
                        role = "seller",
                    },
                },
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.${envDomainName}/2017-09-01/transaction"),
                Method = HttpMethod.Post,
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
          <SectionSubTitle>Example Response</SectionSubTitle>
          <Code language="json">
            {`
{
  "id": 3300000,
  "currency": "usd",
  "description": "The sale of johnwick.com",
  "items": [
    {
      "description": "johnwick.com",
      "extra_attributes": {
        "concierge": false,
        "with_content": true
      },
      "fees": [
        {
          "amount": "16.25",
          "payer_customer": "michael.nyqvist@test.escrow.com",
          "type": "escrow"
        },
        {
          "amount": "16.25",
          "payer_customer": "keanu.reaves@test.escrow.com",
          "type": "escrow"
        }
      ],
      "id": 3877634,
      "inspection_period": 259200,
      "quantity": 1,
      "schedule": [
        {
          "amount": "1000.00",
          "beneficiary_customer": "keanu.reaves@test.escrow.com",
          "payer_customer": "michael.nyqvist@test.escrow.com",
          "status": {
            "secured": false
          }
        }
      ],
      "status": {
        "accepted": false,
        "accepted_returned": false,
        "received": false,
        "received_returned": false,
        "rejected": false,
        "rejected_returned": false,
        "shipped": false,
        "shipped_returned": false
      },
      "title": "johnwick.com",
      "type": "domain_name"
    }
  ],
  "parties": [
    {
      "agreed": true,
      "customer": "michael.nyqvist@test.escrow.com",
      "role": "buyer"
    },
    {
      "agreed": false,
      "customer": "keanu.reaves@test.escrow.com",
      "role": "seller"
    }
  ]
}`}
          </Code>
        </Section>
        <Section>
          <SectionTitle id="domain-name-without-content">
            Creating a domain name transaction without content
          </SectionTitle>
          <SectionDesc>
            Unlike creating a domain name transaction for a domain with content, you do not need to
            set the <AccentedText>extra_attribute</AccentedText>
            <AccentedText>with_content</AccentedText> to a value. It will automatically default to
            false.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.${envDomainName}/2017-09-01/transaction" \\
    -X POST \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
    -d '
{
    "parties": [
        {
            "role": "buyer",
            "customer": "me"
        },
        {
            "role": "seller",
            "customer": "keanu.reaves@test.escrow.com"
        }
    ],
    "currency": "usd",
    "description": "The sale of johnwick.com",
    "items": [
        {
            "title": "johnwick.com",
            "description": "johnwick.com",
            "type": "domain_name",
            "inspection_period": 259200,
            "quantity": 1,
            "extra_attributes": {
                "with_content": false
            },
            "schedule": [
                {
                    "amount": 1000.0,
                    "payer_customer": "me",
                    "beneficiary_customer": "keanu.reaves@test.escrow.com"
                }
            ]
        }
    ]
}'`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="python">
                {`import requests

requests.post(
    'https://api.${envDomainName}/2017-09-01/transaction',
    auth=('email address', 'api-key'),
    json={
        "parties": [
            {
                "role": "buyer",
                "customer": "me"
            },
            {
                "role": "seller",
                "customer": "keanu.reaves@test.escrow.com"
            }
        ],
        "currency": "usd",
        "description": "The sale of johnwick.com",
        "items": [
            {
                "title": "johnwick.com",
                "description": "johnwick.com",
                "type": "domain_name",
                "inspection_period": 259200,
                "quantity": 1,
                "schedule": [
                    {
                        "amount": 1000.0,
                        "payer_customer": "me",
                        "beneficiary_customer": "keanu.reaves@test.escrow.com"
                    }
                ]
            }
        ]
    },
)`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.${envDomainName}/2017-09-01/transaction")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Post.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
  request.body = {
      "parties": [
          {
              "role": "buyer",
              "customer": "me"
          },
          {
              "role": "seller",
              "customer": "keanu.reaves@test.escrow.com"
          }
      ],
      "currency": "usd",
      "description": "The sale of johnwick.com",
      "items": [
          {
              "title": "johnwick.com",
              "description": "johnwick.com",
              "type": "domain_name",
              "inspection_period": 259200,
              "quantity": 1,
              "schedule": [
                  {
                      "amount": 1000.0,
                      "payer_customer": "me",
                      "beneficiary_customer": "keanu.reaves@test.escrow.com"
                  }
              ]
          }
      ]
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.${envDomainName}/2017-09-01/transaction',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'currency' => 'usd',
            'items' => array(
                array(
                    'description' => 'johnwick.com',
                    'schedule' => array(
                        array(
                            'payer_customer' => 'me',
                            'amount' => '1000.0',
                            'beneficiary_customer' => 'keanu.reaves@test.escrow.com',
                        ),
                    ),
                    'title' => 'johnwick.com',
                    'inspection_period' => '259200',
                    'type' => 'domain_name',
                    'quantity' => '1',
                ),
            ),
            'description' => 'The sale of johnwick.com',
            'parties' => array(
                array(
                    'customer' => 'me',
                    'role' => 'buyer',
                ),
                array(
                    'customer' => 'keanu.reaves@test.escrow.com',
                    'role' => 'seller',
                ),
            ),
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                currency = "usd",
                description = "The sale of johnwick.com",
                items = new[]
                {
                    new
                    {
                        title = "johnwick.com",
                        description = "johnwick.com",
                        inspection_period = "259200",
                        type = "domain_name",
                        quantity = "1",
                        schedule = new[]
                        {
                            new
                            {
                                payer_customer = "me",
                                amount = "1000.0",
                                beneficiary_customer = "keanu.reaves@test.escrow.com",
                            },
                        },
                    },
                },
                parties = new[]
                {
                    new
                    {
                        customer = "me",
                        role = "buyer",
                    },
                    new
                    {
                        customer = "keanu.reaves@test.escrow.com",
                        role = "seller",
                    },
                },
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.${envDomainName}/2017-09-01/transaction"),
                Method = HttpMethod.Post,
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
          <SectionSubTitle>Example Response</SectionSubTitle>
          <Code language="json">
            {`{
    "id": 2320000,
    "currency": "usd",
    "description": "The sale of johnwick.com",
    "parties": [
        {
            "customer": "michael.nyqvist@test.escrow.com",
            "agreed": true,
            "role": "buyer"
        },
        {
            "customer": "keanu.reaves@test.escrow.com",
            "agreed": false,
            "role": "seller"
        }
    ],
    "items": [
        {
            "status": {
                "received": false,
                "rejected_returned": false,
                "rejected": false,
                "received_returned": false,
                "shipped": false,
                "accepted": false,
                "shipped_returned": false,
                "accepted_returned": false
            },
            "extra_attributes": {
                "concierge": false,
                "with_content": false
            },
            "description": "johnwick.com",
            "schedule": [
                {
                    "payer_customer": "michael.nyqvist@test.escrow.com",
                    "status": {
                        "secured": false
                    },
                    "amount": "1000.00",
                    "beneficiary_customer": "keanu.reaves@test.escrow.com"
                }
            ],
            "title": "johnwick.com",
            "inspection_period": 259200,
            "fees": [
                {
                    "payer_customer": "michael.nyqvist@test.escrow.com",
                    "amount": "32.50",
                    "type": "escrow"
                }
            ],
            "type": "domain_name",
            "id": 3800000,
            "quantity": 1
        }
    ]
}`}
          </Code>
        </Section>
        <Section>
          <SectionTitle id="domain-name-holding">
            Creating a domain name holding transaction
          </SectionTitle>
          <SectionDesc>
            Creating a domain name holding transaction is very similar to creating a normal domain
            name transaction. The only difference is that you specify multiple
            <AccentedText>schedule</AccentedText> objects, and set <AccentedText>type</AccentedText>
            to <AccentedText>domain_name_holding</AccentedText>
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.${envDomainName}/2017-09-01/transaction" \\
    -X POST \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
    -d '
{
    "parties": [
        {
            "role": "buyer",
            "customer": "me"
        },
        {
            "role": "seller",
            "customer": "keanu.reaves@test.escrow.com"
        }
    ],
    "currency": "usd",
    "description": "The sale of johnwick.com",
    "items": [
        {
            "title": "johnwick.com",
            "description": "johnwick.com",
            "type": "domain_name_holding",
            "inspection_period": 259200,
            "quantity": 1,
            "schedule": [
                {
                    "amount": 20000.0,
                    "payer_customer": "me",
                    "beneficiary_customer": "keanu.reaves@test.escrow.com",
                    "due_date": "2018-01-01T00:00:00Z"
                },
                {
                    "amount": 15000.0,
                    "payer_customer": "me",
                    "beneficiary_customer": "keanu.reaves@test.escrow.com",
                    "due_date": "2018-02-01T00:00:00Z"
                },
                {
                    "amount": 15000.0,
                    "payer_customer": "me",
                    "beneficiary_customer": "keanu.reaves@test.escrow.com",
                    "due_date": "2018-03-01T00:00:00Z"
                },
                {
                    "amount": 15000.0,
                    "payer_customer": "me",
                    "beneficiary_customer": "keanu.reaves@test.escrow.com",
                    "due_date": "2018-04-01T00:00:00Z"
                },
                {
                    "amount": 15000.0,
                    "payer_customer": "me",
                    "beneficiary_customer": "keanu.reaves@test.escrow.com",
                    "due_date": "2018-05-01T00:00:00Z"
                }
            ]
        }
    ]
}'`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="python">
                {`import requests

requests.post(
    'https://api.${envDomainName}/2017-09-01/transaction',
    auth=('email address', 'api-key'),
    json={
        "parties": [
            {
                "role": "buyer",
                "customer": "me"
            },
            {
                "role": "seller",
                "customer": "keanu.reaves@test.escrow.com"
            }
        ],
        "currency": "usd",
        "description": "The sale of johnwick.com",
        "items": [
            {
                "title": "johnwick.com",
                "description": "johnwick.com",
                "type": "domain_name_holding",
                "inspection_period": 259200,
                "quantity": 1,
                "schedule": [
                    {
                        "amount": 20000.0,
                        "payer_customer": "me",
                        "beneficiary_customer": "keanu.reaves@test.escrow.com",
                        "due_date": "2018-01-01T00:00:00Z"
                    },
                    {
                        "amount": 15000.0,
                        "payer_customer": "me",
                        "beneficiary_customer": "keanu.reaves@test.escrow.com",
                        "due_date": "2018-02-01T00:00:00Z"
                    },
                    {
                        "amount": 15000.0,
                        "payer_customer": "me",
                        "beneficiary_customer": "keanu.reaves@test.escrow.com",
                        "due_date": "2018-03-01T00:00:00Z"
                    },
                    {
                        "amount": 15000.0,
                        "payer_customer": "me",
                        "beneficiary_customer": "keanu.reaves@test.escrow.com",
                        "due_date": "2018-04-01T00:00:00Z"
                    },
                    {
                        "amount": 15000.0,
                        "payer_customer": "me",
                        "beneficiary_customer": "keanu.reaves@test.escrow.com",
                        "due_date": "2018-05-01T00:00:00Z"
                    }
                ]
            }
        ]
    },
)`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.${envDomainName}/2017-09-01/transaction")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Post.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
  request.body = {
      "parties": [
          {
              "role": "buyer",
              "customer": "me"
          },
          {
              "role": "seller",
              "customer": "keanu.reaves@test.escrow.com"
          }
      ],
      "currency": "usd",
      "description": "The sale of johnwick.com",
      "items": [
          {
              "title": "johnwick.com",
              "description": "johnwick.com",
              "type": "domain_name_holding",
              "inspection_period": 259200,
              "quantity": 1,
              "schedule": [
                  {
                      "amount": 20000.0,
                      "payer_customer": "me",
                      "beneficiary_customer": "keanu.reaves@test.escrow.com",
                      "due_date": "2018-01-01T00:00:00Z"
                  },
                  {
                      "amount": 15000.0,
                      "payer_customer": "me",
                      "beneficiary_customer": "keanu.reaves@test.escrow.com",
                      "due_date": "2018-02-01T00:00:00Z"
                  },
                  {
                      "amount": 15000.0,
                      "payer_customer": "me",
                      "beneficiary_customer": "keanu.reaves@test.escrow.com",
                      "due_date": "2018-03-01T00:00:00Z"
                  },
                  {
                      "amount": 15000.0,
                      "payer_customer": "me",
                      "beneficiary_customer": "keanu.reaves@test.escrow.com",
                      "due_date": "2018-04-01T00:00:00Z"
                  },
                  {
                      "amount": 15000.0,
                      "payer_customer": "me",
                      "beneficiary_customer": "keanu.reaves@test.escrow.com",
                      "due_date": "2018-05-01T00:00:00Z"
                  }
              ]
          }
      ]
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.${envDomainName}/2017-09-01/transaction',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'currency' => 'usd',
            'items' => array(
                array(
                    'description' => 'johnwick.com',
                    'schedule' => array(
                        array(
                            'payer_customer' => 'me',
                            'due_date' => '2018-01-01T00:00:00Z',
                            'amount' => '20000.0',
                            'beneficiary_customer' => 'keanu.reaves@test.escrow.com',
                        ),
                        array(
                            'payer_customer' => 'me',
                            'due_date' => '2018-02-01T00:00:00Z',
                            'amount' => '15000.0',
                            'beneficiary_customer' => 'keanu.reaves@test.escrow.com',
                        ),
                        array(
                            'payer_customer' => 'me',
                            'due_date' => '2018-03-01T00:00:00Z',
                            'amount' => '15000.0',
                            'beneficiary_customer' => 'keanu.reaves@test.escrow.com',
                        ),
                        array(
                            'payer_customer' => 'me',
                            'due_date' => '2018-04-01T00:00:00Z',
                            'amount' => '15000.0',
                            'beneficiary_customer' => 'keanu.reaves@test.escrow.com',
                        ),
                        array(
                            'payer_customer' => 'me',
                            'due_date' => '2018-05-01T00:00:00Z',
                            'amount' => '15000.0',
                            'beneficiary_customer' => 'keanu.reaves@test.escrow.com',
                        ),
                    ),
                    'title' => 'johnwick.com',
                    'inspection_period' => '259200',
                    'type' => 'domain_name_holding',
                    'quantity' => '1',
                ),
            ),
            'description' => 'The sale of johnwick.com',
            'parties' => array(
                array(
                    'customer' => 'me',
                    'role' => 'buyer',
                ),
                array(
                    'customer' => 'keanu.reaves@test.escrow.com',
                    'role' => 'seller',
                ),
            ),
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                currency = "usd",
                description = "The sale of johnwick.com",
                items = new[]
                {
                    new
                    {
                        title = "johnwick.com",
                        description = "johnwick.com",
                        inspection_period = "259200",
                        type = "domain_name_holding",
                        quantity = "1",
                        schedule = new[]
                        {
                            new
                            {
                                payer_customer = "me",
                                due_date = "2018-01-01T00:00:00Z",
                                amount = "20000.0",
                                beneficiary_customer = "keanu.reaves@test.escrow.com",
                            },
                            new
                            {
                                payer_customer = "me",
                                due_date = "2018-02-01T00:00:00Z",
                                amount = "15000.0",
                                beneficiary_customer = "keanu.reaves@test.escrow.com",
                            },
                            new
                            {
                                payer_customer = "me",
                                due_date = "2018-03-01T00:00:00Z",
                                amount = "15000.0",
                                beneficiary_customer = "keanu.reaves@test.escrow.com",
                            },
                            new
                            {
                                payer_customer = "me",
                                due_date = "2018-04-01T00:00:00Z",
                                amount = "15000.0",
                                beneficiary_customer = "keanu.reaves@test.escrow.com",
                            },
                            new
                            {
                                payer_customer = "me",
                                due_date = "2018-05-01T00:00:00Z",
                                amount = "15000.0",
                                beneficiary_customer = "keanu.reaves@test.escrow.com",
                            },
                        },
                    },
                },
                parties = new[]
                {
                    new
                    {
                        customer = "me",
                        role = "buyer",
                    },
                    new
                    {
                        customer = "keanu.reaves@test.escrow.com",
                        role = "seller",
                    },
                },
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.${envDomainName}/2017-09-01/transaction"),
                Method = HttpMethod.Post,
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
        </Section>
        <Section>
          <SectionTitle id="motor-vehicle">Creating a motor vehicle transaction</SectionTitle>
          <SectionDesc>
            Creating a motor vehicle transaction is a little different to creating a domain name
            transaction. For motor vehicles, we also collect the:
          </SectionDesc>
          <SectionList>
            <SectionListItem>VIN number</SectionListItem>
            <SectionListItem>Odometer reading</SectionListItem>
            <SectionListItem>Year of manufacture</SectionListItem>
            <SectionListItem>Make of car</SectionListItem>
            <SectionListItem>Model of car</SectionListItem>
          </SectionList>
          <SectionDesc>
            We also have two additional upgrades on motor vehicles, lien holder payoff and title
            collection. All of these additional attributes are set in the
            <AccentedText>extra_attributes</AccentedText> field.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.${envDomainName}/2017-09-01/transaction" \\
    -X POST \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
    -d '
{
    "parties": [
        {
            "role": "buyer",
            "customer": "me"
        },
        {
            "role": "seller",
            "customer": "keanu.reaves@test.escrow.com"
        }
    ],
    "currency": "usd",
    "description": "The sale of the 1969 Mustang used in the movie John Wick",
    "items": [
        {
        "description": "1969 Mustang Mach 1 Fastback",
            "type": "motor_vehicle",
            "inspection_period": 259200,
            "quantity": 1,
            "extra_attributes": {
              "vin": "19329304",
              "odometer": "10000",
              "year": 1969,
              "make": "Ford",
              "model": "Mustang Mach 1 Fastback",
              "title_collection": true,
              "lien_holder_payoff": false
            },
            "schedule": [
                {
                    "amount": 50000.0,
                    "payer_customer": "me",
                    "beneficiary_customer": "keanu.reaves@test.escrow.com"
                }
            ]
        }
    ]
}'`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="python">
                {`import requests

requests.post(
    'https://api.${envDomainName}/2017-09-01/transaction',
    auth=('email address', 'api-key'),
    json={
        "parties": [
            {
                "role": "buyer",
                "customer": "me"
            },
            {
                "role": "seller",
                "customer": "keanu.reaves@test.escrow.com"
            }
        ],
        "currency": "usd",
        "description": "The sale of the 1969 Mustang used in the movie John Wick",
        "items": [
            {
            "description": "1969 Mustang Mach 1 Fastback",
                "type": "motor_vehicle",
                "inspection_period": 259200,
                "quantity": 1,
                "extra_attributes": {
                  "vin": "19329304",
                  "odometer": "10000",
                  "year": 1969,
                  "make": "Ford",
                  "model": "Mustang Mach 1 Fastback",
                  "title_collection": False,
                  "lien_holder_payoff": False,
                },
                "schedule": [
                    {
                        "amount": 50000.0,
                        "payer_customer": "me",
                        "beneficiary_customer": "keanu.reaves@test.escrow.com"
                    }
                ]
            }
        ]
    },
)`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.${envDomainName}/2017-09-01/transaction")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Post.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
  request.body = {
      "parties": [
          {
              "role": "buyer",
              "customer": "me"
          },
          {
              "role": "seller",
              "customer": "keanu.reaves@test.escrow.com"
          }
      ],
      "currency": "usd",
      "description": "The sale of the 1969 Mustang used in the movie John Wick",
      "items": [
          {
          "description": "1969 Mustang Mach 1 Fastback",
              "type": "motor_vehicle",
              "inspection_period": 259200,
              "quantity": 1,
              "extra_attributes": {
                "vin": "19329304",
                "odometer": "10000",
                "year": 1969,
                "make": "Ford",
                "model": "Mustang Mach 1 Fastback",
                "title_collection": false,
                "lien_holder_payoff": false,
              },
              "schedule": [
                  {
                      "amount": 50000.0,
                      "payer_customer": "me",
                      "beneficiary_customer": "keanu.reaves@test.escrow.com"
                  }
              ]
          }
      ]
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.${envDomainName}/2017-09-01/transaction',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'currency' => 'usd',
            'items' => array(
                array(
                    'extra_attributes' => array(
                        'title_collection' => false,
                        'make' => 'Ford',
                        'vin' => '19329304',
                        'odometer' => '10000',
                        'year' => '1969',
                        'model' => 'Mustang Mach 1 Fastback',
                        'lien_holder_payoff' => false,
                    ),
                    'description' => '1969 Mustang Mach 1 Fastback',
                    'schedule' => array(
                        array(
                            'payer_customer' => 'me',
                            'amount' => '50000.0',
                            'beneficiary_customer' => 'keanu.reaves@test.escrow.com',
                        ),
                    ),
                    'inspection_period' => '259200',
                    'type' => 'motor_vehicle',
                    'quantity' => '1',
                ),
            ),
            'description' => 'The sale of the 1969 Mustang used in the movie John Wick',
            'parties' => array(
                array(
                    'customer' => 'me',
                    'role' => 'buyer',
                ),
                array(
                    'customer' => 'keanu.reaves@test.escrow.com',
                    'role' => 'seller',
                ),
            ),
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                currency = "usd",
                description = "The sale of the 1969 Mustang used in the movie John Wick",
                items = new[]
                {
                    new
                    {
                        description = "1969 Mustang Mach 1 Fastback",
                        inspection_period = "259200",
                        type = "motor_vehicle",
                        quantity = "1",
                        extra_attributes = new
                        {
                            title_collection = "False",
                            make = "Ford",
                            vin = "19329304",
                            odometer = "10000",
                            year = "1969",
                            model = "Mustang Mach 1 Fastback",
                            lien_holder_payoff = "False",
                        },
                        schedule = new[]
                        {
                            new
                            {
                                payer_customer = "me",
                                amount = "50000.0",
                                beneficiary_customer = "keanu.reaves@test.escrow.com",
                            },
                        },
                    },
                },
                parties = new[]
                {
                    new
                    {
                        customer = "me",
                        role = "buyer",
                    },
                    new
                    {
                        customer = "keanu.reaves@test.escrow.com",
                        role = "seller",
                    },
                },
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.${envDomainName}/2017-09-01/transaction"),
                Method = HttpMethod.Post,
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
        </Section>
        <Section>
          <SectionTitle id="general-merchandise">
            Creating a general merchandise transaction
          </SectionTitle>
          <SectionDesc>
            Creating a general merchandise is as simple as setting the item types to
            <AccentedText>general_merchandise</AccentedText>
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.${envDomainName}/2017-09-01/transaction" \\
    -X POST \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
    -d '
{
    "parties": [
        {
            "role": "buyer",
            "customer": "me"
        },
        {
            "role": "seller",
            "customer": "keanu.reaves@test.escrow.com"
        }
    ],
    "currency": "usd",
    "description": "2 Original signed copies of a movie poster for John Wick",
    "items": [
        {
            "title": "2 movies posters",
            "description": "John Wick movie poster",
            "type": "general_merchandise",
            "inspection_period": 259200,
            "quantity": 2,
            "schedule": [
                {
                    "amount": 10000.0,
                    "payer_customer": "me",
                    "beneficiary_customer": "keanu.reaves@test.escrow.com"
                }
            ]
        }
    ]
}'`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="python">
                {`import requests

requests.post(
    'https://api.${envDomainName}/2017-09-01/transaction',
    auth=('email address', 'api-key'),
    json={
        "parties": [
            {
                "role": "buyer",
                "customer": "me"
            },
            {
                "role": "seller",
                "customer": "keanu.reaves@test.escrow.com"
            }
        ],
        "currency": "usd",
        "description": "2 Original signed copies of a movie poster for John Wick",
        "items": [
            {
                "title": "2 movies posters",
                "description": "John Wick movie poster",
                "type": "general_merchandise",
                "inspection_period": 259200,
                "quantity": 2,
                "schedule": [
                    {
                        "amount": 10000.0,
                        "payer_customer": "me",
                        "beneficiary_customer": "keanu.reaves@test.escrow.com"
                    }
                ]
            }
        ]
    },
)`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.${envDomainName}/2017-09-01/transaction")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Post.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
  request.body = {
      "parties": [
          {
              "role": "buyer",
              "customer": "me"
          },
          {
              "role": "seller",
              "customer": "keanu.reaves@test.escrow.com"
          }
      ],
      "currency": "usd",
      "description": "2 Original signed copies of a movie poster for John Wick",
      "items": [
          {
              "title": "2 movies posters",
              "description": "John Wick movie poster",
              "type": "general_merchandise",
              "inspection_period": 259200,
              "quantity": 2,
              "schedule": [
                  {
                      "amount": 10000.0,
                      "payer_customer": "me",
                      "beneficiary_customer": "keanu.reaves@test.escrow.com"
                  }
              ]
          }
      ]
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.${envDomainName}/2017-09-01/transaction',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'currency' => 'usd',
            'items' => array(
                array(
                    'description' => 'John Wick movie poster',
                    'schedule' => array(
                        array(
                            'payer_customer' => 'me',
                            'amount' => '10000.0',
                            'beneficiary_customer' => 'keanu.reaves@test.escrow.com',
                        ),
                    ),
                    'title' => '2 movies posters',
                    'inspection_period' => '259200',
                    'type' => 'general_merchandise',
                    'quantity' => '2',
                ),
            ),
            'description' => '2 Original signed copies of a movie poster for John Wick',
            'parties' => array(
                array(
                    'customer' => 'me',
                    'role' => 'buyer',
                ),
                array(
                    'customer' => 'keanu.reaves@test.escrow.com',
                    'role' => 'seller',
                ),
            ),
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                currency = "usd",
                description = "2 Original signed copies of a movie poster for John Wick",
                items = new[]
                {
                    new
                    {
                        title = "2 movies posters",
                        description = "John Wick movie poster",
                        inspection_period = "259200",
                        type = "general_merchandise",
                        quantity = "2",
                        schedule = new[]
                        {
                            new
                            {
                                payer_customer = "me",
                                amount = "10000.0",
                                beneficiary_customer = "keanu.reaves@test.escrow.com",
                            },
                        },
                    },
                },
                parties = new[]
                {
                    new
                    {
                        customer = "me",
                        role = "buyer",
                    },
                    new
                    {
                        customer = "keanu.reaves@test.escrow.com",
                        role = "seller",
                    },
                },
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.${envDomainName}/2017-09-01/transaction"),
                Method = HttpMethod.Post,
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
        </Section>
        <Section>
          <SectionTitle id="milestone">Creating a milestone transaction</SectionTitle>
          <SectionDesc>
            In order to create a milestone transaction, you specify multiple items with the
            <AccentedText>type</AccentedText> set to <AccentedText>milestone</AccentedText>.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.${envDomainName}/2017-09-01/transaction" \\
    -X POST \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
    -d '
{
    "parties": [
        {
            "role": "buyer",
            "customer": "me"
        },
        {
            "role": "seller",
            "customer": "keanu.reaves@test.escrow.com"
        }
    ],
    "currency": "usd",
    "description": "John Wick 3",
    "items": [
        {
            "title": "Script",
            "description": "Reading the script",
            "type": "milestone",
            "inspection_period": 259200,
            "quantity": 1,
            "schedule": [
                {
                    "amount": 10000.0,
                    "payer_customer": "me",
                    "beneficiary_customer": "keanu.reaves@test.escrow.com"
                }
            ]
        },
        {
            "title": "Story boards",
            "description": "Doing the story boards",
            "type": "milestone",
            "inspection_period": 259200,
            "quantity": 1,
            "schedule": [
                {
                    "amount": 1000000.0,
                    "payer_customer": "me",
                    "beneficiary_customer": "keanu.reaves@test.escrow.com"
                }
            ]
        },
        {
            "title": "Acting 1",
            "description": "Acting for car crash scene 1",
            "type": "milestone",
            "inspection_period": 259200,
            "quantity": 1,
            "schedule": [
                {
                    "amount": 200000.0,
                    "payer_customer": "me",
                    "beneficiary_customer": "keanu.reaves@test.escrow.com"
                }
            ]
        },
        {
            "title": "Acting 2",
            "description": "Acting for helicopter jump scene 2",
            "type": "milestone",
            "inspection_period": 259200,
            "quantity": 1,
            "schedule": [
                {
                    "amount": 50000.0,
                    "payer_customer": "me",
                    "beneficiary_customer": "keanu.reaves@test.escrow.com"
                }
            ]
        }
    ]
}'`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="python">
                {`import requests

requests.post(
    'https://api.${envDomainName}/2017-09-01/transaction',
    auth=('email address', 'api-key'),
    json={
        "parties": [
            {
                "role": "buyer",
                "customer": "me"
            },
            {
                "role": "seller",
                "customer": "keanu.reaves@test.escrow.com"
            }
        ],
        "currency": "usd",
        "description": "John Wick 3",
        "items": [
            {
                "title": "Script",
                "description": "Reading the script",
                "type": "milestone",
                "inspection_period": 259200,
                "quantity": 1,
                "schedule": [
                    {
                        "amount": 10000.0,
                        "payer_customer": "me",
                        "beneficiary_customer": "keanu.reaves@test.escrow.com"
                    }
                ]
            },
            {
                "title": "Story boards",
                "description": "Doing the story boards",
                "type": "milestone",
                "inspection_period": 259200,
                "quantity": 1,
                "schedule": [
                    {
                        "amount": 1000000.0,
                        "payer_customer": "me",
                        "beneficiary_customer": "keanu.reaves@test.escrow.com"
                    }
                ]
            },
            {
                "title": "Acting 1",
                "description": "Acting for car crash scene 1",
                "type": "milestone",
                "inspection_period": 259200,
                "quantity": 1,
                "schedule": [
                    {
                        "amount": 200000.0,
                        "payer_customer": "me",
                        "beneficiary_customer": "keanu.reaves@test.escrow.com"
                    }
                ]
            },
            {
                "title": "Acting 2",
                "description": "Acting for helicopter jump scene 2",
                "type": "milestone",
                "inspection_period": 259200,
                "quantity": 1,
                "schedule": [
                    {
                        "amount": 50000.0,
                        "payer_customer": "me",
                        "beneficiary_customer": "keanu.reaves@test.escrow.com"
                    }
                ]
            },
        ]
    },
)`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.${envDomainName}/2017-09-01/transaction")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Post.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
  request.body = {
      "parties": [
          {
              "role": "buyer",
              "customer": "me"
          },
          {
              "role": "seller",
              "customer": "keanu.reaves@test.escrow.com"
          }
      ],
      "currency": "usd",
      "description": "John Wick 3",
      "items": [
          {
              "title": "Script",
              "description": "Reading the script",
              "type": "milestone",
              "inspection_period": 259200,
              "quantity": 1,
              "schedule": [
                  {
                      "amount": 10000.0,
                      "payer_customer": "me",
                      "beneficiary_customer": "keanu.reaves@test.escrow.com"
                  }
              ]
          },
          {
              "title": "Story boards",
              "description": "Doing the story boards",
              "type": "milestone",
              "inspection_period": 259200,
              "quantity": 1,
              "schedule": [
                  {
                      "amount": 1000000.0,
                      "payer_customer": "me",
                      "beneficiary_customer": "keanu.reaves@test.escrow.com"
                  }
              ]
          },
          {
              "title": "Acting 1",
              "description": "Acting for car crash scene 1",
              "type": "milestone",
              "inspection_period": 259200,
              "quantity": 1,
              "schedule": [
                  {
                      "amount": 200000.0,
                      "payer_customer": "me",
                      "beneficiary_customer": "keanu.reaves@test.escrow.com"
                  }
              ]
          },
          {
              "title": "Acting 2",
              "description": "Acting for helicopter jump scene 2",
              "type": "milestone",
              "inspection_period": 259200,
              "quantity": 1,
              "schedule": [
                  {
                      "amount": 50000.0,
                      "payer_customer": "me",
                      "beneficiary_customer": "keanu.reaves@test.escrow.com"
                  }
              ]
          },
      ]
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.${envDomainName}/2017-09-01/transaction',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'currency' => 'usd',
            'items' => array(
                array(
                    'description' => 'Reading the script',
                    'schedule' => array(
                        array(
                            'payer_customer' => 'me',
                            'amount' => '10000.0',
                            'beneficiary_customer' => 'keanu.reaves@test.escrow.com',
                        ),
                    ),
                    'title' => 'Script',
                    'inspection_period' => '259200',
                    'type' => 'milestone',
                    'quantity' => '1',
                ),
                array(
                    'description' => 'Doing the story boards',
                    'schedule' => array(
                        array(
                            'payer_customer' => 'me',
                            'amount' => '1000000.0',
                            'beneficiary_customer' => 'keanu.reaves@test.escrow.com',
                        ),
                    ),
                    'title' => 'Story boards',
                    'inspection_period' => '259200',
                    'type' => 'milestone',
                    'quantity' => '1',
                ),
                array(
                    'description' => 'Acting for car crash scene 1',
                    'schedule' => array(
                        array(
                            'payer_customer' => 'me',
                            'amount' => '200000.0',
                            'beneficiary_customer' => 'keanu.reaves@test.escrow.com',
                        ),
                    ),
                    'title' => 'Acting 1',
                    'inspection_period' => '259200',
                    'type' => 'milestone',
                    'quantity' => '1',
                ),
                array(
                    'description' => 'Acting for helicopter jump scene 2',
                    'schedule' => array(
                        array(
                            'payer_customer' => 'me',
                            'amount' => '50000.0',
                            'beneficiary_customer' => 'keanu.reaves@test.escrow.com',
                        ),
                    ),
                    'title' => 'Acting 2',
                    'inspection_period' => '259200',
                    'type' => 'milestone',
                    'quantity' => '1',
                ),
            ),
            'description' => 'John Wick 3',
            'parties' => array(
                array(
                    'customer' => 'me',
                    'role' => 'buyer',
                ),
                array(
                    'customer' => 'keanu.reaves@test.escrow.com',
                    'role' => 'seller',
                ),
            ),
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                currency = "usd",
                description = "John Wick 3",
                items = new[]
                {
                    new
                    {
                        title = "Script",
                        description = "Reading the script",
                        inspection_period = "259200",
                        type = "milestone",
                        quantity = "1",
                        schedule = new[]
                        {
                            new
                            {
                                payer_customer = "me",
                                amount = "10000.0",
                                beneficiary_customer = "keanu.reaves@test.escrow.com",
                            },
                        },
                    },
                    new
                    {
                        title = "Story boards",
                        description = "Doing the story boards",
                        inspection_period = "259200",
                        type = "milestone",
                        quantity = "1",
                        schedule = new[]
                        {
                            new
                            {
                                payer_customer = "me",
                                amount = "1000000.0",
                                beneficiary_customer = "keanu.reaves@test.escrow.com",
                            },
                        },
                    },
                    new
                    {
                        title = "Acting 1",
                        description = "Acting for car crash scene 1",
                        inspection_period = "259200",
                        type = "milestone",
                        quantity = "1",
                        schedule = new[]
                        {
                            new
                            {
                                payer_customer = "me",
                                amount = "200000.0",
                                beneficiary_customer = "keanu.reaves@test.escrow.com",
                            },
                        },
                    },
                    new
                    {
                        title = "Acting 2",
                        description = "Acting for helicopter jump scene 2",
                        inspection_period = "259200",
                        type = "milestone",
                        quantity = "1",
                        schedule = new[]
                        {
                            new
                            {
                                payer_customer = "me",
                                amount = "50000.0",
                                beneficiary_customer = "keanu.reaves@test.escrow.com",
                            },
                        },
                    },
                },
                parties = new[]
                {
                    new
                    {
                        customer = "me",
                        role = "buyer",
                    },
                    new
                    {
                        customer = "keanu.reaves@test.escrow.com",
                        role = "seller",
                    },
                },
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.${envDomainName}/2017-09-01/transaction"),
                Method = HttpMethod.Post,
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
        </Section>
        <Section>
          <SectionTitle>Skipping shipping for a transaction</SectionTitle>
          <SectionDesc>
            It is possible to create a transaction for items that do not involve shipping. This is
            done by setting the <AccentedText>shipping_type</AccentedText> value to{' '}
            <AccentedText>no_shipping</AccentedText>
            on individual items that don&apos;t require shipping.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.${envDomainName}/2017-09-01/transaction" \\
    -X POST \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
    -d '
{
    "parties": [
        {
            "role": "buyer",
            "customer": "me"
        },
        {
            "role": "seller",
            "customer": "keanu.reaves@test.escrow.com"
        }
    ],
    "currency": "usd",
    "description": "The sale of johnwick.com",
    "items": [
        {
            "title": "johnwick.com",
            "description": "johnwick.com",
            "shipping_type": "no_shipping"
            "type": "domain_name",
            "inspection_period": 259200,
            "quantity": 1,
            "schedule": [
                {
                    "amount": 1000.0,
                    "payer_customer": "me",
                    "beneficiary_customer": "keanu.reaves@test.escrow.com"
                }
            ]
        }
    ]
}'
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.post(
    'https://api.${envDomainName}/2017-09-01/transaction',
    auth=('email address', 'api-key'),
    json={
        "parties": [
            {
                "role": "buyer",
                "customer": "me"
            },
            {
                "role": "seller",
                "customer": "keanu.reaves@test.escrow.com"
            }
        ],
        "currency": "usd",
        "description": "The sale of johnwick.com",
        "items": [
            {
                "title": "johnwick.com",
                "description": "johnwick.com",
                "shipping_type": "no_shipping"
                "type": "domain_name",
                "inspection_period": 259200,
                "quantity": 1,
                "schedule": [
                    {
                        "amount": 1000.0,
                        "payer_customer": "me",
                        "beneficiary_customer": "keanu.reaves@test.escrow.com"
                    }
                ]
            }
        ]
    },
)
`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.${envDomainName}/2017-09-01/transaction")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Post.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
  request.body = {
    parties: [
      {
        role: "buyer",
        customer: "me"
      },
      {
        role: "seller",
        customer: "keanu.reaves@test.escrow.com"
      }
    ],
    currency: "usd",
    description: "The sale of johnwick.com",
    items: [
      {
        title: "johnwick.com",
        description: "johnwick.com",
        shipping_type: "no_shipping",
        type: "domain_name",
        inspection_period: 259200,
        quantity: 1,
        schedule: [
          {
            amount: 1000.0,
            payer_customer: "me",
            beneficiary_customer: "keanu.reaves@test.escrow.com"
          }
        ]
      }
    ]
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.${envDomainName}/2017-09-01/transaction',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'currency' => 'usd',
            'items' => array(
                array(
                    'description' => 'johnwick.com',
                    'shipping_type' => 'no_shipping',
                    'schedule' => array(
                        array(
                            'payer_customer' => 'me',
                            'amount' => '1000.0',
                            'beneficiary_customer' => 'keanu.reaves@test.escrow.com',
                        ),
                    ),
                    'title' => 'johnwick.com',
                    'inspection_period' => '259200',
                    'type' => 'domain_name',
                    'quantity' => '1',
                ),
            ),
            'description' => 'The sale of johnwick.com',
            'parties' => array(
                array(
                    'customer' => 'me',
                    'role' => 'buyer',
                ),
                array(
                    'customer' => 'keanu.reaves@test.escrow.com',
                    'role' => 'seller',
                ),
            ),
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                currency = "usd",
                description = "The sale of johnwick.com",
                items = new[]
                {
                    new
                    {
                        title = "johnwick.com",
                        description = "johnwick.com",
                        shipping_type = "no_shipping",
                        inspection_period = "259200",
                        type = "domain_name",
                        quantity = "1",
                        schedule = new[]
                        {
                            new
                            {
                                payer_customer = "me",
                                amount = "1000.0",
                                beneficiary_customer = "keanu.reaves@test.escrow.com",
                            },
                        },
                    },
                },
                parties = new[]
                {
                    new
                    {
                        customer = "me",
                        role = "buyer",
                    },
                    new
                    {
                        customer = "keanu.reaves@test.escrow.com",
                        role = "seller",
                    },
                },
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.${envDomainName}/2017-09-01/transaction"),
                Method = HttpMethod.Post,
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
        </Section>
        <Section>
          <SectionTitle id="broker">
            Creating a brokered transaction with broker commission.
          </SectionTitle>
          <SectionDesc>
            It is possible to create brokered transactions with a broker commission. This is done by
            adding one or more <AccentedText>broker_fee</AccentedText> items to the transaction. You
            can specify more than one broker fee item if you wish to charge a broker fees to both
            the buyer or seller. There is no requirement that the broker fees be the same for both
            the buyer and seller, allowing you to have one party pay more if you wish. There must be
            a broker in the transaction if you include broker fee items.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.${envDomainName}/2017-09-01/transaction" \\
    -X POST \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
    -d '
{
    "parties": [
        {
            "role": "broker",
            "customer": "me"
        },
        {
            "role": "buyer",
            "customer": "john.wick@test.escrow.com"
        },
        {
            "role": "seller",
            "customer": "keanu.reaves@test.escrow.com"
        }
    ],
    "currency": "usd",
    "description": "The sale of johnwick.com",
    "items": [
        {
            "title": "johnwick.com",
            "description": "johnwick.com",
            "type": "domain_name",
            "inspection_period": 259200,
            "quantity": 1,
            "extra_attributes": {
                "with_content": false
            },
            "schedule": [
                {
                    "amount": 1000.0,
                    "payer_customer": "john.wick@test.escrow.com",
                    "beneficiary_customer": "keanu.reaves@test.escrow.com"
                }
            ]
        },
        {
            "type": "broker_fee",
            "schedule": [
                {
                    "amount": 2500,
                    "payer_customer": "john.wick@test.escrow.com",
                    "beneficiary_customer": "me"
                }
            ]
        },
        {
            "type": "broker_fee",
            "schedule": [
                {
                    "amount": 200,
                    "payer_customer": "keanu.reaves@test.escrow.com",
                    "beneficiary_customer": "me"
                }
            ]
        }
    ]
}'`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="python">
                {`import requests

requests.post(
    'https://api.${envDomainName}/2017-09-01/transaction',
    auth=('email address', 'api-key'),
    json={
        "parties": [
            {
                "role": "broker",
                "customer": "me"
            },
            {
                "role": "buyer",
                "customer": "john.wick@test.escrow.com"
            },
            {
                "role": "seller",
                "customer": "keanu.reaves@test.escrow.com"
            }
        ],
        "currency": "usd",
        "description": "The sale of johnwick.com",
        "items": [
            {
                "title": "johnwick.com",
                "description": "johnwick.com",
                "type": "domain_name",
                "inspection_period": 259200,
                "quantity": 1,
                "schedule": [
                    {
                        "amount": 1000.0,
                        "payer_customer": "john.wick@test.escrow.com",
                        "beneficiary_customer": "keanu.reaves@test.escrow.com"
                    }
                ]
            },
            {
                "type": "broker_fee",
                "schedule": [
                    {
                        "amount": 2500,
                        "payer_customer": "john.wick@test.escrow.com",
                        "beneficiary_customer": "me"
                    }
                ]
            },
            {
                "type": "broker_fee",
                "schedule": [
                    {
                        "amount": 200,
                        "payer_customer": "keanu.reaves@test.escrow.com",
                        "beneficiary_customer": "me"
                    }
                ]
            }
        ]
    },
)`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.${envDomainName}/2017-09-01/transaction")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Post.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
  request.body = {
      "parties": [
          {
              "role": "broker",
              "customer": "me"
          },
          {
              "role": "buyer",
              "customer": "john.wick@test.escrow.com"
          },
          {
              "role": "seller",
              "customer": "keanu.reaves@test.escrow.com"
          }
      ],
      "currency": "usd",
      "description": "The sale of johnwick.com",
      "items": [
          {
              "title": "johnwick.com",
              "description": "johnwick.com",
              "type": "domain_name",
              "inspection_period": 259200,
              "quantity": 1,
              "schedule": [
                  {
                      "amount": 1000.0,
                      "payer_customer": "john.wick@test.escrow.com",
                      "beneficiary_customer": "keanu.reaves@test.escrow.com"
                  }
              ]
          },
          {
              "type": "broker_fee",
              "schedule": [
                  {
                      "amount": 2500,
                      "payer_customer": "john.wick@test.escrow.com",
                      "beneficiary_customer": "me"
                  }
              ]
          },
          {
              "type": "broker_fee",
              "schedule": [
                  {
                      "amount": 200,
                      "payer_customer": "keanu.reaves@test.escrow.com",
                      "beneficiary_customer": "me"
                  }
              ]
          }
      ]
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.${envDomainName}/2017-09-01/transaction',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'currency' => 'usd',
            'items' => array(
                array(
                    'description' => 'johnwick.com',
                    'schedule' => array(
                        array(
                            'payer_customer' => 'john.wick@test.escrow.com',
                            'amount' => '1000.0',
                            'beneficiary_customer' => 'keanu.reaves@test.escrow.com',
                        ),
                    ),
                    'title' => 'johnwick.com',
                    'inspection_period' => '259200',
                    'type' => 'domain_name',
                    'quantity' => '1',
                ),
                array(
                    'type' => 'broker_fee',
                    'schedule' => array(
                        array(
                            'payer_customer' => 'john.wick@test.escrow.com',
                            'amount' => '2500',
                            'beneficiary_customer' => 'me',
                        ),
                    ),
                ),
                array(
                    'type' => 'broker_fee',
                    'schedule' => array(
                        array(
                            'payer_customer' => 'keanu.reaves@test.escrow.com',
                            'amount' => '200',
                            'beneficiary_customer' => 'me',
                        ),
                    ),
                ),
            ),
            'description' => 'The sale of johnwick.com',
            'parties' => array(
                array(
                    'customer' => 'me',
                    'role' => 'broker',
                ),
                array(
                    'customer' => 'john.wick@test.escrow.com',
                    'role' => 'buyer',
                ),
                array(
                    'customer' => 'keanu.reaves@test.escrow.com',
                    'role' => 'seller',
                ),
            ),
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                currency = "usd",
                description = "The sale of johnwick.com",
                items = new[]
                {
                    new
                    {
                        title = "johnwick.com",
                        description = "johnwick.com",
                        inspection_period = "259200",
                        type = "domain_name",
                        quantity = "1",
                        schedule = new[]
                        {
                            new
                            {
                                payer_customer = "johnwick@example.com",
                                amount = "1000.0",
                                beneficiary_customer = "keanu.reaves@example.com",
                            },
                        },
                    },
                    new
                    {
                        type = "broker_fee",
                        schedule = new[]
                        {
                            new
                            {
                                payer_customer = "johnwick@example.com",
                                amount = "2500",
                                beneficiary_customer = "me",
                            },
                        },
                    },
                    new
                    {
                        type = "broker_fee",
                        schedule = new[]
                        {
                            new
                            {
                                payer_customer = "keanu.reaves@example.com",
                                amount = "200",
                                beneficiary_customer = "me",
                            },
                        },
                    },
                },
                parties = new[]
                {
                    new
                    {
                        customer = "me",
                        role = "broker",
                    },
                    new
                    {
                        customer = "johnwick@example.com",
                        role = "buyer",
                    },
                    new
                    {
                        customer = "keanu.reaves@example.com",
                        role = "seller",
                    },
                },
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.${envDomainName}/2017-09-01/transaction"),
                Method = HttpMethod.Post,
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
          <SectionSubTitle>Example Response</SectionSubTitle>
          <Code language="json">
            {`{
  "creation_date": "2017-09-28T06:06:28.210000+00:00",
  "currency": "usd",
  "description": "The sale of johnwick.com",
  "id": 1133945,
  "items": [
    {
      "description": "johnwick.com",
      "extra_attributes": {
        "concierge": false,
        "with_content": false
      },
      "fees": [
        {
          "amount": "113.75",
          "payer_customer": "john.wick@test.escrow.com",
          "type": "escrow"
        }
      ],
      "id": 1607038,
      "inspection_period": 1800,
      "quantity": 1,
      "schedule": [
        {
          "amount": "1000.00",
          "beneficiary_customer": "keanu.reaves@test.escrow.com",
          "payer_customer": "john.wick@test.escrow.com",
          "status": {
            "secured": false
          }
        }
      ],
      "status": {
        "accepted": false,
        "accepted_returned": false,
        "received": false,
        "received_returned": false,
        "rejected": false,
        "rejected_returned": false,
        "shipped": false,
        "shipped_returned": false
      },
      "title": "johnwick.com",
      "type": "domain_name"
    }
  ],
  "parties": [
    {
      "agreed": false,
      "customer": "john.wick@test.escrow.com",
      "role": "buyer"
    },
    {
      "agreed": false,
      "customer": "keanu.reaves@test.escrow.com",
      "role": "seller"
    },
    {
      "agreed": true,
      "customer": "broker@escrow.com",
      "role": "broker"
    },
    {
      "agreed": true,
      "customer": "broker@escrow.com",
      "role": "partner"
    }
  ]
}`}
          </Code>
          <SectionDesc>
            <br />
            You may also hide the broker_fee from a party in the transaction by setting the
            visibility object on the item. All broker_fee items must have the same visibility set
            and all parties that the fees are hidden from must also appear in the parties visibility
            objects.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.${envDomainName}/2017-09-01/transaction" \\
    -X POST \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
    -d '
{
    "parties": [
        {
            "role": "broker",
            "customer": "me"
        },
        {
            "role": "buyer",
            "customer": "john.wick@test.escrow.com"
        },
        {
            "role": "seller",
            "customer": "keanu.reaves@test.escrow.com",
            "visibility": {
                "hidden_from": ["john.wick@test.escrow.com"]
            }
        }
    ],
    "currency": "usd",
    "description": "The sale of johnwick.com",
    "items": [
        {
            "title": "johnwick.com",
            "description": "johnwick.com",
            "type": "domain_name",
            "inspection_period": 259200,
            "quantity": 1,
            "extra_attributes": {
                "with_content": false
            },
            "schedule": [
                {
                    "amount": 1000.0,
                    "payer_customer": "john.wick@test.escrow.com",
                    "beneficiary_customer": "keanu.reaves@test.escrow.com"
                }
            ]
        },
        {
            "type": "broker_fee",
            "visibility": {
                "hidden_from": ["john.wick@test.escrow.com"]
            },
            "schedule": [
                {
                    "amount": 250,
                    "payer_customer": "john.wick@test.escrow.com",
                    "beneficiary_customer": "me"
                }
            ]
        },
        {
            "type": "broker_fee",
            "visibility": {
                "hidden_from": ["john.wick@test.escrow.com"]
            },
            "schedule": [
                {
                    "amount": 200,
                    "payer_customer": "keanu.reaves@test.escrow.com",
                    "beneficiary_customer": "me"
                }
            ]
        }
    ]
}'`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="python">
                {`import requests

requests.post(
    'https://api.${envDomainName}/2017-09-01/transaction',
    auth=('email address', 'api-key'),
    json={
        "parties": [
            {
                "role": "broker",
                "customer": "me"
            },
            {
                "role": "buyer",
                "customer": "john.wick@test.escrow.com"
            },
            {
                "role": "seller",
                "customer": "keanu.reaves@test.escrow.com",
                "visibility": {
                    "hidden_from": ["john.wick@test.escrow.com"]
                }
            }
        ],
        "currency": "usd",
        "description": "The sale of johnwick.com",
        "items": [
            {
                "title": "johnwick.com",
                "description": "johnwick.com",
                "type": "domain_name",
                "inspection_period": 259200,
                "quantity": 1,
                "schedule": [
                    {
                        "amount": 1000.0,
                        "payer_customer": "john.wick@test.escrow.com",
                        "beneficiary_customer": "keanu.reaves@test.escrow.com"
                    }
                ]
            },
            {
                "type": "broker_fee",
                "schedule": [
                    {
                        "amount": 2500,
                        "payer_customer": "john.wick@test.escrow.com",
                        "beneficiary_customer": "me"
                    }
                ],
                "visibility": {
                    "hidden_from": ["john.wick@test.escrow.com"]
                }
            },
            {
                "type": "broker_fee",
                "schedule": [
                    {
                        "amount": 200,
                        "payer_customer": "keanu.reaves@test.escrow.com",
                        "beneficiary_customer": "me"
                    }
                ],
                "visibility": {
                    "hidden_from": ["john.wick@test.escrow.com"]
                }
            }
        ]
    },
)`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.${envDomainName}/2017-09-01/transaction")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Post.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
  request.body = {
      "parties": [
          {
              "role": "broker",
              "customer": "me"
          },
          {
              "role": "buyer",
              "customer": "john.wick@test.escrow.com"
          },
          {
              "role": "seller",
              "customer": "keanu.reaves@test.escrow.com",
              "visibility": {
                  "hidden_from": ["john.wick@test.escrow.com"]
              }
          }
      ],
      "currency": "usd",
      "description": "The sale of johnwick.com",
      "items": [
          {
              "title": "johnwick.com",
              "description": "johnwick.com",
              "type": "domain_name",
              "inspection_period": 259200,
              "quantity": 1,
              "schedule": [
                  {
                      "amount": 1000.0,
                      "payer_customer": "john.wick@test.escrow.com",
                      "beneficiary_customer": "keanu.reaves@test.escrow.com"
                  }
              ]
          },
          {
              "type": "broker_fee",
              "schedule": [
                  {
                      "amount": 2500,
                      "payer_customer": "john.wick@test.escrow.com",
                      "beneficiary_customer": "me"
                  }
              ],
              "visibility": {
                  "hidden_from": ["john.wick@test.escrow.com"]
              }
          },
          {
              "type": "broker_fee",
              "schedule": [
                  {
                      "amount": 200,
                      "payer_customer": "keanu.reaves@test.escrow.com",
                      "beneficiary_customer": "me"
                  }
              ],
              "visibility": {
                  "hidden_from": ["john.wick@test.escrow.com"]
              }
          }
      ]
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.${envDomainName}/2017-09-01/transaction',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'currency' => 'usd',
            'items' => array(
                array(
                    'description' => 'johnwick.com',
                    'schedule' => array(
                        array(
                            'payer_customer' => 'john.wick@test.escrow.com',
                            'amount' => '1000.0',
                            'beneficiary_customer' => 'keanu.reaves@test.escrow.com',
                        ),
                    ),
                    'title' => 'johnwick.com',
                    'inspection_period' => '259200',
                    'type' => 'domain_name',
                    'quantity' => '1',
                ),
                array(
                    'type' => 'broker_fee',
                    'schedule' => array(
                        array(
                            'payer_customer' => 'john.wick@test.escrow.com',
                            'amount' => '2500',
                            'beneficiary_customer' => 'me',
                        ),
                    ),
                    'visibility' => array(
                        'hidden_from' => array(
                            'john.wick@test.escrow.com',
                        ),
                    ),
                ),
                array(
                    'type' => 'broker_fee',
                    'schedule' => array(
                        array(
                            'payer_customer' => 'keanu.reaves@test.escrow.com',
                            'amount' => '200',
                            'beneficiary_customer' => 'me',
                        ),
                    ),
                    'visibility' => array(
                        'hidden_from' => array(
                            'john.wick@test.escrow.com',
                        ),
                    ),
                ),
            ),
            'description' => 'The sale of johnwick.com',
            'parties' => array(
                array(
                    'customer' => 'me',
                    'role' => 'broker',
                ),
                array(
                    'customer' => 'john.wick@test.escrow.com',
                    'role' => 'buyer'
                ),
                array(
                    'customer' => 'keanu.reaves@test.escrow.com',
                    'role' => 'seller',
                    'visibility' => array(
                        'hidden_from' => array(
                            'john.wick@test.escrow.com',
                        ),
                    ),
                ),
            ),
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                currency = "usd",
                description = "The sale of johnwick.com",
                items = new[]
                {
                    new
                    {
                        title = "johnwick.com",
                        description = "johnwick.com",
                        inspection_period = "259200",
                        type = "domain_name",
                        quantity = "1",
                        schedule = new[]
                        {
                            new
                            {
                                payer_customer = "johnwick@example.com",
                                amount = "1000.0",
                                beneficiary_customer = "keanu.reaves@example.com",
                            },
                        },
                    },
                    new
                    {
                        type = "broker_fee",
                        schedule = new[]
                        {
                            new
                            {
                                payer_customer = "johnwick@example.com",
                                amount = "2500",
                                beneficiary_customer = "me",
                            },
                        },
                        visibility = new
                        {
                            hidden_from = new[] {johnwick@example.com}
                        },
                    },
                    new
                    {
                        type = "broker_fee",
                        schedule = new[]
                        {
                            new
                            {
                                payer_customer = "keanu.reaves@example.com",
                                amount = "200",
                                beneficiary_customer = "me",
                            },
                        },
                        visibility = new
                        {
                            hidden_from = new[] {johnwick@example.com}
                        },
                    },
                },
                parties = new[]
                {
                    new
                    {
                        customer = "me",
                        role = "broker",
                    },
                    new
                    {
                        customer = "johnwick@example.com",
                        role = "buyer",
                    },
                    new
                    {
                        customer = "keanu.reaves@example.com",
                        role = "seller",
                        ,
                        visibility = new
                        {
                            hidden_from = new[] {johnwick@example.com}
                        },
                    },
                },
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.${envDomainName}/2017-09-01/transaction"),
                Method = HttpMethod.Post,
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
          <SectionSubTitle>Example Response</SectionSubTitle>
          <Code language="json">
            {`{
  "creation_date": "2017-09-28T06:06:28.210000+00:00",
  "currency": "usd",
  "description": "The sale of johnwick.com",
  "id": 1133945,
  "items": [
    {
      "description": "johnwick.com",
      "extra_attributes": {
        "concierge": false,
        "with_content": false
      },
      "fees": [
        {
          "amount": "113.75",
          "payer_customer": "john.wick@test.escrow.com",
          "type": "escrow"
        }
      ],
      "id": 1607038,
      "inspection_period": 1800,
      "quantity": 1,
      "schedule": [
        {
          "amount": "1000.00",
          "beneficiary_customer": "keanu.reaves@test.escrow.com",
          "payer_customer": "john.wick@test.escrow.com",
          "status": {
            "secured": false
          }
        }
      ],
      "status": {
        "accepted": false,
        "accepted_returned": false,
        "received": false,
        "received_returned": false,
        "rejected": false,
        "rejected_returned": false,
        "shipped": false,
        "shipped_returned": false
      },
      "title": "johnwick.com",
      "type": "domain_name"
    }
  ],
  "parties": [
    {
      "agreed": false,
      "customer": "john.wick@test.escrow.com",
      "role": "buyer"
    },
    {
      "agreed": false,
      "customer": "keanu.reaves@test.escrow.com",
      "role": "seller"
    },
    {
      "agreed": true,
      "customer": "broker@escrow.com",
      "role": "broker"
    },
    {
      "agreed": true,
      "customer": "broker@escrow.com",
      "role": "partner"
    }
  ]
}`}
          </Code>
        </Section>
        <footer className="integrationsPortal-footer">
          <h3 className="integrationsPortal-footer-title">{'Next steps'}</h3>
          <nav className="integrationsPortal-footer-steps">
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Agree to a transaction"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_agree_transaction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Fetch transaction details"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_fetch_transaction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Create a customer"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_create_customer'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Full API reference Document"
                link={{
                  type: 'external',
                  route: window.config.api_docs_page,
                }}
              />
            </div>
          </nav>
        </footer>
      </div>
    </div>
  </div>
);

export default CreateTransaction;
