import React, { useState, Fragment } from 'react';
import { useForm } from 'react-final-form';
import { Divider, Alert, MenuItem, Tooltip, Modal, capitalize, Button } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { formatCurrency } from 'spa/components/StartTransaction/util';
import { required } from 'spa/components/form/validate';
import { DefaultSkeleton } from 'spa/components/Skeleton';
import { InlineSelectField } from 'spa/components/StartTransaction/Fields';
import TransactionConstants from 'spa/constants/TransactionConstants';
import { useDebouncedEffect, useWindowDimensions } from 'spa/hooks';
import { fetchTransactionFeesSummary as fetchTransactionFeesSummaryRoutine } from 'spa/actions/TransactionActions';
import { bindPromiseCreators, promisifyRoutine } from 'redux-saga-routines';
import { connect } from 'react-redux';
import { useIsMobileViewport } from 'spa/components/MaterialUI/Theme';

const SKELETON_COLOR = '#e8eef2';

const TransactionSummary = ({
  fetchTransactionFeesSummary,
  values,
  errors,
  submitting,
  setFeeSummaryValid,
}) => {
  const [loading, setLoading] = useState(false);
  const [feesSummary, setFeesSummary] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(false);
  const form = useForm();
  const { width } = useWindowDimensions();
  const partyRole = form.getFieldState('role').value;
  const transactionDisclosure =
    form.getFieldState('transactionDisclosure') &&
    form.getFieldState('transactionDisclosure').value;

  const getTotalFee = (feeType) => {
    const fees = feesSummary.fees || [];
    const filteredFees = fees.filter((f) => f.type === feeType);
    const fee = filteredFees.reduce((sum, curr) => sum + Number(curr.amount), 0);
    return filteredFees.length > 0 ? fee : null;
  };

  const getEscrowFeePayerOptions = () => {
    const getFeeSelectOption = (role, splitRole) => {
      const roleLabel = capitalize(role);
      if (splitRole) {
        const splitRoleLabel = capitalize(splitRole);
        return {
          [`${role},${splitRole}`]: `50% ${roleLabel} / 50% ${splitRoleLabel}`,
        };
      }
      return {
        [role]: roleLabel,
      };
    };

    const buyer = TransactionConstants.TRANSACTION_ROLES.BUYER;
    const seller = TransactionConstants.TRANSACTION_ROLES.SELLER;
    const broker = TransactionConstants.TRANSACTION_ROLES.BROKER;
    if (partyRole && partyRole !== 'broker') {
      return {
        ...getFeeSelectOption(buyer),
        ...getFeeSelectOption(seller),
        ...getFeeSelectOption(buyer, seller),
      };
    }
    switch (transactionDisclosure) {
      case 'confidential':
      case 'transparentToBuyerOnly':
        return {
          ...getFeeSelectOption(buyer),
          ...getFeeSelectOption(broker),
          ...getFeeSelectOption(buyer, broker),
        };
      case 'transparentToSellerOnly':
      case 'transparentToBuyerAndSeller':
        return {
          ...getFeeSelectOption(buyer),
          ...getFeeSelectOption(seller),
          ...getFeeSelectOption(broker),
          ...getFeeSelectOption(buyer, seller),
          ...getFeeSelectOption(buyer, broker),
          ...getFeeSelectOption(seller, broker),
        };
      default:
        return { buyer: 'Buyer' };
    }
  };
  const escrowFeeOptions = getEscrowFeePayerOptions();
  const brokerFeeOptions = {
    buyer: 'Buyer',
    seller: 'Seller',
    'buyer,seller': '50% Buyer / 50% Seller',
  };

  const fetchSummary = async () => {
    const isDataComplete =
      values.role &&
      values.currency &&
      values.items &&
      values.items.length > 0 &&
      values.escrowFeePayer &&
      (values.role !== TransactionConstants.TRANSACTION_ROLES.BROKER ||
        values.brokerCommissionPayer);
    const isDataValid =
      !errors.role &&
      !errors.currency &&
      !errors.items &&
      !errors.escrowFeePayer &&
      !errors.brokerCommissionPayer &&
      !errors.paymentSchedule &&
      (!errors.buyerEmail || errors.buyerEmail === 'Required') &&
      (!errors.sellerEmail || errors.sellerEmail === 'Required');

    if (isDataComplete && isDataValid) {
      setFeeSummaryValid(false);
      setLoading(true);
      let response;
      try {
        if (error) setError(false);
        response = await fetchTransactionFeesSummary(values);
        setFeesSummary(response);
        setFeeSummaryValid(
          response.seller_proceeds > 0 &&
            (response.broker_proceeds > 0 || !response.broker_proceeds)
        );
      } catch (e) {
        setError(true);
        setFeesSummary({});
        setFeeSummaryValid(true);
      }
      setLoading(false);
    }
  };

  useDebouncedEffect(
    fetchSummary,
    [
      values.role,
      values.currency,
      values.items,
      values.brokerCommissionPayer,
      values.escrowFeePayer,
      values.buyerEmail,
      values.sellerEmail,
      values.years,
      values.months,
      values.paymentSchedule,
    ],
    500
  );

  const fallbackTotal = values.items.reduce((acc, cur) => acc + parseFloat(cur.price), 0);
  const currency = values.currency.toUpperCase();
  const itemsSubtotal = feesSummary.items_subtotal || fallbackTotal;
  const buyersPrice = feesSummary.buyer_price;
  const sellerProceeds = feesSummary.seller_proceeds;
  const brokerProceeds = feesSummary.broker_proceeds;
  const shippingFee = getTotalFee('shipping_fee');
  const brokerFee = getTotalFee('broker_fee');
  const conciergeFee = getTotalFee('concierge');
  const dnhFee = getTotalFee('domain_name_holding');
  const lienFee = getTotalFee('lien_holder_payoff');
  const titleCollectionFee = getTotalFee('title_collection');
  const escrowFee = getTotalFee('escrow');

  const hasFeesSummary = Object.getOwnPropertyNames(feesSummary).length > 0;
  const taxTypes = {};
  if (hasFeesSummary && feesSummary.fees.length > 0) {
    feesSummary.fees.forEach((fee) => {
      if (fee.taxes && fee.taxes.length > 0) {
        taxTypes[fee.type] ? taxTypes[fee.type].push(fee.taxes[0].type) : taxTypes[fee.type] = [fee.taxes[0].type];
      }
    });
  }

  const escrowFeeExtra = [conciergeFee, dnhFee, lienFee, titleCollectionFee].reduce(
    (acc, cur) => acc + (cur || 0),
    0
  );
  const getSellerPayment = () => {
    let brokerCommissionProportion = 0;
    let escrowFeeProportion = 0;
    switch (values.brokerCommissionPayer) {
      case TransactionConstants.TRANSACTION_ROLES.SELLER: {
        brokerCommissionProportion = 1;
        break;
      }
      case 'buyer,seller': {
        brokerCommissionProportion = 0.5;
        break;
      }
      default: {
        brokerCommissionProportion = 0;
      }
    }
    switch (values.escrowFeePayer) {
      case TransactionConstants.TRANSACTION_ROLES.SELLER: {
        escrowFeeProportion = 1;
        break;
      }
      case 'buyer,seller':
      case 'seller,broker': {
        escrowFeeProportion = 0.5;
        break;
      }
      default: {
        escrowFeeProportion = 0;
      }
    }

    return (
      brokerCommissionProportion * (brokerFee || 0) +
      escrowFeeProportion * (escrowFee + escrowFeeExtra)
    );
  };

  const getBrokerPayment = () => {
    let escrowFeeProportion = 0;
    switch (values.escrowFeePayer) {
      case TransactionConstants.TRANSACTION_ROLES.BROKER: {
        escrowFeeProportion = 1;
        break;
      }
      case 'buyer,broker':
      case 'seller,broker': {
        escrowFeeProportion = 0.5;
        break;
      }
      default: {
        escrowFeeProportion = 0;
      }
    }
    return escrowFeeProportion * (escrowFee + escrowFeeExtra);
  };

  const disableBrokerFeePayee =
    partyRole === TransactionConstants.TRANSACTION_ROLES.BROKER &&
    transactionDisclosure === 'confidential';

  const FeeText = ({name, children}) => (
    <div className="createTransaction-fee">
      <div className="createTransaction-fee-text">{name}:</div>
      <div className="createTransaction-fee-text createTransaction-fee-amount">
        {loading ? (
          <DefaultSkeleton color={SKELETON_COLOR} />
        ) : children}
      </div>
    </div>
  );

  const isMobileViewport = useIsMobileViewport();

  return (
    <div>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <div className="createTransaction-fee-modal">
          <div className="createTransaction-fee-modal-title">How are the totals calculated?</div>
          {partyRole === TransactionConstants.TRANSACTION_ROLES.BROKER && (
            <Fragment>
              <div className="createTransaction-fee-modal-subtitle">Broker proceeds:</div>
              <div className="createTransaction-fee-modal-description">
                This is the estimated amount that the broker will receive minus fees.
              </div>
            </Fragment>
          )}
          <div className="createTransaction-fee-modal-subtitle">Buyer price:</div>
          <div className="createTransaction-fee-modal-description">
            This is the estimated amount that the buyer will pay for inclusive of fees.
          </div>
          <div className="createTransaction-fee-modal-subtitle">Seller proceeds:</div>
          <div className="createTransaction-fee-modal-description">
            This is the estimated amount that the seller will receive minus fees.
          </div>
          <div className="createTransaction-fee-modal-button">
            <Button
              color="primary"
              variant={width >= TransactionConstants.DESKTOP_VIEW_WIDTH ? 'text' : 'contained'}
              onClick={() => setShowModal(false)}
            >
              Got it
            </Button>
          </div>
        </div>
      </Modal>
      <div className="createTransaction-summary-header">
        <div className="createTransaction-subform--header"> Transaction summary </div>
        <a
          role="button"
          tabIndex={0}
          className="createTransaction-summary-header-anchor"
          onClick={() => setShowModal(true)}
        >
          {width >= TransactionConstants.DESKTOP_VIEW_WIDTH ? (
            'How the totals are calculated?'
          ) : (
            <div className="createTransaction-default-text-icon">
              <Tooltip title="How the totals are calculated?" placement="top" arrow>
                <HelpIcon color="disabled" fontSize="small" />
              </Tooltip>
            </div>
          )}
        </a>
      </div>
      {!loading && (sellerProceeds <= 0 || brokerProceeds <= 0) && (
        <div className="createTransaction-check-container">
          <Alert severity="error" variant="outlined">
            {sellerProceeds <= 0
              ? `The subtotal (${formatCurrency(
                  itemsSubtotal,
                  currency
                )}) must be greater than the fees paid by the seller (${formatCurrency(
                  getSellerPayment(),
                  currency
                )}).`
              : `The broker commission (${formatCurrency(
                  brokerFee,
                  currency
                )}) must be greater than the the fees paid by the broker (${formatCurrency(
                  getBrokerPayment(),
                  currency
                )}).`}
          </Alert>
        </div>
      )}
      {error && (
        <Alert severity="error">{"Sorry, we're unable to display exact fees at this time."}</Alert>
      )}
      <div className="createTransaction-fee-container">
        <FeeText name="Subtotal">
          {formatCurrency(itemsSubtotal, currency)}
        </FeeText>
        {shippingFee && (
          <FeeText name="Shipping fee">
            {formatCurrency(shippingFee, currency)}
          </FeeText>
        )}
        {titleCollectionFee && (
          <div className="createTransaction-fee">
            <div className="createTransaction-fee-text">Title collection fee:</div>
            <div className="createTransaction-fee-text createTransaction-fee-amount">
            {!loading ? (
              <React.Fragment>
                {formatCurrency(titleCollectionFee, currency)} 
                {isMobileViewport ? <br /> : ''}
                {taxTypes["title_collection"] ? (
                  taxTypes["title_collection"].length > 1 ? (` (inc. taxes)`
                  ) : (` (inc. ${taxTypes["title_collection"][0].toUpperCase()})` )) : ''}
              </React.Fragment>
            ) : (
              <DefaultSkeleton color={SKELETON_COLOR} />
            )}
            </div>
          </div>
        )}
        {lienFee && (
          <FeeText name="Lien holder payoff fee">
          {!loading ? (
            <React.Fragment>
              {formatCurrency(lienFee, currency)}
              {isMobileViewport ? <br /> : ''}
              {taxTypes["lien_holder_payoff"] ? (
                  taxTypes["lien_holder_payoff"].length > 1 ? (` (inc. taxes)`
                  ) : (` (inc. ${taxTypes["lien_holder_payoff"][0].toUpperCase()})` )) : ''}
            </React.Fragment>
          ) : (
            <DefaultSkeleton color={SKELETON_COLOR} />
          )}
          </FeeText>
        )}
        {dnhFee && (
          <FeeText name="Domain Name Holding fee">
            {!loading ? (
              <React.Fragment>
                {formatCurrency(dnhFee, currency)} 
                {isMobileViewport ? <br /> : ''}
                {taxTypes["domain_name_holding"] ? (
                  taxTypes["domain_name_holding"].length > 1 ? (` (inc. taxes)`
                  ) : (` (inc. ${taxTypes["domain_name_holding"][0].toUpperCase()})` )) : ''}
              </React.Fragment>
            ) : (
              <DefaultSkeleton color={SKELETON_COLOR} />
            )}
          </FeeText>
        )}
        {partyRole === TransactionConstants.TRANSACTION_ROLES.BROKER && (
          <div className="createTransaction-fee">
            <div className="createTransaction-fee-text">
              <InlineSelectField
                validate={required}
                disabled={submitting || disableBrokerFeePayee}
                name="brokerCommissionPayer"
                label="Broker commission paid by: "
                initialValue={TransactionConstants.TRANSACTION_ROLES.SELLER}
                getLabelFromValue={(value) => brokerFeeOptions[value]}
              >
                {Object.entries(brokerFeeOptions).map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </InlineSelectField>
            </div>
            <div className="createTransaction-fee-text createTransaction-fee-amount">
              {!brokerFee || loading ? (
                <DefaultSkeleton color={SKELETON_COLOR} />
              ) : (
                formatCurrency(brokerFee, currency)
              )}
            </div>
          </div>
        )}
        <div className="createTransaction-fee">
          <div className="createTransaction-fee-text">
            <InlineSelectField
              validate={required}
              disabled={submitting}
              name="escrowFeePayer"
              label={`Escrow fee${conciergeFee ? ' with concierge' : ''} paid by: `}
              initialValue={TransactionConstants.TRANSACTION_ROLES.BUYER}
              getLabelFromValue={(value) => escrowFeeOptions[value]}
            >
              {Object.entries(escrowFeeOptions).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </InlineSelectField>
          </div>
          <div className="createTransaction-fee-text createTransaction-fee-amount">
            {!escrowFee || loading ? (
              <DefaultSkeleton color={SKELETON_COLOR} />
            ) : (
              <React.Fragment>
              {formatCurrency(
                parseFloat(escrowFee) + (conciergeFee ? parseFloat(conciergeFee) : 0),
                currency
              )}
              {isMobileViewport ? <br /> : ''}
              {taxTypes["escrow"] ? (
                taxTypes["escrow"].length > 1 ? (` (inc. taxes)`
                ) : (` (inc. ${taxTypes["escrow"][0].toUpperCase()})` )) : ''}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
      <Divider variant="fullWidth" />
      <FeeText name="Buyer price">
        {formatCurrency(buyersPrice, currency)}
      </FeeText>
      <FeeText name="Seller proceeds">
        <span className={sellerProceeds <= 0 ? 'createTransaction-fee-amount-invalid' : ''}>
          {formatCurrency(sellerProceeds, currency)}
        </span>
      </FeeText>
      {partyRole === TransactionConstants.TRANSACTION_ROLES.BROKER && (
        <FeeText name="Broker proceeds">
          <span className={brokerProceeds <= 0 ? 'createTransaction-fee-amount-invalid' : ''}>
            {formatCurrency(brokerProceeds, currency)}
          </span>
        </FeeText>
      )}
      <div className="materialUI-box-content-italic createTransaction-fee-total-description">
        All prices are in {currency}. Taxes may apply.
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ownProps;

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators(
    {
      fetchTransactionFeesSummary: promisifyRoutine(fetchTransactionFeesSummaryRoutine),
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionSummary);
