import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'spa/components/Icon';

const KYCStatus = ({ status, statusText, hideIcon, className }) => (
  <span className={`kycStatus ${className}`} data-type={status}>
    {!hideIcon && <Icon name="ui-verified" className="kycStatus-icon" />}
    <span className="kycStatus-text">{statusText}</span>
  </span>
);

KYCStatus.propTypes = {
  status: PropTypes.string,
  statusText: PropTypes.string,
  hideIcon: PropTypes.bool,
};

KYCStatus.defaultProps = {
  hideIcon: false,
};

export default KYCStatus;
