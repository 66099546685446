import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR, ARRAY_ERROR } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { Checkbox, Divider, FormControlLabel, Alert, AlertTitle } from '@mui/material';
import { startTransaction as startTransactionRoutine } from 'spa/actions/TransactionActions';
import TransactionConstants from 'spa/constants/TransactionConstants';
import { bindPromiseCreators, promisifyRoutine } from 'redux-saga-routines';
import {
  TransactionSummary,
  TransactionInfo,
  TransactionItems,
  CreateTransactionSuccess,
  TransactionTerms,
  SubmitButton,
} from 'spa/components/StartTransaction';
import {
  DNHAdditionalInfoInput,
  DomainNameAdditionalFields,
} from 'spa/components/StartTransaction/TransactionItem/AdditionalFields';

const { CSV_UPLOAD_ERROR, V3_FORM_NAME } = TransactionConstants;

const StartTransaction = ({ startTransaction }) => {
  const [completed, setComplete] = useState(null);
  const [submitCount, setSubmitCount] = useState(0);
  const [feeSummaryValid, setFeeSummaryValid] = useState(true);
  const [edit, setEdit] = useState(0);
  const onSubmit = async (values) => {
    setSubmitCount(submitCount + 1);
    try {
      const response = await startTransaction(values);
      setComplete(response);
    } catch (e) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      return { [FORM_ERROR]: e };
    }
  };

  if (completed !== null) {
    return <CreateTransactionSuccess transaction={completed} />;
  }
  return (
    <div className="createTransaction section--mid">
      <div className="section-container section--small createTransaction-form--container">
        <Form
          onSubmit={onSubmit}
          subscription={{
            values: true,
            errors: true,
            submitError: true,
            submitting: true,
            hasValidationErrors: true,
            modifiedSinceLastSubmit: true,
          }}
          mutators={{
            ...arrayMutators,
            setItemsError: ([error], state) => {
              state.fields.items.data.error = error;
            },
          }}
        >
          {({
            handleSubmit,
            values,
            errors,
            submitError,
            submitting,
            hasValidationErrors,
            modifiedSinceLastSubmit,
          }) => {
            const submitDisabled =
              (submitError && !modifiedSinceLastSubmit) ||
              hasValidationErrors ||
              !values.agreeTermsConditions ||
              !feeSummaryValid;
            const showSummary =
              edit === -1 &&
              (values.items[0].isDNH === undefined ||
                values.items[0].isDNH === false ||
                (values.items[0].isDNH !== undefined &&
                  values.items[0].isDNH &&
                  values.items[0].dnsManagedBy !== undefined)) &&
              (!values.paymentSchedule ||
                (values.items[0].isDNH === false && values.paymentSchedule.length === 0) ||
                (values.paymentSchedule &&
                  values.paymentSchedule.length > 0 &&
                  !errors.paymentSchedule));
            let error = null;
            if (submitError) {
              if (submitCount >= 3 && submitError.requestId) {
                error = (
                  <Alert severity="error">
                    <AlertTitle>An error has occurred when processing your request.</AlertTitle>
                    For further assistance, you may contact our support team and quote the following
                    reference id: {submitError.requestId}
                  </Alert>
                );
              } else {
                error = (
                  <Alert severity="error">
                    <AlertTitle>An error has occurred when creating your transaction.</AlertTitle>
                    Please try creating the transaction again.
                  </Alert>
                );
              }
            } else if (errors.items && errors.items[ARRAY_ERROR] === CSV_UPLOAD_ERROR) {
              if (values.role === 'broker') {
                error = (
                  <Alert severity="error">
                    <AlertTitle>An error has occurred when uploading your .csv file.</AlertTitle>
                    Please make sure the file has the following columns: <strong>
                      item name
                    </strong>, <strong>item description</strong>, <strong>price</strong> and{' '}
                    <strong>broker commission</strong>.
                  </Alert>
                );
              } else {
                error = (
                  <Alert severity="error">
                    <AlertTitle>An error has occurred when uploading your .csv file.</AlertTitle>
                    Please make sure the file has the following columns: <strong>
                      item name
                    </strong>, <strong>item description</strong>, and <strong>price</strong>.
                  </Alert>
                );
              }
            }

            return (
              <form
                onSubmit={handleSubmit}
                name={V3_FORM_NAME}
                data-tracking-section={V3_FORM_NAME}
              >
                {error}
                <TransactionInfo edit={edit} values={values} submitting={submitting} />
                <TransactionItems
                  fieldName="items"
                  formValues={values}
                  edit={edit}
                  setEdit={setEdit}
                  valid={!hasValidationErrors}
                  submitting={submitting}
                />
                {edit === -1 && <DomainNameAdditionalFields values={values} />}
                {edit === -1 && <DNHAdditionalInfoInput values={values} />}
                {showSummary && (
                  <Fragment>
                    <TransactionSummary
                      submitting={submitting}
                      errors={errors}
                      values={values}
                      setFeeSummaryValid={setFeeSummaryValid}
                    />
                    <TransactionTerms values={values} submitting={submitting} />
                    <Divider variant="fullWidth" />
                    <div className="createTransaction-check-container createTransaction-check-row-header">
                      <Field name="agreeTermsConditions" type="checkbox">
                        {({ input }) => (
                          <FormControlLabel
                            classes={{ root: 'createTransaction-checkbox-label' }}
                            control={<Checkbox {...input} disabled={submitting} />}
                            label={
                              <span className="createTransaction-default-text">
                                {'I have read and agree to the '}
                                <a href="/escrow-101/general-escrow-instructions" target="_blank">
                                  General Escrow Instructions
                                </a>
                                {' and '}
                                <a href="/legal" target="_blank">Privacy Policy</a>.
                              </span>
                            }
                          />
                        )}
                      </Field>
                    </div>
                    <div className="createTransaction-submit">
                      <SubmitButton
                        fullWidth
                        loading={submitting}
                        disabled={submitDisabled}
                        type="submit"
                      />
                    </div>
                  </Fragment>
                )}
              </form>
            );
          }}
        </Form>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators(
    {
      startTransaction: promisifyRoutine(startTransactionRoutine),
    },
    dispatch
  ),
});
export default connect(null, mapDispatchToProps)(StartTransaction);
