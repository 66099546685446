import React from 'react';
import moment from 'moment';
import { ConfirmationHeader } from 'spa/components/CheckoutDetails/V2';
import PaymentConfirmationFooter from './PaymentConfirmationFooter';
import { gettext } from '../../../../utils/filters';

const PaymentConfirmation = ({ transaction, custEmail, ctaMessage, landingUrl }) => {
  const { id, creation_date } = transaction;
  const displayInfo = [
    {
      label: 'Registered Email Address',
      value: custEmail,
    },
    {
      label: 'Transaction Number',
      value: id,
    },
    {
      label: 'Payment Date',
      value: moment(creation_date).format('D MMMM YYYY'),
    },
  ];
  return (
    <div>
      <ConfirmationHeader
        showCheck
        headingText={`Thank you for your payment`}
        subheadingText={`A confirmation email regarding your payment will be sent within the next 24 hours. If you have made a payment during a weekend, please expect the confirmation to arrive on the next business day.`}
      >
        {displayInfo.map(({ label, value }) => (
          <div className="checkout-confirmation-summary-item" key={label}>
            <div>
              <h4 className="checkout-heading-title checkout-heading-title--noBorder checkout-heading-title--center">
                {label}
              </h4>
              <span>{value}</span>
            </div>
          </div>
        ))}
      </ConfirmationHeader>
      <div className="checkout-page-divider" />
      <PaymentConfirmationFooter />
      <button
        className="checkout-card-btn checkout-card-btn--contained btn btn--secondary"
        onClick={() => window.location.assign(landingUrl)}
        data-e2e-target="checkout-form-action"
        data-tracking-name="return-to-home"
      >
        {gettext(ctaMessage)}
      </button>
    </div>
  );
};

export default PaymentConfirmation;
