var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/icons/warning.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
[], 
["specifier"], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
var t_2 = "";t_2 += "<svg version=\"1.1\" class=\"icon icon--warning ";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "specifier"), env.opts.autoescape);
t_2 += "\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewbox=\"0 0 22.5 20\" enable-background=\"new 0 0 22.5 20\" xml:space=\"preserve\">\n    <path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20.6,20H1.9c-1,0-1.9-0.8-1.9-1.9c0-0.3,0.1-0.7,0.2-0.9L9.6,0.9\n\tC9.9,0.4,10.6,0,11.2,0s1.3,0.4,1.6,0.9l9.4,16.2c0.2,0.3,0.2,0.6,0.2,0.9C22.5,19.2,21.7,20,20.6,20z M11.3,1.9L11.3,1.9L11.3,1.9\n\tL1.9,18.1l18.8,0L11.3,1.9z M11.2,13.7c0.7,0,1.2,0.6,1.2,1.2s-0.6,1.2-1.2,1.2S10,15.7,10,15S10.6,13.7,11.2,13.7z M11.9,12\n\tc0,0.3-0.3,0.5-0.6,0.5s-0.6-0.2-0.6-0.5L10,8.3c0-0.1,0-0.1,0-0.2c0-0.7,0.6-1.2,1.2-1.2s1.2,0.6,1.2,1.2c0,0.1,0,0.1,0,0.2\n\tL11.9,12z\"/>\n</svg>";
;
frame = frame.pop();
return new runtime.SafeString(t_2);
});
context.addExport("warning");
context.setVariable("warning", macro_t_1);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/icons/warning.html"] , dependencies)