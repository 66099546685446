import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getWindow } from 'spa/../utils/globals';

const window = getWindow();

export const kycApi = createApi({
  reducerPath: 'kycApi',
  baseQuery: fetchBaseQuery({
    credentials: 'include',
  }),
  tagTypes: ['Details'],
  endpoints: (builder) => ({
    getCustomerDetails: builder.query({
      query: (customerId) => ({
        url: `${window.config.customer_api_endpoint}/${customerId}`,
      }),
      transformErrorResponse: (response, meta) => ({
        ...response,
        xRequestId: meta && meta.response ? meta.response.headers.get('x-request-id') : undefined,
      }),
      providesTags: ['Details'],
    }),
    uploadFile: builder.mutation({
      query: (file) => {
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: `${window.config.v4_api_endpoint}/customer/me/verify/file`,
          method: 'POST',
          body: formData,
          formData: true,
        };
      },
      transformErrorResponse: (response, meta) => ({
        ...response,
        xRequestId: meta && meta.response ? meta.response.headers.get('x-request-id') : undefined,
      }),
    }),
    verifyCustomer: builder.mutation({
      query: (verifyCustomerBody) => ({
        url: `${window.config.v4_api_endpoint}/customer/me/verify`,
        method: 'POST',
        body: verifyCustomerBody,
      }),
      transformErrorResponse: (response, meta) => ({
        ...response,
        xRequestId: meta && meta.response ? meta.response.headers.get('x-request-id') : undefined,
      }),
    }),
    sendSMSCode: builder.mutation({
      query: (phoneNumber) => ({
        url: `${window.config.v4_api_endpoint}/customer/me/sms_verification`,
        method: 'POST',
        body: {
          action: 'phone-verification-send',
          phone: phoneNumber,
        },
      }),
      transformErrorResponse: (response, meta) => ({
        ...response,
        xRequestId: meta && meta.response ? meta.response.headers.get('x-request-id') : undefined,
      }),
    }),
    verifySMSCode: builder.mutation({
      query: ({ phoneNumber, smsCode }) => ({
        url: `${window.config.v4_api_endpoint}/customer/me/sms_verification`,
        method: 'PATCH',
        body: {
          action: 'phone-verification-validate',
          phone: phoneNumber,
          sms_code: smsCode,
        },
      }),
      transformErrorResponse: (response, meta) => ({
        ...response,
        xRequestId: meta && meta.response ? meta.response.headers.get('x-request-id') : undefined,
      }),
    }),
    updateCustomerPhoneNumber: builder.mutation({
      query: (phoneNumber) => ({
        url: `${window.config.v4_api_endpoint}/customer/me`,
        method: 'PATCH',
        body: {
          phone_number: phoneNumber,
        },
      }),
      transformErrorResponse: (response, meta) => ({
        ...response,
        xRequestId: meta && meta.response ? meta.response.headers.get('x-request-id') : undefined,
      }),
    }),
    updateCustomerDetails: builder.mutation({
      query: ({ customerId, customerDetails }) => ({
        url: `${window.config.customer_api_endpoint}/${customerId}`,
        method: 'POST',
        body: {
          ...customerDetails,
          Source: 'kyc',
        },
      }),
      transformErrorResponse: (response, meta) => ({
        ...response,
        xRequestId: meta && meta.response ? meta.response.headers.get('x-request-id') : undefined,
      }),
      // Should also have `invalidatesTags: ['Details']` but for performance reasons,
      // we're not invalidating the cache here because updateV4CustomerDetails is typically called right after this
      // and that invalidates the cache.
    }),
    updateV4CustomerDetails: builder.mutation({
      query: ({ addressDetails }) => ({
        url: `${window.config.v4_api_endpoint}/customer/me`,
        method: 'PATCH',
        body: addressDetails,
      }),
      transformErrorResponse: (response, meta) => ({
        ...response,
        xRequestId: meta && meta.response ? meta.response.headers.get('x-request-id') : undefined,
      }),
      invalidatesTags: ['Details'],
    }),
  }),
});

export const {
  useGetCustomerDetailsQuery,
  useUploadFileMutation,
  useVerifyCustomerMutation,
  useSendSMSCodeMutation,
  useVerifySMSCodeMutation,
  useUpdateCustomerPhoneNumberMutation,
  useUpdateCustomerDetailsMutation,
  useUpdateV4CustomerDetailsMutation,
} = kycApi;
