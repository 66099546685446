import { connect } from 'react-redux';

import { getPartnerLogoSettings, updatePartnerLogoSettings } from 'spa/actions/PartnerActions';
import { listPartnerLogos } from 'spa/actions/UserActions';
import LogoSettingsForm from 'spa/components/LogoUploader/LogoSettingsForm';
import {
  custIdSelector,
  partnerLogosSelector,
  partnerLogosLoadingSelector,
} from 'spa/selectors/CustomerSelectors';
import {
  partnerLogosSettingsSelector,
  partnerLogosEnableCobrandedEmailSettingSelector,
} from 'spa/selectors/PartnerSelectors';

const mapStateToProps = (state) => ({
  customerId: custIdSelector(state),
  partnerLogos: partnerLogosSelector(state),
  partnerLogosLoading: partnerLogosLoadingSelector(state),
  partnerLogosSettingsLoading: partnerLogosSettingsSelector(state).loading,
  partnerLogosSettingsError: partnerLogosSettingsSelector(state).error,
  partnerLogosSettings: {
    enableCobrandedEmails: partnerLogosEnableCobrandedEmailSettingSelector(state),
  },
});

const mapDispatchToProps = (dispatch) => ({
  listLogos: () => dispatch(listPartnerLogos()),
  getPartnerLogoSettings: (customerId) => dispatch(getPartnerLogoSettings.trigger({ customerId })),
  updatePartnerLogoSettings: (customerId, fields) =>
    dispatch(updatePartnerLogoSettings.trigger({ customerId, fields })),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogoSettingsForm);
