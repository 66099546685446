import React from 'react';
import { connect } from 'react-redux';
import QueryString from 'query-string';

import SavedPaymentMethodsContainer from 'spa/containers/SavedPaymentMethods';
import AccountHeader from 'spa/components/AccountHeader';
import Breadcrumbs from 'spa/components/Breadcrumbs';
import {
  custFullNameSelector,
  isCustomerReadySelector,
  buyerCheckoutDetailsSelector,
  customerKyc2ApprovedSelector,
} from 'spa/selectors/CustomerSelectors';
import { initAdyenCheckout as initAdyenCheckoutRoutine } from 'spa/actions/PaymentsActions';
import {
  adyenActionSelector,
  adyenHasCCSelector,
  adyenInitCheckoutSelector,
  adyenIsValidSelector,
  adyenUiLoadingSelector,
  adyenUiErrorSelector,
} from 'spa/selectors/PaymentSelectors';

import ErrorMessages from '../../../constants/ErrorMessages';

const breadcrumbs = [
  {
    text: 'Your Account',
    link: '/account-info',
  },
  {
    text: 'Payment Methods',
  },
];

const SavedPaymentMethodsPage = (props) => (
  <div className="accountInfo">
    <AccountHeader name={props.name} />
    <div className="section-container">
      <Breadcrumbs className="accountInfo-breadcrumbs" items={breadcrumbs} />
      <SavedPaymentMethodsContainer {...props} />
    </div>
  </div>
);

const mapStateToProps = (state) => {
  const adyenThreeDSResult = QueryString.parse(window.location.search).ccresult;
  let adyenThreeDSError = '';
  if (adyenThreeDSResult === 'failed') {
    adyenThreeDSError = ErrorMessages.ADYEN_THREEDS_FAILED_ON_SAVING;
  }

  return {
    adyenThreeDSError,
    display: {
      loading: !isCustomerReadySelector(state),
    },
    name: custFullNameSelector(state),
    customerDetails: buyerCheckoutDetailsSelector(state),
    isTier2Verfied: customerKyc2ApprovedSelector(state),
    adyenAction: adyenActionSelector(state),
    adyenCCAvailable: adyenHasCCSelector(state),
    adyenCheckout: adyenInitCheckoutSelector(state),
    adyenIsValid: adyenIsValidSelector(state),
    adyenLoading: adyenUiLoadingSelector(state),
    adyenError: adyenUiErrorSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  initAdyenCheckout: () => dispatch(initAdyenCheckoutRoutine.trigger()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SavedPaymentMethodsPage);
