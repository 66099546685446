import { getAbTest as getAbTestRoutine } from 'spa/actions/TrackingActions';

const initialState = {
  abTest: {},
};

function TrackingReducer(state = initialState, action) {
  switch (action.type) {
    case getAbTestRoutine.TRIGGER:
      return {
        abTest: {
          ...state.abTest,
        },
      };
    case getAbTestRoutine.SUCCESS:
      return {
        abTest: {
          ...state.abTest,
          ...action.payload,
        },
      };
    case getAbTestRoutine.FAILURE:
      return {
        abTest: {
          ...state.abTest,
        },
      };
    default:
      return state;
  }
}

export default TrackingReducer;
