import React from 'react';
import { reduxForm } from 'redux-form';
import { bindRoutineToReduxForm } from 'redux-saga-routines';

import A from 'spa/components/A';
import { ActionButton, FormError } from 'spa/components/form';
import { saveCreditCardForm as saveCreditCardFormRoutine } from 'spa/actions/PaymentsActions';
import PaymentConstants from 'spa/constants/PaymentConstants';
import AdyenForm from 'spa/components/CreditCard/AdyenForm';
import CreditCardFieldset from 'spa/components/CreditCard/CreditCardFieldset';
import window from 'window-shim';
import { gettext } from '../../../utils/filters';

const SaveNewCreditForm = ({
  customerDetails,
  handleSubmit,
  error,
  submitting,
  adyenAction,
  adyenCCAvailable,
  adyenCheckout,
  adyenIsValid,
  adyenThreeDSError,
}) => {
  const errorMessage = adyenThreeDSError || error;
  const showAdyenForm =
    adyenCCAvailable &&
    window.js_context.credit_card_gateway === PaymentConstants.CREDIT_CARD_GATEWAY.ADYEN;

  return (
    <form
      className="accountInfo-paymentMethod-form"
      onSubmit={handleSubmit(bindRoutineToReduxForm(saveCreditCardFormRoutine))}
    >
      {showAdyenForm ? (
        <AdyenForm
          adyenCCAvailable={adyenCCAvailable}
          adyenAction={adyenAction}
          checkout={adyenCheckout}
        />
      ) : (
        <CreditCardFieldset customerDetails={customerDetails} />
      )}
      <p className="accountInfo-paymentMethod-note">
        <strong>{gettext('NOTE')}:</strong>{' '}
        {gettext('You agree to authorize the use of your card for future payments.')}
      </p>
      <div className="accountInfo-paymentMethod-form-footer">
        {errorMessage && <FormError error={errorMessage} />}
        <ActionButton
          type="submit"
          className="accountForm-btn btn btn--secondary btn--large"
          label={gettext('Add Card')}
          loading={submitting}
          disabled={showAdyenForm ? !adyenIsValid : false}
        />
        <A
          link={{ type: 'internal', route: '/account-info' }}
          className="accountForm-btn accountForm-btn--cancel btn btn--clear btn--large"
        >
          {gettext('Cancel')}
        </A>
      </div>
    </form>
  );
};

export default reduxForm({ form: 'saveCreditCardForm' })(SaveNewCreditForm);
