import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import V2CheckoutContainer from 'spa/containers/Checkout/V2';
import Footer from 'spa/containers/Footer';
import PaymentsContainer from 'spa/containers/Payments';
import PaymentConfirmationContainer from 'spa/containers/Payments/PaymentConfirmation';
import PaypalRedirectionBox from 'spa/containers/Payments/PaypalRedirectionBox';
import { transactionByIdSelector } from 'spa/selectors/TransactionSelectors';
import MainFunnelHeader from 'spa/components/MainFunnelHeader';

import { gettext } from '../../../utils/filters';
import Spinner from '../../components/Indicators/Spinner';
import { paymentsPagePaymentSubmissionSuccessSelector } from '../../selectors/PaymentsPageSelectors';
import { customerSelector, isCustomerReadySelector } from '../../selectors/CustomerSelectors';

const PaymentsPage = (props) => {
  const {
    transaction,
    transactionId,
    customerId,
    areCustomerDetailsReady,
    isSubmissionSuccessful,
    checkoutData,
  } = props;

  useEffect(() => {
    if (transaction && Object.keys(transaction).length > 0) {
      const items = transaction.items || [];
      const schedules = items.reduce((acc, item) => [...acc, ...(item.schedule || [])], []);
      const isSecured = schedules.every((schedule) =>
        schedule.status ? schedule.status.secured : false
      );
      const isAgreed = transaction.parties.every((party) => party.agreed);

      if (isSecured || !isAgreed) {
        window.location.href = `${window.config.www_base_url}/transaction/${transaction.id}`;
      }
    }
  }, [transaction]);

  if (checkoutData) {
    return (
      <div>
        <MainFunnelHeader />
        <div className="checkout section section--small section--dark">
          <div className="checkout-content section-container">
            <div className="checkout-inner">
              <V2CheckoutContainer dataTrackingPrefix="main-funnel" />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
  return (
    <div>
      <MainFunnelHeader />
      <div className="accountInfo">
        <section className="section section--small section--mid">
          <div className="section-container">
            <div className="account-inner">
              {isSubmissionSuccessful ? (
                <div className="checkout-card checkout-card-secondary card">
                  <div className="checkout-card-content checkout-card-content--light">
                    <PaymentConfirmationContainer message="Your funds are being secured in Escrow" />
                    <PaypalRedirectionBox message="Redirecting you to Paypal for payment..." />
                  </div>
                </div>
              ) : (
                <Fragment>
                  <header className="account-section-header-content account-section-header-content--full">
                    <div className="account-section-header-content">
                      <h1 className="account-section-title">{gettext('Escrow Payment Page')}</h1>
                      <p className="account-section-desc">
                        {gettext(
                          'Only payments by cards that are under your name or your company name are accepted.'
                        )}
                      </p>
                      <p className="account-section-desc account-section-note">
                        <span className="field-required">*</span>{' '}
                        {gettext('denotes a required field')}
                      </p>
                    </div>
                  </header>
                  {areCustomerDetailsReady ? (
                    <PaymentsContainer
                      transactionId={transactionId}
                      customerId={customerId}
                      isOutstandingBalance={false}
                    />
                  ) : (
                    <Spinner />
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const transactionId = parseInt(ownProps.match.params.transaction_id, 10);
  return {
    transactionId,
    customerId: customerSelector(state).custId,
    transaction: transactionByIdSelector(state, transactionId),
    areCustomerDetailsReady: isCustomerReadySelector(state),
    isSubmissionSuccessful: paymentsPagePaymentSubmissionSuccessSelector(state),
    checkoutData: (window.js_context || {}).checkout_data,
  };
};

export default withRouter(connect(mapStateToProps, null)(PaymentsPage));
