import React from 'react';
import PropTypes from 'prop-types';

const DashboardCard = ({ children, className }) => (
  <section className={`dashboardCard ${className}`}>
    <div className="dashboardCard-inner">{children}</div>
  </section>
);

DashboardCard.PropTypes = {
  className: PropTypes.string,
};

export default DashboardCard;
