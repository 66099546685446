import React from 'react';
import PropTypes from 'prop-types';

const Card = ({ children, title }) => (
  <section className="accountInfo-card card">
    <div className="card-header card-inner">
      <div>
        <h3 className="card-header-title">{title}</h3>
      </div>
    </div>
    <div className="accountInfo-card-content card-inner">
      <div className="accountInfo-card-data">
        <div className="accountInfo-data-container">{children}</div>
      </div>
    </div>
  </section>
);

Card.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default Card;
