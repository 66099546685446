import { call, put, take, race, takeLatest, select } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';
import _ from 'lodash';
import deepcopy from 'clone';
import QueryString from 'query-string';

import {
  getDisbursementDetailsSuccess,
  getDisbursementDetailsFailure,
  addDomesticWire,
  addInternationalWire,
  addEuroWire,
  addAch,
  getUserFlow as getUserFlowRoutine,
} from 'spa/actions/DisbursementActions';
import { userFlowSelector } from 'spa/selectors/DisbursementSelectors';
import { registeredFieldsSelector } from 'spa/selectors/FormSelectors';
import { logError } from 'spa/actions/ErrorLoggingActions';
import { getTransactionExtraDetails as getTransactionExtraDetailsRoutine } from 'spa/actions/TransactionActions';
import DisbursementConstants from 'spa/constants/DisbursementConstants';
import APIConstants from 'spa/constants/APIConstants';
import { CountryToAlpha2 } from 'spa/constants/ISOCountryCodes';
import { getWindow } from '../../utils/globals';
import API from '../../api';
import { mapFormDataToRequestBody } from '../../utils/DataMapping';

export function processRawFormData(formName, formData) {
  const processedData = deepcopy(formData);
  // remove whitespaces from IBAN
  if (processedData.iban) {
    processedData.iban = processedData.iban.replace(/\s+/g, '');
    if (DisbursementConstants.ibanToParsedFields[formData.country]) {
      const ibanParsedFields = DisbursementConstants.ibanToParsedFields[formData.country](
        processedData.iban
      );
      processedData.accountNumber = ibanParsedFields.accountNumber;
    }
  }
  // convert SWIFT to upper case
  if (processedData.swiftOrBicCode) {
    processedData.swiftOrBicCode = processedData.swiftOrBicCode.toUpperCase();
  }
  if (processedData.country === CountryToAlpha2['New Zealand']) {
    if (processedData.suffix) {
      processedData.accountNumber =
        processedData.accountNumber.padStart(7, '0') + processedData.suffix;
      delete processedData.suffix;
    }
    if (processedData.bankNumber && processedData.branchNumber) {
      processedData.bankNumber += processedData.branchNumber;
      delete processedData.branchNumber;
    }
  }
  // move additional fields to additional_information text box
  if (processedData.brstnCode) {
    processedData.brstnCode = `BRSTN: ${processedData.brstnCode}`;
    if (processedData.additionalInformation) {
      processedData.additionalInformation = `${processedData.brstnCode}\n${processedData.additionalInformation}`;
    } else {
      processedData.additionalInformation = processedData.brstnCode;
    }
    delete processedData.brstnCode;
  }
  if (processedData.ifscCode) {
    processedData.ifscCode = `IFSC: ${processedData.ifscCode}`;
    if (processedData.additionalInformation) {
      processedData.additionalInformation = `${processedData.ifscCode}\n${processedData.additionalInformation}`;
    } else {
      processedData.additionalInformation = processedData.ifscCode;
    }
    delete processedData.ifscCode;
  }
  if (processedData.financialInstitutionNumber) {
    const message = `Financial Institution Number: ${processedData.financialInstitutionNumber}`;
    if (processedData.additionalInformation) {
      processedData.additionalInformation = `${message}\n${processedData.additionalInformation}`;
    } else {
      processedData.additionalInformation = message;
    }
    delete processedData.financialInstitutionNumber;
  }
  if (processedData.transitNumber) {
    const message = `Transit Number: ${processedData.transitNumber}`;
    if (processedData.additionalInformation) {
      processedData.additionalInformation = `${message}\n${processedData.additionalInformation}`;
    } else {
      processedData.additionalInformation = message;
    }
    delete processedData.transitNumber;
  }
  // process clearing system code and member id if applicable
  if (DisbursementConstants.clearingSystemCode[formData.country]) {
    let memberId = null;
    if (DisbursementConstants.memberIdConstructor[formData.country]) {
      memberId = DisbursementConstants.memberIdConstructor[formData.country](formData);
    } else if (DisbursementConstants.ibanToParsedFields[formData.country]) {
      memberId = DisbursementConstants.ibanToParsedFields[formData.country](formData.iban).memberId;
    }

    if (memberId && memberId !== '') {
      processedData.memberId = memberId;
      processedData.clearingSystemCode = DisbursementConstants.clearingSystemCode[formData.country];
    }
  }
  if (processedData.intermediaryBankNeeded) {
    for (const key of Object.keys(processedData.intermediaryBank)) {
      processedData[_.camelCase(`intermediaryBank ${key}`)] = processedData.intermediaryBank[key];
    }

    // detect whether ABA routing number or SWIFT code was used
    // in the abaRoutingNumberOrSwiftCode field and create a new specific field
    delete processedData.intermediaryBank;
  }
  delete processedData.intermediaryBankNeeded;
  return processedData;
}

/**
 * This function is the reverse of mapDisbursementFormToRequestBody
 *
 * Given an object which was returned from the API, converts the keys into
 * the keys for the relevant form fields
 *
 * Expects a mapping object for ${formName} to be defined in
 * DisbursementConstants.reverseApiMapping[formName]
 *
 * Note: Keys can define semantic nesting, i.e if the following
 * mapping exists
 *
 * {
 *   'personalAddress.lane1': street1
 * }
 *
 * Then the function will look inside apiData.personalAddress.lane1 for
 * the value of street1
 *
 * See https://lodash.com/docs/4.17.4#get for more details
 * */
export function reverseMapApiToDisbursementForm(formName, apiData) {
  const mappingObject = DisbursementConstants.reverseApiMapping[formName];
  const formData = {};
  if (!mappingObject) {
    throw new Error(`No mapping exists for ${formName}`);
  }

  for (const key of Object.keys(mappingObject)) {
    if (_.get(apiData, key)) {
      if (_.isArray(mappingObject[key])) {
        for (const target of mappingObject[key]) {
          _.set(formData, target, _.get(apiData, key));
        }
      } else {
        _.set(formData, mappingObject[key], _.get(apiData, key));
      }
      // remove the handled error from the object
      _.unset(apiData, key);
    }
  }

  for (const key of Object.keys(apiData)) {
    if (_.isObject(apiData[key])) {
      if (Object.keys(apiData[key]).length !== 0) {
        if (!formData.unmapped) {
          formData.unmapped = {};
        }
        formData.unmapped[key] = apiData[key];
      }
    } else {
      if (!formData.unmapped) {
        formData.unmapped = {};
      }
      formData.unmapped[key] = apiData[key];
    }
  }

  return formData;
}

export function addFormSpecificDataToRequestPayload(formName, apiBody) {
  switch (formName) {
    case DisbursementConstants.ACH_FORM_NAME:
      apiBody.currency = 'usd';
      apiBody.type = 'ach';
      apiBody.bank_address = {
        country: CountryToAlpha2['United States'],
      };
      break;
    case DisbursementConstants.INTERNATIONAL_WIRE_FORM_NAME:
      if (apiBody.currency === 'usd' && apiBody.bank_address.country === 'US') {
        apiBody.type = 'wire_transfer';
      } else {
        apiBody.type = 'wire_transfer_international';
      }
      break;
    default:
      throw new Error(`Unkown disbursement form ${formName}`);
  }
}

export function* addDisbursementDetails(formName, formSubmitRoutine, action) {
  const registeredFields = yield select(registeredFieldsSelector, formName);

  try {
    const processedData = yield call(
      processRawFormData,
      formName,
      _.pick(action.payload.values, registeredFields)
    );

    const mappingObject = DisbursementConstants.apiMapping[formName];
    const apiBody = yield call(mapFormDataToRequestBody, mappingObject, processedData);

    yield call(addFormSpecificDataToRequestPayload, formName, apiBody);

    // determine which API to call based on user flow
    const userFlow = yield select(userFlowSelector);
    if (userFlow && userFlow.type === DisbursementConstants.TRANSACTION_CREATE_FLOW) {
      yield call(API.addTransactionDisbursementMethod, userFlow.transId, apiBody);
      yield put(getTransactionExtraDetailsRoutine({ transId: userFlow.transId }));

      // wait for the extra details get request to succeed or fail
      const { transactionExtraDetailsAction, error } = yield race({
        transactionExtraDetailsAction: take(getTransactionExtraDetailsRoutine.SUCCESS),
        error: take(getTransactionExtraDetailsRoutine.FAILURE),
      });

      // check if the current user has additional KYC requirements
      if (transactionExtraDetailsAction) {
        yield put(formSubmitRoutine.success());
        const extraDetails = transactionExtraDetailsAction.payload;
        const kycNeeded = extraDetails.tier1_kyc_required;
        if (kycNeeded) {
          window.location.assign('/verify');
        } else {
          window.location.assign(`${window.config.www_base_url}/transaction/${userFlow.transId}`);
        }
      } else {
        throw new Error(error);
      }
    } else if (userFlow && userFlow.type === DisbursementConstants.CUSTOMER_EDIT_FLOW) {
      yield call(API.editCustomerDisbursementMethod, userFlow.disbursementId, apiBody);
      yield put(formSubmitRoutine.success());
      window.location.assign('/account-info');
    } else {
      yield call(API.addCustomerDisbursementMethod, apiBody);
      yield put(formSubmitRoutine.success());
      window.location.assign('/account-info');
    }
  } catch (error) {
    let reduxFormError = error;
    if (error.type === APIConstants.UNPROCESSABLE_ENTITY) {
      reduxFormError = yield call(reverseMapApiToDisbursementForm, formName, error.errors);
    } else if (error.type === APIConstants.BAD_REQUEST) {
      let errorMessage = '';
      if (error.errors && error.errors.error) {
        errorMessage = error.errors.error;
      }

      if (errorMessage === APIConstants.DISBURSEMENT_ALREADY_SET_API_ERROR_MESSAGE) {
        reduxFormError._error = errorMessage;
      } else {
        error.unmapped = true;
      }
    } else {
      yield put(logError(error));
      // set this error as unmapped
      error.unmapped = true;
    }

    if (reduxFormError.unmapped) {
      reduxFormError._error = APIConstants.GENERIC_API_ERROR_MESSAGE;
    }
    if (reduxFormError.requestId) {
      reduxFormError._error = `${reduxFormError._error} To help us assist you, please quote \
                              reference id: ${reduxFormError.requestId}.`;
    }

    yield put(formSubmitRoutine.failure(new SubmissionError(reduxFormError)));
  }
  yield put(formSubmitRoutine.fulfill());
}

export function* getDisbursementDetails() {
  try {
    const disbursementDetails = yield call(API.getDisbursementMethods);
    if (!disbursementDetails.hasOwnProperty('valid_disbursement_names')) {
      const window = getWindow();
      const urlParams = new URLSearchParams({
        flow: 'tier1',
        redirect: `${window.location.pathname}${window.location.search}`,
      });
      window.location.assign(`/verify?${urlParams}`);
    } else {
      yield put(getDisbursementDetailsSuccess(disbursementDetails));
    }
  } catch (error) {
    yield put(getDisbursementDetailsFailure(error.error));
  }
}

export function* extractUserFlow() {
  try {
    const queryParams = QueryString.parse(window.location.search);
    if (queryParams.user_flow === DisbursementConstants.TRANSACTION_CREATE_FLOW) {
      if (!queryParams.trans_id) {
        throw new Error('Missing transID');
      }
      yield put(
        getUserFlowRoutine.success({
          userFlow: queryParams.user_flow,
          transId: queryParams.trans_id,
        })
      );
    } else if (queryParams.user_flow === DisbursementConstants.CUSTOMER_EDIT_FLOW) {
      if (!queryParams.disbursement_id) {
        throw new Error('Missing disbursement_id');
      }
      yield put(
        getUserFlowRoutine.success({
          userFlow: queryParams.user_flow,
          disbursementId: queryParams.disbursement_id,
        })
      );
    }
  } catch (error) {
    yield put(logError(error));
    yield put(getUserFlowRoutine.failure());
  }
}

export function* watcher() {
  yield takeLatest(DisbursementConstants.DISBURSEMENT_FORM_SUBMIT, addDisbursementDetails);
  yield takeLatest(DisbursementConstants.GET_DISBURSEMENT_DETAILS_REQUEST, getDisbursementDetails);
  yield takeLatest(
    addDomesticWire.TRIGGER,
    _.partial(
      addDisbursementDetails,
      DisbursementConstants.DOMESTIC_WIRE_FORM_NAME,
      addDomesticWire
    )
  );
  yield takeLatest(
    addInternationalWire.TRIGGER,
    _.partial(
      addDisbursementDetails,
      DisbursementConstants.INTERNATIONAL_WIRE_FORM_NAME,
      addInternationalWire
    )
  );
  yield takeLatest(
    addEuroWire.TRIGGER,
    _.partial(
      addDisbursementDetails,
      DisbursementConstants.INTERNATIONAL_WIRE_EURO_FORM_NAME,
      addEuroWire
    )
  );
  yield takeLatest(
    addAch.TRIGGER,
    _.partial(addDisbursementDetails, DisbursementConstants.ACH_FORM_NAME, addAch)
  );
  yield takeLatest(getUserFlowRoutine.TRIGGER, extractUserFlow);
}

export default [watcher];
