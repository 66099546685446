import _ from 'lodash';
import { CountryToAlpha2, Alpha3ToAlpha2 } from 'spa/constants/ISOCountryCodes';

const disbursementDetailsSelector = (state) => state.disbursement;

const validDisbursementNamesSelector = (state) =>
  _.get(disbursementDetailsSelector(state), 'disbursement', {}).valid_disbursement_names || [];

const availableCurrencySelector = (state) =>
  _.get(disbursementDetailsSelector(state), 'disbursement', {}).available_currencies || [];

const disbursementByIdSelector = (state, disbursementId) => {
  const disbursement = disbursementDetailsSelector(state).disbursement || {};
  const methods = disbursement.saved_disbursement_methods || [];
  const filtered = methods.filter((method) => method.id === parseInt(disbursementId, 10));
  return filtered.length === 0 ? null : filtered[0];
};

const intermediaryBankSelector = (disbursementMethod) =>
  disbursementMethod.intermediary_bank ||
  (disbursementMethod.intermediary_banks &&
    disbursementMethod.intermediary_banks.length &&
    disbursementMethod.intermediary_banks[0]);

const editedDisbursementMethodSelector = (state) => {
  const userFlow = disbursementDetailsSelector(state).userFlow || {};
  const disbursementId = userFlow.disbursementId;
  if (userFlow.type !== 'customer-edit' || !disbursementId) {
    return null;
  }
  const disbursementMethod = disbursementByIdSelector(state, disbursementId);
  if (!disbursementMethod) {
    return null;
  }

  // We want the intermediary bank country be put in the correct field
  const intermediaryBank = intermediaryBankSelector(disbursementMethod);
  if ((intermediaryBank || {}).bank_address) {
    const intermediaryBankAddress = intermediaryBank.bank_address;
    intermediaryBankAddress.country =
      intermediaryBankAddress.line2 || intermediaryBankAddress.country;
  }
  return disbursementMethod;
};

const abaRoutingNumberSelector = (disbursementMethod) => disbursementMethod.bank_aba_routing_number;

const accountTypeSelector = (disbursementMethod) => disbursementMethod.account_type;

const accountNameSelector = (disbursementMethod) => disbursementMethod.account_name;

const extractAdditionalInformation = (additionalInformation) => {
  if (additionalInformation) {
    const brstnCode = additionalInformation.match(/BRSTN: (.*)/i);
    const ifscCode = additionalInformation.match(/IFSC: (.*)/i);
    const financialInstitutionNumber = additionalInformation.match(
      /Financial Institution Number: (.*)/i
    );
    const transitNumber = additionalInformation.match(/Transit Number: (.*)/i);
    const additionalInfo = additionalInformation.replace(
      /(BRSTN: .*\n?)|(Transit Number: .*\n?)|(IFSC: .*\n?)|(Financial Institution Number: .*\n?)/g,
      ''
    );

    return {
      brstnCode: brstnCode && brstnCode[1],
      ifscCode: ifscCode && ifscCode[1],
      financialInstitutionNumber: financialInstitutionNumber && financialInstitutionNumber[1],
      transitNumber: transitNumber && transitNumber[1],
      additionalInformation: additionalInfo || null,
    };
  }
  return null;
};

const additionalInformationSelector = (disbursementMethod) => {
  const extract = extractAdditionalInformation(disbursementMethod.additional_information);
  if (extract) return extract.additionalInformation;
  return null;
};

const brstnCodeSelector = (disbursementMethod) => {
  const extract = extractAdditionalInformation(disbursementMethod.additional_information);
  if (extract) return extract.brstnCode;
  return null;
};

const ifscCodeSelector = (disbursementMethod) => {
  const extract = extractAdditionalInformation(disbursementMethod.additional_information);
  if (extract) return extract.ifscCode;
  return null;
};

const financialInstitutionNumberSelector = (disbursementMethod) => {
  const extract = extractAdditionalInformation(disbursementMethod.additional_information);
  if (extract) return extract.financialInstitutionNumber;
  return null;
};

const transitNumberSelector = (disbursementMethod) => {
  const extract = extractAdditionalInformation(disbursementMethod.additional_information);
  if (extract) return extract.transitNumber;
  return null;
};

const bankAccountSuffixSelector = (bankDetails) =>
  (bankDetails.bank_account_number || '').substring(7);

const bankAddressSelector = (bankDetails) => bankDetails.bank_address;

const bankBranchNumberSelector = (bankDetails) => bankDetails.bank_branch_number;

const bankNumberSelector = (bankDetails) => (bankDetails.bank_branch_number || '').substring(0, 2);

const bankBranchSelector = (bankDetails) => (bankDetails.bank_branch_number || '').substring(2);

const bankCountrySelector = (bankDetails) => (bankAddressSelector(bankDetails) || {}).country;

const bankAccountNumberSelector = (bankDetails) =>
  bankCountrySelector(bankDetails) === CountryToAlpha2['New Zealand']
    ? (bankDetails.bank_account_number || '').substring(0, 7)
    : bankDetails.bank_account_number;

const bankIbanSelector = (bankDetails) => bankDetails.bank_iban;

const bankNameSelector = (bankDetails) => bankDetails.bank_name;

const bankSortCodeSelector = (bankDetails) => bankDetails.bank_sort_code;

const bankSwiftCodeSelector = (bankDetails) => bankDetails.bank_swift_code;

const bankAbaRoutingSelector = (bankDetails) => bankDetails.bank_aba_routing_number;

const currencySelector = (bankDetails) => bankDetails.currency;

const containerSelector = (state, container) => disbursementDetailsSelector(state)[container] || {};

const customerInformationSelector = (state) => state.customer;

const customerCountrySelector = (state) =>
  CountryToAlpha2[_.get(customerInformationSelector(state), 'personalAddress', {}).Country] ||
  Alpha3ToAlpha2[window.config.geoip_country] ||
  undefined;

const loadingSelector = (state, container) => containerSelector(state, container).loading;

const successSelector = (state, container) => containerSelector(state, container).success;

const userFlowSelector = (state) => state.disbursement.userFlow || {};

export {
  abaRoutingNumberSelector,
  accountTypeSelector,
  accountNameSelector,
  additionalInformationSelector,
  brstnCodeSelector,
  ifscCodeSelector,
  financialInstitutionNumberSelector,
  transitNumberSelector,
  bankAbaRoutingSelector,
  bankAccountNumberSelector,
  bankAccountSuffixSelector,
  bankBranchNumberSelector,
  bankBranchSelector,
  bankCountrySelector,
  bankIbanSelector,
  bankNameSelector,
  bankNumberSelector,
  bankSortCodeSelector,
  bankSwiftCodeSelector,
  currencySelector,
  intermediaryBankSelector,
  disbursementDetailsSelector,
  editedDisbursementMethodSelector,
  validDisbursementNamesSelector,
  loadingSelector,
  successSelector,
  customerCountrySelector,
  userFlowSelector,
  availableCurrencySelector,
};
