import { blur as blurAction } from 'redux-form';

export const onBlurMoney = (formName, dispatch) => (event, newValue, previousValue, name) => {
  if (!newValue) {
    return;
  }
  event.preventDefault();
  let value = newValue;
  if (value[value.length - 1] === '.') {
    value = value.slice(0, value.length - 1);
  }
  if (value[value.length - 2] === '.') {
    value = `${value}0`;
  }
  value = value.replace(/^0+/, '');
  dispatch(blurAction(formName, name, value));
};

export default {
  onBlurMoney,
};
