import window from 'window-shim';
import localStorage from 'local-storage';
import camelCase from 'camelcase';
import _ from 'lodash';

import AppDispatcher from '../dispatcher/AppDispatcher';
import { ChangeEmitter } from '../utils/ChangeEmitter';
import TrackingConstants from '../constants/TrackingConstants';
import SearchConstants from '../constants/SearchConstants';

export class TrackingStore extends ChangeEmitter {
  constructor() {
    super();
    this.flush = this.flush.bind(this);

    // Send lagging events in local storage to the data layer
    this.flushAll();
  }

  getLocalEvents() {
    let localEvents;
    try {
      localEvents = localStorage.get('trackingEvents');
    } catch (err) {
      localEvents = [];
    }

    if (!localEvents) {
      localEvents = [];
    }
    return localEvents;
  }

  push(trackingEvent) {
    const localEvents = this.getLocalEvents();

    localEvents.push(trackingEvent);

    localStorage.set('trackingEvents', localEvents);
  }

  flushAll() {
    const localEvents = this.getLocalEvents();

    localStorage.set('trackingEvents', []);
    for (const e of localEvents) {
      window.dataLayer.push(e);
    }
  }

  flush() {
    const localEvents = this.getLocalEvents();

    const events = _.partition(localEvents, 'wait');
    const readyEvents = events[1];
    const delayedEvents = events[0];

    for (const e of readyEvents) {
      window.dataLayer.push(e);
    }

    localStorage.set('trackingEvents', delayedEvents);
  }

  trackEvent(rawEvent, wait = false) {
    const actionEvent = {};
    Object.keys(rawEvent).forEach((key) => {
      if (key === 'event') {
        actionEvent[key] = rawEvent[key];
      } else {
        const newKey = camelCase('event', key);
        actionEvent[newKey] = rawEvent[key];
      }
    });
    const emptyEvent = {
      event: '',
      eventName: '',
      eventSection: '',
      eventSubsection: '',
      eventLabel: '',
      eventAction: '',
      eventValue: '',
      wait: wait,
    };
    const event = Object.assign(emptyEvent, actionEvent);
    this.push(event);
  }

  trackSearch(value, label) {
    const emptyEvent = {
      event: 'escrow_user_action',
      eventSection: '',
      eventSubsection: '',
      eventLabel: '',
      eventAction: '',
      eventValue: '',
      wait: false,
    };
    const event = Object.assign({}, emptyEvent, {
      eventSection: 'sidenav',
      eventSubsection: '',
      eventLabel: label,
      eventAction: 'search',
      eventValue: value,
    });
    this.push(event);
  }

  trackABTest(experimentName, bucket) {
    const event = Object.assign(
      {},
      {
        event: 'escrowabtest',
      },
      {
        eventTest: experimentName,
        eventVariation: bucket,
      }
    );
    this.push(event);
  }

  handleViewAction(action) {
    if (action.actionType === TrackingConstants.TRACK_EVENT) {
      this.trackEvent(action.attributes.event, action.attributes.wait);
    } else if (action.actionType === TrackingConstants.TRACK_FLUSH) {
      this.flush();
    } else if (action.actionType === SearchConstants.TRACKING_SEARCH) {
      this.trackSearch(action.value, action.label);
    } else if (action.actionType === TrackingConstants.TRACK_AB_TEST) {
      this.trackABTest(action.attributes.experimentName, action.attributes.bucket);
    }
  }
}

const trackingStore = new TrackingStore();
trackingStore.dispatchToken = AppDispatcher.registerStore(trackingStore);
export default trackingStore;
