import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import Icon from 'spa/components/Icon';
import FilterType from './FilterConstant';

class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false,
    };
    this.node;

    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setNodeRef = this.setNodeRef.bind(this);
  }

  componentWillMount() {
    if (this.props.type === FilterType.DROPDOWN) {
      document.addEventListener('mousedown', this.handleClickOutside, false);
    }
  }

  componentWillUnmount() {
    if (this.props.type === FilterType.DROPDOWN) {
      document.removeEventListener('mousedown', this.handleClickOutside, false);
    }
  }

  setNodeRef(node) {
    this.node = node;
  }

  handleClickOutside(event) {
    if (this.node && !this.node.contains(event.target)) {
      this.setState({ showDropdown: false });
    }
  }

  toggleDropdown() {
    this.setState((prevState) => ({
      showDropdown: !prevState.showDropdown,
    }));
  }

  renderChildren() {
    const { children } = this.props;
    if (typeof children === 'string') {
      return children;
    }

    return Children.map(children, (child) =>
      cloneElement(child, { toggleDropdown: this.toggleDropdown })
    );
  }

  renderInputField() {
    const {
      color,
      title,
      type,
      edgeToEdge,
      options,
      name,
      handleChange,
      isDisabled,
      initialSelection,
    } = this.props;

    switch (type) {
      case FilterType.INPUT:
        return (
          <input
            name={name}
            type="text"
            className="filter-input"
            placeholder={title}
            data-color={color}
            onChange={handleChange}
            disabled={isDisabled}
          />
        );
      case FilterType.SELECT:
        return (
          <div className="filter-select">
            <select
              name={name}
              className="filter-select-input filter-trigger"
              data-color={color}
              onChange={(e) => handleChange(e)}
              disabled={isDisabled}
              defaultValue={initialSelection}
            >
              {options.map((option) => (
                <option key={`key-${option.value}`} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <Icon name="ui-chevron-down" className="filter-select-icon" />
          </div>
        );
      default:
        return (
          <div>
            <button className="filter-trigger" onClick={this.toggleDropdown} data-color={color}>
              {title}
            </button>
            <div
              className={`filter-dropdown ${this.state.showDropdown && 'is-active'}`}
              data-edge-to-edge={edgeToEdge}
            >
              {this.state.showDropdown && this.renderChildren()}
            </div>
          </div>
        );
    }
  }

  render() {
    const { className, active } = this.props;

    return (
      <div className={`filter ${active && 'is-active'} ${className}`} ref={this.setNodeRef}>
        {this.renderInputField()}
      </div>
    );
  }
}

Filter.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  active: PropTypes.bool,
  color: PropTypes.string,
  edgeToEdge: PropTypes.bool,
  options: PropTypes.array,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  initialSelection: PropTypes.number,
};

Filter.defaultProps = {
  className: null,
  color: 'light',
  type: FilterType.DROPDOWN,
  edgeToEdge: false,
  initialSelection: -1,
};

export default Filter;
