import validator from 'validator';
import moment from 'moment';

import FormConstants from '../constants/FormConstants';
import AuthenticationActions from '../actions/AuthenticationActions';

export const FILE_VALIDATION_ERROR = {
  TOO_LARGE: 'File too large',
  INVALID_TYPE: 'Invalid file mime type',
};

/**
 * @param {allowedFileTypes} file mimetype which is allowed for upload (e.g. ['image/jpeg'])
 * @param {maxFileSize} maximum file size allowed for upload (in byte)
 */
export function fileValidatorFactory(allowedFileTypes = null, maxFileSize = null) {
  return (file) => {
    const errors = [];

    let mimeType = file.type;
    if (!mimeType && file.name.includes('.pdf')) {
      mimeType = 'application/pdf';
    }

    if (maxFileSize !== null && file.size > maxFileSize) {
      errors.push(FILE_VALIDATION_ERROR.TOO_LARGE);
    }

    if (allowedFileTypes !== null && !allowedFileTypes.includes(mimeType)) {
      errors.push(FILE_VALIDATION_ERROR.INVALID_TYPE);
    }

    return errors;
  };
}

export function validateKYCFile(file) {
  const errors = [];
  const ALLOWED_FILE_TYPES = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/tiff',
    'image/bmp',
    'application/pdf',
  ];
  const FILE_MAX_SIZE = 100000000;

  if (file.size > FILE_MAX_SIZE) {
    errors.push(FILE_VALIDATION_ERROR.TOO_LARGE);
  }

  let mimeType = file.type;
  if (!mimeType && file.name.includes('.pdf')) {
    // IE doesn't set type for PDF files
    mimeType = 'application/pdf';
  }
  if (!ALLOWED_FILE_TYPES.includes(mimeType)) {
    errors.push(FILE_VALIDATION_ERROR.INVALID_TYPE);
  }

  return errors;
}

export function validatePassword(password) {
  if (password.length < 7 || password.length > 120) return false;
  if (!/[A-Z]/.test(password)) return false;
  if (!/[a-z]/.test(password)) return false;
  if (!/[0-9`\-=;\[\]',\./~!@#\$%\^&\*\(\)_\+:\{\}\|"<>\?]/.test(password)) return false;
  // don't allow \ or spaces
  if (/\s/.test(password)) return false;
  if (/[^a-zA-Z0-9`\-=;\[\]',\./~!@#\$%\^&\*\(\)_\+:\{\}\|"<>\?]/.test(password)) return false;
  return true;
}

function getDateOfBirthFields(formState, fieldAttributes) {
  const { field, contents } = fieldAttributes;

  const DOBFields = Object.assign(
    {
      DateOfBirthYear: formState.DateOfBirthYear,
      DateOfBirthMonth: formState.DateOfBirthMonth,
      DateOfBirthDay: formState.DateOfBirthDay,
    },
    { [field]: contents }
  );

  // Date uses 0 index for January
  if (DOBFields.DateOfBirthMonth) {
    // Cast to string because 0 (Jan) will be fasly if an integer
    DOBFields.DateOfBirthMonth = (DOBFields.DateOfBirthMonth - 1).toString();
  }
  return DOBFields;
}

export default function validate(formState, fieldAttributes) {
  const { form, field, contents, type, override, required, skipValidation } = fieldAttributes;
  if (skipValidation) {
    return {};
  }

  if (override && contents) {
    return { [field]: true };
  }

  if (field === 'DateOfBirthYear' || field === 'DateOfBirthMonth' || field === 'DateOfBirthDay') {
    const DOBFields = getDateOfBirthFields(formState, fieldAttributes);
    if (DOBFields.DateOfBirthDay && DOBFields.DateOfBirthMonth && DOBFields.DateOfBirthYear) {
      const DOBMoment = moment({
        year: DOBFields.DateOfBirthYear,
        month: DOBFields.DateOfBirthMonth,
        day: DOBFields.DateOfBirthDay,
      });

      const momentNow = moment();
      const age = momentNow.diff(DOBMoment, 'years');
      const validAge = DOBMoment.isValid() && age >= 18 && age < 150;
      return {
        DateOfBirthYear: validAge,
        DateOfBirthMonth: validAge,
        DateOfBirthDay: validAge,
      };
    }
  }

  if (field === 'Username' || field === 'DisplayNameType') {
    const usernameField = document.querySelector('[data-field=Username]');
    if (usernameField) {
      if (document.getElementById('field-DisplayNameType').value === 'username') {
        if (!formState.Username) {
          return {
            DisplayNameType: true,
            Username: false,
          };
        }
      }
      if (usernameField.className.indexOf('is-hidden') > -1) {
        return {
          DisplayNameType: true,
          Username: true,
        };
      }
    }
    return {
      DisplayNameType: true,
      Username: true,
    };
  }

  if (type === 'date') {
    return {
      [field]: validator.isDate(contents),
    };
  } else if (type === 'date-year') {
    if (field === 'CompanyRegistrationDateYear') {
      return {
        [field]: validator.isInt(contents, { min: 1900, max: new Date().getFullYear() }),
      };
    } else if (field === 'IDExpiryYear') {
      return {
        [field]: validator.isInt(contents, { min: new Date().getFullYear(), max: 3000 }),
      };
    }
    return {
      [field]: validator.isInt(contents, { min: 1, max: 3000 }),
    };
  } else if (type === 'date-month') {
    return {
      [field]: validator.isInt(contents, { min: 1, max: 12, allow_leading_zeroes: true }),
    };
  } else if (type === 'date-day') {
    return {
      [field]: validator.isInt(contents, { min: 1, max: 31, allow_leading_zeroes: true }),
    };
  } else if (type === 'email') {
    // Only do userExists check on these forms with form email-address
    if (
      (form === 'signup-partner' ||
        form === 'signup-customer' ||
        form === 'signup-create' ||
        form === 'partner-login-form' ||
        form === 'partner-signup-form') &&
      field === 'email-address' &&
      contents &&
      validator.isEmail(contents)
    ) {
      AuthenticationActions.userExists(contents, form);
    } else if (form === 'login-form' || form === 'partner-login-form') {
      return {
        [field]: /@/.test(contents),
      };
    }
    return {
      [field]: validator.isEmail(contents),
    };
  } else if (type === 'number') {
    if (field === 'auto_accept' || field === 'auto_reject') {
      if (!contents) {
        return { [field]: true };
      }
    }
    if (field.indexOf('milestone-price') !== -1) {
      return {
        [field]:
          validator.isFloat(contents) &&
          parseFloat(contents) >= FormConstants.MILESTONE_MINIMUM_AMOUNT,
      };
    }
    return {
      [field]:
        (!required && validator.isEmpty(contents)) || validator.isFloat(contents, { min: 0 }), // ensure negative values are not entered
    };
  } else if (type === 'tel') {
    if (field === 'SMSCode') {
      return { [field]: /^[0-9]{6}$/.test(contents) };
    }

    return {
      [field]:
        (!required && validator.isEmpty(contents)) ||
        (!/[a-zA-Z]/.test(contents) && contents.length >= 7),
    };
  } else if (type === 'url') {
    return {
      [field]: validator.isURL(contents),
    };
  } else if (type === 'password') {
    if (form === 'login-form' || form === 'agree-login' || form === 'partner-login-form') {
      return { [field]: !!contents };
    }
    return {
      [field]: validatePassword(contents),
    };
  } else if (type === 'textarea') {
    return {
      [field]: /\S/.test(contents),
    };
  } else if (type === 'select-one') {
    if (contents === '---') {
      // default placeholder selected
      return {
        [field]: false,
      };
    }
  } else if (field === 'vin') {
    if (contents.length < 11 || contents.length > 17) return { [field]: false };
    if (/[^a-zA-Z0-9]/.test(contents)) return { [field]: false };
    return { [field]: true };
  }
  // For empty value, field is valid if not required
  // Test for non-empty whitespace strings
  return { [field]: /\S/.test(contents) || !required };
}
