import React from 'react';
import PropTypes from 'prop-types';

import { ChannelCards } from 'spa/components/ChannelCards';
import { Section } from 'spa/components/Section';

const ButtonActions = ({ children }) => {
  const channelCards = [
    {
      onClick: () => {
        window.location = `${window.config.www_base_url}/buttons/create`;
      },
      description: 'Create your first button and start using it on your website',
      cta: 'Create Buttons',
      key: 'create_button',
    },
  ];

  return (
    <Section>
      {children}
      <ChannelCards
        channelCards={channelCards}
        className="integrationsPortal-channels--conjoined"
        small
      />
    </Section>
  );
};

ButtonActions.propTypes = {
  children: PropTypes.node,
};

export default ButtonActions;
