import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, formValues } from 'redux-form';
import { ActionButton, FormError } from 'spa/components/form';
import VerificationConstants from 'spa/constants/VerificationConstants';
import {
  IdVerificationFieldset,
  AddressVerificationFieldset,
  PhotoVerificationFieldset,
} from './VerificationDetailsFieldset';

import { gettext } from '../../../utils/filters';

const PersonalVerificationForm = (props) => {
  const hideAddressVerificationFieldset = props.idContainsAddress;

  return (
    <form name={props.form} onSubmit={props.handleSubmit(props.onSubmit)}>
      <header className="verifyPage-card-header">
        <div>
          {props.title && <h4 className="verifyPage-card-header-title">{props.title}</h4>}
          {props.desc && <p className="verifyPage-card-header-desc">{props.desc}</p>}
        </div>
      </header>
      <div className="verifyPage-card-content">
        <div className="verifyPage-card-body">
          {props.submitFailed && props.error && <FormError error={props.error} />}
          {props.enableExtendedKyc && <PhotoVerificationFieldset />}
          <IdVerificationFieldset withSubtitle />
          <AddressVerificationFieldset hide={hideAddressVerificationFieldset} withSubtitle />
        </div>
      </div>

      <footer className="verifyPage-card-footer">
        <div className="verifyPage-actions">
          {props.onCancel && (
            <a
              href="#"
              role="button"
              className="verifyPage-secondaryBtn verifyPage-secondaryBtn--alt"
              onClick={props.onCancel}
              data-e2e-target="verification-personal-cancel-button"
            >
              {gettext('Cancel')}
            </a>
          )}
          <ActionButton
            type="submit"
            className="verifyPage-primaryBtn btn btn--secondary"
            data-e2e-target="verification-personal-submit-button"
            data-tracking-name={'submit'}
            loading={props.submitting}
            label={gettext('Save Changes')}
            disabled={props.uploading}
          />
        </div>
      </footer>
    </form>
  );
};

PersonalVerificationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  enableExtendedKyc: PropTypes.bool.isRequired,
  uploading: PropTypes.bool,
};

PersonalVerificationForm.defaultProps = {
  uploading: false,
};

export default reduxForm({
  form: VerificationConstants.PERSONAL_VERIFICATION_FORM,
})(formValues('idContainsAddress')(PersonalVerificationForm));
