import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { push } from 'connected-react-router';
import moment from 'moment';
import {
  customerSelector,
  paymentsInMethodsSelector,
  paymentsOutMethodsSelector,
  transactionsCursorSelector,
} from 'spa/selectors/PartnerSelectors';
import {
  getCustomerList as getCustomerListRoutine,
  getCustomerPaymentsIn as getCustomerPaymentsInRoutine,
  getCustomerPaymentsOut as getCustomerPaymentsOutRoutine,
  getTransactionList as getTransactionListRoutine,
  getTransactionStatistics as getTransactionStatisticsRoutine,
  pushTransactionListPageTransactionCount,
} from 'spa/actions/PartnerActions';
import UserDetails from 'spa/components/PartnerDashboard/UserDetails';
import { urlFor } from '../../../routeConfig';

const mapStateToProps = (state, ownProps) => ({
  customer: customerSelector(state, parseInt(ownProps.match.params.customer_id, 10)),
  customerPaymentsIn: paymentsInMethodsSelector(state),
  customerPaymentsOut: paymentsOutMethodsSelector(state),
  nextCursor: transactionsCursorSelector(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getCustomerData: () => {
    dispatch(
      getCustomerListRoutine.trigger({
        filters: {
          ids: [ownProps.match.params.customer_id],
        },
      })
    );
  },
  handleBack: () => dispatch(push(urlFor('partner_dashboard_users'))),
  retrievePaymentsIn: () => {
    dispatch(
      getCustomerPaymentsInRoutine.trigger({
        customerId: ownProps.match.params.customer_id,
      })
    );
  },
  retrievePaymentsOut: () => {
    dispatch(
      getCustomerPaymentsOutRoutine.trigger({
        customerId: ownProps.match.params.customer_id,
      })
    );
  },
  getUserTransactions: (payload) => {
    dispatch(getTransactionListRoutine.trigger(payload));
  },
  getUserTransactionsCount: ({ filters = {} }) =>
    dispatch(
      getTransactionStatisticsRoutine.trigger({
        dataType: 'initiation_date',
        interval: 'yearly',
        dateStart: '2010-01-01',
        dateEnd: moment().format('YYYY-MM-DD'),
        dataLoaderAction: pushTransactionListPageTransactionCount,
        filters,
      })
    ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDetails));
