import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import AccentedText from 'spa/components/AccentedText';
import { Code } from 'spa/components/Code';
import PrimaryLink from 'spa/components/PrimaryLink';
import { Section, SectionTitle, SectionSubTitle, SectionDesc } from 'spa/components/Section';

import { urlFor, envDomainName } from '../../../routeConfig';

const FundTransaction = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktopSmall-9">
        <h1 className="integrationsPortal-title">API Guide :: Funding a transaction</h1>
        <Section>
          <SectionTitle>Funding a transaction</SectionTitle>
          <SectionDesc>
            Transactions must be funded before the seller can ship the items. A transaction can be
            funded in multiple different ways. Such methods include PayPal, Credit Card, and Wire
            Transfer. Not all methods are applicable on each transaction. For PayPal and Credit
            Card, these must be funded through the Escrow.com website, and are not available to be
            funded through the API. Wire transfers must be initiated through your bank, however we
            expose an API endpoint that allows you to provide us with the wire reference number.
          </SectionDesc>
        </Section>
        <Section>
          <SectionTitle>Fetching available payment methods</SectionTitle>
          <SectionDesc>
            Not all payment methods are available to all customers on all transactions. Because of
            this, we offer an API endpoint to allow you to fetch what payment methods are available
            on the transaction.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.`+envDomainName+`/2017-09-01/transaction/29292/payment_methods" \\
    -X GET \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json"
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.get(
  'https://api.`+envDomainName+`/2017-09-01/transaction/29292/payment_methods',
  auth=('email address', 'api-key'),
)
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="ruby">
                {`require 'net/http'
require 'json'

uri = URI("https://api.`+envDomainName+`/2017-09-01/transaction/29292/payment_methods")

http = Net::HTTP.new(uri.host, uri.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Get.new(uri.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.`+envDomainName+`/2017-09-01/transaction/29292/payment_methods',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:api-key',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue(
                "application/json"));
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri(
                    "https://api.`+envDomainName+`/2017-09-01/transaction/29292/payment_methods"),
                Method = HttpMethod.Get,
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
          <SectionSubTitle>Example Response</SectionSubTitle>
          <Code language="json">
            {`{
  "available_payment_methods": [
    {
      "type": "paypal",
      "total": 210.0,
      "currency": "usd",
    },
    {
      "type": "credit_card",
      "total": 215.0,
      "currency": "usd",
    },
    {
      "type": "wire_transfer",
      "total": 200.0,
      "currency": "usd",
    },
    {
      "type": "international_wire_transfer",
      "total": 225.0,
      "currency": "usd",
    },
  ]
}`}
          </Code>
        </Section>
        <Section>
        <SectionTitle>Paying via PayPal or credit card</SectionTitle>
          <SectionDesc>
            For transactions where PayPal or credit card payments are accepted, use this URL to direct the buyer to Escrow.com to complete the payment: <AccentedText>{"https://www."+envDomainName+"/transactions/{transaction_id}/payment"}</AccentedText>
            <br /><br />
            To simulate a credit card payment, you may use the following test cards:
          </SectionDesc>
          <table className="apiTable">
            <thead className="apiTable-header">
              <tr className="apiTable-row">
                <th className="apiTable-cell">Brand</th>
                <th className="apiTable-cell">Number</th>
                <th className="apiTable-cell">CVV</th>
                <th className="apiTable-cell">Expiry Date</th>
              </tr>
            </thead>
            <tbody>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  Mastercard
                </td>
                <td className="apiTable-cell">
                  <AccentedText>5555 5555 5555 4444</AccentedText>
                </td>
                <td className="apiTable-cell" rowspan="2">
                  Any three (3) digits
                </td>
                <td className="apiTable-cell" rowspan="3">
                  Any future date
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  Visa
                </td>
                <td className="apiTable-cell">
                  <AccentedText>4242 4242 42424 242</AccentedText>
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  Amex
                </td>
                <td className="apiTable-cell">
                  <AccentedText>3714 496353 98431</AccentedText>
                </td>
                <td className="apiTable-cell">
                  Any four (4) digits
                </td>
              </tr>
            </tbody>
          </table>
        </Section>
        <Section>
          <SectionTitle>Paying via wire transfer</SectionTitle>
          <SectionDesc>
            Unlike PayPal or credit card, paying by wire transfer is available on all transactions
            and all transaction types. Additionally, wire transfers do not require the user to visit
            Escrow.com to complete payment.
          </SectionDesc>
          <SectionDesc>
            Paying via wire transfer is completed in two steps. The first step is retrieving the
            bank account details and payment reference details. Escrow.com uses multiple banks to
            help processing customers payments quicker so it is important to not hard code any of
            this information into your integration, doing so may impact payment processing times on
            your transactions if any of the payment information changes.
          </SectionDesc>
          <SectionSubTitle>Retrieving wire transfer details</SectionSubTitle>
          <SectionDesc>
            This endpoint is used for returning the wire transfer details for funding an entire
            transaction.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.`+envDomainName+`/2017-09-01/transaction/29292/payment_methods/\
wire_transfer"\\
    -X GET \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json"`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

 requests.get(
    'https://api.`+envDomainName+`/2017-09-01/transaction/29292/payment_methods/wire_transfer',
    auth=('email address', 'api-key')
 )
  `}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

uri = URI("https://api.`+envDomainName+`/2017-09-01/transaction/29292/payment_methods/wire_transfer")

http = Net::HTTP.new(uri.host, uri.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Get.new(uri.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.`+envDomainName+`/2017-09-01/transaction/29292/payment_methods/\
wire_transfer',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue(
                "application/json"));
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri(
                    "https://api.`+envDomainName+`/2017-09-01/transaction/29292/payment_methods/\
wire_transfer"),
                Method = HttpMethod.Get,
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
          <SectionSubTitle>Example Response</SectionSubTitle>
          <Code language="json">
            {`{
  "bank_name": "Wells Fargo Bank, N.A",
  "bank_address": "420 Montgomery St, San Francisco, CA 94104",
  "routing_number": "121000248",
  "swift_code": "WFBIUS6S",
  "credit_account_name": "Internet Escrow Services Inc",
  "credit_account_number": "7101167844"
}`}
          </Code>
        </Section>
        <Section>
          <SectionSubTitle>Marking a transaction as paid by wire transfer</SectionSubTitle>
          <SectionDesc>
            This endpoint should be called after the wire transfer has been initiated.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.`+envDomainName+`/2017-09-01/transaction/29292/payment_methods/\
wire_transfer"\\
    -X POST \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json"`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

 requests.post(
    'https://api.`+envDomainName+`/2017-09-01/transaction/29292/payment_methods/wire_transfer',
    auth=('email address', 'api-key')
 )
  `}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="ruby">
                {`require 'net/http'
require 'json'

uri = URI("https://api.`+envDomainName+`/2017-09-01/transaction/29292/payment_methods/wire_transfer")

http = Net::HTTP.new(uri.host, uri.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Post.new(uri.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.`+envDomainName+`/2017-09-01/transaction/29292/payment_methods/\
wire_transfer',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_CUSTOMREQUEST => 'POST'
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri(
                    "https://api.`+envDomainName+`/2017-09-01/transaction/29292/payment_methods/\
wire_transfer"),
                Method = HttpMethod.Post,
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
        </Section>
        <footer className="integrationsPortal-footer">
          <h3 className="integrationsPortal-footer-title">{'Next steps'}</h3>
          <nav className="integrationsPortal-footer-steps">
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Ship items in a transaction"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_ship_transaction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Receive items in a transaction"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_receive_items'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Disburse funds in a transaction"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_disburse_transaction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Full API reference Document"
                link={{
                  type: 'external',
                  route: window.config.api_docs_page,
                }}
              />
            </div>
          </nav>
        </footer>
      </div>
    </div>
  </div>
);

export default FundTransaction;
