import React from 'react';
import ReactSVG from 'react-svg';
import { gettext } from '../../../../utils/filters';

const IntegrationsAuthHeader = () => (
  <header className="integrationsAuth-header">
    <div className="section-container">
      <a
        href={window.config.www_base_url}
        className="integrationsAuth-header-logo logo"
        title={gettext('Go to home page')}
      >
        <ReactSVG src={`../../../../build/images/global/escrow-logo-v3.svg`} wrapper="span" />
        <span className="logo-suffix">Developers</span>
      </a>
    </div>
  </header>
);

export default IntegrationsAuthHeader;
