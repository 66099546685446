var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["icons/close.html"] = require( "icons/close.html" );
dependencies["icons/tick.html"] = require( "icons/tick.html" );
dependencies["icons/warning.html"] = require( "icons/warning.html" );
dependencies["icons/secure.html"] = require( "icons/secure.html" );
dependencies["icons/favorite.html"] = require( "icons/favorite.html" );
dependencies["icons/upload.html"] = require( "icons/upload.html" );
dependencies["components/buttons.html"] = require( "components/buttons.html" );
dependencies["icons/email.html"] = require( "icons/email.html" );




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/components/inputs.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("icons/close.html", false, "escrow_www/templates/components/inputs.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(context.getVariables(), frame, function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(t_1.hasOwnProperty("close")) {
var t_4 = t_1.close;
} else {
cb(new Error("cannot import 'close'")); return;
}
context.setVariable("closeIcon", t_4);
env.getTemplate("icons/tick.html", false, "escrow_www/templates/components/inputs.html", false, function(t_6,t_5) {
if(t_6) { cb(t_6); return; }
t_5.getExported(context.getVariables(), frame, function(t_7,t_5) {
if(t_7) { cb(t_7); return; }
if(t_5.hasOwnProperty("tick")) {
var t_8 = t_5.tick;
} else {
cb(new Error("cannot import 'tick'")); return;
}
context.setVariable("tickIcon", t_8);
env.getTemplate("icons/warning.html", false, "escrow_www/templates/components/inputs.html", false, function(t_10,t_9) {
if(t_10) { cb(t_10); return; }
t_9.getExported(context.getVariables(), frame, function(t_11,t_9) {
if(t_11) { cb(t_11); return; }
if(t_9.hasOwnProperty("warning")) {
var t_12 = t_9.warning;
} else {
cb(new Error("cannot import 'warning'")); return;
}
context.setVariable("warningIcon", t_12);
env.getTemplate("icons/secure.html", false, "escrow_www/templates/components/inputs.html", false, function(t_14,t_13) {
if(t_14) { cb(t_14); return; }
t_13.getExported(context.getVariables(), frame, function(t_15,t_13) {
if(t_15) { cb(t_15); return; }
if(t_13.hasOwnProperty("secure")) {
var t_16 = t_13.secure;
} else {
cb(new Error("cannot import 'secure'")); return;
}
context.setVariable("secureIcon", t_16);
env.getTemplate("icons/favorite.html", false, "escrow_www/templates/components/inputs.html", false, function(t_18,t_17) {
if(t_18) { cb(t_18); return; }
t_17.getExported(context.getVariables(), frame, function(t_19,t_17) {
if(t_19) { cb(t_19); return; }
if(t_17.hasOwnProperty("favorite")) {
var t_20 = t_17.favorite;
} else {
cb(new Error("cannot import 'favorite'")); return;
}
context.setVariable("favoriteIcon", t_20);
env.getTemplate("icons/upload.html", false, "escrow_www/templates/components/inputs.html", false, function(t_22,t_21) {
if(t_22) { cb(t_22); return; }
t_21.getExported(context.getVariables(), frame, function(t_23,t_21) {
if(t_23) { cb(t_23); return; }
if(t_21.hasOwnProperty("upload")) {
var t_24 = t_21.upload;
} else {
cb(new Error("cannot import 'upload'")); return;
}
context.setVariable("uploadIcon", t_24);
env.getTemplate("components/buttons.html", false, "escrow_www/templates/components/inputs.html", false, function(t_26,t_25) {
if(t_26) { cb(t_26); return; }
t_25.getExported(context.getVariables(), frame, function(t_27,t_25) {
if(t_27) { cb(t_27); return; }
context.setVariable("button", t_25);
var macro_t_28 = runtime.makeMacro(
["label", "name"], 
["required", "style", "specifier", "mutator", "hint", "link", "linkLabel", "error", "prefix", "shorten", "divider", "suffix", "dropdown", "dataComponent", "compact", "for", "errors", "hintSuggestion", "labelLink", "labelLinkText"], 
function (l_label, l_name, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("linkLabel", kwargs.hasOwnProperty("linkLabel") ? kwargs["linkLabel"] : "");
frame.set("error", kwargs.hasOwnProperty("error") ? kwargs["error"] : "Please fix the error above");
frame.set("prefix", kwargs.hasOwnProperty("prefix") ? kwargs["prefix"] : "");
frame.set("shorten", kwargs.hasOwnProperty("shorten") ? kwargs["shorten"] : "");
frame.set("divider", kwargs.hasOwnProperty("divider") ? kwargs["divider"] : "");
frame.set("suffix", kwargs.hasOwnProperty("suffix") ? kwargs["suffix"] : "");
frame.set("dropdown", kwargs.hasOwnProperty("dropdown") ? kwargs["dropdown"] : "");
frame.set("dataComponent", kwargs.hasOwnProperty("dataComponent") ? kwargs["dataComponent"] : "");
frame.set("compact", kwargs.hasOwnProperty("compact") ? kwargs["compact"] : "");
frame.set("for", kwargs.hasOwnProperty("for") ? kwargs["for"] : "");
frame.set("errors", kwargs.hasOwnProperty("errors") ? kwargs["errors"] : runtime.contextOrFrameLookup(context, frame, "None"));
frame.set("hintSuggestion", kwargs.hasOwnProperty("hintSuggestion") ? kwargs["hintSuggestion"] : runtime.contextOrFrameLookup(context, frame, "False"));
frame.set("labelLink", kwargs.hasOwnProperty("labelLink") ? kwargs["labelLink"] : "");
frame.set("labelLinkText", kwargs.hasOwnProperty("labelLinkText") ? kwargs["labelLinkText"] : "");
var t_29 = "";if(!runtime.contextOrFrameLookup(context, frame, "errors")) {
t_29 += "\n  ";
var t_30;
t_30 = [];
frame.set("errors", t_30, true);
if(frame.topLevel) {
context.setVariable("errors", t_30);
}
if(frame.topLevel) {
context.addExport("errors", t_30);
}
t_29 += "\n  ";
;
}
t_29 += "\n  <div class=\"field ";
t_29 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "specifier"), env.opts.autoescape);
t_29 += " ";
t_29 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "style"), env.opts.autoescape);
t_29 += " ";
t_29 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mutator"), env.opts.autoescape);
t_29 += " ";
if(runtime.contextOrFrameLookup(context, frame, "suffix")) {
t_29 += "field--suffix";
;
}
t_29 += " ";
if(runtime.contextOrFrameLookup(context, frame, "compact")) {
t_29 += "field--compact";
;
}
t_29 += " ";
if(runtime.contextOrFrameLookup(context, frame, "shorten")) {
t_29 += "field--minor";
;
}
t_29 += " ";
if(runtime.contextOrFrameLookup(context, frame, "divider")) {
t_29 += "field--divider";
;
}
t_29 += "\" ";
if(runtime.contextOrFrameLookup(context, frame, "for")) {
t_29 += "for=\"";
t_29 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "for"), env.opts.autoescape);
t_29 += "\"";
;
}
t_29 += "data-target=\"field\" ";
if(runtime.contextOrFrameLookup(context, frame, "dataComponent")) {
t_29 += "data-component=\"";
t_29 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "dataComponent"), env.opts.autoescape);
t_29 += "\"";
;
}
t_29 += " data-field=\"";
t_29 += runtime.suppressValue(l_name, env.opts.autoescape);
t_29 += "\">\n    ";
if(l_label) {
t_29 += runtime.suppressValue((lineno = 35, colno = 11, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_label"), "_label", context, [l_name,l_label,runtime.contextOrFrameLookup(context, frame, "required"),(lineno = 35, colno = 43, runtime.callWrap(t_8, "tickIcon", context, []))])), env.opts.autoescape);
;
}
t_29 += "\n    <div class=\"field-input\">\n      ";
if(runtime.contextOrFrameLookup(context, frame, "prefix")) {
t_29 += "<div class=\"field-prefix\">\n        <div class=\"field-prefix-wrapper\">\n          <span class=\"field-prefix-label\" data-component=\"field-prefix\" data-related-name=\"";
t_29 += runtime.suppressValue(l_name, env.opts.autoescape);
t_29 += "-prefix\">";
t_29 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "prefix"), env.opts.autoescape);
t_29 += "</span>\n        </div>\n      </div>";
;
}
t_29 += "\n      ";
t_29 += runtime.suppressValue((lineno = 45, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "caller"), "caller", context, [])), env.opts.autoescape);
t_29 += "\n      ";
if(runtime.contextOrFrameLookup(context, frame, "suffix")) {
t_29 += "<div class=\"field-prefix\" data-component=\"field-prefix\">";
t_29 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "suffix"), env.opts.autoescape);
t_29 += "</div>";
;
}
t_29 += "\n      ";
if(runtime.contextOrFrameLookup(context, frame, "dropdown")) {
t_29 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "dropdown"), env.opts.autoescape);
;
}
t_29 += "\n    </div>\n    ";
if(runtime.contextOrFrameLookup(context, frame, "hint")) {
t_29 += "<div class=\"field-hint\" id=\"hint-";
t_29 += runtime.suppressValue(l_name, env.opts.autoescape);
t_29 += "\">\n    ";
t_29 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "hint"), env.opts.autoescape);
t_29 += "\n    </div>";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "link")) {
t_29 += "\n    <a href=\"";
t_29 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "link"), env.opts.autoescape);
t_29 += "\" class=\"field-link\" tabindex=\"-1\">";
t_29 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "linkLabel"), env.opts.autoescape);
t_29 += "</a>";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "hintSuggestion")) {
t_29 += "\n    <div class=\"field-hint-suggestion is-hidden\" id=\"hint-";
t_29 += runtime.suppressValue(l_name, env.opts.autoescape);
t_29 += "\">\n      <span class=\"field-hint-errorMsg\" data-target=\"hint-message\"></span>\n    </div>";
;
}
;
}
;
}
t_29 += "\n    ";
if(runtime.contextOrFrameLookup(context, frame, "errors")) {
frame = frame.push();
var t_33 = runtime.contextOrFrameLookup(context, frame, "errors");
if(t_33) {var t_32 = t_33.length;
for(var t_31=0; t_31 < t_33.length; t_31++) {
var t_34 = t_33[t_31];
frame.set("err", t_34);
frame.set("loop.index", t_31 + 1);
frame.set("loop.index0", t_31);
frame.set("loop.revindex", t_32 - t_31);
frame.set("loop.revindex0", t_32 - t_31 - 1);
frame.set("loop.first", t_31 === 0);
frame.set("loop.last", t_31 === t_32 - 1);
frame.set("loop.length", t_32);
t_29 += "\n        <div class=\"field-error field-error--bulleted is-hidden\" data-target=\"field-error\" data-attr=\"";
t_29 += runtime.suppressValue(runtime.memberLookup((t_34),"data-attr"), env.opts.autoescape);
t_29 += "\">\n          <span class=\"field-errorMsg\" id=\"error-";
t_29 += runtime.suppressValue(l_name, env.opts.autoescape);
t_29 += "\">";
t_29 += runtime.suppressValue(runtime.memberLookup((t_34),"error"), env.opts.autoescape);
t_29 += "</span>\n        </div>\n      ";
;
}
}
frame = frame.pop();
;
}
t_29 += "\n    ";
if(runtime.contextOrFrameLookup(context, frame, "error")) {
t_29 += "<div class=\"field-error\" data-target=\"field-error\" data-attr=\"error-invalid\">\n        <span class=\"field-errorMsg\" id=\"error-";
t_29 += runtime.suppressValue(l_name, env.opts.autoescape);
t_29 += "\">";
t_29 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "error"), env.opts.autoescape);
t_29 += "</span>\n      </div>\n    ";
;
}
t_29 += "\n  </div>";
;
frame = frame.pop();
return new runtime.SafeString(t_29);
});
context.setVariable("_field", macro_t_28);
output += "\n\n";
var macro_t_35 = runtime.makeMacro(
["name", "label", "required", "validIcon", "labelLink", "labelLinkText"], 
[], 
function (l_name, l_label, l_required, l_validIcon, l_labelLink, l_labelLinkText, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("name", l_name);
frame.set("label", l_label);
frame.set("required", l_required);
frame.set("validIcon", l_validIcon);
frame.set("labelLink", l_labelLink);
frame.set("labelLinkText", l_labelLinkText);
var t_36 = "";t_36 += "\n<label class=\"field-label\" for=\"field-";
t_36 += runtime.suppressValue(l_name, env.opts.autoescape);
t_36 += "\">\n  <span data-target=\"field-label-";
t_36 += runtime.suppressValue(l_name, env.opts.autoescape);
t_36 += "\">";
t_36 += runtime.suppressValue(l_label, env.opts.autoescape);
t_36 += "</span>";
if(l_required) {
t_36 += "<sup class=\"field-required\"> *</sup>";
;
}
t_36 += "\n  <span class=\"field-validIcon\">";
t_36 += runtime.suppressValue(l_validIcon, env.opts.autoescape);
t_36 += "</span>\n  ";
if(l_labelLink) {
t_36 += "\n    <a href=\"";
t_36 += runtime.suppressValue(l_labelLink, env.opts.autoescape);
t_36 += "\" class=\"field-label-link\">";
t_36 += runtime.suppressValue(l_labelLinkText, env.opts.autoescape);
t_36 += "</a>\n  ";
;
}
t_36 += "\n</label>";
;
frame = frame.pop();
return new runtime.SafeString(t_36);
});
context.setVariable("_label", macro_t_35);
output += "\n\n";
var macro_t_37 = runtime.makeMacro(
["name", "label", "required", "validIcon", "labelLink", "labelLinkText"], 
[], 
function (l_name, l_label, l_required, l_validIcon, l_labelLink, l_labelLinkText, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("name", l_name);
frame.set("label", l_label);
frame.set("required", l_required);
frame.set("validIcon", l_validIcon);
frame.set("labelLink", l_labelLink);
frame.set("labelLinkText", l_labelLinkText);
var t_38 = "";t_38 += "\n  ";
t_38 += runtime.suppressValue((lineno = 92, colno = 9, runtime.callWrap(macro_t_35, "_label", context, [l_name,l_label,l_required,l_validIcon,l_labelLink,l_labelLinkText])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_38);
});
context.addExport("label");
context.setVariable("label", macro_t_37);
output += "\n\n<!- Select -->\n\n";
var macro_t_39 = runtime.makeMacro(
["label", "name", "options"], 
["required", "specifier", "placeholder", "flag", "selected", "dataComponent", "related", "attrs", "disabled"], 
function (l_label, l_name, l_options, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("options", l_options);
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("placeholder", kwargs.hasOwnProperty("placeholder") ? kwargs["placeholder"] : "");
frame.set("flag", kwargs.hasOwnProperty("flag") ? kwargs["flag"] : "");
frame.set("selected", kwargs.hasOwnProperty("selected") ? kwargs["selected"] : "");
frame.set("dataComponent", kwargs.hasOwnProperty("dataComponent") ? kwargs["dataComponent"] : "");
frame.set("related", kwargs.hasOwnProperty("related") ? kwargs["related"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("disabled", kwargs.hasOwnProperty("disabled") ? kwargs["disabled"] : runtime.contextOrFrameLookup(context, frame, "False"));
var t_40 = "";t_40 += "<div class=\"defaultSelect ";
t_40 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "specifier"), env.opts.autoescape);
t_40 += " ";
if(runtime.contextOrFrameLookup(context, frame, "flag")) {
t_40 += "defaultSelect--flags";
;
}
if(runtime.contextOrFrameLookup(context, frame, "disabled")) {
t_40 += "is-disabled";
;
}
t_40 += "\">\n";
if(runtime.contextOrFrameLookup(context, frame, "flag")) {
t_40 += "<span class=\"defaultSelect-flag\" data-select-icon=\"";
t_40 += runtime.suppressValue(l_name, env.opts.autoescape);
t_40 += "\" ";
if(runtime.contextOrFrameLookup(context, frame, "selected")) {
t_40 += " data-select-value=\"";
t_40 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "selected"), env.opts.autoescape);
t_40 += "\"";
;
}
t_40 += "></span>";
;
}
t_40 += "\n  <select class=\"defaultSelect-select\" data-target=\"field-focusable\" name=\"";
t_40 += runtime.suppressValue(l_name, env.opts.autoescape);
t_40 += "\" id=\"field-";
t_40 += runtime.suppressValue(l_name, env.opts.autoescape);
t_40 += "\"\n    ";
if(runtime.contextOrFrameLookup(context, frame, "dataComponent")) {
t_40 += " data-component=\"";
t_40 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "dataComponent"), env.opts.autoescape);
t_40 += "\"";
;
}
t_40 += "\n    ";
if(runtime.contextOrFrameLookup(context, frame, "required")) {
t_40 += " required";
;
}
t_40 += "\n    ";
if(runtime.contextOrFrameLookup(context, frame, "related")) {
t_40 += " data-related=\"";
t_40 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "related"), env.opts.autoescape);
t_40 += "\"";
;
}
t_40 += "\n    ";
if(runtime.contextOrFrameLookup(context, frame, "selected")) {
t_40 += " selected=\"";
t_40 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "selected"), env.opts.autoescape);
t_40 += "\"";
;
}
t_40 += "\n    ";
t_40 += runtime.suppressValue(env.getFilter("xmlattr").call(context, runtime.contextOrFrameLookup(context, frame, "attrs")), env.opts.autoescape);
if(runtime.contextOrFrameLookup(context, frame, "disabled")) {
t_40 += " disabled";
;
}
t_40 += " >\n      ";
if(runtime.contextOrFrameLookup(context, frame, "placeholder")) {
t_40 += "<option value=\"---\" ";
if((runtime.contextOrFrameLookup(context, frame, "selected") == "") || (!runtime.inOperator(runtime.contextOrFrameLookup(context, frame, "selected"),env.getFilter("map").call(context, l_options,runtime.makeKeywordArgs({"attribute": "value"}))))) {
t_40 += "selected";
;
}
t_40 += " disabled>";
t_40 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "placeholder"), env.opts.autoescape);
t_40 += "</option>";
;
}
t_40 += "\n      ";
frame = frame.push();
var t_43 = l_options;
if(t_43) {var t_42 = t_43.length;
for(var t_41=0; t_41 < t_43.length; t_41++) {
var t_44 = t_43[t_41];
frame.set("option", t_44);
frame.set("loop.index", t_41 + 1);
frame.set("loop.index0", t_41);
frame.set("loop.revindex", t_42 - t_41);
frame.set("loop.revindex0", t_42 - t_41 - 1);
frame.set("loop.first", t_41 === 0);
frame.set("loop.last", t_41 === t_42 - 1);
frame.set("loop.length", t_42);
t_40 += "\n        <option value=\"";
t_40 += runtime.suppressValue(runtime.memberLookup((t_44),"value"), env.opts.autoescape);
t_40 += "\" ";
if(runtime.memberLookup((t_44),"value") == runtime.contextOrFrameLookup(context, frame, "selected")) {
t_40 += " selected";
;
}
t_40 += ">";
t_40 += runtime.suppressValue(runtime.memberLookup((t_44),"name"), env.opts.autoescape);
t_40 += "</option>\n      ";
;
}
}
frame = frame.pop();
t_40 += "\n  </select>";
if(runtime.contextOrFrameLookup(context, frame, "disabled")) {
t_40 += "<span class=\"field-input-disabledIcon\">";
t_40 += runtime.suppressValue((lineno = 117, colno = 54, runtime.callWrap(t_16, "secureIcon", context, [])), env.opts.autoescape);
t_40 += "</span>\n  ";
;
}
t_40 += "</div>";
;
frame = frame.pop();
return new runtime.SafeString(t_40);
});
context.setVariable("_select", macro_t_39);
output += "\n\n";
var macro_t_45 = runtime.makeMacro(
["label", "name", "options"], 
["required", "specifier", "placeholder", "flag", "selected", "dataComponent", "related", "attrs", "disabled"], 
function (l_label, l_name, l_options, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("options", l_options);
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("placeholder", kwargs.hasOwnProperty("placeholder") ? kwargs["placeholder"] : "");
frame.set("flag", kwargs.hasOwnProperty("flag") ? kwargs["flag"] : "");
frame.set("selected", kwargs.hasOwnProperty("selected") ? kwargs["selected"] : "");
frame.set("dataComponent", kwargs.hasOwnProperty("dataComponent") ? kwargs["dataComponent"] : "");
frame.set("related", kwargs.hasOwnProperty("related") ? kwargs["related"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("disabled", kwargs.hasOwnProperty("disabled") ? kwargs["disabled"] : runtime.contextOrFrameLookup(context, frame, "False"));
var t_46 = "";t_46 += "<div class=\"defaultSelect ";
t_46 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "specifier"), env.opts.autoescape);
t_46 += " ";
if(runtime.contextOrFrameLookup(context, frame, "flag")) {
t_46 += "defaultSelect--flags";
;
}
if(runtime.contextOrFrameLookup(context, frame, "disabled")) {
t_46 += "is-disabled";
;
}
t_46 += "\" data-target=\"select-wrapper\">\n  ";
if(runtime.contextOrFrameLookup(context, frame, "flag")) {
t_46 += "<span class=\"defaultSelect-flag\" data-select-icon=\"";
t_46 += runtime.suppressValue(l_name, env.opts.autoescape);
t_46 += "\" ";
if(runtime.contextOrFrameLookup(context, frame, "selected")) {
t_46 += " data-select-value=\"";
t_46 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "selected"), env.opts.autoescape);
t_46 += "\"";
;
}
t_46 += "></span>";
;
}
t_46 += "\n  <select class=\"defaultSelect-select\" data-target=\"field-focusable\" name=\"";
t_46 += runtime.suppressValue(l_name, env.opts.autoescape);
t_46 += "\" id=\"field-";
t_46 += runtime.suppressValue(l_name, env.opts.autoescape);
t_46 += "\"\n    ";
if(runtime.contextOrFrameLookup(context, frame, "dataComponent")) {
t_46 += " data-component=\"";
t_46 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "dataComponent"), env.opts.autoescape);
t_46 += "\"";
;
}
t_46 += "\n    ";
if(runtime.contextOrFrameLookup(context, frame, "required")) {
t_46 += " required";
;
}
t_46 += "\n    ";
if(runtime.contextOrFrameLookup(context, frame, "related")) {
t_46 += " data-related=\"";
t_46 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "related"), env.opts.autoescape);
t_46 += "\"";
;
}
t_46 += "\n    ";
if(runtime.contextOrFrameLookup(context, frame, "selected")) {
t_46 += " selected=\"";
t_46 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "selected"), env.opts.autoescape);
t_46 += "\"";
;
}
t_46 += "\n    ";
t_46 += runtime.suppressValue(env.getFilter("xmlattr").call(context, runtime.contextOrFrameLookup(context, frame, "attrs")), env.opts.autoescape);
if(runtime.contextOrFrameLookup(context, frame, "disabled")) {
t_46 += " disabled";
;
}
t_46 += " >\n      ";
if(runtime.contextOrFrameLookup(context, frame, "placeholder")) {
t_46 += "<option value=\"---\" ";
if((runtime.contextOrFrameLookup(context, frame, "selected") == "") || (!runtime.inOperator(runtime.contextOrFrameLookup(context, frame, "selected"),l_options))) {
t_46 += "selected";
;
}
t_46 += " disabled>";
t_46 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "placeholder"), env.opts.autoescape);
t_46 += "</option>";
;
}
t_46 += "\n      ";
frame = frame.push();
var t_49 = l_options;
if(t_49) {var t_48 = t_49.length;
for(var t_47=0; t_47 < t_49.length; t_47++) {
var t_50 = t_49[t_47];
frame.set("option", t_50);
frame.set("loop.index", t_47 + 1);
frame.set("loop.index0", t_47);
frame.set("loop.revindex", t_48 - t_47);
frame.set("loop.revindex0", t_48 - t_47 - 1);
frame.set("loop.first", t_47 === 0);
frame.set("loop.last", t_47 === t_48 - 1);
frame.set("loop.length", t_48);
t_46 += "\n        <option value=\"";
t_46 += runtime.suppressValue(runtime.memberLookup((t_50),"alpha3"), env.opts.autoescape);
t_46 += "\" ";
if(t_50 == runtime.contextOrFrameLookup(context, frame, "selected")) {
t_46 += " selected";
;
}
t_46 += ">";
t_46 += runtime.suppressValue(runtime.memberLookup((t_50),"name"), env.opts.autoescape);
t_46 += " (";
t_46 += runtime.suppressValue(runtime.memberLookup((t_50),"alpha2"), env.opts.autoescape);
t_46 += ")</option>\n      ";
;
}
}
frame = frame.pop();
t_46 += "\n  </select>";
if(runtime.contextOrFrameLookup(context, frame, "disabled")) {
t_46 += "<span class=\"field-input-disabledIcon\">";
t_46 += runtime.suppressValue((lineno = 142, colno = 54, runtime.callWrap(t_16, "secureIcon", context, [])), env.opts.autoescape);
t_46 += "</span>\n  ";
;
}
t_46 += "</div>";
;
frame = frame.pop();
return new runtime.SafeString(t_46);
});
context.setVariable("_selectVatCountry", macro_t_45);
output += "\n\n";
var macro_t_51 = runtime.makeMacro(
["label", "name", "options"], 
["required", "specifier", "placeholder", "flag", "selected", "related", "attrs", "dataComponent"], 
function (l_label, l_name, l_options, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("options", l_options);
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("placeholder", kwargs.hasOwnProperty("placeholder") ? kwargs["placeholder"] : "");
frame.set("flag", kwargs.hasOwnProperty("flag") ? kwargs["flag"] : "");
frame.set("selected", kwargs.hasOwnProperty("selected") ? kwargs["selected"] : "");
frame.set("related", kwargs.hasOwnProperty("related") ? kwargs["related"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("dataComponent", kwargs.hasOwnProperty("dataComponent") ? kwargs["dataComponent"] : "");
var t_52 = "";t_52 += "<div class=\"defaultSelect ";
t_52 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "specifier"), env.opts.autoescape);
t_52 += " ";
if(runtime.contextOrFrameLookup(context, frame, "flag")) {
t_52 += "defaultSelect--flags";
;
}
t_52 += "\" ";
if(runtime.contextOrFrameLookup(context, frame, "dataComponent")) {
t_52 += "data-component=\"";
t_52 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "dataComponent"), env.opts.autoescape);
t_52 += "\"";
;
}
t_52 += " id=\"";
t_52 += runtime.suppressValue(l_name, env.opts.autoescape);
t_52 += "\">\n  ";
if(runtime.contextOrFrameLookup(context, frame, "flag")) {
t_52 += "<span class=\"defaultSelect-flag\" data-select-icon=\"";
t_52 += runtime.suppressValue(l_name, env.opts.autoescape);
t_52 += "\" ";
if(runtime.contextOrFrameLookup(context, frame, "selected")) {
t_52 += " data-select-value=\"";
t_52 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "selected"), env.opts.autoescape);
t_52 += "\"";
;
}
t_52 += "></span>";
;
}
t_52 += "\n  <input type=\"hidden\" name=\"";
t_52 += runtime.suppressValue(l_name, env.opts.autoescape);
t_52 += "-prefix\" value=\"\" />\n  <select class=\"defaultSelect-select\" data-target=\"field-focusable\" name=\"";
t_52 += runtime.suppressValue(l_name, env.opts.autoescape);
t_52 += "\" ";
if(runtime.contextOrFrameLookup(context, frame, "required")) {
t_52 += "required";
;
}
t_52 += " ";
if(runtime.contextOrFrameLookup(context, frame, "related")) {
t_52 += "data-related=\"";
t_52 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "related"), env.opts.autoescape);
t_52 += "\"";
;
}
t_52 += "data-component=\"phone-prefix-field\" ";
t_52 += runtime.suppressValue(env.getFilter("xmlattr").call(context, runtime.contextOrFrameLookup(context, frame, "attrs")), env.opts.autoescape);
t_52 += " >\n      ";
frame = frame.push();
var t_55 = l_options;
if(t_55) {var t_54 = t_55.length;
for(var t_53=0; t_53 < t_55.length; t_53++) {
var t_56 = t_55[t_53];
frame.set("option", t_56);
frame.set("loop.index", t_53 + 1);
frame.set("loop.index0", t_53);
frame.set("loop.revindex", t_54 - t_53);
frame.set("loop.revindex0", t_54 - t_53 - 1);
frame.set("loop.first", t_53 === 0);
frame.set("loop.last", t_53 === t_54 - 1);
frame.set("loop.length", t_54);
t_52 += "\n        ";
if(runtime.memberLookup((t_56),"value") != "") {
t_52 += "<option value=\"";
t_52 += runtime.suppressValue(runtime.memberLookup((t_56),"code"), env.opts.autoescape);
t_52 += "\" data-country=\"";
t_52 += runtime.suppressValue(runtime.memberLookup((t_56),"code"), env.opts.autoescape);
t_52 += "\" data-prefix=\"+";
t_52 += runtime.suppressValue(runtime.memberLookup((t_56),"phoneExt"), env.opts.autoescape);
t_52 += "\" ";
if(runtime.memberLookup((t_56),"code") == runtime.contextOrFrameLookup(context, frame, "selected")) {
t_52 += " selected";
;
}
t_52 += ">";
t_52 += runtime.suppressValue(runtime.memberLookup((t_56),"name"), env.opts.autoescape);
t_52 += " +";
t_52 += runtime.suppressValue(runtime.memberLookup((t_56),"phoneExt"), env.opts.autoescape);
t_52 += "</option>";
;
}
t_52 += "\n      ";
;
}
}
frame = frame.pop();
t_52 += "\n  </select>\n</div>";
;
frame = frame.pop();
return new runtime.SafeString(t_52);
});
context.setVariable("_selectPhoneCountry", macro_t_51);
output += "\n\n";
var macro_t_57 = runtime.makeMacro(
["label", "name", "options"], 
["required", "style", "specifier", "mutator", "placeholder", "hint", "flag", "selected", "shorten", "dataComponent", "related", "attrs", "error", "prefix", "suffix", "disabled"], 
function (l_label, l_name, l_options, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("options", l_options);
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("placeholder", kwargs.hasOwnProperty("placeholder") ? kwargs["placeholder"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "");
frame.set("flag", kwargs.hasOwnProperty("flag") ? kwargs["flag"] : "");
frame.set("selected", kwargs.hasOwnProperty("selected") ? kwargs["selected"] : "");
frame.set("shorten", kwargs.hasOwnProperty("shorten") ? kwargs["shorten"] : "");
frame.set("dataComponent", kwargs.hasOwnProperty("dataComponent") ? kwargs["dataComponent"] : "");
frame.set("related", kwargs.hasOwnProperty("related") ? kwargs["related"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("error", kwargs.hasOwnProperty("error") ? kwargs["error"] : "");
frame.set("prefix", kwargs.hasOwnProperty("prefix") ? kwargs["prefix"] : "");
frame.set("suffix", kwargs.hasOwnProperty("suffix") ? kwargs["suffix"] : "");
frame.set("disabled", kwargs.hasOwnProperty("disabled") ? kwargs["disabled"] : runtime.contextOrFrameLookup(context, frame, "False"));
var t_58 = "";t_58 += runtime.suppressValue((lineno = 164, colno = 12, runtime.callWrap(macro_t_28, "_field", context, [l_label,l_name,runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "style"),runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutator"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "link"),runtime.contextOrFrameLookup(context, frame, "linkLabel"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.contextOrFrameLookup(context, frame, "prefix"),runtime.contextOrFrameLookup(context, frame, "shorten"),runtime.contextOrFrameLookup(context, frame, "divider"),runtime.contextOrFrameLookup(context, frame, "suffix"),runtime.makeKeywordArgs({"caller": (function (){var macro_t_59 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_60 = "";t_60 += "\n";
t_60 += runtime.suppressValue((lineno = 165, colno = 8, runtime.callWrap(macro_t_39, "_select", context, [l_label,l_name,l_options,runtime.contextOrFrameLookup(context, frame, "required"),"defaultSelect--form",runtime.makeKeywordArgs({"placeholder": runtime.contextOrFrameLookup(context, frame, "placeholder"),"flag": runtime.contextOrFrameLookup(context, frame, "flag"),"selected": runtime.contextOrFrameLookup(context, frame, "selected"),"dataComponent": runtime.contextOrFrameLookup(context, frame, "dataComponent"),"related": runtime.contextOrFrameLookup(context, frame, "related"),"attrs": runtime.contextOrFrameLookup(context, frame, "attrs"),"disabled": runtime.contextOrFrameLookup(context, frame, "disabled")})])), env.opts.autoescape);
t_60 += "\n";
;
frame = frame.pop();
return new runtime.SafeString(t_60);
});
return macro_t_59;})()})])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_58);
});
context.addExport("formSelect");
context.setVariable("formSelect", macro_t_57);
output += "\n\n";
var macro_t_61 = runtime.makeMacro(
["label", "name", "options"], 
["required", "style", "specifier", "mutator", "placeholder", "hint", "flag", "selected", "shorten", "dataComponent", "attrs", "error", "prefix", "suffix", "hintSuggestion", "phoneVerification", "labelLink", "labelLinkText", "btnSpecifier", "prefixSpecifier"], 
function (l_label, l_name, l_options, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("options", l_options);
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("placeholder", kwargs.hasOwnProperty("placeholder") ? kwargs["placeholder"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "");
frame.set("flag", kwargs.hasOwnProperty("flag") ? kwargs["flag"] : "");
frame.set("selected", kwargs.hasOwnProperty("selected") ? kwargs["selected"] : "");
frame.set("shorten", kwargs.hasOwnProperty("shorten") ? kwargs["shorten"] : "");
frame.set("dataComponent", kwargs.hasOwnProperty("dataComponent") ? kwargs["dataComponent"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("error", kwargs.hasOwnProperty("error") ? kwargs["error"] : "");
frame.set("prefix", kwargs.hasOwnProperty("prefix") ? kwargs["prefix"] : "");
frame.set("suffix", kwargs.hasOwnProperty("suffix") ? kwargs["suffix"] : "");
frame.set("hintSuggestion", kwargs.hasOwnProperty("hintSuggestion") ? kwargs["hintSuggestion"] : runtime.contextOrFrameLookup(context, frame, "False"));
frame.set("phoneVerification", kwargs.hasOwnProperty("phoneVerification") ? kwargs["phoneVerification"] : runtime.contextOrFrameLookup(context, frame, "False"));
frame.set("labelLink", kwargs.hasOwnProperty("labelLink") ? kwargs["labelLink"] : "");
frame.set("labelLinkText", kwargs.hasOwnProperty("labelLinkText") ? kwargs["labelLinkText"] : "");
frame.set("btnSpecifier", kwargs.hasOwnProperty("btnSpecifier") ? kwargs["btnSpecifier"] : "");
frame.set("prefixSpecifier", kwargs.hasOwnProperty("prefixSpecifier") ? kwargs["prefixSpecifier"] : "");
var t_62 = "";t_62 += runtime.suppressValue((lineno = 170, colno = 7, runtime.callWrap(macro_t_35, "_label", context, [l_name,l_label,runtime.contextOrFrameLookup(context, frame, "required"),(lineno = 170, colno = 39, runtime.callWrap(t_8, "tickIcon", context, [])),runtime.makeKeywordArgs({"labelLink": runtime.contextOrFrameLookup(context, frame, "labelLink"),"labelLinkText": runtime.contextOrFrameLookup(context, frame, "labelLinkText")})])), env.opts.autoescape);
t_62 += "\n<div class=\"defaultForm-group defaultForm-group--mobile ";
t_62 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "specifier"), env.opts.autoescape);
t_62 += "\" data-phone-with-prefix=\"";
t_62 += runtime.suppressValue(l_name, env.opts.autoescape);
t_62 += "\">\n  ";
t_62 += runtime.suppressValue((lineno = 172, colno = 14, runtime.callWrap(macro_t_28, "_field", context, ["",l_name + "-country",runtime.makeKeywordArgs({"required": runtime.contextOrFrameLookup(context, frame, "required"),"style": runtime.contextOrFrameLookup(context, frame, "style"),"specifier": "field--phonePrefix " + runtime.contextOrFrameLookup(context, frame, "prefixSpecifier"),"mutator": runtime.contextOrFrameLookup(context, frame, "mutator"),"hint": runtime.contextOrFrameLookup(context, frame, "hint"),"error": runtime.contextOrFrameLookup(context, frame, "error"),"prefix": runtime.contextOrFrameLookup(context, frame, "prefix"),"shorten": runtime.contextOrFrameLookup(context, frame, "shorten"),"divider": runtime.contextOrFrameLookup(context, frame, "divider"),"suffix": runtime.contextOrFrameLookup(context, frame, "suffix"),"caller": (function (){var macro_t_63 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_64 = "";t_64 += "\n  ";
t_64 += runtime.suppressValue((lineno = 173, colno = 22, runtime.callWrap(macro_t_51, "_selectPhoneCountry", context, ["",l_name + "-country",l_options,true,"defaultSelect--form defaultSelect--phonePrefix",runtime.contextOrFrameLookup(context, frame, "placeholder"),runtime.contextOrFrameLookup(context, frame, "flag"),runtime.contextOrFrameLookup(context, frame, "selected"),l_name,runtime.contextOrFrameLookup(context, frame, "attrs"),"phone-prefix-select"])), env.opts.autoescape);
t_64 += "\n  ";
;
frame = frame.pop();
return new runtime.SafeString(t_64);
});
return macro_t_63;})()})])), env.opts.autoescape);
t_62 += "\n  ";
t_62 += runtime.suppressValue((lineno = 175, colno = 6, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "tel"), "tel", context, ["",l_name,runtime.makeKeywordArgs({"hintSuggestion": runtime.contextOrFrameLookup(context, frame, "hintSuggestion"),"placeholder": runtime.contextOrFrameLookup(context, frame, "placeholder"),"attrs": {"data-has-prefix": "true","data-e2e-target": "phone-number-field"},"specifier": "phoneVerify-input","required": runtime.contextOrFrameLookup(context, frame, "required"),"error": runtime.contextOrFrameLookup(context, frame, "error")})])), env.opts.autoescape);
t_62 += "\n  ";
if(runtime.contextOrFrameLookup(context, frame, "phoneVerification")) {
t_62 += runtime.suppressValue((lineno = 178, colno = 20, runtime.callWrap(runtime.memberLookup((t_25),"primary"), "button[\"primary\"]", context, [(lineno = 178, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Send Code"])),runtime.makeKeywordArgs({"specifier": runtime.contextOrFrameLookup(context, frame, "btnSpecifier"),"attrs": {"data-target": "send-otp-code","data-related": "Phone"},"type": "button"})])), env.opts.autoescape);
;
}
t_62 += "\n</div>";
;
frame = frame.pop();
return new runtime.SafeString(t_62);
});
context.addExport("phoneWithPrefix");
context.setVariable("phoneWithPrefix", macro_t_61);
output += "\n\n";
var macro_t_65 = runtime.makeMacro(
["label", "name", "options"], 
["required", "specifier", "placeholder", "hint", "prefix", "flag", "selected", "related", "shorten", "attrs"], 
function (l_label, l_name, l_options, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("options", l_options);
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("placeholder", kwargs.hasOwnProperty("placeholder") ? kwargs["placeholder"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "");
frame.set("prefix", kwargs.hasOwnProperty("prefix") ? kwargs["prefix"] : "");
frame.set("flag", kwargs.hasOwnProperty("flag") ? kwargs["flag"] : "");
frame.set("selected", kwargs.hasOwnProperty("selected") ? kwargs["selected"] : "");
frame.set("related", kwargs.hasOwnProperty("related") ? kwargs["related"] : "");
frame.set("shorten", kwargs.hasOwnProperty("shorten") ? kwargs["shorten"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
var t_66 = "";t_66 += runtime.suppressValue((lineno = 190, colno = 8, runtime.callWrap(macro_t_39, "_select", context, [l_label,l_name,l_options,runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "placeholder"),runtime.contextOrFrameLookup(context, frame, "flag"),runtime.contextOrFrameLookup(context, frame, "selected"),runtime.contextOrFrameLookup(context, frame, "related"),runtime.contextOrFrameLookup(context, frame, "shorten"),runtime.contextOrFrameLookup(context, frame, "attrs")])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_66);
});
context.addExport("defaultSelect");
context.setVariable("defaultSelect", macro_t_65);
output += "\n\n";
var macro_t_67 = runtime.makeMacro(
["label", "name", "options"], 
["required", "specifier", "placeholder", "hint", "prefix", "flag", "selected", "related", "shorten", "attrs"], 
function (l_label, l_name, l_options, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("options", l_options);
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("placeholder", kwargs.hasOwnProperty("placeholder") ? kwargs["placeholder"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "");
frame.set("prefix", kwargs.hasOwnProperty("prefix") ? kwargs["prefix"] : "");
frame.set("flag", kwargs.hasOwnProperty("flag") ? kwargs["flag"] : "");
frame.set("selected", kwargs.hasOwnProperty("selected") ? kwargs["selected"] : "");
frame.set("related", kwargs.hasOwnProperty("related") ? kwargs["related"] : "");
frame.set("shorten", kwargs.hasOwnProperty("shorten") ? kwargs["shorten"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
var t_68 = "";t_68 += runtime.suppressValue((lineno = 194, colno = 8, runtime.callWrap(macro_t_39, "_select", context, [l_label,l_name,l_options,runtime.contextOrFrameLookup(context, frame, "required"),"defaultSelect--invert",runtime.contextOrFrameLookup(context, frame, "placeholder"),runtime.contextOrFrameLookup(context, frame, "flag"),runtime.contextOrFrameLookup(context, frame, "selected"),runtime.contextOrFrameLookup(context, frame, "related"),runtime.contextOrFrameLookup(context, frame, "shorten"),runtime.contextOrFrameLookup(context, frame, "attrs")])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_68);
});
context.addExport("invertSelect");
context.setVariable("invertSelect", macro_t_67);
output += "\n\n";
var macro_t_69 = runtime.makeMacro(
["label", "name", "options"], 
["required", "style", "specifier", "mutator", "placeholder", "hint", "flag", "selected", "shorten", "dataComponent", "related", "attrs", "error", "prefix", "suffix", "disabled"], 
function (l_label, l_name, l_options, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("options", l_options);
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("placeholder", kwargs.hasOwnProperty("placeholder") ? kwargs["placeholder"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "");
frame.set("flag", kwargs.hasOwnProperty("flag") ? kwargs["flag"] : "");
frame.set("selected", kwargs.hasOwnProperty("selected") ? kwargs["selected"] : "");
frame.set("shorten", kwargs.hasOwnProperty("shorten") ? kwargs["shorten"] : "");
frame.set("dataComponent", kwargs.hasOwnProperty("dataComponent") ? kwargs["dataComponent"] : "");
frame.set("related", kwargs.hasOwnProperty("related") ? kwargs["related"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("error", kwargs.hasOwnProperty("error") ? kwargs["error"] : "");
frame.set("prefix", kwargs.hasOwnProperty("prefix") ? kwargs["prefix"] : "");
frame.set("suffix", kwargs.hasOwnProperty("suffix") ? kwargs["suffix"] : "");
frame.set("disabled", kwargs.hasOwnProperty("disabled") ? kwargs["disabled"] : runtime.contextOrFrameLookup(context, frame, "False"));
var t_70 = "";t_70 += runtime.suppressValue((lineno = 198, colno = 12, runtime.callWrap(macro_t_28, "_field", context, [l_label,l_name,runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "style"),runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutator"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "link"),runtime.contextOrFrameLookup(context, frame, "linkLabel"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.contextOrFrameLookup(context, frame, "prefix"),runtime.contextOrFrameLookup(context, frame, "shorten"),runtime.contextOrFrameLookup(context, frame, "divider"),runtime.contextOrFrameLookup(context, frame, "suffix"),runtime.makeKeywordArgs({"caller": (function (){var macro_t_71 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_72 = "";t_72 += "\n";
t_72 += runtime.suppressValue((lineno = 199, colno = 18, runtime.callWrap(macro_t_45, "_selectVatCountry", context, [l_label,l_name,l_options,runtime.contextOrFrameLookup(context, frame, "required"),"defaultSelect--form",runtime.makeKeywordArgs({"placeholder": runtime.contextOrFrameLookup(context, frame, "placeholder"),"flag": runtime.contextOrFrameLookup(context, frame, "flag"),"selected": runtime.contextOrFrameLookup(context, frame, "selected"),"dataComponent": runtime.contextOrFrameLookup(context, frame, "dataComponent"),"related": runtime.contextOrFrameLookup(context, frame, "related"),"attrs": runtime.contextOrFrameLookup(context, frame, "attrs"),"disabled": runtime.contextOrFrameLookup(context, frame, "disabled")})])), env.opts.autoescape);
t_72 += "\n";
;
frame = frame.pop();
return new runtime.SafeString(t_72);
});
return macro_t_71;})()})])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_70);
});
context.addExport("vatCountrySelect");
context.setVariable("vatCountrySelect", macro_t_69);
output += "\n\n";
var macro_t_73 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_74 = "";t_74 += "\n";
t_74 += runtime.suppressValue((lineno = 205, colno = 6, runtime.callWrap(macro_t_37, "label", context, ["","VAT Information",runtime.makeKeywordArgs({"required": false,"validIcon": (lineno = 205, colno = 64, runtime.callWrap(t_8, "tickIcon", context, [])),"labelLink": "","labelLinkText": ""})])), env.opts.autoescape);
t_74 += "\n<div class=\"defaultForm-group ";
t_74 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "specifier"), env.opts.autoescape);
t_74 += "\">\n  ";
t_74 += runtime.suppressValue((lineno = 207, colno = 19, runtime.callWrap(macro_t_69, "vatCountrySelect", context, ["",runtime.makeKeywordArgs({"name": "vatCountry","specifier": "field--minor","flag": "true","required": false,"options": runtime.contextOrFrameLookup(context, frame, "alpha2_vat_countries"),"selected": "","error": (lineno = 209, colno = 64, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["This country does use VAT numbers"])),"attrs": {"data-e2e-target": "vat-country-dropdown","data-field-type": "country"},"placeholder": (lineno = 211, colno = 27, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["-- Country Code --"]))})])), env.opts.autoescape);
t_74 += "\n  ";
t_74 += runtime.suppressValue((lineno = 214, colno = 13, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"text"), "input[\"text\"]", context, ["",runtime.makeKeywordArgs({"name": "vatNumber","value": "","placeholder": (lineno = 215, colno = 57, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Please enter a VAT number"])),"required": false,"error": (lineno = 216, colno = 35, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["VAT number is invalid"])),"attrs": {"data-max-length": 30,"data-e2e-target": "vat-number-field"}})])), env.opts.autoescape);
t_74 += "\n  ";
t_74 += runtime.suppressValue((lineno = 220, colno = 18, runtime.callWrap(runtime.memberLookup((t_25),"primary"), "button[\"primary\"]", context, [(lineno = 220, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Verify VAT Number"])),runtime.makeKeywordArgs({"specifier": "phoneVerify-btn","icon": (lineno = 222, colno = 17, runtime.callWrap(t_8, "tickIcon", context, [])),"attrs": {"data-target": "verify-vat-number","data-related": "vatNumber"},"type": "button"})])), env.opts.autoescape);
t_74 += "\n</div>\n";
;
frame = frame.pop();
return new runtime.SafeString(t_74);
});
context.addExport("vatWithCountryPrefix");
context.setVariable("vatWithCountryPrefix", macro_t_73);
output += "\n\n<!- Radio -->\n\n";
var macro_t_75 = runtime.makeMacro(
["label", "name", "options"], 
["required", "specifier", "mutator", "hint", "error", "checked", "inline", "rating", "divider", "attrs"], 
function (l_label, l_name, l_options, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("options", l_options);
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "");
frame.set("error", kwargs.hasOwnProperty("error") ? kwargs["error"] : "");
frame.set("checked", kwargs.hasOwnProperty("checked") ? kwargs["checked"] : "");
frame.set("inline", kwargs.hasOwnProperty("inline") ? kwargs["inline"] : "");
frame.set("rating", kwargs.hasOwnProperty("rating") ? kwargs["rating"] : "");
frame.set("divider", kwargs.hasOwnProperty("divider") ? kwargs["divider"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
var t_76 = "";t_76 += runtime.suppressValue((lineno = 234, colno = 12, runtime.callWrap(macro_t_28, "_field", context, [l_label,l_name,runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutator"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.makeKeywordArgs({"prefix": "","shorten": "","divider": "","caller": (function (){var macro_t_77 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_78 = "";t_78 += "\n";
if(runtime.contextOrFrameLookup(context, frame, "rating")) {
t_78 += "<div class=\"defaultRadio-rating\" data-target=\"rating-field\">";
;
}
t_78 += "\n";
frame = frame.push();
var t_81 = l_options;
if(t_81) {var t_80 = t_81.length;
for(var t_79=0; t_79 < t_81.length; t_79++) {
var t_82 = t_81[t_79];
frame.set("option", t_82);
frame.set("loop.index", t_79 + 1);
frame.set("loop.index0", t_79);
frame.set("loop.revindex", t_80 - t_79);
frame.set("loop.revindex0", t_80 - t_79 - 1);
frame.set("loop.first", t_79 === 0);
frame.set("loop.last", t_79 === t_80 - 1);
frame.set("loop.length", t_80);
t_78 += "\n  <label class=\"defaultRadio\n    ";
if(runtime.contextOrFrameLookup(context, frame, "inline")) {
t_78 += " defaultRadio--inline";
;
}
t_78 += "\n    ";
if(runtime.contextOrFrameLookup(context, frame, "rating")) {
t_78 += " defaultRadio--rating";
;
}
t_78 += "\" ";
if(runtime.contextOrFrameLookup(context, frame, "rating")) {
t_78 += "data-value=\"";
t_78 += runtime.suppressValue(runtime.memberLookup((t_82),"value"), env.opts.autoescape);
t_78 += "\"";
;
}
t_78 += ">\n    <span class=\"defaultRadio-wrapper\">\n      <input class=\"defaultRadio-input\" data-target=\"field-focusable\" type=\"radio\" name=\"";
t_78 += runtime.suppressValue(l_name, env.opts.autoescape);
t_78 += "\" value=\"";
t_78 += runtime.suppressValue(runtime.memberLookup((t_82),"value"), env.opts.autoescape);
t_78 += "\"\n      ";
if(runtime.memberLookup((t_82),"checked")) {
t_78 += "checked ";
;
}
t_78 += "\n      ";
if(runtime.contextOrFrameLookup(context, frame, "required")) {
t_78 += "required ";
;
}
t_78 += "\n      ";
if(runtime.memberLookup((t_82),"hint")) {
t_78 += "aria-describedby=\"hint-";
t_78 += runtime.suppressValue(l_name, env.opts.autoescape);
t_78 += "-";
t_78 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"), env.opts.autoescape);
t_78 += "\" ";
;
}
t_78 += ">\n      ";
if(runtime.contextOrFrameLookup(context, frame, "rating")) {
t_78 += "<div class=\"defaultRadio-rate\">\n          ";
t_78 += runtime.suppressValue((lineno = 247, colno = 22, runtime.callWrap(t_20, "favoriteIcon", context, [])), env.opts.autoescape);
t_78 += "\n        </div>";
;
}
t_78 += "\n      <div class=\"defaultRadio-pseudo\" ";
t_78 += runtime.suppressValue(env.getFilter("xmlattr").call(context, runtime.contextOrFrameLookup(context, frame, "attrs")), env.opts.autoescape);
t_78 += "></div>\n      <span class=\"defaultRadio-label\">";
t_78 += runtime.suppressValue(runtime.memberLookup((t_82),"name"), env.opts.autoescape);
t_78 += "</span>\n      ";
if(runtime.memberLookup((t_82),"hint")) {
t_78 += "<span class=\"defaultRadio-hint\" id=\"hint-";
t_78 += runtime.suppressValue(l_name, env.opts.autoescape);
t_78 += "-";
t_78 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"), env.opts.autoescape);
t_78 += "\">\n        ";
t_78 += runtime.suppressValue(runtime.memberLookup((t_82),"hint"), env.opts.autoescape);
t_78 += "\n      </span>";
;
}
t_78 += "\n    </span>\n  </label>\n";
;
}
}
frame = frame.pop();
t_78 += "\n";
if(runtime.contextOrFrameLookup(context, frame, "rating")) {
t_78 += "</div>";
;
}
t_78 += "\n";
;
frame = frame.pop();
return new runtime.SafeString(t_78);
});
return macro_t_77;})()})])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_76);
});
context.addExport("radios");
context.setVariable("radios", macro_t_75);
output += "\n\n\n<!- Checkbox -->\n\n";
var macro_t_83 = runtime.makeMacro(
["label", "name", "options"], 
["required", "specifier", "mutator", "hint", "error"], 
function (l_label, l_name, l_options, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("options", l_options);
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "");
frame.set("error", kwargs.hasOwnProperty("error") ? kwargs["error"] : "");
var t_84 = "";t_84 += runtime.suppressValue((lineno = 268, colno = 12, runtime.callWrap(macro_t_28, "_field", context, [l_label,l_name,runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutator"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.makeKeywordArgs({"prefix": "","caller": (function (){var macro_t_85 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_86 = "";t_86 += "\n";
frame = frame.push();
var t_89 = l_options;
if(t_89) {var t_88 = t_89.length;
for(var t_87=0; t_87 < t_89.length; t_87++) {
var t_90 = t_89[t_87];
frame.set("option", t_90);
frame.set("loop.index", t_87 + 1);
frame.set("loop.index0", t_87);
frame.set("loop.revindex", t_88 - t_87);
frame.set("loop.revindex0", t_88 - t_87 - 1);
frame.set("loop.first", t_87 === 0);
frame.set("loop.last", t_87 === t_88 - 1);
frame.set("loop.length", t_88);
t_86 += "\n  <label class=\"defaultCheckbox\">\n    <input class=\"defaultCheckbox-input\" data-target=\"field-focusable\" type=\"checkbox\" name=\"";
t_86 += runtime.suppressValue(l_name, env.opts.autoescape);
t_86 += "\" value=\"";
t_86 += runtime.suppressValue(runtime.memberLookup((t_90),"value"), env.opts.autoescape);
t_86 += "\"\n    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index") == 0) {
t_86 += " checked";
;
}
t_86 += "\n    ";
if(runtime.contextOrFrameLookup(context, frame, "required")) {
t_86 += "required";
;
}
t_86 += "\n    ";
if(runtime.memberLookup((t_90),"hint")) {
t_86 += "aria-describedby=\"hint-";
t_86 += runtime.suppressValue(l_name, env.opts.autoescape);
t_86 += "-";
t_86 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"), env.opts.autoescape);
t_86 += "\"";
;
}
t_86 += ">\n    <div class=\"defaultCheckbox-pseudo\">\n      <div class=\"defaultCheckbox-pseudoCheck\">\n        ";
t_86 += runtime.suppressValue((lineno = 277, colno = 16, runtime.callWrap(t_8, "tickIcon", context, [])), env.opts.autoescape);
t_86 += "\n      </div>\n    </div>";
t_86 += runtime.suppressValue(runtime.memberLookup((t_90),"name"), env.opts.autoescape);
t_86 += "\n    ";
if(runtime.memberLookup((t_90),"hint")) {
t_86 += "<div class=\"defaultCheckbox-hint\" id=\"hint-";
t_86 += runtime.suppressValue(l_name, env.opts.autoescape);
t_86 += "-";
t_86 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"), env.opts.autoescape);
t_86 += "\">\n      ";
t_86 += runtime.suppressValue(runtime.memberLookup((t_90),"hint"), env.opts.autoescape);
t_86 += "\n    </div>";
;
}
t_86 += "\n  </label>\n";
;
}
}
frame = frame.pop();
t_86 += "\n";
;
frame = frame.pop();
return new runtime.SafeString(t_86);
});
return macro_t_85;})()})])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_84);
});
context.addExport("checkboxes");
context.setVariable("checkboxes", macro_t_83);
output += "\n\n\n<!- Button group -->\n\n";
var macro_t_91 = runtime.makeMacro(
["label", "name", "options", "style"], 
[], 
function (l_label, l_name, l_options, l_style, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("options", l_options);
frame.set("style", l_style);
var t_92 = "";t_92 += "<div class=\"buttonGroup\">\n";
frame = frame.push();
var t_95 = l_options;
if(t_95) {var t_94 = t_95.length;
for(var t_93=0; t_93 < t_95.length; t_93++) {
var t_96 = t_95[t_93];
frame.set("option", t_96);
frame.set("loop.index", t_93 + 1);
frame.set("loop.index0", t_93);
frame.set("loop.revindex", t_94 - t_93);
frame.set("loop.revindex0", t_94 - t_93 - 1);
frame.set("loop.first", t_93 === 0);
frame.set("loop.last", t_93 === t_94 - 1);
frame.set("loop.length", t_94);
t_92 += "\n  <label class=\"buttonGroup-button ";
t_92 += runtime.suppressValue(l_style, env.opts.autoescape);
t_92 += " ";
t_92 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "specifier"), env.opts.autoescape);
t_92 += "\">\n    <input class=\"buttonGroup-radio\" type=\"radio\" name=\"";
t_92 += runtime.suppressValue(l_name, env.opts.autoescape);
t_92 += "\" value=\"option.value\" ";
if(runtime.memberLookup((t_96),"checked")) {
t_92 += "checked";
;
}
t_92 += ">";
t_92 += runtime.suppressValue(runtime.memberLookup((t_96),"name"), env.opts.autoescape);
t_92 += "\n  </label>\n";
;
}
}
frame = frame.pop();
t_92 += "\n</div>";
;
frame = frame.pop();
return new runtime.SafeString(t_92);
});
context.setVariable("_buttonGroup", macro_t_91);
output += "\n\n<!- Default button group -->\n\n";
var macro_t_97 = runtime.makeMacro(
["label", "name", "options", "style"], 
[], 
function (l_label, l_name, l_options, l_style, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("options", l_options);
frame.set("style", l_style);
var t_98 = "";t_98 += runtime.suppressValue((lineno = 308, colno = 12, runtime.callWrap(macro_t_28, "_field", context, [l_label,l_name,runtime.makeKeywordArgs({"caller": (function (){var macro_t_99 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_100 = "";t_100 += "\n\n";
t_100 += runtime.suppressValue((lineno = 310, colno = 13, runtime.callWrap(macro_t_91, "_buttonGroup", context, [l_label,l_name,l_options,"button--default"])), env.opts.autoescape);
t_100 += "\n\n";
;
frame = frame.pop();
return new runtime.SafeString(t_100);
});
return macro_t_99;})()})])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_98);
});
context.addExport("buttonGroupDefault");
context.setVariable("buttonGroupDefault", macro_t_97);
output += "\n\n<!- Invert button group -->\n\n";
var macro_t_101 = runtime.makeMacro(
["label", "name", "options", "style"], 
[], 
function (l_label, l_name, l_options, l_style, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("options", l_options);
frame.set("style", l_style);
var t_102 = "";t_102 += runtime.suppressValue((lineno = 318, colno = 12, runtime.callWrap(macro_t_28, "_field", context, [l_label,l_name,runtime.makeKeywordArgs({"caller": (function (){var macro_t_103 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_104 = "";t_104 += "\n\n";
t_104 += runtime.suppressValue((lineno = 320, colno = 13, runtime.callWrap(macro_t_91, "_buttonGroup", context, [l_label,l_name,l_options,"button--invert"])), env.opts.autoescape);
t_104 += "\n\n";
;
frame = frame.pop();
return new runtime.SafeString(t_104);
});
return macro_t_103;})()})])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_102);
});
context.addExport("buttonGroupInvert");
context.setVariable("buttonGroupInvert", macro_t_101);
output += "\n\n\n<!- Textarea -->\n\n";
var macro_t_105 = runtime.makeMacro(
["label", "name"], 
["required", "specifier", "mutator", "hint", "link", "linkLabel", "error", "shorten", "placeholder", "maxlength", "attrs", "focusable", "readonly"], 
function (l_label, l_name, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("linkLabel", kwargs.hasOwnProperty("linkLabel") ? kwargs["linkLabel"] : "");
frame.set("error", kwargs.hasOwnProperty("error") ? kwargs["error"] : "");
frame.set("shorten", kwargs.hasOwnProperty("shorten") ? kwargs["shorten"] : "");
frame.set("placeholder", kwargs.hasOwnProperty("placeholder") ? kwargs["placeholder"] : "");
frame.set("maxlength", kwargs.hasOwnProperty("maxlength") ? kwargs["maxlength"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("focusable", kwargs.hasOwnProperty("focusable") ? kwargs["focusable"] : true);
frame.set("readonly", kwargs.hasOwnProperty("readonly") ? kwargs["readonly"] : false);
var t_106 = "";t_106 += runtime.suppressValue((lineno = 329, colno = 12, runtime.callWrap(macro_t_28, "_field", context, [l_label,l_name,runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "style"),runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutator"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "link"),runtime.contextOrFrameLookup(context, frame, "linkLabel"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.contextOrFrameLookup(context, frame, "prefix"),runtime.contextOrFrameLookup(context, frame, "shorten"),runtime.makeKeywordArgs({"caller": (function (){var macro_t_107 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_108 = "";t_108 += "\n<textarea class=\"defaultTextarea ";
t_108 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "specifier"), env.opts.autoescape);
t_108 += "\" ";
if(runtime.contextOrFrameLookup(context, frame, "focusable")) {
t_108 += "data-target=\"field-focusable\"";
;
}
t_108 += " name=\"";
t_108 += runtime.suppressValue(l_name, env.opts.autoescape);
t_108 += "\" id=\"field-";
t_108 += runtime.suppressValue(l_name, env.opts.autoescape);
t_108 += "\" ";
if(runtime.contextOrFrameLookup(context, frame, "required")) {
t_108 += "required";
;
}
t_108 += "\n  ";
if(runtime.contextOrFrameLookup(context, frame, "placeholder")) {
t_108 += "placeholder=\"";
t_108 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "placeholder"), env.opts.autoescape);
t_108 += "\"";
;
}
t_108 += " aria-describedby=\"";
if(runtime.contextOrFrameLookup(context, frame, "hint")) {
t_108 += "hint-";
t_108 += runtime.suppressValue(l_name, env.opts.autoescape);
;
}
if(runtime.contextOrFrameLookup(context, frame, "error")) {
t_108 += "error-";
t_108 += runtime.suppressValue(l_name, env.opts.autoescape);
;
}
t_108 += "\"\n  ";
if(runtime.contextOrFrameLookup(context, frame, "maxlength")) {
t_108 += "maxlength=\"";
t_108 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "maxlength"), env.opts.autoescape);
t_108 += "\"";
;
}
t_108 += " ";
if(runtime.contextOrFrameLookup(context, frame, "readonly")) {
t_108 += "readonly";
;
}
t_108 += " ";
t_108 += runtime.suppressValue(env.getFilter("xmlattr").call(context, runtime.contextOrFrameLookup(context, frame, "attrs")), env.opts.autoescape);
t_108 += "></textarea>\n";
;
frame = frame.pop();
return new runtime.SafeString(t_108);
});
return macro_t_107;})()})])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_106);
});
context.addExport("textarea");
context.setVariable("textarea", macro_t_105);
output += "\n\n\n<!- Input -->\n\n";
var macro_t_109 = runtime.makeMacro(
["label", "name"], 
["type", "required", "value", "related", "placeholder", "dataComponent", "hint", "error", "min", "max", "step", "attrs", "disabled"], 
function (l_label, l_name, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("type", kwargs.hasOwnProperty("type") ? kwargs["type"] : "");
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("value", kwargs.hasOwnProperty("value") ? kwargs["value"] : "");
frame.set("related", kwargs.hasOwnProperty("related") ? kwargs["related"] : "");
frame.set("placeholder", kwargs.hasOwnProperty("placeholder") ? kwargs["placeholder"] : "");
frame.set("dataComponent", kwargs.hasOwnProperty("dataComponent") ? kwargs["dataComponent"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "");
frame.set("error", kwargs.hasOwnProperty("error") ? kwargs["error"] : "");
frame.set("min", kwargs.hasOwnProperty("min") ? kwargs["min"] : "");
frame.set("max", kwargs.hasOwnProperty("max") ? kwargs["max"] : "");
frame.set("step", kwargs.hasOwnProperty("step") ? kwargs["step"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("disabled", kwargs.hasOwnProperty("disabled") ? kwargs["disabled"] : runtime.contextOrFrameLookup(context, frame, "False"));
var t_110 = "";t_110 += "<input type=\"";
t_110 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "type"), env.opts.autoescape);
t_110 += "\" class=\"defaultInput\" data-target=\"field-focusable\" id=\"field-";
t_110 += runtime.suppressValue(l_name, env.opts.autoescape);
t_110 += "\"";
if(runtime.contextOrFrameLookup(context, frame, "value")) {
t_110 += "value=\"";
t_110 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "value"), env.opts.autoescape);
t_110 += "\" ";
;
}
if(l_name) {
t_110 += "name=\"";
t_110 += runtime.suppressValue(l_name, env.opts.autoescape);
t_110 += "\" ";
;
}
if(runtime.contextOrFrameLookup(context, frame, "required")) {
t_110 += "required ";
;
}
if(runtime.contextOrFrameLookup(context, frame, "placeholder")) {
t_110 += "placeholder=\"";
t_110 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "placeholder"), env.opts.autoescape);
t_110 += "\"";
;
}
if(runtime.contextOrFrameLookup(context, frame, "step")) {
t_110 += "step=\"";
t_110 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "step"), env.opts.autoescape);
t_110 += "\" ";
;
}
if(runtime.contextOrFrameLookup(context, frame, "min")) {
t_110 += "min=\"";
t_110 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "min"), env.opts.autoescape);
t_110 += "\" ";
;
}
if(runtime.contextOrFrameLookup(context, frame, "max")) {
t_110 += "max=\"";
t_110 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "max"), env.opts.autoescape);
t_110 += "\" ";
;
}
if(runtime.contextOrFrameLookup(context, frame, "dataComponent")) {
t_110 += "data-component=\"";
t_110 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "dataComponent"), env.opts.autoescape);
t_110 += "\" ";
;
}
if(runtime.contextOrFrameLookup(context, frame, "related")) {
t_110 += "data-related=\"";
t_110 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "related"), env.opts.autoescape);
t_110 += "\" ";
;
}
t_110 += "aria-describedby=\"";
if(runtime.contextOrFrameLookup(context, frame, "hint")) {
t_110 += "hint-";
t_110 += runtime.suppressValue(l_name, env.opts.autoescape);
;
}
t_110 += " ";
if(runtime.contextOrFrameLookup(context, frame, "error")) {
t_110 += "error-";
t_110 += runtime.suppressValue(l_name, env.opts.autoescape);
;
}
t_110 += "\"";
if(runtime.contextOrFrameLookup(context, frame, "disabled")) {
t_110 += "disabled";
;
}
t_110 += "\n  ";
t_110 += runtime.suppressValue(env.getFilter("xmlattr").call(context, runtime.contextOrFrameLookup(context, frame, "attrs")), env.opts.autoescape);
t_110 += "\n  autocomplete=\"off\"/>";
if(runtime.contextOrFrameLookup(context, frame, "disabled")) {
t_110 += "<span class=\"field-input-disabledIcon\">";
t_110 += runtime.suppressValue((lineno = 355, colno = 52, runtime.callWrap(t_16, "secureIcon", context, [])), env.opts.autoescape);
t_110 += "</span>\n";
;
}
;
frame = frame.pop();
return new runtime.SafeString(t_110);
});
context.setVariable("_input", macro_t_109);
output += "\n\n";
var macro_t_111 = runtime.makeMacro(
[], 
["label", "name", "required", "value", "related", "placeholder", "dataComponent", "attrs"], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", kwargs.hasOwnProperty("label") ? kwargs["label"] : "");
frame.set("name", kwargs.hasOwnProperty("name") ? kwargs["name"] : "");
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("value", kwargs.hasOwnProperty("value") ? kwargs["value"] : "");
frame.set("related", kwargs.hasOwnProperty("related") ? kwargs["related"] : "");
frame.set("placeholder", kwargs.hasOwnProperty("placeholder") ? kwargs["placeholder"] : "");
frame.set("dataComponent", kwargs.hasOwnProperty("dataComponent") ? kwargs["dataComponent"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
var t_112 = "";t_112 += "<div contentEditable=\"true\" class=\"defaultInput defaultInput--pseudo ";
t_112 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "name"), env.opts.autoescape);
t_112 += "\" data-target=\"field-focusable\" id=\"field-";
t_112 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "name"), env.opts.autoescape);
t_112 += "\"\n  ";
if(runtime.contextOrFrameLookup(context, frame, "name")) {
t_112 += "name=\"";
t_112 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "name"), env.opts.autoescape);
t_112 += "\"";
;
}
t_112 += "\n  ";
if(runtime.contextOrFrameLookup(context, frame, "required")) {
t_112 += "required";
;
}
t_112 += "\n  ";
if(runtime.contextOrFrameLookup(context, frame, "dataComponent")) {
t_112 += "data-component=\"";
t_112 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "dataComponent"), env.opts.autoescape);
t_112 += "\"";
;
}
t_112 += "\n  ";
if(runtime.contextOrFrameLookup(context, frame, "related")) {
t_112 += "data-related=\"";
t_112 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "related"), env.opts.autoescape);
t_112 += "\"";
;
}
t_112 += "aria-describedby=\"";
if(runtime.contextOrFrameLookup(context, frame, "hint")) {
t_112 += "hint-";
t_112 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "name"), env.opts.autoescape);
;
}
t_112 += " ";
if(runtime.contextOrFrameLookup(context, frame, "error")) {
t_112 += "error-";
t_112 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "name"), env.opts.autoescape);
;
}
t_112 += "\"\n  ";
if(runtime.contextOrFrameLookup(context, frame, "placeholder")) {
t_112 += "data-placeholder=\"";
t_112 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "placeholder"), env.opts.autoescape);
t_112 += "\"";
;
}
t_112 += "\n  ";
t_112 += runtime.suppressValue(env.getFilter("xmlattr").call(context, runtime.contextOrFrameLookup(context, frame, "attrs")), env.opts.autoescape);
t_112 += " />\n    ";
if(runtime.contextOrFrameLookup(context, frame, "value")) {
t_112 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "value"), env.opts.autoescape);
;
}
t_112 += "\n  </div>";
;
frame = frame.pop();
return new runtime.SafeString(t_112);
});
context.addExport("pseudoInput");
context.setVariable("pseudoInput", macro_t_111);
output += "\n\n";
var macro_t_113 = runtime.makeMacro(
["label", "name"], 
["required", "style", "specifier", "mutator", "placeholder", "dataComponent", "fieldDataComponent", "hint", "link", "linkLabel", "prefix", "error", "value", "related", "shorten", "divider", "suffix", "attrs", "disabled"], 
function (l_label, l_name, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("placeholder", kwargs.hasOwnProperty("placeholder") ? kwargs["placeholder"] : "");
frame.set("dataComponent", kwargs.hasOwnProperty("dataComponent") ? kwargs["dataComponent"] : "");
frame.set("fieldDataComponent", kwargs.hasOwnProperty("fieldDataComponent") ? kwargs["fieldDataComponent"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("linkLabel", kwargs.hasOwnProperty("linkLabel") ? kwargs["linkLabel"] : "");
frame.set("prefix", kwargs.hasOwnProperty("prefix") ? kwargs["prefix"] : "");
frame.set("error", kwargs.hasOwnProperty("error") ? kwargs["error"] : "");
frame.set("value", kwargs.hasOwnProperty("value") ? kwargs["value"] : "");
frame.set("related", kwargs.hasOwnProperty("related") ? kwargs["related"] : "");
frame.set("shorten", kwargs.hasOwnProperty("shorten") ? kwargs["shorten"] : "");
frame.set("divider", kwargs.hasOwnProperty("divider") ? kwargs["divider"] : "");
frame.set("suffix", kwargs.hasOwnProperty("suffix") ? kwargs["suffix"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("disabled", kwargs.hasOwnProperty("disabled") ? kwargs["disabled"] : runtime.contextOrFrameLookup(context, frame, "False"));
var t_114 = "";t_114 += runtime.suppressValue((lineno = 373, colno = 12, runtime.callWrap(macro_t_28, "_field", context, [l_label,l_name,runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "style"),runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutator"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "link"),runtime.contextOrFrameLookup(context, frame, "linkLabel"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.contextOrFrameLookup(context, frame, "prefix"),runtime.contextOrFrameLookup(context, frame, "shorten"),runtime.contextOrFrameLookup(context, frame, "divider"),runtime.contextOrFrameLookup(context, frame, "suffix"),runtime.makeKeywordArgs({"dataComponent": runtime.contextOrFrameLookup(context, frame, "fieldDataComponent"),"caller": (function (){var macro_t_115 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_116 = "";t_116 += "\n";
t_116 += runtime.suppressValue((lineno = 374, colno = 7, runtime.callWrap(macro_t_109, "_input", context, [l_label,l_name,"text",runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "value"),runtime.contextOrFrameLookup(context, frame, "related"),runtime.contextOrFrameLookup(context, frame, "placeholder"),runtime.contextOrFrameLookup(context, frame, "dataComponent"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.makeKeywordArgs({"attrs": runtime.contextOrFrameLookup(context, frame, "attrs"),"disabled": runtime.contextOrFrameLookup(context, frame, "disabled")})])), env.opts.autoescape);
t_116 += "\n";
;
frame = frame.pop();
return new runtime.SafeString(t_116);
});
return macro_t_115;})()})])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_114);
});
context.addExport("text");
context.setVariable("text", macro_t_113);
output += "\n\n";
var macro_t_117 = runtime.makeMacro(
["label", "name"], 
["required", "style", "specifier", "mutator", "placeholder", "hint", "link", "linkLabel", "prefix", "error", "value", "shorten", "suffix"], 
function (l_label, l_name, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("placeholder", kwargs.hasOwnProperty("placeholder") ? kwargs["placeholder"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("linkLabel", kwargs.hasOwnProperty("linkLabel") ? kwargs["linkLabel"] : "");
frame.set("prefix", kwargs.hasOwnProperty("prefix") ? kwargs["prefix"] : "");
frame.set("error", kwargs.hasOwnProperty("error") ? kwargs["error"] : "");
frame.set("value", kwargs.hasOwnProperty("value") ? kwargs["value"] : "");
frame.set("shorten", kwargs.hasOwnProperty("shorten") ? kwargs["shorten"] : "");
frame.set("suffix", kwargs.hasOwnProperty("suffix") ? kwargs["suffix"] : "");
var t_118 = "";if(runtime.contextOrFrameLookup(context, frame, "hint") == "") {
var t_119;
t_119 = (lineno = 379, colno = 34, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["The email address you registered with"]));
frame.set("hint", t_119, true);
if(frame.topLevel) {
context.setVariable("hint", t_119);
}
if(frame.topLevel) {
context.addExport("hint", t_119);
}
;
}
t_118 += "\n\n";
t_118 += runtime.suppressValue((lineno = 381, colno = 12, runtime.callWrap(macro_t_28, "_field", context, [l_label,l_name,runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "style"),runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutator"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "link"),runtime.contextOrFrameLookup(context, frame, "linkLabel"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.contextOrFrameLookup(context, frame, "prefix"),runtime.contextOrFrameLookup(context, frame, "shorten"),runtime.contextOrFrameLookup(context, frame, "suffix"),runtime.makeKeywordArgs({"caller": (function (){var macro_t_120 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_121 = "";t_121 += "\n";
t_121 += runtime.suppressValue((lineno = 382, colno = 7, runtime.callWrap(macro_t_109, "_input", context, [l_label,l_name,"text",runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "value"),runtime.contextOrFrameLookup(context, frame, "related"),runtime.contextOrFrameLookup(context, frame, "placeholder"),runtime.contextOrFrameLookup(context, frame, "dataComponent"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "error")])), env.opts.autoescape);
t_121 += "\n";
;
frame = frame.pop();
return new runtime.SafeString(t_121);
});
return macro_t_120;})()})])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_118);
});
context.addExport("username");
context.setVariable("username", macro_t_117);
output += "\n\n";
var macro_t_122 = runtime.makeMacro(
["label", "name"], 
["required", "style", "specifier", "mutator", "hint", "link", "linkLabel", "prefix", "related", "error", "value", "shorten", "suffix", "attrs"], 
function (l_label, l_name, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("linkLabel", kwargs.hasOwnProperty("linkLabel") ? kwargs["linkLabel"] : "");
frame.set("prefix", kwargs.hasOwnProperty("prefix") ? kwargs["prefix"] : (lineno = 386, colno = 127, runtime.callWrap(t_16, "secureIcon", context, [])));
frame.set("related", kwargs.hasOwnProperty("related") ? kwargs["related"] : "");
frame.set("error", kwargs.hasOwnProperty("error") ? kwargs["error"] : "");
frame.set("value", kwargs.hasOwnProperty("value") ? kwargs["value"] : "");
frame.set("shorten", kwargs.hasOwnProperty("shorten") ? kwargs["shorten"] : "");
frame.set("suffix", kwargs.hasOwnProperty("suffix") ? kwargs["suffix"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
var t_123 = "";t_123 += runtime.suppressValue((lineno = 387, colno = 12, runtime.callWrap(macro_t_28, "_field", context, [l_label,l_name,runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "style"),runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutator"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "link"),runtime.contextOrFrameLookup(context, frame, "linkLabel"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.contextOrFrameLookup(context, frame, "prefix"),runtime.contextOrFrameLookup(context, frame, "shorten"),runtime.contextOrFrameLookup(context, frame, "suffix"),runtime.makeKeywordArgs({"caller": (function (){var macro_t_124 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_125 = "";t_125 += "\n";
t_125 += runtime.suppressValue((lineno = 388, colno = 7, runtime.callWrap(macro_t_109, "_input", context, [l_label,l_name,"password",runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "value"),runtime.contextOrFrameLookup(context, frame, "related"),runtime.contextOrFrameLookup(context, frame, "placeholder"),runtime.contextOrFrameLookup(context, frame, "dataComponent"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.makeKeywordArgs({"attrs": runtime.contextOrFrameLookup(context, frame, "attrs")})])), env.opts.autoescape);
t_125 += "\n";
;
frame = frame.pop();
return new runtime.SafeString(t_125);
});
return macro_t_124;})()})])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_123);
});
context.addExport("password");
context.setVariable("password", macro_t_122);
output += "\n\n<!-- create password -->\n\n";
var macro_t_126 = runtime.makeMacro(
["name"], 
["required", "dataComponent", "style", "specifier", "mutator", "hint", "link", "linkLabel", "prefix", "related", "error", "value", "shorten", "suffix", "attrs"], 
function (l_name, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("name", l_name);
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("dataComponent", kwargs.hasOwnProperty("dataComponent") ? kwargs["dataComponent"] : "");
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("linkLabel", kwargs.hasOwnProperty("linkLabel") ? kwargs["linkLabel"] : "");
frame.set("prefix", kwargs.hasOwnProperty("prefix") ? kwargs["prefix"] : (lineno = 394, colno = 140, runtime.callWrap(t_16, "secureIcon", context, [])));
frame.set("related", kwargs.hasOwnProperty("related") ? kwargs["related"] : "");
frame.set("error", kwargs.hasOwnProperty("error") ? kwargs["error"] : "");
frame.set("value", kwargs.hasOwnProperty("value") ? kwargs["value"] : "");
frame.set("shorten", kwargs.hasOwnProperty("shorten") ? kwargs["shorten"] : "");
frame.set("suffix", kwargs.hasOwnProperty("suffix") ? kwargs["suffix"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
var t_127 = "";t_127 += runtime.suppressValue((lineno = 396, colno = 12, runtime.callWrap(macro_t_28, "_field", context, [(lineno = 396, colno = 20, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Please enter a new password"])),l_name,runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "style"),runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutator"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "link"),runtime.contextOrFrameLookup(context, frame, "linkLabel"),(lineno = 396, colno = 126, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["<strong>Your password must :</strong> <br>- be <strong>7 or more characters long</strong><br>- have at least <strong>one upper and one lower case character</strong> <br>- have at least <strong>one number or special character</strong>."])),runtime.contextOrFrameLookup(context, frame, "prefix"),runtime.contextOrFrameLookup(context, frame, "shorten"),runtime.contextOrFrameLookup(context, frame, "divider"),runtime.contextOrFrameLookup(context, frame, "suffix"),runtime.contextOrFrameLookup(context, frame, "dropdown"),runtime.contextOrFrameLookup(context, frame, "dataComponent"),runtime.makeKeywordArgs({"caller": (function (){var macro_t_128 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_129 = "";t_129 += "\n\n";
t_129 += runtime.suppressValue((lineno = 398, colno = 7, runtime.callWrap(macro_t_109, "_input", context, [(lineno = 398, colno = 15, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Please enter a new password"])),l_name,"password",true,runtime.contextOrFrameLookup(context, frame, "value"),runtime.contextOrFrameLookup(context, frame, "related"),runtime.contextOrFrameLookup(context, frame, "placeholder"),runtime.contextOrFrameLookup(context, frame, "dataComponent"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.makeKeywordArgs({"attrs": runtime.contextOrFrameLookup(context, frame, "attrs")})])), env.opts.autoescape);
t_129 += "\n";
;
frame = frame.pop();
return new runtime.SafeString(t_129);
});
return macro_t_128;})()})])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_127);
});
context.addExport("newPassword");
context.setVariable("newPassword", macro_t_126);
output += "\n\n<!-- login password -->\n\n";
var macro_t_130 = runtime.makeMacro(
["label", "name"], 
["required", "style", "specifier", "mutator", "hint", "link", "linkLabel", "prefix", "error", "value", "shorten", "suffix", "attrs"], 
function (l_label, l_name, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("linkLabel", kwargs.hasOwnProperty("linkLabel") ? kwargs["linkLabel"] : "");
frame.set("prefix", kwargs.hasOwnProperty("prefix") ? kwargs["prefix"] : (lineno = 404, colno = 132, runtime.callWrap(t_16, "secureIcon", context, [])));
frame.set("error", kwargs.hasOwnProperty("error") ? kwargs["error"] : "");
frame.set("value", kwargs.hasOwnProperty("value") ? kwargs["value"] : "");
frame.set("shorten", kwargs.hasOwnProperty("shorten") ? kwargs["shorten"] : "");
frame.set("suffix", kwargs.hasOwnProperty("suffix") ? kwargs["suffix"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
var t_131 = "";if(runtime.contextOrFrameLookup(context, frame, "linkLabel") == "") {
var t_132;
t_132 = (lineno = 405, colno = 44, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Recover your password"]));
frame.set("linkLabel", t_132, true);
if(frame.topLevel) {
context.setVariable("linkLabel", t_132);
}
if(frame.topLevel) {
context.addExport("linkLabel", t_132);
}
;
}
t_131 += "\n\n";
t_131 += runtime.suppressValue((lineno = 407, colno = 12, runtime.callWrap(macro_t_28, "_field", context, [l_label,l_name,runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "style"),runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutator"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "link"),runtime.contextOrFrameLookup(context, frame, "linkLabel"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.contextOrFrameLookup(context, frame, "prefix"),runtime.contextOrFrameLookup(context, frame, "shorten"),runtime.contextOrFrameLookup(context, frame, "suffix"),runtime.makeKeywordArgs({"caller": (function (){var macro_t_133 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_134 = "";t_134 += "\n";
t_134 += runtime.suppressValue((lineno = 408, colno = 7, runtime.callWrap(macro_t_109, "_input", context, [l_label,l_name,"password",runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "value"),runtime.contextOrFrameLookup(context, frame, "related"),runtime.contextOrFrameLookup(context, frame, "placeholder"),runtime.contextOrFrameLookup(context, frame, "dataComponent"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.makeKeywordArgs({"attrs": runtime.contextOrFrameLookup(context, frame, "attrs")})])), env.opts.autoescape);
t_134 += "\n";
;
frame = frame.pop();
return new runtime.SafeString(t_134);
});
return macro_t_133;})()})])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_131);
});
context.addExport("loginPassword");
context.setVariable("loginPassword", macro_t_130);
output += "\n\n";
var macro_t_135 = runtime.makeMacro(
["label", "name"], 
["required", "style", "specifier", "mutator", "placeholder", "hint", "link", "linkLabel", "prefix", "error", "value", "related", "shorten", "divider", "suffix", "min", "max", "step", "dataComponent", "fieldDataComponent", "attrs", "disabled"], 
function (l_label, l_name, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("placeholder", kwargs.hasOwnProperty("placeholder") ? kwargs["placeholder"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("linkLabel", kwargs.hasOwnProperty("linkLabel") ? kwargs["linkLabel"] : "");
frame.set("prefix", kwargs.hasOwnProperty("prefix") ? kwargs["prefix"] : "");
frame.set("error", kwargs.hasOwnProperty("error") ? kwargs["error"] : (lineno = 423, colno = 29, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Please enter a numeric value"])));
frame.set("value", kwargs.hasOwnProperty("value") ? kwargs["value"] : "");
frame.set("related", kwargs.hasOwnProperty("related") ? kwargs["related"] : "");
frame.set("shorten", kwargs.hasOwnProperty("shorten") ? kwargs["shorten"] : "");
frame.set("divider", kwargs.hasOwnProperty("divider") ? kwargs["divider"] : "");
frame.set("suffix", kwargs.hasOwnProperty("suffix") ? kwargs["suffix"] : "");
frame.set("min", kwargs.hasOwnProperty("min") ? kwargs["min"] : "");
frame.set("max", kwargs.hasOwnProperty("max") ? kwargs["max"] : "");
frame.set("step", kwargs.hasOwnProperty("step") ? kwargs["step"] : "");
frame.set("dataComponent", kwargs.hasOwnProperty("dataComponent") ? kwargs["dataComponent"] : "");
frame.set("fieldDataComponent", kwargs.hasOwnProperty("fieldDataComponent") ? kwargs["fieldDataComponent"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("disabled", kwargs.hasOwnProperty("disabled") ? kwargs["disabled"] : runtime.contextOrFrameLookup(context, frame, "False"));
var t_136 = "";t_136 += runtime.suppressValue((lineno = 436, colno = 12, runtime.callWrap(macro_t_28, "_field", context, [l_label,l_name,runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "style"),runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutator"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "link"),runtime.contextOrFrameLookup(context, frame, "linkLabel"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.contextOrFrameLookup(context, frame, "prefix"),runtime.contextOrFrameLookup(context, frame, "shorten"),runtime.contextOrFrameLookup(context, frame, "divider"),runtime.contextOrFrameLookup(context, frame, "suffix"),runtime.makeKeywordArgs({"dataComponent": runtime.contextOrFrameLookup(context, frame, "fieldDataComponent"),"caller": (function (){var macro_t_137 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_138 = "";t_138 += "\n\n";
t_138 += runtime.suppressValue((lineno = 438, colno = 7, runtime.callWrap(macro_t_109, "_input", context, [l_label,l_name,"number",runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "value"),runtime.contextOrFrameLookup(context, frame, "related"),runtime.contextOrFrameLookup(context, frame, "placeholder"),runtime.contextOrFrameLookup(context, frame, "dataComponent"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.contextOrFrameLookup(context, frame, "min"),runtime.contextOrFrameLookup(context, frame, "max"),runtime.contextOrFrameLookup(context, frame, "step"),runtime.contextOrFrameLookup(context, frame, "attrs"),runtime.makeKeywordArgs({"disabled": runtime.contextOrFrameLookup(context, frame, "disabled")})])), env.opts.autoescape);
t_138 += "\n";
;
frame = frame.pop();
return new runtime.SafeString(t_138);
});
return macro_t_137;})()})])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_136);
});
context.addExport("number");
context.setVariable("number", macro_t_135);
output += "\n\n";
var macro_t_139 = runtime.makeMacro(
["label", "name"], 
["required", "style", "specifier", "mutator", "placeholder", "hint", "link", "linkLabel", "prefix", "error", "value", "shorten", "suffix", "attrs"], 
function (l_label, l_name, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("placeholder", kwargs.hasOwnProperty("placeholder") ? kwargs["placeholder"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("linkLabel", kwargs.hasOwnProperty("linkLabel") ? kwargs["linkLabel"] : "");
frame.set("prefix", kwargs.hasOwnProperty("prefix") ? kwargs["prefix"] : "");
frame.set("error", kwargs.hasOwnProperty("error") ? kwargs["error"] : "");
frame.set("value", kwargs.hasOwnProperty("value") ? kwargs["value"] : "");
frame.set("shorten", kwargs.hasOwnProperty("shorten") ? kwargs["shorten"] : "");
frame.set("suffix", kwargs.hasOwnProperty("suffix") ? kwargs["suffix"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
var t_140 = "";t_140 += runtime.suppressValue((lineno = 443, colno = 12, runtime.callWrap(macro_t_28, "_field", context, [l_label,l_name,runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "style"),runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutator"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.contextOrFrameLookup(context, frame, "link"),runtime.contextOrFrameLookup(context, frame, "linkLabel"),runtime.contextOrFrameLookup(context, frame, "prefix"),runtime.contextOrFrameLookup(context, frame, "shorten"),runtime.contextOrFrameLookup(context, frame, "suffix"),runtime.makeKeywordArgs({"caller": (function (){var macro_t_141 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_142 = "";t_142 += "\n";
t_142 += runtime.suppressValue((lineno = 444, colno = 7, runtime.callWrap(macro_t_109, "_input", context, [l_label,l_name,"date",runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "value"),runtime.contextOrFrameLookup(context, frame, "related"),runtime.contextOrFrameLookup(context, frame, "placeholder"),runtime.contextOrFrameLookup(context, frame, "dataComponent"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.makeKeywordArgs({"attrs": runtime.contextOrFrameLookup(context, frame, "attrs")})])), env.opts.autoescape);
t_142 += "\n";
;
frame = frame.pop();
return new runtime.SafeString(t_142);
});
return macro_t_141;})()})])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_140);
});
context.addExport("date");
context.setVariable("date", macro_t_139);
output += "\n\n";
var macro_t_143 = runtime.makeMacro(
["label", "name"], 
["required", "style", "specifier", "mutator", "placeholder", "hint", "link", "linkLabel", "prefix", "error", "value", "shorten", "suffix", "defaultValues", "attrs", "disabled"], 
function (l_label, l_name, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("placeholder", kwargs.hasOwnProperty("placeholder") ? kwargs["placeholder"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("linkLabel", kwargs.hasOwnProperty("linkLabel") ? kwargs["linkLabel"] : "");
frame.set("prefix", kwargs.hasOwnProperty("prefix") ? kwargs["prefix"] : "");
frame.set("error", kwargs.hasOwnProperty("error") ? kwargs["error"] : "");
frame.set("value", kwargs.hasOwnProperty("value") ? kwargs["value"] : "");
frame.set("shorten", kwargs.hasOwnProperty("shorten") ? kwargs["shorten"] : "");
frame.set("suffix", kwargs.hasOwnProperty("suffix") ? kwargs["suffix"] : "");
frame.set("defaultValues", kwargs.hasOwnProperty("defaultValues") ? kwargs["defaultValues"] : {});
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("disabled", kwargs.hasOwnProperty("disabled") ? kwargs["disabled"] : runtime.contextOrFrameLookup(context, frame, "False"));
var t_144 = "";t_144 += runtime.suppressValue((lineno = 449, colno = 7, runtime.callWrap(macro_t_35, "_label", context, [l_name,l_label,runtime.contextOrFrameLookup(context, frame, "required"),(lineno = 449, colno = 39, runtime.callWrap(t_8, "tickIcon", context, []))])), env.opts.autoescape);
t_144 += "\n<div class=\"field field--group\" data-target=\"validate-as-single-field\">\n  <div class=\"defaultForm-group\">\n    ";
t_144 += runtime.suppressValue((lineno = 452, colno = 15, runtime.callWrap(macro_t_57, "formSelect", context, ["",l_name + "Month",runtime.makeKeywordArgs({"options": runtime.contextOrFrameLookup(context, frame, "months"),"shorten": true,"required": "true","attrs": {"data-type": "date-month","data-e2e-target": "date-month-dropdown"},"selected": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "defaultValues")),"Month"),"disabled": runtime.contextOrFrameLookup(context, frame, "disabled")})])), env.opts.autoescape);
t_144 += "\n    ";
t_144 += runtime.suppressValue((lineno = 453, colno = 11, runtime.callWrap(macro_t_135, "number", context, ["",l_name + "Day",runtime.makeKeywordArgs({"value": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "defaultValues")),"Day"),"required": runtime.contextOrFrameLookup(context, frame, "required"),"placeholder": (lineno = 453, colno = 93, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["DD"])),"error": "","attrs": {"data-type": "date-day","data-max-length": "2","data-e2e-target": "date-day-field"},"disabled": runtime.contextOrFrameLookup(context, frame, "disabled")})])), env.opts.autoescape);
t_144 += "\n    ";
t_144 += runtime.suppressValue((lineno = 454, colno = 11, runtime.callWrap(macro_t_135, "number", context, ["",l_name + "Year",runtime.makeKeywordArgs({"value": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "defaultValues")),"Year"),"required": runtime.contextOrFrameLookup(context, frame, "required"),"placeholder": (lineno = 454, colno = 95, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["YYYY"])),"error": "","attrs": {"data-type": "date-year","data-max-length": "4","data-e2e-target": "date-year-field"},"disabled": runtime.contextOrFrameLookup(context, frame, "disabled")})])), env.opts.autoescape);
t_144 += "\n  </div>\n\n  <div class=\"field-error\" data-target=\"field-error\" data-attr=\"error-invalid\">\n    <span class=\"field-errorMsg\" id=\"error-";
t_144 += runtime.suppressValue(l_name, env.opts.autoescape);
t_144 += "\">";
t_144 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "error"), env.opts.autoescape);
t_144 += "</span>\n  </div>\n</div>";
;
frame = frame.pop();
return new runtime.SafeString(t_144);
});
context.addExport("dateMultipart");
context.setVariable("dateMultipart", macro_t_143);
env.getTemplate("icons/email.html", false, "escrow_www/templates/components/inputs.html", false, function(t_146,t_145) {
if(t_146) { cb(t_146); return; }
t_145.getExported(context.getVariables(), frame, function(t_147,t_145) {
if(t_147) { cb(t_147); return; }
if(t_145.hasOwnProperty("email")) {
var t_148 = t_145.email;
} else {
cb(new Error("cannot import 'email'")); return;
}
context.setVariable("emailIcon", t_148);
var macro_t_149 = runtime.makeMacro(
["label", "name"], 
["required", "style", "specifier", "mutator", "placeholder", "hint", "link", "linkLabel", "prefix", "related", "error", "value", "shorten", "suffix", "errors", "hintSuggestion"], 
function (l_label, l_name, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("placeholder", kwargs.hasOwnProperty("placeholder") ? kwargs["placeholder"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("linkLabel", kwargs.hasOwnProperty("linkLabel") ? kwargs["linkLabel"] : "");
frame.set("prefix", kwargs.hasOwnProperty("prefix") ? kwargs["prefix"] : (lineno = 465, colno = 139, runtime.callWrap(t_148, "emailIcon", context, [])));
frame.set("related", kwargs.hasOwnProperty("related") ? kwargs["related"] : "");
frame.set("error", kwargs.hasOwnProperty("error") ? kwargs["error"] : "");
frame.set("value", kwargs.hasOwnProperty("value") ? kwargs["value"] : "");
frame.set("shorten", kwargs.hasOwnProperty("shorten") ? kwargs["shorten"] : "");
frame.set("suffix", kwargs.hasOwnProperty("suffix") ? kwargs["suffix"] : "");
frame.set("errors", kwargs.hasOwnProperty("errors") ? kwargs["errors"] : runtime.contextOrFrameLookup(context, frame, "None"));
frame.set("hintSuggestion", kwargs.hasOwnProperty("hintSuggestion") ? kwargs["hintSuggestion"] : runtime.contextOrFrameLookup(context, frame, "False"));
var t_150 = "";if(!runtime.contextOrFrameLookup(context, frame, "errors")) {
t_150 += "\n  ";
var t_151;
t_151 = [];
frame.set("errors", t_151, true);
if(frame.topLevel) {
context.setVariable("errors", t_151);
}
if(frame.topLevel) {
context.addExport("errors", t_151);
}
t_150 += "\n";
;
}
t_150 += "\n";
t_150 += runtime.suppressValue((lineno = 469, colno = 12, runtime.callWrap(macro_t_28, "_field", context, [l_label,l_name,runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "style"),runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutator"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "link"),runtime.contextOrFrameLookup(context, frame, "linkLabel"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.contextOrFrameLookup(context, frame, "prefix"),runtime.contextOrFrameLookup(context, frame, "shorten"),runtime.contextOrFrameLookup(context, frame, "suffix"),runtime.makeKeywordArgs({"errors": runtime.contextOrFrameLookup(context, frame, "errors"),"hintSuggestion": runtime.contextOrFrameLookup(context, frame, "hintSuggestion"),"caller": (function (){var macro_t_152 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_153 = "";t_153 += "\n";
t_153 += runtime.suppressValue((lineno = 470, colno = 7, runtime.callWrap(macro_t_109, "_input", context, [l_label,l_name,"email",runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "value"),runtime.contextOrFrameLookup(context, frame, "related"),runtime.contextOrFrameLookup(context, frame, "placeholder"),runtime.contextOrFrameLookup(context, frame, "dataComponent"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "error")])), env.opts.autoescape);
t_153 += "\n";
;
frame = frame.pop();
return new runtime.SafeString(t_153);
});
return macro_t_152;})()})])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_150);
});
context.addExport("email");
context.setVariable("email", macro_t_149);
output += "\n\n";
var macro_t_154 = runtime.makeMacro(
["label", "name"], 
["required", "style", "specifier", "mutator", "placeholder", "hint", "link", "linkLabel", "prefix", "related", "error", "value", "shorten", "suffix", "errors", "attrs", "hintSuggestion"], 
function (l_label, l_name, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("placeholder", kwargs.hasOwnProperty("placeholder") ? kwargs["placeholder"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("linkLabel", kwargs.hasOwnProperty("linkLabel") ? kwargs["linkLabel"] : "");
frame.set("prefix", kwargs.hasOwnProperty("prefix") ? kwargs["prefix"] : (lineno = 474, colno = 139, runtime.callWrap(t_148, "emailIcon", context, [])));
frame.set("related", kwargs.hasOwnProperty("related") ? kwargs["related"] : "");
frame.set("error", kwargs.hasOwnProperty("error") ? kwargs["error"] : "");
frame.set("value", kwargs.hasOwnProperty("value") ? kwargs["value"] : "");
frame.set("shorten", kwargs.hasOwnProperty("shorten") ? kwargs["shorten"] : "");
frame.set("suffix", kwargs.hasOwnProperty("suffix") ? kwargs["suffix"] : "");
frame.set("errors", kwargs.hasOwnProperty("errors") ? kwargs["errors"] : runtime.contextOrFrameLookup(context, frame, "None"));
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("hintSuggestion", kwargs.hasOwnProperty("hintSuggestion") ? kwargs["hintSuggestion"] : runtime.contextOrFrameLookup(context, frame, "False"));
var t_155 = "";if(!runtime.contextOrFrameLookup(context, frame, "errors")) {
t_155 += "\n  ";
var t_156;
t_156 = [];
frame.set("errors", t_156, true);
if(frame.topLevel) {
context.setVariable("errors", t_156);
}
if(frame.topLevel) {
context.addExport("errors", t_156);
}
t_155 += "\n";
;
}
t_155 += "\n";
t_155 += runtime.suppressValue((lineno = 478, colno = 12, runtime.callWrap(macro_t_28, "_field", context, [l_label,l_name,runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "style"),runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutator"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "link"),runtime.contextOrFrameLookup(context, frame, "linkLabel"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.contextOrFrameLookup(context, frame, "prefix"),runtime.contextOrFrameLookup(context, frame, "shorten"),runtime.contextOrFrameLookup(context, frame, "suffix"),runtime.makeKeywordArgs({"errors": runtime.contextOrFrameLookup(context, frame, "errors"),"hintSuggestion": runtime.contextOrFrameLookup(context, frame, "hintSuggestion"),"caller": (function (){var macro_t_157 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_158 = "";t_158 += "\n";
t_158 += runtime.suppressValue((lineno = 479, colno = 7, runtime.callWrap(macro_t_109, "_input", context, [l_label,l_name,"email",runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "value"),runtime.contextOrFrameLookup(context, frame, "related"),runtime.contextOrFrameLookup(context, frame, "placeholder"),runtime.contextOrFrameLookup(context, frame, "dataComponent"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.makeKeywordArgs({"attrs": runtime.contextOrFrameLookup(context, frame, "attrs")})])), env.opts.autoescape);
t_158 += "\n";
;
frame = frame.pop();
return new runtime.SafeString(t_158);
});
return macro_t_157;})()})])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_155);
});
context.addExport("email");
context.setVariable("email", macro_t_154);
output += "\n\n";
var macro_t_159 = runtime.makeMacro(
["label", "name"], 
["style", "specifier", "mutator", "required", "placeholder", "hint", "link", "linkLabel", "prefix", "error", "value", "shorten", "suffix", "hintSuggestion", "attrs", "labelLink", "labelLinkText"], 
function (l_label, l_name, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("placeholder", kwargs.hasOwnProperty("placeholder") ? kwargs["placeholder"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("linkLabel", kwargs.hasOwnProperty("linkLabel") ? kwargs["linkLabel"] : "");
frame.set("prefix", kwargs.hasOwnProperty("prefix") ? kwargs["prefix"] : "");
frame.set("error", kwargs.hasOwnProperty("error") ? kwargs["error"] : "");
frame.set("value", kwargs.hasOwnProperty("value") ? kwargs["value"] : "");
frame.set("shorten", kwargs.hasOwnProperty("shorten") ? kwargs["shorten"] : "");
frame.set("suffix", kwargs.hasOwnProperty("suffix") ? kwargs["suffix"] : "");
frame.set("hintSuggestion", kwargs.hasOwnProperty("hintSuggestion") ? kwargs["hintSuggestion"] : runtime.contextOrFrameLookup(context, frame, "False"));
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("labelLink", kwargs.hasOwnProperty("labelLink") ? kwargs["labelLink"] : "");
frame.set("labelLinkText", kwargs.hasOwnProperty("labelLinkText") ? kwargs["labelLinkText"] : "");
var t_160 = "";if(runtime.contextOrFrameLookup(context, frame, "error") == "") {
var t_161;
t_161 = (lineno = 484, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Your phone number must be a minimum of 7 digits"]));
frame.set("error", t_161, true);
if(frame.topLevel) {
context.setVariable("error", t_161);
}
if(frame.topLevel) {
context.addExport("error", t_161);
}
;
}
t_160 += "\n";
t_160 += runtime.suppressValue((lineno = 485, colno = 12, runtime.callWrap(macro_t_28, "_field", context, [l_label,l_name,runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "style"),runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutator"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "link"),runtime.contextOrFrameLookup(context, frame, "linkLabel"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.contextOrFrameLookup(context, frame, "prefix"),runtime.contextOrFrameLookup(context, frame, "shorten"),runtime.contextOrFrameLookup(context, frame, "suffix"),runtime.makeKeywordArgs({"hintSuggestion": runtime.contextOrFrameLookup(context, frame, "hintSuggestion"),"caller": (function (){var macro_t_162 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_163 = "";t_163 += "\n";
t_163 += runtime.suppressValue((lineno = 486, colno = 7, runtime.callWrap(macro_t_109, "_input", context, [l_label,l_name,"tel",runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "value"),runtime.contextOrFrameLookup(context, frame, "related"),runtime.contextOrFrameLookup(context, frame, "placeholder"),runtime.contextOrFrameLookup(context, frame, "dataComponent"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.makeKeywordArgs({"attrs": runtime.contextOrFrameLookup(context, frame, "attrs")})])), env.opts.autoescape);
t_163 += "\n";
;
frame = frame.pop();
return new runtime.SafeString(t_163);
});
return macro_t_162;})()})])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_160);
});
context.addExport("tel");
context.setVariable("tel", macro_t_159);
output += "\n\n";
var macro_t_164 = runtime.makeMacro(
[], 
["label", "name", "style", "specifier", "mutator", "required", "placeholder", "hint", "link", "linkLabel", "error", "prefix", "value", "related", "shorten", "suffix", "dropdown", "attrs"], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", kwargs.hasOwnProperty("label") ? kwargs["label"] : "");
frame.set("name", kwargs.hasOwnProperty("name") ? kwargs["name"] : "");
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("placeholder", kwargs.hasOwnProperty("placeholder") ? kwargs["placeholder"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("linkLabel", kwargs.hasOwnProperty("linkLabel") ? kwargs["linkLabel"] : "");
frame.set("error", kwargs.hasOwnProperty("error") ? kwargs["error"] : "");
frame.set("prefix", kwargs.hasOwnProperty("prefix") ? kwargs["prefix"] : "");
frame.set("value", kwargs.hasOwnProperty("value") ? kwargs["value"] : "");
frame.set("related", kwargs.hasOwnProperty("related") ? kwargs["related"] : "");
frame.set("shorten", kwargs.hasOwnProperty("shorten") ? kwargs["shorten"] : "");
frame.set("suffix", kwargs.hasOwnProperty("suffix") ? kwargs["suffix"] : "");
frame.set("dropdown", kwargs.hasOwnProperty("dropdown") ? kwargs["dropdown"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
var t_165 = "";if(runtime.contextOrFrameLookup(context, frame, "error") == "") {
var t_166;
t_166 = (lineno = 491, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Please enter a search term"]));
frame.set("error", t_166, true);
if(frame.topLevel) {
context.setVariable("error", t_166);
}
if(frame.topLevel) {
context.addExport("error", t_166);
}
;
}
t_165 += "\n";
t_165 += runtime.suppressValue((lineno = 492, colno = 12, runtime.callWrap(macro_t_28, "_field", context, [macro_t_37,runtime.contextOrFrameLookup(context, frame, "name"),runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "style"),runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutator"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "link"),runtime.contextOrFrameLookup(context, frame, "linkLabel"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.contextOrFrameLookup(context, frame, "prefix"),runtime.contextOrFrameLookup(context, frame, "shorten"),runtime.contextOrFrameLookup(context, frame, "divider"),runtime.contextOrFrameLookup(context, frame, "suffix"),runtime.contextOrFrameLookup(context, frame, "dropdown"),runtime.makeKeywordArgs({"caller": (function (){var macro_t_167 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_168 = "";t_168 += "\n";
t_168 += runtime.suppressValue((lineno = 493, colno = 12, runtime.callWrap(macro_t_111, "pseudoInput", context, [macro_t_37,runtime.contextOrFrameLookup(context, frame, "name"),runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "value"),runtime.contextOrFrameLookup(context, frame, "related"),runtime.contextOrFrameLookup(context, frame, "placeholder"),"search-searchBox",runtime.makeKeywordArgs({"attrs": runtime.contextOrFrameLookup(context, frame, "attrs")})])), env.opts.autoescape);
t_168 += "\n";
;
frame = frame.pop();
return new runtime.SafeString(t_168);
});
return macro_t_167;})()})])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_165);
});
context.addExport("search");
context.setVariable("search", macro_t_164);
output += "\n\n";
var macro_t_169 = runtime.makeMacro(
["label", "name"], 
["style", "specifier", "mutator", "required", "placeholder", "hint", "link", "linkLabel", "error", "prefix", "value", "related", "shorten", "suffix", "dropdown", "dataComponent", "attrs"], 
function (l_label, l_name, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("placeholder", kwargs.hasOwnProperty("placeholder") ? kwargs["placeholder"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("linkLabel", kwargs.hasOwnProperty("linkLabel") ? kwargs["linkLabel"] : "");
frame.set("error", kwargs.hasOwnProperty("error") ? kwargs["error"] : "");
frame.set("prefix", kwargs.hasOwnProperty("prefix") ? kwargs["prefix"] : "");
frame.set("value", kwargs.hasOwnProperty("value") ? kwargs["value"] : "");
frame.set("related", kwargs.hasOwnProperty("related") ? kwargs["related"] : "");
frame.set("shorten", kwargs.hasOwnProperty("shorten") ? kwargs["shorten"] : "");
frame.set("suffix", kwargs.hasOwnProperty("suffix") ? kwargs["suffix"] : "");
frame.set("dropdown", kwargs.hasOwnProperty("dropdown") ? kwargs["dropdown"] : "");
frame.set("dataComponent", kwargs.hasOwnProperty("dataComponent") ? kwargs["dataComponent"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
var t_170 = "";if(runtime.contextOrFrameLookup(context, frame, "error") == "") {
var t_171;
t_171 = (lineno = 498, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Please enter a search term"]));
frame.set("error", t_171, true);
if(frame.topLevel) {
context.setVariable("error", t_171);
}
if(frame.topLevel) {
context.addExport("error", t_171);
}
;
}
t_170 += "\n";
t_170 += runtime.suppressValue((lineno = 499, colno = 12, runtime.callWrap(macro_t_28, "_field", context, [l_label,l_name,runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "style"),runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutator"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "link"),runtime.contextOrFrameLookup(context, frame, "linkLabel"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.contextOrFrameLookup(context, frame, "prefix"),runtime.contextOrFrameLookup(context, frame, "shorten"),runtime.contextOrFrameLookup(context, frame, "divider"),runtime.contextOrFrameLookup(context, frame, "suffix"),runtime.contextOrFrameLookup(context, frame, "dropdown"),runtime.makeKeywordArgs({"caller": (function (){var macro_t_172 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_173 = "";t_173 += "\n";
t_173 += runtime.suppressValue((lineno = 500, colno = 12, runtime.callWrap(macro_t_111, "pseudoInput", context, [l_label,l_name,runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "value"),runtime.contextOrFrameLookup(context, frame, "related"),runtime.contextOrFrameLookup(context, frame, "placeholder"),runtime.contextOrFrameLookup(context, frame, "dataComponent"),runtime.makeKeywordArgs({"attrs": runtime.contextOrFrameLookup(context, frame, "attrs")})])), env.opts.autoescape);
t_173 += "\n";
;
frame = frame.pop();
return new runtime.SafeString(t_173);
});
return macro_t_172;})()})])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_170);
});
context.addExport("calculatorSearch");
context.setVariable("calculatorSearch", macro_t_169);
output += "\n\n\n";
var macro_t_174 = runtime.makeMacro(
["label", "name"], 
["style", "specifier", "mutator", "required", "placeholder", "dataComponent", "fieldDataComponent", "hint", "link", "linkLabel", "error", "prefix", "value", "shorten", "suffix", "attrs"], 
function (l_label, l_name, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("placeholder", kwargs.hasOwnProperty("placeholder") ? kwargs["placeholder"] : "");
frame.set("dataComponent", kwargs.hasOwnProperty("dataComponent") ? kwargs["dataComponent"] : "");
frame.set("fieldDataComponent", kwargs.hasOwnProperty("fieldDataComponent") ? kwargs["fieldDataComponent"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "eg. www.escrow.com");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("linkLabel", kwargs.hasOwnProperty("linkLabel") ? kwargs["linkLabel"] : "");
frame.set("error", kwargs.hasOwnProperty("error") ? kwargs["error"] : "");
frame.set("prefix", kwargs.hasOwnProperty("prefix") ? kwargs["prefix"] : "http://");
frame.set("value", kwargs.hasOwnProperty("value") ? kwargs["value"] : "");
frame.set("shorten", kwargs.hasOwnProperty("shorten") ? kwargs["shorten"] : "");
frame.set("suffix", kwargs.hasOwnProperty("suffix") ? kwargs["suffix"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
var t_175 = "";if(runtime.contextOrFrameLookup(context, frame, "error") == "") {
var t_176;
t_176 = (lineno = 506, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Please enter a well formed domain name"]));
frame.set("error", t_176, true);
if(frame.topLevel) {
context.setVariable("error", t_176);
}
if(frame.topLevel) {
context.addExport("error", t_176);
}
;
}
t_175 += "\n";
t_175 += runtime.suppressValue((lineno = 507, colno = 12, runtime.callWrap(macro_t_28, "_field", context, [l_label,l_name,runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "style"),runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutator"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "link"),runtime.contextOrFrameLookup(context, frame, "linkLabel"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.contextOrFrameLookup(context, frame, "prefix"),runtime.contextOrFrameLookup(context, frame, "shorten"),runtime.contextOrFrameLookup(context, frame, "suffix"),runtime.makeKeywordArgs({"dataComponent": runtime.contextOrFrameLookup(context, frame, "fieldDataComponent"),"caller": (function (){var macro_t_177 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_178 = "";t_178 += "\n";
t_178 += runtime.suppressValue((lineno = 508, colno = 7, runtime.callWrap(macro_t_109, "_input", context, [l_label,l_name,"url",runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "value"),runtime.contextOrFrameLookup(context, frame, "related"),runtime.contextOrFrameLookup(context, frame, "placeholder"),runtime.contextOrFrameLookup(context, frame, "dataComponent"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.makeKeywordArgs({"attrs": runtime.contextOrFrameLookup(context, frame, "attrs")})])), env.opts.autoescape);
t_178 += "\n";
;
frame = frame.pop();
return new runtime.SafeString(t_178);
});
return macro_t_177;})()})])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_175);
});
context.addExport("url");
context.setVariable("url", macro_t_174);
output += "\n\n";
var macro_t_179 = runtime.makeMacro(
["label", "name"], 
["required", "style", "specifier", "mutator", "placeholder", "hint", "link", "linkLabel", "prefix", "error", "value", "related", "shorten", "divider", "suffix"], 
function (l_label, l_name, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("placeholder", kwargs.hasOwnProperty("placeholder") ? kwargs["placeholder"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("linkLabel", kwargs.hasOwnProperty("linkLabel") ? kwargs["linkLabel"] : "");
frame.set("prefix", kwargs.hasOwnProperty("prefix") ? kwargs["prefix"] : "");
frame.set("error", kwargs.hasOwnProperty("error") ? kwargs["error"] : "");
frame.set("value", kwargs.hasOwnProperty("value") ? kwargs["value"] : "");
frame.set("related", kwargs.hasOwnProperty("related") ? kwargs["related"] : "");
frame.set("shorten", kwargs.hasOwnProperty("shorten") ? kwargs["shorten"] : "");
frame.set("divider", kwargs.hasOwnProperty("divider") ? kwargs["divider"] : "");
frame.set("suffix", kwargs.hasOwnProperty("suffix") ? kwargs["suffix"] : "");
var t_180 = "";t_180 += runtime.suppressValue((lineno = 513, colno = 12, runtime.callWrap(macro_t_28, "_field", context, [l_label,l_name,runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "style"),runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutator"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "link"),runtime.contextOrFrameLookup(context, frame, "linkLabel"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.contextOrFrameLookup(context, frame, "prefix"),runtime.contextOrFrameLookup(context, frame, "shorten"),runtime.contextOrFrameLookup(context, frame, "divider"),runtime.contextOrFrameLookup(context, frame, "suffix"),runtime.makeKeywordArgs({"caller": (function (){var macro_t_181 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_182 = "";t_182 += "\n<div class=\"field-sibling\">";
t_182 += runtime.suppressValue((lineno = 514, colno = 34, runtime.callWrap(macro_t_109, "_input", context, [l_label,l_name + "-one","text",runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "value"),runtime.contextOrFrameLookup(context, frame, "related"),(lineno = 514, colno = 98, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Address line 1"])),runtime.contextOrFrameLookup(context, frame, "dataComponent"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "error")])), env.opts.autoescape);
t_182 += "</div>\n<div class=\"field-sibling\">";
t_182 += runtime.suppressValue((lineno = 515, colno = 34, runtime.callWrap(macro_t_109, "_input", context, [l_label,l_name + "-two","text","",runtime.contextOrFrameLookup(context, frame, "value"),runtime.contextOrFrameLookup(context, frame, "related"),(lineno = 515, colno = 92, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Address line 2"])),runtime.contextOrFrameLookup(context, frame, "dataComponent"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "error")])), env.opts.autoescape);
t_182 += "</div>\n";
;
frame = frame.pop();
return new runtime.SafeString(t_182);
});
return macro_t_181;})()})])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_180);
});
context.addExport("address");
context.setVariable("address", macro_t_179);
output += "\n\n";
var macro_t_183 = runtime.makeMacro(
["label", "name"], 
["style", "specifier", "mutator", "required", "placeholder", "dataComponent", "fieldDataComponent", "hint", "link", "linkLabel", "error", "prefix", "value", "shorten", "suffix", "attrs", "multiple"], 
function (l_label, l_name, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("placeholder", kwargs.hasOwnProperty("placeholder") ? kwargs["placeholder"] : "");
frame.set("dataComponent", kwargs.hasOwnProperty("dataComponent") ? kwargs["dataComponent"] : "");
frame.set("fieldDataComponent", kwargs.hasOwnProperty("fieldDataComponent") ? kwargs["fieldDataComponent"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("linkLabel", kwargs.hasOwnProperty("linkLabel") ? kwargs["linkLabel"] : "");
frame.set("error", kwargs.hasOwnProperty("error") ? kwargs["error"] : "");
frame.set("prefix", kwargs.hasOwnProperty("prefix") ? kwargs["prefix"] : "");
frame.set("value", kwargs.hasOwnProperty("value") ? kwargs["value"] : "");
frame.set("shorten", kwargs.hasOwnProperty("shorten") ? kwargs["shorten"] : "");
frame.set("suffix", kwargs.hasOwnProperty("suffix") ? kwargs["suffix"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("multiple", kwargs.hasOwnProperty("multiple") ? kwargs["multiple"] : runtime.contextOrFrameLookup(context, frame, "False"));
var t_184 = "";t_184 += "<label for=\"";
t_184 += runtime.suppressValue(l_name, env.opts.autoescape);
t_184 += "\" ";
if(runtime.contextOrFrameLookup(context, frame, "specifier")) {
t_184 += "class=\"";
t_184 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "specifier"), env.opts.autoescape);
t_184 += "\"";
;
}
t_184 += runtime.suppressValue(env.getFilter("xmlattr").call(context, runtime.contextOrFrameLookup(context, frame, "attrs")), env.opts.autoescape);
t_184 += ">\n  ";
t_184 += runtime.suppressValue(l_label, env.opts.autoescape);
t_184 += "\n</label>\n<div class=\"is-hidden field-error startForm-error\" data-target=\"field-error\" data-attr=\"error-invalid\">\n  <span class=\"field-errorMsg\" id=\"error-";
t_184 += runtime.suppressValue(l_name, env.opts.autoescape);
t_184 += "\">";
t_184 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "error"), env.opts.autoescape);
t_184 += "</span>\n</div>\n<input class=\"is-hidden\" type=\"file\" id=\"";
t_184 += runtime.suppressValue(l_name, env.opts.autoescape);
t_184 += "\" name=\"";
t_184 += runtime.suppressValue(l_name, env.opts.autoescape);
t_184 += "\" data-target=\"field-focusable\" ";
if(runtime.contextOrFrameLookup(context, frame, "multiple")) {
t_184 += "multiple";
;
}
t_184 += " ";
t_184 += runtime.suppressValue(env.getFilter("xmlattr").call(context, runtime.contextOrFrameLookup(context, frame, "attrs")), env.opts.autoescape);
t_184 += ">";
;
frame = frame.pop();
return new runtime.SafeString(t_184);
});
context.addExport("file");
context.setVariable("file", macro_t_183);
output += "\n\n";
var macro_t_185 = runtime.makeMacro(
["label", "name"], 
["required", "style", "specifier", "mutator", "placeholder", "dataComponent", "fieldDataComponent", "hint", "link", "linkLabel", "prefix", "error", "value", "related", "shorten", "divider", "suffix", "attrs"], 
function (l_label, l_name, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("placeholder", kwargs.hasOwnProperty("placeholder") ? kwargs["placeholder"] : "");
frame.set("dataComponent", kwargs.hasOwnProperty("dataComponent") ? kwargs["dataComponent"] : "");
frame.set("fieldDataComponent", kwargs.hasOwnProperty("fieldDataComponent") ? kwargs["fieldDataComponent"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("linkLabel", kwargs.hasOwnProperty("linkLabel") ? kwargs["linkLabel"] : "");
frame.set("prefix", kwargs.hasOwnProperty("prefix") ? kwargs["prefix"] : "");
frame.set("error", kwargs.hasOwnProperty("error") ? kwargs["error"] : "");
frame.set("value", kwargs.hasOwnProperty("value") ? kwargs["value"] : "");
frame.set("related", kwargs.hasOwnProperty("related") ? kwargs["related"] : "");
frame.set("shorten", kwargs.hasOwnProperty("shorten") ? kwargs["shorten"] : "");
frame.set("divider", kwargs.hasOwnProperty("divider") ? kwargs["divider"] : "");
frame.set("suffix", kwargs.hasOwnProperty("suffix") ? kwargs["suffix"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
var t_186 = "";t_186 += runtime.suppressValue((lineno = 530, colno = 12, runtime.callWrap(macro_t_28, "_field", context, [l_label,l_name,runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "style"),runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutator"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "link"),runtime.contextOrFrameLookup(context, frame, "linkLabel"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.contextOrFrameLookup(context, frame, "prefix"),runtime.contextOrFrameLookup(context, frame, "shorten"),runtime.contextOrFrameLookup(context, frame, "divider"),runtime.contextOrFrameLookup(context, frame, "suffix"),runtime.makeKeywordArgs({"dataComponent": runtime.contextOrFrameLookup(context, frame, "fieldDataComponent"),"caller": (function (){var macro_t_187 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_188 = "";t_188 += "\n  ";
t_188 += runtime.suppressValue((lineno = 531, colno = 9, runtime.callWrap(macro_t_35, "_label", context, [l_name,(lineno = 531, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Upload Method"])),runtime.contextOrFrameLookup(context, frame, "required")])), env.opts.autoescape);
t_188 += "\n  <div data-target=\"file-uploader\" class=\"fileUploader\">\n    <input class=\"fileUploader-input\" name=\"";
t_188 += runtime.suppressValue(l_name, env.opts.autoescape);
t_188 += "\" type=\"file\" multiple data-file-upload=\"file\" data-target=\"field-focusable\" ";
if(runtime.contextOrFrameLookup(context, frame, "required")) {
t_188 += "required=true";
;
}
t_188 += " ";
t_188 += runtime.suppressValue(env.getFilter("xmlattr").call(context, runtime.contextOrFrameLookup(context, frame, "attrs")), env.opts.autoescape);
t_188 += ">\n    <div class=\"fileUploader-choice\">\n      <div class=\"fileUploader-choice-items\">\n        <span class=\"fileUploader-choice-btn btn btn--primary\" data-choose-file>\n          <span class=\"media--available@tablet\">";
t_188 += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_189 = "";t_189 += "Take Photo or ";
cb(null, t_189);
;
return t_189;
}
), true && env.opts.autoescape);
t_188 += "</span>";
t_188 += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_190 = "";t_190 += "File Upload";
cb(null, t_190);
;
return t_190;
}
), true && env.opts.autoescape);
t_188 += "\n        </span>\n        <span class=\"fileUploader-separator media--hidden@tablet\">or</span>\n        <span class=\"fileUploader-choice-btn fileUploader-cameraBtn btn btn--primary media--hidden@tablet\" data-take-photo data-target-modal=\"embedded-camera\">\n          ";
t_188 += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_191 = "";t_191 += "Webcam";
cb(null, t_191);
;
return t_191;
}
), true && env.opts.autoescape);
t_188 += "\n        </span>\n      </div>\n      <div class=\"fileUploader-choice-note\">\n        <div class=\"fileUploader-note\">\n          ";
t_188 += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_192 = "";t_192 += "Allowed documents: jpg, png, gif, tiff, bmp, pdf - 100MB maximum file size.";
cb(null, t_192);
;
return t_192;
}
), true && env.opts.autoescape);
t_188 += "\n        </div>\n      </div>\n    </div>\n  </div>\n";
;
frame = frame.pop();
return new runtime.SafeString(t_188);
});
return macro_t_187;})()})])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_186);
});
context.addExport("uploadWell");
context.setVariable("uploadWell", macro_t_185);
output += "\n\n";
output += "\n\n";
var macro_t_193 = runtime.makeMacro(
[], 
["label", "name", "required", "value", "related", "placeholder", "dataComponent", "attrs"], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", kwargs.hasOwnProperty("label") ? kwargs["label"] : "");
frame.set("name", kwargs.hasOwnProperty("name") ? kwargs["name"] : "");
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("value", kwargs.hasOwnProperty("value") ? kwargs["value"] : "");
frame.set("related", kwargs.hasOwnProperty("related") ? kwargs["related"] : "");
frame.set("placeholder", kwargs.hasOwnProperty("placeholder") ? kwargs["placeholder"] : "");
frame.set("dataComponent", kwargs.hasOwnProperty("dataComponent") ? kwargs["dataComponent"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
var t_194 = "";t_194 += "<input class=\"defaultInput ";
t_194 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "name"), env.opts.autoescape);
t_194 += "\" data-target=\"field-focusable\" type=\"text\" autocomplete=\"off\"\n";
if(runtime.contextOrFrameLookup(context, frame, "name")) {
t_194 += " name=\"";
t_194 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "name"), env.opts.autoescape);
t_194 += "\" ";
;
}
t_194 += "\n";
if(runtime.contextOrFrameLookup(context, frame, "required")) {
t_194 += " required ";
;
}
t_194 += "\n";
if(runtime.contextOrFrameLookup(context, frame, "dataComponent")) {
t_194 += " data-component=\"";
t_194 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "dataComponent"), env.opts.autoescape);
t_194 += "\" ";
;
}
t_194 += "\n";
if(runtime.contextOrFrameLookup(context, frame, "related")) {
t_194 += " data-related=\"";
t_194 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "related"), env.opts.autoescape);
t_194 += "\" ";
;
}
t_194 += "aria-describedby=\"";
if(runtime.contextOrFrameLookup(context, frame, "hint")) {
t_194 += "hint-";
t_194 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "name"), env.opts.autoescape);
;
}
t_194 += " ";
if(runtime.contextOrFrameLookup(context, frame, "error")) {
t_194 += "error-";
t_194 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "name"), env.opts.autoescape);
;
}
t_194 += "\"\n";
if(runtime.contextOrFrameLookup(context, frame, "placeholder")) {
t_194 += " data-placeholder=\"";
t_194 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "placeholder"), env.opts.autoescape);
t_194 += "\" ";
;
}
t_194 += "\n";
t_194 += runtime.suppressValue(env.getFilter("xmlattr").call(context, runtime.contextOrFrameLookup(context, frame, "attrs")), env.opts.autoescape);
t_194 += " />";
;
frame = frame.pop();
return new runtime.SafeString(t_194);
});
context.setVariable("_categoryInput", macro_t_193);
output += "\n\n";
var macro_t_195 = runtime.makeMacro(
["label", "name"], 
["style", "specifier", "mutator", "required", "placeholder", "hint", "link", "linkLabel", "error", "errors", "prefix", "value", "related", "shorten", "suffix", "dropdown", "dataComponent", "attrs"], 
function (l_label, l_name, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("name", l_name);
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("mutator", kwargs.hasOwnProperty("mutator") ? kwargs["mutator"] : "");
frame.set("required", kwargs.hasOwnProperty("required") ? kwargs["required"] : "");
frame.set("placeholder", kwargs.hasOwnProperty("placeholder") ? kwargs["placeholder"] : "");
frame.set("hint", kwargs.hasOwnProperty("hint") ? kwargs["hint"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("linkLabel", kwargs.hasOwnProperty("linkLabel") ? kwargs["linkLabel"] : "");
frame.set("error", kwargs.hasOwnProperty("error") ? kwargs["error"] : "");
frame.set("errors", kwargs.hasOwnProperty("errors") ? kwargs["errors"] : runtime.contextOrFrameLookup(context, frame, "None"));
frame.set("prefix", kwargs.hasOwnProperty("prefix") ? kwargs["prefix"] : "");
frame.set("value", kwargs.hasOwnProperty("value") ? kwargs["value"] : "");
frame.set("related", kwargs.hasOwnProperty("related") ? kwargs["related"] : "");
frame.set("shorten", kwargs.hasOwnProperty("shorten") ? kwargs["shorten"] : "");
frame.set("suffix", kwargs.hasOwnProperty("suffix") ? kwargs["suffix"] : "");
frame.set("dropdown", kwargs.hasOwnProperty("dropdown") ? kwargs["dropdown"] : "");
frame.set("dataComponent", kwargs.hasOwnProperty("dataComponent") ? kwargs["dataComponent"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
var t_196 = "";t_196 += "<div class=\"field\" data-component=\"category-container\">\n  ";
t_196 += runtime.suppressValue((lineno = 569, colno = 14, runtime.callWrap(macro_t_28, "_field", context, [l_label,l_name,runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "style"),runtime.contextOrFrameLookup(context, frame, "specifier"),runtime.contextOrFrameLookup(context, frame, "mutator"),runtime.contextOrFrameLookup(context, frame, "hint"),runtime.contextOrFrameLookup(context, frame, "link"),runtime.contextOrFrameLookup(context, frame, "linkLabel"),runtime.contextOrFrameLookup(context, frame, "error"),runtime.contextOrFrameLookup(context, frame, "prefix"),runtime.contextOrFrameLookup(context, frame, "shorten"),runtime.contextOrFrameLookup(context, frame, "divider"),runtime.contextOrFrameLookup(context, frame, "suffix"),runtime.contextOrFrameLookup(context, frame, "dropdown"),runtime.makeKeywordArgs({"errors": runtime.contextOrFrameLookup(context, frame, "errors"),"caller": (function (){var macro_t_197 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_198 = "";t_198 += "\n  ";
t_198 += runtime.suppressValue((lineno = 570, colno = 17, runtime.callWrap(macro_t_193, "_categoryInput", context, [l_label,l_name,runtime.contextOrFrameLookup(context, frame, "required"),runtime.contextOrFrameLookup(context, frame, "value"),runtime.contextOrFrameLookup(context, frame, "related"),runtime.contextOrFrameLookup(context, frame, "placeholder"),runtime.contextOrFrameLookup(context, frame, "dataComponent"),runtime.makeKeywordArgs({"attrs": runtime.contextOrFrameLookup(context, frame, "attrs")})])), env.opts.autoescape);
t_198 += "\n  ";
;
frame = frame.pop();
return new runtime.SafeString(t_198);
});
return macro_t_197;})()})])), env.opts.autoescape);
t_196 += "\n</div>";
;
frame = frame.pop();
return new runtime.SafeString(t_196);
});
context.addExport("categorySearch");
context.setVariable("categorySearch", macro_t_195);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/components/inputs.html"] , dependencies)