var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/icons/ui-close.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
[], 
["specifier"], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
var t_2 = "";t_2 += "<svg class=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "specifier"), env.opts.autoescape);
t_2 += "\" width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M13.705 2.292c-.394-.39-1.032-.39-1.426 0l-4.302 4.3-4.238-4.24c-.39-.39-1.026-.39-1.415 0-.39.39-.39 1.03 0 1.42l4.24 4.23-4.27 4.27c-.393.39-.393 1.03 0 1.43.394.39 1.033.39 1.427 0l4.268-4.27 4.24 4.24c.39.39 1.025.39 1.415 0 .39-.39.39-1.03 0-1.42l-4.24-4.23 4.3-4.3c.393-.4.393-1.03 0-1.43z\" fill-rule=\"evenodd\"/></svg>";
;
frame = frame.pop();
return new runtime.SafeString(t_2);
});
context.addExport("close");
context.setVariable("close", macro_t_1);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/icons/ui-close.html"] , dependencies)