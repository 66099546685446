const AuthenticationConstants = {
  LOCALSTORAGE_MODE_KEY: 'AuthenticationConstants.REACT_AUTH_FORM_MODE',
  MODE_LOGIN: 'AuthenticationConstants.MODE_LOGIN',
  MODE_SIGNUP: 'AuthenticationConstants.MODE_SIGNUP',

  AUTHENTICATION_FORM: 'authentication-form',

  PREFILL_USERNAME: 'AuthenticationConstants.PREFILL_USERNAME',

  ACTION_SET_MODE: 'AuthenticationConstants.ACTION_SET_MODE',
  ACTION_FORM_LOGIN_SIGNUP: 'AuthenticationConstants.ACTION_FORM_LOGIN_SIGNUP',
  ACTION_LOGIN: 'AuthenticationConstants.ACTION_LOGIN',
  ACTION_LOGIN_SSO: 'AuthenticationConstants.ACTION_LOGIN_SSO',
  ACTION_SIGNUP: 'AuthenticationConstants.ACTION_SIGNUP',
  ACTION_UPDATE_CONTACT_DETAILS: 'AuthenticationConstants.ACTION_UPDATE_CONTACT_DETAILS',

  TWO_FA_VALIDATE: 'AuthenticationConstants.TWO_FA_VALIDATE',

  DEFAULT_LOGIN_PAGE: 'AuthenticationConstants.DEFAULT_LOGIN_PAGE',
  INTEGRATIONS_LOGIN_PAGE: 'AuthenticationConstants.INTEGRATIONS_LOGIN_PAGE',
  CHECKOUT_LOGIN_PAGE: 'AuthenticationConstants.CHECKOUT_LOGIN_PAGE',
  INTEGRATIONS_LANDING_LOGIN_PAGE: 'AuthenticationConstants.INTEGRATIONS_LANDING_LOGIN_PAGE',
  KYC_LOGIN_PAGE: 'AuthenticationConstants.KYC_LOGIN_PAGE',
  OFFER_MANAGEMENT_LOGIN_PAGE: 'AuthenticationConstants.OFFER_MANAGEMENT_LOGIN_PAGE',
  TEST_LOGIN_PAGE: 'AuthenticationConstants.TEST_LOGIN_PAGE',
};

AuthenticationConstants.CONTACT_DETAILS_TO_API_BODY = {
  firstName: 'FirstName',
  lastName: 'LastName',
  addressLine1: 'PersonalAddress.Street1',
  addressLine2: 'PersonalAddress.Street2',
  city: 'PersonalAddress.City',
  state: 'PersonalAddress.State',
  postCode: 'PersonalAddress.Zip',
  isCompany: 'IsCompany',
  companyName: 'CompanyName',
  companyCity: 'CompanyAddress.City',
  companyState: 'CompanyAddress.State',
  companyAddressLine1: 'CompanyAddress.Street1',
  companyAddressLine2: 'CompanyAddress.Street2',
  companyPostCode: 'CompanyAddress.Zip',

  dateOfBirth: 'DateOfBirth',
  phone: 'Phone',
  displayName: 'DisplayName',
  email: 'Email',
  displayNameType: 'DisplayNameType',
  issueCheckInCompanyName: 'IssueCheckInCompanyName',
  shippingAddress: 'ShippingAddress',
  useUsernameAsDisplayName: 'UseUsernameAsDisplayName',
  username: 'Username',
  companyCountry: 'CompanyAddress.Country',
  country: 'PersonalAddress.Country',
};

export default AuthenticationConstants;
