import FileUploadConstants from 'spa/constants/FileUploadConstants';

const fileUploadStart = () => ({
  type: FileUploadConstants.FILE_UPLOAD_START,
});

const fileUploadEnd = () => ({
  type: FileUploadConstants.FILE_UPLOAD_END,
});

export { fileUploadStart, fileUploadEnd };
