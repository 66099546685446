import React from 'react';

import GetStartedActions from 'spa/containers/GetStartedActions';

import Footer from 'spa/components/Footer';

const GettingStartedPage = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--12">
        <h1 className="integrationsPortal-title">Getting started</h1>
        <GetStartedActions />
        <Footer />
      </div>
    </div>
  </div>
);

export default GettingStartedPage;
