import React, { Fragment } from 'react';
import moment from 'moment';
import { Line as LineChart, Bar as BarChart, Doughnut as DoughnutChart } from 'react-chartjs-2';
import INTERVAL from 'spa/constants/IntervalConstants';
import { gettext } from '../../../utils/filters';

export const getChartOptions = (options = {}) => ({
  elements: {
    point: {
      pointStyle: 'circle',
      radius: 4,
      borderWidth: 1,
      hitRadius: 1,
    },
    line: {
      tension: 0.4,
      borderWidth: 2,
      fill: true,
    },
    arc: {
      borderWidth: 2,
      borderColor: '#fff',
    },
  },
  tooltips: {
    caretSize: 5,
  },
  legend: {
    display: false,
  },
  responsive: true,
  maintainAspectRatio: false,
  ...options,
});

export const getAxesChartOptions = (options = {}) => ({
  ...getChartOptions(),
  scales: {
    xAxes: [
      {
        gridLines: {
          color: 'rgba(0,0,0,.05)',
          lineWidth: 1,
          offsetGridLines: true,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          stepSize: 1,
          beginAtZero: true,
          maxTicksLimit: 5,
        },
        gridLines: {
          color: 'rgba(0,0,0,.05)',
          lineWidth: 1,
          offsetGridLines: false,
        },
      },
    ],
  },
  tooltips: {
    displayColors: false,
  },
  ...options,
});

export const getDoughnutChartOptions = (options = {}) => ({
  ...getChartOptions(),
  cutoutPercentage: 60,
  animation: {
    easing: 'easeOutBounce',
    animateRotate: true,
    animateScale: false,
    duration: 1000,
  },
  tooltips: {
    callbacks: {
      title: (tooltipItem, data) => data.labels[tooltipItem[0].index],
      label: (tooltipItem, data) => {
        const dataset = data.datasets[tooltipItem.datasetIndex];
        const total = dataset.data.reduce(
          (previousValue, currentValue) => previousValue + currentValue
        );
        const currentValue = dataset.data[tooltipItem.index];
        const percentage = Math.floor((currentValue / total) * 100 * 100) / 100;
        return data.labels.length > 1 ? gettext(`${currentValue} (${percentage}%)`) : null;
      },
    },
  },
  ...options,
});

export const getDatasetOptions = (options = {}) => ({
  backgroundColor: 'rgba(0,43,73,0.5)',
  borderColor: 'rgba(0,43,73,1)',
  pointBackgroundColor: 'rgba(0,43,73,1)',
  pointBorderColor: '#fff',
  pointHoverBackgroundColor: '#fff',
  pointHoverBorderColor: 'rgba(220,220,220,1)',
  ...options,
});

export const getChartLabels = (data) => data.map((item) => item.label);

export const getChartValues = (data) => data.map((item) => item.value);

export const hasNonZeroValue = (data) => data.some((item) => item !== 0);

export const getDateRange = (startDate, endDate, interval, format) => {
  let dateRange = [];
  let nextDate = moment(startDate).format(format);
  while (moment(nextDate).isSameOrBefore(moment(endDate).format(format))) {
    dateRange = dateRange.concat(nextDate);
    nextDate = moment(nextDate).add(1, interval).format(format);
  }

  return dateRange;
};

export const getDateRangeDataSet = (data = [], interval, startDate, endDate, labelFormat) => {
  const [mInterval, format] = INTERVAL[interval.toUpperCase()].format;
  const labels = getDateRange(startDate, endDate, mInterval, format);
  const dataMap = data.reduce((acc, cur) => ({ ...acc, [cur.label]: cur.value }), {});

  const labelFormatter = (dateLabel) =>
    (labelFormat && moment(dateLabel).format(labelFormat)) || dateLabel;

  return labels.map((label) => ({ label: labelFormatter(label), value: dataMap[label] || 0 }));
};

export const BasicBarChart = ({ chartData, chartOptions = {} }) => (
  <Fragment>
    {chartData.labels.length > 0 ? (
      <BarChart data={chartData} options={getAxesChartOptions(chartOptions)} />
    ) : (
      <div className="chartPlaceholder">No data available</div>
    )}
  </Fragment>
);

export const BasicLineChart = ({ chartData, chartOptions = {} }) => (
  <Fragment>
    {chartData.labels.length > 0 ? (
      <LineChart data={chartData} options={getAxesChartOptions(chartOptions)} />
    ) : (
      <div className="chartPlaceholder">No data available</div>
    )}
  </Fragment>
);

export const BasicDoughnutChart = ({ chartData, chartOptions = {} }) => {
  const defaultData = {
    labels: ['No data available'],
    datasets: [
      {
        data: [1],
        backgroundColor: ['#E8EEF2'],
      },
    ],
  };

  return (
    <DoughnutChart
      data={hasNonZeroValue(chartData.datasets[0].data) ? chartData : defaultData}
      options={getDoughnutChartOptions(chartOptions)}
    />
  );
};

export default BasicLineChart;
