import request from 'browser-request';

import AuthenticationActions from './AuthenticationActions';
import ModalActions from './ModalActions';
import FormConstants from '../constants/FormConstants';
import ErrorMessages from '../constants/ErrorMessages';
import AppDispatcher from '../dispatcher/AppDispatcher';

export class FormActions {
  constructor(location = window.location) {
    this.location = location;
  }

  validate(
    formName,
    fieldName,
    fieldType,
    contents,
    override = false,
    required = true,
    skipValidation = false
  ) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.FIELD_CHANGED,
      attributes: {
        form: formName,
        field: fieldName,
        contents: contents,
        type: fieldType,
        override: override,
        required: required,
        skipValidation: skipValidation,
      },
    });
  }

  invalidateField(formName, fieldName, contents) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.FIELD_INVALID,
      attributes: {
        form: formName,
        field: fieldName,
        contents: contents,
      },
    });
  }

  bulkValidate(
    formName,
    fieldNames,
    fieldTypes,
    fieldContents,
    fieldRequired,
    fieldSkipValidation = undefined
  ) {
    const fields = [];
    fieldNames.forEach((fieldName, index) => {
      const type = fieldTypes[index];
      const contents = fieldContents[index];
      const required = fieldRequired[index];
      fields.push({
        form: formName,
        field: fieldName,
        contents: contents,
        type: type,
        override: false,
        required: required,
        skipValidation: fieldSkipValidation !== undefined ? fieldSkipValidation[index] : false,
      });
    });

    AppDispatcher.handleViewAction({
      actionType: FormConstants.FIELDS_CHANGED,
      attributes: {
        form: formName,
        fields: fields,
      },
    });
  }

  initialiseField(formName, fieldName, valid) {
    if (valid) {
      AppDispatcher.handleViewAction({
        actionType: FormConstants.FIELD_VALID,
        attributes: {
          form: formName,
          field: fieldName,
        },
      });
    } else {
      this.invalidateField(formName, fieldName);
    }
  }

  initialiseFields(formName, fieldNames, valid) {
    if (valid) {
      AppDispatcher.handleViewAction({
        actionType: FormConstants.FIELDS_VALID,
        attributes: {
          form: formName,
          fields: fieldNames,
        },
      });
    } else {
      AppDispatcher.handleViewAction({
        actionType: FormConstants.FIELDS_INVALID,
        attributes: {
          form: formName,
          fields: fieldNames,
        },
      });
    }
  }

  clearValidation(formName, fieldName) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.CLEAR_FIELD_VALIDATION,
      attributes: {
        form: formName,
        field: fieldName,
      },
    });
  }

  /**
   * Clears the validation status for the given field names in the relevant
   * form.
   *
   * @param {String} formName The name of the form
   * @param {Array[String]} fieldNames The names of the fields to clear the
   * validations for.
   */
  clearValidations(formName, fieldNames) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.CLEAR_FIELD_VALIDATIONS,
      attributes: {
        form: formName,
        fields: fieldNames,
      },
    });
  }

  /**
   * Clears the validation status and resets the form values for the given
   * field names in the relevant form.
   *
   * @param {String} formName The name of the form
   * @param {Array[String]} fieldNames The names of the fields to clear the
   * validations and state for.
   * @param {Array[String]} fieldContents The resetted values of the fields
   */
  resetFields(formName, fieldNames, fieldContents) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.RESET_FIELDS,
      attributes: {
        form: formName,
        fields: fieldNames,
        contents: fieldContents,
      },
    });
  }

  formStateChanged(formName, fieldName, contents) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.FORM_STATE_CHANGED,
      attributes: {
        form: formName,
        field: fieldName,
        contents: contents,
      },
    });
  }

  formStatesChanged(formName, contents) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.FORM_STATES_CHANGED,
      attributes: {
        form: formName,
        contents: contents,
      },
    });
  }

  showError(formName, title, showError = true) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.SHOW_FORM_ERROR,
      form: formName,
      title: title,
      showError: showError,
    });
  }

  clearError(formName) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.CLEAR_FORM_ERROR,
      form: formName,
    });
  }

  submit(endpoint, requestData, formName, successModal) {
    if (
      formName === 'signup-partner' ||
      formName === 'signup-customer' ||
      formName === 'partner-signup-form'
    ) {
      AuthenticationActions.signup(requestData.form, formName);
    } else {
      AppDispatcher.handleViewAction({
        actionType: FormConstants.SUBMISSION_START,
        name: formName,
      });
      request(
        Object.assign(
          {
            url: endpoint,
            method: 'POST',
          },
          requestData
        ),
        (error, response) => {
          if (!error && response.status === 200) {
            AppDispatcher.handleServerAction({
              actionType: FormConstants.SUBMISSION_SUCCESS,
              name: formName,
            });
            if (successModal) {
              ModalActions.open(successModal);
            }
          } else {
            let title;
            if (response.status === 422) {
              title = ErrorMessages.INVALID_SUBMISSION;
            } else if (response.status === 403) {
              title = ErrorMessages.LOGIN_FAIL;
            } else {
              title = ErrorMessages.TECHNICAL_DIFFICULTIES;
            }
            AppDispatcher.handleServerAction({
              actionType: FormConstants.SUBMISSION_FAILURE,
              name: formName,
              title: title,
              response: response,
            });
          }
        }
      );
    }
  }

  showValidationStatus(formName, fieldName, formType, formValue) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.SHOW_VALIDATION_STATE,
      attributes: {
        form: formName,
        field: fieldName,
        type: formType,
        value: formValue,
      },
    });
  }

  /**
   * Sets the show validation status to true for the given field names in the relevant
   * form.
   *
   * @param {String} formName The name of the form
   * @param {Array[String]} fieldNames The names of the fields to show validations for.
   */
  showValidationStates(formName, fields) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.SHOW_VALIDATION_STATES,
      attributes: {
        form: formName,
        fields: fields,
      },
    });
  }

  /**
   * Clears the show validation status for the given field names in the relevant
   * form.
   *
   * @param {String} formName The name of the form
   * @param {Array[String]} fieldNames The names of the fields to clear the
   * show validations for.
   */
  hideValidationStates(formName, fields) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.HIDE_VALIDATION_STATES,
      attributes: {
        form: formName,
        fields: fields,
      },
    });
  }

  setImmutableField(formName, fieldName, value) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.SET_IMMUTABLE_FIELD,
      formName: formName,
      fieldName: fieldName,
      value: value,
    });
  }

  /**
   * Marks multiple fields as immutable all at once.
   *
   * @param {String} formName The name of the form
   * @param {Object} fields Object containing fieldName: value pairs for the fields
   * that are being marked as immutable
   *
   */
  setImmutableFields(formName, fields) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.SET_IMMUTABLE_FIELDS,
      formName: formName,
      fields: fields,
    });
  }

  /**
   * Clears multiple fields from being immutable all at once.
   *
   * @param {String} formName The name of the form
   * @param {Array[String]} fields Array of the names of the fields that should
   * no longer be immutable
   *
   */
  clearImmutableFields(formName, fields) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.CLEAR_IMMUTABLE_FIELDS,
      formName: formName,
      fields: fields,
    });
  }

  saveForm(formName) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.FORM_SAVE,
      formName: formName,
    });
  }

  resetForm(formName) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.FORM_RESET,
      formName: formName,
    });
  }

  setFieldValidationState(formName, fieldName, isValid) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.SET_FIELD_VALIDATION_STATE,
      formName,
      fieldName,
      isValid,
    });
  }

  startSubmission(formName) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.SUBMISSION_START,
      name: formName,
    });
  }

  submissionFailure(formName, errorMessage) {
    AppDispatcher.handleServerAction({
      actionType: FormConstants.SUBMISSION_FAILURE,
      name: formName,
      title: errorMessage,
    });
  }

  submissionSuccess(formName) {
    AppDispatcher.handleServerAction({
      actionType: FormConstants.SUBMISSION_SUCCESS,
      name: formName,
    });
  }
}

export default new FormActions();
