import React, { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import {
  kycCheckDocumentsSubmitted,
  kycLoadInitPage,
  kycSetSubheader,
} from 'spa/actions/VerificationActions';
import {
  getUserEKYCData as getUserEKYCDataRoutine,
  getUserVerificationStatuses as getUserVerificationStatusesRoutine,
} from 'spa/actions/UserActions';
import {
  VerificationDetails,
  IndividualDetailsForm,
  IndividualVerificationForm,
  CompanyDetailsForm,
  CompanyVerificationForm,
  ExtendedVerificationForm,
} from 'spa/components/CustomerVerification/V2';
import { KYCPages } from 'spa/constants/VerificationConstants';
import {
  isCustomerReadySelector,
  customerKycLoadingUiSelector,
  customerKycSuccessUiSelector,
  customerKycCurrentPageSelector,
  customerKycSubheaderTextSelector,
  customerKycErrorAlertSelector,
  customerEKycDataSuccess,
  isCustomerExtendedKycRequirementLoading,
  customerVerificationStatusLoading,
  customerKycSubmitLoadingSelector,
  customerVerificationStatusSuccess,
  customerKyc2RejectedSelector,
  customerKycDocumentsSubmittedIsReady,
} from 'spa/selectors/CustomerSelectors';
import { Spinner } from 'spa/components/StartTransaction';
import { CHECKOUT_FLOW, KYCContext } from 'spa/context/KYCContext';
import { Alert } from '../../../components/CustomerVerification/V2';

const KycRedirect = () => {
  const { redirect } = useContext(KYCContext);
  useEffect(() => {
    window.location.href = redirect;
  }, [redirect]);
  return (
    <div className="kyc-redirect-container">
      <Spinner />
      <div className="checkout-page-subheader">
        Please wait while redirect you back to your transaction. This will only take a moment.
      </div>
    </div>
  );
};

const KycPageContent = ({ page }) => {
  const { redirect } = useContext(KYCContext);
  let content;
  switch (page) {
    case KYCPages.VERIFICATION_DETAILS:
      if (redirect) {
        content = <KycRedirect />;
        break;
      }
      content = <VerificationDetails />;
      break;
    case KYCPages.INDIVIDUAL_DETAILS_FORM:
      content = <IndividualDetailsForm />;
      break;
    case KYCPages.INDIVIDUAL_VERIFICATION_FORM:
      content = <IndividualVerificationForm />;
      break;
    case KYCPages.EXTENDED_INDIVIDUAL_VERIFICATION_FORM:
      content = <ExtendedVerificationForm />;
      break;
    case KYCPages.COMPANY_DETAILS_FORM:
      content = <CompanyDetailsForm />;
      break;
    case KYCPages.COMPANY_VERIFICATION_FORM:
      content = <CompanyVerificationForm />;
      break;
    default:
      content = <React.Fragment />;
  }
  return <div className="kyc-container-content">{content}</div>;
};

const KycHeader = ({ subheaderText }) => {
  const { flow } = useContext(KYCContext);
  const isCheckoutFlow = flow === CHECKOUT_FLOW;

  return (
    <div className={isCheckoutFlow ? '' : 'kyc-header'}>
      <div className={isCheckoutFlow ? 'checkout-page-header' : 'kyc-header-title'}>
        Please verify your account
      </div>
      <span className={isCheckoutFlow ? 'checkout-page-subheader' : 'kyc-header-title-subheader'}>
        {subheaderText}
      </span>
    </div>
  );
};

const V2KYC = (props) => {
  const {
    setSubheaderText,
    getUserEKYCData,
    currentPage,
    isLoading,
    hasContactDetails,
    loadInitialPage,
    isNextPageLoadSucess,
    subheaderText,
    error,
    getUserVerificationStatuses,
    customerEKycIsReady,
    customerVerificationStatusIsReady,
    isCustomerKyc2Rejected,
    customerKycDoucmentsIsReady,
    checkKYCDocumentsSubmitted,
  } = props;

  useEffect(() => {
    setSubheaderText(`We protect both sides of the transaction by verifying the identity of all users.
Your verified identity must be the same as the account holder's name on any bank account used to pay or receive funds from Escrow.com
`);
    !customerEKycIsReady && getUserEKYCData();
    !customerVerificationStatusIsReady && getUserVerificationStatuses();
    !customerKycDoucmentsIsReady && checkKYCDocumentsSubmitted();
  }, [
    setSubheaderText,
    getUserEKYCData,
    getUserVerificationStatuses,
    customerEKycIsReady,
    customerVerificationStatusIsReady,
    customerKycDoucmentsIsReady,
    checkKYCDocumentsSubmitted,
  ]);

  const kycContext = useContext(KYCContext);

  useEffect(() => {
    const isInitialView = !currentPage && !isLoading;
    if (isInitialView) {
      loadInitialPage({
        kycContext,
      });
    }
  }, [
    currentPage,
    isLoading,
    hasContactDetails,
    loadInitialPage,
    kycContext,
    isCustomerKyc2Rejected,
  ]);

  return (
    <div className="kyc kyc-container">
      {!isLoading && error && (
        <div className="kyc-alert">
          <Alert {...error} type={'error'} />{' '}
        </div>
      )}
      <KycHeader subheaderText={subheaderText} />
      {isLoading && <Spinner />}
      {!isLoading && isNextPageLoadSucess && <KycPageContent page={currentPage} />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading:
    !customerKycSubmitLoadingSelector(state) &&
    (!isCustomerReadySelector(state) ||
      customerKycLoadingUiSelector(state) ||
      customerVerificationStatusLoading(state) ||
      isCustomerExtendedKycRequirementLoading(state) ||
      !customerEKycDataSuccess(state)),
  isCustomerKyc2Rejected: customerKyc2RejectedSelector(state),
  isNextPageLoadSucess: customerKycSuccessUiSelector(state),
  currentPage: customerKycCurrentPageSelector(state),
  subheaderText: customerKycSubheaderTextSelector(state),
  error: customerKycErrorAlertSelector(state),
  customerEKycIsReady: customerEKycDataSuccess(state),
  customerVerificationStatusIsReady: customerVerificationStatusSuccess(state),
  customerKycDoucmentsIsReady: customerKycDocumentsSubmittedIsReady(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadInitialPage: (payload) => dispatch(kycLoadInitPage(payload)),
  setSubheaderText: (subheaderText) => dispatch(kycSetSubheader(subheaderText)),
  getUserEKYCData: () => dispatch(getUserEKYCDataRoutine({ getFields: true })),
  getUserVerificationStatuses: () => dispatch(getUserVerificationStatusesRoutine()),
  checkKYCDocumentsSubmitted: () => dispatch(kycCheckDocumentsSubmitted()),
});

export default connect(mapStateToProps, mapDispatchToProps)(V2KYC);
