var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["icons/ui-exit.html"] = require( "icons/ui-exit.html" );
dependencies["icons/close.html"] = require( "icons/close.html" );
dependencies["icons/chevron-right.html"] = require( "icons/chevron-right.html" );
dependencies["icons/arrow-right.html"] = require( "icons/arrow-right.html" );
dependencies["components/escrowLogo-v2.html"] = require( "components/escrowLogo-v2.html" );




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/navigation/sidebarPartnerLoggedIn.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("icons/ui-exit.html", false, "escrow_www/templates/navigation/sidebarPartnerLoggedIn.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(context.getVariables(), frame, function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(t_1.hasOwnProperty("exit")) {
var t_4 = t_1.exit;
} else {
cb(new Error("cannot import 'exit'")); return;
}
context.setVariable("exitIcon", t_4);
env.getTemplate("icons/close.html", false, "escrow_www/templates/navigation/sidebarPartnerLoggedIn.html", false, function(t_6,t_5) {
if(t_6) { cb(t_6); return; }
t_5.getExported(context.getVariables(), frame, function(t_7,t_5) {
if(t_7) { cb(t_7); return; }
if(t_5.hasOwnProperty("close")) {
var t_8 = t_5.close;
} else {
cb(new Error("cannot import 'close'")); return;
}
context.setVariable("closeIcon", t_8);
env.getTemplate("icons/chevron-right.html", false, "escrow_www/templates/navigation/sidebarPartnerLoggedIn.html", false, function(t_10,t_9) {
if(t_10) { cb(t_10); return; }
t_9.getExported(context.getVariables(), frame, function(t_11,t_9) {
if(t_11) { cb(t_11); return; }
if(t_9.hasOwnProperty("chevronRight")) {
var t_12 = t_9.chevronRight;
} else {
cb(new Error("cannot import 'chevronRight'")); return;
}
context.setVariable("chevronRightIcon", t_12);
env.getTemplate("icons/arrow-right.html", false, "escrow_www/templates/navigation/sidebarPartnerLoggedIn.html", false, function(t_14,t_13) {
if(t_14) { cb(t_14); return; }
t_13.getExported(context.getVariables(), frame, function(t_15,t_13) {
if(t_15) { cb(t_15); return; }
if(t_13.hasOwnProperty("arrowRight")) {
var t_16 = t_13.arrowRight;
} else {
cb(new Error("cannot import 'arrowRight'")); return;
}
context.setVariable("arrowRightIcon", t_16);
output += "<aside class=\"sidebar\" data-target=\"sidebar\">\n    <header class=\"sidebar-header\">\n        <button data-sidebar-toggle class=\"sidebar-close\">";
output += runtime.suppressValue((lineno = 7, colno = 68, runtime.callWrap(t_8, "closeIcon", context, [runtime.makeKeywordArgs({"specifier": "sidebar-close-icon"})])), env.opts.autoescape);
output += "</button>\n        <a href=\"";
output += runtime.suppressValue((lineno = 8, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".partner_dashboard"])), env.opts.autoescape);
output += "\" class=\"sidebar-logo logo logo--dark\" title=\"";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_17 = "";t_17 += "Dashboard";
cb(null, t_17);
;
return t_17;
}
), true && env.opts.autoescape);
output += "\">\n            ";
env.getTemplate("components/escrowLogo-v2.html", false, "escrow_www/templates/navigation/sidebarPartnerLoggedIn.html", null, function(t_20,t_18) {
if(t_20) { cb(t_20); return; }
t_18.render(context.getVariables(), frame, function(t_21,t_19) {
if(t_21) { cb(t_21); return; }
output += t_19
output += "\n        </a>\n    </header>\n    <div class=\"sidebar-content\">\n        <div class=\"sidebar-faction\">\n            <ul class=\"sidebar-list\">\n                <li class=\"sidebar-item\">\n                    <a href=\"";
output += runtime.suppressValue((lineno = 16, colno = 37, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".partner_dashboard"])), env.opts.autoescape);
output += "\" class=\"sidebar-tab\">\n                        ";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_22 = "";t_22 += "Overview";
cb(null, t_22);
;
return t_22;
}
), true && env.opts.autoescape);
output += "\n                    </a>\n                </li>\n                <li class=\"sidebar-item\">\n                    <a href=\"";
output += runtime.suppressValue((lineno = 21, colno = 37, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".partner_dashboard_transactions"])), env.opts.autoescape);
output += "\" class=\"sidebar-tab\">\n                        ";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_23 = "";t_23 += "Transactions";
cb(null, t_23);
;
return t_23;
}
), true && env.opts.autoescape);
output += "\n                    </a>\n                </li>\n                <li class=\"sidebar-item\">\n                    <a href=\"";
output += runtime.suppressValue((lineno = 26, colno = 37, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".partner_dashboard_users"])), env.opts.autoescape);
output += "\" class=\"sidebar-tab\">\n                        ";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_24 = "";t_24 += "Users";
cb(null, t_24);
;
return t_24;
}
), true && env.opts.autoescape);
output += "\n                    </a>\n                </li>\n                <li class=\"sidebar-item\">\n                    <a href=\"";
output += runtime.suppressValue((lineno = 31, colno = 37, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".partner_dashboard_reports"])), env.opts.autoescape);
output += "\" class=\"sidebar-tab\">\n                        ";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_25 = "";t_25 += "Reports";
cb(null, t_25);
;
return t_25;
}
), true && env.opts.autoescape);
output += "\n                    </a>\n                </li>\n            </ul>\n        </div>\n        <div class=\"sidebar-faction\">\n            <div class=\"sidebar-item\">\n                <a target=\"_blank\" href=\"";
output += runtime.suppressValue((lineno = 39, colno = 49, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".integrations_home"])), env.opts.autoescape);
output += "\" class=\"sidebar-tab\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_26 = "";t_26 += "My Integrations";
cb(null, t_26);
;
return t_26;
}
), true && env.opts.autoescape);
output += " ";
output += runtime.suppressValue((lineno = 39, colno = 132, runtime.callWrap(t_4, "exitIcon", context, ["sidebar-tab-icon"])), env.opts.autoescape);
output += " </a>\n            </div>\n            ";
if(!runtime.contextOrFrameLookup(context, frame, "isKycCompleted")) {
output += "\n            <div class=\"sidebar-item\">\n                <a target=\"_blank\" href=\"";
output += runtime.suppressValue((lineno = 43, colno = 49, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".verify"])), env.opts.autoescape);
output += "\" class=\"sidebar-tab\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_27 = "";t_27 += "Get Verified";
cb(null, t_27);
;
return t_27;
}
), true && env.opts.autoescape);
output += " ";
output += runtime.suppressValue((lineno = 43, colno = 118, runtime.callWrap(t_4, "exitIcon", context, ["sidebar-tab-icon"])), env.opts.autoescape);
output += " </a>\n            </div>\n            ";
;
}
output += "\n            <div class=\"sidebar-item\">\n                <a target=\"_blank\" href=\"";
output += runtime.suppressValue((lineno = 47, colno = 49, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".helpdesk"])), env.opts.autoescape);
output += "\" class=\"sidebar-tab\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_28 = "";t_28 += "Help";
cb(null, t_28);
;
return t_28;
}
), true && env.opts.autoescape);
output += " ";
output += runtime.suppressValue((lineno = 47, colno = 112, runtime.callWrap(t_4, "exitIcon", context, ["sidebar-tab-icon"])), env.opts.autoescape);
output += " </a>\n            </div>\n            <div class=\"sidebar-item\">\n                <a target=\"_blank\" href=\"";
output += runtime.suppressValue((lineno = 50, colno = 49, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".contact_us"])), env.opts.autoescape);
output += "\" class=\"sidebar-tab\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_29 = "";t_29 += "Contact Us";
cb(null, t_29);
;
return t_29;
}
), true && env.opts.autoescape);
output += " ";
output += runtime.suppressValue((lineno = 50, colno = 120, runtime.callWrap(t_4, "exitIcon", context, ["sidebar-tab-icon"])), env.opts.autoescape);
output += " </a>\n            </div>\n        </div>\n        <div class=\"sidebar-faction\">\n            <ul class=\"sidebar-list\">\n                <li class=\"sidebar-item\">\n                    <a target=\"_blank\" href=\"";
output += runtime.suppressValue((lineno = 56, colno = 53, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".account-info"])), env.opts.autoescape);
output += "\" class=\"sidebar-tab\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_30 = "";t_30 += "My Profile";
cb(null, t_30);
;
return t_30;
}
), true && env.opts.autoescape);
output += " ";
output += runtime.suppressValue((lineno = 56, colno = 126, runtime.callWrap(t_4, "exitIcon", context, ["sidebar-tab-icon"])), env.opts.autoescape);
output += " </a>\n                </li>\n                <li class=\"sidebar-item\">\n                    <a target=\"_blank\" href=\"#\" data-target=\"logout\" class=\"sidebar-tab\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_31 = "";t_31 += "Log Out";
cb(null, t_31);
;
return t_31;
}
), true && env.opts.autoescape);
output += " ";
output += runtime.suppressValue((lineno = 59, colno = 119, runtime.callWrap(t_4, "exitIcon", context, ["sidebar-tab-icon"])), env.opts.autoescape);
output += " </a>\n                </li>\n            </ul>\n        </div>\n    </div>\n</aside>\n\n<div class=\"sidebar-backdrop\" data-sidebar-toggle></div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/navigation/sidebarPartnerLoggedIn.html"] , dependencies)