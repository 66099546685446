import React from 'react';
import QueryString from 'query-string';
import AdditionalDocumentsForm from 'spa/components/AdditionalDocuments/AdditionalDocumentsForm';

import { connect } from 'react-redux';
import { loadDocuments as loadDocumentsRoutine } from 'spa/actions/AdditionalDocumentsActions';
import { requiredDocsSelector, loadingSelector } from 'spa/selectors/AdditionalDocumentsSelectors';

const AdditionalDocumentsContainer = (props) => <AdditionalDocumentsForm {...props} />;

const mapStateToProps = (state) => ({
  requiredDocs: requiredDocsSelector(
    state,
    parseInt(QueryString.parse(window.location.search)['trans-id'], 10)
  ),
  loading: loadingSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getDocuments: (transactionId) => dispatch(loadDocumentsRoutine.trigger({ transactionId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalDocumentsContainer);
