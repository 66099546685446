import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Code } from 'spa/components/Code';
import AccentedText from 'spa/components/AccentedText';
import PrimaryLink from 'spa/components/PrimaryLink';
import HintBanner from 'spa/components/HintBanner';
import { Section, SectionTitle, SectionSubTitle, SectionDesc } from 'spa/components/Section';

import { urlFor, envDomainName } from '../../../routeConfig';

const CreateCustomer = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktopSmall-9">
        <h1 className="integrationsPortal-title">API Guide :: Retrieving transaction details</h1>
        <Section>
          <SectionTitle>Retrieving transaction details</SectionTitle>
          <SectionDesc>
            Retrieving information about a transaction is a simple get request, given that you have
            permission to view the transaction. You are only able to see the transaction if you are
            the buyer, seller, broker or the partner in the transaction.
          </SectionDesc>
          <HintBanner
            className="integrationsPortal-hintBanner"
            title="Note"
            desc="The information that is returned may be different depending on the user accessing
            the transaction. This can happen in multiple scenarios, such as the broker taking a
            broker commission that is not visible to the buyer or seller (or both). Another similar
            scenario is where the transaction has applicable partner fees."
            icon="ui-alert-circle"
          />
          <SectionSubTitle>Retrieve transaction by id</SectionSubTitle>
          <SectionDesc>
            Use this approach to retrieve the details of a transaction by the Escrow.com transaction
            id.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.${envDomainName}/2017-09-01/transaction/29292" \\
    -X GET \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json"
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.get(
  'https://api.${envDomainName}/2017-09-01/transaction/29292',
  auth=('email-address', 'api-key'),
)
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="ruby">
                {`require 'net/http'
require 'json'

uri = URI("https://api.${envDomainName}/2017-09-01/transaction/29292")

http = Net::HTTP.new(uri.host, uri.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Get.new(uri.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
  CURLOPT_URL => 'https://api.${envDomainName}/2017-09-01/transaction/29292',
  CURLOPT_RETURNTRANSFER => 1,
  CURLOPT_USERPWD => 'email-address:your-password',
  CURLOPT_HTTPHEADER => array(
      'Content-Type: application/json'
  ),
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue(
                "application/json"));
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.${envDomainName}/2017-09-01/transaction/29292"),
                Method = HttpMethod.Get,
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
          <SectionSubTitle>Retrieve transaction by reference</SectionSubTitle>
          <SectionDesc>
            Use this approach to retrieve the details of a transaction by the key you use in your
            system to represent the given order, transaction, or relevant entity.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.${envDomainName}/2017-09-01/transaction/reference/xyz456" \\
    -X GET \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json"
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.get(
  'https://api.${envDomainName}/2017-09-01/transaction/reference/xyz456',
  auth=('email-address', 'api-key'),
)
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="ruby">
                {`require 'net/http'
require 'json'

uri = URI("https://api.${envDomainName}/2017-09-01/transaction/reference/xyz456")

http = Net::HTTP.new(uri.host, uri.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Get.new(uri.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
  CURLOPT_URL => 'https://api.${envDomainName}/2017-09-01/transaction/reference/xyz456',
  CURLOPT_RETURNTRANSFER => 1,
  CURLOPT_USERPWD => 'email-address:your-password',
  CURLOPT_HTTPHEADER => array(
      'Content-Type: application/json'
  ),
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue(
                "application/json"));
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.${envDomainName}/2017-09-01/transaction/reference/xyz456"),
                Method = HttpMethod.Get,
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
          <SectionSubTitle>Example response</SectionSubTitle>
          <SectionDesc>
            Whether you fetch a transaction by <AccentedText>id</AccentedText> or by{' '}
            <AccentedText>reference</AccentedText>, the same response will be returned.
          </SectionDesc>
          <Code language="json">
            {`{
  "id": 128900,
  "parties": [
      {
          "role": "buyer",
          "customer": "michael.nyqvist@test.escrow.com",
          "agreed": true
      },
      {
          "role": "seller",
          "customer": "john.wick@test.escrow.com",
          "agreed": false
      }
  ],
  "currency": "usd",
  "description": "The sale of martingroen.com",
  "items": [
      {
          "id": 2929101,
          "title": "martingroen.com",
          "description": "martingroen.com",
          "type": "domain name",
          "inspection_period": 259200,
          "quantity": 1,
          "schedule": [
              {
                  "amount": 1000.0,
                  "payer_customer": "michael.nyqvist@test.escrow.com",
                  "beneficiary_customer": "john.wick@test.escrow.com",
                  "status": {
                      "secured": false
                  }
              }
          ],
          "fees": [
              {
                  "type": "escrow",
                  "amount": "16.25",
                  "payer_customer": "michael.nyqvist@test.escrow.com"
              },
              {
                  "type": "escrow",
                  "amount": "16.25",
                  "payer_customer": "john.wick@test.escrow.com"
              }
          ],
          "status": {
              "received": false,
              "rejected_returned": false,
              "rejected": false,
              "received_returned": false,
              "shipped": false,
              "accepted": false,
              "shipped_returned": false,
              "accepted_returned": false,
              "canceled": false
          }
      }
  ]
}`}
          </Code>
        </Section>
        <footer className="integrationsPortal-footer">
          <h3 className="integrationsPortal-footer-title">{'Next steps'}</h3>
          <nav className="integrationsPortal-footer-steps">
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Create a transaction"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_create_transaction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Agree to a transaction"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_agree_transaction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Fund a transaction"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_fund_transaction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Full API reference Document"
                link={{
                  type: 'external',
                  route: window.config.api_docs_page,
                }}
              />
            </div>
          </nav>
        </footer>
      </div>
    </div>
  </div>
);

export default CreateCustomer;
