const twoFactorSelector = (state) => state.twoFactorAuthentication;

const twoFactorUrlSelector = (state) => twoFactorSelector(state).secretUrl;
const twoFactorLoadingSelector = (state) => twoFactorSelector(state).loading;
const twoFactorSmsCodeSentSelector = (state) => twoFactorSelector(state).codeSent;
const twoFactorSmsNotificationSelector = (state) => twoFactorSelector(state).showNotification;

export {
  twoFactorSelector,
  twoFactorUrlSelector,
  twoFactorLoadingSelector,
  twoFactorSmsCodeSentSelector,
  twoFactorSmsNotificationSelector,
};
