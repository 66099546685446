import React from 'react';
import PropTypes from 'prop-types';

const CardRow = ({ label, value }) => (
  <div className="accountInfo-data">
    <span className="accountInfo-data-label">{label}</span>
    <span className="accountInfo-data-value">{value}</span>
  </div>
);

CardRow.propTypes = {
  label: PropTypes.node,
  value: PropTypes.node,
};

export default CardRow;
