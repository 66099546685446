import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import VerificationConstants from '../../constants/VerificationConstants';
import { gettext } from '../../../utils/filters';

const AccountTypeFieldset = (props) => {
  const options = [
    {
      label: gettext('Individual'),
      value: VerificationConstants.ACCOUNT_TYPE_INDIVIDUAL,
    },
    {
      label: gettext('Company'),
      value: VerificationConstants.ACCOUNT_TYPE_COMPANY,
    },
  ];
  return (
    <div className="verifyPage-card">
      <header className="verifyPage-card-header">
        <h2 className="verifyPage-card-header-title">{props.title}</h2>
        <div className="verifyPage-card-header-actions">
          <fieldset>
            <div className="field-input">
              {options.map((option) => (
                <label
                  key={option.value}
                  className="defaultRadio defaultRadio--inline defaultRadio--inline--large"
                >
                  <Field
                    className="defaultRadio-input"
                    name="accountType"
                    value={option.value}
                    validate={props.validate}
                    disabled={props.submitting}
                    component="input"
                    type="radio"
                    data-e2e-target="account-type-field"
                  />
                  <div className="defaultRadio-pseudo" />
                  <span className="defaultRadio-label">{option.label}</span>
                </label>
              ))}
            </div>
          </fieldset>
        </div>
      </header>
    </div>
  );
};

AccountTypeFieldset.propTypes = {
  title: PropTypes.string,
};

export default AccountTypeFieldset;
