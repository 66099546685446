import React, { useMemo } from 'react';
import QueryString from 'query-string';
import Breadcrumbs from 'spa/components/Breadcrumbs';
import AdditionalDocumentsContainer from 'spa/containers/AdditionalDocuments';

import { gettext } from '../../../utils/filters';

const AdditionalDocumentsPage = () => {
  const transactionId = QueryString.parse(location.search)['trans-id'];
  const breadcrumbs = useMemo(
    () => [
      {
        text: gettext('Your Account'),
        link: '/account-info',
      },
      {
        text: gettext(`Transaction ${transactionId}`),
        link: `${window.config.www_base_url}/transaction/${transactionId}`,
      },
      {
        text: gettext('Upload Requested Documents'),
      },
    ],
    [transactionId]
  );

  return (
    <section
      className="section section--small section--mid"
      data-e2e-target={`additional-documents-form-container`}
    >
      <div className="section-container">
        <div className="account-inner">
          <Breadcrumbs className="accountInfo-breadcrumbs" items={breadcrumbs} />
          <AdditionalDocumentsContainer />
        </div>
      </div>
    </section>
  );
};

export default AdditionalDocumentsPage;
