export default {
  TYPE_AGREE: 'auth-type-agree',
  TYPE_EMAIL_CAPTURE: 'auth-type-email-capture',
  TYPES: ['auth-type-agree', 'auth-type-email-capture'],
  FORM_ADWORDS_LANDING: 'adwords-landing',
  SIGNUP_STATES: {
    IN_PROGRESS: 'IN_PROGRESS',
    INITIAL: 'INITIAL',
  },
};
