import TourConstants from 'spa/constants/TourConstants';

export const initTour = (name) => ({
  type: TourConstants.INIT,
  name,
});

export const nextStep = (name) => ({
  type: TourConstants.NEXT_STEP,
  name,
});

export const jumpToStep = (name, step) => ({
  type: TourConstants.JUMP,
  name,
  step,
});

export const finishTour = (name) => ({
  type: TourConstants.FINISH,
  name,
});
