import deepcopy from 'clone';
import h from 'virtual-dom/h';
import select from 'vtree-select';
import serialize from 'form-serialize';
import velocity from 'velocity-animate';
import VText from 'virtual-dom/vnode/vtext';
import window from 'window-shim';

import StatesInputTemplate from 'templates/js/contactInformationStates.html';

import virtualize from '../utils/virtualize';
import Component from '../utils/Component';
import { updateIcon } from '../utils/select';
import { parsePhone } from '../utils/parse-phone';
import FormActions from '../actions/FormActions';
import FormConstants from '../constants/FormConstants';
import FormStore from '../stores/FormStore';
import { format, gettext } from '../utils/filters';
import { setVnodeClasses } from '../utils/set-vnode-classes';

function getState(name) {
  return {
    formSubmitted: FormStore.submissionSuccess(name),
    formSubmitting: FormStore.formIsSubmitting(name),
    validFields: FormStore.validFields(name),
    invalidFieldCount: FormStore.invalidFieldCount(name),
    showFormError: FormStore.showFormError(name),
    formErrorTitle: FormStore.errorTitle(name),
    formState: FormStore.currentFormState(name),
    showValidationStatus: FormStore.showValidationStatus(name),
    emailExist: FormStore.emailExist(name),
    lastSubmissionFailure: FormStore.lastFailedSubmission(name),
    immutableFields: FormStore.immutableFields(name),
    fieldHint: FormStore.fieldHints(name),
  };
}

export class Form extends Component {
  constructor(element, options = {}) {
    super();

    this.options = Object.assign(
      {
        handleSubmission: true,
      },
      options
    );

    this.endpoint = element.dataset.action;
    // Set the formName to the form-name data attribute, but fall back to the
    // endpoint name.
    if (element.getAttribute('data-form-name')) {
      this.formName = element.getAttribute('data-form-name');
    } else {
      this.formName = this.endpoint;
    }

    // set successModal based on the data-success-modal attribute
    this.successModal = element.getAttribute('data-success-modal') || '';

    this.template = virtualize(element);
    this.initialTemplate = deepcopy(this.template, false);
    this.setState(getState(this.formName));

    this.addEventListener('submit', 'form', this._onSubmission.bind(this));
    this.addEventListener(
      'input',
      'input[data-target="field-focusable"]',
      this._validateField.bind(this)
    );
    this.addEventListener(
      'input',
      'textarea[data-target="field-focusable"]',
      this._validateField.bind(this)
    );
    this.addEventListener(
      'change',
      'select[data-target="field-focusable"]',
      this._validateField.bind(this)
    );
    this.addEventListener(
      'change',
      'input[data-target="field-focusable"][type="checkbox"]',
      this._validateField.bind(this)
    );
    this.addEventListener(
      'change',
      'input[data-target="field-focusable"][type="radio"]',
      this.updateFormState.bind(this)
    );
    this.addEventListener('blur', '[data-target="field-focusable"]', this._onBlur.bind(this));

    this.addEventListener('click', '[data-target="hint-value"]', this._selectHint.bind(this));

    this._onChange = this._onChange.bind(this);
  }

  _onSubmission(event) {
    event.preventDefault();
    this.validateRequiredFields();

    if (!this.state.invalidFieldCount && this.options.handleSubmission) {
      const requestData = {
        form: serialize(event.target, { hash: true }),
      };
      FormActions.submit(this.endpoint, requestData, this.formName, this.successModal);
    } else {
      this.showFormErrors();
    }
  }

  _selectHint(event) {
    const field = event.target.attributes['data-field'].value;
    const hintValue = event.target.attributes['data-hint-value'].value;
    const fieldElement = this.rootNode.querySelector(
      `[data-target="field-focusable"][name="${field}"]`
    );
    fieldElement.value = hintValue;
    this._validateField({ target: fieldElement });
  }

  _onBlur(event) {
    let value;
    if (event.target.type === 'checkbox') {
      value = event.target.checked.toString();
    } else {
      value = event.target.value;
    }
    if (value && value !== '---') {
      FormActions.showValidationStatus(this.formName, event.target.name, event.target.type, value);
    }
  }

  _validateField(event) {
    // Remove alpha characters from phone field
    if (event.target.type === 'tel' && /[a-zA-Z]/.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[a-zA-Z]/g, '');
    }

    if (event.target.dataset.component === 'phone-prefix-field') {
      // Add the numeric prefix value to the Form Store (needed for libphonenumber validation)
      this.storePhonePrefixValue(event.target);
    }

    // Enforce maximum character length
    const maxLength = event.target.getAttribute('data-max-length');
    if (maxLength) {
      event.target.value = event.target.value.slice(0, maxLength);
    }

    const fieldName = event.target.name;
    let contents;
    if (event.target.type === 'checkbox') {
      contents = event.target.checked.toString();
    } else {
      contents = event.target.value;
    }

    if (event.target.type === 'select') {
      contents = event.target.value;
    }

    const type = event.target.getAttribute('data-type') || event.target.type;
    const required = event.target.required;
    const skipValidation = event.target.dataset.skipValidation === 'true';

    const requiredGroupElement = event.target.dataset.validationRequireGroup;
    const optionalGroupElement = event.target.dataset.validationOptionalGroup;
    if (requiredGroupElement || optionalGroupElement) {
      const validationGroup = requiredGroupElement || optionalGroupElement;
      const requiredGroupElements = this.rootNode.querySelectorAll(
        `[data-validation-require-group="${validationGroup}"]`
      );
      const optionalGroupElements = this.rootNode.querySelectorAll(
        `[data-validation-optional-group="${validationGroup}"]`
      );
      let groupContainsValue = false;
      // Determine whether any element in the group has a value
      for (const element of requiredGroupElements) {
        if (element.value) {
          groupContainsValue = true;
          break;
        }
      }
      for (const element of optionalGroupElements) {
        if (element.value) {
          groupContainsValue = true;
          break;
        }
      }
      // Validate the required elements in the group (excluding any disabled fields) if
      // any of the group elements have a value
      for (const element of requiredGroupElements) {
        if (groupContainsValue && this.state.immutableFields[element.name] === undefined) {
          FormActions.validate(
            this.formName,
            element.name,
            element.type,
            element.value,
            false,
            true,
            skipValidation
          );
        } else {
          FormActions.clearValidation(this.formName, element.name);
        }
      }

      // If this element is optional in the group, validate it normally
      if (optionalGroupElement && contents) {
        FormActions.validate(this.formName, fieldName, type, contents, false, true, skipValidation);
      } else if (optionalGroupElement) {
        FormActions.clearValidation(this.formName, fieldName);
      }
    } else if (contents || required || type === 'select-one') {
      FormActions.validate(this.formName, fieldName, type, contents, false, true, skipValidation);
    } else {
      FormActions.clearValidation(this.formName, fieldName);
      if (!contents) {
        // Ensure that the formState is consistent with what has been inputted
        FormActions.formStateChanged(this.formName, fieldName, contents);
      }
    }

    // Validate related fields (if any)
    const relatedElements = this.rootNode.querySelectorAll(
      `[data-target="field-focusable"][name="${event.target.dataset.related}"]`
    );
    if (relatedElements) {
      for (const element of relatedElements) {
        FormActions.validate(
          this.formName,
          element.name,
          element.type,
          element.value,
          false,
          element.required,
          skipValidation
        );
      }
    }

    if (
      event.target.parentNode &&
      event.target.parentNode.querySelector('[class="defaultRadio-pseudo"]') &&
      event.target.parentNode
        .querySelector('[class="defaultRadio-pseudo"]')
        .getAttribute('data-show-company-details')
    ) {
      this.validateCompanyFields(event.target.value === 'true');
    }
  }

  storePhonePrefixValue(prefixElem) {
    const prefixName = prefixElem.name.replace('-country', '-prefix');
    const optionElement = prefixElem.querySelector(`option[value="${prefixElem.value}"]`);
    if (optionElement) {
      const prefix = optionElement.dataset.prefix;
      FormActions.formStateChanged(this.formName, prefixName, prefix);
    }
  }

  /* If the form contains multipart phone fields (Prefix selector & numerical
   * suffix), and a value is preset in the given formState, this method can be
   * called in componentHasMounted to ensure that the values and label are
   * correctly set.
   */
  prefillPhoneFields(formState) {
    const phoneWithPrefix = this.rootNode.querySelectorAll('[data-phone-with-prefix]');
    for (const input of phoneWithPrefix) {
      const field = input.getAttribute('data-phone-with-prefix');
      if (formState[field]) {
        const phone = parsePhone(formState[field]);
        if (phone.prefix && phone.suffix) {
          this._prefillPhone(field, phone.prefix, phone.suffix);
        }
      }
    }
  }

  _prefillPhone(fieldName, prefix, suffix) {
    const prefixElement = this.rootNode.querySelector(`[name="${fieldName}-country"]`);
    const suffixElement = this.rootNode.querySelector(`[name="${fieldName}"]`);
    if (prefixElement && suffixElement) {
      prefixElement.querySelector(`[data-prefix="+${prefix}"]`).selected = true;
      prefixElement.querySelector(`[data-prefix="+${prefix}"]`).label = `+${prefix}`;
      updateIcon(prefixElement);
      suffixElement.value = suffix;
    }
  }

  updateFormState(event) {
    const fieldName = event.target.name;
    const contents = event.target.value;

    FormActions.formStateChanged(this.formName, fieldName, contents);
  }

  updateFormName(name) {
    this.formName = name;
  }

  _onChange(childState = {}) {
    const newState = Object.assign(getState(this.formName), childState);

    // If there has been a new submission failure, scroll to the error message
    // element
    if (
      newState.showFormError &&
      newState.lastSubmissionFailure !== this.state.lastSubmissionFailure
    ) {
      const errorElement = this.rootNode.querySelectorAll('[data-target="form-error"]')[0];
      const errorScrollTop = this.rootNode.getAttribute('data-error-scroll-top');
      if (errorScrollTop) {
        document.querySelector(`[data-target="${errorScrollTop}"]`).scrollTop = 0;
      } else {
        velocity(errorElement, 'scroll', { duration: 500, offset: -80 });
      }
      errorElement.focus();
    }

    this.setState(newState, false);
  }

  componentHasMounted() {
    FormStore.addChangeListener(this._onChange);
    this.validateRequiredFields();
  }

  focusOnFirstEmptyField() {
    setTimeout(() => {
      const inputFields = this.rootNode.querySelectorAll('input');
      for (const input of inputFields) {
        if (
          input.offsetParent !== null &&
          input.type !== 'radio' &&
          input.type !== 'checkbox' &&
          input.type !== 'password' &&
          !input.value
        ) {
          const isCategorySearchField =
            input.attributes['data-component'] &&
            input.attributes['data-component'].value === 'transaction-category-search';
          if (!isCategorySearchField) {
            input.focus();
          }
          break;
        }
      }
    });
  }

  /**
   * Performs a bulk validation on all required fields in a form
   *
   * @param skipValidation overrides skip validation attribute on field if set
   */
  validateRequiredFields(skipValidation = undefined) {
    const fields = select('[data-target="field"]')(this.template);
    if (fields) {
      const fieldNames = [];
      const fieldContents = [];
      const fieldTypes = [];
      const fieldRequired = [];
      const fieldSkipValidation = [];

      for (const key of fields) {
        for (const reqField of select('[data-target="field-focusable"]')(key)) {
          if (
            reqField.properties.required &&
            this.state.immutableFields[reqField.properties.name] === undefined
          ) {
            const fieldName = reqField.properties.name;
            const field = this.rootNode.querySelector(
              `[data-target="field-focusable"][name="${fieldName}"]`
            );
            if (field) {
              let contents;
              if (field.type === 'checkbox') {
                contents = field.checked.toString();
              } else if (field.type === 'radio') {
                contents = this.rootNode.querySelector(
                  '[data-target="field-focusable"]:checked'
                ).value;
              } else {
                contents = field.value || reqField.properties.value || '';
              }
              fieldNames.push(fieldName);
              fieldContents.push(contents);
              fieldTypes.push(reqField.properties.type);
              fieldRequired.push(reqField.properties.required);
              if (skipValidation !== undefined) {
                fieldSkipValidation.push(skipValidation);
              } else if ('dataset' in reqField && 'skipValidation' in reqField.dataset) {
                fieldSkipValidation.push(reqField.dataset.skipValidation);
              } else {
                fieldSkipValidation.push(false);
              }
            }
          }
        }
      }

      FormActions.bulkValidate(
        this.formName,
        fieldNames,
        fieldTypes,
        fieldContents,
        fieldRequired,
        fieldSkipValidation
      );
    }

    const phoneExtElements = this.rootNode.querySelectorAll(
      'select[data-target="field-focusable"][data-component="phone-prefix-field"]'
    );
    for (const elem of phoneExtElements) {
      // Add the numeric prefix value to the Form Store (needed for libphonenumber validation)
      this.storePhonePrefixValue(elem);
    }
  }

  validateFields(fields) {
    const fieldNames = [];
    const fieldContents = [];
    const fieldTypes = [];
    const fieldRequired = [];

    for (const field of fields) {
      fieldNames.push(field.name);
      fieldContents.push(field.value || '');
      fieldTypes.push(field.type);
      fieldRequired.push(field.required);
    }

    FormActions.bulkValidate(this.formName, fieldNames, fieldTypes, fieldContents, fieldRequired);
  }

  resetFields(fields) {
    const fieldNames = [];
    const fieldContents = [];
    for (const field of fields) {
      fieldNames.push(field.name);
      fieldContents.push(field.defaultValue || field.getAttribute('selected') || '');
    }
    FormActions.resetFields(this.formName, fieldNames, fieldContents);
  }

  showFormErrors() {
    FormActions.showError(this.formName);

    // Scroll to the first invalid field in the form, or if the data-error-scroll-top
    // attribute is defined, jump to the top of the component this relates to (necessary
    // for forms inside modals).
    const firstInvalidField = this.rootNode.querySelector('[data-target="field"].is-invalid');
    if (firstInvalidField) {
      const errorScrollTop = this.rootNode.getAttribute('data-error-scroll-top');
      if (errorScrollTop) {
        document.querySelector(`[data-target="${errorScrollTop}"]`).scrollTop = 0;
      } else {
        velocity(firstInvalidField, 'scroll', { duration: 500, offset: -80 });
      }
      firstInvalidField.focus();
    }
  }

  componentWillDismount() {
    FormStore.removeChangeListener(this._onChange);
  }

  render(template = this.template) {
    const vhtml = deepcopy(template, false);

    // Change $ to €'
    if (this.state.formState['monthly-sales-currency']) {
      const monthlySalesPrefix = select('[data-related-name="monthly-sales-prefix"]')(vhtml)[0];
      const currency = window.config.currencies[this.state.formState['monthly-sales-currency']];
      monthlySalesPrefix.children[0].text = currency ? currency.symbol : '$';
    }

    const statesField = select('[data-component="contactInformation-states"]')(vhtml);
    if (statesField) {
      for (const dropdown of statesField) {
        const prefix = dropdown.properties.attributes['data-fields-prefix'] || '';
        const stateLabel = dropdown.properties.attributes['data-state-label'] || '';
        dropdown.children = virtualize.fromHTML(
          StatesInputTemplate.render({
            fields_prefix: prefix,
            name: `${prefix}State`,
            country: this.state.formState[`${prefix}Country`],
            state: this.state.formState[`${prefix}State`],
            usa_states: window.config.usa_states,
            state_label: stateLabel,
          })
        ).children;
      }
    }

    // If fields have been marked as valid/invalid, update the DOM to show the
    // validation state
    const validationState = this.state.validFields;
    const formState = this.state.formState;

    if (validationState) {
      const fields = select('[data-target="field"]')(vhtml) || [];
      for (const field of fields) {
        const child = select('[data-target="field-focusable"]')(field)[0];
        const name = child.properties.name;
        const valid = validationState[name];
        const showValidation = this.state.showValidationStatus[name];

        if (valid === true && (showValidation || this.state.showFormError)) {
          setVnodeClasses(field, { 'is-valid': true });
        } else if (valid === false && formState[name] !== undefined && showValidation) {
          setVnodeClasses(field, { 'is-invalid': true });

          // state.emailExist stores the email address only if it already exists.
          // If this is the current email, display the secondary error-2 message instead
          const errorMessages = select('[data-attr="error-invalid"]')(field);
          if (errorMessages) {
            if (name === 'email-address' && this.state.emailExist === formState['email-address']) {
              const emailExistsError = select('[data-attr="error-email-exists"]')(field);
              if (emailExistsError) {
                setVnodeClasses(emailExistsError[0], { 'field-error': true, 'is-hidden': false });
                setVnodeClasses(errorMessages[0], { 'field-error': true, 'is-hidden': true });
              }
            } else {
              setVnodeClasses(errorMessages[0], { 'field-error': true, 'is-hidden': false });
            }
          }
        } else if (this.state.showFormError) {
          const inputs = select('[data-target="field-focusable"]')(field);
          for (const reqField in inputs) {
            if (
              inputs[reqField].properties.required &&
              this.state.immutableFields[inputs[reqField].properties.name] === undefined
            ) {
              setVnodeClasses(field, { 'is-invalid': true });
              const errorElement = select('[data-attr="error-invalid"]')(field);
              if (errorElement) {
                setVnodeClasses(errorElement[0], { 'field-error': true });
              }
            } else if (
              inputs[reqField].properties.attributes['data-validation-require-group'] &&
              validationState[inputs[reqField].properties.name] === false
            ) {
              setVnodeClasses(field, { 'is-invalid': true });
              const errorElement = select('[data-attr="error-invalid"]')(field);
              if (errorElement) {
                setVnodeClasses(errorElement[0], { 'field-error': true });
              }
            }
          }
        }

        // Mark immutable fileds with the 'readonly' attribute
        if (this.state.immutableFields && this.state.immutableFields[name] !== undefined) {
          child.properties.attributes.readonly = true;
        } else {
          delete child.properties.attributes.readonly;
        }

        // Set this attribute so that classNames are rendered properly when
        // using Nunjucks components
        field.properties.attributes.class = field.properties.className;
      }
    }

    // If there are invalid fields, show a validation error message,
    // update the DOM to show the error
    if (
      this.state.showFormError &&
      !!this.state.invalidFieldCount &&
      (this.formName !== 'login-form' || this.formName !== 'partner-login-form')
    ) {
      const errorElement = select('[data-target="form-error"]')(vhtml)[0];
      const msg = h(
        'p.defaultForm-errors-msg',
        format(gettext('%d  form error(s) below, please fix them before continuing.'), [
          this.state.invalidFieldCount,
        ])
      );
      errorElement.children = [msg];
    }

    // Server error message displayed at top of form
    if (this.state.formErrorTitle) {
      const errorNode = select('[data-target="form-error"]')(vhtml)[0];
      const msg = h('span', this.state.formErrorTitle);
      errorNode.children = [msg];
    }

    if (this.state.formSubmitting || (this.state.formSubmitted && !this.successModal)) {
      const submitButtons = select('[data-target="form-submit"]')(vhtml);
      if (submitButtons) {
        for (const elem of submitButtons) {
          elem.properties.attributes.disabled = 'disabled';
          setVnodeClasses(elem, { 'is-loading': true });
        }
      }
    }

    if (this.state.formState && this.state.fieldHint) {
      const hints = this.state.fieldHint;
      for (const field in hints) {
        if (hints[field].type === FormConstants.EMAIL_HINT) {
          // Only use hints related to the current field value
          if (this.state.formState[field] === hints[field].email) {
            const hintElement = select(`[id="hint-${field}"]`)(vhtml);
            if (hintElement) {
              setVnodeClasses(hintElement[0], { 'is-hidden': false });
              const hintMessage = select('[data-target="hint-message"]')(hintElement[0]);
              if (hintMessage) {
                const properties = {
                  attributes: {
                    'data-target': 'hint-value',
                    'data-field': field,
                    'data-hint-value': hints[field].hint,
                  },
                };
                hintMessage[0].children = [
                  new VText(gettext('Did you mean ')),
                  h('a', properties, hints[field].hint),
                  new VText('?'),
                ];
              }
            }
          }
        } else if (hints[field].type === FormConstants.PHONE_HINT) {
          // Only show hint if showValidationStatus is true & only use hints
          // related to the current field value
          // OR show a hint if phone is not verified
          if (
            this.state.showValidationStatus[field] &&
            this.state.formState[field] === hints[field].phone
          ) {
            const hintElement = select(`[id="hint-${field}"]`)(vhtml);
            if (hintElement) {
              setVnodeClasses(hintElement[0], { 'is-hidden': false });
              const hintMessage = select('[data-target="hint-message"]')(hintElement[0]);
              if (hintMessage) {
                if (hints[field].hint) {
                  hintMessage[0].children = [new VText(hints[field].hint)];
                } else {
                  hintMessage[0].children = [
                    new VText(
                      gettext(
                        'We do not recognise the format of the phone number you have submitted, please correct it.'
                      )
                    ),
                  ];
                }
              }
            }
          }
        }
      }
    }

    const phonePrefix = select('[data-component="phone-prefix-select"]')(vhtml);
    if (phonePrefix) {
      for (const field of phonePrefix) {
        const name = field.properties.id;
        if (this.state.formState[name]) {
          const country = this.state.formState[name];
          const dropdown = select('[data-target="field-focusable"]')(field)[0];
          const option = select(`[data-country="${country}"]`)(dropdown)[0];
          const prefixValue = option.properties.attributes['data-prefix'];
          // Set the label to show only the prefix (without country name)
          option.children = [new VText(prefixValue)];
          option.properties.label = prefixValue;

          // Set the prefix value as a hidden field
          const prefixHidden = select(`[name="${name}-prefix"]`)(field)[0];
          prefixHidden.properties.value = prefixValue;
        }
      }
    }

    const validateAsSingleFieldGroup = select('[data-target="validate-as-single-field"]')(vhtml);
    for (const group of validateAsSingleFieldGroup || []) {
      const groupFields = select('[data-target="field"]')(group);
      let groupShowValidation = true;
      let groupValid = true;

      for (const groupField of groupFields || []) {
        if (select('input')(groupField)) {
          const fieldName = groupField.properties.attributes['data-field'];
          groupValid = groupValid && !!this.state.validFields[fieldName];
          groupShowValidation = groupShowValidation && !!this.state.showValidationStatus[fieldName];
        }
      }

      groupShowValidation = groupShowValidation || this.state.showFormError;

      setVnodeClasses(group, {
        'is-invalid': groupShowValidation && !groupValid,
      });
      for (const groupField of groupFields || []) {
        setVnodeClasses(groupField, {
          'is-valid': groupShowValidation && groupValid,
          'is-invalid': groupShowValidation && !groupValid,
        });
      }
    }
    return vhtml;
  }
}

setTimeout(() => {
  for (const elem of document.querySelectorAll('[data-component="userSubmittedForm"]')) {
    const component = new Form(elem);
    component.replace(elem, component.initialTemplate);
  }
});
