var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["components/buttons.html"] = require( "components/buttons.html" );
dependencies["icons/phone.html"] = require( "icons/phone.html" );
dependencies["icons/arrow-right.html"] = require( "icons/arrow-right.html" );
dependencies["icons/menu.html"] = require( "icons/menu.html" );
dependencies["components/escrowLogo-v2.html"] = require( "components/escrowLogo-v2.html" );
dependencies["navigation/sidebarLoggedOut.html"] = require( "navigation/sidebarLoggedOut.html" );




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/navigation/headerLoggedOut.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("components/buttons.html", false, "escrow_www/templates/navigation/headerLoggedOut.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(context.getVariables(), frame, function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("button", t_1);
env.getTemplate("icons/phone.html", false, "escrow_www/templates/navigation/headerLoggedOut.html", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(context.getVariables(), frame, function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(t_4.hasOwnProperty("phone")) {
var t_7 = t_4.phone;
} else {
cb(new Error("cannot import 'phone'")); return;
}
context.setVariable("phoneIcon", t_7);
env.getTemplate("icons/arrow-right.html", false, "escrow_www/templates/navigation/headerLoggedOut.html", false, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
t_8.getExported(context.getVariables(), frame, function(t_10,t_8) {
if(t_10) { cb(t_10); return; }
if(t_8.hasOwnProperty("arrowRight")) {
var t_11 = t_8.arrowRight;
} else {
cb(new Error("cannot import 'arrowRight'")); return;
}
context.setVariable("arrowRightIcon", t_11);
env.getTemplate("icons/menu.html", false, "escrow_www/templates/navigation/headerLoggedOut.html", false, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
t_12.getExported(context.getVariables(), frame, function(t_14,t_12) {
if(t_14) { cb(t_14); return; }
if(t_12.hasOwnProperty("menu")) {
var t_15 = t_12.menu;
} else {
cb(new Error("cannot import 'menu'")); return;
}
context.setVariable("menuIcon", t_15);
output += "<div>\n  <header class=\"header ";
if(runtime.contextOrFrameLookup(context, frame, "sticky") && !runtime.contextOrFrameLookup(context, frame, "amp")) {
output += "is-sticky ";
;
}
if(runtime.contextOrFrameLookup(context, frame, "scrollTop")) {
output += "is-header-scrollTop";
;
}
output += "\" data-sticky-header data-header-logged-out>\n      <div class=\"header-primary\" data-e2e-target=\"header-nav-container\">\n          <div class=\"header-inner section-container\">\n            <div class=\"header-logoContainer\">\n                  ";
if(!runtime.contextOrFrameLookup(context, frame, "amp") && runtime.contextOrFrameLookup(context, frame, "header_type") != "aaa-landing") {
output += "\n                  <button data-sidebar-toggle class=\"header-menuBtn media--available@tablet\">\n                      ";
output += runtime.suppressValue((lineno = 12, colno = 31, runtime.callWrap(t_15, "menuIcon", context, [runtime.makeKeywordArgs({"specifier": "header-menuBtn-icon"})])), env.opts.autoescape);
output += "\n                  </button>\n                  ";
;
}
output += "\n                  <a href=\"";
output += runtime.suppressValue((lineno = 15, colno = 34, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".index"])), env.opts.autoescape);
output += "\" class=\"header-logo logo\" title=\"";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_16 = "";t_16 += "Go to home page";
cb(null, t_16);
;
return t_16;
}
), true && env.opts.autoescape);
output += "\">\n                  ";
env.getTemplate("components/escrowLogo-v2.html", false, "escrow_www/templates/navigation/headerLoggedOut.html", null, function(t_19,t_17) {
if(t_19) { cb(t_19); return; }
t_17.render(context.getVariables(), frame, function(t_20,t_18) {
if(t_20) { cb(t_20); return; }
output += t_18
output += "\n                  </a>\n              </div>\n\n              ";
if(runtime.contextOrFrameLookup(context, frame, "header_type") == "aaa-landing") {
output += "\n                <div class=\"header-partnersLogo header-partnersLogo--aaa\"></div>\n              ";
;
}
output += "\n\n              ";
if(!runtime.contextOrFrameLookup(context, frame, "funnel")) {
output += "\n              <nav class=\"header-nav\">\n                <ul class=\"header-nav-list\">\n                  <li class=\"header-nav-item media--hidden@tablet\">\n                    <a href=\"";
output += runtime.suppressValue((lineno = 28, colno = 37, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".login_page"])), env.opts.autoescape);
output += "\" data-target=\"start-transaction\" class=\"header-nav-tab\" data-e2e-target=\"header-nav-start-transaction\" data-tracking-section=\"header\" data-tracking-label=\"start-transaction\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_21 = "";t_21 += "Start New Transaction";
cb(null, t_21);
;
return t_21;
}
), true && env.opts.autoescape);
output += "</a>\n                  </li>\n                  <li class=\"header-nav-item media--hidden@tablet\">\n                    <a href=\"/what-is-escrow\" class=\"header-nav-tab";
if(runtime.contextOrFrameLookup(context, frame, "current_page") == "/what-is-escrow") {
output += " is-active";
;
}
output += "\" data-e2e-target=\"header-nav-what-is-escrow\" data-tracking-section=\"header\" data-tracking-label=\"what-is-escrow\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_22 = "";t_22 += "What Is Escrow?";
cb(null, t_22);
;
return t_22;
}
), true && env.opts.autoescape);
output += "</a>\n                  </li>\n                  <li class=\"header-nav-item media--hidden@tablet\">\n                    <a href=\"";
output += runtime.suppressValue((lineno = 34, colno = 37, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".integrations"])), env.opts.autoescape);
output += "\" class=\"header-nav-tab\" data-e2e-target=\"header-nav-integrations\" data-tracking-section=\"header\" data-tracking-label=\"integrations\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_23 = "";t_23 += "Integrations";
cb(null, t_23);
;
return t_23;
}
), true && env.opts.autoescape);
output += "</a>\n                  </li>\n                  <li class=\"header-nav-item media--hidden@tablet\">\n                    <a href=\"";
output += runtime.suppressValue((lineno = 37, colno = 37, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".helpdesk"])), env.opts.autoescape);
output += "\" class=\"header-nav-tab";
if(runtime.contextOrFrameLookup(context, frame, "current_page") == "/helpdesk") {
output += " is-active";
;
}
output += "\" data-e2e-target=\"header-nav-help\" data-tracking-section=\"header\" data-tracking-label=\"help\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_24 = "";t_24 += "Help";
cb(null, t_24);
;
return t_24;
}
), true && env.opts.autoescape);
output += "</a>\n                  </li>\n                  <li class=\"header-nav-item media--hidden@tablet\">\n                    <a href=\"";
output += runtime.suppressValue((lineno = 40, colno = 37, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".contact_us"])), env.opts.autoescape);
output += "\" class=\"header-nav-tab";
if(runtime.contextOrFrameLookup(context, frame, "current_page") == "/contact-us") {
output += " is-active";
;
}
output += "\" data-e2e-target=\"header-nav-contact-us\" data-tracking-section=\"header\" data-tracking-label=\"contact-us\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_25 = "";t_25 += "Contact Us";
cb(null, t_25);
;
return t_25;
}
), true && env.opts.autoescape);
output += "</a>\n                  </li>\n                  ";
if(runtime.contextOrFrameLookup(context, frame, "current_page") == "/") {
output += "\n                    <li class=\"header-nav-item media--hidden@tablet\">\n                      <a href=\"tel:";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "support_phone_number"), env.opts.autoescape);
output += "\" class=\"header-nav-tab header-phone\" data-e2e-target=\"header-phone-number\" data-tracking-section=\"header\" data-tracking-label=\"header-phone-number\">\n                      ";
output += runtime.suppressValue((lineno = 45, colno = 31, runtime.callWrap(t_7, "phoneIcon", context, [runtime.makeKeywordArgs({"specifier": "header-phone-icon"})])), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "support_phone_number"), env.opts.autoescape);
output += "</a>\n                    </li>\n                  ";
;
}
output += "\n                </ul>\n\n                <div class=\"header-userControl\">\n                  <ul class=\"header-nav-list\">\n                    <li class=\"header-nav-item\">\n                      <a href=\"";
output += runtime.suppressValue((lineno = 53, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".login_page"])), env.opts.autoescape);
output += "\" data-auth-required=\"loggedin_landing_page\" class=\"header-nav-tab";
if(runtime.contextOrFrameLookup(context, frame, "current_page") == "/login-page") {
output += " is-active";
;
}
output += "\" data-e2e-target=\"header-nav-login\" data-tracking-section=\"header\" data-tracking-label=\"login\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_26 = "";t_26 += "Log In";
cb(null, t_26);
;
return t_26;
}
), true && env.opts.autoescape);
output += "</a>\n                    </li>\n                    <li class=\"header-nav-item media--hidden@tablet\">\n                      <a href=\"";
output += runtime.suppressValue((lineno = 56, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".signup_page"])), env.opts.autoescape);
output += "\" data-auth-required=\"loggedin_landing_page\" class=\"header-nav-tab";
if(runtime.contextOrFrameLookup(context, frame, "current_page") == "/signup-page") {
output += " is-active";
;
}
output += "\" data-e2e-target=\"header-nav-signup\" data-tracking-section=\"header\" data-tracking-label=\"sign-up\">\n                        ";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_27 = "";t_27 += "Sign Up";
cb(null, t_27);
;
return t_27;
}
), true && env.opts.autoescape);
output += runtime.suppressValue((lineno = 57, colno = 59, runtime.callWrap(t_11, "arrowRightIcon", context, [runtime.makeKeywordArgs({"specifier": "header-userControl-arrow"})])), env.opts.autoescape);
output += "\n                      </a>\n                    </li>\n                  </ul>\n                </div>\n              </nav>\n              ";
;
}
output += "\n          </div>\n      </div>\n  </header>\n  ";
if(!runtime.contextOrFrameLookup(context, frame, "amp")) {
output += "\n  ";
env.getTemplate("navigation/sidebarLoggedOut.html", false, "escrow_www/templates/navigation/headerLoggedOut.html", null, function(t_30,t_28) {
if(t_30) { cb(t_30); return; }
t_28.render(context.getVariables(), frame, function(t_31,t_29) {
if(t_31) { cb(t_31); return; }
output += t_29
output += "\n  ";
})});
}
output += "\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/navigation/headerLoggedOut.html"] , dependencies)