import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment';
import { getCurrencyString, getCurrencyTagFromApiCurrency } from 'escrow-common-js/dist/utils';
import TransactionTable from 'spa/components/PartnerDashboard/tables/EnhancedTable';
import {
  transactionsCursorSelector,
  transactionsDataSelector,
  transactionsErrorSelector,
  transactionListPageTransactionCount,
  transactionListPageUiSelector,
  transactionListPageFilterValueIdsSelector,
  transactionTableFilterValueSelector,
  transactionListLoadingSelector,
} from 'spa/selectors/PartnerSelectors';
import { updateTransactionTableFilter as updateTransactionTableFilterAction } from 'spa/actions/PartnerActions';
import {
  applyFilters,
  getTransactionTotal,
  getTransactionStatus,
} from '../../../utils/PartnerDashboard';
import { urlFor } from '../../../routeConfig';

const columns = [
  { id: 'title', numeric: false, disablePadding: true, label: 'Title' },
  { id: 'dateCreated', numeric: false, disablePadding: false, label: 'Date Created' },
  { id: 'dateClosed', numeric: false, disablePadding: false, label: 'Date Closed' },
  { id: 'initiator', numeric: false, disablePadding: false, label: 'Initiator' },
  { id: 'amount', numeric: true, disablePadding: false, label: 'Amount', align: 'right' },
  { id: 'buyer', numeric: false, disablePadding: false, label: 'Buyer' },
  { id: 'seller', numeric: false, disablePadding: false, label: 'Seller' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
];

const mapDataToTable = (transactions) => {
  let data = [];
  if (transactions) {
    data = transactions.map((transaction) => {
      const buyer = transaction.parties.find((party) => party.role === 'buyer');
      const seller = transaction.parties.find((party) => party.role === 'seller');
      const initiator = transaction.parties.find((party) => party.initiator);
      const currencyCode = getCurrencyTagFromApiCurrency(transaction.currency);
      const total = getTransactionTotal(transaction);
      const status = getTransactionStatus(transaction);

      return {
        id: transaction.id,
        title: transaction.description,
        dateCreated: moment(transaction.creation_date).format('YYYY-MM-DD HH:mm'),
        dateClosed: transaction.close_date
          ? moment(transaction.close_date).format('YYYY-MM-DD HH:mm')
          : 'N/A',
        initiator: initiator.customer,
        amount: getCurrencyString(total, currencyCode),
        buyer: buyer.customer,
        seller: seller.customer,
        status,
      };
    });
  }
  return data;
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  columns,
  error: transactionsErrorSelector(state) || transactionListPageUiSelector(state).error,
  entries: applyFilters(
    state,
    ownProps.filters,
    transactionsDataSelector,
    transactionListPageFilterValueIdsSelector
  ),
  entryCountTotal: transactionListPageTransactionCount(state),
  nextCursor: transactionsCursorSelector(state),
  mapDataToTable,
  tableFilter: transactionTableFilterValueSelector(state),
  loading: transactionListLoadingSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  viewDetails: (transactionId) =>
    dispatch(
      push(urlFor('partner_dashboard_transactions_details', { transaction_id: transactionId }))
    ),
  updateTableFilter: (payload) => dispatch(updateTransactionTableFilterAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionTable);
