import React from 'react';
import PropTypes from 'prop-types';

const SectionTitle = ({ children, ...attributes }) => (
  <h2 className="integrationsPortal-section-title" {...attributes}>
    {children}
  </h2>
);

SectionTitle.propTypes = {
  children: PropTypes.string,
};

export default SectionTitle;
