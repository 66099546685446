import React from 'react';
import CheckoutConstants from 'spa/constants/CheckoutConstants';
import LineItem from './LineItem';
import Currency from '../Currency';
import { gettext } from '../../../utils/filters';

class TransactionDetails extends React.Component {
  _getCurrency() {
    return this.props.items[0].currency;
  }

  renderLineItems(items) {
    return items
      .filter((item) => !CheckoutConstants.CUSTOMER_FEE_TYPES.includes(item.type))
      .map((item) => <LineItem key={item.key} {...item} />);
  }

  renderExtraFees(items) {
    return items
      .filter(
        (item) =>
          CheckoutConstants.CUSTOMER_FEE_TYPES.includes(item.type) && item.payerRole === 'buyer'
      )
      .filter((item) => item.price !== 0)
      .map((item) => (
        <tr className="orderSummaryTable-breakdown" key={`${item.type}Fee`}>
          <td className="orderSummaryTable-breakdown-cell">
            {CheckoutConstants.apiFeeNameMapping[item.type]}
          </td>
          <td className="orderSummaryTable-breakdown-cell">
            <span className="orderSummaryTable-price">
              <Currency code={this._getCurrency()} amount={parseFloat(item.price)} withTag />
            </span>
          </td>
        </tr>
      ));
  }

  render() {
    const { items } = this.props;
    return (
      <table className="orderSummaryTable">
        <thead className="orderSummaryTable-header">
          <tr>
            <th className="orderSummaryTable-heading">{gettext('Item Description')}</th>
            <th className="orderSummaryTable-heading orderSummaryTable-heading--price">
              {gettext('Price')}
            </th>
          </tr>
        </thead>
        <tbody className="orderSummaryTable-body">{this.renderLineItems(items)}</tbody>
        <tbody className="orderSummaryTable-body">
          <tr className="orderSummaryTable-breakdown">
            <td className="orderSummaryTable-breakdown-cell">{gettext('Subtotal')}</td>
            <td className="orderSummaryTable-breakdown-cell">
              <span className="orderSummaryTable-price">
                <Currency code={this._getCurrency()} amount={this.props.subTotal} withTag />
              </span>
            </td>
          </tr>
          {this.renderExtraFees(items)}
          {this.props.buyerEscrowFee !== 0 ? (
            <tr className="orderSummaryTable-breakdown" key="escrowFee">
              <td className="orderSummaryTable-breakdown-cell">{gettext('Escrow Fee')}</td>
              <td className="orderSummaryTable-breakdown-cell">
                <span className="orderSummaryTable-price">
                  <Currency code={this._getCurrency()} amount={this.props.buyerEscrowFee} withTag />
                </span>
              </td>
            </tr>
          ) : null}
          {this.props.fullInfo
            ? [
                this.props.processingFee !== 0 ? (
                  <tr className="orderSummaryTable-breakdown" key="processingFee">
                    <td className="orderSummaryTable-breakdown-cell">
                      {gettext('Processing Fee')}
                    </td>
                    <td className="orderSummaryTable-breakdown-cell">
                      <span className="orderSummaryTable-price">
                        <Currency
                          code={this._getCurrency()}
                          amount={this.props.processingFee}
                          withTag
                        />
                      </span>
                    </td>
                  </tr>
                ) : null,
                <tr className="orderSummaryTable-breakdown" key="">
                  <td className="orderSummaryTable-breakdown-cell">{gettext('Total')}</td>
                  <td className="orderSummaryTable-breakdown-cell">
                    <span className="orderSummaryTable-price">
                      <Currency
                        code={this._getCurrency()}
                        amount={parseFloat(this.props.totalPrice[this.props.paymentType])}
                        withTag
                      />
                    </span>
                  </td>
                </tr>,
              ]
            : null}
        </tbody>
      </table>
    );
  }
}

export default TransactionDetails;
