import _ from 'lodash';

import React, { Fragment } from 'react';
import Icon from 'spa/components/Icon';
import Modal from 'spa/components/Modal';
import { toggleModalAction } from 'spa/actions/ModalActions';
import { connect } from 'react-redux';
import { getCurrencyString, getCurrencyTagFromApiCurrency } from 'escrow-common-js/dist/utils';
import { gettext } from '../../../utils/filters';

const HIDE_MERCHANT_URL_PARTNERS = [
  window.config.ebay_watches_psa_email,
  window.config.ebay_watches_nonpsa_email,
  window.config.ebay_watches_testpsa_email,
  window.config.ebay_watches_testnonpsa_email,
];

const calculatePercentage = (num, total) =>
  total === 0 ? 0 : Math.floor((num / total) * 100 * 100) / 100;

export const DashboardSummaryHeader = ({ title, children }) => (
  <header className="partnerDashboard-summary-header">
    {title && <h3 className="partnerDashboard-summary-title">{title}</h3>}
    {children}
  </header>
);

export const DashboardSummaryDetails = ({ label, value, total, note, small }) => (
  <div className="partnerDashboard-summary-details">
    {label && <span className="partnerDashboard-summary-label">{label}</span>}
    <span className={`partnerDashboard-summary-value ${small ? 'is-small' : ''}`}>
      {value} {total ? gettext(`(${calculatePercentage(value, total)}%)`) : ''}
    </span>
    {note && <span className="partnerDashboard-summary-note">{note}</span>}
  </div>
);

export const DetailsRow = ({ label, value }) => (
  <Fragment>
    {value && (
      <div className="partnerDashboard-details-row">
        <div className="partnerDashboard-details-label">{label}</div>
        <div className="partnerDashboard-details-value">{value}</div>
      </div>
    )}
  </Fragment>
);

const getTaxesDisplayString = (taxes) => taxes.map((tax) => tax.type.toUpperCase()).join(', ');

const getGroupedFeesByRole = (fees, customerRoles) =>
  fees.reduce((acc, cur) => {
    const feeByRole = _.get(acc, `${customerRoles[cur.payer_customer]}.${cur.type}`, {});

    return {
      ...acc,
      [customerRoles[cur.payer_customer]]: {
        ...(acc[customerRoles[cur.payer_customer]] || {}),
        [cur.type]: {
          ...cur,
          ...feeByRole,
          amount: (feeByRole.amount || 0) + cur.amount,
        },
      },
    };
  }, {});

const getFormattedLineItemFees = (items) => {
  const zeroFeesOnReject = ['disbursement'];

  const lineItemFees = items.reduce((acc, item) => {
    const isRejected = _.get(item, 'status.rejected');

    const fees = item.fees.map((fee) => {
      const tax = fee.amount !== fee.amount_without_taxes ? getTaxesDisplayString(fee.taxes) : null;
      return {
        amount: isRejected && zeroFeesOnReject.includes(fee.type) ? 0 : parseFloat(fee.amount),
        type: fee.type,
        payer_customer: fee.payer_customer,
        tax,
        label: `${_.startCase(fee.type)} Fee${tax ? ` with ${tax}` : ''}`,
      };
    });

    return [...acc, ...fees];
  }, []);

  return lineItemFees;
};

const getFormattedFeeItems = (items) =>
  items.reduce((acc, item) => {
    const schedules = item.schedule.map((sched) => ({
      amount: parseFloat(sched.amount),
      type: item.type,
      payer_customer: sched.payer_customer,
      label: `${_.startCase(item.type)}`,
    }));

    return [...acc, ...schedules];
  }, []);

const TransactionLineItems = ({ transaction, toggleModal, showTitle = true }) => {
  const { items, currency, parties } = transaction;
  const currencyCode = getCurrencyTagFromApiCurrency(currency);
  const itemFeeTypeNames = ['broker_fee', 'shipping_fee', 'partner_fee'];

  const hideMerchantUrl = HIDE_MERCHANT_URL_PARTNERS.includes(window.escrowUser.email);

  const customerRoles = parties.reduce((roles, p) => {
    if (p.role === 'partner') return roles;
    return { ...roles, [p.customer]: p.role };
  }, {});

  const lineItems = items.filter((item) => !itemFeeTypeNames.includes(item.type));
  const itemFees = items.filter((item) => itemFeeTypeNames.includes(item.type));

  const formattedFees = getFormattedFeeItems(itemFees);
  const formattedLineItemFees = getFormattedLineItemFees(lineItems);
  const formattedFeesByRole = getGroupedFeesByRole(
    [...formattedLineItemFees, ...formattedFees],
    customerRoles
  );

  const subtotal = lineItems
    .filter((item) => !_.get(item, 'status.rejected'))
    .reduce(
      (total, item) =>
        total +
        _.get(item, 'schedule', []).reduce(
          (scheduleTotal, schedule) => scheduleTotal + parseFloat(schedule.amount),
          0.0
        ),
      0.0
    );

  const displayAdditionalFeeNote = !items.every((item) =>
    item.schedule.every(
      (schedule) =>
        _.get(schedule, 'status.secured', false) ||
        _.get(schedule, 'status.disbursed_to_beneficiary', false)
    )
  );

  return (
    <Fragment>
      {showTitle && <h4 className="partnerDashboard-details-sectionTitle">Transaction Items</h4>}
      <div className="partnerDashboard-details-tableContainer">
        <table className="transactionTable">
          <thead className="transactionTable-header">
            <tr className="transactionTable-headerRow">
              <td className="transactionTable-cell">No.</td>
              <td className="transactionTable-cell">Item</td>
              <td className="transactionTable-cell" />
              <td className="transactionTable-cell" align="right">
                Price
              </td>
            </tr>
          </thead>
          <tbody className="transactionTable-body">
            {lineItems.map((item, index) => {
              const {
                id,
                title,
                schedule = [],
                status = {},
                extra_attributes: { merchant_url: merchantUrl = null, image_url: imageUrl = null },
              } = item;
              const price = schedule.reduce((total, s) => total + parseFloat(s.amount), 0.0);
              return (
                <tr key={`${id}-${title}`} className="transactionTable-row">
                  <td className="transactionTable-cell">{index + 1}</td>
                  <td className="transactionTable-cell">
                    {!hideMerchantUrl && merchantUrl ? (
                      <a href={merchantUrl} target="_blank">
                        {title}
                      </a>
                    ) : (
                      <span>{title}</span>
                    )}
                  </td>
                  <td className="transactionTable-cell">
                    {imageUrl && (
                      <div>
                        <a
                          role="button"
                          tabIndex={0}
                          onClick={() => {
                            event.preventDefault();
                            toggleModal(`itemModal-${id}`);
                          }}
                        >
                          <img
                            alt={`Item ${id}`}
                            className="transactionTable-cell--img transactionTable-cell--img--clickable"
                            src={imageUrl}
                          />
                        </a>
                        <Modal modifier="img" name={`itemModal-${id}`}>
                          <img alt={`Item ${id}`} src={imageUrl} />
                        </Modal>
                      </div>
                    )}
                  </td>
                  <td className="transactionTable-cell" align="right">
                    {getCurrencyString(status.rejected ? 0.0 : price || 0.0, currencyCode)}
                  </td>
                </tr>
              );
            })}
            <tr className="transactionTable-row transactionTable-row--summary">
              <td className="transactionTable-cell" colSpan="4" align="right">
                <div className="transactionTable-summary">
                  <div className="transactionTable-summary-group">
                    <div className="transactionTable-summary-row">
                      <div className="transactionTable-summary-label">Subtotal</div>
                      <div className="transactionTable-summary-value">
                        {getCurrencyString(subtotal, currencyCode)}
                      </div>
                    </div>
                  </div>
                  {Object.keys(formattedFeesByRole).map((key) => (
                    <div key={key}>
                      <div className="transactionTable-summary-group">
                        <div className="transactionTable-summary-header">
                          {`${_.startCase(key)} Fees`}
                        </div>
                        {Object.values(formattedFeesByRole[key]).map((fee) => (
                          <div key={fee.label} className="transactionTable-summary-row">
                            <div className="transactionTable-summary-label">{fee.label}</div>
                            <div className="transactionTable-summary-value">
                              <div>{getCurrencyString(fee.amount, currencyCode)}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                  {displayAdditionalFeeNote && (
                    <div className="transactionTable-summary-note">
                      <span className="transactionTable-summary-note-iconContainer">
                        <Icon name="ui-info" className="transactionTable-summary-note-icon" />
                      </span>
                      <span>
                        All payments are in {currency.toUpperCase()}. Additional intermediary bank
                        fees may be added.
                      </span>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};
const mapDispatchToProps = (dispatch) => ({
  toggleModal: (id) => dispatch(toggleModalAction(id)),
});
export const TransactionLineItemsTable = connect(null, mapDispatchToProps)(TransactionLineItems);
