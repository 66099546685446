import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Code } from 'spa/components/Code';
import PrimaryLink from 'spa/components/PrimaryLink';
import {
  Section,
  SectionTitle,
  SectionDesc,
  SectionSubTitle,
  SectionList,
  SectionListItem,
} from 'spa/components/Section';
import AccentedText from 'spa/components/AccentedText';
import HintBanner from 'spa/components/HintBanner';
import { urlFor, envDomainName } from '../../../routeConfig';

const CreateAuction = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktopSmall-9">
        <h1 className="integrationsPortal-title">Escrow Offer Documentation</h1>
        <Section>
          <SectionTitle>What is Escrow Offer?</SectionTitle>
          <SectionDesc>
            Escrow Offer is the easiest way to introduce the power of price negotiation for escrow
            transactions to your website, online store, mobile app or classifieds site. With a
            single call to the Escrow Offer API, you can set up a negotiation between a seller and
            one or more prospective buyers. It works like this:
          </SectionDesc>
          <SectionList>
            <SectionListItem>
              A buyer browsing on an integrated application sees an item they would like to
              purchase. However, they don{"'"}t feel comfortable making the purchase outright, as
              they would like to negotiate with the seller.
            </SectionListItem>
            <SectionListItem>
              The buyer clicks the listing{"'"}s Make Offer button, which has been integrated with
              the Escrow Offer API. The API sets up a negotiation between the prospective buyer and
              seller. The buyer is redirected to a form to make their initial offer to the seller.
            </SectionListItem>
            <SectionListItem>
              Once an offer is made, the seller is informed via email. Both parties may view the
              negotiation history on the offer management page as well as accept, counter, or reject
              any offer or counter offer made to them.
            </SectionListItem>
            <SectionListItem>
              Multiple buyers may negotiate with a seller on a given listing during this time. When
              a seller accepts an offer, the buyer is guided through the payment process. When
              payment is secured by Escrow.com for the listing, the negotiation is formally closed.
            </SectionListItem>
          </SectionList>
          <SectionDesc>
            <a href={"https://www."+envDomainName+"/offer"} target="_blank" rel="noopener noreferrer">
              Learn more about Escrow Offer and its benefits here.
            </a>
          </SectionDesc>
          <br />
        </Section>
        <Section>
          <SectionTitle id="transaction-simple">Calling the Escrow Offer API</SectionTitle>
          <SectionDesc>
            The call to the Escrow Offer API specifies how the transaction should be configured.
            This includes details about the item(s) being transacted, the buyer, the seller, the
            terms of the transaction, and optionally a broker for marketplace or commission
            scenarios.
          </SectionDesc>
          <SectionDesc>
            Special attributes can be added to the call to enable the following: Offers made above{' '}
            <AccentedText>accept_amount</AccentedText> (if specified) will automatically be
            accepted. Offers made below <AccentedText>reject_amount</AccentedText> (if specified)
            will be automatically rejected. If <AccentedText>include_buy_now</AccentedText> is not
            set to <AccentedText>false</AccentedText> then buyers will be able to make an offer
            which will be automatically accepted at the price specified by the transaction.
          </SectionDesc>
          <HintBanner
            className="integrationsPortal-hintBanner"
            title="Note"
            desc="The Escrow Offer API supports all the transaction configurations that are
            available in the create transaction endpoint in the Escrow API with the
            exception of Domain Name Holding transactions."
            icon="ui-alert-circle"
          />
          <HintBanner
            className="integrationsPortal-hintBanner"
            title="Note"
            desc="The Escrow Offer API uses the buyer_user placeholder for the buyer email address.
            This indicates that the buyer will provide their details on the offer form on
            www.escrow.com."
            icon="ui-alert-circle"
          />
          <HintBanner
            className="integrationsPortal-hintBanner"
            title="Note"
            desc="When calling the Escrow Offer API, you should specify a listing_reference value.
            This allows us to associate multiple buyers with the same listing so that the seller of
            a given listing may manage multiple offers from multiple buyers for that listing on the
            same offer management screen."
            icon="ui-alert-circle"
          />
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="shell">
                {`% curl "https://api.`+envDomainName+`/integration/2018-08-01/auction" \\
    -X POST \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
    -d '
    {
        "transaction": {
           "parties":[
              {
                "customer":"buyer_user",
                "role":"buyer",
                "agreed": true,
                "initiator": true
              },
              {
                "role": "seller",
                "customer": john.wick@test.escrow.com",
                "agreed": true,
                "first_name": "John",
                "last_name": "Wick",
                "address": {
                  "line1": "1829 West Lane",
                  "line2": "Apartment 301020",
                  "city": "San Francisco",
                  "state": "CA",
                  "country": "US",
                  "post_code": "10203"
                },
                "phone_number": "8885118600"
                "date_of_birth": "1994-2-28",
            }
           ],
           "currency":"usd",
           "description":"2008 BMW 328xi",
           "listing_reference":"listing-xyz456",
           "items": [
                {
                    "title": "2008 BMW 328xi",
                    "description": "A great car for the snow",
                    "type": "motor_vehicle",
                    "inspection_period": 259200,
                    "quantity": 1,
                    "schedule": [
                        {
                            "amount": 5000.0,
                            "payer_customer": "buyer_user",
                            "beneficiary_customer": "john.wick@test.escrow.com"
                        }
                    ],
                    "extra_attributes": {
                        "make": "BMW",
                        "model": "328xi",
                        "year": 2008
                    }
                }
            ]
        }
    }'
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.post(
    'https://api.`+envDomainName+`/integration/2018-08-01/auction',
    auth=('email address', 'api-key'),
    json={
        "transaction": {
           "parties":[
              {
                "customer":"buyer_user",
                "role":"buyer",
                "agreed": true,
                "initiator": true
              },
              {
                "role": "seller",
                "customer": john.wick@test.escrow.com",
                "agreed": true,
                "first_name": "John",
                "last_name": "Wick",
                "address": {
                  "line1": "1829 West Lane",
                  "line2": "Apartment 301020",
                  "city": "San Francisco",
                  "state": "CA",
                  "country": "US",
                  "post_code": "10203"
                },
                "phone_number": "8885118600"
                "date_of_birth": "1994-2-28",
              }
           ],
           "currency":"usd",
           "description":"2008 BMW 328xi",
           "listing_reference":"listing-xyz456",
           "items": [
                {
                    "title": "2008 BMW 328xi",
                    "description": "A great car for the snow",
                    "type": "motor_vehicle",
                    "inspection_period": 259200,
                    "quantity": 1,
                    "schedule": [
                        {
                            "amount": 5000.0,
                            "payer_customer": "buyer_user",
                            "beneficiary_customer": "john.wick@test.escrow.com"
                        }
                    ],
                    "extra_attributes": {
                        "make": "BMW",
                        "model": "328xi",
                        "year": 2008
                    }
                }
            ]
        }
    }
)
`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.`+envDomainName+`/integration/2018-08-01/auction")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Post.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
  request.body = {
        "transaction": {
        "parties":[
            {
                "customer":"buyer_user",
                "role":"buyer",
                "agreed": true,
                "initiator": true
            },
            {
              "role": "seller",
              "customer": john.wick@test.escrow.com",
              "agreed": true,
              "first_name": "John",
              "last_name": "Wick",
              "address": {
                "line1": "1829 West Lane",
                "line2": "Apartment 301020",
                "city": "San Francisco",
                "state": "CA",
                "country": "US",
                "post_code": "10203"
              },
              "phone_number": "8885118600"
              "date_of_birth": "1994-2-28",
            }
        ],
        "currency":"usd",
        "description":"2008 BMW 328xi",
        "listing_reference":"listing-xyz456",
        "items": [
                {
                    "title": "2008 BMW 328xi",
                    "description": "A great car for the snow",
                    "type": "motor_vehicle",
                    "inspection_period": 259200,
                    "quantity": 1,
                    "schedule": [
                        {
                            "amount": 5000.0,
                            "payer_customer": "buyer_user",
                            "beneficiary_customer": "john.wick@test.escrow.com"
                        }
                    ],
                    "extra_attributes": {
                        "make": "BMW",
                        "model": "328xi",
                        "year": 2008
                    }
                }
            ]
        }
    }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.`+envDomainName+`/integration/2018-08-01/auction',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'currency' => 'usd',
            'description' => 'Perfect sedan for the snow',
            'listing_reference' => 'listing-xyz456',
            'items' => array(
                array(
                    'extra_attributes' => array(
                        'make' => 'BMW',
                        'model' => '328xi',
                        'year' => '2008',
                    ),
                    'inspection_period' => '259200',
                    'quantity' => '1',
                    'schedule' => array(
                        array(
                            'amount' => '8000',
                            'beneficiary_customer' => 'john.wick@test.escrow.com',
                            'payer_customer' => 'buyer_user',
                        ),
                    ),
                    'title' => 'BMW 328xi',
                    'type' => 'motor_vehicle',
                ),
            ),
            'parties' => array(
                array(
                    'agree' => 'true',
                    'customer' => 'buyer_user',
                    'initiator' => 'true',
                    'role' => 'buyer',
                ),
                array(
                  'role': 'seller',
                  'customer': john.wick@test.escrow.com',
                  'agreed' => 'true',
                  'first_name' => 'John',
                  'last_name' => 'Wick',
                  'address' => array(
                    'line1' => '1829 West Lane',
                    'line2' => 'Apartment 301020',
                    'city' => 'San Francisco',
                    'state' => 'CA',
                    'country' => 'US',
                    'post_code' => '10203'
                  ),
                  'phone_number' => '8885118600'
                  'date_of_birth' => '1994-2-28',
                ),
            ),
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                currency = "usd",
                description = "Perfect sedan for the snow",
                listing_reference = "listing-xyz456",
                items = new[]
                {
                    new
                    {
                        extra_attributes = new
                        {
                            make = "BMW",
                            model = "328xi",
                            year = "2008",
                        },
                        inspection_period = "259200",
                        quantity = "1",
                        schedule = new[]
                        {
                            new
                            {
                                amount = "8000",
                                beneficiary_customer = "john.wick@test.escrow.com",
                                payer_customer = "buyer_user",
                            },
                        },
                        title = "BMW 328xi",
                        type = "motor_vehicle",
                    },
                },
                parties = new[]
                {
                    new
                    {
                        customer = "buyer_user",
                        role = "buyer",
                        agreed = true,
                        initiator = true,
                    },
                    new
                    {
                        role = "seller",
                        customer = john.wick@test.escrow.com",
                        agreed = true,
                        first_name = "John",
                        last_name = "Wick",
                        address = new {
                          "line1 = "1829 West Lane",
                          "line2 = "Apartment 301020",
                          "city = "San Francisco",
                          "state = "CA",
                          "country = "US",
                          "post_code = "10203"
                        },
                        phone_number = "8885118600"
                        date_of_birth = "1994-2-28",
                    },
                },
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.`+envDomainName+`/integration/2018-08-01/auction"),
                Method = HttpMethod.Post,
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
          <SectionSubTitle>Example Response</SectionSubTitle>
          <Code language="json">
            {`{
    "make_offer_page": "https://www.`+envDomainName+`/offer?token=2d2afb9f-364f-4f9f-82a5-803344d60432&source=api",
    "offer_management_page": "https://www.`+envDomainName+`/offer-management?token=2d2afb9f-364f-4f9f-82a5-803344d60432&source=api",
    "token": "2d2afb9f-364f-4f9f-82a5-803344d60432"
}`}
          </Code>
          <br />
          <br />
          <SectionSubTitle>make_offer_page attribute</SectionSubTitle>
          <SectionDesc>
            The <AccentedText>make_offer_page</AccentedText> attribute contains the link to which
            the buyer should be redirected. The buyer will then be able to make an offer to the
            seller.
          </SectionDesc>
          <SectionDesc>
            Prefilling of fields is also supported by appending extra queries to the url. Supported
            queries for prefilling fields are:
          </SectionDesc>
          <SectionList>
            <SectionListItem>
              <AccentedText>email</AccentedText>
            </SectionListItem>
          </SectionList>
          <SectionSubTitle>offer_management_page attribute</SectionSubTitle>
          <SectionDesc>
            The <AccentedText>offer_management_page</AccentedText> attribute contains the link to
            which both the seller and any buyers may be redirected. Those users can then participate
            and track the negotiation.
          </SectionDesc>
          <SectionSubTitle>token attribute</SectionSubTitle>
          <SectionDesc>
            The <AccentedText>token</AccentedText> attribute contains the Escrow.com negotiation
            identifier that was created by the call to the Escrow Offer API.
          </SectionDesc>
          <SectionDesc>
            For help on making API calls and configuring transactions, please review the links
            below. The Webhooks section may be of interest, as it will allow you to receive
            notifications in real-time to any transactions created as a result of a successful
            Escrow Offer negotiation.
          </SectionDesc>
        </Section>
        <footer className="integrationsPortal-footer">
          <h3 className="integrationsPortal-footer-title">{'Relevant Resources'}</h3>
          <nav className="integrationsPortal-footer-steps">
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="API Basics"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_basics'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Creating transactions"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_create_transaction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Webhooks"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_webhooks'),
                }}
              />
            </div>
          </nav>
        </footer>
      </div>
    </div>
  </div>
);

export default CreateAuction;
