import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { required } from 'spa/components/form/validate';
import { MenuItem } from '@mui/material';
import VerificationConstants, {
  businessTypeOptions,
  businessTypeOptionsAUS,
  companyRelationshipOptions,
  companyProofTypeOptions,
  companyProofTypeOptionsAUS,
  COMPANY_DOCUMENT,
  KYCErrorAlertMessages,
} from 'spa/constants/VerificationConstants';
import {
  customerCompanyAddressCountryNameSelector,
  customerKycFileForProofSelector,
  customerKycHasPrevPagesSelector,
  customerKycSubmitLoadingSelector,
} from 'spa/selectors/CustomerSelectors';
import {
  kycClearErrorAlert,
  kycGotoPrevPage as kycGotoPrevPageRoutine,
  kycSetErrorAlert,
  kycSubmitCompany as kycSubmitCompanyRoutine,
} from 'spa/actions/VerificationActions';
import ActionButtons from 'spa/components/CustomerVerification/V2/ActionButtons';
import { KYCContext } from 'spa/context/KYCContext';
import Heading from './Heading';
import UploadBox from './UploadBox';
import { InputField } from './Fields';

const CompanyVerificationForm = ({
  companyCountry,
  loadPrevPage,
  getUploadedFile,
  submitKYCCompany,
  setErrorAlert,
  clearErrorAlert,
  isSubmitting,
  hasPrevPages,
}) => {
  const kycContext = useContext(KYCContext);
  const onFormSubmit = (values) => {
    if (!getUploadedFile(COMPANY_DOCUMENT).success) {
      setErrorAlert(KYCErrorAlertMessages.FILE_REQUIRED);
      return FORM_ERROR;
    }
    clearErrorAlert();
    submitKYCCompany({ values, kycContext });
  };

  return (
    <div className="kyc-companyVerification">
      <Form
        onSubmit={onFormSubmit}
        subscription={{
          values: true,
          errors: true,
          submitError: true,
          submitting: true,
          hasValidationErrors: true,
          modifiedSinceLastSubmit: true,
        }}
      >
        {({ handleSubmit, hasValidationErrors, values }) => (
          <form onSubmit={handleSubmit} name={VerificationConstants.COMPANY_VERIFICATION_FORM}>
            <Heading text="Verification Method" hasDivider />
            <div className="kyc-companyVerification-section">
              <div className="kyc-companyVerification-section-input">
                <InputField
                  name="company-type"
                  select
                  selectHandleOlark
                  validate={required}
                  label="Company Type"
                  disabled={isSubmitting}
                >
                  {(companyCountry === 'Australia'
                    ? businessTypeOptionsAUS
                    : businessTypeOptions
                  ).map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </InputField>
              </div>
              <div className="kyc-companyVerification-section-input">
                <InputField
                  name="company-relationship"
                  select
                  selectHandleOlark
                  validate={required}
                  label="Your relationship to the company"
                  disabled={isSubmitting}
                >
                  {companyRelationshipOptions.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </InputField>
              </div>
              {values['company-type'] && (
                <div className="kyc-companyVerification-section-input">
                  <InputField
                    name="company-proof-type"
                    select
                    selectHandleOlark
                    validate={required}
                    label="Document Type"
                    disabled={isSubmitting}
                  >
                    {(companyCountry === 'Australia'
                      ? companyProofTypeOptionsAUS[values['company-type']]
                      : companyProofTypeOptions[values['company-type']]
                    ).map(({ label, value }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </InputField>
                </div>
              )}
              <div className="kyc-companyVerification-section-input">
                <UploadBox
                  proofFor={COMPANY_DOCUMENT}
                  label="Upload document"
                  disabled={isSubmitting}
                />
              </div>
            </div>
            <ActionButtons
              secondaryText="Back"
              primaryText="Submit"
              isLoading={isSubmitting}
              isDisabled={hasValidationErrors || !getUploadedFile(COMPANY_DOCUMENT).success}
              withBack={hasPrevPages}
              onSecondaryClick={() => loadPrevPage()}
              type="submit"
            />
          </form>
        )}
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  companyCountry: customerCompanyAddressCountryNameSelector(state),
  getUploadedFile: (proofFor) => customerKycFileForProofSelector(state, proofFor),
  isSubmitting: customerKycSubmitLoadingSelector(state),
  hasPrevPages: customerKycHasPrevPagesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadPrevPage: () => dispatch(kycGotoPrevPageRoutine.trigger()),
  submitKYCCompany: (payload) => dispatch(kycSubmitCompanyRoutine.trigger(payload)),
  setErrorAlert: (error) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    dispatch(kycSetErrorAlert(error));
  },
  clearErrorAlert: () => dispatch(kycClearErrorAlert()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyVerificationForm);
