import { connect } from 'react-redux';
import {
  getWireDetails,
  initPlaidClient,
  getCheckDetails,
  setPaymentType,
  fetchTransactionAndPaymentMethods,
  resetPaymentPageState,
  getLastBuyerBankEntry,
  launchPlaidClient,
  showWireForm as setShowWireForm,
  setAdyenPaymentToken,
} from 'spa/actions/PaymentsActions';
import { PaymentForm } from 'spa/components/Payments';
import {
  buyerCheckoutDetailsSelector,
  customerSavedCardsLoadingSelector,
  customerSavedCardsCalledOnceSelector,
  customerSavedCreditCardsSelector,
  customerSavedPlaidAccountsSelector,
  customerSavedPaymentLoadingSelector,
} from 'spa/selectors/CustomerSelectors';
import {
  paymentsPagePaymentTypeSelector,
  paymentsPagePaymentSubmissionErrorSelector,
  paymentsPageWireDetailsSelector,
  paymentsPageShowWireDetailsFormSelector,
  paymentsPageWireDetailsErrorSelector,
  paymentsPageCheckDetailsSelector,
  paymentsPageCheckDetailsErrorSelector,
  paymentsPagePlaidClientSelector,
} from 'spa/selectors/PaymentsPageSelectors';
import { trackingAbTestSelector } from 'spa/selectors/TrackingSelectors';
import { lastBuyerBankEntrySelector, adyenIsValidSelector } from 'spa/selectors/PaymentSelectors';
import {
  transactionByIdSelector,
  transactionFeesSelector,
  transactionLineItemsSelector,
  transactionAdyenAdditionalActionSelector,
  transactionAdyenCheckoutSelector,
  transactionHasAdyenCCSelector,
  transactionCCPaymentGatewaySelector,
} from 'spa/selectors/TransactionSelectors';
import { getAbTest } from 'spa/actions/TrackingActions';
import { getSavedCreditCards, getSavedPlaidAccounts } from 'spa/actions/UserActions';

import collectBrowserInfo from '../../../utils/BrowserInfo';

const mapDispatchToProps = (dispatch, ownProps) => ({
  resetPaymentPageState: () => dispatch(resetPaymentPageState()),
  setShowWireForm: () => dispatch(setShowWireForm()),
  getTransactionAndPaymentMethods: () =>
    dispatch(fetchTransactionAndPaymentMethods.trigger({ transId: ownProps.transactionId })),
  getWireDetails: () => dispatch(getWireDetails.trigger({ transId: ownProps.transactionId })),
  getAbTest: (testConfig) => dispatch(getAbTest.trigger(testConfig)),
  initPlaidClient: () => dispatch(initPlaidClient.trigger()),
  getCheckDetails: () => dispatch(getCheckDetails.trigger({ transId: ownProps.transactionId })),
  launchPlaidClient: () => dispatch(launchPlaidClient.trigger()),
  getSavedPlaidAccounts: () => dispatch(getSavedPlaidAccounts.trigger()),
  getSavedCreditCards: (currency) =>
    dispatch(
      getSavedCreditCards.trigger({
        customerId: ownProps.customerId,
        currency: currency,
      })
    ),
  setPaymentType: (paymentType) => dispatch(setPaymentType(paymentType)),
  getLastBuyerBankEntry: () =>
    dispatch(getLastBuyerBankEntry.trigger({ customerId: ownProps.customerId })),
  setAdyenPaymentToken: (adyenCCId) =>
    dispatch(setAdyenPaymentToken({ adyenCCId, browserInfo: collectBrowserInfo() })),
});

const mapStateToProps = (state, ownProps) => {
  const transaction = transactionByIdSelector(state, ownProps.transactionId);

  const lastBuyerBankEntry = lastBuyerBankEntrySelector(state);
  let initialBuyerBankDetails = null;
  if (lastBuyerBankEntry) {
    initialBuyerBankDetails = {
      bankState: lastBuyerBankEntry.bank_state,
      bankCountry: lastBuyerBankEntry.bank_country,
      accountName: lastBuyerBankEntry.account_name,
      bankName: lastBuyerBankEntry.bank_name,
      thirdPartyCheckbox: Boolean(lastBuyerBankEntry.third_party_email),
      thirdPartyEmail: lastBuyerBankEntry.third_party_email,
    };
  }

  return {
    ...ownProps,
    customerDetails: buyerCheckoutDetailsSelector(state),
    fees: transactionFeesSelector(transaction),
    fetchErrors:
      paymentsPageWireDetailsErrorSelector(state) || paymentsPageCheckDetailsErrorSelector(state),
    initialValues: {
      paymentType: 'wire_transfer',
      buyerBankDetails: initialBuyerBankDetails || undefined,
    },
    transactionCCPaymentGateway: transactionCCPaymentGatewaySelector(state, transaction.id),
    lineItems: transactionLineItemsSelector(transaction),
    paymentType: paymentsPagePaymentTypeSelector(state),
    submissionError: paymentsPagePaymentSubmissionErrorSelector(state),
    transaction: Object.keys(transaction).length > 0 ? transaction : null,
    wireDetails: paymentsPageWireDetailsSelector(state),
    showWireForm: paymentsPageShowWireDetailsFormSelector(state),
    checkDetails: paymentsPageCheckDetailsSelector(state),
    customerSavedPaymentLoading: customerSavedPaymentLoadingSelector(state),
    customerSavedPlaidAccounts: customerSavedPlaidAccountsSelector(state),
    customerSavedCreditCards: customerSavedCreditCardsSelector(state),
    customerSavedCreditCardsLoading: customerSavedCardsLoadingSelector(state),
    customerSavedCreditCardsCalledOnce: customerSavedCardsCalledOnceSelector(state),
    abTests: trackingAbTestSelector(state),
    plaidClient: paymentsPagePlaidClientSelector(state),
    isAdyenValid: adyenIsValidSelector(state),
    adyenCCAvailable: transactionHasAdyenCCSelector(transaction),
    adyenAction: transactionAdyenAdditionalActionSelector(transaction),
    adyenCheckout: transactionAdyenCheckoutSelector(transaction),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);
