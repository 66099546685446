export default {
  CATEGORY_MENU: 'CATEGORY_MENU',
  CATEGORY_TRANSACTIONS: 'CATEGORY_TRANSACTIONS',
  SEARCH_INPUT: 'SEARCH_INPUT',
  SEARCH_INVALID: 'SEARCH_INVALID',
  SEARCH_LIMIT: 5,
  SEARCH_SUGGESTION_MAX_RESULTS: 2,
  SEARCH_RESET: 'SEARCH_RESET',
  SEARCH_RESULTS: 'SEARCH_RESULTS',
  SEARCH_TIMEOUT: 400,
  DEBOUNCE_TIMEOUT: 800,
  SEARCH_HOVER: 'SEARCH_HOVER',
  SEARCH_SELECT: 'SEARCH_SELECT',
  SEARCH_UPDATE_INPUTS: 'SEARCH_UPDATE_INPUTS',
  CALCULATOR_SEARCH_LIMIT: 8,
  TRACKING_SEARCH: 'TRACKING_SEARCH',
  LOCATION_HEADER: 'SearchConstants.LOCATION_HEADER',
};
