import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, FieldArray } from 'redux-form';
import { bindRoutineToReduxForm } from 'redux-saga-routines';
import { FileUpload } from 'spa/components/form';
import { DashboardCardHeader } from 'spa/components/DashboardCard';
import { Spinner } from 'spa/components/Indicators';
import { submitPartnerLogo } from 'spa/actions/UserActions';
import { oneOrMoreFiles } from 'spa/components/form/validate';
import { fileUploadingSelector } from 'spa/selectors/FileUploadSelectors';
import UserConstants from 'spa/constants/UserConstants';

import LogoPreview from './LogoPreview';
import { fileValidatorFactory } from '../../../utils/FormValidation';
import { gettext } from '../../../utils/filters';

const fileValidator = fileValidatorFactory(['image/jpg', 'image/jpeg', 'image/png'], 1000000);

class LogoUploader extends React.Component {
  constructor(props) {
    super(props);

    this.renderForm = this.renderForm.bind(this);
    this.renderPreview = this.renderPreview.bind(this);
    this.renderControlPanel = this.renderControlPanel.bind(this);
  }

  componentDidMount() {
    if (!this.props.partnerLogos && this.props.customerId) {
      this.props.listLogos();
    }
  }

  componentDidUpdate() {
    if (!this.props.partnerLogos && this.props.customerId) {
      this.props.listLogos();
    }
  }

  renderPreview() {
    return <LogoPreview logos={this.props.partnerLogos} customerId={this.props.customerId} />;
  }

  renderForm() {
    const { note, extraNote, fileExtensions } = this.props;
    return (
      <div>
        <DashboardCardHeader title={gettext('Upload your Logo')} />
        <form onSubmit={this.props.handleSubmit(bindRoutineToReduxForm(submitPartnerLogo))}>
          <FieldArray
            name="logoFiles"
            component={FileUpload}
            label={gettext('Uploaded Files')}
            fileValidators={[fileValidator]}
            fileExtensions={fileExtensions}
            uploadEndpoint="/api/file/logo"
            numFiles={1}
            note={note}
            extraNote={extraNote}
            validate={[oneOrMoreFiles]}
          />
          <button
            className="checkout-card-btn btn btn--block btn--secondary"
            disabled={this.props.submitting || this.props.isFileUploading}
          >
            {this.props.submitting ? <Spinner /> : gettext('Submit')}
          </button>
        </form>
      </div>
    );
  }

  renderControlPanel() {
    const isLoading = this.props.partnerLogosLoading;
    return (
      <button
        className="integrationsPortal-removeBtn btn"
        onClick={this.props.removeLogo}
        disabled={isLoading}
      >
        {isLoading ? <Spinner /> : gettext('Remove Logo')}
      </button>
    );
  }

  render() {
    let displayForm = false;
    let displayPreview = false;

    if (this.props.partnerLogos && this.props.partnerLogos.length === 0) {
      displayForm = true;
    }

    if (this.props.partnerLogos && this.props.partnerLogos.length > 0) {
      displayPreview = true;
    }

    return (
      <div>
        {this.props.partnerLogosLoading && <Spinner />}
        {displayForm && this.renderForm()}
        {displayPreview && this.renderPreview()}
        {displayPreview && this.renderControlPanel()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isFileUploading: fileUploadingSelector(state),
});
export default connect(
  mapStateToProps,
  null
)(
  reduxForm({
    form: UserConstants.PARTNER_LOGO_FORM,
  })(LogoUploader)
);
