import deepcopy from 'clone';

import AppDispatcher from '../dispatcher/AppDispatcher';
import { ChangeEmitter } from '../utils/ChangeEmitter';
import DomainInputConstants from '../constants/DomainInputConstants';

export class DomainInputStore extends ChangeEmitter {
  constructor() {
    super();

    this.pendingDomain = {};
    this.domains = [];
    this.fileError = false;
  }

  getPendingDomain() {
    return deepcopy(this.pendingDomain);
  }

  /*
   * Returns the list of domains which have been entered.
   * Include the pending domain only if it is valid.
   */
  getAllDomains() {
    const allDomains = deepcopy(this.domains);
    if (this.pendingDomain.validName && this.pendingDomain.validPrice) {
      allDomains.push(this.pendingDomain);
    }
    return allDomains;
  }

  getDomains() {
    return deepcopy(this.domains);
  }

  allDomainsValid() {
    for (const domain of this.domains) {
      if (!domain.validName || !domain.validPrice) {
        return false;
      }
    }

    // If there is a pending domain, consider it in the list
    if (this.pendingDomain.url || this.pendingDomain.price) {
      return this.pendingDomain.validPrice && this.pendingDomain.validName;
    }
    return this.domains.length > 0;
  }

  handleViewAction(action) {
    if (action.actionType === DomainInputConstants.ADD_ITEM) {
      const validPrice = this.pendingDomain.price > 0;
      if (this.pendingDomain.url && validPrice) {
        this.domains.push({
          url: this.pendingDomain.url,
          price: this.pendingDomain.price,
          validName: !!this.pendingDomain.url,
          validPrice: validPrice,
        });
        this.pendingDomain = {};
      }
      this.emitChange();
    } else if (action.actionType === DomainInputConstants.REMOVE_ITEM) {
      this.domains.splice(action.index, 1);
      this.emitChange();
    } else if (action.actionType === DomainInputConstants.ITEM_INPUT) {
      if (action.index !== undefined && action.index >= 0) {
        this.domains[action.index] = {
          url: action.url,
          price: action.price,
          validName: !!action.url,
          validPrice: action.price > 0,
        };
      } else if (action.url) {
        this.pendingDomain = {
          url: action.url,
          price: action.price,
          validName: !!action.url,
          validPrice: action.price > 0,
        };
      } else {
        this.pendingDomain = {};
      }
      this.emitChange();
    } else if (action.actionType === DomainInputConstants.INSERT_LIST) {
      for (const domain of action.list) {
        if (domain.url && domain.price > 0) {
          this.domains.push({
            url: domain.url,
            price: domain.price,
            validName: true,
            validPrice: true,
          });
        }
      }
      this.fileError = false;
      this.emitChange();
    } else if (action.actionType === DomainInputConstants.FILE_ERROR) {
      this.fileError = true;
      this.emitChange();
    }
  }
}

const domainInputStore = new DomainInputStore();
domainInputStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;
  const source = payload.source;

  if (source === 'VIEW_ACTION') {
    domainInputStore.handleViewAction(action);
  }
});

export default domainInputStore;
