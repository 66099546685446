import _ from 'lodash';
import React from 'react';
import classnames from 'classnames';
import Icon from 'spa/components/Icon';
import Checkbox from 'spa/components/Checkbox';
import MediaQuery from 'react-responsive';
import MediaQueryConstants from 'spa/constants/MediaQueryConstants';
import { Modal } from 'escrow-common-js/dist/components';
import TourStep from 'spa/components/TransactionsTour/TransactionsTourStep';

const SORT_COLUMNS = [
  { key: 'initiation_date-desc', label: 'Most Recent' },
  { key: 'initiation_date-asc', label: 'Oldest' },
  { key: 'id-asc', label: 'ID Number - Ascending' },
  { key: 'id-desc', label: 'ID Number - Descending' },
  { key: 'description-asc', label: 'Transaction Title - A to Z' },
  { key: 'description-desc', label: 'Transaction Title - Z to A' },
  { key: 'total-desc', label: 'Price Range - High to Low' },
  { key: 'total-asc', label: 'Price Range - Low to High' },
  { key: 'status_code-asc', label: 'Status - A to Z' },
  { key: 'status_code-desc', label: 'Status - Z to A' },
];

const TRANSACTION_TYPES = [
  { key: 'domain_name', label: 'Domain Name' },
  { key: 'domain_name_holding', label: 'Domain Name Holding' },
  { key: 'motor_vehicle', label: 'Motor Vehicle' },
  { key: 'general_merchandise', label: 'General Merchandise' },
  { key: 'milestone', label: 'Milestone' },
];

const PRICE_RANGE = [
  { min: null, max: null, label: 'Any Price' },
  { min: null, max: 2999.99, label: '< 3,000' },
  { min: 3000, max: 10000, label: '3,000 - 10,000' },
  { min: 10000, max: 50000, label: '10,000 - 50,000' },
  { min: 50000.01, max: null, label: '> 50,000' },
];

const TRANSACTION_ROLES = ['buyer', 'seller', 'broker'];

const FilterType = ({ label, children, placeholder, setActive, active }) => (
  <div className="transactions-filter-select-container">
    <label className="transactions-filter-select-label">{label}</label>
    <div
      className={classnames('transactions-filter-select', {
        'is-active': active,
      })}
      onClick={setActive}
      role="button"
      tabIndex={0}
    >
      <div className="transactions-filter-select-placeholder" role="button" tabIndex={0}>
        <span
          className={classnames('transactions-filter-select-placeholder-label', {
            'is-selected': !!placeholder,
          })}
        >
          {placeholder || `Select a ${label}`}
        </span>
        <Icon name="ui-sort-down" className="transactions-icon" wrapper="span" />
      </div>
      <div className="transactions-filter-select-options">{children}</div>
    </div>
  </div>
);

class TransactionFilters extends React.Component {
  constructor(props) {
    super(props);
    const {
      role,
      type,
      amount: { min, max },
      sortBy = 'id',
      sortDirection = 'desc',
    } = props.currentFilters;
    this.state = {
      collapsed: true,
      activeSelect: null,
      filters: {
        sortBy,
        sortDirection,
        type,
        role,
        amount: { min, max },
      },
    };
    this.getActiveFiltersCount = this.getActiveFiltersCount.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.toggleActiveSelect = this.toggleActiveSelect.bind(this);
    this.toggleCheckbox = this.toggleCheckbox.bind(this);
    this.selectPriceRange = this.selectPriceRange.bind(this);
    this.renderRoleFilter = this.renderRoleFilter.bind(this);
    this.renderPriceRangeFilter = this.renderPriceRangeFilter.bind(this);
    this.renderTransactionTypesFilter = this.renderTransactionTypesFilter.bind(this);
    this.renderMobileVersion = this.renderMobileVersion.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.closeFilter = this.closeFilter.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentFilters.status !== this.props.currentFilters.status) {
      this.clearFilters();
    }
  }

  getActiveFiltersCount() {
    const {
      role,
      type,
      amount: { min, max },
    } = this.props.currentFilters;
    let activeFilters = 0;
    if (role.length) activeFilters++;
    if (type.length) activeFilters++;
    if (min || max) activeFilters++;
    return activeFilters;
  }

  formatFilterLabel(filter, defaultLabel) {
    const ret = {
      sort: (
        SORT_COLUMNS.find(
          (constant) => constant.key === `${filter.sortBy}-${filter.sortDirection}`
        ) || {}
      ).label,
      type:
        filter.type.length > 0
          ? filter.type
              .map((t) => TRANSACTION_TYPES.find((constant) => constant.key === t).label)
              .join(', ')
          : 'All',
      role:
        filter.role.length > 0
          ? filter.role.map((t) => TRANSACTION_ROLES.find((constant) => constant === t)).join(', ')
          : 'All',
      amount: PRICE_RANGE.find(
        (constant) => constant.min === filter.amount.min && constant.max === filter.amount.max
      ).label,
    };
    if (defaultLabel !== undefined) {
      ret.type = ret.type === 'All' ? defaultLabel : ret.type;
      ret.role = ret.role === 'All' ? defaultLabel : ret.role;
      ret.amount = ret.amount === 'Any Price' ? defaultLabel : ret.amount;
    }
    return ret;
  }

  selectSort(selected) {
    const [column, direction] = selected.split('-');
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        sortDirection: direction,
        sortBy: column,
      },
    }));
  }

  clearFilters() {
    this.setState({
      filters: {
        role: [],
        type: [],
        amount: { min: null, max: null },
      },
    });
  }

  toggleActiveSelect(filter) {
    this.setState((prevState) => ({
      activeSelect: prevState.activeSelect === filter ? null : filter,
    }));
  }

  toggleCheckbox(value, filterType) {
    const valueIndex = this.state.filters[filterType].indexOf(value);
    if (valueIndex > -1) {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          [filterType]: [
            ...prevState.filters[filterType].slice(0, valueIndex),
            ...prevState.filters[filterType].slice(valueIndex + 1),
          ],
        },
      }));
    } else {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          [filterType]: [...prevState.filters[filterType], value],
        },
      }));
    }
  }

  selectPriceRange(min, max) {
    this.setState((prevState) => ({
      filters: { ...prevState.filters, amount: { min, max } },
    }));
  }

  closeFilter(event, forced) {
    const mobileMenu = document.getElementById('transactions-filter-modal');
    if (
      forced ||
      !(
        this.desktopMenu.contains(event.target) ||
        (mobileMenu && mobileMenu.contains(event.target))
      )
    ) {
      event.stopPropagation();
      this.setState({ collapsed: true }, () =>
        document.removeEventListener('click', this.closeFilter, true)
      );
    }
  }

  toggleFilter(event) {
    if (this.state.collapsed) {
      this.setState({ collapsed: false }, () => {
        document.addEventListener('click', this.closeFilter, true);
      });
    } else {
      this.closeFilter(event, true);
    }
  }

  renderRoleFilter(prefix) {
    return TRANSACTION_ROLES.map((transRole) => (
      <Checkbox
        name={transRole}
        key={prefix + transRole}
        label={transRole}
        id={prefix + transRole}
        checked={this.state.filters.role.includes(transRole)}
        onClick={() => this.toggleCheckbox(transRole, 'role')}
        flipped
        className={classnames(
          'transactions-filter-select-options-item',
          'transactions-filter-select-options-item--checkbox'
        )}
        showLabel
      />
    ));
  }

  renderPriceRangeFilter(prefix) {
    return PRICE_RANGE.map((range, index) => (
      <label
        className={classnames(
          'transactions-filter-select-options-item',
          'transactions-filter-select-options-item--radio',
          'defaultRadio'
        )}
        key={prefix + range.label}
      >
        <input
          className="defaultRadio-input"
          name={`${prefix}amount`}
          value={index}
          component="input"
          type="radio"
          onChange={() => this.selectPriceRange(range.min, range.max)}
          checked={
            this.state.filters.amount.min === range.min &&
            this.state.filters.amount.max === range.max
          }
        />
        <div className="defaultRadio-pseudo" />
        <span className="defaultRadio-label">
          <span className="defaultRadio-title">{range.label}</span>
        </span>
      </label>
    ));
  }

  renderTransactionTypesFilter(prefix) {
    return TRANSACTION_TYPES.map((transType) => (
      <Checkbox
        name={transType.key}
        key={prefix + transType.key}
        label={transType.label}
        id={prefix + transType.key}
        checked={this.state.filters.type.includes(transType.key)}
        onClick={() => this.toggleCheckbox(transType.key, 'type')}
        flipped
        className={classnames(
          'transactions-filter-select-options-item',
          'transactions-filter-select-options-item--checkbox'
        )}
        showLabel
      />
    ));
  }

  renderMobileVersion() {
    const {
      role,
      type,
      amount: { min, max },
      sortBy,
      sortDirection,
    } = this.props.currentFilters;
    const { collapsed, activeSelect, filters } = this.state;
    const { onApplyFilter } = this.props;
    const filtersUpdated = !_.isMatchWith(
      filters,
      {
        role,
        type,
        amount: { min, max },
        sortBy,
        sortDirection,
      },
      _.isEqual
    );

    const options = [
      { key: 'sort', label: 'Sort' },
      { key: 'type', label: 'Transaction Type' },
      { key: 'amount', label: 'Price Range' },
      { key: 'role', label: 'Role' },
    ];
    return (
      <Modal
        name="transactions-filter-modal"
        id="transactions-filter-modal"
        className="transactions-filter-modal"
        exitButton={false}
        targetElementId="spa"
        isOpen={!collapsed}
      >
        <div className="transactions-filter-modal-header">
          <div
            className="transactions-filter-modal-header-back"
            onClick={(event) => {
              if (this.state.activeSelect) {
                this.toggleActiveSelect('');
              } else {
                this.toggleFilter(event);
              }
            }}
            role="button"
            tabIndex={0}
          >
            {this.state.activeSelect ? (
              <Icon name="ui-chevron-right" className="transactions-icon transactions-icon--left" />
            ) : (
              <Icon name="legacy-close" className="transactions-icon" />
            )}
          </div>

          <div className="transactions-filter-modal-header-title">
            {
              (
                options.find((e) => e.key === this.state.activeSelect) || {
                  label: 'Filter',
                }
              ).label
            }
          </div>
          <div
            className="transactions-filter-modal-header-action"
            onClick={() => {
              this.clearFilters();
            }}
            role="button"
            tabIndex={0}
          >
            {!this.state.activeSelect && 'Clear'}
          </div>
        </div>
        <div className="transactions-filter-modal-container">
          <div
            className={classnames(
              'transactions-filter-modal-options',
              'transactions-filter-modal-options--main',
              {
                'is-active': !activeSelect,
              }
            )}
          >
            {options.map((section) => (
              <div
                className={classnames('transactions-filter-select-options-item')}
                key={section.key}
                onClick={() => this.toggleActiveSelect(section.key)}
                role="button"
                tabIndex={0}
              >
                <span>{section.label}</span>
                <span className="transactions-filter-select-options-item-preview">
                  {this.formatFilterLabel(this.state.filters)[section.key]}
                </span>
                <Icon
                  name="ui-chevron-right"
                  className="transactions-icon transactions-icon--right"
                  wrapper="span"
                />
              </div>
            ))}
          </div>
          <div
            className={classnames(
              'transactions-filter-modal-options',
              'transactions-filter-modal-options--section',
              {
                'is-active': activeSelect === 'role',
              }
            )}
          >
            {this.renderRoleFilter('mobile-')}
          </div>
          <div
            className={classnames(
              'transactions-filter-modal-options',
              'transactions-filter-modal-options--section',
              {
                'is-active': activeSelect === 'type',
              }
            )}
          >
            {this.renderTransactionTypesFilter('mobile-')}
          </div>
          <div
            className={classnames(
              'transactions-filter-modal-options',
              'transactions-filter-modal-options--section',
              {
                'is-active': activeSelect === 'amount',
              }
            )}
          >
            {this.renderPriceRangeFilter('mobile-')}
          </div>
          <div
            className={classnames(
              'transactions-filter-modal-options',
              'transactions-filter-modal-options--section',
              {
                'is-active': activeSelect === 'sort',
              }
            )}
          >
            {SORT_COLUMNS.map((column) => (
              <label
                className={classnames(
                  'transactions-filter-select-options-item',
                  'transactions-filter-select-options-item--radio',
                  'defaultRadio'
                )}
                key={column.key}
              >
                <input
                  className="defaultRadio-input"
                  name="sort"
                  value={column.key}
                  component="input"
                  type="radio"
                  checked={`${filters.sortBy}-${filters.sortDirection}` === column.key}
                  onChange={() => this.selectSort(column.key)}
                />
                <div className="defaultRadio-pseudo" />
                <span className="defaultRadio-label">
                  <span className="defaultRadio-title">{column.label}</span>
                </span>
              </label>
            ))}
          </div>
        </div>
        <div className="transactions-filter-modal-actions">
          <div
            role="button"
            tabIndex={0}
            className={classnames(
              [
                'btn',
                'btn--purple',
                'transactions-filter-modal-actions-btn',
                'transactions-filter-actions-btn',
              ],
              {
                'is-disabled': !filtersUpdated,
              }
            )}
            onClick={(event) => {
              event.preventDefault();
              if (filtersUpdated) {
                onApplyFilter(filters, true);
              }
              this.closeFilter(event, true);
            }}
          >
            Apply
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    const { collapsed, activeSelect, filters } = this.state;
    const { onApplyFilter } = this.props;
    const { currentStep, nextStep, close } = this.props.modalProps;
    const activeFilters = this.getActiveFiltersCount();
    const {
      role,
      type,
      amount: { min, max },
    } = this.props.currentFilters;
    const filtersApplied =
      filters.type.length || filters.role.length || filters.amount.min || filters.amount.max;
    const filtersUpdated = !_.isMatchWith(
      filters,
      {
        role,
        type,
        amount: { min, max },
      },
      _.isEqual
    );

    return (
      <div className="transactions-filter">
        <MediaQuery maxWidth={MediaQueryConstants.MAX_BREAKPOINT.TABLET}>
          {this.renderMobileVersion()}
        </MediaQuery>
        <div
          className="transactions-filter-btn"
          role="button"
          tabIndex={0}
          onClick={this.toggleFilter}
          data-tracking-section="transactions-filter"
          data-tracking-action="click"
          data-tracking-label="open-filter"
        >
          {activeFilters > 0 && <div className="transactions-filter-badge">{activeFilters}</div>}
          <Icon name="filter" className="icon transactions-icon" wrapper="span" />
          <span className="transactions-filter-text media--hidden@mobile">Filter</span>
        </div>
        <TourStep
          visible={currentStep === 4}
          onNext={nextStep}
          onClose={close}
          title="filter"
          step={4}
          horizontalPosition="middle"
        >
          In order to help you narrow down on your transactions, we have added a way to do
          filtering. You may choose to either filter by <span>Transaction Type, Price Range,</span>{' '}
          or your <span>Role</span> in the transaction.
        </TourStep>
        <div
          className={classnames('transactions-filter-dropdown', 'media--hidden@mobile', {
            'is-collapsed': collapsed,
          })}
          ref={(element) => {
            this.desktopMenu = element;
          }}
        >
          <FilterType
            label="Transaction Type"
            active={activeSelect === 'type'}
            setActive={() => this.toggleActiveSelect('type')}
            placeholder={this.formatFilterLabel(filters, null).type}
          >
            {this.renderTransactionTypesFilter('desktop-')}
          </FilterType>
          <FilterType
            label="Price Range"
            active={activeSelect === 'amount'}
            setActive={() => this.toggleActiveSelect('amount')}
            placeholder={this.formatFilterLabel(filters, null).amount}
          >
            {this.renderPriceRangeFilter('desktop-')}
          </FilterType>
          <FilterType
            label="Role"
            active={activeSelect === 'role'}
            setActive={() => this.toggleActiveSelect('role')}
            placeholder={this.formatFilterLabel(filters, null).role}
          >
            {this.renderRoleFilter('desktop-')}
          </FilterType>
          <div className="transactions-filter-actions">
            <a
              href="#"
              role="button"
              tabIndex={0}
              className={classnames('transactions-filter-actions-link', {
                'is-disabled': !filtersApplied,
              })}
              onClick={() => {
                filtersApplied && this.clearFilters();
              }}
            >
              Clear
            </a>
            <div
              role="button"
              tabIndex={0}
              className={classnames(['btn', 'btn--purple', 'transactions-filter-actions-btn'], {
                'is-disabled': !filtersUpdated,
              })}
              onClick={(event) => {
                event.preventDefault();
                if (filtersUpdated) {
                  onApplyFilter(filters, true);
                  this.closeFilter(event, true);
                }
              }}
              data-tracking-section="transactions-filter"
              data-tracking-action="click"
              data-tracking-label="apply-filter"
            >
              Apply
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TransactionFilters;
