import DisbursementConstants from 'spa/constants/DisbursementConstants';
import { getUserFlow as getUserFlowRoutine } from 'spa/actions/DisbursementActions';

const initialState = {
  disbursement: [],
};

function disbursementReducer(state = initialState, action) {
  switch (action.type) {
    case DisbursementConstants.GET_DISBURSEMENT_DETAILS_REQUEST:
      return Object.assign({}, state, {
        display: {
          loading: true,
        },
      });
    case DisbursementConstants.GET_DISBURSEMENT_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        display: {
          loading: false,
          success: true,
        },
        disbursement: action.disbursementDetails,
      });
    case DisbursementConstants.GET_DISBURSEMENT_DETAILS_FAILURE:
      return Object.assign({}, state, {
        display: {
          loading: false,
          success: false,
        },
      });
    case getUserFlowRoutine.SUCCESS:
      return {
        ...state,
        userFlow: {
          type: action.payload.userFlow,
          transId: action.payload.transId,
          disbursementId: action.payload.disbursementId,
        },
      };
    default:
      return state;
  }
}

export default disbursementReducer;
