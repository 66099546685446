import React from 'react';

const greeting = () => {
  const date = new Date();
  const time = date.getHours();

  if (time < 12) return 'Good morning';
  if (time >= 12 && time < 17) return 'Good afternoon';
  return 'Good evening';
};

const WelcomeWidget = (props) => (
  <div className="partnerDashboard-card partnerDashboard-greeting" data-e2e-target="greetings-card">
    <h3 className="partnerDashboard-greeting-title">
      {greeting()} {props.name && props.name}
    </h3>
    <p className="partnerDashboard-greeting-desc">
      Welcome to your Escrow dashboard where you can have an overview of your transactions and
      activities.
    </p>
  </div>
);

export default WelcomeWidget;
