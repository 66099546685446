import React, { Fragment } from 'react';
import { useWindowDimensions } from 'spa/hooks';
import TransactionConstants from 'spa/constants/TransactionConstants';
import { Heading } from 'spa/components/CustomerVerification/V2';
import { connect } from 'react-redux';
import { kycGotoNextPage as kycGotoNextPageRoutine } from 'spa/actions/VerificationActions';
import { KYCPages } from 'spa/constants/VerificationConstants';
import moment from 'moment';
import {
  customerRequireExtendedKycSelector,
  customerKyc2ApprovedSelector,
  customerKyc2InProgressSelector,
  customerKyc2IsLocallySubmittedSelector,
  customerKyc2SubmittedSelector,
  customerKyc3ApprovedSelector,
  customerKyc3InProgressSelector,
  customerKyc3IsLocallySubmittedSelector,
  customerKyc3SubmittedSelector,
  customerCompanyNameSelector,
  customerDateOfBirthSelector,
  customerAddressLine1Selector,
  customerAddressLine2Selector,
  customerAddressCitySelector,
  customerAddressStateSelector,
  customerAddressCountryNameSelector,
  customerCompanyAddressLine1Selector,
  customerCompanyAddressLine2Selector,
  customerCompanyAddressCitySelector,
  customerCompanyAddressStateSelector,
  customerCompanyAddressCountryNameSelector,
  customerIsCompanySelector,
  custFullNameSelector,
  customerSubmittedExtendedKyc,
  customerExtendedKycVerified,
  customerExtendedKycRejected,
} from '../../../selectors/CustomerSelectors';
import VerifyButton from './VerifyButton';
import SubmitButton from '../../StartTransaction/SubmitButton';

const CustomerDetails = ({
  requireExtendedKyc,
  submittedExtendedKyc,
  verifiedExtendedKyc,
  rejectedExtendedKyc,
  kycApproved,
  kycInProgress,
  kycLocallySubmitted,
  kycSubmitted,
  kycT2Submitted,
  kycT2InProgress,
  kycT2Approved,
  fullName,
  companyName,
  dateOfBirth,
  addressLine1,
  addressLine2,
  addressCity,
  addressState,
  addressCountryName,
  loadNextPage,
  company = false,
}) => {
  const { width } = useWindowDimensions();
  const fullWidth = width < TransactionConstants.DESKTOP_VIEW_WIDTH;
  const classNameIfFullWidth = fullWidth ? '--fullWidth' : '';

  const getVerificationStatus = (
    isApproved,
    isInProgress,
    isLocallySubmitted,
    isSubmitted,
    extendedKyc,
    isSubmittedExtendedKyc,
    isVerifiedExtendedKyc,
    isRejectedExtendedKyc
  ) => {
    if (isLocallySubmitted) {
      return {
        text: 'Under Review',
        type: 'waiting',
      };
    }
    if (!isApproved && !isInProgress && isSubmitted) {
      return {
        text: 'Rejected',
        type: 'error',
      };
    }
    if (extendedKyc) {
      if (isVerifiedExtendedKyc) {
        return {
          text: 'Verified',
          type: 'success',
        };
      }
      if (isRejectedExtendedKyc) {
        return {
          text: 'Rejected',
          type: 'error',
        };
      }
      if (isSubmittedExtendedKyc) {
        return {
          text: 'Under Review',
          type: 'waiting',
        };
      }
      return {
        text: fullWidth ? 'Document Required' : 'Additional Document Required',
        type: 'error',
      };
    }
    if (isApproved) {
      return {
        text: 'Verified',
        type: 'success',
      };
    }
    if (isSubmitted) {
      return {
        text: 'Under Review',
        type: 'waiting',
      };
    }

    return {
      text: '',
      type: 'default',
    };
  };

  const verificationStatus = getVerificationStatus(
    kycApproved,
    kycInProgress,
    kycLocallySubmitted,
    kycSubmitted,
    company ? false : requireExtendedKyc,
    submittedExtendedKyc,
    verifiedExtendedKyc,
    rejectedExtendedKyc
  );

  const showVerifyButton = (status) => {
    if (!company) {
      return !['Verified', 'Under Review'].includes(status.text);
    }
    // Don't show if company is already verified, kyc in review or pending review
    if (['Verified', 'Under Review'].includes(verificationStatus.text)) {
      return false;
    } else if (!kycT2Approved && !kycT2InProgress && kycT2Submitted) {
      // if the T2 kyc is rejected then also don't show for company
      return false;
    } else if (kycT2Submitted) {
      // show button if T2 KYC has been submitted
      return true;
    }
    return false;
  };

  return (
    <Fragment>
      <Heading
        text={company ? 'Company Details' : 'Personal Details'}
        statusText={verificationStatus.text}
        statusType={verificationStatus.type}
        hasDivider
      />
      <div className={`kyc-details${classNameIfFullWidth}`}>
        <div className={`kyc-details-text${classNameIfFullWidth}`}>
          {
            <Fragment>
              {company ? (
                companyName
              ) : (
                <Fragment>
                  {fullName}
                  <br />
                  {moment(dateOfBirth).format('D / MMM / YYYY')}
                </Fragment>
              )}
              <br />
              {addressLine1} {addressLine2} {addressCity} {addressState} {addressCountryName}
            </Fragment>
          }
        </div>
        <div className={`kyc-details-buttons${classNameIfFullWidth}`}>
          <div className={`kyc-details-buttons-button${classNameIfFullWidth}`}>
            <SubmitButton
              fullWidth={fullWidth}
              onClick={() =>
                loadNextPage({
                  nextPage: company
                    ? KYCPages.COMPANY_DETAILS_FORM
                    : KYCPages.INDIVIDUAL_DETAILS_FORM,
                })
              }
              muiVariant="outlined"
            >
              Edit
            </SubmitButton>
          </div>
          {showVerifyButton(verificationStatus) ? (
            <div className={`kyc-details-buttons-button${classNameIfFullWidth}`}>
              <VerifyButton
                fullWidth={fullWidth}
                verificationStatusText={verificationStatus.text}
                company={company}
                loadNextPage={loadNextPage}
                extendedKyc={requireExtendedKyc}
              />
            </div>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state, { company }) => ({
  requireExtendedKyc: customerRequireExtendedKycSelector(state),
  submittedExtendedKyc:
    customerSubmittedExtendedKyc(state) || customerKyc2IsLocallySubmittedSelector(state),
  verifiedExtendedKyc: customerExtendedKycVerified(state),
  rejectedExtendedKyc: customerExtendedKycRejected(state),
  kycApproved: company ? customerKyc3ApprovedSelector(state) : customerKyc2ApprovedSelector(state),
  kycInProgress: company
    ? customerKyc3InProgressSelector(state)
    : customerKyc2InProgressSelector(state),
  kycLocallySubmitted: company
    ? customerKyc3IsLocallySubmittedSelector(state)
    : customerKyc2IsLocallySubmittedSelector(state),
  kycSubmitted: company
    ? customerKyc3SubmittedSelector(state)
    : customerKyc2SubmittedSelector(state),
  kycT2Submitted: customerKyc2SubmittedSelector(state),
  kycT2InProgress: customerKyc2InProgressSelector(state),
  kycT2Approved: customerKyc2ApprovedSelector(state),
  fullName: custFullNameSelector(state),
  companyName: customerCompanyNameSelector(state),
  dateOfBirth: customerDateOfBirthSelector(state),
  addressLine1: company
    ? customerCompanyAddressLine1Selector(state)
    : customerAddressLine1Selector(state),
  addressLine2: company
    ? customerCompanyAddressLine2Selector(state)
    : customerAddressLine2Selector(state),
  addressCity: company
    ? customerCompanyAddressCitySelector(state)
    : customerAddressCitySelector(state),
  addressState: company
    ? customerCompanyAddressStateSelector(state)
    : customerAddressStateSelector(state),
  addressCountryName: company
    ? customerCompanyAddressCountryNameSelector(state)
    : customerAddressCountryNameSelector(state),
  isCompany: customerIsCompanySelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadNextPage: (nextPage) => dispatch(kycGotoNextPageRoutine.trigger(nextPage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetails);
