import _ from 'lodash';

import { call, put, takeLatest } from 'redux-saga/effects';
import { takeEvery, select } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import { SET_SUBMIT_SUCCEEDED, SET_SUBMIT_FAILED } from 'redux-form/es/actionTypes';
import { getFormSyncErrors, getFormSubmitErrors } from 'redux-form';
import APIConstants from 'spa/constants/APIConstants';
import { returnToLogin as returnToLoginRoutine } from 'spa/actions/CheckoutActions';
import { logError } from 'spa/actions/ErrorLoggingActions';
import {
  getAbTest as getAbTestRoutine,
  trackFormSubmissionByName,
  trackFormSubmissionFailByName,
} from 'spa/actions/TrackingActions';
import TrackingStore from '../../stores/TrackingStore';
import TrackingConstants from '../../constants/TrackingConstants';
import { findAttribute } from '../../components/Tracking';
import API from '../../api';

const getFormErrors = (formName) => (state) => getFormSyncErrors(formName)(state);
const getFormAPIErrors = (formName) => (state) => getFormSubmitErrors(formName)(state);

function pushLocalEvent(trackingEvent) {
  TrackingStore.trackEvent(trackingEvent);
  setTimeout(TrackingStore.flush, 500);
}

function getSurroundingTrackingData(formName) {
  const formElem = document.querySelector(`form[name="${formName}"]`);
  let formSection = '';
  let formSubSection = '';
  let formLabel = '';
  if (formElem) {
    formSection = findAttribute(formElem, 'data-tracking-section') || '';
    formSubSection = findAttribute(formElem, 'data-tracking-subsection') || '';
    formLabel = findAttribute(formElem, 'data-tracking-label') || '';
  }
  return {
    section: formSection,
    subsection: formSubSection,
    label: formLabel,
  };
}

export function sendPageView() {
  const pageView = {
    event: 'escrow_page_view',
  };
  pushLocalEvent(pageView);
}

export function sendFormSubmitSuccess(action) {
  const formSubmit = {
    event: 'escrow_user_action',
    action: 'form-submission',
    value: 'success',
    name: action.meta.form,
    ...getSurroundingTrackingData(action.meta.form),
  };

  pushLocalEvent(formSubmit);
}

export function* sendFormSubmitSuccessByFormName(action) {
  const formSubmit = {
    event: 'escrow_user_action',
    action: 'form-submission',
    value: 'success',
    name: action.payload.form,
    ...getSurroundingTrackingData(action.payload.form),
  };
  pushLocalEvent(formSubmit);
  yield put(trackFormSubmissionByName.success());
}

export function* sendFormSubmitFailByFormName(action) {
  const surroundingTrackingData = getSurroundingTrackingData(action.payload.form);
  const formSubmit = {
    event: 'escrow_user_action',
    action: 'form-submission',
    value: 'validation-error',
    name: action.payload.form,
    ...surroundingTrackingData,
    label: action.payload.error,
  };
  pushLocalEvent(formSubmit);
  yield put(trackFormSubmissionFailByName.success());
}

export function* getAbTest(action) {
  try {
    const testData = yield call(API.getAbTest, action.payload);
    yield put(
      getAbTestRoutine.success({
        [testData.ab_test_name]: testData.enrollment_type === 'variation',
      })
    );
  } catch (error) {
    let errorMsg = '';
    if (error.type === APIConstants.UNAUTHORIZED) {
      yield put(returnToLoginRoutine());
    } else if (error.type === APIConstants.BAD_REQUEST) {
      return;
    } else {
      yield put(logError(error));
      errorMsg = APIConstants.GENERIC_API_ERROR_MESSAGE;
    }
    yield put(getAbTestRoutine.failure({ errorMessage: errorMsg }));
  }
}

export function* sendFormSubmitFail(action) {
  const surroundingTrackingData = getSurroundingTrackingData(action.meta.form);
  const formSubmit = {
    event: 'escrow_user_action',
    action: 'form-submission',
    value: 'validation-error',
    name: action.meta.form,
    ...surroundingTrackingData,
  };

  pushLocalEvent(formSubmit);

  const errors = yield select(getFormErrors(action.meta.form));
  const apiErrors = yield select(getFormAPIErrors(action.meta.form));
  let errorFieldsList = [];

  if (errors) {
    errorFieldsList = _.concat(errorFieldsList, Object.keys(errors));
  }
  if (apiErrors) {
    errorFieldsList = _.concat(errorFieldsList, Object.keys(apiErrors));
  }

  for (const fieldName of errorFieldsList) {
    const fieldErrorEvent = {
      event: 'escrow_user_action',
      action: 'form-submission',
      value: 'validation-error',
      name: action.meta.form,
      ...surroundingTrackingData,
      label: fieldName,
    };
    pushLocalEvent(fieldErrorEvent);
  }
}

export function sendTrackingEvent(action) {
  let rawEvent = {};

  if (action && action.payload) {
    rawEvent = action.payload;
  }

  if (rawEvent.event) {
    pushLocalEvent(rawEvent);
  }
}

export function* trackingWatcher() {
  yield takeEvery(LOCATION_CHANGE, sendPageView);
  yield takeEvery(SET_SUBMIT_SUCCEEDED, sendFormSubmitSuccess);
  yield takeEvery(trackFormSubmissionByName.TRIGGER, sendFormSubmitSuccessByFormName);
  yield takeEvery(trackFormSubmissionFailByName.TRIGGER, sendFormSubmitFailByFormName);
  yield takeEvery(SET_SUBMIT_FAILED, sendFormSubmitFail);
  yield takeEvery(TrackingConstants.TRACK_ESCROW_USER_ACTION, sendTrackingEvent);
  yield takeLatest(getAbTestRoutine.TRIGGER, getAbTest);
}

export default [trackingWatcher];
