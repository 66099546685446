export function updateIcon(element) {
  const value = element.value;
  const name = element.getAttribute('name');

  const iconElements = document.querySelectorAll(`[data-select-icon="${name}"]`);
  for (const e of iconElements) {
    e.setAttribute('data-select-value', value);
  }
}

function onChange(event) {
  if (event.target.tagName === 'SELECT') {
    updateIcon(event.target);
  }
}

for (const e of document.querySelectorAll('html')) {
  updateIcon(e);
  e.addEventListener('change', onChange);
}

export const createSelectOptions = (obj) => {
  const result = [];
  for (const key of Object.keys(obj)) {
    result.push({ value: obj[key], label: key });
  }
  return result;
};
