import velocity from 'velocity-animate';

if (window.location.hash) {
  setTimeout(() => {
    const element = document.querySelector(window.location.hash);
    if (element) {
      velocity(element, 'scroll', { duration: 0, offset: -90 });
    }
  });
}
