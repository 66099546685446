import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api' }),
  endpoints: (builder) => ({
    isAuthed: builder.query({
      query: () => ({
        url: '/auth/verify',
      }),
    }),
  }),
});

export const { useIsAuthedQuery } = authApi;
