import React from 'react';
import { Divider } from '@mui/material';

const Heading = ({ text, secondaryText, statusText, statusType = 'default', hasDivider }) => (
  <div className="kyc-heading">
    <div className="kyc-heading-content">
      <div className="kyc-heading-section">
        <div className="kyc-heading-section--inner">
          <span className="kyc-heading-section-text">{text}</span>
          {secondaryText && <span>{secondaryText}</span>}
        </div>
      </div>
      <div className="kyc-heading-status">
        {statusText ? (
          <span className="kyc-heading-status-text">
            <div className={`kyc-heading-status-text-${statusType}`}> {statusText} </div>
          </span>
        ) : null}
      </div>
    </div>
    {hasDivider && <Divider variant="fullWidth" />}
  </div>
);

export default Heading;
