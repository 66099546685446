import React from 'react';
import PropTypes from 'prop-types';

const DashboardCardHeader = ({ title, description, children }) => (
  <header className="dashboardCard-header">
    <h3 className="dashboardCard-title">{title}</h3>
    <div className="dashboardCard-header-action">{children}</div>
    {description && <p className="dashboardCard-header-desc">{description}</p>}
  </header>
);

DashboardCardHeader.PropTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default DashboardCardHeader;
