import QueryString from 'query-string';
import window from 'window-shim';

import buttonCSSPrimary from '../../css/buyItNowButtons/EscrowButtonPrimary.inline.css';
import buttonCSSSecondary from '../../css/buyItNowButtons/EscrowButtonSecondary.inline.css';

export const stripHTMLWhitespace = (html) => html.replace(/>\s*</g, '><');

export const createButtonSnippet = (
  buttonBehavior,
  buttonData,
  buttonStyle,
  buttonText,
  buttonValid,
  buttonType,
  itemKey
) => {
  const hiddenInputs = [];
  for (const key of Object.keys(buttonData)) {
    if (key === 'item_key' && !buttonData[key]) {
      hiddenInputs.push(`<input type="hidden" name="${key}" value="${itemKey}">`);
    } else {
      hiddenInputs.push(`<input type="hidden" name="${key}" value="${buttonData[key]}">`);
    }
  }

  let pageURL = `${window.config.www_base_url}/checkout`;
  if (buttonType === 'offer') {
    pageURL = `${window.config.www_base_url}/offer`;
  }

  const trackingArguments = {
    name: 'bin',
    user_id: buttonData.non_initiator_id,
    title: buttonData.title,
    price: buttonData.price,
  };

  const trackingURL = `${window.config.escrow_qts_gif_url}?${QueryString.stringify(
    trackingArguments
  )}`;

  let buttonClassName = '';
  let escrowStyle = '';
  let target = '';

  if (buttonStyle === 'escrow' || buttonStyle === 'green') {
    buttonClassName = 'EscrowButtonPrimary';
    escrowStyle = `<style>${buttonCSSPrimary.toString()}</style>`;
  } else if (buttonStyle === 'light') {
    buttonClassName = 'EscrowButtonSecondary';
    escrowStyle = `<style>${buttonCSSSecondary.toString()}</style>`;
  }
  if (buttonBehavior === 'new_tab') {
    target = 'target="_blank"';
  }

  const buttonSnippetCode = `
    <form action="${pageURL}" method="post" ${target}>
      ${hiddenInputs.join(' ')}
      ${escrowStyle}
      <button class="${buttonClassName}" type="submit">${buttonText}</button>
      <img src="${trackingURL}" style="display: none;">
    </form>`.trim();

  const disabled = buttonValid ? '' : 'disabled';

  const buttonPreviewCode = `
    <form name="escrow-buy-it-now" action="${pageURL}?preview=1" method="post" ${target}>
      ${hiddenInputs.join(' ')}
      ${escrowStyle}
      <button class="${buttonClassName}" type="submit" ${disabled}>${buttonText}</button>
    </form>`.trim();

  return {
    buttonSnippetCode: stripHTMLWhitespace(buttonSnippetCode),
    buttonPreviewCode: stripHTMLWhitespace(buttonPreviewCode),
  };
};
