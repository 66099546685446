import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import {
  getTransactionStatistics as getTransactionStatisticsRoutine,
  pushTransactionCountByInitiationDate,
  pushTransactionCountByCloseDate,
  pushOngoingTransactionCount,
} from 'spa/actions/PartnerActions';
import PartnerConstants from 'spa/constants/PartnerConstants';
import INTERVAL from 'spa/constants/IntervalConstants';
import {
  transactionsCountStatisticsTimeDataSelector,
  transactionsCountStatisticsAggregatesSelector,
  completedTransactionsCountStatisticsTimeDataSelector,
  completedTransactionsCountStatisticsAggregatesSelector,
  ongoingTransactionsCountStatisticsTimeDataSelector,
  ongoingTransactionsCountStatisticsAggregatesSelector,
} from 'spa/selectors/PartnerSelectors';
import A from 'spa/components/A';
import {
  getDateRangeDataSet,
  BasicDoughnutChart,
  BasicBarChart,
  getChartLabels,
  getChartValues,
  getDatasetOptions,
} from 'spa/components/Charts/BasicCharts';
import withLoadingSpinner from 'spa/components/LoadingSpinner';
import { Filter, FilterType } from 'spa/components/Filter';
import { DashboardSummaryDetails, DashboardSummaryHeader } from '../SharedComponents';
import { getIntervalByDisplayRange, getDisplayRange } from '../../../../utils/PartnerDashboard';
import { urlFor } from '../../../../routeConfig';
import { gettext } from '../../../../utils/filters';

const { TRANSACTION_STATISTICS_DATA_TYPE, DEFAULT_START_DATE } = PartnerConstants;

const TransactionByStatusChart = withLoadingSpinner(
  ({
    totalCreatedTransactionsCount,
    totalCompletedTransactionsCount,
    totalOngoingTransactionsCount,
  }) => {
    const completedTransactionsText = 'Completed transactions';
    const ongoingTransactionsText = 'Ongoing transactions';
    const cancelledTransactionsText = 'Cancelled transactions';

    const chartData = {
      labels: [completedTransactionsText, ongoingTransactionsText, cancelledTransactionsText],
      datasets: [
        {
          data: [
            totalCompletedTransactionsCount,
            totalOngoingTransactionsCount,
            totalCreatedTransactionsCount -
              totalCompletedTransactionsCount -
              totalOngoingTransactionsCount,
          ],
          backgroundColor: ['#002B49', '#3CB95D', '#A4B0B1'],
        },
      ],
    };

    return (
      <div className="grid">
        <div className="grid-col grid-col--6">
          <div className="partnerDashboard-summary-sectionTitle">
            {gettext('Total Transactions Created')}
          </div>
          <DashboardSummaryDetails value={totalCreatedTransactionsCount} />
          <br />
          <div className="partnerDashboard-statusContainer">
            <div className="partnerDashboard-chartIndicator partnerDashboard-chartIndicator--secondary" />
            <DashboardSummaryDetails
              value={totalCompletedTransactionsCount}
              total={totalCreatedTransactionsCount}
              label={gettext(completedTransactionsText)}
            />
          </div>
          <div className="partnerDashboard-statusContainer">
            <div className="partnerDashboard-chartIndicator" />
            <DashboardSummaryDetails
              value={totalOngoingTransactionsCount}
              total={totalCreatedTransactionsCount}
              label={gettext(ongoingTransactionsText)}
            />
          </div>
          <div className="partnerDashboard-statusContainer">
            <div className="partnerDashboard-chartIndicator partnerDashboard-chartIndicator--cancelled" />
            <DashboardSummaryDetails
              value={
                totalCreatedTransactionsCount -
                totalCompletedTransactionsCount -
                totalOngoingTransactionsCount
              }
              total={totalCreatedTransactionsCount}
              label={gettext(cancelledTransactionsText)}
            />
          </div>
        </div>
        <div className="grid-col grid-col--6">
          <div className="partnerDashboard-chartContainer partnerDashboard-transactionChartContainer">
            <BasicDoughnutChart chartData={chartData} />
          </div>
        </div>
      </div>
    );
  }
);

const CreatedTransactionCountChart = withLoadingSpinner(
  ({ transactionCount, interval, startDate, endDate, labelFormat, chartLabel }) => {
    const data = getDateRangeDataSet(transactionCount, interval, startDate, endDate, labelFormat);
    const chartData = {
      labels: getChartLabels(data),
      datasets: [
        {
          ...getDatasetOptions({
            backgroundColor: 'rgba(33,127,45,0.5)',
            borderColor: 'rgba(33,127,45,1)',
            borderWidth: 2,
          }),
          data: getChartValues(data),
        },
      ],
    };

    return (
      <div className="partnerDashboard-chartContainer">
        <BasicBarChart chartData={chartData} />
        <div className="partnerDashboard-chartContainer-label">{chartLabel}</div>
      </div>
    );
  }
);

const TransactionsOverview = ({
  transactionCount,
  completedTransactionCount,
  ongoingTransactionCount,
  transactionsCountAggregates,
  completedTransactionsCountAggregates,
  ongoingTransactionsCountAggregates,
  interval,
  dateStart,
  dateEnd,
  getTransactionStatistics,
}) => {
  const [dateRangeFilter, setDateFilter] = useState(INTERVAL.RANGE.THIS_YEAR.value);
  const [displayInterval, setDisplayInterval] = useState(interval);
  const [displayStartDate, displayEndDate] = getDisplayRange(dateRangeFilter);

  useEffect(() => {
    getTransactionStatistics({
      dataType: TRANSACTION_STATISTICS_DATA_TYPE.INITIATION_DATE,
      dataLoaderAction: pushTransactionCountByInitiationDate,
      interval,
      dateStart,
      dateEnd,
    });
    getTransactionStatistics({
      dataType: TRANSACTION_STATISTICS_DATA_TYPE.CLOSE_DATE,
      dataLoaderAction: pushTransactionCountByCloseDate,
      interval,
      dateStart,
      dateEnd,
    });
    getTransactionStatistics({
      dataType: TRANSACTION_STATISTICS_DATA_TYPE.INITIATION_DATE,
      dataLoaderAction: pushOngoingTransactionCount,
      filters: { status: 'ongoing' },
      interval,
      dateStart,
      dateEnd,
    });
  }, [interval, dateStart, dateEnd, getTransactionStatistics]);

  useEffect(() => {
    if (transactionCount && !transactionCount[displayInterval]) {
      getTransactionStatistics({
        dataType: TRANSACTION_STATISTICS_DATA_TYPE.INITIATION_DATE,
        interval: displayInterval,
        dateStart: dateStart || DEFAULT_START_DATE,
        dateEnd: dateEnd || moment().format('YYYY-MM-DD'),
        dataLoaderAction: pushTransactionCountByInitiationDate,
      });
    }
  }, [
    dateRangeFilter,
    dateEnd,
    dateStart,
    displayInterval,
    getTransactionStatistics,
    transactionCount,
  ]);

  return (
    <Fragment>
      <div className="grid-col grid-col--tablet-6 integrationsPortal-card">
        <div
          className="partnerDashboard-card partnerDashboard-summary-content"
          data-e2e-target="transactions-overview-card"
        >
          <DashboardSummaryHeader title={gettext('Transactions Overview')}>
            <div className="partnerDashboard-summary-action">
              <Filter
                name="dateRangeFilter"
                type={FilterType.SELECT}
                options={Object.values(INTERVAL.RANGE)}
                handleChange={(e) => {
                  setDateFilter(e.target.value);
                  setDisplayInterval(getIntervalByDisplayRange(e.target.value));
                }}
                color="light"
              />
            </div>
          </DashboardSummaryHeader>
          <div className="partnerDashboard-summary-content">
            <CreatedTransactionCountChart
              isLoading={!(transactionCount && transactionCount[displayInterval])}
              transactionCount={transactionCount && transactionCount[displayInterval]}
              interval={displayInterval}
              startDate={displayStartDate}
              endDate={displayEndDate}
              labelFormat={INTERVAL.RANGE[dateRangeFilter].format}
              chartLabel={gettext(
                `Total transactions per ${INTERVAL[
                  displayInterval.toUpperCase()
                ].text.toLowerCase()} ${
                  [INTERVAL.RANGE.THIS_YEAR.value, INTERVAL.RANGE.LAST_YEAR.value].includes(
                    dateRangeFilter
                  )
                    ? INTERVAL.RANGE[dateRangeFilter].label.toLowerCase()
                    : ''
                }`
              )}
            />
          </div>
        </div>
      </div>
      <div className="grid-col grid-col--tablet-6 integrationsPortal-card">
        <div
          className="partnerDashboard-card partnerDashboard-summary-content"
          data-e2e-target="total-transactions-card"
        >
          <DashboardSummaryHeader>
            <A
              className="partnerDashboard-summary-action"
              link={{ type: 'app', route: urlFor('partner_dashboard_transactions') }}
              role="button"
              tabIndex={0}
            >
              {gettext('View all transactions')} →
            </A>
          </DashboardSummaryHeader>
          <div className="partnerDashboard-summary-content">
            <TransactionByStatusChart
              isLoading={
                !(transactionCount && transactionCount[interval]) ||
                !completedTransactionCount ||
                !ongoingTransactionCount
              }
              totalCreatedTransactionsCount={
                transactionsCountAggregates && transactionsCountAggregates.total
              }
              totalCompletedTransactionsCount={
                completedTransactionsCountAggregates && completedTransactionsCountAggregates.total
              }
              totalOngoingTransactionsCount={
                ongoingTransactionsCountAggregates && ongoingTransactionsCountAggregates.total
              }
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  transactionCount: transactionsCountStatisticsTimeDataSelector(state),
  transactionsCountAggregates: transactionsCountStatisticsAggregatesSelector(state),
  completedTransactionCount: completedTransactionsCountStatisticsTimeDataSelector(state),
  completedTransactionsCountAggregates:
    completedTransactionsCountStatisticsAggregatesSelector(state),
  ongoingTransactionCount: ongoingTransactionsCountStatisticsTimeDataSelector(state),
  ongoingTransactionsCountAggregates: ongoingTransactionsCountStatisticsAggregatesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getTransactionStatistics: ({
    dataType,
    interval,
    dataLoaderAction,
    dateStart = DEFAULT_START_DATE,
    dateEnd = moment().format('YYYY-MM-DD'),
    filters = {},
  }) =>
    dispatch(
      getTransactionStatisticsRoutine.trigger({
        dataType,
        interval,
        dateStart,
        dateEnd,
        dataLoaderAction,
        filters,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsOverview);
