import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { kycCurrentPageSelector, saveCurrentPage } from 'spa/features/kyc/kycSlice';
import { useGetCustomerDetailsQuery } from 'spa/features/kyc/kycApi';
import { V3KYCDesktopPages as Pages } from 'spa/constants/VerificationConstants';
import { EscrowPaper } from 'spa/features/ui/EscrowPaper';
import { LargeText } from 'spa/features/ui/EscrowTypography';
import V2KYCContainer from 'spa/containers/KYC/V2';
import MyTransactionsHeader from 'spa/components/HeaderV3/MyTransactionsHeader';
import SelectionPage from './pages/FlowSelectionPage';
import MobileTransferPage from './pages/MobileTransferPage';
import PhoneNumberPage from './pages/PhoneNumberPage';
import SMSCodePage from './pages/SMSCodePage';

const DesktopPage = ({ isMobile }) => {
  const currentPage = useSelector(kycCurrentPageSelector);

  const pages = {};
  pages[Pages.SELECTION] = <SelectionPage />;
  pages[Pages.MOBILE_TRANSFER] = <MobileTransferPage />;
  pages[Pages.PHONE_NUMBER] = <PhoneNumberPage />;
  pages[Pages.SMS_CODE] = <SMSCodePage />;
  pages[Pages.V2_KYC] = <V2KYCContainer isMobile={isMobile} />;

  if (currentPage in pages) {
    if (currentPage === Pages.V2_KYC) {
      return <Fragment>{pages[currentPage]}</Fragment>;
    }

    return (
      <Box
        variant="outlined"
        sx={{
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        <EscrowPaper>{pages[currentPage]}</EscrowPaper>
      </Box>
    );
  }

  return <LargeText> Page does not exist </LargeText>;
};

const DesktopKYC = ({ customerId, isMobile }) => {
  const {
    data: customerData,
    // eslint-disable-next-line no-unused-vars
    isLoading: customerIsLoading,
    // eslint-disable-next-line no-unused-vars
    isFetching: customerIsFetching,
    // eslint-disable-next-line no-unused-vars
    error: customerError,
  } = useGetCustomerDetailsQuery(customerId);

  // eslint-disable-next-line no-console
  console.log('customerData:');
  // eslint-disable-next-line no-console
  console.log(customerData);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(saveCurrentPage({ page: Pages.SELECTION }));
  }, [dispatch]);

  return (
    <Fragment>
      {!customerData ? (
        <div> Loading... </div>
      ) : (
        <Fragment>
          <MyTransactionsHeader
            title={'Verify your identity'}
            toggleSidebar={() => {
              isMobile;
            }}
          />
          <DesktopPage isMobile={isMobile} />
        </Fragment>
      )}
    </Fragment>
  );
};

export default DesktopKYC;
