import React, { Fragment } from 'react';
import { Card, CardContent } from '@mui/material';
import { formatUTCtoSentence } from '../../../utils/date';

const TransactionDetails = ({ data, loading, error }) => (
  <Fragment>
    {!loading && !error && data && (
      <div className="transactionProgress-container section-container">
        <Card className="transactionProgress-card">
          <CardContent className="transactionProgress-card-wrapper">
            <h3 className="transactionProgress-card-title">{data.transaction_details.title}</h3>
            <p className="transactionProgress-card-content">
              {'Created on: '}
              {formatUTCtoSentence(data.transaction_details.initiation_date)}
            </p>
          </CardContent>
        </Card>
      </div>
    )}
  </Fragment>
);

export default TransactionDetails;
