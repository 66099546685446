import React from 'react';
import { TextField } from '@mui/material';

const Input = ({ shrinkLabel, children, inputProps, ...props }) => (
  <TextField
    onClick={(e) => e.stopPropagation()}
    fullWidth
    margin="normal"
    InputProps={inputProps}
    InputLabelProps={{
      shrink: shrinkLabel,
    }}
    {...props}
  >
    {children}
  </TextField>
);

export default Input;
