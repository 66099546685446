import React, { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { kycImageSelector, kycSelectedIDSelector } from 'spa/features/kyc/kycSlice';
import {
  KYC_PAGE_STATES,
  KYC_FILES,
  KYC_UPLOADING_TEXT,
  horizontalDocTypes,
} from 'spa/constants/VerificationConstants';
import { SmallText } from 'spa/features/ui/EscrowTypography';

const { LOADING } = KYC_PAGE_STATES;

const getUploadingText = (fileKey, selectedIdType) => {
  if (
    (fileKey === KYC_FILES.ID_FRONT || fileKey === KYC_FILES.ID_BACK) &&
    selectedIdType === 'passport'
  ) {
    return KYC_UPLOADING_TEXT.passport;
  } else if (KYC_UPLOADING_TEXT[fileKey]) {
    return KYC_UPLOADING_TEXT[fileKey];
  }
  // this is a backup case, should never happen
  return KYC_UPLOADING_TEXT.default;
};

const PhotoReview = ({ fileKey, parentPageState }) => {
  const kycImageDataUrl = useSelector(kycImageSelector(fileKey));
  const selectedId = useSelector(kycSelectedIDSelector);

  const imgRef = useRef(null);

  const uploadingText = useMemo(
    () => getUploadingText(fileKey, selectedId.type),
    [fileKey, selectedId.type]
  );
  const imageClassName = useMemo(() => {
    if (
      (fileKey === KYC_FILES.ID_FRONT || fileKey === KYC_FILES.ID_BACK) &&
      // the above condition is necessary, otherwise selectedId.type us undefined
      !horizontalDocTypes.includes(selectedId.type)
    ) {
      return fileKey === KYC_FILES.ID_FRONT
        ? 'camera-captured-image-rotate90deg'
        : 'camera-captured-image-rotate270deg';
    }
    if (fileKey === KYC_FILES.SELFIE) {
      return 'camera-captured-image-selfie';
    }
    return 'camera-captured-image';
  }, [fileKey, selectedId.type]);

  const handleImageLoad = useCallback(() => {
    if (
      (imageClassName === 'camera-captured-image-rotate90deg' ||
        imageClassName === 'camera-captured-image-rotate270deg') &&
      imgRef.current
    ) {
      const imgHeight = imgRef.current.height;
      const imgWidth = imgRef.current.width;
      const moveDownDistance = (imgWidth - imgHeight) / 2 + 5;
      imgRef.current.style.marginTop = `${moveDownDistance}px`;
    }
  }, [imageClassName]);

  if (parentPageState === LOADING) {
    return (
      <div className="camera-upload-container">
        <img
          className="camera-upload-icon"
          src={`spa/../../build/images/kyc/uploading.gif`}
          alt={'Uploading file'}
        />
        <SmallText sx={{ fontSize: '16px', fontWeight: '600' }}>{uploadingText}</SmallText>
      </div>
    );
  }
  return (
    <img
      ref={imgRef}
      onLoad={handleImageLoad}
      src={kycImageDataUrl}
      alt="Capture failed"
      className={imageClassName}
    />
  );
};

export default PhotoReview;
