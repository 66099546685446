import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Code } from 'spa/components/Code';
import PrimaryLink from 'spa/components/PrimaryLink';
import { Section, SectionTitle, SectionDesc, SectionSubTitle } from 'spa/components/Section';

import { urlFor, envDomainName } from '../../../routeConfig';

const ListEvents = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktopSmall-9">
        <h1 className="integrationsPortal-title">Escrow Offer API :: Listing auction events</h1>
        <Section>
          <SectionTitle>Looking at auction history</SectionTitle>
          <SectionDesc>
            Use this endpoint to get a chronological history of events for an auction.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.`+envDomainName+`/integration/2018-08-01/auction/2d2afb9f-364f-4f9f-82a5-803344d60432/event" \\
    -X GET \\
    -u "email-address:your-api-key"
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.get(
    'https://api.`+envDomainName+`/integration/2018-08-01/auction/2d2afb9f-364f-4f9f-82a5-803344d60432/event',
    auth=('email address', 'api-key'),
)
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="ruby">
                {`require 'net/http'
require 'json'

uri = URI("https://api.`+envDomainName+`/integration/2018-08-01/auction/2d2afb9f-364f-4f9f-82a5-803344d60432/event")

http = Net::HTTP.new(uri.host, uri.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Get.new(uri.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "my-password")
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
  CURLOPT_URL => 'https://api.`+envDomainName+`/integration/2018-08-01/auction/2d2afb9f-364f-4f9f-82a5-803344d60432/event',
  CURLOPT_RETURNTRANSFER => 1,
  CURLOPT_USERPWD => 'email-address:your-password',
  CURLOPT_HTTPHEADER => array(
      'Content-Type: application/json'
  ),
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue(
                "application/json"));
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.`+envDomainName+`/integration/2018-08-01/auction/2d2afb9f-364f-4f9f-82a5-803344d60432/event"),
                Method = HttpMethod.Get,
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
          <SectionSubTitle>Example Response</SectionSubTitle>
          <Code language="json">
            {`{
    "events": [
        {
            "action": "offer",
            "auction_id": 123123,
            "creation_date": "2019-05-09T07:49:48.532617+00:00",
            "id": 1001,
            "offer_id": 12014,
            "no_fee_amount": 500.50,
            "note": "I really want it?",
            "transaction_id": 1464147
        },
        {
            "action": "reject",
            "auction_id": 123123,
            "creation_date": "2019-05-09T07:59:48.532617+00:00",
            "id": 1002,
            "offer_id": 12014,
            "no_fee_amount": 500.50,
            "note": "I really want it?",
            "transaction_id": 1464147
        }
    ]
}`}
          </Code>
        </Section>
        <footer className="integrationsPortal-footer">
          <h3 className="integrationsPortal-footer-title">{'Next steps'}</h3>
          <nav className="integrationsPortal-footer-steps">
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Retrieving auction details"
                link={{
                  type: 'app',
                  route: urlFor('escrow_offer_docs_fetch_auction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Making an offer"
                link={{
                  type: 'app',
                  route: urlFor('escrow_offer_docs_create_offer'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Full API reference Document"
                link={{
                  type: 'external',
                  route: window.config.api_docs_page,
                }}
              />
            </div>
          </nav>
        </footer>
      </div>
    </div>
  </div>
);

export default ListEvents;
