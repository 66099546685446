import {
  getTwoFactorStatus as getTwoFactorStatusRoutine,
  enableAuthAppTwoFactor as enableAuthAppTwoFactorRoutine,
  sendSMSTwoFactor as sendSMSTwoFactorRoutine,
  enableSMSTwoFactor as enableSMSTwoFactorRoutine,
  disableTwoFactor as disableTwoFactorRoutine,
} from 'spa/actions/TwoFactorActions';

const initialState = {
  secretUrl: null,
  authAppEnabled: false,
  smsEnabled: false,
  loading: false,
  codeSent: false,
  showNotification: false,
};

function twoFactorReducer(state = initialState, action) {
  switch (action.type) {
    case getTwoFactorStatusRoutine.TRIGGER:
    case enableAuthAppTwoFactorRoutine.TRIGGER:
      return {
        ...state,
        loading: true,
      };
    case getTwoFactorStatusRoutine.SUCCESS:
    case getTwoFactorStatusRoutine.FAILURE:
      return {
        ...state,
        loading: false,
        authAppEnabled: action.payload.authenticator,
        smsEnabled: action.payload.sms,
      };
    case enableAuthAppTwoFactorRoutine.SUCCESS:
      return {
        ...state,
        loading: false,
        secretUrl: action.payload,
      };
    case enableAuthAppTwoFactorRoutine.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case sendSMSTwoFactorRoutine.TRIGGER:
      return {
        ...state,
        codeSent: false,
      };
    case sendSMSTwoFactorRoutine.SUCCESS:
    case enableSMSTwoFactorRoutine.SUCCESS:
    case disableTwoFactorRoutine.SUCCESS:
      return {
        ...state,
        showNotification: true,
        codeSent: true,
      };
    case sendSMSTwoFactorRoutine.FAILURE:
      return {
        ...state,
        codeSent: false,
      };
    case sendSMSTwoFactorRoutine.FULFILL:
    case enableSMSTwoFactorRoutine.FULFILL:
    case disableTwoFactorRoutine.FULFILL:
      return {
        ...state,
        showNotification: false,
      };
    default:
      return state;
  }
}

export default twoFactorReducer;
