import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { kycCurrentPageSelector, saveCurrentPage } from 'spa/features/kyc/kycSlice';
import { useGetCustomerDetailsQuery } from 'spa/features/kyc/kycApi';
import { V3KYCMobilePages as Pages } from 'spa/constants/VerificationConstants';

import { LargeText } from 'spa/features/ui/EscrowTypography';

import AccountSetupPage from './pages/account-setup/AccountSetupPage';
import PhoneNumberPage from './pages/sms-verification/PhoneNumberPage';
import SMSCodePage from './pages/sms-verification/SMSCodePage';
import SMSCodeSuccessPage from './pages/sms-verification/SMSCodeSuccessPage';
import AccountTypePage from './pages/individual-details-verification/AccountTypePage';
import IndividualDetailsPage from './pages/individual-details-verification/IndividualDetailsPage';
import CompanyDetailsPage from './pages/company-details-verification/CompanyDetailsPage';
import IDSelectionPage from './pages/id-verification/IDSelectionPage';
import IDCapturePage from './pages/id-verification/IDCapturePage';
import IDCaptureReviewPage from './pages/id-verification/IDCaptureReviewPage';
import IDInstructionsPage from './pages/id-verification/IDInstructionsPage';
import POASelectionPage from './pages/poa-verification/POASelectionPage';
import POAInstructionsPage from './pages/poa-verification/POAInstructionsPage';
import POACapturePage from './pages/poa-verification/POACapturePage';
import POACaptureReviewPage from './pages/poa-verification/POACaptureReviewPage';
import SelfieInstructionsPage from './pages/selfie-verification/SelfieInstructionsPage';
import SelfieCapturePage from './pages/selfie-verification/SelfieCapturePage';
import SelfieCaptureReviewPage from './pages/selfie-verification/SelfieCaptureReviewPage';
import VerificationSuccessPage from './pages/verification-flow-success/VerificationSuccessPage';
import CompanyDocumentSelectionPage from './pages/company-verification/CompanyDocumentSelectionPage';
import CompanyCapturePage from './pages/company-verification/CompanyCapturePage';
import CompanyCaptureReviewPage from './pages/company-verification/CompanyCaptureReviewPage';
import CompanyVerificationSuccessPage from './pages/company-verification-flow-success/CompanyVerificationSuccessPage';

import ForceCurrentPage from './pages/development-only/ForceCurrentPage';
import POAUploadSuccessPage from './pages/poa-verification/POAUploadSuccessPage';
import CompanyUploadSuccessPage from './pages/company-verification/CompanyUploadSuccessPage';
import POAFileReviewPage from './pages/poa-verification/POAFileReviewPage';
import IDUploadSuccessPage from './pages/id-verification/IDUploadSuccessPage';
import SelfieUploadSuccessPage from './pages/selfie-verification/SelfieUploadSuccessPage';
import CompanyFileReviewPage from './pages/company-verification/CompanyFileReviewPage';

const MobilePage = () => {
  const currentPage = useSelector(kycCurrentPageSelector);

  const pages = {};

  // New User Account Setup
  pages[Pages.ACCOUNT_SETUP] = <AccountSetupPage />;

  // SMS Verification
  pages[Pages.PHONE_NUMBER] = <PhoneNumberPage />;
  pages[Pages.SMS_CODE] = <SMSCodePage />;
  pages[Pages.SMS_CODE_SUCCESS] = <SMSCodeSuccessPage />;

  // Individual Details
  pages[Pages.INDIVIDUAL_DETAILS] = <IndividualDetailsPage />;

  // Company Details
  pages[Pages.COMPANY_DETAILS] = <CompanyDetailsPage />;

  // Individual Details Verification
  pages[Pages.ID_SELECTION] = <IDSelectionPage />;
  pages[Pages.ID_FRONT_INSTRUCTIONS] = <IDInstructionsPage />;
  pages[Pages.ID_FRONT_CAPTURE] = <IDCapturePage />;
  pages[Pages.ID_FRONT_CAPTURE_REVIEW] = <IDCaptureReviewPage />;
  pages[Pages.ID_BACK_INSTRUCTIONS] = <IDInstructionsPage back />;
  pages[Pages.ID_BACK_CAPTURE] = <IDCapturePage back />;
  pages[Pages.ID_BACK_CAPTURE_REVIEW] = <IDCaptureReviewPage back />;
  pages[Pages.ID_FRONT_UPLOAD_SUCCESS] = <IDUploadSuccessPage />;
  pages[Pages.ID_BACK_UPLOAD_SUCCESS] = <IDUploadSuccessPage back />;

  // Proof of Address (POA) Verification
  pages[Pages.POA_SELECTION] = <POASelectionPage />;
  pages[Pages.POA_INSTRUCTIONS] = <POAInstructionsPage />;
  pages[Pages.POA_CAPTURE] = <POACapturePage />;
  pages[Pages.POA_CAPTURE_REVIEW] = <POACaptureReviewPage />;
  pages[Pages.POA_UPLOAD_SUCCESS] = <POAUploadSuccessPage />;
  pages[Pages.POA_FILE_REVIEW] = <POAFileReviewPage />;

  // Selfie (Extended KYC) Verification
  pages[Pages.SELFIE_INSTRUCTIONS] = <SelfieInstructionsPage />;
  pages[Pages.SELFIE_CAPTURE] = <SelfieCapturePage />;
  pages[Pages.SELFIE_CAPTURE_REVIEW] = <SelfieCaptureReviewPage />;
  pages[Pages.SELFIE_UPLOAD_SUCCESS] = <SelfieUploadSuccessPage />;

  // Account Type Selection
  pages[Pages.ACCOUNT_TYPE] = <AccountTypePage />;

  // Verification Flow Success
  pages[Pages.VERIFICATION_SUCCESS] = <VerificationSuccessPage />;

  // Company Verifications
  pages[Pages.COMPANY_DOCUMENT_SELECTION] = <CompanyDocumentSelectionPage />;
  pages[Pages.COMPANY_CAPTURE] = <CompanyCapturePage />;
  pages[Pages.COMPANY_CAPTURE_REVIEW] = <CompanyCaptureReviewPage />;
  pages[Pages.COMPANY_UPLOAD_SUCCESS] = <CompanyUploadSuccessPage />;
  pages[Pages.COMPANY_FILE_REVIEW] = <CompanyFileReviewPage />;

  pages[Pages.COMPANY_VERIFICATION_SUCCESS] = <CompanyVerificationSuccessPage />;

  // TODO: Remove on release
  // For Development Only
  pages.FORCE_CURRENT_PAGE = <ForceCurrentPage pages={pages} />;

  if (currentPage in pages) {
    return <Fragment>{pages[currentPage]}</Fragment>;
  }

  return <LargeText> Page does not exist </LargeText>;
};

const MobileKYC = ({ customerId }) => {
  const {
    data: customerData,
    // eslint-disable-next-line no-unused-vars
    isLoading: customerIsLoading,
    // eslint-disable-next-line no-unused-vars
    isFetching: customerIsFetching,
    // eslint-disable-next-line no-unused-vars
    error: customerError,
  } = useGetCustomerDetailsQuery(customerId);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(saveCurrentPage({ page: 'FORCE_CURRENT_PAGE' }));

    // TODO: Uncomment on release
    // dispatch(saveCurrentPage({ page: 'Pages.PHONE_NUMBER' }));
  }, [dispatch]);

  // eslint-disable-next-line no-console
  console.log('customerData:');
  // eslint-disable-next-line no-console
  console.log(customerData);

  return <Fragment>{!customerData ? <div> Loading... </div> : <MobilePage />}</Fragment>;
};

export default MobileKYC;
