import { connect } from 'react-redux';
import PaymentVerificationSection from 'spa/components/PaymentVerification/Verification';
import { getCardVerificationDetails as getCardVerificationDetailsRoutine } from 'spa/actions/PaymentsActions';
import { cardVerificationDetailsByIdSelector } from 'spa/selectors/PaymentSelectors';

const mapStateToProps = (state, ownProps) => ({
  cardId: ownProps.cardId,
  transId: ownProps.transId,
  cardVerificationId: ownProps.cardVerificationId,
  charge: cardVerificationDetailsByIdSelector(state, ownProps.cardVerificationId),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getCardVerificationDetails: () =>
    dispatch(
      getCardVerificationDetailsRoutine.trigger({
        cardId: ownProps.cardId,
        transId: ownProps.transId,
        cardVerificationId: ownProps.cardVerificationId,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentVerificationSection);
