import request from 'browser-request';
import validator from 'validator';
import window from 'window-shim';
import { postJSON } from '../utils/FetchUtils';
import { urlfor } from '../utils/filters';

import StartTransactionConstants from '../constants/StartTransactionConstants';
import ErrorMessages from '../constants/ErrorMessages';
import StartTransactionStore from '../stores/StartTransactionStore';
import AuthenticationStore from '../stores/AuthenticationStore';
import AuthenticationActions from './AuthenticationActions';
import FormConstants from '../constants/FormConstants';
import AuthenticationConstants from '../constants/AuthenticationConstants';
import AppDispatcher from '../dispatcher/AppDispatcher';
import TrackingActions from '../actions/TrackingActions';
import API from '../api/API';

export class StartTransactionActions {
  _redirect(url) {
    window.location.href = url;
  }

  selectRole(value) {
    AppDispatcher.handleViewAction({
      actionType: StartTransactionConstants.SELECT_ROLE,
      role: value,
    });
  }

  selectReturning(value) {
    AppDispatcher.handleViewAction({
      actionType: StartTransactionConstants.SELECT_RETURNING,
      returning: value,
    });
  }

  selectShipping(value) {
    AppDispatcher.handleViewAction({
      actionType: StartTransactionConstants.SELECT_SHIPPING,
      value: value,
    });
  }

  selectShippingPayer(value) {
    AppDispatcher.handleViewAction({
      actionType: StartTransactionConstants.SELECT_SHIPPING_PAYER,
      value: value,
    });
  }

  toggleAdvanced() {
    AppDispatcher.handleViewAction({
      actionType: StartTransactionConstants.TOGGLE_ADVANCED,
    });
  }

  signupShadow(email, field) {
    if (email && validator.isEmail(email)) {
      let source;
      if (field === 'email-address') {
        source = AuthenticationConstants.REGISTER_SOURCES.START_INITIATOR;
      } else if (field === 'email-address-other') {
        source = AuthenticationConstants.REGISTER_SOURCES.START_NON_INITIATOR;
      }

      if (source) {
        AuthenticationActions.signupShadow(email, source);
      }
    }
  }

  submit(data, formName, transactionType) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.SUBMISSION_START,
      name: formName,
    });

    AppDispatcher.handleViewAction({
      actionType: StartTransactionConstants.UPDATE_FIELDS,
      data: data,
      transactionType: transactionType,
    });

    data.password = data.password || data['password-new'];
    if (!AuthenticationStore.isAuthenticated()) {
      if (StartTransactionStore.returning) {
        if (StartTransactionStore.emailExists[data['email-address']]) {
          AuthenticationActions.login(data['email-address'], data.password, formName);
        } else {
          AppDispatcher.handleServerAction({
            actionType: FormConstants.SUBMISSION_FAILURE,
            name: formName,
            title: ErrorMessages.EMAIL_NOT_FOUND,
          });
        }
      } else if (!StartTransactionStore.emailExists[data['email-address']]) {
        AuthenticationActions.signup(data, formName);
      } else {
        AppDispatcher.handleServerAction({
          actionType: FormConstants.SUBMISSION_FAILURE,
          name: formName,
          title: ErrorMessages.EMAIL_ALREADY_EXISTS,
        });
      }
    } else {
      this.startTransaction(formName);
    }
  }

  submitCheckout(data, loginData, formName) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.SUBMISSION_START,
      name: formName,
    });

    let submission;

    if (!AuthenticationStore.isAuthenticated()) {
      if (loginData.returning) {
        submission = AuthenticationActions._login(loginData.email, loginData.password).then(() => {
          if (formName === 'checkout-start-transaction') {
            this._startTransactionV4(data);
          } else {
            this._startTransaction(data);
          }
        });
      } else {
        submission = AuthenticationActions._signup(loginData.email, loginData.password).then(() => {
          if (formName === 'checkout-start-transaction') {
            this._startTransactionV4(data);
          } else {
            this._startTransaction(data);
          }
        });
      }
    } else if (formName === 'checkout-start-transaction') {
      submission = this._startTransactionV4(data);
    } else {
      submission = this._startTransaction(data);
    }

    submission
      .then((transactionID) => {
        this._redirect(`${window.config.www_base_url}/transaction/${transactionID}`);
      })
      .catch((error) => {
        AppDispatcher.handleServerAction({
          actionType: FormConstants.SUBMISSION_FAILURE,
          name: formName,
          title: error,
        });
      });
  }

  loginOrSignup(userFields) {
    if (StartTransactionStore.emailExists[userFields.email]) {
      return () => AuthenticationActions._login(userFields.email, userFields.password);
    }

    const userSignupFields = {
      Phone: userFields.phone,
      FirstName: userFields.first_name,
      LastName: userFields.last_name,
    };
    return () =>
      AuthenticationActions._signup(userFields.email, userFields.password, userSignupFields).catch(
        () => window.Promise.reject(ErrorMessages.TECHNICAL_DIFFICULTIES)
      );
  }

  buyerAuthenticate(buyer, formName) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.SUBMISSION_START,
      name: formName,
    });

    this.loginOrSignup(buyer)()
      .then(() => {
        AppDispatcher.handleServerAction({
          actionType: StartTransactionConstants.MULTI_STEP_PROGRESSION,
          name: formName,
          stepComplete: 'buyerAuthenticated',
        });
        AppDispatcher.handleServerAction({
          actionType: FormConstants.SUBMISSION_SUCCESS,
          name: formName,
        });
        AppDispatcher.handleViewAction({
          actionType: FormConstants.SHOW_FORM_ERROR,
          form: formName,
          showError: false,
        });
      })
      .catch((error) => {
        let errorMessage;
        if (error !== ErrorMessages.TECHNICAL_DIFFICULTIES) {
          errorMessage =
            'The email address and password combination for the Buyer is incorrect. ' +
            'Please try again.';
        } else {
          errorMessage = error;
        }
        AppDispatcher.handleServerAction({
          actionType: FormConstants.SUBMISSION_FAILURE,
          name: formName,
          title: errorMessage,
        });
      });
  }

  sellerAuthenticate(seller, formName) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.SUBMISSION_START,
      name: formName,
    });

    this.loginOrSignup(seller)()
      .then(() => {
        AppDispatcher.handleServerAction({
          actionType: StartTransactionConstants.MULTI_STEP_PROGRESSION,
          name: formName,
          stepComplete: 'sellerAuthenticated',
        });
        AppDispatcher.handleServerAction({
          actionType: FormConstants.SUBMISSION_SUCCESS,
          name: formName,
        });
        AppDispatcher.handleViewAction({
          actionType: FormConstants.SHOW_FORM_ERROR,
          form: formName,
          showError: false,
        });
      })
      .catch((error) => {
        let errorMessage;
        if (error !== ErrorMessages.TECHNICAL_DIFFICULTIES) {
          errorMessage =
            'The email address and password combination for the Seller is incorrect. ' +
            'Please try again.';
        } else {
          errorMessage = error;
        }
        AppDispatcher.handleServerAction({
          actionType: FormConstants.SUBMISSION_FAILURE,
          name: formName,
          title: errorMessage,
        });
      });
  }

  submitAAAStartTransaction(data, buyer, formName) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.SUBMISSION_START,
      name: formName,
    });

    const submission = () =>
      this._startTransaction(data).then((transactionID) =>
        AuthenticationActions._login(buyer.email, buyer.password).then(() =>
          API.performTransactionAction(transactionID, 'agree')
        )
      );

    submission()
      .then(() => {
        this._redirect(urlfor('.partners_aaa_success'));
      })
      .catch((error) => {
        AppDispatcher.handleServerAction({
          actionType: FormConstants.SUBMISSION_FAILURE,
          name: formName,
          title: error,
        });
      });
  }

  submitNamesconStartTransaction(data, buyer, formName) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.SUBMISSION_START,
      name: formName,
    });

    const submission = () =>
      this._startTransaction(data).then((transactionID) =>
        AuthenticationActions._login(buyer.email, buyer.password).then(() =>
          API.performTransactionAction(transactionID, 'agree')
        )
      );

    submission()
      .then(() => {
        this._redirect(urlfor('.namescon_start_success'));
      })
      .catch((error) => {
        AppDispatcher.handleServerAction({
          actionType: FormConstants.SUBMISSION_FAILURE,
          name: formName,
          title: error,
        });
      });
  }

  _startTransaction(data) {
    return postJSON(window.config.transaction_api_endpoint, data).then((response) => {
      if (response.status === 201) {
        return response.json().then((body) => window.Promise.resolve(body.TransactionID));
      }
      return window.Promise.reject();
    });
  }

  _startTransactionV4(data) {
    return postJSON(`${window.config.v4_api_endpoint}/transaction`, data).then((response) => {
      if (response.status === 201) {
        return response.json().then((body) => window.Promise.resolve(body.TransactionID));
      }
      return window.Promise.reject();
    });
  }

  startTransaction(formName) {
    const formData = StartTransactionStore.getFormData();
    request.post(
      {
        url: window.config.transaction_api_endpoint,
        json: formData,
        contentType: 'application/json',
        withCredentials: true,
      },
      (error, response) => {
        if (!error && response.status === 201) {
          TrackingActions.track(
            {
              event: 'escrow_user_action',
              action: 'conversion',
              label: 'create',
              value: StartTransactionConstants.CREATE_TO_PAID_RATE * 25,
            },
            true
          );

          if (formData.ShipmentFreightlancer) {
            this.sendFreightlanderEmail(response.body.TransactionID).then(() => {
              this.dispatchSuccess(formName, response);
            });
          } else {
            this.dispatchSuccess(formName, response);
          }
        } else if (error && response.status === 401) {
          AppDispatcher.handleServerAction({
            actionType: FormConstants.SUBMISSION_FAILURE,
            name: formName,
            title: ErrorMessages.SESSION_EXPIRY,
          });
        } else {
          AppDispatcher.handleServerAction({
            actionType: FormConstants.SUBMISSION_FAILURE,
            name: formName,
            title: ErrorMessages.TRANSACTION_FAILED,
          });
        }
      }
    );
  }

  sendFreightlanderEmail(transactionId) {
    return new Promise((resolve) => {
      request.post(
        {
          url: `/api/freightlancer-inquiry`,
          json: {
            transaction_id: transactionId,
          },
          contentType: 'application/json',
          withCredentials: true,
        },
        () => {
          resolve();
        }
      );
    });
  }

  dispatchSuccess(formName, response) {
    AppDispatcher.handleServerAction({
      actionType: FormConstants.SUBMISSION_SUCCESS,
      name: formName,
      transactionID: response.body.TransactionID,
      role: StartTransactionStore.getRole(),
    });
  }
}

export default new StartTransactionActions();
