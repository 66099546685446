import React from 'react';

const Breadcrumbs = ({ className, items }) => (
  <ol className={`breadcrumbs ${className}`} itemScope itemType="http://schema.org/BreadcrumbList">
    {items.map((item) => (
      <li
        key={item.link ? item.link : ''}
        className="breadcrumbs-item"
        itemProp="itemListElement"
        itemScope
        itemType="http://schema.org/ListItem"
      >
        {item.link ? (
          <a className="breadcrumbs-link" itemProp="item" href={item.link}>
            <span itemProp="name">{item.text}</span>
          </a>
        ) : (
          <span>{item.text}</span>
        )}
        <meta itemProp="position" content={item.index} />
      </li>
    ))}
  </ol>
);

export default Breadcrumbs;
