import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'escrow-common-js/dist/components';
import { Spinner } from 'spa/components/Indicators';
import {
  customerEmailVerificationStatusSelector,
  customerEmailVerificationSent,
  customerEmailVerificationSending,
  CustomerV4GetStatusSelector,
  customerDetailsLoadingSelector,
  custEmailSelector,
} from 'spa/selectors/CustomerSelectors';
import { sendVerificationEmail } from 'spa/actions/TwoFactorActions';
import { getV4Customer as getV4CustomerRoutine } from 'spa/actions/UserActions';
import { gettext } from '../../../utils/filters';

import AuthenticationStore from '../../../stores/AuthenticationStore';

const EmailVerificationContainer = (props) => {
  const {
    isEmailVerified,
    sendEmail,
    dispatchV4GetCustomer,
    custEmail,
    emailSent,
    emailSending,
    isCustomerV4GetSuccess,
    loading,
    onEmailVerified,
  } = props;

  const [btnDisable, setBtnDisable] = useState(false);
  const [firstEmailSent, setFirstEmailSent] = useState(false);

  useEffect(() => {
    if (!isCustomerV4GetSuccess) {
      dispatchV4GetCustomer(AuthenticationStore.getCustomerID());
    }
  }, [isCustomerV4GetSuccess, dispatchV4GetCustomer]);

  useEffect(() => {
    if (!isEmailVerified && isCustomerV4GetSuccess) {
      sendEmail();
      setFirstEmailSent(true);
    } else if (onEmailVerified && isCustomerV4GetSuccess) {
      onEmailVerified();
    }
  }, [isEmailVerified, isCustomerV4GetSuccess, sendEmail, onEmailVerified]);

  useEffect(() => {
    if (emailSent && !firstEmailSent) {
      setBtnDisable(true);
      const timeout = setTimeout(() => setBtnDisable(false), 30000);
      return () => clearTimeout(timeout);
    }
  }, [emailSent, firstEmailSent]);

  if (loading) {
    return (
      <div className="twoFactorAuth-card-placeholder">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="twoFactorAuth-page">
      <h1 className="emailVerification-header">
        {gettext("Before you continue, let's verify your email")}
      </h1>
      <div>
        <p className="emailVerification-text">
          {gettext('We sent an email to ')}
          <strong>{gettext(custEmail)}</strong>
          {gettext('. ')}
          {gettext(
            'Please follow the instructions to verify your email. If you have already completed the verification, simply refresh this page to proceed.'
          )}
        </p>
        <p className="emailVerification-text">{gettext("Haven't received an email?")}</p>
      </div>
      <div className="emailVerification-resendBtn">
        <Button
          role="button"
          color="primaryHollow"
          onClick={() => {
            sendEmail();
            setFirstEmailSent(false);
          }}
          disabled={btnDisable || (!firstEmailSent && emailSending)}
        >
          {!firstEmailSent && emailSending ? <Spinner /> : gettext('Resend email')}
        </Button>
      </div>
    </div>
  );
};

EmailVerificationContainer.propTypes = {
  onEmailVerified: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loading: customerDetailsLoadingSelector(state),
  isCustomerV4GetSuccess: CustomerV4GetStatusSelector(state),
  custEmail: custEmailSelector(state),
  isEmailVerified: customerEmailVerificationStatusSelector(state),
  emailSent: customerEmailVerificationSent(state),
  emailSending: customerEmailVerificationSending(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchV4GetCustomer: (custId) => {
    dispatch(getV4CustomerRoutine({ custId }));
  },
  sendEmail: () => dispatch(sendVerificationEmail.trigger()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerificationContainer);
