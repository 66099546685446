import { connect } from 'react-redux';
import {
  getOutstandingBalance,
  getWireDetails,
  setPaymentType,
  fetchTransactionAndPaymentMethods,
  resetPaymentPageState,
  getLastBuyerBankEntry,
} from 'spa/actions/PaymentsActions';
import { getSavedCreditCards } from 'spa/actions/UserActions';
import { OutstandingPaymentForm } from 'spa/components/Payments';
import {
  buyerCheckoutDetailsSelector,
  customerSavedCardsLoadingSelector,
  customerSavedCardsCalledOnceSelector,
  customerSavedCreditCardsSelector,
} from 'spa/selectors/CustomerSelectors';
import {
  paymentsPageOutstandingBalanceSelector,
  paymentsPageOutstandingBalanceErrorSelector,
  paymentsPagePaymentTypeSelector,
  paymentsPagePaymentSubmissionErrorSelector,
  paymentsPageWireDetailsSelector,
  paymentsPageWireDetailsErrorSelector,
} from 'spa/selectors/PaymentsPageSelectors';
import { lastBuyerBankEntrySelector, adyenIsValidSelector } from 'spa/selectors/PaymentSelectors';
import {
  transactionByIdSelector,
  transactionFeesSelector,
  transactionLineItemsSelector,
  transactionAdyenAdditionalActionSelector,
  transactionAdyenCheckoutSelector,
  transactionHasAdyenCCSelector,
  transactionCCPaymentGatewaySelector,
} from 'spa/selectors/TransactionSelectors';

const mapDispatchToProps = (dispatch, ownProps) => ({
  resetPaymentPageState: () => dispatch(resetPaymentPageState()),
  getOutstandingBalance: () =>
    dispatch(getOutstandingBalance.trigger({ transId: ownProps.transactionId })),
  getSavedCreditCards: (currency) =>
    dispatch(
      getSavedCreditCards.trigger({
        customerId: ownProps.customerId,
        currency: currency,
      })
    ),
  getTransactionAndPaymentMethods: () =>
    dispatch(fetchTransactionAndPaymentMethods.trigger({ transId: ownProps.transactionId })),
  getWireDetails: () => dispatch(getWireDetails.trigger({ transId: ownProps.transactionId })),
  setPaymentType: (paymentType) => dispatch(setPaymentType(paymentType)),
  getLastBuyerBankEntry: () =>
    dispatch(getLastBuyerBankEntry.trigger({ customerId: ownProps.customerId })),
});

const mapStateToProps = (state, ownProps) => {
  const transaction = transactionByIdSelector(state, ownProps.transactionId);

  const lastBuyerBankEntry = lastBuyerBankEntrySelector(state);
  let initialBuyerBankDetails = null;
  if (lastBuyerBankEntry) {
    initialBuyerBankDetails = {
      bankState: lastBuyerBankEntry.bank_state,
      bankCountry: lastBuyerBankEntry.bank_country,
      accountName: lastBuyerBankEntry.account_name,
      bankName: lastBuyerBankEntry.bank_name,
      thirdPartyCheckbox: Boolean(lastBuyerBankEntry.third_party_email),
      thirdPartyEmail: lastBuyerBankEntry.third_party_email,
    };
  }

  return {
    ...ownProps,
    transactionCCPaymentGateway: transactionCCPaymentGatewaySelector(state, transaction.id),
    customerDetails: buyerCheckoutDetailsSelector(state),
    customerSavedCreditCards: customerSavedCreditCardsSelector(state),
    customerSavedCreditCardsLoading: customerSavedCardsLoadingSelector(state),
    customerSavedCreditCardsCalledOnce: customerSavedCardsCalledOnceSelector(state),
    fees: transactionFeesSelector(transaction),
    fetchErrors:
      paymentsPageOutstandingBalanceErrorSelector(state) ||
      paymentsPageWireDetailsErrorSelector(state),
    initialValues: {
      paymentType: 'wire_transfer',
      buyerBankDetails: initialBuyerBankDetails || undefined,
    },
    lineItems: transactionLineItemsSelector(transaction),
    outstandingBalance: paymentsPageOutstandingBalanceSelector(state),
    paymentType: paymentsPagePaymentTypeSelector(state),
    submissionError: paymentsPagePaymentSubmissionErrorSelector(state),
    transaction: Object.keys(transaction).length > 0 ? transaction : null,
    wireDetails: paymentsPageWireDetailsSelector(state),
    isAdyenValid: adyenIsValidSelector(state),
    adyenCCAvailable: transactionHasAdyenCCSelector(transaction),
    adyenAction: transactionAdyenAdditionalActionSelector(transaction),
    adyenCheckout: transactionAdyenCheckoutSelector(transaction),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OutstandingPaymentForm);
