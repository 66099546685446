import UserConstants from 'spa/constants/UserConstants';

import TaskReducer from './TaskReducer';

const initialState = {
  tasks: {},
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case UserConstants.GET_USER_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case UserConstants.GET_USER_SUCCESS:
      return Object.assign({}, state, action.user, {
        loading: false,
      });
    case UserConstants.GET_USER_FAILURE:
      return Object.assign({}, state, {
        error: true,
        loading: false,
      });
    default:
      return Object.assign({}, state, {
        tasks: TaskReducer(state.tasks, action),
      });
  }
}

export default userReducer;
