import React, { useEffect } from 'react';

import A from 'spa/components/A';
import CreditCardLogo from 'spa/components/CreditCardLogo';
import SaveCreditCardForm from 'spa/components/SavedPaymentMethods/SaveCreditCardForm';
import { Spinner } from 'spa/components/Indicators';

const SavedPaymentMethodsContainer = ({
  adyenLoading,
  initAdyenCheckout,
  display,
  isTier2Verfied,
  ...props
}) => {
  useEffect(() => {
    initAdyenCheckout();
  }, [initAdyenCheckout]);
  const loading = display.loading || adyenLoading;

  return (
    <section className="accountInfo-card card account">
      <div className="accountInfo-card-inner">
        <h2 className="accountForm-header accountForm-header--highlight">
          <span className="accountForm-header-title">Add a new credit card</span>
          <span className="card-header-action">
            <CreditCardLogo type="visa" />
            <CreditCardLogo type="mastercard" />
            <CreditCardLogo type="amex" />
          </span>
        </h2>
        {loading && <Spinner />}
        {!loading && isTier2Verfied && <SaveCreditCardForm {...props} />}
        {!loading && !isTier2Verfied && (
          <div>
            <div className="alert accountInfo-card-alert">
              You must <A link={{ type: 'internal', route: '/verify' }}>verify your account</A> in
              order to use Credit Cards as a payment method.
              <br />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default SavedPaymentMethodsContainer;
