import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

const useDebouncedEffect = (effect, dependencies, delay) => {
  useEffect(
    () => {
      const handler = setTimeout(() => effect(), delay);
      return () => clearTimeout(handler);
    },
    // Note: This comment has been added long after this code was written
    // This code was probably found at: https://dev.to/rajeshroyal/how-to-make-a-custom-debounce-hook-in-react-js-4gcc
    // and it's difficult to get this to pass lint. So lint has been disabled for now.
    // Also not convinced this code actually does debouncing correctly lol
    //
    // A better debouncing solution is use-debounce: https://www.npmjs.com/package/use-debounce
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...(dependencies || []), delay]
  );
};

export { useWindowDimensions, useDebouncedEffect };
