import { gettext } from './filters';

export default class PhotoCapture {
  constructor(rootModal) {
    this.rootModal = rootModal;
    this.video = rootModal.querySelector('[data-camera="video"]');
    this.imageCanvas = rootModal.querySelector('[data-camera="snapshot"]');
    this.uploadButton = rootModal.querySelector('[data-camera="upload"]');
    this.retakeButton = rootModal.querySelector('[data-camera="retake"]');
    this.captureButton = rootModal.querySelector('[ data-camera="capture"]');
    this.closeButtons = rootModal.querySelectorAll('[data-target="modal-close"]');
    this.helpText = rootModal.querySelector('[data-camera="loader"]');
    this.imageContext = this.imageCanvas.getContext('2d');
    this.uploadCallback = null;
    this.constraints = {
      video: true,
      audio: false,
    };

    this._paintImageToCanvas = this._paintImageToCanvas.bind(this);
    this._uploadPhoto = this._uploadPhoto.bind(this);
    this._retakePhoto = this._retakePhoto.bind(this);
    this._setListeners = this._setListeners.bind(this);
    this._removeListeners = this._removeListeners.bind(this);
    this._close = this._close.bind(this);
    this._getBlobFromCanvas = this._getBlobFromCanvas.bind(this);
    this._onKeyDown = this._onKeyDown.bind(this);
  }

  async init(uploadCallback) {
    if (await this._bootCamera()) {
      if (!this.rootModal.classList.contains('is-visible')) {
        this._close();
      }
      this._setListeners();
      this.uploadCallback = uploadCallback;
      this.helpText.classList.add('is-hidden');
      this.captureButton.classList.remove('is-hidden');
      this.video.classList.remove('is-hidden');
    }
  }

  _checkIfSupported() {
    // If browser doesn't support mediaDevices, just deny immediately
    // instead of trying to get "potential" legacy support
    if (navigator.mediaDevices === undefined) {
      const error = new Error('getUserMedia is not implemented in this browser');
      error.name = 'UnsupportedBrowser';
      throw error;
    }
  }

  async _bootCamera() {
    try {
      this._checkIfSupported();
      this.video.srcObject = await navigator.mediaDevices.getUserMedia(this.constraints);
      return true;
    } catch (e) {
      if (e.name === 'NotAllowedError') {
        this.helpText.innerText = gettext(
          "Looks like Escrow.com is not allowed to access your camera. Either update Escrow.com's camera permissions and reload the page or use the file uploader instead."
        );
      } else if (e.name === 'NotFoundError') {
        this.helpText.innerText = gettext(
          "Looks like you don't have any camera devices available. Either connect a camera device and try again or use the file uploader instead."
        );
      } else if (e.name === 'UnsupportedBrowser') {
        this.helpText.innerText = gettext(
          'Your browser does not support this feature. Please use the file uploader instead.'
        );
      } else {
        this.helpText.innerText = gettext(
          'Something went wrong. Please close this window and try again.'
        );
      }
      return false;
    }
  }

  /*
    Based from https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
   */
  _getBlobFromCanvas(fileName = 'image.png', type = 'image/png') {
    const binStr = atob(this.imageCanvas.toDataURL(type).split(',')[1]);
    const len = binStr.length;
    const arr = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }

    const blobFile = new Blob([arr], { type: type });
    blobFile.lastModifiedDate = new Date();
    blobFile.name = fileName;
    return blobFile;
  }

  _paintImageToCanvas() {
    this.imageContext.drawImage(this.video, 0, 0);
    this.video.classList.add('is-hidden');
    this.captureButton.classList.add('is-hidden');
    this.imageCanvas.classList.remove('is-hidden');
    this.uploadButton.classList.remove('is-hidden');
    this.retakeButton.classList.remove('is-hidden');
  }

  _retakePhoto() {
    this.video.classList.remove('is-hidden');
    this.captureButton.classList.remove('is-hidden');
    this.imageCanvas.classList.add('is-hidden');
    this.uploadButton.classList.add('is-hidden');
    this.retakeButton.classList.add('is-hidden');
  }

  _uploadPhoto() {
    this.uploadCallback(this._getBlobFromCanvas());
    this._close();
  }

  _onKeyDown(event) {
    if (event.keyCode === 27) {
      this._close();
    }
  }

  _setListeners() {
    this.captureButton.addEventListener('click', this._paintImageToCanvas);
    this.uploadButton.addEventListener('click', this._uploadPhoto);
    this.retakeButton.addEventListener('click', this._retakePhoto);
    this.rootModal.addEventListener('keydown', this._onKeyDown);
    for (const closeButton of this.closeButtons) {
      closeButton.addEventListener('click', this._close);
    }
  }

  _removeListeners() {
    this.captureButton.removeEventListener('click', this._paintImageToCanvas);
    this.uploadButton.removeEventListener('click', this._uploadPhoto);
    this.retakeButton.removeEventListener('click', this._retakePhoto);
    this.rootModal.removeEventListener('keydown', this._onKeyDown);
    for (const closeButton of this.closeButtons) {
      closeButton.removeEventListener('click', this._close);
    }
  }

  _close() {
    // Let modal closes first before clean-up for better UX
    setTimeout(() => {
      // Cleanup Resources
      if (this.video.srcObject) {
        this.video.srcObject.getVideoTracks().forEach((track) => track.stop());
      }
      this.imageContext.clearRect(0, 0, this.imageCanvas.width, this.imageCanvas.height);

      // Reset state of elements manipulated
      this.imageCanvas.classList.add('is-hidden');
      this.uploadButton.classList.add('is-hidden');
      this.retakeButton.classList.add('is-hidden');
      this.captureButton.classList.add('is-hidden');
      this.video.classList.add('is-hidden');
      this.helpText.innerText = '';
      this.helpText.classList.remove('is-hidden');

      // Remove any registered callbacks
      this._removeListeners();
    }, 500);
  }
}

export async function dataURLtoFile(dataurl, filename) {
  const res = await fetch(dataurl);
  const blob = await res.blob();
  return new File([blob], filename, { type: blob.type });
}
