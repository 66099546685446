import libphonenumber, { PhoneNumberType } from 'google-libphonenumber';
import { CountryToAlpha2, Alpha3ToAlpha2 } from '../spa/constants/ISOCountryCodes';

export function parsePhone(number) {
  const phUtil = libphonenumber.PhoneNumberUtil.getInstance();
  try {
    const phone = phUtil.parse(number, '');
    if (phUtil.isValidNumber(phone)) {
      return {
        prefix: phone.getCountryCode(),
        suffix: phone.getNationalNumber(),
      };
    }
    throw new Error();
  } catch (err) {
    return {};
  }
}

export function formatPhoneNumber(prefix, suffix) {
  const phUtil = libphonenumber.PhoneNumberUtil.getInstance();
  try {
    const phone = phUtil.parse(`${prefix}${suffix}`, '');
    if (phUtil.isValidNumber(phone)) {
      return `+${phone.getCountryCode()}${phone.getNationalNumber()}`;
    }
    throw new Error();
  } catch (err) {
    return `${prefix}${suffix}`;
  }
}

export function formatPhoneNumberInternational(phoneNumber) {
  const phUtil = libphonenumber.PhoneNumberUtil.getInstance();
  const PNF = libphonenumber.PhoneNumberFormat;
  try {
    const trimmedPhoneNumber = (phoneNumber || '').trim();
    const phone = phUtil.parse(trimmedPhoneNumber, '');
    if (phUtil.isValidNumber(phone)) {
      return phUtil.format(phone, PNF.INTERNATIONAL);
    }
    throw new Error();
  } catch (err) {
    return phoneNumber;
  }
}

export function getNumberType(number) {
  const phUtil = libphonenumber.PhoneNumberUtil.getInstance();
  try {
    return phUtil.getNumberType(phUtil.parse(number, ''));
  } catch (err) {
    return PhoneNumberType.UNKNOWN;
  }
}

export function parsePhoneNumber(number, country) {
  const phUtil = libphonenumber.PhoneNumberUtil.getInstance();

  // get the alpha-2 code for the country
  let alpha2Country = 'ZZ';
  if (!country) {
    alpha2Country = 'ZZ';
  } else if (country.length > 3) {
    // Assume country is a full country name
    alpha2Country = CountryToAlpha2[country] || 'ZZ';
  } else if (country.length === 3) {
    // Assume country is an alpha-3 code
    alpha2Country = Alpha3ToAlpha2[country] || 'ZZ';
  } else if (country.length === 2) {
    // Assume country is an alpha-2 code
    alpha2Country = country;
  }

  try {
    const phone = phUtil.parse(number, alpha2Country);
    if (phUtil.isValidNumber(phone)) {
      return phone;
    }
    throw new Error();
  } catch (err) {
    return null;
  }
}

export function parseInvalidNumber(number) {
  try {
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    const utilParse = phoneUtil.parse(number);
    const primaryPhoneNumber = utilParse.getNationalNumber();
    const primaryPhoneCountry = phoneUtil.getRegionCodeForCountryCode(utilParse.getCountryCode());
    return {
      phoneNumber: primaryPhoneNumber,
      phoneCountry: primaryPhoneCountry,
    };
  } catch (err) {
    return null;
  }
}
