import { race, take, put, takeLatest, select, call } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';
import PhoneCountryCodes from 'spa/constants/PhoneCountryCodes';
import {
  acceptOffer as acceptOfferRoutine,
  acceptOfferFromManagement as acceptOfferFromManagementRoutine,
  cancelAuction as cancelAuctionRoutine,
  changeView,
  getAuction as getAuctionRoutine,
  initialiseMakeAnOffer as initialiseMakeAnOfferRoutine,
  initialiseOfferManagement as initaliseOfferManagementRoutine,
  getAuctionOffers as getAuctionOffersRoutine,
  pushAuctionId,
  cancelOffer as cancelOfferRoutine,
  rejectOffer as rejectOfferRoutine,
  submitOffer as submitOfferRoutine,
  submitCounterOffer as submitCounterOfferRoutine,
  submitOfferForm as submitOfferFormRoutine,
  buyItNow as buyItNowRoutine,
  payOfferTransaction as payOfferTransactionRoutine,
} from 'spa/actions/OfferActions';
import { getTransactionById as getTransactionByIdRoutine } from 'spa/actions/TransactionActions';
import Errors from 'spa/constants/APIConstants';
import {
  auctionTokenSelector,
  auctionIdSelector,
  auctionsByIdSelector,
  offersByIdSelector,
} from 'spa/selectors/OfferSelectors';
import { custEmailSelector } from 'spa/selectors/CustomerSelectors';
import { transactionByIdSelector } from 'spa/selectors/TransactionSelectors';
import OfferConstants from 'spa/constants/OfferConstants';
import { logError } from 'spa/actions/ErrorLoggingActions';
import AuthenticationStore from '../../stores/AuthenticationStore';
import API from '../../api';
import { defaultApiExceptionMessage, errorWithRequestId } from '../../utils/error';
import { gettext } from '../../utils/filters';

export function* buyItNowSaga(action) {
  yield put(buyItNowRoutine.request(action.payload));
  const { auctionToken, auctionId } = action.payload;
  try {
    const checkoutResponse = yield call(API.auctionBuyItNow, auctionToken);
    yield put(
      buyItNowRoutine.success({
        auctionId,
        buyItNowUrl: checkoutResponse.landing_page,
        buyItNowToken: checkoutResponse.token,
      })
    );
  } catch (apiError) {
    const errorMessage = defaultApiExceptionMessage(
      apiError,
      gettext('Failed to open Escrow Pay.')
    );
    yield put(buyItNowRoutine.failure({ auctionId, errorMessage }));
  }
  yield put(buyItNowRoutine.fulfill());
}

export function* buyItNowCtaSaga(action) {
  const { auctionToken, auctionId } = action.payload;

  yield put(buyItNowRoutine.trigger({ auctionToken, auctionId }));
  const { apiRequestSuccess, apiRequestFailure } = yield race({
    apiRequestSuccess: take(buyItNowRoutine.SUCCESS),
    apiRequestFailure: take(buyItNowRoutine.FAILURE),
  });

  if (apiRequestSuccess) {
    const auctions = yield select(auctionsByIdSelector);
    const { buyItNowUrl } = auctions[auctionId];
    window.location.assign(buyItNowUrl);
  }

  if (apiRequestFailure) {
    yield put(logError(`Failed to create checkout page for auction ${auctionToken}`));
  }
}

export function* payOfferTransactionSaga(action) {
  const { transactionId } = action.payload;
  yield put(payOfferTransactionRoutine.request({ transactionId }));

  yield put(getTransactionByIdRoutine.trigger({ transId: transactionId }));
  const { getTransactionSuccess, getTransactionFailure } = yield race({
    getTransactionSuccess: take(getTransactionByIdRoutine.SUCCESS),
    getTransactionFailure: take(getTransactionByIdRoutine.FAILURE),
  });

  if (getTransactionSuccess) {
    const transaction = yield select(transactionByIdSelector, transactionId);
    const buyer = transaction.parties.filter((party) => party.role === 'buyer')[0];
    if (buyer.next_step) {
      yield put(payOfferTransactionRoutine.success({ transactionId }));
      window.location.assign(buyer.next_step);
    } else {
      const errorMessage = gettext('You have already paid.');
      yield put(payOfferTransactionRoutine.failure({ transactionId, errorMessage }));
    }
  }

  if (getTransactionFailure) {
    const errorMessage = defaultApiExceptionMessage(
      getTransactionFailure,
      gettext('Failed to get transaction information.')
    );
    yield put(payOfferTransactionRoutine.failure({ transactionId, errorMessage }));
  }

  yield put(payOfferTransactionRoutine.fulfill());
}

export function* acceptOfferSaga(action) {
  const { auctionId, offerId } = action.payload;
  yield put(acceptOfferRoutine.request(action.payload));
  try {
    const offer = yield call(API.applyActionToOffer, 'accept', auctionId, offerId);
    yield put(acceptOfferRoutine.success({ offer }));
  } catch (error) {
    yield put(logError('Failed to accept offer'));
    const errorMessage = defaultApiExceptionMessage(
      error,
      gettext('An error occurred when accepting the offer.')
    );
    yield put(acceptOfferRoutine.failure({ offerId, errorMessage }));
    throw error;
  }
  yield put(acceptOfferRoutine.fulfill());
}

export function* acceptOfferFromManagementSaga(action) {
  yield put(acceptOfferFromManagementRoutine.request(action.payload));
  yield put(acceptOfferRoutine.trigger(action.payload));
  const { acceptOfferSuccess } = yield race({
    acceptOfferSuccess: take(acceptOfferRoutine.SUCCESS),
  });
  if (acceptOfferSuccess) {
    const offers = yield select(offersByIdSelector, action.payload.offerId);
    const offer = offers[action.payload.offerId];
    const transactionId = offer.transactionId;
    yield put(
      getTransactionByIdRoutine.trigger({
        transId: transactionId,
      })
    );
    const { getTransactionSuccess } = yield race({
      getTransactionSuccess: take(getTransactionByIdRoutine.SUCCESS),
    });
    if (getTransactionSuccess) {
      const transaction = yield select(transactionByIdSelector, transactionId);
      const buyer = transaction.parties.filter((party) => party.role === 'buyer')[0];
      const redirectUrl = buyer.next_step;
      const custEmail = yield select(custEmailSelector);
      if (redirectUrl && buyer.customer === custEmail) {
        window.location.assign(redirectUrl);
      } else {
        window.location.assign(`${window.config.www_base_url}/transaction/${transactionId}`);
      }
    }
  }
  yield put(acceptOfferFromManagementRoutine.fulfill());
}

export function* rejectOfferSaga(action) {
  const { auctionId, offerId } = action.payload;
  yield put(rejectOfferRoutine.request(action.payload));
  try {
    const offer = yield call(API.applyActionToOffer, 'reject', auctionId, offerId);
    yield put(rejectOfferRoutine.success({ offer }));
  } catch (error) {
    yield put(logError('Failed to reject offer'));
    const errorMessage = defaultApiExceptionMessage(
      error,
      gettext('An error occurred when rejecting the offer.')
    );
    yield put(rejectOfferRoutine.failure({ offerId, errorMessage }));
    throw error;
  }
  yield put(rejectOfferRoutine.fulfill());
}

export function* cancelOfferSaga(action) {
  const { auctionId, offerId } = action.payload;
  yield put(cancelOfferRoutine.request(action.payload));
  try {
    const offer = yield call(API.applyActionToOffer, 'cancel', auctionId, offerId);
    yield put(cancelOfferRoutine.success({ offer }));
  } catch (error) {
    yield put(logError('Failed to cancel offer'));
    const errorMessage = defaultApiExceptionMessage(
      error,
      gettext('An error occurred when cancelling your offer.')
    );
    yield put(cancelOfferRoutine.failure({ offerId, errorMessage }));
    throw error;
  }
  yield put(cancelOfferRoutine.fulfill());
}

export function* runRecaptchaV3(action) {
  try {
    const token = yield call(grecaptcha.execute, window.config.recaptcha_site_key, { action });
    const verifyResponse = yield call(API.verifyRecaptchaToken, token);

    return verifyResponse;
  } catch (error) {
    // temporarily catch all errors while running this in the background
    yield put(logError(error));
  }
}

export function* submitOfferSaga(action) {
  const apiBody = action.payload.apiBody;
  const auctionToken = action.payload.auctionToken;
  const auctionId = action.payload.auctionId;
  yield put(submitOfferRoutine.request({ auctionId }));
  try {
    if (window.config.recaptcha_site_key) {
      yield call(runRecaptchaV3, 'make_offer');
    }
    const response = yield call(API.createOffer, auctionToken, apiBody);
    yield put(submitOfferRoutine.success({ auctionId, offer: response }));
  } catch (error) {
    let errorMessage;
    if (Errors.API_ERRORS.includes(error.type) && error.errorCode === 'EXISTING_AUCTION_BIDDER') {
      errorMessage = errorWithRequestId(
        error.requestId,
        gettext('User with this email address has already made an offer.')
      );
    }
    if (Errors.API_ERRORS.includes(error.type) && error.errorCode === 'AUCTION_CREATOR') {
      errorMessage = errorWithRequestId(
        error.requestId,
        gettext('User cannot make a new offer as the initiating party.')
      );
    }
    if (error.type === Errors.GONE) {
      errorMessage = 'Sorry! This auction has already ended.';
    }
    errorMessage =
      errorMessage ||
      defaultApiExceptionMessage(error, gettext('An error occurred when submitting the offer.'));
    yield put(submitOfferRoutine.failure({ auctionId, errorMessage }));
  }
}

export function* submitCounterOfferSaga(action) {
  const apiBody = action.payload.apiBody;
  const auctionId = action.payload.auctionId;
  yield put(submitCounterOfferRoutine.request({ auctionId }));
  try {
    const response = yield call(API.createCounterOffer, auctionId, apiBody);
    yield put(submitCounterOfferRoutine.success({ auctionId, offer: response }));
  } catch (error) {
    let errorMessage;
    if (Errors.API_ERRORS.includes(error.type) && error.errorCode === 'EXISTING_AUCTION_BIDDER') {
      errorMessage = errorWithRequestId(
        error.requestId,
        gettext('User with this email address has already made an offer.')
      );
    }
    errorMessage =
      errorMessage ||
      defaultApiExceptionMessage(error, gettext('An error occurred when submitting the offer.'));
    yield put(submitCounterOfferRoutine.failure({ auctionId, errorMessage }));
  }
}

export function* submitOfferFormSaga(action) {
  yield put(submitOfferFormRoutine.request());
  try {
    const formData = action.payload.values;
    const formProps = action.payload.props;
    const isCounterOffer = Boolean(formProps.transactionId);

    const auctionId = yield select(auctionIdSelector);
    const auctionToken = yield select(auctionTokenSelector);

    let payload = null;
    let routine = null;
    if (isCounterOffer) {
      payload = {
        auctionId,
        apiBody: {
          note: formData.message,
          no_fee_amount: formData.amount,
          transaction_id: formProps.transactionId,
        },
      };
      routine = submitCounterOfferRoutine;
    } else {
      const buyerEmail = yield select(custEmailSelector);
      payload = {
        auctionId,
        auctionToken,
        apiBody: {
          note: formData.message,
          no_fee_amount: formData.amount,
          buyer_email: formData.email || buyerEmail,
        },
      };

      if (formData.phoneCountry && formData.phoneNumber) {
        const prefix = PhoneCountryCodes[formData.phoneCountry].countryCode;
        payload.apiBody.phone_number = `+${prefix}${formData.phoneNumber}`;
      }
      routine = submitOfferRoutine;
    }

    yield put(routine.trigger(payload));
    const { apiRequestSuccess, apiRequestFailure } = yield race({
      apiRequestSuccess: take(routine.SUCCESS),
      apiRequestFailure: take(routine.FAILURE),
    });
    if (apiRequestFailure) {
      throw new Error(apiRequestFailure.payload.errorMessage);
    }

    yield put(submitOfferFormRoutine.success(apiRequestSuccess));
    yield put(changeView(OfferConstants.MAKE_OFFER_SUCCESS_VIEW));
  } catch (error) {
    const submissionError = new SubmissionError({
      _error: error.message || 'Failed to submit offer',
    });
    yield put(submitOfferFormRoutine.failure(submissionError));
  }
  yield put(submitOfferFormRoutine.fulfill());
}

export function* getAuctionSaga(action) {
  yield put(getAuctionRoutine.request());
  try {
    const auctionToken = action.payload;
    const auctionData = yield call(API.getAuctionByToken, auctionToken);
    yield put(getAuctionRoutine.success(auctionData));
  } catch (error) {
    yield put(logError('Failed to get auction.'));
    let errorMessage;
    if (error.type === Errors.NOT_FOUND) {
      errorMessage = errorWithRequestId(
        error.requestId,
        gettext('Offer data could not be found for this link.')
      );
    }
    if (error.type === Errors.GONE) {
      errorMessage = gettext('Sorry! This auction has already ended.');
    }
    yield put(getAuctionRoutine.failure(errorMessage || defaultApiExceptionMessage(error)));
  }
  yield put(getAuctionRoutine.fulfill());
}

export function* initialiseMakeAnOfferSaga(action) {
  yield put(initialiseMakeAnOfferRoutine.request());
  const auctionToken = action.payload;
  yield put(getAuctionRoutine.trigger(auctionToken));
  const { getAuctionSuccess, getAuctionFailure } = yield race({
    getAuctionSuccess: take(getAuctionRoutine.SUCCESS),
    getAuctionFailure: take(getAuctionRoutine.FAILURE),
  });

  if (getAuctionSuccess) {
    yield put(pushAuctionId(getAuctionSuccess.payload.id));
    yield put(initialiseMakeAnOfferRoutine.success());
  }
  if (getAuctionFailure) {
    yield put(initialiseMakeAnOfferRoutine.failure());
  }
  yield put(initialiseMakeAnOfferRoutine.fulfill());
}

export function* initialiseOfferManagementSaga(action) {
  yield put(initaliseOfferManagementRoutine.request());
  const { auctionToken, isMasked } = action.payload;
  yield put(getAuctionRoutine.trigger(auctionToken));
  const { getAuctionSuccess, getAuctionFailure } = yield race({
    getAuctionSuccess: take(getAuctionRoutine.SUCCESS),
    getAuctionFailure: take(getAuctionRoutine.FAILURE),
  });
  if (getAuctionSuccess) {
    yield put(pushAuctionId(getAuctionSuccess.payload.id));
    if (AuthenticationStore.isAuthenticated()) {
      // Will handle errors from this call in a future diff
      yield put(
        getAuctionOffersRoutine.trigger({
          auctionId: getAuctionSuccess.payload.id,
          isMasked,
        })
      );
      yield race([take(getAuctionOffersRoutine.SUCCESS), take(getAuctionOffersRoutine.FAILURE)]);
    }
    yield put(initaliseOfferManagementRoutine.success());
    yield put(initaliseOfferManagementRoutine.fulfill());
    return;
  }
  if (getAuctionFailure) {
    yield put(initaliseOfferManagementRoutine.failure());
    yield put(initaliseOfferManagementRoutine.fulfill());
  }
}

export function* getAuctionOffersSaga(action) {
  const auctionId = action.payload.auctionId;
  const isMasked = Boolean(action.payload.isMasked);
  yield put(getAuctionOffersRoutine.request({ auctionId }));
  let auctionData;
  try {
    auctionData = yield call(API.getAuctionOffers, auctionId, isMasked);
    for (const offer of auctionData.offers) {
      const transaction = yield call(API.getTransactionById, offer.transaction_id);
      if (transaction) offer.offerTransaction = transaction;
    }
  } catch (error) {
    yield put(logError('Failed to get offers'));
    let errorMessage;
    if (error.type === Errors.NOT_FOUND) {
      errorMessage = errorWithRequestId(
        error.requestId,
        gettext('Offer data could not be found for this link.')
      );
    } else if (error.type === Errors.FORBIDDEN) {
      errorMessage = gettext('NO_OFFERS');
    }
    yield put(
      getAuctionOffersRoutine.failure({
        auctionId: auctionId,
        errorMessage: errorMessage || defaultApiExceptionMessage(error),
      })
    );
    yield put(getAuctionOffersRoutine.fulfill());
    return;
  }
  yield put(getAuctionOffersRoutine.success({ auctionId, offers: auctionData.offers }));
  yield put(getAuctionOffersRoutine.fulfill());
}

export function* cancelAuctionSaga(action) {
  yield put(cancelAuctionRoutine.request());
  try {
    const auctionId = action.payload;
    yield call(API.cancelAuction, auctionId);
    yield put(cancelAuctionRoutine.success());
  } catch (error) {
    yield put(logError('Failed to cancel offer transaction'));
    yield put(cancelAuctionRoutine.failure());
  }
  yield put(cancelAuctionRoutine.fulfill());
}

function* offerWatcher() {
  yield takeLatest(OfferConstants.BUY_IT_NOW_CTA, buyItNowCtaSaga);
  yield takeLatest(buyItNowRoutine.TRIGGER, buyItNowSaga);
  yield takeLatest(payOfferTransactionRoutine.TRIGGER, payOfferTransactionSaga);
  yield takeLatest(getAuctionRoutine.TRIGGER, getAuctionSaga);
  yield takeLatest(acceptOfferRoutine.TRIGGER, acceptOfferSaga);
  yield takeLatest(rejectOfferRoutine.TRIGGER, rejectOfferSaga);
  yield takeLatest(cancelOfferRoutine.TRIGGER, cancelOfferSaga);
  yield takeLatest(submitOfferRoutine.TRIGGER, submitOfferSaga);
  yield takeLatest(cancelAuctionRoutine.TRIGGER, cancelAuctionSaga);
  yield takeLatest(getAuctionOffersRoutine.TRIGGER, getAuctionOffersSaga);
  yield takeLatest(acceptOfferFromManagementRoutine.TRIGGER, acceptOfferFromManagementSaga);
  yield takeLatest(submitCounterOfferRoutine.TRIGGER, submitCounterOfferSaga);
  yield takeLatest(submitOfferFormRoutine.TRIGGER, submitOfferFormSaga);
  yield takeLatest(initialiseMakeAnOfferRoutine.TRIGGER, initialiseMakeAnOfferSaga);
  yield takeLatest(initaliseOfferManagementRoutine.TRIGGER, initialiseOfferManagementSaga);
}

export default [offerWatcher];
