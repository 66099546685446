import React from 'react';
import { Field } from 'redux-form';

import { Checkbox } from 'spa/components/form';
import Icon from 'spa/components/Icon';
import InfoTooltip from './InfoTooltip';

class ServiceCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
    };
    this.toggleState = this.toggleState.bind(this);
  }

  toggleState() {
    this.setState((prevState) => ({
      isChecked: !prevState.isChecked,
    }));
  }

  render() {
    const { formName, title, price, description, iconPath, tooltipId, tooltip } = this.props;

    return (
      <div className={`serviceCard ${this.state.isChecked && 'is-active'}`}>
        <div className="serviceCard-action">
          <Field name={formName} component={Checkbox} onChange={this.toggleState} />
        </div>
        <div className="serviceCard-inner">
          <div className="serviceCard-content">
            <h4 className="serviceCard-title">
              <span className="serviceCard-title-inner">{title}</span>
              {tooltip && (
                <InfoTooltip id={tooltipId} message={tooltip} className="serviceCard-title-icon" />
              )}
              {price && <span className="serviceCard-price">{price}</span>}
            </h4>
            <p className="serviceCard-desc">{description}</p>
          </div>
          {iconPath && (
            <div className="serviceCard-icon">
              <Icon name={iconPath} className="serviceCard-icon-img" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ServiceCard;
