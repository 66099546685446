import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, formValues, Form, Field } from 'redux-form';
import { bindRoutineToReduxForm } from 'redux-saga-routines';
import A from 'spa/components/A';
import { submitOfferForm } from 'spa/actions/OfferActions';
import { FormError, ActionButton } from 'spa/components/form';
import { Input, PhoneSelect, Checkbox } from 'spa/components/form';
import { required, email, phone } from 'spa/components/form/validate';
import { currencies } from 'escrow-common-js/dist/constants';
import CreateOfferFieldset from './CreateOfferFieldset';

import { gettext } from '../../../utils/filters';

const phoneValidator = phone('phoneCountry');
const normalizePhone = (value) => {
  const re = /^[0-9]*$/;

  if (re.test(value)) {
    return value;
  }
};

const offerEmailValidator = (value, _, props) => {
  if (value === props.sellerEmail) {
    return gettext('Cannot make a new offer as the seller party.');
  } else if (value === props.brokerEmail) {
    return gettext('Cannot make a new offer as the initiating party.');
  }
};

const CreateOfferForm = ({
  form,
  role,
  error,
  submitting,
  handleSubmit,
  label,
  currency,
  isAuthenticated,
  acceptTerms,
  amount,
  initialValues,
}) => (
  <Form name={form} onSubmit={handleSubmit(bindRoutineToReduxForm(submitOfferForm))}>
    {error && <FormError error={error} />}
    {!isAuthenticated && (
      <Fragment>
        <Field
          name="email"
          label={gettext('Your email address')}
          component={Input}
          disabled={initialValues ? initialValues.email : false}
          validate={[required, email, offerEmailValidator]}
          requiredIndicator
        />
        <div className="field">
          <label className="field-label">{gettext('Your phone number (optional)')}</label>
          <div className="defaultForm-group">
            <Field
              name="phoneCountry"
              component={PhoneSelect}
              className="field--phoneSelect"
              placeholder={gettext('country')}
            />
            <Field
              name="phoneNumber"
              component={Input}
              validate={phoneValidator}
              normalize={normalizePhone}
            />
          </div>
        </div>
      </Fragment>
    )}
    <CreateOfferFieldset formName={form} label={label} currency={currency} role={role} />
    <div className="checkout-terms checkout-terms--offerTerms">
      <span className="checkout-terms-inner checkout-terms--checkbox">
        <Field
          name="acceptTerms"
          label={
            <div>
              {gettext('I agree to ')}
              <A
                link={{
                  type: 'internal',
                  route: '/legal',
                  newTab: true,
                }}
                className="checkout-terms-link"
              >
                {gettext('General Escrow Instructions')}
              </A>
              {', '}
              <A
                link={{
                  type: 'internal',
                  route: '/legal',
                  newTab: true,
                }}
                className="checkout-terms-link"
              >
                {gettext('Terms of Using the Escrow Platform ')}
              </A>
              {gettext('and')}
              <A
                link={{
                  type: 'internal',
                  route: '/legal',
                  newTab: true,
                }}
                className="checkout-terms-link"
              >
                {gettext(' Privacy Policy')}
              </A>
            </div>
          }
          component={Checkbox}
          disabled={submitting}
        />
      </span>
    </div>
    <ActionButton
      className="checkout-card-btn checkout-card-btn--auth btn btn--secondary"
      name="submitMakeOffer"
      type="submit"
      label={gettext('Submit offer')}
      loading={submitting}
      disabled={!acceptTerms}
      data-tracking-name="submit-offer"
      data-tracking-value={amount}
    />
  </Form>
);

CreateOfferForm.propTypes = {
  label: PropTypes.string,
  role: PropTypes.oneOf(['buyer', 'seller']).isRequired,
  currency: PropTypes.oneOf(Object.keys(currencies)),
  isAuthenticated: PropTypes.bool,
};
export default reduxForm({
  destroyOnUnmount: true,
})(
  formValues({
    acceptTerms: 'acceptTerms',
    amount: 'amount',
    email: 'email',
  })(CreateOfferForm)
);
