export default {
  DEFAULT_START_DATE: '2010-01-01',
  GET_TRANSACTIONS: 'PartnerConstants.GET_TRANSACTIONS',
  GET_CUSTOMERS: 'PartnerConstants.GET_CUSTOMERS',
  GET_FILTERED_TRANSACTIONS: 'PartnerConstants.GET_FILTERED_TRANSACTIONS',
  GET_FILTERED_CUSTOMERS: 'PartnerConstants.GET_FILTERED_CUSTOMERS',
  GET_TRANSACTION_STATISTICS: 'PartnerConstants.GET_TRANSACTION_STATISTICS',
  GET_CUSTOMER_STATISTICS: 'PartnerConstants.GET_CUSTOMER_STATISTICS',
  GET_TRANSACTION_PARTY_INFO: 'PartnerConstants.GET_TRANSACTION_PARTY_INFO',
  PUSH_TRANSACTION_COUNT_BY_INITIATION_DATE:
    'PartnerConstants.PUSH_TRANSACTION_COUNT_BY_INITIATION_DATE',
  PUSH_TRANSACTION_COUNT_BY_CLOSE_DATE: 'PartnerConstants.PUSH_TRANSACTION_COUNT_BY_CLOSE_DATE',
  PUSH_ONGOING_TRANSACTION_COUNT: 'PartnerConstants.PUSH_ONGOING_TRANSACTION_COUNT',
  PUSH_TRANSACTION_LIST_PAGE_TRANSACTION_COUNT:
    'PartnerConstants.PUSH_TRANSACTION_LIST_PAGE_TRANSACTION_COUNT',
  RESET_TRANSACTION_LIST_PAGE_TRANSACTION_COUNT:
    'PartnerConstants.RESET_TRANSACTION_LIST_PAGE_TRANSACTION_COUNT',
  PUSH_FILTERED_TRANSACTION_DATA: 'PartnerConstants.PUSH_FILTERED_TRANSACTION_DATA',
  PUSH_CUSTOMER_COUNT_BY_DATE_CREATED: 'PartnerConstants.PUSH_CUSTOMER_COUNT_BY_DATE_CREATED',
  PUSH_FILTERED_CUSTOMER_DATA: 'PartnerConstants.PUSH_FILTERED_CUSTOMER_DATA',
  PUSH_USER_LIST_PAGE_USERS_COUNT: 'PartnerConstants.PUSH_USER_LIST_PAGE_USERS_COUNT',
  RESET_USER_LIST_PAGE_USERS_COUNT: 'PartnerConstants.RESET_USER_LIST_PAGE_USERS_COUNT',
  TRANSACTION_STATISTICS_DATA_TYPE: {
    INITIATION_DATE: 'initiation_date',
    CLOSE_DATE: 'close_date',
  },
  CUSTOMER_STATISTICS_DATA_TYPE: {
    DATE_CREATED: 'date_created',
  },
  GET_CUSTOMER_PAYMENTS_IN: 'PartnerConstants.GET_CUSTOMER_PAYMENTS_IN',
  GET_CUSTOMER_PAYMENTS_OUT: 'PartnerConstants.GET_CUSTOMER_PAYMENTS_OUT',
  GET_REPORTS_LIST: 'PartnerConstants.GET_REPORTS_LIST',
  GET_REPORTS_COUNT: 'PartnerConstants.GET_REPORTS_COUNT',
  GET_PARTNER_LOGO_SETTINGS: 'PartnerConstants.GET_PARTNER_LOGO_SETTINGS',
  UPDATE_PARTNER_LOGO_SETTINGS: 'PartnerConstants.UPDATE_PARTNER_LOGO_SETTINGS',
  UPDATE_TRANSACTION_LIST_FILTER: 'PartnerConstants.UPDATE_TRANSACTION_LIST_FILTER',
  UPDATE_TRANSACTION_TABLE_FILTER: 'PartnerConstants.UPDATE_TRANSACTION_TABLE_FILTER',
  UPDATE_CUSTOMER_TABLE_FILTER: 'PartnerConstants.UPDATE_CUSTOMER_TABLE_FILTER',
  UPDATE_REPORTS_TABLE_FILTER: 'PartnerConstants.UPDATE_REPORTS_TABLE_FILTER',

  GET_TRANSACTION_HOLDING_PEN_CONDITIONS: 'PartnerConstants.GET_TRANSACTION_HOLDING_PEN_CONDITIONS',
};
