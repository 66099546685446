var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/icons/go.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_2 = "";t_2 += "<svg width=\"14\" height=\"14\" viewBox=\"0 0 14 14\" xmlns=\"http://www.w3.org/2000/svg\" class=\"icon\">\n    <g fill=\"none\" fill-rule=\"evenodd\">\n        <path d=\"M11.47 7.974l.03 3.464c0 1.14-.92 2.062-2.07 2.062H2.57C1.425 13.5.5 12.58.5 11.43V4.57c0-1.144.918-2.07 2.064-2.07l4.193.056\" stroke=\"#1B97F1\"/>\n    </g>\n    <g transform=\"translate(0 -1)\" fill=\"none\" fill-rule=\"evenodd\">\n        <path d=\"M8.5 1.5h4M12.5 1.5v4\" stroke=\"#1B97F1\" stroke-linecap=\"square\"/><use stroke=\"#08F\" mask=\"url(#b)\" stroke-width=\"2\" xlink:href=\"#a\"/><path d=\"M4.982 9.018l7.03-7.03\" stroke=\"#1B97F1\" stroke-linecap=\"square\"/>\n    </g>\n</svg>";
;
frame = frame.pop();
return new runtime.SafeString(t_2);
});
context.addExport("go");
context.setVariable("go", macro_t_1);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/icons/go.html"] , dependencies)