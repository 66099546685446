import { Dialog, DialogActions, DialogContent, DialogTitle, List, Typography } from '@mui/material';
import { EscrowAlert } from 'spa/features/ui/EscrowAlert';
import AlertTitle from '@mui/material/AlertTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { MediumText, SmallText } from 'spa/features/ui/EscrowTypography';
import { usePatchTransactionMutation } from '../../transactionApi';
import { transactionSelector } from '../../transactionSlice';
import { SelectItem } from '../select-item/SelectItem';
import { EscrowButton } from 'spa/features/ui/EscrowButton';

const DomainTransferMethodOptions = {
  username_password: 'username_password',
  authorization_code: 'authorization_code',
  push: 'push',
};

function formatCalendarDays(days) {
  return Number(days) === 1 ? '1 calendar day' : `${days} calendar days`;
}

/**
 * Button that opens the dialog for selecting domain transfer method.
 *
 * @param {(value: DomainTransferMethodOptions) => void} props.onConfirm
 */
export const SelectDomainTransferMethod = ({ transactionId }) => {
  const theme = useTheme();
  const isMobileViewport = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const transaction = useSelector(transactionSelector(transactionId));
  const [patchTransaction, { isLoading, error }] = usePatchTransactionMutation();
  if (!transaction) {
    return null;
  }

  const shipActionType = transaction.transactionStatus === 25 ? 'ship' : 'ship_return';

  const handleConfirm = async () => {
    if (!value) {
      return;
    }
    try {
      await patchTransaction({
        id: transaction.id,
        action: shipActionType,
        shipping_information: {
          authorization_type: value,
        },
      }).unwrap();
      setOpen(false);
    } catch (e) {
      let errorMsgTmp =
        "Your transfer method couldn't be set due to a technical issue. Please refresh the page and try again.";
      if (e.xRequestId) {
        errorMsgTmp += ` If the issue persists, contact support@escrow.com and provide the id: ${e.xRequestId}`;
      }
      setErrorMsg(errorMsgTmp);
    }
  };
  const handleDialogClose = () => {
    if (isLoading) {
      return; // Don't close the dialog while processing
    }
    setValue('');
    setOpen(false);
  };
  const handleBack = () => {
    setValue('');
    setOpen(false);
  };

  return (
    <React.Fragment>
      <EscrowButton variant="contained" onClick={() => setOpen(true)}>
        Select a transfer method
      </EscrowButton>
      <Dialog fullScreen={isMobileViewport} open={open} onClose={handleDialogClose} maxWidth="md">
        {isMobileViewport && (
          <IconButton
            sx={{ marginLeft: 'auto' }}
            onClick={handleDialogClose}
            aria-label="Close Domain Transfer Modal"
          >
            <CloseIcon sx={{ color: 'mono.dark', height: 24, width: 24 }} />
          </IconButton>
        )}
        {error && (
          <EscrowAlert>
            <AlertTitle>Unable to set domain transfer method</AlertTitle>
            {errorMsg}
          </EscrowAlert>
        )}
        <DialogTitle>
          <MediumText>Please select a domain name transfer method</MediumText>
        </DialogTitle>
        <DialogContent>
          {shipActionType === 'ship' ? (
            <SmallText>
              The buyer will have <strong>{formatCalendarDays(transaction.inspectionDays)}</strong>{' '}
              to inspect the domain name once received.
            </SmallText>
          ) : (
            <SmallText>
              The seller will have <strong>{formatCalendarDays(transaction.inspectionDays)}</strong>{' '}
              to inspect the returned domain name once received.
            </SmallText>
          )}
          <List>
            <SelectItem
              value={DomainTransferMethodOptions.username_password}
              label="Username and Password"
              checked={value === DomainTransferMethodOptions.username_password}
              onCheck={setValue}
            >
              <div>
                <SmallText>
                  <strong>Username and Password</strong>
                </SmallText>
                <SmallText>
                  The domain name will be placed into a sub-account and you must provide the buyer
                  with credentials in order to manage the transfer to their account.
                </SmallText>
              </div>
            </SelectItem>
            <SelectItem
              value={DomainTransferMethodOptions.authorization_code}
              label="Authorization Code"
              checked={value === DomainTransferMethodOptions.authorization_code}
              onCheck={setValue}
            >
              <div>
                <SmallText>
                  <strong>Authorization Code</strong>
                </SmallText>
                <SmallText>
                  The domain name will be transferred between accounts from different registrars.
                </SmallText>
              </div>
            </SelectItem>
            <SelectItem
              value={DomainTransferMethodOptions.push}
              label="Domain Push"
              checked={value === DomainTransferMethodOptions.push}
              onCheck={setValue}
            >
              <div>
                <SmallText>
                  <strong>Domain Push</strong>
                </SmallText>
                <SmallText>
                  The domain name will be transferred between accounts within the same registrar.
                </SmallText>
              </div>
            </SelectItem>
          </List>
        </DialogContent>
        <DialogActions>
          {!isMobileViewport && (
            <EscrowButton variant="text" onClick={handleBack}>
              Back
            </EscrowButton>
          )}
          <EscrowButton
            sx={{ width: isMobileViewport ? '100%' : 'auto' }}
            variant="contained"
            disabled={isLoading || !value}
            onClick={handleConfirm}
          >
            Continue
          </EscrowButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
