import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getWindow } from '../../../utils/globals';

const window = getWindow();

export const stripeApi = createApi({
  reducerPath: 'stripeApi',
  baseQuery: fetchBaseQuery({
    credentials: 'include',
  }),
  tagTypes: ['PaymentLink'],
  endpoints: (builder) => ({
    getStripePaymentLink: builder.query({
      query: (transactionId) => ({
        url: `${window.config.internal_endpoint}/stripe/${transactionId}/payment_link`,
      }),
      transformErrorResponse: (response, meta) => ({
        ...response,
        xRequestId: meta && meta.response ? meta.response.headers.get('x-request-id') : undefined,
      }),
      providesTags: ['PaymentLink'],
    }),
  }),
});

export const {
  useGetStripePaymentLinkQuery,
} = stripeApi;
