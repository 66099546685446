import { gettext } from '../../utils/filters';
import { Alpha2VatCountries, CountryToAlpha2 } from './ISOCountryCodes';
import { getNationalDocuments } from '../../utils/IdentificationDocuments';
import { FILE_VALIDATION_ERROR } from '../../utils/FormValidation';

export const INDIV_VERIFICATION_UPLOAD_OPTION_VALUE = 'upload-document';

export const ACH_CHECKOUT_FLOW = 'ACH_CHECKOUT_FLOW';

export const individualVerificationFieldOptions = (
  countryAlpha2,
  disableEKYC = false,
  disableKYC = false
) =>
  getNationalDocuments(countryAlpha2).reduce(
    (docs, doc) => [
      { label: gettext(doc.displayName), value: doc.name, disabled: disableEKYC },
      ...docs,
    ],
    disableKYC
      ? []
      : [{ label: gettext('Upload a Document'), value: INDIV_VERIFICATION_UPLOAD_OPTION_VALUE }]
  );

export const idTypeOptions = [
  { label: gettext('Passport'), value: 'passport' },
  { label: gettext("Driver's License"), value: 'drivers-license' },
  { label: gettext('National ID Card'), value: 'national-id-card' },
  { label: gettext('Tax ID'), value: 'tax-id' },
  { label: gettext('Proof of Age Card'), value: 'proof-of-age' },
  { label: gettext('Professional License ID'), value: 'professional-license' },
  { label: gettext('State ID'), value: 'state-id' },
  { label: gettext('Voter ID'), value: 'voter-id' },
  { label: gettext('Postal ID'), value: 'postal-id' },
  { label: gettext('Government-Issued Health Card'), value: 'government-health-card' },
  { label: gettext('Other Government-Issued ID'), value: 'government-other' },
];

export const idTypeOptionsPerCountry = {
  US: [
    { label: gettext('Passport'), value: 'passport' },
    { label: gettext("Driver's License"), value: 'drivers-license' },
    { label: gettext('National ID Card'), value: 'national-id-card' },
    { label: gettext('Tax ID'), value: 'tax-id' },
    { label: gettext('Proof of Age Card'), value: 'proof-of-age' },
    { label: gettext('Professional License ID'), value: 'professional-license' },
    { label: gettext('State ID'), value: 'state-id' },
    { label: gettext('Other Government-Issued ID'), value: 'government-other' },
  ],
  CA: [
    { label: gettext('Passport'), value: 'passport' },
    { label: gettext('Canadian Citizenship Card'), value: 'canadian-citizenship' },
    { label: gettext('Secure Certificate of Indian Status'), value: 'cert-indian-status' },
    { label: gettext('Canadian Permanent Resident Card'), value: 'canadian-permanent-resident' },
    { label: gettext("Driver's License"), value: 'drivers-license' },
    { label: gettext('Provincial or territorial identity cards'), value: 'provincial-territorial' },
    { label: gettext('Other Government-Issued ID'), value: 'government-other' },
  ],
  AU: [
    { label: gettext('Australian or foreign passport'), value: 'passport' },
    { label: gettext("Australian driver's license"), value: 'drivers-license' },
    {
      label: gettext('Proof of age card issued by an Australian State or Territory'),
      value: 'proof-of-age',
    },
    { label: gettext('Other Australian or foreign IDs'), value: 'government-other' },
  ],
};

export const frontOnlyIdTypes = ['passport'];

export const requiredProofOfAddressByCountry = {
  US: ['passport'],
  AU: ['passport'],
};

export const getIdTypeWithRequiredProofOfAddress = (countryAlpha2) =>
  countryAlpha2 && countryAlpha2 in requiredProofOfAddressByCountry
    ? requiredProofOfAddressByCountry[countryAlpha2]
    : [];

export const getIdTypeOptions = (countryAlpha2) =>
  countryAlpha2 && countryAlpha2 in idTypeOptionsPerCountry
    ? idTypeOptionsPerCountry[countryAlpha2]
    : idTypeOptions;

export const addressProofTypeOptions = [
  { label: gettext('Bank Statement'), value: 'bank-statement' },
  { label: gettext('Income Tax Return Form'), value: 'income-tax-return-form' },
  { label: gettext('Residence ID or Permit'), value: 'residence-id-or-permit' },
  { label: gettext('Notarized Lease Agreement'), value: 'notarized-lease-agreement' },
  { label: gettext('ID Card (different to card used in proof of identity)'), value: 'id-card' },
  { label: gettext('Electricity Bill'), value: 'electricity-bill' },
  { label: gettext('Water Bill'), value: 'water-bill' },
  { label: gettext('Internet Bill'), value: 'internet-bill' },
  { label: gettext('Cable Bill'), value: 'cable-bill' },
  { label: gettext('Landline Bill'), value: 'landline-bill' },
  { label: gettext('Mobile Phone Bill'), value: 'mobile-phone-bill' },
  { label: gettext('Other Utility Bill'), value: 'utility-bill' },
];

export const businessTypeOptions = [
  { label: gettext('Sole Proprietor'), value: 'sole-proprietor' },
  { label: gettext('General Partnership'), value: 'general-partnership' },
  { label: gettext('Limited Partnership'), value: 'limited-partnership' },
  { label: gettext('Limited Liability Partnership'), value: 'limited-liability-partnership' },
  { label: gettext('Limited Liability Company'), value: 'limited-liability-company' },
  { label: gettext('Corporation'), value: 'corporation' },
  {
    label: gettext('Social/Recreational Clubs, Unincorporated Associations and Civic Groups'),
    value: 'unincorporated-association',
  },
];

export const businessTypeOptionsAUS = [
  { label: gettext('Sole Proprietor'), value: 'sole-proprietor' },
  { label: gettext('Company - Domestic'), value: 'company-domestic' },
  { label: gettext('Company - Foreign Registered'), value: 'company-foreign-registered' },
  { label: gettext('Company - Foreign Unregistered'), value: 'company-foreign-unregistered' },
  { label: gettext('Trust'), value: 'trust' },
  { label: gettext('Partnership'), value: 'partnership' },
  { label: gettext('Association - Incorporated'), value: 'association-incorporated' },
  { label: gettext('Association - Unincorporated'), value: 'association-unincorporated' },
  { label: gettext('Registered Co-operatives'), value: 'registered-cooperatives' },
  { label: gettext('Government Bodies'), value: 'government-bodies' },
];

export const businessTypeAUSDocumentNotes = {
  'sole-proprietor': [
    gettext('Customer full name & date of birth'),
    gettext('Registered Business Name, Address and ABN'),
  ],
  'company-domestic': [
    gettext('Registered company name, address and ACN'),
    gettext('Companies address that represents the principle place of business (if any)'),
    gettext('Company category - Public or Proprietary'),
    gettext('If Proprietary, the name of each director of the company'),
  ],
  'company-foreign-registered': [
    gettext('Registered company name'),
    gettext('Registered Australian office address'),
    gettext(
      'Companies Australian address that represents the principle place of business (if any)'
    ),
    gettext('ARBN issued to the country'),
    gettext('Country the company was formed incorporated or registered'),
    gettext('Registered company category - Public or Proprietary'),
    gettext('If Proprietary, the name of each director of the company'),
  ],
  'company-foreign-unregistered': [
    gettext('Company name'),
    gettext('Country the company was formed'),
    gettext('Full address of the company in its country of formation'),
    gettext('Full address of the principal place of business and its country of formation'),
    gettext(
      'Any reference of relevant foreign registration and name of each director if a private company'
    ),
  ],
  trust: [
    gettext('Legal trust name'),
    gettext('Business name (if any) of the trustee in respect of the trust'),
    gettext('Type of trust'),
    gettext('Country the trust was established'),
    gettext('Name of the settlor of the trust'),
    gettext('Class or name of beneficiaries of the trust'),
  ],
  partnership: [
    gettext('Partnership name'),
    gettext('Registered partnership name'),
    gettext('Name and address of each partner or relevant professional association'),
  ],
  'association-incorporated': [
    gettext('Name of the association'),
    gettext(
      'Address of association principal place of administration or registered office or residential address of public officer / president, secretary or treasurer'
    ),
    gettext('Unique identifying number'),
    gettext(
      'Name of chairman, secretary, and treasurer, or equivalent officer on each case of the association'
    ),
  ],
  'association-unincorporated': [
    gettext('Name of the association'),
    gettext('Address of association principal place of administration'),
    gettext(
      'Name of chairman, secretary, and treasurer, or equivalent officer on each case of the association'
    ),
    gettext(
      'Information about the member required to be collected following the procedure for individuals set out above'
    ),
  ],
  'registered-cooperatives': [
    gettext('Name of the co-operative'),
    gettext(
      "Address of registered office or principal place of operations (if any) or the residential address of the co operative's secretary or president or treasurer"
    ),
    gettext('Unique identifying number'),
    gettext(
      'Name of chairman, secretary, and treasurer, or equivalent officer on each case of the co-operative'
    ),
  ],
  'government-bodies': [
    gettext('Name of the government body'),
    gettext('Address of government body  principal place of operations'),
    gettext(
      'If entity or emanation or is established under legislation of the Commonwealth and/or State, Territory or a foreign country and the name of the State, Territory or Country'
    ),
  ],
};

export const companyRelationshipOptions = [
  { label: gettext('Owner'), value: 'owner' },
  { label: gettext('Director'), value: 'director' },
  { label: gettext('Officer'), value: 'officer' },
  { label: gettext('Authorized Representative'), value: 'representative' },
];

export const representativeExtraProof = {
  representative: [{ label: gettext('Authorization Letter'), value: 'authorization-letter' }],
  default: [],
};

export const companyProofTypeOptions = {
  'sole-proprietor': [
    { label: gettext('Fictitious Name Certificate or Statement'), value: 'fictitious-certificate' },
    { label: gettext('Certificate of Assumed Name'), value: 'certificate-assumed-name' },
    { label: gettext('Business License'), value: 'business-license' },
    { label: gettext('Registration of Trade Name'), value: 'registration-of-trade-name' },
  ],
  'general-partnership': [
    { label: gettext('Partnership Agreemeent'), value: 'partnership-agreement' },
    { label: gettext('Fictitious Name Certificate or Statement'), value: 'fictitious-certificate' },
    { label: gettext('Certificate of Assumed Name'), value: 'certificate-assumed-name' },
    { label: gettext('Business License'), value: 'business-license' },
  ],
  'limited-partnership': [
    {
      label: gettext('Certificate of Limited Partnership'),
      value: 'certificate-limited-partnership',
    },
    { label: gettext('Limited Partnership Document'), value: 'limited-partnership-document' },
  ],
  'limited-liability-partnership': [
    { label: gettext('Statement of Qualification'), value: 'statement-of-qualification' },
    { label: gettext('Limited Partnership Document'), value: 'limited-partnership-document' },
    {
      label: gettext('Limited Liability Partnership Election'),
      value: 'limited-liability-partnership-election',
    },
  ],
  'limited-liability-company': [
    { label: gettext('Articles of Organization'), value: 'articles-of-organization' },
    { label: gettext('Certificate of Organization'), value: 'certificate-of-organization' },
    { label: gettext('Certificate of Formation'), value: 'certificate-of-formation' },
  ],
  corporation: [
    { label: gettext('Articles of Incorporation'), value: 'articles-of-incorporation' },
    { label: gettext('Certificate of Good Standing'), value: 'certificate-good-standing' },
  ],
  'unincorporated-association': [
    { label: gettext('Business License'), value: 'business-license' },
    { label: gettext('Fictitious Name Certificate or Statement'), value: 'fictitious-certificate' },
    { label: gettext('Certificate of Assumed Name'), value: 'certificate-assumed-name' },
    {
      label: gettext('Organization/Association Articles or Bylaws'),
      value: 'organization-articles',
    },
    { label: gettext('Certificate of Good Standing'), value: 'certificate-good-standing' },
    { label: gettext('Certificate of Formation'), value: 'certificate-of-formation' },
    { label: gettext('Certificate of Organization'), value: 'certificate-of-organization' },
    { label: gettext('Statement of Organization'), value: 'statement-of-organization' },
    { label: gettext('Certificate of Registration'), value: 'certificate-of-registration' },
  ],
  default: [
    { label: gettext('Fictitious Name Certificate or Statement'), value: 'fictitious-certificate' },
    { label: gettext('Certificate of Assumed Name'), value: 'certificate-assumed-name' },
    { label: gettext('Business License'), value: 'business-license' },
    { label: gettext('Registration of Trade Name'), value: 'registration-of-trade-name' },
  ],
};

export const companyProofTypeOptionsAUS = {
  'sole-proprietor': [
    { label: gettext('Passport'), value: 'passport' },
    { label: gettext('Drivers Licence'), value: 'drivers-license' },
    { label: gettext('Utility Bill'), value: 'utility-bill' },
    {
      label: gettext('Other - document containing Business Name and ABN Number'),
      value: 'other-id-company-business-name-abn',
    },
  ],
  'company-domestic': [
    { label: gettext('Company Constitution'), value: 'company-constitution' },
    {
      label: gettext('ASIC Company Extract (Current comspany information)'),
      value: 'asic-company-extract',
    },
  ],
  'company-foreign-registered': [
    { label: gettext('Company Constitution'), value: 'company-constitution' },
    {
      label: gettext('ASIC Company Extract (Current company information)'),
      value: 'asic-company-extract',
    },
  ],
  'company-foreign-unregistered': [
    { label: gettext('Articles of Organization'), value: 'articles-of-organization' },
    { label: gettext('Certificate of Organization'), value: 'certificate-of-organization' },
    { label: gettext('Certificate of Formation'), value: 'certificate-of-formation' },
    { label: gettext('Articles of Incorporation'), value: 'articles-of-incorporation' },
    { label: gettext('Certificate of Good Standing'), value: 'certificate-good-standing' },
  ],
  trust: [
    { label: gettext('Trust Deed'), value: 'trust-deed' },
    {
      label: gettext('Business Registration Certificate'),
      value: 'business-registration-certificate',
    },
  ],
  partnership: [
    { label: gettext('Partnership Agreemeent'), value: 'partnership-agreement' },
    { label: gettext('Fictitious Name Certificate or Statement'), value: 'fictitious-certificate' },
    { label: gettext('Certificate of Assumed Name'), value: 'certificate-assumed-name' },
    {
      label: gettext('Certificate of Limited Partnership'),
      value: 'certificate-limited-partnership',
    },
    { label: gettext('Limited Partnership Document'), value: 'limited-partnership-document' },
  ],
  'association-incorporated': [
    { label: gettext('Articles of Incorporation'), value: 'articles-of-incorporation' },
    { label: gettext('Certificate of Good Standing'), value: 'certificate-good-standing' },
    {
      label: gettext('Constitution of the Incorporated Association'),
      value: 'constitution-of-incorporated-association',
    },
    {
      label: gettext('Published Annual Report'),
      value: 'published-annual-report',
    },
  ],
  'association-unincorporated': [
    { label: gettext('Articles of Organization'), value: 'articles-of-organization' },
    { label: gettext('Certificate of Organization'), value: 'certificate-of-organization' },
    { label: gettext('Certificate of Formation'), value: 'certificate-of-formation' },
    {
      label: gettext('Rules of Unincorporated Association'),
      value: 'rules-unincorporated-association',
    },
  ],
  'registered-cooperatives': [
    {
      label: gettext('Business Registration Certificate'),
      value: 'business-registration-certificate',
    },
    { label: gettext('Certificate of Assumed Name'), value: 'certificate-assumed-name' },
    {
      label: gettext('Organization/Association Articles or Bylaws'),
      value: 'organization-articles',
    },
    { label: gettext('Certificate of Good Standing'), value: 'certificate-good-standing' },
    { label: gettext('Certificate of Formation'), value: 'certificate-of-formation' },
    { label: gettext('Certificate of Organization'), value: 'certificate-of-organization' },
    { label: gettext('Statement of Organization'), value: 'statement-of-organization' },
    { label: gettext('Certificate of Registration'), value: 'certificate-of-registration' },
  ],
  'government-bodies': [
    { label: gettext('Letter from the Government body'), value: 'letter-government-body' },
  ],
  default: [
    { label: gettext('Fictitious Name Certificate or Statement'), value: 'fictitious-certificate' },
    { label: gettext('Certificate of Assumed Name'), value: 'certificate-assumed-name' },
    { label: gettext('Business License'), value: 'business-license' },
    { label: gettext('Registration of Trade Name'), value: 'registration-of-trade-name' },
  ],
};

export const messagesByAlpha3Country = {
  AUS: {
    CUSTOMER_VERIFICATION_DESCRIPTION: gettext(
      "All customers are required to verify their accounts in order to transact on Escrow.com. We protect both sides of the transaction by verifying the identity of all users. The verified identity must be the same as the beneficial account holder's name on any bank account used to pay or receive funds from Escrow.com."
    ),
    PERSONAL_VERIFICATION_DESCRIPTION: gettext(
      'Verify your identity to process the transaction as well as allow the option to pay with credit card, debit card or PayPal for transactions under $5,000.'
    ),
    PERSONAL_VERIFICATION_NOTE: gettext(
      'Required Documents: Government issued ID, Proof of address'
    ),
    COMPANY_VERIFICATION_DESCRIPTION: gettext(
      'Verify your company to allow payments to and from your company bank accounts. Personal Details verification is required prior to Company Details verification.'
    ),
    COMPANY_VERIFICATION_NOTE: gettext(
      'Required Documents: Government issued ID, Proof of address, Company registration documents'
    ),
  },
  default: {
    CUSTOMER_VERIFICATION_DESCRIPTION: gettext(
      "All customers are required to verify their accounts in order to transact on Escrow.com. We protect both sides of the transaction by verifying the identity of all users. The verified identity must be the same as the beneficial account holder's name on any bank account used to pay or receive funds from Escrow.com."
    ),
    PERSONAL_VERIFICATION_DESCRIPTION: gettext(
      'Verify your identity to allow payment over $3,000 as well as the option to pay with credit card, debit card or PayPal for transactions under $5,000.'
    ),
    PERSONAL_VERIFICATION_NOTE: gettext(
      'Required Documents: Government issued ID, Proof of address'
    ),
    COMPANY_VERIFICATION_DESCRIPTION: gettext(
      'Verify your company to allow payments to and from your company bank accounts. Personal Details verification is required prior to Company Details verification.'
    ),
    COMPANY_VERIFICATION_NOTE: gettext(
      'Required Documents: Government issued ID, Proof of address, Company registration documents'
    ),
  },
};

export const apiMapping = {
  companyName: 'CompanyName',
  firstName: 'FirstName',
  middleName: 'MiddleName',
  lastName: 'LastName',
  localFName: 'LocalFName',
  localMName: 'LocalMName',
  localLName: 'LocalLName',
  companyCity: 'CompanyAddress.City',
  companyState: 'CompanyAddress.State',
  companyAddressLine1: 'CompanyAddress.Street1',
  companyAddressLine2: 'CompanyAddress.Street2',
  companyPostCode: 'CompanyAddress.Zip',
  city: 'PersonalAddress.City',
  state: 'PersonalAddress.State',
  addressLine1: 'PersonalAddress.Street1',
  addressLine2: 'PersonalAddress.Street2',
  postCode: 'PersonalAddress.Zip',
  // Following fields require preprocessing
  dateOfBirth: 'DateOfBirth',
  phone: 'Phone',
  displayName: 'DisplayName',
  email: 'Email',
  displayNameType: 'DisplayNameType',
  issueCheckInCompanyName: 'IssueCheckInCompanyName',
  shippingAddress: 'ShippingAddress',
  useUsernameAsDisplayName: 'UseUsernameAsDisplayName',
  username: 'Username',
  isCompany: 'IsCompany',
  companyCountry: 'CompanyAddress.Country',
  country: 'PersonalAddress.Country',
  vatDetails: 'VatDetails',
};

export const kycV2ApiMapping = {
  'first-name': 'FirstName',
  'middle-name': 'MiddleName',
  'last-name': 'LastName',
  'date-of-birth': 'DateOfBirth',
  'local-fname': 'LocalFName',
  'local-lname': 'LocalLName',
  address: 'PersonalAddress.Street1',
  city: 'PersonalAddress.City',
  state: 'PersonalAddress.State',
  'post-code': 'PersonalAddress.Zip',
  country: 'PersonalAddress.Country',
  // Company details
  'company-name': 'CompanyName',
  'company-address': 'CompanyAddress.Street1',
  'company-city': 'CompanyAddress.City',
  'company-state': 'CompanyAddress.State',
  'company-post-code': 'CompanyAddress.Zip',
  'company-country': 'CompanyAddress.Country',
  // Following fields are not included on the form
  phone: 'Phone',
  email: 'Email',
  'display-name': 'DisplayName',
  'display-name-type': 'DisplayNameType',
  'issue-check-in-company-name': 'IssueCheckInCompanyName',
  'shipping-address': 'ShippingAddress',
  'use-username-as-display-name': 'UseUsernameAsDisplayName',
  'is-company': 'IsCompany',
  vatDetails: 'VatDetails',
  'address-line-2': 'PersonalAddress.Street2',
  'company-address-line-2': 'CompanyAddress.Street2',
};

export const kycV3ApiMapping = {
  // TODO: Add all mappings here including IndividualDetails and CompanyDetails
};

export const apiVerificationStatuses = {
  VERIFIED: 'verified',
  NOT_VERIFIED: 'not_verified',
  PENDING: 'pending',
  REJECTED: 'rejected',
};

export const CountriesWithLocalNames = {
  [CountryToAlpha2.China]: 'in simplified chinese',
  [CountryToAlpha2.Japan]: 'in Kanji or Katakana',
  [CountryToAlpha2['Korea, Republic of']]: 'in Hangul',
  [CountryToAlpha2['Russian Federation']]: 'in Cyrillic',
  [CountryToAlpha2.Thailand]: 'in Thai',
  [CountryToAlpha2.Ukraine]: 'in Cyrillic',
};

export const KYCAlpha2VatCountries = [...Alpha2VatCountries, 'EL'];

export const VerificationTierRequirement = {
  NOTHING: 'NOTHING',
  CUSTOMER_DETAILS: 'CUSTOMER_DETAILS',
  PERSONAL_VERIFICATION: 'PERSONAL_VERIFICATION',
  COMPANY_VERIFICATION: 'COMPANY_VERIFICATION',
  ACCOUNT_TYPE: 'ACCOUNT_TYPE',
};

export const KYCPages = {
  VERIFICATION_DETAILS: 'VERIFICATION_DETAILS',
  INDIVIDUAL_DETAILS_FORM: 'INDIVIDUAL_DETAILS_FORM',
  INDIVIDUAL_VERIFICATION_FORM: 'INDIVIDUAL_VERIFICATION_FORM',
  COMPANY_DETAILS_FORM: 'COMPANY_DETAILS_FORM',
  COMPANY_VERIFICATION_FORM: 'COMPANY_VERIFICATION_FORM',
  EXTENDED_INDIVIDUAL_VERIFICATION_FORM: 'EXTENDED_INDIVIDUAL_VERIFICATION_FORM',
};

export const V3KYCDesktopPages = {
  SELECTION: 'SELECTION',
  MOBILE_TRANSFER: 'MOBILE_TRANSFER',
  PHONE_NUMBER: 'PHONE_NUMBER',
  SMS_CODE: 'SMS_CODE',
  V2_KYC: 'V2_KYC',
};

export const V3KYCMobilePages = {
  ACCOUNT_SETUP: 'ACCOUNT_SETUP',

  PHONE_NUMBER: 'PHONE_NUMBER',
  SMS_CODE: 'SMS_CODE',
  SMS_CODE_SUCCESS: 'SMS_CODE_SUCCESS',

  ACCOUNT_TYPE: 'ACCOUNT_TYPE',
  INDIVIDUAL_DETAILS: 'INDIVIDUAL_DETAILS',
  COMPANY_DETAILS: 'COMPANY_DETAILS',

  ID_SELECTION: 'ID_SELECTION',
  ID_FRONT_INSTRUCTIONS: 'ID_FRONT_INSTRUCTIONS',
  ID_FRONT_CAPTURE: 'ID_FRONT_CAPTURE',
  ID_FRONT_CAPTURE_REVIEW: 'ID_FRONT_CAPTURE_REVIEW',
  ID_BACK_INSTRUCTIONS: 'ID_BACK_INSTRUCTIONS',
  ID_BACK_CAPTURE: 'ID_BACK_CAPTURE',
  ID_BACK_CAPTURE_REVIEW: 'ID_BACK_CAPTURE_REVIEW',
  ID_FRONT_UPLOAD_SUCCESS: 'ID_FRONT_UPLOAD_SUCCESS',
  ID_BACK_UPLOAD_SUCCESS: 'ID_BACK_UPLOAD_SUCCESS',

  POA_SELECTION: 'POA_SELECTION',
  POA_INSTRUCTIONS: 'POA_INSTRUCTIONS',
  POA_CAPTURE: 'POA_CAPTURE',
  POA_CAPTURE_REVIEW: 'POA_CAPTURE_REVIEW',
  POA_UPLOAD_SUCCESS: 'POA_UPLOAD_SUCCESS',
  POA_FILE_REVIEW: 'POA_FILE_REVIEW',

  SELFIE_INSTRUCTIONS: 'SELFIE_INSTRUCTIONS',
  SELFIE_CAPTURE: 'SELFIE_CAPTURE',
  SELFIE_CAPTURE_REVIEW: 'SELFIE_CAPTURE_REVIEW',
  SELFIE_UPLOAD_SUCCESS: 'SELFIE_UPLOAD_SUCCESS',

  VERIFICATION_SUCCESS: 'VERIFICATION_SUCCESS',

  COMPANY_DOCUMENT_SELECTION: 'COMPANY_DOCUMENT_SELECTION',
  COMPANY_CAPTURE: 'COMPANY_CAPTURE',
  COMPANY_CAPTURE_REVIEW: 'COMPANY_CAPTURE_REVIEW',
  COMPANY_UPLOAD_SUCCESS: 'COMPANY_UPLOAD_SUCCESS',
  COMPANY_FILE_REVIEW: 'COMPANY_FILE_REVIEW',

  COMPANY_VERIFICATION_SUCCESS: 'COMPANY_VERIFICATION_SUCCESS',
};

export const KYCErrorAlertMessages = {
  FILE_UPLOAD: {
    title: 'File upload failed',
    message: 'Please check if your file is of an accepted format and under the size limit.',
  },
  FILE_UPLOAD_TYPE: {
    title: 'File upload failed',
    message:
      'The file format is unsupported. Please upload a file in any of the supported extensions: jpg, png, gif, tiff, bmp, pdf.',
  },
  FILE_UPLOAD_SIZE: {
    title: 'File upload failed',
    message:
      'The file size exceeded the allowed limit. Please upload a smaller file and try again.',
  },
  FILE_REQUIRED: {
    title: 'File required',
    message: 'Please check if you have uploaded all required files',
  },
  SUBMISSION_FAILED: {
    title: 'Submission failed',
    message: 'This form was not successfully submitted, please try again.',
  },
  DOCUMENT_REJECTED: {
    title: 'Your document has been rejected',
    message: 'Please upload a valid one and ensure that they meet the upload criteria.',
  },
  UNEXPECTED: {
    message: 'An unexpected error occured.',
  },
  DISABLED_EKYC: {
    message:
      'You may finish your account verification by uploading a document, or you may opt to submit later.',
  },
  CUST_REFRESH_FAILED: {
    message: 'Failed to refresh customer information, please try refreshing the page.',
  },
};

export const FILE_VALIDATION_ERROR_TO_KYC_MESSAGE = {
  [FILE_VALIDATION_ERROR.TOO_LARGE]: KYCErrorAlertMessages.FILE_UPLOAD_SIZE,
  [FILE_VALIDATION_ERROR.INVALID_TYPE]: KYCErrorAlertMessages.FILE_UPLOAD_TYPE,
};

export const ERROR_TYPES = {
  FILE_UPLOAD_FAILED: 'FILE_UPLOAD',
  PHOTO_UPLOAD_FAILED: 'PHOTO_UPLOAD_FAILED',
  SUBMISSION_FAILED: 'SUBMISSION_FAILED',
};

export const KYC_FILES = {
  ID_FRONT: 'front',
  ID_BACK: 'back',
  ADDRESS: 'address',
  SELFIE: 'selfie',
  COMPANY_DOCUMENT: 'company_document',
};

export const KYC_BUTTONS = {
  ResendSMS: 'Resend SMS',
  SendLink: 'Send Link',
  RetakePhoto: 'Retake photo',
  SubmitPhoto: 'Submit photo',
  SubmitFile: 'Submit file',
  TryAgain: 'Try again',
  SubmitVerification: 'Submit verification',
  UploadFile: 'Upload a file',
  ReplaceFile: 'Replace file',
};

export const KYC_TITLES = {
  ID_FRONT: 'Is the front of your ID clear?',
  ID_BACK: 'Is the back of your ID clear?',
  PASSPORT: 'Is your passport clear?',
  POA: 'Is your proof of address clear?',
  SELFIE: 'Is your selfie clear?',
  COMPANY_DOCUMENT: 'Is the document clear?',
  UPLOADED_FILE: 'Is this the correct file?',
  SUBMIT_VERIFICATION: 'Submit verification',
  UPLOAD_ERROR: 'Upload failed',
};

export const KYC_SUBTITLES = {
  ID_FRONT: 'Review the photo and make sure the information is clear before you submit.',
  ID_BACK: 'Review the photo and make sure the information is clear before you submit.',
  PASSPORT: 'Review the photo and make sure the information is clear before you submit.',
  POA: 'Review the photo and make sure the information is clear before you submit.',
  SELFIE: 'Review the photo and make sure your face is clear before you submit.',
  COMPANY_DOCUMENT:
    'Review the photo and make sure that the information is clear before you submit.',
  UPLOADED_FILE: 'Review the uploaded file and make sure it is correct before you submit.',
  SUBMIT_VERIFICATION: 'Please submit your verification',
  UPLOAD_ERROR: 'The is error uploading the file. Please try again.',
};

export const KYC_UPLOADING_TEXT = {
  passport: 'Uploading your passport',
  front: 'Uploading the front of your ID',
  back: 'Uploading the back of your ID',
  address: 'Uploading your proof of address',
  selfie: 'Uploading your selfie',
  company_document: 'Uploading your company document',
  default: 'Uploading your file',
};

export const KYC_PAGE_STATES = {
  INITIAL: 'initial',
  LOADING: 'loading',
  ERROR: 'error',
  SUCCESS: 'success',
};

// should be eventually replaced by the above object
export const ID_FRONT = 'front';
export const ID_BACK = 'back';
export const ID_ADDRESS = 'address';
export const ID_EXTENDED = 'extended';
export const COMPANY_DOCUMENT = 'company_document';

export const horizontalDocTypes = ['passport'];

export const CAMERA_INSTRUCTIONS = {
  ID: 'Align your ID within the frame',
  PASSPORT: 'Align your passport within the frame',
  DOCUMENT: 'Align your document within the frame',
  SELFIE: 'Align your face within the frame',
};

export default {
  idTypeOptions,
  addressProofTypeOptions,
  businessTypeOptions,
  companyRelationshipOptions,
  representativeExtraProof,
  companyProofTypeOptions,
  apiVerificationStatuses,
  INDIV_VERIFICATION_UPLOAD_OPTION_VALUE,

  CountriesWithLocalNames,

  ACCOUNT_TYPE_INDIVIDUAL: 'individual',
  ACCOUNT_TYPE_COMPANY: 'company',

  PERSONAL_VERIFICATION_FORM: 'personal-verification-form',
  COMPANY_VERIFICATION_FORM: 'company-verification-form',
  EXTENDED_VERIFICATION_FORM: 'extended-verification-form',
  ELECTRONIC_VERIFICATION_FORM: 'electronic-verification-form',
  ACCOUNT_TYPE_FORM: 'account-type-form',
  CUSTOMER_DETAILS_FORM: 'customer-details-form',
  COMPANY_DETAILS_FORM: 'company-details-form',

  SUBMIT_PERSONAL_VERIFICATION_FORM: 'VerificationConstants.SUBMIT_PERSONAL_VERIFICATION_FORM',
  SUBMIT_ELECTRONIC_VERIFICATION_FORM: 'VerificationConstants.SUBMIT_ELECTRONIC_VERIFICATION_FORM',
  SUBMIT_COMPANY_VERIFICATION_FORM: 'VerificationConstants.SUBMIT_COMPANY_VERIFICATION_FORM',
  SUBMIT_CUSTOMER_DETAILS_FORM: 'VerificationConstants.SUBMIT_CUSTOMER_DETAILS_FORM',

  PARTNER_KYC_SUCCESS: 'VerificationConstants.PARTNER_KYC_SUCCESS',

  KYC_GOTO_NEXT_PAGE: 'VerificationConstants.KYC_GOTO_NEXT_PAGE',
  KYC_GOTO_PREV_PAGE: 'VerificationConstants.KYC_GOTO_PREV_PAGE',

  KYC_SET_SUBHEADER: 'VerificationConstants.KYC_SET_SUBHEADER',
  KYC_SET_ERROR_ALERT: 'VerificationConstants.KYC_SET_ERROR_ALERT',
  KYC_CLEAR_ERROR_ALERT: 'VerificationConstants.KYC_CLEAR_ERROR_ALERT',
  KYC_SUBMIT_CUSTOMER_DETAILS: 'VerificationConstants.KYC_SUBMIT_CUSTOMER_DETAILS',
  KYC_SUBMIT_INDIVIDUAL: 'VerificationConstants.KYC_SUBMIT_INDIVIDUAL',
  KYC_SUBMIT_COMPANY: 'VerificationConstants.KYC_SUBMIT_COMPANY',
  KYC_UPLOAD_FILE: 'VerificationConstants.KYC_UPLOAD_FILE',

  ID_FRONT,
  ID_BACK,
  ID_ADDRESS,

  KYC_SET_ISCOMPANY: 'VerificationConstants.KYC_SET_ISCOMPANY',
  KYC_SUBMIT_EXTENDED: 'VerificationConstants.KYC_SUBMIT_EXTENDED',
  KYC_CLEAR_FILE_UPLOADS: 'VerificationConstants.KYC_CLEAR_FILE_UPLOADS',
  KYC_ADD_INIT_PAGES: 'VerificationConstants.KYC_ADD_INIT_PAGES',
  KYC_LOAD_INIT_PAGES: 'VerificationConstants.KYC_LOAD_INIT_PAGES',
  KYC_DEQUEUE_INIT_PAGES: 'KYC_DEQUEUE_INIT_PAGES',
  KYC_CHECK_DOCUMENTS_SUBMITTED: 'KYC_CHECK_DOCUMENTS_SUBMITTED',
};
