import React from 'react';
import PropTypes from 'prop-types';

const CardHeader = ({ heading }) => (
  <div className="card-header card-header--small card-inner">
    <h2 className="card-header-title">{heading}</h2>
  </div>
);

CardHeader.propTypes = {
  heading: PropTypes.node,
};

CardHeader.defaultProps = {
  heading: '',
};

export default CardHeader;
