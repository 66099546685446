import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import {
  customerEmailVerificationStatusSelector,
  CustomerV4GetStatusSelector,
} from 'spa/selectors/CustomerSelectors';
import EmailVerificationRequiredContainer from './EmailVerificatonContainer';

const EmailVerificationRequired = (props) => {
  const { route, isEmailVerified } = props;

  if (isEmailVerified) {
    return <div>{renderRoutes(route.routes)}</div>;
  }
  return (
    <div className="twoFactorAuth section section--small section--dark">
      <div className="twoFactorAuth-content section-container">
        <div className="twoFactorAuth-inner">
          <div className="twoFactorAuth-card">
            <EmailVerificationRequiredContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

EmailVerificationRequired.propTypes = {
  route: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isEmailVerified:
    CustomerV4GetStatusSelector(state) && customerEmailVerificationStatusSelector(state),
});

export default withRouter(connect(mapStateToProps)(EmailVerificationRequired));
