var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["icons/close.html"] = require( "icons/close.html" );
dependencies["icons/chevron-right.html"] = require( "icons/chevron-right.html" );
dependencies["icons/arrow-right.html"] = require( "icons/arrow-right.html" );
dependencies["components/escrowLogo-v2.html"] = require( "components/escrowLogo-v2.html" );




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/navigation/integrationsLanding/sidebarLoggedIn.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("icons/close.html", false, "escrow_www/templates/navigation/integrationsLanding/sidebarLoggedIn.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(context.getVariables(), frame, function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(t_1.hasOwnProperty("close")) {
var t_4 = t_1.close;
} else {
cb(new Error("cannot import 'close'")); return;
}
context.setVariable("closeIcon", t_4);
env.getTemplate("icons/chevron-right.html", false, "escrow_www/templates/navigation/integrationsLanding/sidebarLoggedIn.html", false, function(t_6,t_5) {
if(t_6) { cb(t_6); return; }
t_5.getExported(context.getVariables(), frame, function(t_7,t_5) {
if(t_7) { cb(t_7); return; }
if(t_5.hasOwnProperty("chevronRight")) {
var t_8 = t_5.chevronRight;
} else {
cb(new Error("cannot import 'chevronRight'")); return;
}
context.setVariable("chevronRightIcon", t_8);
env.getTemplate("icons/arrow-right.html", false, "escrow_www/templates/navigation/integrationsLanding/sidebarLoggedIn.html", false, function(t_10,t_9) {
if(t_10) { cb(t_10); return; }
t_9.getExported(context.getVariables(), frame, function(t_11,t_9) {
if(t_11) { cb(t_11); return; }
if(t_9.hasOwnProperty("arrowRight")) {
var t_12 = t_9.arrowRight;
} else {
cb(new Error("cannot import 'arrowRight'")); return;
}
context.setVariable("arrowRightIcon", t_12);
output += "<aside class=\"sidebar\" data-target=\"sidebar\">\n    <header class=\"sidebar-header\">\n        <button data-sidebar-toggle class=\"sidebar-close\">";
output += runtime.suppressValue((lineno = 6, colno = 68, runtime.callWrap(t_4, "closeIcon", context, [runtime.makeKeywordArgs({"specifier": "sidebar-close-icon"})])), env.opts.autoescape);
output += "</button>\n        <a href=\"";
output += runtime.suppressValue((lineno = 7, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".integrations"])), env.opts.autoescape);
output += "\" class=\"sidebar-logo logo logo--dark\" title=\"";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_13 = "";t_13 += "Escrow.com Integrations";
cb(null, t_13);
;
return t_13;
}
), true && env.opts.autoescape);
output += "\">\n            ";
env.getTemplate("components/escrowLogo-v2.html", false, "escrow_www/templates/navigation/integrationsLanding/sidebarLoggedIn.html", null, function(t_16,t_14) {
if(t_16) { cb(t_16); return; }
t_14.render(context.getVariables(), frame, function(t_17,t_15) {
if(t_17) { cb(t_17); return; }
output += t_15
output += "\n        </a>\n    </header>\n    <div class=\"sidebar-content\">\n        <div class=\"sidebar-faction\">\n            <div class=\"sidebar-item\">\n                <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "config")),"ESCROW_LOGGEDIN_LANDING_PAGE"), env.opts.autoescape);
output += "?hid=mt\" class=\"sidebar-tab\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_18 = "";t_18 += "My Transactions";
cb(null, t_18);
;
return t_18;
}
), true && env.opts.autoescape);
output += "</a>\n            </div>\n            <div class=\"sidebar-item\">\n                <a href=\"";
output += runtime.suppressValue((lineno = 17, colno = 33, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".integrations_home"])), env.opts.autoescape);
output += "\" class=\"sidebar-tab\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_19 = "";t_19 += "My Integrations";
cb(null, t_19);
;
return t_19;
}
), true && env.opts.autoescape);
output += "</a>\n            </div>\n            <div class=\"sidebar-item\">\n                <a href=\"";
output += runtime.suppressValue((lineno = 20, colno = 33, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".api_guide_getting_started"])), env.opts.autoescape);
output += "\" class=\"sidebar-tab\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_20 = "";t_20 += "Documentation";
cb(null, t_20);
;
return t_20;
}
), true && env.opts.autoescape);
output += "</a>\n            </div>\n            ";
if(!runtime.contextOrFrameLookup(context, frame, "isKycCompleted")) {
output += "\n            <div class=\"sidebar-item\">\n                <a href=\"";
output += runtime.suppressValue((lineno = 24, colno = 33, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".verify"])), env.opts.autoescape);
output += "\" class=\"sidebar-tab\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_21 = "";t_21 += "Get Verified";
cb(null, t_21);
;
return t_21;
}
), true && env.opts.autoescape);
output += "</a>\n            </div>\n            ";
;
}
output += "\n            <div class=\"sidebar-item\">\n                <a href=\"";
output += runtime.suppressValue((lineno = 28, colno = 33, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".helpdesk"])), env.opts.autoescape);
output += "\" class=\"sidebar-tab\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_22 = "";t_22 += "Help";
cb(null, t_22);
;
return t_22;
}
), true && env.opts.autoescape);
output += "</a>\n            </div>\n            <div class=\"sidebar-item\">\n                <a href=\"";
output += runtime.suppressValue((lineno = 31, colno = 33, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".contact_us"])), env.opts.autoescape);
output += "\" class=\"sidebar-tab\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_23 = "";t_23 += "Contact Us";
cb(null, t_23);
;
return t_23;
}
), true && env.opts.autoescape);
output += "</a>\n            </div>\n        </div>\n        <div class=\"sidebar-faction\">\n            <ul class=\"sidebar-list\">\n                <li class=\"sidebar-item\">\n                    <a href=\"";
output += runtime.suppressValue((lineno = 37, colno = 37, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".account-info"])), env.opts.autoescape);
output += "\" class=\"sidebar-tab\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_24 = "";t_24 += "My Profile";
cb(null, t_24);
;
return t_24;
}
), true && env.opts.autoescape);
output += "</a>\n                </li>\n                <li class=\"sidebar-item\">\n                    <a href=\"#\" data-target=\"logout\" class=\"sidebar-tab\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_25 = "";t_25 += "Log Out";
cb(null, t_25);
;
return t_25;
}
), true && env.opts.autoescape);
output += "</a>\n                </li>\n            </ul>\n        </div>\n    </div>\n</aside>\n\n<div class=\"sidebar-backdrop\" data-sidebar-toggle></div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/navigation/integrationsLanding/sidebarLoggedIn.html"] , dependencies)