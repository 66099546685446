import React, { useRef, useEffect, createRef, Fragment, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Stack, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useVerifySMSCodeMutation, useSendSMSCodeMutation } from 'spa/features/kyc/kycApi';
import { V3KYCMobilePages as Pages } from 'spa/constants/VerificationConstants';
import { kycPhoneNumberSelector } from 'spa/features/kyc/kycSlice';
import { SmallText } from 'spa/features/ui/EscrowTypography';
import { required } from 'spa/features/kyc/utils';
import { ERROR_TYPES } from 'spa/constants/VerificationConstants';
import MobilePage from '../../components/MobilePage';
import KYCAlert from '../../components/KYCAlert';
import { FieldError } from '../../components/FieldError';

const SMSCodePage = () => {
  const theme = useTheme();

  const [
    verifySMSCode,
    { isLoading: isLoadingVerify, error: verifyError, isSuccess: isSuccessVerify },
  ] = useVerifySMSCodeMutation();
  const [sendSMSCode, { isLoading: isLoadingSend, error: sendError, isSuccess: isSuccessSend }] =
    useSendSMSCodeMutation();
  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
  } = useForm();

  const isLoading = isLoadingVerify || isLoadingSend;
  const isSuccess = isSuccessVerify && isSuccessSend;
  const submissionError = verifyError || sendError;

  const phoneNumber = useSelector((state) => kycPhoneNumberSelector(state));

  const onSubmitCode = useCallback(
    (data) => {
      const smsCode = Object.values(data).join('');
      // eslint-disable-next-line no-console
      console.log('smsCode', smsCode);
      // eslint-disable-next-line no-console
      console.log(`phoneNumber`, phoneNumber);
      if (isValid) {
        verifySMSCode({ phoneNumber, smsCode });
      }
    },
    [isValid, phoneNumber, verifySMSCode]
  );

  const onResendCode = () => {
    if (phoneNumber) {
      sendSMSCode(phoneNumber);
    }
  };

  const smsCodeRefs = useRef(Array.from({ length: 6 }, () => createRef()));

  useEffect(() => {
    smsCodeRefs.current[0].current.focus();
  }, []);

  const focusOnNextField = ({ target }) => {
    const index = parseInt(target.id, 10);
    if (index < smsCodeRefs.current.length - 1 && target.value.length === target.maxLength) {
      const nextField = smsCodeRefs.current[index + 1].current;
      nextField.focus();
    }
  };

  const focusOnPrevField = ({ target }) => {
    const index = parseInt(target.id, 10);
    if (index > 0 && target.value.length === 0) {
      const prevField = smsCodeRefs.current[index - 1].current;
      prevField.focus();
    }
  };

  const hasErrors = Object.keys(errors).length > 0;

  return (
    <MobilePage
      title="Enter the 6-digit code"
      subtitle={
        <Fragment>
          {'We have sent a verification code to '}
          <span style={{ fontWeight: 'bold' }}>{phoneNumber}</span>.
        </Fragment>
      }
      ctaText="Verify code"
      onSubmit={handleSubmit((data) => {
        if (isValid) {
          onSubmitCode(data);
        }
      })}
      nextPage={Pages.SMS_CODE_SUCCESS}
      dynamicSubtitleFontSize="3.3vw"
      disableButtons={isLoading}
      nextPageTrigger={isSuccess}
    >
      <form>
        <Stack direction="column">
          {submissionError && (
            <KYCAlert
              sx={{ marginBottom: '16px' }}
              body="You have entered an invalid code. Please try again."
              isError={(verifyError && verifyError.status !== 403) || sendError}
              errorType={ERROR_TYPES.SUBMISSION_FAILED}
              xRequestId={submissionError.xRequestId}
            />
          )}
          <Stack spacing={1} direction="row">
            {smsCodeRefs.current.map((_, index) => (
              <Controller
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                name={`smsCode${index}`}
                control={control}
                defaultValue=""
                rules={{ validate: { required } }}
                render={({ field: { onChange, value } }) => (
                  <Fragment>
                    <TextField
                      sx={{ width: '16%' }}
                      id={`${index}`}
                      inputRef={smsCodeRefs.current[index]}
                      value={value}
                      onChange={(e) => {
                        if (/[0-9]/.test(e.target.value) || e.target.value.length === 0) {
                          focusOnNextField(e);
                          onChange(e);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Backspace') {
                          focusOnPrevField(e);
                        }
                      }}
                      InputProps={{
                        inputProps: {
                          style: { textAlign: 'center' },
                          maxLength: 1,
                        },
                      }}
                      error={hasErrors}
                      disabled={isLoading}
                    />
                  </Fragment>
                )}
              />
            ))}
          </Stack>
          <FieldError
            error={hasErrors}
            sx={{ marginLeft: '14px', marginTop: '3px' }}
            text={hasErrors ? Object.values(errors)[0].message : null}
          />
          <Stack direction="row" justifyContent="space-between" sx={{ marginTop: '16px' }}>
            <Button variant="text" sx={{ margin: '0px', padding: '0px' }}>
              <SmallText>
                <span style={{ textDecorationLine: 'underline' }}> {"Didn't receive a code?"}</span>
              </SmallText>
            </Button>
            <Button variant="text" sx={{ margin: '0px', padding: '0px' }} onClick={onResendCode}>
              <SmallText sx={{ color: theme.palette.secondaryLight.main }}>Resend code</SmallText>
            </Button>
          </Stack>
        </Stack>
      </form>
    </MobilePage>
  );
};

export default SMSCodePage;
