import { createRoutine } from 'redux-saga-routines';
import TrackingConstants from '../../constants/TrackingConstants';

export function trackEscrowUserAction({
  name = '',
  section = '',
  subsection = '',
  label = '',
  action = '',
  value = '',
} = {}) {
  return {
    type: TrackingConstants.TRACK_ESCROW_USER_ACTION,
    payload: {
      event: 'escrow_user_action',
      name,
      section,
      subsection,
      label,
      action,
      value,
    },
  };
}

export const getAbTest = createRoutine(TrackingConstants.GET_AB_TEST);
export const trackFormSubmissionByName = createRoutine(
  TrackingConstants.TRACK_FORM_SUBMISSION_BY_NAME
);
export const trackFormSubmissionFailByName = createRoutine(
  TrackingConstants.TRACK_FORM_SUBMISSION_FAIL_BY_NAME
);
