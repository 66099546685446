import classNames from 'classnames';
import React, { Fragment } from 'react';

import Icon from 'spa/components/Icon';
import { gettext } from '../../../utils/filters';
import { getWindow } from '../../../utils/globals';

class UserControl extends React.Component {
  constructor(props) {
    super(props);
    this.history = props.history;
    this.state = { dropdownVisible: false };
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.hideDropdown = this.hideDropdown.bind(this);
    this.logout = this.logout.bind(this);
  }

  getAvatarLetter() {
    const { custFullName, custEmail } = this.props;

    if (custFullName) return custFullName.charAt(0);
    if (custEmail) return custEmail.charAt(0).toUpperCase();
    return '?';
  }

  toggleDropdown(event) {
    event.stopPropagation();
    if (this.state.dropdownVisible) {
      this.hideDropdown();
    } else {
      this.setState({ dropdownVisible: true }, () => {
        document.addEventListener('click', this.hideDropdown);
      });
    }
  }

  hideDropdown(event) {
    if (event && this.dropdownMenu.contains(event.target)) {
      return;
    }
    this.setState({ dropdownVisible: false }, () => {
      document.removeEventListener('click', this.hideDropdown);
    });
  }

  logout() {
    // Need to do this because window is undefined inside the render function
    const window = getWindow();
    window.location = `${window.config.my_escrow_base_url}/myescrow/logout.asp`;
  }

  renderDropdownOptions() {
    const { kycActionRequired } = this.props;

    return (
      <nav className="headerV3-dropdown-nav">
        <ul className="headerV3-dropdown-nav-list">
          {kycActionRequired && (
            <li className="headerV3-dropdown-nav-item">
              <a href="/verify" className="headerV3-dropdown-nav-link">
                <div>
                  <div className="headerV3-dropdown-nav-verify">
                    <Icon name="ui-warning" className="headerV3-dropdown-nav-warning" />
                    {gettext('Verify My Account')}
                  </div>
                  <span className="headerV3-dropdown-nav-desc">
                    {gettext('Accounts must be verified to make or receive payments')}
                  </span>
                </div>
                <Icon name="nav-arrow" className="headerV3-dropdown-nav-arrow" />
              </a>
            </li>
          )}
          <li className="headerV3-dropdown-nav-item">
            <a href="/account-info" className="headerV3-dropdown-nav-link">
              {gettext('My Profile')}
              <Icon name="nav-arrow" className="headerV3-dropdown-nav-arrow" />
            </a>
          </li>
          <li className="headerV3-dropdown-nav-item">
            <a
              className="headerV3-dropdown-nav-link"
              onClick={this.logout}
              role="button"
              tabIndex={0}
            >
              {gettext('Sign Out')}
            </a>
          </li>
        </ul>
      </nav>
    );
  }

  renderDropdownCard() {
    const { custFullName, custCompanyName, custEmail } = this.props;
    const { dropdownVisible } = this.state;

    return (
      <div
        className={classNames('headerV3-dropdown', 'card', { 'is-active': dropdownVisible })}
        ref={(element) => {
          this.dropdownMenu = element;
        }}
      >
        <div className="headerV3-dropdown-content">
          <div className="headerV3-dropdown-content-icon">
            <span className="headerV3-dropdown-avatar avatar avatar--large avatar--light">
              {this.getAvatarLetter()}
            </span>
          </div>
          <div className="headerV3-dropdown-content-copy">
            <span className="headerV3-dropdown-companyName">{gettext(custCompanyName)}</span>
            <span className="headerV3-dropdown-userName">{gettext(custFullName)}</span>
            <span className="headerV3-dropdown-userEmail">{gettext(custEmail)}</span>
          </div>
        </div>
        {this.renderDropdownOptions()}
      </div>
    );
  }

  render() {
    const { dropdownVisible } = this.state;

    return (
      <Fragment>
        <li className="headerV3-nav-item">
          <a className="headerV3-inner media--available@tablet" href="/account-info">
            <span className="headerV3-avatar avatar">{this.getAvatarLetter()}</span>
          </a>
        </li>
        <li className="headerV3-nav-item media--hidden@tablet">
          <span
            className="headerV3-userControl"
            role="button"
            tabIndex={0}
            onClick={this.toggleDropdown}
          >
            <span className="headerV3-avatar avatar">{this.getAvatarLetter()}</span>
            <Icon
              name="nav-arrow"
              className={classNames('headerV3-nav-arrow', {
                'is-active': dropdownVisible,
              })}
            />
          </span>
        </li>
        <li className="headerV3-nav-item media--hidden@tablet">{this.renderDropdownCard()}</li>
      </Fragment>
    );
  }
}

export default UserControl;
