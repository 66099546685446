import React from 'react';
import Cleave from 'cleave.js/react';
import CreditCardLogo from 'spa/components/CreditCardLogo';
import InfoTooltip from './InfoTooltip';
import FieldError from './FieldError';

const CreditCardNumberInput = ({
  input,
  meta: { error, warning, touched },
  label,
  requiredIndicator,
  tooltip,
  className,
  cardType,
  onCreditCardTypeChanged,
  onCreditCardChange,
  ...attributes
}) => (
  <div className={`${error && touched ? 'field is-invalid' : 'field'} ${className}`}>
    {label && (
      <label htmlFor={input.name} className="field-label">
        {label}
        {requiredIndicator && <span className="field-required">*</span>}
        {tooltip && <InfoTooltip id={`${input.name}-field-input-tooltip`} message={tooltip} />}
      </label>
    )}
    <div className="field-input">
      <div className="field-prefix">
        <CreditCardLogo type={cardType} size="small" />
      </div>
      <Cleave
        {...input}
        id={input.name}
        className="defaultInput"
        {...attributes}
        onChange={onCreditCardChange}
        options={{ creditCard: true, onCreditCardTypeChanged }}
      />
    </div>
    {error && touched && <FieldError message={error} />}
    {warning && touched && <FieldError message={warning} />}
  </div>
);

export default CreditCardNumberInput;
