import React from 'react';
import { connect } from 'react-redux';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import KYCCheckoutContainer from 'spa/containers/Checkout/V2/KYCCheckoutContainer';
import {
  checkoutPaymentTypeSelector,
  checkoutTransIdSelector,
} from 'spa/selectors/CheckoutSelectors';
import { customerEKycDisabledSelector } from 'spa/selectors/CustomerSelectors';
import { transactionByIdSelector } from 'spa/selectors/TransactionSelectors';
import { KYCPages } from 'spa/constants/VerificationConstants.js';
import { gettext } from '../../../../utils/filters.js';

class CustomerDetailsStepContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: KYCPages.INDIVIDUAL_DETAILS_FORM,
    };
    this._renderPage = this._renderPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.updateStepPercentage = this.updateStepPercentage.bind(this);
  }

  updateStepPercentage(nextStep) {
    const orderedKycPages = [
      KYCPages.INDIVIDUAL_DETAILS_FORM,
      KYCPages.COMPANY_DETAILS_FORM,
      KYCPages.INDIVIDUAL_VERIFICATION_FORM,
      KYCPages.EXTENDED_INDIVIDUAL_VERIFICATION_FORM,
      KYCPages.COMPANY_VERIFICATION_FORM,
    ];
    const pageIndex = orderedKycPages.findIndex((val) => val === nextStep);
    if (!pageIndex) return;
    const percent = pageIndex / orderedKycPages.length;
    this.props.setStepPercentage(percent);
  }

  nextPage(nextKYCPage) {
    const { paymentMethodUnavailable } = this.props;
    let nextPage = nextKYCPage;
    switch (nextKYCPage) {
      case KYCPages.VERIFICATION_DETAILS:
        this.props.nextStep();
        break;
      default:
    }
    if (paymentMethodUnavailable) {
      nextPage = 'payment_method_unavailable';
    }
    if (nextPage !== this.state.page) {
      this.updateStepPercentage(nextPage);
      this.setState({ page: nextPage });
    }
  }

  _renderPaymentMethodUnavailable() {
    const header = 'Payment Method Not Available';
    const subheader =
      'Unfortunately, the selected payment method is not available for your country. Please select a different payment method to continue with this transaction.';
    return (
      <div className="checkout-card-placeholder">
        <img
          alt="Payment Method Unavailable Error"
          src="../../../../build/images/escrow-pay/error.png"
        />
        <h3 className="checkout-page-header">{gettext(header)}</h3>
        <p className="checkout-page-subheader">{gettext(subheader)}</p>
        <button
          className="checkout-card-btn checkout-card-btn--contained btn btn--secondary"
          onClick={this.props.prevStep}
        >
          {gettext('Change Payment Method')}
        </button>
      </div>
    );
  }

  _renderPage() {
    switch (this.state.page) {
      case KYCPages.INDIVIDUAL_DETAILS_FORM:
      case KYCPages.INDIVIDUAL_VERIFICATION_FORM:
      case KYCPages.COMPANY_DETAILS_FORM:
      case KYCPages.COMPANY_VERIFICATION_FORM:
      case KYCPages.EXTENDED_INDIVIDUAL_VERIFICATION_FORM:
      case KYCPages.VERIFICATION_DETAILS:
        return (
          <KYCCheckoutContainer
            onNextPage={this.nextPage}
            onEkycSubmission={this.props.showEkycSuccessAnimation}
          />
        );
      case 'payment_method_unavailable':
        return this._renderPaymentMethodUnavailable();
      default:
        return null;
    }
  }

  render() {
    return (
      <SwitchTransition>
        <CSSTransition classNames="checkout-step" key={this.state.page}>
          <div
            className="checkout-page"
            data-tracking-section={`${this.props.dataTrackingPrefix}-step-escrow`}
          >
            {this._renderPage()}
          </div>
        </CSSTransition>
      </SwitchTransition>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const paymentType = checkoutPaymentTypeSelector(state);

  const transId = checkoutTransIdSelector(state);
  const paymentMethods = (transactionByIdSelector(state, transId) || {}).paymentMethods || {};
  const availablePaymentMethods = [
    ...(paymentMethods.available_payment_methods || []),
    ...(paymentMethods.conditionally_available_payment_methods || []),
  ];
  const availablePaymentTypes = availablePaymentMethods.map((paymentMethod) => paymentMethod.type);

  return {
    ...ownProps,
    showEkycSuccessAnimation: () => ownProps.toggleEKYCSuccessScreen(true),
    paymentMethodUnavailable: !availablePaymentTypes.includes(paymentType),
    ekycDisabled: customerEKycDisabledSelector(state),
  };
};

export default connect(mapStateToProps)(CustomerDetailsStepContainer);
