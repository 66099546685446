import React from 'react';

import ACHDisbursementDetailsContainer from 'spa/containers/DisbursementDetails/ACH';

const ACHDisbursementDetails = () => (
  <div>
    <ACHDisbursementDetailsContainer />
  </div>
);

export default ACHDisbursementDetails;
