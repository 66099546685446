import React, { useState, useMemo } from 'react';
import { V3KYCMobilePages as Pages, KYC_FILES } from 'spa/constants/VerificationConstants';
import CameraPage from '../camera/CameraPage';
import MobilePage from '../../components/MobilePage';

const IDCapturePage = ({ back }) => {
  const [triggerNextPage, setTriggerNextPage] = useState(null);

  const nextPage = useMemo(
    () => (back ? Pages.ID_BACK_CAPTURE_REVIEW : Pages.ID_FRONT_CAPTURE_REVIEW),
    [back]
  );

  return (
    <MobilePage nextPageTrigger={triggerNextPage} nextPage={nextPage}>
      <CameraPage
        fileKey={back ? KYC_FILES.ID_BACK : KYC_FILES.ID_FRONT}
        setTriggerNextPage={setTriggerNextPage}
      />
    </MobilePage>
  );
};

export default IDCapturePage;
