import React from 'react';
import { Button } from 'escrow-common-js/dist/components';
import { getCurrencyString } from 'escrow-common-js/dist/utils';
import PaymentConstants from 'spa/constants/PaymentConstants';
import { Spinner } from 'spa/components/Indicators';
import Icon from 'spa/components/Icon';
import { gettext } from '../../../../utils/filters';

import { getWindow } from '../../../../utils/globals';

const window = getWindow();

class WirePaymentReviewPanel extends React.Component {
  constructor(props) {
    super(props);

    this.onMarkAsPaid = this.onMarkAsPaid.bind(this);
    this.onTransferLater = this.onTransferLater.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.confirmPaymentMethodSuccess && this.props.confirmPaymentMethodSuccess) {
      this.props.nextPage(PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER);
    }
  }

  onMarkAsPaid() {
    this.props.confirmPaymentMethod();
  }

  onTransferLater() {
    window.location.assign(this.props.landingUrl);
  }

  render() {
    const { wireDetails, currency, transactionTotal, confirmPaymentMethodLoading } = this.props;
    return (
      <div className="checkout-confirmation-container">
        {wireDetails ? (
          <WireTransferInstructions
            wireDetails={wireDetails}
            transactionTotal={transactionTotal}
            currency={currency}
          />
        ) : (
          <div className="checkout-confirmation-header">
            <Spinner />
          </div>
        )}
        <div className="checkout-confirmation-wireTransfer-footer">
          <p className="checkout-confirmation-wireTransfer-message">
            {`For your convenience, you can `}
            <a
              type="button"
              target="_blank"
              download="Escrow - Wire Transfer Instructions.pdf"
              href={`${window.config.www_base_url}/transaction/${this.props.transaction.id}/wire-instructions`}
              data-e2e-target="wire-download-instructions"
            >
              download the instructions
            </a>
            {` as a PDF document.`}
          </p>
          <div className="checkout-page-form-actions checkout-page-form-actions--full-width">
            <a
              tabIndex={0}
              role="button"
              className={`checkout-page-form-action checkout-page-form-action--full-width checkout-page-form-action--wide checkout-page-form-action--secondary ${
                confirmPaymentMethodLoading ? `is-disabled` : ``
              }`}
              onClick={() => {
                if (!confirmPaymentMethodLoading) this.onTransferLater();
              }}
              data-e2e-target="checkout-form-action"
              data-tracking-name="wire-transfer-later"
            >
              {gettext('Complete the transfer later')}
            </a>
            <Button
              className="checkout-page-form-action checkout-page-form-action--full-width checkout-page-form-action--wide checkout-page-form-action--strong"
              onClick={this.onMarkAsPaid}
              loading={confirmPaymentMethodLoading}
              disabled={confirmPaymentMethodLoading}
              data-e2e-target="checkout-form-action"
              data-tracking-name="wire-payment-sent"
            >
              {gettext(`Mark as Paid`)}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const WireTransferInstructions = ({ wireDetails, transactionTotal, currency }) => {
  const detailsList = [];
  PaymentConstants.WIRE_FIELD_ORDER.map((field) => {
    const fieldList = Object.keys(wireDetails);
    if (fieldList.indexOf(field) > -1) {
      if (
        field !== 'bank_account_number' ||
        wireDetails.bank_account_number !== wireDetails.credit_account_number
      ) {
        detailsList.push({
          name: PaymentConstants.apiWireNameMapping[field],
          value: wireDetails[field],
          key: field,
        });
      }
    }
  });

  return (
    <div className="checkout-card checkout-card--light checkout-confirmation-wireTransfer">
      <div className="checkout-card-content">
        <div className="checkout-confirmation-wireTransfer-banner">
          <Icon name="ui-info" className="checkout-confirmation-wireTransfer-banner-icon" />
          <div className="checkout-confirmation-wireTransfer-banner-text">
            If you are using an online payment platform like Wise, we require a wire receipt and a
            banking statement to verify your funds. Visit our FAQs for more details on{' '}
            <a
              type="button"
              href={`${window.config.www_base_url}/support/faqs/online-payments-platform`}
              target="_blank"
            >
              Funding Transactions from Online Payment Platforms
            </a>
            .
          </div>
        </div>
        <div className="checkout-confirmation-wireTransfer-header">
          <h3 className="checkout-confirmation-wireTransfer-header-text">
            Please transfer{' '}
            <span className="checkout-confirmation-wireTransfer-amt">
              {getCurrencyString(transactionTotal, currency)}
            </span>{' '}
            to the following account
          </h3>
        </div>
        <div className="checkout-confirmation-wireTransfer-info">
          {detailsList.map((field) => (
            <div key={field.key} className="checkout-confirmation-wireTransfer-info-item">
              <h4 className="checkout-confirmation-wireTransfer-info-item-title">
                {`${field.name.toUpperCase()}:`}
              </h4>
              <span className="checkout-confirmation-wireTransfer-info-item-content">
                {field.value}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WirePaymentReviewPanel;
