import { put, takeEvery, call } from 'redux-saga/effects';

import Task from 'spa/constants/TaskConstants';
import { updateTaskSuccess, updateTaskFailure, updateTaskFulfilled } from 'spa/actions/TaskActions';

import API from '../../api';

export function* updateTask(action) {
  try {
    const result = yield call(API.updateTask, action);

    yield put(updateTaskSuccess(result));
  } catch (err) {
    yield put(updateTaskFailure(err.message));
  }
  yield put(updateTaskFulfilled(action.name, action.taskList));
}

export function* taskWatcher() {
  yield takeEvery(Task.UPDATE_TASK_REQUEST, updateTask);
}

export default [taskWatcher];
