var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/navigation/v2/expandableSearch.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<form class=\"expandableSearch\" data-target=\"searchOverlay-input\">\n  <label class=\"expandableSearch-label\" for=\"expandable-search\" data-target=\"field\">\n    ";
output += runtime.suppressValue((lineno = 2, colno = 15, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "searchIcon"), "searchIcon", context, [runtime.makeKeywordArgs({"specifier": "expandableSearch-icon"})])), env.opts.autoescape);
output += "\n  </label>\n  <input type=\"text\" class=\"expandableSearch-input\" id=\"expandable-search\" data-header-search placeholder=\"Search for Escrow.com\" data-component=\"search-searchBox\">\n  <button class=\"expandableSearch-closeBtn\" tabindex=\"-1\" aria-label=\"";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_1 = "";t_1 += "Close search";
cb(null, t_1);
;
return t_1;
}
), true && env.opts.autoescape);
output += "\">\n    ";
output += runtime.suppressValue((lineno = 6, colno = 14, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "closeIcon"), "closeIcon", context, [runtime.makeKeywordArgs({"specifier": "expandableSearch-closeIcon"})])), env.opts.autoescape);
output += "\n  </button>\n  <div class=\"expandableSearch-result\" data-component=\"search-searchResults\">\n    <div class=\"expandableSearch-result-section\">\n      <h3 class=\"expandableSearch-result-title\">\n        <span class=\"expandableSearch-result-title-inner\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_2 = "";t_2 += "Quick Links";
cb(null, t_2);
;
return t_2;
}
), true && env.opts.autoescape);
output += "</span>\n      </h3>\n      <ul class=\"expandableSearch-result-list\">\n        <li class=\"expandableSearch-result-item\">\n          <a href=\"#\" class=\"expandableSearch-result-link\">\n            <span>\n              ";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_3 = "";t_3 += "Start a";
cb(null, t_3);
;
return t_3;
}
), true && env.opts.autoescape);
output += " <strong class=\"expandableSearch-result-highlight\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_4 = "";t_4 += "Transaction";
cb(null, t_4);
;
return t_4;
}
), true && env.opts.autoescape);
output += "</strong>\n            </span>\n          </a>\n        </li>\n        <li class=\"expandableSearch-result-item\">\n          <a href=\"#\" class=\"expandableSearch-result-link\">\n            <span>";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_5 = "";t_5 += "Consumer: Start a";
cb(null, t_5);
;
return t_5;
}
), true && env.opts.autoescape);
output += " <strong class=\"expandableSearch-result-highlight\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_6 = "";t_6 += "Transaction";
cb(null, t_6);
;
return t_6;
}
), true && env.opts.autoescape);
output += "</strong></span>\n          </a>\n        </li>\n        <li class=\"expandableSearch-result-item\">\n          <a href=\"#\" class=\"expandableSearch-result-link\">\n            <span>";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_7 = "";t_7 += "Broker: Start a";
cb(null, t_7);
;
return t_7;
}
), true && env.opts.autoescape);
output += " <strong class=\"expandableSearch-result-highlight\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_8 = "";t_8 += "Transaction";
cb(null, t_8);
;
return t_8;
}
), true && env.opts.autoescape);
output += "</strong></span>\n          </a>\n        </li>\n      </ul>\n    </div>\n    <div class=\"expandableSearch-result-section\">\n      <h3 class=\"expandableSearch-result-title\">\n        <span class=\"expandableSearch-result-title-inner\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_9 = "";t_9 += "Suggested Searches";
cb(null, t_9);
;
return t_9;
}
), true && env.opts.autoescape);
output += "</span>\n      </h3>\n      <ul class=\"expandableSearch-result-list\">\n        <li class=\"expandableSearch-result-item\">\n          <a href=\"#\" class=\"expandableSearch-result-link\">\n            <span><strong class=\"expandableSearch-result-highlight\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_10 = "";t_10 += "Transaction";
cb(null, t_10);
;
return t_10;
}
), true && env.opts.autoescape);
output += "</strong> ";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_11 = "";t_11 += "and Terms";
cb(null, t_11);
;
return t_11;
}
), true && env.opts.autoescape);
output += "</span>\n          </a>\n        </li>\n        <li class=\"expandableSearch-result-item\">\n          <a href=\"#\" class=\"expandableSearch-result-link\">\n            <span><strong class=\"expandableSearch-result-highlight\">";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_12 = "";t_12 += "Transaction";
cb(null, t_12);
;
return t_12;
}
), true && env.opts.autoescape);
output += "</strong> ";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_13 = "";t_13 += "History";
cb(null, t_13);
;
return t_13;
}
), true && env.opts.autoescape);
output += "</span>\n          </a>\n        </li>\n      </ul>\n    </div>\n  </div>\n</form>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/navigation/v2/expandableSearch.html"] , dependencies)