import format from 'date-fns-tz/format';

const getTimePeriodShort = (msSince) => {
  const totalSeconds = msSince / 1000;
  const seconds = totalSeconds % 60;
  const totalMinutes = totalSeconds / 60;
  const minutes = totalMinutes % 60;
  const totalHours = totalMinutes / 60;
  const hours = totalHours % 24;
  const totalDays = totalHours / 24;
  const floor = Math.floor;
  if (floor(totalDays) > 0) {
    return `${floor(totalDays)}d ${floor(hours)}h`;
  } else if (floor(hours) > 0) {
    return `${floor(hours)}h ${floor(minutes)}m`;
  }
  return `${floor(minutes)}m ${floor(seconds)}s`;
};

const formatUTCtoSentence = (utcDateStr) =>
  format(new Date(utcDateStr), "MMMM d, yyyy 'at' h:m a z");

export { getTimePeriodShort, formatUTCtoSentence };
