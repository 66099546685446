export const normaliseMoney = (value) => {
  if (!value) {
    return value;
  }

  let v = value.toString().replace(/[^\d.]/g, ''); // Remove non-numerical characters
  v = v.slice(0, v.indexOf('.') >= 0 ? v.indexOf('.') + 3 : undefined); // Remove trailing numbers
  return v;
};

export const normalise2FACode = (value) => {
  if (!value) {
    return value;
  }

  const v = value.toString().replace(/[^\d.]/g, '');
  return v.substring(0, 6);
};

export const normalizePhone = (value) => {
  const re = /^[0-9]*$/;

  if (re.test(value)) {
    return value;
  }
};
