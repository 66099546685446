import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { bindRoutineToReduxForm } from 'redux-saga-routines';

import {
  getV4Customer as getV4CustomerRoutine,
  deleteTaxNumber as deleteTaxNumberRoutine,
} from 'spa/actions/UserActions';
import VatFieldset from 'spa/components/TaxDetails/VatFieldset';
import Icon from 'spa/components/Icon';
import { Spinner } from 'spa/components/Indicators';
import { FormError } from 'spa/components/form';
import {
  customerTaxNumbersSelector,
  customerDetailsLoadingSelector,
  custIdSelector,
  customerCountrySelector,
  customerCompanyCountrySelector,
} from 'spa/selectors/CustomerSelectors';
import { Alpha2VatCountries } from 'spa/constants/ISOCountryCodes';
import { addCustomerVatNumber } from 'spa/actions/UserActions';
import { gettext } from '../../../utils/filters';

const AccountInfoCard = ({ children }) => (
  <section className="accountInfo-card card">
    <div className="accountInfo-card-inner">{children}</div>
  </section>
);

const CardTitle = ({ title, iconName = null }) => (
  <h2 className="accountForm-header accountForm-header--highlight">
    {iconName && (
      <span className="accountForm-header-iconHolder accountForm-header-iconHolder--inset">
        <Icon name={iconName} className="accountForm-header-icon" />
      </span>
    )}
    <span className="accountForm-header-title">{title}</span>
  </h2>
);

const CardFooter = ({ children }) => (
  <div className="accountForm-footer accountForm-footer--settings">{children}</div>
);

class VatDetails extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      v4GetCustomerDone: false,
    };

    this.deleteTaxNumber = this.deleteTaxNumber.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.v4GetCustomerDone) {
      if (nextProps.custId) {
        this.props.dispatchV4GetCustomer(nextProps.custId);
        this.setState({
          v4GetCustomerDone: true,
        });
      }
    }
  }

  deleteTaxNumber(event) {
    this.props.dispatchDeleteTaxNumber(event.currentTarget.getAttribute('data-taxNumber-id'));
  }

  renderCurrentVatNumbers() {
    const { custVatNumbers } = this.props;
    return (custVatNumbers || []).map((vatNumber) => (
      <div key={`${vatNumber.id}`} className="notificationSetting is-active">
        <span className="notificationSetting-label">
          <span className="notificationSetting-label-text">{vatNumber.number}</span>
        </span>
        <a
          role="presentation"
          className="notificationSetting-icon notificationSetting-action"
          data-taxNumber-id={vatNumber.id}
          onClick={this.deleteTaxNumber}
        >
          <Icon name="ui-close" />
        </a>
      </div>
    ));
  }

  renderVatNumberForm() {
    const { submitFailed, submitting, error, handleSubmit } = this.props;
    return (
      <div>
        {submitFailed && <FormError error={error} />}
        <form onSubmit={handleSubmit(bindRoutineToReduxForm(addCustomerVatNumber))}>
          <VatFieldset />
          <CardFooter>
            <button
              className="accountForm-btn btn btn--secondary btn--large"
              type="submit"
              disabled={submitting}
            >
              {submitting ? <Spinner /> : 'Verify VAT Number'}
            </button>
          </CardFooter>
        </form>
      </div>
    );
  }

  renderVatDetails() {
    const { custDetailsLoading, custVatNumbers } = this.props;
    if (custDetailsLoading) {
      return <Spinner />;
    }
    if (custVatNumbers && custVatNumbers.length > 0) {
      return this.renderCurrentVatNumbers();
    }
    return this.renderVatNumberForm();
  }

  render() {
    return (
      <AccountInfoCard>
        <CardTitle title={gettext('VAT Details')} iconName="ui-cog" />
        {this.renderVatDetails()}
      </AccountInfoCard>
    );
  }
}

const mapStateToProps = (state) => {
  const userPersonalCountry = customerCountrySelector(state);
  const userCompanyCountry = customerCompanyCountrySelector(state);
  let vatCountryInitial;
  if (userPersonalCountry && Alpha2VatCountries.includes(userPersonalCountry)) {
    vatCountryInitial = userPersonalCountry;
  } else if (userCompanyCountry && Alpha2VatCountries.includes(userCompanyCountry)) {
    vatCountryInitial = userCompanyCountry;
  }
  return {
    initialValues: {
      vatCountry: vatCountryInitial,
    },
    custVatNumbers: (customerTaxNumbersSelector(state) || []).filter((n) => n.type === 'vat'),
    custDetailsLoading: customerDetailsLoadingSelector(state),
    custId: custIdSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchV4GetCustomer: (custId) => {
    dispatch(getV4CustomerRoutine({ custId }));
  },
  dispatchDeleteTaxNumber: (taxNumberId) => {
    dispatch(deleteTaxNumberRoutine({ taxNumberId }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    enableReinitialize: true,
    form: 'vat-number-form',
  })(VatDetails)
);
