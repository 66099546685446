import React from 'react';
import classnames from 'classnames';
import Icon from 'spa/components/Icon';
import CheckoutConstants from 'spa/constants/CheckoutConstants';
import { gettext } from '../../../utils/filters';

export class StepButton extends React.Component {
  constructor(props) {
    super(props);
    this.title = 'StepButton';
    this.step = props.currentStep;
    this.head = props.currentHead;
  }

  componentWillReceiveProps(nextProps) {
    this.step = nextProps.currentStep;
    this.head = nextProps.currentHead;
  }

  handleOnClick() {
    throw new Error('handleOnClick cannot be called on an abstract class');
  }

  isActive() {
    throw new Error('isActive cannot be called on an abstract class');
  }

  isTicked() {
    throw new Error('isTicked cannot be called on an abstract class');
  }

  isClickable() {
    return this.isTicked();
  }

  render() {
    const tick = this.isTicked() ? <Icon className="stepProgress-icon" name="ui-tick" /> : null;
    const active = this.isActive();
    const clickable = this.isClickable();
    const click = this.isClickable() ? this.handleOnClick : null;

    return (
      <div
        className={classnames('stepProgress-item', {
          'is-active': active,
          'is-clickable': clickable,
        })}
        onClick={click}
        role="presentation"
        key={this.title}
        data-tracking-name={`${this.nextSection}`}
      >
        <span>{this.title}</span>
        <span className="stepProgress-iconHolder">{tick}</span>
      </div>
    );
  }
}

class CustomerDetailsStepButton extends StepButton {
  constructor(props) {
    super(props);
    this.nextStep = CheckoutConstants.STEP_VIEW_DETAILS;
    this.nextSection = 'checkout-customer-details-section';
    this.title = gettext('Customer Details');
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick(event) {
    event.preventDefault();
    this.props.setCurrentStep(this.nextStep);
  }

  isActive() {
    return [
      CheckoutConstants.STEP_VIEW_DETAILS,
      CheckoutConstants.STEP_TIER1_VERIFICATION,
    ].includes(this.step);
  }

  isTicked() {
    return (
      CheckoutConstants.STEP_ORDER[this.head] >=
      CheckoutConstants.STEP_ORDER[CheckoutConstants.STEP_VIEW_DETAILS]
    );
  }
}

class VerificationStepButton extends StepButton {
  constructor(props) {
    super(props);
    this.nextStep = CheckoutConstants.STEP_TIER2_TIER3_VERIFICATION;
    this.nextSection = 'checkout-kyc-verification-section';
    this.title = gettext('Verification');
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick(event) {
    event.preventDefault();
    this.props.setCurrentStep(this.nextStep);
  }

  isActive() {
    return [CheckoutConstants.STEP_TIER2_TIER3_VERIFICATION].includes(this.step);
  }

  isTicked() {
    return (
      CheckoutConstants.STEP_ORDER[this.head] >=
      CheckoutConstants.STEP_ORDER[CheckoutConstants.STEP_TIER2_TIER3_VERIFICATION]
    );
  }
}

class PaymentStepButton extends StepButton {
  constructor(props) {
    super(props);
    this.nextStep = CheckoutConstants.STEP_PAYMENT_METHOD;
    this.nextSection = 'checkout-payment-section';
    this.title = gettext('Payment');
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick(event) {
    event.preventDefault();
    this.props.setCurrentStep(this.nextStep);
  }

  isActive() {
    return [CheckoutConstants.STEP_PAYMENT_METHOD].includes(this.step);
  }

  isTicked() {
    return (
      CheckoutConstants.STEP_ORDER[this.head] >=
      CheckoutConstants.STEP_ORDER[CheckoutConstants.STEP_PAYMENT_METHOD]
    );
  }
}

const StepProgress = (props) => {
  if (props.currentHead === CheckoutConstants.STEP_FINAL) {
    return null;
  }

  return (
    <div className="stepProgress">
      <CustomerDetailsStepButton {...props} />
      <VerificationStepButton {...props} />
      <PaymentStepButton {...props} />
    </div>
  );
};

export default StepProgress;
