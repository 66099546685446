import React from 'react';
import ReactTooltip from 'react-tooltip';
import Icon from 'spa/components/Icon';

const InfoTooltipIcon = () => (
  <Icon name="ui-info-alt" className="startTransaction-infoTooltip-icon" />
);

const InfoTooltip = ({
  id,
  message,
  children,
  place,
  cta,
  ctaLink,
  className,
  edgeToEdge,
  size = 'small',
}) => (
  <span
    className={`startTransaction-infoTooltip ${className}`}
    data-tip
    data-for={id}
    data-place={place || 'top'}
    data-event="mouseover"
    data-event-off="mouseout"
  >
    <span className="startTransaction-infoTooltip-trigger">
      <InfoTooltipIcon />
    </span>
    <ReactTooltip
      class={`callout callout--${size} ${edgeToEdge && 'callout--edgeToEdge'}`}
      id={id}
      type="light"
      effect="solid"
      data-edge-to-edge={edgeToEdge}
      data-size={size}
    >
      <div>
        {message}
        {children}
      </div>
      {cta && (
        <div className="startTransaction-infoTooltip-footer">
          <a href={ctaLink} className="startTransaction-infoTooltip-link">
            {cta}
          </a>
        </div>
      )}
    </ReactTooltip>
  </span>
);

export default InfoTooltip;
