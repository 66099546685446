import React from 'react';
import { connect } from 'react-redux';
import QRCode from 'qrcode.react';
import PropTypes from 'prop-types';
import { Spinner } from 'spa/components/Indicators';
import { twoFactorUrlSelector, twoFactorLoadingSelector } from 'spa/selectors/TwoFactorSelectors';
import SetupTwoFactorVerificationForm from 'spa/components/TwoFactorAuthentication/SetupTwoFactorVerificationForm';
import { enableAuthAppTwoFactor } from 'spa/actions/TwoFactorActions';
import { gettext } from '../../../utils/filters';

const getSecretKey = (url) => {
  const secretKey = url
    .match(/secret=[A-Z0-9]{16}/)[0]
    .split('=')[1]
    .match(/.{1,4}/g);
  return secretKey;
};

class SetupAuthenticatorAppContainer extends React.Component {
  constructor(props) {
    super(props);
    this.renderLoading = this.renderLoading.bind(this);
    this.renderInstructions = this.renderInstructions.bind(this);
  }

  componentDidMount() {
    this.props.enableAuthAppTwoFactor();
  }

  renderLoading() {
    return (
      <div className="twoFactorAuth-card-placeholder">
        <Spinner />
      </div>
    );
  }

  renderInstructions() {
    const { secretUrl } = this.props;

    return (
      <div>
        <div className="twoFactorAuth-page-step">
          <div className="twoFactorAuth-page-step-icon">
            <div className="twoFactorAuth-page-step-icon-text">1</div>
          </div>
          <div className="twoFactorAuth-page-step-text">
            {gettext('Download and install Authy or Google Authenticator for your phone or tablet')}
          </div>
        </div>
        <div className="twoFactorAuth-page-step">
          <div className="twoFactorAuth-page-step-icon">
            <div className="twoFactorAuth-page-step-icon-text">2</div>
          </div>
          <div className="twoFactorAuth-page-step-text">{gettext('Scan the QR code')}</div>
        </div>
        <div className="twoFactorAuth-page-qr">
          {secretUrl && (
            <QRCode className="twoFactorAuth-page-qr" value={secretUrl} includeMargin />
          )}
          <div className="twoFactorAuth-page-qr-instructions">
            <div className="twoFactorAuth-page-step-text twoFactorAuth-page-step-text-desc">
              {gettext(
                `Open the authenticator app and scan the image using your phone's
                 camera.`
              )}
            </div>
            <div className="twoFactorAuth-page-step-text twoFactorAuth-page-step-text-desc">
              {gettext('If you are unable to scan the QR code, manually enter the following code:')}
            </div>
            {secretUrl && (
              <div className="twoFactorAuth-page-step-text twoFactorAuth-page-step-text-code">
                {getSecretKey(secretUrl)}
              </div>
            )}
          </div>
        </div>
        <div className="twoFactorAuth-page-step">
          <div className="twoFactorAuth-page-step-icon">
            <div className="twoFactorAuth-page-step-icon-text">3</div>
          </div>
          <div className="twoFactorAuth-page-step-text">
            {gettext('Enter your Authentication Code')}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { loading, navigateDone, navigateMain, configType } = this.props;

    if (loading) {
      return this.renderLoading();
    }

    return (
      <div>
        <div className="twoFactorAuth-card-header">
          <h2 className="twoFactorAuth-card-header-title twoFactorAuth-card-header-title--auth">
            {gettext('Scan to set up')}
          </h2>
        </div>
        <div className="twoFactorAuth-page">
          {this.renderInstructions()}
          <SetupTwoFactorVerificationForm
            navigateDone={navigateDone}
            navigateMain={navigateMain}
            configType={configType}
          />
        </div>
      </div>
    );
  }
}

SetupAuthenticatorAppContainer.propTypes = {
  navigateDone: PropTypes.func.isRequired,
  navigateMain: PropTypes.func.isRequired,
  configType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  secretUrl: twoFactorUrlSelector(state),
  loading: twoFactorLoadingSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  enableAuthAppTwoFactor: () => dispatch(enableAuthAppTwoFactor.trigger()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetupAuthenticatorAppContainer);
