import React from 'react';
import { withRouter } from 'react-router';

import MobileNavigationHeader from './MobileNavigationHeader';
import NavSection from './NavSection';
import config from './config';

const Navigation = ({ location }) => (
  <div>
    <MobileNavigationHeader />
    <div className="integrationsPortal-nav-inner">
      {
        // at the top level is sections
        config.map((section, index) => (
          <NavSection
            key={`stable${index}`} // eslint-disable-line react/no-array-index-key
            location={location}
            section={section}
          />
        ))
      }
    </div>
  </div>
);

const NavigationContainer = withRouter(Navigation);

export default NavigationContainer;
