const authenticationSelector = (state) => state.authentication;

const prefillSelector = (state) => authenticationSelector(state).prefill;

const formSelector = (state) => authenticationSelector(state).form;

const modeSelector = (state) => formSelector(state).mode;

const loginSelector = (state) => authenticationSelector(state).login;

const loginLoadingSelector = (state) => loginSelector(state).loading;

const loginSuccessSelector = (state) => loginSelector(state).success;

const twoFAStatusSelector = (state) => ({
  has_valid_cust_device_token: loginSelector(state).has_valid_cust_device_token,
  authenticator: loginSelector(state).authenticator,
  sms: loginSelector(state).sms,
});

const login2faSuccessSelector = (state) =>
  loginSelector(state).has_valid_cust_device_token ||
  !(loginSelector(state).authenticator || loginSelector(state).sms);

export {
  modeSelector,
  prefillSelector,
  loginLoadingSelector,
  loginSuccessSelector,
  twoFAStatusSelector,
  login2faSuccessSelector,
};
