import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Field } from 'redux-form';
import { Input, TextArea } from 'spa/components/form';
import { currencies } from 'escrow-common-js/dist/constants';

import { normaliseMoney } from 'spa/components/form/normalise';
import { onBlurMoney } from 'spa/components/form/onBlur';
import { required, isDecimal, maxChars } from 'spa/components/form/validate';
import { gettext } from '../../../utils/filters';

const decimalValidator = isDecimal(gettext('decimal number'));

const maxCharValidator = maxChars(2000, gettext('Message'));

const CreateOfferFieldset = (props) => (
  <fieldset>
    <Field
      name="amount"
      label={props.label}
      component={Input}
      normalize={normaliseMoney}
      onBlur={onBlurMoney(props.formName, props.dispatch)}
      validate={[required, decimalValidator]}
      prefix={currencies[props.currency].symbol}
      suffix={
        <div className="field-currencyCode">
          <span
            className="field-currencyCode-flag"
            data-select-icon="amount"
            data-select-value={props.currency}
          />
          <span>{props.currency}</span>
        </div>
      }
      requiredIndicator
    />
    <Field
      name="message"
      label={gettext(
        'Message To %s (Optional)',
        props.role === 'buyer' ? gettext('Seller') : gettext('Buyer')
      )}
      component={TextArea}
      validate={[maxCharValidator]}
    />
  </fieldset>
);

CreateOfferFieldset.propTypes = {
  formName: PropTypes.string,
  label: PropTypes.string,
  role: PropTypes.oneOf(['buyer', 'seller']).isRequired,
  currency: PropTypes.oneOf(Object.keys(currencies)),
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(null, mapDispatchToProps)(CreateOfferFieldset);
