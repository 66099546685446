import PaymentConstants from 'spa/constants/PaymentConstants';
import { WIRE_INSTRUCTIONS_DISPLAY_ORDER } from 'escrow-common-js/dist/constants';
import { gettext } from '../../utils/filters';

const CheckoutConstants = {
  LINE_ITEM_TYPES: {
    domain_name: gettext('Domain Name'),
    domain_name_holding: gettext('Domain Name Holding'),
    general_merchandise: gettext('General Merchandise'),
    milestone: gettext('Milestone'),
    motor_vehicle: gettext('Motor Vehicle'),
    broker_fee: gettext('Broker Fee'),
    partner_fee: gettext('Partner Fee'),
    shipping_fee: gettext('Shipping Fee'),
  },
  CUSTOMER_FEE_TYPES: ['broker_fee', 'partner_fee', 'shipping_fee'],

  FEE_LINE_ITEM_TYPES: {
    credit_card: gettext('Payment Processing Fee'),
    concierge: gettext('Concierge Fee'),
    dibursement: gettext('Disbursement Fee'),
    escrow: gettext('Escrow Fee'),
    intermediary: gettext('Payment Processing Fee'),
    lien_holder_payoff: gettext('Lien Holder Payoff Fee'),
    title_collection: gettext('Title Collection Fee'),
    domain_name_holding_fee: gettext('Domain Name Holding Fee'),
    other: gettext('Other Fee/s'),
  },

  TRANSACTION_FEE_TYPES: [
    'credit_card',
    'concierge',
    'dibursement',
    'domain_name_holding_fee',
    'escrow',
    'intermediary',
    'lien_holder_payoff',
    'title_collection',
    'other',
  ],

  REDIRECT_TYPES: {
    automatic: 'automatic',
    manual: 'manual',
  },

  CUSTOMER_DETAILS_DISPLAY: 'checkout-customer-details-display',
  CUSTOMER_DETAILS_FORM: 'checkout-customer-details-form',
  PAYMENT_METHOD_FORM_NAME: 'checkout-payment-method-form',
  PAYMENT_DETAILS_FORM: 'checkout-payment-details-form',
  VERIFICATION_DETAILS_FORM_NAME: 'checkout-verification-details-form',
  RECEIVE_SMS_FORM_NAME: 'receive-sms-form-name',

  ACTION_SET_REDUX_CUSTOMER_DETAILS: 'checkout_action_set_redux_customer_details',
  ACTION_SET_STEP: 'checkout_action_set_step',
  ACTION_MOVE_TO_NEXT_STEP: 'MOVE_TO_NEXT_STEP',
  ACTION_MOVE_TO_PREV_STEP: 'MOVE_TO_PREV_STEP',

  ACTION_LOAD_NEXT_STEP_DATA: 'LOAD_NEXT_STEP_DATA',

  // STEP_INIT the initialise step before page gets loaded
  STEP_INIT: 'checkout_step_init',

  // STEP_LOGIN allows user to fill in Email and Password fields (IN USE)
  STEP_LOGIN: 'checkout_step_login',

  // STEP_SIGNUP allows user to fill in Email and Password along with their contact details
  STEP_SIGNUP: 'checkout_step_signup',

  // STEP_VIEW_DETAILS allows user to view their personal/company details
  STEP_VIEW_DETAILS: 'checkout_step_view_details',

  // STEP_TIER1_VERIFICATION edit customer contact details (IN USE)
  STEP_TIER1_VERIFICATION: 'checkout_step_tier1_verification',

  // STEP_TIER2_TIER3_VERIFICATION edit customer ID/company details (IN USE)
  STEP_TIER2_TIER3_VERIFICATION: 'checkout_step_tier2_tier3_verification',

  // STEP_PAYMENT_METHOD allows user to review their goods and select a payment method (IN USE)
  STEP_PAYMENT_METHOD: 'checkout_step_payment_method',

  // STEP_FINAL the final step notifies customer that their transaction has been started
  STEP_FINAL: 'checkout_step_final',

  // V2 Checkout Steps
  STEP_INITIAL: -1,
  STEP_AUTHENTICATE: 0,
  STEP_PAYMENT: 1,
  STEP_CUSTOMER_DETAILS: 2,
  STEP_CONFIRMATION: 3,
  STEP_FINISHED: 4,

  // Step Flow Pages
  PAYMENT_DIRECT_DEBIT_FLOW_PAGES: ['payment_selection', 'account_selection'],
  PAYMENT_WIRE_FLOW_PAGES: ['payment_selection'],
  // PAYMENT_CC_FLOW_PAGES: ['payment_selection', 'account_selection'],
  PAYMENT_CC_FLOW_PAGES: ['payment_selection'],
  PAYMENT_PAYPAL_FLOW_PAGES: ['payment_selection'],

  AUTHENTICATION_STEP_FORM: 'checkout-authentication-step-form',
  PAYMENT_METHOD_SELECTION_FORM: 'checkout-payment-method-selection-form',
  CUSTOMER_DETAILS_STEP_FORM: 'checkout-customer-details-step-form',
  PAYMENT_REVIEW_FORM: 'checkout-confirmation-payment-review-form',

  CONFIRMATION_WIRE: ['payment_review', 'wire_payment_review', 'wire_payment_confirmation'],
  CONFIRMATION_PAYMENT: ['payment_review', 'payment_confirmation'],
  CONFIRMATION_STRIPE: ['payment_review', 'stripe_confirmation'],

  MAPPING_CUSTOMER_INFORMATION: 'checkout_mapping_customer_information',

  BUYER_PLACEHOLDER: 'checkout_user',

  SET_PAYMENT_TYPE: 'CheckoutConstants.SET_PAYMENT_TYPE',
  SET_PAYMENT_DETAILS: 'CheckoutConstants.SET_PAYMENT_DETAILS',
  SUBMIT_PAYMENT_DETAILS: 'CheckoutConstants.SUBMIT_PAYMENT_DETAILS',

  SET_TRANSACTION_ID: 'CheckoutConstants.SET_TRANSACTION_ID',
  SET_STRIPE_PAYMENT_LINK: 'CheckoutConstants.SET_STRIPE_PAYMENT_LINK',
  SELECT_PAYMENT_METHOD: 'CheckoutConstants.SELECT_PAYMENT_METHOD',
  AGREE_AND_PAY: 'CheckoutConstants.AGREE_AND_PAY',
  CONFIRM_PAYMENT_METHOD: 'CheckoutConstants.CONFIRM_PAYMENT_METHOD',
  AGREE_WIRE_PAYMENT: 'CheckoutConstants.AGREE_WIRE_PAYMENT',
  AGREE_STRIPE_PAYMENT: 'CheckoutConstants.AGREE_STRIPE_PAYMENT',
  CONFIRM_STRIPE_PAYMENT: 'CheckoutConstants.CONFIRM_STRIPE_PAYMENT',
  AGREE_PAYMENT_REVIEW: 'CheckoutConstants.AGREE_PAYMENT_REVIEW',
  SUBMIT_RECEIVE_SMS: 'CheckoutConstants.SUBMIT_RECEIVE_SMS',
  GET_CHECKOUT_PARTNER_DATA: 'CheckoutConstants.GET_CHECKOUT_PARTNER_DATA',
  RETURN_TO_LOGIN: 'CheckoutConstants.RETURN_TO_LOGIN',
  FETCH_TRANSACTION_AND_PAYMENT_METHODS: 'CheckoutConstants.FETCH_TRANSACTION_AND_PAYMENT_METHODS',
  SEND_ESCROW_PAY_BUYER_REMINDER: 'CheckoutConstants.SEND_ESCROW_PAY_BUYER_REMINDER',
  SET_PAYPAL_ORDER_DETAILS: 'CheckoutConstants.SET_PAYPAL_ORDER_DETAILS',
  SET_AUTHORIZED_PAYMENT: 'CheckoutConstants.SET_AUTHORIZED_PAYMENT',

  GET_CHECKOUT_FLAGS: 'CheckoutConstants.GET_CHECKOUT_FLAGS',
  GET_TRANSACTION_ID: 'CheckoutConstants.GET_TRANSACTION_ID',
};

CheckoutConstants.ENABLED_PAYMENT_METHODS_V2 = [
  PaymentConstants.PAYMENT_METHODS.DIRECT_DEBIT,
  PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER,
  PaymentConstants.PAYMENT_METHODS.CREDIT_CARD,
  PaymentConstants.PAYMENT_METHODS.PAYPAL,
];

CheckoutConstants.WELCOME_SCREEN_SLIDES = (partnerName) =>
  partnerName
    ? [
        {
          title: 'Welcome to Escrow.com!',
          subtitle: `${partnerName} partnered with Escrow.com for a safer and more secure form of payment.`,
          imageUrl: '../../../../build/images/escrow-pay/welcome-screen-1.png',
        },
        {
          title: 'Keep your funds safe with us',
          subtitle:
            'We are a trusted and licensed escrow service where your funds are secured until your purchase is complete.',
          imageUrl: '../../../../build/images/escrow-pay/welcome-screen-2.png',
        },
        {
          title: 'Setup your account in minutes',
          subtitle: 'We verify all parties involved to protect you from fraud.',
          imageUrl: '../../../../build/images/escrow-pay/welcome-screen-3.png',
          link: `${window.config.www_base_url}/what-is-escrow`,
          linkLabel: 'Learn More',
        },
      ]
    : [];

CheckoutConstants.STEP_ORDER = {
  [CheckoutConstants.STEP_INIT]: 0,
  [CheckoutConstants.STEP_LOGIN]: 1,
  [CheckoutConstants.STEP_SIGNUP]: 1,
  [CheckoutConstants.STEP_TIER1_VERIFICATION]: 2,
  [CheckoutConstants.STEP_VIEW_DETAILS]: 3,
  [CheckoutConstants.STEP_TIER2_TIER3_VERIFICATION]: 4,
  [CheckoutConstants.STEP_PAYMENT_METHOD]: 5,
  [CheckoutConstants.STEP_FINAL]: 6,
};

CheckoutConstants.apiMapping = {
  // Field names in CustomerInfoForm
  [CheckoutConstants.MAPPING_CUSTOMER_INFORMATION]: {
    'company-name': 'CompanyName',
    'first-name': 'FirstName',
    'last-name': 'LastName',
    'local-fname': 'LocalFName',
    'local-lname': 'LocalLName',
    'company-city': 'CompanyAddress.City',
    'company-state': 'CompanyAddress.State',
    'company-address-line-1': 'CompanyAddress.Street1',
    'company-address-line-2': 'CompanyAddress.Street2',
    'company-post-code': 'CompanyAddress.Zip',
    city: 'PersonalAddress.City',
    state: 'PersonalAddress.State',
    'address-line-1': 'PersonalAddress.Street1',
    'address-line-2': 'PersonalAddress.Street2',
    'post-code': 'PersonalAddress.Zip',
    // Following fields require preprocessing
    'date-of-birth': 'DateOfBirth',
    phone: 'Phone',
    'display-name': 'DisplayName',
    email: 'Email',
    'display-name-type': 'DisplayNameType',
    'issue-check-in-company-name': 'IssueCheckInCompanyName',
    'shipping-address': 'ShippingAddress',
    'use-username-as-display-name': 'UseUsernameAsDisplayName',
    username: 'Username',
    'is-company': 'IsCompany',
    'company-country': 'CompanyAddress.Country',
    country: 'PersonalAddress.Country',
  },
};

CheckoutConstants.apiFeeNameMapping = {
  broker_fee: gettext('Broker Fee'),
  shipping_fee: gettext('Shipping Fee'),
  partner_fee: gettext('Partner Fee'),
};

CheckoutConstants.apiWireNameMapping = {};
WIRE_INSTRUCTIONS_DISPLAY_ORDER.forEach((wireDetail) => {
  CheckoutConstants.apiWireNameMapping[wireDetail.key] = gettext(wireDetail.displayName);
});

CheckoutConstants.WIRE_FIELD_ORDER = WIRE_INSTRUCTIONS_DISPLAY_ORDER.map(
  (wireDetail) => wireDetail.key
);

export default CheckoutConstants;
