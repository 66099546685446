export const baseSelector = (state) => state.partner;

export const customersSelector = (state) => baseSelector(state).customers;
export const customersCursorSelector = (state) => customersSelector(state).nextCursor;
export const customersDataSelector = (state) => customersSelector(state).data;
export const customersErrorSelector = (state) => customersSelector(state).error;
export const customersStatisticsSelector = (state) => customersSelector(state).statistics;
export const customersListLoadingSelector = (state) => customersSelector(state).loading;
export const customersCountStatisticsSelector = (state) =>
  customersStatisticsSelector(state).customerCount;
export const customersCountStatisticsAggregatesSelector = (state) =>
  customersCountStatisticsSelector(state)
    ? customersCountStatisticsSelector(state).aggregates
    : null;
export const customersCountStatisticsTimeDataSelector = (state) =>
  customersCountStatisticsSelector(state) ? customersCountStatisticsSelector(state).data : null;
export const customerSelector = (state, customerId) =>
  customersDataSelector(state).find((customer) => customer.id === customerId);

export const paymentsInMethodsSelector = (state) => baseSelector(state).paymentsInMethods;
export const paymentsOutMethodsSelector = (state) => baseSelector(state).paymentsOutMethods;

export const transactionsSelector = (state) => baseSelector(state).transactions;
export const transactionsCursorSelector = (state) => transactionsSelector(state).nextCursor;
export const transactionsDataSelector = (state) => transactionsSelector(state).data;
export const transactionsErrorSelector = (state) => transactionsSelector(state).error;
export const transactionsStatisticsSelector = (state) => transactionsSelector(state).statistics;
export const transactionListLoadingSelector = (state) => transactionsSelector(state).loading;
export const transactionsCountStatisticsSelector = (state) =>
  transactionsStatisticsSelector(state).transactionCount;
export const transactionsCountStatisticsAggregatesSelector = (state) =>
  transactionsCountStatisticsSelector(state)
    ? transactionsCountStatisticsSelector(state).aggregates
    : null;
export const transactionsCountStatisticsTimeDataSelector = (state) =>
  transactionsCountStatisticsSelector(state)
    ? transactionsCountStatisticsSelector(state).data
    : null;
export const transactionSelector = (state, transactionId) =>
  transactionsDataSelector(state).find(
    (transaction) => transaction.id === parseInt(transactionId, 10)
  );

export const transactionHoldingPenSelector = (state, transactionId) =>
  transactionsSelector(state).holdingPen[transactionId] || {};
export const transactionHoldingPenDataSelector = (state, transactionId) =>
  transactionHoldingPenSelector(state, transactionId).data;
export const transactionHoldingPenErrorSelector = (state, transactionId) =>
  transactionHoldingPenSelector(state, transactionId).error;
export const transactionHoldingPenLoadingSelector = (state, transactionId) =>
  transactionHoldingPenSelector(state, transactionId).loading;

export const completedTransactionsCountStatisticsSelector = (state) =>
  transactionsStatisticsSelector(state).completedTransactionCount;
export const completedTransactionsCountStatisticsAggregatesSelector = (state) =>
  completedTransactionsCountStatisticsSelector(state)
    ? completedTransactionsCountStatisticsSelector(state).aggregates
    : null;
export const completedTransactionsCountStatisticsTimeDataSelector = (state) =>
  completedTransactionsCountStatisticsSelector(state)
    ? completedTransactionsCountStatisticsSelector(state).data
    : null;

export const ongoingTransactionsCountStatisticsSelector = (state) =>
  transactionsStatisticsSelector(state).ongoingTransactionCount;
export const ongoingTransactionsCountStatisticsAggregatesSelector = (state) =>
  ongoingTransactionsCountStatisticsSelector(state)
    ? ongoingTransactionsCountStatisticsSelector(state).aggregates
    : null;
export const ongoingTransactionsCountStatisticsTimeDataSelector = (state) =>
  ongoingTransactionsCountStatisticsSelector(state)
    ? ongoingTransactionsCountStatisticsSelector(state).data
    : null;

export const partnerDashboardUiSelector = (state) => baseSelector(state).partnerDashboardUi;

export const transactionListPageUiSelector = (state) =>
  partnerDashboardUiSelector(state).transactionListPage;
export const transactionListPageUiErrorSelector = (state) =>
  transactionListPageUiSelector(state).error;
export const transactionListPageFilterValueSelector = (state, filterCategory) =>
  transactionListPageUiSelector(state)[filterCategory];
export const transactionListPageFilterValueIdsSelector = (state, filterCategory, filterValue) =>
  transactionListPageFilterValueSelector(state, filterCategory)
    ? transactionListPageFilterValueSelector(state, filterCategory)[filterValue]
    : null;
export const transactionListPageOpenDateRangeSelector = (state) =>
  transactionListPageUiSelector(state).openDateRange;
export const transactionListPageCloseDateRangeSelector = (state) =>
  transactionListPageUiSelector(state).closeDateRange;
export const transactionListPageFiltersSelector = (state) =>
  transactionListPageUiSelector(state).filters;
export const transactionListPageTransactionCount = (state) =>
  transactionListPageUiSelector(state).transactionCount;
export const transactionTableFilterValueSelector = (state) =>
  transactionListPageUiSelector(state).tableFilterValues;

export const customerListPageUiSelector = (state) =>
  partnerDashboardUiSelector(state).customerListPage;
export const customerListPageUiErrorSelector = (state) => customerListPageUiSelector(state).error;
export const customerListPageFilterValueSelector = (state, filterCategory) =>
  customerListPageUiSelector(state)[filterCategory];
export const customerListPageFilterValueIdsSelector = (state, filterCategory, filterValue) =>
  customerListPageFilterValueSelector(state, filterCategory)
    ? customerListPageFilterValueSelector(state, filterCategory)[filterValue]
    : null;
export const customerListPageUserCountSelector = (state) =>
  customerListPageUiSelector(state).customerCount;
export const customerTableFilterValueSelector = (state) =>
  customerListPageUiSelector(state).tableFilterValues;

export const reportsSelector = (state) => baseSelector(state).reports;
export const reportsCursorSelector = (state) => reportsSelector(state).nextCursor;
export const reportsDataSelector = (state) => reportsSelector(state).data;
export const reportsErrorSelector = (state) => reportsSelector(state).error;
export const reportsCountSelector = (state) => reportsSelector(state).total;
export const reportsListLoadingSelector = (state) => reportsSelector(state).loading;

export const reportsListPageUiSelector = (state) =>
  partnerDashboardUiSelector(state).reportsListPage;
export const reportsTableFilterValueSelector = (state) =>
  reportsListPageUiSelector(state).tableFilterValues;

export const partnerLogosSelector = (state) => baseSelector(state).partnerLogos;
export const partnerLogosSettingsSelector = (state) => partnerLogosSelector(state).settings;
export const partnerLogosSettingsDataSelector = (state) => partnerLogosSettingsSelector(state).data;
export const partnerLogosEnableCobrandedEmailSettingSelector = (state) =>
  partnerLogosSettingsDataSelector(state).enableCobrandedEmails;
