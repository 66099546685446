import { createSelector } from 'reselect';
import moment from 'moment';

const apiIntegrationSelector = (state) => state.apiIntegration;

const apiKeysSelector = (state) => apiIntegrationSelector(state).apiKeys;

const apiKeysTransformation = createSelector(apiKeysSelector, (apiKeys) =>
  apiKeys.map(
    ({ last_four_characters: lastFourCharacters, date_created: dateCreated, ...rest }) => ({
      ...rest,
      key: `*******************${lastFourCharacters}`,
      dateCreated: moment(dateCreated).format('YYYY-MM-DD h:mm'),
    })
  )
);

const containerSelector = (state, container) => apiIntegrationSelector(state)[container] || {};

const loadingSelector = (state, container) => containerSelector(state, container).loading;

const successSelector = (state, container) => containerSelector(state, container).success;

const newAPIKeySelector = createSelector(
  apiKeysSelector,
  (apiKeys) => apiKeys[apiKeys.length - 1] || {}
);

export {
  apiIntegrationSelector,
  apiKeysTransformation,
  loadingSelector,
  successSelector,
  newAPIKeySelector,
};
