var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["components/inputs.html"] = require( "components/inputs.html" );




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/js/contactInformationStates.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("components/inputs.html", false, "escrow_www/templates/js/contactInformationStates.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("input", t_1);
var macro_t_4 = runtime.makeMacro(
[], 
["name", "country", "state", "state_label", "fields_prefix"], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("name", kwargs.hasOwnProperty("name") ? kwargs["name"] : "State");
frame.set("country", kwargs.hasOwnProperty("country") ? kwargs["country"] : "");
frame.set("state", kwargs.hasOwnProperty("state") ? kwargs["state"] : "");
frame.set("state_label", kwargs.hasOwnProperty("state_label") ? kwargs["state_label"] : "");
frame.set("fields_prefix", kwargs.hasOwnProperty("fields_prefix") ? kwargs["fields_prefix"] : "");
var t_5 = "";t_5 += "<div data-component=\"contactInformation-states\" data-fields-prefix=\"";
t_5 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "fields_prefix"), env.opts.autoescape);
t_5 += "\" data-state-label=\"";
t_5 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "state_label"), env.opts.autoescape);
t_5 += "\">\n\t  ";
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "country"),runtime.contextOrFrameLookup(context, frame, "user_country"),true) == "United States") {
t_5 += "\n\t    ";
t_5 += runtime.suppressValue((lineno = 5, colno = 22, runtime.callWrap(runtime.memberLookup((t_1),"formSelect"), "input[\"formSelect\"]", context, [runtime.contextOrFrameLookup(context, frame, "state_label"),runtime.makeKeywordArgs({"name": runtime.contextOrFrameLookup(context, frame, "name"),"selected": runtime.contextOrFrameLookup(context, frame, "state"),"options": runtime.contextOrFrameLookup(context, frame, "usa_states"),"error": (lineno = 5, colno = 96, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Please choose the state you reside in"])),"required": "true","attrs": {"data-e2e-target": "agree-page-state"}})])), env.opts.autoescape);
t_5 += "\n\t  ";
;
}
else {
t_5 += "\n\t    ";
t_5 += runtime.suppressValue((lineno = 7, colno = 16, runtime.callWrap(runtime.memberLookup((t_1),"text"), "input[\"text\"]", context, [runtime.contextOrFrameLookup(context, frame, "state_label"),runtime.makeKeywordArgs({"name": runtime.contextOrFrameLookup(context, frame, "name"),"value": runtime.contextOrFrameLookup(context, frame, "state"),"placeholder": (lineno = 7, colno = 73, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["State/Province/Region"])),"error": (lineno = 7, colno = 113, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Please enter the state you reside in"])),"required": "true","attrs": {"data-max-length": "50","data-e2e-target": "agree-page-state"}})])), env.opts.autoescape);
t_5 += "\n\t  ";
;
}
t_5 += "\n\t</div>";
;
frame = frame.pop();
return new runtime.SafeString(t_5);
});
context.addExport("state_box");
context.setVariable("state_box", macro_t_4);
output += "\n\n";
output += runtime.suppressValue((lineno = 12, colno = 10, runtime.callWrap(macro_t_4, "state_box", context, [runtime.makeKeywordArgs({"name": (env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "name"),"State",true)),"country": runtime.contextOrFrameLookup(context, frame, "country"),"state": runtime.contextOrFrameLookup(context, frame, "state"),"state_label": runtime.contextOrFrameLookup(context, frame, "state_label"),"fields_prefix": (env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "fields_prefix"),"",true))})])), env.opts.autoescape);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/js/contactInformationStates.html"] , dependencies)