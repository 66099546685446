import React from 'react';
import uuidv1 from 'uuid/v1';

import { CollapsableContentDesc, CollapsableContentList } from 'spa/components/CollapsableContent';
import A from 'spa/components/A';
import { urlFor } from '../../../routeConfig';

const content = [
  {
    key: 'account-faq',
    title: 'Account FAQs',
    faqs: [
      {
        key: uuidv1(),
        question: 'I forgot my password. How do I reset it?',
        answer: (
          <CollapsableContentDesc>
            You can reset your password{' '}
            <A link={{ type: 'external', route: `${window.config.ecart_url}/#/ForgotPwd` }}>here</A>
            .
          </CollapsableContentDesc>
        ),
      },
      {
        key: uuidv1(),
        question: 'How do I change my password?',
        answer: (
          <CollapsableContentDesc>
            You can change your password in the Account Settings section, which is available{' '}
            <A link={{ type: 'internal', route: '/account-info/password' }}>here</A>.
          </CollapsableContentDesc>
        ),
      },
      {
        key: uuidv1(),
        question: 'How do I verify my account?',
        answer: (
          <CollapsableContentDesc>
            You can <A link={{ type: 'internal', route: '/verify' }}>verify your account here</A>.
            The submission usually take less than 5 minutes for you to complete and we review most
            submissions within the hour.
          </CollapsableContentDesc>
        ),
      },
      {
        key: uuidv1(),
        question: 'Why do you need my personal / company documents?',
        answer: (
          <CollapsableContentDesc>
            As a licensed escrow provider, Escrow.com is required to abide by US Anti-Money
            Laundering and Counter-Terrorism Financing laws, which requires the verification of
            individual identity and the identity and standing of your business. We treat these
            documents as confidential at all times and protect them using the same systems that
            protect millions of dollars of our customer’s money. Details on this process and levels
            of verification can be found <A link={{ type: 'internal', route: '/verify' }}>here</A>.
          </CollapsableContentDesc>
        ),
      },
      {
        key: uuidv1(),
        question: 'I am a Seller, Broker, Partner, or Affiliate. How do I get paid?',
        answer: (
          <div>
            <CollapsableContentDesc>
              Sellers, Brokers and Partners can be paid via the following disbursement methods:
            </CollapsableContentDesc>
            <CollapsableContentList>
              <li>ACH / Electronic Check (US)</li>
              <li>Wire Transfer (US and International</li>
            </CollapsableContentList>
            You can add settlement accounts for disbursements{' '}
            <A link={{ type: 'internal', route: '/account-info/disbursement-options' }}>here</A>.
          </div>
        ),
      },
    ],
  },
  {
    key: 'sandbox-faqs',
    title: 'Sandbox FAQs',
    faqs: [
      {
        key: uuidv1(),
        question: 'Do you have a way for me test your API / my integration?',
        answer: (
          <CollapsableContentDesc>
            Yes, Escrow.com provides you with a sandbox environment where you can run all your
            testing without interfering with your normal (production) Escrow.com account. This is
            available at{' '}
            <A link={{ type: 'external', route: 'https://www.escrow-sandbox.com' }}>
              www.escrow-sandbox.com
            </A>
            .
          </CollapsableContentDesc>
        ),
      },
      {
        key: uuidv1(),
        question: 'What is the difference between the Sandbox and Production environments?',
        answer: (
          <div>
            <CollapsableContentDesc>
              The Production environment uses the{' '}
              <A link={{ type: 'internal', route: '/' }}>Escrow.com</A> domain and is where all the
              Escrow.com transactions happen while the Sandbox environment is a test environment
              which uses the{' '}
              <A link={{ type: 'external', route: 'https://www.escrow-sandbox.com' }}>
                escrow-sandbox.com
              </A>{' '}
              domain.
            </CollapsableContentDesc>
            <CollapsableContentDesc>
              The Sandbox is virtually identical to the Production environment but we maintain it
              separately to allow you to test your integrations before launching them on the
              Production environment.
            </CollapsableContentDesc>
          </div>
        ),
      },
      {
        key: uuidv1(),
        question: 'Does my Escrow.com account work in the Sandbox Environment?',
        answer: (
          <CollapsableContentDesc>
            No. You must create a separate account in the Escrow Sandbox environment. No link will
            be established between these 2 accounts and, as such, no information will be shared
            between these accounts either.
          </CollapsableContentDesc>
        ),
      },
    ],
  },
  {
    key: 'api-faqs',
    title: 'API FAQs',
    faqs: [
      {
        key: uuidv1(),
        question: 'What is an API?',
        answer: (
          <div>
            <CollapsableContentDesc>
              API stands for Application Programming Interface. It allows you to integrate your
              website, app, classifieds site, marketplace, or store with our payment services. By
              calling our API from your code, you can perform various actions in our system. These
              actions include:
            </CollapsableContentDesc>
            <CollapsableContentList>
              <li>Transaction Creation</li>
              <li>Transaction Agreement</li>
              <li>Transaction Status Check</li>
              <li>Item Shipment</li>
              <li>Item Receipt</li>
              <li>Item Acceptance</li>
              <li>Item Rejection</li>
            </CollapsableContentList>
            A full list of functionality may be viewed in our documentation which is available{' '}
            <A link={{ type: 'internal', route: '/api/docs/reference' }}>here</A>.
          </div>
        ),
      },
      {
        key: uuidv1(),
        question: 'What is an API key?',
        answer: (
          <div>
            <CollapsableContentDesc>
              An API key is a string of characters that uniquely identifies an account and is used
              to sign each API request that you send to us. This is a way for us to ensure it is
              really you communicating Escrow.com.
            </CollapsableContentDesc>
            <CollapsableContentDesc>
              The API Key Management page may be found{' '}
              <A link={{ type: 'app', route: urlFor('integrations_api') }}>here</A>.
            </CollapsableContentDesc>
          </div>
        ),
        hints: [
          {
            key: uuidv1(),
            title: 'Security Tip',
            desc:
              'API keys should be treated as secrets (passwords). Keep your API keys safe ' +
              'and don’t share them to others. For example, do not check them into a ' +
              'public code repository such as GitHub. Escrow.com staff will never ask you ' +
              'for your password or API key.',
          },
        ],
      },
      {
        key: uuidv1(),
        question: 'Can I use multiple API keys?',
        answer: (
          <CollapsableContentDesc>
            Yes, you can use as many API keys as you like (within reason). API keys may be created
            and managed on the API Key Management page which is available{' '}
            <A link={{ type: 'app', route: urlFor('integrations_api') }}>here</A>.
          </CollapsableContentDesc>
        ),
      },
      {
        key: uuidv1(),
        question: 'I’ve lost my API key, can I retrieve it again?',
        answer: (
          <div>
            <CollapsableContentDesc>
              As a security best practice, Escrow API keys are non-reversible. That means we only
              show your key to you once (at the time it is created) and we don’t store it in our
              servers. As such, lost API keys are impossible to retrieve.
            </CollapsableContentDesc>
            <CollapsableContentDesc>
              If you have lost your API key, don’t worry, you can simply generate a new API key in
              your API Key Management page which is available{' '}
              <A link={{ type: 'app', route: urlFor('integrations_api') }}>here</A>.
            </CollapsableContentDesc>
          </div>
        ),
        hints: [
          {
            key: uuidv1(),
            title: 'Security Tip',
            desc:
              'API keys should be treated as secrets (passwords). Keep your API keys safe ' +
              'and don’t share them to others. For example, do not check them into a ' +
              'public code repository such as GitHub. Escrow.com staff will never ask you ' +
              'for your password or API key.',
          },
        ],
      },
    ],
  },
  {
    key: 'bin-faqs',
    title: 'Buy It Now Button FAQs',
    faqs: [
      {
        key: uuidv1(),
        question: 'What is a Buy It Now Button?',
        answer: (
          <CollapsableContentDesc>
            A Buy It Now Button is a short snippet of code you can use on your website or
            marketplace listing that allows potential buyers to pay you securely using Escrow.com.
          </CollapsableContentDesc>
        ),
      },
      {
        key: uuidv1(),
        question: 'How does a Buy It Now Button work?',
        answer: (
          <div>
            <CollapsableContentDesc>
              A Buy It Now Button is an easy way to add Escrow.com to your website or email.
            </CollapsableContentDesc>
            <CollapsableContentDesc>
              Each button is unique and contains a pre-loaded Escrow.com transaction that your Buyer
              can click your button to create. Each button is based on the item you wish to sell and
              the value you wish to sell it for, which you can set here. The process of creating a
              button does not create a transaction right away but will allow you to preset the exact
              terms you want for your transaction.
            </CollapsableContentDesc>
            <CollapsableContentDesc>
              Once you’ve installed your Buy It Now Button on your website or marketplace listing,
              potential buyers can click through and will be directed to a checkout interface where
              they can create a transaction with you, with the terms you’ve pre-determined during
              the creation process.
            </CollapsableContentDesc>
          </div>
        ),
      },
      {
        key: uuidv1(),
        question: 'How many Buy It Now Buttons can I create?',
        answer: (
          <CollapsableContentDesc>
            There is no limit for how many Buy It Now Buttons you can create.
          </CollapsableContentDesc>
        ),
      },
    ],
  },
  {
    key: 'referrals-faqs',
    title: 'Referral Links FAQs',
    faqs: [
      {
        key: uuidv1(),
        question: 'How do referral links work?',
        answer: (
          <CollapsableContentDesc>
            A Partner ID is associated with every referral link, which allows Escrow.com to detect
            when a customer that follows your link creates an account or transactions, to provide
            you with insights and analytics into traffic you refer to Escrow.com.
          </CollapsableContentDesc>
        ),
      },
    ],
  },
];

export default content;
