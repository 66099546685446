import _ from 'lodash';
import deepcopy from 'clone';

import FormConstants from '../constants/FormConstants';
import FormStore from '../stores/FormStore';
import ModifyTransactionConstants from '../constants/ModifyTransactionConstants';
import AppDispatcher from '../dispatcher/AppDispatcher';
import { ChangeEmitter } from '../utils/ChangeEmitter';

export class ModifyTransactionStore extends ChangeEmitter {
  constructor() {
    super();
    this.modifiableFields = {};
    this.modifiedFields = {};
  }

  getModifiedFields() {
    return deepcopy(this.modifiedFields);
  }

  modified() {
    return !_.isEmpty(this.modifiedFields);
  }

  savedState() {
    return Object.assign({}, this.modifiableFields, this.modifiedFields);
  }

  /*
   * Compare values in modifiableFields with FormStore, any changed values
   * are stored in modifiedFields
   */
  formSave() {
    const updatedValues = FormStore.currentFormState(ModifyTransactionConstants.FORM_NAME);
    this.modifiedFields = {};
    for (const field of Object.keys(this.modifiableFields)) {
      if (field in updatedValues) {
        if (updatedValues[field] !== this.modifiableFields[field]) {
          this.modifiedFields[field] = updatedValues[field];
        }
      }
    }
    if (this.modified()) {
      this.modifiedFields.ModifyReason = updatedValues.ModifyReason;
    }
    if (!this.modifiedFields.ModifyReason) {
      delete this.modifiedFields.ModifyReason;
    }
  }

  handleViewAction(action) {
    const actionType = action.actionType;
    if (actionType === FormConstants.FORM_SAVE) {
      this.formSave();
      this.emitChange();
    } else if (actionType === FormConstants.FORM_RESET) {
      this.modifiedFields = {};
      this.emitChange();
    }
  }

  handleServerAction(action) {
    const actionType = action.actionType;
    if (actionType === ModifyTransactionConstants.INITIALISE_VALUES) {
      this.modifiableFields = action.value;
      this.emitChange();
    }
  }
}

const modifyTransactionStore = new ModifyTransactionStore();
modifyTransactionStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;
  const source = payload.source;

  if (source === 'VIEW_ACTION') {
    modifyTransactionStore.handleViewAction(action);
  } else if (source === 'SERVER_ACTION') {
    modifyTransactionStore.handleServerAction(action);
  }
});

export default modifyTransactionStore;
