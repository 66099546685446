import React from 'react';
import { reduxForm, formValues } from 'redux-form';
import { RadioGroup } from 'spa/components/form';
import VerificationConstants from 'spa/constants/VerificationConstants';
import { gettext } from '../../../utils/filters';

const AccountSelectorForm = (props) => (
  <form name={props.form}>
    <fieldset className="verifyPage-accountSelector">
      <h2 className="verifyPage-accountSelector-title">
        {gettext('Please choose an account type')}
      </h2>
      <RadioGroup
        name="accountType"
        formName={props.form}
        onChange={props.onChange}
        options={[
          {
            label: gettext('Individual'),
            value: 'individual',
          },
          {
            label: gettext('Company'),
            value: 'company',
          },
        ]}
      />
    </fieldset>
  </form>
);

export default reduxForm({
  form: VerificationConstants.PERSONAL_VERIFICATION_FORM,
})(formValues('accountType')(AccountSelectorForm));
