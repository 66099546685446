import React from 'react';
import classnames from 'classnames';
import { Button } from 'escrow-common-js/dist/components';
import { reduxForm, formValues } from 'redux-form';
import { bindRoutineToReduxForm } from 'redux-saga-routines';
import { selectPaymentMethod } from 'spa/actions/CheckoutActions';
import { Spinner } from 'spa/components/Indicators';
import { FormError } from 'spa/components/form';
import { ConfirmationMessageBox } from 'spa/components/CheckoutDetails';
import CheckoutConstants from 'spa/constants/CheckoutConstants';
import TransactionSummaryContainer from 'spa/containers/TransactionSummary/TransactionSummaryContainer';
import PaymentConstants from 'spa/constants/PaymentConstants';
import TransactionConstants from 'spa/constants/TransactionConstants';
import { PaymentForm } from './PaymentForm';
import PaypalRedirectionBox from './PaypalRedirectionBox';
import { scrollTo } from '../../../utils/ScrollHelper';
import { gettext } from '../../../utils/filters';
import CardHeader from '../CheckoutDetails/CardHeader';

const getSelectedPaymentMethod = (transaction) => {
  let selectedMethod = '';
  if (!transaction || !transaction.paymentMethods) {
    return selectedMethod;
  }
  selectedMethod = transaction.paymentMethods.selected_payment_method;
  return selectedMethod;
};

const isEbayMotorsTransaction = (transaction) => {
  if (transaction && transaction.parties) {
    const { parties } = transaction;
    const partner = parties.find((party) => party.role === 'partner');
    return partner && window.config.ebay_motors_email === partner.customer;
  }
};

class IntegrationPaymentForm extends PaymentForm {
  constructor(props) {
    super(props);
    this._setPaymentType = this._setPaymentType.bind(this);
    this.renderWireDetails = this.renderWireDetails.bind(this);
    this.validators = {
      acceptTerms: (value) =>
        value !== true
          ? gettext('You need to agree to the General Escrow Instructions.')
          : undefined,
    };
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillReceiveProps(nextProps) {
    if (nextProps.isCurrentStep && this.props.currentStep !== nextProps.currentStep) {
      scrollTo(this.Card);
    }
  }

  _setPaymentType(event, newValue) {
    this.props.setPaymentType(newValue);
  }

  render() {
    const {
      checkoutPaymentType,
      customerDetails,
      form,
      isCurrentStep,
      handleSubmit,
      submitFailed,
      submitting,
      error,
      moveToPrevStep,
      returnUrl,
      transaction,
      isAgreedTransaction,
      transactionCreationFailure,
      transactionCreationLoading,
      transactionCreationError,
      paymentMethodSelectionSuccess,
      paymentMethodSelectionFailure,
      paymentMethodSelectionError,
      paymentType,
      wireDetails,
      partner,
    } = this.props;
    const { showModal } = this.state;
    const paymentOptions = this._getPaymentOptions(transaction);
    const selectedPaymentMethod = getSelectedPaymentMethod(transaction);
    const disableSubmitAction =
      showModal || (!wireDetails && paymentType === PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER);

    let submitLabel = gettext('Agree & Continue');
    const isEbay = isEbayMotorsTransaction(transaction);
    if (!isEbay) {
      const submitLabelAction =
        this.props.paymentType === PaymentConstants.PAYMENT_METHODS.CREDIT_CARD ||
        this.props.paymentType === PaymentConstants.PAYMENT_METHODS.PAYPAL
          ? gettext('Pay')
          : gettext('Continue');
      const agreeLabel = gettext('Agree');

      submitLabel = isAgreedTransaction
        ? submitLabelAction
        : `${agreeLabel} & ${submitLabelAction}`;
    }

    if (!isCurrentStep) {
      return null;
    }

    if (paymentMethodSelectionSuccess) {
      if (paymentType === PaymentConstants.PAYMENT_METHODS.PAYPAL) {
        return (
          <PaypalRedirectionBox
            heading="Redirecting you to Paypal for payment..."
            isCurrentStep={
              paymentMethodSelectionSuccess &&
              paymentType === PaymentConstants.PAYMENT_METHODS.PAYPAL
            }
          />
        );
      }
      const { loading, success, partnerData } = partner;
      if (loading) return <Spinner />;
      let companyName = null;
      if (success && partnerData && partnerData.name) {
        companyName = partnerData.name;
      }
      const heading =
        checkoutPaymentType === 'credit_card'
          ? 'Your funds are being secured in Escrow'
          : 'Success! Your transaction has been updated. Please follow the payment instructions and wire the funds directly through your bank.  Escrow.com will confirm once your payment has been secured';
      return (
        <ConfirmationMessageBox
          heading={heading}
          isCurrentStep={
            paymentMethodSelectionSuccess && paymentType !== PaymentConstants.PAYMENT_METHODS.PAYPAL
          }
          returnUrl={returnUrl}
          companyName={companyName}
          transactionId={transaction.id}
        />
      );
    }

    return (
      <div
        className={classnames('checkout-card', 'checkout-card--noShadow', 'card', {
          'is-disabled': !isCurrentStep || transactionCreationLoading,
          'is-accomplished': selectedPaymentMethod,
        })}
        ref={(section) => {
          this.Card = section;
        }}
      >
        {transactionCreationLoading && <CardHeader heading={<Spinner />} />}
        <div className="checkout-card-content checkout-card-content--light">
          {submitFailed && error && <FormError error={error} />}
          {transactionCreationFailure && transactionCreationError && (
            <FormError error={transactionCreationError} />
          )}
          {paymentMethodSelectionFailure && paymentMethodSelectionError && (
            <FormError error={paymentMethodSelectionError} />
          )}
          {!transactionCreationFailure && (
            <div>
              <form
                name={form}
                onSubmit={handleSubmit(bindRoutineToReduxForm(selectPaymentMethod))}
              >
                <h3 className="checkout-heading">{gettext('Order Summary')}</h3>
                <TransactionSummaryContainer
                  transaction={transaction}
                  payBy={checkoutPaymentType}
                  viewerPartyEmail={customerDetails.email}
                  viewerPartyRole={TransactionConstants.TRANSACTION_ROLES.BUYER}
                  hideTotal={false}
                />
                {this.renderPaymentOptions(paymentOptions)}
                {!disableSubmitAction && this.renderTermsCheckbox(submitting)}
                <div className="checkout-actions">
                  <div className="checkout-actions-item checkout-actions-item--secondary">
                    <a
                      href="#"
                      className="checkout-card-secondaryAction"
                      onClick={moveToPrevStep}
                      data-tracking-name="back"
                    >
                      {gettext('Back')}
                    </a>
                  </div>
                  <div className="checkout-actions-item checkout-actions-item--primary">
                    <Button
                      type="submit"
                      data-e2e-target="checkout-payment-agree-btn"
                      disabled={disableSubmitAction || submitting}
                      data-tracking-name="agree"
                      className="checkout-card-btn"
                    >
                      {!disableSubmitAction && submitting ? <Spinner /> : submitLabel}
                    </Button>
                  </div>
                  <div className="checkout-actions-item" />
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: CheckoutConstants.PAYMENT_METHOD_FORM_NAME,
  enableReinitialize: true,
})(
  formValues(
    'paymentType',
    'buyerBankDetails.bankCountry',
    'buyerBankDetails.bankState',
    'buyerBankDetails.bankName',
    'buyerBankDetails.accountName',
    'buyerBankDetails.thirdPartyCheckbox'
  )(IntegrationPaymentForm)
);
