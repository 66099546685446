import React from 'react';
import { connect } from 'react-redux';
import ClipboardButton from 'react-clipboard.js';

import { newAPIKeySelector } from 'spa/selectors/APIIntegrationSelectors';
import Modal from 'spa/components/Modal';
import Icon from 'spa/components/Icon';

class APIKeysModal extends React.Component {
  constructor() {
    super();
    this.state = {
      copied: false,
    };
    this.onCopy = this.onCopy.bind(this);
  }

  onCopy() {
    this.setState({ copied: true });
    setTimeout(() => this.setState({ copied: false }), 3000);
  }

  render() {
    const { newAPIKey } = this.props;

    return (
      <Modal
        name="newAPIKey"
        modifier="contentCenter"
        title="Save your API Key"
        description="As a security best practice, Escrow API Keys are non-reversible.
                     That means this is the only time we will show you this API key. Never
                     share your API Keys with anyone. Escrow.com will never ask you for
                     your API key."
      >
        <div className="apiKey">
          <span id="newAPIKey" className="apiKey-code">
            {newAPIKey.key}
          </span>
          <ClipboardButton
            onSuccess={this.onCopy}
            data-clipboard-target="#newAPIKey"
            className="apiKey-btn btn btn--secondary btn--large"
          >
            {this.state.copied ? (
              <span className="apiKey-btn-inner">
                Copied
                <Icon name="ui-tick" className="apiKey-btn-icon" />
              </span>
            ) : (
              'Copy'
            )}
          </ClipboardButton>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  newAPIKey: newAPIKeySelector(state),
});

const APIKeysModalContainer = connect(mapStateToProps, null)(APIKeysModal);

export default APIKeysModalContainer;
