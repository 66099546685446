import { createRoutine } from 'redux-saga-routines';
import TwoFactorConstants from 'spa/constants/TwoFactorConstants';

export const getTwoFactorStatus = createRoutine(TwoFactorConstants.GET_STATUS);
export const enableAuthAppTwoFactor = createRoutine(TwoFactorConstants.ENABLE_AUTH_APP);
export const enableSMSTwoFactor = createRoutine(TwoFactorConstants.ENABLE_SMS);
export const disableTwoFactor = createRoutine(TwoFactorConstants.DISABLE_TWO_FACTOR);
export const validateTwoFactor = createRoutine(TwoFactorConstants.VALIDATE);
export const sendSMSTwoFactor = createRoutine(TwoFactorConstants.SEND_SMS_TWO_FACTOR);
export const sendVerificationEmail = createRoutine(TwoFactorConstants.SEND_VERIFICATION_EMAIL);
