var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/components/simpleTag.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
[], 
["text", "style", "specifier", "icon", "link", "attrs"], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("text", kwargs.hasOwnProperty("text") ? kwargs["text"] : "");
frame.set("style", kwargs.hasOwnProperty("style") ? kwargs["style"] : "");
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("icon", kwargs.hasOwnProperty("icon") ? kwargs["icon"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
var t_2 = "";if(runtime.contextOrFrameLookup(context, frame, "link")) {
t_2 += "<a class=\"simpleTag ";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "style"), env.opts.autoescape);
t_2 += " ";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "specifier"), env.opts.autoescape);
t_2 += " ";
if(runtime.contextOrFrameLookup(context, frame, "icon")) {
t_2 += "simpleTag--icon";
;
}
t_2 += "\" href=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "link"), env.opts.autoescape);
t_2 += "\" ";
t_2 += runtime.suppressValue(env.getFilter("xmlattr").call(context, runtime.contextOrFrameLookup(context, frame, "attrs")), env.opts.autoescape);
t_2 += ">";
;
}
else {
t_2 += "\n<button class=\"simpleTag ";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "style"), env.opts.autoescape);
t_2 += " ";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "specifier"), env.opts.autoescape);
t_2 += " ";
if(runtime.contextOrFrameLookup(context, frame, "icon")) {
t_2 += "simpleTag--icon";
;
}
t_2 += "\" ";
t_2 += runtime.suppressValue(env.getFilter("xmlattr").call(context, runtime.contextOrFrameLookup(context, frame, "attrs")), env.opts.autoescape);
t_2 += ">";
;
}
t_2 += "\n";
if(runtime.contextOrFrameLookup(context, frame, "icon")) {
t_2 += "<span class=\"simpleTag-icon\">";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "icon"), env.opts.autoescape);
t_2 += "</span>";
;
}
t_2 += "\n<span class=\"simpleTag-title\">";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "text"), env.opts.autoescape);
t_2 += "</span></a>\n";
if(runtime.contextOrFrameLookup(context, frame, "link")) {
t_2 += "</a>";
;
}
else {
t_2 += "\n</button>";
;
}
;
frame = frame.pop();
return new runtime.SafeString(t_2);
});
context.setVariable("_tag", macro_t_1);
output += "\n\n";
var macro_t_3 = runtime.makeMacro(
["text"], 
["specifier", "attrs", "icon", "link", "external"], 
function (l_text, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("text", l_text);
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("icon", kwargs.hasOwnProperty("icon") ? kwargs["icon"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("external", kwargs.hasOwnProperty("external") ? kwargs["external"] : "");
var t_4 = "";t_4 += runtime.suppressValue((lineno = 19, colno = 5, runtime.callWrap(macro_t_1, "_tag", context, [runtime.makeKeywordArgs({"text": l_text,"style": "","specifier": runtime.contextOrFrameLookup(context, frame, "specifier"),"icon": runtime.contextOrFrameLookup(context, frame, "icon"),"link": runtime.contextOrFrameLookup(context, frame, "link"),"attrs": runtime.contextOrFrameLookup(context, frame, "attrs")})])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_4);
});
context.addExport("default");
context.setVariable("default", macro_t_3);
output += "\n\n";
var macro_t_5 = runtime.makeMacro(
["text"], 
["specifier", "attrs", "icon", "link", "external"], 
function (l_text, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("text", l_text);
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
frame.set("attrs", kwargs.hasOwnProperty("attrs") ? kwargs["attrs"] : {});
frame.set("icon", kwargs.hasOwnProperty("icon") ? kwargs["icon"] : "");
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("external", kwargs.hasOwnProperty("external") ? kwargs["external"] : "");
var t_6 = "";t_6 += runtime.suppressValue((lineno = 23, colno = 5, runtime.callWrap(macro_t_1, "_tag", context, [runtime.makeKeywordArgs({"text": l_text,"style": "simpleTag--invert","specifier": runtime.contextOrFrameLookup(context, frame, "specifier"),"icon": runtime.contextOrFrameLookup(context, frame, "icon"),"link": runtime.contextOrFrameLookup(context, frame, "link"),"attrs": runtime.contextOrFrameLookup(context, frame, "attrs")})])), env.opts.autoescape);
;
frame = frame.pop();
return new runtime.SafeString(t_6);
});
context.addExport("defaultInvert");
context.setVariable("defaultInvert", macro_t_5);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/components/simpleTag.html"] , dependencies)