import React from 'react';
import PropTypes from 'prop-types';

import A from 'spa/components/A';

const FooterButton = ({ children, link }) => (
  <A className="integrationsPortal-footer-btn btn btn--secondary btn--hollow" link={link}>
    {children}
  </A>
);

FooterButton.propTypes = {
  children: PropTypes.string,
  link: PropTypes.shape({
    type: PropTypes.string,
    route: PropTypes.string,
    newTab: PropTypes.bool,
  }).isRequired,
};

export default FooterButton;
