export default {
  GOOGLE_MAPS_FIELDS: {
    subpremise: 'Street1',
    street_number: 'Street1',
    route: 'Street1',
    locality: 'City',
    administrative_area_level_1: 'State',
    administrative_area_level_2: 'State',
    administrative_area_level_3: 'State',
    administrative_area_level_4: 'State',
    administrative_area_level_5: 'State',
    administrative_area_level_6: 'State',
    administrative_area_level_7: 'State',
    sublocality: 'City',
    sublocality_level_1: 'City',
    sublocality_level_2: 'City',
    sublocality_level_3: 'City',
    sublocality_level_4: 'City',
    sublocality_level_5: 'City',
    postal_town: 'City',
    country: 'Country',
    postal_code: 'Zip',
  },
};

export const formFieldMapping = {
  address: ['Street1', 'Street2'],
  city: ['City'],
  state: ['State'],
  'post-code': ['Zip'],
  country: ['Country'],
};
