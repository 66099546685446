import React from 'react';
import { FormControl } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const CustomFormControl = ({ children, sx }) => {
  const theme = useTheme();
  return (
    <FormControl
      fullWidth
      sx={{
        marginTop: '8px',
        marginBottom: '6px',
        '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.secondaryLight.dark,
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: theme.palette.secondaryLight.dark,
        },
        color: theme.palette.secondary.dark,
        ...sx,
      }}
    >
      {children}
    </FormControl>
  );
};
