import { createRoutine } from 'redux-saga-routines';

import CheckoutConstants from 'spa/constants/CheckoutConstants';

export const setStep = (nextStep) => ({
  type: CheckoutConstants.ACTION_SET_STEP,
  payload: nextStep,
});

export const setReduxCustomerInformation = (v2PostBody) => ({
  type: CheckoutConstants.ACTION_SET_REDUX_CUSTOMER_DETAILS,
  payload: v2PostBody,
});

export const moveToNextStep = createRoutine(CheckoutConstants.ACTION_MOVE_TO_NEXT_STEP);
export const loadNextStepData = createRoutine(CheckoutConstants.ACTION_LOAD_NEXT_STEP_DATA);

export const moveToPrevStep = () => ({
  type: CheckoutConstants.ACTION_MOVE_TO_PREV_STEP,
});

export const saveCustomerDetails = createRoutine(CheckoutConstants.CUSTOMER_DETAILS_STEP_FORM);
export const submitCustomerDetails = createRoutine(CheckoutConstants.CUSTOMER_DETAILS_FORM);
export const authenticateCustomer = createRoutine(CheckoutConstants.AUTHENTICATION_STEP_FORM);

export const showCustomerDetails = createRoutine(CheckoutConstants.CUSTOMER_DETAILS_DISPLAY);

export const setPaymentType = (paymentType) => ({
  payload: {
    paymentType,
  },
  type: CheckoutConstants.SET_PAYMENT_TYPE,
});

export const setPaymentDetails = (details) => ({
  payload: details,
  type: CheckoutConstants.SET_PAYMENT_DETAILS,
});

export const setTransactionId = (transId) => ({
  payload: {
    transId,
  },
  type: CheckoutConstants.SET_TRANSACTION_ID,
});

export const setPaypalOrderDetails = (orderDetails) => ({
  payload: orderDetails,
  type: CheckoutConstants.SET_PAYPAL_ORDER_DETAILS,
});

export const setAuthorizedPayment = (details) => ({
  payload: details,
  type: CheckoutConstants.SET_AUTHORIZED_PAYMENT,
});

export const setStripePaymentLink = (link) => ({
  payload: {
    link,
  },
  type: CheckoutConstants.SET_STRIPE_PAYMENT_LINK,
});

export const selectPaymentMethod = createRoutine(CheckoutConstants.SELECT_PAYMENT_METHOD);

export const agreeAndPay = createRoutine(CheckoutConstants.AGREE_AND_PAY);

export const getCheckoutPartnerData = createRoutine(CheckoutConstants.GET_CHECKOUT_PARTNER_DATA);

export const returnToLogin = createRoutine(CheckoutConstants.RETURN_TO_LOGIN);

export const fetchTransactionAndPaymentMethods = createRoutine(
  CheckoutConstants.FETCH_TRANSACTION_AND_PAYMENT_METHODS
);

export const sendEscrowPayBuyerReminder = createRoutine(
  CheckoutConstants.SEND_ESCROW_PAY_BUYER_REMINDER
);

export const confirmPaymentMethod = createRoutine(CheckoutConstants.CONFIRM_PAYMENT_METHOD);
export const agreeWirePayment = createRoutine(CheckoutConstants.AGREE_WIRE_PAYMENT);

export const agreeStripePaymentRoutine = createRoutine(CheckoutConstants.AGREE_STRIPE_PAYMENT);
export const confirmStripePaymentRoutine = createRoutine(CheckoutConstants.CONFIRM_STRIPE_PAYMENT);

export const submitReceiveSms = createRoutine(CheckoutConstants.SUBMIT_RECEIVE_SMS);

export const agreePaymentReview = createRoutine(CheckoutConstants.AGREE_PAYMENT_REVIEW);
export const submitPaymentDetails = createRoutine(CheckoutConstants.SUBMIT_PAYMENT_DETAILS);
export const getCheckoutLabels = createRoutine(CheckoutConstants.GET_CHECKOUT_FLAGS);


export default {
  setStep,
  setStripePaymentLink,
  setReduxCustomerInformation,
  moveToNextStep,
  moveToPrevStep,
  loadNextStepData,
  saveCustomerDetails,
  submitCustomerDetails,
  authenticateCustomer,
  selectPaymentMethod,
  getCheckoutPartnerData,
  setPaymentType,
  returnToLogin,
  fetchTransactionAndPaymentMethods,
  sendEscrowPayBuyerReminder,
  confirmPaymentMethod,
  agreeStripePaymentRoutine,
  confirmStripePaymentRoutine,
  setPaymentDetails,
  agreeAndPay,
  submitPaymentDetails,
  getCheckoutLabels,
};
