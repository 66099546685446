import React from 'react';
import { useDispatch } from 'react-redux';
import QRCode from 'qrcode.react';
import { useForm } from 'react-hook-form';
import { savePhoneNumber } from 'spa/features/kyc/kycSlice';
import PhoneNumberField from 'spa/features/kyc/mobile/components/PhoneNumberField';
import MobilePageFormControl from 'spa/features/kyc/mobile/components/MobilePageFormControl';
import { EscrowButton } from 'spa/features/ui/EscrowButton';
import { SmallText } from 'spa/features/ui/EscrowTypography';
import { KYC_BUTTONS } from 'spa/constants/VerificationConstants';
import DesktopPage from '../components/DesktopPage';

const mobileLoginURL = 'https://www.escrow-sandbox.com/verify';
const logoPath = 'spa/../../build/images/favicons/escrow-logo-web-full.png';

const MobileTransferPage = () => {
  const dispatch = useDispatch();
  const { handleSubmit, control } = useForm();

  const onSubmit = ({ phoneNumberInput }) => {
    const phoneNumber =
      phoneNumberInput && phoneNumberInput[0] === '+' ? phoneNumberInput : `+${phoneNumberInput}`;

    // eslint-disable-next-line no-console
    console.log(`phoneNumber`, phoneNumber);

    dispatch(savePhoneNumber({ phoneNumber }));
  };

  return (
    <DesktopPage title="Scan the QR to start verifying on mobile" noButton>
      <QRCode
        value={mobileLoginURL}
        style={{ alignSelf: 'center', borderRadius: '1rem' }}
        size={256}
        imageSettings={{
          src: logoPath,
          height: 44,
          width: 44,
        }}
      />
      <SmallText sx={{ alignSelf: 'center', fontSize: '16px' }}>
        Or get a link sent to your phone
      </SmallText>
      <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center' }}>
        <form style={{ alignSelf: 'center', maxWidth: '300px' }}>
          <MobilePageFormControl>
            <PhoneNumberField name="phoneNumberInput" control={control} />
          </MobilePageFormControl>
        </form>
        <EscrowButton
          sx={{
            height: '55px',
            margin: '0px 10px',
            fontSize: '15px',
            padding: '0px 15px',
          }}
          onClick={handleSubmit(onSubmit)}
          variant="outlined"
        >
          {KYC_BUTTONS.SendLink}
        </EscrowButton>
      </div>
    </DesktopPage>
  );
};

export default MobileTransferPage;
