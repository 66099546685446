import { GlobalEventHandler } from '../utils/EventHandler';

let isOpen = false;

const menuSelector = () => document.querySelector('[data-target="user-menu"]');
const userControlSelector = () => document.querySelector('[data-target="user-control"]');

const toggleMenu = () => {
  const menu = menuSelector();
  const userControl = userControlSelector();
  menu.classList.toggle('is-active');
  userControl.classList.toggle('is-active');
  isOpen = !isOpen;
};

const closeMenu = (event) => {
  const menu = menuSelector();
  const userControl = userControlSelector();
  if (menu && userControl) {
    if (!menu.contains(event.target) && !userControl.contains(event.target)) {
      menu.classList.remove('is-active');
      userControl.classList.remove('is-active');
      isOpen = false;
    }
  }
};

GlobalEventHandler.addEventListener('click', '[data-toggle-userMenu]', toggleMenu);

window.addEventListener('click', closeMenu);
