import ModalConstants from 'spa/constants/ModalConstants';

function initModalAction(name) {
  return {
    type: ModalConstants.INIT_MODAL,
    name,
  };
}

function toggleModalAction(name) {
  return {
    type: ModalConstants.TOGGLE_MODAL,
    name,
  };
}

export { initModalAction, toggleModalAction };
