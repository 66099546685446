import React from 'react';
import PropTypes from 'prop-types';

import AuthenticationStore from '../../../stores/AuthenticationStore';
import NavLink from './NavLink';

const NavSection = ({ section, location }) => {
  const authenticated = AuthenticationStore.isAuthenticated();
  // We will assume all pages required authentication unless explicitly set to false
  if (!authenticated && section.authRequired !== false) {
    return null;
  }

  // if the seciton has a displaySectionTitle, call that with the current authentication
  // state, otherwise, default to displaying the title.
  // Display section title can be used to:
  //    - only display when authenticated;
  //      ({authenticated}) => return authenticated;
  //    - only display NOT authenticated:
  //      ({authenticated}) => return !authenticated;
  let displaySectionTitle = true;
  if (typeof section.displaySectionTitle === 'function') {
    displaySectionTitle = section.displaySectionTitle({ authenticated });
  }

  return (
    <div className="integrationsPortal-nav-section">
      {section.sectionTitle && displaySectionTitle && (
        <h3 className="integrationsPortal-nav-title">{section.sectionTitle}</h3>
      )}
      {section.items.map((navLink) => (
        <div key={navLink.label}>
          <NavLink navLink={navLink} location={location} />
          {navLink.items &&
            navLink.items.map((secondChild) => (
              <div key={secondChild.label} className="integrationsPortal-nav-contentLevel2">
                <NavLink navLink={secondChild} location={location} />
                {secondChild.items &&
                  secondChild.items.map((thirdChild) => (
                    <div key={thirdChild.label} className="integrationsPortal-nav-contentLevel3">
                      <NavLink navLink={thirdChild} location={location} />
                    </div>
                  ))}
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};

NavSection.propTypes = {
  section: PropTypes.object,
};

export default NavSection;
