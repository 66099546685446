import TaskConstants from 'spa/constants/TaskConstants';

function updateTaskRequest(name, status, taskList) {
  return {
    type: TaskConstants.UPDATE_TASK_REQUEST,
    name,
    status,
    taskList,
  };
}

function updateTaskSuccess(result) {
  return {
    type: TaskConstants.UPDATE_TASK_SUCCESS,
    ...result,
  };
}

function updateTaskFailure(message) {
  return {
    type: TaskConstants.UPDATE_TASK_FAILURE,
    message,
  };
}

function updateTaskFulfilled(name, taskList) {
  return {
    type: TaskConstants.UPDATE_TASK_FULFILLED,
    name,
    taskList,
  };
}

export { updateTaskRequest, updateTaskSuccess, updateTaskFailure, updateTaskFulfilled };
