import React from 'react';

import { SmallText, SmallLink } from '../../../ui/EscrowTypography';

export const EmailText = ({ email, testId = undefined }) => (
  <SmallLink data-testid={testId} style={{ overflowWrap: 'break-word' }} href={`mailto:${email}`}>
    {email}
  </SmallLink>
);

export const EmailOrYouText = ({ transactionRole: role, userRole, parties }) => (
  <SmallText variant="bold">
    {userRole === role ? 'You' : <EmailText email={parties[role].email} />}
  </SmallText>
);
