/* eslint-disable no-console */
/* eslint-disable quote-props */
/* eslint-disable dot-notation */
import React, { useEffect, useMemo, useCallback, useState, Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { MenuItem, TextField, Stack } from '@mui/material';

import { V3KYCMobilePages as Pages, kycV2ApiMapping } from 'spa/constants/VerificationConstants';
import VerificationConstants from 'spa/constants/VerificationConstants';
import {
  age18,
  isValidName,
  noStartTrailWhitespace,
  // validateVATNumber,
  date,
} from 'spa/components/form/validate';
import {
  CountryToAlpha2,
  Alpha3ToAlpha2,
  Alpha2VatCountries,
  // LocalNameCountries,
} from 'spa/constants/ISOCountryCodes';
import { getStateSelectOptions } from 'spa/components/CustomerVerification/V2/Fields/AddressFieldSet';

import MobilePage from 'spa/features/kyc/mobile/components/MobilePage';
import MobilePageFormControl from 'spa/features/kyc/mobile/components/MobilePageFormControl';
import { processKycV2RawIndividualFormData } from 'spa/sagas/VerificationSaga';
import {
  kycAccountTypeSelector,
  kycCustomerIdSelector,
  kycEmailSelector,
  kycIndividualDetailsSelector,
  kycPhoneNumberSelector,
  saveIndividualDetails,
} from 'spa/features/kyc/kycSlice';
import {
  useGetCustomerDetailsQuery,
  useUpdateCustomerDetailsMutation,
  useUpdateV4CustomerDetailsMutation,
} from 'spa/features/kyc/kycApi';
import { ERROR_TYPES } from 'spa/constants/VerificationConstants';
import { errorMessage, required } from 'spa/features/kyc/utils';
import { SmallText } from 'spa/features/ui/EscrowTypography';
import AddressAutocomplete from 'mui-address-autocomplete';
import AutocompleteAddressConstants from '../../../../../../constants/AutocompleteAddressConstants';
import { mapFormDataToRequestBody } from '../../../../../../utils/DataMapping';
import { SelectField } from '../../components/SelectField';
import { DatePickerField } from '../../components/DatePickerField';
import KYCAlert from '../../components/KYCAlert';

const IndividualDetailsPage = () => {
  const dispatch = useDispatch();

  const phoneNumber = useSelector(kycPhoneNumberSelector);
  const email = useSelector(kycEmailSelector);
  const accountType = useSelector(kycAccountTypeSelector);
  const customerId = useSelector(kycCustomerIdSelector);
  const individualDetails = useSelector(kycIndividualDetailsSelector);

  const [manualAddress, setManualAddress] = useState(false);

  useEffect(() => {
    if (
      individualDetails['country'] ||
      individualDetails['address'] ||
      individualDetails['city'] ||
      individualDetails['state'] ||
      individualDetails['post-code']
    ) {
      setManualAddress(true);
    }
  }, [individualDetails]);

  const [
    updateCustomerDetails,
    { isLoading: isLoadingLegacy, error: errorLegacy, isSuccess: isSuccessLegacy },
  ] = useUpdateCustomerDetailsMutation();
  const [
    updateV4CustomerDetails,
    { isLoading: isLoadingV4, error: errorV4, isSuccess: isSuccessV4 },
  ] = useUpdateV4CustomerDetailsMutation();

  const { data: customerData } = useGetCustomerDetailsQuery();
  // eslint-disable-next-line no-console
  console.log('customerDetails: ', customerData);

  const isSuccess = isSuccessLegacy && isSuccessV4;
  const isLoading = isLoadingLegacy || isLoadingV4;
  const submissionError = errorV4 || errorLegacy;

  // eslint-disable-next-line no-console
  console.log('errorLegacy', errorLegacy);
  // eslint-disable-next-line no-console
  console.log('errorV4', errorV4);
  // eslint-disable-next-line no-console
  console.log('submissionError', submissionError);

  const onSubmitDetails = useCallback(
    (rawData) => {
      dispatch(saveIndividualDetails(rawData));

      // eslint-disable-next-line no-console
      console.log('data', rawData);

      const data = {
        ...rawData,
        phone: phoneNumber,
        email: email,
        'is-company': accountType === VerificationConstants.ACCOUNT_TYPE_COMPANY,
      };

      const addressDetails = {
        address: {
          state: data.state,
          country: data.country,
        },
      };
      const processedData = processKycV2RawIndividualFormData(data);
      const customerDetails = mapFormDataToRequestBody(kycV2ApiMapping, processedData);

      // eslint-disable-next-line no-console
      console.log('customerDetails', customerDetails);
      // eslint-disable-next-line no-console
      console.log('addressDetails', addressDetails);

      updateCustomerDetails({ customerId, customerDetails });
      // Sets ISO code for GST Tax as per D141759
      updateV4CustomerDetails({ addressDetails });
    },
    [
      accountType,
      customerId,
      dispatch,
      email,
      phoneNumber,
      updateCustomerDetails,
      updateV4CustomerDetails,
    ]
  );

  const defaultCountry =
    (Alpha3ToAlpha2[window.config.geoip_country] &&
      Alpha3ToAlpha2[window.config.geoip_country].toUpperCase()) ||
    'US';

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    resetField,
    formState: { isValid },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      'first-name': individualDetails['first-name'],
      'middle-name': individualDetails['middle-name'],
      'last-name': individualDetails['last-name'],
      'date-of-birth': individualDetails['date-of-birth'] || null,
      country: individualDetails['country'] || defaultCountry,
      address: individualDetails['address'],
      city: individualDetails['city'],
      state: individualDetails['state'],
      'post-code': individualDetails['post-code'],
      occupation: individualDetails['occupation'],
      'vat-country-code': individualDetails['vat-country-code'],
      'vat-number': individualDetails['vat-number'],
      'local-fname': individualDetails['local-fname'],
      'local-lname': individualDetails['local-lname'],
    },
  });

  const selectedCountry = watch('country');
  // eslint-disable-next-line no-console
  console.log('selectedCountry', selectedCountry);

  const vatCountries = useMemo(() => [...Alpha2VatCountries, 'EL'], []);

  useEffect(() => {
    if (vatCountries.includes(selectedCountry)) {
      setValue('vat-country-code', selectedCountry);
    }
  }, [selectedCountry, setValue, vatCountries]);

  const fillAddress = useCallback(
    (autocompleteValue) => {
      console.log('autocompleteValue', autocompleteValue);
      setManualAddress(true);
      const address = autocompleteValue.address_components;
      const addressDict = {
        Street1: '',
        Street2: '',
        City: '',
        State: '',
        Zip: '',
        Country: '',
      };

      const addressSortedAscSpecific = address.reverse();

      addressSortedAscSpecific.forEach((component) => {
        const mappedType = AutocompleteAddressConstants.GOOGLE_MAPS_FIELDS[component.types[0]];
        if (mappedType === 'Country') {
          addressDict[mappedType] = component.short_name;
        } else if (mappedType === 'State') {
          if (!addressDict.State) {
            const matchedIsoStates = getStateSelectOptions(addressDict.Country).filter(
              (ISOstate) =>
                ISOstate.value.toLowerCase().includes(component.long_name.toLowerCase()) ||
                ISOstate.value.toLowerCase() === component.short_name.toLowerCase()
            );
            if (matchedIsoStates.length >= 1) {
              addressDict.State = matchedIsoStates[0].value;
            }
          }
        } else {
          if (addressDict[mappedType]) {
            addressDict[mappedType] += ' ';
          }
          addressDict[mappedType] += component.long_name;
        }
      });
      console.log('addressDict', addressDict);
      // setValue of fields
      setValue('country', addressDict.Country);
      setValue('state', addressDict.State);
      setValue('address', addressDict.Street1);
      setValue('city', addressDict.City);
      setValue('post-code', addressDict.Zip);
    },
    [setValue]
  );

  return (
    <MobilePage
      title="Enter your details"
      ctaText="Continue"
      onSubmit={handleSubmit((data) => {
        if (isValid) {
          onSubmitDetails(data);
        }
      })}
      nextPage={Pages.ID_SELECTION}
      nextPageTrigger={isSuccess}
      disableButtons={isLoading}
    >
      <form>
        <Stack direction="column">
          {submissionError && (
            <KYCAlert
              sx={{ marginBottom: '32px' }}
              isError={submissionError}
              errorType={ERROR_TYPES.SUBMISSION_ERROR}
              xRequestId={submissionError.xRequestId}
            />
          )}
          <MobilePageFormControl>
            <Controller
              name="first-name"
              control={control}
              rules={{ validate: { required, noStartTrailWhitespace, isValidName } }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  disabled={isLoading}
                  error={!!error}
                  helperText={errorMessage(error)}
                  id="first-name"
                  label="First name"
                />
              )}
            />
          </MobilePageFormControl>
          <MobilePageFormControl>
            <Controller
              name="middle-name"
              control={control}
              rules={{ validate: { noStartTrailWhitespace, isValidName } }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  disabled={isLoading}
                  error={!!error}
                  helperText={errorMessage(error)}
                  id="middle-name"
                  label="Middle name (optional)"
                />
              )}
            />
          </MobilePageFormControl>
          <MobilePageFormControl>
            <Controller
              name="last-name"
              control={control}
              rules={{ validate: { required, noStartTrailWhitespace, isValidName } }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  helperText={errorMessage(error)}
                  disabled={isLoading}
                  id="last-name"
                  label="Last name"
                />
              )}
            />
          </MobilePageFormControl>
          <MobilePageFormControl>
            <Controller
              name="date-of-birth"
              control={control}
              rules={{ validate: { required, age18, date } }}
              render={({ field, fieldState: { error } }) => (
                <DatePickerField
                  {...field}
                  id="date-of-birth"
                  disabled={isLoading}
                  error={!!error}
                  helperText={errorMessage(error)}
                  label="Date of birth"
                  disableFuture
                  ignoreTimezone
                />
              )}
            />
          </MobilePageFormControl>
          <MobilePageFormControl>
            <Controller
              name="country"
              control={control}
              rules={{ validate: { required } }}
              render={({ field, fieldState: { error } }) => (
                <SelectField
                  {...field}
                  id="country"
                  error={!!error}
                  disabled={isLoading}
                  label="Country"
                  helpertext={errorMessage(error)}
                  onChange={(event) => {
                    console.log('onChange Country');
                    setValue('country', event.target.value);
                    resetField('state');
                  }}
                >
                  {Object.entries(CountryToAlpha2).map(([name, alpha2]) => (
                    <MenuItem key={alpha2} value={alpha2}>
                      {name}
                    </MenuItem>
                  ))}
                </SelectField>
              )}
            />
          </MobilePageFormControl>
          <MobilePageFormControl>
            {manualAddress ? (
              <Controller
                name="address"
                control={control}
                rules={{ validate: { required } }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    helperText={errorMessage(error)}
                    disabled={isLoading}
                    id="address"
                    label="Address"
                  />
                )}
              />
            ) : (
              <Controller
                name="address"
                control={control}
                rules={{ validate: { required } }}
                render={({ field, fieldState: { error } }) => (
                  <AddressAutocomplete
                    label="Address"
                    renderInput={(props) => (
                      <TextField
                        {...field}
                        error={!!error}
                        label="Address"
                        helperText={errorMessage(error)}
                        {...props}
                      />
                    )}
                    onChange={(_, value) => {
                      console.log('onChanged', value);
                      fillAddress(value);
                    }}
                    requestOptions={{
                      types: ['address'], // Restrict to address types
                      componentRestrictions: {
                        country: watch('country'), // Limit to chosen country addresses
                      },
                    }}
                  />
                )}
              />
            )}
          </MobilePageFormControl>
          {!manualAddress ? (
            <SmallText onClick={() => setManualAddress(true)}>
              <span style={{ textDecorationLine: 'underline' }}>Enter your address manually</span>
            </SmallText>
          ) : (
            <Fragment>
              <MobilePageFormControl>
                <Controller
                  name="city"
                  control={control}
                  rules={{ validate: { required } }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={!!error}
                      helperText={errorMessage(error)}
                      disabled={isLoading}
                      id="city"
                      label="City"
                    />
                  )}
                />
              </MobilePageFormControl>
              <MobilePageFormControl>
                <Controller
                  name="state"
                  control={control}
                  rules={{ validate: { required } }}
                  render={({ field, fieldState: { error } }) => (
                    <SelectField
                      {...field}
                      id="state"
                      error={!!error}
                      helpertext={errorMessage(error)}
                      disabled={isLoading}
                      label="State"
                    >
                      {getStateSelectOptions(selectedCountry).map(({ label, value }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </SelectField>
                  )}
                />
              </MobilePageFormControl>
              <MobilePageFormControl>
                <Controller
                  name="post-code"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={!!error}
                      helperText={errorMessage(error)}
                      id="zipcode"
                      disabled={isLoading}
                      label="Zip code"
                    />
                  )}
                />
              </MobilePageFormControl>
            </Fragment>
          )}
          {/* Temporarily disable fields while they're under discussion */}
          {/* {selectedCountry === 'CA' && (
            <MobilePageFormControl>
              <Controller
                name="occupation"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    helperText={errorMessage(error)}
                    id="occupation"
                    label="Occupation"
                    disabled={isLoading}
                  />
                )}
              />
            </MobilePageFormControl>
          )}
          {vatCountries.includes(selectedCountry) && (
            <React.Fragment>
              <MobilePageFormControl>
                <Controller
                  name="vat-country-code"
                  control={control}
                  rules={{ validate: { required } }}
                  shouldUnregister
                  render={({ field, fieldState: { error } }) => (
                    <SelectField
                      {...field}
                      error={!!error}
                      helpertext={errorMessage(error)}
                      id="vat-country"
                      label="VAT country code"
                      disabled={isLoading}
                    >
                      {vatCountries.map((alpha2) => (
                        <MenuItem key={alpha2} value={alpha2}>
                          {alpha2}
                        </MenuItem>
                      ))}
                    </SelectField>
                  )}
                />
              </MobilePageFormControl>
              <MobilePageFormControl>
                <Controller
                  name="vat-number"
                  control={control}
                  shouldUnregister
                  rules={{
                    validate: {
                      validateVAT: async (value) => validateVATNumber(value, getValues()),
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      id="vat"
                      error={!!error}
                      helperText={error ? 'Invalid VAT information' : null}
                      value={field.value || ''}
                      label="VAT number (optional)"
                      sx={{
                        '& .MuiOutlinedInput-root.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline':
                          {
                            borderColor: 'red',
                          },
                        '& .MuiInputLabel-root.Mui-error': {
                          color: 'red',
                        },
                      }}
                      disabled={isLoading}
                    />
                  )}
                />
              </MobilePageFormControl>
            </React.Fragment>
          )}
          {Object.keys(LocalNameCountries).includes(selectedCountry) && (
            <React.Fragment>
              <MobilePageFormControl>
                <Controller
                  name="local-fname"
                  control={control}
                  shouldUnregister
                  rules={{ validate: { required, noStartTrailWhitespace } }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={!!error}
                      helperText={errorMessage(error)}
                      id="local-first-name"
                      label={`First name in ${LocalNameCountries[selectedCountry]}`}
                      disabled={isLoading}
                    />
                  )}
                />
              </MobilePageFormControl>
              <MobilePageFormControl>
                <Controller
                  name="local-lname"
                  control={control}
                  rules={{ validate: { required, noStartTrailWhitespace } }}
                  shouldUnregister
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={!!error}
                      helperText={errorMessage(error)}
                      id="local-last-name"
                      label={`Last name in ${LocalNameCountries[selectedCountry]}`}
                      disabled={isLoading}
                    />
                  )}
                />
              </MobilePageFormControl>
            </React.Fragment>
          )} */}
        </Stack>
      </form>
    </MobilePage>
  );
};

export default IndividualDetailsPage;
