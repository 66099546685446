import React from 'react';
import Icon from 'spa/components/Icon';

const FormMessageBox = ({ message }) => (
  <div className="defaultForm-messageBox">
    <div className="defaultForm-messageBox-icon-wrapper">
      <Icon name="ui-alert-circle" className="defaultForm-messageBox-icon" />
    </div>
    <span className="defaultForm-messageBox-msg">{message}</span>
  </div>
);

export default FormMessageBox;
