import React from 'react';
import PropTypes from 'prop-types';
import HeaderLogo from './HeaderLogo';
import HeaderBackArrow from './HeaderBackArrow';
import HeaderTitle from './HeaderTitle';
import { gettext } from '../../../utils/filters';

const MainFunnelHeader = (props) => {
  const { title, hideBackArrow } = props;

  const helpAndFaqs = () => (
    <a className="headerV3-inner" href="/learn-more" target="_blank">
      <span className="headerV3-avatar avatar media--available@tablet">{gettext('?')}</span>
      <div className="headerV3-label media--hidden@tablet">{gettext('Help and FAQs')}</div>
    </a>
  );

  return (
    <div>
      <header className="headerV3 is-header-scrollTop is-sticky" data-sticky-header>
        <div className="headerV3-primary">
          <div className="headerV3-container section-container">
            <div className="headerV3-inner">
              {!hideBackArrow && <HeaderBackArrow href="/transactions" />}
              <HeaderLogo />
              <HeaderTitle title={title} />
            </div>
            {helpAndFaqs()}
          </div>
        </div>
      </header>
    </div>
  );
};

MainFunnelHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default MainFunnelHeader;
