import React, { Fragment } from 'react';
import MediaQuery from 'react-responsive';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import MediaQueryConstants from 'spa/constants/MediaQueryConstants';
import {
  transactionOrDraftSelector,
  transactionDraftTotalSelector,
  transactionTotalsSelector,
} from 'spa/selectors/TransactionSelectors';
import {
  checkoutPaymentTypeSelector,
  checkoutTokenSelector,
  checkoutStepSelector,
  checkoutLabelsLoadingSelector,
  checkoutLabelsDataSelector,
} from 'spa/selectors/CheckoutSelectors';
import { getCheckoutLabels } from 'spa/actions/CheckoutActions';
import Icon from 'spa/components/Icon';

import { getCurrencyTagFromApiCurrency, getCurrencyString } from 'escrow-common-js/dist/utils';
import { getPartiesByRole } from '../../../utils/V4Transaction';
import { gettext } from '../../../utils/filters';
import { getTransactionSummary } from '../../../utils/V4Transaction';

const TransactionFeeItemRow = ({ currency, title, totalPrice, type, loading }) => (
  <div className="checkout-fees-item" key={type}>
    <span className="checkout-fees-label">{loading ? '' : title}</span>
    <span className="checkout-fees-value">
      {loading ? (
        <Skeleton width={100} style={{ lineHeight: 1.25 }} />
      ) : (
        getCurrencyString(totalPrice, currency)
      )}
    </span>
  </div>
);

const isBlinkerTransaction = (transaction) => {
  if (transaction && transaction.parties) {
    const { parties } = transaction;
    const partner = parties.find((party) => party.role === 'partner');
    return partner && window.config.blinker_partner_emails.includes(partner.customer);
  }
};

class CheckoutHeaderContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetailsDropdown: false,
      showLogo: false,
      itemsHeaderExpanded: false,
    };

    const { getLabels } = props;
    getLabels();

    this.renderPartnerLogo = this.renderPartnerLogo.bind(this);
    this.toggleDetailsDropdown = this.toggleDetailsDropdown.bind(this);
    this.handleLogoOnLoad = this.handleLogoOnLoad.bind(this);
    this.renderFee = this.renderFee.bind(this);
  }

  _renderInspectionPeriod(inspectionPeriodSeconds) {
    const inspectionPeriodDays = inspectionPeriodSeconds / 86400;
    const labelSuffix = inspectionPeriodDays === 1 ? 'day' : 'days';
    return (
      <div className="checkout-item-body-desc">
        <span className="checkout-item-body-label">{gettext('Inspection period:')}</span>
        <span className="checkout-item-body-desc-suffix">{`${inspectionPeriodDays} ${labelSuffix}`}</span>
      </div>
    );
  }

  _renderDomainDetails(otherTerms) {
    const concierge = otherTerms.concierge ? 'Yes' : 'No';
    const withContent = otherTerms.withContent ? 'Yes' : 'No';

    return (
      <div>
        <div className="checkout-item-body-desc">
          <span className="checkout-item-body-label">{gettext('With Content:')}</span>
          <span className="checkout-item-body-desc-suffix">{`${withContent}`}</span>
        </div>
        <div className="checkout-item-body-desc">
          <span className="checkout-item-body-label">{gettext('With Concierge:')}</span>
          <span className="checkout-item-body-desc-suffix">{`${concierge}`}</span>
        </div>
      </div>
    );
  }

  _getEscrowFeePayer(escrowFeeSplit) {
    let escrowFeePayer = '';
    if (escrowFeeSplit.buyer === 1.0) {
      escrowFeePayer = gettext('Buyer');
    } else if (escrowFeeSplit.seller === 1.0) {
      escrowFeePayer = gettext('Seller');
    } else if (escrowFeeSplit.broker === 1.0) {
      escrowFeePayer = gettext('Broker');
    } else if (escrowFeeSplit.partner === 1.0) {
      escrowFeePayer = gettext('Partner');
    } else {
      const feeStatuses = [];

      if (escrowFeeSplit.buyer > 0.0) {
        feeStatuses.push(`${escrowFeeSplit.buyer * 100}% ${gettext('Buyer')}`);
      }

      if (escrowFeeSplit.seller > 0.0) {
        feeStatuses.push(`${escrowFeeSplit.seller * 100}% ${gettext('Seller')}`);
      }

      if (escrowFeeSplit.broker > 0.0) {
        feeStatuses.push(`${escrowFeeSplit.broker * 100}% ${gettext('Broker')}`);
      }
      escrowFeePayer = feeStatuses.join(' / ');
    }
    return escrowFeePayer;
  }

  _checkPartnerFeeLabel(partnerFeeLabel) {
    const hashPattern = /[A-Za-z0-9]{30}/;
    if (!partnerFeeLabel || hashPattern.test(partnerFeeLabel)) {
      return 'Partner Fee';
    }
    return partnerFeeLabel;
  }

  toggleDetailsDropdown() {
    this.setState((prevState) => ({
      showDetailsDropdown: !prevState.showDetailsDropdown,
    }));
  }

  handleLogoOnLoad() {
    this.setState({ ...this.state, showLogo: true });
  }

  renderPartnerLogo() {
    if (!this.props.token) {
      return null;
    }

    return (
      <div className="checkout-header-logo">
        <div className={classnames({ 'is-hidden': !this.state.showLogo })}>
          <img
            className="checkout-header-logo-img"
            alt="partner logo"
            src={`/api/checkout/${this.props.token}/logo`}
            onLoad={this.handleLogoOnLoad}
          />
        </div>
      </div>
    );
  }

  renderHeaderFee(transactionSummary) {
    return (
      <div className="checkout-fees-item checkout-fees--highlight">
        <span className="checkout-fees-label">Total</span>
        <span className="checkout-fees-value">
          {getCurrencyString(this.props.transactionTotal, transactionSummary.currency)}
        </span>
      </div>
    );
  }

  renderFee(transactionSummary, paymentOnlyFee = false) {
    const { labelsLoading } = this.props;
    const { combineBrokerFee, combinePartnerFee, partnerFeeLabel } = this.props.checkoutLabelData;
    const lineItemFees = transactionSummary.feeLineItemDataSquashed;
    const fees = transactionSummary.feeData.filter((item) => item.amountInclTax !== 0);
    const brokerFee = transactionSummary.feeLineItemDataSquashed.find(
      (item) => item.type === 'broker_fee'
    );
    const partnerFee = transactionSummary.feeLineItemDataSquashed.find(
      (item) => item.type === 'partner_fee'
    );

    const escrowFee = transactionSummary.feeData.find((fee) => fee.type === 'escrow');
    if (paymentOnlyFee) {
      return (
        <Fragment>
          {fees &&
            fees
              .filter((item) => item.type !== 'escrow' && item.amountInclTax > 0)
              .map((item) => (
                <TransactionFeeItemRow
                  key={item.type}
                  currency={transactionSummary.currency}
                  title={item.displayName}
                  totalPrice={item.amountInclTax}
                  loading={labelsLoading}
                />
              ))}
        </Fragment>
      );
    }
    return (
      <Fragment>
        {fees &&
          fees
            .filter((item) =>
              combineBrokerFee || combinePartnerFee ? item.type !== 'escrow' : true
            )
            .filter((item) => item.amountInclTax > 0)
            .map((item) => (
              <TransactionFeeItemRow
                key={item.type}
                currency={transactionSummary.currency}
                title={item.displayName}
                totalPrice={item.amountInclTax}
                loading={labelsLoading}
              />
            ))}
        {partnerFee && !combinePartnerFee && partnerFee.totalAmountPaid !== 0 && (
          <TransactionFeeItemRow
            key="broker_escrow_fee"
            currency={transactionSummary.currency}
            title={this._checkPartnerFeeLabel(partnerFeeLabel)}
            totalPrice={partnerFee.totalAmountPaid}
            loading={labelsLoading}
          />
        )}
        {lineItemFees &&
          lineItemFees
            .filter((item) => (combineBrokerFee ? item.type !== 'broker_fee' : true))
            .filter((item) => item.type !== 'partner_fee')
            .filter((item) => item.amountInclTax !== 0)
            .filter((item) => item.totalAmountPaid !== 0)
            .map((item) => (
              <TransactionFeeItemRow
                key={item.type}
                currency={transactionSummary.currency}
                title={item.typeDisplayName}
                totalPrice={item.totalAmountPaid}
                loading={labelsLoading}
              />
            ))}
        {combineBrokerFee && brokerFee && escrowFee && (
          <TransactionFeeItemRow
            key="broker_escrow_fee"
            currency={transactionSummary.currency}
            title="Processing Fee"
            totalPrice={brokerFee.totalAmountPaid + escrowFee.amountInclTax}
            loading={labelsLoading}
          />
        )}
        {combinePartnerFee && escrowFee && (
          <TransactionFeeItemRow
            key={escrowFee.type}
            currency={transactionSummary.currency}
            title={this._checkPartnerFeeLabel(partnerFeeLabel)}
            totalPrice={(partnerFee ? partnerFee.totalAmountPaid : 0) + escrowFee.amountInclTax}
            loading={labelsLoading}
          />
        )}
      </Fragment>
    );
  }

  render() {
    const buyerEmail = getPartiesByRole(this.props.transaction, 'buyer')[0].customer;
    const config = { payBy: this.props.paymentType };
    const viewerPartyRole = 'buyer';
    const transactionSummary = getTransactionSummary(
      this.props.transaction,
      buyerEmail,
      viewerPartyRole,
      config
    );
    const showPaymentOnlyFee = isBlinkerTransaction(this.props.transaction);
    const nonFeeItems = transactionSummary.lineItemData.filter((item) => !item.isFeeItem);
    const feeSplit = showPaymentOnlyFee
      ? null
      : this._getEscrowFeePayer(nonFeeItems[0].escrowFeeSplitsByRole);

    return (
      <div>
        <div className="checkout-header checkout-header--motorVehicle">
          <div className="checkout-header-logo-container">
            {this.renderPartnerLogo()}
            <MediaQuery minWidth={MediaQueryConstants.MIN_BREAKPOINT.MOBILE}>
              {this.renderHeaderFee(transactionSummary)}
            </MediaQuery>
          </div>
          {nonFeeItems && (
            <div className="checkout-item">
              {nonFeeItems[0].extraAttributes && nonFeeItems[0].extraAttributes.image_url && (
                <div className="checkout-item-view">
                  <figure className="checkout-item-figure">
                    <img
                      className="checkout-item-img"
                      src={nonFeeItems[0].extraAttributes.image_url}
                      alt="listing-preview"
                    />
                  </figure>
                </div>
              )}
              <div className="checkout-item-content">
                <h3 className="checkout-item-header">
                  <span className="checkout-item-header-name">{nonFeeItems[0].title}</span>
                  <span className="checkout-item-header-price">
                    {getCurrencyString(nonFeeItems[0].totalAmountPaid, transactionSummary.currency)}
                  </span>
                </h3>
                <div className="checkout-item-body">
                  <div className="checkout-item-body-content">
                    <p className="checkout-item-body-desc">{nonFeeItems[0].description}</p>
                    {nonFeeItems[0].type === 'domain_name' &&
                      this._renderDomainDetails(nonFeeItems[0].otherTerms)}
                    {!showPaymentOnlyFee &&
                      nonFeeItems[0].inspectionPeriodSeconds &&
                      this._renderInspectionPeriod(nonFeeItems[0].inspectionPeriodSeconds)}
                    {feeSplit && (
                      <div className="checkout-item-body-desc">
                        <span className="checkout-item-body-label">
                          {gettext('Escrow fee payer: ')}
                        </span>
                        <span className="checkout-item-body-desc-suffix">{`${feeSplit}`}</span>
                      </div>
                    )}
                    {nonFeeItems[0].extraAttributes.vin && (
                      <div className="checkout-item-body-desc">
                        <span className="checkout-item-body-label">{gettext('Vin:')}</span>
                        <span className="checkout-item-body-desc-suffix">{`${nonFeeItems[0].extraAttributes.vin}`}</span>
                      </div>
                    )}
                    {nonFeeItems[0].extraAttributes.odometer && (
                      <div className="checkout-item-body-desc">
                        <span className="checkout-item-body-label">{gettext('Odometer:')}</span>
                        <span className="checkout-item-body-desc-suffix">{`${nonFeeItems[0].extraAttributes.odometer}`}</span>
                      </div>
                    )}
                  </div>
                  <MediaQuery minWidth={MediaQueryConstants.MIN_BREAKPOINT.MOBILE}>
                    <div className="checkout-item-body-fees">
                      {this.renderFee(transactionSummary, showPaymentOnlyFee)}
                    </div>
                  </MediaQuery>
                </div>
              </div>
            </div>
          )}
          {nonFeeItems && nonFeeItems.length > 1 && (
            <Fragment>
              <div
                className={classnames(['checkout-accordion-panel'], {
                  'is-expanded': this.state.itemsHeaderExpanded,
                })}
              >
                {nonFeeItems.slice(1, nonFeeItems.length).map((displayItem) => (
                  <div className="checkout-accordion-item checkout-item" key={displayItem.key}>
                    {displayItem.extraAttributes && displayItem.extraAttributes.image_url && (
                      <div className="checkout-item-view">
                        <figure className="checkout-item-figure">
                          <img
                            className="checkout-item-img"
                            src={displayItem.extraAttributes.image_url}
                            alt="listing-preview"
                          />
                        </figure>
                      </div>
                    )}
                    <div className="checkout-item-content">
                      <h3 className="checkout-item-header">
                        <span className="checkout-item-header-name">{displayItem.title}</span>
                        <span className="checkout-item-header-price">
                          {getCurrencyString(
                            displayItem.totalAmountPaid,
                            transactionSummary.currency
                          )}
                        </span>
                      </h3>
                      <div className="checkout-item-body">
                        <div className="checkout-item-body-content">
                          <p className="checkout-item-body-desc">{displayItem.description}</p>
                          {displayItem.type === 'domain_name' &&
                            this._renderDomainDetails(displayItem.otherTerms)}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <footer className="checkout-accordion-footer">
                <button
                  className={classnames(['checkout-accordion-button btnIcon'], {
                    'is-active': !this.state.itemsHeaderExpanded,
                  })}
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      itemsHeaderExpanded: !this.state.itemsHeaderExpanded,
                    })
                  }
                >
                  <Icon name="ui-chevron-down" className="checkout-accordion-indicatorIcon" />
                </button>
              </footer>
            </Fragment>
          )}
          <MediaQuery maxWidth={MediaQueryConstants.MAX_BREAKPOINT.PHONE}>
            <div className="checkout-item-body-fees">
              {this.renderFee(transactionSummary, showPaymentOnlyFee)}
            </div>
            {this.renderHeaderFee(transactionSummary)}
          </MediaQuery>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const transaction = transactionOrDraftSelector(state);
  const currency = getCurrencyTagFromApiCurrency(transaction.currency);
  const paymentType = checkoutPaymentTypeSelector(state);
  const token = checkoutTokenSelector();
  const checkoutLabelData = checkoutLabelsDataSelector(state);
  const labelsLoading = checkoutLabelsLoadingSelector(state);

  let transactionTotal = (transactionTotalsSelector(transaction) || {})[paymentType];
  if (!transactionTotal) {
    transactionTotal = transactionDraftTotalSelector(state, true);
  }
  return {
    transaction,
    currency,
    transactionTotal,
    paymentType,
    token,
    currentStep: checkoutStepSelector(state),
    labelsLoading,
    checkoutLabelData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getLabels: () => {
    dispatch(getCheckoutLabels());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutHeaderContainer);
