import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';

import APIIntegration from 'spa/constants/APIIntegrationConstants';
import APIConstants from 'spa/constants/APIConstants';
import {
  getAPIKeySuccess,
  getAPIKeyFailure,
  createAPIKeys,
  deleteAPIKeySuccess,
  deleteAPIKeyFailure,
} from 'spa/actions/APIIntegrationActions';
import { apiError } from 'spa/actions/APIActions';
import { toggleModalAction } from 'spa/actions/ModalActions';

import API from '../../api';

export function* getAPIKey() {
  try {
    const apiKeys = yield call(API.getAPIKeys);

    yield put(getAPIKeySuccess(apiKeys));
  } catch (err) {
    if (err.error) {
      yield put(getAPIKeyFailure(err.error));
    } else {
      yield put(apiError(err.message));
    }
  }
}

export function* createAPIKey(action) {
  try {
    const { name } = action.payload.values;
    const apiKey = yield call(API.createAPIKey, name);

    yield put(createAPIKeys.success(apiKey));
    yield put(toggleModalAction('newAPIKey'));
  } catch (err) {
    let formError = null;
    if (err.type === APIConstants.UNPROCESSABLE_ENTITY) {
      const {
        errors: { error: _error, ...rest },
      } = err;
      formError = new SubmissionError({
        _error,
        ...rest,
      });
    } else {
      yield put(apiError(err.message));
      formError = new SubmissionError({
        _error: APIConstants.GENERIC_API_ERROR_MESSAGE,
      });
    }

    yield put(createAPIKeys.failure(formError));
  }
  yield put(createAPIKeys.fulfill());
}

export function* deleteAPIKey(action) {
  try {
    const { id } = yield action;
    const apiKey = yield call(API.deleteAPIKey, id);
    yield put(deleteAPIKeySuccess(apiKey));
  } catch (err) {
    if (err.error) {
      yield put(deleteAPIKeyFailure(err.error));
    } else {
      yield put(apiError(err.message));
    }
  }
}

export function* apiIntegrationWatcher() {
  yield takeLatest(APIIntegration.GET_APIKEY_REQUEST, getAPIKey);
  yield takeLatest(createAPIKeys.TRIGGER, createAPIKey);
  yield takeEvery(APIIntegration.DELETE_APIKEY_REQUEST, deleteAPIKey);
}

export default [apiIntegrationWatcher];
