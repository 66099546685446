import window from 'window-shim';
import localStorage from 'local-storage';

import AppDispatcher from '../dispatcher/AppDispatcher';
import AuthenticationConstants from '../constants/AuthenticationConstants';
import { ChangeEmitter } from '../utils/ChangeEmitter';
import FormActions from '../actions/FormActions';
import FormConstants from '../constants/FormConstants';

export class AuthenticationStore extends ChangeEmitter {
  constructor() {
    super();
    this.authenticated = !!window.escrowUser;
    this.customerID = null;
    this.email = null;

    if (window.escrowUser) {
      this.customerID = window.escrowUser.id;
      this.email = window.escrowUser.email;
    }
  }

  isAuthenticated() {
    return this.authenticated;
  }

  getEmail() {
    return this.email;
  }

  getCustomerID() {
    return this.customerID;
  }

  authenticationUrl() {
    let url;
    try {
      url = localStorage.get('authenticationUrl');
    } catch (err) {
      url = '';
    }
    return url;
  }

  setAuthenticationUrl(name) {
    localStorage.set('authenticationUrl', name);
  }

  handleViewAction(action) {
    if (action.actionType === AuthenticationConstants.SET_AUTH_URL) {
      this.setAuthenticationUrl(action.name);
    }
  }

  handleServerAction(action) {
    if (
      action.actionType === FormConstants.SUBMISSION_FAILURE &&
      (action.name === 'login-form' || action.name === 'partner-login-form')
    ) {
      setTimeout(() => {
        FormActions.showError(action.name, action.title);
      });
    }

    if (action.actionType === AuthenticationConstants.VERIFY) {
      this._onVerify(action);
    } else if (action.actionType === AuthenticationConstants.UNVERIFY) {
      this._onUnVerify();
    } else if (action.actionType === AuthenticationConstants.LOGOUT) {
      this._onUnVerify();
    }
  }

  _on2FAVerify() {
    this.authenticated = true;
    this.emitChange();
  }

  _onVerify(action) {
    this.authenticated =
      action.data.has_valid_cust_device_token ||
      !(action.data.authenticator || action.data.sms || action.data.has_valid_cust_device_token);
    this.email = action.data.uid;
    this.customerID = action.data.id;
    this.emitChange();
  }

  _onUnVerify() {
    this.authenticated = false;
    this.email = null;
    this.customerID = null;
    this.emitChange();
  }
}

const authenticationStore = new AuthenticationStore();
authenticationStore.dispatchToken = AppDispatcher.registerStore(authenticationStore);

export default authenticationStore;
