import QueryString from 'query-string';
import { connect } from 'react-redux';
import React, { Fragment } from 'react';

import { OfferManagementContainer } from 'spa/containers/Offer';
import { trackEscrowUserAction } from 'spa/actions/TrackingActions';
import Header from 'spa/components/Header';
import Footer from 'spa/containers/Footer';
import AuthenticationStore from '../../../stores/AuthenticationStore';
import { gettext } from '../../../utils/filters';

class OfferManagementPage extends React.Component {
  constructor(props) {
    super(props);

    const query = QueryString.parse(window.location.search);
    this.state = {
      token: query.token,
      source: query.source,
      email: query.email,
    };
  }

  componentDidMount() {
    this.props.trackPageSource(this.state.source);
  }

  render() {
    return (
      <Fragment>
        {!AuthenticationStore.isAuthenticated() && (
          <Header placeholder={gettext('Offer Management with Escrow.com')} />
        )}
        <div className="checkout section section--small section--dark">
          <div className="checkout-content section-container">
            <OfferManagementContainer
              token={this.state.token}
              source={this.state.source}
              email={this.state.email}
            />
          </div>
          <Footer />
        </div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  trackPageSource: (source) =>
    dispatch(
      trackEscrowUserAction({
        name: 'user_landing',
        section: source,
      })
    ),
});

export default connect(null, mapDispatchToProps)(OfferManagementPage);
