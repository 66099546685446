// Allows us to use function.bind
require('polyfill-function-prototype-bind');
// Allows us to use ES6 stuff today
require('babel-polyfill');
// Allows us to use dom functions
require('dom-shims');
// Allows us to use classList on dom elements in browsers that don't support it
require('classlist-polyfill');

// Touch device tests for mobile signup/login
require('browsernizr/test/touchevents');

require('whatwg-fetch');

// String.trim polyfill
if (!String.prototype.trim) {
  /* eslint-disable */
  String.prototype.trim = function () {
    return this.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
  };
  /* eslint-enable */
}
