import React from 'react';
import { Field } from 'redux-form';
import ToggleSwitch from 'spa/components/ToggleSwitch';
import InfoTooltip from 'spa/components/form/InfoTooltip';

class NotificationSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: Boolean(this.props.active),
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      active: Boolean(nextProps.active),
    });
  }

  render() {
    return (
      <div className={`notificationSetting ${this.state.active && 'is-active'}`}>
        <span className="notificationSetting-label">
          <span className="notificationSetting-label-text">{this.props.label}</span>
          {this.props.tooltip && (
            <InfoTooltip
              id={this.props.tooltipId}
              className="notificationSetting-tooltip"
              message={this.props.tooltip}
            />
          )}
        </span>
        <Field
          name={this.props.fieldName}
          component={ToggleSwitch}
          className="notificationSetting-icon"
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}

export default NotificationSetting;
