import { Stack } from '@mui/material';
import { EscrowAlert } from 'spa/features/ui/EscrowAlert';
import AlertTitle from '@mui/material/AlertTitle';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { usePatchTransactionMutation } from '../../transactionApi';
import { transactionSelector } from '../../transactionSlice';
import { EscrowButton } from 'spa/features/ui/EscrowButton';
import { useTheme } from '@mui/material/styles';

export const CancelTransaction = ({ transactionId }) => {
  const theme = useTheme();

  const [patchTransaction, { isLoading, error }] = usePatchTransactionMutation();
  const transaction = useSelector(transactionSelector(transactionId));
  const [errorMsg, setErrorMsg] = useState('');
  if (!transaction) {
    return null;
  }

  const handleReject = async () => {
    try {
      await patchTransaction({
        id: transaction.id,
        action: 'cancel',
      }).unwrap();
    } catch (e) {
      let errorMsgTmp =
        "Your cancellation agreement couldn't be confirmed due to a technical issue. Please refresh the page and try again.";
      if (e.xRequestId) {
        errorMsgTmp += ` If the issue persists, contact support@escrow.com and provide the id: ${e.xRequestId}`;
      }
      setErrorMsg(errorMsgTmp);
    }
  };

  return (
    <React.Fragment>
      <Stack sx={{ width: '100%' }}>
        {error && (
          <EscrowAlert
            sx={{ marginLeft: 0, marginRight: 0, backgroundColor: theme.palette.mono.xxlight }}
          >
            <AlertTitle>Unable to approve the transaction cancellation</AlertTitle>
            {errorMsg}
          </EscrowAlert>
        )}
        <EscrowButton
          sx={{ width: { xs: '100%', md: 'fit-content' } }}
          onClick={handleReject}
          variant="contained"
          disabled={isLoading}
        >
          Agree to cancellation
        </EscrowButton>
      </Stack>
    </React.Fragment>
  );
};
