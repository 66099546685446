import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Icon from 'spa/components/Icon';
import { gettext } from '../../../utils/filters';

const VerificationStatusPanel = (props) => {
  let statusIcon = null;
  let statusText = null;
  let statusStateClass = null;
  switch (props.verificationStatus) {
    case 'NOT_VERIFIED':
      statusText = gettext('Not Verified');
      statusStateClass = '';
      break;
    case 'SUBMITTED':
      statusIcon = <Icon name="ui-info-alt" className="verifyPage-icon" />;
      statusText = gettext('Documents Submitted - Pending Review');
      statusStateClass = 'is-pending';
      break;
    case 'EXTENDED_REQUESTED':
      statusIcon = <Icon name="ui-info-alt" className="verifyPage-icon" />;
      statusText = gettext('Additional Document Requested');
      statusStateClass = 'is-pending';
      break;
    case 'VERIFIED':
      statusText = gettext('Verified');
      statusStateClass = 'is-verified';
      break;
    case 'REJECTED':
      statusText = gettext('Rejected');
      statusStateClass = 'is-rejected';
      break;
    default:
      throw new Error('verificationStatus is not defined in PersonalVerificationPanel');
  }

  const e2e = props['data-e2e-target'];

  return (
    <div>
      <header className="verifyPage-card-header">
        {props.title && <h4 className="verifyPage-card-header-title">{props.title}</h4>}
        {props.verificationStatus && (
          <span className={`verifyPage-status ${statusStateClass}`}>
            {statusIcon}
            {statusText}
          </span>
        )}
      </header>
      <div>
        <div>
          <div className="verifyPage-card-content">{props.children}</div>
          <footer
            className={classnames({
              'verifyPage-card-footer': true,
              'is-highlighted': props.description || props.note,
            })}
          >
            {props.description && <p className="verifyPage-note">{props.description}</p>}
            {props.note && <p className="verifyPage-note">{props.note}</p>}
            {props.verifyDetailsLabel && props.verifyDetailsOnClick && (
              <div className="verifyPage-actions">
                {props.editDetailsLabel && props.editDetailsOnClick && (
                  <a
                    href="#"
                    role="button"
                    className="verifyPage-secondaryBtn"
                    data-tracking-name="edit-details"
                    onClick={props.editDetailsOnClick}
                    data-e2e-target={e2e ? `${e2e}-edit-button` : null}
                  >
                    {props.editDetailsLabel}
                  </a>
                )}
                <button
                  className={classnames('verifyPage-primaryBtn', 'btn', 'btn--secondary', {
                    'is-disabled': props.verifyDetailsStatus === 'DISABLED',
                    'is-hidden': props.verifyDetailsStatus === 'HIDDEN',
                  })}
                  onClick={props.verifyDetailsOnClick}
                  data-tracking-name="verify-details"
                  disabled={props.verifyDetailsStatus === 'DISABLED'}
                  data-e2e-target={e2e ? `${e2e}-verify-button` : null}
                >
                  {props.verifyDetailsLabel}
                </button>
              </div>
            )}
          </footer>
        </div>
      </div>
    </div>
  );
};

VerificationStatusPanel.propTypes = {
  verificationStatus: PropTypes.oneOf([
    'NOT_VERIFIED',
    'SUBMITTED',
    'VERIFIED',
    'REJECTED',
    'EXTENDED_REQUESTED',
  ]).isRequired,

  editDetailsLabel: PropTypes.string,
  editDetailsOnClick: PropTypes.func,

  verifyDetailsLabel: PropTypes.string,
  verifyDetailsOnClick: PropTypes.func,
  verifyDetailsStatus: PropTypes.oneOf(['NORMAL', 'HIDDEN', 'DISABLED']),

  title: PropTypes.string,
  description: PropTypes.string,
  note: PropTypes.string,
  children: PropTypes.element,
};

export default VerificationStatusPanel;
