import React, { useState, useEffect } from 'react';
import ReactSVG from 'react-svg';
import { CardBrandedConfirmation } from 'spa/components/Card';
import PaymentConstants from 'spa/constants/PaymentConstants';
import API from '../../../../api';

const CheckoutPlaidOAuthContainer = () => {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const redirectToCheckout = () => {
    const checkoutQuery = JSON.parse(localStorage.getItem('checkout_query'));
    Object.keys(checkoutQuery).map((key) => {
      if (checkoutQuery[key] === '') delete checkoutQuery[key];
    });
    window.location = `${window.config.www_base_url}/checkout?${new URLSearchParams(
      checkoutQuery
    )}&continue=${PaymentConstants.PAYMENT_METHODS.DIRECT_DEBIT}`;
  };

  useEffect(() => {
    const Plaid = window.Plaid;
    const handler = Plaid.create({
      token: localStorage.getItem('plaid_link_token'),
      receivedRedirectUri: window.location.href,
      onSuccess: async (publicToken, metadata) => {
        await API.getPlaidAccessToken(publicToken, metadata.accounts, metadata.institution);
        setLoading(false);
        setSuccess(true);
        redirectToCheckout();
      },
      onEvent: async (eventName, { error_code: errorCode }) => {
        if (['FAIL_OAUTH', 'ERROR', 'CLOSE_AUTH'].includes(eventName)) {
          setLoading(false);
          setError(eventName === 'ERROR' ? errorCode : eventName);
          redirectToCheckout();
        }
      },
    });
    handler.open();
  }, []);

  let header = '';
  let subheader = '';
  let logo;
  const errorState =
    !success && ['INSUFFICIENT_CREDENTIALS', 'FAIL_OAUTH', 'CLOSE_AUTH'].includes(error);
  let redirect = false;
  if (success) {
    logo = (
      <ReactSVG
        beforeInjection={(svg) => svg.classList.add('checkout-redirect-icon')}
        src={`../../../../build/images/global/icons/ui-circle-tick.svg`}
      />
    );
    header = 'Success!';
    subheader = 'Your bank account has been linked.';
  } else if (errorState) {
    logo = (
      <img
        className="checkout-redirect-icon"
        alt="Failed to link bank"
        src="../../../../build/images/escrow-pay/error.png"
      />
    );
    header = 'Uh Oh!';
    subheader = 'Sorry, the bank account you selected was not linked.';
  } else {
    redirect = true;
    header = 'Redirecting...';
    logo = (
      <ReactSVG
        beforeInjection={(svg) => svg.classList.add('checkout-redirect-icon')}
        src={`../../../../build/images/escrow-pay/loading-animation.svg`}
      />
    );
  }
  subheader = `${subheader}\nWe will be redirecting you back to your transaction shortly.`;
  return (
    <CardBrandedConfirmation
      loading={loading}
      error={errorState}
      success={success || redirect}
      logo={logo}
      header={header}
      subheader={subheader}
    />
  );
};

export default CheckoutPlaidOAuthContainer;
