const webhooksIntegrationSelector = (state) => state.webhooksIntegration;

const webhooksSelector = (state) => webhooksIntegrationSelector(state).webhooks;

const containerSelector = (state, container) => webhooksIntegrationSelector(state)[container] || {};

const loadingSelector = (state, container) => containerSelector(state, container).loading;

const successSelector = (state, container) => containerSelector(state, container).success;

export { webhooksIntegrationSelector, webhooksSelector, loadingSelector, successSelector };
