import { gettext } from '../utils/filters';

import AppDispatcher from '../dispatcher/AppDispatcher';
import { ChangeEmitter } from '../utils/ChangeEmitter';

import SearchConstants from '../constants/SearchConstants';

export class SearchResultsStore extends ChangeEmitter {
  constructor() {
    super();
    this.results = {};
    this.terms = {};
    this.suggestion = {};
    this.error = gettext('Please enter a search term'); // setting this to a default value causes this to be shown on page refresh

    this.handleServerAction = this.handleServerAction.bind(this);
  }

  getResults(section) {
    if (this.results[section]) {
      return {
        results: this.results[section].toJS(),
        terms: this.terms[section],
        suggestion: this.suggestion[section],
      };
    } else if (this.error) {
      return {
        results: [],
        terms: '',
        suggestion: '',
        error: this.error,
      };
    }
    return {
      results: [],
      terms: '',
      suggestion: '',
    };
  }

  handleServerAction(action) {
    if (action.actionType === SearchConstants.SEARCH_RESULTS) {
      const results = action.results.map((r) => r.set('link', `/${r.get('route')}`));
      this.results[action.category] = results;
      this.terms[action.category] = action.terms;
      if (action.suggestions) {
        const suggestion = action.suggestions
          .map((nextVal) => {
            if (nextVal.get('options').count() > 0) {
              return nextVal.get('options').get(0).get('text');
            }
            return nextVal.get('text');
          })
          .join(' ');
        this.suggestion[action.category] = suggestion;
      } else {
        this.suggestion[action.category] = '';
      }
      this.emitChange();
    }
  }

  handleViewAction(action) {
    if (action.actionType === SearchConstants.SEARCH_RESET) {
      this.results = {};
      this.terms = {};
      this.suggestion = {};
      this.emitChange();
    } else if (action.actionType === SearchConstants.SEARCH_INVALID) {
      this.results = {};
      this.input = {};
      this.error = action.error;
      this.emitChange();
    }
  }
}

const searchResultsStore = new SearchResultsStore();
searchResultsStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;
  const source = payload.source;

  if (source === 'SERVER_ACTION') {
    searchResultsStore.handleServerAction(action);
  }

  if (source === 'VIEW_ACTION') {
    searchResultsStore.handleViewAction(action);
  }
});
export default searchResultsStore;
