import UploadIcon from '@mui/icons-material/Upload';
import { Button } from '@mui/material';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';

import { customerKycFileForProofSelector } from 'spa/selectors/CustomerSelectors';
import {
  kycSetErrorAlert,
  kycUploadFile as kycUploadFileRoutine,
} from 'spa/actions/VerificationActions';
import { FILE_VALIDATION_ERROR_TO_KYC_MESSAGE } from 'spa/constants/VerificationConstants';
import FieldError from 'spa/components/form/FieldError';
import classNames from 'classnames';
import { composeValidators, required } from 'spa/components/form/validate';
import { validateKYCFile } from '../../../../utils/FormValidation';

const UploadBox = ({
  proofFor,
  label,
  fileStates,
  uploadFile,
  setErrorAlert,
  disabled,
  setUploadFileError,
}) => {
  const acceptedFileExtensions = ['.jpg', '.png', '.gif', '.tiff', '.bmp', '.pdf'];

  const UploadButtonText = () => {
    if (fileStates.loading) {
      return 'Uploading...';
    } else if (fileStates.success) {
      return 'Change uploaded file';
    }

    return 'Upload file';
  };

  const validateUploadedFile = (files) => {
    if (!files || !files.length) return undefined;

    const errors = validateKYCFile(files[0]);
    if (errors.length > 0) {
      return 'Invalid file';
    }
    return undefined;
  };

  const handleChange = (files) => {
    const file = files[0];
    const errors = validateKYCFile(file);
    const payload = { proofFor, file };
    if (errors.length > 0) {
      setErrorAlert(FILE_VALIDATION_ERROR_TO_KYC_MESSAGE[errors[0]]);
      setUploadFileError(payload);
      return;
    }
    uploadFile(payload);
  };

  const isDisabled = fileStates.loading || disabled;

  return (
    <Field
      name={`upload-file-${proofFor}`}
      validate={(files) => composeValidators([required, validateUploadedFile])(files)}
    >
      {({ input: fieldInput, meta }) => (
        <div
          className={classNames('field', {
            'is-invalid':
              meta.dirty && (meta.error || (!fileStates.loading && !fileStates.success)),
          })}
        >
          <label
            className={classNames('kyc-uploadBox', {
              'kyc-uploadBox--isInvalid':
                meta.dirty && (meta.error || (!fileStates.loading && !fileStates.success)),
            })}
          >
            <span
              className={classNames('kyc-uploadBox-label', {
                'kyc-uploadBox-label--isInvalid':
                  meta.dirty && (meta.error || (!fileStates.loading && !fileStates.success)),
              })}
            >
              {label}
            </span>
            <input
              disabled={isDisabled}
              data-uploading={fileStates.loading}
              className="fileUploader-input"
              title="" // remove tooltip
              type="file"
              accept={acceptedFileExtensions}
              onChange={({ target }) => {
                fieldInput.onChange(target.files);
                handleChange(target.files);
              }}
              onClick={(event) => {
                event.target.value = null;
              }}
            />
            <div className="kyc-uploadBox-text">
              <span className="kyc-uploadBox-text--header">
                {fileStates.file ? fileStates.file.name : 'Drag and drop'}
              </span>
              {!fileStates.file && (
                <Fragment>
                  <span>Accepted format: {acceptedFileExtensions.join(', ')}</span>
                  <span>Maximum file size: 100MB</span>
                </Fragment>
              )}
              {fileStates.file && (
                <span>File size: {(fileStates.file.size / 1000 ** 2).toFixed(2)}MB</span>
              )}
            </div>
            <Button
              disabled={isDisabled}
              color="secondaryLight"
              variant="contained"
              sx={{ width: '25rem' }}
              component="span"
            >
              <UploadIcon sx={{ marginRight: '0.5rem' }} />
              {UploadButtonText(fileStates)}
            </Button>
          </label>
          {meta.error && (meta.dirty || meta.submitFailed) && <FieldError message={meta.error} />}
        </div>
      )}
    </Field>
  );
};

const mapStateToProps = (state, ownProps) => ({
  fileStates: customerKycFileForProofSelector(state, ownProps.proofFor),
});

const mapDispatchToProps = (dispatch) => ({
  setUploadFileError: (payload) => dispatch(kycUploadFileRoutine.failure(payload)),
  uploadFile: (payload) => dispatch(kycUploadFileRoutine.trigger(payload)),
  setErrorAlert: (error) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    dispatch(kycSetErrorAlert(error));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadBox);
