import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  V3KYCMobilePages as Pages,
  KYC_FILES as FILES,
  KYC_BUTTONS as BUTTONS,
  KYC_TITLES as TITLES,
  KYC_SUBTITLES as SUBTITLES,
  KYC_PAGE_STATES,
} from 'spa/constants/VerificationConstants';
import { kycImageSelector, updateKycImageId, kycImagesSelector } from 'spa/features/kyc/kycSlice';
import { useUploadFileMutation, useVerifyCustomerMutation } from 'spa/features/kyc/kycApi';
import { ERROR_TYPES } from 'spa/constants/VerificationConstants';
import { dataURLtoFile } from 'spa/../utils/PhotoCapture';

import MobilePage from '../../components/MobilePage';
import KYCAlert from '../../components/KYCAlert';
import PhotoReview from '../../components/PhotoReview';
import RetakePhotoButton from '../../components/ExtraButtons/RetakePhotoButton';

const key = FILES.SELFIE;

const { INITIAL, LOADING, ERROR, SUCCESS } = KYC_PAGE_STATES;

const SelfieCaptureReviewPage = () => {
  const dispatch = useDispatch();

  const [pageState, setPageState] = useState(INITIAL);
  const [onSubmit, setOnSubmit] = useState(null);
  const [retryVerify, setRetryVerify] = useState(false);

  const [prevPageTrigger, setPrevPageTrigger] = useState(false);

  const kycImageDataUrl = useSelector(kycImageSelector(key));
  const kycImages = useSelector(kycImagesSelector);

  const [
    uploadFile,
    {
      data,
      isLoading: isLoadingUpload,
      isError: isErrorUpload,
      error: uploadError,
      isSuccess: isSuccessUpload,
      reset,
    },
  ] = useUploadFileMutation();

  const [
    verifyCustomerExtended,
    {
      isLoading: isLoadingVerify,
      isError: isErrorVerify,
      error: verifyError,
      reset: resetVerify,
      isSuccess: isSuccessVerify,
      isUninitialized: isUninitializedVerify,
    },
  ] = useVerifyCustomerMutation();

  const handleUpload = useCallback(async () => {
    const capturedFile = await dataURLtoFile(kycImageDataUrl, `${key}.png`);
    await uploadFile(capturedFile);
  }, [kycImageDataUrl, uploadFile]);

  const verifyExtendedKYC = useCallback(
    async (selfieFileId) => {
      const t2ExtendedDocumentsObject = [];

      t2ExtendedDocumentsObject.push({
        file_ids: [selfieFileId],
        proof_type: 'self_portrait_with_id',
        proof_for: ['photo_verification'],
      });

      const payload = {
        account: {
          type: 'individual',
        },
        trigger_extended_kyc: true,
        documents: t2ExtendedDocumentsObject,
      };

      await verifyCustomerExtended(payload);
    },
    [verifyCustomerExtended]
  );

  useEffect(() => {
    // Reset image id on initial page load
    dispatch(updateKycImageId({ fileKey: key, id: null }));
  }, [dispatch]);

  useEffect(() => {
    // Only trigger these side effects if the file has not yet been added to the state
    const selfieFileId = kycImages[key].id;

    if (!selfieFileId) {
      if (isSuccessUpload && data) {
        const uploadId = data.id;
        if (uploadId) {
          dispatch(updateKycImageId({ fileKey: key, id: uploadId }));
          verifyExtendedKYC(uploadId);
        }
      } else if (isErrorUpload) {
        setOnSubmit(() => reset);
        setPageState(ERROR);
      } else if (isLoadingUpload) {
        setPageState(LOADING);
      } else {
        setOnSubmit(() => handleUpload);
        setPageState(INITIAL);
      }
    }
  }, [
    data,
    dispatch,
    handleUpload,
    isErrorUpload,
    isLoadingUpload,
    isSuccessUpload,
    reset,
    setOnSubmit,
    setPageState,
    kycImages,
    verifyExtendedKYC,
  ]);

  useEffect(() => {
    // Only trigger these side effects if the file upload succeeded
    const selfieFileId = kycImages[key].id;

    if (selfieFileId) {
      if (!isUninitializedVerify) {
        if (isSuccessVerify) {
          setPageState(SUCCESS);
        } else if (isErrorVerify) {
          setOnSubmit(() => resetVerify);
          setRetryVerify(true);
          setPageState(ERROR);
        } else if (isLoadingVerify) {
          setPageState(LOADING);
        }
      } else if (retryVerify) {
        setOnSubmit(() => verifyExtendedKYC(selfieFileId));
        setRetryVerify(false);
      }
    }
  }, [
    isSuccessVerify,
    isErrorVerify,
    isLoadingVerify,
    isUninitializedVerify,
    setPageState,
    setOnSubmit,
    resetVerify,
    kycImages,
    verifyExtendedKYC,
    retryVerify,
    verifyError,
  ]);

  return (
    <MobilePage
      title={pageState === LOADING ? null : TITLES.SELFIE}
      subtitle={pageState === LOADING ? null : SUBTITLES.SELFIE}
      ctaText={pageState === INITIAL ? BUTTONS.SubmitPhoto : BUTTONS.TryAgain}
      onSubmit={onSubmit}
      nextPage={Pages.SELFIE_UPLOAD_SUCCESS}
      nextPageTrigger={pageState === SUCCESS}
      disableButtons={pageState === LOADING}
      noButton={pageState === LOADING ? true : null}
      ExtraButton={(props) => (
        <RetakePhotoButton
          {...props}
          onClick={() => {
            setPrevPageTrigger(true);
          }}
        >
          Retake photo
        </RetakePhotoButton>
      )}
      prevPageTrigger={prevPageTrigger}
    >
      {uploadError && (
        <KYCAlert
          isError={uploadError}
          errorType={ERROR_TYPES.PHOTO_UPLOAD_FAILED}
          xRequestId={uploadError.xRequestId}
        />
      )}
      {verifyError && (
        <KYCAlert
          isError={verifyError}
          errorType={ERROR_TYPES.SUBMISSION_FAILED}
          xRequestId={verifyError.xRequestId}
        />
      )}
      <PhotoReview fileKey={key} parentPageState={pageState} />
    </MobilePage>
  );
};

export default SelfieCaptureReviewPage;
