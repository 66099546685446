import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { Stepper, Step } from '@mui/material';
import StepLabel, { stepLabelClasses } from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Check from '@mui/icons-material/Check';
import { styled } from '@mui/material/styles';
import MediaQueryConstants from 'spa/constants/MediaQueryConstants';

const ColorLibLabel = styled(StepLabel)(({ theme }) => ({
  [`& .${stepLabelClasses.label}`]: {
    [`&.${stepLabelClasses.active}`]: {
      fontWeight: 700,
    },
    color: theme.palette.mono.xdark,
    fontSize: 14,
    fontWeight: 400,
  },
  [`& .${stepLabelClasses.alternativeLabel}`]: {
    [`&.${stepLabelClasses.completed}`]: {
      fontWeight: 400,
    },
  },
  '& .ColorLibLabel-optional': {
    color: theme.palette.mono.xdark,
    fontSize: 14,
  },
}));

const DetailsLabel = styled(StepLabel)(({ theme, orientation }) => ({
  [`& .${stepLabelClasses.label}`]: {
    letterSpacing: '0.15px',
    lineHeight: '1.43',
    color: theme.palette.mono.xdark,
    fontWeight: 400,
    fontSize: '14px',
    marginTop: orientation === 'horizontal' ? '8px' : '0',
    [`&.${stepLabelClasses.active}`]: {
      fontWeight: 500,
      color: theme.palette.mono.xdark,
    },
    [`&.${stepLabelClasses.completed}`]: {
      fontWeight: 400,
      color: theme.palette.mono.xdark,
    },
    [`&.${stepLabelClasses.alternativeLabel}`]: {
      marginTop: '8px',
    },
  },
}));

const ColorLibOptional = styled('div')(({ theme }) => ({
  color: theme.palette.mono.xdark,
  margin: '3rem',
  textAlign: 'center',
  fontSize: '14px',
}));

function makeColorLibConnectorStyle({ theme }) {
  return {
    [`&.${stepConnectorClasses.vertical}`]: {
      marginLeft: '21.5px',
    },
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: '21.5px',
      marginLeft: '1rem',
      width: 'calc(100% - 6rem)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.primary.main,
      },
      [`&.${stepConnectorClasses.lineVertical}`]: {
        borderColor: theme.palette.mono.main,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.primary.main,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mono.main,
      borderWidth: 1,
      borderRadius: 1,
      [`&.${stepConnectorClasses.lineVertical}`]: {
        minHeight: '30px',
      },
    },
  };
}

const ColorLibConnector = styled(StepConnector)(makeColorLibConnectorStyle);
const DetailsConnector = styled(StepConnector)(({ theme }) => {
  const style = makeColorLibConnectorStyle({ theme });
  style[`&.${stepConnectorClasses.alternativeLabel}`] = {
    ...style[`&.${stepConnectorClasses.alternativeLabel}`],
    borderColor: theme.palette.secondaryLight.dark,
    marginLeft: '-10px',
    marginRight: '-10px',
    width: 'auto',
    top: '20px',
  };
  style[`&.${stepConnectorClasses.active}`] = {
    ...style[`&.${stepConnectorClasses.active}`],
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.secondaryLight.dark,
    },
  };
  style[`&.${stepConnectorClasses.completed}`] = {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.secondaryLight.dark,
    },
  };
  style[`&.${stepConnectorClasses.vertical}`] = {
    marginLeft: '20px',
    marginTop: '-12px',
    marginBottom: '-12px',
  };
  return style;
});

function makeColorLibIconRootStyle({ theme, ownerState }) {
  return {
    border: '1px solid transparent',
    backgroundColor: 'transparent',
    borderRadius: '50%',
    color: 'white',
    display: 'flex',
    width: 45,
    height: 45,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '100%',
    ...(ownerState.active && {
      border: '1px solid',
      borderColor: theme.palette.primary.main,
      backgroundColor: 'transparent',
      width: 45,
      height: 45,
    }),
    '& .ColorLibIcon-Icon': {
      backgroundColor: theme.palette.mono.main,
      width: 35,
      height: 35,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .ColorLibIcon-completedIcon': {
      backgroundColor: theme.palette.primary.main,
      width: 35,
      height: 35,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .ColorLibIcon-check': {
      width: 27,
      height: 27,
    },
    '& .ColorLibIcon-activeIcon': {
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      width: 35,
      height: 35,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };
}

const ColorLibIconRoot = styled('div')(makeColorLibIconRootStyle);
const DetailsIconRoot = styled('div')(({ theme, ownerState }) => {
  const style = makeColorLibIconRootStyle({ theme, ownerState });

  Object.assign(style, {
    width: '40px',
    height: '40px',
  });
  if (ownerState.active) {
    Object.assign(style, {
      border: '1px solid',
      borderColor: theme.palette.secondaryLight.dark,
      backgroundColor: theme.palette.white.main,
      width: '40px',
      height: '40px',
      fontSize: '12px',
    });
  }
  style['& .ColorLibIcon-Icon'] = {
    ...style['& .ColorLibIcon-Icon'],
    backgroundColor: theme.palette.white.main,
    border: '1px solid',
    borderColor: theme.palette.mono.main,
    color: theme.palette.mono.xdark,
    width: '32px',
    height: '32px',
    fontSize: '12px',
  };
  style['& .ColorLibIcon-completedIcon'] = {
    ...style['& .ColorLibIcon-completedIcon'],
    backgroundColor: theme.palette.secondaryLight.dark,
    width: '32px',
    height: '32px',
  };
  style['& .ColorLibIcon-activeIcon'] = {
    ...style['& .ColorLibIcon-activeIcon'],
    backgroundColor: theme.palette.secondaryLight.dark,
    width: '32px',
    height: '32px',
  };
  style['& .ColorLibIcon-check'] = {
    width: '24px',
    height: '24px',
  };
  return style;
});

const ColorLibIcon = ({ active, completed, className, icon }) => {
  let stepIcon = <div className="ColorLibIcon-Icon">{icon}</div>;
  if (completed)
    stepIcon = (
      <div data-testid="completedIcon" className="ColorLibIcon-completedIcon">
        <Check className="ColorLibIcon-check" />
      </div>
    );
  if (active) {
    stepIcon = (
      <div data-testid="activeIcon" className="ColorLibIcon-activeIcon">
        {icon}
      </div>
    );
  }

  return (
    <ColorLibIconRoot ownerState={{ active, completed }} className={className}>
      {stepIcon}
    </ColorLibIconRoot>
  );
};

const DetailsIcon = ({ active, completed, className, icon }) => {
  let stepIcon = <div className="ColorLibIcon-Icon">{icon}</div>;
  if (completed)
    stepIcon = (
      <div data-testid="completedIcon" className="ColorLibIcon-completedIcon">
        <Check className="ColorLibIcon-check" />
      </div>
    );
  if (active) {
    stepIcon = (
      <div data-testid="activeIcon" className="ColorLibIcon-activeIcon">
        {icon}
      </div>
    );
  }

  return (
    <DetailsIconRoot ownerState={{ active, completed }} className={className}>
      {stepIcon}
    </DetailsIconRoot>
  );
};

const StepperWrapper = ({ isMobile, steps, subtitle, stepperVariant }) => {
  let connector;
  if (stepperVariant === 'detailsPage') {
    connector = <DetailsConnector />;
  } else {
    connector = <ColorLibConnector />;
  }

  let IconClass;
  if (stepperVariant === 'detailsPage') {
    IconClass = DetailsIcon;
  } else {
    IconClass = ColorLibIcon;
  }

  let IconLabelClass;
  if (stepperVariant === 'detailsPage') {
    IconLabelClass = DetailsLabel;
  } else {
    IconLabelClass = ColorLibLabel;
  }

  const orientation = isMobile ? 'vertical' : 'horizontal';
  return (
    <Fragment>
      <Stepper
        {...(isMobile
          ? { orientation: orientation, alternativeLabel: false }
          : { orientation: orientation, alternativeLabel: true })}
        connector={connector}
        data-testid="transaction-stepper"
        sx={{
          marginLeft: orientation === 'horizontal' ? '-8px' : '0',
          marginRight: orientation === 'horizontal' ? '-8px' : '0',
        }}
      >
        {steps.map((step) => (
          <Step
            data-testid={step.title}
            key={step.title}
            active={step.inProgress === true}
            completed={step.completed === true}
          >
            <IconLabelClass
              StepIconComponent={IconClass}
              optional={
                isMobile && subtitle && step.inProgress ? (
                  <span className="ColorLibLabel-optional">{subtitle}</span>
                ) : null
              }
              sx={{ letterSpacing: '0.15px', lineHeight: '143%' }}
              orientation={orientation}
            >
              {step.title}
            </IconLabelClass>
          </Step>
        ))}
      </Stepper>
      {!isMobile && subtitle && <ColorLibOptional>{subtitle}</ColorLibOptional>}
    </Fragment>
  );
};

const TransactionStepper = ({ steps, variant }) => {
  const subtitle = (steps.find((s) => s.subtitle) || {}).subtitle;
  const stepperVariant = variant !== undefined ? variant : 'progressPage';

  return steps && steps.length > 0 ? (
    <Fragment>
      <MediaQuery maxWidth={MediaQueryConstants.MAX_BREAKPOINT.MOBILE}>
        <StepperWrapper
          isMobile
          subtitle={subtitle}
          steps={steps}
          stepperVariant={stepperVariant}
        />
      </MediaQuery>
      <MediaQuery minWidth={MediaQueryConstants.MIN_BREAKPOINT.TABLET}>
        <StepperWrapper subtitle={subtitle} steps={steps} stepperVariant={stepperVariant} />
      </MediaQuery>
    </Fragment>
  ) : null;
};

TransactionStepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
      inProgress: PropTypes.bool,
      completed: PropTypes.bool,
    })
  ).isRequired,
  variant: PropTypes.oneOf(['progressPage', 'detailsPage']),
};

export default TransactionStepper;
