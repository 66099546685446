import { get } from 'lodash';

const baseSelector = (state) => state.form;

export function registeredFieldsSelector(state, formName) {
  return Object.keys(get(baseSelector(state), `${formName}.registeredFields`, {}));
}

export default {
  registeredFieldsSelector,
};
