import window from 'window-shim';
import AuthenticationStore from '../stores/AuthenticationStore';
import AppDispatcher from '../dispatcher/AppDispatcher';
import ModalConstants from '../constants/ModalConstants';
import APIKeyConstants from '../constants/APIKeyConstants';
import ErrorMessages from '../constants/ErrorMessages';

export class APIKeyActions {
  _post(url, body) {
    return window.fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      credentials: 'include',
    });
  }

  _patch(url, body) {
    return window.fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      credentials: 'include',
    });
  }

  _submitAPIKey(apiKeyName, customerID) {
    return this._post(`${window.config.v4_api_endpoint}/customer/${customerID}/api_key`, {
      name: apiKeyName,
    }).then((response) =>
      response.json().then((body) => {
        if (response.status !== 201) {
          if (body && body.error) {
            return window.Promise.reject(body.error);
          }
          return window.Promise.reject(ErrorMessages.TECHNICAL_DIFFICULTIES);
        }
        return window.Promise.resolve(body);
      })
    );
  }

  _removeAPIKey(id, customerID) {
    return this._patch(`${window.config.v4_api_endpoint}/customer/${customerID}/api_key/${id}`, {
      is_active: false,
    }).then((response) =>
      response.json().then((body) => {
        if (response.status !== 200 || body.is_active !== false) {
          return window.Promise.reject(body.error);
        }
        return window.Promise.resolve(body);
      })
    );
  }

  _reloadWindow() {
    window.location.reload();
  }

  submitAPIKey(apiKeyName) {
    const customerID = AuthenticationStore.customerID;
    this._submitAPIKey(apiKeyName, customerID)
      .then((response) => {
        AppDispatcher.handleViewAction({
          actionType: ModalConstants.MODAL_OPEN,
          name: APIKeyConstants.CONFIRMATION_MODAL_NAME,
        });
        AppDispatcher.handleServerAction({
          actionType: APIKeyConstants.API_KEY_CREATED,
          key: response,
        });
      })
      .catch((error) => {
        AppDispatcher.handleServerAction({
          actionType: APIKeyConstants.API_KEY_CREATION_FAILED,
          error: error,
        });
      });
  }

  removeAPIKey(id) {
    const customerID = AuthenticationStore.customerID;
    this._removeAPIKey(id, customerID)
      .then((response) => {
        AppDispatcher.handleServerAction({
          actionType: APIKeyConstants.API_KEY_REMOVED,
          key: response,
        });
        this._reloadWindow();
      })
      .catch((error) => {
        AppDispatcher.handleServerAction({
          actionType: APIKeyConstants.API_KEY_REMOVAL_FAILED,
          error: error,
        });
      });
  }
}

export default new APIKeyActions();
