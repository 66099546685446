import { EventHandler } from '../utils/EventHandler';
import TrackingActions from '../actions/TrackingActions';

export function findAttribute(element, attribute) {
  const attributeValue = element.getAttribute(attribute);
  if (attributeValue) {
    return attributeValue;
  } else if (element.parentElement) {
    return findAttribute(element.parentElement, attribute);
  }

  return '';
}

export class Tracking {
  constructor(element = document) {
    this.element = element;
    this.eventHandler = new EventHandler(element);
    this.eventHandler.addEventListener(
      'click',
      '[data-tracking-label], [data-tracking-name]',
      this._onClick.bind(this)
    );
  }

  _onClick(event) {
    const section = findAttribute(event.target, 'data-tracking-section');
    const subsection = findAttribute(event.target, 'data-tracking-subsection');
    const label = findAttribute(event.target, 'data-tracking-label');
    const name = findAttribute(event.target, 'data-tracking-name');
    let value = findAttribute(event.target, 'data-tracking-value');

    // Only allow boolean values (no user input should go into QTS)
    if (!value && event.target.type === 'checkbox') {
      value = !!event.target.checked;
    }

    const trackingEvent = {
      event: 'escrow_user_action',
      section: section,
      subsection: subsection,
      label: label,
      name: name,
      action: 'click',
      value: value,
    };
    TrackingActions.track(trackingEvent);
  }
}

export default new Tracking();
