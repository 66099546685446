import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const EscrowPaper = styled(Paper)(() => ({
  padding: '24px',
  borderRadius: '8px',
}));

EscrowPaper.defaultProps = {
  ...EscrowPaper.defaultProps,
  variant: 'outlined',
};

export { EscrowPaper };
