import { connect } from 'react-redux';
import Sidebar from 'spa/components/HeaderV3/Sidebar';
import { sidebarOpenSelector } from 'spa/selectors/HeaderSelectors';
import { toggleSidebar } from 'spa/actions/HeaderActions';

const mapStateToProps = (state) => ({
  sidebarOpen: sidebarOpenSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  toggleSidebar: () => dispatch(toggleSidebar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
