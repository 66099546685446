const FontFaceObserver = require('fontfaceobserver');
const Roboto = new FontFaceObserver('Roboto');
const Montserrat = new FontFaceObserver('Montserrat');
const root = document.documentElement;

Roboto.load()
  .then(() => {
    root.classList.add('is-primaryFont-loaded');
  })
  .catch(() => {});

Montserrat.load()
  .then(() => {
    root.classList.add('is-secondaryFont-loaded');
  })
  .catch(() => {});
