import classNames from 'classnames';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg';

import Icon from 'spa/components/Icon';
import {
  customerIsKycCompleted,
  isCustomerReadySelector,
  isCustomerExtendedKycRequirementLoading,
} from 'spa/selectors/CustomerSelectors';
import { getWindow } from '../../../utils/globals';

const Sidebar = (props) => {
  const { sidebarOpen, toggleSidebar, isKycCompleted, isCustomerReady } = props;
  const window = getWindow();

  const header = () => (
    <header className="sidebarV2-header">
      <button className="sidebarV2-close" onClick={() => toggleSidebar()}>
        <Icon name="ui-close" className="sidebarV2-close-icon" />
      </button>
      <a href="/transactions" className="sidebarV2-logo logo logo--dark" title="My Transactions">
        <ReactSVG src={`../../../../../build/images/global/escrow-logo-v3.svg`} />
      </a>
    </header>
  );

  const mainLinks = () => {
    const links =
      isCustomerReady && isKycCompleted
        ? [
            { name: 'My Transactions', link: '/transactions' },
            { name: 'My Integrations', link: '/integrations/portal' },
            { name: 'Help', link: '/helpdesk' },
            { name: 'Contact Us', link: '/contact-us' },
          ]
        : [
            { name: 'My Transactions', link: '/transactions' },
            { name: 'My Integrations', link: '/integrations/portal' },
            { name: 'Get Verified', link: '/verify' },
            { name: 'Help', link: '/helpdesk' },
            { name: 'Contact Us', link: '/contact-us' },
          ];

    return (
      <div className="sidebarV2-faction">
        {links.map((item) => (
          <div key={item.link} className="sidebarV2-item">
            <a key={item.link} href={item.link} className="sidebarV2-tab">
              {item.name}
            </a>
          </div>
        ))}
      </div>
    );
  };

  const accountLinks = () => {
    const links = [
      { name: 'Start New Transaction', link: window.config.transaction_choice },
      { name: 'My Profile', link: '/account-info' },
      { name: 'Log Out', link: `${window.config.my_escrow_base_url}/myescrow/logout.asp` },
    ];

    return (
      <div className="sidebarV2-faction">
        <ul className="sidebarV2-list">
          {links.map((item) => (
            <li key={item.link} className="sidebarV2-item">
              <a key={item.link} href={item.link} className="sidebarV2-tab">
                {item.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <Fragment>
      <aside className={classNames('sidebarV2', sidebarOpen ? 'sidebarV2-is-active' : '')}>
        {header()}
        <div className="sidebarV2-content">
          {mainLinks()}
          {accountLinks()}
        </div>
      </aside>
      <div
        className={classNames(
          'sidebarV2-backdrop',
          sidebarOpen ? 'sidebarV2-backdrop-is-active' : ''
        )}
        role="button"
        tabIndex={0}
        onClick={() => toggleSidebar()}
      />
    </Fragment>
  );
};

const mapStatesToProps = (state) => ({
  isKycCompleted: customerIsKycCompleted(state),
  isCustomerReady:
    isCustomerReadySelector(state) && !isCustomerExtendedKycRequirementLoading(state),
});

export default connect(mapStatesToProps)(Sidebar);
