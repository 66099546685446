import { Box, ListItem, Radio } from '@mui/material';
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';

export const SelectItem = ({ label, value, children, checked, onCheck }) => {
  const theme = useTheme();
  return (
    <ListItem
      sx={{
        padding: '8px 0px 8px 0px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          border: '1px solid #e0e0e0',
          borderRadius: '8px',
          padding: '16px',
          userSelect: 'none',
        }}
        role="button"
        tabIndex={0}
        onClick={() => onCheck(value)}
      >
        <Radio
          value={value}
          checked={checked}
          aria-label={label}
          sx={{
            '&.MuiButtonBase-root:hover': {
              backgroundColor: 'transparent',
            },
            '&.MuiRadio-root:hover .MuiSvgIcon-root, &.MuiRadio-root.Mui-checked:hover .MuiSvgIcon-root':
              {
                backgroundColor: 'transparent',
              },
            '&.MuiRadio-root:hover .MuiTouchRipple-root, &.MuiRadio-root.Mui-checked:hover .MuiTouchRipple-root':
              {
                display: 'none',
              },
            '&.MuiRadio-root.Mui-checked': {
              color: theme.palette.secondaryLight.dark,
            },
            marginRight: '8px',
          }}
        />
        {children}
      </Box>
    </ListItem>
  );
};
