import React, { Fragment } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const DEFAULT_THEME_COLOR = '#F4F2FF';
const DEFAULT_HIGHLIGHT_COLOR = 'rgba(150, 150, 150, 0.1)';

const DefaultSkeleton = (props) => (
  <SkeletonTheme
    color={props.color || DEFAULT_THEME_COLOR}
    highlightColor={props.highlightColor || DEFAULT_HIGHLIGHT_COLOR}
  >
    <Skeleton
      {...props}
      height="2rem"
      style={{
        borderRadius: props.isRound ? '50rem' : 0,
        lineHeight: 1.25,
      }}
    />
  </SkeletonTheme>
);

const TwoRowSkeleton = (props) => (
  <Fragment>
    <DefaultSkeleton />
    <div className="skeleton@mobile-line-break" />
    <div {...props}>
      <DefaultSkeleton />
    </div>
  </Fragment>
);

const InlineSkeleton = () => (
  <div className="skeleton-inline-wrapper">
    <div className="skeleton-inline-left">
      <DefaultSkeleton isRound />
    </div>
    <div className="skeleton-inline-right">
      <DefaultSkeleton isRound />
    </div>
  </div>
);

export { DefaultSkeleton, TwoRowSkeleton, InlineSkeleton };
