import { select, put, race, take, takeLatest, takeEvery, call, all } from 'redux-saga/effects';
import { unregisterField, change, SubmissionError } from 'redux-form';
import { delay } from 'redux-saga';
import { pick } from 'lodash';
import deepcopy from 'clone';
import { EKYC_MAX_ATTEMPTS } from 'escrow-common-js/dist/constants';

import APIConstants from 'spa/constants/APIConstants';
import { hasVatDetails } from 'spa/components/ContactDetails';
import {
  customerCompanyNameSelector,
  customerCountrySelector,
  customerKyc2RejectedSelector,
  customerKyc3RejectedSelector,
  customerKycInitPagesSelector,
  customerKycPagesSelector,
  customerKycUploadedFilesSelector,
} from 'spa/selectors/CustomerSelectors';
import {
  updateIndividualVerificationStatus,
  updateCompanyVerificationStatus,
  fluxGetCustomerRequest,
  verifyCustomerInformation as verifyCustomerInformationRoutine,
  electronicallyVerifyCustomer as electronicallyVerifyCustomerRoutine,
  setCustomerTaxNumber as setCustomerTaxNumberRoutine,
  getV4Customer as getV4CustomerRoutine,
  setExtendedKycRequirement as setExtendedKycRequirementRoutine,
} from 'spa/actions/UserActions';
import { CHECKOUT_FLOW, VERIFY_FLOW, TIER_1_FLOW } from 'spa/context/KYCContext';
import {
  submitPersonalVerificationForm as submitPersonalVerificationFormRoutine,
  submitElectronicVerificationForm as submitElectronicVerificationFormRoutine,
  submitCompanyVerificationForm as submitCompanyVerificationFormRoutine,
  submitCustomerDetailsForm as submitCustomerDetailsFormRoutine,
  partnerKYCSuccess as partnerKYCSuccessRoutine,
  kycGotoNextPage as kycGotoNextPageRoutine,
  kycGotoPrevPage as kycGotoPrevPageRoutine,
  kycSetIsCompany as kycSetIsCompanyRoutine,
  kycSetErrorAlert,
  kycClearErrorAlert,
  kycSubmitCustomerDetails as kycSubmitCustomerDetailsRoutine,
  kycSubmitIndividual as kycSubmitIndividualRoutine,
  kycUploadFile as kycUploadFileRoutine,
  kycSubmitExtended as kycSubmitExtendedRoutine,
  kycSubmitCompany as kycSubmitCompanyRoutine,
  kycClearFileUploads,
  kycAddInitPages,
  kycDequeueInitPages,
} from 'spa/actions/VerificationActions';
import { logError } from 'spa/actions/ErrorLoggingActions';
import { registeredFieldsSelector } from 'spa/selectors/FormSelectors';
import { checkoutPaymentTypeSelector } from 'spa/selectors/CheckoutSelectors';
import PaymentConstants from 'spa/constants/PaymentConstants';
import VerificationConstants, {
  apiMapping,
  kycV2ApiMapping,
  KYCAlpha2VatCountries,
  KYCErrorAlertMessages,
  KYCPages,
  INDIV_VERIFICATION_UPLOAD_OPTION_VALUE,
  ID_EXTENDED,
  ID_ADDRESS,
  ID_BACK,
  ID_FRONT,
  COMPANY_DOCUMENT,
  ACH_CHECKOUT_FLOW,
} from 'spa/constants/VerificationConstants';
import PhoneCountryCodes from 'spa/constants/PhoneCountryCodes';
import { Alpha2ToAlpha3 } from 'spa/constants/ISOCountryCodes';
import { urlSchemeRegex } from 'spa/components/form/validate';
import { processEKycSubmissionFields } from 'spa/sagas/UserSaga';
import { getUserVerificationStatuses as getUserVerificationStatusesRoutine } from 'spa/actions/UserActions';
import { getRequiredNextPage } from 'spa/utils/Verification';

import API from '../../api';
import { gettext } from '../../utils/filters';
import { getCountryDocument } from '../../utils/IdentificationDocuments';
import OlarkApi from '../../utils/OlarkApi';
import { mapFormDataToRequestBody } from '../../utils/DataMapping';
import ErrorMessages from '../../constants/ErrorMessages';
import UserActions from '../../actions/UserActions';
import AuthenticationStore from '../../stores/AuthenticationStore';
import NavigationStore from '../../stores/NavigationStore';
import OlarkStore from '../../stores/OlarkStore';

const processPersonalVerificationForm = (formData) => {
  const apiBody = {
    Files: [],
    TextInputs: [],
  };

  if (formData.idProofType) {
    apiBody.TextInputs.push({ Key: 'IDType', Value: formData.idProofType });
    if (formData.idContainsAddress) {
      apiBody.TextInputs.push(
        { Key: 'AddressProofType', Value: formData.idProofType },
        { Key: 'IdContainsAddress', Value: 'true' }
      );
    } else {
      apiBody.TextInputs.push(
        { Key: 'AddressProofType', Value: formData.addressProofType },
        { Key: 'IdContainsAddress', Value: 'false' }
      );
    }

    if (formData.photoVerificationFile || [] > 0) {
      apiBody.TextInputs.push({ Key: 'HasPhotoVerification', Value: 'true' });
    }

    const allFileData = [].concat(
      formData.idProofFile || [],
      formData.addressProofFile || [],
      formData.photoVerificationFile || []
    );
    for (const fileData of allFileData) {
      apiBody.Files.push(fileData.fileId);
    }
  }

  return apiBody;
};

const processRawFormData = (formData, userEmail) => {
  const processedData = deepcopy(formData);
  if (processedData.primaryPhoneCountry && processedData.primaryPhoneNumber) {
    const phonePrefix = PhoneCountryCodes[processedData.primaryPhoneCountry].countryCode;
    const phoneSuffix = processedData.primaryPhoneNumber;
    processedData.phone = `+${phonePrefix}${phoneSuffix}`;
    delete processedData.primaryPhoneCountry;
    delete processedData.primaryPhoneNumber;
  }

  if (processedData.phoneNumber) {
    processedData.phone = `+${processedData.phoneNumber}`;
    delete processedData.phoneNumber;
  }

  if (processedData['full-country'] || processedData.occupation) {
    delete processedData['full-country'];
    delete processedData.occupation; // Don't handle Occupation field for now
  }

  if (processedData['company-full-country']) {
    delete processedData['company-full-country'];
  }

  if (
    !processedData.vatDetails &&
    processedData['vat-country-code'] &&
    processedData['vat-number']
  ) {
    processedData.vatDetails = {
      vatCountry: processedData['vat-country-code'],
      vatNumber: processedData['vat-number'],
    };
    delete processedData['vat-country-code'];
    delete processedData['vat-number'];
  }

  if (processedData.country) {
    const personalCountry = PhoneCountryCodes[Alpha2ToAlpha3[processedData.country]].name;
    processedData.country = personalCountry;
  }

  if (processedData.companyCountry) {
    const companyCountry = PhoneCountryCodes[Alpha2ToAlpha3[processedData.companyCountry]].name;
    processedData.companyCountry = companyCountry;
  }

  if (processedData.firstName && processedData.middleName && processedData.lastName) {
    processedData.displayName = `${processedData.firstName} ${processedData.middleName} ${processedData.lastName}`;
  } else if (processedData.firstName && processedData.lastName) {
    processedData.displayName = `${processedData.firstName} ${processedData.lastName}`;
  }

  if (userEmail) {
    processedData.email = userEmail;
  }

  processedData.isCompany =
    processedData.accountType === VerificationConstants.ACCOUNT_TYPE_COMPANY;
  processedData.displayNameType = 'name';
  processedData.issueCheckInCompanyName = false;
  processedData.shippingAddress = null;
  processedData.useUsernameAsDisplayName = false;
  processedData.username = null;

  delete processedData.accountType;

  return processedData;
};

export const processKycV2RawIndividualFormData = (formData, userEmail) => {
  const processedData = deepcopy(formData);

  if (processedData['complete-primary-phone-number']) {
    const phoneData = processedData['complete-primary-phone-number'];
    processedData.phone = `${phoneData && phoneData[0] === '+' ? '' : '+'}${phoneData}`;
    delete processedData['complete-primary-phone-number'];
  }

  if (processedData.country) {
    const personalCountry = PhoneCountryCodes[Alpha2ToAlpha3[processedData.country]].name;
    processedData.country = personalCountry;
  }

  if (processedData['company-country']) {
    const companyCountry = PhoneCountryCodes[Alpha2ToAlpha3[processedData['company-country']]].name;
    processedData['company-country'] = companyCountry;
  }

  processedData['is-company'] = Boolean(
    processedData['account-type'] === VerificationConstants.ACCOUNT_TYPE_COMPANY
  );
  delete processedData['account-type'];

  if (processedData['first-name'] && processedData['last-name']) {
    processedData['display-name'] = `${processedData['first-name']} ${processedData['last-name']}`;
  }

  if (userEmail) {
    processedData.email = userEmail;
  }

  if (processedData['vat-country-code'] && processedData['vat-number']) {
    processedData.vatDetails = {
      vatCountry: processedData['vat-country-code'],
      vatNumber: processedData['vat-number'],
    };
  }
  delete processedData['vat-country-code'];
  delete processedData['vat-number'];

  // Remove line 2 since already included in line 1
  processedData['address-line-2'] = '';
  processedData['company-address-line-2'] = '';

  processedData['display-name-type'] = 'name';
  processedData['issue-check-in-company-name'] = false;
  processedData['shipping-address'] = null;
  processedData['use-username-as-display-name'] = false;

  // Cleanup address autofill fields
  delete processedData['full-country'];
  delete processedData['company-full-country'];
  // Don't handle Occupation field for now
  delete processedData.occupation;

  return processedData;
};

export function* submitPersonalVerificationFormSaga(action) {
  yield put(submitPersonalVerificationFormRoutine.request());
  const formData = action.payload.values;
  const apiBody = processPersonalVerificationForm(formData);
  if (apiBody.Files.length > 0 || apiBody.TextInputs.length > 0) {
    yield put(verifyCustomerInformationRoutine.trigger(apiBody));
    const { verifyCustomerFailure } = yield race({
      verifyCustomerSuccess: take(verifyCustomerInformationRoutine.SUCCESS),
      verifyCustomerFailure: take(verifyCustomerInformationRoutine.FAILURE),
    });

    if (verifyCustomerFailure) {
      const reduxFormError = { _error: verifyCustomerFailure.payload };
      yield put(submitPersonalVerificationFormRoutine.failure(new SubmissionError(reduxFormError)));
      return;
    }

    yield put(
      updateIndividualVerificationStatus({
        isSubmitted: true,
        isLocallySubmitted: true,
      })
    );

    if (OlarkApi.isAvailable()) {
      // Use Olark to notify company verification submission
      OlarkApi.notifyOperator('Customer has submitted personal verification request');
      OlarkStore.notifyKYCSubmission();
    }

    yield put(submitPersonalVerificationFormRoutine.success());
  } else {
    yield put(logError('No file has been selected for personal verification'));
  }
}

const processElectronicVerificationForm = (formData, docFields) => {
  const apiFields = docFields.reduce(
    (fields, field) => [
      {
        name: field.name,
        value: formData[field.name],
      },
      ...fields,
    ],
    []
  );
  const apiBody = {
    document: formData.verificationMethod || formData.individualVerificationMethod,
    fields: apiFields,
  };
  return apiBody;
};

export function* submitElectronicVerificationFormSaga(action) {
  yield put(submitElectronicVerificationFormRoutine.request());
  const formData = action.payload.values;
  if (formData.verificationMethod !== 'upload-document') {
    const userCountry = yield select(customerCountrySelector);
    const countryDocument = getCountryDocument(userCountry, formData.verificationMethod) || {};
    const docFields = countryDocument.fields
      ? countryDocument.fields.filter((field) => field.required)
      : [];
    const apiBody = processElectronicVerificationForm(formData, docFields);
    yield put(electronicallyVerifyCustomerRoutine.trigger(apiBody));

    const { failure } = yield race({
      success: take(electronicallyVerifyCustomerRoutine.SUCCESS),
      failure: take(electronicallyVerifyCustomerRoutine.FAILURE),
    });

    if (failure) {
      const reduxFormError = { _error: failure.payload.message || failure.payload };
      if (failure.payload.rejected) {
        yield put(
          updateIndividualVerificationStatus({
            isSubmitted: true,
            ...(failure.payload.attempts_left !== undefined && {
              electronicVerification: {
                attempts: EKYC_MAX_ATTEMPTS - failure.payload.attempts_left,
              },
            }),
          })
        );
      }
      yield put(
        submitElectronicVerificationFormRoutine.failure(new SubmissionError(reduxFormError))
      );

      if (failure.payload.attempts_left === 0) {
        yield put(change(action.payload.props.form, 'verificationMethod', 'upload-document'));
        action.payload.props.onDocumentChange &&
          action.payload.props.onDocumentChange({ target: { value: 'upload-document' } });
      }
      return;
    }
    const submittedFields = docFields.reduce(
      (acc, field) => [
        {
          country_code: userCountry,
          document_type: formData.verificationMethod,
          field_type: field.name,
          field_value: formData[field.name],
        },
        ...acc,
      ],
      []
    );
    const fields = yield call(processEKycSubmissionFields, submittedFields);
    yield put(
      updateIndividualVerificationStatus({
        isApproved: true,
        isSubmitted: true,
        electronicVerification: {
          submittedFields: fields,
        },
      })
    );
  } else {
    yield put(submitPersonalVerificationFormRoutine.trigger(action.payload));

    const { failure } = yield race({
      success: take(submitPersonalVerificationFormRoutine.SUCCESS),
      failure: take(submitPersonalVerificationFormRoutine.FAILURE),
    });

    if (failure) {
      const reduxFormError = { _error: failure.payload };
      yield put(
        submitElectronicVerificationFormRoutine.failure(new SubmissionError(reduxFormError))
      );
      return;
    }
  }
  yield put(submitElectronicVerificationFormRoutine.success());
}

const processCompanyVerificationForm = (formData, companyName) => {
  const apiBody = {
    Files: [],
    TextInputs: [],
  };

  if (formData.companyProofType && formData.companyType && formData.companyRelationship) {
    apiBody.TextInputs.push(
      { Key: 'CompanyProofType', Value: formData.companyProofType },
      { Key: 'CompanyName', Value: companyName },
      { Key: 'CompanyType', Value: formData.companyType },
      { Key: 'CompanyRelationship', Value: formData.companyRelationship }
    );

    for (const fileData of formData.companyProofFile || []) {
      apiBody.Files.push(fileData.fileId);
    }
  }

  return apiBody;
};

export function* submitCompanyVerificationFormSaga(action) {
  yield put(submitCompanyVerificationFormRoutine.request());
  const formData = action.payload.values;
  const companyName = yield select(customerCompanyNameSelector);
  const apiBody = processCompanyVerificationForm(formData, companyName);
  if (apiBody.Files.length > 0 || apiBody.TextInputs.length > 0) {
    yield put(verifyCustomerInformationRoutine.trigger(apiBody));
    const { verifyCustomerFailure } = yield race({
      verifyCustomerSuccess: take(verifyCustomerInformationRoutine.SUCCESS),
      verifyCustomerFailure: take(verifyCustomerInformationRoutine.FAILURE),
    });

    if (verifyCustomerFailure) {
      const reduxFormError = { _error: verifyCustomerFailure.payload };
      yield put(submitCompanyVerificationFormRoutine.failure(new SubmissionError(reduxFormError)));
      return;
    }

    yield put(
      updateCompanyVerificationStatus({
        isSubmitted: true,
        isLocallySubmitted: true,
      })
    );

    if (OlarkApi.isAvailable()) {
      // Use Olark to notify company verification submission
      OlarkApi.notifyOperator('Customer has submitted company verification request');
      OlarkStore.notifyKYCSubmission();
    }

    yield put(submitCompanyVerificationFormRoutine.success());
  } else {
    yield put(logError('No file has been selected for company verification'));
  }
}

function* updateCustomerDetailsVatNumber(vatDetails) {
  const apiRequest = {
    taxType: 'vat',
    taxNumber: `${vatDetails.vatCountry}${vatDetails.vatNumber.replace(/\s+/g, '')}`,
  };
  yield put(setCustomerTaxNumberRoutine.trigger(apiRequest));

  const { setCustomerTaxFailure } = yield race({
    setCustomerTaxSuccess: take(setCustomerTaxNumberRoutine.SUCCESS),
    setCustomerTaxFailure: take(setCustomerTaxNumberRoutine.FAILURE),
  });

  if (setCustomerTaxFailure) {
    const reduxFormError = {
      vatDetails: {
        vatNumber: setCustomerTaxFailure.payload,
      },
    };
    throw reduxFormError;
  }
}

function* kycSetErrorAndScroll(error) {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  yield put(kycSetErrorAlert(error));
}

export function* kycSubmitCustomerDetails(action) {
  yield put(kycClearErrorAlert());
  try {
    const { values, form, formName, kycContext } = action.payload;
    const customerFormData = { ...pick(values, [...form.getRegisteredFields(), 'account-type']) };
    const customerEmail = AuthenticationStore.getEmail();
    const customerID = AuthenticationStore.getCustomerID();

    const v4Customer = {
      address: {
        state: customerFormData.state,
        country: customerFormData.country,
      },
      ...((customerFormData['company-country'] && {
        company: {
          address: {
            country: customerFormData['company-country'],
            state: customerFormData['company-state'],
          },
        },
      }) ||
        {}),
    };

    const processedFormData = yield call(
      processKycV2RawIndividualFormData,
      customerFormData,
      customerEmail
    );

    let redirectToCompanyDetailsForm;
    const hasCompanyName = yield select(customerCompanyNameSelector);
    switch (formName) {
      case VerificationConstants.COMPANY_DETAILS_FORM:
        redirectToCompanyDetailsForm = false;
        break;
      default:
        redirectToCompanyDetailsForm = processedFormData['is-company'] && !hasCompanyName;
        delete processedFormData['is-company'];
    }

    const apiData = yield call(mapFormDataToRequestBody, kycV2ApiMapping, processedFormData);
    const vatRequired = Boolean(
      KYCAlpha2VatCountries.includes(values.country || values['company-country'])
    );

    yield call(API.updateCustomerDetails, customerID, { ...apiData, Source: 'kyc' });
    yield call(API.updateCustomerV4, v4Customer);
    if (processedFormData.vatDetails && vatRequired) {
      yield call(updateCustomerDetailsVatNumber, processedFormData.vatDetails);
    }
    yield put(
      kycGotoNextPageRoutine.trigger({
        nextPage: redirectToCompanyDetailsForm
          ? KYCPages.COMPANY_DETAILS_FORM
          : KYCPages.VERIFICATION_DETAILS,
        refreshCustomer: true,
        forceNextPage: redirectToCompanyDetailsForm,
        kycContext,
      })
    );
    const { nextPageFailure } = yield race({
      nextPageSuccess: take(kycGotoNextPageRoutine.SUCCESS),
      nextPageFailure: take(kycGotoNextPageRoutine.FAILURE),
    });
    if (nextPageFailure) throw nextPageFailure;
  } catch (error) {
    yield call(kycSetErrorAndScroll, KYCErrorAlertMessages.SUBMISSION_FAILED);
    yield put(kycSubmitCustomerDetailsRoutine.failure());
  }
  yield put(kycSubmitCustomerDetailsRoutine.success());
}

export function* submitCustomerDetailsSaga(action) {
  yield put(submitCustomerDetailsFormRoutine.request());
  yield put(unregisterField(action.payload.props.form, 'showCancel'));
  const registeredFields = yield select(registeredFieldsSelector, action.payload.props.form);
  const customerData = pick(action.payload.values, registeredFields);

  // Reset local names to nothing if user selects a country that does not support local names
  if (action.payload.values.localFName !== '' && !registeredFields.includes('localFName')) {
    customerData.localFName = '';
    customerData.localLName = '';
  }

  // Update customer details
  const customerEmail = AuthenticationStore.getEmail();
  const customerID = AuthenticationStore.getCustomerID();

  const v4Customer = {
    address: {
      country: customerData.country,
      state: customerData.state,
    },
  };
  if (customerData.companyCountry) {
    v4Customer.company = {
      address: {
        country: customerData.companyCountry,
        state: customerData.companyState,
      },
    };
  }

  const processedData = yield call(processRawFormData, customerData, customerEmail);
  const customerInformation = yield call(mapFormDataToRequestBody, apiMapping, processedData);
  customerInformation.Source = 'kyc';

  // Check VAT number and update it
  if (hasVatDetails(customerData)) {
    try {
      yield call(updateCustomerDetailsVatNumber, customerData.vatDetails);
    } catch (updateCustomerDetailsVatNumberError) {
      const reduxFormError = new SubmissionError(updateCustomerDetailsVatNumberError);
      yield put(submitCustomerDetailsFormRoutine.failure(reduxFormError));
      return;
    }
  }

  // Refresh customer detail from V2
  try {
    yield call(API.updateCustomerDetails, customerID, customerInformation);

    UserActions.getCustomerData(customerID);
    NavigationStore.setIgnoreMissingCustomerData(false);
    const { getCustomerFailure } = yield race({
      getCustomerSuccess: take(fluxGetCustomerRequest.SUCCESS),
      getCustomerFailure: take(fluxGetCustomerRequest.FAILURE),
    });
    if (getCustomerFailure) {
      const error = {
        type: APIConstants.INTERNAL_SERVER_ERROR,
        errors: {
          error: gettext('Failed to refresh customer information'),
        },
      };
      throw error;
    }
  } catch (error) {
    let errorMessage = ErrorMessages.VERIFY_SUBMISSION_FAIL;
    if (error.type === APIConstants.BAD_REQUEST) {
      errorMessage = error.errors.Message;
    } else if (error.type === APIConstants.INTERNAL_SERVER_ERROR) {
      errorMessage = error.errors.error;
    } else if (error.type === APIConstants.UNHANDLED) {
      errorMessage = error.errors.error;
    } else if (error === ErrorMessages.INCOMPLETE_FORM) {
      errorMessage = ErrorMessages.INCOMPLETE_FORM;
    }

    const reduxFormError = { _error: errorMessage };
    yield put(submitCustomerDetailsFormRoutine.failure(new SubmissionError(reduxFormError)));
    return;
  }

  // Store national documents + ISO country/states after customer is created
  try {
    yield call(API.updateCustomerV4, v4Customer);
  } catch (v4CustomerError) {
    const reduxFormError = new SubmissionError(v4CustomerError);
    yield put(submitCustomerDetailsFormRoutine.failure(reduxFormError));
    return;
  }

  // Notify form with successful submission
  yield put(submitCustomerDetailsFormRoutine.success());
}

export function* kycCheckDocumentsSubmittedSaga() {
  const customerID = AuthenticationStore.getCustomerID();
  const checkDocSubmission = yield call(API.userHasDocumentsSubmitted, customerID);
  yield put(
    updateIndividualVerificationStatus({
      hasDocumentsSubmitted: checkDocSubmission.has_submitted_t2_docs,
    })
  );
}

export function* partnerKYCSuccess(action) {
  try {
    const { token } = action.payload;
    const response = yield call(API.getReturnURLFromToken, token);

    if (response.return_url) {
      const returnURL = urlSchemeRegex.test(response.return_url)
        ? response.return_url
        : `https://${response.return_url}`;

      yield delay(5000);
      window.location.assign(returnURL);
    }
    yield put(partnerKYCSuccessRoutine.success());
  } catch (error) {
    yield put(partnerKYCSuccessRoutine.failure(error));
  }
}

export function* kycGotoNextPage(action) {
  const {
    payload: {
      nextPage,
      refreshCustomer = false,
      forceNextPage = false,
      kycContext: { flow = VERIFY_FLOW, onNextPage } = {},
    },
  } = action;
  const customerID = AuthenticationStore.getCustomerID();
  try {
    if (refreshCustomer) {
      UserActions.getCustomerData(customerID);
      NavigationStore.setIgnoreMissingCustomerData(false);
      const { getCustomerFailure, getV4CustomerFailure, setExtendedKycRequirementRoutineFailure } =
        yield race({
          getCustomerSuccess: all([
            // Will wait for all 3 actions since fluxGetCustomerRequest.SUCCESS isn't configured
            // to wait for the success/fail states of it's side effects
            take(fluxGetCustomerRequest.SUCCESS),
            take(getV4CustomerRoutine.SUCCESS),
            take(setExtendedKycRequirementRoutine.SUCCESS),
          ]),
          getCustomerFailure: take(fluxGetCustomerRequest.FAILURE),
          getV4CustomerFailure: take(getV4CustomerRoutine.FAILURE),
          setExtendedKycRequirementRoutineFailure: take(setExtendedKycRequirementRoutine.FAILURE),
        });
      const ekycEligibility = yield call(API.getUserEKYCEligibility, customerID);
      if (ekycEligibility && ekycEligibility.eligibility) {
        yield put(
          updateIndividualVerificationStatus({
            electronicVerification: {
              eligibility: ekycEligibility.eligibility,
            },
          })
        );
      }
      yield call(kycCheckDocumentsSubmittedSaga);
      yield put(getUserVerificationStatusesRoutine.trigger());
      const { getVerificationFailure } = yield race({
        getVerificationSuccess: take(getUserVerificationStatusesRoutine.SUCCESS),
        getVerificationFailure: take(getUserVerificationStatusesRoutine.FAILURE),
      });
      if (
        getCustomerFailure ||
        getV4CustomerFailure ||
        setExtendedKycRequirementRoutineFailure ||
        getVerificationFailure
      ) {
        const error = {
          kycErrorAlertMessage: KYCErrorAlertMessages.CUST_REFRESH_FAILED,
        };
        throw error;
      }
    }

    const initPages = yield select(customerKycInitPagesSelector);
    const initPage = initPages[0];
    if (initPage) yield put(kycDequeueInitPages());
    const nextPageOverride = yield call(getRequiredNextPage, flow);
    const actualNextPage = (forceNextPage && nextPage) || initPage || nextPageOverride || nextPage;

    if (onNextPage) {
      yield call(onNextPage, actualNextPage);
    }
    yield put(
      kycGotoNextPageRoutine.success({
        nextPage: actualNextPage,
        purgeHistory:
          forceNextPage || !!nextPageOverride || nextPage === KYCPages.VERIFICATION_DETAILS,
      })
    );
  } catch (e) {
    yield call(kycSetErrorAndScroll, e.kycErrorAlertMessage || KYCErrorAlertMessages.UNEXPECTED);
    yield put(kycGotoNextPageRoutine.failure());
  }
}

export function* kycGotoPrevPage() {
  try {
    const pages = yield select(customerKycPagesSelector);
    const prevPage = pages.slice(-2)[0];
    yield put(kycGotoPrevPageRoutine.success());
    if (prevPage === KYCPages.VERIFICATION_DETAILS) {
      yield put(kycClearFileUploads());
    }
  } catch (e) {
    yield call(kycSetErrorAndScroll, KYCErrorAlertMessages.UNEXPECTED);
    yield put(kycGotoPrevPageRoutine.failure());
  }
}

export function* kycGotoPageSuccess() {
  yield put(kycClearErrorAlert());
}

export function* kycUploadFile(action) {
  const { file } = action.payload;
  try {
    const { id } = yield call(API.uploadKYCFile, file);
    yield put(kycClearErrorAlert());
    yield put(kycUploadFileRoutine.success({ ...action.payload, id }));
  } catch (e) {
    yield call(kycSetErrorAndScroll, KYCErrorAlertMessages.UNEXPECTED);
    yield put(kycUploadFileRoutine.failure(action.payload));
  }
}

const processKycFormValuesForSubmit = (
  values,
  type,
  uploadedFiles,
  fileTypesToSubmit,
  kycContext,
  paymentMethod
) => {
  const fileTypeToApiData = {
    [ID_BACK]: {
      proofType: values.identityDocumentType,
      proofFor: 'id',
    },
    [ID_FRONT]: {
      proofType: values.identityDocumentType,
      proofFor: 'id',
    },
    [ID_ADDRESS]: {
      proofType: values.addressDocumentType,
      proofFor: 'address',
    },
    [ID_EXTENDED]: {
      proofType: values.extendedDocumentType,
      proofFor: 'photo_verification',
    },
    [COMPANY_DOCUMENT]: {
      proofType: values['company-proof-type'],
      proofFor: 'company_existence',
    },
  };
  const documentsPayload = fileTypesToSubmit.reduce((documents, fileType) => {
    const docIndex = documents.findIndex(
      (doc) => doc.proof_type === fileTypeToApiData[fileType].proofType
    );
    if (docIndex !== -1) {
      documents[docIndex].file_ids.push(uploadedFiles[fileType].id);
    } else {
      const shouldIncludeAddress =
        fileTypeToApiData[fileType].proofFor === 'id' && values.isAddressInId;
      documents.push({
        file_ids: [uploadedFiles[fileType].id],
        proof_type: fileTypeToApiData[fileType].proofType,
        proof_for: [
          fileTypeToApiData[fileType].proofFor,
          ...(shouldIncludeAddress ? ['address'] : []),
        ],
      });
    }
    return documents;
  }, []);

  let source = null;
  if (
    kycContext &&
    kycContext.flow &&
    kycContext.flow === CHECKOUT_FLOW &&
    paymentMethod === PaymentConstants.PAYMENT_METHODS.DIRECT_DEBIT
  ) {
    source = ACH_CHECKOUT_FLOW;
  }
  return {
    account:
      type === VerificationConstants.ACCOUNT_TYPE_COMPANY
        ? {
            type,
            relationship: values['company-relationship'],
            company_type: values['company-type'],
          }
        : {
            type,
          },
    documents: documentsPayload,
    ...(source ? { source } : {}),
  };
};

export function* kycSubmitIndividualUploadDocument(values, requiredIdSides, kycContext) {
  const fileTypesToSubmit = [...requiredIdSides, ...(!values.isAddressInId ? [ID_ADDRESS] : [])];
  const uploadedFiles = yield select(customerKycUploadedFilesSelector);
  const paymentMethod = yield select(checkoutPaymentTypeSelector);
  const payload = processKycFormValuesForSubmit(
    values,
    VerificationConstants.ACCOUNT_TYPE_INDIVIDUAL,
    uploadedFiles,
    fileTypesToSubmit,
    kycContext,
    paymentMethod
  );
  try {
    yield call(API.verifyCustomer, payload);
  } catch (e) {
    yield call(kycSetErrorAndScroll, KYCErrorAlertMessages.UNEXPECTED);
    throw e;
  }
}

export function* kycSubmitIndividualEkyc(values, handleEkycMaxAttempts, onEkycSubmission) {
  const { individualVerificationMethod } = values;
  const userCountry = yield select(customerCountrySelector);
  const countryDocument = getCountryDocument(userCountry, individualVerificationMethod) || {};
  const docFields = countryDocument.fields
    ? countryDocument.fields.filter((field) => field.required)
    : [];
  const apiBody = processElectronicVerificationForm(values, docFields);
  yield put(electronicallyVerifyCustomerRoutine.trigger(apiBody));

  const { failure } = yield race({
    success: take(electronicallyVerifyCustomerRoutine.SUCCESS),
    failure: take(electronicallyVerifyCustomerRoutine.FAILURE),
  });

  if (failure) {
    const errorMessage = failure.payload.message || failure.payload;
    if (failure.payload.rejected) {
      yield put(
        updateIndividualVerificationStatus({
          isSubmitted: true,
          ...(failure.payload.attempts_left !== undefined && {
            electronicVerification: {
              attempts: EKYC_MAX_ATTEMPTS - failure.payload.attempts_left,
            },
          }),
        })
      );
    }
    yield call(kycSetErrorAndScroll, { message: errorMessage });
    if (failure.payload.attempts_left === 0) {
      handleEkycMaxAttempts();
    }
    throw Error();
  }
  const submittedFields = docFields.reduce(
    (acc, field) => [
      {
        country_code: userCountry,
        document_type: values.individualVerificationMethod,
        field_type: field.name,
        field_value: values[field.name],
      },
      ...acc,
    ],
    []
  );
  const fields = yield call(processEKycSubmissionFields, submittedFields);
  yield put(
    updateIndividualVerificationStatus({
      isApproved: true,
      isSubmitted: true,
      electronicVerification: {
        submittedFields: fields,
      },
    })
  );
  if (onEkycSubmission) {
    onEkycSubmission();
  }
}

export function* kycSubmitIndividual(action) {
  const { values, requiredIdSides, handleEkycMaxAttempts, kycContext, paymentMethod } =
    action.payload;
  const { individualVerificationMethod } = values;
  const { onEkycSubmission } = kycContext;
  try {
    switch (individualVerificationMethod) {
      case INDIV_VERIFICATION_UPLOAD_OPTION_VALUE:
        yield call(
          kycSubmitIndividualUploadDocument,
          values,
          requiredIdSides,
          kycContext,
          paymentMethod
        );
        break;
      default:
        yield call(kycSubmitIndividualEkyc, values, handleEkycMaxAttempts, onEkycSubmission);
    }
    yield put(kycClearErrorAlert());
    yield put(
      kycGotoNextPageRoutine.trigger({
        nextPage: KYCPages.VERIFICATION_DETAILS,
        refreshCustomer: true,
        kycContext,
      })
    );
    const { nextPageFailure } = yield race({
      nextPageSuccess: take(kycGotoNextPageRoutine.SUCCESS),
      nextPageFailure: take(kycGotoNextPageRoutine.FAILURE),
    });
    if (nextPageFailure) throw nextPageFailure;
    yield put(kycClearFileUploads());
    yield put(kycSubmitIndividualRoutine.success());
  } catch (e) {
    yield put(kycSubmitIndividualRoutine.failure());
  }
}

export function* kycSubmitExtended(action) {
  const { values, kycContext } = action.payload;
  const uploadedFiles = yield select(customerKycUploadedFilesSelector);
  const payload = processKycFormValuesForSubmit(
    values,
    VerificationConstants.ACCOUNT_TYPE_INDIVIDUAL,
    uploadedFiles,
    [ID_EXTENDED]
  );
  try {
    yield call(API.verifyCustomer, payload);
    yield put(
      kycGotoNextPageRoutine.trigger({
        nextPage: KYCPages.VERIFICATION_DETAILS,
        refreshCustomer: true,
        kycContext,
      })
    );
    const { nextPageFailure } = yield race({
      nextPageSuccess: take(kycGotoNextPageRoutine.SUCCESS),
      nextPageFailure: take(kycGotoNextPageRoutine.FAILURE),
    });
    if (nextPageFailure) throw nextPageFailure;
    yield put(kycSubmitExtendedRoutine.success());
  } catch (e) {
    yield put(kycSetErrorAlert(KYCErrorAlertMessages.UNEXPECTED));
    yield put(kycSubmitExtendedRoutine.failure());
  }
}

export function* kycSubmitCompany(action) {
  const { values, kycContext } = action.payload;
  const uploadedFiles = yield select(customerKycUploadedFilesSelector);
  const payload = processKycFormValuesForSubmit(
    values,
    VerificationConstants.ACCOUNT_TYPE_COMPANY,
    uploadedFiles,
    [COMPANY_DOCUMENT]
  );
  try {
    yield call(API.verifyCustomer, payload);
    yield put(
      kycGotoNextPageRoutine.trigger({
        nextPage: KYCPages.VERIFICATION_DETAILS,
        refreshCustomer: true,
        kycContext,
      })
    );
    const { nextPageFailure } = yield race({
      nextPageSuccess: take(kycGotoNextPageRoutine.SUCCESS),
      nextPageFailure: take(kycGotoNextPageRoutine.FAILURE),
    });
    if (nextPageFailure) throw nextPageFailure;
    yield put(kycSubmitCompanyRoutine.success());
  } catch (e) {
    yield put(kycSetErrorAlert(KYCErrorAlertMessages.UNEXPECTED));
    yield put(kycSubmitCompanyRoutine.failure());
  }
}

export function* kycSetIsCompany(action) {
  const isCompany = action.payload === VerificationConstants.ACCOUNT_TYPE_COMPANY;
  const customerID = AuthenticationStore.getCustomerID();
  try {
    yield call(API.updateCustomerDetails, customerID, { IsCompany: isCompany });
    yield put(
      kycGotoNextPageRoutine.trigger({
        nextPage: KYCPages.VERIFICATION_DETAILS,
        refreshCustomer: true,
      })
    );
    yield put(kycSetIsCompanyRoutine.success());
  } catch (e) {
    yield put(kycSetErrorAlert(KYCErrorAlertMessages.UNEXPECTED));
    yield put(kycSetIsCompanyRoutine.failure());
  }
}

export function* kycLoadInitPageSaga(action) {
  const { kycContext = {} } = action.payload;
  const initialPages = [];
  const isCustomerKyc2Rejected = yield select(customerKyc2RejectedSelector);
  const isCustomerKyc3Rejected = yield select(customerKyc3RejectedSelector);
  if (kycContext.flow === CHECKOUT_FLOW) {
    isCustomerKyc2Rejected && initialPages.push(KYCPages.INDIVIDUAL_DETAILS_FORM);
    isCustomerKyc3Rejected && initialPages.push(KYCPages.COMPANY_DETAILS_FORM);
  } else if (kycContext.flow === TIER_1_FLOW) {
    initialPages.push(KYCPages.INDIVIDUAL_DETAILS_FORM);
  }
  yield put(kycAddInitPages(initialPages));
  yield put(
    kycGotoNextPageRoutine.trigger({
      nextPage: KYCPages.VERIFICATION_DETAILS,
      kycContext,
    })
  );
}

export function* watcher() {
  yield takeLatest(
    submitPersonalVerificationFormRoutine.TRIGGER,
    submitPersonalVerificationFormSaga
  );
  yield takeLatest(
    submitElectronicVerificationFormRoutine.TRIGGER,
    submitElectronicVerificationFormSaga
  );
  yield takeLatest(submitCompanyVerificationFormRoutine.TRIGGER, submitCompanyVerificationFormSaga);
  yield takeLatest(submitCustomerDetailsFormRoutine.TRIGGER, submitCustomerDetailsSaga);
  yield takeLatest(kycSubmitCustomerDetailsRoutine.TRIGGER, kycSubmitCustomerDetails);
  yield takeLatest(partnerKYCSuccessRoutine.TRIGGER, partnerKYCSuccess);
  yield takeLatest(kycGotoNextPageRoutine.TRIGGER, kycGotoNextPage);
  yield takeLatest(kycGotoPrevPageRoutine.TRIGGER, kycGotoPrevPage);
  yield takeLatest(kycGotoNextPageRoutine.SUCCESS, kycGotoPageSuccess);
  yield takeLatest(kycGotoPrevPageRoutine.SUCCESS, kycGotoPageSuccess);
  yield takeLatest(kycSubmitIndividualRoutine.TRIGGER, kycSubmitIndividual);
  yield takeEvery(kycUploadFileRoutine.TRIGGER, kycUploadFile);
  yield takeLatest(kycSetIsCompanyRoutine.TRIGGER, kycSetIsCompany);
  yield takeLatest(kycSubmitExtendedRoutine.TRIGGER, kycSubmitExtended);
  yield takeLatest(kycSubmitCompanyRoutine.TRIGGER, kycSubmitCompany);
  yield takeLatest(VerificationConstants.KYC_LOAD_INIT_PAGES, kycLoadInitPageSaga);
  yield takeLatest(
    VerificationConstants.KYC_CHECK_DOCUMENTS_SUBMITTED,
    kycCheckDocumentsSubmittedSaga
  );
}

export default [watcher];
