import React, { useContext } from 'react';
import ReactSVG from 'react-svg';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { connect } from 'react-redux';
import {
  kycClearErrorAlert,
  kycGotoPrevPage as kycGotoPrevPageRoutine,
  kycSetErrorAlert,
  kycSetSubheader,
  kycSubmitExtended as kycSubmitExtendedRoutine,
} from 'spa/actions/VerificationActions';
import { ID_EXTENDED, KYCErrorAlertMessages } from 'spa/constants/VerificationConstants';
import ActionButtons from 'spa/components/CustomerVerification/V2/ActionButtons';
import VerificationConstants from 'spa/constants/VerificationConstants';
import {
  customerKycFileForProofSelector,
  customerKycHasPrevPagesSelector,
  customerKycSubmitLoadingSelector,
  customerKycUploadedFilesSelector,
} from 'spa/selectors/CustomerSelectors';
import { KYCContext } from 'spa/context/KYCContext';
import Heading from './Heading';
import UploadBox from './UploadBox';

const ExtendedVerificationForm = ({
  submitKYCExtended,
  getUploadedFile,
  setErrorAlert,
  clearErrorAlert,
  isSubmitting,
  loadPrevPage,
  uploadedFiles,
  hasPrevPages,
}) => {
  const kycContext = useContext(KYCContext);
  const handleOnSubmit = (values) => {
    if (!getUploadedFile(ID_EXTENDED)) {
      setErrorAlert(KYCErrorAlertMessages.FILE_REQUIRED);
      return FORM_ERROR;
    }
    clearErrorAlert();
    submitKYCExtended({
      values,
      kycContext,
    });
  };
  return (
    <Form
      onSubmit={handleOnSubmit}
      initialValues={{
        extendedDocumentType: 'self_portrait_with_id',
      }}
      subscription={{
        hasValidationErrors: true,
      }}
    >
      {({ handleSubmit, hasValidationErrors }) => (
        <form onSubmit={handleSubmit} name={VerificationConstants.EXTENDED_VERIFICATION_FORM}>
          <Heading text={'Photo Verification'} hasDivider />
          <div>Please upload a high-resolution photo of yourself holding your ID</div>
          <br />
          <div>Example of photo verification</div>
          <br />
          <ReactSVG
            src={'../../../../../build/images/verify/sample-id-extended.svg'}
            className="kyc-sample-id"
            beforeInjection={(svg) => {
              svg.classList.add('kyc-sample-id-image');
            }}
          />
          <UploadBox
            proofFor={ID_EXTENDED}
            label={'Upload Photo Verification'}
            disabled={isSubmitting}
          />
          <ActionButtons
            secondaryText="Back"
            primaryText="Verify"
            withBack={hasPrevPages}
            onSecondaryClick={() => loadPrevPage()}
            isDisabled={
              hasValidationErrors ||
              Object.values(uploadedFiles).some((file) => file && file.loading)
            }
            type="submit"
            isLoading={isSubmitting}
          />
        </form>
      )}
    </Form>
  );
};

const mapStateToProps = (state) => ({
  isSubmitting: customerKycSubmitLoadingSelector(state),
  getUploadedFile: (proofFor) => customerKycFileForProofSelector(state, proofFor),
  uploadedFiles: customerKycUploadedFilesSelector(state),
  hasPrevPages: customerKycHasPrevPagesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadPrevPage: () => dispatch(kycGotoPrevPageRoutine.trigger()),

  setErrorAlert: (error) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    dispatch(kycSetErrorAlert(error));
  },
  clearErrorAlert: () => dispatch(kycClearErrorAlert()),
  setSubheaderText: (subheaderText) => dispatch(kycSetSubheader(subheaderText)),
  submitKYCExtended: (payload) => dispatch(kycSubmitExtendedRoutine.trigger(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExtendedVerificationForm);
