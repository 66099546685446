import React, { useState } from 'react';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { Modal } from 'escrow-common-js/dist/components';
import MediaQuery from 'react-responsive';
import MediaQueryConstants from 'spa/constants/MediaQueryConstants';

const Slider = ({ children, onSlideChange }) => {
  const [direction, setDirection] = useState('left');
  const [activeSlide, setActiveSlide] = useState(0);

  const goToSlide = (slideIndex) => {
    setDirection(slideIndex < activeSlide ? 'right' : 'left');
    setActiveSlide(slideIndex);
    onSlideChange && onSlideChange(slideIndex);
  };

  return (
    <div className="slider-container">
      <div className="slider-slides">
        {React.Children.map(children, (child, index) => (
          <SlideWrapper
            index={index}
            activeIndex={activeSlide}
            slideDirection={direction}
            jumpToStep={goToSlide}
          >
            {child}
          </SlideWrapper>
        ))}
      </div>
    </div>
  );
};

const SlideWrapper = ({ index, activeIndex, slideDirection, jumpToStep, children }) => (
  <CSSTransition classNames="slider-slide" in={index === activeIndex} timeout={350} appear>
    <div
      className={classnames('slider-slide', {
        'is-slideBack': slideDirection === 'right',
      })}
    >
      {React.cloneElement(children, {
        index,
        activeIndex,
        jumpToStep,
      })}
    </div>
  </CSSTransition>
);

const MobileSliderWrapper = ({ children, display, ...props }) => (
  <MediaQuery maxWidth={MediaQueryConstants.MAX_BREAKPOINT.TABLET}>
    <Modal isOpen={display} paddingSize="" targetElementId="spa" exitButton={false}>
      <Slider {...props}>{children}</Slider>
    </Modal>
  </MediaQuery>
);

export default Slider;
export { MobileSliderWrapper, SlideWrapper };
