import classNames from 'classnames/dedupe';

export function setVnodeClasses(vnode, classes) {
  if (vnode.properties.className) {
    vnode.properties.className = classNames(vnode.properties.className.split(' '), classes);
  } else {
    vnode.properties.className = classNames(classes);
  }
  vnode.properties.attributes.class = vnode.properties.className;
}
