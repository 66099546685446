import React from 'react';
import { useDispatch } from 'react-redux';

import { pushPreviousPage, saveCurrentPage } from 'spa/features/kyc/kycSlice';

import { Select, MenuItem } from '@mui/material';

import MobilePage from '../../components/MobilePage';

const ForceCurrentPage = ({ pages }) => {
  const dispatch = useDispatch();

  return (
    <MobilePage title="Select page to skip to (for development only)" noButton>
      <Select
        onChange={(event) => {
          dispatch(pushPreviousPage({ page: 'FORCE_CURRENT_PAGE' }));
          dispatch(saveCurrentPage({ page: event.target.value }));
        }}
        sx={{ width: '100%' }}
      >
        {Object.keys(pages).map((page) => (
          <MenuItem key={page} value={page}>
            {page}
          </MenuItem>
        ))}
      </Select>
    </MobilePage>
  );
};

export default ForceCurrentPage;
