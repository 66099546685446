import { call, put, takeEvery, takeLatest, select, all } from 'redux-saga/effects';
import {
  getTransactionList as getTransactionListRoutine,
  getTransactionStatistics as getTransactionStatisticsRoutine,
  getCustomerList as getCustomerListRoutine,
  getCustomerStatistics as getCustomerStatisticsRoutine,
  getTransactionPartyInfo as getTransactionPartyInfoRoutine,
  getCustomerPaymentsIn as getCustomerPaymentsInRoutine,
  getCustomerPaymentsOut as getCustomerPaymentsOutRoutine,
  getReportsList as getReportsListRoutine,
  getReportsCount as getReportsCountRoutine,
  pushFilteredTransactionData,
  pushFilteredCustomerData,
  getPartnerLogoSettings as getPartnerLogoSettingsRoutine,
  updatePartnerLogoSettings as updatePartnerLogoSettingsRoutine,
  getTransactionHoldingPenConditions as getTransactionHoldingPenConditionsRoutine,
} from 'spa/actions/PartnerActions';
import { transactionSelector } from 'spa/selectors/PartnerSelectors';
import { hasActiveFilters } from '../../utils/PartnerDashboard';
import API from '../../api';

export function* getTransactionHoldingPenConditions(action) {
  const { transactionId } = action.payload;
  try {
    const holdingPen = yield call(API.getTransactionHoldingPenConditions, transactionId);
    yield put(getTransactionHoldingPenConditionsRoutine.success({ holdingPen, transactionId }));
  } catch (error) {
    const message = 'Failed to retrieve blocking conditions for this transaction.';
    yield put(getTransactionHoldingPenConditionsRoutine.failure({ transactionId, error: message }));
  }
}

export function* getTransactionList(action) {
  try {
    const { cursor, limit, filters } = action.payload || {};
    const hasFilters = filters && hasActiveFilters(filters);

    const payload = {
      show_cancelled: true,
      ...((cursor && { next_cursor: cursor }) || {}),
      ...((limit && { limit }) || {}),
      ...(hasFilters ? filters : {}),
    };

    const transactionsData = yield call(API.getPartnerTransactions, payload);
    yield put(getTransactionListRoutine.success(transactionsData));
    if (hasFilters) {
      yield put(pushFilteredTransactionData({ ...transactionsData, filters }));
    }
  } catch (error) {
    yield put(getTransactionListRoutine.failure(error.message));
  }
}

export function* getTransactionStatistics(action) {
  try {
    const { dataType, interval, dateStart, dateEnd, filters, triggerAction, dataLoaderAction } =
      action.payload;

    if (triggerAction) yield put(triggerAction());

    const statisticsData = yield call(
      API.getTransactionStatistics,
      dataType,
      interval,
      dateStart,
      dateEnd,
      filters
    );

    if (dataLoaderAction) {
      yield put(
        dataLoaderAction({
          ...statisticsData.statistics.transaction_count,
          interval: interval,
        })
      );
    } else {
      throw new Error('No data loader passed');
    }
    yield put(getTransactionStatisticsRoutine.success());
  } catch (error) {
    yield put(getTransactionStatisticsRoutine.failure(error.message));
  }
}

export function* getCustomerList(action) {
  try {
    const { cursor, limit, filters } = action.payload || {};
    const hasFilters = filters && hasActiveFilters(filters);

    const payload = {
      ...((cursor && { next_cursor: cursor }) || {}),
      ...((limit && { limit }) || {}),
      ...(hasFilters ? filters : {}),
    };

    const customersData = yield call(API.getPartnerCustomers, payload);
    yield put(getCustomerListRoutine.success(customersData));
    if (hasFilters) {
      yield put(pushFilteredCustomerData({ ...customersData, filters }));
    }
  } catch (error) {
    yield put(getCustomerListRoutine.failure(error.message || error.errors));
  }
}

export function* getCustomerStatistics(action) {
  try {
    const { dataType, interval, dateStart, dateEnd, filters, triggerAction, dataLoaderAction } =
      action.payload;

    if (triggerAction) yield put(triggerAction());

    const statisticsData = yield call(
      API.getCustomerStatistics,
      dataType,
      interval,
      dateStart,
      dateEnd,
      filters
    );
    if (dataLoaderAction) {
      yield put(dataLoaderAction(statisticsData.statistics.customer_count));
    } else {
      throw new Error('No data loader passed');
    }
    yield put(getCustomerStatisticsRoutine.success());
  } catch (error) {
    yield put(getCustomerStatisticsRoutine.failure(error.message));
  }
}

export function* getTransactionPartyInfo(action) {
  try {
    const transactionId = action.payload;
    const transaction = yield select(transactionSelector, transactionId);
    const transactionPartyInfo = yield all(
      transaction.parties.reduce(
        (mappedPartyInfo, party) => ({
          ...mappedPartyInfo,
          [party.id]: call(API.getPartneredCustomerDetailsByPartyId, party.id),
        }),
        {}
      )
    );

    yield put(
      getTransactionPartyInfoRoutine.success({
        transactionId,
        transactionPartyInfo,
      })
    );
  } catch (error) {
    yield put(getTransactionPartyInfoRoutine.failure(error.message));
  }
}

export function* getCustomerPaymentsIn(action) {
  try {
    const { customerId } = action.payload;
    const paymentsInMethods = yield call(API.getCustomerPaymentsIn, customerId);
    yield put(getCustomerPaymentsInRoutine.success(paymentsInMethods));
  } catch (error) {
    yield put(getCustomerPaymentsInRoutine.failure(error.message));
  } finally {
    yield put(getCustomerPaymentsInRoutine.fulfill());
  }
}

export function* getCustomerPaymentsOut(action) {
  try {
    const { customerId } = action.payload;
    const paymentsOutMethods = yield call(API.getCustomerPaymentsOut, customerId);
    yield put(getCustomerPaymentsOutRoutine.success(paymentsOutMethods));
  } catch (error) {
    yield put(getCustomerPaymentsOutRoutine.failure(error.message));
  } finally {
    yield put(getCustomerPaymentsOutRoutine.fulfill());
  }
}

export function* getReportsList(action) {
  try {
    const { cursor, limit } = action.payload || {};

    const payload = {
      show_cancelled: true,
      ...((cursor && { next_cursor: cursor }) || {}),
      ...((limit && { limit }) || {}),
    };

    const reports = yield call(API.getPartnerReports, payload);
    yield put(getReportsListRoutine.success(reports));
  } catch (error) {
    yield put(getReportsListRoutine.failure(error.message));
  }
}

export function* getReportsCount() {
  try {
    const reports = yield call(API.getPartnerReportsCount);
    yield put(getReportsCountRoutine.success(reports.count));
  } catch (error) {
    yield put(getReportsCountRoutine.failure(error.message));
  }
}

export function* getPartnerLogoSettings(action) {
  const { customerId } = action.payload;
  try {
    const settings = yield call(API.getPartnerLogoSettings, customerId);
    const mappedSettings = {
      enableCobrandedEmails: settings.cobranded_transaction_emails || false,
    };
    yield put(getPartnerLogoSettingsRoutine.success(mappedSettings));
  } catch (error) {
    const message = error.message || 'Failed to retrieve your partner logo settings.';
    yield put(getPartnerLogoSettingsRoutine.failure(message));
  }
}

export function* updatePartnerLogoSettings(action) {
  const { customerId, fields } = action.payload;
  try {
    yield call(API.updatePartnerLogoSettings, customerId, fields);
  } catch (error) {
    const message = error.message || 'Failed to update your partner logo settings.';
    yield put(updatePartnerLogoSettingsRoutine.failure(message));
  } finally {
    yield put(getPartnerLogoSettingsRoutine.trigger({ customerId }));
  }
}

export function* partnerDashboardWatcher() {
  yield takeLatest(getTransactionListRoutine.TRIGGER, getTransactionList);
  yield takeEvery(getTransactionStatisticsRoutine.TRIGGER, getTransactionStatistics);
  yield takeLatest(getCustomerListRoutine.TRIGGER, getCustomerList);
  yield takeLatest(getCustomerStatisticsRoutine.TRIGGER, getCustomerStatistics);
  yield takeLatest(getTransactionPartyInfoRoutine.TRIGGER, getTransactionPartyInfo);
  yield takeLatest(getCustomerPaymentsInRoutine.TRIGGER, getCustomerPaymentsIn);
  yield takeLatest(getCustomerPaymentsOutRoutine.TRIGGER, getCustomerPaymentsOut);
  yield takeLatest(getReportsListRoutine.TRIGGER, getReportsList);
  yield takeLatest(getReportsCountRoutine.TRIGGER, getReportsCount);
  yield takeLatest(getPartnerLogoSettingsRoutine.TRIGGER, getPartnerLogoSettings);
  yield takeLatest(updatePartnerLogoSettingsRoutine.TRIGGER, updatePartnerLogoSettings);
  yield takeLatest(
    getTransactionHoldingPenConditionsRoutine.TRIGGER,
    getTransactionHoldingPenConditions
  );
}

export default [partnerDashboardWatcher];
