import { createRoutine } from 'redux-saga-routines';

import TransactionConstants from 'spa/constants/TransactionConstants';

export function setTransactionDraft(draft) {
  return {
    type: TransactionConstants.SET_TRANSACTION_DRAFT,
    draft,
  };
}

export function setFilters(filters) {
  return {
    type: TransactionConstants.SET_FILTERS,
    filters,
  };
}

export function changeDraftBuyerEmail(email) {
  return {
    type: TransactionConstants.CHANGE_DRAFT_BUYER_EMAIL,
    email,
  };
}

export const checkTourStatus = createRoutine(TransactionConstants.CHECK_TOUR_STATUS);

export const setToured = createRoutine(TransactionConstants.SET_TOURED);

export const changeBuyerEmail = createRoutine(TransactionConstants.CHANGE_BUYER_EMAIL);

export const getTransactionById = createRoutine(TransactionConstants.GET_TRANSACTION_BY_ID);

export const getTransactionExtraDetails = createRoutine(
  TransactionConstants.GET_TRANSACTION_EXTRA_DETAILS
);

export const getPaymentMethods = createRoutine(TransactionConstants.GET_PAYMENT_METHODS);

export const submitCreditCardPayment = createRoutine(
  TransactionConstants.SUBMIT_CREDIT_CARD_PAYMENT
);

export const getCreditCardPageData = createRoutine(
  TransactionConstants.FETCH_CREDIT_CARD_PAGE_DATA
);

export const submitPoliPayment = createRoutine(TransactionConstants.SUBMIT_POLI_PAYMENT);

export const submitPlaidPayment = createRoutine(TransactionConstants.SUBMIT_PLAID_PAYMENT);

export const getTransactions = createRoutine(TransactionConstants.GET_TRANSACTIONS);

export const updateQueryParameters = createRoutine(TransactionConstants.UPDATE_QUERY_PARAMETERS);

export const getAdyenMethods = createRoutine(TransactionConstants.GET_ADYEN_METHODS);

export const startTransaction = createRoutine(TransactionConstants.START_TRANSACTION);

export const fetchTransactionFeesSummary = createRoutine(
  TransactionConstants.FETCH_TRANSACTION_FEES_SUMMARY
);
