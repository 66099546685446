var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/icons/arrow-right.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
[], 
["specifier"], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("specifier", kwargs.hasOwnProperty("specifier") ? kwargs["specifier"] : "");
var t_2 = "";t_2 += "<svg width=\"16px\" height=\"10px\" viewBox=\"0 0 16 10\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" class=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "specifier"), env.opts.autoescape);
t_2 += "\">\n    <title>Shape</title>\n    <g id=\"Symbols\" stroke=\"none\" stroke-width=\"1\" fill-rule=\"evenodd\">\n        <g id=\"Logged-out-nav\" transform=\"translate(-1267.000000, -27.000000)\">\n            <g id=\"Group-3\" transform=\"translate(1207.000000, 22.000000)\">\n                <polygon id=\"Shape\" points=\"69.2905883 13 70.7042214 14.414 75.4099998 9.707 70.7042214 5 69.2905883 6.414 71.5829933 8.707 60 8.707 60 10.707 71.5829933 10.707\"></polygon>\n            </g>\n        </g>\n    </g>\n</svg>";
;
frame = frame.pop();
return new runtime.SafeString(t_2);
});
context.addExport("arrowRight");
context.setVariable("arrowRight", macro_t_1);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/icons/arrow-right.html"] , dependencies)