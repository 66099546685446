import React from 'react';
import { connect } from 'react-redux';
import querystring from 'query-string';

import CreditCardForm from 'spa/components/CreditCard/CreditCardForm';
import {
  transactionByIdSelector,
  transactionLineItemsSelector,
  transactionFeesSelector,
  transactionTotalsSelector,
  transactionUISelector,
} from 'spa/selectors/TransactionSelectors';
import {
  buyerCheckoutDetailsSelector,
  customerDetailsLoadingSelector,
  customerSavedCreditCardsSelector,
  customerSavedCardsLoadingSelector,
} from 'spa/selectors/CustomerSelectors';
import { getCreditCardPageData as getCreditCardPageDataRoutine } from 'spa/actions/TransactionActions';
import { getCurrencyTagFromApiCurrency } from 'escrow-common-js/dist/utils';
import CheckoutConstants from '../../constants/CheckoutConstants';

const transactionId = querystring.parse(location.search).trans_id;

const getBillingLineItems = (lineItems, feeItems) => {
  const result = [];
  if (!lineItems || !feeItems) return result;

  result.push(
    ...lineItems.filter((item) => item.amount > 0.0),
    ...feeItems.knownFees.filter((fee) => fee.amount > 0.0)
  );
  if (feeItems.conditionalFees.credit_card) result.push(feeItems.conditionalFees.credit_card);
  return result;
};

const getItemSubtotal = (lineItems) => {
  let sum = 0;
  lineItems
    .filter((item) => CheckoutConstants.LINE_ITEM_TYPES[item.type])
    .map((item) => {
      sum += parseFloat(item.amount);
    });

  return sum;
};

const mapStateToProps = (state) => {
  const transaction = transactionByIdSelector(state, transactionId);
  const lineItems = transactionLineItemsSelector(transaction);
  const feeItems = transactionFeesSelector(transaction, true);
  const total = transactionTotalsSelector(transaction);
  const { getTransactionById, getPaymentMethods, getCreditCardPageData } =
    transactionUISelector(state);
  const customerDetails = buyerCheckoutDetailsSelector(state);
  return {
    billingItems: getBillingLineItems(lineItems, feeItems),
    currency: transaction.currency
      ? getCurrencyTagFromApiCurrency(transaction.currency)
      : undefined,
    customerDetails: customerDetails,
    isLoading:
      getCreditCardPageData.loading ||
      customerDetailsLoadingSelector(state) ||
      customerSavedCardsLoadingSelector(state),
    dataError: getTransactionById.error || getPaymentMethods.error || getCreditCardPageData.error,
    dataErrorMessage:
      getTransactionById.errorMessage ||
      getPaymentMethods.errorMessage ||
      getCreditCardPageData.errorMessage,
    subtotal: getItemSubtotal(lineItems),
    transaction: transaction,
    total: total.credit_card || undefined,
    savedCards: customerSavedCreditCardsSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCreditCardData: (id) => dispatch(getCreditCardPageDataRoutine({ transId: id })),
});

const CreditCardPaymentHeader = () => (
  <header className="account-section-header">
    <div className="account-section-header-content account-section-header-content--full">
      <h2 className="account-section-title">Pay with Credit / Debit Card</h2>
      <p className="account-section-desc">
        Only payments by cards that are under your name or your company name are accepted.
      </p>
      <p className="account-section-desc account-section-note">
        <span className="field-required">*</span> denotes a required field
      </p>
    </div>
  </header>
);

class CreditCardPaymentContainer extends React.Component {
  componentDidMount() {
    this.props.getCreditCardData(transactionId);
  }

  render() {
    const {
      billingItems,
      currency,
      customerDetails,
      dataError,
      dataErrorMessage,
      isLoading,
      subtotal,
      total,
      transaction,
      savedCards,
    } = this.props;

    return (
      <div>
        <CreditCardPaymentHeader />
        <CreditCardForm
          billingLineItems={billingItems}
          billingTotal={total}
          currency={currency}
          customerDetails={customerDetails}
          dataError={dataError}
          dataErrorMessage={dataErrorMessage}
          isLoading={isLoading}
          transaction={transaction}
          subTotal={subtotal}
          savedCards={savedCards}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardPaymentContainer);
