import React, { useState, useEffect } from 'react';
import QueryString from 'query-string';
import { connect } from 'react-redux';

import Header from 'spa/containers/Header';
import Footer from 'spa/containers/Footer';
import IntegrationsLandingPageContainer from 'spa/containers/IntegrationsLanding';
import {
  tokenCustomerSelector,
  tokenReturnUrlSelector,
} from 'spa/selectors/LandingPageTokenSelectors';
import { fetchTokenCustomerDetails as fetchTokenCustomerDetailsRoutine } from 'spa/actions/LandingPageTokenActions';

const IntegrationsLandingPage = (props) => {
  const [token] = useState(QueryString.parse(window.location.search).token);
  const { fetchCustomerFromToken } = props;

  useEffect(() => {
    if (token) {
      fetchCustomerFromToken(token);
    }
  }, [token, fetchCustomerFromToken]);

  return (
    <div className="section--dark">
      <Header />
      <div className="checkout section section--small">
        <div className="checkout-content section-container">
          <div className="checkout-inner">
            {props.returnUrl && (
              <IntegrationsLandingPageContainer token={token} returnUrl={props.returnUrl} />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  tokenCustomerDetails: tokenCustomerSelector(state),
  returnUrl: tokenReturnUrlSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchCustomerFromToken: (token) => {
    dispatch(fetchTokenCustomerDetailsRoutine({ token }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationsLandingPage);
