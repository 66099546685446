import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Code } from 'spa/components/Code';
import PrimaryLink from 'spa/components/PrimaryLink';
import { Section, SectionTitle, SectionSubTitle, SectionDesc } from 'spa/components/Section';
import AccentedText from 'spa/components/AccentedText';

import { urlFor, envDomainName } from '../../../routeConfig';

const ReceiveTransaction = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktopSmall-9">
        <h1 className="integrationsPortal-title">API Guide :: Receiving items in a transaction</h1>
        <Section>
          <SectionTitle>Receiving items in a transaction</SectionTitle>
          <SectionDesc>
            Once you have received the goods, you mark the applicable items as received. This will
            start the inspection period. If you do not accept or reject the items before the end of
            the inspection period, Escrow.com will automatically assume that you accept them.
          </SectionDesc>
        </Section>
        <Section>
          <SectionSubTitle>Marking all items as received</SectionSubTitle>
          <SectionDesc>
            Marking all of the items in a transaction as received is as simple as making a patch
            request to
            <AccentedText>
              https://api.{envDomainName}/2017-09-01/transaction/<b>id</b>
            </AccentedText>
            and setting the <AccentedText>action</AccentedText> field to
            <AccentedText>receive</AccentedText>.
          </SectionDesc>
          <SectionDesc>
            If the API call is successful, it will return the updated transaction object.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.${envDomainName}/2017-09-01/transaction/2020" \\
    -X PATCH \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
    -d '
{
    "action": "receive"
}'
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.patch(
    'https://api.${envDomainName}/2017-09-01/transaction/2020'
    auth=('email address', 'api-key'),
    json={
        "action": "receive",
    },
)
`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.${envDomainName}/2017-09-01/transaction/2020")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Patch.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
  request.body = {
    'action': 'receive',
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.${envDomainName}/2017-09-01/transaction/2020',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_CUSTOMREQUEST => 'PATCH',
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'action' => 'receive',
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                action = "receive",
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.${envDomainName}/2017-09-01/transaction/2020"),
                Method = new HttpMethod("PATCH"),
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
        </Section>
        <Section>
          <SectionSubTitle>
            Marking individual items as received on a milestone transaction
          </SectionSubTitle>
          <SectionDesc>
            For milestone transactions, you must mark individual items on a transaction as received.
            The request is similar to marking all of the items as received on a transaction, however
            you perform the patch request on the item subresource of the transaction.
            <AccentedText>
              https://api.{envDomainName}/2017-09-01/transaction/<b>transaction_id</b>/item/
              <b>item_id</b>
            </AccentedText>
          </SectionDesc>
          <SectionDesc>
            If the API call is successful, it will return the updated transaction object.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.${envDomainName}/2017-09-01/transaction/2020/item/1999" \\
    -X PATCH \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
    -d '
{
    "action": "receive"
}'
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.patch(
    'https://api.${envDomainName}/2017-09-01/transaction/2020/item/1999'
    auth=('email address', 'api-key'),
    json={
        "action": "receive",
    },
)
`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.${envDomainName}/2017-09-01/transaction/2020/item/1999")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Patch.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
  request.body = {
    'action': 'receive',
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.${envDomainName}/2017-09-01/transaction/2020/item/1999',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_CUSTOMREQUEST => 'PATCH',
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'action' => 'receive',
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                action = "receive",
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri(
                    "https://api.${envDomainName}/2017-09-01/transaction/2020/item/1999"),
                Method = new HttpMethod("PATCH"),
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
        </Section>
        <footer className="integrationsPortal-footer">
          <h3 className="integrationsPortal-footer-title">{'Next steps'}</h3>
          <nav className="integrationsPortal-footer-steps">
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Accept items in a transaction"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_accept_items'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Reject items in a transaction"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_reject_items'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Full API reference Document"
                link={{
                  type: 'external',
                  route: window.config.api_docs_page,
                }}
              />
            </div>
          </nav>
        </footer>
      </div>
    </div>
  </div>
);

export default ReceiveTransaction;
