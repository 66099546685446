import { createRoutine } from 'redux-saga-routines';
import PaymentConstants from 'spa/constants/PaymentConstants';

export const getCheckDetails = createRoutine(PaymentConstants.GET_CHECK_DETAILS);
export const getWireDetails = createRoutine(PaymentConstants.GET_WIRE_DETAILS);
export const initPlaidClient = createRoutine(PaymentConstants.INIT_PLAID);
export const launchPlaidClient = createRoutine(PaymentConstants.LAUNCH_PLAID);
export const getLastBuyerBankEntry = createRoutine(PaymentConstants.GET_LAST_BUYER_BANK_ENTRY);

export const setPaymentType = (paymentType) => ({
  payload: {
    paymentType,
  },
  type: PaymentConstants.SET_PAYMENT_TYPE,
});

export const setAdyenFormData = (payload) => ({
  type: PaymentConstants.SET_ADYEN_FORM_DATA,
  payload,
});

export const setAdyenAction = (payload) => ({
  type: PaymentConstants.SET_ADYEN_ACTION,
  payload,
});

export const setAdyenData = (payload) => ({
  type: PaymentConstants.SET_ADYEN_DATA,
  payload,
});

export const setAdyenPaymentToken = (payload) => ({
  type: PaymentConstants.SET_ADYEN_PAYMENT_TOKEN,
  payload,
});

export const setAdyenThreeDSResult = (threeDSResult) => ({
  type: PaymentConstants.SET_ADYEN_THREEDS_RESULT,
  payload: {
    threeDSResult,
  },
});

export const initAdyenCheckout = createRoutine(PaymentConstants.INIT_ADYEN_CHECKOUT);

export const selectPaymentMethod = createRoutine(PaymentConstants.SELECT_PAYMENT_METHOD);

export const submitUserWireDetails = createRoutine(PaymentConstants.SUBMIT_USER_WIRE_DETAILS);
export const showWireForm = () => ({
  type: PaymentConstants.SHOW_WIRE_FORM,
});

export const fetchTransactionAndPaymentMethods = createRoutine(
  PaymentConstants.FETCH_TRANSACTION_AND_PAYMENT_METHODS
);

export const getOutstandingBalance = createRoutine(PaymentConstants.GET_OUTSTANDING_BALANCE);

export const triggerCardVerification = createRoutine(PaymentConstants.TRIGGER_CHARGE);
export const triggerCardVerificationForm = createRoutine(PaymentConstants.TRIGGER_CHARGE_FORM);
export const getCardDetails = createRoutine(PaymentConstants.GET_CARD_DETAILS);

export const verifyCardVerification = createRoutine(PaymentConstants.VERIFY_CHARGE);
export const verifyCardVerificationForm = createRoutine(PaymentConstants.VERIFY_CHARGE_FORM);
export const getCardVerificationDetails = createRoutine(PaymentConstants.GET_CHARGE_DETAILS);

export const saveCreditCardForm = createRoutine(PaymentConstants.SAVE_CREDIT_CARD_FORM);
export const saveCreditCard = createRoutine(PaymentConstants.SAVE_CREDIT_CARD);
export const processSuccessfulPaypalPayment = createRoutine(
  PaymentConstants.PROCESS_SUCCESSFUL_PAYPAL_PAYMENT
);

export const resetPaymentPageState = () => ({
  type: PaymentConstants.RESET_STATE,
});
