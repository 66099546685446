import React from 'react';
import window from 'window-shim';
import { connect } from 'react-redux';
import { isSubmitting } from 'redux-form';
import { bindRoutineToReduxForm } from 'redux-saga-routines';

import { Spinner } from 'spa/components/Indicators';
import {
  AccountType,
  PersonalDetails,
  CompanyDetails,
  customerStateToForm,
  ContactDetailsForm,
} from 'spa/components/ContactDetails';
import AuthenticationContainer from 'spa/containers/Authentication';
import {
  isCustomerReadySelector,
  customerSelector,
  customerRequireExtendedKycSelector,
  customerDetailsLoadingSelector,
  customerIsCompanySelector,
  customerKyc1VerifiedSelector,
  customerKyc2ApprovedSelector,
  customerKyc2SubmittedSelector,
  customerKyc2IsLocallySubmittedSelector,
  customerKyc2InProgressSelector,
  customerKyc3ApprovedSelector,
  customerKyc3SubmittedSelector,
  customerKyc3IsLocallySubmittedSelector,
  customerKyc3InProgressSelector,
  customerEKycFieldsSelector,
  customerEKycDataLoading,
  customerEKycDataSuccess,
  customerTaxNumbersSelector,
  customerCountrySelector,
  customerCompanyCountrySelector,
  customerSubmittedExtendedKyc,
  customerExtendedKycVerified,
  customerExtendedKycRejected,
} from 'spa/selectors/CustomerSelectors';
import {
  loginLoadingSelector,
  login2faSuccessSelector,
} from 'spa/selectors/AuthenticationSelectors';
import { fileUploadingSelector } from 'spa/selectors/FileUploadSelectors';
import AuthenticationConstants from 'spa/constants/AuthenticationConstants';
import VerificationConstants, {
  messagesByAlpha3Country,
} from 'spa/constants/VerificationConstants';
import { GeoipAlpha3, Alpha2VatCountries, Alpha3ToAlpha2 } from 'spa/constants/ISOCountryCodes';
import {
  PersonalVerificationForm,
  CompanyVerificationForm,
  ElectronicVerificationForm,
  VerificationStatusPanel,
  SampleVerificationDocument,
} from 'spa/components/CustomerVerification';
import { LandingPageTokenHeader } from 'spa/components/LandingPageToken';
import {
  submitCompanyVerificationForm,
  submitPersonalVerificationForm,
  submitElectronicVerificationForm,
  submitCustomerDetailsForm,
} from 'spa/actions/VerificationActions';
import { getUserEKYCData as getUserEKYCDataRoutine } from 'spa/actions/UserActions';
import { gettext } from '../../../utils/filters';
import AuthenticationStore from '../../../stores/AuthenticationStore';

class KYCContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAccountTypePanel: true,
      showPersonalVerificationsForm: false,
      showCompanyVerificationsForm: false,
      showElectronicVerificationForm: false,
      showDetailsForm: false,
      showStatusPanels: true,
      showSampleDocuments: false,
    };

    this.handleSubmissionSuccess = this.handleSubmissionSuccess.bind(this);
    this.switchToStatusView = this.switchToStatusView.bind(this);
    this.switchToEditCustomerDetailsView = this.switchToEditCustomerDetailsView.bind(this);
    this.switchToPersonalVerificationsView = this.switchToPersonalVerificationsView.bind(this);
    this.switchToCompanyVerificationsView = this.switchToCompanyVerificationsView.bind(this);
    this.switchToElectronicVerificationView = this.switchToElectronicVerificationView.bind(this);
    this.toggleShowSampleDocuments = this.toggleShowSampleDocuments.bind(this);
  }

  componentDidMount() {
    if (AuthenticationStore.isAuthenticated() && !this.props.ekycDataReady) {
      this.props.getUserEKYCData();
    }
    const initialView = (window.js_context || {}).spa_landing_view;
    switch (initialView) {
      case 'CUSTOMER_DETAILS':
        this.switchToEditCustomerDetailsView();
        break;
      case 'PERSONAL_VERIFICATION':
        this.props.forEKyc
          ? this.switchToElectronicVerificationView()
          : this.switchToPersonalVerificationsView();
        break;
      case 'COMPANY_VERIFICATION':
        this.switchToCompanyVerificationsView();
        break;
      default:
        break;
    }
  }

  getVerificationStatus(
    locallySubmittted,
    isSubmitted,
    isVerified,
    isInProgress,
    extendedKycRequested,
    extendedKycSubmitted,
    extendedKycVerified,
    extendedKycRejected
  ) {
    if (locallySubmittted) {
      return 'SUBMITTED';
    } else if (extendedKycVerified) {
      return 'VERIFIED';
    } else if (extendedKycRejected) {
      return 'REJECTED';
    } else if (extendedKycRequested && extendedKycSubmitted) {
      return 'SUBMITTED';
    } else if (extendedKycRequested) {
      return 'EXTENDED_REQUESTED';
    } else if (!isVerified && !isInProgress && isSubmitted) {
      return 'REJECTED';
    } else if (isVerified) {
      return 'VERIFIED';
    } else if (isSubmitted) {
      return 'SUBMITTED';
    }
    return 'NOT_VERIFIED';
  }

  redirectToReturnUrl() {
    const jsContext = window.js_context || {};
    if (jsContext.return_url) {
      window.location.assign(`${window.config.www_base_url}${jsContext.return_url}`);
    }
  }

  handleSubmissionSuccess(switchViewFunction, submissionType) {
    // Handle url redirection
    let isRedirectAvailable = false;
    const jsContext = window.js_context || {};
    if (jsContext.return_url && jsContext.return_requirements) {
      const requirements = jsContext.return_requirements;
      const statuses = {
        CUSTOMER_DETAILS: this.props.isCustomerDetailsProvided,
        PERSONAL_VERIFICATION: this.props.isPersonalInformationSubmitted,
        COMPANY_VERIFICATION: this.props.isCompanyInformationSubmitted,
        ACCOUNT_TYPE: this.props.isCompany
          ? this.props.isCompanyInformationSubmitted
          : this.props.isPersonalInformationSubmitted,
      };

      if (submissionType) {
        statuses[submissionType] = true;
      }

      isRedirectAvailable = requirements.reduce((accu, curr) => accu && statuses[curr], true);
    }

    if (isRedirectAvailable) {
      return this.redirectToReturnUrl;
    }

    // By default: switch back to main verificaiton page
    return switchViewFunction;
  }

  switchToStatusView() {
    this.setState({
      showAccountTypePanel: true,
      showPersonalVerificationsForm: false,
      showCompanyVerificationsForm: false,
      showElectronicVerificationForm: false,
      showDetailsForm: false,
      showStatusPanels: true,
      showSampleDocuments: false,
    });
  }

  switchToEditCustomerDetailsView() {
    this.setState({
      showAccountTypePanel: false,
      showPersonalVerificationsForm: false,
      showCompanyVerificationsForm: false,
      showElectronicVerificationForm: false,
      showDetailsForm: true,
      showStatusPanels: false,
      showSampleDocuments: false,
    });
  }

  switchToPersonalVerificationsView() {
    this.setState({
      showAccountTypePanel: false,
      showPersonalVerificationsForm: true,
      showCompanyVerificationsForm: false,
      showElectronicVerificationForm: false,
      showDetailsForm: false,
      showStatusPanels: false,
      showSampleDocuments: true,
    });
  }

  switchToElectronicVerificationView() {
    this.setState({
      showAccountTypePanel: false,
      showPersonalVerificationsForm: false,
      showCompanyVerificationsForm: false,
      showElectronicVerificationForm: true,
      showDetailsForm: false,
      showStatusPanels: false,
      showSampleDocuments: this.props.personalEKycDisabled,
    });
  }

  switchToCompanyVerificationsView() {
    this.setState({
      showAccountTypePanel: false,
      showPersonalVerificationsForm: false,
      showCompanyVerificationsForm: true,
      showElectronicVerificationForm: false,
      showDetailsForm: false,
      showStatusPanels: false,
      showSampleDocuments: false,
    });
  }

  toggleShowSampleDocuments(event) {
    this.setState({
      showSampleDocuments: event.target.value === 'upload-document',
    });
  }

  renderLoadingSpinner() {
    return (
      <div className="verifyPage-loader">
        <Spinner />
      </div>
    );
  }

  renderAuthenticationForm() {
    return (
      <div data-tracking-subsection="kyc-page-auth">
        <AuthenticationContainer
          onSuccess={this.props.getUserEKYCData}
          loginPage={AuthenticationConstants.KYC_LOGIN_PAGE}
        />
      </div>
    );
  }

  renderAccountTypePanel() {
    return (
      <AccountType
        title={gettext('Account Type')}
        isCompany={this.props.isCustomerOnBehalfOfCompany}
        changeAccountTypeOnClick={this.switchToEditCustomerDetailsView}
        changeAccountTypeLabel={gettext('Change')}
        data-e2e-target="account-type-panel"
      />
    );
  }

  renderDetailsForm() {
    const verificationStatus = this.getVerificationStatus(
      this.props.personalLocallySubmittted,
      this.props.isPersonalInformationSubmitted,
      this.props.isPersonalInformationVerified,
      this.props.isPersonalInformationInProgress,
      this.props.enableExtendedKyc,
      this.props.extendedKycSubmitted,
      this.props.extendedKycVerified,
      this.props.extendedKycRejected
    );
    return (
      <div className="verifyPage-body" data-tracking-subsection="personal-details">
        <ContactDetailsForm
          initialValues={{
            ...this.props.customerFormDetails,
            country: this.props.customerFormDetails.country
              ? this.props.customerFormDetails.country
              : Alpha3ToAlpha2[window.config.geoip_country],
            companyCountry: this.props.customerFormDetails.companyCountry
              ? this.props.customerFormDetails.companyCountry
              : Alpha3ToAlpha2[window.config.geoip_country],
            vatDetails: this.props.vatDetails,
            showCancel: this.props.hasContactDetails,
          }}
          submitFunction={bindRoutineToReduxForm(submitCustomerDetailsForm)}
          onCancel={this.switchToStatusView}
          onSubmitSuccess={(result, dispatch, props) => {
            const forEKyc = !props.disableEKYC && props.isCountryForEKyc;
            const successFunction = forEKyc
              ? this.handleSubmissionSuccess(
                  this.switchToElectronicVerificationView,
                  'CUSTOMER_DETAILS'
                )
              : this.handleSubmissionSuccess(this.switchToStatusView, 'CUSTOMER_DETAILS');
            successFunction();
          }}
          title={gettext('Add Your Details')}
          disableEKYC={
            this.props.enableExtendedKyc ||
            this.props.personalEKycDisabled ||
            !['NOT_VERIFIED', 'REJECTED'].includes(verificationStatus)
          }
          disableVatDetails={this.props.hasTaxNumbers}
          disableNameAndDob={this.props.isPersonalInformationVerified}
          disableCompanyName={this.props.isCompanyInformationVerified}
          isCountryForEKyc={this.props.isCountryForEKyc}
        />
      </div>
    );
  }

  renderPersonalVerificationForm() {
    const enableExtendedKyc =
      this.props.enableExtendedKyc || Boolean((window.js_context || {}).extended_kyc);
    const messages = messagesByAlpha3Country[GeoipAlpha3] || messagesByAlpha3Country.default;
    return (
      <div data-tracking-subsection="personal-details">
        <PersonalVerificationForm
          onSubmit={bindRoutineToReduxForm(submitPersonalVerificationForm)}
          onCancel={this.switchToStatusView}
          onSubmitSuccess={this.handleSubmissionSuccess(
            this.switchToStatusView,
            'PERSONAL_VERIFICATION'
          )}
          title={gettext('Verify Your Details')}
          desc={messages.PERSONAL_VERIFICATION_DESCRIPTION}
          uploading={this.props.isFileUploading}
          enableExtendedKyc={enableExtendedKyc}
        />
      </div>
    );
  }

  renderElectronicVerificationForm() {
    const message =
      'Please select a document from the list below which will be used to verify your personal details. This verification of your personal details does not involve a credit check and will not impact your credit score.';
    return (
      <div data-tracking-subsection="personal-details">
        <ElectronicVerificationForm
          disableEKYC={this.props.personalEKycDisabled}
          onSubmit={bindRoutineToReduxForm(submitElectronicVerificationForm)}
          onCancel={this.switchToStatusView}
          onSubmitSuccess={this.handleSubmissionSuccess(
            this.switchToStatusView,
            'PERSONAL_VERIFICATION'
          )}
          onDocumentChange={this.toggleShowSampleDocuments}
          title={gettext('Document Verification')}
          desc={message}
          personalCountry={this.props.personalCountry}
          uploading={this.props.isFileUploading}
          {...(this.props.personalEKycDisabled && {
            initialValues: { verificationMethod: 'upload-document' },
          })}
        />
      </div>
    );
  }

  renderCompanyVerificationForm() {
    const messages = messagesByAlpha3Country[GeoipAlpha3] || messagesByAlpha3Country.default;
    return (
      <div data-tracking-subsection="company-details">
        <CompanyVerificationForm
          onSubmit={bindRoutineToReduxForm(submitCompanyVerificationForm)}
          onCancel={this.switchToStatusView}
          onSubmitSuccess={this.handleSubmissionSuccess(
            this.switchToStatusView,
            'COMPANY_VERIFICATION'
          )}
          title={gettext('Verify Your Company')}
          desc={messages.COMPANY_VERIFICATION_DESCRIPTION}
          companyCountry={this.props.companyCountry}
          uploading={this.props.isFileUploading}
        />
      </div>
    );
  }

  renderPersonalVerificationStatusPanel() {
    const {
      forEKyc,
      customerDetails,
      hasContactDetails,
      personalEKycFields,
      personalLocallySubmittted,
      isPersonalInformationSubmitted,
      isPersonalInformationVerified,
      isPersonalInformationInProgress,
      enableExtendedKyc,
      extendedKycSubmitted,
      extendedKycVerified,
      extendedKycRejected,
    } = this.props;
    const verificationStatus = this.getVerificationStatus(
      personalLocallySubmittted,
      isPersonalInformationSubmitted,
      isPersonalInformationVerified,
      isPersonalInformationInProgress,
      enableExtendedKyc,
      extendedKycSubmitted,
      extendedKycVerified,
      extendedKycRejected
    );
    const enableExtendedKycView =
      enableExtendedKyc || Boolean((window.js_context || {}).extended_kyc);
    let verifyDetailsStatus = 'NORMAL';
    if (verificationStatus === 'VERIFIED' || verificationStatus === 'SUBMITTED') {
      verifyDetailsStatus = 'HIDDEN';
    } else if (!hasContactDetails) {
      verifyDetailsStatus = 'DISABLED';
    }
    const messages = messagesByAlpha3Country[GeoipAlpha3] || messagesByAlpha3Country.default;
    let note = messages.PERSONAL_VERIFICATION_NOTE;
    if (enableExtendedKycView) {
      note += ', Photo Verification';
    }
    return (
      <div data-tracking-subsection="personal-details">
        <VerificationStatusPanel
          title={gettext('Your Personal Details')}
          description={messages.PERSONAL_VERIFICATION_DESCRIPTION}
          note={note}
          verificationStatus={verificationStatus}
          editDetailsLabel={gettext('Edit Details')}
          editDetailsOnClick={this.switchToEditCustomerDetailsView}
          verifyDetailsLabel={gettext('Verify Details →')}
          verifyDetailsOnClick={
            forEKyc &&
            !enableExtendedKyc &&
            ['NOT_VERIFIED', 'REJECTED'].includes(verificationStatus)
              ? this.switchToElectronicVerificationView
              : this.switchToPersonalVerificationsView
          }
          verifyDetailsStatus={verifyDetailsStatus}
          data-e2e-target="personal-details-panel"
        >
          <PersonalDetails
            firstName={customerDetails.firstName}
            middleName={customerDetails.middleName}
            lastName={customerDetails.lastName}
            custEmail={customerDetails.custEmail}
            phoneNumber={customerDetails.phoneNumber}
            dateOfBirth={customerDetails.dateOfBirth}
            personalAddress={customerDetails.personalAddress}
            documentFields={personalEKycFields}
          />
        </VerificationStatusPanel>
      </div>
    );
  }

  renderCompanyVerificationStatusPanel() {
    const { customerDetails, isPersonalInformationSubmitted, isCompanyInformationVerified } =
      this.props;
    const verificationStatus = this.getVerificationStatus(
      this.props.companyLocallySubmitted,
      this.props.isCompanyInformationSubmitted,
      this.props.isCompanyInformationVerified,
      this.props.isCompanyInformationInProgress
    );
    let verifyDetailsStatus = 'NORMAL';
    if (isCompanyInformationVerified || verificationStatus === 'SUBMITTED') {
      verifyDetailsStatus = 'HIDDEN';
    } else if (!isPersonalInformationSubmitted) {
      verifyDetailsStatus = 'DISABLED';
    }
    const messages = messagesByAlpha3Country[GeoipAlpha3] || messagesByAlpha3Country.default;
    return (
      <div data-tracking-subsection="company-details">
        <VerificationStatusPanel
          title={gettext('Your Company Details')}
          description={messages.COMPANY_VERIFICATION_DESCRIPTION}
          note={messages.COMPANY_VERIFICATION_NOTE}
          verificationStatus={verificationStatus}
          editDetailsLabel={gettext('Edit Details')}
          editDetailsOnClick={this.switchToEditCustomerDetailsView}
          verifyDetailsLabel={gettext('Verify Details →')}
          verifyDetailsOnClick={this.switchToCompanyVerificationsView}
          verifyDetailsStatus={verifyDetailsStatus}
          data-e2e-target="company-details-panel"
        >
          <CompanyDetails
            isCompany={customerDetails.isCompany}
            firstName={customerDetails.firstName}
            middleName={customerDetails.middleName}
            lastName={customerDetails.lastName}
            companyName={customerDetails.companyName}
            companyAddress={customerDetails.companyAddress}
          />
        </VerificationStatusPanel>
      </div>
    );
  }

  renderIntroHeader() {
    const messages = messagesByAlpha3Country[GeoipAlpha3] || messagesByAlpha3Country.default;
    return (
      <header className="verifyPage-header">
        <h1 className="verifyPage-header-title">{gettext('Please verify your account')}</h1>
        <p className="verifyPage-header-desc">{messages.CUSTOMER_VERIFICATION_DESCRIPTION}</p>
      </header>
    );
  }

  render() {
    return (
      <div>
        {!this.props.isCustomerDetailsSubmitting &&
          this.props.isCustomerDetailsLoading &&
          this.props.isTwoFAReady &&
          !this.state.showDetailsForm &&
          this.renderLoadingSpinner()}
        {(this.props.isCustomerDetailsSubmitting ||
          (!this.props.isCustomerDetailsLoading &&
            this.props.isCustomerReady &&
            !this.props.hideIntroHeader)) &&
          this.renderIntroHeader()}
        {(this.props.isCustomerDetailsSubmitting ||
          (this.props.isCustomerReady &&
            (!this.props.hasContactDetails || this.state.showDetailsForm))) &&
          this.renderDetailsForm()}
        {!this.props.isCustomerDetailsSubmitting && !this.props.isCustomerDetailsLoading && (
          <div>
            {!this.state.showPersonalVerificationsForm && (
              <div className="verifyPage-body">
                {this.props.landingPageToken && (
                  <LandingPageTokenHeader landingPageToken={this.props.landingPageToken} />
                )}
                {!this.props.isCustomerReady ? (
                  <div className="verifyPage-card">{this.renderAuthenticationForm()}</div>
                ) : (
                  <div>
                    {this.props.hasContactDetails &&
                      this.state.showAccountTypePanel &&
                      this.renderAccountTypePanel()}

                    {this.props.hasContactDetails && this.state.showStatusPanels && (
                      <div className="verifyPage-card">
                        {this.renderPersonalVerificationStatusPanel()}
                      </div>
                    )}

                    {this.props.hasContactDetails &&
                      this.state.showStatusPanels &&
                      this.props.isCustomerOnBehalfOfCompany && (
                        <div className="verifyPage-card">
                          {this.renderCompanyVerificationStatusPanel()}
                        </div>
                      )}

                    {this.props.hasContactDetails && this.state.showCompanyVerificationsForm && (
                      <div className="verifyPage-card">{this.renderCompanyVerificationForm()}</div>
                    )}
                  </div>
                )}
              </div>
            )}
            {this.props.hasContactDetails &&
              (this.state.showPersonalVerificationsForm ||
                this.state.showElectronicVerificationForm) && (
                <div
                  className={`verifyPage-body ${
                    this.state.showSampleDocuments ? `verifyPage-documents` : ''
                  }`}
                >
                  <div className="verifyPage-documents-formContainer">
                    {this.props.landingPageToken && (
                      <LandingPageTokenHeader landingPageToken={this.props.landingPageToken} />
                    )}
                    <div className="verifyPage-card">
                      {this.state.showElectronicVerificationForm &&
                        this.renderElectronicVerificationForm()}
                      {this.state.showPersonalVerificationsForm &&
                        this.renderPersonalVerificationForm()}
                    </div>
                  </div>
                  {this.state.showSampleDocuments && (
                    <div className="verifyPage-documents-sample">
                      {this.props.enableExtendedKyc && (
                        <SampleVerificationDocument
                          title={gettext('Example of a photo of you holding your ID')}
                          description={gettext(
                            'Please make sure you take a high-resolution photo of you holding your ID'
                          )}
                          imgAlt={gettext('Extended Verification Example')}
                          imgSrc="../build/images/verify/kyc-photo-verification.jpg"
                        />
                      )}
                      <SampleVerificationDocument
                        title={gettext('Example of an acceptable ID')}
                        description={gettext(
                          'Please make sure you take a high-resolution photo of your ID in a well-lighted room'
                        )}
                        imgAlt={gettext('Driver License Example')}
                        imgSrc="../build/images/verify/example-driver-license.png"
                      />
                      <SampleVerificationDocument
                        title={gettext('Example of an acceptable document')}
                        description={gettext(
                          'Please make sure you take a high-resolution photo of your document in a well-lighted room'
                        )}
                        imgAlt={gettext('Bank Statement Example')}
                        imgSrc="../build/images/verify/example-bank-statement.png"
                      />
                    </div>
                  )}
                </div>
              )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const userPersonalCountry = customerCountrySelector(state);
  const userCompanyCountry = customerCompanyCountrySelector(state);
  let vatCountryInitial;
  if (userPersonalCountry && Alpha2VatCountries.includes(userPersonalCountry)) {
    vatCountryInitial = userPersonalCountry;
  } else if (userCompanyCountry && Alpha2VatCountries.includes(userCompanyCountry)) {
    vatCountryInitial = userCompanyCountry;
  }

  return {
    isCustomerReady:
      !loginLoadingSelector(state) &&
      login2faSuccessSelector(state) &&
      isCustomerReadySelector(state) &&
      customerEKycDataSuccess(state),
    isCustomerDetailsLoading:
      customerDetailsLoadingSelector(state) || customerEKycDataLoading(state),
    isCustomerDetailsSubmitting: isSubmitting(VerificationConstants.CUSTOMER_DETAILS_FORM)(state),
    isCustomerOnBehalfOfCompany: customerIsCompanySelector(state),

    isCustomerDetailsProvided: customerKyc1VerifiedSelector(state),

    personalEKycFields: customerEKycFieldsSelector(state),
    personalEKycDisabled: true, // force disable for Shopify integration
    isPersonalInformationVerified: customerKyc2ApprovedSelector(state),
    isPersonalInformationSubmitted:
      customerKyc2SubmittedSelector(state) || customerKyc2IsLocallySubmittedSelector(state),
    isPersonalInformationInProgress:
      customerKyc2InProgressSelector(state) || customerKyc2IsLocallySubmittedSelector(state),

    isCompanyInformationVerified: customerKyc3ApprovedSelector(state),
    isCompanyInformationSubmitted:
      customerKyc3SubmittedSelector(state) || customerKyc3IsLocallySubmittedSelector(state),
    isCompanyInformationInProgress:
      customerKyc3InProgressSelector(state) || customerKyc3IsLocallySubmittedSelector(state),

    customerDetails: customerSelector(state),
    hasContactDetails: customerKyc1VerifiedSelector(state),
    hasTaxNumbers: (customerTaxNumbersSelector(state) || []).length > 0,
    customerFormDetails: customerStateToForm(customerSelector(state)),
    vatDetails: { vatCountry: vatCountryInitial },
    enableExtendedKyc: customerRequireExtendedKycSelector(state),
    extendedKycSubmitted:
      customerSubmittedExtendedKyc(state) || customerKyc2IsLocallySubmittedSelector(state),
    extendedKycVerified: customerExtendedKycVerified(state),
    extendedKycRejected: customerExtendedKycRejected(state),
    personalLocallySubmittted: customerKyc2IsLocallySubmittedSelector(state),
    companyLocallySubmitted: customerKyc3IsLocallySubmittedSelector(state),
    companyCountry: userCompanyCountry,
    personalCountry: userPersonalCountry,
    ekycDataReady: customerEKycDataSuccess(state),
    forEKyc: false, // force disable for Shopify integration
    isCountryForEKyc: false, // force disable for Shopify integration
    isFileUploading: fileUploadingSelector(state),
    isCompany: customerIsCompanySelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUserEKYCData: () => dispatch(getUserEKYCDataRoutine({ getFields: true })),
});

export default connect(mapStateToProps, mapDispatchToProps)(KYCContainer);
