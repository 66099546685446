import NotificationSettingConstants from 'spa/constants/NotificationSettingConstants';
import { createRoutine } from 'redux-saga-routines';

export const getNotificationSettings = createRoutine(
  NotificationSettingConstants.GET_NOTIFICATION_SETTINGS
);

export const setNotificationSettings = createRoutine(
  NotificationSettingConstants.SET_NOTIFICATION_SETTINGS
);
