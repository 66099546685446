import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import ReportsTable from 'spa/containers/PartnerDashboard/ReportsTable';
import { maxRowsPerPage } from 'spa/components/PartnerDashboard/tables/EnhancedTable';
import {
  getReportsList as getReportsListRoutine,
  getReportsCount as getReportsCountRoutine,
} from 'spa/actions/PartnerActions';
import { reportsCountSelector, reportsCursorSelector } from 'spa/selectors/PartnerSelectors';

const ReportsListPage = (props) => {
  const { nextCursor, getReportsData, getReportsCount, reportsTotal } = props;
  return (
    <Fragment>
      <header className="partnerDashboard-sectionHeader">
        <h2 className="partnerDashboard-sectionHeader-title">Reports</h2>
      </header>
      <div className="partnerDashboard-controlResult">
        <div className="partnerDashboard-controlResult-count">{reportsTotal} results found</div>
      </div>
      <ReportsTable
        getData={() => getReportsData({ cursor: nextCursor, limit: maxRowsPerPage })}
        getDataTotals={() => getReportsCount()}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  reportsTotal: reportsCountSelector(state),
  nextCursor: reportsCursorSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getReportsData: (payload) => dispatch(getReportsListRoutine.trigger(payload)),
  getReportsCount: () => dispatch(getReportsCountRoutine.trigger()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsListPage);
