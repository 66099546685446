import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  flowSelection: null,
  customerId: null,
  details: {
    phone: '',
    accountType: '',
    email: '',
  },
  individualDetails: {
    'first-name': '',
    'middle-name': '',
    'last-name': '',
    'date-of-birth': '',
    country: '',
    address: '',
    city: '',
    state: '',
    'post-code': '',
    occupation: '',
    'vat-country-code': '',
    'vat-number': '',
    'local-fname': '',
    'local-lname': '',
  },
  id: {
    type: '',
    number: '',
    expiry: '',
  },
  poa: {
    type: '',
  },
  companyDetails: {
    companyName: '',
    country: '',
    companyAddress: '',
    city: '',
    state: '',
    postCode: '',
  },
  companyDocument: {
    companyType: '',
    documentType: '',
    relationship: '',
  },
  images: {},
  uploadedFilesInfo: {},
  navigation: {
    previousPages: [],
    currentPage: '',
  },
};

export const kycSlice = createSlice({
  name: 'kyc',
  initialState,
  reducers: {
    pushPreviousPage: (state, action) => {
      const { page } = action.payload;
      state.navigation.previousPages.push(page);
    },
    popPreviousPage: (state) => {
      state.navigation.previousPages.pop();
    },
    saveCurrentPage: (state, action) => {
      const { page } = action.payload;
      state.navigation.currentPage = page;
    },
    saveIndividualDetails: (state, action) => {
      state.individualDetails = action.payload;
    },
    saveSelectedID: (state, action) => {
      state.id = action.payload;
    },
    saveSelectedPOA: (state, action) => {
      state.poa = action.payload;
    },
    saveCompanyDetails: (state, action) => {
      state.companyDetails = action.payload;
    },
    saveCompanyDocument: (state, action) => {
      state.companyDocument = action.payload;
    },
    storeKycDataUrl: (state, action) => {
      const { fileKey, value } = action.payload;
      state.images[fileKey] = { id: null, value: value };
    },
    updateKycImageId: (state, action) => {
      const { fileKey, id } = action.payload;
      if (state.images[fileKey]) {
        state.images[fileKey].id = id;
      } else {
        state.images[fileKey] = { id: id };
      }
    },
    updateUploadedFilesInfo: (state, action) => {
      const { fileKey, fileName, fileSize } = action.payload;
      state.uploadedFilesInfo = {
        ...state.uploadedFilesInfo,
        [fileKey]: {
          fileName: fileName,
          fileSize: fileSize,
        },
      };
    },
    removeUploadedFilesInfo: (state, action) => {
      const { fileKey } = action.payload;
      state.uploadedFilesInfo = {
        ...state.uploadedFilesInfo,
        [fileKey]: null,
      };
    },
    clearKycImages: (state) => {
      state.images = {};
    },
    savePhoneNumber: (state, action) => {
      const { phoneNumber } = action.payload;
      state.details.phone = phoneNumber;
    },
    saveAccountType: (state, action) => {
      const { accountType } = action.payload;
      state.details.accountType = accountType;
    },
    saveFlowSelection: (state, action) => {
      const { flowSelection } = action.payload;
      state.flowSelection = flowSelection;
    },
    saveCustomerId: (state, action) => {
      const { customerId } = action.payload;
      state.customerId = customerId;
    },
    saveEmail: (state, action) => {
      const { email } = action.payload;
      state.details.email = email;
    },
  },
});

export const kycSliceSelector = (state) => state.kyc;
export const kycIndividualDetailsSelector = (state) => kycSliceSelector(state).individualDetails;
export const kycSelectedIDSelector = (state) => kycSliceSelector(state).id;
export const kycSelectedPOASelector = (state) => kycSliceSelector(state).poa;
export const kycCompanyDetailsSelector = (state) => kycSliceSelector(state).companyDetails;
export const kycCompanyDocumentSelector = (state) => kycSliceSelector(state).companyDocument;
export const kycImageSelector = (key) => (state) => kycSliceSelector(state).images[key].value;
export const kycPhoneNumberSelector = (state) => kycSliceSelector(state).details.phone;
export const kycImagesSelector = (state) => kycSliceSelector(state).images;
export const kycUploadedFileInfoSelector = (key) => (state) =>
  kycSliceSelector(state).uploadedFilesInfo[key];
export const kycPreviousPagesSelector = (state) => kycSliceSelector(state).navigation.previousPages;
export const kycCurrentPageSelector = (state) => kycSliceSelector(state).navigation.currentPage;
export const kycAccountTypeSelector = (state) => kycSliceSelector(state).details.accountType;
export const kycFlowSelector = (state) => kycSliceSelector(state).flowSelection;
export const kycCustomerIdSelector = (state) => kycSliceSelector(state).customerId;
export const kycEmailSelector = (state) => kycSliceSelector(state).details.email;
export const {
  pushPreviousPage,
  popPreviousPage,
  saveCurrentPage,
  saveIndividualDetails,
  saveSelectedID,
  saveSelectedPOA,
  saveCompanyDetails,
  saveCompanyDocument,
  storeKycDataUrl,
  updateKycImageId,
  updateUploadedFilesInfo,
  removeUploadedFilesInfo,
  clearKycImages,
  savePhoneNumber,
  saveAccountType,
  saveFlowSelection,
  saveCustomerId,
  saveEmail,
} = kycSlice.actions;
