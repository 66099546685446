import React from 'react';
import { Alert } from '@mui/material';
import { SmallText } from 'spa/features/ui/EscrowTypography';
import { ERROR_TYPES } from 'spa/constants/VerificationConstants';

const KYCAlert = ({ body, isError, errorType, xRequestId, sx }) => {
  let error = body;
  let contact = 'contact support@escrow.com.';
  if (xRequestId) {
    contact = `contact support@escrow.com and reference: ${xRequestId}`;
  }

  if (isError) {
    if (errorType === ERROR_TYPES.PHOTO_UPLOAD_FAILED) {
      error = `We could not upload the photo due to a technical issue on our end. Please try again. If the issue persists, ${contact}`;
    } else if (errorType === ERROR_TYPES.SUBMISSION_FAILED) {
      error = `We could not complete the submission due to a technical issue on our end. Please try again. If the issue persists, ${contact}`;
    } else if (errorType === ERROR_TYPES.FILE_UPLOAD_FAILED) {
      error = `We could not upload the file due to a technical issue on our end. Please try to upload a file again. If the issue persists, ${contact}`;
    } else {
      error = `We could not process that action due to a technical issue on our end. Please try again. If the issue persists, ${contact}`;
    }
  }

  return (
    <Alert variant="outlined" severity="error" sx={{ bgcolor: 'background.paper', ...sx }}>
      <SmallText>{error}</SmallText>
    </Alert>
  );
};

export default KYCAlert;
