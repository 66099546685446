import { invert } from 'lodash';

export const CountryToAlpha2 = {
  Afghanistan: 'AF',
  Albania: 'AL',
  Algeria: 'DZ',
  'American Samoa': 'AS',
  Andorra: 'AD',
  Angola: 'AO',
  Anguilla: 'AI',
  Antarctica: 'AQ',
  'Antigua and Barbuda': 'AG',
  Argentina: 'AR',
  Armenia: 'AM',
  Aruba: 'AW',
  Australia: 'AU',
  Austria: 'AT',
  Azerbaijan: 'AZ',
  Bahamas: 'BS',
  Bahrain: 'BH',
  Bangladesh: 'BD',
  Barbados: 'BB',
  Belarus: 'BY',
  Belgium: 'BE',
  Belize: 'BZ',
  Benin: 'BJ',
  Bermuda: 'BM',
  Bhutan: 'BT',
  'Bolivia, Plurinational State of': 'BO',
  'Bonaire, Sint Eustatius and Saba': 'BQ',
  'Bosnia and Herzegovina': 'BA',
  Botswana: 'BW',
  'Bouvet Island': 'BV',
  Brazil: 'BR',
  'British Indian Ocean Territory': 'IO',
  'Brunei Darussalam': 'BN',
  Bulgaria: 'BG',
  'Burkina Faso': 'BF',
  Burundi: 'BI',
  'Cabo Verde': 'CV',
  Cambodia: 'KH',
  Cameroon: 'CM',
  Canada: 'CA',
  'Cayman Islands': 'KY',
  'Central African Republic': 'CF',
  Chad: 'TD',
  Chile: 'CL',
  China: 'CN',
  'Christmas Island': 'CX',
  'Cocos (Keeling) Islands': 'CC',
  Colombia: 'CO',
  Comoros: 'KM',
  Congo: 'CG',
  'Congo, The Democratic Republic of the': 'CD',
  'Cook Islands': 'CK',
  'Costa Rica': 'CR',
  Croatia: 'HR',
  Cuba: 'CU',
  Curaçao: 'CW',
  Cyprus: 'CY',
  Czechia: 'CZ',
  "Côte d'Ivoire": 'CI',
  Denmark: 'DK',
  Djibouti: 'DJ',
  Dominica: 'DM',
  'Dominican Republic': 'DO',
  Ecuador: 'EC',
  Egypt: 'EG',
  'El Salvador': 'SV',
  'Equatorial Guinea': 'GQ',
  Eritrea: 'ER',
  Estonia: 'EE',
  Ethiopia: 'ET',
  'Falkland Islands (Malvinas)': 'FK',
  'Faroe Islands': 'FO',
  Fiji: 'FJ',
  Finland: 'FI',
  France: 'FR',
  'French Guiana': 'GF',
  'French Polynesia': 'PF',
  'French Southern Territories': 'TF',
  Gabon: 'GA',
  Gambia: 'GM',
  Georgia: 'GE',
  Germany: 'DE',
  Ghana: 'GH',
  Gibraltar: 'GI',
  Greece: 'GR',
  Greenland: 'GL',
  Grenada: 'GD',
  Guadeloupe: 'GP',
  Guam: 'GU',
  Guatemala: 'GT',
  Guernsey: 'GG',
  Guinea: 'GN',
  'Guinea-Bissau': 'GW',
  Guyana: 'GY',
  Haiti: 'HT',
  'Heard Island and McDonald Islands': 'HM',
  'Holy See (Vatican City State)': 'VA',
  Honduras: 'HN',
  'Hong Kong': 'HK',
  Hungary: 'HU',
  Iceland: 'IS',
  India: 'IN',
  Indonesia: 'ID',
  'Iran, Islamic Republic of': 'IR',
  Iraq: 'IQ',
  Ireland: 'IE',
  'Isle of Man': 'IM',
  Israel: 'IL',
  Italy: 'IT',
  Jamaica: 'JM',
  Japan: 'JP',
  Jersey: 'JE',
  Jordan: 'JO',
  Kazakhstan: 'KZ',
  Kenya: 'KE',
  Kiribati: 'KI',
  "Korea, Democratic People's Republic of": 'KP',
  'Korea, Republic of': 'KR',
  Kosovo: 'XK',
  Kuwait: 'KW',
  Kyrgyzstan: 'KG',
  "Lao People's Democratic Republic": 'LA',
  Latvia: 'LV',
  Lebanon: 'LB',
  Lesotho: 'LS',
  Liberia: 'LR',
  Libya: 'LY',
  Liechtenstein: 'LI',
  Lithuania: 'LT',
  Luxembourg: 'LU',
  Macao: 'MO',
  'Macedonia, Republic of': 'MK',
  Madagascar: 'MG',
  Malawi: 'MW',
  Malaysia: 'MY',
  Maldives: 'MV',
  Mali: 'ML',
  Malta: 'MT',
  'Marshall Islands': 'MH',
  Martinique: 'MQ',
  Mauritania: 'MR',
  Mauritius: 'MU',
  Mayotte: 'YT',
  Mexico: 'MX',
  'Micronesia, Federated States of': 'FM',
  'Moldova, Republic of': 'MD',
  Monaco: 'MC',
  Mongolia: 'MN',
  Montenegro: 'ME',
  Montserrat: 'MS',
  Morocco: 'MA',
  Mozambique: 'MZ',
  Myanmar: 'MM',
  Namibia: 'NA',
  Nauru: 'NR',
  Nepal: 'NP',
  Netherlands: 'NL',
  'New Caledonia': 'NC',
  'New Zealand': 'NZ',
  Nicaragua: 'NI',
  Niger: 'NE',
  Nigeria: 'NG',
  Niue: 'NU',
  'Norfolk Island': 'NF',
  'Northern Mariana Islands': 'MP',
  Norway: 'NO',
  Oman: 'OM',
  Pakistan: 'PK',
  Palau: 'PW',
  'Palestine, State of': 'PS',
  Panama: 'PA',
  'Papua New Guinea': 'PG',
  Paraguay: 'PY',
  Peru: 'PE',
  Philippines: 'PH',
  Pitcairn: 'PN',
  Poland: 'PL',
  Portugal: 'PT',
  'Puerto Rico': 'PR',
  Qatar: 'QA',
  Romania: 'RO',
  'Russian Federation': 'RU',
  Rwanda: 'RW',
  Réunion: 'RE',
  'Saint Barthélemy': 'BL',
  'Saint Helena, Ascension and Tristan da Cunha': 'SH',
  'Saint Kitts and Nevis': 'KN',
  'Saint Lucia': 'LC',
  'Saint Martin (French part)': 'MF',
  'Saint Pierre and Miquelon': 'PM',
  'Saint Vincent and the Grenadines': 'VC',
  Samoa: 'WS',
  'San Marino': 'SM',
  'Sao Tome and Principe': 'ST',
  'Saudi Arabia': 'SA',
  Senegal: 'SN',
  Serbia: 'RS',
  Seychelles: 'SC',
  'Sierra Leone': 'SL',
  Singapore: 'SG',
  'Sint Maarten (Dutch part)': 'SX',
  Slovakia: 'SK',
  Slovenia: 'SI',
  'Solomon Islands': 'SB',
  Somalia: 'SO',
  'South Africa': 'ZA',
  'South Georgia and the South Sandwich Islands': 'GS',
  'South Sudan': 'SS',
  Spain: 'ES',
  'Sri Lanka': 'LK',
  Sudan: 'SD',
  Suriname: 'SR',
  'Svalbard and Jan Mayen': 'SJ',
  Swaziland: 'SZ',
  Sweden: 'SE',
  Switzerland: 'CH',
  'Syrian Arab Republic': 'SY',
  'Taiwan, Province of China': 'TW',
  Tajikistan: 'TJ',
  'Tanzania, United Republic of': 'TZ',
  Thailand: 'TH',
  'Timor-Leste': 'TL',
  Togo: 'TG',
  Tokelau: 'TK',
  Tonga: 'TO',
  'Trinidad and Tobago': 'TT',
  Tunisia: 'TN',
  Turkey: 'TR',
  Turkmenistan: 'TM',
  'Turks and Caicos Islands': 'TC',
  Tuvalu: 'TV',
  Uganda: 'UG',
  Ukraine: 'UA',
  'United Arab Emirates': 'AE',
  'United Kingdom': 'GB',
  'United States': 'US',
  'United States Minor Outlying Islands': 'UM',
  Uruguay: 'UY',
  Uzbekistan: 'UZ',
  Vanuatu: 'VU',
  'Venezuela, Bolivarian Republic of': 'VE',
  'Viet Nam': 'VN',
  'Virgin Islands, British': 'VG',
  'Virgin Islands, U.S.': 'VI',
  'Wallis and Futuna': 'WF',
  'Western Sahara': 'EH',
  Yemen: 'YE',
  Zambia: 'ZM',
  Zimbabwe: 'ZW',
  'Åland Islands': 'AX',
};

export const Alpha2ToCountry = invert(CountryToAlpha2);

export const Alpha3ToAlpha2 = {
  LAO: 'LA',
  SYC: 'SC',
  BGD: 'BD',
  KOR: 'KR',
  CZE: 'CZ',
  AUS: 'AU',
  SLE: 'SL',
  GMB: 'GM',
  CHL: 'CL',
  SUR: 'SR',
  VCT: 'VC',
  BLR: 'BY',
  HUN: 'HU',
  KGZ: 'KG',
  MDA: 'MD',
  GUY: 'GY',
  NRU: 'NR',
  MAF: 'MF',
  LBR: 'LR',
  VGB: 'VG',
  RWA: 'RW',
  GRC: 'GR',
  TON: 'TO',
  PYF: 'PF',
  ITA: 'IT',
  FLK: 'FK',
  HND: 'HN',
  ALB: 'AL',
  HRV: 'HR',
  BFA: 'BF',
  BOL: 'BO',
  ATF: 'TF',
  TGO: 'TG',
  IRL: 'IE',
  BTN: 'BT',
  NAM: 'NA',
  TTO: 'TT',
  AFG: 'AF',
  HKG: 'HK',
  TJK: 'TJ',
  PLW: 'PW',
  LCA: 'LC',
  ISL: 'IS',
  NLD: 'NL',
  AIA: 'AI',
  AGO: 'AO',
  IDN: 'ID',
  BLM: 'BL',
  STP: 'ST',
  LUX: 'LU',
  SRB: 'RS',
  MDV: 'MV',
  CIV: 'CI',
  MAR: 'MA',
  MKD: 'MK',
  PRT: 'PT',
  PAN: 'PA',
  SSD: 'SS',
  BHR: 'BH',
  KIR: 'KI',
  MAC: 'MO',
  CHN: 'CN',
  BHS: 'BS',
  IRN: 'IR',
  VEN: 'VE',
  BGR: 'BG',
  ATG: 'AG',
  ESP: 'ES',
  THA: 'TH',
  ASM: 'AS',
  CYM: 'KY',
  NPL: 'NP',
  SJM: 'SJ',
  BRN: 'BN',
  MNP: 'MP',
  ZMB: 'ZM',
  ZWE: 'ZW',
  CXR: 'CX',
  GIB: 'GI',
  BES: 'BQ',
  WSM: 'WS',
  TUR: 'TR',
  NZL: 'NZ',
  SWE: 'SE',
  GAB: 'GA',
  CUW: 'CW',
  KEN: 'KE',
  SLV: 'SV',
  SWZ: 'SZ',
  GBR: 'GB',
  MYT: 'YT',
  GNQ: 'GQ',
  KWT: 'KW',
  SXM: 'SX',
  NGA: 'NG',
  LBY: 'LY',
  DMA: 'DM',
  OMN: 'OM',
  PNG: 'PG',
  NIU: 'NU',
  REU: 'RE',
  ABW: 'AW',
  DJI: 'DJ',
  MMR: 'MM',
  BVT: 'BV',
  NIC: 'NI',
  COL: 'CO',
  SEN: 'SN',
  POL: 'PL',
  SHN: 'SH',
  LKA: 'LK',
  YEM: 'YE',
  AUT: 'AT',
  HMD: 'HM',
  JOR: 'JO',
  LVA: 'LV',
  SOM: 'SO',
  TZA: 'TZ',
  WLF: 'WF',
  ERI: 'ER',
  SVK: 'SK',
  UGA: 'UG',
  TUN: 'TN',
  ECU: 'EC',
  CHE: 'CH',
  MLI: 'ML',
  FSM: 'FM',
  DNK: 'DK',
  TUV: 'TV',
  ARG: 'AR',
  DEU: 'DE',
  JAM: 'JM',
  MDG: 'MG',
  AZE: 'AZ',
  BMU: 'BM',
  KHM: 'KH',
  MSR: 'MS',
  BEL: 'BE',
  IND: 'IN',
  TKM: 'TM',
  PSE: 'PS',
  COK: 'CK',
  TKL: 'TK',
  PCN: 'PN',
  TWN: 'TW',
  GHA: 'GH',
  MCO: 'MC',
  PER: 'PE',
  SGP: 'SG',
  SVN: 'SI',
  SMR: 'SM',
  IOT: 'IO',
  ESH: 'EH',
  BWA: 'BW',
  BLZ: 'BZ',
  ETH: 'ET',
  EST: 'EE',
  CUB: 'CU',
  DOM: 'DO',
  NCL: 'NC',
  EGY: 'EG',
  USA: 'US',
  FIN: 'FI',
  LSO: 'LS',
  SGS: 'GS',
  COG: 'CG',
  KAZ: 'KZ',
  NER: 'NE',
  LBN: 'LB',
  SLB: 'SB',
  MRT: 'MR',
  VAT: 'VA',
  GTM: 'GT',
  URY: 'UY',
  CPV: 'CV',
  TCA: 'TC',
  CYP: 'CY',
  FRO: 'FO',
  BRB: 'BB',
  GEO: 'GE',
  AND: 'AD',
  RUS: 'RU',
  MNE: 'ME',
  BDI: 'BI',
  ROU: 'RO',
  SDN: 'SD',
  MYS: 'MY',
  ARE: 'AE',
  GUM: 'GU',
  MTQ: 'MQ',
  MUS: 'MU',
  IRQ: 'IQ',
  PRY: 'PY',
  FRA: 'FR',
  SAU: 'SA',
  ZAF: 'ZA',
  GIN: 'GN',
  MWI: 'MW',
  PRI: 'PR',
  DZA: 'DZ',
  ISR: 'IL',
  MHL: 'MH',
  UKR: 'UA',
  GUF: 'GF',
  ALA: 'AX',
  GRD: 'GD',
  BIH: 'BA',
  ATA: 'AQ',
  TCD: 'TD',
  JEY: 'JE',
  GGY: 'GG',
  SYR: 'SY',
  COM: 'KM',
  MEX: 'MX',
  PRK: 'KP',
  VIR: 'VI',
  VNM: 'VN',
  CAF: 'CF',
  GRL: 'GL',
  KNA: 'KN',
  CRI: 'CR',
  CAN: 'CA',
  NFK: 'NF',
  ARM: 'AM',
  UMI: 'UM',
  GLP: 'GP',
  VUT: 'VU',
  CMR: 'CM',
  BEN: 'BJ',
  HTI: 'HT',
  COD: 'CD',
  IMN: 'IM',
  QAT: 'QA',
  MLT: 'MT',
  LTU: 'LT',
  CCK: 'CC',
  JPN: 'JP',
  BRA: 'BR',
  LIE: 'LI',
  GNB: 'GW',
  UZB: 'UZ',
  SPM: 'PM',
  FJI: 'FJ',
  MNG: 'MN',
  TLS: 'TL',
  PHL: 'PH',
  NOR: 'NO',
  MOZ: 'MZ',
  PAK: 'PK',
  RKS: 'XK',
};

export const Alpha2ToAlpha3 = {
  LA: 'LAO',
  SC: 'SYC',
  BD: 'BGD',
  KR: 'KOR',
  CZ: 'CZE',
  AU: 'AUS',
  SL: 'SLE',
  GM: 'GMB',
  CL: 'CHL',
  SR: 'SUR',
  VC: 'VCT',
  BY: 'BLR',
  HU: 'HUN',
  KG: 'KGZ',
  MD: 'MDA',
  GY: 'GUY',
  NR: 'NRU',
  MF: 'MAF',
  LR: 'LBR',
  VG: 'VGB',
  RW: 'RWA',
  GR: 'GRC',
  TO: 'TON',
  PF: 'PYF',
  IT: 'ITA',
  FK: 'FLK',
  HN: 'HND',
  AL: 'ALB',
  HR: 'HRV',
  BF: 'BFA',
  BO: 'BOL',
  TF: 'ATF',
  TG: 'TGO',
  IE: 'IRL',
  BT: 'BTN',
  NA: 'NAM',
  TT: 'TTO',
  AF: 'AFG',
  HK: 'HKG',
  TJ: 'TJK',
  PW: 'PLW',
  LC: 'LCA',
  IS: 'ISL',
  NL: 'NLD',
  AI: 'AIA',
  AO: 'AGO',
  ID: 'IDN',
  BL: 'BLM',
  ST: 'STP',
  LU: 'LUX',
  RS: 'SRB',
  MV: 'MDV',
  CI: 'CIV',
  MA: 'MAR',
  MK: 'MKD',
  PT: 'PRT',
  PA: 'PAN',
  SS: 'SSD',
  BH: 'BHR',
  KI: 'KIR',
  MO: 'MAC',
  CN: 'CHN',
  BS: 'BHS',
  IR: 'IRN',
  VE: 'VEN',
  BG: 'BGR',
  AG: 'ATG',
  ES: 'ESP',
  TH: 'THA',
  AS: 'ASM',
  KY: 'CYM',
  NP: 'NPL',
  SJ: 'SJM',
  BN: 'BRN',
  MP: 'MNP',
  ZM: 'ZMB',
  ZW: 'ZWE',
  CX: 'CXR',
  GI: 'GIB',
  BQ: 'BES',
  WS: 'WSM',
  TR: 'TUR',
  NZ: 'NZL',
  SE: 'SWE',
  GA: 'GAB',
  CW: 'CUW',
  KE: 'KEN',
  SV: 'SLV',
  SZ: 'SWZ',
  GB: 'GBR',
  YT: 'MYT',
  GQ: 'GNQ',
  KW: 'KWT',
  SX: 'SXM',
  NG: 'NGA',
  LY: 'LBY',
  DM: 'DMA',
  OM: 'OMN',
  PG: 'PNG',
  NU: 'NIU',
  RE: 'REU',
  AW: 'ABW',
  DJ: 'DJI',
  MM: 'MMR',
  BV: 'BVT',
  NI: 'NIC',
  CO: 'COL',
  SN: 'SEN',
  PL: 'POL',
  SH: 'SHN',
  LK: 'LKA',
  YE: 'YEM',
  AT: 'AUT',
  HM: 'HMD',
  JO: 'JOR',
  LV: 'LVA',
  SO: 'SOM',
  TZ: 'TZA',
  WF: 'WLF',
  ER: 'ERI',
  SK: 'SVK',
  UG: 'UGA',
  TN: 'TUN',
  EC: 'ECU',
  CH: 'CHE',
  ML: 'MLI',
  FM: 'FSM',
  DK: 'DNK',
  TV: 'TUV',
  AR: 'ARG',
  DE: 'DEU',
  JM: 'JAM',
  MG: 'MDG',
  AZ: 'AZE',
  BM: 'BMU',
  KH: 'KHM',
  MS: 'MSR',
  BE: 'BEL',
  IN: 'IND',
  TM: 'TKM',
  PS: 'PSE',
  CK: 'COK',
  TK: 'TKL',
  PN: 'PCN',
  TW: 'TWN',
  GH: 'GHA',
  MC: 'MCO',
  PE: 'PER',
  SG: 'SGP',
  SI: 'SVN',
  SM: 'SMR',
  IO: 'IOT',
  EH: 'ESH',
  BW: 'BWA',
  BZ: 'BLZ',
  ET: 'ETH',
  EE: 'EST',
  CU: 'CUB',
  DO: 'DOM',
  NC: 'NCL',
  EG: 'EGY',
  US: 'USA',
  FI: 'FIN',
  LS: 'LSO',
  GS: 'SGS',
  CG: 'COG',
  KZ: 'KAZ',
  NE: 'NER',
  LB: 'LBN',
  SB: 'SLB',
  MR: 'MRT',
  VA: 'VAT',
  GT: 'GTM',
  UY: 'URY',
  CV: 'CPV',
  TC: 'TCA',
  CY: 'CYP',
  FO: 'FRO',
  BB: 'BRB',
  GE: 'GEO',
  AD: 'AND',
  RU: 'RUS',
  ME: 'MNE',
  BI: 'BDI',
  RO: 'ROU',
  SD: 'SDN',
  MY: 'MYS',
  AE: 'ARE',
  GU: 'GUM',
  MQ: 'MTQ',
  MU: 'MUS',
  IQ: 'IRQ',
  PY: 'PRY',
  FR: 'FRA',
  SA: 'SAU',
  ZA: 'ZAF',
  GN: 'GIN',
  MW: 'MWI',
  PR: 'PRI',
  DZ: 'DZA',
  IL: 'ISR',
  MH: 'MHL',
  UA: 'UKR',
  GF: 'GUF',
  AX: 'ALA',
  GD: 'GRD',
  BA: 'BIH',
  AQ: 'ATA',
  TD: 'TCD',
  JE: 'JEY',
  GG: 'GGY',
  SY: 'SYR',
  KM: 'COM',
  MX: 'MEX',
  KP: 'PRK',
  VI: 'VIR',
  VN: 'VNM',
  CF: 'CAF',
  GL: 'GRL',
  KN: 'KNA',
  CR: 'CRI',
  CA: 'CAN',
  NF: 'NFK',
  AM: 'ARM',
  UM: 'UMI',
  GP: 'GLP',
  VU: 'VUT',
  CM: 'CMR',
  BJ: 'BEN',
  HT: 'HTI',
  CD: 'COD',
  IM: 'IMN',
  QA: 'QAT',
  MT: 'MLT',
  LT: 'LTU',
  CC: 'CCK',
  JP: 'JPN',
  BR: 'BRA',
  LI: 'LIE',
  GW: 'GNB',
  UZ: 'UZB',
  PM: 'SPM',
  FJ: 'FJI',
  MN: 'MNG',
  TL: 'TLS',
  PH: 'PHL',
  NO: 'NOR',
  MZ: 'MOZ',
  PK: 'PAK',
  XK: 'RKS',
};

export const Alpha2VatCountries = [
  CountryToAlpha2.Austria,
  CountryToAlpha2.Belgium,
  CountryToAlpha2.Bulgaria,
  CountryToAlpha2.Cyprus,
  CountryToAlpha2.Czechia,
  CountryToAlpha2.Germany,
  CountryToAlpha2.Denmark,
  CountryToAlpha2.Estonia,
  CountryToAlpha2.Greece,
  CountryToAlpha2.Spain,
  CountryToAlpha2.Finland,
  CountryToAlpha2.France,
  CountryToAlpha2['United Kingdom'],
  CountryToAlpha2.Croatia,
  CountryToAlpha2.Hungary,
  CountryToAlpha2.Ireland,
  CountryToAlpha2.Italy,
  CountryToAlpha2.Lithuania,
  CountryToAlpha2.Luxembourg,
  CountryToAlpha2.Latvia,
  CountryToAlpha2.Malta,
  CountryToAlpha2.Netherlands,
  CountryToAlpha2.Poland,
  CountryToAlpha2.Portugal,
  CountryToAlpha2.Romania,
  CountryToAlpha2.Sweden,
  CountryToAlpha2.Slovenia,
  CountryToAlpha2.Slovakia,
];

export const AlternateCountryToAlpha2 = {
  'Czech Republic': 'CZ',
};

export const AlternateAlpha2ToAlpha2 = {
  EL: 'GR',
};

export const FullNames = Object.keys(CountryToAlpha2);

export const AlternateFullNames = Object.keys(AlternateCountryToAlpha2);

export const Alpha2Names = Object.keys(Alpha2ToAlpha3);

export const AlternateAlpha2Names = Object.keys(AlternateAlpha2ToAlpha2);

export const Alpha3Names = Object.keys(Alpha3ToAlpha2);

export const GeoipAlpha3 = Alpha3Names.includes((window.config || {}).geoip_country)
  ? window.config.geoip_country
  : null;

export const LocalNameCountries = {
  CN: 'Simplified Chinese',
  RU: 'Cyrillic',
  UA: 'Cyrillic',
  JP: 'Kanji or Katakana',
  KR: 'Hangul',
  TH: 'Thai',
};

export default {
  CountryToAlpha2,
  Alpha2ToCountry,
  Alpha3ToAlpha2,
  Alpha2ToAlpha3,
  Alpha2VatCountries,
  FullNames,
  Alpha2Names,
  Alpha3Names,
  AlternateCountryToAlpha2,
  AlternateAlpha2ToAlpha2,
  AlternateFullNames,
  AlternateAlpha2Names,
  GeoipAlpha3,
  LocalNameCountries,
};
