import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = ({ value, label, size, className }) => (
  <div className="progressBar-container">
    <div className={`progressBar progressBar--${size} ${className}`}>
      <div className="progressBar-value" style={{ width: `${value}%` }} />
    </div>
    {label && <span className="progressBar-label">{`${value}%`} complete</span>}
  </div>
);

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
};

ProgressBar.defaultProps = {
  size: 'large',
  className: '',
};

export default ProgressBar;
