import deepcopy from 'clone';
import { SubmissionError } from 'redux-form';
import { call, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import PhoneCountryCodes from 'spa/constants/PhoneCountryCodes';
import { Alpha2ToAlpha3 } from 'spa/constants/ISOCountryCodes';
import APIConstants from 'spa/constants/APIConstants';
import AuthenticationConstants from 'spa/constants/AuthenticationConstants';
import { modeSelector } from 'spa/selectors/AuthenticationSelectors';
import { setCustomerTaxNumber as setCustomerTaxNumberRoutine } from 'spa/actions/UserActions';
import { validateVatNumber } from 'spa/sagas/UserSaga';
import { sendVerificationEmail } from 'spa/sagas/TwoFactorSaga';
import { hasVatDetails } from 'spa/components/ContactDetails';

import {
  formLoginSignup as formLoginSignupRoutine,
  login as loginRoutine,
  loginSSO as loginSSORoutine,
  signup as signupRoutine,
  twoFAValidate as twoFAValidateRoutine,
  updateContactDetails as updateContactDetailsRoutine,
  setMode,
} from 'spa/actions/AuthenticationActions';
import { moveToNextStep } from 'spa/actions/CheckoutActions';

import API from '../../api';
import { runRecaptchaV3 } from './OfferSaga';
import ErrorMessages from '../../constants/ErrorMessages';
import LegacyAuthenticationConstants from '../../constants/AuthenticationConstants';
import AuthenticationStore from '../../stores/AuthenticationStore';
import { mapFormDataToRequestBody } from '../../utils/DataMapping';

const authStoreUpdateUserDetails = (loginDetails) => {
  AuthenticationStore._onVerify({
    actionType: LegacyAuthenticationConstants.VERIFY,
    data: loginDetails,
  });
};

const hasContactDetails = (formProps) => Boolean((formProps || {}).enableSignupCustomerDetails);

const processContactDetailsData = (formData, userEmail) => {
  const processedData = deepcopy(formData);

  if (processedData.primaryPhoneCountry && processedData.primaryPhoneNumber) {
    const phonePrefix = PhoneCountryCodes[processedData.primaryPhoneCountry].countryCode;
    const phoneSuffix = processedData.primaryPhoneNumber;
    processedData.phone = `+${phonePrefix}${phoneSuffix}`;
    delete processedData.primaryPhoneCountry;
    delete processedData.primaryPhoneNumber;
  }

  if (processedData.country) {
    const personalCountry = PhoneCountryCodes[Alpha2ToAlpha3[processedData.country]].name;
    processedData.country = personalCountry;
  }

  if (processedData.companyCountry) {
    const companyCountry = PhoneCountryCodes[Alpha2ToAlpha3[processedData.companyCountry]].name;
    processedData.companyCountry = companyCountry;
  }

  if (processedData.firstName && processedData.lastName) {
    processedData.displayName = `${processedData.firstName} ${processedData.lastName}`;
  }

  if (userEmail) {
    processedData.email = userEmail;
  }

  processedData.displayNameType = 'name';
  processedData.issueCheckInCompanyName = false;
  processedData.shippingAddress = null;
  processedData.useUsernameAsDisplayName = false;
  processedData.username = null;

  delete processedData.password;
  delete processedData.vatDetails;

  const apiBody = mapFormDataToRequestBody(
    AuthenticationConstants.CONTACT_DETAILS_TO_API_BODY,
    processedData
  );

  return apiBody;
};

function* updateVatDetails(formData) {
  const apiRequest = {
    taxType: 'vat',
    taxNumber: `${formData.vatDetails.vatCountry}${formData.vatDetails.vatNumber}`,
  };
  yield put(setCustomerTaxNumberRoutine.trigger(apiRequest));

  const { setCustomerTaxFailure } = yield race({
    setCustomerTaxSuccess: take(setCustomerTaxNumberRoutine.SUCCESS),
    setCustomerTaxFailure: take(setCustomerTaxNumberRoutine.FAILURE),
  });

  if (setCustomerTaxFailure) {
    const reduxFormError = {
      vatDetails: {
        vatNumber: setCustomerTaxFailure.payload,
      },
    };
    throw reduxFormError;
  }
}

export function* login(action) {
  yield put(loginRoutine.request());
  let loginDetails = null;
  try {
    const { username, password, isSignup, turnstileResponse } = action.payload;
    loginDetails = yield call(API.loginUser, username, password, isSignup, turnstileResponse);
  } catch (error) {
    yield put(loginRoutine.failure(error));
    return;
  }
  authStoreUpdateUserDetails(loginDetails);
  yield put(loginRoutine.success(loginDetails));
}

export function* loginSSO(action) {
  yield put(loginRoutine.request());
  let loginDetails = null;
  try {
    const { auth } = action.payload;
    loginDetails = yield call(API.loginUserByToken, auth);
  } catch (error) {
    yield put(loginRoutine.failure(error));
    return;
  }
  authStoreUpdateUserDetails(loginDetails);

  // Update Escrow Pay Card
  yield put(moveToNextStep());

  yield put(loginRoutine.success());
}

export function* signup(action) {
  yield put(signupRoutine.request());
  yield put(
    loginRoutine.trigger({
      ...action.payload,
      isSignup: true,
    })
  );
  const { success, failure } = yield race({
    success: take(loginRoutine.SUCCESS),
    failure: take(loginRoutine.FAILURE),
  });
  if (success) {
    yield put(signupRoutine.success());
  } else {
    yield put(signupRoutine.failure(failure.payload));
  }
}

export function* updateContactDetails(action) {
  yield put(updateContactDetailsRoutine.request());
  try {
    const { apiBody, customerId } = action.payload;
    yield call(API.updateCustomerDetails, customerId, apiBody);
    yield put(updateContactDetailsRoutine.success());
  } catch (error) {
    yield put(updateContactDetailsRoutine.failure(error));
  }
}

export function formLoginSignupErrorHandler(error) {
  const reduxError = { _error: ErrorMessages.TECHNICAL_DIFFICULTIES };
  switch (error.type) {
    case APIConstants.FORBIDDEN:
      if (error.errors === 'email-already-exists') {
        reduxError._error = ErrorMessages.EMAIL_ALREADY_EXISTS_SIMPLE;
      } else if (error.errors === 'incomplete-form') {
        reduxError._error = ErrorMessages.INCOMPLETE_FORM;
      } else if (error.errors === 'turnstile-failed') {
        reduxError._error = ErrorMessages.TURNSTILE_FAILED;
      } else {
        reduxError._error = ErrorMessages.LOGIN_FAIL;
      }
      break;
    case APIConstants.NOT_FOUND:
      reduxError._error = ErrorMessages.EMAIL_NOT_FOUND;
      break;
    case APIConstants.UNAUTHORIZED:
      reduxError._error = error.errors;
      break;
    case APIConstants.UNHANDLED:
      if (error.errors && error.errors.error) {
        reduxError._error = error.errors.error;
      }
      break;
    default:
      break;
  }
  return new SubmissionError(reduxError);
}

export function* formLoginSignup(action) {
  yield put(formLoginSignupRoutine.request());
  const mode = yield select(modeSelector);
  const formData = action.payload.values;
  const formProps = action.payload.props;
  if (mode === AuthenticationConstants.MODE_LOGIN) {
    if (window.config.recaptcha_site_key) {
      yield call(runRecaptchaV3, 'login');
    }
    yield put(loginRoutine.trigger(formData));
    const { loginSuccess, loginFailure } = yield race({
      loginSuccess: take(loginRoutine.SUCCESS),
      loginFailure: take(loginRoutine.FAILURE),
    });
    if (loginSuccess) {
      yield put(formLoginSignupRoutine.success());
    } else {
      const error = formLoginSignupErrorHandler(loginFailure.payload);
      yield put(formLoginSignupRoutine.failure(error));
    }
  } else if (mode === AuthenticationConstants.MODE_SIGNUP) {
    // Check VAT number
    if (hasVatDetails(formData)) {
      try {
        const { vatCountry, vatNumber } = formData.vatDetails;
        yield call(validateVatNumber, `${vatCountry}${vatNumber}`);
      } catch (validationError) {
        const reduxFormError = new SubmissionError({ vatDetails: validationError });
        yield put(formLoginSignupRoutine.failure(reduxFormError));
        return;
      }
    }

    // Sign up user account
    if (window.config.recaptcha_site_key) {
      yield call(runRecaptchaV3, 'signup');
    }
    yield put(signupRoutine.trigger(formData));
    const { signupFailure } = yield race({
      signupSuccess: take(signupRoutine.SUCCESS),
      signupFailure: take(signupRoutine.FAILURE),
    });
    if (signupFailure) {
      const error = formLoginSignupErrorHandler(signupFailure.payload);
      yield put(formLoginSignupRoutine.failure(error));
      return;
    }

    // Update customer details
    if (hasContactDetails(formProps)) {
      const apiBody = yield call(processContactDetailsData, formData, formData.username);
      const customerId = AuthenticationStore.getCustomerID();
      yield put(updateContactDetailsRoutine.trigger({ apiBody, customerId }));
      const { updateFailure } = yield race({
        updateSuccess: take(updateContactDetailsRoutine.SUCCESS),
        updateFailure: take(updateContactDetailsRoutine.FAILURE),
      });
      if (updateFailure) {
        const error = formLoginSignupErrorHandler(updateFailure.payload);
        yield put(formLoginSignupRoutine.failure(error));
        return;
      }
    }

    // Update VAT details
    if (hasVatDetails(formData)) {
      try {
        yield call(updateVatDetails, formData);
      } catch (updateVatError) {
        const reduxFormError = new SubmissionError(updateVatError);
        yield put(formLoginSignupRoutine.failure(reduxFormError));
        return;
      }
    }

    yield call(sendVerificationEmail);

    yield put(formLoginSignupRoutine.success());
  }
}

export function* switchModeByEmail(action) {
  const email = action.payload;
  if (email) {
    let doesEmailExist;
    try {
      doesEmailExist = yield call(API.checkIfEmailExist, email);
    } catch (error) {
      console.error(error); //eslint-disable-line
    }
    if (doesEmailExist) {
      yield put(setMode(AuthenticationConstants.MODE_LOGIN));
    } else {
      yield put(setMode(AuthenticationConstants.MODE_SIGNUP));
    }
  }
}

export function* validateTwoFactor(action) {
  try {
    const formData = action.payload.values;
    const formProps = action.payload.props;
    yield put(twoFAValidateRoutine.request());
    yield call(API.validate2fa, formData.code2fa, formProps.configType);
    yield put(twoFAValidateRoutine.success());
    yield delay(2000);
    AuthenticationStore._on2FAVerify();
    yield put(twoFAValidateRoutine.fulfill());
  } catch (error) {
    const reduxFormError = { _error: ErrorMessages.TWO_FACTOR_CODE_FAIL };
    yield put(twoFAValidateRoutine.failure(new SubmissionError(reduxFormError)));
  }
}

export function* authenticationWatcher() {
  yield takeLatest(loginRoutine.TRIGGER, login);
  yield takeLatest(loginSSORoutine.TRIGGER, loginSSO);
  yield takeLatest(signupRoutine.TRIGGER, signup);
  yield takeLatest(updateContactDetailsRoutine.TRIGGER, updateContactDetails);
  yield takeLatest(formLoginSignupRoutine.TRIGGER, formLoginSignup);
  yield takeLatest(AuthenticationConstants.PREFILL_USERNAME, switchModeByEmail);
  yield takeLatest(twoFAValidateRoutine.TRIGGER, validateTwoFactor);
}

export default [authenticationWatcher];
