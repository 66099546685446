import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AnimateHeight from 'react-animate-height';

import { makeExpandedSelector } from 'spa/selectors/ExpandCollapseSelectors';

import ProgressBar from 'spa/components/ProgressBar';
import Checkbox from 'spa/components/Checkbox';
import ExpandCollapse from 'spa/components/ExpandCollapse';
import { Spinner } from 'spa/components/Indicators';

import TaskList from './TaskList';

const TaskListItem = ({ task, updateTask, expanded }) => {
  const {
    completed,
    description,
    label,
    name,
    subtasks,
    frozen,
    progressBarValue,
    updating,
    taskList: taskListName,
  } = task;

  const onClick = () => {
    // if the task was incomplete, update to complete as vice versa
    const status = !completed ? 'complete' : 'incomplete';
    updateTask(name, status, taskListName);
  };

  return (
    <div className="taskList-item">
      <div className="taskList-inner">
        <Checkbox
          checked={completed}
          name={name}
          id={name}
          label={label}
          onClick={onClick}
          disabled={frozen || updating}
        />
        <span className="taskList-content">
          <div className="taskList-header">
            {label && <h4 className="taskList-title">{label}</h4>}
            {updating && (
              <span className="taskList-spinner">
                <Spinner />
              </span>
            )}
            {typeof progressBarValue !== 'undefined' && ( // have to check type because 0 is a valid %
              <span className="taskList-progress">
                <ProgressBar value={progressBarValue} label />
              </span>
            )}
            {subtasks && !!subtasks.length && (
              <ExpandCollapse initExpand name={name} collapseText="Collapse" expandText="Expand" />
            )}
          </div>
          {description && <p className="taskList-desc">{description}</p>}
          {subtasks && !!subtasks.length && (
            <AnimateHeight duration={400} height={expanded ? 'auto' : 0}>
              <TaskList taskList={subtasks} updateTask={updateTask} />
            </AnimateHeight>
          )}
        </span>
      </div>
    </div>
  );
};

TaskListItem.propTypes = {
  task: PropTypes.object,
  updateTask: PropTypes.func,
};

const makeMapStateToProps = () => {
  const expandedSelector = makeExpandedSelector();
  const mapStateToProps = (state, props) => ({
    expanded: expandedSelector(state, props.task.name),
  });
  return mapStateToProps;
};

const TaskListItemContainer = connect(makeMapStateToProps, null)(TaskListItem);

export default TaskListItemContainer;
