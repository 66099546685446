import React, { Fragment, useCallback, useEffect } from 'react';
import moment from 'moment';
import { Divider, MenuItem } from '@mui/material';
import classnames from 'classnames';
import _ from 'lodash';
import { useForm } from 'react-final-form';
import {
  compose,
  maxChars,
  required,
  strRequired,
  priceValidate,
  vehicleYearValidate,
  vehicleMakeValidate,
  vinValidate,
  odometerValidate,
  DNHTermValidate,
  inspectionPeriodValidate,
  dnhDateValidate,
} from 'spa/components/form/validate';
import { formatCurrency, updateFormValues } from 'spa/components/StartTransaction/util';
import PaymentSchedule from 'spa/components/StartTransaction/TransactionItem/PaymentSchedule';
import ExtraServiceCheck from 'spa/components/StartTransaction/TransactionItem/ExtraServiceCheck';
import {
  InputField,
  DatePickerField,
  HiddenField,
  SwitchField,
} from 'spa/components/StartTransaction/Fields';

const ShippingMethodField = ({ name, isMV, updateFieldItem }) => (
  <InputField
    select
    validate={required}
    name={`${name}.shippingMethod`}
    label="Shipping method"
    onChange={(e) => {
      if (e.target.value === 'no_shipping') {
        updateFieldItem({ shippingFeePayer: null });
      }
    }}
  >
    {[
      ...(isMV ? [] : [{ value: 'standard_shipping', label: 'Standard Shipping' }]),
      { value: 'cargo_shipping', label: 'Cargo Shipping' },
      { value: 'no_shipping', label: 'No Shipping' },
    ].map(({ value, label }) => (
      <MenuItem key={value} value={value}>
        {label}
      </MenuItem>
    ))}
  </InputField>
);

const ShippingFeePayerField = ({ name, updateFieldItem }) => (
  <InputField
    validate={required}
    name={`${name}.shippingFeePayer`}
    select
    label="Shipping fee paid by"
    initialValue="seller"
    onChange={(e) => {
      if (e.target.value === 'seller') {
        updateFieldItem({ shippingFee: '0.00' });
      }
    }}
  >
    <MenuItem value="buyer">Buyer</MenuItem>
    <MenuItem value="seller">Seller</MenuItem>
  </InputField>
);

const ShippingCostField = ({ name, currency }) => (
  <InputField
    initialValue="0.00"
    name={`${name}.shippingFee`}
    label={`Shipping cost (${currency.toUpperCase()})`}
    isNumeric
    currency={currency}
    validate={priceValidate}
  />
);

const ItemInspectionPeriodField = ({ name }) => (
  <InputField
    name={`${name}.itemInspectionPeriod`}
    validate={inspectionPeriodValidate}
    isNumeric
    label={'Item inspection period (days)'}
  />
);

function getFirstShippingRequiredItem(items, index) {
  const itemIndex = items.findIndex((v, i) => v.shippingMethod !== 'no_shipping' && i < index);
  if (itemIndex !== -1) {
    return {
      index: itemIndex,
      shippingFeePayer: items[itemIndex].shippingFeePayer,
      shippingMethod: items[itemIndex].shippingMethod,
    };
  }
  return -1;
}

const ShippingFields = ({ name, values, isMilestone, index, isMV }) => {
  /**
   * Shipping fee payer is a transaction-level value, not item-by-item.
   *
   * For standard transactions (GM), shipping fee payer and shipping method
   * is from the first item. The rest items should have the exact same
   * shipping fee payer and method.
   *
   * For milestone transactions(GM), each item could have independent shipping
   * method and cost, but the shipping fee payer field will also depend on
   * the first shipping-required item.
   *
   * If the item is not shipping-required, or the shipping cost is paid by seller,
   * hide the shipping cost field.
   *
   * Product Spec: https://phabricator.tools.flnltd.com/T252313
   */
  const form = useForm();
  const currency = form.getFieldState('currency').value;
  const items = form.getFieldState('items').value;
  const firstShippingRequiredItem = getFirstShippingRequiredItem(items, index);
  const firstItem = items[0];

  const updateFieldItem = (newValues) => {
    Object.entries(newValues).forEach(([key, value]) => form.change(`${name}.${key}`, value));
  };

  const shouldShowShippingMethod = index === 0 || !!isMilestone;
  const shouldShowShippingFeePayer =
    values.shippingMethod !== 'no_shipping' &&
    (index === 0 || (index > 0 && !!isMilestone && firstShippingRequiredItem === -1));
  const shouldShowShippingCost =
    index === 0
      ? values.shippingMethod !== 'no_shipping' && values.shippingFeePayer !== 'seller'
      : (!isMilestone &&
          firstItem.shippingMethod !== 'no_shipping' &&
          firstItem.shippingFeePayer !== 'seller') ||
        (!!isMilestone &&
          values.shippingMethod !== 'no_shipping' &&
          values.shippingFeePayer !== 'seller' &&
          (firstShippingRequiredItem === -1 ||
            firstShippingRequiredItem.shippingFeePayer !== 'seller'));
  const shouldRenderHiddenShippingFeePayer =
    !shouldShowShippingFeePayer && firstShippingRequiredItem !== -1;
  const fieldCount = [
    shouldShowShippingMethod,
    shouldShowShippingFeePayer,
    shouldShowShippingCost,
    !!isMilestone,
  ].reduce((acc, cur) => acc + cur, 0);

  const fieldClassNames = classnames({
    'createTransaction-inline-field--narrow': fieldCount === 3,
    'createTransaction-inline-field--half': fieldCount % 2 === 0,
    'createTransaction-inline-field--wide': fieldCount === 1,
  });

  return fieldCount <= 3 ? (
    <div className="createTransaction-inline-fields-container">
      {shouldShowShippingMethod && (
        <div className={fieldClassNames}>
          <ShippingMethodField name={name} isMV={isMV} updateFieldItem={updateFieldItem} />
        </div>
      )}
      {shouldShowShippingFeePayer && (
        <div className={fieldClassNames}>
          <ShippingFeePayerField name={name} updateFieldItem={updateFieldItem} />
        </div>
      )}
      {shouldRenderHiddenShippingFeePayer && (
        <HiddenField
          name={`${name}.shippingFeePayer`}
          fieldProps={{
            type: 'text',
            initialValue: firstShippingRequiredItem.shippingFeePayer,
          }}
        />
      )}
      {shouldShowShippingCost && (
        <div className={fieldClassNames}>
          <ShippingCostField name={name} currency={currency} />
        </div>
      )}
      {!!isMilestone && (
        <div className={fieldClassNames}>
          <ItemInspectionPeriodField name={name} />
        </div>
      )}
    </div>
  ) : (
    <Fragment>
      <div className="createTransaction-inline-fields-container">
        <div className={fieldClassNames}>
          <ShippingMethodField name={name} isMV={isMV} updateFieldItem={updateFieldItem} />
        </div>
        <div className={fieldClassNames}>
          <ShippingFeePayerField name={name} updateFieldItem={updateFieldItem} />
        </div>
      </div>
      <div className="createTransaction-inline-fields-container">
        <div className={fieldClassNames}>
          <ShippingCostField name={name} currency={currency} />
        </div>
        <div className={fieldClassNames}>
          <ItemInspectionPeriodField name={name} />
        </div>
      </div>
    </Fragment>
  );
};

const TitleCollectionServiceCheck = ({ name, disabled, currency }) => (
  <ExtraServiceCheck
    disabled={disabled}
    name={`${name}.titleCollection`}
    serviceName="titleCollection"
    title="Upgrade to title collection service"
    price={formatCurrency(60, currency.toUpperCase())}
    description="Escrow.com ensures the safe delivery of the vehicle's title. Fees apply."
  />
);

const ConciergeCheck = ({ name, cascadeChange }) => (
  <ExtraServiceCheck
    name={`${name}.concierge`}
    serviceName="concierge"
    title="Upgrade to Escrow concierge service"
    price=""
    description="Ensure a smooth transfer of the domain with our personal assistance. Fees apply."
    cascadeChange={cascadeChange}
  />
);

const LienHolderServiceCheck = ({ name, disabled, currency }) => (
  <ExtraServiceCheck
    disabled={disabled}
    name={`${name}.lienHolder`}
    serviceName="concierge"
    title="Upgrade to lien holder payoff service"
    price={formatCurrency(60, currency.toUpperCase())}
    description="Escrow.com guarantees to pay off the lien holder upon transaction closure. Fees apply."
  />
);

const GMAdditionalInfoInput = ({ name, values, isMilestone, index }) => (
  <ShippingFields name={name} values={values} isMilestone={isMilestone} index={index} />
);

const DNHAdditionalInfoInput = ({ values }) => {
  const minDate = moment().add(1, 'days');
  const maxDate = moment('12/31/2099', 'MM/DD/YYYY');
  const firstItem = values.items[0];
  const { totalPrice, brokerCommission } = values.items.reduce(
    (accumulator, object) => {
      accumulator.totalPrice += parseFloat(object.price);
      accumulator.brokerCommission += parseFloat(object.brokerCommission);
      return accumulator;
    },
    { totalPrice: 0, brokerCommission: 0 }
  );
  const isDNH = firstItem.isDNH;
  return isDNH ? (
    <Fragment>
      <div className="createTransaction-subform--header"> Payment schedule terms </div>
      <Divider variant="fullWidth" />
      <div className="createTransaction-inline-fields-container">
        <div className="createTransaction-inline-field--half">
          <InputField
            select
            name="years"
            label="Term length (years)"
            validate={(value) => DNHTermValidate(value, values, 'months')}
          >
            {_.range(6).map((value) => (
              <MenuItem key={value} value={value} disabled={value === 0 && values.months < 3}>
                {value}
              </MenuItem>
            ))}
          </InputField>
        </div>
        <div className="createTransaction-inline-field--half">
          <InputField
            select
            name="months"
            label="Term length (months)"
            validate={(value) => DNHTermValidate(value, values, 'years')}
          >
            {values.years &&
              _.range(0, 3).map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            {_.range(3, 12).map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </InputField>
        </div>
      </div>
      <div className="createTransaction-inline-fields-container">
        <div className="createTransaction-inline-field--half">
          <InputField select name="paymentFrequency" label="Payment frequency" validate={required}>
            {['Monthly', 'Quarterly', 'Annual'].map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </InputField>
        </div>
        <div className="createTransaction-inline-field--half">
          <DatePickerField
            name="firstPaymentDate"
            validate={dnhDateValidate(minDate, maxDate)}
            label="First payment date"
            minDate={minDate}
            shrink
          />
        </div>
      </div>
      <PaymentSchedule
        year={values.years}
        month={values.months}
        paymentFrequency={values.paymentFrequency}
        firstDate={values.firstPaymentDate}
        totalPrice={totalPrice}
        brokerCommission={brokerCommission}
        paymentSchedule={values.paymentSchedule}
      />
    </Fragment>
  ) : null;
};

const MVAdditionalInfoInput = ({ name, values, index, upsells, currency }) => (
  <Fragment>
    <div className="createTransaction-inline-fields-container createTransaction-inline-fields-container--mobile">
      <div className="createTransaction-inline-field--half createTransaction-inline-field--half-mobile">
        <InputField name={`${name}.make`} validate={vehicleMakeValidate} label="Make" />
      </div>
      <div className="createTransaction-inline-field--half createTransaction-inline-field--half-mobile">
        <InputField
          name={`${name}.model`}
          validate={compose([strRequired, maxChars(100, 'Model')])}
          label="Model"
        />
      </div>
    </div>
    <div className="createTransaction-inline-fields-container">
      <div className="createTransaction-inline-field--half">
        <InputField name={`${name}.year`} validate={vehicleYearValidate} isNumeric label="Year" />
      </div>
      <div className="createTransaction-inline-field--half">
        <InputField
          name={`${name}.odometer`}
          validate={odometerValidate}
          isNumeric
          label="Odometer"
        />
      </div>
    </div>
    <InputField name={`${name}.vin`} validate={vinValidate} shrinkLabel label="VIN" />
    <ShippingFields name={name} values={values} isMilestone={false} index={index} isMV />
    {index === 0 ? (
      <TitleCollectionServiceCheck name={name} disabled={!!values.lienHolder} currency={currency} />
    ) : (
      <HiddenField
        name={`${name}.titleCollection`}
        fieldProps={{ initialValue: upsells.firstItemTitleCollection, type: 'checkbox' }}
      />
    )}
    {index === 0 ? (
      <LienHolderServiceCheck name={name} disabled={!!values.titleCollection} currency={currency} />
    ) : (
      <HiddenField
        name={`${name}.lienHolder`}
        fieldProps={{ initialValue: upsells.firstItemLienHolder, type: 'checkbox' }}
      />
    )}
  </Fragment>
);

const DomainNameAdditionalFields = ({ values }) => {
  const form = useForm();
  const { category, itemType } = values.items[0];
  const { isDNH } = values.items.length > 0 ? values.items[0] : { isDNH: false };

  const updateAllFormItems = useCallback(
    (value) => {
      for (let i = 0; i < values.items.length; i++) {
        updateFormValues(form, `items[${i}]`, value);
      }
    },
    [form, values.items.length]
  );

  useEffect(() => {
    if (values.items.length > 0) {
      if (values.items[0].concierge === true) {
        updateAllFormItems({ concierge: true });
      } else if (values.items[0].isDNH) {
        updateAllFormItems({
          isDNH: true,
          dnsManagedBy: values.items[0].dnsManagedBy,
          concierge: false,
        });
      }
    }
  }, [updateAllFormItems, values.items]);

  const onDNHToggle = (e) => {
    if (e.target.checked) {
      form.change('transactionDisclosure', 'transparentToBuyerAndSeller');
      form.change('items[0].dnsManagedBy', undefined);
      updateAllFormItems({ concierge: false });
      updateAllFormItems({ isDNH: true });
    } else {
      form.change('items[0].dnsManagedBy', 'buyer');
      updateAllFormItems({ isDNH: false });
    }
  };

  const onDNHManagerToggle = (e) => {
    updateAllFormItems({ dnsManagedBy: e.target.value });
  };

  const conciergeChange = (value) => {
    updateAllFormItems({ concierge: value });
  };

  return (
    <Fragment>
      {category === 'domain' && itemType === 'domain_name' ? (
        <SwitchField
          name={'items[0].isDNH'}
          label="Use Domain Name Holding service"
          trackingName="DNH"
          initialValue={isDNH}
          onChange={onDNHToggle}
        />
      ) : null}
      {isDNH ? (
        <InputField
          select
          name={'items[0].dnsManagedBy'}
          label="DNS managed by"
          validate={required}
          onChange={onDNHManagerToggle}
        >
          {[
            { desc: 'Buyer', value: 'buyer' },
            { desc: 'Escrow.com', value: 'escrow' },
          ].map(({ desc, value }) => (
            <MenuItem key={value} value={value}>
              {desc}
            </MenuItem>
          ))}
        </InputField>
      ) : null}
      {isDNH || category === 'website' || !category || itemType !== 'domain_name' ? null : (
        <ConciergeCheck name={'items[0]'} cascadeChange={conciergeChange} />
      )}
    </Fragment>
  );
};

export {
  GMAdditionalInfoInput,
  MVAdditionalInfoInput,
  DNHAdditionalInfoInput,
  ConciergeCheck,
  DomainNameAdditionalFields,
};
