import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import Icon from 'spa/components/Icon';
import Checkbox from 'spa/components/Checkbox';
import PropTypes from 'prop-types';
import { FormError } from 'spa/components/form';
import { Spinner } from 'spa/components/Indicators';
import { Filter, FilterType } from 'spa/components/Filter';
import EnhancedTableHead from './EnhancedTableHead';

export const paginationOptions = [
  { label: 5, value: 5 },
  { label: 10, value: 10 },
  { label: 15, value: 15 },
];

export const maxRowsPerPage = paginationOptions.reduce(
  (currentMax, option) => Math.max(option.value, currentMax),
  0
);

const desc = (a, b, orderBy) => {
  let referenceVal = a[orderBy];
  let comparedVal = b[orderBy];

  if (orderBy === 'amount') {
    [referenceVal, comparedVal] = [referenceVal, comparedVal].map((amt) =>
      parseFloat(amt.match(/[\d.]+/))
    );
  }

  if (comparedVal < referenceVal) {
    return -1;
  }
  if (comparedVal > referenceVal) {
    return 1;
  }
  return 0;
};

const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const getSorting = (order, orderBy) =>
  order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);

const ConditionalLink = ({ condition, callback, children }) =>
  condition ? (
    <a onClick={callback} href="#">
      {children}
    </a>
  ) : (
    <Fragment>{children}</Fragment>
  );

class EnhancedTable extends Component {
  constructor(props) {
    super(props);

    this.handleRequestSort = this.handleRequestSort.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleDisplayChange = this.handleDisplayChange.bind(this);
  }

  componentDidMount() {
    const { getData, getDataTotals, nextCursor } = this.props;
    getData({
      cursor: nextCursor,
      limit: maxRowsPerPage,
    });
    getDataTotals();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.tableFilter !== this.props.tableFilter ||
      prevProps.entries.length !== this.props.entries.length
    ) {
      this.handleDisplayChange();
    }
  }

  handleDisplayChange() {
    const { currentPageIndex } = this.props.tableFilter;
    const { getData, nextCursor, entries, entryCountTotal, loading } = this.props;
    if (entries && entries.length > 0 && entryCountTotal) {
      if (
        entryCountTotal !== entries.length &&
        entries.length < (currentPageIndex + 2) * maxRowsPerPage &&
        loading === false
      ) {
        getData({
          cursor: nextCursor,
          limit: maxRowsPerPage,
        });
      }
    }
  }

  handleRequestSort(event, property) {
    const { tableFilter } = this.props;
    const orderBy = property;
    let order = 'desc';

    if (tableFilter.orderBy === property && tableFilter.order === 'desc') {
      order = 'asc';
    }
    this.props.updateTableFilter({ order, orderBy });
  }

  handleClick(event, id) {
    const { viewDetails } = this.props;
    viewDetails(id);
  }

  handleChangePage(event, pageIndex) {
    this.props.updateTableFilter({ currentPageIndex: pageIndex });
  }

  handleChangeRowsPerPage(event) {
    const { currentPageIndex, rowsPerPage } = this.props.tableFilter;
    const newRowsPerPage = event.target.value;
    const currentDisplayedItem = currentPageIndex * rowsPerPage + 1;
    const newPageIndex = Math.ceil(currentDisplayedItem / newRowsPerPage) - 1;

    this.props.updateTableFilter({
      rowsPerPage: parseInt(event.target.value, 10),
      currentPageIndex: newPageIndex,
    });
  }

  isSelected(id) {
    return this.props.tableFilter.selected.indexOf(id) !== -1;
  }

  render() {
    const {
      error,
      entries,
      entryCountTotal,
      columns,
      mapDataToTable,
      enableLink = true,
    } = this.props;
    const { order, orderBy, rowsPerPage, currentPageIndex } = this.props.tableFilter;
    const currentPage = currentPageIndex + 1;
    const data = mapDataToTable(entries);
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - currentPageIndex * rowsPerPage);
    const pages =
      entryCountTotal % rowsPerPage > 0
        ? parseInt(entryCountTotal / rowsPerPage, 10) + 1
        : parseInt(entryCountTotal / rowsPerPage, 10);
    const displayData = stableSort(data, getSorting(order, orderBy))
      .slice(currentPageIndex * rowsPerPage, currentPageIndex * rowsPerPage + rowsPerPage)
      .map((row) => row);
    const displayNumberRange = `${1 + rowsPerPage * currentPageIndex}-${
      _.clamp(rowsPerPage, 0, displayData.length) + rowsPerPage * currentPageIndex
    }`;

    return (
      <div className="card">
        {error && (
          <div className="card-inner">
            <FormError error={error} />
          </div>
        )}
        <div className="partnerDashboard-transactionTable">
          {entries && displayData.length > 0 && entryCountTotal ? (
            <table className="transactionTable">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
                columns={columns}
              />
              <tbody className="transactionTable-body">
                {displayData.map((entry) => {
                  const isSelected = this.isSelected(entry.id);
                  return (
                    <tr
                      className="transactionTable-row"
                      tabIndex={-1}
                      key={entry.id}
                      selected={isSelected}
                    >
                      <td className="transactionTable-cell" width="18" hidden>
                        <Checkbox
                          id={`${entry.id}`}
                          label={`check-box${entry.id}`}
                          name={`${entry.id}`}
                        />
                      </td>
                      {columns.map((col, index) => (
                        <td
                          key={col.id}
                          className={
                            col.id === 'initiator'
                              ? 'transactionTable-cell transactionTable-cell--email'
                              : 'transactionTable-cell'
                          }
                          align={col.align ? col.align : 'left'}
                        >
                          <ConditionalLink
                            condition={index === 0 && enableLink}
                            callback={(event) => this.handleClick(event, entry.id)}
                          >
                            {entry[col.id]}
                          </ConditionalLink>
                        </td>
                      ))}
                    </tr>
                  );
                })}
                {emptyRows > 0 && (
                  <tr className="transactionTable-emptyRow" style={{ height: 49 * emptyRows }}>
                    <td className="transactionTable-cell" colSpan={columns.length + 1} />
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            <div className="card-inner">
              {entryCountTotal !== 0 ? <Spinner /> : 'No items to display'}
            </div>
          )}
        </div>
        {entryCountTotal !== null && entryCountTotal !== 0 && (
          <div className="transactionTable-footer">
            <div className="transactionTable-rangeSelector">
              <span className="transactionTable-rangeSelector-label">Items per page:</span>
              <Filter
                name="rowsPerPage"
                type={FilterType.SELECT}
                options={paginationOptions}
                color="dark"
                handleChange={this.handleChangeRowsPerPage}
                isDisabled={!(entries && displayData.length > 0 && entryCountTotal)}
                initialSelection={rowsPerPage}
              />
            </div>
            <div className="transactionTable-pagination pagination">
              <div className="pagination">
                <span className="pagination-summary">
                  {displayData.length > 0 && `${displayNumberRange} of ${entryCountTotal} entries`}
                </span>
                <div className="pagination-controls">
                  <button
                    className="pagination-btn"
                    disabled={currentPage === 1}
                    onClick={(event) => this.handleChangePage(event, currentPageIndex - 1)}
                  >
                    <Icon
                      name="ui-chevron-right"
                      className="pagination-icon pagination-icon--prev"
                    />
                  </button>
                  <input type="text" className="pagination-input" value={currentPage} readOnly />
                  <span className="pagination-value">of {pages}</span>
                  <button
                    className="pagination-btn"
                    disabled={
                      currentPage === pages ||
                      !(entries && displayData.length > 0 && entryCountTotal)
                    }
                    onClick={(event) => this.handleChangePage(event, currentPageIndex + 1)}
                  >
                    <Icon name="ui-chevron-right" className="pagination-icon" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  tableFilter: PropTypes.func.isRequired,
  updateTableFilter: PropTypes.func.isRequired,
  loading: PropTypes.func.isRequired,
};

export default EnhancedTable;
