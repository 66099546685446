import React from 'react';
import PropTypes from 'prop-types';

const SectionSubTitle = ({ children }) => (
  <h3 className="integrationsPortal-section-subTitle">{children}</h3>
);

SectionSubTitle.propTypes = {
  children: PropTypes.string,
};

export default SectionSubTitle;
