/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storeKycDataUrl } from 'spa/features/kyc/kycSlice';
import {
  removeUploadedFilesInfo,
  kycSelectedIDSelector,
  kycSelectedPOASelector,
} from 'spa/features/kyc/kycSlice';
import {
  KYC_FILES,
  horizontalDocTypes,
  CAMERA_INSTRUCTIONS,
} from 'spa/constants/VerificationConstants';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import CircleIcon from '@mui/icons-material/Circle';

const getLivenessFrameClass = ({ fileKey, fileType }) => {
  if (fileKey === KYC_FILES.SELFIE) {
    return 'camera-liveness-frame-selfie';
  } else if (horizontalDocTypes.includes(fileType)) {
    return 'camera-liveness-frame-doc-horizontal';
  }
  return 'camera-liveness-frame-doc-vertical';
};

const getCameraInstruction = ({ fileKey, fileType }) => {
  if (fileKey === KYC_FILES.SELFIE) {
    return CAMERA_INSTRUCTIONS.SELFIE;
  } else if (fileType === 'passport') {
    return CAMERA_INSTRUCTIONS.PASSPORT;
  } else if (fileKey === KYC_FILES.ID_FRONT || fileKey === KYC_FILES.ID_BACK) {
    return CAMERA_INSTRUCTIONS.ID;
  }
  return CAMERA_INSTRUCTIONS.DOCUMENT;
};

const CameraPage = ({ fileKey, setTriggerNextPage }) => {
  const dispatch = useDispatch();

  const videoFrameRef = useRef(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const debounceRef = useRef(null);

  const selectedId = useSelector(kycSelectedIDSelector);
  const selectedPOA = useSelector(kycSelectedPOASelector);

  const [permissionBlocked, setPermissionBlocked] = useState(false);
  const [useFrontCamera, setUsingFrontCamera] = useState(fileKey === KYC_FILES.SELFIE);

  const fileType = useMemo(() => {
    if (fileKey === KYC_FILES.ID_FRONT || fileKey === KYC_FILES.ID_BACK) {
      return selectedId.type;
    } else if (fileKey === KYC_FILES.POA) {
      return selectedPOA.type;
    } else if (fileKey === KYC_FILES.SELFIE) {
      return 'selfie';
    }
    return 'others';
  }, [fileKey, selectedId, selectedPOA]);

  useEffect(() => {
    // Get access to the camera
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode: useFrontCamera ? 'user' : 'environment',
          },
        })
        .then((stream) => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
          }
        })
        .catch((err) => {
          if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
            setPermissionBlocked(true);
          }
        });
    }
    // this is to fix the error that the video.current may be changed in the cleanup function
    const currentVideoRef = videoRef.current;

    return () => {
      if (currentVideoRef && currentVideoRef.srcObject) {
        const tracks = currentVideoRef.srcObject.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, [useFrontCamera]);

  const handleScroll = useCallback(() => {
    if (videoFrameRef.current) {
      const { top } = videoFrameRef.current.getBoundingClientRect();

      if (top < 0) {
        if (debounceRef.current) {
          clearTimeout(debounceRef.current);
        }

        debounceRef.current = setTimeout(() => {
          window.scrollBy({
            top: top - 20,
            left: 0,
            behavior: 'instant',
          });
        }, 100);
      }
    }
  }, [videoFrameRef, debounceRef]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
    };
  }, [handleScroll]);

  const handleSnap = useCallback(() => {
    dispatch(removeUploadedFilesInfo({ fileKey }));
    if (videoRef.current && canvasRef.current) {
      const { top: videoFrameTop, left: videoFrameLeft } =
        videoFrameRef.current.getBoundingClientRect();

      const videoFrameWidth = videoFrameRef.current.offsetWidth;
      const videoFrameHeight = videoFrameRef.current.offsetHeight;

      canvasRef.current.width = videoFrameWidth;
      canvasRef.current.height = videoFrameHeight;
      const ctx = canvasRef.current.getContext('2d');

      // original video width and height (dependens on camera resolution not container)
      const videoWidth = videoRef.current.videoWidth;
      const videoHeight = videoRef.current.videoHeight;
      // video width and width displayed in container
      const videoOffsetWidth = videoRef.current.offsetWidth;
      const videoOffsetHeight = videoRef.current.offsetHeight;
      const videoAspectRatio = videoWidth / videoHeight;
      const containerAspectRatio = videoOffsetWidth / videoOffsetHeight;

      let scaleIndex = null;
      let scaledLeft = null;
      let scaledTop = null;

      if (videoAspectRatio > containerAspectRatio) {
        // The video is wider than the container, so it is vertically scaled to fit
        scaleIndex = videoOffsetHeight / videoHeight;
        const leftOverflowWidth = (videoWidth * scaleIndex - videoOffsetWidth) / 2;
        scaledLeft = leftOverflowWidth + videoFrameLeft;
        const topOverflowHeight = (videoHeight * scaleIndex - videoOffsetHeight) / 2;
        scaledTop = topOverflowHeight + videoFrameTop;
      } else {
        // The video is taller than the container, so it is horizontally scaled to fit
        scaleIndex = videoOffsetWidth / videoWidth;
        const topOverflowHeight = (videoHeight * scaleIndex - videoOffsetHeight) / 2;
        scaledTop = topOverflowHeight + videoFrameTop;
        const leftOverflowWidth = (videoWidth * scaleIndex - videoOffsetWidth) / 2;
        scaledLeft = leftOverflowWidth + videoFrameLeft;
      }

      ctx.drawImage(
        videoRef.current,
        scaledLeft / scaleIndex,
        scaledTop / scaleIndex,
        videoFrameWidth / scaleIndex,
        videoFrameHeight / scaleIndex,
        0,
        0,
        videoFrameWidth,
        videoFrameHeight
      );

      const imageUrl = canvasRef.current.toDataURL();
      dispatch(storeKycDataUrl({ fileKey: fileKey, value: imageUrl }));

      if (setTriggerNextPage) {
        setTriggerNextPage(true);
      }
    }
  }, [dispatch, fileKey, setTriggerNextPage]);

  if (permissionBlocked) {
    return (
      <div>
        <p>Camera access was denied. Please grant permission to proceed.</p>
        <p>
          To grant permission, please change the camera permission in browser setting and refresh
          the page.
        </p>
      </div>
    );
  }

  return (
    <div className="camera-wrapper">
      <video className="camera-video" ref={videoRef} autoPlay playsInline />
      <div ref={videoFrameRef} className={getLivenessFrameClass({ fileKey, fileType })}>
        <span className="camera-instruction-text">
          {getCameraInstruction({ fileKey, fileType })}
        </span>
      </div>
      <div className="camera-bottom-components">
        <div className="camera-buttons-wrapper">
          <ChangeCircleIcon
            className="camera-button-switch"
            sx={{ fontSize: '40px', marginLeft: '2rem', visibility: 'hidden' }}
          />
          <CircleIcon
            className="camera-button-snap"
            sx={{ fontSize: '60px' }}
            onClick={handleSnap}
          />
          <ChangeCircleIcon
            className="camera-button-switch"
            sx={{ fontSize: '40px', marginRight: '2rem' }}
            onClick={() => setUsingFrontCamera((prev) => !prev)}
          />
        </div>
      </div>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </div>
  );
};

export default CameraPage;
