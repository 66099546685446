import React from 'react';
import { connect } from 'react-redux';

import ACHFormContainer from 'spa/components/DisbursementDetails/ACHForm';
import {
  getDisbursementDetailsRequest,
  getUserFlow as getUserFlowAction,
} from 'spa/actions/DisbursementActions';
import {
  abaRoutingNumberSelector,
  accountTypeSelector,
  accountNameSelector,
  bankAccountNumberSelector,
  bankNameSelector,
  editedDisbursementMethodSelector,
  validDisbursementNamesSelector,
  loadingSelector,
  successSelector,
} from 'spa/selectors/DisbursementSelectors';

class ACHContainer extends React.Component {
  componentDidMount() {
    this.props.getUserFlow();
  }

  render() {
    return <ACHFormContainer {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  let initialValues = {
    accountName: validDisbursementNamesSelector(state)[0],
  };
  const editedMethod = editedDisbursementMethodSelector(state);
  if (editedMethod) {
    initialValues = {
      accountName: accountNameSelector(editedMethod),
      accountType: accountTypeSelector(editedMethod),
      abaRoutingNumber: abaRoutingNumberSelector(editedMethod),
      accountNumber: bankAccountNumberSelector(editedMethod),
      bankName: bankNameSelector(editedMethod),
    };
  }

  return {
    validDisbursementNames: validDisbursementNamesSelector(state),
    display: {
      loading: loadingSelector(state, 'display'),
      success: successSelector(state, 'display'),
    },
    initialValues: initialValues,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDisbursementDetails: () => {
    dispatch(getDisbursementDetailsRequest());
  },
  getUserFlow: () => {
    dispatch(getUserFlowAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ACHContainer);
