import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from 'spa/components/Icon';
import { trackEscrowUserAction } from 'spa/actions/TrackingActions';
import { fileUploadStart, fileUploadEnd } from 'spa/actions/FileUploadActions';
import { findAttribute } from '../../../../components/Tracking';
import { gettext } from '../../../../utils/filters';
import { formatFileSize } from '../../../../utils/Formatting';
import ProgressBar from '../../ProgressBar';
import InfoTooltip from '../InfoTooltip';
import FieldError from '../FieldError';

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };
    this.config = {
      numFiles: props.numFiles,
      note:
        props.note ||
        gettext('Allowed documents: jpg, png, gif, tiff, bmp, pdf - 100MB maximum file size.'),
      extraNote: props.extraNote,
    };

    this.handleFileUploadRequest = this.handleFileUploadRequest.bind(this);
    this.handleFileDrop = this.handleFileDrop.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.fireTrackingEvent = this.fireTrackingEvent.bind(this);
  }

  /**
   * @param {event} React synthetic event (i.e. onDragEnter, onDragLeave, onDrop)
   */
  getFilenameListFromEvent(event) {
    const fileList = [];

    if (!event.dataTransfer) {
      return fileList;
    }

    if (event.dataTransfer.items) {
      // Try to get file from dataTransfer.items interface
      const itemArray = Array.from(event.dataTransfer.items);
      itemArray.map((item) => {
        if (item.kind === 'file') {
          fileList.push(item.getAsFile().name);
        }
      });
    } else {
      // Try to get file from dataTransfer.files interface
      const fileArray = Array.from(event.dataTransfer.files);
      fileArray.map((file) => fileList.push(file.name));
    }

    return fileList;
  }

  /**
   * @param {File} file
   */
  validateFile(file) {
    let errors = [];

    const fileExists = Boolean(this.state.files.find((f) => f.name === file.name));
    if (fileExists) {
      errors.push('File already exists');
    }

    // run the validators give to us
    for (const fileValidator of this.props.fileValidators) {
      const error = fileValidator(file);
      if (error) {
        errors = errors.concat(error);
      }
    }

    return errors;
  }

  fireTrackingEvent(event) {
    const name =
      this.props['data-tracking-name'] || findAttribute(event.target, 'data-tracking-name');

    const section =
      this.props['data-tracking-section'] || findAttribute(event.target, 'data-tracking-section');

    const subsection =
      this.props['data-tracking-subsection'] ||
      findAttribute(event.target, 'data-tracking-subsection');

    const value =
      this.props['data-tracking-value'] || findAttribute(event.target, 'data-tracking-value');

    this.props.dispatch(
      trackEscrowUserAction({
        name: name,
        section: section,
        subsection: subsection,
        value: value,
        label: '',
        action: 'click',
      })
    );
  }

  handleFileUploadRequest(event) {
    this.fireTrackingEvent(event);

    /** @type {(Array<File>)} */
    const filesToUpload = event.target.files;
    for (const file of filesToUpload) {
      // add file to state
      const currentFiles = this.state.files;
      const isValidFile = this.validateFile(file).length === 0;
      currentFiles.push({
        name: file.name,
        size: file.size,
        type: file.type,
        status: isValidFile ? 'IN_PROGRESS' : 'INVALID',
        progress: 0,
      });
      this.setState({
        files: currentFiles,
      });
      // Check for file validation errors
      if (isValidFile) {
        // add a new file field to redux forms
        this.uploadFile(file);
      }
    }
  }

  handleFileDrop(event) {
    const filenameList = this.getFilenameListFromEvent(event);
    let isValid = true;

    // Abort if file extensions does not match
    if (isValid && this.props.fileExtensions) {
      isValid = filenameList.reduce((result, next) => {
        const extension = `.${next.split('.').pop()}`;
        return result && this.props.fileExtensions.includes(extension);
      }, true);
    }

    // Abort if number of file exceeds free file slots
    if (isValid && this.config.numFiles) {
      const freeFileSlot = this.config.numFiles - this.state.files.length;
      const numDropFile = filenameList.length;
      isValid = numDropFile <= freeFileSlot;
    }

    // Disable propagation to file uploader if criteria is not satisfied
    if (!isValid) {
      event.preventDefault();
    }
  }

  uploadFile(file) {
    const formData = new FormData();
    const xhr = new XMLHttpRequest();
    this.props.fileUploadStart();
    formData.append('file', file);

    const progressHandler = (progressEvent) => {
      if (progressEvent.lengthComputable) {
        const percentageComplete = parseInt(progressEvent.loaded / progressEvent.total, 10);
        const currentFiles = this.state.files;
        const fileInState = currentFiles.find((f) => f.name === file.name);
        fileInState.progress = percentageComplete;
        this.setState({
          files: currentFiles,
        });
      }
    };

    const errorHandler = () => {
      this.props.fileUploadEnd();
      const currentFiles = this.state.files;
      const fileInState = currentFiles.find((f) => f.name === file.name);
      fileInState.progress = 0;
      fileInState.status = 'FAILED';
      this.setState({
        files: currentFiles,
      });
    };

    const fileIdSelector = (response) => {
      let fileId = null;
      const jsonResponse = JSON.parse(response) || {};

      if (fileId === null && jsonResponse.Id) {
        fileId = jsonResponse.Id;
      }

      if (
        fileId === null &&
        jsonResponse.submission_ids &&
        jsonResponse.submission_ids.length > 0
      ) {
        fileId = jsonResponse.submission_ids[0];
      }

      return fileId;
    };

    const completionHandler = (loadEvent) => {
      // upload completed
      this.props.fileUploadEnd();
      const currentFiles = this.state.files;
      const fileInState = currentFiles.find((f) => f.name === file.name);
      if (loadEvent.target.status >= 200 && loadEvent.target.status <= 299) {
        fileInState.progress = 100;
        fileInState.status = 'OK';
        fileInState.fileId = fileIdSelector(loadEvent.target.response);
        this.setState({
          files: currentFiles,
        });
        this.props.fields.push({
          fileName: fileInState.name,
          fileId: fileInState.fileId,
        });
      } else {
        errorHandler();
      }
    };

    xhr.addEventListener('progress', progressHandler);
    xhr.addEventListener('load', completionHandler);
    xhr.addEventListener('error', errorHandler);
    xhr.addEventListener('abort', errorHandler);

    xhr.open('post', this.props.uploadEndpoint, true);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.withCredentials = true;
    xhr.send(formData);
  }

  removeFile(event) {
    const index = event.currentTarget.getAttribute('data-file-index');
    const currentFiles = this.state.files;
    const fileToRemove = currentFiles[index];
    currentFiles.splice(index, 1);
    this.setState({
      files: currentFiles,
    });

    // if this file exists in the redux-form state, remove it as well
    const fileIndexInFormState = (this.props.fields.getAll() || []).findIndex(
      (savedFile) => savedFile && fileToRemove && savedFile.fileId === fileToRemove.fileId
    );
    if (fileIndexInFormState >= 0) {
      this.props.fields.remove(fileIndexInFormState);
    }
  }

  renderFileProgress() {
    return this.state.files.map((fileData, index) => (
      <div key={`${fileData.name}${fileData.size}`} className="uploadedFiles">
        <div className="uploadedFiles-item">
          <span className="uploadedFiles-details">
            <ProgressBar
              value={fileData.progress}
              size="small"
              className="uploadedFiles-progressBar"
            />
            <span
              className={classNames('uploadedFiles-statusIcon', {
                'uploadedFiles-statusIcon--success': fileData.status === 'OK',
                'uploadedFiles-statusIcon--error': ['FAILED', 'INVALID'].includes(fileData.status),
              })}
            >
              {fileData.status === 'OK' && <Icon name="ui-tick" className="icon" />}
              {['FAILED', 'INVALID'].includes(fileData.status) && <Icon name="ui-warning" />}
            </span>
            <span className="uploadedFiles-fileName">
              {fileData.status === 'INVALID' && <strong>(Invalid) </strong>}
              {fileData.name}
            </span>
          </span>
          <span className="uploadedFiles-control">
            <span className="uploadedFiles-size">{formatFileSize(fileData.size)}</span>
            <span>
              <button
                type="button"
                className="uploadedFiles-btn"
                data-file-index={index}
                onClick={this.removeFile}
              >
                <Icon name="legacy-close" />
              </button>
            </span>
          </span>
        </div>
      </div>
    ));
  }

  render() {
    const { meta, name, label, tooltip, requiredIndicator, fileExtensions } = this.props;

    let acceptFileExtensions = '*';
    if (fileExtensions && fileExtensions.length > 0) {
      acceptFileExtensions = fileExtensions.join(',');
    }

    let displayFileUploader = true;
    if (this.config.numFiles && this.state.files.length >= this.config.numFiles) {
      displayFileUploader = false;
    }

    return (
      <div
        className={classNames('field', {
          'is-invalid': meta.error && (meta.dirty || meta.submitFailed),
        })}
      >
        <label htmlFor={name} className="field-label">
          {label}
          {requiredIndicator && <span className="field-required">*</span>}
          {tooltip && <InfoTooltip id={`${name}-field-input-tooltip`} message={tooltip} />}
        </label>

        {this.renderFileProgress()}

        {displayFileUploader && (
          <div>
            <div className="fileUploader">
              <input
                className="fileUploader-input"
                id={name}
                type="file"
                accept={acceptFileExtensions}
                multiple={!this.config.numFiles || this.config.numFiles > 1}
                onChange={this.handleFileUploadRequest}
                onDrop={this.handleFileDrop}
                data-e2e-target={this.props['data-e2e-target']}
              />
              <div className="fileUploader-inner">
                <span className="fileUploader-btn btn btn--primary">
                  <span className="media--available@tablet">Take Photo or </span>
                  {gettext('Choose file')}
                </span>
                <div className="fileUploader-content">
                  <h4 className="fileUploader-heading">
                    {gettext('Drag & Drop Files here or browse your device')}
                  </h4>
                  <div className="fileUploader-note">{this.config.note}</div>
                  {this.config.extraNote && (
                    <Fragment>
                      <div className="fileUploader-note">{this.config.extraNote}</div>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {meta.error && (meta.dirty || meta.submitFailed) && <FieldError message={meta.error} />}
        {meta.warning && (meta.dirty || meta.submitFailed) && <FieldError message={meta.warning} />}
      </div>
    );
  }
}

FileUpload.propTypes = {
  uploadEndpoint: PropTypes.string.isRequired,
  fileValidators: PropTypes.arrayOf(PropTypes.func),
};

FileUpload.defaultProps = {
  fileValidators: [],
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  fileUploadStart: () => dispatch(fileUploadStart()),
  fileUploadEnd: () => dispatch(fileUploadEnd()),
});

export default connect(null, mapDispatchToProps)(FileUpload);
