import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { bindRoutineToReduxForm } from 'redux-saga-routines';
import { required, isDecimal } from 'spa/components/form/validate';
import { ActionButton, FormError } from 'spa/components/form';
import { verifyCardVerificationForm as verifyCardVerificationFormRoutine } from 'spa/actions/PaymentsActions';
import PaymentConstants from 'spa/constants/PaymentConstants';
import { Input } from 'spa/components/form';
import { gettext } from '../../../utils/filters';

const chargeLabel = 'charge amount';
const isChargeAmount = isDecimal(chargeLabel);

const VerifyChargeForm = (props) => (
  <div>
    <form onSubmit={props.handleSubmit(bindRoutineToReduxForm(verifyCardVerificationFormRoutine))}>
      <Field
        name="firstCharge"
        label={'First Amount'}
        placeholder={`Enter the first amount charged (e.g. 1.40)`}
        component={Input}
        validate={[required, isChargeAmount]}
        requiredIndicator
      />
      <Field
        name="secondCharge"
        label={'Second Amount'}
        placeholder={'Enter the second amount charged (e.g. 1.31)'}
        component={Input}
        validate={[required, isChargeAmount]}
        requiredIndicator
      />
      {props.error && <FormError error={props.error} />}
      <ActionButton
        className="btn btn--secondary"
        name="submit"
        type="submit"
        label={gettext('Verify my card')}
        loading={props.submitting}
      />
    </form>
  </div>
);

export default reduxForm({
  form: PaymentConstants.CREATE_VERIFY_CHARGE_FORM,
  enableReinitialize: true,
})(VerifyChargeForm);
