import React from 'react';

import Footer from 'spa/components/Footer';
import { Section, SectionDesc } from 'spa/components/Section';
import ReferralLinksBanner from 'spa/containers/ReferralLinksBanner';

const ReferralLinksBannerPage = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktop-9">
        <h1 className="integrationsPortal-title">Affiliate Banners</h1>
        <Section>
          <SectionDesc>
            If you wish to place a banner on your site to show you believe in the security of
            Escrow.com, we have placed a variety of banners below. Save them to your computer, and
            you can use them at any time.
          </SectionDesc>
        </Section>

        <ReferralLinksBanner id="1" src="banner-468x60.gif" width="468" height="60" />
        <ReferralLinksBanner id="2" src="banner-125x125.gif" width="125" height="125" />
        <ReferralLinksBanner id="3" src="banner-88x31.gif" width="88" height="31" />
        <ReferralLinksBanner id="4" src="Escow_Banner_120x240.jpg" width="120" height="240" />
        <ReferralLinksBanner id="5" src="Escow_Banner_250x250.jpg" width="250" height="250" />

        <ReferralLinksBanner id="6" src="Escow_Banner_265x100.jpg" width="265" height="100" />
        <ReferralLinksBanner id="7" src="Escow_Banner_300x250.jpg" width="300" height="250" />
        <ReferralLinksBanner id="8" src="Escow_Banner_640x135.jpg" width="640" height="135" />
        <ReferralLinksBanner id="9" src="Escow_Banner_703x90.jpg" width="703" height="90" />

        <ReferralLinksBanner id="10" src="Escow_Banner_720x100.jpg" width="720" height="100" />
        <ReferralLinksBanner id="11" src="Escow_Banner_728x90.jpg" width="728" height="90" />
        <ReferralLinksBanner id="12" src="Escow_Banner_836x100.jpg" width="836" height="100" />
        <ReferralLinksBanner id="13" src="Escow_Banner_960x100.jpg" width="960" height="100" />
        <ReferralLinksBanner
          id="14"
          src="Escrow.com_redone_ad_450x376px.jpg"
          width="450"
          height="376"
        />

        <Footer />
      </div>
    </div>
  </div>
);

export default ReferralLinksBannerPage;
