import React from 'react';

const renderUrlAsLink = (message) => {
  const urlPattern =
    /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\z`!()\[\]{};:'".,<>?«»“”‘’]))/gi;

  /* eslint-disable react/jsx-key */
  return message
    .split(urlPattern)
    .filter((text) => !!text)
    .map((text) => {
      if (text.match(urlPattern)) {
        return <a href={text}>{text}</a>;
      }
      return text;
    });
};

export { renderUrlAsLink };
