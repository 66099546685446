import React, { Component } from 'react';
import PropTypes from 'prop-types';

class EnhancedTableHead extends Component {
  constructor(props) {
    super(props);
    this.createSortHandler = this.createSortHandler.bind(this);
  }

  createSortHandler(property) {
    return (event) => this.props.onRequestSort(event, property);
  }

  render() {
    const { orderBy, columns } = this.props;

    return (
      <thead>
        <tr className="transactionTable-headerRow">
          {columns.map(
            (column) => (
              <td
                className="transactionTable-cell"
                key={column.id}
                align={column.numeric ? 'right' : 'left'}
              >
                <button
                  className={`transactionTable-sortBtn ${orderBy === column.id && 'is-active'}`}
                  onClick={this.createSortHandler(column.id)}
                >
                  {column.label}
                </button>
              </td>
            ),
            this
          )}
        </tr>
      </thead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
};

export default EnhancedTableHead;
