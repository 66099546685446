export const getWindow = () => window;
export const getNavigator = () => navigator;

/**
 * Get the URL params from the current window location
 */

export function getWindowUrlParams() {
  const href = window.location.href;
  const startParamIdx = href.indexOf('?');
  if (startParamIdx === -1) {
    return null;
  }
  const searchParams = new URLSearchParams(href.substring(startParamIdx));
  return searchParams;
}
