import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { DEFAULT_ESCROW_PAGE, MAIN_FUNNEL_PAGE } from 'spa/constants/HeaderConstants';
import Announcement from '../Announcement'; // vite needs this path to be like this otherwise it dies
import { updateStickyNav } from '../../../navigation/sticky-nav';
import UserActions from '../../../actions/UserActions';
import AuthenticationStore from '../../../stores/AuthenticationStore';
import { gettext } from '../../../utils/filters';
import MainFunnelHeader from './MainFunnelHeader';
import MyTransactionsHeader from './MyTransactionsHeader';
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.renderAnnouncementLink = this.renderAnnouncementLink.bind(this);
  }

  componentDidMount() {
    const loggedInUser = AuthenticationStore.getCustomerID();
    if (loggedInUser) {
      UserActions.getCustomerData(loggedInUser);
    }
    updateStickyNav();
  }

  renderAnnouncementLink() {
    const { emailSending, emailSent, sendVerificationEmail } = this.props;

    if (emailSent || emailSending) {
      return <span>{emailSent ? 'Email Sent' : 'Sending...'}</span>;
    }

    return (
      <a
        role="button"
        tabIndex={0}
        className="headerV3-announcement-link"
        onClick={sendVerificationEmail}
      >
        Send verification email
      </a>
    );
  }

  render() {
    const { title, pageType, toggleSidebar, emailVerified, hideEmailBanner, hideBackArrow } =
      this.props;
    return (
      <Fragment>
        {!emailVerified && !hideEmailBanner && (
          <Announcement
            type="warning"
            title={gettext('Please verify your email address')}
            closable={false}
            className="headerV3-announcement--warning"
          >
            <Fragment>
              <span>
                {gettext(
                  "Verify your email address to confirm that this account belongs to you. Haven't received a verification email? "
                )}
              </span>
              {this.renderAnnouncementLink()}
            </Fragment>
          </Announcement>
        )}
        {pageType === DEFAULT_ESCROW_PAGE && (
          <MyTransactionsHeader title={title} toggleSidebar={toggleSidebar} />
        )}
        {pageType === MAIN_FUNNEL_PAGE && (
          <MainFunnelHeader title={title} hideBackArrow={hideBackArrow} />
        )}
      </Fragment>
    );
  }
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  pageType: PropTypes.string.isRequired,
};

export default Header;
