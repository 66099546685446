import React from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

const CollapsableContentBody = ({ children, height }) => (
  <AnimateHeight duration={200} height={height}>
    <div className="collapsableContent-body">{children}</div>
  </AnimateHeight>
);

CollapsableContentBody.propTypes = {
  children: PropTypes.node,
};

CollapsableContentBody.defaultProps = {
  height: 'auto',
};

export default CollapsableContentBody;
