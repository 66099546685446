import React from 'react';
import moment from 'moment';
import { getCurrencyString } from 'escrow-common-js/dist/utils';
import { getJSON } from '../../../utils/FetchUtils';

export const values = {
  email: 'lgoze+buyer@freelancer.com',
  password: 'Free123',
  auction_id: 193797,
  refreshTimeSeconds: 10,
};

const base64Auth = btoa(`${values.email}:${values.password}`);

const getAuctionOffers = () =>
  getJSON(`${window.config.offer_endpoint}/${values.auction_id}/offer`, {
    Authorization: `Basic ${base64Auth}`,
  })
    .then((response) => {
      if (response.ok) {
        return response.json().then((body) => Promise.resolve(body));
      }
      return Promise.reject(null);
    })
    .catch((error) => Promise.reject(error));

export const OffersToRows = (offers) =>
  offers.reverse().map((offer) => {
    const email = offer.by_party.customer;
    const name = email.substring(0, email.lastIndexOf('@'));
    const domain = email.substring(email.lastIndexOf('@') + 1);
    let displayEmail = '';
    if (name.length < 3) {
      displayEmail = '';
    } else {
      displayEmail = name.substring(0, 2);
    }
    displayEmail = `${displayEmail}***@${domain}`;

    return (
      <tr key={offer.id}>
        <td className="guitar-table-cell guitar-table-email">{displayEmail}</td>
        <td className="guitar-table-cell">{moment(offer.date_created).fromNow()}</td>
        <td className="guitar-table-cell guitar-table-amount" align="right">
          {getCurrencyString(parseFloat(offer.no_fee_amount), 'aud')}
        </td>
      </tr>
    );
  });

class GuitarOffersList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...values,
      offers: [],
    };

    this.updateOffers();
  }

  componentDidMount() {
    this.interval = setInterval(() => this.updateOffers(), this.state.refreshTimeSeconds * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  updateOffers() {
    getAuctionOffers()
      .then((data) => {
        this.setState({ offers: data.offers });
      })
      .catch();
  }

  render() {
    return (
      <div className="guitar-offersList">
        <div className="guitar-offersList-card">
          <header className="guitar-offersList-header">
            <h2 className="guitar-offersList-title">
              Offers for Huntington Telecaster signed by Dave Grohl
            </h2>
            <span className="guitar-offersList-count">
              Total of {this.state.offers.length} offers
            </span>
          </header>
          <div className="guitar-offersList-body">
            <table className="guitar-table">
              <tbody>{OffersToRows(this.state.offers)}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default GuitarOffersList;
