import React from 'react';
import { EscrowButton } from 'spa/features/ui/EscrowButton';

const RetakePhotoButton = ({ children, onClick, sx, disabled }) => (
  <EscrowButton
    key="retake-photo"
    disabled={disabled}
    onClick={onClick}
    variant="text"
    sx={{
      ...sx,
      textDecoration: 'underline',
      backgroundColor: 'transparent !important',
    }}
  >
    {children}
  </EscrowButton>
);

export default RetakePhotoButton;
