import {
  getWireDetails as getWireDetailsRoutine,
  initPlaidClient as initPlaidClientRoutine,
  getOutstandingBalance as getOutstandingBalanceRoutine,
  selectPaymentMethod as selectPaymentMethodRoutine,
  getCheckDetails as getCheckDetailsRoutine,
} from 'spa/actions/PaymentsActions';
import PaymentConstants from 'spa/constants/PaymentConstants';

const initialState = {
  plaidClient: {
    data: null,
    error: null,
  },
  wireDetails: {
    data: null,
    showForm: true,
    error: null,
  },
  checkDetails: {
    data: null,
    error: null,
  },
  outstandingBalance: {
    data: null,
    error: null,
  },
  paymentSubmission: {
    success: false,
    error: null,
  },
  paymentType: null,
};

function PaymentsPageReducer(state = initialState, action) {
  switch (action.type) {
    case PaymentConstants.INITIALIZE_PAGE:
      return initialState;
    case PaymentConstants.SET_PAYMENT_TYPE:
      return {
        ...state,
        paymentType: action.payload.paymentType,
      };
    case PaymentConstants.SHOW_WIRE_FORM:
      return {
        ...state,
        wireDetails: {
          ...state.wireDetails,
          showForm: true,
        },
      };

    case getCheckDetailsRoutine.TRIGGER:
    case getWireDetailsRoutine.TRIGGER:
      return {
        ...state,
        paymentSubmission: {
          success: false,
        },
      };
    case getWireDetailsRoutine.SUCCESS:
      return {
        ...state,
        wireDetails: {
          ...state.wireDetails,
          data: action.payload,
          showForm: false,
          error: null,
        },
      };
    case getWireDetailsRoutine.FAILURE:
      return {
        ...state,
        wireDetails: {
          ...state.wireDetails,
          error: 'Failed to retrieve wire details',
        },
      };
    case initPlaidClientRoutine.TRIGGER:
      return {
        ...state,
        plaidClient: {
          data: null,
        },
      };
    case initPlaidClientRoutine.SUCCESS:
      return {
        ...state,
        plaidClient: {
          data: action.payload.handler,
          error: null,
        },
      };
    case initPlaidClientRoutine.FAILURE:
      return {
        ...state,
        plaidClient: {
          ...state.plaidClient,
          error: 'Failed to connect to the server.',
        },
      };
    case getCheckDetailsRoutine.SUCCESS:
      return {
        ...state,
        checkDetails: {
          data: action.payload,
          error: null,
        },
      };
    case getCheckDetailsRoutine.FAILURE:
      return {
        ...state,
        checkDetails: {
          ...state.checkDetails,
          error: 'Failed to retrieve check details',
        },
      };
    case getOutstandingBalanceRoutine.TRIGGER:
      return {
        ...state,
        paymentSubmission: {
          error: null,
          success: false,
        },
        outstandingBalance: {
          data: null,
          error: null,
        },
      };
    case getOutstandingBalanceRoutine.SUCCESS:
      return {
        ...state,
        outstandingBalance: {
          data: action.payload,
          error: null,
        },
      };
    case getOutstandingBalanceRoutine.FAILURE:
      return {
        ...state,
        outstandingBalance: {
          ...state.outstandingBalance,
          error: "Failed to retrieve transaction's outstanding balance",
        },
      };
    case selectPaymentMethodRoutine.TRIGGER:
      return {
        ...state,
        paymentSubmission: {
          success: false,
          error: null,
        },
      };
    case selectPaymentMethodRoutine.SUCCESS:
      return {
        ...state,
        paymentSubmission: {
          success: true,
          error: null,
        },
      };
    case selectPaymentMethodRoutine.FAILURE:
      return {
        ...state,
        paymentSubmission: {
          success: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}

export default PaymentsPageReducer;
