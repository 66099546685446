import React from 'react';

import * as DisbursementDetailsContainer from 'spa/containers/DisbursementDetails';

import ACHDisbursementDetails from './ACH';
import InternationalWireDisbursementDetails from './InternationalWire';
// import EuroWireDisbursementDetails from './EuroWire';

const DisbursementDetails = () => (
  <div>
    <DisbursementDetailsContainer />
  </div>
);

export { ACHDisbursementDetails, InternationalWireDisbursementDetails, DisbursementDetails };
