import React from 'react';
import StartTransaction from 'spa/containers/StartTransaction';
import EscrowTheme from 'spa/components/MaterialUI/Theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import HeaderV3 from 'spa/containers/HeaderV3';
import { MAIN_FUNNEL_PAGE } from 'spa/constants/HeaderConstants';
import { gettext } from '../../../utils/filters';

const startTransactionPage = () => (
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <HeaderV3 title={gettext('Start Transaction')} pageType={MAIN_FUNNEL_PAGE} />
    <EscrowTheme>
      <StartTransaction />
    </EscrowTheme>
  </LocalizationProvider>
);
export default startTransactionPage;
