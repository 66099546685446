import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { V3KYCMobilePages as Pages } from 'spa/constants/VerificationConstants';
import MobilePage from '../../components/MobilePage';
import UploadSuccess from '../../components/UploadSuccess';
import { kycSelectedIDSelector } from 'spa/features/kyc/kycSlice';

const IDUploadSuccessPage = ({ back }) => {
  const [nextPageTrigger, setNextPageTrigger] = useState(false);

  const id = useSelector(kycSelectedIDSelector);

  const nextPage = useMemo(
    () =>
      back || (id && id.type === 'passport') ? Pages.POA_SELECTION : Pages.ID_BACK_INSTRUCTIONS,
    [back, id]
  );

  useEffect(() => {
    setTimeout(() => {
      setNextPageTrigger(true);
    }, 1500);
  }, []);

  // TODO T297643: Update the copy for uploadedText
  return (
    <MobilePage nextPage={nextPage} nextPageTrigger={nextPageTrigger} noButton>
      <UploadSuccess uploadedText={'Upload successful'} />
    </MobilePage>
  );
};

export default IDUploadSuccessPage;
