import React from 'react';

const ToggleSwitch = ({ input, className, disabled }) => (
  <label className={`toggleSwitch ${className}`}>
    <span className="toggleSwitch-inner">
      <input
        type="checkbox"
        className="toggleSwitch-checkbox"
        disabled={disabled}
        {...input}
        checked={input.value}
      />
      <span className="toggleSwitch-bg" />
      <span className="toggleSwitch-handle" />
    </span>
  </label>
);

export default ToggleSwitch;
