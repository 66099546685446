import FileUploadConstants from 'spa/constants/FileUploadConstants';

const initialState = {
  uploading: false,
};

function fileUploadReducer(state = initialState, action) {
  switch (action.type) {
    case FileUploadConstants.FILE_UPLOAD_START:
      return {
        ...state,
        uploading: true,
      };
    case FileUploadConstants.FILE_UPLOAD_END:
      return {
        ...state,
        uploading: false,
      };
    default:
      return state;
  }
}

export default fileUploadReducer;
