import React from 'react';

import DisplayWebhooks from 'spa/containers/DisplayWebhooks';
import CreateWebhooks from 'spa/containers/CreateWebhooks';
import Footer from 'spa/components/Footer';

const WebhooksPage = () => (
  <div className="grid">
    <div className="grid-col grid-col--12">
      <h1 className="integrationsPortal-title">Webhooks Dashboard</h1>
      <DisplayWebhooks />
      <CreateWebhooks />
      <Footer />
    </div>
  </div>
);

export default WebhooksPage;
