import deepcopy from 'clone';
import virtualize from '../utils/virtualize';

import Component from '../utils/Component';
import LanguageActions from '../actions/LanguageActions';

export default class LanguageSelector extends Component {
  constructor(element) {
    super();

    this.template = virtualize(element);
    this.addEventListener('change', 'select', this._onChange.bind(this));
  }

  _onChange(event) {
    LanguageActions.change(event.target.value);
  }

  render() {
    return deepcopy(this.template, false);
  }
}

setTimeout(() => {
  for (const e of document.querySelectorAll('[data-component="languageSelector"]')) {
    const component = new LanguageSelector(e);
    component.replace(e);
  }
});
