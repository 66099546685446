import React from 'react';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { renderRoutes } from 'react-router-config';

import store, { history } from '../store';

const SpaContainer = (props) => (
  <AppContainer>
    <Provider store={store}>
      <ConnectedRouter history={history}>{renderRoutes(props.createRoutes())}</ConnectedRouter>
    </Provider>
  </AppContainer>
);

export default SpaContainer;
