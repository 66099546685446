const filters = require('../../escrow_www/static/js/utils/filters');
const { format, gettext, setattr, xmlattr, urlfor, tosymbol } = filters;

class TranslationExtension {
  constructor() {
    this.tags = ['trans'];
  }

  parse(parser, nodes) {
    const tok = parser.nextToken();
    const args = parser.parseSignature(null, true);
    parser.advanceAfterBlockEnd(tok.value);
    const body = parser.parseUntilBlocks('endtrans');
    parser.advanceAfterBlockEnd();
    return new nodes.CallExtension(this, 'run', args, [body]);
  }

  run(context, textId) {
    return gettext(textId());
  }
}

module.exports = function configure(env) {
  env.addFilter('format', format);
  env.addFilter('setattr', setattr);
  env.addFilter('xmlattr', xmlattr);
  env.addFilter('to_symbol', tosymbol);
  env.addGlobal('gettext', gettext);
  env.addGlobal('url_for', urlfor);
  env.addExtension('TranslationExtension', new TranslationExtension());
};
