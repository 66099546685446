import React from 'react';
import { Field } from 'redux-form';
import classnames from 'classnames';

const ListOption = ({
  id,
  name,
  title,
  subtitle,
  paymentMethodEta,
  content,
  showContent,
  badgeText,
  onChange,
  alternate,
  small,
  logo,
  disabled,
}) => (
  <label
    key={id}
    className={classnames(
      'checkout-paymentOption',
      { 'is-disabled': disabled },
      {
        alternate: alternate,
      },
      { 'checkout-paymentOption--small': small }
    )}
  >
    {logo && <div className="checkout-paymentOption-logo">{logo}</div>}
    <div className="checkout-paymentOption-text">
      <div className="checkout-paymentOption-text-title-container">
        <span className="checkout-paymentOption-text-title">{title}</span>
        {badgeText && <span className="checkout-paymentOption-text-badge">{badgeText}</span>}
      </div>
      {subtitle && <p className="checkout-paymentOption-text-detail">{subtitle}</p>}
      {paymentMethodEta && <p className="checkout-paymentOption-text-detail">{paymentMethodEta}</p>}
      {showContent && content}
    </div>
    <div>
      {!disabled && (
        <Field
          name={name}
          type="radio"
          component="input"
          className="checkout-paymentOption-radio"
          value={id}
          onChange={onChange}
          data-e2e-target="checkout-payment-method"
          data-tracking-name={name}
        />
      )}
      <div className="checkout-paymentOption-pseudo" />
    </div>
  </label>
);
export default ListOption;
