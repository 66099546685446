import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import ReactSVG from 'react-svg';
import { Stack } from '@mui/material';

import {
  V3KYCMobilePages as Pages,
  KYC_FILES as FILES,
  KYC_BUTTONS as BUTTONS,
  KYC_TITLES as TITLES,
  KYC_SUBTITLES as SUBTITLES,
} from 'spa/constants/VerificationConstants';
import {
  kycUploadedFileInfoSelector,
  kycImagesSelector,
  kycCompanyDocumentSelector,
} from 'spa/features/kyc/kycSlice';
import { SmallText } from 'spa/features/ui/EscrowTypography';
import { useUploadFileMutation, useVerifyCustomerMutation } from 'spa/features/kyc/kycApi';
import { updateKycImageId, updateUploadedFilesInfo } from 'spa/features/kyc/kycSlice';
import { ERROR_TYPES } from 'spa/constants/VerificationConstants';

import MobilePage from '../../components/MobilePage';
import UploadFileButton from '../../components/ExtraButtons/UploadFileButton';
import KYCAlert from '../../components/KYCAlert';

const fileKey = FILES.COMPANY_DOCUMENT;

const CompanyFileReviewPage = () => {
  const dispatch = useDispatch();

  const companyFileInfo = useSelector(kycUploadedFileInfoSelector(fileKey));
  const companyFileName =
    companyFileInfo && companyFileInfo.fileName ? companyFileInfo.fileName : null;
  const companyFileSize =
    companyFileInfo && companyFileInfo.fileSize ? companyFileInfo.fileSize : null;
  const kycImages = useSelector(kycImagesSelector);
  const kycCompanyDocument = useSelector(kycCompanyDocumentSelector);

  const [nextPageTrigger, setNextPageTrigger] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm();

  const [
    uploadFile,
    {
      data: uploadData,
      isSuccess: isSuccessUpload,
      isLoading: isLoadingUpload,
      error: uploadError,
    },
  ] = useUploadFileMutation();

  // eslint-disable-next-line no-console
  console.log(
    'uploadData, isSuccessUpload, isLoadingUpload, error',
    uploadData,
    isSuccessUpload,
    isLoadingUpload,
    uploadError
  );

  const [
    verifyCustomerT3,
    {
      isLoading: isLoadingVerify,
      isError: isErrorVerify,
      isSuccess: isSuccessVerify,
      error: verifyError,
    },
  ] = useVerifyCustomerMutation();

  // eslint-disable-next-line no-console
  console.log(
    'isLoadingVerify, isErrorVerify, verifyError, isSuccessVerify',
    isLoadingVerify,
    isErrorVerify,
    verifyError,
    isSuccessVerify
  );

  const verifyT3KYC = useCallback(async () => {
    const t3DocumentsObject = [];

    t3DocumentsObject.push({
      file_ids: [kycImages[fileKey].id],
      proof_type: kycCompanyDocument.documentType,
      proof_for: ['company_existence'],
    });

    const payload = {
      account: {
        type: 'company',
        relationship: kycCompanyDocument.relationship,
        company_type: kycCompanyDocument.companyType,
      },
      documents: t3DocumentsObject,
    };

    await verifyCustomerT3(payload);
  }, [kycCompanyDocument, verifyCustomerT3, kycImages]);

  useEffect(() => {
    if (isSuccessUpload && uploadData) {
      const uploadId = uploadData.id;
      if (uploadId) {
        dispatch(updateKycImageId({ fileKey, id: uploadId }));
      }
    }
  }, [uploadData, dispatch, isSuccessUpload]);

  useEffect(() => {
    if (isSubmitting && isSuccessVerify) {
      setNextPageTrigger(true);
    }
  }, [isSubmitting, isSuccessVerify]);

  useEffect(() => {
    if (isSubmitting && (uploadError || verifyError)) {
      setIsSubmitting(false);
    }
  }, [isSubmitting, uploadError, verifyError]);

  const disabled = isLoadingUpload || isSubmitting;

  return (
    <form>
      <MobilePage
        title={TITLES.UPLOADED_FILE}
        subtitle={SUBTITLES.UPLOADED_FILE}
        ctaText={verifyError ? BUTTONS.TryAgain : BUTTONS.SubmitFile}
        nextPageTrigger={nextPageTrigger}
        onSubmit={handleSubmit(() => {
          if (isValid) {
            // To allow time for the file to API calls to end
            // before moving to the next page,
            // we instead set the Submit state to true
            setIsSubmitting(true);
            verifyT3KYC();
          }
        })}
        nextPage={Pages.COMPANY_UPLOAD_SUCCESS}
        disableButtons={disabled}
        ExtraButton={(props) => (
          <UploadFileButton
            {...props}
            fileKey={fileKey}
            control={control}
            onUploadFile={(file) => {
              uploadFile(file);
              dispatch(
                updateUploadedFilesInfo({ fileKey, fileName: file.name, fileSize: file.size })
              );
            }}
            disabled={disabled}
          >
            Replace file
          </UploadFileButton>
        )}
      >
        {uploadError && (
          <KYCAlert
            isError={uploadError}
            errorType={ERROR_TYPES.FILE_UPLOAD_FAILED}
            xRequestId={uploadError.xRequestId}
          />
        )}
        {verifyError && (
          <KYCAlert
            isError={verifyError}
            errorType={ERROR_TYPES.SUBMISSION_FAILED}
            xRequestId={verifyError.xRequestId}
          />
        )}
        <Stack direction="row" sx={{ gap: '8px' }}>
          <ReactSVG src="spa/../../build/images/kyc/file-display-thumbnail.svg" />
          <Stack direction="column">
            {!uploadError && (
              <Fragment>
                <SmallText sx={{ fontSize: '16px', fontWeight: '600' }}>
                  {companyFileName}
                </SmallText>
                <SmallText sx={{ fontSize: '16px' }}>
                  {(companyFileSize / 1000 ** 2).toFixed(2)} MB
                </SmallText>
              </Fragment>
            )}
          </Stack>
        </Stack>
      </MobilePage>
    </form>
  );
};

export default CompanyFileReviewPage;
