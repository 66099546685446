import classNames from 'classnames/dedupe';
import deepcopy from 'clone';
import virtualize from '../utils/virtualize';

import ModalActions from '../actions/ModalActions';
import ModalStore from '../stores/ModalStore';
import Component from '../utils/Component';

function getState(modalName) {
  return {
    show: ModalStore.showingModal() === modalName,
  };
}

export default class Modal extends Component {
  constructor(element, props = {}) {
    super();
    this.props = props;
    this.template = virtualize(element);
    this.initialTemplate = deepcopy(this.template, false);
    this.grabFocus = this.grabFocus.bind(this);
    this._onChange = this._onChange.bind(this);
    this._onKeyDown = this._onKeyDown.bind(this);
    this._onClose = this._onClose.bind(this);

    this.addEventListener('click', '[data-target="modal-close"]', this._onClose);
    this.addEventListener('click', '[data-target="modal-session-timeout-reload"]', this._onReload);
    this.setState(getState(this.props.name));
  }

  componentWillMount() {
    ModalActions.registerModal(this.props.name, this.props.persistent);
  }

  getTemplate() {
    return this.template;
  }

  componentWillDismount() {
    ModalStore.removeChangeListener(this._onChange);
  }

  componentHasMounted() {
    ModalStore.addChangeListener(this._onChange.bind(this));
  }

  grabFocus() {
    // Focus on the first text or email field in the form
    const firstText = this.rootNode.querySelector('input[type="email"], input[type="text"]');
    if (firstText) {
      firstText.focus();
    } else {
      this.rootNode.focus();
    }
  }

  _onChange() {
    this.setState(getState(this.props.name));
    const state = getState(this.props.name);
    if (state.show) {
      this.rootNode.addEventListener('keydown', this._onKeyDown);
      window.setTimeout(this.grabFocus, 300);
    }
  }

  _onClose() {
    this.rootNode.removeEventListener('keydown', this._onKeyDown);
    ModalActions.close(this.props.name);
  }

  _onKeyDown(event) {
    if (event.keyCode === 27) {
      this._onClose();
    }
  }

  _onReload(event) {
    event.preventDefault();
    window.location.reload();
  }

  render() {
    const vhtml = deepcopy(this.template, false);
    vhtml.properties.className = classNames(vhtml.properties.className, {
      'is-visible': !!this.state.show,
    });

    return vhtml;
  }
}

setTimeout(() => {
  for (const e of document.querySelectorAll('[data-component="modal"]')) {
    const component = new Modal(e, {
      name: e.attributes.getNamedItem('data-target').value,
      persistent: e.attributes.getNamedItem('data-target-modal-nonpersistent') === null,
    });
    component.replace(e, component.initialTemplate);
  }
});
