import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useIsMobileViewport } from 'spa/components/MaterialUI/Theme';
import { MediumText } from 'spa/features/ui/EscrowTypography';
import MobileKYC from 'spa/features/kyc/mobile/MobileKYC';
import DesktopKYC from 'spa/features/kyc/desktop/DesktopKYC';
import { saveCustomerId, saveEmail, savePhoneNumber } from 'spa/features/kyc/kycSlice';

const V3KYC = ({ customerId }) => {
  const isMobileViewport = useIsMobileViewport();

  return (
    <Fragment>
      {isMobileViewport ? (
        <MobileKYC customerId={customerId} />
      ) : (
        <DesktopKYC customerId={customerId} isMobile={isMobileViewport} />
      )}
    </Fragment>
  );
};

const V3KYCContainer = () => {
  const dispatch = useDispatch();

  const customerId = useSelector((state) => state.customer.custId);
  useEffect(() => {
    dispatch(saveCustomerId({ customerId }));
  }, [customerId, dispatch]);

  const phoneNumber = useSelector((state) => state.customer.phoneNumber);
  useEffect(() => {
    if (phoneNumber) {
      dispatch(savePhoneNumber({ phoneNumber }));
    }
  }, [phoneNumber, dispatch]);

  const email = useSelector((state) => state.customer.email);
  useEffect(() => {
    dispatch(saveEmail({ email }));
  }, [email, dispatch]);

  return (
    <div className="kycv3">
      {customerId ? <V3KYC customerId={customerId} /> : <MediumText> Loading... </MediumText>}
    </div>
  );
};

export default V3KYCContainer;
