import { connect } from 'react-redux';
import { WirePaymentReviewPanel } from 'spa/components/CheckoutDetails/V2';
import PaymentConstants from 'spa/constants/PaymentConstants';
import { confirmPaymentMethod } from 'spa/actions/CheckoutActions';
import {
  transactionOrDraftSelector,
  transactionDraftTotalSelector,
  transactionTotalsSelector,
} from 'spa/selectors/TransactionSelectors';
import { paymentsPageWireDetailsSelector } from 'spa/selectors/PaymentsPageSelectors';
import { getCurrencyTagFromApiCurrency } from 'escrow-common-js/dist/utils';
import { checkoutConfirmPaymentMethodSelector } from '../../../selectors/CheckoutSelectors';

const mapStateToProps = (state, ownProps) => {
  const transaction = transactionOrDraftSelector(state);
  const wireDetails = paymentsPageWireDetailsSelector(state);
  const {
    loading: confirmPaymentMethodLoading,
    success: confirmPaymentMethodSuccess,
    error: confirmPaymentMethodError,
  } = checkoutConfirmPaymentMethodSelector(state);

  const currency = getCurrencyTagFromApiCurrency(transaction.currency);
  let transactionTotal = (transactionTotalsSelector(transaction) || {})[
    PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER
  ];
  if (!transactionTotal) {
    transactionTotal = transactionDraftTotalSelector(state, true);
  }

  return {
    ...ownProps,
    transaction,
    wireDetails,
    transactionTotal,
    confirmPaymentMethodLoading,
    confirmPaymentMethodSuccess,
    confirmPaymentMethodError,
    currency,
  };
};

const mapDispatchToProps = (dispatch) => ({
  confirmPaymentMethod: () => {
    dispatch(
      confirmPaymentMethod({
        paymentMethod: PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER,
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WirePaymentReviewPanel);
