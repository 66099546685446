import React from 'react';
import { reduxForm } from 'redux-form';
import { bindRoutineToReduxForm } from 'redux-saga-routines';
import { FormError, ActionButton } from 'spa/components/form';
import { triggerCardVerificationForm as triggerCardVerificationFormRoutine } from 'spa/actions/PaymentsActions';
import PaymentConstants from 'spa/constants/PaymentConstants';
import { gettext } from '../../../utils/filters';

const TriggerChargeForm = (props) => {
  const { submitting, error } = props;
  return (
    <div>
      {error && <FormError error={error} />}
      <form
        onSubmit={props.handleSubmit(bindRoutineToReduxForm(triggerCardVerificationFormRoutine))}
      >
        <ActionButton
          className="btn btn--secondary"
          name="submit"
          type="submit"
          label={gettext('Charge my card')}
          loading={submitting}
        />
      </form>
    </div>
  );
};

export default reduxForm({
  form: PaymentConstants.CREATE_TRIGGER_CHARGE_FORM,
  enableReinitialize: true,
})(TriggerChargeForm);
