import { Currency } from 'escrow-common-js/dist/constants';
import { gettext } from '../utils/filters';

export default {
  CALCULATOR_VALUE_UPDATED: 'CALCULATOR_VALUE_UPDATED',
  SEARCH_HOVER: 'CALCULATOR_SEARCH_HOVER',
  SEARCH_SELECT: 'CALCULATOR_SEARCH_SELECT',
  SEARCH_KEYSTROKE_INC: 'CALCULATOR_SEARCH_KEYSTROKE_INC',
  SEARCH_KEYSTROKE_CLEAR: 'CALCULATOR_SEARCH_KEYSTROKE_CLEAR',
  CALCULATOR_SEARCH: 'CALCULATOR_SEARCH',
  PAYMENT_METHOD_CHANGED: 'PAYMENT_METHOD_CHANGED',
  SEARCH_DEFAULTS: [
    {
      phrase: gettext('Domains'),
      category: 'DNQP',
    },
    {
      phrase: gettext('Cars, Trucks, etc.'),
      category: 'MVQP',
    },
    {
      phrase: gettext('Contracted Services'),
      category: 'MSQP',
    },
    {
      phrase: gettext('Milestone Transactions'),
      category: 'MSQP',
    },
    {
      phrase: gettext('Jewelry'),
      category: 'GMQP',
    },
    {
      phrase: gettext('Antiques'),
      category: 'GMQP',
    },
    {
      phrase: gettext('Electronics'),
      category: 'GMQP',
    },
  ],
  DOMAIN_TRANSACTION_TYPE: 'DNQP',
  MOTOR_VEHICLE_TRANSACTION_TYPE: 'MVQP',
  GENERAL_TRANSACTION_TYPE: 'GMQP',
  CREDIT_PAYPAL_METHOD: 'credit-card-paypal',
  WIRE_METHOD: 'wire',
  CHECK_MONEY_ORDER_METHOD: 'check-money-order',
  PAYMENT_METHODS: [
    { name: gettext('Wire'), value: 'wire' },
    { name: gettext('Credit Card or PayPal'), value: 'credit-card-paypal' },
    { name: gettext('Check or Money Order'), value: 'check-money-order' },
  ],
  USD_CURRENCY: Currency.USD,
  EUR_CURRENCY: Currency.EUR,
  AUD_CURRENCY: Currency.AUD,
  GBP_CURRENCY: Currency.GBP,
  CAD_CURRENCY: Currency.CAD,
  ROLE_SELLER: 'seller',
  ROLE_BROKER: 'broker',
  DEFAULT_AMOUNT: '15000',
};
