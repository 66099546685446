/**
 * This is a pretty big hack, and it is needed because of how we treat (or
 * mistreat) the VNode objects. Ideally the VNode objects are immutable, but
 * because we update them to add new children some issues arise.
 *
 * Because we add new children to the VNodes, we need to then go update the
 * children count for each VNode manually. This count is actually a count of all
 * of the descendant nodes i.e All child nodes and all child nodes of those
 * child nodes and so on.
 */
export function updateChildrenCount(node) {
  let count = 0;

  // The children element isn't always defined for some reason. Whatever.
  if (node.children) {
    for (const child of node.children) {
      updateChildrenCount(child);
      // Update the count with the number of VNodes within this child. Add one
      // as we iterating over a VNode as well.
      count += child.count + 1;
    }
  }

  // Finally update the number of descendants VNodes for this VNode. This must
  // be set, but if there are no children to iterate over, then this will be set
  // to zero.
  node.count = count;
  return count;
}
