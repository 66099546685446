export default {
  PUSH_AUCTION_ID: 'OfferConstants.PUSH_AUCTION_ID',
  PUSH_AUCTION_TOKEN: 'OfferConstants.PUSH_AUCTION_TOKEN',
  PUSH_AUCTION_SOURCE: 'OfferConstants.PUSH_AUCTION_SOURCE',
  SET_TRANS_ID: 'OfferConstants.SET_TRANS_ID',

  INIT_MAKE_AN_OFFER: 'OfferConstants.INIT_MAKE_AN_OFFER',
  INIT_OFFER_MANAGEMENT: 'OfferConstants.INIT_OFFER_MANAGEMENT',
  GET_AUCTION: 'OfferConstants.GET_AUCTION',
  GET_AUCTION_OFFERS: 'OfferConstants.GET_AUCTION_OFFERS',
  CANCEL_AUCTION: 'OfferConstants.CANCEL_AUCTION',
  ACCEPT_OFFER: 'OfferConstants.ACCEPT_OFFER',
  ACCEPT_OFFER_FROM_MANAGEMENT: 'OfferConstants.ACCEPT_OFFER_FROM_MANAGEMENT',
  REJECT_OFFER: 'OfferConstants.REJECT_OFFER',
  CANCEL_OFFER: 'OfferConstants.CANCEL_OFFER',
  SUBMIT_OFFER: 'OfferConstants.SUBMIT_OFFER',
  SUBMIT_COUNTER_OFFER: 'OfferConstants.SUBMIT_COUNTER_OFFER',
  SUBMIT_OFFER_FORM: 'OfferConstants.SUBMIT_OFFER_FORM',
  CHANGE_VIEW: 'OfferConstants.CHANGE_VIEW',
  BUY_IT_NOW: 'OfferConstants.BUY_IT_NOW',
  BUY_IT_NOW_CTA: 'OfferConstants.BUT_IT_NOW_CTA',
  PAY_OFFER_TRANSACTION: 'OfferConstants.PAY_OFFER_TRANSACTION',

  CREATE_OFFER_FORM: 'create-offer-form',
  CREATE_COUNTER_OFFER_FORM: 'create-counter-offer-form',

  MAKE_OFFER_VIEW: 'OfferConstants.MAKE_OFFER_VIEW',
  MAKE_OFFER_SUCCESS_VIEW: 'OfferConstants.MAKE_OFFER_SUCCESS_VIEW',
};
