import React, { Fragment, useEffect } from 'react';
import window from 'window-shim';
import ReactSVG from 'react-svg';
import { gettext } from '../../../utils/filters';

const ConfirmationIcon = (
  <div className="checkout-confirmation-icon">
    <ReactSVG src={`../../../../build/images/global/confirmation-success-icon.svg`} />
  </div>
);

const getCtaMessage = (companyName, returnUrl) => {
  if (companyName && returnUrl) return `Return to ${companyName}`;
  if (returnUrl) return `Continue`;
  return `View Transaction`;
};

const ConfirmationMessageBox = ({
  isCurrentStep,
  heading,
  returnUrl,
  companyName,
  transactionId,
}) => {
  useEffect(() => {
    if (isCurrentStep) {
      window.scrollTo(0, 0);
    }
  }, [isCurrentStep]);

  if (!isCurrentStep) {
    return null;
  }

  return (
    <div className="checkout-confirmation">
      <div className="checkout-confirmation-inner">
        {ConfirmationIcon}
        <h3 className="checkout-confirmation-heading checkout-heading">{gettext(heading)}.</h3>
        <br />
        <Fragment>
          <p />
          <button
            className="checkout-card-btn checkout-card-btn--contained btn btn--secondary"
            onClick={() =>
              window.location.assign(
                returnUrl ||
                  (transactionId
                    ? `${window.config.www_base_url}/transaction/${transactionId}`
                    : `${window.config.www_base_url}/transactions`)
              )
            }
          >
            {getCtaMessage(companyName, returnUrl)}
          </button>
        </Fragment>
      </div>
    </div>
  );
};

export default ConfirmationMessageBox;
