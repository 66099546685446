import React, { useMemo, useState, useEffect } from 'react';

import Box from '@mui/material/Box';

import HeaderV3 from 'spa/containers/HeaderV3';
import { DEFAULT_ESCROW_PAGE } from 'spa/constants/HeaderConstants';
import { LoadingError } from 'spa/containers/Transactions/TransactionError';

export const TransactionDetailsErrorScreen = ({ xRequestId }) => {
  const loadingErrorText = xRequestId
    ? `We encountered an issue while loading your transaction.\nPlease try reloading the page.\n
    If the issue persists, contact support@escrow.com and provide the id: ${xRequestId}.`
    : 'We encountered an issue while loading your transaction.\nPlease try reloading the page.';

  return (
    <React.Fragment>
      <HeaderV3 title="Transaction details" pageType={DEFAULT_ESCROW_PAGE} />
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        <LoadingError text={loadingErrorText} colorClass="blue" />
      </Box>
    </React.Fragment>
  );
};
