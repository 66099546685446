import React, { useMemo, useState } from 'react';
import { sum } from 'lodash';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import { Stack, TableBody, TableHead, Divider } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import { formatCurrency } from 'spa/components/StartTransaction/util';
import { parseAmount } from 'spa/features/transaction/utils/parsers';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { EscrowPaper } from '../../../ui/EscrowPaper';
import { SmallText, MediumText, SmallLink } from '../../../ui/EscrowTypography';
import { EscrowLeftCell, EscrowRightCell } from '../../../ui/EscrowTable';
import { calculateFees } from '../../utils/transactionResponse';

const rejectionStatuses = [45, 50, 55, 60, 65, 85];

const displayCurrency = (value, prependNegative, currency = 'USD') => {
  const currencyFormattedString = formatCurrency(value, currency);
  if (prependNegative) {
    return `-${currencyFormattedString}`;
  }
  return currencyFormattedString;
};

const FeeDisplay = ({ value, displayAsCredit, currency }) => {
  let feeString = formatCurrency(value, currency);

  if (displayAsCredit) {
    feeString = `-${feeString}`;
  }

  return (
    <SmallText sx={{ color: displayAsCredit ? 'error.main' : undefined }}>{feeString}</SmallText>
  );
};

const ShowMoreText = ({ children }) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <React.Fragment>
      <SmallText sx={{ display: showMore ? 'block' : 'none' }}>{children}</SmallText>
      {!showMore && <SmallLink onClick={() => setShowMore(true)}>Show more</SmallLink>}
      {showMore && <SmallLink onClick={() => setShowMore(false)}>Show less</SmallLink>}
    </React.Fragment>
  );
};

const renderTotalText = (transactionStatus, userRole, currency) => {
  if (rejectionStatuses.includes(transactionStatus) && userRole === 'buyer') {
    const buyerProceedsText =
      transactionStatus === 45 ||
      transactionStatus === 50 ||
      transactionStatus === 55 ||
      transactionStatus === 65
        ? 'Total potential refund'
        : 'Total refund';
    return (
      <Box fontWeight="medium">
        {buyerProceedsText} ({currency.toUpperCase()})
      </Box>
    );
  } else if (rejectionStatuses.includes(transactionStatus) && userRole === 'seller') {
    return <Box fontWeight="medium">Total proceeds ({currency.toUpperCase()})</Box>;
  } else {
    return <Box fontWeight="medium">Total ({currency.toUpperCase()})</Box>;
  }
};

const pushFeeItem = (feeItem, feesDisplayedAsCredit, feesDisplayedAsNonCredit) => {
  if (feeItem.displayAsCredit) {
    feesDisplayedAsCredit.push(feeItem);
  } else {
    feesDisplayedAsNonCredit.push(feeItem);
  }
};

const calculateBuyerRefund = (subtotal, fees) => {
  let feesCopy = { ...fees };
  delete feesCopy.brokerFee;
  return subtotal + fees.brokerFee - sum(Object.values(feesCopy));
};

const renderBrokerViewBuyerTotalText = (transactionStatus) => {
  if (rejectionStatuses.includes(transactionStatus)) {
    const buyerProceedsText =
      transactionStatus === 45 ||
      transactionStatus === 50 ||
      transactionStatus === 55 ||
      transactionStatus === 65
        ? 'Buyer potential refund'
        : 'Buyer refund';
    return <Box fontWeight="medium">{buyerProceedsText}</Box>;
  } else {
    return <Box fontWeight="medium">Buyer payment</Box>;
  }
};
const TransactionItemDetails = ({ transaction, userRole }) => {
  const theme = useTheme();
  const isMobileViewport = useMediaQuery(theme.breakpoints.down('md'));
  const {
    transactionItems,
    currency,
    parties,
    transactionStatus,
    combineBrokerCommissionWithEscrowFee,
    partnerFeeLabel,
    commissionType,
    brokerFee,
    odometer,
    vin,
    taxes,
  } = transaction;

  const customer = useSelector((state) => state.customer);

  const currentUserIsBuyer = userRole === 'buyer';

  const feesDisplayedAsCredit = [];
  const feesDisplayedAsNonCredit = [];

  const shippingFee = useMemo(() => {
    const shippingFeePayer = transaction.shippingFeePayer;
    if (shippingFeePayer === undefined) {
      return 0;
    }

    let shippingFeePayerEmail = transaction.parties[shippingFeePayer].email;
    if (transaction.partyHiddenFrom[shippingFeePayer] !== undefined && userRole !== 'broker') {
      if (shippingFeePayer === 'seller') {
        shippingFeePayerEmail = 'confidential+1@escrow.com';
      } else {
        shippingFeePayerEmail = 'confidential+2@escrow.com';
      }
    }

    return parseFloat(transaction.fees[shippingFeePayerEmail].shippingFee);
  }, [transaction, userRole]);

  const displayShippingFeeForSeller =
    transaction.shippingFeeBeneficiary === 'seller' && userRole === 'seller';

  const fees = useMemo(
    () => calculateFees(transaction, customer.custEmail),
    [transaction, customer.custEmail]
  );

  const userTaxes = useMemo(() => {
    if (taxes[customer.custEmail]) {
      return taxes[customer.custEmail];
    }
    return undefined;
  }, [taxes, customer.custEmail]);

  const subtotal = useMemo(
    () =>
      parseFloat(transactionItems.reduce((runningTotal, item) => runningTotal + item.amount, 0)),
    [transactionItems]
  );

  const totalBuyerPaymentBeforeRejection = useMemo(() => {
    if (rejectionStatuses.includes(transactionStatus) && currentUserIsBuyer) {
      return subtotal + sum(Object.values(fees));
    }
    return undefined;
  }, [currentUserIsBuyer, subtotal, fees, transactionStatus]);

  const buyerTotal = useMemo(() => {
    if (userRole === 'broker') {
      const buyerFees = calculateFees(transaction, parties.buyer.email);
      if (rejectionStatuses.includes(transactionStatus)) {
        let buyerPaymentTotal = subtotal + sum(Object.values(buyerFees));
        return calculateBuyerRefund(buyerPaymentTotal, buyerFees);
      }
      return subtotal + sum(Object.values(buyerFees));
    }
    return undefined;
  }, [userRole, subtotal, parties, transactionStatus, totalBuyerPaymentBeforeRejection]);

  const sellerTotal = useMemo(() => {
    if (userRole === 'broker') {
      if (rejectionStatuses.includes(transactionStatus)) {
        return shippingFee ? shippingFee : 0;
      }
      const sellerFees = calculateFees(transaction, parties.seller.email);
      return subtotal - sum(Object.values(sellerFees));
    }
    return undefined;
  }, [userRole, subtotal, parties, transactionStatus]);

  const brokerTotal = useMemo(() => {
    if (userRole === 'broker') {
      if (rejectionStatuses.includes(transactionStatus)) {
        return 0;
      }
      const brokerFees = calculateFees(transaction, parties.broker.email);
      return brokerFee - sum(Object.values(brokerFees));
    }
    return undefined;
  }, [userRole, brokerFee, parties, transactionStatus]);

  const total = useMemo(() => {
    if (rejectionStatuses.includes(transactionStatus) && currentUserIsBuyer) {
      return calculateBuyerRefund(totalBuyerPaymentBeforeRejection, fees);
    }
    if (rejectionStatuses.includes(transactionStatus) && !currentUserIsBuyer) {
      return shippingFee ? shippingFee : 0;
    }
    if (currentUserIsBuyer) {
      return subtotal + sum(Object.values(fees));
    }
    if (displayShippingFeeForSeller) {
      return subtotal - sum(Object.values(fees)) + shippingFee;
    }
    return subtotal - sum(Object.values(fees));
  }, [
    currentUserIsBuyer,
    subtotal,
    fees,
    transactionStatus,
    totalBuyerPaymentBeforeRejection,
    shippingFee,
  ]);

  if (fees.processingFee && combineBrokerCommissionWithEscrowFee) {
    const feeItem = {
      label: 'Processing fee',
      value: fees.processingFee,
      displayAsCredit: !currentUserIsBuyer || rejectionStatuses.includes(transactionStatus),
    };
    pushFeeItem(feeItem, feesDisplayedAsCredit, feesDisplayedAsNonCredit);
  }

  if (fees.titleCollectionFee) {
    const feeItem = {
      label:
        userTaxes && userTaxes.titleCollection
          ? 'Title collection fee with ' + userTaxes.titleCollection.toUpperCase()
          : 'Title collection fee',
      value: fees.titleCollectionFee,
      displayAsCredit: !currentUserIsBuyer || rejectionStatuses.includes(transactionStatus),
    };
    pushFeeItem(feeItem, feesDisplayedAsCredit, feesDisplayedAsNonCredit);
  }

  if (fees.lienHolderPayoffFee) {
    const feeItem = {
      label:
        userTaxes && userTaxes.lienHolderPayoff
          ? 'Lien holder payoff fee with ' + userTaxes.lienHolderPayoff.toUpperCase()
          : 'Lien holder payoff fee',
      value: fees.lienHolderPayoffFee,
      displayAsCredit: !currentUserIsBuyer,
    };
    pushFeeItem(feeItem, feesDisplayedAsCredit, feesDisplayedAsNonCredit);
  }

  if (fees.brokerFee && !combineBrokerCommissionWithEscrowFee) {
    const feeItem = {
      label: 'Broker fee',
      value: fees.brokerFee,
      displayAsCredit: !currentUserIsBuyer,
    };
    pushFeeItem(feeItem, feesDisplayedAsCredit, feesDisplayedAsNonCredit);
  }

  if (fees.conciergeFee) {
    const feeItem = {
      label:
        userTaxes && userTaxes.concierge
          ? 'Concierge fee with ' + userTaxes.concierge.toUpperCase()
          : 'Concierge fee',
      value: fees.conciergeFee,
      displayAsCredit: !currentUserIsBuyer || rejectionStatuses.includes(transactionStatus),
    };
    pushFeeItem(feeItem, feesDisplayedAsCredit, feesDisplayedAsNonCredit);
  }

  if (fees.partnerFee) {
    const feeItem = {
      label: partnerFeeLabel,
      value: fees.partnerFee,
      displayAsCredit: !currentUserIsBuyer || rejectionStatuses.includes(transactionStatus),
    };
    pushFeeItem(feeItem, feesDisplayedAsCredit, feesDisplayedAsNonCredit);
  }

  if (fees.combinedPartnerAndEscrowFee) {
    const feeItem = {
      label: partnerFeeLabel,
      value: fees.combinedPartnerAndEscrowFee,
      displayAsCredit: !currentUserIsBuyer || rejectionStatuses.includes(transactionStatus),
    };
    pushFeeItem(feeItem, feesDisplayedAsCredit, feesDisplayedAsNonCredit);
  }

  if (fees.shippingFee || displayShippingFeeForSeller) {
    const feeItem = {
      label: 'Shipping fee',
      value: displayShippingFeeForSeller ? shippingFee : fees.shippingFee,
      displayAsCredit:
        (!currentUserIsBuyer && !displayShippingFeeForSeller) ||
        (currentUserIsBuyer && rejectionStatuses.includes(transactionStatus)),
    };
    pushFeeItem(feeItem, feesDisplayedAsCredit, feesDisplayedAsNonCredit);
  }

  if (fees.paymentProcessingFee) {
    const feeItem = {
      label:
        userTaxes && userTaxes.paymentProcessingFee
          ? 'Payment processing fee with ' + userTaxes.paymentProcessingFee.toUpperCase()
          : 'Payment processing fee',
      value: fees.paymentProcessingFee,
      displayAsCredit: !currentUserIsBuyer || rejectionStatuses.includes(transactionStatus),
    };
    pushFeeItem(feeItem, feesDisplayedAsCredit, feesDisplayedAsNonCredit);
  }

  if (fees.escrowFee && !combineBrokerCommissionWithEscrowFee) {
    const feeItem = {
      label:
        userTaxes && userTaxes.escrowFee
          ? 'Escrow fee with ' + userTaxes.escrowFee.toUpperCase()
          : 'Escrow fee',
      value: fees.escrowFee,
      displayAsCredit: !currentUserIsBuyer || rejectionStatuses.includes(transactionStatus),
    };
    pushFeeItem(feeItem, feesDisplayedAsCredit, feesDisplayedAsNonCredit);
  }

  if (fees.disbursementFee) {
    const feeItem = {
      label:
        userTaxes && userTaxes.disbursement
          ? 'Disbursement fee with ' + userTaxes.disbursement.toUpperCase()
          : 'Disbursement fee',
      value: fees.disbursementFee,
      displayAsCredit: !currentUserIsBuyer || rejectionStatuses.includes(transactionStatus),
    };
    pushFeeItem(feeItem, feesDisplayedAsCredit, feesDisplayedAsNonCredit);
  }

  const feeItems = [...feesDisplayedAsNonCredit, ...feesDisplayedAsCredit];

  return (
    <Box>
      <EscrowPaper
        sx={{
          gap: '32px',
        }}
      >
        <Table>
          <TableHead sx={{ padding: '0' }}>
            <TableRow>
              <TableCell sx={{ paddingTop: '0', paddingLeft: '0', borderBottom: 'none' }}>
                <Stack direction="row" alignItems="center" gap={1}>
                  <DescriptionOutlinedIcon
                    sx={{ color: 'secondaryLight.dark', height: 20, width: 20 }}
                  />
                  <MediumText>Item details</MediumText>
                </Stack>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ padding: '0' }}>
            {transactionItems.map((item, index) => (
              // We're not supposed to use indexes as keys, but this is a static list
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={index} sx={{ padding: '0', verticalAlign: 'top' }}>
                <EscrowLeftCell>
                  <SmallText component="div">
                    <Box fontWeight="medium">{item.title}</Box>
                  </SmallText>
                  {!isMobileViewport && (
                    <React.Fragment>
                      <SmallText>{item.description}</SmallText>
                      {vin && <SmallText>VIN: {vin}</SmallText>}
                      {odometer && <SmallText>Odometer: {odometer}</SmallText>}
                    </React.Fragment>
                  )}
                  {isMobileViewport && (
                    <ShowMoreText>
                      {item.description}
                      {vin && <SmallText>VIN: {vin}</SmallText>}
                      {odometer && <SmallText>Odometer: {odometer}</SmallText>}
                    </ShowMoreText>
                  )}
                </EscrowLeftCell>
                <EscrowRightCell>
                  {userRole === 'seller' && rejectionStatuses.includes(transactionStatus) ? (
                    <SmallText>{displayCurrency(0.0, false, currency)}</SmallText>
                  ) : (
                    <SmallText>{displayCurrency(item.amount, false, currency)}</SmallText>
                  )}
                </EscrowRightCell>
              </TableRow>
            ))}
            <TableRow sx={{ padding: '0', borderBottom: 'none' }}>
              <EscrowLeftCell>
                <SmallText>Subtotal</SmallText>
              </EscrowLeftCell>
              <EscrowRightCell>
                {userRole === 'seller' && rejectionStatuses.includes(transactionStatus) ? (
                  <SmallText>{displayCurrency(0.0, false, currency)}</SmallText>
                ) : (
                  <SmallText>{displayCurrency(subtotal, false, currency)}</SmallText>
                )}
              </EscrowRightCell>
            </TableRow>
            {currentUserIsBuyer && rejectionStatuses.includes(transactionStatus) ? (
              <TableRow sx={{ padding: '0', borderBottom: 'none' }}>
                <EscrowLeftCell>
                  <SmallText>Total paid amount (before rejection)</SmallText>
                </EscrowLeftCell>
                <EscrowRightCell>
                  <SmallText>
                    {displayCurrency(totalBuyerPaymentBeforeRejection, false, currency)}
                  </SmallText>
                </EscrowRightCell>
              </TableRow>
            ) : null}
            {userRole === 'broker' && buyerTotal ? (
              <TableRow sx={{ padding: '0', borderBottom: 'none' }}>
                <EscrowLeftCell>
                  <SmallText component="div">
                    {renderBrokerViewBuyerTotalText(transactionStatus)}
                  </SmallText>
                </EscrowLeftCell>
                <EscrowRightCell>
                  <SmallText component="div">
                    <Box fontWeight="medium">{displayCurrency(buyerTotal, false, currency)}</Box>
                  </SmallText>
                </EscrowRightCell>
              </TableRow>
            ) : null}
            {userRole === 'broker' && sellerTotal !== undefined ? (
              <TableRow sx={{ padding: '0', borderBottom: 'none' }}>
                <EscrowLeftCell>
                  <SmallText component="div">
                    <Box fontWeight="medium">Seller proceeds</Box>
                  </SmallText>
                </EscrowLeftCell>
                <EscrowRightCell>
                  <SmallText component="div">
                    <Box fontWeight="medium">{displayCurrency(sellerTotal, false, currency)}</Box>
                  </SmallText>
                </EscrowRightCell>
              </TableRow>
            ) : null}
            {userRole === 'broker' ? (
              <React.Fragment>
                <TableRow sx={{ padding: '0', borderBottom: 'none' }}>
                  <TableCell colSpan={2} sx={{ py: '8px', px: '0', border: 'none' }}>
                    <Divider />
                  </TableCell>
                </TableRow>
                <TableRow sx={{ padding: '0', borderBottom: 'none' }}>
                  <EscrowLeftCell>
                    <SmallText>Total broker commission</SmallText>
                  </EscrowLeftCell>
                  <EscrowRightCell>
                    <SmallText>{displayCurrency(brokerFee, false, currency)}</SmallText>
                  </EscrowRightCell>
                </TableRow>
              </React.Fragment>
            ) : null}

            {feeItems.map((feeItem) => (
              <TableRow sx={{ padding: '0', borderBottom: 'none' }}>
                <EscrowLeftCell>
                  <SmallText>{feeItem.label}</SmallText>
                </EscrowLeftCell>
                <EscrowRightCell>
                  <FeeDisplay
                    value={feeItem.value}
                    displayAsCredit={feeItem.displayAsCredit}
                    currency={currency}
                  />
                </EscrowRightCell>
              </TableRow>
            ))}

            {userRole !== 'broker' ? (
              <TableRow sx={{ padding: '0', borderBottom: 'none' }}>
                <EscrowLeftCell>
                  <SmallText component="div">
                    {renderTotalText(transactionStatus, userRole, currency)}
                  </SmallText>
                </EscrowLeftCell>
                <EscrowRightCell>
                  <SmallText component="div">
                    <Box fontWeight="medium">{formatCurrency(total, currency)}</Box>
                  </SmallText>
                </EscrowRightCell>
              </TableRow>
            ) : (
              <TableRow sx={{ padding: '0', borderBottom: 'none' }}>
                <EscrowLeftCell>
                  <SmallText component="div">
                    <Box fontWeight="medium">Broker proceeds ({currency.toUpperCase()})</Box>
                  </SmallText>
                </EscrowLeftCell>
                <EscrowRightCell>
                  <SmallText component="div">
                    <Box fontWeight="medium">{formatCurrency(brokerTotal, currency)}</Box>
                  </SmallText>
                </EscrowRightCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </EscrowPaper>
    </Box>
  );
};

export default TransactionItemDetails;
